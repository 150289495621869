import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import mapboxgl from "mapbox-gl";
import { PopupForm } from "./PopupForm";
import { isCoordinateInProvince } from "./isCoordinateInPEI";
import "./MapContainer.css";
import { CropTypes } from "./PopupForm";
import { regionsGeoJSON } from "./clipped_features";
import { IrrigationTypes } from "./PopupForm";
import Legend from "./Legend";
import { PopupFormIS } from "./PopupFormIS";
import AttributionModal from "./AttributionModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

mapboxgl.accessToken =
  "pk.eyJ1Ijoic2hyaXJhbTEwNTYiLCJhIjoiY2xrajQ0cmF2MG54ejNkcDR1cXp5ZWp0bCJ9.tugX_HHJ8KY0ueQW1Q2tvw";

const getMay30thOrCurrent = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const may30th = new Date(currentYear, 4, 30); // Months are 0-indexed in JS Date

  if (currentDate <= may30th) {
    return currentDate.toISOString().substring(0, 10);
  } else {
    return `${currentYear}-05-30`;
  }
};

function MapContainer() {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const popupRef = useRef(null);
  const [view, setView] = useState("map");
  const [selectedCropType, setSelectedCropType] = useState("Vegetables"); // Add this line
  const [selectedCrop, setSelectedCrop] = useState("Potato"); // Add this line
  const [irrigationType, setIrrigationType] = useState("Center Pivot"); // Set default value
  const [selectedDate, setSelectedDate] = useState(getMay30thOrCurrent()); // set today's date as default
  const [formVisible, setFormVisible] = useState(false);
  const viewRef = useRef(view);
  const isMobile = window.innerWidth <= 768;
  const isTablet = window.innerWidth >= 768 && window.innerWidth <= 1024;

  const currentDate = new Date();
  const maxDate = currentDate.toISOString().substring(0, 10);
  const oneAndHalfYearsAgo = new Date(
    currentDate.setMonth(currentDate.getMonth() - 18)
  );
  const minDate = oneAndHalfYearsAgo.toISOString().substring(0, 10);
  const hasGeolocateControl = useRef(false);
  const [attributionVisible, setAttributionVisible] = useState(false);

  // Initialize the geolocate control outside the useEffect:
  const geolocateRef = useRef(
    new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
    })
  );

  useEffect(() => {
    viewRef.current = view;
  }, [view]);

  useEffect(() => {
    if (popupRef.current) {
      popupRef.current.remove();
      popupRef.current = null;
    }
  }, [attributionVisible]);

  useEffect(() => {
    if (map.current) {
      if (view === "heatmap") {
        if (hasGeolocateControl.current) {
          map.current.removeControl(geolocateRef.current);
          hasGeolocateControl.current = false; // Update the ref after removal.
        }
      } else {
        if (!hasGeolocateControl.current) {
          map.current.addControl(geolocateRef.current, "bottom-right");
          hasGeolocateControl.current = true; // Update the ref after adding.
        }
      }
    }
  }, [view]);

  function handleCropTypeChange(e) {
    setSelectedCropType(e.target.value);
    setSelectedCrop("");
  }

  const updateWaterRequirementHeatmap = () => {
    if (
      view !== "heatmap" ||
      !selectedCropType ||
      !selectedCrop ||
      !irrigationType
    )
      return;

    const coordinates = [
      { latitude: 46.3, longitude: -63.58 },
      { latitude: 46.46, longitude: -61.99 },
      { latitude: 47.06, longitude: -64.0 },
      { latitude: 46.44, longitude: -63.84 },
      { latitude: 46.29, longitude: -63.12 },
      { latitude: 46.45, longitude: -62.58 },
    ];

    const promises = coordinates.map((coord) =>
      fetch(`${process.env.REACT_APP_BACKEND_URL}calcFieldEt0/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          crop: selectedCrop,
          cropType: selectedCropType,
          irrigationType: irrigationType,
          latitude: coord.latitude,
          longitude: coord.longitude,
          area: 1,
          sowingDate: selectedDate,
        }),
      }).then((response) => response.json())
    );

    Promise.all(promises).then((results) => {
      // Calculate the averages as described
      const avgFirstThreeValues =
        (results[0].etMm + results[1].etMm + results[2].etMm) / 3;
      const avgFifthAndSixthValues = (results[4].etMm + results[5].etMm) / 2;

      // Update the regionsGeoJSON data with the calculated values
      regionsGeoJSON.features[0].properties.etValue = avgFifthAndSixthValues; // average of the first three values
      regionsGeoJSON.features[1].properties.etValue = results[3].etMm; // fourth value
      regionsGeoJSON.features[2].properties.etValue = avgFirstThreeValues; // average of the fifth and sixth values

      // Check if the heatmap layer already exists, and remove it if so
      if (map.current.getLayer("heatmap")) {
        map.current.removeLayer("heatmap");
        map.current.removeSource("heatmap");
      }

      // Add the updated GeoJSON data as a source
      map.current.addSource("heatmap", {
        type: "geojson",
        data: regionsGeoJSON,
      });

      // Add a fill layer to display the heatmap
      map.current.addLayer({
        id: "heatmap",
        type: "fill",
        source: "heatmap",
        paint: {
          "fill-outline-color": "rgba(0, 0, 0, 0.2)",
          "fill-color": [
            "interpolate",
            ["linear"],
            ["get", "etValue"], // Use the 'etValue' property to define the fill color
            0.0,
            "rgb(45, 0, 85)", // Deep Purple
            0.2,
            "rgb(60, 10, 110)",
            0.4,
            "rgb(75, 20, 135)",
            0.6,
            "rgb(90, 35, 160)", // Muted Purple
            0.8,
            "rgb(105, 50, 185)",
            1.0,
            "rgb(120, 65, 210)",
            1.2,
            "rgb(130, 85, 230)", // Transition to Blue
            1.4,
            "rgb(140, 105, 245)",
            1.6,
            "rgb(145, 130, 255)",
            1.8,
            "rgb(150, 155, 255)", // Lighter Blue
            2.0,
            "rgb(150, 180, 255)",
            2.2,
            "rgb(145, 205, 255)",
            2.4,
            "rgb(135, 230, 255)", // Cyan Touch
            2.6,
            "rgb(125, 240, 250)",
            2.8,
            "rgb(110, 245, 240)",
            3.0,
            "rgb(95, 250, 225)", // Transition to Green
            3.2,
            "rgb(75, 250, 205)",
            3.4,
            "rgb(55, 245, 185)",
            3.6,
            "rgb(35, 240, 160)",
            3.8,
            "rgb(20, 230, 140)", // Muted Green
            4.0,
            "rgb(15, 220, 120)",
            4.2,
            "rgb(15, 210, 100)",
            4.4,
            "rgb(20, 190, 80)",
            4.6,
            "rgb(25, 170, 65)",
            4.8,
            "rgb(30, 150, 50)", // Earthy Green
            5.0,
            "rgb(40, 130, 40)",
            5.2,
            "rgb(50, 110, 30)",
            5.4,
            "rgb(65, 90, 20)",
            5.6,
            "rgb(80, 70, 15)",
            5.8,
            "rgb(95, 50, 10)",
            6.0,
            "rgb(110, 35, 10)", // Brownish Transition
            6.2,
            "rgb(120, 20, 5)",
            6.4,
            "rgb(130, 10, 0)",
            6.6,
            "rgb(140, 5, 0)", // Deep Brown
            6.8,
            "rgb(150, 0, 0)",
            7.0,
            "rgb(155, 0, 0)",
            7.2,
            "rgb(160, 0, 0)",
            7.4,
            "rgb(165, 0, 0)",
            7.6,
            "rgb(170, 0, 0)",
            7.8,
            "rgb(175, 0, 0)",
            8.0,
            "rgb(180, 0, 0)", // Muted Red
            8.2,
            "rgb(185, 5, 0)",
            8.4,
            "rgb(190, 10, 0)",
            8.6,
            "rgb(195, 15, 0)",
            8.8,
            "rgb(200, 20, 0)",
            9.0,
            "rgb(205, 25, 0)", // Earthy Red
            9.2,
            "rgb(210, 30, 0)",
            9.4,
            "rgb(215, 35, 0)",
            9.6,
            "rgb(220, 40, 0)",
            9.8,
            "rgb(225, 45, 0)",
            10.0,
            "rgb(230, 50, 0)", // Warm Earthy Finish
          ],
          "fill-opacity": 0.8,
        },
      });
    });
  };

  useEffect(() => {
    updateWaterRequirementHeatmap();
  }, [selectedCropType, selectedCrop, irrigationType, view, selectedDate]);

  const toggleHeatmap = (newView) => {
    setView(newView);
    if (newView === "heatmap") {
      updateWaterRequirementHeatmap(); // Call this function to use the heatmap layer defined in updateWaterRequirementHeatmap
    } else {
      if (map.current.getLayer("heatmap")) {
        map.current.removeLayer("heatmap");
        map.current.removeSource("heatmap");
      }
    }
  };

  useEffect(() => {
    const bounds = [
      [-74.0, 41.0], // Southwest coordinates (3 degrees added west and 3 degrees added south)
      [-52.0, 52.0], // Northeast coordinates (3 degrees added east and 3 degrees added north)
    ];

    const xOffsetMobile = -0.08; // X-axis offset for mobile devices
    const xOffsetDesktop = 0.0; // X-axis offset for desktop devices

    const xOffset = isMobile || isTablet ? xOffsetMobile : xOffsetDesktop;

    const center = [
      (bounds[0][0] + bounds[1][0]) / 2, // Calculate the center longitude
      (bounds[0][1] + bounds[1][1]) / 2, // Calculate the center latitude
    ];

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-v9",
      attributionControl: false,
      maxBounds: bounds,
      cluster: false,
      center: center, // Set the initial center coordinates
      zoom: isMobile ? 7 : isTablet ? 8 : 8.5,
      pitch: 35,
      bearing: isMobile || isTablet ? -30 : 5,
    });

    map.current.on("load", () => {
      fetch(`${process.env.REACT_APP_BACKEND_URL}weatherStationEt0/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          data.forEach((item) => {
            const element = document.createElement("div");
            element.className = "custom-marker";
            const etValue = item.et.toFixed(2);
            element.innerHTML = `<span class="marker-number">${etValue} <span class="marker-unit">mm/day</span></span>`;
            new mapboxgl.Marker(element)
              .setLngLat([item.longitude, item.latitude])
              .addTo(map.current);
          });
        })
        .catch((error) => {
          console.error("Error sending GET request:", error);
        });
      if (map.current) {
        if (view === "heatmap" && hasGeolocateControl.current) {
          map.current.removeControl(geolocateRef.current);
          hasGeolocateControl.current = false;
        } else if (view !== "heatmap" && !hasGeolocateControl.current) {
          map.current.addControl(geolocateRef.current, "bottom-right");
          hasGeolocateControl.current = true;
        }
      }
    });

    map.current.on("click", (event) => {
      const { lat, lng } = event.lngLat;

      if (popupRef.current) {
        popupRef.current.remove();
        popupRef.current = null;
      }
      setAttributionVisible(false);

      if (viewRef.current === "heatmap") return;

      const pixel = map.current.project([lng, lat]);

      // Define your pixel offsets. Adjust these values to get the desired offset.
      const xOffset = isMobile ? 0 : 0; // Example pixel values, adjust as necessary
      const yOffset = isMobile
        ? viewRef.current === "irrigation"
          ? 365
          : 175
        : viewRef.current === "irrigation"
        ? 365
        : -150;

      pixel.x += xOffset;
      pixel.y += yOffset;

      const adjustedLatLng = map.current.unproject(pixel);

      map.current.flyTo({
        center: adjustedLatLng,
      });

      if (!isCoordinateInProvince(lat, lng)) {
        return;
      }

      const container = document.createElement("div");
      if (viewRef.current === "irrigation") {
        ReactDOM.render(
          <PopupFormIS latitude={lat} longitude={lng} />,
          container
        );
      } else {
        ReactDOM.render(
          <PopupForm latitude={lat} longitude={lng} />,
          container
        );
      }

      const newPopup = new mapboxgl.Popup()
        .setLngLat([lng, lat])
        .setDOMContent(container)
        .addTo(map.current);

      popupRef.current = newPopup;
    });

    return () => {
      if (popupRef.current) popupRef.current.remove();
    };
  }, []);

  useEffect(() => {
    if (map.current && map.current.isStyleLoaded()) {
      toggleHeatmap(view);
      if (popupRef.current) {
        popupRef.current.remove();
        popupRef.current = null;
      }
      if (attributionVisible) setAttributionVisible(false);
    }
  }, [view]);

  // Inside the return statement of the MapContainer function
  return (
    <div className="map-wrapper">
      <div className="tab-container">
        <div className="wrapper">
          <div
            className={`tab-switch ${
              view === "map" ? "left" : view === "heatmap" ? "center" : "right"
            } text-center`}
          >
            <div
              className={`tab ${view === "map" ? "active" : ""}`}
              onClick={() => toggleHeatmap("map")}
            >
              PCWR
            </div>
            <div
              className={`tab ${view === "heatmap" ? "active" : ""}`}
              onClick={() => toggleHeatmap("heatmap")}
            >
              Island Overview
            </div>
            <div
              className={`tab ${view === "irrigation" ? "active" : ""}`}
              onClick={() => toggleHeatmap("irrigation")}
            >
              ACWR
            </div>
          </div>
        </div>
        {view === "heatmap" && (
          <div className="crop-selection-container">
            <button
              className={`crop-toggle-btn ${formVisible ? "hidden" : ""}`} // Add conditional class
              onClick={() => {
                setFormVisible(!formVisible);
                if (popupRef.current) {
                  popupRef.current.remove();
                  popupRef.current = null;
                }
              }}
            >
              &#9776;
            </button>
            <div
              className={formVisible ? "crop-selection open" : "crop-selection"}
            >
              <button
                className="close-btn"
                onClick={() => setFormVisible(false)}
              >
                &times;
              </button>
              <div className="form-group">
                <label htmlFor="cropType">Crop Type:</label>
                <select
                  id="cropType"
                  value={selectedCropType}
                  onChange={handleCropTypeChange}
                  required
                >
                  <option value="">Select Crop Type</option>
                  {Object.keys(CropTypes).map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="crop">Crop:</label>
                <select
                  id="crop"
                  value={selectedCrop}
                  onChange={(e) => setSelectedCrop(e.target.value)}
                  required
                  disabled={!selectedCropType}
                >
                  <option value="">Select Crop</option>
                  {selectedCropType &&
                    Array.from(CropTypes[selectedCropType]).map((cropName) => (
                      <option key={cropName} value={cropName}>
                        {cropName}
                      </option>
                    ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="irrigation-type">Irrigation Type:</label>
                <select
                  id="irrigation-type"
                  value={irrigationType}
                  onChange={(e) => setIrrigationType(e.target.value)}
                  required
                >
                  <option value="">Select Irrigation Type</option>
                  {Array.from(IrrigationTypes).map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="sowing-date">Sowing Date:</label>
                <input
                  type="date"
                  id="sowing-date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  min={minDate}
                  max={maxDate}
                  required
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div ref={mapContainer} className="map-container">
        {view === "heatmap" && <Legend isVisible={true} />}
      </div>
      {view !== "heatmap" && (
        <>
          <button
            className="attribution-button"
            onClick={() => setAttributionVisible(!attributionVisible)}
          >
            <FontAwesomeIcon
              icon={faInfo}
              size="lg"
              style={{ color: "#000000" }}
            />
          </button>

          <AttributionModal
            visible={attributionVisible}
            onClose={() => setAttributionVisible(false)}
          />
        </>
      )}
    </div>
  );
}

export default MapContainer;
