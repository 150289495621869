import React from "react";
import "./AttributionModal.css";

function AttributionModal({ visible, onClose }) {
  if (!visible) return null;

  return (
    <div className="attribution-modal">
      <button className="close-btn2" onClick={onClose}>
        &times;
      </button>

      <h2>About Us</h2>
      <br />
      <p>
        This application is owned by the Centre of Excellence in Food Security
        and Sustainability at the University of Prince Edward Island. <br />
        <br />
        Developed in partnership with Collabrix AI. <br />
        All rights reserved.
      </p>

      <br />

      <h3>Data Sources</h3>
      <br />
      <p>The weather and climate data used in this tool are derived from:</p>
      <br />
      <ul>
        <li>Environment and Climate Change Canada</li>
      </ul>

      <br />
      <h3>Contact Us</h3>
      <br />

      <p>
        If you have any questions, comments, or suggestions, please contact our
        team at:{" "}
        <a href="mailto:precisionirrigationpei@gmail.com">
          precisionirrigationpei@gmail.com
        </a>
      </p>
    </div>
  );
}

export default AttributionModal;
