export const regionsGeoJSON = {
  type: "FeatureCollection",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        id: "relation/2256614",
        "@id": "relation/2256614",
        "@relations": null,
        admin_level: "5",
        boundary: "administrative",
        land_area: "administrative",
        name: "Kings County",
        source: "StatisticsCanadaCensusBoundaries",
        type: "boundary",
        wikidata: "Q1742133",
        wikipedia: "en:Kings County, Prince Edward Island",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-62.8608907, 46.4167248],
              [-62.8674951, 46.433655],
              [-62.867594774210325, 46.434017376836692],
              [-62.8669237, 46.4340888],
              [-62.8642845, 46.4341882],
              [-62.8620443, 46.4343588],
              [-62.8599352, 46.4344574],
              [-62.8593216, 46.434599],
              [-62.8571643, 46.4349233],
              [-62.8557608, 46.4346916],
              [-62.8548817, 46.4346502],
              [-62.8539392, 46.4347983],
              [-62.8533053, 46.4347999],
              [-62.8531363, 46.4347353],
              [-62.8521223, 46.4347339],
              [-62.8515404, 46.4345418],
              [-62.8512815, 46.4345343],
              [-62.8509314, 46.4345122],
              [-62.8502001, 46.4345986],
              [-62.8496231, 46.434327],
              [-62.8492902, 46.4342098],
              [-62.8487524, 46.4341464],
              [-62.8478141, 46.43394],
              [-62.8469871, 46.4337049],
              [-62.8457914, 46.4335627],
              [-62.8454202, 46.4336037],
              [-62.8451625, 46.4335764],
              [-62.8450727, 46.4336335],
              [-62.844734, 46.4337074],
              [-62.8442465, 46.4337649],
              [-62.8431158, 46.4335967],
              [-62.8418471, 46.4338029],
              [-62.8394126, 46.4337562],
              [-62.8386107, 46.4335815],
              [-62.8374143, 46.4336424],
              [-62.8368498, 46.433355],
              [-62.8361325, 46.4329278],
              [-62.835906, 46.4328615],
              [-62.835285, 46.432461],
              [-62.8350369, 46.4321828],
              [-62.8348293, 46.4315234],
              [-62.83471, 46.4313048],
              [-62.8346719, 46.430985],
              [-62.8346048, 46.4305727],
              [-62.8346579, 46.4302675],
              [-62.8349749, 46.4299819],
              [-62.8354521, 46.4298086],
              [-62.8359959, 46.4297726],
              [-62.8363412, 46.4298743],
              [-62.8367213, 46.4298773],
              [-62.8368653, 46.4299732],
              [-62.8373784, 46.430251],
              [-62.8378496, 46.4307468],
              [-62.8379533, 46.4307458],
              [-62.8383214, 46.430378],
              [-62.8385266, 46.4298461],
              [-62.83892, 46.4284911],
              [-62.8394489, 46.4279408],
              [-62.8393635, 46.4277351],
              [-62.8393353, 46.4274395],
              [-62.8398958, 46.4270335],
              [-62.8400577, 46.4270445],
              [-62.8404954, 46.4268358],
              [-62.8410543, 46.4268361],
              [-62.8414359, 46.4270025],
              [-62.8419748, 46.427046],
              [-62.842221, 46.4269775],
              [-62.8423641, 46.4267067],
              [-62.8422551, 46.426604],
              [-62.8417297, 46.4264334],
              [-62.8414005, 46.4262565],
              [-62.8410326, 46.4260546],
              [-62.8408033, 46.4258448],
              [-62.840699, 46.4255708],
              [-62.8407933, 46.4246812],
              [-62.8406711, 46.4242275],
              [-62.8406528, 46.4238645],
              [-62.8411551, 46.4231819],
              [-62.8412397, 46.421312],
              [-62.8413114, 46.4204138],
              [-62.8415005, 46.4188178],
              [-62.8416694, 46.4183128],
              [-62.8421968, 46.4176907],
              [-62.8431827, 46.4170102],
              [-62.8449242, 46.4161206],
              [-62.8456113, 46.4154274],
              [-62.8460184, 46.4152679],
              [-62.8462892, 46.4150766],
              [-62.8467201, 46.4144278],
              [-62.8471145, 46.4141923],
              [-62.8472408, 46.4141999],
              [-62.8476505, 46.4140923],
              [-62.8489686, 46.4139153],
              [-62.8493758, 46.4138474],
              [-62.8511879, 46.4137883],
              [-62.8525413, 46.413218],
              [-62.8533664, 46.4128116],
              [-62.8537744, 46.4125405],
              [-62.8541089, 46.4124347],
              [-62.8548983, 46.4117604],
              [-62.8552257, 46.4116822],
              [-62.8556407, 46.4117699],
              [-62.8559981, 46.4117642],
              [-62.8566895, 46.4113779],
              [-62.856988, 46.4112989],
              [-62.8572175, 46.4111222],
              [-62.8572539, 46.410709],
              [-62.8576976, 46.4102279],
              [-62.8576995, 46.4100048],
              [-62.8573996, 46.4095341],
              [-62.8573642, 46.4088798],
              [-62.8574739, 46.4087794],
              [-62.8576011, 46.4086755],
              [-62.857707632343768, 46.408643692763988],
              [-62.8608907, 46.4167248],
            ],
          ],
          [
            [
              [-62.6675696, 45.959156],
              [-62.6710803, 45.9680854],
              [-62.6713274, 45.9681841],
              [-62.6713304, 45.9682219],
              [-62.6713367, 45.9682399],
              [-62.6715794, 45.9688673],
              [-62.6718416, 45.9695639],
              [-62.6719497, 45.9698274],
              [-62.6719846, 45.9699332],
              [-62.6721815, 45.970451],
              [-62.672693, 45.9717593],
              [-62.6733781, 45.9734641],
              [-62.6735773, 45.9739601],
              [-62.6735994, 45.9740389],
              [-62.6737107, 45.9743159],
              [-62.6740029, 45.9750814],
              [-62.6743683, 45.9760136],
              [-62.6744191, 45.9761599],
              [-62.6746352, 45.9766981],
              [-62.6746764, 45.9768287],
              [-62.674756, 45.9770133],
              [-62.6748156, 45.977179],
              [-62.6749053, 45.9773984],
              [-62.6749434, 45.9775042],
              [-62.6750866, 45.9778352],
              [-62.6751151, 45.9779253],
              [-62.6751946, 45.9781212],
              [-62.6752487, 45.9782405],
              [-62.6752963, 45.9783846],
              [-62.6754127, 45.9786571],
              [-62.6754927, 45.9788768],
              [-62.6755691, 45.979048],
              [-62.6757286, 45.9794699],
              [-62.6759289, 45.9799631],
              [-62.6759543, 45.9800441],
              [-62.677127, 45.9830545],
              [-62.6776929, 45.9844888],
              [-62.6777343, 45.9845812],
              [-62.6777564, 45.98466],
              [-62.6777819, 45.9847172],
              [-62.6779502, 45.9851711],
              [-62.6781123, 45.9855899],
              [-62.678319, 45.98611],
              [-62.6788033, 45.9872683],
              [-62.6792793, 45.9885148],
              [-62.679465, 45.9889849],
              [-62.6797657, 45.9897689],
              [-62.6800232, 45.9904219],
              [-62.6804037, 45.9913604],
              [-62.6804844, 45.9915725],
              [-62.6808828, 45.9925286],
              [-62.6809227, 45.9926637],
              [-62.6811738, 45.9933189],
              [-62.6814156, 45.9939223],
              [-62.6816447, 45.9944763],
              [-62.6816637, 45.9945438],
              [-62.6821629, 45.9958295],
              [-62.682465, 45.9965905],
              [-62.682551, 45.9967932],
              [-62.6828371, 45.9975407],
              [-62.6831233, 45.998268],
              [-62.6837252, 45.9997761],
              [-62.6838098, 45.9999986],
              [-62.683858, 46.0001435],
              [-62.6843446, 46.0014022],
              [-62.6844019, 46.0015373],
              [-62.6844145, 46.0015913],
              [-62.6845575, 46.0019785],
              [-62.6846308, 46.0021362],
              [-62.6846628, 46.0021812],
              [-62.684666, 46.0021947],
              [-62.6846626, 46.0022154],
              [-62.6846878, 46.0023208],
              [-62.6848735, 46.0028093],
              [-62.6849797, 46.0030781],
              [-62.6850339, 46.0032191],
              [-62.6851203, 46.0034479],
              [-62.6851991, 46.00365],
              [-62.6852317, 46.0037338],
              [-62.6852533, 46.003791],
              [-62.685343, 46.0040198],
              [-62.6853977, 46.0041639],
              [-62.6854511, 46.0043057],
              [-62.6854727, 46.0043629],
              [-62.6855688, 46.0046074],
              [-62.6855904, 46.0046646],
              [-62.6856801, 46.0048934],
              [-62.6857882, 46.0051793],
              [-62.6858098, 46.0052365],
              [-62.686049, 46.0058539],
              [-62.6861227, 46.0060524],
              [-62.6862876, 46.0064663],
              [-62.6863315, 46.0065807],
              [-62.6864681, 46.0069273],
              [-62.6864823, 46.0069598],
              [-62.6865076, 46.0070476],
              [-62.6865681, 46.0072003],
              [-62.6865897, 46.0072575],
              [-62.6866654, 46.0074565],
              [-62.6866984, 46.0075434],
              [-62.6868596, 46.0079617],
              [-62.6869388, 46.0081711],
              [-62.6870596, 46.0084886],
              [-62.6870886, 46.0085651],
              [-62.6871677, 46.0087745],
              [-62.687308, 46.009137],
              [-62.6873872, 46.0093464],
              [-62.6874289, 46.0094567],
              [-62.687508, 46.0096661],
              [-62.6876928, 46.0101547],
              [-62.6879572, 46.0108446],
              [-62.6882362, 46.0116069],
              [-62.6883206, 46.011841],
              [-62.688549, 46.0124624],
              [-62.6886579, 46.012729],
              [-62.6890096, 46.0136728],
              [-62.6893341, 46.0145153],
              [-62.6894996, 46.0149296],
              [-62.6896016, 46.0151674],
              [-62.6898149, 46.0156902],
              [-62.6900781, 46.0162991],
              [-62.6901495, 46.0164541],
              [-62.690322, 46.0167757],
              [-62.6904708, 46.0170735],
              [-62.6905296, 46.0171793],
              [-62.6906317, 46.0173919],
              [-62.6907499, 46.0176226],
              [-62.690938, 46.0180324],
              [-62.6915461, 46.0195549],
              [-62.6915939, 46.019663],
              [-62.6916642, 46.0197982],
              [-62.6916704, 46.0198319],
              [-62.6918456, 46.0202548],
              [-62.6918806, 46.0203498],
              [-62.6919252, 46.0204489],
              [-62.691941, 46.0205074],
              [-62.6920586, 46.0208383],
              [-62.6922724, 46.0214151],
              [-62.6925112, 46.0220006],
              [-62.6925429, 46.0221064],
              [-62.6925557, 46.0221266],
              [-62.6925895, 46.0222113],
              [-62.6927708, 46.0226998],
              [-62.693055, 46.0234865],
              [-62.69323, 46.0239503],
              [-62.6935828, 46.0249521],
              [-62.6937644, 46.0253844],
              [-62.6939111, 46.0257154],
              [-62.6939811, 46.025891],
              [-62.6941469, 46.0262648],
              [-62.694301, 46.026667],
              [-62.6944492, 46.0270753],
              [-62.6946879, 46.0276878],
              [-62.6947422, 46.0278026],
              [-62.6948094, 46.0279062],
              [-62.6949408, 46.0280798],
              [-62.6950048, 46.0281924],
              [-62.6950303, 46.0282442],
              [-62.6951355, 46.0284986],
              [-62.6951735, 46.0286247],
              [-62.6951949, 46.0288407],
              [-62.6951944, 46.0289329],
              [-62.695184, 46.0290543],
              [-62.6951773, 46.0291105],
              [-62.6951541, 46.0292153],
              [-62.6956128, 46.0303723],
              [-62.696178, 46.0318392],
              [-62.6961282, 46.032081],
              [-62.6961577, 46.0321225],
              [-62.6962025, 46.0322013],
              [-62.6963142, 46.0324274],
              [-62.6964119, 46.0326288],
              [-62.6965376, 46.032913],
              [-62.6965406, 46.0329445],
              [-62.696582, 46.0330638],
              [-62.6972186, 46.0347388],
              [-62.697828, 46.0361273],
              [-62.6989033, 46.0382966],
              [-62.6996102, 46.040256],
              [-62.7049899, 46.0539],
              [-62.7052262, 46.0545104],
              [-62.7054826, 46.0551427],
              [-62.7057184, 46.0557595],
              [-62.7057177, 46.0557672],
              [-62.7073118, 46.0597217],
              [-62.7084073, 46.0632896],
              [-62.7084956, 46.0634628],
              [-62.7087989, 46.0637639],
              [-62.7133522, 46.0747475],
              [-62.7144412, 46.077266],
              [-62.7149748, 46.0787018],
              [-62.7153325, 46.0795169],
              [-62.7160605, 46.0812326],
              [-62.7169217, 46.0834479],
              [-62.7174705, 46.0848392],
              [-62.7174407, 46.0848454],
              [-62.717476, 46.0848527],
              [-62.717579, 46.0851004],
              [-62.717726, 46.0854336],
              [-62.7177962, 46.0856092],
              [-62.7181029, 46.0863027],
              [-62.7181669, 46.0864197],
              [-62.7181359, 46.0864261],
              [-62.7189589, 46.0885567],
              [-62.7199039, 46.0908756],
              [-62.7207525, 46.093081],
              [-62.7214159, 46.0945981],
              [-62.7216782, 46.0952718],
              [-62.7224114, 46.0970597],
              [-62.7226412, 46.097654],
              [-62.7233117, 46.0993245],
              [-62.7234842, 46.0997298],
              [-62.7237971, 46.1005133],
              [-62.724372, 46.1019226],
              [-62.7253299, 46.1043266],
              [-62.7257268, 46.1051376],
              [-62.7258673, 46.1054889],
              [-62.7260591, 46.1059301],
              [-62.7270744, 46.1085276],
              [-62.7271112, 46.1086026],
              [-62.7272314, 46.1088159],
              [-62.727241, 46.1088474],
              [-62.727257, 46.1088744],
              [-62.7273277, 46.108965],
              [-62.727334, 46.1089938],
              [-62.7273571, 46.1090393],
              [-62.7273884, 46.1091226],
              [-62.7274428, 46.1092262],
              [-62.7287309, 46.1124046],
              [-62.7288261, 46.1126338],
              [-62.7288709, 46.1127288],
              [-62.7289695, 46.11294],
              [-62.7329356, 46.1227421],
              [-62.7338082, 46.1248858],
              [-62.7346318, 46.126876],
              [-62.7348031, 46.1272988],
              [-62.7348951, 46.1275455],
              [-62.7354866, 46.1289787],
              [-62.7359972, 46.1302915],
              [-62.7360105, 46.1303451],
              [-62.7360521, 46.1304442],
              [-62.7361008, 46.1305387],
              [-62.7361257, 46.1306085],
              [-62.7368387, 46.1323555],
              [-62.7369609, 46.1326428],
              [-62.737264, 46.1333911],
              [-62.737477, 46.1339003],
              [-62.738639, 46.1367635],
              [-62.7409517, 46.1424757],
              [-62.747608, 46.1596958],
              [-62.7486423, 46.1629471],
              [-62.7527726, 46.1715243],
              [-62.7532109, 46.1726151],
              [-62.7538569, 46.1746804],
              [-62.7603933, 46.1906646],
              [-62.7616968, 46.1938606],
              [-62.7625071, 46.1959345],
              [-62.7676156, 46.2083879],
              [-62.7696791, 46.2134814],
              [-62.7696914, 46.2134792],
              [-62.7698318, 46.2138434],
              [-62.7701088, 46.2145317],
              [-62.7705411, 46.2155978],
              [-62.7707002, 46.2159755],
              [-62.771609, 46.2182197],
              [-62.7716405, 46.2182877],
              [-62.771941, 46.2190126],
              [-62.7760729, 46.229221],
              [-62.7790491, 46.2362949],
              [-62.7791499, 46.2365335],
              [-62.780585, 46.2399441],
              [-62.7846254, 46.2500558],
              [-62.7929195, 46.2704636],
              [-62.7931014, 46.270889],
              [-62.7941704, 46.2740913],
              [-62.7966615, 46.2735935],
              [-62.7970543, 46.2735128],
              [-62.7974113, 46.2734464],
              [-62.7990407, 46.2731254],
              [-62.7992062, 46.2730915],
              [-62.7993276, 46.273058],
              [-62.7994432, 46.2730312],
              [-62.7996963, 46.2729889],
              [-62.8002845, 46.2728748],
              [-62.8017508, 46.2725994],
              [-62.8033027, 46.2722908],
              [-62.8056786, 46.2782155],
              [-62.8069355, 46.2814517],
              [-62.8091875, 46.2869687],
              [-62.8135287, 46.2978288],
              [-62.8143722, 46.2999812],
              [-62.8164049, 46.3051728],
              [-62.8174248, 46.3085832],
              [-62.8197979, 46.3140843],
              [-62.8203293, 46.3151907],
              [-62.8213035, 46.3174246],
              [-62.8214002, 46.3176011],
              [-62.8227663, 46.3207025],
              [-62.8234391, 46.3224363],
              [-62.8235167, 46.3226669],
              [-62.8276761, 46.3333894],
              [-62.8332485, 46.3471368],
              [-62.8360757, 46.3542228],
              [-62.8438336, 46.3735819],
              [-62.8440374, 46.3741877],
              [-62.8442844, 46.3748943],
              [-62.8526905, 46.39589],
              [-62.8564653, 46.4053542],
              [-62.856473245114472, 46.405375244617325],
              [-62.856176, 46.4054755],
              [-62.8557624, 46.4054596],
              [-62.8548059, 46.4057546],
              [-62.8533853, 46.4062992],
              [-62.8516204, 46.4066305],
              [-62.8504448, 46.4067399],
              [-62.8483503, 46.4071892],
              [-62.8476262, 46.4074511],
              [-62.8473717, 46.4076588],
              [-62.847066, 46.4078571],
              [-62.8464413, 46.4079027],
              [-62.8455145, 46.4075173],
              [-62.8452695, 46.4074744],
              [-62.8448022, 46.4074887],
              [-62.8437576, 46.4075262],
              [-62.8435755, 46.4076802],
              [-62.8434308, 46.4083572],
              [-62.8434752, 46.4086692],
              [-62.8438481, 46.4088832],
              [-62.8440145, 46.4089876],
              [-62.8442744, 46.4089752],
              [-62.8446891, 46.4088797],
              [-62.8451405, 46.4090322],
              [-62.8453434, 46.4092014],
              [-62.8453882, 46.4096966],
              [-62.8451601, 46.409945],
              [-62.8443645, 46.410149],
              [-62.8439959, 46.4101502],
              [-62.8439245, 46.4100924],
              [-62.8431932, 46.4099955],
              [-62.842661, 46.4100358],
              [-62.8414847, 46.4104399],
              [-62.8403447, 46.410339],
              [-62.8402458, 46.4102605],
              [-62.8402868, 46.4101541],
              [-62.8409317, 46.4095873],
              [-62.8410937, 46.4093848],
              [-62.8411602, 46.4089526],
              [-62.8412413, 46.4088513],
              [-62.8414346, 46.4092712],
              [-62.8415608, 46.4092789],
              [-62.8419699, 46.4088964],
              [-62.8422352, 46.4085097],
              [-62.8422181, 46.4081268],
              [-62.8419124, 46.4077554],
              [-62.8416083, 46.4075474],
              [-62.8406572, 46.407468],
              [-62.8404294, 46.40733],
              [-62.8404751, 46.4070525],
              [-62.8406695, 46.4067912],
              [-62.8406463, 46.4065077],
              [-62.8402454, 46.4059981],
              [-62.8401937, 46.4058054],
              [-62.8402291, 46.4054121],
              [-62.8403612, 46.4052287],
              [-62.8403196, 46.4050602],
              [-62.8399313, 46.4046267],
              [-62.8399912, 46.404019],
              [-62.8398761, 46.403924],
              [-62.8397436, 46.4039241],
              [-62.8394104, 46.4041017],
              [-62.8393085, 46.4043576],
              [-62.838973, 46.4046666],
              [-62.8389424, 46.4049804],
              [-62.8394091, 46.4058304],
              [-62.8394036, 46.4062047],
              [-62.8391031, 46.4065067],
              [-62.8385877, 46.4068383],
              [-62.8385934, 46.4071253],
              [-62.8388325, 46.4073593],
              [-62.8388267, 46.4075503],
              [-62.8386072, 46.4077511],
              [-62.8383629, 46.4080746],
              [-62.8380311, 46.4083239],
              [-62.8369354, 46.4084434],
              [-62.8368357, 46.408568],
              [-62.8368435, 46.4087236],
              [-62.8364988, 46.4088052],
              [-62.8361763, 46.4088037],
              [-62.8354846, 46.4090983],
              [-62.8349699, 46.4091351],
              [-62.8339933, 46.4094732],
              [-62.8316779, 46.4095731],
              [-62.8307285, 46.4097486],
              [-62.8276795, 46.410544],
              [-62.8275684, 46.4105726],
              [-62.8264814, 46.4107361],
              [-62.8243045, 46.4113975],
              [-62.822638, 46.411807],
              [-62.8220894, 46.4118307],
              [-62.8203903, 46.4121157],
              [-62.8200941, 46.4121548],
              [-62.8192346, 46.4122731],
              [-62.8183849, 46.4123239],
              [-62.8175571, 46.4121084],
              [-62.8175022, 46.4121586],
              [-62.8174595, 46.4127629],
              [-62.8171751, 46.4130812],
              [-62.8165349, 46.41366],
              [-62.8160328, 46.4146173],
              [-62.8157033, 46.4149184],
              [-62.8153018, 46.4154563],
              [-62.8151169, 46.4163114],
              [-62.8151696, 46.4164842],
              [-62.8154525, 46.4167555],
              [-62.8157905, 46.4170681],
              [-62.8162598, 46.4174006],
              [-62.8171665, 46.4177379],
              [-62.8180003, 46.4178539],
              [-62.8181754, 46.4179108],
              [-62.8186509, 46.4182355],
              [-62.818955, 46.4184435],
              [-62.8196716, 46.4186876],
              [-62.8209643, 46.418837],
              [-62.8212483, 46.4189967],
              [-62.821499, 46.4194183],
              [-62.8215727, 46.4200977],
              [-62.8218686, 46.4206282],
              [-62.8217657, 46.4218402],
              [-62.8216134, 46.4219752],
              [-62.8214773, 46.422035],
              [-62.8203561, 46.4220938],
              [-62.8199442, 46.4224244],
              [-62.8199881, 46.4225531],
              [-62.8202734, 46.4226929],
              [-62.8209146, 46.4226639],
              [-62.8211749, 46.4228348],
              [-62.8211766, 46.4230899],
              [-62.8207088, 46.4236737],
              [-62.8206804, 46.4238562],
              [-62.8208093, 46.4239157],
              [-62.8215723, 46.4234958],
              [-62.821665, 46.4235822],
              [-62.8210947, 46.4241472],
              [-62.8209997, 46.4241922],
              [-62.8207968, 46.4246842],
              [-62.8210369, 46.4247151],
              [-62.8213253, 46.4245204],
              [-62.8217172, 46.4241414],
              [-62.8221349, 46.4235282],
              [-62.8230501, 46.4228817],
              [-62.8233849, 46.4227759],
              [-62.8238188, 46.4228404],
              [-62.8243444, 46.4231944],
              [-62.8245112, 46.4234821],
              [-62.8244462, 46.4241694],
              [-62.8245052, 46.4243345],
              [-62.8248529, 46.4243964],
              [-62.8248518, 46.4245079],
              [-62.8249195, 46.4246254],
              [-62.8253858, 46.4246311],
              [-62.8257815, 46.4242841],
              [-62.8259777, 46.4242779],
              [-62.8266963, 46.4248685],
              [-62.8268945, 46.4252089],
              [-62.8268377, 46.4255738],
              [-62.8284924, 46.4266857],
              [-62.8287926, 46.4267702],
              [-62.8298389, 46.426713],
              [-62.8305756, 46.4269138],
              [-62.831469, 46.4272864],
              [-62.8316493, 46.427447],
              [-62.8316696, 46.427587],
              [-62.8312687, 46.4278303],
              [-62.8310751, 46.4278884],
              [-62.8305644, 46.4282321],
              [-62.8300539, 46.4286674],
              [-62.8296404, 46.4288346],
              [-62.8290741, 46.4288619],
              [-62.8285388, 46.4287587],
              [-62.8277811, 46.4287127],
              [-62.8260478, 46.4284151],
              [-62.8248437, 46.4283202],
              [-62.8243834, 46.4282151],
              [-62.8242885, 46.4282602],
              [-62.8232921, 46.428255],
              [-62.8222334, 46.4284193],
              [-62.8215999, 46.4286039],
              [-62.8209281, 46.4290384],
              [-62.8203715, 46.4296596],
              [-62.8200674, 46.4302044],
              [-62.8200631, 46.4306505],
              [-62.8202748, 46.4308639],
              [-62.8205688, 46.4309561],
              [-62.8210913, 46.4308917],
              [-62.8229632, 46.4303369],
              [-62.8238492, 46.4300759],
              [-62.8242741, 46.4300047],
              [-62.8251781, 46.4300152],
              [-62.8257366, 46.4298323],
              [-62.8260992, 46.4298389],
              [-62.8263982, 46.4299432],
              [-62.8268678, 46.4297059],
              [-62.8270097, 46.4293635],
              [-62.8271545, 46.4292561],
              [-62.8274407, 46.4291928],
              [-62.8279065, 46.4295848],
              [-62.8283791, 46.4295827],
              [-62.8283949, 46.4299855],
              [-62.8286365, 46.4301798],
              [-62.829009, 46.430119],
              [-62.8292962, 46.4299441],
              [-62.8293205, 46.4295464],
              [-62.8296403, 46.4294043],
              [-62.8300053, 46.4293711],
              [-62.8301454, 46.429435],
              [-62.8302227, 46.4299631],
              [-62.8305635, 46.4303276],
              [-62.8310702, 46.4305216],
              [-62.8314539, 46.4304651],
              [-62.8322281, 46.4299578],
              [-62.8323339, 46.4297338],
              [-62.8323535, 46.4295073],
              [-62.8321164, 46.4291418],
              [-62.8319203, 46.4285784],
              [-62.8321408, 46.4281744],
              [-62.8325092, 46.42799],
              [-62.8333681, 46.4279831],
              [-62.8335109, 46.4280988],
              [-62.8335083, 46.4287083],
              [-62.8333596, 46.4293533],
              [-62.8332791, 46.4297294],
              [-62.8332116, 46.4303649],
              [-62.8333268, 46.4304599],
              [-62.8338455, 46.4303634],
              [-62.8338808, 46.4305397],
              [-62.833589, 46.4308858],
              [-62.8332956, 46.4317298],
              [-62.832874, 46.4328808],
              [-62.8305411, 46.4329643],
              [-62.8272864, 46.4329491],
              [-62.8240256, 46.4329416],
              [-62.8222051, 46.4329362],
              [-62.8189835, 46.4332284],
              [-62.8185259, 46.4332668],
              [-62.8180884, 46.4333536],
              [-62.8151019, 46.4336645],
              [-62.8135543, 46.433906],
              [-62.8103452, 46.434274],
              [-62.8099914, 46.4343115],
              [-62.8085326, 46.4346073],
              [-62.8067141, 46.4351315],
              [-62.8066053, 46.4351203],
              [-62.8061854, 46.4352953],
              [-62.8052755, 46.4355673],
              [-62.8042017, 46.4357867],
              [-62.8035403, 46.4358589],
              [-62.8023275, 46.4359029],
              [-62.8010884, 46.4359979],
              [-62.8002107, 46.4360278],
              [-62.7989142, 46.4362127],
              [-62.7979389, 46.4362357],
              [-62.794727, 46.4366429],
              [-62.7945645, 46.4366621],
              [-62.7929403, 46.4367416],
              [-62.7901935, 46.4371743],
              [-62.7886131, 46.4372909],
              [-62.7863539, 46.4377579],
              [-62.784656, 46.4380025],
              [-62.7836546, 46.4382597],
              [-62.7832533, 46.4383195],
              [-62.7822967, 46.438319],
              [-62.7801374, 46.438753],
              [-62.7793427, 46.4386343],
              [-62.7772432, 46.4392148],
              [-62.7765156, 46.4394048],
              [-62.7757366, 46.4396031],
              [-62.7751393, 46.4398147],
              [-62.7745006, 46.4399986],
              [-62.774014, 46.4400108],
              [-62.7737863, 46.4400665],
              [-62.7733627, 46.440218],
              [-62.7722831, 46.4403868],
              [-62.7707609, 46.4407317],
              [-62.7695509, 46.4410416],
              [-62.7684626, 46.4412376],
              [-62.7669759, 46.4413179],
              [-62.7661077, 46.4414091],
              [-62.7656031, 46.4418903],
              [-62.7642376, 46.4420687],
              [-62.7634922, 46.4420982],
              [-62.7633447, 46.4420619],
              [-62.7619227, 46.4420354],
              [-62.7612324, 46.4421064],
              [-62.7595842, 46.4421049],
              [-62.7582548, 46.4422564],
              [-62.7575008, 46.4424251],
              [-62.756294, 46.4424607],
              [-62.7548095, 46.4426952],
              [-62.7516068, 46.4431251],
              [-62.7515718, 46.443132],
              [-62.7508052, 46.4433162],
              [-62.7500001, 46.4433317],
              [-62.7483814, 46.443363],
              [-62.7475197, 46.4432256],
              [-62.7461077, 46.443223],
              [-62.745046, 46.4434262],
              [-62.743544, 46.443389],
              [-62.7425812, 46.4430095],
              [-62.7424462, 46.4426547],
              [-62.7420449, 46.4422939],
              [-62.7415182, 46.4419114],
              [-62.7412486, 46.4413841],
              [-62.7411044, 46.4409563],
              [-62.7410543, 46.4406494],
              [-62.740687, 46.4403431],
              [-62.7403073, 46.4400264],
              [-62.74001, 46.4398099],
              [-62.7396089, 46.4395179],
              [-62.7394846, 46.4394837],
              [-62.7382537, 46.4393062],
              [-62.7373417, 46.4389191],
              [-62.7365538, 46.438798],
              [-62.7341918, 46.4384217],
              [-62.7338942, 46.4382971],
              [-62.7333216, 46.4381484],
              [-62.7329852, 46.4379988],
              [-62.7323983, 46.4377219],
              [-62.7320395, 46.4373305],
              [-62.7315056, 46.4371786],
              [-62.731079, 46.436941],
              [-62.730692, 46.4368495],
              [-62.7304717, 46.4367112],
              [-62.7302993, 46.4365379],
              [-62.7302061, 46.4362166],
              [-62.7302457, 46.4360374],
              [-62.7287344, 46.4352894],
              [-62.7287626, 46.4351317],
              [-62.7291779, 46.4349778],
              [-62.7294208, 46.4349155],
              [-62.7295762, 46.4348181],
              [-62.7296514, 46.4346708],
              [-62.7279251, 46.4335389],
              [-62.7273187, 46.4333771],
              [-62.7270113, 46.4331367],
              [-62.7265951, 46.4326101],
              [-62.7264553, 46.4325238],
              [-62.7262014, 46.4323671],
              [-62.72524, 46.432079],
              [-62.7244838, 46.4315541],
              [-62.7224726, 46.4307402],
              [-62.7220475, 46.4306277],
              [-62.720836, 46.4306507],
              [-62.7201635, 46.4303514],
              [-62.7192147, 46.430231],
              [-62.7177094, 46.4301614],
              [-62.7175169, 46.4301077],
              [-62.7167765, 46.4305155],
              [-62.7160299, 46.4308395],
              [-62.7151957, 46.4312723],
              [-62.7144752, 46.4317285],
              [-62.7141738, 46.4318469],
              [-62.7141566, 46.4320453],
              [-62.7143186, 46.4323764],
              [-62.7142945, 46.4325057],
              [-62.7142456, 46.4325317],
              [-62.7141509, 46.4325821],
              [-62.7137401, 46.4319339],
              [-62.7132814, 46.4313364],
              [-62.7117204, 46.4314214],
              [-62.7121122, 46.4320939],
              [-62.7117877, 46.4321864],
              [-62.7113097, 46.4314182],
              [-62.711221, 46.4311884],
              [-62.7109598, 46.4310997],
              [-62.7096132, 46.4312539],
              [-62.7083316, 46.4312906],
              [-62.70739, 46.4314173],
              [-62.7069451, 46.4312563],
              [-62.7065702, 46.4309899],
              [-62.7063045, 46.4303682],
              [-62.7059685, 46.4300352],
              [-62.7053689, 46.4294074],
              [-62.7047579, 46.4290501],
              [-62.7040706, 46.4287143],
              [-62.7030333, 46.4282643],
              [-62.7007863, 46.4274507],
              [-62.7000101, 46.4273353],
              [-62.6982074, 46.4272324],
              [-62.6960936, 46.4270402],
              [-62.6952263, 46.4268185],
              [-62.694679, 46.4265467],
              [-62.6943608, 46.426027],
              [-62.6938199, 46.4257475],
              [-62.691864, 46.4252493],
              [-62.6899968, 46.4248972],
              [-62.6889257, 46.424709],
              [-62.6883923, 46.4244018],
              [-62.6878892, 46.4238923],
              [-62.6872021, 46.4235565],
              [-62.6865367, 46.4219862],
              [-62.6865434, 46.4216119],
              [-62.6867487, 46.4213752],
              [-62.6868878, 46.4211842],
              [-62.686743, 46.4210164],
              [-62.6862205, 46.4209884],
              [-62.685853, 46.4209692],
              [-62.6856033, 46.4208221],
              [-62.6855859, 46.4207339],
              [-62.6857286, 46.4205749],
              [-62.6859748, 46.4205984],
              [-62.6883958, 46.4207564],
              [-62.6890909, 46.420698],
              [-62.6894298, 46.4205331],
              [-62.6904765, 46.4202027],
              [-62.6911155, 46.4199394],
              [-62.6913958, 46.419701],
              [-62.69162, 46.4193493],
              [-62.6920716, 46.4190444],
              [-62.692443, 46.4189124],
              [-62.6930055, 46.4188539],
              [-62.6934466, 46.418983],
              [-62.6938404, 46.4188596],
              [-62.6942676, 46.4191157],
              [-62.6943802, 46.4190673],
              [-62.6943268, 46.418731],
              [-62.6940846, 46.4184647],
              [-62.6939346, 46.418468],
              [-62.6935435, 46.4183684],
              [-62.6933588, 46.4181954],
              [-62.6932155, 46.4178245],
              [-62.6934081, 46.4176949],
              [-62.6939006, 46.4176503],
              [-62.6941882, 46.4175674],
              [-62.6947843, 46.4176136],
              [-62.6968458, 46.418346],
              [-62.6969394, 46.4185043],
              [-62.6973504, 46.4187439],
              [-62.6976041, 46.4187397],
              [-62.6977856, 46.4185142],
              [-62.6977882, 46.4183828],
              [-62.6979285, 46.4180803],
              [-62.6981812, 46.4179128],
              [-62.6989101, 46.4176841],
              [-62.6994401, 46.4176843],
              [-62.7000197, 46.4177754],
              [-62.7004537, 46.4177472],
              [-62.7007148, 46.41761],
              [-62.7017114, 46.4175412],
              [-62.702634, 46.41725],
              [-62.7027538, 46.4171465],
              [-62.7025902, 46.4170937],
              [-62.7017858, 46.4171872],
              [-62.7003661, 46.4171673],
              [-62.6997784, 46.4169821],
              [-62.69926, 46.4166196],
              [-62.6992026, 46.4165336],
              [-62.6989808, 46.4160682],
              [-62.6991182, 46.4158707],
              [-62.6992858, 46.4158156],
              [-62.6994262, 46.4156812],
              [-62.6994549, 46.4154697],
              [-62.6992977, 46.4152258],
              [-62.6993254, 46.4149717],
              [-62.699522, 46.4146909],
              [-62.6997689, 46.4139813],
              [-62.6999241, 46.4138068],
              [-62.7000156, 46.4135083],
              [-62.6999876, 46.4133108],
              [-62.6999463, 46.4128274],
              [-62.6997133, 46.412147],
              [-62.699676, 46.4120104],
              [-62.6994451, 46.4116567],
              [-62.6990222, 46.4109745],
              [-62.6984227, 46.4102192],
              [-62.6986151, 46.4099339],
              [-62.699209, 46.4095768],
              [-62.6997514, 46.4092343],
              [-62.700391, 46.4089172],
              [-62.7006943, 46.4085118],
              [-62.7009172, 46.4080883],
              [-62.7009164, 46.4076501],
              [-62.700738, 46.4072861],
              [-62.7004436, 46.4068468],
              [-62.7005063, 46.4065858],
              [-62.7003465, 46.4064734],
              [-62.7000704, 46.4063773],
              [-62.6996609, 46.4060261],
              [-62.6993287, 46.4059084],
              [-62.6986557, 46.4054456],
              [-62.69826, 46.405059],
              [-62.6978133, 46.4044797],
              [-62.6976501, 46.4039687],
              [-62.6979689, 46.4035976],
              [-62.6981827, 46.4025748],
              [-62.6982745, 46.4021314],
              [-62.6981387, 46.4017329],
              [-62.6979201, 46.4012099],
              [-62.697495, 46.4008128],
              [-62.6974378, 46.4005362],
              [-62.6982203, 46.3990145],
              [-62.6983116, 46.3989376],
              [-62.6986191, 46.3987572],
              [-62.6986108, 46.398401],
              [-62.6986135, 46.398178],
              [-62.6984775, 46.3980543],
              [-62.6973588, 46.3973588],
              [-62.6961264, 46.3968231],
              [-62.695628, 46.3966923],
              [-62.695608, 46.3965714],
              [-62.6957658, 46.3963378],
              [-62.6958221, 46.3962166],
              [-62.6965612, 46.3956054],
              [-62.6977486, 46.3950442],
              [-62.698229, 46.3944654],
              [-62.6982866, 46.3942839],
              [-62.6982464, 46.3933824],
              [-62.6980557, 46.3929423],
              [-62.6976637, 46.3926793],
              [-62.6970468, 46.3925131],
              [-62.6962101, 46.3920938],
              [-62.6954783, 46.3921025],
              [-62.6950827, 46.3918076],
              [-62.6951066, 46.3916131],
              [-62.6960856, 46.3910105],
              [-62.6967244, 46.3901063],
              [-62.6968967, 46.3892778],
              [-62.6971488, 46.3882369],
              [-62.6974083, 46.3879759],
              [-62.6976276, 46.3874806],
              [-62.6979227, 46.3870222],
              [-62.6977635, 46.3860251],
              [-62.6976544, 46.3852198],
              [-62.6974688, 46.3847973],
              [-62.6972686, 46.3846411],
              [-62.6967076, 46.3842579],
              [-62.6964446, 46.3838888],
              [-62.6964302, 46.383632],
              [-62.6961576, 46.3832697],
              [-62.695125, 46.3827592],
              [-62.6943716, 46.3824937],
              [-62.6938617, 46.3822468],
              [-62.6932274, 46.3820144],
              [-62.6927573, 46.3818127],
              [-62.6923444, 46.3815395],
              [-62.692104, 46.3812408],
              [-62.6919896, 46.3803941],
              [-62.6918928, 46.3796684],
              [-62.69173, 46.3793637],
              [-62.6920688, 46.3787125],
              [-62.6923337, 46.3783813],
              [-62.6926261, 46.3781571],
              [-62.6927515, 46.3780441],
              [-62.692822, 46.3780195],
              [-62.6930406, 46.3779343],
              [-62.6930381, 46.3778824],
              [-62.6934345, 46.3774444],
              [-62.6937545, 46.3772113],
              [-62.6937835, 46.3769373],
              [-62.6940722, 46.376743],
              [-62.694795, 46.3765563],
              [-62.6955499, 46.37663],
              [-62.6961953, 46.3768947],
              [-62.6967437, 46.3771638],
              [-62.6973979, 46.3774017],
              [-62.6979453, 46.377435],
              [-62.6983785, 46.3772613],
              [-62.6987572, 46.3769184],
              [-62.6993223, 46.3762703],
              [-62.6994442, 46.3757772],
              [-62.6995936, 46.3750154],
              [-62.6991526, 46.3749863],
              [-62.6989494, 46.3753808],
              [-62.6986252, 46.3763706],
              [-62.6984346, 46.3767314],
              [-62.6982872, 46.376972],
              [-62.6979813, 46.3771459],
              [-62.6976045, 46.3771805],
              [-62.6972342, 46.3771095],
              [-62.6969023, 46.3769746],
              [-62.6964998, 46.3767238],
              [-62.6960315, 46.3763692],
              [-62.6954325, 46.3762079],
              [-62.6948619, 46.3761402],
              [-62.6940391, 46.3764202],
              [-62.6933278, 46.3768636],
              [-62.692938, 46.3770189],
              [-62.6925431, 46.3772538],
              [-62.6918656, 46.3778584],
              [-62.6914567, 46.3783119],
              [-62.6902954, 46.3792801],
              [-62.6900572, 46.379942],
              [-62.6900033, 46.3811433],
              [-62.6899395, 46.3812409],
              [-62.689054, 46.3812257],
              [-62.6888866, 46.3813241],
              [-62.6888914, 46.3814279],
              [-62.6897187, 46.381991],
              [-62.6897845, 46.3823117],
              [-62.6899055, 46.3823991],
              [-62.6902427, 46.3824373],
              [-62.6903886, 46.3825852],
              [-62.6904284, 46.3827736],
              [-62.6906855, 46.3828931],
              [-62.6913235, 46.3829963],
              [-62.6923098, 46.3833253],
              [-62.6925045, 46.3834309],
              [-62.6930827, 46.3834805],
              [-62.6941328, 46.3837118],
              [-62.694461, 46.3838892],
              [-62.6946829, 46.3842904],
              [-62.6949486, 46.3845455],
              [-62.695282, 46.3846433],
              [-62.6959531, 46.3853092],
              [-62.6964427, 46.3864754],
              [-62.6964996, 46.3869353],
              [-62.6964368, 46.3872879],
              [-62.6962265, 46.3876957],
              [-62.6958868, 46.3887768],
              [-62.6957954, 46.3889453],
              [-62.6956622, 46.3896782],
              [-62.6954832, 46.3900472],
              [-62.6948005, 46.3906397],
              [-62.6946553, 46.3908384],
              [-62.6938527, 46.3914154],
              [-62.6938517, 46.3922399],
              [-62.6940288, 46.3925322],
              [-62.6947055, 46.3928437],
              [-62.6958744, 46.3931106],
              [-62.6962103, 46.3932603],
              [-62.6964159, 46.3937366],
              [-62.69642, 46.3944817],
              [-62.6962661, 46.394728],
              [-62.694847, 46.3957601],
              [-62.694358, 46.3962949],
              [-62.6943076, 46.3966319],
              [-62.6943346, 46.3970192],
              [-62.6947702, 46.397411],
              [-62.6950393, 46.3977975],
              [-62.6960223, 46.3981139],
              [-62.6964428, 46.3982101],
              [-62.6966589, 46.3982888],
              [-62.6968753, 46.3983895],
              [-62.696914, 46.3985397],
              [-62.6965587, 46.3989756],
              [-62.6963547, 46.399284],
              [-62.6964918, 46.3995711],
              [-62.6963442, 46.3999155],
              [-62.6962183, 46.4003714],
              [-62.6962364, 46.4006626],
              [-62.6963058, 46.4012257],
              [-62.6965309, 46.4015848],
              [-62.6966631, 46.4017733],
              [-62.6968128, 46.4019353],
              [-62.6965045, 46.4027027],
              [-62.6962222, 46.4037856],
              [-62.6962974, 46.4046803],
              [-62.6965918, 46.4052586],
              [-62.6971658, 46.4057785],
              [-62.698256, 46.4064732],
              [-62.6987355, 46.4067189],
              [-62.6990189, 46.4069706],
              [-62.6990832, 46.4076777],
              [-62.6989267, 46.4079637],
              [-62.6982499, 46.4086402],
              [-62.6972931, 46.4093719],
              [-62.6970703, 46.4097037],
              [-62.6968092, 46.4103753],
              [-62.6969737, 46.4110362],
              [-62.6975086, 46.4119328],
              [-62.6978421, 46.412656],
              [-62.6979879, 46.4128273],
              [-62.6979502, 46.4130572],
              [-62.6975183, 46.413777],
              [-62.6975828, 46.4142093],
              [-62.6978707, 46.4149312],
              [-62.6978404, 46.4152251],
              [-62.6979983, 46.415516],
              [-62.6984543, 46.4157083],
              [-62.6984152, 46.4159609],
              [-62.6980719, 46.4161086],
              [-62.6980093, 46.4161864],
              [-62.6978468, 46.4162053],
              [-62.6972606, 46.4162751],
              [-62.6965392, 46.4164761],
              [-62.6960955, 46.4164784],
              [-62.695667, 46.4162422],
              [-62.6955633, 46.416243],
              [-62.6944656, 46.416564],
              [-62.6934144, 46.4166075],
              [-62.6926231, 46.4167307],
              [-62.6922779, 46.4169033],
              [-62.6921789, 46.4170995],
              [-62.6915975, 46.4172729],
              [-62.6913832, 46.4177405],
              [-62.69082, 46.4183487],
              [-62.6905773, 46.4185404],
              [-62.6891588, 46.4193694],
              [-62.6883733, 46.4197597],
              [-62.6880621, 46.4197621],
              [-62.6876237, 46.4195016],
              [-62.6871915, 46.419325],
              [-62.6865667, 46.4191863],
              [-62.6864118, 46.4190859],
              [-62.6864033, 46.4189502],
              [-62.6864184, 46.4188032],
              [-62.6860151, 46.4185359],
              [-62.6859591, 46.418331],
              [-62.6860933, 46.4180363],
              [-62.6853954, 46.4174212],
              [-62.6851437, 46.4168557],
              [-62.6848603, 46.416604],
              [-62.684563, 46.4164793],
              [-62.6842319, 46.4164333],
              [-62.6841831, 46.4164756],
              [-62.6842441, 46.416601],
              [-62.6849057, 46.4172428],
              [-62.6849401, 46.4176941],
              [-62.6852321, 46.4179899],
              [-62.6853878, 46.4184369],
              [-62.6852968, 46.4191353],
              [-62.6855903, 46.4193196],
              [-62.686089, 46.4193589],
              [-62.6862075, 46.419486],
              [-62.6860061, 46.4196631],
              [-62.6855807, 46.419917],
              [-62.6854995, 46.4199264],
              [-62.6851254, 46.4201898],
              [-62.685166, 46.4206333],
              [-62.6848241, 46.4211129],
              [-62.6846449, 46.4214819],
              [-62.6846359, 46.421621],
              [-62.6844881, 46.4218595],
              [-62.6841428, 46.4220322],
              [-62.6835965, 46.422107],
              [-62.6829103, 46.4220262],
              [-62.6823655, 46.4218979],
              [-62.6821781, 46.4218562],
              [-62.6816368, 46.4218515],
              [-62.6813953, 46.4220234],
              [-62.6814012, 46.4222905],
              [-62.6815922, 46.4224558],
              [-62.6820095, 46.4225961],
              [-62.6831558, 46.4225995],
              [-62.6834206, 46.4226913],
              [-62.6840114, 46.4230919],
              [-62.6840934, 46.4234291],
              [-62.6839493, 46.4236996],
              [-62.6836552, 46.4238818],
              [-62.6833602, 46.4239006],
              [-62.6830087, 46.424081],
              [-62.6826749, 46.4240748],
              [-62.6824248, 46.4241109],
              [-62.6820586, 46.4240717],
              [-62.6813611, 46.4240782],
              [-62.6809513, 46.4239102],
              [-62.6806387, 46.4239324],
              [-62.6803463, 46.4239115],
              [-62.6795144, 46.4243042],
              [-62.679393, 46.4244001],
              [-62.6787612, 46.4247273],
              [-62.6781231, 46.4250622],
              [-62.6774386, 46.4254913],
              [-62.6763588, 46.4261553],
              [-62.6755005, 46.426599],
              [-62.674914, 46.4266686],
              [-62.6738342, 46.4272409],
              [-62.6729749, 46.4275211],
              [-62.6723783, 46.4276581],
              [-62.6710531, 46.4276571],
              [-62.6695996, 46.427413],
              [-62.6688824, 46.4271876],
              [-62.6682881, 46.4267549],
              [-62.6672399, 46.4256786],
              [-62.6667405, 46.4252926],
              [-62.6656551, 46.4247929],
              [-62.664162, 46.4243602],
              [-62.6633851, 46.4239896],
              [-62.6619341, 46.4231757],
              [-62.6616529, 46.423159],
              [-62.6610657, 46.4229734],
              [-62.660459, 46.4225562],
              [-62.6599, 46.4220249],
              [-62.6593139, 46.4212896],
              [-62.6580549, 46.4201828],
              [-62.6561574, 46.418351],
              [-62.65613, 46.4183302],
              [-62.6556848, 46.4178223],
              [-62.6542597, 46.4167024],
              [-62.6537502, 46.4164754],
              [-62.6532299, 46.4161823],
              [-62.652742, 46.4159541],
              [-62.6520204, 46.4158495],
              [-62.6513359, 46.4156898],
              [-62.6513788, 46.4155505],
              [-62.6524415, 46.4156386],
              [-62.6527391, 46.4152806],
              [-62.6529798, 46.4149454],
              [-62.6533791, 46.4146511],
              [-62.6535385, 46.4143253],
              [-62.6536425, 46.4136114],
              [-62.653944, 46.4128718],
              [-62.654311, 46.4124529],
              [-62.6549907, 46.4119202],
              [-62.6555324, 46.4116502],
              [-62.6556816, 46.4113919],
              [-62.6555263, 46.4109449],
              [-62.6555208, 46.4105861],
              [-62.6557277, 46.410238],
              [-62.6557093, 46.410078],
              [-62.6553436, 46.4098555],
              [-62.6553643, 46.4095374],
              [-62.6558039, 46.409065],
              [-62.6562867, 46.4084393],
              [-62.6568893, 46.4081581],
              [-62.6569239, 46.407529],
              [-62.6572239, 46.4069537],
              [-62.6571864, 46.4068361],
              [-62.6569411, 46.4069121],
              [-62.6566587, 46.4071633],
              [-62.6565841, 46.4076032],
              [-62.6562412, 46.4078277],
              [-62.6560664, 46.4078697],
              [-62.6558762, 46.4081183],
              [-62.6555446, 46.4083344],
              [-62.6552246, 46.4087602],
              [-62.6549343, 46.4089743],
              [-62.6546642, 46.4090536],
              [-62.6545452, 46.4092012],
              [-62.6543815, 46.4097699],
              [-62.65454, 46.4099023],
              [-62.6546783, 46.4100779],
              [-62.6546802, 46.4104046],
              [-62.6544491, 46.4109473],
              [-62.6542975, 46.4111537],
              [-62.653497, 46.4120372],
              [-62.653033, 46.4127957],
              [-62.6522106, 46.4146387],
              [-62.6520226, 46.4148719],
              [-62.6518938, 46.4151462],
              [-62.6512125, 46.4153943],
              [-62.6512313, 46.4155364],
              [-62.6511569, 46.4156589],
              [-62.6505707, 46.4158506],
              [-62.6498368, 46.4159752],
              [-62.6495526, 46.4161815],
              [-62.6494219, 46.4165081],
              [-62.6491062, 46.4168449],
              [-62.6490258, 46.4170177],
              [-62.6496056, 46.4176692],
              [-62.649626, 46.4180643],
              [-62.6493978, 46.4183839],
              [-62.648572, 46.4187686],
              [-62.6479137, 46.4191465],
              [-62.647019, 46.4201466],
              [-62.6452062, 46.4219747],
              [-62.6433367, 46.4228128],
              [-62.6424425, 46.4230081],
              [-62.6416511, 46.4230392],
              [-62.6404925, 46.4229592],
              [-62.6401711, 46.4230288],
              [-62.6394061, 46.4235389],
              [-62.637924, 46.4248831],
              [-62.637417, 46.4251462],
              [-62.6357994, 46.4251235],
              [-62.6354261, 46.4249286],
              [-62.6353235, 46.4244711],
              [-62.6349814, 46.423799],
              [-62.6347945, 46.4235741],
              [-62.6343106, 46.4231326],
              [-62.6340659, 46.4229975],
              [-62.6333234, 46.4229862],
              [-62.6330796, 46.4230144],
              [-62.6314895, 46.4230125],
              [-62.6306989, 46.4227686],
              [-62.6291321, 46.4225003],
              [-62.6286901, 46.4222992],
              [-62.6284585, 46.4219653],
              [-62.6284555, 46.4216584],
              [-62.6283467, 46.4212087],
              [-62.6277347, 46.4200859],
              [-62.6275912, 46.4195515],
              [-62.6277139, 46.4189975],
              [-62.6278063, 46.4185542],
              [-62.6277634, 46.4182421],
              [-62.627582, 46.4178459],
              [-62.6269876, 46.417158],
              [-62.6266044, 46.4166999],
              [-62.6264311, 46.416576],
              [-62.6261086, 46.4164533],
              [-62.6257186, 46.416393],
              [-62.6256162, 46.4163333],
              [-62.6256028, 46.4162187],
              [-62.6257742, 46.4161597],
              [-62.6259057, 46.4160881],
              [-62.6262372, 46.4159511],
              [-62.6266409, 46.4155056],
              [-62.626677, 46.4150608],
              [-62.6265705, 46.4149924],
              [-62.6264317, 46.4149388],
              [-62.6263541, 46.4147918],
              [-62.6264617, 46.4145514],
              [-62.6264416, 46.414328],
              [-62.6273994, 46.4136887],
              [-62.6277284, 46.4135914],
              [-62.62793, 46.4134145],
              [-62.6279266, 46.4132909],
              [-62.6276959, 46.4131203],
              [-62.6277857, 46.4127167],
              [-62.6276032, 46.4122488],
              [-62.6276316, 46.4114648],
              [-62.6275221, 46.4112901],
              [-62.6267959, 46.410857],
              [-62.6267423, 46.4107955],
              [-62.6267511, 46.4103097],
              [-62.6263388, 46.4098346],
              [-62.6264897, 46.4094649],
              [-62.6269056, 46.4090954],
              [-62.6273307, 46.4090251],
              [-62.6277484, 46.4088908],
              [-62.6280376, 46.4086967],
              [-62.6280559, 46.4084184],
              [-62.6279395, 46.4079964],
              [-62.627655, 46.407856],
              [-62.6278133, 46.4075502],
              [-62.6277975, 46.4073505],
              [-62.6279443, 46.407132],
              [-62.6282823, 46.4068956],
              [-62.628849, 46.4066863],
              [-62.6287303, 46.4066507],
              [-62.6280793, 46.4066542],
              [-62.6276127, 46.4063925],
              [-62.6274551, 46.4059852],
              [-62.6266848, 46.4052598],
              [-62.6265121, 46.4049078],
              [-62.6263637, 46.4047996],
              [-62.6261301, 46.4047604],
              [-62.6258281, 46.4046234],
              [-62.6254558, 46.4045918],
              [-62.6252962, 46.4044792],
              [-62.625, 46.4040775],
              [-62.624617, 46.4038603],
              [-62.6236957, 46.4035047],
              [-62.6234131, 46.4035994],
              [-62.6230895, 46.4036171],
              [-62.622819, 46.4033497],
              [-62.6226361, 46.403065],
              [-62.6218754, 46.4029854],
              [-62.6216236, 46.4027862],
              [-62.6213287, 46.4023666],
              [-62.6210267, 46.4022296],
              [-62.6206108, 46.4021608],
              [-62.6204293, 46.4018563],
              [-62.6204005, 46.4014171],
              [-62.6204065, 46.4006245],
              [-62.6201887, 46.3999085],
              [-62.6199387, 46.3995062],
              [-62.619416, 46.3992229],
              [-62.6186332, 46.3990429],
              [-62.6153775, 46.3989882],
              [-62.6145205, 46.3989731],
              [-62.6142284, 46.3988604],
              [-62.613752, 46.3985744],
              [-62.6134423, 46.3981185],
              [-62.6127377, 46.3977138],
              [-62.6126565, 46.397285],
              [-62.6128193, 46.3967363],
              [-62.613498, 46.3957857],
              [-62.6135782, 46.3957045],
              [-62.6137418, 46.3953193],
              [-62.6141709, 46.3950977],
              [-62.6146774, 46.3949265],
              [-62.6157034, 46.3943851],
              [-62.6163292, 46.3935363],
              [-62.6166979, 46.3922213],
              [-62.6166548, 46.3920009],
              [-62.6165169, 46.3917336],
              [-62.6164654, 46.3918156],
              [-62.6164414, 46.3923567],
              [-62.6161893, 46.3931256],
              [-62.6156063, 46.3938482],
              [-62.6152894, 46.3941131],
              [-62.6143737, 46.3945544],
              [-62.6131573, 46.3952769],
              [-62.6125536, 46.3957878],
              [-62.6122811, 46.3962533],
              [-62.6114414, 46.3971112],
              [-62.6114254, 46.3974414],
              [-62.61152, 46.3975798],
              [-62.6115816, 46.3978686],
              [-62.6119576, 46.3982788],
              [-62.6124988, 46.3986306],
              [-62.6131801, 46.3993014],
              [-62.6136532, 46.3994637],
              [-62.6140661, 46.399664],
              [-62.6149443, 46.3997076],
              [-62.6158447, 46.3998515],
              [-62.6169021, 46.3997095],
              [-62.6174368, 46.3997223],
              [-62.6182222, 46.3998626],
              [-62.6187149, 46.4001649],
              [-62.618752, 46.4003016],
              [-62.6187548, 46.4006084],
              [-62.6185827, 46.4009496],
              [-62.6186697, 46.4011157],
              [-62.6186801, 46.4013949],
              [-62.6183653, 46.4018033],
              [-62.6183619, 46.4021179],
              [-62.6186592, 46.4025894],
              [-62.6193645, 46.403249],
              [-62.6200609, 46.403518],
              [-62.6210148, 46.4042531],
              [-62.6212533, 46.4043044],
              [-62.6215851, 46.4045141],
              [-62.6221878, 46.4048079],
              [-62.6238127, 46.4050581],
              [-62.6242671, 46.4052437],
              [-62.625, 46.4056859],
              [-62.6251589, 46.4057817],
              [-62.6255106, 46.4060397],
              [-62.625706, 46.4063088],
              [-62.6257472, 46.4072623],
              [-62.6255197, 46.4077452],
              [-62.6256135, 46.4081585],
              [-62.6252342, 46.4084095],
              [-62.6250814, 46.4089824],
              [-62.625, 46.4090673],
              [-62.6248961, 46.4091758],
              [-62.6245014, 46.410012],
              [-62.624492, 46.4106811],
              [-62.625, 46.4113046],
              [-62.625025, 46.4113353],
              [-62.6257394, 46.4119473],
              [-62.6260778, 46.4120576],
              [-62.6263235, 46.4122645],
              [-62.6262166, 46.4124882],
              [-62.626156, 46.4127094],
              [-62.6263878, 46.4129518],
              [-62.6263795, 46.4132543],
              [-62.6259388, 46.4135632],
              [-62.6255336, 46.4141202],
              [-62.6254717, 46.4143613],
              [-62.6257624, 46.4145755],
              [-62.6257882, 46.4146302],
              [-62.6258045, 46.4146822],
              [-62.6258558, 46.4147504],
              [-62.6258104, 46.4148713],
              [-62.6257569, 46.414995],
              [-62.6256332, 46.4151854],
              [-62.6253406, 46.4157861],
              [-62.6251381, 46.4159951],
              [-62.624943, 46.4160589],
              [-62.6247864, 46.4161371],
              [-62.6248221, 46.4162372],
              [-62.625, 46.41623],
              [-62.6252156, 46.4161898],
              [-62.6253641, 46.4162002],
              [-62.6253726, 46.4163201],
              [-62.6253609, 46.4164894],
              [-62.6251794, 46.4165903],
              [-62.6249303, 46.4163891],
              [-62.6239829, 46.4166504],
              [-62.6236563, 46.4168624],
              [-62.6235443, 46.4171336],
              [-62.6232093, 46.4175852],
              [-62.6229263, 46.4177716],
              [-62.622785, 46.4178189],
              [-62.6224437, 46.4178401],
              [-62.6213874, 46.4178706],
              [-62.6203691, 46.4182012],
              [-62.6197989, 46.4182869],
              [-62.6189088, 46.4183306],
              [-62.6183046, 46.4184949],
              [-62.6172258, 46.418955],
              [-62.6168155, 46.4190616],
              [-62.615823, 46.4190861],
              [-62.6152941, 46.4191571],
              [-62.6142328, 46.4191755],
              [-62.6126244, 46.4191049],
              [-62.6117315, 46.4188417],
              [-62.6109666, 46.4188216],
              [-62.6105764, 46.418885],
              [-62.6101479, 46.4188316],
              [-62.6086354, 46.4184412],
              [-62.6081282, 46.4183575],
              [-62.6063457, 46.4183929],
              [-62.6054609, 46.4183569],
              [-62.6049953, 46.4181669],
              [-62.6049017, 46.4181002],
              [-62.6045521, 46.4179856],
              [-62.6037535, 46.4179524],
              [-62.603244, 46.4178167],
              [-62.6024666, 46.4178121],
              [-62.6013887, 46.4176605],
              [-62.5999249, 46.4173399],
              [-62.5992094, 46.4170635],
              [-62.5988694, 46.4170647],
              [-62.5986199, 46.4169174],
              [-62.5979194, 46.4167079],
              [-62.5973934, 46.4166474],
              [-62.5945262, 46.4155804],
              [-62.5932379, 46.4151132],
              [-62.5924373, 46.4148537],
              [-62.5921359, 46.4145086],
              [-62.5915759, 46.4144244],
              [-62.591309, 46.4144512],
              [-62.5910971, 46.4143789],
              [-62.5900535, 46.4144025],
              [-62.5892604, 46.414436],
              [-62.5883557, 46.4144508],
              [-62.5875912, 46.4145],
              [-62.5869125, 46.4145811],
              [-62.5866567, 46.4146243],
              [-62.5863839, 46.4146091],
              [-62.5847571, 46.4147777],
              [-62.5843107, 46.4148388],
              [-62.5840306, 46.4149168],
              [-62.5835205, 46.4149108],
              [-62.5817366, 46.4151084],
              [-62.5810066, 46.4153851],
              [-62.581027, 46.415429],
              [-62.5810686, 46.4155136],
              [-62.5811876, 46.4155433],
              [-62.581705, 46.4167705],
              [-62.584026, 46.4188967],
              [-62.5847653, 46.4192507],
              [-62.5854393, 46.4195112],
              [-62.5864033, 46.4199176],
              [-62.5868265, 46.4200261],
              [-62.5870683, 46.4202936],
              [-62.5874883, 46.4204802],
              [-62.5877356, 46.4205659],
              [-62.5881596, 46.420797],
              [-62.5885835, 46.4210933],
              [-62.5899528, 46.4216912],
              [-62.5904581, 46.4220638],
              [-62.5910773, 46.4222999],
              [-62.5919993, 46.4226681],
              [-62.5927372, 46.4232692],
              [-62.5931066, 46.4234323],
              [-62.5938663, 46.4235322],
              [-62.5945715, 46.4238454],
              [-62.5967804, 46.4245967],
              [-62.5973594, 46.424902],
              [-62.5984186, 46.4255524],
              [-62.5994336, 46.4259478],
              [-62.6011545, 46.4267911],
              [-62.6022019, 46.4273079],
              [-62.6023172, 46.4273458],
              [-62.6031446, 46.4278486],
              [-62.6035832, 46.4280825],
              [-62.6038269, 46.4281746],
              [-62.6045126, 46.428466],
              [-62.6048116, 46.428675],
              [-62.6054969, 46.428944],
              [-62.605814, 46.4291366],
              [-62.6059851, 46.4292407],
              [-62.6061447, 46.4293101],
              [-62.6073946, 46.4298046],
              [-62.6080602, 46.4301253],
              [-62.6100038, 46.4307125],
              [-62.6115005, 46.4310864],
              [-62.6129611, 46.4313955],
              [-62.6147621, 46.4316116],
              [-62.6166688, 46.4316238],
              [-62.6180414, 46.4316948],
              [-62.6204722, 46.4320621],
              [-62.6222063, 46.4320688],
              [-62.6227474, 46.4321654],
              [-62.6232033, 46.4323312],
              [-62.6238548, 46.4323277],
              [-62.6248052, 46.432246],
              [-62.625, 46.4322462],
              [-62.6263956, 46.432248],
              [-62.6271592, 46.4323796],
              [-62.6281993, 46.4324241],
              [-62.629356, 46.4323608],
              [-62.6315962, 46.4320325],
              [-62.633168, 46.4319661],
              [-62.6352708, 46.4320637],
              [-62.636733, 46.4318225],
              [-62.6374443, 46.4318727],
              [-62.638564, 46.4321108],
              [-62.6393704, 46.4321161],
              [-62.6404622, 46.4319868],
              [-62.6415538, 46.4319491],
              [-62.6418837, 46.4320152],
              [-62.6432603, 46.4320263],
              [-62.6445047, 46.4318539],
              [-62.6457252, 46.4317843],
              [-62.6458601, 46.4318363],
              [-62.647863, 46.4318748],
              [-62.6481942, 46.431921],
              [-62.6503331, 46.4320831],
              [-62.650918, 46.4322169],
              [-62.6516418, 46.4322513],
              [-62.651817, 46.4322169],
              [-62.6531261, 46.4322018],
              [-62.654211, 46.432355],
              [-62.655253, 46.4327261],
              [-62.655943, 46.4328391],
              [-62.656274, 46.4329769],
              [-62.6569347, 46.433364],
              [-62.6577948, 46.4340521],
              [-62.6584822, 46.4348264],
              [-62.6586466, 46.4351343],
              [-62.6589412, 46.4353905],
              [-62.6596546, 46.4356756],
              [-62.6612382, 46.436673],
              [-62.6624111, 46.4373388],
              [-62.6630326, 46.4375],
              [-62.663301, 46.4375696],
              [-62.6656601, 46.4378976],
              [-62.6667662, 46.4381709],
              [-62.6679508, 46.4386577],
              [-62.6694907, 46.4390878],
              [-62.670398, 46.4394067],
              [-62.6704605, 46.4394206],
              [-62.6734014, 46.4403799],
              [-62.6743335, 46.440851],
              [-62.6752116, 46.4415355],
              [-62.6761922, 46.4422391],
              [-62.6767845, 46.4425283],
              [-62.6779497, 46.4427833],
              [-62.6791238, 46.4428991],
              [-62.6806246, 46.4428656],
              [-62.6816277, 46.4427729],
              [-62.6832476, 46.4425],
              [-62.6843485, 46.442231],
              [-62.6857208, 46.4419544],
              [-62.6860261, 46.4418681],
              [-62.6890323, 46.4410081],
              [-62.6920385, 46.440148],
              [-62.692475, 46.4400817],
              [-62.6936606, 46.4400184],
              [-62.6945411, 46.4398581],
              [-62.6951663, 46.4399051],
              [-62.6968565, 46.440423],
              [-62.6976279, 46.4405263],
              [-62.6990282, 46.4408002],
              [-62.6998886, 46.4408663],
              [-62.7009087, 46.4411365],
              [-62.7018178, 46.4412519],
              [-62.7022829, 46.4413697],
              [-62.703072, 46.4413779],
              [-62.7053169, 46.4411515],
              [-62.7064912, 46.4411754],
              [-62.707315, 46.441239],
              [-62.7079568, 46.4412399],
              [-62.7090425, 46.4411286],
              [-62.7098347, 46.4408841],
              [-62.7101008, 46.4407513],
              [-62.7110102, 46.440388],
              [-62.7113427, 46.4402268],
              [-62.7118265, 46.440166],
              [-62.7119851, 46.4401778],
              [-62.7121868, 46.4402369],
              [-62.7126951, 46.4405575],
              [-62.7130821, 46.4406836],
              [-62.7137352, 46.4407916],
              [-62.7146042, 46.4409111],
              [-62.7148749, 46.4409798],
              [-62.7154783, 46.4411212],
              [-62.7161479, 46.4413324],
              [-62.7164982, 46.4414595],
              [-62.7175121, 46.4415518],
              [-62.7179452, 46.4416844],
              [-62.7189406, 46.4417713],
              [-62.7196981, 46.4419873],
              [-62.7203961, 46.4422937],
              [-62.7209304, 46.442585],
              [-62.7213977, 46.4427469],
              [-62.7218525, 46.4427715],
              [-62.7223487, 46.4428862],
              [-62.7226359, 46.4429484],
              [-62.7229886, 46.4430908],
              [-62.7235383, 46.4432511],
              [-62.7239254, 46.4432822],
              [-62.7241889, 46.443202],
              [-62.7245024, 46.4431259],
              [-62.7249774, 46.4430678],
              [-62.7254314, 46.4430409],
              [-62.7261616, 46.4431078],
              [-62.727092, 46.4434226],
              [-62.7280687, 46.4435637],
              [-62.7282162, 46.4436917],
              [-62.7283637, 46.4438197],
              [-62.7284061, 46.4441517],
              [-62.7280356, 46.4450887],
              [-62.7276426, 46.4456506],
              [-62.7259113, 46.4465027],
              [-62.7243927, 46.4469986],
              [-62.7237472, 46.4472699],
              [-62.721508, 46.4479472],
              [-62.7183795, 46.4485575],
              [-62.718327, 46.4485679],
              [-62.7177403, 46.4486377],
              [-62.7171323, 46.4487707],
              [-62.7163616, 46.4491059],
              [-62.715416, 46.4492007],
              [-62.7137709, 46.449597],
              [-62.7127351, 46.4497488],
              [-62.7108122, 46.4503438],
              [-62.7099977, 46.4506417],
              [-62.709417, 46.4509787],
              [-62.7076491, 46.4514906],
              [-62.7072112, 46.4515769],
              [-62.7068909, 46.4517185],
              [-62.705457, 46.4521449],
              [-62.7049614, 46.4524127],
              [-62.7024075, 46.453315],
              [-62.6994745, 46.4542854],
              [-62.6981968, 46.4547006],
              [-62.6966751, 46.4551442],
              [-62.6944239, 46.4557248],
              [-62.6912708, 46.4562539],
              [-62.6903674, 46.4564055],
              [-62.6871258, 46.456605],
              [-62.685632, 46.4567026],
              [-62.684954, 46.4566658],
              [-62.6841245, 46.4567439],
              [-62.6834424, 46.4569502],
              [-62.6824664, 46.4570637],
              [-62.6819823, 46.4570608],
              [-62.6791609, 46.4573605],
              [-62.6761092, 46.4575614],
              [-62.6748844, 46.4575994],
              [-62.6726221, 46.4579003],
              [-62.6707526, 46.4581893],
              [-62.6682919, 46.4588823],
              [-62.6672518, 46.4590933],
              [-62.665959, 46.4592884],
              [-62.6640826, 46.45986],
              [-62.6629192, 46.4604616],
              [-62.6624638, 46.4604595],
              [-62.6623751, 46.4604049],
              [-62.6621823, 46.4604428],
              [-62.6617439, 46.4607121],
              [-62.661307, 46.46087],
              [-62.6603082, 46.4610662],
              [-62.6595249, 46.4611415],
              [-62.6573292, 46.4617429],
              [-62.6566397, 46.4617933],
              [-62.6563005, 46.4618665],
              [-62.6547926, 46.461999],
              [-62.6539753, 46.462153],
              [-62.6511243, 46.4625426],
              [-62.6491054, 46.4629062],
              [-62.6458718, 46.4631483],
              [-62.6445215, 46.4632498],
              [-62.6429815, 46.4636959],
              [-62.6423666, 46.4639278],
              [-62.6412712, 46.4641886],
              [-62.6406463, 46.4643962],
              [-62.6376906, 46.4650611],
              [-62.6358425, 46.4655414],
              [-62.6348554, 46.4659967],
              [-62.6343317, 46.4662434],
              [-62.6325749, 46.4667384],
              [-62.6315314, 46.4667337],
              [-62.6305413, 46.4673204],
              [-62.6298926, 46.4675392],
              [-62.6293631, 46.4676103],
              [-62.6289714, 46.467602],
              [-62.6285957, 46.4672635],
              [-62.6282409, 46.4670451],
              [-62.6276983, 46.4668767],
              [-62.6269726, 46.4668619],
              [-62.6267335, 46.4669023],
              [-62.6249627, 46.4674326],
              [-62.6242229, 46.4675449],
              [-62.6239966, 46.467478],
              [-62.6237277, 46.4674456],
              [-62.6229844, 46.4674343],
              [-62.6222909, 46.4671057],
              [-62.6221153, 46.4667933],
              [-62.6216405, 46.4665592],
              [-62.6203738, 46.4662644],
              [-62.6202901, 46.4662219],
              [-62.6201198, 46.4662684],
              [-62.61973, 46.4664952],
              [-62.6194822, 46.4664914],
              [-62.6192376, 46.4662646],
              [-62.6190595, 46.465992],
              [-62.6189075, 46.4657601],
              [-62.6186851, 46.4656335],
              [-62.6178106, 46.4656021],
              [-62.61698, 46.4655162],
              [-62.6159679, 46.4654724],
              [-62.614624, 46.4654739],
              [-62.6143038, 46.465432],
              [-62.6138078, 46.4656077],
              [-62.6135676, 46.4655762],
              [-62.6132715, 46.4654314],
              [-62.6124854, 46.4655461],
              [-62.6117479, 46.4657102],
              [-62.6110458, 46.4657757],
              [-62.6105544, 46.4660551],
              [-62.6100265, 46.4660146],
              [-62.6096534, 46.4660746],
              [-62.6091147, 46.4662847],
              [-62.6085556, 46.4666297],
              [-62.6082152, 46.466631],
              [-62.6080661, 46.466706],
              [-62.6076632, 46.4666933],
              [-62.6074741, 46.466763],
              [-62.6072551, 46.4667601],
              [-62.6066642, 46.4664505],
              [-62.6057483, 46.4664336],
              [-62.6036064, 46.4667286],
              [-62.6031027, 46.4669319],
              [-62.6028657, 46.4671157],
              [-62.6026328, 46.4671482],
              [-62.6019632, 46.4668082],
              [-62.6013712, 46.4668652],
              [-62.6005774, 46.4670075],
              [-62.5995451, 46.4670067],
              [-62.5980424, 46.4669671],
              [-62.5971539, 46.4669709],
              [-62.5963099, 46.4671753],
              [-62.5958643, 46.4671971],
              [-62.5944311, 46.467327],
              [-62.5942033, 46.4673716],
              [-62.5933492, 46.4672051],
              [-62.5927386, 46.4671937],
              [-62.592303, 46.4672396],
              [-62.5919004, 46.4671352],
              [-62.590454, 46.4671172],
              [-62.5892272, 46.4672656],
              [-62.5865278, 46.4673552],
              [-62.585774, 46.4675026],
              [-62.5851948, 46.4674523],
              [-62.5819664, 46.4677761],
              [-62.5815659, 46.4678151],
              [-62.5794648, 46.4678399],
              [-62.579, 46.4679766],
              [-62.5776405, 46.4681245],
              [-62.5761626, 46.4681452],
              [-62.5743867, 46.468224],
              [-62.5718833, 46.4683992],
              [-62.5703641, 46.4684343],
              [-62.5692982, 46.4686753],
              [-62.5678586, 46.4688126],
              [-62.566387, 46.4688253],
              [-62.5655696, 46.4688868],
              [-62.5648395, 46.4690229],
              [-62.5639285, 46.4690176],
              [-62.5628281, 46.4694287],
              [-62.5620017, 46.4695378],
              [-62.5605602, 46.4695314],
              [-62.5600708, 46.4692607],
              [-62.5595078, 46.4692267],
              [-62.5588858, 46.4689557],
              [-62.5572666, 46.4689316],
              [-62.5565116, 46.4687519],
              [-62.5560913, 46.4689975],
              [-62.5558859, 46.4690507],
              [-62.5556273, 46.4689507],
              [-62.5552383, 46.4689024],
              [-62.5546584, 46.4690352],
              [-62.5544632, 46.4690209],
              [-62.5529039, 46.4690505],
              [-62.5508468, 46.4690203],
              [-62.5504364, 46.46929],
              [-62.5501886, 46.4692861],
              [-62.5499377, 46.4691584],
              [-62.5496491, 46.4693325],
              [-62.5491113, 46.4692673],
              [-62.5489558, 46.46935],
              [-62.5485268, 46.4692964],
              [-62.5479583, 46.4688317],
              [-62.5478045, 46.4688028],
              [-62.5472739, 46.4688017],
              [-62.5469336, 46.4690578],
              [-62.5467871, 46.4690929],
              [-62.5461606, 46.468718],
              [-62.5455639, 46.4686709],
              [-62.5452074, 46.4689105],
              [-62.5448697, 46.4688717],
              [-62.5446697, 46.4691004],
              [-62.5444193, 46.4691362],
              [-62.5436832, 46.4689331],
              [-62.5432079, 46.4688819],
              [-62.5429871, 46.4689904],
              [-62.5427542, 46.4690227],
              [-62.5405338, 46.4683373],
              [-62.5402523, 46.4683203],
              [-62.5398584, 46.4682597],
              [-62.5378715, 46.4682155],
              [-62.5359781, 46.4684931],
              [-62.5346477, 46.4685497],
              [-62.5339126, 46.4686734],
              [-62.5323105, 46.4687371],
              [-62.5307368, 46.4688934],
              [-62.5275646, 46.4694004],
              [-62.5271213, 46.4694738],
              [-62.5263627, 46.4695169],
              [-62.5232399, 46.4701449],
              [-62.5223041, 46.4703337],
              [-62.5208854, 46.4704989],
              [-62.5195423, 46.4708259],
              [-62.5172794, 46.4716132],
              [-62.516706, 46.4716464],
              [-62.5154448, 46.4713622],
              [-62.5149471, 46.4713022],
              [-62.514215, 46.4712943],
              [-62.5120934, 46.4716158],
              [-62.5098808, 46.4720857],
              [-62.5083749, 46.4721761],
              [-62.5070115, 46.4720359],
              [-62.5060092, 46.4720152],
              [-62.5051035, 46.4721849],
              [-62.5038051, 46.4725291],
              [-62.5032318, 46.4725621],
              [-62.5017253, 46.4727441],
              [-62.5006541, 46.472717],
              [-62.500303, 46.4726218],
              [-62.5000001, 46.472597],
              [-62.4997288, 46.4725831],
              [-62.4989474, 46.4724539],
              [-62.4977894, 46.4725157],
              [-62.4946179, 46.4730017],
              [-62.4941597, 46.4730384],
              [-62.4923593, 46.473382],
              [-62.4913044, 46.4733713],
              [-62.488085, 46.4730424],
              [-62.4877298, 46.4730068],
              [-62.4867094, 46.4730809],
              [-62.4860985, 46.4733239],
              [-62.4850721, 46.4735692],
              [-62.4836847, 46.4736949],
              [-62.4819966, 46.4736633],
              [-62.481264, 46.4737468],
              [-62.4805305, 46.4737585],
              [-62.4795537, 46.4738699],
              [-62.4787813, 46.4741114],
              [-62.4783005, 46.4741395],
              [-62.4780245, 46.4740427],
              [-62.4777304, 46.474041],
              [-62.4774289, 46.4742303],
              [-62.477034, 46.4743528],
              [-62.4764332, 46.4743649],
              [-62.4756879, 46.4744639],
              [-62.4747691, 46.4744854],
              [-62.4744778, 46.4744439],
              [-62.4735267, 46.4744324],
              [-62.4728741, 46.4745265],
              [-62.4724407, 46.4747872],
              [-62.4721951, 46.4748349],
              [-62.471952, 46.4746794],
              [-62.4716198, 46.4745608],
              [-62.4697506, 46.4744791],
              [-62.4690281, 46.4743317],
              [-62.4676459, 46.4736324],
              [-62.4668933, 46.4735039],
              [-62.4660181, 46.4735626],
              [-62.46473, 46.4740022],
              [-62.4639461, 46.4740759],
              [-62.4635789, 46.4739641],
              [-62.4627563, 46.4734307],
              [-62.461836, 46.4733086],
              [-62.4615165, 46.4731744],
              [-62.4613225, 46.4729767],
              [-62.4611513, 46.4723692],
              [-62.4608499, 46.47214],
              [-62.4602545, 46.471909],
              [-62.4593846, 46.4717247],
              [-62.4582365, 46.4718102],
              [-62.456297, 46.4715785],
              [-62.4556367, 46.4717001],
              [-62.4552928, 46.4716687],
              [-62.4550279, 46.4714128],
              [-62.4545989, 46.4711953],
              [-62.4535151, 46.4710198],
              [-62.4510748, 46.4710224],
              [-62.4478789, 46.4706092],
              [-62.4472325, 46.4705319],
              [-62.4450686, 46.4703761],
              [-62.4423847, 46.4703146],
              [-62.4411489, 46.4704169],
              [-62.4386907, 46.470841],
              [-62.4359852, 46.4710058],
              [-62.4348547, 46.471251],
              [-62.4335388, 46.4713425],
              [-62.4327232, 46.4712913],
              [-62.4320368, 46.4712086],
              [-62.4307236, 46.4712603],
              [-62.4295487, 46.4712329],
              [-62.4278039, 46.4711067],
              [-62.4253685, 46.4711208],
              [-62.4245803, 46.4710904],
              [-62.4229941, 46.4706785],
              [-62.4214886, 46.4705123],
              [-62.4202716, 46.4699748],
              [-62.4196897, 46.4698732],
              [-62.4191953, 46.4695773],
              [-62.4187496, 46.4691898],
              [-62.4179919, 46.4690789],
              [-62.4172221, 46.4690343],
              [-62.4169332, 46.4689697],
              [-62.4167874, 46.4689384],
              [-62.4169552, 46.4685681],
              [-62.414279, 46.4678746],
              [-62.4113547, 46.467545],
              [-62.4106136, 46.4675838],
              [-62.4100887, 46.4675024],
              [-62.408676, 46.4674946],
              [-62.40684, 46.4673089],
              [-62.4048192, 46.4672871],
              [-62.4040894, 46.4674122],
              [-62.4021669, 46.4677732],
              [-62.400865, 46.4679923],
              [-62.4000081, 46.4682822],
              [-62.3997098, 46.4683399],
              [-62.398815, 46.4683495],
              [-62.3984978, 46.4684304],
              [-62.3982991, 46.4685472],
              [-62.398017, 46.4686214],
              [-62.3972648, 46.4683289],
              [-62.3949748, 46.4683271],
              [-62.3940294, 46.4685622],
              [-62.3935023, 46.4685921],
              [-62.3927739, 46.4684519],
              [-62.3920749, 46.4685478],
              [-62.3911611, 46.4685806],
              [-62.3903169, 46.468528],
              [-62.3888606, 46.468646],
              [-62.3882051, 46.4686158],
              [-62.387711, 46.4687505],
              [-62.3871292, 46.4687388],
              [-62.3851552, 46.4683489],
              [-62.3831558, 46.4683168],
              [-62.3825158, 46.4683947],
              [-62.3815803, 46.468327],
              [-62.3810197, 46.4681804],
              [-62.3804737, 46.4680702],
              [-62.3788301, 46.4681458],
              [-62.3781992, 46.4681761],
              [-62.377428, 46.4680702],
              [-62.3772027, 46.4680745],
              [-62.3767886, 46.4680564],
              [-62.3756074, 46.4681995],
              [-62.3747765, 46.4682031],
              [-62.3743582, 46.4682446],
              [-62.3736569, 46.4684519],
              [-62.3730249, 46.4689007],
              [-62.3721122, 46.4694037],
              [-62.3716151, 46.469578],
              [-62.3704584, 46.4697817],
              [-62.3697071, 46.4701228],
              [-62.3692776, 46.4701599],
              [-62.368795, 46.4698086],
              [-62.3683171, 46.4697963],
              [-62.3677122, 46.4697039],
              [-62.367188, 46.469694],
              [-62.366766, 46.4698669],
              [-62.3664038, 46.4699302],
              [-62.3661322, 46.4699369],
              [-62.3658092, 46.4700973],
              [-62.365527, 46.4701713],
              [-62.3647158, 46.4700599],
              [-62.3644469, 46.4701902],
              [-62.3644341, 46.4703691],
              [-62.3643251, 46.4705208],
              [-62.3641229, 46.4706056],
              [-62.3631237, 46.4703801],
              [-62.362706, 46.4704934],
              [-62.3624546, 46.4706203],
              [-62.3617331, 46.4707072],
              [-62.3616327, 46.4707397],
              [-62.3609596, 46.4707126],
              [-62.3606058, 46.4708201],
              [-62.3603146, 46.4707783],
              [-62.3599176, 46.4705215],
              [-62.3594729, 46.4702871],
              [-62.3586682, 46.4700044],
              [-62.3575279, 46.4698976],
              [-62.3571918, 46.4698382],
              [-62.3569457, 46.4696504],
              [-62.3569279, 46.4692552],
              [-62.3568677, 46.4691296],
              [-62.3565773, 46.4689961],
              [-62.3560657, 46.4689706],
              [-62.355528, 46.4690677],
              [-62.354372, 46.4691796],
              [-62.3535349, 46.4690273],
              [-62.3529532, 46.4690153],
              [-62.3525516, 46.469145],
              [-62.3519212, 46.4692469],
              [-62.3514227, 46.4694411],
              [-62.3506421, 46.4701078],
              [-62.3504041, 46.4701276],
              [-62.3501011, 46.4700095],
              [-62.3498315, 46.470068],
              [-62.3492931, 46.4706554],
              [-62.3490592, 46.4707789],
              [-62.3487139, 46.470767],
              [-62.3482036, 46.470323],
              [-62.3476906, 46.4703173],
              [-62.3474061, 46.4705029],
              [-62.3473207, 46.4708068],
              [-62.3471076, 46.4711224],
              [-62.3468933, 46.4712944],
              [-62.3465852, 46.4713276],
              [-62.3464456, 46.471263],
              [-62.3461218, 46.470953],
              [-62.3458243, 46.4709188],
              [-62.3454943, 46.4710151],
              [-62.3449916, 46.4711054],
              [-62.3444641, 46.4708999],
              [-62.3440438, 46.4708895],
              [-62.3434658, 46.4711446],
              [-62.3429619, 46.4710914],
              [-62.3423039, 46.4713358],
              [-62.3405666, 46.4715072],
              [-62.3401364, 46.4714724],
              [-62.3396161, 46.4711675],
              [-62.3392708, 46.4711556],
              [-62.3390832, 46.4712767],
              [-62.3387006, 46.4713831],
              [-62.3384454, 46.4716413],
              [-62.3381715, 46.4717594],
              [-62.3375336, 46.4717255],
              [-62.3372562, 46.4718116],
              [-62.3365788, 46.471844],
              [-62.3361579, 46.4717618],
              [-62.3352725, 46.4715598],
              [-62.3348414, 46.4712181],
              [-62.3346688, 46.4712121],
              [-62.3345655, 46.4712843],
              [-62.3345209, 46.471502],
              [-62.33444, 46.4715829],
              [-62.3342648, 46.4714533],
              [-62.3341813, 46.4710119],
              [-62.3339367, 46.4708042],
              [-62.3336421, 46.4707302],
              [-62.3323297, 46.4706889],
              [-62.3313609, 46.4712414],
              [-62.3310225, 46.4712935],
              [-62.3301398, 46.4712151],
              [-62.3297695, 46.4710708],
              [-62.3294106, 46.471166],
              [-62.3290058, 46.4711002],
              [-62.328483, 46.4710702],
              [-62.3280554, 46.471159],
              [-62.3274065, 46.4713558],
              [-62.32628, 46.4713766],
              [-62.3258228, 46.4715561],
              [-62.3255733, 46.4717348],
              [-62.3250746, 46.4719288],
              [-62.3247425, 46.4719731],
              [-62.3236736, 46.4719959],
              [-62.322398, 46.472163],
              [-62.3215937, 46.4721152],
              [-62.3207466, 46.4721018],
              [-62.3203795, 46.4721528],
              [-62.3199075, 46.4722959],
              [-62.3195593, 46.4723237],
              [-62.318814, 46.4726566],
              [-62.3177352, 46.4726551],
              [-62.3171764, 46.4727234],
              [-62.3151406, 46.4725531],
              [-62.3143321, 46.4725648],
              [-62.3137913, 46.4727015],
              [-62.3131856, 46.4727004],
              [-62.3120976, 46.4721125],
              [-62.3115245, 46.471981],
              [-62.3111588, 46.4720121],
              [-62.3108053, 46.4721911],
              [-62.3105934, 46.4724867],
              [-62.3102693, 46.4727385],
              [-62.309886, 46.472773],
              [-62.3096695, 46.4728931],
              [-62.3094238, 46.4729403],
              [-62.3091427, 46.4727591],
              [-62.3088633, 46.4727933],
              [-62.3087102, 46.4728358],
              [-62.3085137, 46.4728409],
              [-62.3083148, 46.4729575],
              [-62.3082183, 46.4730937],
              [-62.3079613, 46.4731366],
              [-62.3077326, 46.4731087],
              [-62.3075239, 46.4732011],
              [-62.3072201, 46.4731746],
              [-62.3066891, 46.4733354],
              [-62.3052147, 46.4732203],
              [-62.3047885, 46.4732892],
              [-62.3040577, 46.4736584],
              [-62.3036476, 46.4737437],
              [-62.303166, 46.4734639],
              [-62.3026462, 46.4736291],
              [-62.302456, 46.4736265],
              [-62.3022877, 46.4735608],
              [-62.3020814, 46.4735416],
              [-62.3018134, 46.4738153],
              [-62.3013968, 46.4736732],
              [-62.3008725, 46.4736629],
              [-62.3000718, 46.4738821],
              [-62.299307, 46.4737678],
              [-62.2989965, 46.4739124],
              [-62.2989385, 46.4740738],
              [-62.2987171, 46.4741817],
              [-62.2983186, 46.474108],
              [-62.2980949, 46.4743274],
              [-62.2972117, 46.4744121],
              [-62.297021, 46.4743377],
              [-62.2970413, 46.4740594],
              [-62.2968418, 46.473869],
              [-62.2963145, 46.4736633],
              [-62.2958092, 46.4736297],
              [-62.2953108, 46.4736601],
              [-62.2942389, 46.4739575],
              [-62.2933952, 46.4739758],
              [-62.292689, 46.4737718],
              [-62.2917696, 46.4737197],
              [-62.2913322, 46.4737841],
              [-62.2898473, 46.4742065],
              [-62.2893405, 46.4741928],
              [-62.2887231, 46.4741153],
              [-62.2882754, 46.4743189],
              [-62.2882077, 46.474456],
              [-62.2881848, 46.4746107],
              [-62.2880297, 46.4748365],
              [-62.2877917, 46.474856],
              [-62.2877098, 46.4747934],
              [-62.2876073, 46.4745387],
              [-62.2873649, 46.4746179],
              [-62.2871108, 46.4746209],
              [-62.2864309, 46.4742942],
              [-62.2859881, 46.4742747],
              [-62.2858988, 46.4743114],
              [-62.2853731, 46.4743209],
              [-62.2852361, 46.4743798],
              [-62.2842036, 46.4743755],
              [-62.2839231, 46.4742661],
              [-62.2830072, 46.4742459],
              [-62.282836, 46.47422],
              [-62.2824942, 46.4742399],
              [-62.2800722, 46.4748485],
              [-62.2790963, 46.4749378],
              [-62.2782541, 46.4751713],
              [-62.2772796, 46.4752407],
              [-62.2757126, 46.4756002],
              [-62.2737568, 46.475675],
              [-62.2736324, 46.4757184],
              [-62.2731228, 46.4757443],
              [-62.2725819, 46.4756456],
              [-62.2725024, 46.4757066],
              [-62.2722595, 46.475714],
              [-62.2710797, 46.4754373],
              [-62.2705998, 46.4753725],
              [-62.2703447, 46.4754672],
              [-62.2698531, 46.4755615],
              [-62.2694088, 46.4755618],
              [-62.2689879, 46.4757144],
              [-62.2684031, 46.4757416],
              [-62.2666776, 46.4753539],
              [-62.2635241, 46.4753185],
              [-62.262275, 46.4754338],
              [-62.2614888, 46.4754539],
              [-62.2600929, 46.4756039],
              [-62.2580068, 46.4758013],
              [-62.2554104, 46.4761879],
              [-62.2540412, 46.4765221],
              [-62.253047, 46.4770131],
              [-62.2525747, 46.4771559],
              [-62.2519865, 46.477151],
              [-62.2515196, 46.4773776],
              [-62.2512357, 46.4774712],
              [-62.2508011, 46.4774957],
              [-62.2500001, 46.4773783],
              [-62.2497965, 46.4773485],
              [-62.2494185, 46.4772314],
              [-62.2492859, 46.4772307],
              [-62.2489306, 46.4774294],
              [-62.2476678, 46.4774165],
              [-62.247245, 46.4775171],
              [-62.2471132, 46.47766],
              [-62.2468966, 46.4777799],
              [-62.2466776, 46.4777761],
              [-62.2464541, 46.4778319],
              [-62.2461588, 46.4781563],
              [-62.2459174, 46.4781438],
              [-62.2454904, 46.4778336],
              [-62.2444174, 46.4777517],
              [-62.2438472, 46.4778152],
              [-62.2426325, 46.478087],
              [-62.2424422, 46.4780842],
              [-62.2413344, 46.4783159],
              [-62.2402603, 46.4788678],
              [-62.2398296, 46.478996],
              [-62.2392128, 46.479297],
              [-62.2383514, 46.4793182],
              [-62.2374525, 46.4792224],
              [-62.2369754, 46.4793529],
              [-62.2362115, 46.4793816],
              [-62.2355269, 46.4790423],
              [-62.2349369, 46.4789854],
              [-62.2341031, 46.4790992],
              [-62.2331313, 46.4793636],
              [-62.2327363, 46.4793216],
              [-62.2323315, 46.4792554],
              [-62.2321209, 46.4790605],
              [-62.2311938, 46.4790355],
              [-62.2310031, 46.4789609],
              [-62.2298, 46.4793087],
              [-62.2293844, 46.4793098],
              [-62.2291015, 46.4793117],
              [-62.2287502, 46.4793789],
              [-62.2259458, 46.4792231],
              [-62.2255111, 46.4792474],
              [-62.2238025, 46.4792542],
              [-62.2223585, 46.4794258],
              [-62.2213686, 46.4796217],
              [-62.2204747, 46.4798449],
              [-62.218578, 46.4799724],
              [-62.2159695, 46.4802817],
              [-62.2152065, 46.4804537],
              [-62.215104, 46.4804341],
              [-62.2129074, 46.4809454],
              [-62.2122649, 46.4812772],
              [-62.212234, 46.4817664],
              [-62.2120396, 46.481895],
              [-62.2120632, 46.4821191],
              [-62.2122082, 46.4823396],
              [-62.2121283, 46.4826357],
              [-62.2118159, 46.4827999],
              [-62.2116656, 46.4828025],
              [-62.2111095, 46.4825234],
              [-62.2109969, 46.4821008],
              [-62.2106501, 46.4818013],
              [-62.2102147, 46.4816821],
              [-62.2095064, 46.4816607],
              [-62.2080571, 46.4817482],
              [-62.2061338, 46.4819981],
              [-62.2058815, 46.4820528],
              [-62.2053362, 46.4823203],
              [-62.204863, 46.4829332],
              [-62.2046634, 46.4832848],
              [-62.2042545, 46.4836568],
              [-62.2039813, 46.4836829],
              [-62.2036405, 46.4833038],
              [-62.2033471, 46.4831378],
              [-62.2028481, 46.483096],
              [-62.2026235, 46.4829364],
              [-62.2024785, 46.4827159],
              [-62.2021067, 46.4825192],
              [-62.2013147, 46.4823831],
              [-62.2008999, 46.4822206],
              [-62.2005613, 46.4822722],
              [-62.1999808, 46.4825464],
              [-62.199677, 46.4825195],
              [-62.1993967, 46.4824098],
              [-62.198965, 46.4823942],
              [-62.1983913, 46.4824254],
              [-62.197658, 46.4825066],
              [-62.1970704, 46.482645],
              [-62.1966447, 46.4828569],
              [-62.1962421, 46.4832211],
              [-62.1958369, 46.4833899],
              [-62.1956081, 46.4833617],
              [-62.1953436, 46.4831967],
              [-62.1951698, 46.4829752],
              [-62.195006, 46.482164],
              [-62.1945729, 46.4818613],
              [-62.1942212, 46.4818566],
              [-62.1938867, 46.4820838],
              [-62.1935952, 46.4819696],
              [-62.1935512, 46.4817886],
              [-62.1934196, 46.4816962],
              [-62.1929435, 46.4817348],
              [-62.1928366, 46.4817748],
              [-62.1926792, 46.4819486],
              [-62.1921353, 46.4821961],
              [-62.1913093, 46.4822819],
              [-62.1900109, 46.4822032],
              [-62.1896338, 46.4822294],
              [-62.1888458, 46.4825757],
              [-62.1883772, 46.482515],
              [-62.1880454, 46.4823236],
              [-62.1878538, 46.4820336],
              [-62.1877463, 46.4816231],
              [-62.1874751, 46.481394],
              [-62.1870558, 46.4812912],
              [-62.1863588, 46.481274],
              [-62.1839134, 46.4813291],
              [-62.1830905, 46.4811716],
              [-62.1824405, 46.4812956],
              [-62.1817842, 46.4812973],
              [-62.1813638, 46.4813127],
              [-62.1808652, 46.4813425],
              [-62.1806626, 46.4814269],
              [-62.1800023, 46.481383],
              [-62.1795601, 46.4815066],
              [-62.1794625, 46.481499],
              [-62.1791321, 46.4815947],
              [-62.1775555, 46.4818366],
              [-62.1753454, 46.4819838],
              [-62.1735014, 46.4824791],
              [-62.171829, 46.4827653],
              [-62.1700052, 46.4831456],
              [-62.1683025, 46.4834506],
              [-62.1673786, 46.4838356],
              [-62.1669393, 46.4839194],
              [-62.1662856, 46.4839395],
              [-62.1660097, 46.4840769],
              [-62.165468, 46.4852772],
              [-62.1650103, 46.4855277],
              [-62.1644668, 46.4855398],
              [-62.164246, 46.485484],
              [-62.1640518, 46.4853055],
              [-62.1638946, 46.4847934],
              [-62.1638352, 46.4847394],
              [-62.1639512, 46.4842015],
              [-62.1637316, 46.4840539],
              [-62.1636701, 46.4838763],
              [-62.1633651, 46.4835622],
              [-62.1630549, 46.4827854],
              [-62.1626863, 46.4825488],
              [-62.1617896, 46.482576],
              [-62.1612153, 46.4824634],
              [-62.1607155, 46.482206],
              [-62.1599682, 46.4820153],
              [-62.1594024, 46.4820186],
              [-62.1587469, 46.4819868],
              [-62.1582165, 46.4820552],
              [-62.1574034, 46.482197],
              [-62.1565658, 46.4822063],
              [-62.1554466, 46.4821257],
              [-62.1545084, 46.4821672],
              [-62.1531456, 46.4823288],
              [-62.1522671, 46.4824961],
              [-62.1512567, 46.4828778],
              [-62.1500241, 46.4828448],
              [-62.1486076, 46.4827507],
              [-62.1481063, 46.4828838],
              [-62.1469205, 46.4831273],
              [-62.1467721, 46.4835703],
              [-62.1465332, 46.4837665],
              [-62.1459167, 46.4837274],
              [-62.1455652, 46.4834839],
              [-62.1453133, 46.4833868],
              [-62.1446525, 46.4833165],
              [-62.1439131, 46.4834521],
              [-62.1435893, 46.483446],
              [-62.1435028, 46.4833851],
              [-62.143474, 46.4831269],
              [-62.1435553, 46.4828453],
              [-62.1434637, 46.4823538],
              [-62.1431922, 46.481866],
              [-62.1426963, 46.4814433],
              [-62.1419225, 46.4810177],
              [-62.1402768, 46.4803514],
              [-62.1395118, 46.4800922],
              [-62.1380018, 46.4797657],
              [-62.1376387, 46.4796846],
              [-62.1354788, 46.4795536],
              [-62.1345135, 46.479574],
              [-62.1312698, 46.4794271],
              [-62.1309309, 46.4794067],
              [-62.1304297, 46.4796195],
              [-62.1301708, 46.47961],
              [-62.1297282, 46.4796615],
              [-62.1288469, 46.4794896],
              [-62.1283544, 46.4794396],
              [-62.1272742, 46.4795274],
              [-62.1261199, 46.4794531],
              [-62.1246626, 46.4791164],
              [-62.1241529, 46.4791415],
              [-62.1237514, 46.4790351],
              [-62.1234051, 46.4787353],
              [-62.1228814, 46.478417],
              [-62.1223412, 46.4779386],
              [-62.1218663, 46.4778852],
              [-62.1210965, 46.4780642],
              [-62.119586, 46.4780444],
              [-62.1178363, 46.4776849],
              [-62.1172815, 46.4776206],
              [-62.1165894, 46.477615],
              [-62.1161776, 46.4777912],
              [-62.1159507, 46.4778147],
              [-62.1157555, 46.4777278],
              [-62.1155332, 46.4771694],
              [-62.1152428, 46.4768198],
              [-62.114791, 46.4764682],
              [-62.1145573, 46.476356],
              [-62.1142452, 46.4762129],
              [-62.1136901, 46.4759071],
              [-62.1131268, 46.4758247],
              [-62.1122092, 46.475751],
              [-62.1119549, 46.4756818],
              [-62.1108517, 46.4750231],
              [-62.1100053, 46.4747008],
              [-62.1092568, 46.4745296],
              [-62.107963, 46.4742465],
              [-62.107354, 46.474212],
              [-62.1057708, 46.4738664],
              [-62.1048309, 46.4737838],
              [-62.104153, 46.4735395],
              [-62.1011924, 46.4732465],
              [-62.0997014, 46.4732749],
              [-62.0967192, 46.47326],
              [-62.0958449, 46.4732236],
              [-62.0953844, 46.4731348],
              [-62.0942402, 46.4731561],
              [-62.0930264, 46.4734778],
              [-62.091759, 46.4734507],
              [-62.0907135, 46.4735193],
              [-62.0890576, 46.4734418],
              [-62.0884898, 46.4732493],
              [-62.0877495, 46.4731221],
              [-62.0872598, 46.4728168],
              [-62.0854333, 46.4722624],
              [-62.0839127, 46.4719307],
              [-62.0830635, 46.4716479],
              [-62.0826189, 46.4715038],
              [-62.0820786, 46.4714757],
              [-62.0817732, 46.4713965],
              [-62.0814193, 46.4710524],
              [-62.0809872, 46.470821],
              [-62.0803301, 46.470665],
              [-62.0789093, 46.470608],
              [-62.0780237, 46.4704952],
              [-62.0762827, 46.4701791],
              [-62.0754068, 46.4700906],
              [-62.0725483, 46.4695293],
              [-62.0714978, 46.4692389],
              [-62.0702424, 46.4687653],
              [-62.0694125, 46.468531],
              [-62.0683897, 46.4684768],
              [-62.068302, 46.4682671],
              [-62.067167, 46.4683234],
              [-62.0670264, 46.46835],
              [-62.0658837, 46.4682793],
              [-62.0655273, 46.468262],
              [-62.0651232, 46.4683385],
              [-62.0641422, 46.4683863],
              [-62.0632378, 46.4684673],
              [-62.0624628, 46.46849],
              [-62.0613561, 46.4685164],
              [-62.0608838, 46.4685896],
              [-62.060445, 46.4686895],
              [-62.0601206, 46.4687052],
              [-62.0589957, 46.4687747],
              [-62.0578882, 46.4686972],
              [-62.0570874, 46.4686074],
              [-62.0552189, 46.468302],
              [-62.0522199, 46.4676232],
              [-62.0506007, 46.4673031],
              [-62.0481102, 46.4668495],
              [-62.0467762, 46.46676],
              [-62.0457137, 46.4667519],
              [-62.0439311, 46.4667925],
              [-62.0427859, 46.4670284],
              [-62.0414637, 46.467004],
              [-62.0401887, 46.467004],
              [-62.0390081, 46.4669958],
              [-62.0380519, 46.466882],
              [-62.0369894, 46.4668413],
              [-62.0361394, 46.4668901],
              [-62.034522, 46.4670365],
              [-62.0335894, 46.4671747],
              [-62.0319838, 46.466947],
              [-62.031004, 46.4667682],
              [-62.0297998, 46.46676],
              [-62.0288042, 46.4667599],
              [-62.0284862, 46.466696],
              [-62.0282018, 46.4667172],
              [-62.0251749, 46.4663235],
              [-62.0236649, 46.4663021],
              [-62.0229408, 46.4663291],
              [-62.0223977, 46.4662965],
              [-62.0215949, 46.466077],
              [-62.0196274, 46.4650486],
              [-62.0195363, 46.4650332],
              [-62.0186188, 46.4645997],
              [-62.015678, 46.4636348],
              [-62.0156668, 46.4636304],
              [-62.0140016, 46.4633119],
              [-62.0133273, 46.4632305],
              [-62.0114307, 46.4632102],
              [-62.0109481, 46.4631121],
              [-62.0107232, 46.4629813],
              [-62.0104779, 46.4629285],
              [-62.0097879, 46.4629005],
              [-62.008965, 46.4627296],
              [-62.0061512, 46.4624909],
              [-62.0048857, 46.4625145],
              [-62.0028327, 46.4627193],
              [-62.0018358, 46.4629205],
              [-62.0017336, 46.4629724],
              [-62.0003916, 46.4631604],
              [-62.0000001, 46.463186],
              [-61.9998227, 46.4632024],
              [-61.997688, 46.4631289],
              [-61.9956941, 46.4633876],
              [-61.9948233, 46.4633822],
              [-61.9929601, 46.4630877],
              [-61.9925048, 46.4630823],
              [-61.9913086, 46.4630497],
              [-61.9896176, 46.4633267],
              [-61.9893412, 46.4633201],
              [-61.9890728, 46.4632859],
              [-61.9875725, 46.4632882],
              [-61.9871314, 46.4631039],
              [-61.9863932, 46.4630278],
              [-61.9861216, 46.4628898],
              [-61.9860609, 46.4628556],
              [-61.9859522, 46.4625563],
              [-61.9859657, 46.4620354],
              [-61.9860955, 46.4617752],
              [-61.9862608, 46.4614255],
              [-61.9862372, 46.4607912],
              [-61.9861327, 46.4606257],
              [-61.9856386, 46.4599209],
              [-61.9849531, 46.4591889],
              [-61.9838187, 46.4582284],
              [-61.9832419, 46.4577239],
              [-61.9820533, 46.456865],
              [-61.9798247, 46.4556671],
              [-61.9785898, 46.4550974],
              [-61.9775546, 46.4547706],
              [-61.9766275, 46.4544802],
              [-61.9760035, 46.4544758],
              [-61.9751282, 46.45435],
              [-61.9746914, 46.4540084],
              [-61.9737351, 46.4540572],
              [-61.973133, 46.4536506],
              [-61.9727908, 46.4535795],
              [-61.9724601, 46.4535977],
              [-61.9723481, 46.4535104],
              [-61.9720491, 46.453423],
              [-61.9719053, 46.453313],
              [-61.9714212, 46.4531544],
              [-61.9709785, 46.4530812],
              [-61.9707663, 46.4529066],
              [-61.9708901, 46.4527824],
              [-61.9712029, 46.4522977],
              [-61.9712733, 46.4520304],
              [-61.9715276, 46.4517657],
              [-61.9717814, 46.4516478],
              [-61.9718109, 46.4514445],
              [-61.9721509, 46.4510265],
              [-61.9722596, 46.4508233],
              [-61.9724546, 46.4504082],
              [-61.9726559, 46.4500571],
              [-61.9729611, 46.4497061],
              [-61.973025, 46.4496288],
              [-61.9731864, 46.4492004],
              [-61.9732583, 46.448952],
              [-61.9735266, 46.4486992],
              [-61.9738525, 46.4485204],
              [-61.9752869, 46.4483284],
              [-61.9764129, 46.4480248],
              [-61.9773953, 46.4476439],
              [-61.9785469, 46.4470224],
              [-61.979521, 46.4464538],
              [-61.9798548, 46.4462473],
              [-61.9800561, 46.4461833],
              [-61.981032, 46.4460972],
              [-61.9824056, 46.4457273],
              [-61.9829997, 46.4455109],
              [-61.9832631, 46.4453177],
              [-61.9845153, 46.4445923],
              [-61.9852882, 46.4440877],
              [-61.9855412, 46.4438016],
              [-61.9857664, 46.4437234],
              [-61.9858965, 46.4437822],
              [-61.9862262, 46.4436166],
              [-61.9864457, 46.4431862],
              [-61.9874248, 46.4426865],
              [-61.9881721, 46.441854],
              [-61.9890072, 46.4410767],
              [-61.9891157, 46.4410171],
              [-61.9896554, 46.4406152],
              [-61.9916577, 46.4395915],
              [-61.9920393, 46.4392912],
              [-61.9922532, 46.4389246],
              [-61.9923441, 46.438653],
              [-61.9933436, 46.4379816],
              [-61.9942136, 46.437413],
              [-61.9953136, 46.436853],
              [-61.996139, 46.4363384],
              [-61.9962954, 46.4362567],
              [-61.9990031, 46.4349228],
              [-62.0002937, 46.4342954],
              [-62.0014336, 46.433652],
              [-62.0018562, 46.433368],
              [-62.0032764, 46.432587],
              [-62.0048737, 46.4316702],
              [-62.0062409, 46.4308131],
              [-62.0083981, 46.4293691],
              [-62.0097722, 46.4285031],
              [-62.0109917, 46.4278532],
              [-62.0117747, 46.4272865],
              [-62.0126361, 46.4267197],
              [-62.0141826, 46.4259371],
              [-62.0156509, 46.4252084],
              [-62.0177064, 46.423967],
              [-62.0191355, 46.4233057],
              [-62.0206233, 46.4225905],
              [-62.0225026, 46.4217133],
              [-62.0237555, 46.4211735],
              [-62.0258111, 46.4201884],
              [-62.0267507, 46.419797],
              [-62.0288454, 46.4189063],
              [-62.030529, 46.4180696],
              [-62.0319189, 46.4175028],
              [-62.0334263, 46.416828],
              [-62.0349337, 46.4162341],
              [-62.0366369, 46.4155053],
              [-62.0374395, 46.4151274],
              [-62.0384771, 46.414709],
              [-62.0407284, 46.4138587],
              [-62.0418442, 46.4132648],
              [-62.0432146, 46.412725],
              [-62.0456421, 46.4117667],
              [-62.0472669, 46.4109568],
              [-62.0483632, 46.4104979],
              [-62.0504775, 46.409526],
              [-62.0515346, 46.4091211],
              [-62.0526113, 46.4085407],
              [-62.0546668, 46.4074892],
              [-62.0554303, 46.4072988],
              [-62.0563012, 46.4067814],
              [-62.0577705, 46.4065616],
              [-62.0580177, 46.4064952],
              [-62.058407, 46.406454],
              [-62.0585987, 46.4065808],
              [-62.0585608, 46.4067707],
              [-62.0582675, 46.4069829],
              [-62.0574254, 46.4072143],
              [-62.0568689, 46.4074764],
              [-62.0566603, 46.4077117],
              [-62.0564149, 46.4079017],
              [-62.0560208, 46.4079308],
              [-62.0558246, 46.4080071],
              [-62.0555953, 46.4082855],
              [-62.0550545, 46.4093014],
              [-62.0544968, 46.4097986],
              [-62.0542848, 46.4099302],
              [-62.0526894, 46.4111656],
              [-62.0520546, 46.4113968],
              [-62.0519193, 46.411579],
              [-62.0516659, 46.4117967],
              [-62.0514346, 46.4118797],
              [-62.0511153, 46.4118358],
              [-62.0507864, 46.4116958],
              [-62.0501751, 46.4117007],
              [-62.0498669, 46.4115895],
              [-62.0496196, 46.4115841],
              [-62.0492193, 46.4117644],
              [-62.0490964, 46.4117876],
              [-62.0487138, 46.4120363],
              [-62.0478032, 46.4124763],
              [-62.0463196, 46.4128748],
              [-62.0441034, 46.4132815],
              [-62.0430426, 46.4136521],
              [-62.042556, 46.4137573],
              [-62.0419179, 46.4139564],
              [-62.040832, 46.4146449],
              [-62.0401922, 46.4147921],
              [-62.0390283, 46.4156649],
              [-62.0387857, 46.4156717],
              [-62.038548, 46.4157624],
              [-62.0384173, 46.415885],
              [-62.0377252, 46.4163292],
              [-62.037469, 46.4169453],
              [-62.0369763, 46.4173453],
              [-62.0369211, 46.4178255],
              [-62.0366596, 46.417999],
              [-62.0363037, 46.4180533],
              [-62.0359716, 46.417953],
              [-62.0355722, 46.4176828],
              [-62.0352881, 46.417704],
              [-62.0352212, 46.417821],
              [-62.0350171, 46.4179967],
              [-62.0348495, 46.4180023],
              [-62.0346675, 46.4178997],
              [-62.0344711, 46.4179043],
              [-62.0341155, 46.4181738],
              [-62.0336797, 46.4180736],
              [-62.0334371, 46.4181522],
              [-62.0331199, 46.4184471],
              [-62.0329332, 46.4185477],
              [-62.0323634, 46.418538],
              [-62.0320839, 46.4184278],
              [-62.0318252, 46.4182745],
              [-62.0315427, 46.4182758],
              [-62.0310274, 46.4185234],
              [-62.0305168, 46.4186396],
              [-62.0298484, 46.4190009],
              [-62.0294415, 46.419117],
              [-62.0289438, 46.4193613],
              [-62.0287955, 46.4195589],
              [-62.0272402, 46.4206067],
              [-62.0267312, 46.4207282],
              [-62.0260273, 46.4206698],
              [-62.0246812, 46.4217269],
              [-62.0242267, 46.4221522],
              [-62.0237238, 46.4220972],
              [-62.0232084, 46.422273],
              [-62.0222701, 46.4226918],
              [-62.0217133, 46.4230255],
              [-62.0209191, 46.4237367],
              [-62.0204033, 46.4236255],
              [-62.0199867, 46.4236455],
              [-62.0195639, 46.4238886],
              [-62.0185841, 46.4243218],
              [-62.0180879, 46.4246178],
              [-62.0176701, 46.4250883],
              [-62.0174932, 46.4254284],
              [-62.0169986, 46.4258481],
              [-62.0169124, 46.4258448],
              [-62.0166367, 46.4264123],
              [-62.0156967, 46.4267792],
              [-62.0137564, 46.427981],
              [-62.01334, 46.4283598],
              [-62.0130241, 46.4286348],
              [-62.0129938, 46.4287254],
              [-62.0123589, 46.4293869],
              [-62.0115848, 46.429768],
              [-62.0113761, 46.4302185],
              [-62.0112184, 46.4307507],
              [-62.0109632, 46.4310598],
              [-62.0099545, 46.4314918],
              [-62.0098029, 46.4316575],
              [-62.009498, 46.4317934],
              [-62.0088737, 46.4318134],
              [-62.0082156, 46.4313897],
              [-62.0081646, 46.4315233],
              [-62.0081856, 46.4317673],
              [-62.00855, 46.4323313],
              [-62.0087753, 46.432615],
              [-62.0090436, 46.4326491],
              [-62.0097062, 46.4326544],
              [-62.0100432, 46.4327669],
              [-62.0102796, 46.4329114],
              [-62.010519, 46.4328727],
              [-62.0106898, 46.4327556],
              [-62.0106371, 46.4326938],
              [-62.0102506, 46.4325515],
              [-62.0097524, 46.4325087],
              [-62.0095607, 46.4323818],
              [-62.0096852, 46.4322669],
              [-62.0100268, 46.432248],
              [-62.0105363, 46.4323671],
              [-62.0110823, 46.4323879],
              [-62.0113729, 46.4323591],
              [-62.0122141, 46.4320762],
              [-62.0127585, 46.4319733],
              [-62.014826, 46.4317131],
              [-62.01583, 46.4314124],
              [-62.0167112, 46.4312785],
              [-62.0185725, 46.4308549],
              [-62.0198749, 46.4303542],
              [-62.0208851, 46.4299022],
              [-62.0229421, 46.4289718],
              [-62.023467, 46.4286768],
              [-62.0252921, 46.4273986],
              [-62.0257834, 46.4269468],
              [-62.0276033, 46.425513],
              [-62.0286196, 46.4249097],
              [-62.0295002, 46.4244169],
              [-62.0306938, 46.4239758],
              [-62.0312584, 46.4235427],
              [-62.0314736, 46.4232997],
              [-62.0319676, 46.4225929],
              [-62.0325431, 46.421949],
              [-62.0333452, 46.4212818],
              [-62.0347171, 46.4205524],
              [-62.0364658, 46.4199408],
              [-62.0369078, 46.419818],
              [-62.0384077, 46.4194362],
              [-62.0414441, 46.418646],
              [-62.0433683, 46.4180729],
              [-62.0440351, 46.4178031],
              [-62.0446075, 46.4174141],
              [-62.0455742, 46.4159717],
              [-62.0458866, 46.4157364],
              [-62.0465263, 46.4155173],
              [-62.0480245, 46.4151553],
              [-62.0485048, 46.4151295],
              [-62.0487764, 46.4152673],
              [-62.0494075, 46.4156697],
              [-62.0495623, 46.4156796],
              [-62.049685, 46.4155845],
              [-62.0493813, 46.4152701],
              [-62.0492932, 46.4150715],
              [-62.0490903, 46.4149403],
              [-62.0488716, 46.4149361],
              [-62.0486272, 46.4148192],
              [-62.0486749, 46.4146536],
              [-62.0490766, 46.4143818],
              [-62.0495822, 46.4141816],
              [-62.0524069, 46.4130722],
              [-62.0535742, 46.4125182],
              [-62.0555935, 46.4118288],
              [-62.0564488, 46.4115068],
              [-62.056517, 46.4112981],
              [-62.0562822, 46.4111338],
              [-62.0556247, 46.411141],
              [-62.0553387, 46.4109668],
              [-62.0552937, 46.4108057],
              [-62.0555137, 46.4106466],
              [-62.0561037, 46.4103977],
              [-62.0563683, 46.4101844],
              [-62.0565851, 46.410065],
              [-62.0570094, 46.4099455],
              [-62.0572916, 46.4098006],
              [-62.0579535, 46.4089281],
              [-62.0585412, 46.4083404],
              [-62.0587006, 46.4082188],
              [-62.0592535, 46.4077812],
              [-62.0602543, 46.4069977],
              [-62.0610864, 46.4065267],
              [-62.061528, 46.406332],
              [-62.0614317, 46.4060175],
              [-62.0615351, 46.4058739],
              [-62.0616334, 46.405503],
              [-62.0615966, 46.4054577],
              [-62.0614814, 46.4053099],
              [-62.0614203, 46.4050605],
              [-62.0616624, 46.4048384],
              [-62.0618052, 46.4042667],
              [-62.0622338, 46.4041245],
              [-62.0625895, 46.4042029],
              [-62.0630215, 46.4044326],
              [-62.0637062, 46.4043698],
              [-62.0652548, 46.4028085],
              [-62.0655752, 46.4024918],
              [-62.0674763, 46.4014828],
              [-62.0688219, 46.4010262],
              [-62.0697404, 46.4006432],
              [-62.0717803, 46.3994574],
              [-62.0736707, 46.3985588],
              [-62.0756145, 46.3975424],
              [-62.0773019, 46.3965112],
              [-62.0779534, 46.396084],
              [-62.0804312, 46.3944856],
              [-62.0813924, 46.3938816],
              [-62.0822147, 46.3933513],
              [-62.082706, 46.3930419],
              [-62.0834643, 46.3925778],
              [-62.0843294, 46.3920033],
              [-62.0856857, 46.3909425],
              [-62.0868071, 46.3900364],
              [-62.0877683, 46.3893366],
              [-62.0886869, 46.3885705],
              [-62.0894345, 46.3879148],
              [-62.0897763, 46.3876349],
              [-62.0901073, 46.3873402],
              [-62.0906627, 46.3869056],
              [-62.0912074, 46.3864562],
              [-62.0919137, 46.3860198],
              [-62.0930335, 46.3853393],
              [-62.0937764, 46.38488],
              [-62.0948359, 46.3842164],
              [-62.0966431, 46.3830562],
              [-62.0976814, 46.3825916],
              [-62.0988532, 46.3820059],
              [-62.099735, 46.3815177],
              [-62.1003766, 46.3811151],
              [-62.1014747, 46.3805486],
              [-62.1023121, 46.3801138],
              [-62.1031799, 46.3797982],
              [-62.103921, 46.3793858],
              [-62.1051784, 46.3789023],
              [-62.1058621, 46.3783888],
              [-62.1066897, 46.3780082],
              [-62.1073566, 46.3775544],
              [-62.1076254, 46.3773807],
              [-62.1076571, 46.3773383],
              [-62.1078943, 46.3774111],
              [-62.1082056, 46.3778352],
              [-62.1081473, 46.3778597],
              [-62.1080894, 46.3779455],
              [-62.1078865, 46.37815],
              [-62.1080325, 46.3783869],
              [-62.1079393, 46.378659],
              [-62.1076668, 46.3789657],
              [-62.1075772, 46.3791665],
              [-62.1077659, 46.3794053],
              [-62.1080429, 46.3798151],
              [-62.1083836, 46.3802249],
              [-62.1085591, 46.3803544],
              [-62.1085476, 46.3806611],
              [-62.1091256, 46.3816108],
              [-62.1096182, 46.3820914],
              [-62.109667, 46.3823562],
              [-62.1092768, 46.383083],
              [-62.1091343, 46.3834364],
              [-62.1090885, 46.3835821],
              [-62.1087884, 46.3840373],
              [-62.1087607, 46.384395],
              [-62.1088078, 46.3846797],
              [-62.1086136, 46.384808],
              [-62.1082022, 46.3848406],
              [-62.1077114, 46.3850057],
              [-62.1062173, 46.3862296],
              [-62.1046056, 46.3871104],
              [-62.1042764, 46.387421],
              [-62.1041924, 46.3876132],
              [-62.1043795, 46.3877995],
              [-62.1043432, 46.3878978],
              [-62.1040325, 46.3880417],
              [-62.1033328, 46.3882269],
              [-62.1030393, 46.388224],
              [-62.1027042, 46.3881638],
              [-62.1022962, 46.3883001],
              [-62.1019047, 46.3885964],
              [-62.1014213, 46.3885904],
              [-62.1011903, 46.3886735],
              [-62.1009643, 46.3889122],
              [-62.1008216, 46.3891939],
              [-62.1000966, 46.3894818],
              [-62.0995982, 46.389818],
              [-62.09801, 46.3900221],
              [-62.0975735, 46.390229],
              [-62.0969356, 46.390285],
              [-62.0965136, 46.3905284],
              [-62.0959936, 46.3905489],
              [-62.0955332, 46.390767],
              [-62.0953034, 46.3907584],
              [-62.0951102, 46.3906516],
              [-62.0948166, 46.3906487],
              [-62.0938638, 46.3910516],
              [-62.0936913, 46.3909735],
              [-62.0936717, 46.3907814],
              [-62.0937337, 46.390724],
              [-62.0937966, 46.3903597],
              [-62.0936544, 46.3902627],
              [-62.0933385, 46.3902509],
              [-62.0931061, 46.3904256],
              [-62.0930633, 46.3905316],
              [-62.0928339, 46.3906666],
              [-62.0928154, 46.390905],
              [-62.092554, 46.3910069],
              [-62.0922127, 46.3910261],
              [-62.092079, 46.3911168],
              [-62.0919088, 46.3913057],
              [-62.0916121, 46.3913425],
              [-62.0914127, 46.3913151],
              [-62.0911611, 46.391513],
              [-62.0909377, 46.3920906],
              [-62.0906017, 46.3923371],
              [-62.0900376, 46.3925552],
              [-62.0896571, 46.3928559],
              [-62.0883762, 46.3935495],
              [-62.0882488, 46.3936325],
              [-62.0882124, 46.3937307],
              [-62.0884041, 46.3938575],
              [-62.08839, 46.3939645],
              [-62.087933, 46.3942863],
              [-62.0878649, 46.394495],
              [-62.0879179, 46.3946285],
              [-62.087765, 46.3947423],
              [-62.0871016, 46.3948292],
              [-62.086649, 46.3950196],
              [-62.0864847, 46.3950573],
              [-62.0861821, 46.3952453],
              [-62.0859681, 46.3951815],
              [-62.0856445, 46.3952691],
              [-62.0854944, 46.3951998],
              [-62.0851467, 46.3952267],
              [-62.0843434, 46.3955554],
              [-62.0824346, 46.3958788],
              [-62.0823245, 46.3958866],
              [-62.0822382, 46.3958117],
              [-62.0827101, 46.3956698],
              [-62.0836351, 46.3955529],
              [-62.0840081, 46.3954233],
              [-62.0842009, 46.3953149],
              [-62.0841687, 46.3952101],
              [-62.0838624, 46.3952226],
              [-62.0835691, 46.3952914],
              [-62.0831114, 46.3953979],
              [-62.0817094, 46.395501],
              [-62.0814628, 46.3957827],
              [-62.0808939, 46.3960604],
              [-62.0808877, 46.3961399],
              [-62.0809819, 46.3961872],
              [-62.0813441, 46.3961968],
              [-62.0813953, 46.3962784],
              [-62.081303, 46.3963547],
              [-62.0806796, 46.3965187],
              [-62.0803564, 46.3968215],
              [-62.0799407, 46.3971288],
              [-62.0794432, 46.3972298],
              [-62.0788471, 46.3974865],
              [-62.0787737, 46.3974679],
              [-62.0780419, 46.3976916],
              [-62.0775591, 46.3979725],
              [-62.0774045, 46.3980345],
              [-62.0773008, 46.3980346],
              [-62.0766506, 46.3983929],
              [-62.0765187, 46.3986072],
              [-62.0767056, 46.3987218],
              [-62.0769242, 46.398726],
              [-62.0775205, 46.398541],
              [-62.0784463, 46.3980455],
              [-62.0792211, 46.3977875],
              [-62.0795881, 46.3978091],
              [-62.0801724, 46.3979984],
              [-62.0806622, 46.3979967],
              [-62.0810669, 46.3977567],
              [-62.081468, 46.3973413],
              [-62.082105, 46.3968549],
              [-62.0823473, 46.3967762],
              [-62.0835169, 46.3968478],
              [-62.0853816, 46.396722],
              [-62.0860817, 46.3966086],
              [-62.0873541, 46.3963212],
              [-62.088114, 46.3958832],
              [-62.0881778, 46.3958777],
              [-62.0883586, 46.3960717],
              [-62.0884808, 46.3958331],
              [-62.0887991, 46.3955182],
              [-62.089408, 46.3953177],
              [-62.0899194, 46.3950378],
              [-62.0897851, 46.3949132],
              [-62.0895058, 46.3948749],
              [-62.0891845, 46.3946358],
              [-62.0889269, 46.3943193],
              [-62.0889264, 46.3941041],
              [-62.0890519, 46.3938975],
              [-62.0901764, 46.393214],
              [-62.0908886, 46.3928699],
              [-62.0914222, 46.3925271],
              [-62.0915795, 46.3922818],
              [-62.0916173, 46.392092],
              [-62.0918784, 46.3919183],
              [-62.0927542, 46.3919151],
              [-62.0933613, 46.3916626],
              [-62.0936146, 46.3915166],
              [-62.0941548, 46.3913095],
              [-62.0960223, 46.3911438],
              [-62.0964496, 46.3910561],
              [-62.0970503, 46.3908114],
              [-62.0981238, 46.3907835],
              [-62.0986767, 46.3905609],
              [-62.0988137, 46.3905023],
              [-62.099461, 46.3903988],
              [-62.1000727, 46.3900867],
              [-62.1002911, 46.3900191],
              [-62.1011124, 46.3899739],
              [-62.1014757, 46.3898199],
              [-62.1021889, 46.3893124],
              [-62.1024245, 46.3890979],
              [-62.1027063, 46.388953],
              [-62.1029166, 46.3888412],
              [-62.1047288, 46.3883465],
              [-62.1059345, 46.3877256],
              [-62.1066249, 46.3876595],
              [-62.1064392, 46.3873816],
              [-62.1064544, 46.3871829],
              [-62.1066248, 46.3870657],
              [-62.1070055, 46.3869084],
              [-62.1072696, 46.3866233],
              [-62.1073419, 46.3862832],
              [-62.1077858, 46.3859051],
              [-62.1087862, 46.3854799],
              [-62.109126, 46.3854805],
              [-62.1100706, 46.3861492],
              [-62.1109771, 46.3863438],
              [-62.1114513, 46.3865583],
              [-62.1122655, 46.3867365],
              [-62.1130253, 46.3869639],
              [-62.1139995, 46.3868046],
              [-62.115231, 46.3870133],
              [-62.1159934, 46.387031],
              [-62.1163209, 46.3869722],
              [-62.1170308, 46.386754],
              [-62.1175827, 46.386673],
              [-62.1180044, 46.3867733],
              [-62.1189498, 46.3866129],
              [-62.1189941, 46.386487],
              [-62.1188581, 46.3863824],
              [-62.1185997, 46.3863728],
              [-62.1182124, 46.3864661],
              [-62.118, 46.3863825],
              [-62.1180204, 46.3862677],
              [-62.1185784, 46.3856786],
              [-62.1189751, 46.3855378],
              [-62.1200594, 46.3854423],
              [-62.1221881, 46.385768],
              [-62.1230964, 46.3860127],
              [-62.1233579, 46.3859825],
              [-62.1233638, 46.3858312],
              [-62.1233189, 46.3857419],
              [-62.1232241, 46.3855511],
              [-62.1223509, 46.3852997],
              [-62.1217685, 46.3852344],
              [-62.1205483, 46.3852253],
              [-62.1189985, 46.3848612],
              [-62.1181373, 46.3849011],
              [-62.1179205, 46.3849489],
              [-62.1169108, 46.3848997],
              [-62.1164453, 46.3849622],
              [-62.1160878, 46.3848932],
              [-62.1159122, 46.3848548],
              [-62.1147444, 46.3842417],
              [-62.1131134, 46.3833125],
              [-62.1124032, 46.3830557],
              [-62.1115494, 46.3825633],
              [-62.1107347, 46.3821008],
              [-62.1106844, 46.3816561],
              [-62.1097765, 46.3808756],
              [-62.1094531, 46.3805587],
              [-62.1092271, 46.3802415],
              [-62.1090003, 46.3799071],
              [-62.1087854, 46.3797028],
              [-62.1084876, 46.3792514],
              [-62.1084527, 46.3789361],
              [-62.1084412, 46.3787283],
              [-62.1086803, 46.3782703],
              [-62.108589, 46.3780594],
              [-62.108537, 46.3778846],
              [-62.108337, 46.3777751],
              [-62.108008, 46.377373],
              [-62.1078097, 46.3771556],
              [-62.1079177, 46.3771254],
              [-62.1080181, 46.3772442],
              [-62.1082261, 46.3772244],
              [-62.1084317, 46.3771189],
              [-62.1089761, 46.376843],
              [-62.1095377, 46.3764431],
              [-62.1098213, 46.3762411],
              [-62.1104116, 46.3758799],
              [-62.1108798, 46.3757304],
              [-62.1115478, 46.375501],
              [-62.1121126, 46.3752601],
              [-62.1126242, 46.3750525],
              [-62.113218, 46.3748824],
              [-62.1135121, 46.3746865],
              [-62.1137199, 46.3744661],
              [-62.1141154, 46.3744101],
              [-62.1144501, 46.3743471],
              [-62.115331, 46.3741269],
              [-62.1157278, 46.3740778],
              [-62.1161335, 46.3739693],
              [-62.1164639, 46.374006],
              [-62.1182836, 46.373541],
              [-62.119473, 46.3730018],
              [-62.1207107, 46.3726707],
              [-62.1217218, 46.3721512],
              [-62.1225577, 46.3718481],
              [-62.1238945, 46.3713052],
              [-62.1250862, 46.3708686],
              [-62.1258736, 46.3706323],
              [-62.1265046, 46.3703008],
              [-62.1272285, 46.369953],
              [-62.1278754, 46.3694223],
              [-62.1287997, 46.3690758],
              [-62.1291882, 46.3690153],
              [-62.1307282, 46.3686086],
              [-62.1315715, 46.3682581],
              [-62.1323039, 46.368069],
              [-62.1334225, 46.3678508],
              [-62.1338499, 46.3677687],
              [-62.1343591, 46.3677085],
              [-62.1353243, 46.3677735],
              [-62.1363859, 46.3677639],
              [-62.1372965, 46.3677617],
              [-62.1383617, 46.3678689],
              [-62.1386155, 46.3678921],
              [-62.1388539, 46.3679429],
              [-62.1397369, 46.3679581],
              [-62.1406134, 46.3680835],
              [-62.1412242, 46.3680972],
              [-62.1422025, 46.3680837],
              [-62.1432042, 46.368026],
              [-62.144051, 46.36797],
              [-62.1449096, 46.367819],
              [-62.1459082, 46.3677275],
              [-62.146871, 46.3676876],
              [-62.1484141, 46.3674354],
              [-62.150373, 46.367158],
              [-62.1512274, 46.3670865],
              [-62.1526332, 46.3669818],
              [-62.1551044, 46.3665825],
              [-62.1570937, 46.3664584],
              [-62.1615085, 46.3647456],
              [-62.1631579, 46.3641324],
              [-62.1642787, 46.3636585],
              [-62.1667896, 46.3625851],
              [-62.1677996, 46.3619475],
              [-62.1685316, 46.3616314],
              [-62.1702851, 46.3605198],
              [-62.1708982, 46.3600241],
              [-62.1709042, 46.3599446],
              [-62.1710504, 46.3598383],
              [-62.1710305, 46.3596464],
              [-62.1710347, 46.359515],
              [-62.1711645, 46.3593205],
              [-62.1712563, 46.3591724],
              [-62.1722101, 46.3578284],
              [-62.1721635, 46.3573805],
              [-62.1717015, 46.3567182],
              [-62.1715142, 46.356532],
              [-62.1706702, 46.3559754],
              [-62.1705557, 46.3556644],
              [-62.1706109, 46.3551644],
              [-62.1704353, 46.3547476],
              [-62.1704365, 46.354656],
              [-62.1704733, 46.3539441],
              [-62.1707363, 46.3535871],
              [-62.171234, 46.3533223],
              [-62.1718885, 46.3531907],
              [-62.1734351, 46.3529799],
              [-62.174118, 46.3527776],
              [-62.1747041, 46.3523327],
              [-62.1751, 46.35212],
              [-62.1753425, 46.3518061],
              [-62.175433, 46.3513711],
              [-62.1757238, 46.3511784],
              [-62.1770164, 46.3512054],
              [-62.1783037, 46.3514554],
              [-62.1790026, 46.3516481],
              [-62.1793756, 46.3516616],
              [-62.1796298, 46.3514955],
              [-62.1798951, 46.3509553],
              [-62.1803067, 46.3506873],
              [-62.1803958, 46.3506507],
              [-62.1814675, 46.3504784],
              [-62.1819733, 46.3502577],
              [-62.1824132, 46.349919],
              [-62.1826106, 46.3495158],
              [-62.1826288, 46.3489706],
              [-62.1823536, 46.3480442],
              [-62.1823813, 46.34783],
              [-62.1824175, 46.3477317],
              [-62.1827973, 46.3475024],
              [-62.1835395, 46.3474258],
              [-62.1840886, 46.347621],
              [-62.184525, 46.3475571],
              [-62.1848756, 46.3475618],
              [-62.1851468, 46.3476274],
              [-62.1854758, 46.3477668],
              [-62.1860716, 46.3477247],
              [-62.1868835, 46.3475628],
              [-62.1872997, 46.3476137],
              [-62.1875583, 46.3476948],
              [-62.1880698, 46.3477014],
              [-62.1884021, 46.3478728],
              [-62.1890858, 46.3478856],
              [-62.1898663, 46.3481409],
              [-62.1904096, 46.3481088],
              [-62.1906116, 46.3480244],
              [-62.1911552, 46.348064],
              [-62.1913471, 46.3481905],
              [-62.1915217, 46.348392],
              [-62.1922043, 46.3484964],
              [-62.1925318, 46.3486556],
              [-62.1935773, 46.3486775],
              [-62.1938985, 46.3488445],
              [-62.1940605, 46.3490615],
              [-62.1944695, 46.3492835],
              [-62.19473, 46.3494164],
              [-62.1957839, 46.3501677],
              [-62.1961069, 46.3503865],
              [-62.1966736, 46.3505783],
              [-62.1969769, 46.3506769],
              [-62.1977806, 46.3507776],
              [-62.1984964, 46.3508234],
              [-62.2000783, 46.3506772],
              [-62.2009964, 46.3504035],
              [-62.2018498, 46.3502271],
              [-62.203546, 46.3503201],
              [-62.2041895, 46.3502556],
              [-62.2046925, 46.3501462],
              [-62.2064114, 46.3497061],
              [-62.2073832, 46.3493306],
              [-62.2082414, 46.3488595],
              [-62.2093357, 46.3481538],
              [-62.2093834, 46.3478248],
              [-62.2092947, 46.3476264],
              [-62.2092369, 46.3475526],
              [-62.2091978, 46.347077],
              [-62.209266, 46.3470118],
              [-62.209367, 46.3465094],
              [-62.2092768, 46.3463308],
              [-62.2094482, 46.3458868],
              [-62.209441, 46.3451375],
              [-62.2093024, 46.3448376],
              [-62.2093326, 46.344512],
              [-62.2095547, 46.3443128],
              [-62.2096549, 46.3442806],
              [-62.2101921, 46.3443278],
              [-62.2105658, 46.3444846],
              [-62.2115461, 46.3451454],
              [-62.2126247, 46.3451085],
              [-62.2133409, 46.3452258],
              [-62.2136155, 46.3453233],
              [-62.2138912, 46.3453293],
              [-62.213988, 46.345265],
              [-62.2142, 46.3452766],
              [-62.2144735, 46.3454657],
              [-62.2147138, 46.3454066],
              [-62.214937, 46.3454226],
              [-62.2159449, 46.3458691],
              [-62.2160884, 46.3458743],
              [-62.2162836, 46.3457259],
              [-62.2171551, 46.3456894],
              [-62.2177986, 46.3459317],
              [-62.2182907, 46.3458895],
              [-62.2192265, 46.3453773],
              [-62.2196774, 46.3453496],
              [-62.2222805, 46.3460843],
              [-62.2231616, 46.3460721],
              [-62.2239429, 46.345857],
              [-62.2246004, 46.345757],
              [-62.2255556, 46.3452932],
              [-62.2261144, 46.3452056],
              [-62.2273579, 46.3447321],
              [-62.2279122, 46.3447041],
              [-62.2295502, 46.3449579],
              [-62.229975, 46.3451245],
              [-62.2303079, 46.3453675],
              [-62.2310296, 46.3461821],
              [-62.2314228, 46.3463874],
              [-62.231608, 46.3464498],
              [-62.2319922, 46.3464675],
              [-62.2322455, 46.3464646],
              [-62.2324788, 46.3465765],
              [-62.2329569, 46.3465697],
              [-62.2336226, 46.3470556],
              [-62.2337217, 46.347115],
              [-62.2346109, 46.3473818],
              [-62.235137, 46.3474244],
              [-62.235728, 46.3473698],
              [-62.2364611, 46.3471051],
              [-62.2360376, 46.3469299],
              [-62.2362738, 46.3468893],
              [-62.2373462, 46.3464699],
              [-62.2378277, 46.3462381],
              [-62.2384106, 46.3459897],
              [-62.2387021, 46.3458264],
              [-62.238811, 46.3457375],
              [-62.2390188, 46.3454538],
              [-62.2392441, 46.3451798],
              [-62.2392387, 46.3448206],
              [-62.238983, 46.3446041],
              [-62.2388295, 46.34441],
              [-62.23882, 46.3441036],
              [-62.2390249, 46.3435999],
              [-62.2391943, 46.3432392],
              [-62.2390521, 46.342736],
              [-62.2390098, 46.3424936],
              [-62.2389434, 46.3423111],
              [-62.2390402, 46.3421098],
              [-62.2392422, 46.3419295],
              [-62.2398131, 46.3418542],
              [-62.2404358, 46.3420359],
              [-62.2410795, 46.3421338],
              [-62.2419048, 46.3423156],
              [-62.2421166, 46.3426116],
              [-62.2424175, 46.3428041],
              [-62.2429036, 46.3431312],
              [-62.243326, 46.3434009],
              [-62.2435739, 46.3435464],
              [-62.2437913, 46.3435446],
              [-62.2441133, 46.3436951],
              [-62.2442287, 46.3438081],
              [-62.2447729, 46.3439102],
              [-62.2454314, 46.3440433],
              [-62.2460277, 46.3442181],
              [-62.2466494, 46.344559],
              [-62.2471461, 46.3450372],
              [-62.2475182, 46.3456639],
              [-62.247829, 46.3462014],
              [-62.2482213, 46.3466111],
              [-62.2483693, 46.3467226],
              [-62.2486842, 46.3467719],
              [-62.2492598, 46.3467501],
              [-62.249993, 46.3467816],
              [-62.2516278, 46.3469536],
              [-62.2524467, 46.3471088],
              [-62.2533782, 46.347234],
              [-62.2548163, 46.3474407],
              [-62.2549383, 46.3475459],
              [-62.2549176, 46.3476297],
              [-62.2548692, 46.3476579],
              [-62.2547656, 46.3476461],
              [-62.2514304, 46.3472667],
              [-62.2514391, 46.3472231],
              [-62.2496222, 46.3469883],
              [-62.2496182, 46.3469633],
              [-62.2495093, 46.3469493],
              [-62.2489997, 46.3469414],
              [-62.2489006, 46.3471009],
              [-62.2474403, 46.3472768],
              [-62.2475564, 46.3476543],
              [-62.2485985, 46.3475239],
              [-62.2493383, 46.3475288],
              [-62.2502934, 46.3475316],
              [-62.2508241, 46.3475219],
              [-62.2512608, 46.3476262],
              [-62.2512695, 46.3478941],
              [-62.2501982, 46.3478946],
              [-62.2490067, 46.347907],
              [-62.2481048, 46.3479138],
              [-62.2481009, 46.348004],
              [-62.2481887, 46.348161],
              [-62.2480166, 46.3482124],
              [-62.2478504, 46.3482639],
              [-62.2478836, 46.3483098],
              [-62.2479079, 46.3483434],
              [-62.2482522, 46.348242],
              [-62.2486114, 46.3487702],
              [-62.2497329, 46.3482782],
              [-62.24995, 46.3485285],
              [-62.2489527, 46.3490161],
              [-62.2490986, 46.3491569],
              [-62.2490073, 46.3491972],
              [-62.2489188, 46.3492396],
              [-62.2489452, 46.349272],
              [-62.2489749, 46.3493084],
              [-62.2491557, 46.3492271],
              [-62.2498091, 46.3498887],
              [-62.2506042, 46.3495221],
              [-62.2506835, 46.3492221],
              [-62.2501916, 46.3487284],
              [-62.2502999, 46.3486789],
              [-62.2507997, 46.3491076],
              [-62.2508449, 46.3491444],
              [-62.2509085, 46.3491586],
              [-62.2509808, 46.3491519],
              [-62.251123, 46.3490841],
              [-62.2512356, 46.3490706],
              [-62.2513427, 46.3490806],
              [-62.2514864, 46.3490672],
              [-62.2517586, 46.3490418],
              [-62.251768, 46.3490854],
              [-62.2517838, 46.3491576],
              [-62.2518037, 46.3491646],
              [-62.2518135, 46.3492097],
              [-62.2518229, 46.349253],
              [-62.2516653, 46.3492803],
              [-62.2515386, 46.3495435],
              [-62.2524108, 46.3498984],
              [-62.2523576, 46.3499607],
              [-62.2514855, 46.3496058],
              [-62.2514085, 46.3497105],
              [-62.2513526, 46.3497878],
              [-62.251246, 46.3499046],
              [-62.251209, 46.3499621],
              [-62.2512046, 46.3500291],
              [-62.2511974, 46.3500953],
              [-62.2511518, 46.3501865],
              [-62.2510775, 46.3502807],
              [-62.2505521, 46.3505309],
              [-62.2504987, 46.350622],
              [-62.2504784, 46.3508697],
              [-62.2505384, 46.3512545],
              [-62.2506836, 46.3515545],
              [-62.2510183, 46.3519421],
              [-62.2518638, 46.3524263],
              [-62.2523407, 46.3525084],
              [-62.2526611, 46.3524649],
              [-62.2531271, 46.3524662],
              [-62.2536796, 46.3525633],
              [-62.2538701, 46.352699],
              [-62.2542428, 46.3527948],
              [-62.2545815, 46.3528225],
              [-62.2549498, 46.353205],
              [-62.2556866, 46.3537604],
              [-62.2559721, 46.3539756],
              [-62.2560626, 46.3542645],
              [-62.2567901, 46.3546391],
              [-62.2573752, 46.3549141],
              [-62.2578595, 46.3549283],
              [-62.2585934, 46.355218],
              [-62.2587644, 46.3554673],
              [-62.2589225, 46.3555291],
              [-62.2598486, 46.3554968],
              [-62.2610385, 46.355687],
              [-62.2611786, 46.3557683],
              [-62.2615045, 46.3558831],
              [-62.2616485, 46.3560436],
              [-62.2621601, 46.356179],
              [-62.2625903, 46.3564566],
              [-62.2633133, 46.3566183],
              [-62.264081, 46.3566149],
              [-62.2649277, 46.3565053],
              [-62.2654874, 46.3565403],
              [-62.266276, 46.3564418],
              [-62.2665432, 46.3563434],
              [-62.2669153, 46.3563428],
              [-62.2671199, 46.3562699],
              [-62.2675385, 46.3562908],
              [-62.2682924, 46.3561242],
              [-62.2696055, 46.3558503],
              [-62.2702523, 46.3556726],
              [-62.2711015, 46.3555227],
              [-62.271735, 46.3553531],
              [-62.2722922, 46.3551569],
              [-62.2735919, 46.3548218],
              [-62.2739879, 46.3548185],
              [-62.2748234, 46.3546326],
              [-62.2751239, 46.3546377],
              [-62.2755196, 46.3548224],
              [-62.276035, 46.3549424],
              [-62.2764438, 46.3549763],
              [-62.2766252, 46.3550768],
              [-62.2771068, 46.3552021],
              [-62.2774924, 46.3553365],
              [-62.2776962, 46.3553883],
              [-62.2777499, 46.3555401],
              [-62.2769939, 46.355855],
              [-62.2768958, 46.3560008],
              [-62.2767161, 46.3562218],
              [-62.2767251, 46.356447],
              [-62.2769645, 46.3565474],
              [-62.2769225, 46.3567728],
              [-62.2768124, 46.3569916],
              [-62.276204, 46.3569741],
              [-62.275589, 46.3567112],
              [-62.2747893, 46.3565267],
              [-62.2743382, 46.3565636],
              [-62.2738976, 46.3571152],
              [-62.2722995, 46.3576617],
              [-62.2718941, 46.3575903],
              [-62.2699195, 46.3576296],
              [-62.2697614, 46.3575881],
              [-62.2692988, 46.357775],
              [-62.2681076, 46.3576971],
              [-62.2659397, 46.3573711],
              [-62.2645496, 46.3575065],
              [-62.2642411, 46.357631],
              [-62.2638426, 46.3579217],
              [-62.2638226, 46.3582148],
              [-62.2635958, 46.3583007],
              [-62.2633711, 46.3590413],
              [-62.2633389, 46.3590312],
              [-62.2634162, 46.3592021],
              [-62.2630136, 46.3597403],
              [-62.262585, 46.360012],
              [-62.2618629, 46.3602951],
              [-62.2617178, 46.360263],
              [-62.2613863, 46.3603755],
              [-62.2607088, 46.3606495],
              [-62.2604488, 46.3609166],
              [-62.2599716, 46.361066],
              [-62.2596396, 46.361065],
              [-62.2592256, 46.3611602],
              [-62.2591294, 46.3613389],
              [-62.2592911, 46.3614856],
              [-62.2597726, 46.3615644],
              [-62.2606726, 46.3614472],
              [-62.261782, 46.3609338],
              [-62.2618718, 46.3609196],
              [-62.2619947, 46.3609329],
              [-62.2623624, 46.3610173],
              [-62.2627357, 46.361441],
              [-62.263227, 46.3620313],
              [-62.263254, 46.3621576],
              [-62.2632393, 46.3623477],
              [-62.2629061, 46.3628363],
              [-62.261639, 46.3638179],
              [-62.2611836, 46.364098],
              [-62.2608143, 46.3642154],
              [-62.2606425, 46.364281],
              [-62.2604123, 46.3644362],
              [-62.2602926, 46.3646551],
              [-62.260691, 46.3646373],
              [-62.260799, 46.3645774],
              [-62.2612199, 46.3645684],
              [-62.2616326, 46.364445],
              [-62.2620828, 46.3642009],
              [-62.2622555, 46.3640295],
              [-62.2624914, 46.3639669],
              [-62.2631369, 46.3640517],
              [-62.2632626, 46.3639881],
              [-62.2633115, 46.3638946],
              [-62.2632558, 46.3636399],
              [-62.2640351, 46.3629952],
              [-62.2640892, 46.3628797],
              [-62.2641765, 46.3627376],
              [-62.2642691, 46.3626848],
              [-62.2647471, 46.3626443],
              [-62.265327, 46.3627125],
              [-62.2653374, 46.3627978],
              [-62.2655889, 46.3628409],
              [-62.2656741, 46.3628139],
              [-62.2658155, 46.3628565],
              [-62.2662879, 46.3628637],
              [-62.2666761, 46.3626951],
              [-62.2667418, 46.362458],
              [-62.2670107, 46.3623586],
              [-62.2669879, 46.3621262],
              [-62.2669899, 46.3618054],
              [-62.2675054, 46.3614029],
              [-62.2680047, 46.3612036],
              [-62.2684947, 46.3610376],
              [-62.2688185, 46.3610929],
              [-62.2695453, 46.3615409],
              [-62.2705882, 46.3618367],
              [-62.2716879, 46.3625329],
              [-62.2726219, 46.3629802],
              [-62.273625, 46.3632706],
              [-62.2743688, 46.3631733],
              [-62.2745177, 46.3632622],
              [-62.2744894, 46.363568],
              [-62.274611, 46.3641062],
              [-62.2744782, 46.3642688],
              [-62.2741016, 46.3649289],
              [-62.2740704, 46.3650394],
              [-62.2740286, 46.3652172],
              [-62.2737579, 46.3662213],
              [-62.2739577, 46.3667902],
              [-62.2744068, 46.3671805],
              [-62.2752844, 46.3680044],
              [-62.2762146, 46.368583],
              [-62.2764978, 46.3689595],
              [-62.277591, 46.3698268],
              [-62.2785792, 46.3704792],
              [-62.2789743, 46.3708995],
              [-62.2791203, 46.3712632],
              [-62.2790775, 46.3715327],
              [-62.2789432, 46.3717152],
              [-62.2791193, 46.371825],
              [-62.2793733, 46.3718937],
              [-62.2796113, 46.3719459],
              [-62.2802848, 46.3722605],
              [-62.2819932, 46.3728266],
              [-62.2825864, 46.3730586],
              [-62.2844394, 46.3737732],
              [-62.2853032, 46.3743972],
              [-62.2856838, 46.374546],
              [-62.2863441, 46.375],
              [-62.2867525, 46.3752808],
              [-62.2871447, 46.3755058],
              [-62.2877598, 46.3756748],
              [-62.2882334, 46.3758906],
              [-62.2894476, 46.3764472],
              [-62.2901558, 46.3769183],
              [-62.2904187, 46.3773379],
              [-62.290469, 46.3779095],
              [-62.2903226, 46.3781793],
              [-62.289948, 46.3786563],
              [-62.2885798, 46.3795726],
              [-62.2881579, 46.3799084],
              [-62.2873779, 46.3803393],
              [-62.2870071, 46.3804498],
              [-62.286355, 46.3804707],
              [-62.2859783, 46.3804256],
              [-62.2853408, 46.3804829],
              [-62.2845942, 46.3806918],
              [-62.2842001, 46.3808852],
              [-62.2843441, 46.380962],
              [-62.2846726, 46.3809576],
              [-62.2850483, 46.3808592],
              [-62.2857472, 46.3806725],
              [-62.2860265, 46.3807102],
              [-62.2863088, 46.3809433],
              [-62.2867347, 46.3809463],
              [-62.2880107, 46.3809751],
              [-62.2885665, 46.3808551],
              [-62.2891781, 46.380522],
              [-62.2894667, 46.3802771],
              [-62.2900039, 46.380017],
              [-62.2903976, 46.3799869],
              [-62.2908833, 46.3801155],
              [-62.2918286, 46.3807304],
              [-62.2923864, 46.3808974],
              [-62.2926254, 46.381093],
              [-62.2929958, 46.3813809],
              [-62.2934597, 46.3815725],
              [-62.2936476, 46.3817584],
              [-62.2943335, 46.3822207],
              [-62.294615, 46.3825454],
              [-62.2947358, 46.3829401],
              [-62.2947317, 46.383705],
              [-62.2949197, 46.3838909],
              [-62.2950701, 46.3839599],
              [-62.2952467, 46.3839063],
              [-62.2953888, 46.3836961],
              [-62.2956861, 46.3835671],
              [-62.2963242, 46.3835814],
              [-62.2971637, 46.3834394],
              [-62.2977252, 46.3832399],
              [-62.2980523, 46.3832553],
              [-62.2983974, 46.3833391],
              [-62.2988176, 46.3836567],
              [-62.2991025, 46.3840133],
              [-62.299339, 46.3840853],
              [-62.300964, 46.3837703],
              [-62.3011408, 46.3839518],
              [-62.3012345, 46.3845607],
              [-62.3015417, 46.384691],
              [-62.3016167, 46.3846896],
              [-62.3016716, 46.384568],
              [-62.3020706, 46.3843867],
              [-62.3023376, 46.3840414],
              [-62.3022416, 46.383779],
              [-62.3019446, 46.3835097],
              [-62.3018559, 46.383383],
              [-62.3015657, 46.3831777],
              [-62.3005623, 46.3834188],
              [-62.3002546, 46.3832168],
              [-62.3001707, 46.3828672],
              [-62.3000363, 46.3828147],
              [-62.2995384, 46.3827734],
              [-62.2982953, 46.382451],
              [-62.2968664, 46.3824648],
              [-62.2966507, 46.3824214],
              [-62.2961479, 46.3821328],
              [-62.2959379, 46.3815397],
              [-62.2957202, 46.3812094],
              [-62.294766, 46.3804787],
              [-62.293809, 46.3797877],
              [-62.2922329, 46.378824],
              [-62.2922479, 46.378697],
              [-62.2929182, 46.3778758],
              [-62.2930441, 46.377414],
              [-62.2929407, 46.3767808],
              [-62.2922264, 46.3754489],
              [-62.2922416, 46.375],
              [-62.2922462, 46.3748638],
              [-62.2918377, 46.3741522],
              [-62.2915153, 46.3734437],
              [-62.2914203, 46.3730897],
              [-62.2907948, 46.3721195],
              [-62.2906943, 46.3716816],
              [-62.2909726, 46.3715758],
              [-62.29121, 46.3715563],
              [-62.2916493, 46.3712171],
              [-62.2917338, 46.3707698],
              [-62.2917867, 46.3703612],
              [-62.291918, 46.3702184],
              [-62.2918981, 46.3700982],
              [-62.2917545, 46.3700931],
              [-62.2914967, 46.3703909],
              [-62.2913145, 46.3709941],
              [-62.2906032, 46.3714314],
              [-62.2903702, 46.3716264],
              [-62.2901761, 46.3716834],
              [-62.2899177, 46.3716743],
              [-62.2896126, 46.3715959],
              [-62.2889858, 46.3711157],
              [-62.2880192, 46.3699303],
              [-62.2874308, 46.3690052],
              [-62.2868775, 46.3683084],
              [-62.2860332, 46.3677329],
              [-62.2855568, 46.3673217],
              [-62.2848017, 46.3669446],
              [-62.2841497, 46.3667304],
              [-62.2830585, 46.3663853],
              [-62.2824158, 46.3661236],
              [-62.2822417, 46.3660657],
              [-62.2819016, 46.365994],
              [-62.2812244, 46.3660459],
              [-62.2810512, 46.3658964],
              [-62.2810603, 46.3653787],
              [-62.2806866, 46.3648237],
              [-62.2797413, 46.3634318],
              [-62.2796882, 46.3631351],
              [-62.2795408, 46.3627912],
              [-62.2794328, 46.3626161],
              [-62.2794012, 46.3621847],
              [-62.2795195, 46.3619856],
              [-62.2796458, 46.3615956],
              [-62.2799703, 46.3610174],
              [-62.2805199, 46.3605068],
              [-62.2807622, 46.3602643],
              [-62.2808215, 46.3600831],
              [-62.2810725, 46.3599565],
              [-62.2814976, 46.3597934],
              [-62.2815609, 46.359454],
              [-62.2813901, 46.3591347],
              [-62.2807899, 46.3588149],
              [-62.2806128, 46.3583925],
              [-62.2804231, 46.35825],
              [-62.2802367, 46.3578442],
              [-62.2794588, 46.3572734],
              [-62.2784343, 46.3571227],
              [-62.2783489, 46.3570431],
              [-62.2783948, 46.3567418],
              [-62.2786857, 46.3567516],
              [-62.2790561, 46.3564591],
              [-62.2793348, 46.35619],
              [-62.2796154, 46.3552675],
              [-62.2794306, 46.3545718],
              [-62.2791003, 46.3538191],
              [-62.2790249, 46.3530435],
              [-62.2796931, 46.3517005],
              [-62.2797431, 46.3510966],
              [-62.2797222, 46.3508329],
              [-62.2795184, 46.3503955],
              [-62.278856, 46.3495435],
              [-62.2777598, 46.3486443],
              [-62.2772303, 46.3483348],
              [-62.275518, 46.3477566],
              [-62.2748406, 46.3472666],
              [-62.2741924, 46.3468493],
              [-62.2740451, 46.3467405],
              [-62.2738405, 46.3463946],
              [-62.2736066, 46.3452709],
              [-62.2735011, 46.3449843],
              [-62.2734316, 46.3448344],
              [-62.2731063, 46.344564],
              [-62.2725958, 46.3444663],
              [-62.272234, 46.3444575],
              [-62.270996, 46.3444736],
              [-62.2706682, 46.3443146],
              [-62.2703999, 46.3437394],
              [-62.2705097, 46.3434963],
              [-62.2711153, 46.3432428],
              [-62.2719501, 46.3429973],
              [-62.2734445, 46.3422333],
              [-62.2745352, 46.3418734],
              [-62.2756409, 46.3413864],
              [-62.2771103, 46.3409601],
              [-62.2775902, 46.3407699],
              [-62.2780828, 46.3405641],
              [-62.2796235, 46.3396343],
              [-62.2804413, 46.3389938],
              [-62.2810295, 46.3383452],
              [-62.2814262, 46.337642],
              [-62.2816017, 46.33721],
              [-62.2816809, 46.3366789],
              [-62.2816162, 46.3363062],
              [-62.2815224, 46.3360957],
              [-62.2811334, 46.335831],
              [-62.2810177, 46.3356835],
              [-62.281112, 46.3352606],
              [-62.281129, 46.3351855],
              [-62.280865, 46.3350208],
              [-62.2805091, 46.3349326],
              [-62.2803603, 46.3348437],
              [-62.2801498, 46.3345773],
              [-62.2799339, 46.3339921],
              [-62.2800501, 46.3335062],
              [-62.280472, 46.3332421],
              [-62.2809159, 46.3331502],
              [-62.2817331, 46.3331431],
              [-62.2831702, 46.3333888],
              [-62.2844526, 46.3333901],
              [-62.2849762, 46.3333089],
              [-62.2853977, 46.3334432],
              [-62.285719, 46.3333749],
              [-62.2860141, 46.333194],
              [-62.2862717, 46.3331313],
              [-62.2867865, 46.3331693],
              [-62.2871093, 46.3330811],
              [-62.2877321, 46.3330591],
              [-62.2881555, 46.3330103],
              [-62.2890271, 46.3330448],
              [-62.289348, 46.3331398],
              [-62.289877, 46.3331424],
              [-62.2904283, 46.3333888],
              [-62.2911755, 46.3333951],
              [-62.2916335, 46.3335028],
              [-62.2922393, 46.3335559],
              [-62.2933973, 46.3337638],
              [-62.2936643, 46.3338887],
              [-62.2943081, 46.3343654],
              [-62.2945552, 46.3346051],
              [-62.2950653, 46.334866],
              [-62.2956873, 46.3354057],
              [-62.2958028, 46.3361866],
              [-62.2962585, 46.3364057],
              [-62.2970205, 46.3369184],
              [-62.2976167, 46.3371823],
              [-62.2981292, 46.3373317],
              [-62.2984609, 46.3375943],
              [-62.298515, 46.3377994],
              [-62.298979, 46.3380626],
              [-62.2993349, 46.3381508],
              [-62.2999856, 46.3384564],
              [-62.3004149, 46.338563],
              [-62.3007088, 46.3388721],
              [-62.301345, 46.3393764],
              [-62.3016466, 46.3396578],
              [-62.301974, 46.33998],
              [-62.3019697, 46.3400396],
              [-62.3021632, 46.340146],
              [-62.3024705, 46.3401129],
              [-62.3029889, 46.3401828],
              [-62.3033878, 46.3404716],
              [-62.3037589, 46.3406678],
              [-62.3043619, 46.3407606],
              [-62.3048701, 46.3409696],
              [-62.305122, 46.3409864],
              [-62.3059015, 46.3410255],
              [-62.306328, 46.3411719],
              [-62.3065382, 46.3413665],
              [-62.3067211, 46.3415402],
              [-62.3067534, 46.3418082],
              [-62.3065402, 46.3422868],
              [-62.3067548, 46.3426568],
              [-62.3073247, 46.3432066],
              [-62.3079834, 46.3437196],
              [-62.3086104, 46.3440363],
              [-62.3096328, 46.3443747],
              [-62.3102418, 46.344623],
              [-62.3116282, 46.3448586],
              [-62.3118101, 46.3448888],
              [-62.3127125, 46.3453744],
              [-62.3130608, 46.3454901],
              [-62.3141518, 46.3458349],
              [-62.3150655, 46.3463248],
              [-62.3152569, 46.3465427],
              [-62.3152443, 46.3467932],
              [-62.3149188, 46.3471564],
              [-62.3148588, 46.3474292],
              [-62.3150277, 46.3476382],
              [-62.3155736, 46.3485975],
              [-62.3158083, 46.3488526],
              [-62.31667, 46.3494244],
              [-62.3172329, 46.3496751],
              [-62.3175204, 46.3497568],
              [-62.3180687, 46.3499711],
              [-62.3194345, 46.3501779],
              [-62.3207068, 46.350246],
              [-62.32171, 46.3501374],
              [-62.3235445, 46.3501493],
              [-62.3247408, 46.3500753],
              [-62.3261658, 46.3501009],
              [-62.3268475, 46.3500608],
              [-62.3275052, 46.3501951],
              [-62.327929, 46.3503811],
              [-62.3284736, 46.3504279],
              [-62.3288711, 46.3505414],
              [-62.329447, 46.3504736],
              [-62.3305319, 46.3504275],
              [-62.3315552, 46.350439],
              [-62.332897, 46.3504217],
              [-62.3332195, 46.3504966],
              [-62.3349195, 46.351416],
              [-62.3355235, 46.3514169],
              [-62.3357229, 46.3514437],
              [-62.3366477, 46.3522645],
              [-62.3367866, 46.3524924],
              [-62.3371238, 46.3526037],
              [-62.3373037, 46.352582],
              [-62.3377902, 46.3526188],
              [-62.337925, 46.3527429],
              [-62.3379331, 46.3529504],
              [-62.3380645, 46.3530426],
              [-62.3384002, 46.3531737],
              [-62.3386149, 46.3533086],
              [-62.3388972, 46.353143],
              [-62.3391989, 46.3531893],
              [-62.3393349, 46.3530586],
              [-62.3395381, 46.352954],
              [-62.339825, 46.3529639],
              [-62.3400201, 46.3530504],
              [-62.3403637, 46.3531538],
              [-62.3404569, 46.3530575],
              [-62.3405123, 46.3526092],
              [-62.340813, 46.3523487],
              [-62.3413332, 46.3522351],
              [-62.3419008, 46.3522627],
              [-62.3428473, 46.352622],
              [-62.3434632, 46.3526991],
              [-62.3448064, 46.3526617],
              [-62.3457775, 46.3525199],
              [-62.3460351, 46.3524571],
              [-62.3473998, 46.3521216],
              [-62.3481902, 46.3519297],
              [-62.3511207, 46.3515008],
              [-62.3518424, 46.3514658],
              [-62.352767, 46.3514897],
              [-62.3530253, 46.3514985],
              [-62.3532841, 46.3515792],
              [-62.3536596, 46.3518789],
              [-62.3539951, 46.3523366],
              [-62.3545029, 46.352637],
              [-62.3546163, 46.3526608],
              [-62.35499, 46.3527453],
              [-62.3554583, 46.3527136],
              [-62.3558938, 46.3525772],
              [-62.3565613, 46.3521738],
              [-62.3569869, 46.3521765],
              [-62.3573144, 46.3522634],
              [-62.3575991, 46.3523848],
              [-62.3583507, 46.3524942],
              [-62.3590069, 46.3528116],
              [-62.359452, 46.3528627],
              [-62.360039, 46.3525402],
              [-62.3608312, 46.3520017],
              [-62.3613144, 46.3516796],
              [-62.3617922, 46.3512737],
              [-62.3621115, 46.3511532],
              [-62.3627906, 46.3509892],
              [-62.3634952, 46.3511927],
              [-62.3637297, 46.3512127],
              [-62.3638569, 46.3513644],
              [-62.3637658, 46.3515127],
              [-62.3640959, 46.3517232],
              [-62.3641755, 46.3518972],
              [-62.3641488, 46.3521114],
              [-62.3642641, 46.3523504],
              [-62.3646675, 46.3527427],
              [-62.364646, 46.3532041],
              [-62.3648787, 46.3535707],
              [-62.3647817, 46.3539617],
              [-62.3649203, 46.3539545],
              [-62.3651761, 46.3536765],
              [-62.3651706, 46.3535926],
              [-62.3653596, 46.3535234],
              [-62.3655849, 46.3535909],
              [-62.365985, 46.3539512],
              [-62.3666001, 46.3541196],
              [-62.3671488, 46.3541703],
              [-62.3672691, 46.3542581],
              [-62.3671066, 46.3544397],
              [-62.3667991, 46.3546364],
              [-62.3667912, 46.3548273],
              [-62.3673586, 46.3551814],
              [-62.3678021, 46.3554157],
              [-62.3677957, 46.3555868],
              [-62.3676514, 46.3556735],
              [-62.3676702, 46.3558136],
              [-62.3678611, 46.3559596],
              [-62.3681817, 46.3559825],
              [-62.3684435, 46.3560233],
              [-62.3688139, 46.3559125],
              [-62.369066, 46.3557657],
              [-62.3691501, 46.3557168],
              [-62.3694867, 46.3557562],
              [-62.3698933, 46.3559454],
              [-62.3699877, 46.3559925],
              [-62.3704141, 46.3559035],
              [-62.3704631, 46.3558613],
              [-62.3705409, 46.3558202],
              [-62.3707741, 46.3556967],
              [-62.3711156, 46.3557482],
              [-62.3719141, 46.3560902],
              [-62.3726472, 46.3566212],
              [-62.3730081, 46.3570479],
              [-62.3732713, 46.3570688],
              [-62.3734506, 46.3569753],
              [-62.3733898, 46.3567781],
              [-62.3731346, 46.3564028],
              [-62.3718172, 46.3555929],
              [-62.3713827, 46.3553111],
              [-62.3710461, 46.3552717],
              [-62.3708877, 46.3553938],
              [-62.3706224, 46.3554843],
              [-62.3705216, 46.355445],
              [-62.370183, 46.3551904],
              [-62.3694797, 46.3549672],
              [-62.3691286, 46.3547281],
              [-62.3690147, 46.3544692],
              [-62.3690167, 46.353796],
              [-62.3686352, 46.353014],
              [-62.3685797, 46.3526655],
              [-62.3682846, 46.3522849],
              [-62.3682534, 46.351997],
              [-62.3682339, 46.3517852],
              [-62.3681416, 46.3516267],
              [-62.367878, 46.3513707],
              [-62.3675862, 46.3513487],
              [-62.3673102, 46.3515066],
              [-62.3671289, 46.3515482],
              [-62.366938, 46.3514022],
              [-62.3667659, 46.3505079],
              [-62.3665305, 46.3500178],
              [-62.3665089, 46.3499175],
              [-62.3662286, 46.3495733],
              [-62.3656536, 46.3492468],
              [-62.3647958, 46.3490142],
              [-62.3638635, 46.3490182],
              [-62.3629738, 46.349151],
              [-62.3626162, 46.349083],
              [-62.3609002, 46.3476577],
              [-62.3606037, 46.3474602],
              [-62.359347, 46.3466124],
              [-62.3590394, 46.3464106],
              [-62.3571481, 46.3453457],
              [-62.3562905, 46.3451131],
              [-62.353533, 46.3446598],
              [-62.352308, 46.3443348],
              [-62.3519723, 46.3442037],
              [-62.351603, 46.3440596],
              [-62.3503035, 46.3434093],
              [-62.3495072, 46.3428839],
              [-62.3487211, 46.3424545],
              [-62.3483237, 46.3423811],
              [-62.3476023, 46.342416],
              [-62.3466168, 46.3425214],
              [-62.3456321, 46.3425353],
              [-62.3452514, 46.3425501],
              [-62.3448853, 46.3426012],
              [-62.3446857, 46.3427377],
              [-62.3442853, 46.3428673],
              [-62.3435113, 46.3429123],
              [-62.3431905, 46.3430526],
              [-62.3429473, 46.3433152],
              [-62.3427688, 46.343317],
              [-62.3420133, 46.3431037],
              [-62.3416177, 46.342847],
              [-62.3411987, 46.3426732],
              [-62.3406453, 46.3426102],
              [-62.3400222, 46.3423975],
              [-62.33929, 46.3421013],
              [-62.3386809, 46.3418532],
              [-62.3377903, 46.3412806],
              [-62.3375179, 46.3409087],
              [-62.3375111, 46.3408447],
              [-62.3368874, 46.3399985],
              [-62.3368728, 46.3399621],
              [-62.3368771, 46.3395042],
              [-62.3367978, 46.3391668],
              [-62.3368202, 46.3387772],
              [-62.3370052, 46.3386043],
              [-62.3374484, 46.3384403],
              [-62.3380663, 46.3384059],
              [-62.3382642, 46.3384526],
              [-62.3388282, 46.3384482],
              [-62.339099, 46.3384416],
              [-62.3395988, 46.3383712],
              [-62.3399972, 46.3381898],
              [-62.3403179, 46.3380495],
              [-62.3410352, 46.3375125],
              [-62.3417546, 46.3370275],
              [-62.3426459, 46.3361499],
              [-62.3434953, 46.3352152],
              [-62.3436697, 46.3351097],
              [-62.3437546, 46.3349692],
              [-62.3446473, 46.3340718],
              [-62.3452024, 46.3333898],
              [-62.3453125, 46.3332183],
              [-62.3453391, 46.3331675],
              [-62.3464909, 46.331064],
              [-62.3469617, 46.3296342],
              [-62.3471436, 46.3291027],
              [-62.3493636, 46.3294182],
              [-62.3494184, 46.3292966],
              [-62.3496653, 46.3293011],
              [-62.3499542, 46.3293628],
              [-62.3500139, 46.3296517],
              [-62.349925, 46.3296885],
              [-62.3497112, 46.3300237],
              [-62.3495431, 46.3306832],
              [-62.3494928, 46.3311435],
              [-62.349375, 46.3313426],
              [-62.3493774, 46.3321912],
              [-62.3494477, 46.3324127],
              [-62.3497815, 46.3328903],
              [-62.3504601, 46.3334515],
              [-62.350879, 46.3336252],
              [-62.3514407, 46.3337322],
              [-62.3521509, 46.3336929],
              [-62.352633, 46.3336258],
              [-62.3540847, 46.3331101],
              [-62.35531, 46.3329451],
              [-62.3574682, 46.3325213],
              [-62.3577089, 46.3325335],
              [-62.3579446, 46.3325337],
              [-62.3599211, 46.3332031],
              [-62.3607819, 46.333631],
              [-62.3616785, 46.3339605],
              [-62.3625212, 46.33432],
              [-62.3634539, 46.3349496],
              [-62.3637665, 46.3357251],
              [-62.3640944, 46.3360471],
              [-62.3644055, 46.3362808],
              [-62.3655332, 46.33716],
              [-62.3667031, 46.3372081],
              [-62.3679239, 46.3374291],
              [-62.3684596, 46.3376586],
              [-62.3687323, 46.3378671],
              [-62.3695796, 46.3387287],
              [-62.3714079, 46.3405958],
              [-62.3716239, 46.3408741],
              [-62.3724423, 46.3413363],
              [-62.3738232, 46.3418137],
              [-62.374443, 46.3421576],
              [-62.3749121, 46.3425959],
              [-62.3750001, 46.3426716],
              [-62.3750856, 46.3427452],
              [-62.3751884, 46.3428364],
              [-62.3757663, 46.343123],
              [-62.3761301, 46.3431832],
              [-62.3772051, 46.3431124],
              [-62.3795689, 46.343197],
              [-62.380627, 46.3432012],
              [-62.3812911, 46.3433274],
              [-62.3818248, 46.3436683],
              [-62.3819226, 46.3439106],
              [-62.3819061, 46.3445475],
              [-62.3820823, 46.3448204],
              [-62.3826161, 46.3451612],
              [-62.3834984, 46.3454542],
              [-62.384147, 46.345799],
              [-62.3846941, 46.3460327],
              [-62.3848649, 46.3460585],
              [-62.384963, 46.3458108],
              [-62.3851611, 46.3456941],
              [-62.3856811, 46.3457437],
              [-62.3862303, 46.3460293],
              [-62.3868825, 46.3462427],
              [-62.3876796, 46.3462777],
              [-62.3884117, 46.3461752],
              [-62.3892614, 46.3458734],
              [-62.3895687, 46.34584],
              [-62.390048, 46.3459758],
              [-62.3904272, 46.3461441],
              [-62.3916797, 46.3468161],
              [-62.3920555, 46.3469523],
              [-62.392796, 46.3468938],
              [-62.3933936, 46.3470655],
              [-62.393775, 46.3471223],
              [-62.3940515, 46.347036],
              [-62.394617, 46.3470114],
              [-62.3947401, 46.3470594],
              [-62.39523, 46.3471278],
              [-62.3955904, 46.3473193],
              [-62.3957276, 46.3473319],
              [-62.395883, 46.3472496],
              [-62.3962441, 46.3471861],
              [-62.3967333, 46.3473461],
              [-62.3971153, 46.3476379],
              [-62.3974154, 46.3481939],
              [-62.397689, 46.3483107],
              [-62.3978479, 46.3482603],
              [-62.3979082, 46.3480592],
              [-62.3977969, 46.347924],
              [-62.3976508, 46.3474689],
              [-62.3967278, 46.3469356],
              [-62.3963233, 46.3467984],
              [-62.3956255, 46.3468225],
              [-62.3953442, 46.3467333],
              [-62.3944225, 46.3466702],
              [-62.3943624, 46.3465446],
              [-62.3945367, 46.346439],
              [-62.3951449, 46.34638],
              [-62.3967056, 46.3461102],
              [-62.3977344, 46.3458782],
              [-62.3983133, 46.3455831],
              [-62.3986318, 46.3452274],
              [-62.39886, 46.3450918],
              [-62.3995584, 46.3451393],
              [-62.3998257, 46.3451006],
              [-62.400711, 46.3453536],
              [-62.40152, 46.3453014],
              [-62.402315, 46.3454478],
              [-62.4026383, 46.3455942],
              [-62.4029175, 46.3457949],
              [-62.4032583, 46.3459379],
              [-62.4037041, 46.3460605],
              [-62.4045369, 46.345997],
              [-62.4048981, 46.3460969],
              [-62.40553, 46.3465166],
              [-62.4070892, 46.3469199],
              [-62.4073601, 46.3465865],
              [-62.4074952, 46.3465472],
              [-62.4082188, 46.3465637],
              [-62.4089698, 46.3464377],
              [-62.4096682, 46.3464852],
              [-62.4099314, 46.346506],
              [-62.4103261, 46.346619],
              [-62.410823, 46.3469146],
              [-62.4117958, 46.3471506],
              [-62.4122781, 46.3474099],
              [-62.4127925, 46.3478654],
              [-62.4137137, 46.349],
              [-62.4141883, 46.3494502],
              [-62.4159213, 46.3498393],
              [-62.4163209, 46.349801],
              [-62.4172588, 46.349717],
              [-62.4178305, 46.3493578],
              [-62.4181602, 46.3493331],
              [-62.41869, 46.3494067],
              [-62.4191561, 46.3493228],
              [-62.4194676, 46.349393],
              [-62.4200122, 46.3496663],
              [-62.4203923, 46.3497429],
              [-62.4212231, 46.3497907],
              [-62.4215016, 46.3499196],
              [-62.421629, 46.3500713],
              [-62.4216347, 46.3503185],
              [-62.4217321, 46.3506524],
              [-62.4218501, 46.3520865],
              [-62.4217822, 46.352152],
              [-62.4218151, 46.3522566],
              [-62.4217242, 46.352405],
              [-62.4215611, 46.352515],
              [-62.4211194, 46.3524962],
              [-62.4210767, 46.3525306],
              [-62.4209578, 46.3527497],
              [-62.421091, 46.3534752],
              [-62.4213444, 46.3536351],
              [-62.4215838, 46.3536671],
              [-62.4219723, 46.3536244],
              [-62.4224818, 46.3534144],
              [-62.4230971, 46.3534192],
              [-62.4241765, 46.3536148],
              [-62.4246973, 46.3535726],
              [-62.4261218, 46.3538515],
              [-62.4264844, 46.3539314],
              [-62.4274462, 46.3539995],
              [-62.4276716, 46.3539035],
              [-62.4278352, 46.3535385],
              [-62.4285181, 46.3529878],
              [-62.4287378, 46.3526446],
              [-62.4286155, 46.3515968],
              [-62.4288807, 46.3515061],
              [-62.4292336, 46.3517251],
              [-62.4300284, 46.3524529],
              [-62.4301124, 46.3525672],
              [-62.4302622, 46.3525643],
              [-62.4304876, 46.3526316],
              [-62.4310826, 46.3526794],
              [-62.431434, 46.352755],
              [-62.4319282, 46.3527636],
              [-62.4326085, 46.3525792],
              [-62.432913, 46.3525854],
              [-62.4337196, 46.3530626],
              [-62.4337274, 46.3531983],
              [-62.4334383, 46.3533002],
              [-62.4331423, 46.3535014],
              [-62.4326392, 46.3537037],
              [-62.432577, 46.353853],
              [-62.4326813, 46.3539242],
              [-62.433121, 46.3540544],
              [-62.4334928, 46.3542501],
              [-62.4335432, 46.3543514],
              [-62.433605, 46.3547837],
              [-62.4340511, 46.3550696],
              [-62.4340379, 46.3551767],
              [-62.4343103, 46.3553133],
              [-62.4345, 46.355479],
              [-62.4344275, 46.355859],
              [-62.4344758, 46.3559084],
              [-62.4347047, 46.3560077],
              [-62.4348209, 46.3559917],
              [-62.4352975, 46.355677],
              [-62.4355435, 46.3551196],
              [-62.4361348, 46.3548087],
              [-62.4363384, 46.3546124],
              [-62.4366219, 46.35459],
              [-62.4368319, 46.3547125],
              [-62.4369729, 46.3550838],
              [-62.4372543, 46.3551729],
              [-62.4374951, 46.3551849],
              [-62.4377747, 46.3553855],
              [-62.4379287, 46.3556496],
              [-62.438288, 46.3558608],
              [-62.4391947, 46.3561421],
              [-62.4401944, 46.3563269],
              [-62.4407985, 46.3563272],
              [-62.4412968, 46.3561128],
              [-62.441415, 46.3559852],
              [-62.4419278, 46.3556439],
              [-62.4423562, 46.3556064],
              [-62.4428274, 46.3556978],
              [-62.4431817, 46.3558969],
              [-62.4433177, 46.3560926],
              [-62.443402, 46.3565337],
              [-62.4437165, 46.3567274],
              [-62.4441268, 46.3567849],
              [-62.4441771, 46.3567229],
              [-62.4437687, 46.3563905],
              [-62.4436339, 46.3558482],
              [-62.4434238, 46.3557257],
              [-62.4425969, 46.3554551],
              [-62.4419304, 46.3552774],
              [-62.4416636, 46.3552247],
              [-62.4409084, 46.3552473],
              [-62.4405429, 46.3552072],
              [-62.4401214, 46.3549819],
              [-62.4398762, 46.3547028],
              [-62.4394588, 46.354418],
              [-62.439166, 46.3541612],
              [-62.4387961, 46.3536907],
              [-62.4375551, 46.3526772],
              [-62.4370118, 46.3523841],
              [-62.4362942, 46.3521968],
              [-62.4360008, 46.3520316],
              [-62.4356827, 46.3514791],
              [-62.434519, 46.3508426],
              [-62.4339092, 46.3504318],
              [-62.4332463, 46.3502861],
              [-62.4329935, 46.3501979],
              [-62.4324594, 46.3498574],
              [-62.4317972, 46.34962],
              [-62.431403, 46.3494156],
              [-62.4312085, 46.3494011],
              [-62.4306137, 46.3496799],
              [-62.4302637, 46.3495846],
              [-62.4294767, 46.3489925],
              [-62.4286374, 46.3487372],
              [-62.4283511, 46.3484727],
              [-62.4282389, 46.3481024],
              [-62.4283844, 46.3478324],
              [-62.4296341, 46.347237],
              [-62.4298418, 46.346981],
              [-62.430059, 46.346096],
              [-62.4304808, 46.3455764],
              [-62.4310033, 46.3450959],
              [-62.4313686, 46.3449727],
              [-62.4325254, 46.3449637],
              [-62.4327654, 46.3449041],
              [-62.4341094, 46.3441922],
              [-62.4340736, 46.3441273],
              [-62.4340288, 46.3439465],
              [-62.433909, 46.3437672],
              [-62.433785, 46.3436476],
              [-62.4337107, 46.3433941],
              [-62.4338625, 46.3432797],
              [-62.4344384, 46.3431874],
              [-62.434896, 46.3430594],
              [-62.4349904, 46.342943],
              [-62.4349924, 46.3428316],
              [-62.4347573, 46.34274],
              [-62.4344634, 46.3428298],
              [-62.4341611, 46.3428754],
              [-62.4335761, 46.3428518],
              [-62.4332926, 46.3427109],
              [-62.4329871, 46.3419796],
              [-62.4322281, 46.3413168],
              [-62.43213, 46.3409112],
              [-62.4319044, 46.3405172],
              [-62.4316146, 46.3402207],
              [-62.4313682, 46.3401248],
              [-62.4307496, 46.3400881],
              [-62.4303087, 46.3399777],
              [-62.4297977, 46.3395542],
              [-62.4294541, 46.3394511],
              [-62.4292706, 46.3392776],
              [-62.4291459, 46.3389229],
              [-62.4288219, 46.3387049],
              [-62.4286839, 46.3384573],
              [-62.4285117, 46.3382882],
              [-62.4284585, 46.3382266],
              [-62.4281779, 46.3382092],
              [-62.4279841, 46.3382665],
              [-62.4277833, 46.3382597],
              [-62.4273668, 46.3380466],
              [-62.4270723, 46.3380645],
              [-62.4269869, 46.3381334],
              [-62.4274748, 46.3384764],
              [-62.4280675, 46.3386357],
              [-62.4281634, 46.3386629],
              [-62.4283951, 46.3388857],
              [-62.4285227, 46.3393641],
              [-62.4288888, 46.3398026],
              [-62.4289323, 46.3400032],
              [-62.4292466, 46.340197],
              [-62.4298219, 46.3403597],
              [-62.4305434, 46.3404875],
              [-62.4306855, 46.3406755],
              [-62.4306276, 46.3409285],
              [-62.4310183, 46.3414276],
              [-62.43104, 46.3415279],
              [-62.4315259, 46.3419824],
              [-62.4321146, 46.3423646],
              [-62.4323078, 46.342399],
              [-62.4324492, 46.3425153],
              [-62.4323528, 46.3429064],
              [-62.4325195, 46.343155],
              [-62.4331237, 46.3436453],
              [-62.4332771, 46.3438377],
              [-62.4333045, 46.3440218],
              [-62.4333221, 46.3441818],
              [-62.4331885, 46.3443645],
              [-62.4328156, 46.3445153],
              [-62.4318848, 46.3445001],
              [-62.4311739, 46.3446316],
              [-62.4307394, 46.3448401],
              [-62.4300272, 46.3454815],
              [-62.4292403, 46.3462876],
              [-62.4291236, 46.3467219],
              [-62.4289985, 46.3469488],
              [-62.4284212, 46.3472242],
              [-62.4282708, 46.3473187],
              [-62.4277622, 46.3479271],
              [-62.4270933, 46.3482791],
              [-62.4268051, 46.348616],
              [-62.4261543, 46.3490363],
              [-62.4260395, 46.3490324],
              [-62.4257583, 46.34927],
              [-62.4252454, 46.3499379],
              [-62.4250159, 46.3500936],
              [-62.4239835, 46.3501306],
              [-62.4236944, 46.3500691],
              [-62.4228741, 46.3496272],
              [-62.4228691, 46.3494517],
              [-62.4233177, 46.3490445],
              [-62.4232931, 46.348984],
              [-62.4232686, 46.3489234],
              [-62.4225624, 46.3489036],
              [-62.4222054, 46.3487442],
              [-62.421129, 46.3486721],
              [-62.4206831, 46.3485496],
              [-62.4200651, 46.3485846],
              [-62.4199629, 46.3485652],
              [-62.419426, 46.3482643],
              [-62.4190635, 46.3481843],
              [-62.4187024, 46.3482479],
              [-62.4185057, 46.3483448],
              [-62.4178632, 46.3483192],
              [-62.4166329, 46.3487996],
              [-62.4161521, 46.3488472],
              [-62.4153794, 46.3487096],
              [-62.4147243, 46.3485361],
              [-62.4137121, 46.3477132],
              [-62.4129079, 46.3467977],
              [-62.4123242, 46.3464275],
              [-62.4108825, 46.3459884],
              [-62.4082657, 46.345653],
              [-62.4076639, 46.3457044],
              [-62.4070452, 46.3456676],
              [-62.4062607, 46.3452905],
              [-62.405507, 46.3451296],
              [-62.4047757, 46.344814],
              [-62.4039226, 46.3447573],
              [-62.4035594, 46.3446055],
              [-62.402938, 46.3444451],
              [-62.401742, 46.3443568],
              [-62.4015657, 46.3442473],
              [-62.4015447, 46.343892],
              [-62.4013719, 46.3438144],
              [-62.4014173, 46.3440669],
              [-62.4009421, 46.3443616],
              [-62.4004452, 46.3445559],
              [-62.3991071, 46.3446062],
              [-62.3981813, 46.3446027],
              [-62.3978033, 46.3447412],
              [-62.397293, 46.3452059],
              [-62.3967106, 46.3456324],
              [-62.3962151, 46.3458068],
              [-62.3959044, 46.3458082],
              [-62.3947867, 46.3459137],
              [-62.3935289, 46.3463729],
              [-62.3930593, 46.3464247],
              [-62.3926016, 46.3463893],
              [-62.3918025, 46.346139],
              [-62.3903437, 46.345213],
              [-62.3900575, 46.3451117],
              [-62.3896761, 46.3450549],
              [-62.3890532, 46.3450776],
              [-62.3876716, 46.345417],
              [-62.3868878, 46.3454381],
              [-62.3860893, 46.3452596],
              [-62.3853386, 46.3448954],
              [-62.3846627, 46.3446931],
              [-62.3835423, 46.3446749],
              [-62.3832932, 46.3446186],
              [-62.3828217, 46.3442918],
              [-62.3826791, 46.344032],
              [-62.3825865, 46.3431485],
              [-62.3824649, 46.3429173],
              [-62.3820628, 46.3425052],
              [-62.3815171, 46.3422515],
              [-62.3806963, 46.3420642],
              [-62.3799455, 46.3420267],
              [-62.3790455, 46.3420637],
              [-62.3778781, 46.3423027],
              [-62.3772378, 46.3423287],
              [-62.3766159, 46.3420963],
              [-62.3759085, 46.3419327],
              [-62.3755119, 46.3417678],
              [-62.3754735, 46.3415793],
              [-62.3753204, 46.3413868],
              [-62.3750001, 46.3411688],
              [-62.3749693, 46.3411478],
              [-62.3748903, 46.3410455],
              [-62.374951, 46.3403544],
              [-62.3748257, 46.3402545],
              [-62.374515, 46.3402558],
              [-62.374135, 46.3405058],
              [-62.373769, 46.340557],
              [-62.3733695, 46.3404318],
              [-62.3724725, 46.3402657],
              [-62.3721263, 46.3400388],
              [-62.3717602, 46.339365],
              [-62.3710971, 46.3389838],
              [-62.3705484, 46.3383714],
              [-62.3701402, 46.3378037],
              [-62.3695095, 46.3372922],
              [-62.3669308, 46.3361124],
              [-62.3668106, 46.3360246],
              [-62.3663134, 46.335657],
              [-62.3648704, 46.3338787],
              [-62.3640055, 46.3326221],
              [-62.3635163, 46.3320636],
              [-62.3631122, 46.3317629],
              [-62.3621996, 46.3314169],
              [-62.3616529, 46.3310197],
              [-62.3604247, 46.3301012],
              [-62.3594487, 46.3295977],
              [-62.3581423, 46.3292102],
              [-62.3567777, 46.3289124],
              [-62.3561164, 46.3285112],
              [-62.3556093, 46.3278843],
              [-62.3549028, 46.3272306],
              [-62.3546498, 46.3270706],
              [-62.3539523, 46.3270944],
              [-62.3535408, 46.3272196],
              [-62.3512626, 46.3281172],
              [-62.3510797, 46.328342],
              [-62.3510087, 46.3286104],
              [-62.3503952, 46.3285853],
              [-62.3498265, 46.3285777],
              [-62.3497681, 46.3288306],
              [-62.3498926, 46.3288588],
              [-62.3498952, 46.3289824],
              [-62.349395, 46.3289811],
              [-62.3492506, 46.3286694],
              [-62.348683, 46.3278451],
              [-62.3487036, 46.3276387],
              [-62.3483387, 46.3274349],
              [-62.3476772, 46.327197],
              [-62.3475129, 46.3271635],
              [-62.3459693, 46.3269589],
              [-62.3451886, 46.3269399],
              [-62.3447222, 46.3267884],
              [-62.344311, 46.3267503],
              [-62.343896, 46.3268436],
              [-62.3436072, 46.3267818],
              [-62.3428525, 46.3262418],
              [-62.3422407, 46.3260334],
              [-62.3418996, 46.3258185],
              [-62.3418706, 46.3255825],
              [-62.3421253, 46.3245995],
              [-62.3423495, 46.3243602],
              [-62.342743, 46.3241667],
              [-62.3444779, 46.3240274],
              [-62.3454197, 46.3238846],
              [-62.3460268, 46.3239175],
              [-62.3469849, 46.3240262],
              [-62.347494, 46.3241433],
              [-62.3480865, 46.3241399],
              [-62.348475, 46.3239342],
              [-62.3487209, 46.3236319],
              [-62.3496508, 46.3218912],
              [-62.3504463, 46.3206599],
              [-62.3513324, 46.318647],
              [-62.3522254, 46.3170962],
              [-62.352445, 46.3165183],
              [-62.352996, 46.3155693],
              [-62.3533657, 46.3152235],
              [-62.3538059, 46.3149359],
              [-62.3542724, 46.3149241],
              [-62.3544422, 46.3150414],
              [-62.3544133, 46.3152038],
              [-62.3537494, 46.3165273],
              [-62.3534126, 46.3173761],
              [-62.3530453, 46.3180089],
              [-62.3528092, 46.3183353],
              [-62.3526402, 46.318688],
              [-62.3524956, 46.319663],
              [-62.3522257, 46.3201396],
              [-62.3516822, 46.3206626],
              [-62.3517193, 46.3207077],
              [-62.3516773, 46.3212121],
              [-62.351096, 46.322905],
              [-62.3511356, 46.3230736],
              [-62.3515031, 46.323401],
              [-62.3516727, 46.3236818],
              [-62.3517793, 46.32404],
              [-62.3520386, 46.3241922],
              [-62.3521246, 46.3241952],
              [-62.3523144, 46.3240345],
              [-62.3522239, 46.3236928],
              [-62.3519607, 46.3232733],
              [-62.3518668, 46.3228996],
              [-62.3521548, 46.3219296],
              [-62.3525179, 46.3213567],
              [-62.3527146, 46.3212598],
              [-62.3528577, 46.321428],
              [-62.3528585, 46.3218981],
              [-62.3529116, 46.3219597],
              [-62.3530493, 46.3220441],
              [-62.3532942, 46.3219967],
              [-62.353942, 46.3213817],
              [-62.3541598, 46.3211501],
              [-62.3541134, 46.3207542],
              [-62.3539598, 46.32049],
              [-62.3539867, 46.3202759],
              [-62.3542198, 46.3201525],
              [-62.3543121, 46.3201477],
              [-62.3543786, 46.3201022],
              [-62.3550648, 46.320074],
              [-62.3552116, 46.3201109],
              [-62.3556062, 46.3200607],
              [-62.3573293, 46.3196818],
              [-62.3576561, 46.3195337],
              [-62.3578121, 46.3195231],
              [-62.3582514, 46.3194904],
              [-62.359182, 46.3191798],
              [-62.3598669, 46.3190081],
              [-62.3607804, 46.3189359],
              [-62.36167, 46.3190382],
              [-62.361986, 46.3191207],
              [-62.3627265, 46.3192975],
              [-62.3640552, 46.3195302],
              [-62.3645532, 46.3194796],
              [-62.365255, 46.3192328],
              [-62.3657236, 46.3192728],
              [-62.3663291, 46.3194887],
              [-62.3665634, 46.3195087],
              [-62.3666565, 46.3194123],
              [-62.3662846, 46.3191446],
              [-62.3663834, 46.3189687],
              [-62.3664263, 46.318771],
              [-62.3662867, 46.3184715],
              [-62.3664827, 46.3183029],
              [-62.366694, 46.3182425],
              [-62.3669765, 46.3183119],
              [-62.3673218, 46.3184671],
              [-62.3676513, 46.3184426],
              [-62.3678814, 46.3183588],
              [-62.3675682, 46.3182366],
              [-62.3674732, 46.3181178],
              [-62.3676783, 46.3179017],
              [-62.3676281, 46.3178004],
              [-62.3670078, 46.3177115],
              [-62.3660809, 46.3171658],
              [-62.3654266, 46.3168287],
              [-62.3647358, 46.3166815],
              [-62.3640757, 46.3165872],
              [-62.3627115, 46.3162895],
              [-62.3612609, 46.3157537],
              [-62.3605556, 46.3154069],
              [-62.3601515, 46.3152695],
              [-62.3582327, 46.3138055],
              [-62.3571189, 46.3131458],
              [-62.3565107, 46.3129696],
              [-62.354303, 46.3128937],
              [-62.3542337, 46.3129789],
              [-62.3546641, 46.3137904],
              [-62.3548304, 46.3144374],
              [-62.3548231, 46.3147],
              [-62.3547566, 46.3147455],
              [-62.3546419, 46.3147416],
              [-62.354345, 46.3144725],
              [-62.3539685, 46.313631],
              [-62.3538148, 46.3129685],
              [-62.3535179, 46.3126994],
              [-62.3533691, 46.3126106],
              [-62.3527701, 46.3125],
              [-62.3525748, 46.3124639],
              [-62.3512383, 46.3120235],
              [-62.3505657, 46.3119446],
              [-62.3498333, 46.3115768],
              [-62.3487189, 46.3108453],
              [-62.3485591, 46.3105889],
              [-62.3483666, 46.3102277],
              [-62.3478888, 46.3098368],
              [-62.3474226, 46.3096853],
              [-62.3468265, 46.3094218],
              [-62.3464692, 46.3089554],
              [-62.3460081, 46.3086527],
              [-62.3456193, 46.3086234],
              [-62.3450666, 46.3083972],
              [-62.3445359, 46.308343],
              [-62.3441007, 46.308081],
              [-62.3439888, 46.3080373],
              [-62.3435367, 46.3078504],
              [-62.3407249, 46.3074666],
              [-62.3403173, 46.3074604],
              [-62.3402215, 46.3074332],
              [-62.3394496, 46.3074583],
              [-62.3389224, 46.3074361],
              [-62.3377343, 46.3070127],
              [-62.3367453, 46.3067793],
              [-62.3358217, 46.3067553],
              [-62.3356987, 46.3067072],
              [-62.3348737, 46.3066707],
              [-62.3341229, 46.3065611],
              [-62.3338938, 46.3063898],
              [-62.3337453, 46.3061377],
              [-62.3327652, 46.3052235],
              [-62.3324241, 46.3051719],
              [-62.3322355, 46.3050777],
              [-62.3322653, 46.3048238],
              [-62.332107, 46.3045475],
              [-62.3321179, 46.3043168],
              [-62.332281, 46.3042069],
              [-62.3326448, 46.304104],
              [-62.3332273, 46.3040764],
              [-62.3349285, 46.3043942],
              [-62.3351753, 46.3043988],
              [-62.3355655, 46.3044083],
              [-62.3366206, 46.3043612],
              [-62.3374643, 46.3045377],
              [-62.3381347, 46.3045649],
              [-62.3385747, 46.3044407],
              [-62.3388929, 46.3044119],
              [-62.3392234, 46.3045308],
              [-62.3397234, 46.3045322],
              [-62.3403159, 46.3047638],
              [-62.3406851, 46.3047447],
              [-62.3411902, 46.3045948],
              [-62.3415978, 46.3046009],
              [-62.3419375, 46.3046724],
              [-62.3422668, 46.3046479],
              [-62.343014, 46.3043272],
              [-62.3438202, 46.3038253],
              [-62.3443259, 46.3037471],
              [-62.3448229, 46.3037882],
              [-62.3453096, 46.3037333],
              [-62.3459938, 46.30349],
              [-62.346811, 46.3025941],
              [-62.3471958, 46.3023565],
              [-62.3476722, 46.3022056],
              [-62.3484896, 46.3021063],
              [-62.3494579, 46.3021477],
              [-62.3506691, 46.3019265],
              [-62.3517366, 46.302027],
              [-62.3529086, 46.3018722],
              [-62.3533944, 46.3019088],
              [-62.3539242, 46.3020545],
              [-62.3545261, 46.3020752],
              [-62.3560553, 46.30255],
              [-62.357008, 46.3028098],
              [-62.3572818, 46.3029984],
              [-62.3575284, 46.3031662],
              [-62.357799, 46.3037212],
              [-62.3581365, 46.3039041],
              [-62.3587615, 46.3040052],
              [-62.3594326, 46.304104],
              [-62.3599898, 46.3041072],
              [-62.3622025, 46.3037052],
              [-62.3632114, 46.3036601],
              [-62.3644634, 46.3039142],
              [-62.3654423, 46.303888],
              [-62.3658745, 46.3037913],
              [-62.3676594, 46.3030996],
              [-62.3682743, 46.3027782],
              [-62.3687017, 46.302506],
              [-62.3690276, 46.3024495],
              [-62.3691415, 46.302545],
              [-62.3692771, 46.3033024],
              [-62.3694258, 46.3035545],
              [-62.369975, 46.3039118],
              [-62.3705258, 46.304086],
              [-62.3717032, 46.3041781],
              [-62.371915, 46.3041893],
              [-62.3734192, 46.3039699],
              [-62.3736975, 46.3039356],
              [-62.3740975, 46.3038059],
              [-62.3750001, 46.3032508],
              [-62.3752755, 46.3030814],
              [-62.3756282, 46.3026474],
              [-62.3759157, 46.3024023],
              [-62.3760877, 46.3024082],
              [-62.3762547, 46.3025652],
              [-62.3762403, 46.3030905],
              [-62.376011, 46.3039709],
              [-62.3761591, 46.3041512],
              [-62.37648, 46.3042458],
              [-62.3765819, 46.3044285],
              [-62.3766201, 46.3047803],
              [-62.3767947, 46.3050731],
              [-62.3767442, 46.3052984],
              [-62.3765546, 46.3054592],
              [-62.3762021, 46.3055666],
              [-62.3761965, 46.3056461],
              [-62.3764775, 46.3057354],
              [-62.376871, 46.30594],
              [-62.3781024, 46.3058387],
              [-62.3785742, 46.3060738],
              [-62.3790272, 46.306169],
              [-62.3817681, 46.3062585],
              [-62.3822414, 46.3063105],
              [-62.3827952, 46.3062816],
              [-62.3833056, 46.3063786],
              [-62.3838124, 46.306607],
              [-62.3842375, 46.3066095],
              [-62.3848487, 46.3064193],
              [-62.3852053, 46.3064155],
              [-62.3862707, 46.3067903],
              [-62.3868216, 46.3071277],
              [-62.3871899, 46.3075266],
              [-62.3883539, 46.3078889],
              [-62.3890196, 46.3079035],
              [-62.3897545, 46.307833],
              [-62.3904635, 46.3078849],
              [-62.3912152, 46.3080658],
              [-62.3923708, 46.3085472],
              [-62.3929253, 46.30859],
              [-62.3933582, 46.3085649],
              [-62.3936777, 46.308516],
              [-62.3943322, 46.3086896],
              [-62.3948405, 46.308898],
              [-62.3960195, 46.3088066],
              [-62.396516, 46.3086123],
              [-62.3968972, 46.3081792],
              [-62.397142, 46.3079684],
              [-62.3973609, 46.3078803],
              [-62.3976762, 46.307891],
              [-62.3980482, 46.3079952],
              [-62.3987376, 46.3083253],
              [-62.3992466, 46.3084421],
              [-62.3994906, 46.3086495],
              [-62.3996073, 46.3090319],
              [-62.3999583, 46.3092708],
              [-62.4003876, 46.3095403],
              [-62.4006652, 46.3095975],
              [-62.4008351, 46.3095515],
              [-62.4016749, 46.3096237],
              [-62.402719, 46.3092449],
              [-62.4031001, 46.3093016],
              [-62.4035476, 46.3094761],
              [-62.4040413, 46.3094848],
              [-62.4042797, 46.3096084],
              [-62.4043846, 46.3099147],
              [-62.4047349, 46.3100819],
              [-62.4054964, 46.3102869],
              [-62.4062887, 46.3102181],
              [-62.406753, 46.3101541],
              [-62.4069467, 46.3100969],
              [-62.4071572, 46.3098014],
              [-62.4071894, 46.3095077],
              [-62.4074663, 46.3093299],
              [-62.408104, 46.3090885],
              [-62.4081739, 46.3089117],
              [-62.4081411, 46.308807],
              [-62.4078334, 46.3086054],
              [-62.4074866, 46.3086335],
              [-62.406744, 46.3087319],
              [-62.4060755, 46.3090837],
              [-62.4058048, 46.3090904],
              [-62.4057118, 46.3090236],
              [-62.4057909, 46.3087992],
              [-62.4056769, 46.3085404],
              [-62.4059392, 46.3083263],
              [-62.406407, 46.3082943],
              [-62.4066454, 46.3080913],
              [-62.4066993, 46.3080612],
              [-62.4067657, 46.3076891],
              [-62.406628, 46.3076047],
              [-62.4059469, 46.3076454],
              [-62.4055078, 46.3075151],
              [-62.4050092, 46.3074942],
              [-62.4040708, 46.3075979],
              [-62.4033702, 46.3079167],
              [-62.4029296, 46.3079695],
              [-62.4024744, 46.3081492],
              [-62.402143, 46.3081221],
              [-62.4019486, 46.3079442],
              [-62.401462, 46.3076728],
              [-62.4014067, 46.3075594],
              [-62.4013887, 46.3070012],
              [-62.4013369, 46.3069198],
              [-62.4010223, 46.3066542],
              [-62.4004931, 46.3064172],
              [-62.4002218, 46.3061889],
              [-62.3999737, 46.3057145],
              [-62.3997599, 46.3046717],
              [-62.3998551, 46.3044638],
              [-62.4008942, 46.3039095],
              [-62.4018744, 46.3038631],
              [-62.4024093, 46.3038573],
              [-62.4028176, 46.3037716],
              [-62.4028435, 46.303649],
              [-62.4023863, 46.3036136],
              [-62.4009418, 46.3035607],
              [-62.4007719, 46.3036067],
              [-62.4004845, 46.3036885],
              [-62.3986876, 46.3037431],
              [-62.3958924, 46.3034491],
              [-62.3945899, 46.3030942],
              [-62.3936776, 46.3029119],
              [-62.3931826, 46.3027596],
              [-62.392215, 46.3026272],
              [-62.3916411, 46.3023727],
              [-62.3911685, 46.3023925],
              [-62.3907875, 46.3023357],
              [-62.3890324, 46.3012325],
              [-62.3875212, 46.3004999],
              [-62.3869628, 46.3000269],
              [-62.3869812, 46.2997686],
              [-62.3873294, 46.2995574],
              [-62.3873548, 46.2991998],
              [-62.3879633, 46.2985594],
              [-62.3885653, 46.2982533],
              [-62.3888354, 46.2978482],
              [-62.3899788, 46.2968755],
              [-62.3903265, 46.296266],
              [-62.3900748, 46.2962495],
              [-62.3898924, 46.296311],
              [-62.3886476, 46.297336],
              [-62.3882866, 46.2978893],
              [-62.3882949, 46.2979334],
              [-62.3879152, 46.2981833],
              [-62.3874517, 46.2983189],
              [-62.3869069, 46.298627],
              [-62.3867459, 46.2991154],
              [-62.3865549, 46.2992961],
              [-62.3854993, 46.299272],
              [-62.3847015, 46.2994201],
              [-62.3837417, 46.2994233],
              [-62.3832811, 46.2991925],
              [-62.3832127, 46.2990229],
              [-62.3830602, 46.2978507],
              [-62.3829038, 46.2976263],
              [-62.3824419, 46.2972521],
              [-62.3823701, 46.2968872],
              [-62.3823268, 46.2968498],
              [-62.3820087, 46.2968788],
              [-62.3815486, 46.2970464],
              [-62.381199, 46.2971141],
              [-62.3802302, 46.2970014],
              [-62.3801233, 46.2969698],
              [-62.3792551, 46.2968964],
              [-62.3789678, 46.2968149],
              [-62.3784486, 46.296602],
              [-62.3775829, 46.2959272],
              [-62.3773894, 46.2956577],
              [-62.3773807, 46.2952153],
              [-62.3775381, 46.2950216],
              [-62.3779884, 46.2948298],
              [-62.3782395, 46.2946114],
              [-62.3782977, 46.2943585],
              [-62.3781811, 46.2941394],
              [-62.3775111, 46.2936227],
              [-62.3773624, 46.2933707],
              [-62.3774101, 46.2931851],
              [-62.3784524, 46.2921015],
              [-62.378521, 46.2919445],
              [-62.3783449, 46.291835],
              [-62.3780716, 46.2918814],
              [-62.3778107, 46.2920756],
              [-62.3777274, 46.2921962],
              [-62.3775294, 46.2924762],
              [-62.3773406, 46.2925455],
              [-62.3767059, 46.2925835],
              [-62.3765234, 46.292645],
              [-62.3763068, 46.2926217],
              [-62.3757625, 46.2922765],
              [-62.3753606, 46.292191],
              [-62.3751027, 46.2921822],
              [-62.3750001, 46.292265],
              [-62.3749321, 46.2923198],
              [-62.3747247, 46.2929738],
              [-62.3747732, 46.2934216],
              [-62.3750001, 46.2936157],
              [-62.3751903, 46.2937784],
              [-62.3751769, 46.2938854],
              [-62.3750001, 46.2940197],
              [-62.3748307, 46.2941484],
              [-62.3746103, 46.2944197],
              [-62.37458, 46.2947652],
              [-62.3746561, 46.2949072],
              [-62.3746983, 46.2953627],
              [-62.374472, 46.29604],
              [-62.3745774, 46.2962547],
              [-62.3746359, 46.2965634],
              [-62.3745545, 46.2968991],
              [-62.374552, 46.2975005],
              [-62.3743385, 46.2978357],
              [-62.3742849, 46.2984273],
              [-62.3745813, 46.2993495],
              [-62.3746515, 46.2998976],
              [-62.3749273, 46.3003013],
              [-62.3750001, 46.3003401],
              [-62.3753592, 46.3005312],
              [-62.3755263, 46.3005249],
              [-62.375843, 46.3005159],
              [-62.3759597, 46.3005716],
              [-62.3762001, 46.3007471],
              [-62.376111, 46.3011105],
              [-62.3759187, 46.3011478],
              [-62.3755574, 46.3009761],
              [-62.3750001, 46.3007853],
              [-62.3747047, 46.3006841],
              [-62.3742415, 46.3003297],
              [-62.3739119, 46.2997927],
              [-62.3736228, 46.2993327],
              [-62.3734281, 46.2989198],
              [-62.3732335, 46.2974554],
              [-62.3733364, 46.2956068],
              [-62.3736255, 46.2942904],
              [-62.3735972, 46.2937996],
              [-62.3738138, 46.293098],
              [-62.3737766, 46.2923281],
              [-62.3737292, 46.292187],
              [-62.3736622, 46.2919976],
              [-62.3733917, 46.2911161],
              [-62.373168, 46.2903039],
              [-62.3725749, 46.2891127],
              [-62.3723231, 46.2876463],
              [-62.371968, 46.2869053],
              [-62.3718277, 46.2866974],
              [-62.3717648, 46.2866116],
              [-62.3713562, 46.2862989],
              [-62.3708329, 46.2861456],
              [-62.3702003, 46.2862355],
              [-62.3698246, 46.2858642],
              [-62.3696751, 46.285867],
              [-62.369394, 46.285941],
              [-62.369176, 46.2859375],
              [-62.368729, 46.2855996],
              [-62.3684886, 46.2855874],
              [-62.3682838, 46.2856401],
              [-62.3679525, 46.2856128],
              [-62.3676005, 46.2853937],
              [-62.3670526, 46.285343],
              [-62.3667984, 46.2852029],
              [-62.3669925, 46.2846559],
              [-62.3668542, 46.2844999],
              [-62.366625, 46.284492],
              [-62.3663834, 46.2843364],
              [-62.3665041, 46.283771],
              [-62.3663423, 46.2834627],
              [-62.3662606, 46.2834002],
              [-62.3660606, 46.283465],
              [-62.3657376, 46.2836451],
              [-62.3655203, 46.2835501],
              [-62.36535, 46.283361],
              [-62.3646981, 46.2832391],
              [-62.3643664, 46.2829768],
              [-62.3640852, 46.2824892],
              [-62.363465, 46.2821652],
              [-62.3633603, 46.2820223],
              [-62.3632997, 46.281825],
              [-62.3624736, 46.2812471],
              [-62.3622078, 46.2808675],
              [-62.3615991, 46.2802214],
              [-62.3615344, 46.2799204],
              [-62.3613186, 46.2798055],
              [-62.3608204, 46.2797844],
              [-62.3605355, 46.2795914],
              [-62.3603715, 46.2793946],
              [-62.36025, 46.2793267],
              [-62.3598008, 46.2792635],
              [-62.3595, 46.2788907],
              [-62.3585781, 46.2784488],
              [-62.3584399, 46.2782928],
              [-62.3582798, 46.2778014],
              [-62.3578923, 46.2775172],
              [-62.3578598, 46.2772493],
              [-62.3575367, 46.2768678],
              [-62.3575643, 46.2765621],
              [-62.3576484, 46.2763499],
              [-62.35814, 46.2759805],
              [-62.3587068, 46.2758447],
              [-62.3593013, 46.2754031],
              [-62.3602224, 46.2752117],
              [-62.3605202, 46.2750627],
              [-62.3606573, 46.274912],
              [-62.360865, 46.2748196],
              [-62.3613213, 46.2747835],
              [-62.3617599, 46.2749141],
              [-62.3622532, 46.274923],
              [-62.3626705, 46.27479],
              [-62.3629758, 46.2747766],
              [-62.3630967, 46.2747728],
              [-62.3635766, 46.2748888],
              [-62.3645626, 46.274835],
              [-62.3655926, 46.274727],
              [-62.3659511, 46.2746119],
              [-62.3662795, 46.2746789],
              [-62.3666309, 46.274663],
              [-62.3675166, 46.2742434],
              [-62.3677821, 46.2742246],
              [-62.3681103, 46.2744549],
              [-62.3683842, 46.2744802],
              [-62.3688581, 46.2742774],
              [-62.3695568, 46.2743053],
              [-62.3698308, 46.2741674],
              [-62.3702123, 46.2741326],
              [-62.3707689, 46.2744623],
              [-62.3713266, 46.2743739],
              [-62.3722209, 46.2743965],
              [-62.3736741, 46.2744742],
              [-62.3749907, 46.2742125],
              [-62.3750001, 46.2742129],
              [-62.3757006, 46.2742448],
              [-62.3767883, 46.2745368],
              [-62.3783687, 46.2746028],
              [-62.3791504, 46.2748366],
              [-62.3797771, 46.2749894],
              [-62.3806755, 46.2751156],
              [-62.3808362, 46.2751171],
              [-62.3812604, 46.275048],
              [-62.3821578, 46.2747043],
              [-62.3826295, 46.2746128],
              [-62.3833729, 46.2746581],
              [-62.3840424, 46.2744499],
              [-62.3846628, 46.2744472],
              [-62.3848563, 46.2745534],
              [-62.3849191, 46.2746391],
              [-62.3848723, 46.2747331],
              [-62.3847842, 46.2748416],
              [-62.3844599, 46.2750416],
              [-62.3840427, 46.2751748],
              [-62.3837232, 46.2753869],
              [-62.3836833, 46.2755448],
              [-62.3839067, 46.2757954],
              [-62.3841735, 46.2760833],
              [-62.3847491, 46.2763896],
              [-62.3847623, 46.2764458],
              [-62.3852066, 46.2766601],
              [-62.3855825, 46.2767048],
              [-62.3865908, 46.2766595],
              [-62.3875286, 46.276556],
              [-62.3881721, 46.2766337],
              [-62.3896336, 46.277245],
              [-62.3899585, 46.2772799],
              [-62.3898741, 46.2770939],
              [-62.3892482, 46.2766862],
              [-62.3877888, 46.2761268],
              [-62.387735, 46.2759935],
              [-62.3877882, 46.2758918],
              [-62.3881397, 46.2757126],
              [-62.3884129, 46.2756662],
              [-62.3889433, 46.2757201],
              [-62.3893289, 46.2757889],
              [-62.3898439, 46.2757348],
              [-62.3901374, 46.2756452],
              [-62.3905182, 46.275702],
              [-62.3910889, 46.2761595],
              [-62.3915228, 46.2762778],
              [-62.39287, 46.276232],
              [-62.3931809, 46.276139],
              [-62.3935136, 46.2758198],
              [-62.3938735, 46.2758479],
              [-62.3944387, 46.2760583],
              [-62.3949495, 46.2760637],
              [-62.3949678, 46.2754789],
              [-62.3947436, 46.2751567],
              [-62.3937928, 46.2747142],
              [-62.3931858, 46.2741201],
              [-62.3921589, 46.2736988],
              [-62.3911328, 46.2730228],
              [-62.3909687, 46.272826],
              [-62.390505, 46.2722368],
              [-62.389789, 46.2718859],
              [-62.3882633, 46.2715352],
              [-62.3871161, 46.2713528],
              [-62.3864686, 46.2711714],
              [-62.3864316, 46.2711264],
              [-62.3859295, 46.2706752],
              [-62.3852512, 46.270441],
              [-62.3846687, 46.2700707],
              [-62.3845667, 46.2700513],
              [-62.3838639, 46.270083],
              [-62.3830479, 46.2700911],
              [-62.3827733, 46.2699941],
              [-62.3823857, 46.2698733],
              [-62.3808785, 46.2691011],
              [-62.3797451, 46.2683217],
              [-62.3780708, 46.2669941],
              [-62.3779849, 46.2669911],
              [-62.3778521, 46.2670822],
              [-62.3777813, 46.2673506],
              [-62.3778699, 46.267477],
              [-62.377862, 46.2678312],
              [-62.3773691, 46.2683839],
              [-62.3771288, 46.2692597],
              [-62.3769331, 46.2694283],
              [-62.3760631, 46.2706809],
              [-62.375861, 46.2708572],
              [-62.375849, 46.2711077],
              [-62.3756336, 46.271391],
              [-62.3756144, 46.2717408],
              [-62.3757686, 46.2720766],
              [-62.3758789, 46.2721401],
              [-62.3760347, 46.2721295],
              [-62.3763298, 46.2718569],
              [-62.3763259, 46.2714266],
              [-62.3767432, 46.2711303],
              [-62.3772758, 46.2709095],
              [-62.3777271, 46.2708612],
              [-62.3778088, 46.2709237],
              [-62.3777535, 46.2711369],
              [-62.3770406, 46.271471],
              [-62.3767616, 46.2717601],
              [-62.3766712, 46.2721433],
              [-62.3770613, 46.2728775],
              [-62.3770548, 46.2730485],
              [-62.3769234, 46.2731197],
              [-62.3764812, 46.2731205],
              [-62.3759878, 46.2732749],
              [-62.3756454, 46.2732433],
              [-62.375107, 46.2729819],
              [-62.3750001, 46.2728273],
              [-62.3749716, 46.2727861],
              [-62.3750001, 46.2725488],
              [-62.3750001, 46.2719735],
              [-62.3747288, 46.2714357],
              [-62.3747479, 46.2710859],
              [-62.3750001, 46.2704926],
              [-62.3752168, 46.2699829],
              [-62.3760832, 46.2685825],
              [-62.3762008, 46.2677744],
              [-62.3764126, 46.2676224],
              [-62.3765957, 46.2666976],
              [-62.3765688, 46.2663383],
              [-62.3766844, 46.2656564],
              [-62.3767454, 46.2652647],
              [-62.3767637, 46.2649323],
              [-62.3767356, 46.2646504],
              [-62.3766166, 46.2642727],
              [-62.3767683, 46.2636944],
              [-62.3770045, 46.2634133],
              [-62.3773233, 46.2631295],
              [-62.3773275, 46.2630699],
              [-62.3780684, 46.2619296],
              [-62.3782392, 46.2616667],
              [-62.3783444, 46.26148],
              [-62.3786408, 46.2611624],
              [-62.3788818, 46.2607512],
              [-62.3790007, 46.2604434],
              [-62.3792002, 46.2601356],
              [-62.3793743, 46.2599049],
              [-62.3795343, 46.259562],
              [-62.3796436, 46.2591077],
              [-62.3796488, 46.2590263],
              [-62.3797862, 46.2569058],
              [-62.3796516, 46.2566184],
              [-62.3796349, 46.2565303],
              [-62.3794703, 46.2560985],
              [-62.3791905, 46.2557545],
              [-62.3789797, 46.2556517],
              [-62.378398, 46.2553531],
              [-62.3778473, 46.254944],
              [-62.3776591, 46.2545234],
              [-62.3773226, 46.2542491],
              [-62.3771264, 46.2531314],
              [-62.3769207, 46.2527142],
              [-62.3764734, 46.2521414],
              [-62.3764758, 46.2517034],
              [-62.3766352, 46.2515615],
              [-62.3768245, 46.251564],
              [-62.3770374, 46.2517186],
              [-62.3778244, 46.2525977],
              [-62.3779829, 46.2527107],
              [-62.3786154, 46.253019],
              [-62.3793346, 46.2532387],
              [-62.3803814, 46.2532904],
              [-62.3814289, 46.2534137],
              [-62.3818157, 46.2536261],
              [-62.3820131, 46.2539992],
              [-62.3824683, 46.254381],
              [-62.3826931, 46.2546117],
              [-62.3828012, 46.2547866],
              [-62.3828675, 46.2549044],
              [-62.38306, 46.2552654],
              [-62.3833657, 46.2556501],
              [-62.3834585, 46.2558803],
              [-62.3836294, 46.256141],
              [-62.3836468, 46.2563008],
              [-62.383454, 46.2564297],
              [-62.3831521, 46.2566384],
              [-62.3831086, 46.2569276],
              [-62.3831783, 46.2572406],
              [-62.3835503, 46.2577431],
              [-62.3842351, 46.2583678],
              [-62.3850914, 46.2596514],
              [-62.3854838, 46.2599475],
              [-62.3861912, 46.2602543],
              [-62.3866326, 46.260345],
              [-62.3888181, 46.260535],
              [-62.389511, 46.2606422],
              [-62.3906027, 46.2607112],
              [-62.3908318, 46.260719],
              [-62.3921322, 46.2611655],
              [-62.3925205, 46.2613579],
              [-62.3930143, 46.2616017],
              [-62.393561, 46.2620702],
              [-62.3936441, 46.2622762],
              [-62.3937495, 46.2624908],
              [-62.3938597, 46.2628808],
              [-62.3940888, 46.2630519],
              [-62.394204, 46.2631275],
              [-62.3948955, 46.2632545],
              [-62.3951379, 46.2633185],
              [-62.3956178, 46.2634344],
              [-62.3960201, 46.2634281],
              [-62.3969632, 46.2632451],
              [-62.3991126, 46.2631349],
              [-62.4023447, 46.2629778],
              [-62.40357, 46.2630273],
              [-62.4046785, 46.2631843],
              [-62.4071632, 46.2638578],
              [-62.4081467, 46.2642415],
              [-62.4089445, 46.2644915],
              [-62.4091037, 46.2646761],
              [-62.4090332, 46.2647813],
              [-62.4088474, 46.2648108],
              [-62.4087587, 46.2648476],
              [-62.4088669, 46.2650225],
              [-62.4088781, 46.2651902],
              [-62.4087314, 46.2653166],
              [-62.408686, 46.265554],
              [-62.4087922, 46.2656771],
              [-62.4093112, 46.2658898],
              [-62.4100357, 46.2662846],
              [-62.4112722, 46.2668282],
              [-62.4128378, 46.2676736],
              [-62.4134826, 46.2678945],
              [-62.414022, 46.2679008],
              [-62.414768, 46.2675795],
              [-62.4151355, 46.2674167],
              [-62.416029, 46.2673672],
              [-62.4163657, 46.2673148],
              [-62.4172634, 46.267369],
              [-62.4176938, 46.2674552],
              [-62.4177483, 46.2674969],
              [-62.4177511, 46.2676204],
              [-62.417478, 46.2678302],
              [-62.4174277, 46.2682188],
              [-62.4176038, 46.2684916],
              [-62.4178072, 46.2686219],
              [-62.4182732, 46.268773],
              [-62.4191396, 46.2691925],
              [-62.4203455, 46.2693566],
              [-62.4209596, 46.2696879],
              [-62.4213872, 46.2698138],
              [-62.4217765, 46.2700778],
              [-62.4219288, 46.2703617],
              [-62.4219667, 46.2706417],
              [-62.4218174, 46.271461],
              [-62.4212895, 46.2724388],
              [-62.4212652, 46.2730314],
              [-62.4213064, 46.2731801],
              [-62.4213954, 46.2739597],
              [-62.4219007, 46.274371],
              [-62.4221972, 46.2752212],
              [-62.4224887, 46.2755696],
              [-62.4226501, 46.2756427],
              [-62.4227933, 46.2754842],
              [-62.4228149, 46.275258],
              [-62.4220382, 46.2739654],
              [-62.4218816, 46.2735778],
              [-62.4220476, 46.2725201],
              [-62.422394, 46.2721654],
              [-62.4227627, 46.2716562],
              [-62.4231791, 46.2714512],
              [-62.4236779, 46.2713803],
              [-62.4243485, 46.271152],
              [-62.4248537, 46.2712367],
              [-62.4251527, 46.2712309],
              [-62.4252023, 46.2710971],
              [-62.4249437, 46.2708535],
              [-62.4249884, 46.2707076],
              [-62.425441, 46.270476],
              [-62.4255052, 46.2703786],
              [-62.4252914, 46.2703156],
              [-62.4251315, 46.2703859],
              [-62.424845, 46.2703763],
              [-62.4246724, 46.2702988],
              [-62.4246535, 46.2701588],
              [-62.4244529, 46.2698254],
              [-62.4235682, 46.2691744],
              [-62.4232809, 46.2686033],
              [-62.4233017, 46.268142],
              [-62.4228985, 46.2679134],
              [-62.4224283, 46.2676586],
              [-62.4217715, 46.267525],
              [-62.4214459, 46.2674184],
              [-62.4211281, 46.2674476],
              [-62.4207558, 46.2675983],
              [-62.4205266, 46.2675906],
              [-62.4203694, 46.2674578],
              [-62.420467, 46.2667204],
              [-62.4205172, 46.2663319],
              [-62.4202796, 46.2661168],
              [-62.4197417, 46.2659274],
              [-62.4193281, 46.2656028],
              [-62.4189717, 46.2652802],
              [-62.4182641, 46.2653001],
              [-62.4179805, 46.265414],
              [-62.4179043, 46.2654354],
              [-62.4172994, 46.2658729],
              [-62.4171611, 46.2658802],
              [-62.4170549, 46.2657571],
              [-62.4170779, 46.2651845],
              [-62.4172153, 46.2642891],
              [-62.4173222, 46.2641573],
              [-62.4173102, 46.2637547],
              [-62.4169531, 46.2630338],
              [-62.4168183, 46.2627466],
              [-62.4168475, 46.2621661],
              [-62.4171275, 46.2615305],
              [-62.4176603, 46.2608914],
              [-62.4183629, 46.2603695],
              [-62.4193637, 46.2597701],
              [-62.4199489, 46.2592841],
              [-62.4201514, 46.2591795],
              [-62.4208663, 46.2588448],
              [-62.4212912, 46.2588484],
              [-62.4215075, 46.2588681],
              [-62.4216698, 46.2589394],
              [-62.4218081, 46.2589866],
              [-62.4219292, 46.2590246],
              [-62.4220485, 46.2590377],
              [-62.4222167, 46.2590056],
              [-62.4225464, 46.2588964],
              [-62.4231137, 46.2586593],
              [-62.4229315, 46.2582444],
              [-62.4229077, 46.2581253],
              [-62.4229147, 46.2580366],
              [-62.4229268, 46.2580069],
              [-62.4229543, 46.2579897],
              [-62.4229895, 46.257999],
              [-62.4229798, 46.2580125],
              [-62.4234071, 46.2579642],
              [-62.4234224, 46.2580356],
              [-62.4231034, 46.2580755],
              [-62.4232299, 46.2585327],
              [-62.4237056, 46.2585475],
              [-62.4236975, 46.2582229],
              [-62.4236435, 46.2581396],
              [-62.4236191, 46.2579456],
              [-62.4241766, 46.2580404],
              [-62.4243538, 46.2586633],
              [-62.4249399, 46.2585919],
              [-62.4249217, 46.2584473],
              [-62.4249565, 46.2584453],
              [-62.4250136, 46.2584419],
              [-62.4251315, 46.2584349],
              [-62.4251739, 46.2584324],
              [-62.4252124, 46.2587247],
              [-62.4251661, 46.2587955],
              [-62.4250885, 46.2588577],
              [-62.4250103, 46.2588847],
              [-62.4248033, 46.2588901],
              [-62.4244383, 46.258943],
              [-62.4242283, 46.2590038],
              [-62.4241914, 46.2591571],
              [-62.4242196, 46.2594117],
              [-62.4243606, 46.2595707],
              [-62.4245132, 46.2596876],
              [-62.4245932, 46.2597216],
              [-62.4246568, 46.2597436],
              [-62.4249876, 46.2596864],
              [-62.4250713, 46.2596751],
              [-62.4251566, 46.2596765],
              [-62.4252376, 46.2597097],
              [-62.4258749, 46.2602797],
              [-62.4258597, 46.2608093],
              [-62.4257765, 46.2612536],
              [-62.4257718, 46.261494],
              [-62.4258494, 46.2619095],
              [-62.4257675, 46.2621959],
              [-62.4257504, 46.2622846],
              [-62.4256413, 46.2623122],
              [-62.4256783, 46.2623706],
              [-62.4257917, 46.2623665],
              [-62.4258515, 46.2623835],
              [-62.4259487, 46.2624953],
              [-62.426475, 46.2632626],
              [-62.4267995, 46.263525],
              [-62.4270548, 46.2636569],
              [-62.4272525, 46.2637635],
              [-62.427385, 46.2638213],
              [-62.4275184, 46.2638362],
              [-62.4277182, 46.2638049],
              [-62.4278631, 46.2637797],
              [-62.427994, 46.2637905],
              [-62.4282917, 46.2639018],
              [-62.4287322, 46.2640033],
              [-62.4291161, 46.2640232],
              [-62.4297495, 46.2641586],
              [-62.4301721, 46.2641892],
              [-62.4312386, 46.2641679],
              [-62.4316339, 46.264204],
              [-62.4320774, 46.2642789],
              [-62.4332344, 46.264628],
              [-62.4338355, 46.2647289],
              [-62.4350045, 46.2651743],
              [-62.4367681, 46.2659025],
              [-62.437142, 46.2662217],
              [-62.4374831, 46.2664362],
              [-62.4383769, 46.2668763],
              [-62.4387809, 46.2671765],
              [-62.4394636, 46.267514],
              [-62.4401973, 46.2676979],
              [-62.440717, 46.2676555],
              [-62.4414243, 46.2671456],
              [-62.4420654, 46.2668445],
              [-62.4425514, 46.2664626],
              [-62.4433763, 46.2654986],
              [-62.4436997, 46.2653899],
              [-62.4439051, 46.2654088],
              [-62.4442048, 46.2654745],
              [-62.4445516, 46.2657729],
              [-62.4448569, 46.2666671],
              [-62.4450135, 46.2668914],
              [-62.4457049, 46.2683441],
              [-62.445942, 46.2686507],
              [-62.446161, 46.2690522],
              [-62.4465456, 46.2694673],
              [-62.4480675, 46.2704579],
              [-62.4489456, 46.2712081],
              [-62.4495161, 46.2715935],
              [-62.4499697, 46.2717599],
              [-62.450551, 46.2718231],
              [-62.4531719, 46.2712933],
              [-62.4543174, 46.271005],
              [-62.4553401, 46.270832],
              [-62.4556733, 46.2707475],
              [-62.4563941, 46.2706202],
              [-62.4566492, 46.2706685],
              [-62.4569533, 46.2708379],
              [-62.4569507, 46.2710409],
              [-62.4571032, 46.2711615],
              [-62.4572234, 46.2712491],
              [-62.45891, 46.2718548],
              [-62.459281, 46.2718871],
              [-62.4623572, 46.2718301],
              [-62.4652505, 46.2719262],
              [-62.4667655, 46.272108],
              [-62.4671367, 46.2723035],
              [-62.4672557, 46.2724109],
              [-62.4673173, 46.2725165],
              [-62.467562, 46.2732136],
              [-62.4684431, 46.2737605],
              [-62.4689699, 46.2742001],
              [-62.4699373, 46.2754945],
              [-62.4701364, 46.2755211],
              [-62.4708601, 46.2753539],
              [-62.4715952, 46.2753544],
              [-62.4737449, 46.2756605],
              [-62.474967, 46.2756771],
              [-62.4764025, 46.2754299],
              [-62.4775695, 46.2750783],
              [-62.4790162, 46.2744173],
              [-62.4804585, 46.2736526],
              [-62.4807358, 46.2735463],
              [-62.4819526, 46.2733873],
              [-62.4827957, 46.273236],
              [-62.4830863, 46.2731859],
              [-62.4837853, 46.2729581],
              [-62.4845096, 46.2724444],
              [-62.4852441, 46.2717],
              [-62.4855297, 46.2712197],
              [-62.4861647, 46.2704163],
              [-62.4865415, 46.2701142],
              [-62.4866888, 46.2700593],
              [-62.4869935, 46.270137],
              [-62.4874418, 46.2703828],
              [-62.4878759, 46.2709187],
              [-62.4881674, 46.2713584],
              [-62.4889932, 46.2728829],
              [-62.4895049, 46.2742139],
              [-62.4896427, 46.2742981],
              [-62.4902367, 46.2743455],
              [-62.4908024, 46.2745553],
              [-62.4912395, 46.2747967],
              [-62.4922085, 46.2750715],
              [-62.4923365, 46.2751314],
              [-62.4925739, 46.2754379],
              [-62.4926359, 46.2757068],
              [-62.4928591, 46.2758853],
              [-62.4938682, 46.2759106],
              [-62.4944142, 46.2760719],
              [-62.4961563, 46.2768818],
              [-62.4971065, 46.2771799],
              [-62.4978991, 46.2776001],
              [-62.4986054, 46.2778543],
              [-62.4993474, 46.2781734],
              [-62.4999839, 46.2787101],
              [-62.5020608, 46.2802376],
              [-62.5030025, 46.2807464],
              [-62.5035464, 46.2808558],
              [-62.5040174, 46.2808553],
              [-62.5043921, 46.2809193],
              [-62.5044762, 46.2810336],
              [-62.5044084, 46.2813539],
              [-62.5042977, 46.2815454],
              [-62.5043476, 46.2819931],
              [-62.5048209, 46.2827175],
              [-62.5052684, 46.2831463],
              [-62.5055217, 46.2833059],
              [-62.5054899, 46.2834363],
              [-62.5051521, 46.2836721],
              [-62.5051779, 46.2839676],
              [-62.5058791, 46.2847194],
              [-62.5059416, 46.2851515],
              [-62.5057906, 46.2854293],
              [-62.5046976, 46.2861262],
              [-62.5045403, 46.2864118],
              [-62.5045346, 46.287001],
              [-62.5045348, 46.2874192],
              [-62.5046063, 46.2875489],
              [-62.50483, 46.2872178],
              [-62.5052764, 46.2870565],
              [-62.505387, 46.2866665],
              [-62.5054385, 46.2863198],
              [-62.5060029, 46.2859082],
              [-62.5064599, 46.2854627],
              [-62.5066092, 46.2852254],
              [-62.5067636, 46.2849612],
              [-62.5067222, 46.2846633],
              [-62.5066159, 46.2844857],
              [-62.5068019, 46.2843975],
              [-62.5070368, 46.2844372],
              [-62.5074164, 46.2845826],
              [-62.5081796, 46.2849301],
              [-62.5086624, 46.2850658],
              [-62.5086039, 46.2852785],
              [-62.5090732, 46.2859294],
              [-62.5093312, 46.2863658],
              [-62.5094725, 46.2865903],
              [-62.5095582, 46.2869544],
              [-62.5096088, 46.2870557],
              [-62.5099448, 46.2874916],
              [-62.5100171, 46.2878036],
              [-62.5100529, 46.2881487],
              [-62.5100817, 46.2883429],
              [-62.510248, 46.2884664],
              [-62.5107935, 46.2888142],
              [-62.5114663, 46.2890518],
              [-62.5120126, 46.2892901],
              [-62.5125384, 46.2895618],
              [-62.5130631, 46.2901514],
              [-62.5140891, 46.2907554],
              [-62.5145338, 46.2910983],
              [-62.5149198, 46.2914028],
              [-62.515271, 46.2916993],
              [-62.5158098, 46.2917674],
              [-62.5162431, 46.2919027],
              [-62.5165827, 46.2920737],
              [-62.5170111, 46.2921985],
              [-62.5180324, 46.2927665],
              [-62.5183754, 46.2929585],
              [-62.5186811, 46.2931185],
              [-62.518871, 46.2935069],
              [-62.5190195, 46.2938929],
              [-62.5192576, 46.2942787],
              [-62.5194039, 46.2944688],
              [-62.5197351, 46.2947189],
              [-62.5200984, 46.2948357],
              [-62.5204362, 46.2954771],
              [-62.5206839, 46.2961811],
              [-62.5207459, 46.2964482],
              [-62.5207876, 46.2966746],
              [-62.5205383, 46.2969046],
              [-62.5205493, 46.2971643],
              [-62.5206081, 46.2976018],
              [-62.5206284, 46.2977519],
              [-62.5205255, 46.297905],
              [-62.5203142, 46.2980128],
              [-62.5201265, 46.2980984],
              [-62.5196687, 46.2980726],
              [-62.5191533, 46.2978077],
              [-62.5186209, 46.2975609],
              [-62.5186731, 46.2976504],
              [-62.51887, 46.2977985],
              [-62.5191507, 46.2979963],
              [-62.5194592, 46.2981996],
              [-62.519825, 46.2982934],
              [-62.5201068, 46.2982718],
              [-62.5204064, 46.2982054],
              [-62.5205865, 46.298314],
              [-62.520745, 46.2985183],
              [-62.5208769, 46.2987074],
              [-62.5209126, 46.2991477],
              [-62.5208829, 46.2996333],
              [-62.520936, 46.3000761],
              [-62.5209152, 46.3005828],
              [-62.5209478, 46.3010339],
              [-62.5210805, 46.3015388],
              [-62.5210659, 46.3021689],
              [-62.5211394, 46.3026657],
              [-62.5211094, 46.3031569],
              [-62.5211271, 46.3033612],
              [-62.5213572, 46.3035896],
              [-62.5221707, 46.3040168],
              [-62.5222028, 46.3045595],
              [-62.5224562, 46.304974],
              [-62.5227045, 46.3053758],
              [-62.5229317, 46.3057312],
              [-62.5232943, 46.3060048],
              [-62.5233761, 46.3060838],
              [-62.5233724, 46.3062537],
              [-62.5232651, 46.3063483],
              [-62.5231489, 46.3064338],
              [-62.522689, 46.3066707],
              [-62.5220845, 46.3069129],
              [-62.5209328, 46.3072384],
              [-62.5203647, 46.3075316],
              [-62.5200596, 46.307706],
              [-62.5197996, 46.3079143],
              [-62.5198893, 46.3083788],
              [-62.5199675, 46.3086558],
              [-62.5198609, 46.3089653],
              [-62.5193105, 46.3091255],
              [-62.5188519, 46.3091703],
              [-62.5181858, 46.3093518],
              [-62.5187797, 46.3093643],
              [-62.5194356, 46.3093476],
              [-62.520315, 46.3091774],
              [-62.5206163, 46.3089329],
              [-62.520682, 46.3089091],
              [-62.5207593, 46.3089132],
              [-62.5208372, 46.3089451],
              [-62.5210155, 46.3091052],
              [-62.5210549, 46.3091932],
              [-62.5210139, 46.3093024],
              [-62.5213512, 46.3095297],
              [-62.522632, 46.3099262],
              [-62.5233475, 46.3102243],
              [-62.5239653, 46.3103519],
              [-62.5245441, 46.3102911],
              [-62.5252269, 46.3101381],
              [-62.5264625, 46.3101384],
              [-62.5271561, 46.3103362],
              [-62.5275422, 46.3104045],
              [-62.5287178, 46.3104428],
              [-62.5291762, 46.3105492],
              [-62.5300274, 46.3108796],
              [-62.5308356, 46.311336],
              [-62.5315628, 46.3115468],
              [-62.5322429, 46.3119432],
              [-62.532803, 46.3124154],
              [-62.5328784, 46.3125],
              [-62.5331574, 46.3125],
              [-62.5326049, 46.3118594],
              [-62.5318688, 46.3114412],
              [-62.5305238, 46.3108479],
              [-62.5295444, 46.3102028],
              [-62.5289419, 46.3100199],
              [-62.5280803, 46.3100118],
              [-62.5275517, 46.3100106],
              [-62.5272726, 46.3099736],
              [-62.5262668, 46.3096342],
              [-62.5255406, 46.3094951],
              [-62.5248547, 46.3095246],
              [-62.5241145, 46.3096757],
              [-62.5236871, 46.309622],
              [-62.5216627, 46.3088782],
              [-62.5216194, 46.3088781],
              [-62.5213384, 46.3090182],
              [-62.5212785, 46.3090179],
              [-62.52122, 46.3090092],
              [-62.5209795, 46.3088551],
              [-62.5209589, 46.3088071],
              [-62.5209619, 46.3087579],
              [-62.5209922, 46.3087135],
              [-62.521107, 46.3086463],
              [-62.5211183, 46.3086215],
              [-62.5208811, 46.308288],
              [-62.5208014, 46.3080226],
              [-62.5208763, 46.3077661],
              [-62.5215116, 46.3073806],
              [-62.5226688, 46.3070957],
              [-62.5234694, 46.3068151],
              [-62.524007, 46.306514],
              [-62.5242731, 46.3061483],
              [-62.5243484, 46.3060552],
              [-62.524407, 46.3057823],
              [-62.5241137, 46.3053625],
              [-62.523582, 46.3049828],
              [-62.5233827, 46.3047015],
              [-62.5233972, 46.3040647],
              [-62.5225131, 46.30312],
              [-62.5218466, 46.3025248],
              [-62.5216455, 46.3021001],
              [-62.5217133, 46.3012699],
              [-62.5216749, 46.3004801],
              [-62.5216827, 46.299433],
              [-62.5218809, 46.2982048],
              [-62.5218796, 46.2979698],
              [-62.5215962, 46.2975743],
              [-62.5216509, 46.2971062],
              [-62.5218489, 46.2968975],
              [-62.5220962, 46.29681],
              [-62.5223286, 46.2969411],
              [-62.5224696, 46.2974036],
              [-62.5226532, 46.2975769],
              [-62.5235305, 46.2981112],
              [-62.5241728, 46.2982994],
              [-62.524472, 46.2982932],
              [-62.5247416, 46.2982144],
              [-62.525134, 46.2982749],
              [-62.5265202, 46.299017],
              [-62.5270841, 46.2991747],
              [-62.527398, 46.2992048],
              [-62.5255268, 46.297989],
              [-62.5249495, 46.2977751],
              [-62.5242611, 46.2978443],
              [-62.5230474, 46.2972711],
              [-62.5230994, 46.2968427],
              [-62.5230412, 46.2967691],
              [-62.5227582, 46.2965369],
              [-62.5222239, 46.2964518],
              [-62.5219046, 46.2962462],
              [-62.5219458, 46.2959768],
              [-62.5220941, 46.2957387],
              [-62.5221967, 46.2954115],
              [-62.5221476, 46.2947806],
              [-62.5220469, 46.2944867],
              [-62.5223358, 46.294293],
              [-62.5224021, 46.2939925],
              [-62.5222655, 46.2938884],
              [-62.5220116, 46.2938204],
              [-62.5218643, 46.2936205],
              [-62.5216387, 46.2931353],
              [-62.5213015, 46.2927698],
              [-62.5211529, 46.2923349],
              [-62.5208301, 46.2920974],
              [-62.5201713, 46.2919843],
              [-62.5194025, 46.2917163],
              [-62.5186913, 46.2916135],
              [-62.5180631, 46.2913899],
              [-62.5171636, 46.2908468],
              [-62.516377, 46.290164],
              [-62.5151664, 46.2892962],
              [-62.5134583, 46.2884798],
              [-62.5132698, 46.2882944],
              [-62.5134512, 46.2881609],
              [-62.5142323, 46.2879953],
              [-62.5154692, 46.2876295],
              [-62.5163903, 46.2875998],
              [-62.5172773, 46.2879036],
              [-62.5178562, 46.2880061],
              [-62.5183935, 46.2882148],
              [-62.51875, 46.2882105],
              [-62.5196309, 46.2877573],
              [-62.5199985, 46.2877574],
              [-62.5207789, 46.288193],
              [-62.520993, 46.2882557],
              [-62.5213969, 46.2882291],
              [-62.5220655, 46.2878566],
              [-62.5221183, 46.2877548],
              [-62.5216508, 46.2877873],
              [-62.5208722, 46.28775],
              [-62.5204042, 46.2876193],
              [-62.5197309, 46.287215],
              [-62.5194653, 46.2872342],
              [-62.5187013, 46.287743],
              [-62.51782, 46.2877779],
              [-62.5171837, 46.2876735],
              [-62.5160072, 46.286982],
              [-62.5154788, 46.2869807],
              [-62.5139978, 46.2875576],
              [-62.5137336, 46.2875569],
              [-62.5136552, 46.2875265],
              [-62.5130937, 46.2874205],
              [-62.5119698, 46.2873917],
              [-62.5118753, 46.2873448],
              [-62.5118745, 46.2872731],
              [-62.5118844, 46.2870425],
              [-62.5118093, 46.2868807],
              [-62.5108039, 46.2861231],
              [-62.510848, 46.285559],
              [-62.5106246, 46.2853805],
              [-62.5102792, 46.2849709],
              [-62.510098, 46.2845947],
              [-62.5094036, 46.2834888],
              [-62.5089728, 46.28273],
              [-62.5088749, 46.2823962],
              [-62.5085356, 46.2822338],
              [-62.5082531, 46.2821648],
              [-62.508213, 46.2817414],
              [-62.5081526, 46.281616],
              [-62.5071237, 46.2808695],
              [-62.5059704, 46.2798401],
              [-62.5058009, 46.2796315],
              [-62.5055476, 46.2794718],
              [-62.5050193, 46.2794705],
              [-62.5049436, 46.2794003],
              [-62.5050021, 46.2789642],
              [-62.5040499, 46.2781046],
              [-62.5025445, 46.2763469],
              [-62.5018575, 46.2753049],
              [-62.5016877, 46.274933],
              [-62.5014488, 46.2746464],
              [-62.5011257, 46.2745004],
              [-62.5000536, 46.2741196],
              [-62.4993465, 46.2743186],
              [-62.4988971, 46.274256],
              [-62.4985385, 46.2742084],
              [-62.49801, 46.2740437],
              [-62.4974489, 46.2736828],
              [-62.4970402, 46.2730243],
              [-62.4963066, 46.2723312],
              [-62.4956244, 46.271811],
              [-62.4950239, 46.2709351],
              [-62.494758, 46.2703728],
              [-62.4946766, 46.2698007],
              [-62.4945136, 46.2693294],
              [-62.4944876, 46.2688706],
              [-62.4944076, 46.2686968],
              [-62.4943759, 46.2685723],
              [-62.4943859, 46.2683417],
              [-62.4948831, 46.2678722],
              [-62.495472, 46.2674894],
              [-62.4957093, 46.2673777],
              [-62.4960929, 46.2673943],
              [-62.497062, 46.2678323],
              [-62.4972478, 46.2678026],
              [-62.497251, 46.2676713],
              [-62.4969732, 46.2674511],
              [-62.4963839, 46.2672526],
              [-62.4960461, 46.2670702],
              [-62.4959683, 46.2669482],
              [-62.4961163, 46.2665469],
              [-62.4963416, 46.2663592],
              [-62.4966187, 46.2662528],
              [-62.4975399, 46.2661318],
              [-62.4980691, 46.2659501],
              [-62.4989969, 46.2653116],
              [-62.4993228, 46.265163],
              [-62.4998184, 46.2649682],
              [-62.5, 46.264986],
              [-62.5003934, 46.2650389],
              [-62.5016221, 46.2653739],
              [-62.5022438, 46.2653505],
              [-62.5029958, 46.265184],
              [-62.5041626, 46.2652502],
              [-62.5056834, 46.2652602],
              [-62.5062183, 46.2651623],
              [-62.5063544, 46.265103],
              [-62.5061816, 46.2650257],
              [-62.5058818, 46.2649601],
              [-62.5040119, 46.2650183],
              [-62.5033872, 46.2649181],
              [-62.502006, 46.2648808],
              [-62.5001937, 46.2644311],
              [-62.5, 46.264417],
              [-62.4994791, 46.2643877],
              [-62.4993157, 46.2644261],
              [-62.4988062, 46.2646563],
              [-62.4987373, 46.2647416],
              [-62.4980007, 46.2652709],
              [-62.4977961, 46.2653239],
              [-62.4970325, 46.2653227],
              [-62.4964903, 46.2656115],
              [-62.4962585, 46.2656437],
              [-62.4959685, 46.2656023],
              [-62.4955412, 46.2653852],
              [-62.4954214, 46.265206],
              [-62.4954093, 46.2647119],
              [-62.4952273, 46.2645187],
              [-62.494999, 46.2645829],
              [-62.4946305, 46.2647659],
              [-62.4944469, 46.2648474],
              [-62.4939991, 46.2651831],
              [-62.4940024, 46.2654699],
              [-62.4940985, 46.2656603],
              [-62.4940495, 46.2659573],
              [-62.4939107, 46.2660563],
              [-62.4934759, 46.2662849],
              [-62.4928965, 46.2665286],
              [-62.4922719, 46.2668465],
              [-62.491869, 46.2669448],
              [-62.4912527, 46.2668886],
              [-62.4904984, 46.266585],
              [-62.4893833, 46.2657638],
              [-62.4890729, 46.2656023],
              [-62.4886432, 46.2655881],
              [-62.4874408, 46.265875],
              [-62.4869855, 46.2659834],
              [-62.4866794, 46.2659256],
              [-62.4838702, 46.2657811],
              [-62.4824236, 46.2654427],
              [-62.4816047, 46.265328],
              [-62.4805757, 46.2653458],
              [-62.4800094, 46.2654823],
              [-62.4792087, 46.2658541],
              [-62.4788282, 46.265961],
              [-62.4785033, 46.2659263],
              [-62.4783221, 46.2658049],
              [-62.4782196, 46.265459],
              [-62.4780287, 46.2653133],
              [-62.4776522, 46.2653606],
              [-62.476091, 46.2658545],
              [-62.4748331, 46.2660279],
              [-62.4730307, 46.2660199],
              [-62.4722431, 46.2662845],
              [-62.4718445, 46.2664864],
              [-62.4714261, 46.267476],
              [-62.471163, 46.2677102],
              [-62.4710141, 46.2676216],
              [-62.4709999, 46.2674937],
              [-62.4709746, 46.2673615],
              [-62.4712184, 46.266824],
              [-62.4711549, 46.2664117],
              [-62.4706934, 46.2661097],
              [-62.4693421, 46.2658897],
              [-62.4688137, 46.2657248],
              [-62.4684354, 46.2654654],
              [-62.4678487, 46.2644823],
              [-62.4675954, 46.2641593],
              [-62.4665817, 46.2635403],
              [-62.4655121, 46.2628995],
              [-62.4651784, 46.2624942],
              [-62.465001, 46.2619865],
              [-62.4648219, 46.2617536],
              [-62.4644347, 46.2615416],
              [-62.4636492, 46.2612766],
              [-62.4626439, 46.2605384],
              [-62.4620183, 46.260203],
              [-62.4612221, 46.2596788],
              [-62.4609145, 46.2594774],
              [-62.4603706, 46.2590412],
              [-62.4588542, 46.2583894],
              [-62.4581336, 46.2579354],
              [-62.4576923, 46.2574269],
              [-62.4573226, 46.2572115],
              [-62.4568524, 46.2571202],
              [-62.4565282, 46.2569939],
              [-62.4561668, 46.2566594],
              [-62.4552823, 46.2557538],
              [-62.4543769, 46.2548198],
              [-62.4535702, 46.2541996],
              [-62.4528742, 46.253806],
              [-62.4518429, 46.2532818],
              [-62.4512993, 46.2530088],
              [-62.4501054, 46.2525947],
              [-62.4492808, 46.2520694],
              [-62.4483653, 46.2512026],
              [-62.447524, 46.2505892],
              [-62.4466088, 46.2502122],
              [-62.445846, 46.25],
              [-62.4442488, 46.2497311],
              [-62.4426055, 46.2495088],
              [-62.4421011, 46.2493327],
              [-62.4411336, 46.2488026],
              [-62.4412201, 46.2487139],
              [-62.441408, 46.2487361],
              [-62.4422307, 46.2489548],
              [-62.443246, 46.2488812],
              [-62.4443491, 46.2485358],
              [-62.4446148, 46.2480987],
              [-62.4450336, 46.2478539],
              [-62.4458931, 46.2477911],
              [-62.4462834, 46.2477085],
              [-62.446962, 46.2475242],
              [-62.4475834, 46.2475011],
              [-62.4478754, 46.2475945],
              [-62.4483307, 46.2476495],
              [-62.4481127, 46.247483],
              [-62.4475484, 46.2473447],
              [-62.4469771, 46.2473057],
              [-62.4465757, 46.2473838],
              [-62.4458907, 46.2474127],
              [-62.4450492, 46.2473806],
              [-62.4449082, 46.2474276],
              [-62.4439132, 46.2483659],
              [-62.443576, 46.24851],
              [-62.442838, 46.2486406],
              [-62.4414637, 46.2485946],
              [-62.4410621, 46.2485095],
              [-62.4406549, 46.2483404],
              [-62.4404773, 46.2479244],
              [-62.4403708, 46.2474748],
              [-62.4402324, 46.2473189],
              [-62.4395439, 46.2473157],
              [-62.4387053, 46.2474071],
              [-62.4359939, 46.24736],
              [-62.4353408, 46.2470952],
              [-62.4347968, 46.2470769],
              [-62.4337376, 46.2472046],
              [-62.4332608, 46.2473757],
              [-62.4323791, 46.2477563],
              [-62.4320725, 46.2477898],
              [-62.4317329, 46.2472289],
              [-62.4311957, 46.246948],
              [-62.4292041, 46.2465387],
              [-62.4281643, 46.2460728],
              [-62.4269723, 46.2459123],
              [-62.4261246, 46.245861],
              [-62.4254126, 46.2459685],
              [-62.4248599, 46.2460515],
              [-62.4239248, 46.2463339],
              [-62.4232727, 46.2465768],
              [-62.4230381, 46.2465472],
              [-62.4228429, 46.2464609],
              [-62.4227214, 46.246305],
              [-62.4225769, 46.2463874],
              [-62.4225538, 46.2465132],
              [-62.4225967, 46.2466416],
              [-62.4226815, 46.2467884],
              [-62.4226188, 46.2468983],
              [-62.4226414, 46.2471889],
              [-62.4225659, 46.2473727],
              [-62.4224694, 46.247493],
              [-62.4224682, 46.2477901],
              [-62.4224837, 46.2484974],
              [-62.4224823, 46.2494737],
              [-62.4224977, 46.2499585],
              [-62.4226231, 46.2502915],
              [-62.4229142, 46.2506347],
              [-62.4231495, 46.2510893],
              [-62.4231677, 46.2513148],
              [-62.4231305, 46.2515228],
              [-62.4230442, 46.2518533],
              [-62.4231245, 46.2519906],
              [-62.4233014, 46.2521266],
              [-62.4233192, 46.2522246],
              [-62.4233018, 46.2524804],
              [-62.4231453, 46.252456],
              [-62.4227723, 46.2523017],
              [-62.4226138, 46.2521878],
              [-62.4225537, 46.2520912],
              [-62.4225581, 46.2519871],
              [-62.4225972, 46.2518106],
              [-62.4225963, 46.2516965],
              [-62.4225259, 46.2515443],
              [-62.4224894, 46.2514267],
              [-62.4225252, 46.2512293],
              [-62.422543, 46.2509994],
              [-62.4225518, 46.2507244],
              [-62.4225093, 46.2506446],
              [-62.4224344, 46.2505958],
              [-62.4223402, 46.2505505],
              [-62.4222377, 46.2504874],
              [-62.4221582, 46.2503946],
              [-62.4221507, 46.2502621],
              [-62.4221917, 46.2498374],
              [-62.4222141, 46.2494183],
              [-62.4222491, 46.2492877],
              [-62.4222275, 46.249075],
              [-62.4222025, 46.2484193],
              [-62.4221297, 46.2482878],
              [-62.4220857, 46.24774],
              [-62.4221357, 46.2472752],
              [-62.4221186, 46.2468451],
              [-62.4221118, 46.2466184],
              [-62.4221078, 46.2463808],
              [-62.4220782, 46.2457596],
              [-62.4220459, 46.2455705],
              [-62.4220063, 46.2453039],
              [-62.4219954, 46.2450945],
              [-62.4218771, 46.2446152],
              [-62.421697, 46.2442831],
              [-62.4215245, 46.2441357],
              [-62.4212062, 46.2436413],
              [-62.4203392, 46.2425608],
              [-62.4196112, 46.2412822],
              [-62.4194049, 46.2410403],
              [-62.4192208, 46.2408798],
              [-62.4191425, 46.2407985],
              [-62.4190765, 46.2406649],
              [-62.4190345, 46.2404407],
              [-62.4190485, 46.2401541],
              [-62.4189144, 46.2397722],
              [-62.418875, 46.2394838],
              [-62.4188395, 46.2391185],
              [-62.418955, 46.2385644],
              [-62.4191571, 46.2380206],
              [-62.4191771, 46.2377257],
              [-62.4195772, 46.2369562],
              [-62.4198316, 46.2360661],
              [-62.4198386, 46.2354469],
              [-62.4196365, 46.2348157],
              [-62.4194619, 46.2344459],
              [-62.4192518, 46.2341794],
              [-62.4183703, 46.2334162],
              [-62.4183352, 46.2332193],
              [-62.4180667, 46.2316501],
              [-62.4179987, 46.2308072],
              [-62.4180522, 46.2302474],
              [-62.4180129, 46.2297371],
              [-62.4178594, 46.2292885],
              [-62.4176354, 46.228912],
              [-62.4174949, 46.2286154],
              [-62.417026, 46.228092],
              [-62.4156383, 46.2271132],
              [-62.4135355, 46.2256797],
              [-62.4128892, 46.2251981],
              [-62.4126994, 46.2251043],
              [-62.4125639, 46.2249959],
              [-62.4124966, 46.2248888],
              [-62.4123961, 46.2247125],
              [-62.4117601, 46.2242271],
              [-62.411593, 46.2240763],
              [-62.411386, 46.2238048],
              [-62.4108629, 46.222556],
              [-62.4104298, 46.2214291],
              [-62.4104004, 46.2214103],
              [-62.4104274, 46.2209479],
              [-62.4104098, 46.2209132],
              [-62.4103695, 46.2208923],
              [-62.4098568, 46.2206953],
              [-62.4097846, 46.2206923],
              [-62.4094554, 46.2205805],
              [-62.4089803, 46.2203473],
              [-62.4089629, 46.2203059],
              [-62.4089963, 46.2202805],
              [-62.4090556, 46.2202791],
              [-62.4095145, 46.2204914],
              [-62.4097363, 46.2205603],
              [-62.4097782, 46.2204998],
              [-62.4099376, 46.2205492],
              [-62.4112372, 46.220952],
              [-62.4112911, 46.2210085],
              [-62.4128092, 46.2215424],
              [-62.41434, 46.2219695],
              [-62.4144111, 46.222022],
              [-62.414319, 46.2221503],
              [-62.414323, 46.2222194],
              [-62.4144287, 46.2222685],
              [-62.4144377, 46.2222984],
              [-62.4145727, 46.2223581],
              [-62.4145989, 46.2224072],
              [-62.414588, 46.2224602],
              [-62.4143311, 46.2223806],
              [-62.4142775, 46.2226852],
              [-62.4145992, 46.222834],
              [-62.4147857, 46.2228573],
              [-62.4154203, 46.2227552],
              [-62.4161149, 46.2225867],
              [-62.417342, 46.2223961],
              [-62.4182827, 46.2223898],
              [-62.4183767, 46.2223131],
              [-62.4190635, 46.2222967],
              [-62.419776, 46.2224261],
              [-62.4203612, 46.2225638],
              [-62.4206729, 46.2226736],
              [-62.4209176, 46.2228264],
              [-62.4211363, 46.2230962],
              [-62.4222436, 46.2234566],
              [-62.4232544, 46.223603],
              [-62.4239813, 46.2237393],
              [-62.4243276, 46.2238039],
              [-62.4247527, 46.2235628],
              [-62.4250286, 46.2235753],
              [-62.4254826, 46.2236959],
              [-62.4259216, 46.2237162],
              [-62.426288, 46.2238443],
              [-62.426701, 46.2239021],
              [-62.4270261, 46.2238616],
              [-62.427298, 46.2237193],
              [-62.4276853, 46.2235132],
              [-62.4275611, 46.2234852],
              [-62.4268582, 46.2234457],
              [-62.4262928, 46.2233627],
              [-62.4256406, 46.2232623],
              [-62.4252345, 46.2231737],
              [-62.4246904, 46.2231099],
              [-62.4244686, 46.2230993],
              [-62.4243908, 46.2233533],
              [-62.4243753, 46.2231165],
              [-62.424367, 46.2228922],
              [-62.4244706, 46.222688],
              [-62.4246323, 46.2224806],
              [-62.4248645, 46.2223974],
              [-62.4251071, 46.2222854],
              [-62.4253157, 46.2220372],
              [-62.4254492, 46.2217738],
              [-62.4254151, 46.2215471],
              [-62.4252098, 46.2213189],
              [-62.4247733, 46.2211224],
              [-62.4234077, 46.2208649],
              [-62.4221473, 46.2208423],
              [-62.4216135, 46.2210115],
              [-62.4206302, 46.221046],
              [-62.4201466, 46.2209899],
              [-62.4197773, 46.2207743],
              [-62.4193753, 46.220291],
              [-62.4192713, 46.2202198],
              [-62.4182592, 46.2196003],
              [-62.4170226, 46.2190768],
              [-62.416116, 46.2188352],
              [-62.4155277, 46.218674],
              [-62.415014, 46.218501],
              [-62.4139798, 46.2181339],
              [-62.413586, 46.2179905],
              [-62.413092, 46.2179829],
              [-62.4127523, 46.2180526],
              [-62.4126023, 46.2181471],
              [-62.412663, 46.218181],
              [-62.4126881, 46.2183132],
              [-62.4128969, 46.2183641],
              [-62.4132689, 46.2187031],
              [-62.4139857, 46.2198501],
              [-62.4139697, 46.2203234],
              [-62.4138755, 46.2206029],
              [-62.4140615, 46.220839],
              [-62.4151973, 46.2213175],
              [-62.4152217, 46.2213902],
              [-62.4151455, 46.2216389],
              [-62.4151888, 46.2217243],
              [-62.4151205, 46.2217779],
              [-62.4143916, 46.221849],
              [-62.4146109, 46.221492],
              [-62.4139877, 46.221319],
              [-62.4138561, 46.2215578],
              [-62.4127244, 46.2212217],
              [-62.4113899, 46.2208347],
              [-62.411408, 46.2207913],
              [-62.4100417, 46.2203944],
              [-62.409767, 46.2202953],
              [-62.4093032, 46.2200823],
              [-62.4092722, 46.2200289],
              [-62.4092966, 46.2200003],
              [-62.4093601, 46.2199983],
              [-62.4098272, 46.2202022],
              [-62.4101496, 46.2202939],
              [-62.4102438, 46.2203122],
              [-62.4103415, 46.2202646],
              [-62.4104764, 46.2201998],
              [-62.4106851, 46.2199986],
              [-62.4108982, 46.2194489],
              [-62.4110391, 46.2189394],
              [-62.4111093, 46.2184405],
              [-62.4110797, 46.2178666],
              [-62.4108564, 46.2170046],
              [-62.4103623, 46.2161258],
              [-62.4092413, 46.2142968],
              [-62.4090561, 46.213979],
              [-62.4089487, 46.2137919],
              [-62.4088265, 46.2136099],
              [-62.4088135, 46.2134714],
              [-62.4088771, 46.2133792],
              [-62.4092429, 46.213103],
              [-62.4096634, 46.2128934],
              [-62.4110126, 46.2125634],
              [-62.4110869, 46.2125203],
              [-62.411371, 46.2123205],
              [-62.4116764, 46.2121608],
              [-62.4120465, 46.2118454],
              [-62.4124485, 46.2113855],
              [-62.4126527, 46.211031],
              [-62.4127722, 46.2108574],
              [-62.4135007, 46.2100348],
              [-62.4139499, 46.2093146],
              [-62.4140587, 46.2091373],
              [-62.4142384, 46.2087964],
              [-62.4145824, 46.2079427],
              [-62.4147787, 46.2074236],
              [-62.4149313, 46.2069681],
              [-62.4151278, 46.2061876],
              [-62.4154212, 46.2050258],
              [-62.4156275, 46.2042458],
              [-62.4156746, 46.2036249],
              [-62.4157839, 46.2019751],
              [-62.4156673, 46.20094],
              [-62.4155026, 46.1996302],
              [-62.4153727, 46.1991213],
              [-62.4150859, 46.1984403],
              [-62.4148631, 46.1979458],
              [-62.414419, 46.1971476],
              [-62.4134565, 46.1959303],
              [-62.4128276, 46.1954172],
              [-62.411336, 46.1946498],
              [-62.4105344, 46.1943062],
              [-62.409664, 46.1939288],
              [-62.4087733, 46.1934504],
              [-62.4071086, 46.1919919],
              [-62.4057012, 46.1907759],
              [-62.4040183, 46.1896407],
              [-62.4035366, 46.1893401],
              [-62.4029459, 46.1889656],
              [-62.4024226, 46.1885812],
              [-62.4020148, 46.1880036],
              [-62.4018697, 46.1876876],
              [-62.4018233, 46.1874635],
              [-62.4017581, 46.1869448],
              [-62.4016246, 46.186039],
              [-62.4015376, 46.185452],
              [-62.4013631, 46.1840425],
              [-62.4011377, 46.1833072],
              [-62.4010336, 46.1830327],
              [-62.4007013, 46.1824339],
              [-62.4002848, 46.1820818],
              [-62.3991901, 46.181282],
              [-62.3988752, 46.1810962],
              [-62.3978312, 46.1805751],
              [-62.3975152, 46.180424],
              [-62.3970566, 46.1799661],
              [-62.3969538, 46.1793626],
              [-62.3970122, 46.1792526],
              [-62.3971464, 46.1791592],
              [-62.397345, 46.1790551],
              [-62.3974641, 46.1789796],
              [-62.3977462, 46.1789339],
              [-62.3982341, 46.1788712],
              [-62.3985217, 46.1787851],
              [-62.3994718, 46.1781988],
              [-62.4011449, 46.1784339],
              [-62.4016317, 46.1786495],
              [-62.4016939, 46.1787067],
              [-62.4019395, 46.1792448],
              [-62.4020562, 46.1794264],
              [-62.4025025, 46.179675],
              [-62.4032864, 46.1799825],
              [-62.4038835, 46.1804338],
              [-62.4040214, 46.180626],
              [-62.404279, 46.1809847],
              [-62.4043199, 46.1812577],
              [-62.4042691, 46.1814546],
              [-62.4039476, 46.1825066],
              [-62.4036811, 46.1827463],
              [-62.4036107, 46.1829436],
              [-62.4036614, 46.1831096],
              [-62.4037749, 46.183645],
              [-62.404104, 46.1841268],
              [-62.4044927, 46.184505],
              [-62.4052596, 46.185012],
              [-62.4058832, 46.1853766],
              [-62.4071351, 46.1861236],
              [-62.4090683, 46.1869195],
              [-62.4101171, 46.1872937],
              [-62.4115624, 46.1875105],
              [-62.4120544, 46.1875988],
              [-62.4127034, 46.1877356],
              [-62.4144153, 46.1880224],
              [-62.4179566, 46.1884426],
              [-62.4184477, 46.1885893],
              [-62.4191066, 46.1888282],
              [-62.4196174, 46.1888945],
              [-62.4200523, 46.1889441],
              [-62.4204429, 46.1890211],
              [-62.4208825, 46.1890384],
              [-62.4211007, 46.189132],
              [-62.4219612, 46.1892781],
              [-62.4224976, 46.1892657],
              [-62.4232303, 46.1892859],
              [-62.423488, 46.1894845],
              [-62.4241365, 46.1896615],
              [-62.4247216, 46.1896847],
              [-62.4262504, 46.1900145],
              [-62.4268418, 46.1900229],
              [-62.4273676, 46.1900446],
              [-62.4294068, 46.190039],
              [-62.4297327, 46.1901099],
              [-62.4299196, 46.1902575],
              [-62.4301133, 46.1905219],
              [-62.4303372, 46.1908464],
              [-62.4303808, 46.1909431],
              [-62.4303628, 46.1910185],
              [-62.4301656, 46.1913701],
              [-62.4299028, 46.1918616],
              [-62.4297608, 46.1921778],
              [-62.4295993, 46.1926008],
              [-62.4297122, 46.193033],
              [-62.4299265, 46.1933792],
              [-62.4300895, 46.1936417],
              [-62.4302397, 46.1940469],
              [-62.4305023, 46.1942465],
              [-62.4310096, 46.1946421],
              [-62.4319612, 46.1954705],
              [-62.4320745, 46.1956295],
              [-62.4321114, 46.1959261],
              [-62.4320915, 46.1960654],
              [-62.4319413, 46.1963203],
              [-62.4315216, 46.1966328],
              [-62.4311277, 46.1970124],
              [-62.4303463, 46.1974645],
              [-62.4294, 46.1979951],
              [-62.4290523, 46.1981329],
              [-62.4282213, 46.1981556],
              [-62.4268371, 46.1975299],
              [-62.4262272, 46.1976376],
              [-62.4256405, 46.1978082],
              [-62.4250732, 46.1980111],
              [-62.4242339, 46.1983328],
              [-62.4232783, 46.1986936],
              [-62.4230375, 46.1987307],
              [-62.4223607, 46.1987307],
              [-62.4218384, 46.1987121],
              [-62.4215751, 46.1986691],
              [-62.4210025, 46.1985505],
              [-62.420252, 46.1985171],
              [-62.4197082, 46.1985503],
              [-62.4186825, 46.1985381],
              [-62.4181321, 46.198518],
              [-62.4177606, 46.1986488],
              [-62.4174034, 46.198836],
              [-62.4175375, 46.1990624],
              [-62.4174651, 46.1994984],
              [-62.4173992, 46.1998719],
              [-62.4173222, 46.200195],
              [-62.417274, 46.2004721],
              [-62.4171953, 46.2012271],
              [-62.4171881, 46.2017702],
              [-62.4172419, 46.2026671],
              [-62.417356, 46.2034282],
              [-62.4174532, 46.2039914],
              [-62.4175657, 46.2050759],
              [-62.417609, 46.2055378],
              [-62.4176014, 46.2060224],
              [-62.4176309, 46.2064871],
              [-62.4176965, 46.206984],
              [-62.4176787, 46.2073677],
              [-62.4176978, 46.2074165],
              [-62.4177863, 46.2079224],
              [-62.4177787, 46.2080955],
              [-62.4177536, 46.2082132],
              [-62.4176915, 46.2082901],
              [-62.4175554, 46.2083465],
              [-62.4175011, 46.2083357],
              [-62.4174864, 46.2082901],
              [-62.4175306, 46.2082914],
              [-62.417538, 46.2082134],
              [-62.4171549, 46.207607],
              [-62.416876, 46.206938],
              [-62.4164926, 46.2064962],
              [-62.4164352, 46.2064909],
              [-62.4163685, 46.2073803],
              [-62.4164013, 46.207497],
              [-62.4164317, 46.207802],
              [-62.4165403, 46.2081154],
              [-62.4167732, 46.2083066],
              [-62.4169608, 46.2083651],
              [-62.4168737, 46.2089143],
              [-62.4168465, 46.2094301],
              [-62.4166089, 46.2096418],
              [-62.4165157, 46.2098608],
              [-62.4159846, 46.2102744],
              [-62.4152748, 46.2100692],
              [-62.4147301, 46.2101641],
              [-62.4144682, 46.2104372],
              [-62.4146667, 46.2107414],
              [-62.4145133, 46.2110191],
              [-62.4144512, 46.2111747],
              [-62.4149644, 46.2115051],
              [-62.4149162, 46.2117356],
              [-62.4142071, 46.2118138],
              [-62.4142515, 46.2119952],
              [-62.4145916, 46.2120484],
              [-62.4153217, 46.2119388],
              [-62.4160483, 46.2117433],
              [-62.4172724, 46.2119108],
              [-62.4179918, 46.2118754],
              [-62.4189113, 46.2115202],
              [-62.4194157, 46.2112785],
              [-62.4201741, 46.2111767],
              [-62.4216985, 46.2111087],
              [-62.4224018, 46.2110567],
              [-62.4232181, 46.2108653],
              [-62.42433, 46.210218],
              [-62.4244786, 46.2101433],
              [-62.4251455, 46.2099548],
              [-62.4267605, 46.2095752],
              [-62.4277616, 46.2092825],
              [-62.428153, 46.2093434],
              [-62.4288222, 46.2096964],
              [-62.4301369, 46.2102502],
              [-62.4307915, 46.2105668],
              [-62.4310148, 46.210654],
              [-62.4313818, 46.2106544],
              [-62.4320878, 46.2103994],
              [-62.4321903, 46.2103272],
              [-62.4332463, 46.2098213],
              [-62.4339481, 46.2096259],
              [-62.4346694, 46.2094789],
              [-62.4355623, 46.2091745],
              [-62.4363883, 46.2091703],
              [-62.436957, 46.2093208],
              [-62.4400221, 46.2095271],
              [-62.4410819, 46.2095428],
              [-62.4417439, 46.209342],
              [-62.4421162, 46.2090997],
              [-62.442263, 46.2085552],
              [-62.4424931, 46.2083798],
              [-62.4428266, 46.208367],
              [-62.4437484, 46.2085532],
              [-62.4439487, 46.2087231],
              [-62.4441147, 46.2086451],
              [-62.4444809, 46.2085737],
              [-62.4454179, 46.2085414],
              [-62.4475285, 46.2087832],
              [-62.4483038, 46.2089325],
              [-62.4489966, 46.2089477],
              [-62.4501024, 46.2089608],
              [-62.4522898, 46.2090896],
              [-62.4528919, 46.2090898],
              [-62.4546397, 46.2092715],
              [-62.45511, 46.2092713],
              [-62.4561594, 46.2093541],
              [-62.4590874, 46.2092208],
              [-62.4611553, 46.2091702],
              [-62.462275, 46.2091478],
              [-62.4643141, 46.208933],
              [-62.4645735, 46.2088301],
              [-62.4652933, 46.2087028],
              [-62.4655723, 46.2086483],
              [-62.4665196, 46.2085485],
              [-62.467134, 46.2083698],
              [-62.4676033, 46.2081346],
              [-62.4685343, 46.207855],
              [-62.4688857, 46.2077472],
              [-62.4700922, 46.2073812],
              [-62.4708337, 46.2073541],
              [-62.4714216, 46.2076443],
              [-62.4717751, 46.2080064],
              [-62.4722483, 46.2081296],
              [-62.4728732, 46.2084648],
              [-62.4731551, 46.2085339],
              [-62.473403, 46.2086814],
              [-62.4735632, 46.2089376],
              [-62.4737985, 46.2095186],
              [-62.4738537, 46.2096319],
              [-62.4741779, 46.2099214],
              [-62.4745849, 46.2100901],
              [-62.4746331, 46.2101395],
              [-62.4752327, 46.2103425],
              [-62.476805, 46.2105777],
              [-62.478092, 46.2108751],
              [-62.4788821, 46.2114785],
              [-62.4792535, 46.2115824],
              [-62.4814026, 46.2116854],
              [-62.4823328, 46.2117519],
              [-62.4829014, 46.2117389],
              [-62.4839419, 46.2118688],
              [-62.4853655, 46.2120154],
              [-62.4859437, 46.2122813],
              [-62.4862375, 46.2126812],
              [-62.486602, 46.2128843],
              [-62.4869832, 46.2130123],
              [-62.4883595, 46.2135992],
              [-62.4885454, 46.2137327],
              [-62.4893065, 46.2144266],
              [-62.4898428, 46.2150535],
              [-62.48979, 46.2151552],
              [-62.4896867, 46.2151558],
              [-62.4893444, 46.2149614],
              [-62.4892722, 46.2147599],
              [-62.4887915, 46.2144097],
              [-62.4885564, 46.2144099],
              [-62.488124, 46.214527],
              [-62.487376, 46.214562],
              [-62.4868455, 46.214437],
              [-62.4853812, 46.2146315],
              [-62.4838774, 46.2145659],
              [-62.4833417, 46.2146836],
              [-62.4827477, 46.2149824],
              [-62.4823502, 46.2150927],
              [-62.4813832, 46.2151443],
              [-62.4790071, 46.2155037],
              [-62.4788572, 46.2155982],
              [-62.4774978, 46.2159354],
              [-62.4767414, 46.2159263],
              [-62.4752158, 46.2157603],
              [-62.4736149, 46.2156873],
              [-62.4729097, 46.2158511],
              [-62.4723813, 46.2161958],
              [-62.4722957, 46.2163562],
              [-62.472603, 46.2165575],
              [-62.4723847, 46.2166458],
              [-62.4718683, 46.2170666],
              [-62.4712365, 46.217412],
              [-62.4712011, 46.2175103],
              [-62.4710402, 46.2177638],
              [-62.4706328, 46.2178498],
              [-62.4700587, 46.2179422],
              [-62.4697763, 46.2181279],
              [-62.4695008, 46.2182143],
              [-62.4687187, 46.2183277],
              [-62.4685768, 46.2181398],
              [-62.4680782, 46.2178844],
              [-62.467178, 46.2173807],
              [-62.4667494, 46.2172749],
              [-62.4666467, 46.2171839],
              [-62.4664982, 46.2172586],
              [-62.4668259, 46.21758],
              [-62.4677849, 46.218229],
              [-62.4682009, 46.2183503],
              [-62.4684042, 46.2184805],
              [-62.4683793, 46.2186747],
              [-62.4684834, 46.2187459],
              [-62.4692402, 46.2185003],
              [-62.4698228, 46.2184519],
              [-62.4703488, 46.2183101],
              [-62.47063, 46.2183075],
              [-62.4713074, 46.2182145],
              [-62.4727984, 46.2175513],
              [-62.4732903, 46.217488],
              [-62.4736836, 46.2172741],
              [-62.4739577, 46.2172076],
              [-62.4740465, 46.2173339],
              [-62.4739999, 46.217428],
              [-62.4735406, 46.2178507],
              [-62.4735256, 46.2180691],
              [-62.4735893, 46.2182265],
              [-62.4740321, 46.2184602],
              [-62.4740863, 46.2187566],
              [-62.4742707, 46.21891],
              [-62.4754496, 46.2190327],
              [-62.4756493, 46.2191309],
              [-62.4759923, 46.219397],
              [-62.4764466, 46.2193802],
              [-62.4767308, 46.2195011],
              [-62.4769548, 46.2194966],
              [-62.4775244, 46.2191372],
              [-62.477747, 46.2191526],
              [-62.4778582, 46.2192876],
              [-62.4776903, 46.2194771],
              [-62.4776947, 46.2195808],
              [-62.4779007, 46.2196712],
              [-62.4783596, 46.2195033],
              [-62.4789994, 46.2194568],
              [-62.4793597, 46.2191382],
              [-62.4799132, 46.2183444],
              [-62.4803637, 46.2181324],
              [-62.4805585, 46.2178004],
              [-62.480822, 46.2176379],
              [-62.4816264, 46.2175331],
              [-62.4825208, 46.2169536],
              [-62.4828802, 46.2169814],
              [-62.4831543, 46.2169148],
              [-62.4841415, 46.216402],
              [-62.4849347, 46.2162928],
              [-62.4850887, 46.2161387],
              [-62.4850183, 46.2158258],
              [-62.4854344, 46.215529],
              [-62.4856603, 46.2154131],
              [-62.4861249, 46.2153289],
              [-62.4865031, 46.2153334],
              [-62.4871401, 46.2153266],
              [-62.4883682, 46.2153153],
              [-62.4888522, 46.2155343],
              [-62.4889938, 46.2155589],
              [-62.4893331, 46.2156298],
              [-62.4896192, 46.2156393],
              [-62.4915767, 46.2153614],
              [-62.4920573, 46.2152937],
              [-62.4924575, 46.2151436],
              [-62.4933528, 46.2147989],
              [-62.4941876, 46.2145835],
              [-62.4952014, 46.214183],
              [-62.4964789, 46.2137831],
              [-62.4973014, 46.2133285],
              [-62.4987005, 46.2127415],
              [-62.5000001, 46.212256],
              [-62.5026415, 46.2112069],
              [-62.5040388, 46.2104765],
              [-62.5048621, 46.2100934],
              [-62.5051527, 46.2099517],
              [-62.5064005, 46.2094791],
              [-62.506777, 46.2093401],
              [-62.5078677, 46.209153],
              [-62.5093111, 46.2090928],
              [-62.5097536, 46.209163],
              [-62.5102617, 46.209534],
              [-62.511884, 46.2108889],
              [-62.5126737, 46.2114203],
              [-62.5128797, 46.2115107],
              [-62.5135774, 46.2117923],
              [-62.514235, 46.2121601],
              [-62.5142358, 46.2122318],
              [-62.5139609, 46.2122268],
              [-62.513829, 46.2122265],
              [-62.5136225, 46.2122277],
              [-62.5131934, 46.2119589],
              [-62.5125435, 46.2118182],
              [-62.5120513, 46.2117185],
              [-62.511145, 46.2111314],
              [-62.5109189, 46.2110842],
              [-62.5107879, 46.2111556],
              [-62.5103395, 46.2112563],
              [-62.5100149, 46.2112218],
              [-62.5091191, 46.2112402],
              [-62.5088786, 46.2113199],
              [-62.5085548, 46.2116119],
              [-62.5083915, 46.2121599],
              [-62.5083389, 46.2126797],
              [-62.5083259, 46.2127868],
              [-62.5084027, 46.2130918],
              [-62.5085948, 46.2132176],
              [-62.5090942, 46.2131264],
              [-62.5096454, 46.2131166],
              [-62.5103107, 46.2129473],
              [-62.5106514, 46.2127435],
              [-62.5113185, 46.2124628],
              [-62.5114808, 46.2123527],
              [-62.5116842, 46.2122279],
              [-62.5116311, 46.2121665],
              [-62.5118009, 46.2120287],
              [-62.5123284, 46.2120301],
              [-62.5128245, 46.212325],
              [-62.5132747, 46.2123676],
              [-62.5135688, 46.2125126],
              [-62.513993, 46.2125145],
              [-62.5143626, 46.2127296],
              [-62.5145919, 46.2129003],
              [-62.5148131, 46.2129355],
              [-62.5151873, 46.2129994],
              [-62.5155354, 46.2132776],
              [-62.5160159, 46.2134645],
              [-62.5165965, 46.213782],
              [-62.5168106, 46.214008],
              [-62.5168284, 46.2141678],
              [-62.5166795, 46.2143341],
              [-62.5167658, 46.2145002],
              [-62.5170907, 46.2146979],
              [-62.5180402, 46.2149041],
              [-62.5186432, 46.2152303],
              [-62.5193226, 46.2154436],
              [-62.519986, 46.2158952],
              [-62.5216238, 46.2167767],
              [-62.5221499, 46.2173075],
              [-62.5227776, 46.2176942],
              [-62.5235496, 46.2180657],
              [-62.5243713, 46.2182119],
              [-62.5248926, 46.218221],
              [-62.5253169, 46.2182229],
              [-62.5271205, 46.2178437],
              [-62.5275309, 46.2176262],
              [-62.5271094, 46.2165654],
              [-62.5274666, 46.2165412],
              [-62.5277638, 46.2173192],
              [-62.5278474, 46.217525],
              [-62.5282923, 46.217647],
              [-62.5290447, 46.2177153],
              [-62.5312881, 46.2177007],
              [-62.5320415, 46.2175858],
              [-62.5325377, 46.2176258],
              [-62.5329862, 46.2177797],
              [-62.5335437, 46.2182716],
              [-62.533655, 46.2184066],
              [-62.5340235, 46.2188963],
              [-62.5342033, 46.2195471],
              [-62.5341005, 46.2199658],
              [-62.5340482, 46.2202308],
              [-62.5341363, 46.2205402],
              [-62.5343697, 46.2209061],
              [-62.5342964, 46.2211426],
              [-62.5344252, 46.2215289],
              [-62.534702, 46.2216773],
              [-62.5352654, 46.2217433],
              [-62.5358042, 46.2217489],
              [-62.5359973, 46.2216915],
              [-62.5363895, 46.2216604],
              [-62.5388195, 46.2214327],
              [-62.5396006, 46.2215018],
              [-62.5399369, 46.2217038],
              [-62.5401141, 46.2221396],
              [-62.5401885, 46.2229939],
              [-62.5404497, 46.2235438],
              [-62.5405154, 46.2238446],
              [-62.5408259, 46.2245155],
              [-62.5409579, 46.2247706],
              [-62.5409473, 46.2254391],
              [-62.5406966, 46.225841],
              [-62.540317, 46.2261114],
              [-62.5398998, 46.2261735],
              [-62.5395131, 46.2263799],
              [-62.5392068, 46.2264138],
              [-62.5387829, 46.2265752],
              [-62.5384453, 46.2269027],
              [-62.5383144, 46.2272289],
              [-62.5379165, 46.2276857],
              [-62.5378633, 46.2278791],
              [-62.5375946, 46.2281212],
              [-62.5373541, 46.2284558],
              [-62.5369185, 46.2287044],
              [-62.5366897, 46.2292066],
              [-62.5359746, 46.2296014],
              [-62.53593, 46.2298388],
              [-62.5356773, 46.2300974],
              [-62.5352798, 46.2302079],
              [-62.5348438, 46.2302932],
              [-62.5345325, 46.230315],
              [-62.5339386, 46.2303594],
              [-62.5335647, 46.2302039],
              [-62.5332275, 46.230185],
              [-62.5330853, 46.2302521],
              [-62.5324655, 46.2302558],
              [-62.5321838, 46.2303502],
              [-62.5315246, 46.2305119],
              [-62.5309151, 46.2309579],
              [-62.530789, 46.231551],
              [-62.5304272, 46.2317263],
              [-62.5299021, 46.2316853],
              [-62.5295533, 46.2317536],
              [-62.5290283, 46.2317127],
              [-62.5280882, 46.2317856],
              [-62.5278409, 46.2317098],
              [-62.5278977, 46.2315485],
              [-62.5277327, 46.2314436],
              [-62.5269009, 46.2312731],
              [-62.5254282, 46.2308231],
              [-62.5246881, 46.2304844],
              [-62.5245504, 46.2301455],
              [-62.5242509, 46.2298252],
              [-62.5241301, 46.2298292],
              [-62.5235465, 46.230061],
              [-62.5214996, 46.2304681],
              [-62.5203458, 46.2304782],
              [-62.5198649, 46.2308009],
              [-62.5197124, 46.2309353],
              [-62.5199579, 46.2308676],
              [-62.5201977, 46.2307162],
              [-62.5204915, 46.2306979],
              [-62.5211153, 46.2311443],
              [-62.521244, 46.2312759],
              [-62.5212516, 46.2315031],
              [-62.5208499, 46.2319279],
              [-62.520509, 46.2326414],
              [-62.5200509, 46.2331361],
              [-62.5191893, 46.2339122],
              [-62.5189934, 46.2342641],
              [-62.5186731, 46.2345881],
              [-62.5176367, 46.2349803],
              [-62.5172278, 46.2350864],
              [-62.5162639, 46.2354251],
              [-62.5163632, 46.2354841],
              [-62.5170654, 46.2354513],
              [-62.5185714, 46.2349869],
              [-62.5187208, 46.2349838],
              [-62.5195693, 46.2348244],
              [-62.5198578, 46.2346308],
              [-62.5201835, 46.2339725],
              [-62.5204211, 46.2337692],
              [-62.5205298, 46.2334344],
              [-62.5209633, 46.233134],
              [-62.5211722, 46.2329298],
              [-62.521187, 46.2324565],
              [-62.5217188, 46.2321434],
              [-62.5219076, 46.2319823],
              [-62.5220468, 46.2312822],
              [-62.5223979, 46.2310109],
              [-62.5228178, 46.2309092],
              [-62.5235938, 46.2305483],
              [-62.5240182, 46.2305502],
              [-62.5244037, 46.2308733],
              [-62.5248857, 46.2312951],
              [-62.5255869, 46.2317001],
              [-62.5260297, 46.2317703],
              [-62.5262264, 46.2319997],
              [-62.526217, 46.2321387],
              [-62.5268744, 46.2325981],
              [-62.5271168, 46.2326617],
              [-62.528882, 46.2326037],
              [-62.5290198, 46.2326878],
              [-62.529285, 46.2329234],
              [-62.5301029, 46.2328743],
              [-62.5304996, 46.232947],
              [-62.5308064, 46.2330764],
              [-62.5310381, 46.2330441],
              [-62.5311092, 46.2330106],
              [-62.5307009, 46.2327704],
              [-62.5307049, 46.2327108],
              [-62.531005, 46.2324299],
              [-62.5312416, 46.2319001],
              [-62.5320166, 46.2314674],
              [-62.5326476, 46.231468],
              [-62.5330063, 46.2316788],
              [-62.5334727, 46.2317377],
              [-62.5344021, 46.2320785],
              [-62.5364504, 46.2324157],
              [-62.5376822, 46.2327821],
              [-62.538095, 46.233126],
              [-62.5386232, 46.2330356],
              [-62.5387645, 46.2328969],
              [-62.5387988, 46.232472],
              [-62.5382035, 46.2320267],
              [-62.5380679, 46.2317396],
              [-62.5381269, 46.2313752],
              [-62.538489, 46.2308535],
              [-62.5384921, 46.2307222],
              [-62.5383396, 46.2306018],
              [-62.5379344, 46.2304851],
              [-62.5377372, 46.2303474],
              [-62.5376571, 46.2301736],
              [-62.5372666, 46.2298384],
              [-62.5372693, 46.2295439],
              [-62.5373918, 46.2294284],
              [-62.538435, 46.2288452],
              [-62.5386366, 46.228577],
              [-62.5386834, 46.2278818],
              [-62.5395432, 46.2271254],
              [-62.539841, 46.2270475],
              [-62.5408494, 46.2272354],
              [-62.5415367, 46.2271661],
              [-62.5424225, 46.2269599],
              [-62.5439369, 46.2264476],
              [-62.5449116, 46.2261129],
              [-62.5477171, 46.2249098],
              [-62.548309, 46.2247219],
              [-62.548969, 46.2246318],
              [-62.5499215, 46.2247979],
              [-62.5510762, 46.2251139],
              [-62.5516818, 46.2254918],
              [-62.5520522, 46.22587],
              [-62.5521333, 46.2261155],
              [-62.552047, 46.2262042],
              [-62.5517658, 46.2262071],
              [-62.5515897, 46.2263527],
              [-62.5514748, 46.2264406],
              [-62.5514603, 46.2269139],
              [-62.5513204, 46.2270328],
              [-62.5512768, 46.2275967],
              [-62.5509845, 46.22785],
              [-62.5505864, 46.2286533],
              [-62.5503166, 46.2302809],
              [-62.5498025, 46.2305907],
              [-62.5488487, 46.230954],
              [-62.548368, 46.2310221],
              [-62.5482379, 46.2311652],
              [-62.5482993, 46.2313623],
              [-62.5485426, 46.2314975],
              [-62.5489845, 46.2314959],
              [-62.549589, 46.2312925],
              [-62.5519161, 46.2316662],
              [-62.5524576, 46.231632],
              [-62.5529128, 46.2314317],
              [-62.5536808, 46.2308433],
              [-62.5539799, 46.2307455],
              [-62.5549561, 46.2306456],
              [-62.5558925, 46.2305404],
              [-62.5563787, 46.2306477],
              [-62.5567237, 46.2308937],
              [-62.5571158, 46.2314637],
              [-62.557491, 46.2322004],
              [-62.5575097, 46.2327783],
              [-62.5578153, 46.2329275],
              [-62.5583959, 46.2330815],
              [-62.5587704, 46.2334002],
              [-62.5588337, 46.2337406],
              [-62.5587252, 46.2340755],
              [-62.558019, 46.2353706],
              [-62.5576318, 46.2356687],
              [-62.5573751, 46.235732],
              [-62.5567546, 46.2361739],
              [-62.5562641, 46.2370738],
              [-62.5562472, 46.2372404],
              [-62.556552, 46.2375728],
              [-62.5567017, 46.2380792],
              [-62.5572917, 46.2389503],
              [-62.5573478, 46.2392268],
              [-62.5572784, 46.2397501],
              [-62.5564066, 46.2412867],
              [-62.5560619, 46.2415503],
              [-62.5558199, 46.24165],
              [-62.555731, 46.2417785],
              [-62.5560097, 46.2418154],
              [-62.5563089, 46.2417175],
              [-62.5568459, 46.2413247],
              [-62.5572428, 46.240796],
              [-62.5578809, 46.2397496],
              [-62.5578711, 46.2391242],
              [-62.5581157, 46.2389848],
              [-62.5586766, 46.2390904],
              [-62.5589696, 46.2390003],
              [-62.5580509, 46.2384094],
              [-62.55787, 46.2381965],
              [-62.5571659, 46.2366291],
              [-62.5591312, 46.2352113],
              [-62.5597627, 46.2339178],
              [-62.5600143, 46.2335875],
              [-62.5604185, 46.2333776],
              [-62.5606084, 46.2331966],
              [-62.5604111, 46.2330589],
              [-62.5596064, 46.2329095],
              [-62.5589114, 46.2321506],
              [-62.5582938, 46.2316052],
              [-62.5572962, 46.230311],
              [-62.5568313, 46.2298859],
              [-62.556028, 46.2297167],
              [-62.5551256, 46.2297434],
              [-62.5540993, 46.2299053],
              [-62.5533038, 46.2299633],
              [-62.5526273, 46.2301285],
              [-62.5522079, 46.2303937],
              [-62.5520398, 46.2304201],
              [-62.5518188, 46.2302935],
              [-62.5518281, 46.2301545],
              [-62.5516411, 46.2299494],
              [-62.5516419, 46.2297663],
              [-62.5527748, 46.2282701],
              [-62.5527732, 46.2277804],
              [-62.5528393, 46.2277347],
              [-62.5528978, 46.2274619],
              [-62.5529064, 46.2269048],
              [-62.5535123, 46.2260804],
              [-62.5538748, 46.2257218],
              [-62.5541322, 46.2254753],
              [-62.5550383, 46.2248795],
              [-62.5561014, 46.2245077],
              [-62.5567706, 46.2242785],
              [-62.5572164, 46.2242172],
              [-62.5585739, 46.2242451],
              [-62.5587466, 46.2243223],
              [-62.5591205, 46.2244776],
              [-62.5594603, 46.2244567],
              [-62.5601434, 46.2241921],
              [-62.5604358, 46.2241936],
              [-62.560896, 46.2242601],
              [-62.5610887, 46.2242942],
              [-62.561239, 46.2243627],
              [-62.5614965, 46.224371],
              [-62.5619781, 46.2243746],
              [-62.5622772, 46.2242767],
              [-62.5626902, 46.2246204],
              [-62.5629058, 46.2248264],
              [-62.5628787, 46.2250604],
              [-62.563052, 46.2253009],
              [-62.5632506, 46.2254188],
              [-62.5642151, 46.2260071],
              [-62.5641585, 46.2261685],
              [-62.5637576, 46.226502],
              [-62.5636492, 46.2268369],
              [-62.5637711, 46.2271593],
              [-62.5646987, 46.2273564],
              [-62.5652487, 46.2273661],
              [-62.5664172, 46.2273917],
              [-62.5688788, 46.2277256],
              [-62.5691877, 46.2276519],
              [-62.570019, 46.2276586],
              [-62.5709833, 46.227391],
              [-62.5715969, 46.2273948],
              [-62.5721376, 46.2275435],
              [-62.5725518, 46.2276125],
              [-62.5731834, 46.2277761],
              [-62.5733087, 46.2278756],
              [-62.5739873, 46.2281084],
              [-62.5743952, 46.2281852],
              [-62.5745261, 46.2281137],
              [-62.5746851, 46.2278799],
              [-62.5749701, 46.2275626],
              [-62.5758568, 46.2270814],
              [-62.5768691, 46.2266083],
              [-62.5778002, 46.226236],
              [-62.5781805, 46.2260371],
              [-62.5800448, 46.2253444],
              [-62.5801445, 46.2253117],
              [-62.5805745, 46.224796],
              [-62.580763, 46.2246348],
              [-62.5808979, 46.2245038],
              [-62.5812213, 46.2245579],
              [-62.582834, 46.2252345],
              [-62.584414, 46.2260613],
              [-62.5858091, 46.2267348],
              [-62.5870815, 46.2272691],
              [-62.5883162, 46.2279415],
              [-62.5893841, 46.2286204],
              [-62.5899223, 46.2288088],
              [-62.5905135, 46.2291501],
              [-62.5906622, 46.22933],
              [-62.5906724, 46.229609],
              [-62.5905172, 46.2298748],
              [-62.5896826, 46.2304374],
              [-62.5892638, 46.2306112],
              [-62.588864, 46.2306701],
              [-62.5884488, 46.2305294],
              [-62.5877323, 46.2304349],
              [-62.5871059, 46.2305384],
              [-62.5866634, 46.2307233],
              [-62.5857813, 46.2313082],
              [-62.5855487, 46.2316153],
              [-62.5851559, 46.2318297],
              [-62.5855803, 46.2318313],
              [-62.5857761, 46.2317341],
              [-62.5858873, 46.2316142],
              [-62.5865213, 46.2311368],
              [-62.5868848, 46.2311046],
              [-62.5873599, 46.230861],
              [-62.5880481, 46.230863],
              [-62.5892102, 46.2320267],
              [-62.5893514, 46.2322343],
              [-62.589257, 46.2325338],
              [-62.5890875, 46.2329265],
              [-62.5892584, 46.2342458],
              [-62.5891472, 46.234712],
              [-62.5887465, 46.2350456],
              [-62.5887014, 46.2351198],
              [-62.5891423, 46.2350463],
              [-62.5894015, 46.2349431],
              [-62.5896325, 46.2347474],
              [-62.5897608, 46.2343693],
              [-62.5895097, 46.2339154],
              [-62.5894465, 46.2334834],
              [-62.5895195, 46.2329005],
              [-62.5898551, 46.2323379],
              [-62.5902548, 46.2319326],
              [-62.59044, 46.2316479],
              [-62.590967, 46.2311392],
              [-62.5914148, 46.2308748],
              [-62.5938421, 46.2307769],
              [-62.5957098, 46.2305537],
              [-62.5965049, 46.2305869],
              [-62.5971413, 46.230854],
              [-62.5976831, 46.2307279],
              [-62.5982717, 46.2307625],
              [-62.5994545, 46.2310071],
              [-62.6004054, 46.2313757],
              [-62.6006617, 46.2314038],
              [-62.6016304, 46.2316774],
              [-62.6020506, 46.2318301],
              [-62.6026692, 46.2321921],
              [-62.6041585, 46.2335332],
              [-62.6045126, 46.2337315],
              [-62.606444, 46.2346328],
              [-62.6066886, 46.2348396],
              [-62.6063064, 46.2352415],
              [-62.6063447, 46.2353582],
              [-62.607223, 46.2350874],
              [-62.6074882, 46.235068],
              [-62.6089563, 46.2356877],
              [-62.609189, 46.235727],
              [-62.6094437, 46.2358664],
              [-62.6101234, 46.2362621],
              [-62.6104643, 46.2363127],
              [-62.6106972, 46.2362604],
              [-62.6113827, 46.2363936],
              [-62.6118605, 46.2364564],
              [-62.6125252, 46.2364695],
              [-62.6126652, 46.2362589],
              [-62.6128971, 46.2361349],
              [-62.613136, 46.2361663],
              [-62.6133619, 46.2363964],
              [-62.6134063, 46.2365053],
              [-62.6137012, 46.2365584],
              [-62.6140226, 46.2364691],
              [-62.6144296, 46.236474],
              [-62.6146992, 46.2366497],
              [-62.6148304, 46.2369246],
              [-62.61537, 46.2379688],
              [-62.6153088, 46.2384645],
              [-62.6151536, 46.2387303],
              [-62.6151114, 46.2391112],
              [-62.6151658, 46.2392443],
              [-62.6153535, 46.2393577],
              [-62.6155601, 46.2393562],
              [-62.6156348, 46.2372565],
              [-62.6155968, 46.2370483],
              [-62.6153662, 46.2367146],
              [-62.6146805, 46.2362351],
              [-62.6142751, 46.2361187],
              [-62.6139368, 46.2360284],
              [-62.6134129, 46.2359681],
              [-62.6131543, 46.2358882],
              [-62.6128626, 46.2356123],
              [-62.6129431, 46.2349102],
              [-62.6126194, 46.2345098],
              [-62.6124712, 46.2341468],
              [-62.6127072, 46.2338716],
              [-62.6131986, 46.2335527],
              [-62.6139601, 46.2332264],
              [-62.6145766, 46.2330986],
              [-62.6152709, 46.2327463],
              [-62.6155452, 46.2325901],
              [-62.6156635, 46.2327254],
              [-62.6157926, 46.2328199],
              [-62.6159925, 46.2328527],
              [-62.6162289, 46.2328537],
              [-62.6164707, 46.2327842],
              [-62.6169023, 46.2326736],
              [-62.6180522, 46.2324278],
              [-62.618148, 46.2323892],
              [-62.6182295, 46.2323422],
              [-62.618305, 46.2322547],
              [-62.6183377, 46.2321672],
              [-62.6183883, 46.2318048],
              [-62.6184079, 46.2317831],
              [-62.6184409, 46.2317743],
              [-62.6187362, 46.2317836],
              [-62.6187713, 46.2318023],
              [-62.6187823, 46.2318283],
              [-62.618685, 46.2323466],
              [-62.6189036, 46.2324629],
              [-62.6197272, 46.2330264],
              [-62.6203101, 46.2333234],
              [-62.6207093, 46.2338854],
              [-62.6208907, 46.2340066],
              [-62.620765, 46.2335607],
              [-62.6208092, 46.2333233],
              [-62.6209165, 46.2332629],
              [-62.6211155, 46.2332891],
              [-62.6215629, 46.233554],
              [-62.6231675, 46.234369],
              [-62.6236786, 46.2345364],
              [-62.6241827, 46.2345483],
              [-62.6242948, 46.2345],
              [-62.6249561, 46.2343895],
              [-62.6250001, 46.2343907],
              [-62.6259469, 46.2344167],
              [-62.627359, 46.2346681],
              [-62.6302899, 46.2347643],
              [-62.6305112, 46.2348907],
              [-62.6305545, 46.2350194],
              [-62.6303521, 46.2353077],
              [-62.6304, 46.2355401],
              [-62.6308347, 46.235912],
              [-62.630936, 46.2362059],
              [-62.631688, 46.2366395],
              [-62.6318348, 46.236676],
              [-62.6318387, 46.2366164],
              [-62.6317442, 46.2365696],
              [-62.6315107, 46.2363672],
              [-62.631367, 46.2361079],
              [-62.6308305, 46.2356253],
              [-62.6307591, 46.2353125],
              [-62.6308366, 46.2351796],
              [-62.6311283, 46.2350176],
              [-62.6317196, 46.2350123],
              [-62.6341493, 46.2354946],
              [-62.6349709, 46.2354766],
              [-62.635369, 46.2355289],
              [-62.6354539, 46.2354599],
              [-62.634693, 46.2350738],
              [-62.6337526, 46.2348014],
              [-62.6332112, 46.2348362],
              [-62.6329386, 46.2347918],
              [-62.6324926, 46.234507],
              [-62.6317787, 46.2342816],
              [-62.6306604, 46.2340116],
              [-62.6289755, 46.2338073],
              [-62.6274892, 46.2339039],
              [-62.6267218, 46.2337086],
              [-62.6260659, 46.2336481],
              [-62.6255745, 46.2335291],
              [-62.6250001, 46.2332987],
              [-62.6248422, 46.2332354],
              [-62.6246359, 46.2331453],
              [-62.6239106, 46.233007],
              [-62.623636, 46.2328192],
              [-62.6235892, 46.2322205],
              [-62.6233939, 46.2316968],
              [-62.6231059, 46.2314529],
              [-62.6227741, 46.2317012],
              [-62.6226305, 46.2318798],
              [-62.6218298, 46.2320178],
              [-62.6208964, 46.2319923],
              [-62.6198628, 46.2315724],
              [-62.6194423, 46.2313428],
              [-62.6193128, 46.2313039],
              [-62.6191813, 46.2313024],
              [-62.6190657, 46.2313367],
              [-62.6189767, 46.2313886],
              [-62.6189141, 46.2314553],
              [-62.6188828, 46.2315201],
              [-62.618879, 46.2316384],
              [-62.6188566, 46.2316584],
              [-62.6188207, 46.231671],
              [-62.6184941, 46.2316702],
              [-62.618464, 46.2316611],
              [-62.6184418, 46.2316389],
              [-62.6184485, 46.2315558],
              [-62.6179268, 46.2315244],
              [-62.6179235, 46.2312199],
              [-62.6177172, 46.2309217],
              [-62.6176143, 46.2308333],
              [-62.6175072, 46.2307593],
              [-62.6174188, 46.230726],
              [-62.6173344, 46.2307168],
              [-62.6172526, 46.2307261],
              [-62.616655, 46.2308331],
              [-62.6160829, 46.2308611],
              [-62.615905, 46.2308634],
              [-62.6151803, 46.2309799],
              [-62.6149562, 46.2309847],
              [-62.6137116, 46.2309812],
              [-62.6126783, 46.2310799],
              [-62.6121368, 46.2311146],
              [-62.6107443, 46.2314407],
              [-62.6099219, 46.2317332],
              [-62.6096146, 46.2316956],
              [-62.6083261, 46.2306157],
              [-62.6081533, 46.2305386],
              [-62.6075791, 46.2302855],
              [-62.6063297, 46.2298319],
              [-62.6056502, 46.2294361],
              [-62.603675, 46.2278965],
              [-62.6025523, 46.2271762],
              [-62.5993712, 46.2260958],
              [-62.5968732, 46.2254432],
              [-62.5950206, 46.2242257],
              [-62.5939706, 46.2234519],
              [-62.5937959, 46.2232313],
              [-62.5930234, 46.2220761],
              [-62.5925204, 46.2217894],
              [-62.5906682, 46.2212646],
              [-62.5899978, 46.2211676],
              [-62.5886502, 46.2211644],
              [-62.5883953, 46.2211165],
              [-62.5874582, 46.2211503],
              [-62.586654, 46.221256],
              [-62.5857406, 46.2212786],
              [-62.5850916, 46.2211186],
              [-62.5845926, 46.2207722],
              [-62.5844403, 46.2205603],
              [-62.5839401, 46.2198875],
              [-62.5835155, 46.2196311],
              [-62.5832382, 46.2195745],
              [-62.5829896, 46.2195187],
              [-62.5817279, 46.2195182],
              [-62.5772565, 46.2200042],
              [-62.5768799, 46.2198886],
              [-62.5763561, 46.2195732],
              [-62.5755293, 46.219069],
              [-62.5747995, 46.2189183],
              [-62.5725905, 46.2190186],
              [-62.5718932, 46.2191555],
              [-62.5715955, 46.2192335],
              [-62.570952, 46.2195951],
              [-62.5707519, 46.2198434],
              [-62.5707482, 46.2201578],
              [-62.571031, 46.2203898],
              [-62.5712713, 46.2206563],
              [-62.5713337, 46.220925],
              [-62.5711248, 46.2212209],
              [-62.5708178, 46.221438],
              [-62.5705741, 46.2213943],
              [-62.5696145, 46.2208181],
              [-62.568749, 46.2203803],
              [-62.5683625, 46.2202405],
              [-62.5682418, 46.2202446],
              [-62.5672676, 46.2202332],
              [-62.5668692, 46.2202721],
              [-62.5666341, 46.2202725],
              [-62.5659518, 46.2203541],
              [-62.5649378, 46.2203374],
              [-62.5639518, 46.2201584],
              [-62.5634039, 46.2199457],
              [-62.5629364, 46.2195605],
              [-62.5627935, 46.2192095],
              [-62.5625377, 46.2190898],
              [-62.5622717, 46.2190375],
              [-62.5618637, 46.2187058],
              [-62.5614712, 46.2182274],
              [-62.5610964, 46.2180004],
              [-62.5605679, 46.2179276],
              [-62.5571553, 46.2178456],
              [-62.5561372, 46.2176336],
              [-62.5555085, 46.2174302],
              [-62.5552125, 46.2173967],
              [-62.5547113, 46.2173447],
              [-62.553789, 46.2174145],
              [-62.5528112, 46.217371],
              [-62.5525313, 46.217354],
              [-62.5520243, 46.2172182],
              [-62.5510493, 46.2165338],
              [-62.5500642, 46.2159168],
              [-62.5497364, 46.2157589],
              [-62.5474895, 46.2146313],
              [-62.5469096, 46.2143856],
              [-62.5454493, 46.2137571],
              [-62.5452816, 46.213692],
              [-62.5441344, 46.2131851],
              [-62.5429161, 46.2129665],
              [-62.5417206, 46.2124102],
              [-62.5411515, 46.2122604],
              [-62.5407349, 46.2122309],
              [-62.5399131, 46.2120849],
              [-62.5390437, 46.2120527],
              [-62.5386401, 46.212171],
              [-62.5380506, 46.2126654],
              [-62.537723, 46.2127622],
              [-62.5374333, 46.212721],
              [-62.5374114, 46.2126208],
              [-62.5373698, 46.2124721],
              [-62.5370748, 46.2111448],
              [-62.5369894, 46.2107957],
              [-62.53679, 46.2103513],
              [-62.5362831, 46.2097059],
              [-62.5358512, 46.2094769],
              [-62.5354972, 46.2093699],
              [-62.5338741, 46.2090345],
              [-62.5329837, 46.2087189],
              [-62.53194, 46.2079565],
              [-62.531552, 46.2076731],
              [-62.5306165, 46.2076865],
              [-62.5303062, 46.2075251],
              [-62.5303255, 46.2071555],
              [-62.5305114, 46.2065246],
              [-62.5304864, 46.2057913],
              [-62.5302325, 46.2053054],
              [-62.5301833, 46.2049296],
              [-62.5301417, 46.2045262],
              [-62.5293882, 46.2034586],
              [-62.5289869, 46.2031192],
              [-62.5286664, 46.2030251],
              [-62.5282758, 46.2027815],
              [-62.5279428, 46.2024482],
              [-62.5268689, 46.2012867],
              [-62.5267893, 46.2010213],
              [-62.5268117, 46.2007753],
              [-62.5264832, 46.2005456],
              [-62.5259461, 46.2002654],
              [-62.5252691, 46.200104],
              [-62.5246434, 46.2001154],
              [-62.5227079, 46.2001677],
              [-62.5225179, 46.2000939],
              [-62.5221578, 46.1997398],
              [-62.5215037, 46.198986],
              [-62.5201136, 46.1979255],
              [-62.519213, 46.1970044],
              [-62.5186799, 46.1966645],
              [-62.5178024, 46.1963333],
              [-62.5178175, 46.1965328],
              [-62.5183947, 46.1972364],
              [-62.5183901, 46.1976423],
              [-62.5185876, 46.1979433],
              [-62.5187001, 46.1983132],
              [-62.5187451, 46.1987486],
              [-62.5188907, 46.1989683],
              [-62.5192464, 46.1992188],
              [-62.5204748, 46.1997883],
              [-62.5208385, 46.2001744],
              [-62.5209497, 46.2003094],
              [-62.5209443, 46.2003888],
              [-62.5213202, 46.2005962],
              [-62.5217751, 46.2006508],
              [-62.5218653, 46.2007573],
              [-62.5218506, 46.2009757],
              [-62.5214322, 46.2013124],
              [-62.5208324, 46.2016193],
              [-62.5206911, 46.2017579],
              [-62.5205181, 46.202027],
              [-62.5205131, 46.2022696],
              [-62.520877, 46.2026557],
              [-62.5208559, 46.2028819],
              [-62.5206359, 46.2030818],
              [-62.5204344, 46.2030951],
              [-62.520244, 46.2031128],
              [-62.519634, 46.2029774],
              [-62.5188126, 46.2025764],
              [-62.5186472, 46.2023082],
              [-62.5186109, 46.2011526],
              [-62.5185694, 46.2007492],
              [-62.5177426, 46.1975534],
              [-62.5174898, 46.1971391],
              [-62.5172496, 46.1964546],
              [-62.5170705, 46.195967],
              [-62.5166828, 46.1954289],
              [-62.5163438, 46.1951033],
              [-62.51575, 46.1947296],
              [-62.5152608, 46.1945902],
              [-62.5136227, 46.1944013],
              [-62.512649, 46.1943893],
              [-62.5121982, 46.194275],
              [-62.5113106, 46.1937563],
              [-62.5101962, 46.1926092],
              [-62.5086417, 46.1916984],
              [-62.5083845, 46.1914352],
              [-62.507966, 46.1910991],
              [-62.5075322, 46.1907084],
              [-62.5068036, 46.1906802],
              [-62.5064421, 46.1905226],
              [-62.5061756, 46.1904295],
              [-62.5059907, 46.1902134],
              [-62.5057403, 46.190016],
              [-62.5052576, 46.1897066],
              [-62.5046513, 46.1895076],
              [-62.5043658, 46.1894105],
              [-62.5038761, 46.1890993],
              [-62.5036251, 46.1888238],
              [-62.5033569, 46.1884259],
              [-62.5031124, 46.1880418],
              [-62.5027919, 46.1875734],
              [-62.5026409, 46.1873911],
              [-62.5024977, 46.1871459],
              [-62.5022934, 46.1865262],
              [-62.5014221, 46.1849022],
              [-62.5003402, 46.1831461],
              [-62.5, 46.182053],
              [-62.4997706, 46.1812213],
              [-62.4994946, 46.1805819],
              [-62.4992954, 46.1801098],
              [-62.4988355, 46.1796921],
              [-62.498397, 46.179422],
              [-62.4980266, 46.1791926],
              [-62.497492, 46.1786065],
              [-62.497043, 46.1781312],
              [-62.4965626, 46.1777747],
              [-62.4963281, 46.1776403],
              [-62.4960117, 46.1773427],
              [-62.495837, 46.1770655],
              [-62.4957712, 46.1767674],
              [-62.4958276, 46.1765333],
              [-62.4960203, 46.1760509],
              [-62.4962191, 46.1755835],
              [-62.4964014, 46.1751757],
              [-62.4965838, 46.1748635],
              [-62.4969136, 46.1745748],
              [-62.4971635, 46.1743844],
              [-62.4973361, 46.1741742],
              [-62.4975185, 46.1739879],
              [-62.4981156, 46.1737266],
              [-62.4987517, 46.1736564],
              [-62.4993257, 46.1735232],
              [-62.5, 46.173189],
              [-62.5001857, 46.1731381],
              [-62.500698, 46.1729929],
              [-62.5012629, 46.172866],
              [-62.5016517, 46.1728697],
              [-62.5022691, 46.1729968],
              [-62.5024976, 46.1729978],
              [-62.5034352, 46.1728764],
              [-62.5038371, 46.1728964],
              [-62.5043624, 46.1730001],
              [-62.5049348, 46.1729935],
              [-62.5062338, 46.1729293],
              [-62.5074137, 46.1727866],
              [-62.508508, 46.172698],
              [-62.5089136, 46.1726735],
              [-62.5109985, 46.1731189],
              [-62.5120204, 46.1730887],
              [-62.5140482, 46.1727571],
              [-62.514778, 46.1727252],
              [-62.5149691, 46.1727792],
              [-62.515622, 46.1732066],
              [-62.5161739, 46.1734316],
              [-62.5172762, 46.1734836],
              [-62.5191459, 46.1732024],
              [-62.5200373, 46.1731519],
              [-62.5205786, 46.1732811],
              [-62.521651, 46.1733519],
              [-62.5219506, 46.1735806],
              [-62.522031, 46.1739176],
              [-62.5219282, 46.1740814],
              [-62.5215887, 46.1743569],
              [-62.5211157, 46.1744888],
              [-62.5206918, 46.1744868],
              [-62.5212475, 46.1742343],
              [-62.5215258, 46.1740165],
              [-62.5215955, 46.1737482],
              [-62.5213352, 46.1736163],
              [-62.5207126, 46.173588],
              [-62.5197371, 46.173779],
              [-62.5183722, 46.1737981],
              [-62.5177486, 46.1739528],
              [-62.5168745, 46.1739999],
              [-62.5161546, 46.1738012],
              [-62.5152962, 46.1737014],
              [-62.5148545, 46.1737945],
              [-62.5144292, 46.1740671],
              [-62.5137469, 46.1747493],
              [-62.5136517, 46.1748855],
              [-62.5137052, 46.1751102],
              [-62.5138887, 46.1754466],
              [-62.5138328, 46.1756796],
              [-62.5135933, 46.1758309],
              [-62.5132212, 46.1759103],
              [-62.5126382, 46.176142],
              [-62.5121088, 46.1765983],
              [-62.5120363, 46.1769064],
              [-62.5120396, 46.1776481],
              [-62.511866, 46.1783574],
              [-62.5118897, 46.178663],
              [-62.5120063, 46.1788236],
              [-62.512375, 46.1788615],
              [-62.512903, 46.1788928],
              [-62.5135602, 46.178816],
              [-62.5140309, 46.1786394],
              [-62.514374, 46.1784242],
              [-62.5152597, 46.1778132],
              [-62.5155416, 46.1774351],
              [-62.5159159, 46.1769623],
              [-62.5160767, 46.1768373],
              [-62.5167638, 46.1763013],
              [-62.5172138, 46.1759423],
              [-62.5174049, 46.1755993],
              [-62.51749, 46.1752598],
              [-62.5178314, 46.1751512],
              [-62.5180859, 46.1760671],
              [-62.5187898, 46.1769945],
              [-62.5192078, 46.177607],
              [-62.5194251, 46.1780464],
              [-62.5197142, 46.1784236],
              [-62.5200947, 46.1788192],
              [-62.5207838, 46.1793985],
              [-62.5223398, 46.1802675],
              [-62.5229069, 46.1805044],
              [-62.5234331, 46.1807095],
              [-62.5236935, 46.1807947],
              [-62.524069, 46.180723],
              [-62.5252237, 46.1807646],
              [-62.5258238, 46.180709],
              [-62.5262153, 46.1807402],
              [-62.526373, 46.1806459],
              [-62.5266394, 46.1805916],
              [-62.5267966, 46.180518],
              [-62.5270084, 46.180327],
              [-62.5270158, 46.1800951],
              [-62.5272374, 46.1800712],
              [-62.5272202, 46.1799877],
              [-62.5273852, 46.1799638],
              [-62.527395, 46.179899],
              [-62.5276438, 46.1798854],
              [-62.5276807, 46.1799468],
              [-62.527693, 46.1803372],
              [-62.5277096, 46.1806108],
              [-62.5303429, 46.180061],
              [-62.5308625, 46.1798598],
              [-62.5309117, 46.1797711],
              [-62.5309314, 46.1795682],
              [-62.531227, 46.1795017],
              [-62.5312935, 46.1795154],
              [-62.5314092, 46.1794915],
              [-62.5314437, 46.1795682],
              [-62.5315348, 46.1795495],
              [-62.5312713, 46.1789254],
              [-62.5313058, 46.1789168],
              [-62.5312203, 46.1786645],
              [-62.5311654, 46.1785025],
              [-62.5311285, 46.1784633],
              [-62.5310841, 46.1783337],
              [-62.5312738, 46.1783013],
              [-62.5313156, 46.1784343],
              [-62.5313156, 46.1785008],
              [-62.5314388, 46.178893],
              [-62.5314806, 46.1788913],
              [-62.5315724, 46.1791777],
              [-62.5316869, 46.1794502],
              [-62.5317212, 46.1794927],
              [-62.5317909, 46.1796169],
              [-62.5318604, 46.1797711],
              [-62.5319096, 46.179973],
              [-62.5320591, 46.1799285],
              [-62.5322277, 46.1799065],
              [-62.5323464, 46.1798694],
              [-62.5324019, 46.1798388],
              [-62.5324246, 46.1798064],
              [-62.5323121, 46.1796376],
              [-62.5323026, 46.179314],
              [-62.5324294, 46.1792754],
              [-62.5324934, 46.1793385],
              [-62.532712, 46.1792835],
              [-62.5328167, 46.1796607],
              [-62.5328628, 46.1800708],
              [-62.5331799, 46.1800678],
              [-62.5333252, 46.1800116],
              [-62.5332587, 46.1799229],
              [-62.533207, 46.1796671],
              [-62.5334693, 46.1795803],
              [-62.5337512, 46.1799229],
              [-62.5338497, 46.1799468],
              [-62.5346057, 46.1798083],
              [-62.5362259, 46.1793699],
              [-62.536237, 46.1793392],
              [-62.5362215, 46.1792117],
              [-62.5362624, 46.1791877],
              [-62.5366137, 46.1790951],
              [-62.5366185, 46.1786566],
              [-62.5357271, 46.1764607],
              [-62.5362147, 46.1764009],
              [-62.5371181, 46.1784795],
              [-62.5372924, 46.1789528],
              [-62.537572, 46.1789048],
              [-62.5379577, 46.179021],
              [-62.5383954, 46.1794532],
              [-62.5385967, 46.1794854],
              [-62.5393432, 46.1794021],
              [-62.5394381, 46.1794459],
              [-62.5394443, 46.1795117],
              [-62.53909, 46.1796037],
              [-62.5387485, 46.1796255],
              [-62.5386283, 46.1796606],
              [-62.5385777, 46.179792],
              [-62.5387484, 46.1803177],
              [-62.5390648, 46.1811104],
              [-62.5392482, 46.1814522],
              [-62.539691, 46.1819515],
              [-62.541819, 46.1832348],
              [-62.5425194, 46.183516],
              [-62.5434149, 46.1838756],
              [-62.5440564, 46.1838805],
              [-62.5448693, 46.1833814],
              [-62.5452151, 46.1833528],
              [-62.5455282, 46.1833829],
              [-62.5457704, 46.1834464],
              [-62.5480703, 46.1848703],
              [-62.5493486, 46.1862331],
              [-62.5505137, 46.1870629],
              [-62.550747, 46.187174],
              [-62.5516223, 46.187453],
              [-62.5520782, 46.1875],
              [-62.5530851, 46.1876038],
              [-62.5538081, 46.1877625],
              [-62.5542036, 46.188018],
              [-62.5546135, 46.1884015],
              [-62.5552506, 46.1891584],
              [-62.5554488, 46.1892824],
              [-62.5555343, 46.189336],
              [-62.5556242, 46.1893922],
              [-62.5558064, 46.1895062],
              [-62.5561126, 46.1896125],
              [-62.5562956, 46.1897048],
              [-62.5565767, 46.1897716],
              [-62.5567748, 46.1898186],
              [-62.5568144, 46.189828],
              [-62.5571479, 46.1897916],
              [-62.5579583, 46.1897194],
              [-62.5583956, 46.1896824],
              [-62.5588167, 46.189701],
              [-62.5594915, 46.1899068],
              [-62.5601098, 46.1902128],
              [-62.56087, 46.1905776],
              [-62.5612026, 46.1907475],
              [-62.5615696, 46.1910021],
              [-62.5622939, 46.1911409],
              [-62.562754, 46.1914622],
              [-62.5630509, 46.1918221],
              [-62.5631373, 46.191988],
              [-62.5630992, 46.1924724],
              [-62.5628064, 46.1929088],
              [-62.5627557, 46.193154],
              [-62.5636017, 46.1939614],
              [-62.5638398, 46.1944308],
              [-62.5639822, 46.1948733],
              [-62.5642868, 46.1952055],
              [-62.5648576, 46.1954985],
              [-62.5666994, 46.1958165],
              [-62.5671362, 46.1960574],
              [-62.5674585, 46.1962947],
              [-62.5677698, 46.1965276],
              [-62.5682244, 46.1970553],
              [-62.5686559, 46.1971854],
              [-62.5692671, 46.197249],
              [-62.5700919, 46.1971511],
              [-62.571152, 46.1969828],
              [-62.5714843, 46.1969835],
              [-62.5720776, 46.1970667],
              [-62.5737026, 46.1973032],
              [-62.574026, 46.1976121],
              [-62.5740828, 46.1980518],
              [-62.5738193, 46.1986524],
              [-62.5737114, 46.1988958],
              [-62.5735538, 46.1991096],
              [-62.5733569, 46.1992267],
              [-62.573037, 46.1992961],
              [-62.5725235, 46.1992597],
              [-62.5711197, 46.1989906],
              [-62.5706779, 46.1987613],
              [-62.5702661, 46.1986379],
              [-62.5697835, 46.1986543],
              [-62.569232, 46.1988696],
              [-62.5685257, 46.1993782],
              [-62.5682741, 46.1994537],
              [-62.5682304, 46.199508],
              [-62.5684467, 46.1995309],
              [-62.5687456, 46.199433],
              [-62.5697017, 46.1988905],
              [-62.5701143, 46.1988905],
              [-62.5703414, 46.1987896],
              [-62.5707198, 46.1990565],
              [-62.5711375, 46.1991887],
              [-62.5719995, 46.1994704],
              [-62.5724058, 46.1995986],
              [-62.5728293, 46.1997938],
              [-62.5734608, 46.1998348],
              [-62.5743559, 46.2000118],
              [-62.5748369, 46.2001584],
              [-62.5752039, 46.2004072],
              [-62.575374, 46.200625],
              [-62.5754235, 46.2010494],
              [-62.575611, 46.2013649],
              [-62.5763778, 46.2018322],
              [-62.5770688, 46.2022673],
              [-62.5772612, 46.2025306],
              [-62.5772519, 46.2028681],
              [-62.5771231, 46.2031131],
              [-62.5767032, 46.2034271],
              [-62.5762322, 46.2036934],
              [-62.5765671, 46.2035932],
              [-62.5768073, 46.2035149],
              [-62.5770878, 46.2034271],
              [-62.5773278, 46.2033138],
              [-62.5776691, 46.2030391],
              [-62.5779162, 46.2031135],
              [-62.578059, 46.2032584],
              [-62.578006, 46.2033697],
              [-62.5779615, 46.2035774],
              [-62.5780794, 46.2038205],
              [-62.5783999, 46.2040752],
              [-62.5786191, 46.204169],
              [-62.5789888, 46.2043505],
              [-62.5792563, 46.2043703],
              [-62.5794616, 46.2043407],
              [-62.5794787, 46.2041542],
              [-62.5793666, 46.2039954],
              [-62.5794402, 46.2035741],
              [-62.5792884, 46.2030474],
              [-62.5790617, 46.202753],
              [-62.5786485, 46.2022442],
              [-62.578322, 46.2020592],
              [-62.577792, 46.2018644],
              [-62.5770727, 46.2015996],
              [-62.5765406, 46.2013941],
              [-62.576073, 46.2011816],
              [-62.5759899, 46.2008397],
              [-62.576029, 46.2006145],
              [-62.5757606, 46.1999765],
              [-62.5758781, 46.1998156],
              [-62.5759835, 46.1996133],
              [-62.5763085, 46.1991502],
              [-62.5760363, 46.1991364],
              [-62.5757729, 46.1991267],
              [-62.5754702, 46.1988795],
              [-62.5754144, 46.1988031],
              [-62.575332, 46.198642],
              [-62.5756031, 46.1984002],
              [-62.5759749, 46.1982651],
              [-62.5763183, 46.1982977],
              [-62.5766219, 46.1982829],
              [-62.5770226, 46.198192],
              [-62.5773785, 46.1980579],
              [-62.5774751, 46.1979817],
              [-62.5778639, 46.1977075],
              [-62.5782958, 46.1973087],
              [-62.578542, 46.1969954],
              [-62.5786058, 46.1968638],
              [-62.5786669, 46.1964393],
              [-62.5787488, 46.1961553],
              [-62.5790986, 46.1959712],
              [-62.5794793, 46.1959732],
              [-62.5795976, 46.1960143],
              [-62.5797409, 46.1961017],
              [-62.5798333, 46.1961924],
              [-62.5798764, 46.1963203],
              [-62.5797952, 46.1965305],
              [-62.5797064, 46.1967136],
              [-62.5796402, 46.1968884],
              [-62.579387, 46.1970753],
              [-62.5791583, 46.1970982],
              [-62.5789055, 46.1971177],
              [-62.5786656, 46.1971554],
              [-62.5782056, 46.1975923],
              [-62.5781571, 46.1977561],
              [-62.5781914, 46.1981261],
              [-62.5782984, 46.1986347],
              [-62.5784607, 46.1989911],
              [-62.5786704, 46.1993043],
              [-62.578878, 46.1994753],
              [-62.5791987, 46.1996335],
              [-62.5797481, 46.1997276],
              [-62.5806565, 46.1997379],
              [-62.5822885, 46.1995381],
              [-62.5835295, 46.1995134],
              [-62.584063, 46.1995217],
              [-62.5847133, 46.1995703],
              [-62.5858879, 46.1997512],
              [-62.5871244, 46.2000374],
              [-62.5875199, 46.2000381],
              [-62.5876866, 46.2000315],
              [-62.5885292, 46.1998594],
              [-62.5891706, 46.1996091],
              [-62.5902785, 46.1989797],
              [-62.5905596, 46.1989767],
              [-62.592193, 46.1999364],
              [-62.5933455, 46.2008011],
              [-62.593526, 46.2011054],
              [-62.5935247, 46.2014715],
              [-62.5932352, 46.2021231],
              [-62.5932163, 46.2029306],
              [-62.5934857, 46.2034527],
              [-62.5939336, 46.2037894],
              [-62.594331, 46.2039334],
              [-62.5950251, 46.2039276],
              [-62.5973138, 46.2039089],
              [-62.5984723, 46.2038183],
              [-62.5997208, 46.2034878],
              [-62.600634, 46.2034651],
              [-62.6010867, 46.2038139],
              [-62.6012982, 46.2038246],
              [-62.6018355, 46.2038496],
              [-62.6021238, 46.2039105],
              [-62.6025952, 46.2043275],
              [-62.6027941, 46.2043537],
              [-62.6036059, 46.2041287],
              [-62.6036906, 46.2040597],
              [-62.6041121, 46.204101],
              [-62.6051763, 46.2043099],
              [-62.6068231, 46.2047244],
              [-62.6078481, 46.2050912],
              [-62.6087387, 46.2054976],
              [-62.6088953, 46.2055583],
              [-62.6100039, 46.205876],
              [-62.6105463, 46.2062594],
              [-62.6107265, 46.2063089],
              [-62.6106766, 46.2059332],
              [-62.6105901, 46.2057672],
              [-62.6104078, 46.2055744],
              [-62.6100664, 46.2053605],
              [-62.6094038, 46.205236],
              [-62.6091094, 46.2050914],
              [-62.6089456, 46.2049668],
              [-62.6089501, 46.2047241],
              [-62.6092594, 46.2044672],
              [-62.6103192, 46.204043],
              [-62.610961, 46.2036095],
              [-62.6120102, 46.2034357],
              [-62.6128406, 46.2031873],
              [-62.6132634, 46.2032087],
              [-62.6144367, 46.2039384],
              [-62.6145758, 46.2040025],
              [-62.6150867, 46.2040784],
              [-62.615164, 46.204037],
              [-62.6150499, 46.2039419],
              [-62.6144637, 46.2036129],
              [-62.6142989, 46.2034166],
              [-62.6142141, 46.2030477],
              [-62.6144309, 46.202541],
              [-62.6149309, 46.2020832],
              [-62.6156434, 46.2017076],
              [-62.6166881, 46.2013386],
              [-62.6173564, 46.2012005],
              [-62.6181713, 46.2010989],
              [-62.6180801, 46.2003914],
              [-62.618175, 46.2002551],
              [-62.6186946, 46.1999372],
              [-62.6196405, 46.1996725],
              [-62.6211973, 46.1993594],
              [-62.6227445, 46.1989305],
              [-62.6235705, 46.1983953],
              [-62.625, 46.198002],
              [-62.6250765, 46.197981],
              [-62.6256319, 46.1977279],
              [-62.6264815, 46.1971814],
              [-62.6270478, 46.1970241],
              [-62.6279345, 46.197052],
              [-62.6290692, 46.1973186],
              [-62.6297812, 46.1975639],
              [-62.6314291, 46.1980496],
              [-62.6331679, 46.1984585],
              [-62.6337359, 46.1986276],
              [-62.6345483, 46.1990034],
              [-62.634784, 46.1992576],
              [-62.6347955, 46.1996082],
              [-62.6356371, 46.1992724],
              [-62.6359817, 46.1992634],
              [-62.6367673, 46.1994353],
              [-62.637765, 46.1993432],
              [-62.638676, 46.1990852],
              [-62.6391112, 46.1996203],
              [-62.6392751, 46.1997448],
              [-62.6393601, 46.1995843],
              [-62.6392979, 46.199041],
              [-62.6394999, 46.1988443],
              [-62.6406395, 46.198685],
              [-62.6410415, 46.1985861],
              [-62.6419918, 46.1985164],
              [-62.642749, 46.1985958],
              [-62.6439243, 46.1985928],
              [-62.6447697, 46.1987268],
              [-62.6448917, 46.1987027],
              [-62.644682, 46.1984892],
              [-62.6441344, 46.1980939],
              [-62.6434581, 46.1980051],
              [-62.6427441, 46.1980544],
              [-62.6421666, 46.1982074],
              [-62.6407118, 46.1981021],
              [-62.6401681, 46.1981767],
              [-62.6394642, 46.1981586],
              [-62.6383433, 46.1982946],
              [-62.6380076, 46.1982562],
              [-62.6377606, 46.1980892],
              [-62.6376776, 46.1979553],
              [-62.6377208, 46.1975546],
              [-62.6375793, 46.1974387],
              [-62.6373298, 46.1977493],
              [-62.6369971, 46.1980175],
              [-62.6365306, 46.1980506],
              [-62.6356348, 46.197724],
              [-62.6352756, 46.1976052],
              [-62.6347051, 46.1974759],
              [-62.6344965, 46.197334],
              [-62.6343843, 46.1969444],
              [-62.6341869, 46.1968068],
              [-62.6335252, 46.1968457],
              [-62.6331175, 46.1967692],
              [-62.6328171, 46.1965409],
              [-62.6327882, 46.1961937],
              [-62.6325708, 46.1960993],
              [-62.6322361, 46.1961325],
              [-62.6321465, 46.1961894],
              [-62.6317822, 46.1961501],
              [-62.6303983, 46.1955732],
              [-62.6301632, 46.1955738],
              [-62.63006, 46.1955745],
              [-62.6296867, 46.1956742],
              [-62.6290226, 46.1956613],
              [-62.6289222, 46.1955308],
              [-62.6290779, 46.1954282],
              [-62.6297823, 46.1952633],
              [-62.6301782, 46.1950807],
              [-62.6303612, 46.1949989],
              [-62.6312889, 46.1950121],
              [-62.6314233, 46.1949726],
              [-62.6313614, 46.1948671],
              [-62.6308032, 46.1948137],
              [-62.6302385, 46.1948597],
              [-62.6299798, 46.1948714],
              [-62.6295466, 46.1950091],
              [-62.6291232, 46.1952624],
              [-62.6286356, 46.195267],
              [-62.6273348, 46.1952619],
              [-62.6263879, 46.1954549],
              [-62.6259935, 46.1955261],
              [-62.6251272, 46.1958013],
              [-62.625, 46.1958698],
              [-62.6235766, 46.1966361],
              [-62.6228005, 46.1971092],
              [-62.6221557, 46.1973277],
              [-62.6214017, 46.1974631],
              [-62.6202623, 46.1975307],
              [-62.618237, 46.1981713],
              [-62.6173638, 46.1986373],
              [-62.6164251, 46.1989659],
              [-62.6159386, 46.199042],
              [-62.6152193, 46.1991706],
              [-62.614499, 46.1992274],
              [-62.6139899, 46.1993864],
              [-62.6136895, 46.1995959],
              [-62.6132618, 46.1995624],
              [-62.6126952, 46.1993733],
              [-62.6119692, 46.1992548],
              [-62.6110813, 46.1992466],
              [-62.6108433, 46.1993784],
              [-62.6108451, 46.1996133],
              [-62.6110658, 46.1998313],
              [-62.6112566, 46.2000682],
              [-62.6110368, 46.2003598],
              [-62.6105622, 46.2006035],
              [-62.6102736, 46.2006342],
              [-62.6097076, 46.200262],
              [-62.6093209, 46.2002139],
              [-62.6090283, 46.2003041],
              [-62.6084984, 46.2003431],
              [-62.6082636, 46.200252],
              [-62.6078247, 46.2002142],
              [-62.6066987, 46.2003378],
              [-62.6058737, 46.2000688],
              [-62.6052507, 46.2000411],
              [-62.6047772, 46.1999186],
              [-62.6036671, 46.1993659],
              [-62.6026755, 46.1983193],
              [-62.6022138, 46.1980181],
              [-62.6019751, 46.1979866],
              [-62.6013291, 46.197787],
              [-62.6002186, 46.1969795],
              [-62.5996918, 46.1964492],
              [-62.5986807, 46.1957006],
              [-62.5974465, 46.19512],
              [-62.5965424, 46.1947489],
              [-62.5951111, 46.194377],
              [-62.5934237, 46.1943232],
              [-62.592407, 46.1941833],
              [-62.5919684, 46.1940539],
              [-62.5909635, 46.193389],
              [-62.5902196, 46.1931105],
              [-62.5900519, 46.1930455],
              [-62.5888932, 46.1929727],
              [-62.5883334, 46.1930305],
              [-62.5875149, 46.1930999],
              [-62.5867809, 46.1931919],
              [-62.5861372, 46.1933902],
              [-62.5859482, 46.1933882],
              [-62.5856034, 46.1934886],
              [-62.5841018, 46.1936596],
              [-62.5835956, 46.1936872],
              [-62.5830085, 46.1937241],
              [-62.5825999, 46.1935757],
              [-62.5818021, 46.1934189],
              [-62.5810237, 46.1934019],
              [-62.580312, 46.1935025],
              [-62.5797847, 46.1935016],
              [-62.5791323, 46.1934011],
              [-62.5780702, 46.1930805],
              [-62.5765086, 46.1926881],
              [-62.5756885, 46.1925225],
              [-62.5743428, 46.1922446],
              [-62.5730891, 46.1923078],
              [-62.5728012, 46.1921553],
              [-62.5717443, 46.1905531],
              [-62.5713437, 46.1902855],
              [-62.5710596, 46.1898186],
              [-62.5708861, 46.1896697],
              [-62.5701875, 46.189918],
              [-62.5697569, 46.1900156],
              [-62.5688589, 46.1900743],
              [-62.5686278, 46.1900151],
              [-62.5678894, 46.189657],
              [-62.5669908, 46.1889515],
              [-62.5664622, 46.1883693],
              [-62.5654715, 46.1876687],
              [-62.5654532, 46.1876004],
              [-62.5654312, 46.1875],
              [-62.5654092, 46.1874],
              [-62.5652115, 46.1870991],
              [-62.5650032, 46.186957],
              [-62.5642433, 46.1864072],
              [-62.5630677, 46.1856449],
              [-62.5618911, 46.1845562],
              [-62.561582, 46.1841203],
              [-62.5610377, 46.1837764],
              [-62.5603187, 46.1835582],
              [-62.5595542, 46.1832509],
              [-62.558364, 46.1824523],
              [-62.5578951, 46.1819237],
              [-62.557893, 46.1815256],
              [-62.5579921, 46.1813298],
              [-62.5584671, 46.1809948],
              [-62.559084, 46.1809391],
              [-62.5602481, 46.1808413],
              [-62.560843, 46.1806853],
              [-62.5614314, 46.1802823],
              [-62.5619746, 46.1796988],
              [-62.5622227, 46.1792451],
              [-62.5625271, 46.1789763],
              [-62.5628172, 46.1791806],
              [-62.5631379, 46.179784],
              [-62.5641739, 46.1805736],
              [-62.5647634, 46.1808434],
              [-62.5658697, 46.1811815],
              [-62.5672627, 46.1813457],
              [-62.5686734, 46.1814149],
              [-62.5694046, 46.1813628],
              [-62.5703854, 46.1810918],
              [-62.5713257, 46.1806523],
              [-62.5722355, 46.1800685],
              [-62.5728051, 46.1800351],
              [-62.5732841, 46.1802415],
              [-62.5737217, 46.180554],
              [-62.5747439, 46.1811242],
              [-62.5755116, 46.1813],
              [-62.5768405, 46.1813068],
              [-62.5774212, 46.1812777],
              [-62.577625, 46.181316],
              [-62.578509, 46.1812926],
              [-62.5789819, 46.1811605],
              [-62.5797773, 46.180573],
              [-62.5802986, 46.1797976],
              [-62.5804015, 46.1795422],
              [-62.5805822, 46.1794086],
              [-62.5812871, 46.1794073],
              [-62.5816039, 46.1794692],
              [-62.5820255, 46.179419],
              [-62.5828148, 46.1794403],
              [-62.5836278, 46.1797966],
              [-62.5840116, 46.1798845],
              [-62.5845673, 46.1798864],
              [-62.5851697, 46.1797026],
              [-62.5873116, 46.1797193],
              [-62.5876997, 46.1796561],
              [-62.5879415, 46.179811],
              [-62.5880004, 46.1800478],
              [-62.5881752, 46.1801767],
              [-62.5880848, 46.1804166],
              [-62.5880461, 46.1804831],
              [-62.588053, 46.1809848],
              [-62.5881805, 46.1811361],
              [-62.5889179, 46.1814224],
              [-62.5893547, 46.1813169],
              [-62.5898854, 46.1813498],
              [-62.5905499, 46.1815262],
              [-62.5910869, 46.1818976],
              [-62.5910876, 46.1817145],
              [-62.5910393, 46.1816652],
              [-62.5904155, 46.1812194],
              [-62.5900267, 46.1811194],
              [-62.5889143, 46.1810441],
              [-62.5884825, 46.1808154],
              [-62.5884312, 46.1805511],
              [-62.5883421, 46.1804249],
              [-62.588237, 46.1802822],
              [-62.5883645, 46.1800872],
              [-62.5886208, 46.1800238],
              [-62.5913114, 46.1788479],
              [-62.5913922, 46.1788385],
              [-62.5923176, 46.1787088],
              [-62.5928006, 46.1785093],
              [-62.5935034, 46.1780182],
              [-62.5939677, 46.1774957],
              [-62.5945779, 46.177539],
              [-62.5951914, 46.1777058],
              [-62.5981767, 46.1775302],
              [-62.5990126, 46.1773657],
              [-62.6000072, 46.1769675],
              [-62.6004717, 46.1766997],
              [-62.6013173, 46.1769972],
              [-62.6018428, 46.1771094],
              [-62.6031707, 46.1773904],
              [-62.6039301, 46.1774304],
              [-62.6050756, 46.1772638],
              [-62.6057104, 46.1773676],
              [-62.6075381, 46.1772822],
              [-62.6086388, 46.1770982],
              [-62.6097762, 46.1767044],
              [-62.6102279, 46.1765436],
              [-62.6109195, 46.1763944],
              [-62.6111038, 46.1762927],
              [-62.6112946, 46.1760918],
              [-62.6117544, 46.1757203],
              [-62.6124798, 46.1754925],
              [-62.6129336, 46.1754751],
              [-62.6131994, 46.1755273],
              [-62.6136859, 46.1757974],
              [-62.6139088, 46.1760671],
              [-62.6142933, 46.1776315],
              [-62.6144741, 46.1778442],
              [-62.6147644, 46.1780484],
              [-62.6152491, 46.1780837],
              [-62.6155662, 46.1780539],
              [-62.6149981, 46.17763],
              [-62.6146863, 46.1771425],
              [-62.6144899, 46.1762925],
              [-62.6144267, 46.174985],
              [-62.6146198, 46.1748359],
              [-62.6151284, 46.1747684],
              [-62.6153701, 46.174577],
              [-62.6154372, 46.1741652],
              [-62.6157995, 46.1739697],
              [-62.6168135, 46.1736197],
              [-62.6172005, 46.1734847],
              [-62.6187936, 46.1727788],
              [-62.6197704, 46.171946],
              [-62.6201748, 46.1713697],
              [-62.6202883, 46.1712101],
              [-62.6204146, 46.1709434],
              [-62.6206898, 46.1703273],
              [-62.6211716, 46.1700559],
              [-62.6224932, 46.1699066],
              [-62.6233171, 46.1694828],
              [-62.625, 46.169258],
              [-62.6259928, 46.1691254],
              [-62.6268011, 46.16894],
              [-62.6270961, 46.1688099],
              [-62.6286412, 46.16778],
              [-62.6288651, 46.1676836],
              [-62.6298787, 46.167425],
              [-62.6308831, 46.1673969],
              [-62.6319975, 46.1675235],
              [-62.6326692, 46.1677635],
              [-62.6342324, 46.1683897],
              [-62.6345678, 46.1684281],
              [-62.6352491, 46.1683461],
              [-62.6361346, 46.1681192],
              [-62.6371529, 46.1679641],
              [-62.6377346, 46.1679147],
              [-62.6384421, 46.1677817],
              [-62.6395459, 46.1677208],
              [-62.6407657, 46.1674248],
              [-62.6408866, 46.1673648],
              [-62.6415644, 46.1671592],
              [-62.6432179, 46.1664588],
              [-62.6444489, 46.1662773],
              [-62.6453025, 46.1659641],
              [-62.6459401, 46.165708],
              [-62.6463648, 46.1654237],
              [-62.6463749, 46.1654034],
              [-62.6463887, 46.1653753],
              [-62.6468141, 46.1650967],
              [-62.6471492, 46.1648921],
              [-62.6474837, 46.1646285],
              [-62.6475431, 46.1646032],
              [-62.6477567, 46.1644127],
              [-62.6478318, 46.1643061],
              [-62.6480912, 46.1641436],
              [-62.6475456, 46.1639596],
              [-62.6475179, 46.1639876],
              [-62.6468055, 46.1645058],
              [-62.6468735, 46.1645531],
              [-62.6464928, 46.1648713],
              [-62.6464448, 46.1648781],
              [-62.6463322, 46.164711],
              [-62.6460053, 46.1647583],
              [-62.6457988, 46.1646845],
              [-62.6457227, 46.1646971],
              [-62.6458794, 46.1650215],
              [-62.6454298, 46.1650919],
              [-62.6445187, 46.1652469],
              [-62.6444912, 46.1651848],
              [-62.6446463, 46.1651341],
              [-62.644718, 46.1650901],
              [-62.644842, 46.1650505],
              [-62.6448676, 46.1650125],
              [-62.644882, 46.1649146],
              [-62.6448793, 46.1647995],
              [-62.6446337, 46.1646127],
              [-62.6439305, 46.1645032],
              [-62.643845, 46.1645005],
              [-62.6427587, 46.1644665],
              [-62.6419531, 46.1646123],
              [-62.6418884, 46.1646382],
              [-62.6410752, 46.1648117],
              [-62.6407308, 46.1649123],
              [-62.6399597, 46.1651428],
              [-62.6395989, 46.1653186],
              [-62.6393051, 46.1655203],
              [-62.6390865, 46.1659751],
              [-62.6388239, 46.166138],
              [-62.637793, 46.1664002],
              [-62.6361049, 46.1665501],
              [-62.6359485, 46.1664895],
              [-62.6359606, 46.1661277],
              [-62.6357685, 46.1659107],
              [-62.6349705, 46.1659374],
              [-62.6344153, 46.1658444],
              [-62.6329595, 46.1650663],
              [-62.6325289, 46.1648179],
              [-62.6322953, 46.1647987],
              [-62.6311679, 46.1648707],
              [-62.6291587, 46.1647636],
              [-62.6288565, 46.1648297],
              [-62.6275728, 46.1652788],
              [-62.6256886, 46.1658005],
              [-62.625, 46.1659452],
              [-62.6231854, 46.1663266],
              [-62.6220591, 46.1664701],
              [-62.6213658, 46.1663846],
              [-62.6202025, 46.1663916],
              [-62.6193256, 46.1666624],
              [-62.6190268, 46.166852],
              [-62.6185562, 46.1671276],
              [-62.6182369, 46.1675434],
              [-62.6179726, 46.1682554],
              [-62.6176438, 46.1689017],
              [-62.6173086, 46.1692095],
              [-62.6169362, 46.1694724],
              [-62.6166171, 46.1697966],
              [-62.616207, 46.1701976],
              [-62.6155524, 46.1704833],
              [-62.6142601, 46.1707967],
              [-62.6129116, 46.1711799],
              [-62.612526, 46.171295],
              [-62.6111073, 46.1718749],
              [-62.6095427, 46.1725816],
              [-62.6090247, 46.1728796],
              [-62.6084956, 46.1731733],
              [-62.6081549, 46.1732142],
              [-62.6077619, 46.1732654],
              [-62.6069551, 46.1736856],
              [-62.6066318, 46.1737231],
              [-62.6063003, 46.173625],
              [-62.6055499, 46.1730997],
              [-62.6049824, 46.1729305],
              [-62.6032911, 46.172427],
              [-62.6026994, 46.172452],
              [-62.6021944, 46.1725514],
              [-62.6012637, 46.1728522],
              [-62.6006828, 46.172973],
              [-62.5985121, 46.1734851],
              [-62.5973339, 46.1734555],
              [-62.596034, 46.1733584],
              [-62.5951406, 46.1732663],
              [-62.5942738, 46.1730317],
              [-62.5935036, 46.1725495],
              [-62.59292, 46.1720174],
              [-62.5926045, 46.1715895],
              [-62.5922621, 46.1713955],
              [-62.5919172, 46.1715875],
              [-62.5917296, 46.1715655],
              [-62.5917854, 46.171241],
              [-62.5919233, 46.1708872],
              [-62.5921355, 46.170277],
              [-62.5920701, 46.1697933],
              [-62.5918009, 46.1692713],
              [-62.5918633, 46.1688474],
              [-62.592061, 46.1684557],
              [-62.5920261, 46.1677701],
              [-62.5918004, 46.1676315],
              [-62.5916621, 46.1677306],
              [-62.5916612, 46.1680052],
              [-62.591445, 46.1683286],
              [-62.5913252, 46.1688422],
              [-62.5911291, 46.1691225],
              [-62.5911787, 46.1694982],
              [-62.5916618, 46.1706838],
              [-62.5914401, 46.1711782],
              [-62.5911198, 46.1714307],
              [-62.5909273, 46.1717429],
              [-62.5908162, 46.1718628],
              [-62.5905389, 46.1718977],
              [-62.5902537, 46.1720518],
              [-62.5897238, 46.1728747],
              [-62.5891834, 46.1731639],
              [-62.58854, 46.1733623],
              [-62.5878747, 46.1738067],
              [-62.5874722, 46.1737422],
              [-62.5868121, 46.1734146],
              [-62.5865525, 46.1733546],
              [-62.5859154, 46.1731989],
              [-62.5849511, 46.1731402],
              [-62.5844139, 46.1732066],
              [-62.5821231, 46.1731931],
              [-62.5815359, 46.1733215],
              [-62.5808522, 46.1733514],
              [-62.578406, 46.1730024],
              [-62.5778892, 46.1729341],
              [-62.576025, 46.1728823],
              [-62.5755633, 46.1730188],
              [-62.5746222, 46.1733866],
              [-62.5743547, 46.1734457],
              [-62.5737278, 46.1735689],
              [-62.5734406, 46.1735796],
              [-62.5730472, 46.1737222],
              [-62.5725546, 46.1741521],
              [-62.5723617, 46.1742096],
              [-62.572074, 46.1737108],
              [-62.5720812, 46.1728275],
              [-62.5717657, 46.1721447],
              [-62.5708788, 46.170751],
              [-62.5704475, 46.1704307],
              [-62.570208, 46.1703275],
              [-62.5698569, 46.1701809],
              [-62.568347, 46.169798],
              [-62.5673602, 46.1694757],
              [-62.5669989, 46.1693964],
              [-62.5655461, 46.1693617],
              [-62.5647638, 46.1694957],
              [-62.5634221, 46.1699421],
              [-62.5629086, 46.1699972],
              [-62.5628537, 46.1706481],
              [-62.5624722, 46.170612],
              [-62.5625205, 46.1700603],
              [-62.5617662, 46.170032],
              [-62.5615067, 46.1699719],
              [-62.5608091, 46.170037],
              [-62.559747, 46.1705003],
              [-62.5593696, 46.1704045],
              [-62.5588676, 46.1703724],
              [-62.5583277, 46.1704784],
              [-62.557165, 46.1709026],
              [-62.5566849, 46.1709707],
              [-62.5563501, 46.1710952],
              [-62.5554987, 46.1716608],
              [-62.5547744, 46.1718682],
              [-62.5535748, 46.1719011],
              [-62.5509611, 46.1715778],
              [-62.5501594, 46.1715718],
              [-62.5497378, 46.1716219],
              [-62.5492583, 46.1718531],
              [-62.5486064, 46.1722619],
              [-62.5483443, 46.1724962],
              [-62.5479854, 46.1729782],
              [-62.5479458, 46.1732276],
              [-62.5480101, 46.1732934],
              [-62.5480392, 46.1734575],
              [-62.5478744, 46.1733527],
              [-62.5476333, 46.172505],
              [-62.5474827, 46.1722733],
              [-62.5471168, 46.1718356],
              [-62.5462246, 46.1712136],
              [-62.5445332, 46.1708921],
              [-62.5441982, 46.1707619],
              [-62.5437002, 46.1704154],
              [-62.54342, 46.1699804],
              [-62.5430596, 46.1686075],
              [-62.5429671, 46.1684493],
              [-62.5423846, 46.1678254],
              [-62.5410925, 46.1671904],
              [-62.5406789, 46.1668666],
              [-62.5400867, 46.1664732],
              [-62.5387112, 46.1658872],
              [-62.5378136, 46.1659457],
              [-62.537668, 46.1659807],
              [-62.5366316, 46.1659749],
              [-62.5361551, 46.1658201],
              [-62.5358541, 46.1656113],
              [-62.5351511, 46.1649915],
              [-62.5349542, 46.164599],
              [-62.5346746, 46.1643272],
              [-62.5330902, 46.1637821],
              [-62.5323682, 46.1635318],
              [-62.5319283, 46.1635135],
              [-62.5308308, 46.16382],
              [-62.5295032, 46.164139],
              [-62.5291406, 46.1640794],
              [-62.5287504, 46.1638359],
              [-62.5281788, 46.163053],
              [-62.5271524, 46.1612882],
              [-62.5270524, 46.1611576],
              [-62.5266885, 46.1608631],
              [-62.5261219, 46.1606019],
              [-62.5251656, 46.1600055],
              [-62.5251165, 46.1598845],
              [-62.5250482, 46.1593688],
              [-62.5251375, 46.1591489],
              [-62.5258265, 46.1585306],
              [-62.526532, 46.1580919],
              [-62.5268771, 46.1579001],
              [-62.5272566, 46.1575384],
              [-62.5275964, 46.1571714],
              [-62.5284809, 46.1562012],
              [-62.5286479, 46.1561032],
              [-62.5287934, 46.1560681],
              [-62.5289064, 46.155837],
              [-62.5294837, 46.1551752],
              [-62.529902, 46.1544923],
              [-62.5299247, 46.1544095],
              [-62.5305863, 46.1530062],
              [-62.5307131, 46.152485],
              [-62.5308528, 46.1521114],
              [-62.5311086, 46.1511208],
              [-62.531971, 46.1503051],
              [-62.5325758, 46.1498273],
              [-62.5337221, 46.1491323],
              [-62.5343595, 46.1488506],
              [-62.534734, 46.1487314],
              [-62.5348786, 46.1486247],
              [-62.5350831, 46.1484801],
              [-62.5356926, 46.1467408],
              [-62.535905, 46.1464771],
              [-62.5360862, 46.1462522],
              [-62.5365124, 46.1459596],
              [-62.53677, 46.1458764],
              [-62.5368758, 46.1458361],
              [-62.5374636, 46.1457796],
              [-62.5376181, 46.1459517],
              [-62.537544, 46.1461165],
              [-62.5369914, 46.1463294],
              [-62.536513, 46.1466322],
              [-62.5362207, 46.1469769],
              [-62.5360931, 46.1476812],
              [-62.5361346, 46.1478298],
              [-62.5365163, 46.1480292],
              [-62.5366627, 46.1480658],
              [-62.537281, 46.1481536],
              [-62.5393268, 46.1487255],
              [-62.5404131, 46.1494334],
              [-62.5414791, 46.1496749],
              [-62.5418764, 46.1497276],
              [-62.5426741, 46.1497017],
              [-62.5430892, 46.1496595],
              [-62.5436096, 46.1494137],
              [-62.5441567, 46.1487708],
              [-62.5442865, 46.1486277],
              [-62.5443534, 46.1483991],
              [-62.5446154, 46.1481648],
              [-62.5447622, 46.1481098],
              [-62.5450609, 46.1482668],
              [-62.5451877, 46.148255],
              [-62.5452376, 46.1481929],
              [-62.54523, 46.1479658],
              [-62.5451358, 46.147919],
              [-62.5445287, 46.1478356],
              [-62.5443492, 46.1476945],
              [-62.5443911, 46.1474969],
              [-62.5448258, 46.1472483],
              [-62.545693, 46.1472087],
              [-62.5460179, 46.1470601],
              [-62.5463008, 46.1468543],
              [-62.5464812, 46.1468124],
              [-62.5467231, 46.146876],
              [-62.5471494, 46.146838],
              [-62.5473502, 46.1466615],
              [-62.5475787, 46.1455585],
              [-62.5474777, 46.1453562],
              [-62.5469656, 46.144882],
              [-62.546911, 46.1445857],
              [-62.547001, 46.1441827],
              [-62.5470501, 46.144049],
              [-62.5479352, 46.1430588],
              [-62.547886, 46.1426831],
              [-62.5476921, 46.1424142],
              [-62.5474658, 46.1422039],
              [-62.5475041, 46.1419743],
              [-62.5477406, 46.1418626],
              [-62.5484511, 46.1418537],
              [-62.5490358, 46.1419284],
              [-62.5493084, 46.14179],
              [-62.5496427, 46.141757],
              [-62.5498381, 46.1416599],
              [-62.549702, 46.141556],
              [-62.5497028, 46.1413729],
              [-62.5500191, 46.1411802],
              [-62.5502418, 46.1411038],
              [-62.5511262, 46.1408061],
              [-62.551756, 46.1408066],
              [-62.5520229, 46.1408391],
              [-62.55245, 46.1408728],
              [-62.5528719, 46.1412406],
              [-62.553123, 46.1417661],
              [-62.5530715, 46.1421943],
              [-62.5533652, 46.1423391],
              [-62.5534515, 46.142505],
              [-62.5533021, 46.1428544],
              [-62.553549, 46.14293],
              [-62.5541479, 46.1428777],
              [-62.5544846, 46.1431513],
              [-62.5546847, 46.1434125],
              [-62.5548154, 46.1433411],
              [-62.5548247, 46.1432021],
              [-62.554685, 46.1430662],
              [-62.5546559, 46.1429021],
              [-62.5547705, 46.1428143],
              [-62.5555399, 46.1426959],
              [-62.5554497, 46.1425895],
              [-62.5548511, 46.1425503],
              [-62.5545797, 46.1424141],
              [-62.5541436, 46.1415723],
              [-62.5539979, 46.1410979],
              [-62.5537817, 46.1408203],
              [-62.5534018, 46.1405096],
              [-62.5529487, 46.1404352],
              [-62.5521378, 46.140405],
              [-62.5520521, 46.1404023],
              [-62.551841, 46.1403915],
              [-62.5513586, 46.1404992],
              [-62.5508918, 46.1404602],
              [-62.5500782, 46.1404697],
              [-62.549092, 46.1407482],
              [-62.5483624, 46.1411266],
              [-62.5473738, 46.1410985],
              [-62.5471413, 46.1411507],
              [-62.5466046, 46.1414716],
              [-62.5460383, 46.1417199],
              [-62.5455947, 46.1417612],
              [-62.5453068, 46.1417001],
              [-62.5430723, 46.1408715],
              [-62.5429063, 46.1407866],
              [-62.5425189, 46.1407581],
              [-62.5422935, 46.1408741],
              [-62.5423042, 46.14097],
              [-62.5425271, 46.1414031],
              [-62.5424182, 46.1415746],
              [-62.5421299, 46.1416051],
              [-62.5414417, 46.1416225],
              [-62.5389422, 46.1409046],
              [-62.5385584, 46.1409081],
              [-62.537577, 46.1406891],
              [-62.5368887, 46.1404519],
              [-62.5360831, 46.1403421],
              [-62.5359019, 46.1403123],
              [-62.5350473, 46.140591],
              [-62.534231, 46.14064],
              [-62.5337013, 46.1405153],
              [-62.5329202, 46.1402113],
              [-62.5323641, 46.1401375],
              [-62.5311533, 46.13933],
              [-62.5300867, 46.1382525],
              [-62.5294829, 46.1375284],
              [-62.5292513, 46.1371428],
              [-62.5290514, 46.1366268],
              [-62.5289376, 46.1357675],
              [-62.5289778, 46.1356813],
              [-62.5289367, 46.1354412],
              [-62.5292036, 46.1347097],
              [-62.5295481, 46.1341001],
              [-62.5296668, 46.1336981],
              [-62.5296596, 46.1333795],
              [-62.5297819, 46.1330094],
              [-62.5300077, 46.1328018],
              [-62.5303736, 46.1326386],
              [-62.5308109, 46.1326051],
              [-62.5321754, 46.1329122],
              [-62.5332437, 46.1326048],
              [-62.5334043, 46.1325145],
              [-62.5338835, 46.1322833],
              [-62.5343975, 46.1320454],
              [-62.5351016, 46.131535],
              [-62.5354987, 46.131333],
              [-62.5356767, 46.1312393],
              [-62.5357507, 46.1310746],
              [-62.5353572, 46.1307991],
              [-62.535085, 46.1305913],
              [-62.534989, 46.1304011],
              [-62.5351795, 46.1302919],
              [-62.535582, 46.1302652],
              [-62.5358845, 46.1303626],
              [-62.5362174, 46.1306043],
              [-62.5362536, 46.1308323],
              [-62.5359761, 46.1312133],
              [-62.5359186, 46.1313029],
              [-62.5359529, 46.1313876],
              [-62.536023, 46.1315371],
              [-62.5363028, 46.1317173],
              [-62.5365384, 46.1317887],
              [-62.5370649, 46.1317899],
              [-62.5383522, 46.1316288],
              [-62.5410129, 46.1314724],
              [-62.5411244, 46.1312612],
              [-62.541115, 46.1311454],
              [-62.5413327, 46.131057],
              [-62.5414983, 46.1309788],
              [-62.5431562, 46.1306665],
              [-62.5446951, 46.1303384],
              [-62.5451278, 46.1302012],
              [-62.5453223, 46.1300324],
              [-62.5454026, 46.1298599],
              [-62.545691, 46.1292285],
              [-62.5463071, 46.1289182],
              [-62.5468521, 46.1283867],
              [-62.5468244, 46.1282027],
              [-62.5466722, 46.1280824],
              [-62.5466815, 46.1279434],
              [-62.5468073, 46.1278599],
              [-62.5472196, 46.1278574],
              [-62.547594, 46.1279929],
              [-62.5481664, 46.1279915],
              [-62.548567, 46.1278214],
              [-62.5489073, 46.127526],
              [-62.548779, 46.1270483],
              [-62.5487749, 46.1268532],
              [-62.5487512, 46.1266097],
              [-62.5484865, 46.1263743],
              [-62.5481563, 46.1263477],
              [-62.5481059, 46.1262465],
              [-62.5474282, 46.1261967],
              [-62.5470515, 46.1260094],
              [-62.5467137, 46.1257557],
              [-62.5454979, 46.125],
              [-62.5449172, 46.124639],
              [-62.544532, 46.1241529],
              [-62.5442932, 46.1237035],
              [-62.5439339, 46.123258],
              [-62.5437424, 46.1227862],
              [-62.5427664, 46.1214692],
              [-62.5423963, 46.1206733],
              [-62.5415538, 46.1197545],
              [-62.5414623, 46.1194133],
              [-62.5414733, 46.1189083],
              [-62.5408625, 46.1183751],
              [-62.5409485, 46.1180317],
              [-62.5399934, 46.1178535],
              [-62.5394131, 46.1177192],
              [-62.5393778, 46.1178176],
              [-62.5396665, 46.1179503],
              [-62.5398566, 46.1181873],
              [-62.5399061, 46.1184714],
              [-62.5401819, 46.1187112],
              [-62.540185, 46.1188346],
              [-62.5395824, 46.1189464],
              [-62.5394495, 46.1192206],
              [-62.538855, 46.1197226],
              [-62.5384455, 46.1196854],
              [-62.5376604, 46.1200419],
              [-62.5362049, 46.1204124],
              [-62.5360166, 46.1205734],
              [-62.5356558, 46.1212581],
              [-62.5350862, 46.121729],
              [-62.5333817, 46.1224814],
              [-62.5322647, 46.1231772],
              [-62.5324677, 46.1240712],
              [-62.5327934, 46.1239943],
              [-62.5328608, 46.1241835],
              [-62.5320368, 46.1243517],
              [-62.5319457, 46.1241736],
              [-62.5322081, 46.1241026],
              [-62.5319176, 46.1233171],
              [-62.531773, 46.1231692],
              [-62.5315214, 46.1230814],
              [-62.5308362, 46.1228044],
              [-62.5300345, 46.1224718],
              [-62.5294586, 46.1221864],
              [-62.5285713, 46.1218511],
              [-62.5266075, 46.1212697],
              [-62.5257537, 46.121202],
              [-62.5249574, 46.1212994],
              [-62.5243694, 46.1214473],
              [-62.5238439, 46.1215177],
              [-62.5233755, 46.12159],
              [-62.5226068, 46.1216166],
              [-62.5217695, 46.1214738],
              [-62.5206703, 46.1213105],
              [-62.5205477, 46.1212628],
              [-62.5201493, 46.1212298],
              [-62.5185255, 46.1209539],
              [-62.5174692, 46.1209193],
              [-62.516991, 46.1209673],
              [-62.5159957, 46.120875],
              [-62.5152914, 46.1207127],
              [-62.5145095, 46.1204284],
              [-62.5141553, 46.1202497],
              [-62.513349, 46.1196502],
              [-62.5122512, 46.1185398],
              [-62.5113529, 46.1172727],
              [-62.5113159, 46.1172277],
              [-62.5112915, 46.1169126],
              [-62.511395, 46.1168204],
              [-62.5115052, 46.1168837],
              [-62.511724, 46.1172849],
              [-62.5119884, 46.1175204],
              [-62.512847, 46.1178549],
              [-62.5139798, 46.1184492],
              [-62.5143139, 46.1184164],
              [-62.5148475, 46.1182269],
              [-62.5152843, 46.1177756],
              [-62.5159613, 46.1167353],
              [-62.5159788, 46.1164772],
              [-62.5160022, 46.1157935],
              [-62.5158168, 46.1154055],
              [-62.5153349, 46.1144943],
              [-62.5152665, 46.1138156],
              [-62.5154645, 46.1136788],
              [-62.5158794, 46.1136367],
              [-62.5161211, 46.1137003],
              [-62.5165903, 46.1136998],
              [-62.5169191, 46.1134917],
              [-62.5175362, 46.1124892],
              [-62.5179176, 46.1121794],
              [-62.5182397, 46.1120705],
              [-62.5185716, 46.1117312],
              [-62.518668, 46.1115752],
              [-62.5186382, 46.1110848],
              [-62.5186722, 46.1107516],
              [-62.5189573, 46.1103431],
              [-62.5191081, 46.109974],
              [-62.5200739, 46.1092296],
              [-62.5204151, 46.109006],
              [-62.520991, 46.1082728],
              [-62.5209915, 46.1081813],
              [-62.5210069, 46.1065981],
              [-62.5212505, 46.106041],
              [-62.5215266, 46.1056799],
              [-62.5215325, 46.105509],
              [-62.5214777, 46.1053043],
              [-62.5213675, 46.105241],
              [-62.521057, 46.105772],
              [-62.5206454, 46.1061923],
              [-62.5205338, 46.1064035],
              [-62.5205675, 46.1073437],
              [-62.5205161, 46.1079349],
              [-62.5201779, 46.1085367],
              [-62.5194723, 46.1091582],
              [-62.5194299, 46.1091927],
              [-62.5193362, 46.109309],
              [-62.5191551, 46.1095338],
              [-62.5179712, 46.1104581],
              [-62.5179756, 46.1105617],
              [-62.5179964, 46.1113542],
              [-62.5177612, 46.1117007],
              [-62.5175088, 46.1117959],
              [-62.5169431, 46.1122071],
              [-62.5162463, 46.1125265],
              [-62.5149475, 46.1126113],
              [-62.5145777, 46.112834],
              [-62.5145232, 46.1130471],
              [-62.514635, 46.1135084],
              [-62.5144119, 46.1136762],
              [-62.5142451, 46.1137742],
              [-62.5136514, 46.1138383],
              [-62.5126621, 46.1138297],
              [-62.5111036, 46.1130185],
              [-62.5104569, 46.1127665],
              [-62.5100596, 46.1125505],
              [-62.5098282, 46.1124196],
              [-62.5083263, 46.1119564],
              [-62.5080258, 46.1117476],
              [-62.5074733, 46.1115424],
              [-62.506456, 46.1114414],
              [-62.5058196, 46.1113767],
              [-62.5053598, 46.1112382],
              [-62.5043444, 46.1110257],
              [-62.5035313, 46.1110348],
              [-62.5026289, 46.1112638],
              [-62.5017507, 46.1113901],
              [-62.5008158, 46.111423],
              [-62.5000001, 46.111307],
              [-62.499254, 46.1112522],
              [-62.4990294, 46.1110219],
              [-62.4990296, 46.1107673],
              [-62.4992959, 46.1106368],
              [-62.5000001, 46.110552],
              [-62.5000001, 46.109903],
              [-62.4987303, 46.1101207],
              [-62.4985273, 46.1102453],
              [-62.498249, 46.1102998],
              [-62.4972, 46.1103289],
              [-62.4963749, 46.110262],
              [-62.4955717, 46.1102952],
              [-62.4953077, 46.1102228],
              [-62.4950429, 46.1100788],
              [-62.4950893, 46.1099848],
              [-62.4955047, 46.1098512],
              [-62.4953883, 46.1097957],
              [-62.4948367, 46.1096621],
              [-62.4941071, 46.1096221],
              [-62.4936989, 46.1098195],
              [-62.4936421, 46.1099808],
              [-62.4939091, 46.1101766],
              [-62.4938352, 46.1105045],
              [-62.4940693, 46.1105958],
              [-62.494392, 46.1108134],
              [-62.494419, 46.1110888],
              [-62.4943435, 46.1112734],
              [-62.4941122, 46.1115602],
              [-62.4940664, 46.1119805],
              [-62.4943406, 46.1126581],
              [-62.4947096, 46.1130364],
              [-62.4948206, 46.1131713],
              [-62.4952882, 46.1134454],
              [-62.4955683, 46.1135342],
              [-62.4957115, 46.1134474],
              [-62.4958875, 46.1130474],
              [-62.4959013, 46.1125942],
              [-62.4956698, 46.1120453],
              [-62.4957725, 46.1118816],
              [-62.4957186, 46.1117485],
              [-62.4952333, 46.1113146],
              [-62.4951606, 46.1112048],
              [-62.4952571, 46.1110488],
              [-62.4956317, 46.111093],
              [-62.4958109, 46.1112342],
              [-62.4960287, 46.1115637],
              [-62.4962168, 46.11233],
              [-62.4963963, 46.1126344],
              [-62.4962805, 46.1135776],
              [-62.4963684, 46.1136323],
              [-62.4963734, 46.1140622],
              [-62.4965775, 46.1148449],
              [-62.4973848, 46.1160972],
              [-62.4977598, 46.1169771],
              [-62.4977677, 46.1171126],
              [-62.4977984, 46.1178379],
              [-62.4975093, 46.1184691],
              [-62.4973303, 46.1187457],
              [-62.4974733, 46.1195861],
              [-62.4967233, 46.1202618],
              [-62.4964654, 46.1204363],
              [-62.4961655, 46.1205538],
              [-62.4957639, 46.120652],
              [-62.4950238, 46.1206793],
              [-62.4944023, 46.120814],
              [-62.4939352, 46.1208663],
              [-62.4934795, 46.1206682],
              [-62.4929955, 46.1206323],
              [-62.4924971, 46.1207233],
              [-62.4911222, 46.121247],
              [-62.4899865, 46.1212015],
              [-62.4890435, 46.1210987],
              [-62.4888102, 46.1210791],
              [-62.4881705, 46.1211455],
              [-62.487124, 46.1215526],
              [-62.4867077, 46.1216145],
              [-62.4863175, 46.1214623],
              [-62.4861759, 46.1210669],
              [-62.4857867, 46.1205215],
              [-62.4855474, 46.1202549],
              [-62.483566, 46.1193621],
              [-62.4824456, 46.1190837],
              [-62.4812989, 46.118852],
              [-62.4808374, 46.1184474],
              [-62.480508, 46.1182093],
              [-62.4798174, 46.1179512],
              [-62.4790003, 46.1178766],
              [-62.4786357, 46.1179235],
              [-62.4783314, 46.1179145],
              [-62.4778556, 46.1181277],
              [-62.4771685, 46.1182808],
              [-62.4758994, 46.1187645],
              [-62.4755315, 46.1190034],
              [-62.4742666, 46.1195353],
              [-62.4736477, 46.1197393],
              [-62.4718421, 46.1198753],
              [-62.4705068, 46.1200707],
              [-62.4698486, 46.1203285],
              [-62.4692918, 46.120633],
              [-62.4690019, 46.1207754],
              [-62.4681493, 46.1215219],
              [-62.4676325, 46.1230104],
              [-62.4674624, 46.1241754],
              [-62.4677445, 46.125579],
              [-62.4678683, 46.1262059],
              [-62.4680079, 46.1268504],
              [-62.4681301, 46.1275419],
              [-62.4683355, 46.1284851],
              [-62.4684946, 46.1292012],
              [-62.4687463, 46.1303664],
              [-62.4688045, 46.1311332],
              [-62.4690011, 46.1320615],
              [-62.4686152, 46.1328737],
              [-62.4686516, 46.1333681],
              [-62.4688239, 46.134421],
              [-62.4687483, 46.1351866],
              [-62.4688154, 46.1353759],
              [-62.4688713, 46.1365598],
              [-62.4688307, 46.1369007],
              [-62.468884, 46.1371254],
              [-62.4690234, 46.1372613],
              [-62.4691158, 46.1375828],
              [-62.469175, 46.1380158],
              [-62.469084, 46.1382407],
              [-62.4689973, 46.1387381],
              [-62.4686324, 46.1397094],
              [-62.4683275, 46.1401892],
              [-62.4684076, 46.1405867],
              [-62.468779, 46.1408915],
              [-62.4692253, 46.1410374],
              [-62.470136, 46.1409837],
              [-62.4704627, 46.1409787],
              [-62.470794, 46.1408225],
              [-62.4707903, 46.1406274],
              [-62.4706687, 46.1403965],
              [-62.4707289, 46.140104],
              [-62.4716548, 46.1392114],
              [-62.472125, 46.1387017],
              [-62.4722247, 46.1382513],
              [-62.4721874, 46.1380431],
              [-62.472045, 46.1377836],
              [-62.4716914, 46.1375132],
              [-62.4712245, 46.1373106],
              [-62.4704156, 46.1371683],
              [-62.4699437, 46.1369537],
              [-62.4698475, 46.1368549],
              [-62.469838, 46.136576],
              [-62.4700838, 46.1359992],
              [-62.4702423, 46.1352761],
              [-62.4701466, 46.1350859],
              [-62.4695076, 46.134806],
              [-62.4695046, 46.1346825],
              [-62.4697078, 46.1345579],
              [-62.4703478, 46.1344916],
              [-62.4712138, 46.1346358],
              [-62.4717607, 46.1347575],
              [-62.4725181, 46.1349816],
              [-62.4755925, 46.1354777],
              [-62.477877, 46.1353903],
              [-62.4786133, 46.1354227],
              [-62.4794012, 46.1355364],
              [-62.4811468, 46.1355384],
              [-62.4823196, 46.1354658],
              [-62.4837127, 46.135102],
              [-62.4852293, 46.1348577],
              [-62.4860306, 46.1346096],
              [-62.48672, 46.1345727],
              [-62.4870187, 46.1344751],
              [-62.4883997, 46.1340352],
              [-62.4886671, 46.1339764],
              [-62.4898604, 46.1333512],
              [-62.4902126, 46.1332235],
              [-62.4910383, 46.1330359],
              [-62.4914528, 46.1330854],
              [-62.4925494, 46.1332887],
              [-62.4932458, 46.1335704],
              [-62.4940644, 46.1337367],
              [-62.4947115, 46.1337341],
              [-62.4951738, 46.1336698],
              [-62.4961159, 46.1335377],
              [-62.4969313, 46.1334173],
              [-62.4975029, 46.1333446],
              [-62.4976957, 46.1332873],
              [-62.4978149, 46.1333031],
              [-62.4981371, 46.1331944],
              [-62.4986432, 46.1330757],
              [-62.4998283, 46.1325695],
              [-62.5, 46.132391],
              [-62.5005136, 46.1319195],
              [-62.5008911, 46.131924],
              [-62.5009803, 46.1319587],
              [-62.5014325, 46.1323795],
              [-62.5028611, 46.1334254],
              [-62.50349, 46.1337725],
              [-62.5042003, 46.1340187],
              [-62.5057535, 46.1343284],
              [-62.5068569, 46.1344323],
              [-62.507179, 46.1345782],
              [-62.5078678, 46.135142],
              [-62.5079859, 46.1353409],
              [-62.5079689, 46.1355075],
              [-62.5078389, 46.1356504],
              [-62.5077644, 46.1356519],
              [-62.5077822, 46.1358117],
              [-62.5076781, 46.1359953],
              [-62.507647, 46.1367067],
              [-62.5073621, 46.1370237],
              [-62.5060716, 46.1377335],
              [-62.5051002, 46.138211],
              [-62.5042341, 46.1387397],
              [-62.5039234, 46.1390161],
              [-62.5036819, 46.1391156],
              [-62.5030936, 46.1392634],
              [-62.5021999, 46.1396082],
              [-62.5013074, 46.1401877],
              [-62.5004081, 46.1408665],
              [-62.5, 46.141084],
              [-62.499646, 46.1414663],
              [-62.4990211, 46.1414776],
              [-62.4981789, 46.1421582],
              [-62.4980572, 46.1426],
              [-62.4975707, 46.1431849],
              [-62.497609, 46.1452279],
              [-62.4977208, 46.1454345],
              [-62.4982151, 46.1459125],
              [-62.4986295, 46.1462167],
              [-62.499688, 46.1466495],
              [-62.5, 46.146231],
              [-62.5000506, 46.1461998],
              [-62.5000253, 46.1460676],
              [-62.5001334, 46.1458244],
              [-62.5004035, 46.1457258],
              [-62.5008271, 46.1457278],
              [-62.5012379, 46.1455821],
              [-62.5014478, 46.1453582],
              [-62.501393, 46.1451534],
              [-62.5009527, 46.1448086],
              [-62.5007894, 46.1446838],
              [-62.5009319, 46.1445254],
              [-62.5014336, 46.1445578],
              [-62.5016156, 46.1446593],
              [-62.5020456, 46.1450714],
              [-62.5020931, 46.145467],
              [-62.5018643, 46.1459689],
              [-62.5013408, 46.1465089],
              [-62.5009429, 46.1468022],
              [-62.5007125, 46.1469061],
              [-62.5001946, 46.1471119],
              [-62.5, 46.147209],
              [-62.4997437, 46.1473439],
              [-62.4986465, 46.1473953],
              [-62.4982679, 46.1475739],
              [-62.4980032, 46.147593],
              [-62.4963236, 46.1473666],
              [-62.4957033, 46.1472267],
              [-62.4948626, 46.1469602],
              [-62.4937634, 46.1467051],
              [-62.4926199, 46.1465957],
              [-62.4920766, 46.1466693],
              [-62.4913032, 46.14701],
              [-62.490526, 46.1471555],
              [-62.4891013, 46.1475582],
              [-62.4881273, 46.1475658],
              [-62.4876071, 46.1477197],
              [-62.4867955, 46.1477805],
              [-62.4857696, 46.1479615],
              [-62.4852592, 46.1481396],
              [-62.4848862, 46.1484019],
              [-62.4847513, 46.1485328],
              [-62.4842665, 46.1490063],
              [-62.4832293, 46.1491829],
              [-62.481555, 46.1491315],
              [-62.479484, 46.1489355],
              [-62.4784855, 46.1488825],
              [-62.4776108, 46.1486943],
              [-62.4771176, 46.1487058],
              [-62.4762665, 46.1485065],
              [-62.4743214, 46.1476459],
              [-62.4738652, 46.1474644],
              [-62.4732905, 46.1473548],
              [-62.472084, 46.1472083],
              [-62.4711281, 46.1469509],
              [-62.4708799, 46.1466369],
              [-62.4705009, 46.1461425],
              [-62.4694283, 46.145797],
              [-62.468239, 46.1456028],
              [-62.4676171, 46.1455913],
              [-62.4669871, 46.1455244],
              [-62.4665854, 46.1453897],
              [-62.4663068, 46.1451075],
              [-62.4662738, 46.1449275],
              [-62.4657679, 46.1443806],
              [-62.4657484, 46.1437942],
              [-62.4661283, 46.1433805],
              [-62.466383, 46.1427653],
              [-62.4668087, 46.1421797],
              [-62.4671448, 46.1412523],
              [-62.4674723, 46.1404654],
              [-62.4676616, 46.1380644],
              [-62.4674796, 46.1373734],
              [-62.4675815, 46.1355977],
              [-62.4675961, 46.13472],
              [-62.4675888, 46.1330856],
              [-62.4676107, 46.1318345],
              [-62.4675743, 46.1309365],
              [-62.4674259, 46.1296147],
              [-62.4673195, 46.1290749],
              [-62.4672831, 46.1288378],
              [-62.4672685, 46.1284846],
              [-62.4671739, 46.1277228],
              [-62.4670428, 46.1272536],
              [-62.4668026, 46.1263354],
              [-62.4664896, 46.1253212],
              [-62.4662727, 46.1248325],
              [-62.4662348, 46.1241759],
              [-62.4657616, 46.1233182],
              [-62.4646624, 46.1216127],
              [-62.4640728, 46.120755],
              [-62.4634336, 46.1202375],
              [-62.462555, 46.1198137],
              [-62.4618252, 46.1188716],
              [-62.4610757, 46.1182549],
              [-62.4602991, 46.1175027],
              [-62.4595852, 46.1171558],
              [-62.4579156, 46.1152297],
              [-62.4571098, 46.1143034],
              [-62.4559034, 46.1131289],
              [-62.4557541, 46.1128056],
              [-62.4557249, 46.111571],
              [-62.455591, 46.1106114],
              [-62.4553164, 46.1096989],
              [-62.4551085, 46.1092304],
              [-62.4547227, 46.1084375],
              [-62.4542898, 46.1078301],
              [-62.4538286, 46.1073849],
              [-62.4529606, 46.1066993],
              [-62.452201, 46.1065147],
              [-62.4520317, 46.1063976],
              [-62.4509013, 46.1056196],
              [-62.4505428, 46.1055917],
              [-62.4497832, 46.1054071],
              [-62.4487638, 46.1049272],
              [-62.4481657, 46.1047242],
              [-62.4478483, 46.1046817],
              [-62.4475481, 46.1044727],
              [-62.4476433, 46.1043366],
              [-62.4482901, 46.1037534],
              [-62.448924, 46.1028594],
              [-62.4493637, 46.1026234],
              [-62.4502482, 46.1024898],
              [-62.4505238, 46.1024752],
              [-62.4510337, 46.1021341],
              [-62.4515747, 46.1015911],
              [-62.4520971, 46.1006536],
              [-62.4522358, 46.1001369],
              [-62.4524114, 46.0996653],
              [-62.4523898, 46.0995651],
              [-62.4525208, 46.0990761],
              [-62.4524485, 46.0970601],
              [-62.4525545, 46.0966021],
              [-62.4526782, 46.096467],
              [-62.4529301, 46.0964634],
              [-62.4538494, 46.0964862],
              [-62.4544221, 46.096394],
              [-62.4552091, 46.0969256],
              [-62.4558403, 46.0969786],
              [-62.4563048, 46.0972925],
              [-62.4567448, 46.0973828],
              [-62.4576041, 46.0972802],
              [-62.4581031, 46.0972611],
              [-62.4586582, 46.0977532],
              [-62.4586737, 46.0978611],
              [-62.4588783, 46.0978799],
              [-62.4590315, 46.0979805],
              [-62.4595049, 46.0980838],
              [-62.4601524, 46.0971544],
              [-62.4602296, 46.0971132],
              [-62.4604974, 46.096963],
              [-62.4609642, 46.0969109],
              [-62.461368, 46.0970279],
              [-62.4621152, 46.09681],
              [-62.4622138, 46.0967059],
              [-62.4621149, 46.0966469],
              [-62.4619339, 46.096617],
              [-62.4612837, 46.0964242],
              [-62.4607371, 46.0961393],
              [-62.4607402, 46.095845],
              [-62.4610193, 46.0952813],
              [-62.4608909, 46.0949865],
              [-62.4608016, 46.0947886],
              [-62.4606462, 46.0946362],
              [-62.4604776, 46.0950086],
              [-62.460013, 46.0952756],
              [-62.4587488, 46.0954443],
              [-62.4572064, 46.0958305],
              [-62.4560614, 46.0955972],
              [-62.4556383, 46.095595],
              [-62.4554366, 46.0956997],
              [-62.4554264, 46.0959301],
              [-62.4541761, 46.0960634],
              [-62.4529882, 46.0961192],
              [-62.4526113, 46.0961043],
              [-62.4523208, 46.0960929],
              [-62.4522431, 46.0956446],
              [-62.4521512, 46.0941613],
              [-62.4515462, 46.0929871],
              [-62.4512926, 46.0926841],
              [-62.4500051, 46.091121],
              [-62.4494599, 46.0906531],
              [-62.4492968, 46.0903651],
              [-62.4493113, 46.0902383],
              [-62.4496773, 46.0880063],
              [-62.4500223, 46.0870708],
              [-62.451251, 46.0862565],
              [-62.451718, 46.0857867],
              [-62.4519591, 46.085361],
              [-62.4522089, 46.0839807],
              [-62.4523159, 46.0831765],
              [-62.4523089, 46.0823686],
              [-62.452157, 46.081504],
              [-62.4521662, 46.0808757],
              [-62.4525803, 46.0803444],
              [-62.45377, 46.0791786],
              [-62.4556463, 46.0773434],
              [-62.4560021, 46.0769932],
              [-62.4561689, 46.0768954],
              [-62.4562773, 46.0768154],
              [-62.4571621, 46.0767535],
              [-62.4577817, 46.0766389],
              [-62.4598393, 46.076067],
              [-62.4607299, 46.075671],
              [-62.4613156, 46.0752171],
              [-62.4622573, 46.0739952],
              [-62.4628517, 46.0731675],
              [-62.463274, 46.0720994],
              [-62.4634976, 46.0718403],
              [-62.4642029, 46.0713108],
              [-62.4651699, 46.0703841],
              [-62.4654087, 46.0700698],
              [-62.4654593, 46.0693354],
              [-62.46562, 46.0688275],
              [-62.4657664, 46.0686096],
              [-62.4660148, 46.0681563],
              [-62.4663192, 46.0674702],
              [-62.4666211, 46.0669869],
              [-62.4666174, 46.0667918],
              [-62.4669015, 46.0659857],
              [-62.4676517, 46.0638025],
              [-62.4680359, 46.0624547],
              [-62.4681212, 46.0622944],
              [-62.4681786, 46.0620417],
              [-62.468534, 46.0611106],
              [-62.469037, 46.0607772],
              [-62.469257, 46.0604861],
              [-62.4696575, 46.059481],
              [-62.4696447, 46.0587525],
              [-62.4695951, 46.0585598],
              [-62.469685, 46.0576677],
              [-62.4699935, 46.0565043],
              [-62.4699467, 46.0548556],
              [-62.4699101, 46.054556],
              [-62.4697734, 46.0542173],
              [-62.4698278, 46.0538412],
              [-62.4702942, 46.0527905],
              [-62.4703945, 46.0524119],
              [-62.4704882, 46.0522957],
              [-62.4707858, 46.0518719],
              [-62.4708817, 46.0518075],
              [-62.471246, 46.0518276],
              [-62.4714624, 46.0519223],
              [-62.4719692, 46.0519471],
              [-62.4720729, 46.0520182],
              [-62.4720323, 46.052359],
              [-62.4721765, 46.052507],
              [-62.4722913, 46.0525824],
              [-62.472607, 46.0526446],
              [-62.473867, 46.0523524],
              [-62.4760735, 46.0513396],
              [-62.4766344, 46.0508251],
              [-62.4771983, 46.0500164],
              [-62.4774973, 46.0495728],
              [-62.4781112, 46.0482843],
              [-62.4782195, 46.0477866],
              [-62.4787476, 46.0463322],
              [-62.4788402, 46.0459812],
              [-62.4790939, 46.0441954],
              [-62.4793684, 46.0428559],
              [-62.4795212, 46.0423756],
              [-62.4800837, 46.041169],
              [-62.4805296, 46.0405792],
              [-62.4811257, 46.0399664],
              [-62.4815529, 46.0393998],
              [-62.4822704, 46.0387632],
              [-62.4830938, 46.0382495],
              [-62.484016, 46.0377947],
              [-62.4843015, 46.0377127],
              [-62.4845184, 46.0377159],
              [-62.4848513, 46.0377747],
              [-62.4848655, 46.0379024],
              [-62.4832754, 46.0387687],
              [-62.482018, 46.0395306],
              [-62.481685, 46.0398895],
              [-62.481495, 46.0405794],
              [-62.4814519, 46.0409598],
              [-62.4811484, 46.0423899],
              [-62.4806742, 46.0435596],
              [-62.4805675, 46.0442006],
              [-62.48061, 46.044747],
              [-62.4807357, 46.0450814],
              [-62.4812862, 46.0454696],
              [-62.4816553, 46.0455017],
              [-62.4819523, 46.0455872],
              [-62.4830068, 46.0456221],
              [-62.483668, 46.0457276],
              [-62.4837704, 46.0458185],
              [-62.4844747, 46.0459612],
              [-62.4852912, 46.046219],
              [-62.4863494, 46.0467036],
              [-62.4875742, 46.0470903],
              [-62.48768, 46.0472131],
              [-62.4878434, 46.0472464],
              [-62.4885591, 46.0478111],
              [-62.4891435, 46.0481209],
              [-62.4897052, 46.0480957],
              [-62.4901324, 46.0479467],
              [-62.4905461, 46.0479962],
              [-62.4912731, 46.0483106],
              [-62.4917113, 46.0484206],
              [-62.4923112, 46.0484205],
              [-62.4925881, 46.048549],
              [-62.4926147, 46.0486613],
              [-62.4924405, 46.0487868],
              [-62.4917399, 46.0488393],
              [-62.4913669, 46.0490298],
              [-62.4907041, 46.0496166],
              [-62.4907498, 46.0498687],
              [-62.4910905, 46.0503176],
              [-62.4917413, 46.0507449],
              [-62.4919257, 46.0508067],
              [-62.4920256, 46.0506827],
              [-62.4919467, 46.0501629],
              [-62.4918017, 46.0499433],
              [-62.4917449, 46.0496868],
              [-62.4918866, 46.0496199],
              [-62.4924084, 46.049844],
              [-62.4930016, 46.0499432],
              [-62.4932424, 46.0500983],
              [-62.4934341, 46.0503871],
              [-62.4938612, 46.0515828],
              [-62.4939087, 46.0517236],
              [-62.493852, 46.0518848],
              [-62.4939878, 46.0519888],
              [-62.494126, 46.0523076],
              [-62.4943357, 46.0525015],
              [-62.4950168, 46.0524007],
              [-62.4953899, 46.0526279],
              [-62.4954624, 46.0527377],
              [-62.4957078, 46.0527418],
              [-62.4961878, 46.0529088],
              [-62.4962975, 46.052809],
              [-62.4961921, 46.0525947],
              [-62.4953894, 46.052047],
              [-62.4949184, 46.0518325],
              [-62.4945347, 46.0515096],
              [-62.494389, 46.0509637],
              [-62.4944555, 46.0508266],
              [-62.494918, 46.0505793],
              [-62.4959253, 46.0502188],
              [-62.4964583, 46.0497749],
              [-62.496886, 46.0491166],
              [-62.4968433, 46.0489879],
              [-62.4966633, 46.0490297],
              [-62.4964753, 46.0491906],
              [-62.4958942, 46.0495852],
              [-62.4944471, 46.0496925],
              [-62.4940706, 46.0498512],
              [-62.4937628, 46.0499246],
              [-62.4934413, 46.0497787],
              [-62.4933359, 46.0495643],
              [-62.4934193, 46.0495154],
              [-62.4938186, 46.0496917],
              [-62.4945368, 46.0493812],
              [-62.4958517, 46.0492019],
              [-62.4971618, 46.0483381],
              [-62.4978869, 46.0482868],
              [-62.4981251, 46.0482863],
              [-62.4985793, 46.0484127],
              [-62.4990435, 46.0483086],
              [-62.5, 46.048276],
              [-62.5010512, 46.0483589],
              [-62.5017705, 46.0482831],
              [-62.5020957, 46.0482063],
              [-62.5033943, 46.048265],
              [-62.5039225, 46.0482267],
              [-62.5043359, 46.048113],
              [-62.5050353, 46.0478257],
              [-62.5063325, 46.0472319],
              [-62.5072025, 46.0467871],
              [-62.5082992, 46.0463697],
              [-62.5100235, 46.0456267],
              [-62.5112311, 46.044835],
              [-62.5118533, 46.0445889],
              [-62.5128786, 46.0442963],
              [-62.5136651, 46.044155],
              [-62.5138575, 46.0440977],
              [-62.5149134, 46.0441125],
              [-62.5157662, 46.0440887],
              [-62.5158691, 46.0440881],
              [-62.5163581, 46.043953],
              [-62.51688, 46.0439224],
              [-62.5173702, 46.0442766],
              [-62.5183102, 46.0446535],
              [-62.5179766, 46.0446863],
              [-62.5174668, 46.0445383],
              [-62.5173416, 46.0445302],
              [-62.5171657, 46.0445125],
              [-62.5167649, 46.0446108],
              [-62.5163525, 46.0445416],
              [-62.5160596, 46.0443967],
              [-62.5153675, 46.0444934],
              [-62.515176, 46.0446223],
              [-62.5157843, 46.0446661],
              [-62.5162768, 46.0448176],
              [-62.5170583, 46.0449188],
              [-62.5173464, 46.0450515],
              [-62.5173566, 46.0452388],
              [-62.5175801, 46.0453973],
              [-62.5176478, 46.045495],
              [-62.5182191, 46.0457485],
              [-62.5183848, 46.0455788],
              [-62.5187896, 46.0454211],
              [-62.5195262, 46.0453417],
              [-62.5205006, 46.0452941],
              [-62.5207314, 46.0450073],
              [-62.5203146, 46.0448345],
              [-62.5199663, 46.044831],
              [-62.5196805, 46.0447501],
              [-62.5193461, 46.0444567],
              [-62.5192717, 46.0444582],
              [-62.5191608, 46.0445779],
              [-62.5186776, 46.0445422],
              [-62.5186634, 46.0444144],
              [-62.518655, 46.0441158],
              [-62.5189348, 46.0436953],
              [-62.5190408, 46.0435635],
              [-62.519578, 46.0429685],
              [-62.5197571, 46.0426004],
              [-62.5200198, 46.0426011],
              [-62.5205261, 46.0429717],
              [-62.5209949, 46.0433888],
              [-62.5209895, 46.0434682],
              [-62.5212086, 46.0435231],
              [-62.522145, 46.0441227],
              [-62.5226181, 46.0445135],
              [-62.5230634, 46.0446872],
              [-62.5241883, 46.0450343],
              [-62.5253782, 46.0453215],
              [-62.525553, 46.0453172],
              [-62.5257455, 46.0453125],
              [-62.5272755, 46.0452747],
              [-62.527532, 46.0452831],
              [-62.527674, 46.0453792],
              [-62.527772, 46.0453665],
              [-62.5284529, 46.04552],
              [-62.5295854, 46.0456697],
              [-62.5306649, 46.0458429],
              [-62.5312626, 46.0460454],
              [-62.531736, 46.0463114],
              [-62.5317684, 46.0464009],
              [-62.5316576, 46.0464553],
              [-62.5305404, 46.0464497],
              [-62.5302923, 46.0464854],
              [-62.5299343, 46.0467124],
              [-62.5297321, 46.0470002],
              [-62.5295017, 46.0475005],
              [-62.5294322, 46.0477687],
              [-62.5293854, 46.0480812],
              [-62.5294063, 46.0484611],
              [-62.5300759, 46.0483848],
              [-62.5301861, 46.0483682],
              [-62.530222, 46.0484642],
              [-62.528756, 46.048885],
              [-62.528593, 46.0493615],
              [-62.5286248, 46.0500283],
              [-62.5287217, 46.0502823],
              [-62.5287858, 46.0506027],
              [-62.5287907, 46.051124],
              [-62.5287954, 46.0517474],
              [-62.5285949, 46.0519238],
              [-62.5283121, 46.0514571],
              [-62.5281562, 46.0513963],
              [-62.527628, 46.0514348],
              [-62.5270436, 46.0517976],
              [-62.5270111, 46.0518562],
              [-62.5267759, 46.0520395],
              [-62.5261301, 46.0520422],
              [-62.5251279, 46.0524153],
              [-62.524286, 46.0529527],
              [-62.5239234, 46.0530761],
              [-62.523396, 46.0531861],
              [-62.5237835, 46.0532863],
              [-62.5240984, 46.0532767],
              [-62.5248672, 46.0529756],
              [-62.5260775, 46.0523986],
              [-62.5267776, 46.0524374],
              [-62.5270672, 46.0522956],
              [-62.5273607, 46.052349],
              [-62.5277954, 46.0526815],
              [-62.5283285, 46.0534189],
              [-62.5286657, 46.054691],
              [-62.5289548, 46.05515],
              [-62.5291757, 46.0553482],
              [-62.529169, 46.0554474],
              [-62.5296202, 46.0554502],
              [-62.5296831, 46.0552812],
              [-62.5300416, 46.0549627],
              [-62.5300412, 46.054545],
              [-62.5303681, 46.0543567],
              [-62.5324385, 46.0538196],
              [-62.532691, 46.0536329],
              [-62.5330197, 46.0533333],
              [-62.5341972, 46.0517963],
              [-62.5347784, 46.05131],
              [-62.5352096, 46.0511013],
              [-62.5356438, 46.051016],
              [-62.5363974, 46.0510246],
              [-62.5369172, 46.0509421],
              [-62.5379264, 46.0506328],
              [-62.5389458, 46.0500016],
              [-62.5394499, 46.0498111],
              [-62.5400187, 46.0498495],
              [-62.5403848, 46.0500126],
              [-62.5406637, 46.0502842],
              [-62.5407979, 46.0507541],
              [-62.5406667, 46.0515176],
              [-62.5408209, 46.0519443],
              [-62.5412298, 46.052236],
              [-62.5423185, 46.0527011],
              [-62.5428313, 46.0530639],
              [-62.5433221, 46.0541818],
              [-62.5433547, 46.0546324],
              [-62.5430844, 46.054914],
              [-62.5428908, 46.0552458],
              [-62.5429995, 46.0555836],
              [-62.5430784, 46.0556856],
              [-62.5432383, 46.0556868],
              [-62.5435633, 46.0551006],
              [-62.5442759, 46.0546145],
              [-62.5447177, 46.0545016],
              [-62.5452856, 46.0544683],
              [-62.5455083, 46.0543005],
              [-62.5451634, 46.0540745],
              [-62.5449273, 46.0539316],
              [-62.5451851, 46.0536654],
              [-62.5453286, 46.0537417],
              [-62.5454337, 46.0537928],
              [-62.5453179, 46.0539005],
              [-62.5455959, 46.0541005],
              [-62.5461343, 46.05374],
              [-62.5463797, 46.0534894],
              [-62.5466209, 46.0530437],
              [-62.5475149, 46.0518952],
              [-62.5480991, 46.0515322],
              [-62.5485716, 46.0514719],
              [-62.5487841, 46.051626],
              [-62.548782, 46.0518288],
              [-62.5488796, 46.0519076],
              [-62.5495385, 46.0523069],
              [-62.5496245, 46.0524728],
              [-62.5505157, 46.0530745],
              [-62.5509028, 46.0532661],
              [-62.5514459, 46.0535184],
              [-62.5519617, 46.0534953],
              [-62.5522629, 46.0537756],
              [-62.5526614, 46.0542261],
              [-62.5528737, 46.0547263],
              [-62.5534684, 46.0556605],
              [-62.5538508, 46.0560946],
              [-62.553853, 46.0561464],
              [-62.5538798, 46.0562586],
              [-62.5538969, 46.0566013],
              [-62.5539959, 46.0566602],
              [-62.5543825, 46.0566887],
              [-62.554711, 46.0569897],
              [-62.5551474, 46.0575568],
              [-62.5552468, 46.0575242],
              [-62.5553442, 46.0573483],
              [-62.5552972, 46.0570246],
              [-62.5544112, 46.0560889],
              [-62.5542458, 46.0559125],
              [-62.5540163, 46.0556703],
              [-62.5531229, 46.0544161],
              [-62.5529557, 46.0540964],
              [-62.552952, 46.0538099],
              [-62.5530672, 46.0535391],
              [-62.5533148, 46.0533402],
              [-62.5537419, 46.053191],
              [-62.5544543, 46.0527048],
              [-62.5557397, 46.0518513],
              [-62.5561501, 46.0515225],
              [-62.5568369, 46.0506496],
              [-62.55712, 46.0505156],
              [-62.5574937, 46.0503964],
              [-62.5578356, 46.0501528],
              [-62.5580336, 46.0499245],
              [-62.55825, 46.0497644],
              [-62.5584544, 46.049783],
              [-62.5586532, 46.0499724],
              [-62.5589075, 46.0499289],
              [-62.5598767, 46.0493595],
              [-62.5605744, 46.0490917],
              [-62.560763, 46.0493484],
              [-62.5609991, 46.0494913],
              [-62.5614135, 46.0491028],
              [-62.5619304, 46.0488053],
              [-62.5619711, 46.0485361],
              [-62.5619991, 46.0483739],
              [-62.5620942, 46.0481462],
              [-62.5620274, 46.0481202],
              [-62.5613378, 46.0485236],
              [-62.5611815, 46.0485543],
              [-62.5598547, 46.0484041],
              [-62.5598416, 46.0482565],
              [-62.5604001, 46.0475067],
              [-62.5606893, 46.0471103],
              [-62.5609856, 46.0467778],
              [-62.561242, 46.0465315],
              [-62.5619168, 46.0454911],
              [-62.5626632, 46.0442343],
              [-62.5627485, 46.0439825],
              [-62.5629952, 46.0433659],
              [-62.5629852, 46.0430871],
              [-62.5628491, 46.0427287],
              [-62.5628425, 46.0422272],
              [-62.5627177, 46.0421277],
              [-62.5625752, 46.0421231],
              [-62.5624146, 46.0423049],
              [-62.562509, 46.0428609],
              [-62.5624506, 46.0434796],
              [-62.5616126, 46.0447414],
              [-62.5604445, 46.0460681],
              [-62.5604426, 46.0465255],
              [-62.5603697, 46.0467619],
              [-62.5599763, 46.0471788],
              [-62.5594038, 46.0474547],
              [-62.5588359, 46.047488],
              [-62.5578147, 46.0474669],
              [-62.5574768, 46.0476509],
              [-62.5574033, 46.0483248],
              [-62.5571545, 46.0493988],
              [-62.5559997, 46.050181],
              [-62.5553887, 46.0510324],
              [-62.554523, 46.0514181],
              [-62.5533196, 46.0522424],
              [-62.5528689, 46.0524029],
              [-62.5523469, 46.0524337],
              [-62.5520547, 46.0523606],
              [-62.5511413, 46.0517502],
              [-62.5503837, 46.0509459],
              [-62.5495354, 46.0502183],
              [-62.5493389, 46.0500806],
              [-62.548897, 46.049939],
              [-62.5482151, 46.0499686],
              [-62.5478713, 46.0500688],
              [-62.5471548, 46.0503598],
              [-62.5463128, 46.0509889],
              [-62.5457733, 46.0518784],
              [-62.5453757, 46.0523548],
              [-62.5449977, 46.052625],
              [-62.5447696, 46.0526176],
              [-62.5439837, 46.052067],
              [-62.5438309, 46.051875],
              [-62.543658, 46.0517262],
              [-62.5434209, 46.051257],
              [-62.5436715, 46.0506724],
              [-62.5437257, 46.0502048],
              [-62.5435827, 46.050037],
              [-62.5432753, 46.0497645],
              [-62.5427906, 46.0494941],
              [-62.5411929, 46.0489529],
              [-62.5407039, 46.0488335],
              [-62.539646, 46.0484212],
              [-62.5384332, 46.0480197],
              [-62.5371589, 46.0477674],
              [-62.5368712, 46.0477978],
              [-62.5360042, 46.0482032],
              [-62.5359178, 46.0481288],
              [-62.5358607, 46.0478723],
              [-62.5360001, 46.0474989],
              [-62.5364395, 46.046825],
              [-62.5367303, 46.0466634],
              [-62.5371523, 46.0454838],
              [-62.5377476, 46.0448706],
              [-62.5378438, 46.04446],
              [-62.5381251, 46.0439282],
              [-62.5384555, 46.0437718],
              [-62.538948, 46.0436685],
              [-62.5391484, 46.0434921],
              [-62.5390441, 46.0430033],
              [-62.5388115, 46.0426378],
              [-62.5386337, 46.0419676],
              [-62.5387246, 46.041891],
              [-62.5387174, 46.0415726],
              [-62.5389154, 46.0408351],
              [-62.5388587, 46.0404872],
              [-62.5385621, 46.0403105],
              [-62.536304, 46.0395726],
              [-62.5359237, 46.0395363],
              [-62.5357412, 46.0396179],
              [-62.535482, 46.0396492],
              [-62.5354214, 46.0396155],
              [-62.5353755, 46.0393633],
              [-62.5360331, 46.0376543],
              [-62.5363166, 46.0371743],
              [-62.5365735, 46.0368366],
              [-62.5369065, 46.0366406],
              [-62.5373816, 46.0365407],
              [-62.5388162, 46.0366113],
              [-62.5392793, 46.03669],
              [-62.5404797, 46.0367609],
              [-62.5425987, 46.0367621],
              [-62.5431699, 46.0367608],
              [-62.5441913, 46.0366906],
              [-62.5447945, 46.0364675],
              [-62.5458238, 46.0360236],
              [-62.5460148, 46.035986],
              [-62.5467712, 46.0358634],
              [-62.5475685, 46.0354716],
              [-62.5478048, 46.0352685],
              [-62.5481876, 46.0350104],
              [-62.5488912, 46.0342257],
              [-62.5490985, 46.0338585],
              [-62.5493613, 46.0332584],
              [-62.5494498, 46.03313],
              [-62.5495553, 46.0330897],
              [-62.5505876, 46.0336244],
              [-62.55091, 46.0338417],
              [-62.5517321, 46.0340195],
              [-62.5525433, 46.0341014],
              [-62.5530162, 46.0342043],
              [-62.5536979, 46.0341746],
              [-62.5541733, 46.0339832],
              [-62.5546886, 46.0337055],
              [-62.5547839, 46.0337325],
              [-62.5561629, 46.0346363],
              [-62.5571522, 46.0353883],
              [-62.5576557, 46.0358899],
              [-62.5581493, 46.0363673],
              [-62.5587213, 46.0367836],
              [-62.5590613, 46.037252],
              [-62.5592908, 46.0374941],
              [-62.5598605, 46.0378586],
              [-62.560448, 46.0381282],
              [-62.560498, 46.0383208],
              [-62.5603178, 46.0384542],
              [-62.560349, 46.03867],
              [-62.560639, 46.0389459],
              [-62.5608765, 46.0390689],
              [-62.5611997, 46.038248],
              [-62.5616789, 46.0377423],
              [-62.5623734, 46.0364043],
              [-62.562284, 46.0358605],
              [-62.5624542, 46.0354483],
              [-62.5625351, 46.0353475],
              [-62.5631151, 46.035044],
              [-62.5641309, 46.0347983],
              [-62.5651263, 46.0346872],
              [-62.5661282, 46.034823],
              [-62.5666232, 46.0346798],
              [-62.5670071, 46.0347479],
              [-62.5676985, 46.0347423],
              [-62.5680412, 46.0345704],
              [-62.5682834, 46.0341048],
              [-62.5687159, 46.0334386],
              [-62.5692145, 46.0323806],
              [-62.5690702, 46.0322328],
              [-62.5683029, 46.0320052],
              [-62.5680576, 46.0320013],
              [-62.5676285, 46.0320988],
              [-62.5668911, 46.0324529],
              [-62.566477, 46.0324953],
              [-62.5661581, 46.03231],
              [-62.5660105, 46.0320387],
              [-62.5660111, 46.0316012],
              [-62.5660286, 46.030651],
              [-62.5662129, 46.030112],
              [-62.5670032, 46.0292187],
              [-62.5673588, 46.0285937],
              [-62.5674022, 46.0282848],
              [-62.5674927, 46.0279536],
              [-62.567612, 46.0278779],
              [-62.5677508, 46.0278506],
              [-62.5683037, 46.0283815],
              [-62.5688881, 46.0287822],
              [-62.5696692, 46.0289744],
              [-62.5702243, 46.0289565],
              [-62.5711594, 46.0287201],
              [-62.5729215, 46.0279813],
              [-62.5744674, 46.0274026],
              [-62.5756505, 46.0267843],
              [-62.5764159, 46.0261764],
              [-62.5766043, 46.0258325],
              [-62.5766293, 46.0254554],
              [-62.5769051, 46.0252574],
              [-62.5787367, 46.0251055],
              [-62.5796186, 46.0248076],
              [-62.5800869, 46.0244607],
              [-62.580438, 46.0239867],
              [-62.5808575, 46.0237734],
              [-62.581166, 46.0237714],
              [-62.5815894, 46.0238446],
              [-62.5825223, 46.0238109],
              [-62.5849623, 46.0232647],
              [-62.5867105, 46.0228155],
              [-62.5871323, 46.022654],
              [-62.5878173, 46.02231],
              [-62.5882846, 46.0218914],
              [-62.5887672, 46.0213262],
              [-62.5890501, 46.021192],
              [-62.5897351, 46.0211941],
              [-62.5918609, 46.0215127],
              [-62.5926438, 46.0215019],
              [-62.5935829, 46.0214603],
              [-62.5961268, 46.0212393],
              [-62.597337, 46.0212421],
              [-62.5979742, 46.0211033],
              [-62.5996848, 46.0203545],
              [-62.6001697, 46.0201869],
              [-62.6008537, 46.0201173],
              [-62.6027945, 46.0194274],
              [-62.6033428, 46.0191624],
              [-62.6037655, 46.0190725],
              [-62.604142, 46.0190765],
              [-62.6044377, 46.0191814],
              [-62.6048364, 46.0188481],
              [-62.6063525, 46.0184508],
              [-62.6075985, 46.0180808],
              [-62.6087717, 46.017601],
              [-62.6091999, 46.0173402],
              [-62.609555, 46.0170611],
              [-62.609873, 46.0167372],
              [-62.610207, 46.0160835],
              [-62.6106638, 46.0157321],
              [-62.6111158, 46.0153686],
              [-62.6112031, 46.0151686],
              [-62.6111762, 46.0150564],
              [-62.6111689, 46.0149925],
              [-62.610771, 46.0147054],
              [-62.6099851, 46.0145015],
              [-62.609822, 46.014377],
              [-62.6098314, 46.0141466],
              [-62.6102204, 46.0136976],
              [-62.6105045, 46.0135435],
              [-62.6108699, 46.0135432],
              [-62.6111161, 46.0136186],
              [-62.61138, 46.0135992],
              [-62.6115001, 46.013595],
              [-62.6119391, 46.01343],
              [-62.612124, 46.0133086],
              [-62.6127398, 46.0127952],
              [-62.6132174, 46.0121263],
              [-62.6135585, 46.0115365],
              [-62.613741, 46.0113633],
              [-62.6142097, 46.0111793],
              [-62.615009, 46.0110019],
              [-62.615465, 46.0109248],
              [-62.6161587, 46.0108792],
              [-62.616467, 46.0108771],
              [-62.6165543, 46.010677],
              [-62.6168174, 46.0104944],
              [-62.6173847, 46.0104607],
              [-62.617627, 46.0105957],
              [-62.6176304, 46.0107191],
              [-62.6175421, 46.0108475],
              [-62.6176604, 46.010841],
              [-62.6177712, 46.0108349],
              [-62.6181148, 46.010643],
              [-62.6184962, 46.010659],
              [-62.6186842, 46.0107525],
              [-62.6188892, 46.0104965],
              [-62.6190943, 46.0102405],
              [-62.6189597, 46.0101169],
              [-62.618968, 46.0098149],
              [-62.619071, 46.0092852],
              [-62.6196062, 46.0083436],
              [-62.6200031, 46.0081216],
              [-62.6212738, 46.0076289],
              [-62.6214835, 46.0074764],
              [-62.6215296, 46.0073824],
              [-62.6197621, 46.0079668],
              [-62.6191254, 46.0084517],
              [-62.6188075, 46.0087757],
              [-62.618547, 46.0089186],
              [-62.6181926, 46.0090148],
              [-62.6172178, 46.0090833],
              [-62.6168322, 46.0092182],
              [-62.6157978, 46.0097621],
              [-62.6146484, 46.0102308],
              [-62.6143634, 46.0103132],
              [-62.6135552, 46.0105381],
              [-62.6130156, 46.0108471],
              [-62.6124068, 46.0115159],
              [-62.6117533, 46.0126048],
              [-62.6112672, 46.0127922],
              [-62.6104542, 46.013005],
              [-62.6102019, 46.0132833],
              [-62.6097875, 46.0135088],
              [-62.6093194, 46.0135098],
              [-62.609033, 46.0136121],
              [-62.6088356, 46.013749],
              [-62.6074935, 46.0148041],
              [-62.6069144, 46.0150163],
              [-62.6065511, 46.0151599],
              [-62.6058909, 46.0155645],
              [-62.6056529, 46.0156245],
              [-62.6051019, 46.0155831],
              [-62.6038294, 46.0157494],
              [-62.603392, 46.0158031],
              [-62.6011141, 46.0162396],
              [-62.5994625, 46.0167875],
              [-62.5988745, 46.0170472],
              [-62.5981772, 46.0174068],
              [-62.5975368, 46.0174222],
              [-62.5966763, 46.0173112],
              [-62.5962598, 46.0170473],
              [-62.5959753, 46.0169467],
              [-62.5954999, 46.0168838],
              [-62.5952218, 46.016684],
              [-62.5944765, 46.0155187],
              [-62.5942417, 46.0157021],
              [-62.5942424, 46.0158652],
              [-62.5946914, 46.0164165],
              [-62.5947809, 46.0170518],
              [-62.5945629, 46.0174147],
              [-62.5939972, 46.018029],
              [-62.5937419, 46.0180925],
              [-62.5934216, 46.0179271],
              [-62.5931374, 46.0177351],
              [-62.5929642, 46.0176778],
              [-62.5921502, 46.0173813],
              [-62.5913307, 46.0172557],
              [-62.5909472, 46.0170963],
              [-62.5902934, 46.0170555],
              [-62.5894542, 46.0168815],
              [-62.5880899, 46.0170526],
              [-62.5869807, 46.0172517],
              [-62.5852748, 46.0176664],
              [-62.5832422, 46.0183609],
              [-62.582123, 46.0188819],
              [-62.5818784, 46.0190411],
              [-62.5814805, 46.019446],
              [-62.581345, 46.0195967],
              [-62.5812542, 46.0200194],
              [-62.5811311, 46.0201547],
              [-62.5808858, 46.0201508],
              [-62.5799783, 46.0197159],
              [-62.5794459, 46.0196511],
              [-62.5790281, 46.019753],
              [-62.5767738, 46.0206869],
              [-62.5754227, 46.02126],
              [-62.5746248, 46.0219265],
              [-62.5744798, 46.0219616],
              [-62.5741143, 46.0219618],
              [-62.5740328, 46.0218995],
              [-62.5734694, 46.0215273],
              [-62.5731679, 46.0213386],
              [-62.5725874, 46.0212245],
              [-62.5719223, 46.0211792],
              [-62.5710817, 46.0213709],
              [-62.5701002, 46.0217928],
              [-62.5695935, 46.0221145],
              [-62.5691278, 46.0224217],
              [-62.5681793, 46.022948],
              [-62.5674515, 46.0234178],
              [-62.567094, 46.0238995],
              [-62.5668982, 46.023925],
              [-62.5664329, 46.0237946],
              [-62.565166, 46.0236981],
              [-62.5640176, 46.0237087],
              [-62.5616359, 46.024491],
              [-62.5608931, 46.0246699],
              [-62.559518, 46.0252539],
              [-62.5581761, 46.026197],
              [-62.5574326, 46.0268133],
              [-62.5571673, 46.0268525],
              [-62.5569523, 46.0267381],
              [-62.556728, 46.0261621],
              [-62.5565533, 46.02587],
              [-62.5564464, 46.0256756],
              [-62.5559034, 46.0251688],
              [-62.5548214, 46.0244416],
              [-62.5544861, 46.0243313],
              [-62.5538839, 46.0243715],
              [-62.5536444, 46.0244513],
              [-62.5530774, 46.0246477],
              [-62.5523399, 46.0247471],
              [-62.5515869, 46.0249932],
              [-62.5507497, 46.0252166],
              [-62.5496106, 46.0253428],
              [-62.5480852, 46.0254405],
              [-62.546968, 46.0256667],
              [-62.5459888, 46.0260486],
              [-62.5454618, 46.0261588],
              [-62.5446374, 46.0262752],
              [-62.5432931, 46.0263111],
              [-62.5419706, 46.0264471],
              [-62.541432, 46.0266444],
              [-62.5410959, 46.026717],
              [-62.5386694, 46.0266261],
              [-62.5378214, 46.0267536],
              [-62.5369797, 46.0271279],
              [-62.5354706, 46.0274049],
              [-62.5350072, 46.0274177],
              [-62.5346876, 46.0274152],
              [-62.5340835, 46.027312],
              [-62.533414, 46.0269083],
              [-62.5330298, 46.0264224],
              [-62.5330391, 46.0262835],
              [-62.5328183, 46.0260853],
              [-62.5325957, 46.025744],
              [-62.5326077, 46.0253108],
              [-62.5327777, 46.0249901],
              [-62.5331703, 46.0242472],
              [-62.5333407, 46.0240896],
              [-62.5333955, 46.0240397],
              [-62.5332549, 46.0244329],
              [-62.5330208, 46.0249424],
              [-62.5329945, 46.0252478],
              [-62.5330751, 46.0254931],
              [-62.5332785, 46.0256946],
              [-62.5335216, 46.0256469],
              [-62.5336996, 46.0254617],
              [-62.53381, 46.0251789],
              [-62.5337601, 46.0247318],
              [-62.5338495, 46.0244205],
              [-62.5341527, 46.0242434],
              [-62.5343401, 46.0241739],
              [-62.5349085, 46.0242124],
              [-62.5349913, 46.0242548],
              [-62.5354769, 46.0242508],
              [-62.5360048, 46.0239577],
              [-62.5367267, 46.023496],
              [-62.5367267, 46.0232414],
              [-62.5367324, 46.0230705],
              [-62.5361349, 46.0218498],
              [-62.5359386, 46.0217121],
              [-62.5354419, 46.0217119],
              [-62.5349073, 46.0218496],
              [-62.5344253, 46.0221401],
              [-62.5340746, 46.0221764],
              [-62.5333277, 46.0219055],
              [-62.5327223, 46.0215675],
              [-62.5317733, 46.0213297],
              [-62.530775, 46.021317],
              [-62.5302284, 46.0213787],
              [-62.5290529, 46.0216227],
              [-62.5279432, 46.0220757],
              [-62.5269747, 46.0222987],
              [-62.5263462, 46.0223895],
              [-62.5245167, 46.0228468],
              [-62.5238521, 46.0229642],
              [-62.5228965, 46.0228256],
              [-62.5225632, 46.0226038],
              [-62.5222579, 46.0222199],
              [-62.5221351, 46.0220091],
              [-62.5220822, 46.0216931],
              [-62.5221431, 46.0216354],
              [-62.5222175, 46.0216339],
              [-62.5225873, 46.0219921],
              [-62.5228325, 46.0219961],
              [-62.5231299, 46.0217354],
              [-62.5245833, 46.0204186],
              [-62.5252261, 46.0196918],
              [-62.5256147, 46.0190084],
              [-62.5258484, 46.018845],
              [-62.5262481, 46.0189297],
              [-62.5265756, 46.0190677],
              [-62.5267777, 46.0190345],
              [-62.5273554, 46.018934],
              [-62.5282732, 46.0189561],
              [-62.5288273, 46.0186122],
              [-62.529356, 46.0183908],
              [-62.530191, 46.0183704],
              [-62.5304505, 46.0182476],
              [-62.5315409, 46.0181638],
              [-62.5316045, 46.0180665],
              [-62.5316094, 46.017824],
              [-62.5317349, 46.0177406],
              [-62.5320295, 46.0177741],
              [-62.533634, 46.0181963],
              [-62.534149, 46.0182648],
              [-62.5349207, 46.0179957],
              [-62.536197, 46.0173531],
              [-62.536966, 46.016869],
              [-62.5379246, 46.0166218],
              [-62.5390749, 46.015645],
              [-62.5396707, 46.0153581],
              [-62.540417, 46.0152114],
              [-62.5408081, 46.0149974],
              [-62.5412935, 46.0144842],
              [-62.5419058, 46.0141222],
              [-62.5434472, 46.0136951],
              [-62.5448218, 46.0134573],
              [-62.5461582, 46.013449],
              [-62.5469569, 46.0132005],
              [-62.5476545, 46.0125868],
              [-62.547705, 46.0120873],
              [-62.5479937, 46.0117824],
              [-62.5483407, 46.0117141],
              [-62.5489588, 46.0116904],
              [-62.5496711, 46.0113675],
              [-62.5500591, 46.0112846],
              [-62.5519289, 46.0110668],
              [-62.5522937, 46.0109037],
              [-62.552496, 46.0105244],
              [-62.5535021, 46.0095627],
              [-62.5540408, 46.0092738],
              [-62.5547358, 46.0092089],
              [-62.555123, 46.0093089],
              [-62.5560792, 46.0092643],
              [-62.5567974, 46.0092796],
              [-62.5574547, 46.0093526],
              [-62.5584496, 46.0092416],
              [-62.5580712, 46.0090941],
              [-62.5576218, 46.0090716],
              [-62.5573717, 46.0090556],
              [-62.5558854, 46.0088324],
              [-62.5553665, 46.0088236],
              [-62.5550817, 46.0088144],
              [-62.5546546, 46.0088005],
              [-62.5544565, 46.0087742],
              [-62.5540601, 46.0088131],
              [-62.5535787, 46.0090123],
              [-62.5521394, 46.0102225],
              [-62.5516545, 46.0103897],
              [-62.5503133, 46.010386],
              [-62.5497753, 46.0104919],
              [-62.5488904, 46.0109206],
              [-62.5482372, 46.0110426],
              [-62.547389, 46.0110071],
              [-62.5469891, 46.0112685],
              [-62.5460669, 46.0119983],
              [-62.5453638, 46.0119277],
              [-62.5449472, 46.0117551],
              [-62.5443922, 46.0115183],
              [-62.543815, 46.0115273],
              [-62.5428575, 46.0118462],
              [-62.5425598, 46.0119439],
              [-62.5401298, 46.0125132],
              [-62.5378256, 46.0129075],
              [-62.5374767, 46.0130871],
              [-62.5370714, 46.0134279],
              [-62.5367149, 46.013635],
              [-62.5355579, 46.0139474],
              [-62.534995, 46.0143387],
              [-62.5350413, 46.0147539],
              [-62.5347939, 46.0149526],
              [-62.5347588, 46.0150509],
              [-62.5345585, 46.0152274],
              [-62.534421, 46.0152348],
              [-62.5340805, 46.0149493],
              [-62.5336417, 46.0150225],
              [-62.5330236, 46.0150461],
              [-62.5327811, 46.0150023],
              [-62.5324717, 46.0149326],
              [-62.5321887, 46.014812],
              [-62.5318531, 46.0145385],
              [-62.5319524, 46.0142514],
              [-62.5320431, 46.0141748],
              [-62.53204, 46.0140514],
              [-62.5318068, 46.0138688],
              [-62.531615, 46.0138347],
              [-62.5311753, 46.0138362],
              [-62.5306119, 46.0138099],
              [-62.5300703, 46.0136291],
              [-62.529794, 46.0136638],
              [-62.5285283, 46.0136384],
              [-62.5280709, 46.0137348],
              [-62.5279721, 46.0139305],
              [-62.5278652, 46.0144997],
              [-62.5283085, 46.0147847],
              [-62.5282657, 46.0149106],
              [-62.5279555, 46.0150238],
              [-62.5276818, 46.0150188],
              [-62.5271211, 46.0146982],
              [-62.5266774, 46.0147592],
              [-62.525992, 46.0151028],
              [-62.5255488, 46.0150724],
              [-62.5252684, 46.0151666],
              [-62.5248892, 46.0152019],
              [-62.524462, 46.0149334],
              [-62.5241251, 46.0149343],
              [-62.5240059, 46.01501],
              [-62.5238621, 46.0150251],
              [-62.5238216, 46.0152028],
              [-62.5240418, 46.0154924],
              [-62.5241366, 46.0158654],
              [-62.5243502, 46.0159997],
              [-62.5246559, 46.0160376],
              [-62.525032, 46.0161334],
              [-62.5253382, 46.0163343],
              [-62.5255603, 46.0162581],
              [-62.525834, 46.0160085],
              [-62.5262643, 46.0158913],
              [-62.5263605, 46.0157353],
              [-62.5266026, 46.015616],
              [-62.5272724, 46.0156736],
              [-62.5276369, 46.0158566],
              [-62.5278732, 46.0159081],
              [-62.5280147, 46.0158411],
              [-62.5279426, 46.0156399],
              [-62.5280134, 46.0156064],
              [-62.5282252, 46.0155974],
              [-62.5285648, 46.0160659],
              [-62.5287419, 46.0163183],
              [-62.5286404, 46.0168082],
              [-62.5283168, 46.0171198],
              [-62.5280872, 46.0172237],
              [-62.5274685, 46.0175933],
              [-62.5271477, 46.0176107],
              [-62.5270983, 46.0175812],
              [-62.5264605, 46.0170472],
              [-62.5258459, 46.0168481],
              [-62.5249977, 46.0168124],
              [-62.5238948, 46.0171662],
              [-62.5237052, 46.0171838],
              [-62.5230294, 46.0180607],
              [-62.5226426, 46.0183781],
              [-62.5222879, 46.0184739],
              [-62.5217738, 46.018477],
              [-62.5209851, 46.018658],
              [-62.5201026, 46.0185376],
              [-62.5190834, 46.0184962],
              [-62.5188355, 46.0185319],
              [-62.5183632, 46.018592],
              [-62.5175821, 46.0187454],
              [-62.5162865, 46.0187387],
              [-62.5158775, 46.0187929],
              [-62.5148021, 46.0190758],
              [-62.5141487, 46.0191975],
              [-62.5137841, 46.0192691],
              [-62.5130137, 46.0192637],
              [-62.5121107, 46.0190232],
              [-62.5112407, 46.0188872],
              [-62.5108971, 46.0187327],
              [-62.5106626, 46.0185699],
              [-62.510506, 46.0184375],
              [-62.5100304, 46.018211],
              [-62.5097892, 46.0181474],
              [-62.5095591, 46.0180881],
              [-62.5089927, 46.0176837],
              [-62.5088282, 46.0174157],
              [-62.5088173, 46.0169022],
              [-62.5088774, 46.016773],
              [-62.509073, 46.0163299],
              [-62.5097832, 46.0155378],
              [-62.5099133, 46.0153035],
              [-62.5102628, 46.0150325],
              [-62.5103175, 46.0149826],
              [-62.5106522, 46.01493],
              [-62.5110634, 46.0149276],
              [-62.5122436, 46.0149505],
              [-62.5127888, 46.0149087],
              [-62.5133136, 46.014747],
              [-62.5135335, 46.0146189],
              [-62.5141976, 46.0140839],
              [-62.514646, 46.0133627],
              [-62.515186, 46.0128912],
              [-62.5154024, 46.0124767],
              [-62.5155704, 46.0118498],
              [-62.5156377, 46.0112752],
              [-62.515968, 46.011119],
              [-62.5163707, 46.0110726],
              [-62.5183235, 46.0106434],
              [-62.518971, 46.0104379],
              [-62.5197315, 46.0104191],
              [-62.5200741, 46.0102473],
              [-62.5207079, 46.0098226],
              [-62.521193, 46.0096555],
              [-62.5215089, 46.0096261],
              [-62.5231964, 46.0084722],
              [-62.5237237, 46.0082707],
              [-62.5238661, 46.0082754],
              [-62.523944, 46.0085603],
              [-62.5238678, 46.0089277],
              [-62.5241063, 46.009031],
              [-62.5247662, 46.00881],
              [-62.525237, 46.0087697],
              [-62.5258565, 46.0084718],
              [-62.5262396, 46.0083769],
              [-62.5266561, 46.0080405],
              [-62.5267429, 46.0077689],
              [-62.5267616, 46.0074911],
              [-62.5269294, 46.0071187],
              [-62.5268333, 46.0070201],
              [-62.5262682, 46.007105],
              [-62.5257391, 46.0074179],
              [-62.5250912, 46.0079694],
              [-62.5248086, 46.0080119],
              [-62.5247098, 46.007953],
              [-62.5246342, 46.0077198],
              [-62.5234707, 46.0071129],
              [-62.5225938, 46.0069132],
              [-62.5222877, 46.0067123],
              [-62.5220933, 46.0062087],
              [-62.5220974, 46.0058946],
              [-62.5223862, 46.0055899],
              [-62.5230888, 46.0054975],
              [-62.5234568, 46.0052033],
              [-62.5234105, 46.0050427],
              [-62.5224134, 46.0053561],
              [-62.5220245, 46.0053672],
              [-62.5215901, 46.0057985],
              [-62.5209087, 46.0068461],
              [-62.5207129, 46.007126],
              [-62.5205518, 46.0073992],
              [-62.5202447, 46.0076357],
              [-62.5197352, 46.0077423],
              [-62.5192337, 46.0077299],
              [-62.5186264, 46.0075946],
              [-62.5182895, 46.0075955],
              [-62.5179264, 46.0076472],
              [-62.5169422, 46.007599],
              [-62.5165733, 46.0075671],
              [-62.5157141, 46.0075269],
              [-62.5154414, 46.0075936],
              [-62.5147302, 46.0078964],
              [-62.5142526, 46.0082904],
              [-62.5138286, 46.0093265],
              [-62.5137748, 46.009448],
              [-62.5139098, 46.0101525],
              [-62.5141882, 46.0106788],
              [-62.5142668, 46.0110353],
              [-62.5142303, 46.0111535],
              [-62.5139108, 46.0111509],
              [-62.5133937, 46.0110305],
              [-62.5120214, 46.0108104],
              [-62.5117353, 46.0108209],
              [-62.5115408, 46.0108265],
              [-62.5107269, 46.0108753],
              [-62.5099917, 46.0110261],
              [-62.5090835, 46.0111196],
              [-62.5081396, 46.0111482],
              [-62.5069907, 46.0110865],
              [-62.5048768, 46.0106072],
              [-62.5041582, 46.0104284],
              [-62.5038075, 46.0104646],
              [-62.5036389, 46.0105108],
              [-62.5033763, 46.0105101],
              [-62.5033114, 46.0103727],
              [-62.503579, 46.0101309],
              [-62.5035391, 46.0099625],
              [-62.5035409, 46.0098512],
              [-62.5033555, 46.0095548],
              [-62.5034421, 46.0091201],
              [-62.5033341, 46.0089455],
              [-62.5025682, 46.0083713],
              [-62.5024725, 46.0084358],
              [-62.5024135, 46.0087998],
              [-62.5023096, 46.0092379],
              [-62.5020729, 46.0095307],
              [-62.5018696, 46.0097484],
              [-62.5018106, 46.0101124],
              [-62.5018671, 46.0102058],
              [-62.5023056, 46.0106418],
              [-62.5025872, 46.0107824],
              [-62.5028618, 46.0106045],
              [-62.5030216, 46.0106058],
              [-62.5033315, 46.0110018],
              [-62.5032673, 46.0111906],
              [-62.5025995, 46.0116937],
              [-62.5014155, 46.0121478],
              [-62.5007204, 46.0122124],
              [-62.5, 46.012393],
              [-62.4986282, 46.0125054],
              [-62.4979654, 46.0127659],
              [-62.4970545, 46.0133166],
              [-62.4957859, 46.0142572],
              [-62.4955549, 46.0143808],
              [-62.4947716, 46.0148999],
              [-62.493571, 46.015429],
              [-62.4932238, 46.0154971],
              [-62.4905595, 46.0153015],
              [-62.4902405, 46.0152074],
              [-62.489593, 46.0151582],
              [-62.4881199, 46.0152446],
              [-62.4879184, 46.0152787],
              [-62.4877315, 46.0152839],
              [-62.4876669, 46.0152318],
              [-62.4876482, 46.0150938],
              [-62.4876132, 46.0150279],
              [-62.4875002, 46.0150035],
              [-62.4872076, 46.0151171],
              [-62.4872484, 46.0152317],
              [-62.4873408, 46.0153036],
              [-62.4873961, 46.0154323],
              [-62.4873447, 46.0154949],
              [-62.4868078, 46.0155353],
              [-62.4868978, 46.0159968],
              [-62.4866423, 46.016019],
              [-62.4866014, 46.0158101],
              [-62.4858187, 46.015872],
              [-62.4857578, 46.0158764],
              [-62.4854611, 46.0158049],
              [-62.4852395, 46.0157896],
              [-62.4845478, 46.0158057],
              [-62.4841832, 46.0158772],
              [-62.4838311, 46.0158472],
              [-62.4833, 46.0156848],
              [-62.4827873, 46.0156678],
              [-62.4819757, 46.0160115],
              [-62.4804944, 46.0166346],
              [-62.4798246, 46.0169869],
              [-62.4792946, 46.0172387],
              [-62.4788533, 46.0174009],
              [-62.4785561, 46.0178133],
              [-62.4782068, 46.0179777],
              [-62.4777612, 46.0186123],
              [-62.4776595, 46.0188476],
              [-62.4775787, 46.019238],
              [-62.4774493, 46.0193523],
              [-62.4772652, 46.0194227],
              [-62.477096, 46.0192385],
              [-62.4769623, 46.0190233],
              [-62.4767719, 46.0189692],
              [-62.4765495, 46.0189161],
              [-62.4763554, 46.0188515],
              [-62.4761456, 46.0187548],
              [-62.4758801, 46.0185777],
              [-62.475688, 46.0179546],
              [-62.4757245, 46.0173031],
              [-62.4762882, 46.0170279],
              [-62.4763053, 46.0168614],
              [-62.4762622, 46.0168242],
              [-62.4761094, 46.0168867],
              [-62.4757462, 46.0169383],
              [-62.4754272, 46.0168441],
              [-62.4753277, 46.0167135],
              [-62.4751747, 46.016613],
              [-62.4748983, 46.0166475],
              [-62.4747697, 46.0170251],
              [-62.4744568, 46.0171761],
              [-62.4741719, 46.0177491],
              [-62.4736575, 46.0180427],
              [-62.4737775, 46.0183755],
              [-62.4743022, 46.0186606],
              [-62.4755199, 46.0189077],
              [-62.4761351, 46.0191788],
              [-62.4768336, 46.0197497],
              [-62.4771718, 46.0198503],
              [-62.4773816, 46.0198156],
              [-62.4775318, 46.019707],
              [-62.4776704, 46.0195762],
              [-62.4778888, 46.0194568],
              [-62.4781291, 46.0191799],
              [-62.4783865, 46.0190706],
              [-62.4785718, 46.0189495],
              [-62.4787977, 46.0190684],
              [-62.4789895, 46.0191026],
              [-62.4791656, 46.0192834],
              [-62.4793039, 46.0197654],
              [-62.4792971, 46.0198646],
              [-62.4791611, 46.0208506],
              [-62.4789674, 46.0212294],
              [-62.4786391, 46.021608],
              [-62.478228, 46.0216729],
              [-62.4780819, 46.0215953],
              [-62.4779931, 46.0216205],
              [-62.4779417, 46.0215997],
              [-62.477372, 46.0213181],
              [-62.4768168, 46.0209466],
              [-62.4760086, 46.0203158],
              [-62.4750224, 46.0196955],
              [-62.473867, 46.019081],
              [-62.4729492, 46.0186691],
              [-62.4718087, 46.0184284],
              [-62.4704711, 46.0181222],
              [-62.4687726, 46.0179739],
              [-62.4668039, 46.0178441],
              [-62.4658296, 46.0176912],
              [-62.4652924, 46.0176587],
              [-62.4647082, 46.0177368],
              [-62.4642698, 46.0177141],
              [-62.4635649, 46.0178231],
              [-62.4627147, 46.0177212],
              [-62.4617397, 46.0173704],
              [-62.4608925, 46.0169881],
              [-62.4600614, 46.0167853],
              [-62.459371, 46.0168617],
              [-62.4587469, 46.0172187],
              [-62.4584962, 46.0172939],
              [-62.4579009, 46.0172342],
              [-62.4572545, 46.0175825],
              [-62.4567752, 46.0175784],
              [-62.4559, 46.0176843],
              [-62.4557153, 46.017714],
              [-62.4549729, 46.0179636],
              [-62.4544784, 46.0180146],
              [-62.4542497, 46.0179354],
              [-62.4543023, 46.0176706],
              [-62.4545292, 46.0174435],
              [-62.4544609, 46.0172742],
              [-62.4540541, 46.0167991],
              [-62.4532791, 46.0156196],
              [-62.4529147, 46.0152733],
              [-62.4529185, 46.0148875],
              [-62.4530036, 46.0145642],
              [-62.4529692, 46.0141533],
              [-62.45316, 46.0139529],
              [-62.4535529, 46.0137194],
              [-62.4536607, 46.0131502],
              [-62.4539925, 46.0122305],
              [-62.454273, 46.0121365],
              [-62.4543232, 46.0116569],
              [-62.4542329, 46.0103173],
              [-62.4544015, 46.0101082],
              [-62.4551839, 46.0097724],
              [-62.455369, 46.0094882],
              [-62.4554309, 46.0089215],
              [-62.4557764, 46.0085472],
              [-62.4559692, 46.0082355],
              [-62.4559793, 46.0080051],
              [-62.4562713, 46.006917],
              [-62.4563303, 46.0062269],
              [-62.456724, 46.0049036],
              [-62.4569187, 46.0044805],
              [-62.4571482, 46.0034699],
              [-62.4572895, 46.00324],
              [-62.4574812, 46.0031112],
              [-62.457945, 46.0031704],
              [-62.4586027, 46.0031527],
              [-62.4592588, 46.0029916],
              [-62.4596849, 46.0027712],
              [-62.460316, 46.0026412],
              [-62.460645, 46.002505],
              [-62.4612133, 46.0021262],
              [-62.4618463, 46.0018849],
              [-62.4627983, 46.00132],
              [-62.4634133, 46.0010104],
              [-62.4644871, 46.0],
              [-62.4652505, 45.9992739],
              [-62.4662627, 45.9985798],
              [-62.4667656, 45.9981551],
              [-62.468418, 45.9964482],
              [-62.4687564, 45.9961731],
              [-62.4699927, 45.9954546],
              [-62.4702173, 45.9951757],
              [-62.4705335, 45.994892],
              [-62.4707187, 45.9947709],
              [-62.4717923, 45.9939276],
              [-62.4723976, 45.9933392],
              [-62.4728005, 45.9930384],
              [-62.4730004, 45.9929536],
              [-62.4736278, 45.9927915],
              [-62.4747413, 45.9922797],
              [-62.4749392, 45.9923062],
              [-62.4750894, 45.9924464],
              [-62.4751826, 45.9925848],
              [-62.4754632, 45.9926538],
              [-62.475849, 45.9925194],
              [-62.4762872, 45.992538],
              [-62.4765016, 45.9924894],
              [-62.477039, 45.9920579],
              [-62.4778242, 45.9915908],
              [-62.4781639, 45.9912958],
              [-62.4783477, 45.9911945],
              [-62.4787766, 45.9910974],
              [-62.4791641, 45.9906887],
              [-62.479535, 45.990355],
              [-62.4799289, 45.9901016],
              [-62.4804028, 45.9895128],
              [-62.4808487, 45.9892492],
              [-62.4810721, 45.9891532],
              [-62.4812838, 45.9891443],
              [-62.4816688, 45.9889384],
              [-62.4818046, 45.9887878],
              [-62.4819277, 45.9884896],
              [-62.4821302, 45.9883651],
              [-62.4823357, 45.9883639],
              [-62.4829589, 45.9886789],
              [-62.4832333, 45.9887556],
              [-62.483638, 45.9887611],
              [-62.4844713, 45.9885064],
              [-62.4848926, 45.9884368],
              [-62.4850986, 45.9883442],
              [-62.4853024, 45.9881999],
              [-62.4859107, 45.9874803],
              [-62.4864699, 45.9868943],
              [-62.4866995, 45.9867905],
              [-62.4869028, 45.9867376],
              [-62.4875357, 45.9866591],
              [-62.4877146, 45.9865458],
              [-62.4886565, 45.985702],
              [-62.4902, 45.9844009],
              [-62.4905317, 45.9839704],
              [-62.4908998, 45.9836763],
              [-62.4919031, 45.983101],
              [-62.492114, 45.9830205],
              [-62.492726, 45.9829135],
              [-62.4931407, 45.9826886],
              [-62.4934184, 45.9823796],
              [-62.4938659, 45.9822592],
              [-62.4945205, 45.9822808],
              [-62.4950764, 45.9822435],
              [-62.4954198, 45.9821435],
              [-62.4959001, 45.9821276],
              [-62.4961741, 45.9820412],
              [-62.4969749, 45.9815904],
              [-62.4974336, 45.9812198],
              [-62.4977358, 45.9809712],
              [-62.4978841, 45.9805506],
              [-62.4979651, 45.9804499],
              [-62.4982285, 45.9802677],
              [-62.498435, 45.9800836],
              [-62.4989328, 45.9798097],
              [-62.5002033, 45.9789926],
              [-62.5012555, 45.9789557],
              [-62.5021789, 45.9784612],
              [-62.502609, 45.9783442],
              [-62.5034768, 45.9778279],
              [-62.5042213, 45.9777013],
              [-62.5051303, 45.9773336],
              [-62.5058147, 45.9773363],
              [-62.5061629, 45.9772483],
              [-62.5069047, 45.9769069],
              [-62.5073646, 45.9767709],
              [-62.5076591, 45.9765499],
              [-62.5076631, 45.9764904],
              [-62.5078517, 45.9764011],
              [-62.5090042, 45.9763118],
              [-62.5098608, 45.9757912],
              [-62.5102104, 45.9756834],
              [-62.5108905, 45.9750734],
              [-62.5112609, 45.9749941],
              [-62.5115345, 45.9747446],
              [-62.5125807, 45.9746239],
              [-62.5127048, 45.9745604],
              [-62.513981, 45.9742444],
              [-62.5141864, 45.9742432],
              [-62.5149126, 45.9740483],
              [-62.5153652, 45.9738484],
              [-62.5157885, 45.9738305],
              [-62.5162106, 45.9735779],
              [-62.5165414, 45.9734934],
              [-62.5168415, 45.9736106],
              [-62.5171176, 45.9735759],
              [-62.517978, 45.9732502],
              [-62.51846, 45.9731228],
              [-62.5187828, 45.9731573],
              [-62.518905, 45.973205],
              [-62.5192243, 45.9732076],
              [-62.5195426, 45.9731385],
              [-62.5199543, 45.9734622],
              [-62.5201792, 45.9735093],
              [-62.5208551, 45.9734678],
              [-62.5214495, 45.9736186],
              [-62.5217896, 45.9736496],
              [-62.5221711, 45.9735746],
              [-62.5229737, 45.9734299],
              [-62.5241768, 45.9730955],
              [-62.524457, 45.9730012],
              [-62.52489, 45.9727529],
              [-62.5257842, 45.9726033],
              [-62.5262986, 45.9724173],
              [-62.5271167, 45.9718714],
              [-62.5276983, 45.97162],
              [-62.5285039, 45.9713441],
              [-62.5291766, 45.9711792],
              [-62.5296901, 45.9711761],
              [-62.5308762, 45.971008],
              [-62.5312195, 45.9709079],
              [-62.531825, 45.9708084],
              [-62.5331841, 45.9709522],
              [-62.5334371, 45.9710917],
              [-62.5345668, 45.9710848],
              [-62.5349687, 45.9711297],
              [-62.5358797, 45.971068],
              [-62.5363185, 45.9709033],
              [-62.5370271, 45.9707117],
              [-62.5379882, 45.970242],
              [-62.5389014, 45.9702319],
              [-62.5402223, 45.9703505],
              [-62.5410959, 45.9703352],
              [-62.5414876, 45.9701929],
              [-62.5429088, 45.9700045],
              [-62.543136, 45.9698489],
              [-62.5454272, 45.9693587],
              [-62.5460797, 45.9689822],
              [-62.5467847, 45.9687586],
              [-62.5475288, 45.9686317],
              [-62.5475826, 45.9685101],
              [-62.5482521, 45.9684762],
              [-62.5484726, 45.9684198],
              [-62.5488974, 45.9680359],
              [-62.5491152, 45.9680191],
              [-62.5492976, 45.968192],
              [-62.5501902, 45.9680619],
              [-62.5516139, 45.9675793],
              [-62.5526999, 45.9673719],
              [-62.5528772, 45.9672782],
              [-62.5532821, 45.967192],
              [-62.5538623, 45.9672147],
              [-62.5540049, 45.9671279],
              [-62.5545508, 45.9670661],
              [-62.5546468, 45.9669101],
              [-62.555158, 45.9668551],
              [-62.5561601, 45.9671341],
              [-62.5567279, 45.9671724],
              [-62.5575897, 45.9669896],
              [-62.5576661, 45.9667852],
              [-62.5579003, 45.9666934],
              [-62.5587693, 45.9665744],
              [-62.5598952, 45.9662808],
              [-62.5608256, 45.9662671],
              [-62.5613004, 45.9663302],
              [-62.5618014, 45.9663424],
              [-62.5619356, 45.9662116],
              [-62.5621556, 45.9662465],
              [-62.562462, 45.9663558],
              [-62.5629238, 45.9662714],
              [-62.5633568, 45.9662774],
              [-62.5636234, 45.966127],
              [-62.5644872, 45.9660873],
              [-62.5647975, 45.966137],
              [-62.5657564, 45.9661243],
              [-62.5673297, 45.9662188],
              [-62.5674742, 45.9662752],
              [-62.5680112, 45.9662607],
              [-62.5682094, 45.9661955],
              [-62.5687967, 45.9662821],
              [-62.5693422, 45.9663116],
              [-62.5699672, 45.9662602],
              [-62.5713947, 45.9663181],
              [-62.5716217, 45.9661624],
              [-62.5720302, 45.9661079],
              [-62.5727624, 45.9661594],
              [-62.5728867, 45.9660043],
              [-62.5731121, 45.9659599],
              [-62.5747427, 45.9659647],
              [-62.5749667, 45.9659401],
              [-62.5753706, 45.9657821],
              [-62.5760201, 45.9657911],
              [-62.5761581, 45.9659466],
              [-62.5777331, 45.9659297],
              [-62.5791328, 45.9658037],
              [-62.5804183, 45.9657655],
              [-62.5804514, 45.965524],
              [-62.5811264, 45.9656649],
              [-62.5822968, 45.9656429],
              [-62.5825594, 45.9655519],
              [-62.5829591, 45.9655449],
              [-62.5832109, 45.965704],
              [-62.5837923, 45.9657068],
              [-62.5839398, 45.965632],
              [-62.5860096, 45.9656348],
              [-62.5862804, 45.9656793],
              [-62.5864792, 45.9657771],
              [-62.5872648, 45.9657983],
              [-62.5874005, 45.9659021],
              [-62.5879757, 45.9659126],
              [-62.5881405, 45.9658343],
              [-62.5885215, 45.9658505],
              [-62.5895589, 45.9660309],
              [-62.5898709, 45.9659693],
              [-62.5900203, 45.9660377],
              [-62.5901148, 45.9659016],
              [-62.590334, 45.9658648],
              [-62.5904053, 45.9659944],
              [-62.5905079, 45.9659937],
              [-62.5906002, 45.9661517],
              [-62.5907781, 45.966221],
              [-62.5911146, 45.9662199],
              [-62.5914675, 45.9661437],
              [-62.5918949, 45.9659744],
              [-62.5921241, 45.9658704],
              [-62.5922333, 45.9657705],
              [-62.5927445, 45.9657153],
              [-62.5931834, 45.9658049],
              [-62.5936164, 45.9658108],
              [-62.5946288, 45.9656762],
              [-62.5947132, 45.9656073],
              [-62.5950808, 45.9655674],
              [-62.5953293, 45.9656031],
              [-62.5965913, 45.9655759],
              [-62.5968006, 45.965515],
              [-62.5973697, 45.9655331],
              [-62.5979717, 45.9656558],
              [-62.5988613, 45.9656563],
              [-62.6000503, 45.9656108],
              [-62.6007208, 45.9656481],
              [-62.6027637, 45.9655383],
              [-62.604372, 45.9655339],
              [-62.604566, 45.9656196],
              [-62.6053083, 45.9656035],
              [-62.605462, 45.9655209],
              [-62.6060386, 45.9655114],
              [-62.6071118, 45.9653189],
              [-62.6072796, 45.9654555],
              [-62.6077682, 45.965483],
              [-62.6081073, 45.9654421],
              [-62.6082512, 45.9653353],
              [-62.609675, 45.9653608],
              [-62.6101261, 45.9655262],
              [-62.6103315, 45.9655248],
              [-62.611056, 45.9652576],
              [-62.6119445, 45.9651864],
              [-62.6135329, 45.9652249],
              [-62.6138671, 45.9651719],
              [-62.614582, 45.9652264],
              [-62.6153358, 45.9651231],
              [-62.6154139, 45.965062],
              [-62.6156281, 45.9650131],
              [-62.61638, 45.9651125],
              [-62.616546, 45.9650144],
              [-62.6170928, 45.9650238],
              [-62.6171712, 45.9648712],
              [-62.6178602, 45.9648851],
              [-62.6181202, 45.9648337],
              [-62.6181758, 45.9648554],
              [-62.6183825, 45.9648341],
              [-62.6187482, 45.9645594],
              [-62.6193273, 45.9645101],
              [-62.6195725, 45.9644225],
              [-62.6200872, 45.964399],
              [-62.6202457, 45.9643285],
              [-62.6211921, 45.9643307],
              [-62.6215895, 45.9642717],
              [-62.6217306, 45.9642046],
              [-62.621905, 45.9642419],
              [-62.6228057, 45.9642466],
              [-62.6235406, 45.9641665],
              [-62.6237587, 45.9640581],
              [-62.6242299, 45.9640889],
              [-62.6250234, 45.9638993],
              [-62.6251699, 45.9637528],
              [-62.6253629, 45.9637668],
              [-62.6255387, 45.9636929],
              [-62.6267577, 45.9635366],
              [-62.6273177, 45.9636935],
              [-62.6275, 45.9635203],
              [-62.6276324, 45.9635006],
              [-62.6277367, 45.9633886],
              [-62.6280424, 45.9633347],
              [-62.6282195, 45.9632408],
              [-62.6289184, 45.9632788],
              [-62.6295601, 45.9634065],
              [-62.629945, 45.963363],
              [-62.6308264, 45.9631363],
              [-62.6311015, 45.9629382],
              [-62.6324043, 45.9628959],
              [-62.6333072, 45.9629522],
              [-62.635354, 45.9626907],
              [-62.6358095, 45.9626136],
              [-62.6361834, 45.9624743],
              [-62.6368539, 45.9624199],
              [-62.6370506, 45.9624658],
              [-62.6375659, 45.9626968],
              [-62.6381754, 45.962883],
              [-62.6383585, 45.9628728],
              [-62.6387707, 45.9627586],
              [-62.6389716, 45.9625621],
              [-62.6392661, 45.9625038],
              [-62.639862, 45.962105],
              [-62.6406464, 45.9620542],
              [-62.6412625, 45.9620497],
              [-62.6414959, 45.962232],
              [-62.6415813, 45.9622347],
              [-62.6417038, 45.9621908],
              [-62.6418567, 45.9619451],
              [-62.6420904, 45.9619445],
              [-62.6423216, 45.9619836],
              [-62.6427043, 45.9623257],
              [-62.6430804, 45.9623296],
              [-62.6433424, 45.9624213],
              [-62.6437409, 45.9623424],
              [-62.6440898, 45.9623255],
              [-62.6442186, 45.9622739],
              [-62.6443831, 45.9617582],
              [-62.6445317, 45.9616634],
              [-62.6447805, 45.9616076],
              [-62.645117, 45.9616063],
              [-62.6453827, 45.9617299],
              [-62.6455249, 45.9617344],
              [-62.6462056, 45.9616126],
              [-62.6466345, 45.9617693],
              [-62.6467954, 45.9617505],
              [-62.647027, 45.9616066],
              [-62.6474455, 45.9614846],
              [-62.6486363, 45.9611442],
              [-62.6493687, 45.9611036],
              [-62.6497576, 45.960909],
              [-62.6502759, 45.9609173],
              [-62.6506479, 45.9610721],
              [-62.6509213, 45.9610767],
              [-62.6512641, 45.9609761],
              [-62.6524885, 45.9604658],
              [-62.653164, 45.9604233],
              [-62.6534363, 45.9603563],
              [-62.6545065, 45.9602944],
              [-62.6546511, 45.9603506],
              [-62.6550877, 45.9603882],
              [-62.6562038, 45.9602323],
              [-62.6568508, 45.9601889],
              [-62.6573768, 45.9600781],
              [-62.6578212, 45.9599051],
              [-62.6582558, 45.959708],
              [-62.6585948, 45.9596669],
              [-62.6588545, 45.9597068],
              [-62.6592814, 45.9596287],
              [-62.6594979, 45.9596315],
              [-62.6595982, 45.959579],
              [-62.6601921, 45.9594743],
              [-62.660405, 45.9594452],
              [-62.6606885, 45.959291],
              [-62.6608915, 45.9592377],
              [-62.6618863, 45.9591057],
              [-62.6622948, 45.9589595],
              [-62.6625867, 45.9589407],
              [-62.6627527, 45.9588425],
              [-62.6633503, 45.9587698],
              [-62.6635602, 45.9589632],
              [-62.6637728, 45.9590255],
              [-62.6641885, 45.9590345],
              [-62.6646533, 45.9591643],
              [-62.6649601, 45.9591818],
              [-62.6661367, 45.9590595],
              [-62.6663407, 45.9590778],
              [-62.666691, 45.9589496],
              [-62.6670288, 45.9589283],
              [-62.667442046644751, 45.958799876477904],
              [-62.6675696, 45.959156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        id: "relation/2256616",
        "@id": "relation/2256616",
        "@relations": null,
        admin_level: "5",
        boundary: "administrative",
        land_area: "administrative",
        name: "Queens County",
        source: "StatisticsCanadaCensusBoundaries",
        type: "boundary",
        wikidata: "Q2006290",
        wikipedia: "en:Queens County, Prince Edward Island",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-62.8564653, 46.4053542],
              [-62.8526905, 46.39589],
              [-62.8442844, 46.3748943],
              [-62.8440374, 46.3741877],
              [-62.8438336, 46.3735819],
              [-62.8360757, 46.3542228],
              [-62.8332485, 46.3471368],
              [-62.8276761, 46.3333894],
              [-62.8235167, 46.3226669],
              [-62.8234391, 46.3224363],
              [-62.8227663, 46.3207025],
              [-62.8214002, 46.3176011],
              [-62.8213035, 46.3174246],
              [-62.8203293, 46.3151907],
              [-62.8197979, 46.3140843],
              [-62.8174248, 46.3085832],
              [-62.8164049, 46.3051728],
              [-62.8143722, 46.2999812],
              [-62.8135287, 46.2978288],
              [-62.8091875, 46.2869687],
              [-62.8069355, 46.2814517],
              [-62.8056786, 46.2782155],
              [-62.8033027, 46.2722908],
              [-62.8017508, 46.2725994],
              [-62.8002845, 46.2728748],
              [-62.7996963, 46.2729889],
              [-62.7994432, 46.2730312],
              [-62.7993276, 46.273058],
              [-62.7992062, 46.2730915],
              [-62.7990407, 46.2731254],
              [-62.7974113, 46.2734464],
              [-62.7970543, 46.2735128],
              [-62.7966615, 46.2735935],
              [-62.7941704, 46.2740913],
              [-62.7931014, 46.270889],
              [-62.7929195, 46.2704636],
              [-62.7846254, 46.2500558],
              [-62.780585, 46.2399441],
              [-62.7791499, 46.2365335],
              [-62.7790491, 46.2362949],
              [-62.7760729, 46.229221],
              [-62.771941, 46.2190126],
              [-62.7716405, 46.2182877],
              [-62.771609, 46.2182197],
              [-62.7707002, 46.2159755],
              [-62.7705411, 46.2155978],
              [-62.7701088, 46.2145317],
              [-62.7698318, 46.2138434],
              [-62.7696914, 46.2134792],
              [-62.7696791, 46.2134814],
              [-62.7676156, 46.2083879],
              [-62.7625071, 46.1959345],
              [-62.7616968, 46.1938606],
              [-62.7603933, 46.1906646],
              [-62.7538569, 46.1746804],
              [-62.7532109, 46.1726151],
              [-62.7527726, 46.1715243],
              [-62.7486423, 46.1629471],
              [-62.747608, 46.1596958],
              [-62.7409517, 46.1424757],
              [-62.738639, 46.1367635],
              [-62.737477, 46.1339003],
              [-62.737264, 46.1333911],
              [-62.7369609, 46.1326428],
              [-62.7368387, 46.1323555],
              [-62.7361257, 46.1306085],
              [-62.7361008, 46.1305387],
              [-62.7360521, 46.1304442],
              [-62.7360105, 46.1303451],
              [-62.7359972, 46.1302915],
              [-62.7354866, 46.1289787],
              [-62.7348951, 46.1275455],
              [-62.7348031, 46.1272988],
              [-62.7346318, 46.126876],
              [-62.7338082, 46.1248858],
              [-62.7329356, 46.1227421],
              [-62.7289695, 46.11294],
              [-62.7288709, 46.1127288],
              [-62.7288261, 46.1126338],
              [-62.7287309, 46.1124046],
              [-62.7274428, 46.1092262],
              [-62.7273884, 46.1091226],
              [-62.7273571, 46.1090393],
              [-62.727334, 46.1089938],
              [-62.7273277, 46.108965],
              [-62.727257, 46.1088744],
              [-62.727241, 46.1088474],
              [-62.7272314, 46.1088159],
              [-62.7271112, 46.1086026],
              [-62.7270744, 46.1085276],
              [-62.7260591, 46.1059301],
              [-62.7258673, 46.1054889],
              [-62.7257268, 46.1051376],
              [-62.7253299, 46.1043266],
              [-62.724372, 46.1019226],
              [-62.7237971, 46.1005133],
              [-62.7234842, 46.0997298],
              [-62.7233117, 46.0993245],
              [-62.7226412, 46.097654],
              [-62.7224114, 46.0970597],
              [-62.7216782, 46.0952718],
              [-62.7214159, 46.0945981],
              [-62.7207525, 46.093081],
              [-62.7199039, 46.0908756],
              [-62.7189589, 46.0885567],
              [-62.7181359, 46.0864261],
              [-62.7181669, 46.0864197],
              [-62.7181029, 46.0863027],
              [-62.7177962, 46.0856092],
              [-62.717726, 46.0854336],
              [-62.717579, 46.0851004],
              [-62.717476, 46.0848527],
              [-62.7174407, 46.0848454],
              [-62.7174705, 46.0848392],
              [-62.7169217, 46.0834479],
              [-62.7160605, 46.0812326],
              [-62.7153325, 46.0795169],
              [-62.7149748, 46.0787018],
              [-62.7144412, 46.077266],
              [-62.7133522, 46.0747475],
              [-62.7087989, 46.0637639],
              [-62.7084956, 46.0634628],
              [-62.7084073, 46.0632896],
              [-62.7073118, 46.0597217],
              [-62.7057177, 46.0557672],
              [-62.7057184, 46.0557595],
              [-62.7054826, 46.0551427],
              [-62.7052262, 46.0545104],
              [-62.7049899, 46.0539],
              [-62.6996102, 46.040256],
              [-62.6989033, 46.0382966],
              [-62.697828, 46.0361273],
              [-62.6972186, 46.0347388],
              [-62.696582, 46.0330638],
              [-62.6965406, 46.0329445],
              [-62.6965376, 46.032913],
              [-62.6964119, 46.0326288],
              [-62.6963142, 46.0324274],
              [-62.6962025, 46.0322013],
              [-62.6961577, 46.0321225],
              [-62.6961282, 46.032081],
              [-62.696178, 46.0318392],
              [-62.6956128, 46.0303723],
              [-62.6951541, 46.0292153],
              [-62.6951773, 46.0291105],
              [-62.695184, 46.0290543],
              [-62.6951944, 46.0289329],
              [-62.6951949, 46.0288407],
              [-62.6951735, 46.0286247],
              [-62.6951355, 46.0284986],
              [-62.6950303, 46.0282442],
              [-62.6950048, 46.0281924],
              [-62.6949408, 46.0280798],
              [-62.6948094, 46.0279062],
              [-62.6947422, 46.0278026],
              [-62.6946879, 46.0276878],
              [-62.6944492, 46.0270753],
              [-62.694301, 46.026667],
              [-62.6941469, 46.0262648],
              [-62.6939811, 46.025891],
              [-62.6939111, 46.0257154],
              [-62.6937644, 46.0253844],
              [-62.6935828, 46.0249521],
              [-62.69323, 46.0239503],
              [-62.693055, 46.0234865],
              [-62.6927708, 46.0226998],
              [-62.6925895, 46.0222113],
              [-62.6925557, 46.0221266],
              [-62.6925429, 46.0221064],
              [-62.6925112, 46.0220006],
              [-62.6922724, 46.0214151],
              [-62.6920586, 46.0208383],
              [-62.691941, 46.0205074],
              [-62.6919252, 46.0204489],
              [-62.6918806, 46.0203498],
              [-62.6918456, 46.0202548],
              [-62.6916704, 46.0198319],
              [-62.6916642, 46.0197982],
              [-62.6915939, 46.019663],
              [-62.6915461, 46.0195549],
              [-62.690938, 46.0180324],
              [-62.6907499, 46.0176226],
              [-62.6906317, 46.0173919],
              [-62.6905296, 46.0171793],
              [-62.6904708, 46.0170735],
              [-62.690322, 46.0167757],
              [-62.6901495, 46.0164541],
              [-62.6900781, 46.0162991],
              [-62.6898149, 46.0156902],
              [-62.6896016, 46.0151674],
              [-62.6894996, 46.0149296],
              [-62.6893341, 46.0145153],
              [-62.6890096, 46.0136728],
              [-62.6886579, 46.012729],
              [-62.688549, 46.0124624],
              [-62.6883206, 46.011841],
              [-62.6882362, 46.0116069],
              [-62.6879572, 46.0108446],
              [-62.6876928, 46.0101547],
              [-62.687508, 46.0096661],
              [-62.6874289, 46.0094567],
              [-62.6873872, 46.0093464],
              [-62.687308, 46.009137],
              [-62.6871677, 46.0087745],
              [-62.6870886, 46.0085651],
              [-62.6870596, 46.0084886],
              [-62.6869388, 46.0081711],
              [-62.6868596, 46.0079617],
              [-62.6866984, 46.0075434],
              [-62.6866654, 46.0074565],
              [-62.6865897, 46.0072575],
              [-62.6865681, 46.0072003],
              [-62.6865076, 46.0070476],
              [-62.6864823, 46.0069598],
              [-62.6864681, 46.0069273],
              [-62.6863315, 46.0065807],
              [-62.6862876, 46.0064663],
              [-62.6861227, 46.0060524],
              [-62.686049, 46.0058539],
              [-62.6858098, 46.0052365],
              [-62.6857882, 46.0051793],
              [-62.6856801, 46.0048934],
              [-62.6855904, 46.0046646],
              [-62.6855688, 46.0046074],
              [-62.6854727, 46.0043629],
              [-62.6854511, 46.0043057],
              [-62.6853977, 46.0041639],
              [-62.685343, 46.0040198],
              [-62.6852533, 46.003791],
              [-62.6852317, 46.0037338],
              [-62.6851991, 46.00365],
              [-62.6851203, 46.0034479],
              [-62.6850339, 46.0032191],
              [-62.6849797, 46.0030781],
              [-62.6848735, 46.0028093],
              [-62.6846878, 46.0023208],
              [-62.6846626, 46.0022154],
              [-62.684666, 46.0021947],
              [-62.6846628, 46.0021812],
              [-62.6846308, 46.0021362],
              [-62.6845575, 46.0019785],
              [-62.6844145, 46.0015913],
              [-62.6844019, 46.0015373],
              [-62.6843446, 46.0014022],
              [-62.683858, 46.0001435],
              [-62.6838098, 45.9999986],
              [-62.6837252, 45.9997761],
              [-62.6831233, 45.998268],
              [-62.6828371, 45.9975407],
              [-62.682551, 45.9967932],
              [-62.682465, 45.9965905],
              [-62.6821629, 45.9958295],
              [-62.6816637, 45.9945438],
              [-62.6816447, 45.9944763],
              [-62.6814156, 45.9939223],
              [-62.6811738, 45.9933189],
              [-62.6809227, 45.9926637],
              [-62.6808828, 45.9925286],
              [-62.6804844, 45.9915725],
              [-62.6804037, 45.9913604],
              [-62.6800232, 45.9904219],
              [-62.6797657, 45.9897689],
              [-62.679465, 45.9889849],
              [-62.6792793, 45.9885148],
              [-62.6788033, 45.9872683],
              [-62.678319, 45.98611],
              [-62.6781123, 45.9855899],
              [-62.6779502, 45.9851711],
              [-62.6777819, 45.9847172],
              [-62.6777564, 45.98466],
              [-62.6777343, 45.9845812],
              [-62.6776929, 45.9844888],
              [-62.677127, 45.9830545],
              [-62.6759543, 45.9800441],
              [-62.6759289, 45.9799631],
              [-62.6757286, 45.9794699],
              [-62.6755691, 45.979048],
              [-62.6754927, 45.9788768],
              [-62.6754127, 45.9786571],
              [-62.6752963, 45.9783846],
              [-62.6752487, 45.9782405],
              [-62.6751946, 45.9781212],
              [-62.6751151, 45.9779253],
              [-62.6750866, 45.9778352],
              [-62.6749434, 45.9775042],
              [-62.6749053, 45.9773984],
              [-62.6748156, 45.977179],
              [-62.674756, 45.9770133],
              [-62.6746764, 45.9768287],
              [-62.6746352, 45.9766981],
              [-62.6744191, 45.9761599],
              [-62.6743683, 45.9760136],
              [-62.6740029, 45.9750814],
              [-62.6737107, 45.9743159],
              [-62.6735994, 45.9740389],
              [-62.6735773, 45.9739601],
              [-62.6733781, 45.9734641],
              [-62.672693, 45.9717593],
              [-62.6721815, 45.970451],
              [-62.6719846, 45.9699332],
              [-62.6719497, 45.9698274],
              [-62.6718416, 45.9695639],
              [-62.6715794, 45.9688673],
              [-62.6713367, 45.9682399],
              [-62.6713304, 45.9682219],
              [-62.6713274, 45.9681841],
              [-62.6710803, 45.9680854],
              [-62.6675696, 45.959156],
              [-62.667442046644751, 45.958799876477904],
              [-62.6674941, 45.9587837],
              [-62.6678344, 45.9587228],
              [-62.6684628, 45.9587026],
              [-62.6686039, 45.9586354],
              [-62.6690895, 45.9590096],
              [-62.6693171, 45.9590167],
              [-62.6695352, 45.9591449],
              [-62.6699823, 45.9592493],
              [-62.670275, 45.9593522],
              [-62.6703937, 45.9595342],
              [-62.6702329, 45.9596696],
              [-62.6701223, 45.959652],
              [-62.6698929, 45.9597475],
              [-62.669807, 45.9598441],
              [-62.6698234, 45.9599907],
              [-62.6699507, 45.960159],
              [-62.6702675, 45.9604347],
              [-62.6704096, 45.9604996],
              [-62.6708014, 45.9606936],
              [-62.6709294, 45.9606833],
              [-62.6710823, 45.9608439],
              [-62.671716, 45.9609726],
              [-62.6726484, 45.961112],
              [-62.6727258, 45.9611521],
              [-62.6728319, 45.96115],
              [-62.6736632, 45.9613065],
              [-62.6748096, 45.9613586],
              [-62.6757134, 45.9613701],
              [-62.6760517, 45.961278],
              [-62.6764758, 45.9610925],
              [-62.6776042, 45.960786],
              [-62.6783482, 45.9606722],
              [-62.6789457, 45.9606908],
              [-62.6797816, 45.9606498],
              [-62.6807007, 45.9606674],
              [-62.6814612, 45.9604809],
              [-62.6815178, 45.9605217],
              [-62.6822552, 45.9604925],
              [-62.6825192, 45.9605159],
              [-62.6837113, 45.9608079],
              [-62.6842958, 45.9610235],
              [-62.6848782, 45.9611051],
              [-62.6860244, 45.9613997],
              [-62.686632, 45.961478],
              [-62.6872814, 45.9614594],
              [-62.6888317, 45.9618149],
              [-62.6899971, 45.9618206],
              [-62.6904951, 45.9619094],
              [-62.6909508, 45.9619318],
              [-62.691647, 45.9619644],
              [-62.69228, 45.9619888],
              [-62.6929101, 45.961942],
              [-62.6937188, 45.9620255],
              [-62.6947367, 45.9619839],
              [-62.6958975, 45.9620054],
              [-62.6969859, 45.9620318],
              [-62.6977746, 45.9619694],
              [-62.6983911, 45.9618281],
              [-62.6990662, 45.9615454],
              [-62.6996511, 45.9612793],
              [-62.700003, 45.96106],
              [-62.7002234, 45.9608051],
              [-62.7007848, 45.9603788],
              [-62.701188, 45.9601263],
              [-62.7016188, 45.9598055],
              [-62.7033622, 45.9593741],
              [-62.7045636, 45.9590374],
              [-62.7053108, 45.9589411],
              [-62.7059925, 45.958799],
              [-62.7071935, 45.9589195],
              [-62.7072293, 45.9590756],
              [-62.7071302, 45.9591998],
              [-62.7069358, 45.9593887],
              [-62.7069257, 45.9596388],
              [-62.7071988, 45.9600091],
              [-62.708022, 45.9606944],
              [-62.7091869, 45.9613902],
              [-62.7095529, 45.9615526],
              [-62.7112127, 45.9619018],
              [-62.7120524, 45.9621462],
              [-62.7127402, 45.9622707],
              [-62.7132176, 45.9624762],
              [-62.7139698, 45.9624834],
              [-62.7157175, 45.9627954],
              [-62.7165476, 45.9628328],
              [-62.7168753, 45.96297],
              [-62.7178031, 45.9630859],
              [-62.7193494, 45.9631571],
              [-62.7195498, 45.9631434],
              [-62.719905, 45.963027],
              [-62.7205248, 45.9629876],
              [-62.7207809, 45.9628789],
              [-62.7214637, 45.9627989],
              [-62.7213642, 45.9629959],
              [-62.7209217, 45.9632255],
              [-62.7211218, 45.9632976],
              [-62.7212588, 45.9633357],
              [-62.7213984, 45.9634042],
              [-62.7213787, 45.963463],
              [-62.7212137, 45.9636049],
              [-62.7212845, 45.9637064],
              [-62.7213804, 45.9638471],
              [-62.7219443, 45.9639602],
              [-62.7221658, 45.9639749],
              [-62.7227027, 45.9638681],
              [-62.7229823, 45.9638647],
              [-62.7231654, 45.9639458],
              [-62.723578, 45.9640414],
              [-62.7238816, 45.9640701],
              [-62.7245022, 45.964056],
              [-62.7252087, 45.9639555],
              [-62.7253064, 45.9639821],
              [-62.7255578, 45.9642939],
              [-62.7257297, 45.9643337],
              [-62.7258512, 45.9642871],
              [-62.7267788, 45.9642813],
              [-62.7271588, 45.9642094],
              [-62.7272678, 45.9641395],
              [-62.7272845, 45.9640773],
              [-62.7274352, 45.9640139],
              [-62.7278974, 45.9637706],
              [-62.7280571, 45.9636605],
              [-62.7283542, 45.9633965],
              [-62.7288837, 45.9632258],
              [-62.7292561, 45.9632888],
              [-62.7293476, 45.9633751],
              [-62.7300441, 45.9633606],
              [-62.7314185, 45.9631719],
              [-62.7317984, 45.9630244],
              [-62.7321918, 45.9628415],
              [-62.7335564, 45.9623543],
              [-62.7337284, 45.9622482],
              [-62.73515, 45.9615798],
              [-62.735536, 45.9613331],
              [-62.735823, 45.9612106],
              [-62.7365047, 45.9611598],
              [-62.7369748, 45.9610269],
              [-62.7375661, 45.9609614],
              [-62.7388921, 45.9607234],
              [-62.7393684, 45.9606743],
              [-62.7399931, 45.9604388],
              [-62.7404867, 45.9603862],
              [-62.7408714, 45.9603422],
              [-62.7413551, 45.960174],
              [-62.7427591, 45.9598747],
              [-62.7433913, 45.9594287],
              [-62.7446802, 45.9591457],
              [-62.7454187, 45.9584564],
              [-62.7457011, 45.9580336],
              [-62.746015, 45.9574628],
              [-62.7462918, 45.9568487],
              [-62.7464337, 45.9565269],
              [-62.7465635, 45.9560895],
              [-62.746618, 45.9554864],
              [-62.7465789, 45.9541004],
              [-62.746214, 45.9529299],
              [-62.7459462, 45.9524576],
              [-62.7457308, 45.9520634],
              [-62.7451151, 45.9517042],
              [-62.7445394, 45.9515315],
              [-62.7440468, 45.951333],
              [-62.7434201, 45.9512202],
              [-62.742201, 45.950758],
              [-62.7422321, 45.9506288],
              [-62.7423442, 45.9504471],
              [-62.7427103, 45.950152],
              [-62.7435934, 45.9497932],
              [-62.7446755, 45.9495318],
              [-62.7449179, 45.949392],
              [-62.7451566, 45.9493118],
              [-62.7455647, 45.9492566],
              [-62.7459555, 45.9492963],
              [-62.7491487, 45.9492738],
              [-62.7493676, 45.9493281],
              [-62.7500001, 45.9493741],
              [-62.7502949, 45.949499],
              [-62.7511373, 45.9495929],
              [-62.7512622, 45.948419],
              [-62.7512763, 45.948387],
              [-62.7513385, 45.9484002],
              [-62.7513835, 45.9484462],
              [-62.7512871, 45.9500501],
              [-62.7512264, 45.950105],
              [-62.7511052, 45.9501526],
              [-62.7511066, 45.950096],
              [-62.7508233, 45.9500695],
              [-62.7507443, 45.9499457],
              [-62.7504936, 45.949937],
              [-62.7500913, 45.949963],
              [-62.7496994, 45.9501251],
              [-62.7493988, 45.9503321],
              [-62.748914, 45.9503187],
              [-62.7484276, 45.950422],
              [-62.7481173, 45.9505063],
              [-62.7476782, 45.9507473],
              [-62.7474067, 45.9511063],
              [-62.7473677, 45.9519047],
              [-62.7471655, 45.9525088],
              [-62.7470948, 45.9524831],
              [-62.7469264, 45.9521706],
              [-62.7470393, 45.9516382],
              [-62.7471689, 45.9514589],
              [-62.7470924, 45.951158],
              [-62.7467943, 45.9512239],
              [-62.7464613, 45.9514004],
              [-62.7465282, 45.9516529],
              [-62.7466529, 45.9518812],
              [-62.7466715, 45.9521018],
              [-62.7467578, 45.9523886],
              [-62.7469849, 45.9528127],
              [-62.7470397, 45.95288],
              [-62.7471602, 45.9529235],
              [-62.7473286, 45.9528923],
              [-62.7474594, 45.952999],
              [-62.7476253, 45.9532105],
              [-62.7481658, 45.9536016],
              [-62.7486708, 45.952995],
              [-62.7486567, 45.9528442],
              [-62.7481624, 45.9524384],
              [-62.7482691, 45.9523688],
              [-62.7488879, 45.9528346],
              [-62.7489007, 45.9529622],
              [-62.748293, 45.953694],
              [-62.7485663, 45.953925],
              [-62.7491186, 45.9533447],
              [-62.7494055, 45.9528607],
              [-62.749515, 45.9528936],
              [-62.749232, 45.9533571],
              [-62.7492314, 45.9534117],
              [-62.7492762, 45.9534547],
              [-62.7493488, 45.9534755],
              [-62.7494256, 45.9534547],
              [-62.7497159, 45.953146],
              [-62.7498268, 45.9531995],
              [-62.7491781, 45.9538701],
              [-62.7491824, 45.9539235],
              [-62.7490027, 45.9541068],
              [-62.7491438, 45.9541789],
              [-62.7493187, 45.9540008],
              [-62.7495768, 45.9539121],
              [-62.7497164, 45.9539863],
              [-62.7497538, 45.9540937],
              [-62.7497267, 45.9549732],
              [-62.7500857, 45.9549769],
              [-62.7503686, 45.9549171],
              [-62.7505889, 45.9547526],
              [-62.750546, 45.9545818],
              [-62.7504679, 45.9543133],
              [-62.7506496, 45.9539403],
              [-62.7507766, 45.9538728],
              [-62.7512555, 45.9539199],
              [-62.7517331, 45.9538436],
              [-62.7519919, 45.9537063],
              [-62.7522455, 45.95373],
              [-62.7524432, 45.953818],
              [-62.7523455, 45.9539976],
              [-62.7524577, 45.9542485],
              [-62.7526308, 45.9544164],
              [-62.7526064, 45.9545762],
              [-62.7521349, 45.9547555],
              [-62.7516177, 45.9548822],
              [-62.7508085, 45.9547216],
              [-62.7506024, 45.9549475],
              [-62.7500001, 45.9552136],
              [-62.7497281, 45.9552101],
              [-62.7496132, 45.9554879],
              [-62.7493988, 45.9559919],
              [-62.7492004, 45.9563774],
              [-62.7487637, 45.9569928],
              [-62.7484369, 45.9574566],
              [-62.7481805, 45.9576837],
              [-62.7479382, 45.9577779],
              [-62.747671, 45.95787],
              [-62.7475062, 45.9579167],
              [-62.7472523, 45.9579289],
              [-62.7472431, 45.958128],
              [-62.7476489, 45.9581622],
              [-62.7494561, 45.9578869],
              [-62.7498828, 45.957717],
              [-62.7498779, 45.957522],
              [-62.7497616, 45.9572839],
              [-62.7500001, 45.9568967],
              [-62.7510071, 45.9571466],
              [-62.7512805, 45.9573337],
              [-62.7516207, 45.9572725],
              [-62.7518903, 45.9573363],
              [-62.7523802, 45.957526],
              [-62.7527315, 45.9577434],
              [-62.753042, 45.9577926],
              [-62.7533549, 45.9577106],
              [-62.7537941, 45.9577994],
              [-62.7541281, 45.9579288],
              [-62.7542555, 45.9580797],
              [-62.7546204, 45.9580789],
              [-62.7560776, 45.9582978],
              [-62.756445, 45.9582572],
              [-62.7569853, 45.957875],
              [-62.7571538, 45.9578838],
              [-62.757068, 45.9582615],
              [-62.7574246, 45.9582709],
              [-62.7581926, 45.9583591],
              [-62.7587429, 45.9585748],
              [-62.7595221, 45.9587294],
              [-62.7597187, 45.9589403],
              [-62.7602506, 45.9591287],
              [-62.7607982, 45.9591493],
              [-62.7610431, 45.959215],
              [-62.7616072, 45.9594666],
              [-62.7619547, 45.9596615],
              [-62.7620111, 45.9599929],
              [-62.7622336, 45.9604154],
              [-62.7627107, 45.9607808],
              [-62.7632776, 45.9614586],
              [-62.7634587, 45.9617936],
              [-62.7634494, 45.9622713],
              [-62.7633842, 45.9625136],
              [-62.7635023, 45.9628519],
              [-62.7636639, 45.9632496],
              [-62.7640459, 45.9634148],
              [-62.7643167, 45.9635256],
              [-62.7646472, 45.9635137],
              [-62.7645039, 45.9633866],
              [-62.7641445, 45.9632698],
              [-62.7638979, 45.9628696],
              [-62.7638086, 45.962608],
              [-62.7638802, 45.9624211],
              [-62.7640309, 45.9619207],
              [-62.7640395, 45.9616904],
              [-62.7639763, 45.9615135],
              [-62.7638695, 45.9612506],
              [-62.7637195, 45.9611048],
              [-62.763791, 45.9606439],
              [-62.7634969, 45.9604098],
              [-62.7630626, 45.9601143],
              [-62.7627365, 45.9599485],
              [-62.7628378, 45.959676],
              [-62.7629776, 45.9595371],
              [-62.7632228, 45.9593354],
              [-62.7630766, 45.9591785],
              [-62.7630848, 45.959042],
              [-62.7635786, 45.9588206],
              [-62.7634402, 45.9586695],
              [-62.7630626, 45.9584963],
              [-62.7627667, 45.9582378],
              [-62.7625174, 45.9579978],
              [-62.762045, 45.9579437],
              [-62.7613109, 45.957724],
              [-62.7610792, 45.9577291],
              [-62.7609294, 45.9578033],
              [-62.7607567, 45.9577051],
              [-62.7610732, 45.9575318],
              [-62.761604, 45.957599],
              [-62.7625413, 45.9578671],
              [-62.7632206, 45.9581814],
              [-62.7639239, 45.9586379],
              [-62.7644616, 45.9588643],
              [-62.7647573, 45.9589608],
              [-62.7648823, 45.9589685],
              [-62.7650258, 45.9590444],
              [-62.7659621, 45.9593746],
              [-62.7669563, 45.9596847],
              [-62.7677025, 45.9597817],
              [-62.7683882, 45.9598005],
              [-62.7692863, 45.960073],
              [-62.7699302, 45.9601601],
              [-62.7703242, 45.9601426],
              [-62.771239, 45.9600327],
              [-62.772229, 45.9596054],
              [-62.772948, 45.9595706],
              [-62.7736564, 45.9595103],
              [-62.7744078, 45.9594142],
              [-62.7751111, 45.9593055],
              [-62.7756507, 45.9591812],
              [-62.7765895, 45.9591179],
              [-62.7772414, 45.9591772],
              [-62.7787456, 45.9591904],
              [-62.779003, 45.9592697],
              [-62.7795946, 45.9596612],
              [-62.7805547, 45.9599921],
              [-62.7813444, 45.9605922],
              [-62.7817639, 45.9607241],
              [-62.7830766, 45.960978],
              [-62.7839849, 45.9613193],
              [-62.7845849, 45.9613889],
              [-62.7862152, 45.9612985],
              [-62.7870453, 45.9613352],
              [-62.78863, 45.9613388],
              [-62.7903376, 45.9617555],
              [-62.7906989, 45.9619055],
              [-62.7918199, 45.9621258],
              [-62.7944363, 45.9621322],
              [-62.7952071, 45.9622068],
              [-62.7957936, 45.9623117],
              [-62.7959805, 45.9624246],
              [-62.7968552, 45.9624785],
              [-62.7974728, 45.9627275],
              [-62.7981522, 45.9628988],
              [-62.7998112, 45.9629918],
              [-62.8003109, 45.9629311],
              [-62.8013946, 45.9629236],
              [-62.8019786, 45.9629767],
              [-62.8027655, 45.9630677],
              [-62.8036154, 45.9630611],
              [-62.8042783, 45.9629416],
              [-62.8050454, 45.9629842],
              [-62.8059133, 45.9635229],
              [-62.8063168, 45.9636382],
              [-62.8067671, 45.9636396],
              [-62.8079807, 45.9636518],
              [-62.8085165, 45.9637472],
              [-62.8093157, 45.9637311],
              [-62.8133636, 45.963823],
              [-62.8150052, 45.9637364],
              [-62.8154949, 45.9636515],
              [-62.8163571, 45.9636292],
              [-62.8168702, 45.9634416],
              [-62.8172822, 45.963418],
              [-62.8183205, 45.9636872],
              [-62.8192448, 45.9637702],
              [-62.8207391, 45.9636671],
              [-62.8211623, 45.9637393],
              [-62.821442, 45.963827],
              [-62.8219729, 45.9639103],
              [-62.8230417, 45.9638662],
              [-62.8239636, 45.9639888],
              [-62.824643, 45.9641599],
              [-62.8255209, 45.9638796],
              [-62.8261382, 45.9638541],
              [-62.8264786, 45.9639754],
              [-62.8282838, 45.964104],
              [-62.8287617, 45.964309],
              [-62.8292653, 45.9643716],
              [-62.8294063, 45.9643041],
              [-62.829702, 45.9643168],
              [-62.8300073, 45.9648307],
              [-62.8309738, 45.9656304],
              [-62.8313693, 45.9660675],
              [-62.8315654, 45.9664072],
              [-62.8318366, 45.9665424],
              [-62.8326333, 45.9671344],
              [-62.8339946, 45.9679138],
              [-62.8341806, 45.968138],
              [-62.8344295, 45.9682646],
              [-62.8351124, 45.9688531],
              [-62.83579, 45.9692468],
              [-62.8360664, 45.9694855],
              [-62.8362722, 45.9696664],
              [-62.8366696, 45.9697894],
              [-62.8370569, 45.9697968],
              [-62.8381171, 45.9697086],
              [-62.8392246, 45.9697808],
              [-62.8404923, 45.9706966],
              [-62.8419115, 45.9718434],
              [-62.8429833, 45.9725109],
              [-62.8440447, 45.9729714],
              [-62.8446418, 45.9732831],
              [-62.8450962, 45.9734077],
              [-62.8456308, 45.9734312],
              [-62.8459783, 45.973342],
              [-62.8460725, 45.9733885],
              [-62.8459905, 45.9738036],
              [-62.8458858, 45.9740271],
              [-62.8453131, 45.9746386],
              [-62.8448493, 45.9753328],
              [-62.8446758, 45.9757532],
              [-62.8439671, 45.9764441],
              [-62.8435796, 45.9769139],
              [-62.843509, 45.9779933],
              [-62.84314, 45.9784398],
              [-62.8427122, 45.9786301],
              [-62.842215, 45.9787428],
              [-62.8415563, 45.9791688],
              [-62.8412595, 45.9792675],
              [-62.8411508, 45.9793677],
              [-62.8409306, 45.9794249],
              [-62.8403001, 45.9797603],
              [-62.8397078, 45.9799378],
              [-62.8389834, 45.9803182],
              [-62.8386168, 45.9808164],
              [-62.8385758, 45.9813082],
              [-62.8383532, 45.9819737],
              [-62.8377754, 45.9826645],
              [-62.8372663, 45.9830671],
              [-62.8368333, 45.9831538],
              [-62.836082, 45.9831278],
              [-62.8357012, 45.9832955],
              [-62.8354135, 45.9836211],
              [-62.8350067, 45.9838398],
              [-62.8339912, 45.9840886],
              [-62.8335637, 45.9840736],
              [-62.833289, 45.9841851],
              [-62.83305, 45.9842089],
              [-62.8324917, 45.9841394],
              [-62.8315866, 45.9842762],
              [-62.8306854, 45.9841822],
              [-62.8301152, 45.9843682],
              [-62.8297616, 45.9845566],
              [-62.8297111, 45.9847301],
              [-62.8295359, 45.9849874],
              [-62.8296053, 45.985065],
              [-62.8299034, 45.9849465],
              [-62.8303913, 45.984424],
              [-62.8306461, 45.9843599],
              [-62.8309605, 45.9843493],
              [-62.8316316, 45.9844764],
              [-62.8329797, 45.9844418],
              [-62.8331604, 45.9843436],
              [-62.8334183, 45.9843056],
              [-62.8336567, 45.9843907],
              [-62.8341239, 45.9844019],
              [-62.8349526, 45.9841699],
              [-62.8353819, 45.9840585],
              [-62.835946, 45.9837759],
              [-62.8366273, 45.9834499],
              [-62.8376169, 45.9832125],
              [-62.8380644, 45.9829791],
              [-62.8384463, 45.9827],
              [-62.8388266, 45.9822579],
              [-62.8389004, 45.9820732],
              [-62.8389646, 45.9813872],
              [-62.8393456, 45.9807423],
              [-62.8429136, 45.9790733],
              [-62.8437045, 45.9785358],
              [-62.8439716, 45.9784562],
              [-62.8441876, 45.9781842],
              [-62.8442252, 45.9778433],
              [-62.8440649, 45.9769997],
              [-62.8441978, 45.9766855],
              [-62.8447787, 45.9758436],
              [-62.8451262, 45.9751858],
              [-62.8455087, 45.974704],
              [-62.8463346, 45.9738653],
              [-62.8464088, 45.9732949],
              [-62.8467171, 45.9728149],
              [-62.8469277, 45.9729165],
              [-62.847063, 45.9731311],
              [-62.8472651, 45.9732802],
              [-62.8477505, 45.9734574],
              [-62.8480827, 45.9737175],
              [-62.8488801, 45.9739237],
              [-62.8491474, 45.9739355],
              [-62.8497077, 45.9738166],
              [-62.8504184, 45.9734716],
              [-62.8517895, 45.9735235],
              [-62.8522112, 45.9739095],
              [-62.8534488, 45.975524],
              [-62.8540267, 45.9760617],
              [-62.85432, 45.9764997],
              [-62.8545469, 45.9767091],
              [-62.8553725, 45.9777788],
              [-62.8555994, 45.9784653],
              [-62.8559353, 45.979143],
              [-62.8566777, 45.9800791],
              [-62.8572607, 45.9806289],
              [-62.8583068, 45.98221],
              [-62.8593211, 45.9834641],
              [-62.8594649, 45.9835399],
              [-62.8596044, 45.9838779],
              [-62.8599107, 45.9840975],
              [-62.8599833, 45.9844098],
              [-62.8602931, 45.9845699],
              [-62.860467, 45.9848095],
              [-62.8605171, 45.9850217],
              [-62.8604691, 45.9851555],
              [-62.8602355, 45.9852481],
              [-62.8600812, 45.9854425],
              [-62.8599752, 45.9855944],
              [-62.8598672, 45.985969],
              [-62.8592924, 45.9862347],
              [-62.8590577, 45.9864386],
              [-62.8586968, 45.9866547],
              [-62.8583777, 45.9867449],
              [-62.8583042, 45.987021],
              [-62.8584589, 45.9870096],
              [-62.8587977, 45.9868763],
              [-62.8595764, 45.9864457],
              [-62.8602027, 45.9859867],
              [-62.8602696, 45.9855354],
              [-62.8603671, 45.9854309],
              [-62.8608385, 45.985369],
              [-62.8610134, 45.9854974],
              [-62.8610485, 45.9856733],
              [-62.8611674, 45.9856887],
              [-62.8617301, 45.9860072],
              [-62.8622865, 45.9862421],
              [-62.8627994, 45.9864399],
              [-62.8634284, 45.9865098],
              [-62.8635214, 45.9865761],
              [-62.8640341, 45.9866825],
              [-62.8654976, 45.9872975],
              [-62.8658236, 45.9874739],
              [-62.866282, 45.9876303],
              [-62.8665845, 45.987818],
              [-62.8673042, 45.987994],
              [-62.8676252, 45.9881583],
              [-62.8682677, 45.9881928],
              [-62.8688108, 45.9880774],
              [-62.8699726, 45.9879161],
              [-62.8708244, 45.9879806],
              [-62.871493, 45.9880556],
              [-62.8726429, 45.9884785],
              [-62.8731166, 45.9887626],
              [-62.8733853, 45.9892316],
              [-62.8737849, 45.9899946],
              [-62.8746169, 45.9913507],
              [-62.8757268, 45.9924484],
              [-62.8769815, 45.9935105],
              [-62.8783584, 45.9944369],
              [-62.8807423, 45.9958933],
              [-62.8831313, 45.9969761],
              [-62.8839219, 45.9972096],
              [-62.8848755, 45.996907],
              [-62.8850484, 45.9967808],
              [-62.8858305, 45.996676],
              [-62.8867905, 45.9968428],
              [-62.8878028, 45.9970906],
              [-62.8891254, 45.9972041],
              [-62.8901988, 45.9975769],
              [-62.8911632, 45.9979585],
              [-62.8917598, 45.9982898],
              [-62.8923383, 45.9987358],
              [-62.8925124, 45.9989754],
              [-62.8926463, 45.9994127],
              [-62.8926593, 45.9999657],
              [-62.892772, 46.0],
              [-62.8929646, 46.0002051],
              [-62.8929928, 46.0005002],
              [-62.8929311, 46.0005779],
              [-62.892585, 46.000739],
              [-62.8922409, 46.0007689],
              [-62.8916854, 46.0005144],
              [-62.8911639, 46.0004557],
              [-62.8908586, 46.000602],
              [-62.8904202, 46.0009711],
              [-62.8900797, 46.0013272],
              [-62.8879793, 46.0061618],
              [-62.8878191, 46.0064554],
              [-62.88735, 46.0076349],
              [-62.8870502, 46.0079762],
              [-62.8867013, 46.0080854],
              [-62.8865239, 46.0079968],
              [-62.8865286, 46.0079174],
              [-62.8868566, 46.0074855],
              [-62.8869533, 46.0071065],
              [-62.8866033, 46.0064642],
              [-62.886598, 46.005975],
              [-62.8868652, 46.0055094],
              [-62.8879228, 46.0044145],
              [-62.8883233, 46.0038176],
              [-62.8883209, 46.0034716],
              [-62.8882417, 46.0030757],
              [-62.8879378, 46.0029079],
              [-62.8876121, 46.0029144],
              [-62.8871878, 46.0030454],
              [-62.886272, 46.00319],
              [-62.8855612, 46.0031497],
              [-62.8850799, 46.003279],
              [-62.8843056, 46.0035391],
              [-62.8828679, 46.0043049],
              [-62.8820525, 46.0044882],
              [-62.881301, 46.0049398],
              [-62.8797386, 46.0054037],
              [-62.879476, 46.0054041],
              [-62.878465, 46.0052278],
              [-62.8767971, 46.0052553],
              [-62.8761635, 46.0053563],
              [-62.8750001, 46.0053324],
              [-62.8747444, 46.0053272],
              [-62.8742742, 46.0054608],
              [-62.8739571, 46.0054198],
              [-62.87364, 46.0053788],
              [-62.8715492, 46.0047458],
              [-62.8709925, 46.004511],
              [-62.8708925, 46.0041781],
              [-62.87053, 46.0038455],
              [-62.8698099, 46.0035781],
              [-62.8690268, 46.0034083],
              [-62.8674629, 46.0029376],
              [-62.8671285, 46.0029],
              [-62.8659007, 46.0026299],
              [-62.8656389, 46.0024274],
              [-62.8652961, 46.0020517],
              [-62.8645617, 46.0015452],
              [-62.8638393, 46.0013175],
              [-62.8627556, 46.0012145],
              [-62.8621874, 46.0012696],
              [-62.8613152, 46.0015425],
              [-62.8611793, 46.0016221],
              [-62.8602608, 46.0017147],
              [-62.8593306, 46.0020973],
              [-62.8589013, 46.002216],
              [-62.8590067, 46.0022668],
              [-62.8596717, 46.00231],
              [-62.8599736, 46.0022233],
              [-62.8607058, 46.0019066],
              [-62.8609683, 46.0019062],
              [-62.8613675, 46.0021007],
              [-62.861516, 46.0020971],
              [-62.8619836, 46.0019118],
              [-62.8622496, 46.001852],
              [-62.8624891, 46.0020458],
              [-62.8625803, 46.0023348],
              [-62.8621291, 46.0026279],
              [-62.8616402, 46.0026932],
              [-62.8609719, 46.0030495],
              [-62.8604247, 46.0031207],
              [-62.8599998, 46.0032167],
              [-62.8596413, 46.0033625],
              [-62.859421, 46.0034632],
              [-62.8586072, 46.0034123],
              [-62.8581792, 46.0036027],
              [-62.8580096, 46.0038224],
              [-62.8577191, 46.0040063],
              [-62.8574908, 46.0041498],
              [-62.8572769, 46.0044279],
              [-62.8569065, 46.0046553],
              [-62.856479, 46.0050286],
              [-62.856019, 46.0052776],
              [-62.8555213, 46.0055486],
              [-62.8555606, 46.00565],
              [-62.8553063, 46.0058579],
              [-62.8553364, 46.0058819],
              [-62.8553884, 46.0059069],
              [-62.8556481, 46.0056962],
              [-62.8557001, 46.005694],
              [-62.8558685, 46.0056526],
              [-62.8559753, 46.0056263],
              [-62.8561903, 46.0056016],
              [-62.8565007, 46.0054522],
              [-62.8565894, 46.0053143],
              [-62.8573114, 46.0049366],
              [-62.8576259, 46.0047023],
              [-62.8580972, 46.0043995],
              [-62.85854, 46.0041555],
              [-62.8593405, 46.00401],
              [-62.8603498, 46.0038986],
              [-62.8611807, 46.0039188],
              [-62.8615773, 46.0036717],
              [-62.8616927, 46.0034994],
              [-62.8622656, 46.0032876],
              [-62.8627769, 46.0032485],
              [-62.8636047, 46.002953],
              [-62.864489, 46.0030502],
              [-62.8655436, 46.0033552],
              [-62.866408, 46.0034041],
              [-62.8673961, 46.0038661],
              [-62.8688956, 46.0047486],
              [-62.8697566, 46.0053342],
              [-62.8698546, 46.0054125],
              [-62.8700014, 46.0056315],
              [-62.8700437, 46.0061656],
              [-62.8704146, 46.0068364],
              [-62.8704126, 46.0074448],
              [-62.8706073, 46.0075299],
              [-62.870856, 46.0074735],
              [-62.8712169, 46.007643],
              [-62.8713878, 46.0076479],
              [-62.871925, 46.0080174],
              [-62.8725434, 46.0081744],
              [-62.8728803, 46.0081722],
              [-62.8743239, 46.0089416],
              [-62.8747997, 46.0090031],
              [-62.8750001, 46.0089255],
              [-62.8750001, 46.0086311],
              [-62.8746929, 46.008495],
              [-62.874619, 46.0082026],
              [-62.8747497, 46.0080195],
              [-62.8747382, 46.0074465],
              [-62.8749455, 46.0072219],
              [-62.8752339, 46.0071705],
              [-62.875872, 46.0072844],
              [-62.8765189, 46.0074422],
              [-62.8771405, 46.0074483],
              [-62.8773326, 46.0074816],
              [-62.8776832, 46.0075355],
              [-62.8784731, 46.0074946],
              [-62.8792008, 46.0073486],
              [-62.8803651, 46.0074418],
              [-62.8814969, 46.0074108],
              [-62.8819676, 46.0074601],
              [-62.8826427, 46.0075273],
              [-62.8839888, 46.0075382],
              [-62.8846445, 46.00774],
              [-62.8850468, 46.0080776],
              [-62.8852347, 46.0083733],
              [-62.8853302, 46.0087857],
              [-62.885193, 46.0096565],
              [-62.8853019, 46.0100335],
              [-62.8852869, 46.0107687],
              [-62.885175, 46.0114057],
              [-62.8848381, 46.0121794],
              [-62.8843053, 46.0128878],
              [-62.8842961, 46.013138],
              [-62.8844403, 46.0133052],
              [-62.8849449, 46.0134589],
              [-62.8858007, 46.0134637],
              [-62.8863431, 46.0138451],
              [-62.8869035, 46.0140203],
              [-62.8881884, 46.0145861],
              [-62.8891177, 46.0147003],
              [-62.889553, 46.0148678],
              [-62.8901243, 46.0153415],
              [-62.8904071, 46.0158666],
              [-62.8918277, 46.0177128],
              [-62.8923118, 46.0180209],
              [-62.8930902, 46.0181784],
              [-62.8952554, 46.0193062],
              [-62.8960503, 46.0195716],
              [-62.8979679, 46.0199645],
              [-62.899138, 46.0205468],
              [-62.8999678, 46.0205108],
              [-62.9009915, 46.0206713],
              [-62.9017371, 46.0209988],
              [-62.9022143, 46.021426],
              [-62.9023196, 46.021771],
              [-62.9022524, 46.0220395],
              [-62.9017916, 46.0224002],
              [-62.9018557, 46.0226685],
              [-62.9020477, 46.0229961],
              [-62.9020425, 46.0232783],
              [-62.9020943, 46.0235621],
              [-62.902381, 46.0237333],
              [-62.9026722, 46.0237337],
              [-62.9028676, 46.0240017],
              [-62.9031414, 46.0240056],
              [-62.9031974, 46.0237328],
              [-62.9033815, 46.0236108],
              [-62.9038101, 46.0236031],
              [-62.9042864, 46.0237559],
              [-62.9049523, 46.024276],
              [-62.9051127, 46.0244594],
              [-62.9052495, 46.0242686],
              [-62.9051146, 46.0239426],
              [-62.9044396, 46.0232871],
              [-62.9043927, 46.0230153],
              [-62.9044926, 46.022871],
              [-62.9045632, 46.022543],
              [-62.9043513, 46.0221671],
              [-62.9043726, 46.0219013],
              [-62.9047159, 46.0215969],
              [-62.9047639, 46.0211688],
              [-62.9051577, 46.0207824],
              [-62.9051324, 46.0206305],
              [-62.9049149, 46.0204454],
              [-62.9044352, 46.0203521],
              [-62.9039299, 46.0200156],
              [-62.9032584, 46.0199805],
              [-62.9026709, 46.0198763],
              [-62.9021747, 46.0196752],
              [-62.9011416, 46.0186078],
              [-62.9001645, 46.0179475],
              [-62.8982694, 46.0168833],
              [-62.8975502, 46.016302],
              [-62.8972291, 46.0158435],
              [-62.8971953, 46.0157391],
              [-62.8971122, 46.0156969],
              [-62.8965064, 46.014936],
              [-62.896052, 46.0146088],
              [-62.8956467, 46.0145137],
              [-62.8952845, 46.0143641],
              [-62.89533, 46.01427],
              [-62.895629, 46.01404],
              [-62.8960055, 46.0140428],
              [-62.8976671, 46.0146113],
              [-62.8979619, 46.0146436],
              [-62.8971051, 46.0139789],
              [-62.8956614, 46.013007],
              [-62.8949824, 46.012908],
              [-62.8948569, 46.012809],
              [-62.8948481, 46.0123793],
              [-62.894623, 46.0121303],
              [-62.8939413, 46.0115938],
              [-62.893936, 46.0114902],
              [-62.8940931, 46.0114391],
              [-62.8944077, 46.0114282],
              [-62.8953426, 46.012123],
              [-62.8976699, 46.0139831],
              [-62.8987224, 46.0150074],
              [-62.8990444, 46.0153546],
              [-62.9006182, 46.0166403],
              [-62.9016118, 46.0174083],
              [-62.901743, 46.0174081],
              [-62.9020674, 46.0177156],
              [-62.9036772, 46.0189745],
              [-62.9040712, 46.0193595],
              [-62.904712, 46.0198192],
              [-62.905794, 46.0203472],
              [-62.9062929, 46.0206],
              [-62.9065662, 46.0208066],
              [-62.9067198, 46.020815],
              [-62.907338, 46.0211746],
              [-62.9077799, 46.0214258],
              [-62.9088484, 46.0222834],
              [-62.9095965, 46.0232512],
              [-62.9108409, 46.0251],
              [-62.9117133, 46.0264809],
              [-62.9122018, 46.0269123],
              [-62.9123488, 46.0271313],
              [-62.9125836, 46.0273129],
              [-62.9131172, 46.027853],
              [-62.9141339, 46.0287211],
              [-62.9166124, 46.0301714],
              [-62.9170548, 46.0305141],
              [-62.918225, 46.0312989],
              [-62.919753, 46.0323126],
              [-62.9216991, 46.0340858],
              [-62.9217018, 46.0341376],
              [-62.9230081, 46.035137],
              [-62.9230123, 46.0352604],
              [-62.9240363, 46.0360092],
              [-62.9246572, 46.036329],
              [-62.9252253, 46.0364764],
              [-62.9256509, 46.0366197],
              [-62.9267537, 46.0366071],
              [-62.9273477, 46.0367035],
              [-62.9277105, 46.0369444],
              [-62.9284493, 46.0372993],
              [-62.9290063, 46.0374423],
              [-62.9295602, 46.0374421],
              [-62.9298244, 46.0375132],
              [-62.9304731, 46.0379451],
              [-62.930948, 46.0383204],
              [-62.9314018, 46.0389616],
              [-62.9322673, 46.0399842],
              [-62.9329927, 46.0406688],
              [-62.9332713, 46.0408875],
              [-62.933643, 46.0411724],
              [-62.9343206, 46.0416966],
              [-62.9349008, 46.0420311],
              [-62.9352483, 46.0421443],
              [-62.9356536, 46.042442],
              [-62.9369246, 46.0428795],
              [-62.9373984, 46.0429804],
              [-62.9376974, 46.0430445],
              [-62.9380903, 46.0430635],
              [-62.9383739, 46.0429999],
              [-62.9390468, 46.0427206],
              [-62.9396251, 46.0425977],
              [-62.9400801, 46.0426303],
              [-62.9402704, 46.0427948],
              [-62.9402223, 46.0431315],
              [-62.9395744, 46.0437655],
              [-62.9392971, 46.0441156],
              [-62.9392598, 46.0446594],
              [-62.9387972, 46.0451514],
              [-62.938719, 46.0454157],
              [-62.9387091, 46.0456858],
              [-62.9381767, 46.0461004],
              [-62.9371935, 46.0466056],
              [-62.9365259, 46.0469885],
              [-62.9362385, 46.0473144],
              [-62.9351798, 46.0481357],
              [-62.9343812, 46.0491989],
              [-62.9338845, 46.0495866],
              [-62.9330924, 46.0498506],
              [-62.9328119, 46.0500574],
              [-62.932652, 46.0506455],
              [-62.9324212, 46.0508816],
              [-62.9316821, 46.0514096],
              [-62.9312903, 46.0519593],
              [-62.9309989, 46.0528419],
              [-62.9307384, 46.0533914],
              [-62.9302776, 46.0537522],
              [-62.930009, 46.053852],
              [-62.9287763, 46.054028],
              [-62.9263899, 46.0540043],
              [-62.9260357, 46.0541016],
              [-62.9250331, 46.0541528],
              [-62.9250315, 46.0540812],
              [-62.9252639, 46.0539167],
              [-62.9252674, 46.0538572],
              [-62.9252177, 46.0538279],
              [-62.9247306, 46.0538539],
              [-62.9240131, 46.0539131],
              [-62.9231534, 46.0537655],
              [-62.9228144, 46.0536048],
              [-62.9226976, 46.0535497],
              [-62.9222978, 46.0530612],
              [-62.9220975, 46.0525782],
              [-62.9221503, 46.0524564],
              [-62.9223826, 46.0525863],
              [-62.9225263, 46.0528648],
              [-62.9228179, 46.0532509],
              [-62.9229409, 46.0530038],
              [-62.9228664, 46.051737],
              [-62.9226799, 46.0516044],
              [-62.9220157, 46.051339],
              [-62.9213103, 46.0507026],
              [-62.920816, 46.0504619],
              [-62.9199029, 46.050253],
              [-62.9194845, 46.0500821],
              [-62.9188131, 46.04955],
              [-62.9184914, 46.0493857],
              [-62.9184112, 46.0494869],
              [-62.9184343, 46.0496784],
              [-62.9187951, 46.0503449],
              [-62.9191285, 46.051285],
              [-62.9193697, 46.0514589],
              [-62.9197442, 46.0515013],
              [-62.9197754, 46.0515539],
              [-62.9196016, 46.0530603],
              [-62.9193274, 46.0536451],
              [-62.9187878, 46.0541787],
              [-62.9186468, 46.0542463],
              [-62.918229, 46.0542583],
              [-62.9173725, 46.0539595],
              [-62.9162212, 46.05372],
              [-62.9148813, 46.0530933],
              [-62.9142159, 46.0528477],
              [-62.913804, 46.0527605],
              [-62.9130587, 46.0528112],
              [-62.9126156, 46.0528619],
              [-62.9119647, 46.0531496],
              [-62.9116517, 46.0532197],
              [-62.9112077, 46.0534527],
              [-62.9112033, 46.053747],
              [-62.9112023, 46.0538115],
              [-62.9111953, 46.0538994],
              [-62.9105923, 46.0538649],
              [-62.9104364, 46.0538266],
              [-62.9104308, 46.0538969],
              [-62.9100041, 46.0538931],
              [-62.9099965, 46.0538232],
              [-62.9099545, 46.0534627],
              [-62.9097217, 46.0532091],
              [-62.909398, 46.0529851],
              [-62.90836, 46.0528601],
              [-62.9076092, 46.0530276],
              [-62.9069688, 46.0524267],
              [-62.9064321, 46.0517432],
              [-62.9057681, 46.0511834],
              [-62.9053953, 46.0509182],
              [-62.9047624, 46.0507053],
              [-62.9043872, 46.0504798],
              [-62.9044773, 46.0500171],
              [-62.9044298, 46.0495623],
              [-62.904101, 46.0491314],
              [-62.9039655, 46.0490082],
              [-62.9039583, 46.0487415],
              [-62.9041798, 46.0482785],
              [-62.9042116, 46.047834],
              [-62.9044517, 46.0477335],
              [-62.9041215, 46.0472309],
              [-62.9039246, 46.0465969],
              [-62.903918, 46.0461274],
              [-62.9039673, 46.0460652],
              [-62.9047156, 46.0459672],
              [-62.905862, 46.0456062],
              [-62.9061155, 46.0454702],
              [-62.906057, 46.045397],
              [-62.9054722, 46.045436],
              [-62.9047409, 46.045439],
              [-62.9044447, 46.0454266],
              [-62.9043036, 46.0454941],
              [-62.904304, 46.0455856],
              [-62.9043836, 46.0456873],
              [-62.9043108, 46.0457608],
              [-62.9039757, 46.0456319],
              [-62.9024837, 46.0453626],
              [-62.9021698, 46.0452622],
              [-62.9018109, 46.0450531],
              [-62.9016812, 46.0448306],
              [-62.9014016, 46.0446318],
              [-62.9010013, 46.0446402],
              [-62.9006784, 46.0447901],
              [-62.8996385, 46.0444104],
              [-62.8994159, 46.0442131],
              [-62.8993282, 46.0435703],
              [-62.8988887, 46.042985],
              [-62.8987087, 46.0425504],
              [-62.8982128, 46.0421464],
              [-62.898305, 46.0419383],
              [-62.8980814, 46.0417609],
              [-62.8979685, 46.0417378],
              [-62.8977893, 46.0418718],
              [-62.8976928, 46.0426366],
              [-62.8979362, 46.0431566],
              [-62.8977356, 46.0435566],
              [-62.897736, 46.0440338],
              [-62.8982337, 46.0446009],
              [-62.8983131, 46.0449969],
              [-62.8984058, 46.045266],
              [-62.8986492, 46.0454002],
              [-62.8989178, 46.0453005],
              [-62.8991767, 46.0452681],
              [-62.8996149, 46.0454875],
              [-62.9006457, 46.0457317],
              [-62.9008336, 46.0459359],
              [-62.9013146, 46.0463036],
              [-62.9022035, 46.0467267],
              [-62.9022041, 46.0469097],
              [-62.901627, 46.0473983],
              [-62.9010445, 46.0473976],
              [-62.9002656, 46.0472401],
              [-62.8996245, 46.0471661],
              [-62.8982202, 46.0471538],
              [-62.8975662, 46.0472981],
              [-62.8965722, 46.0473015],
              [-62.8956169, 46.0470355],
              [-62.894664, 46.0467299],
              [-62.8941023, 46.046666],
              [-62.8938831, 46.0467035],
              [-62.8935613, 46.0468335],
              [-62.8932206, 46.0468953],
              [-62.8926057, 46.0468618],
              [-62.8922233, 46.0466639],
              [-62.8926069, 46.0464562],
              [-62.8926492, 46.0461273],
              [-62.8924599, 46.0458514],
              [-62.8919305, 46.0454345],
              [-62.8912139, 46.0449964],
              [-62.890801, 46.0449289],
              [-62.8894946, 46.0449033],
              [-62.8884092, 46.0446176],
              [-62.8877808, 46.0442337],
              [-62.8868318, 46.0435741],
              [-62.8865923, 46.0434718],
              [-62.8858911, 46.0435471],
              [-62.8852957, 46.0437646],
              [-62.8847393, 46.0438043],
              [-62.8839417, 46.0435785],
              [-62.8828958, 46.0432064],
              [-62.8826933, 46.0430574],
              [-62.882785, 46.0426663],
              [-62.8828935, 46.0424745],
              [-62.8828633, 46.0419248],
              [-62.8827946, 46.0417359],
              [-62.8823808, 46.0413939],
              [-62.8820593, 46.0412296],
              [-62.8815636, 46.0412113],
              [-62.8813229, 46.0411288],
              [-62.8810187, 46.040961],
              [-62.8807299, 46.0409209],
              [-62.8801174, 46.0408476],
              [-62.8794363, 46.0405853],
              [-62.8789297, 46.0402685],
              [-62.8782692, 46.0398517],
              [-62.878035, 46.0398529],
              [-62.8781272, 46.0400306],
              [-62.8785879, 46.0403501],
              [-62.8787187, 46.0405527],
              [-62.8792473, 46.0407867],
              [-62.8794468, 46.0407924],
              [-62.8797315, 46.041095],
              [-62.8802096, 46.041411],
              [-62.8808917, 46.0415619],
              [-62.8811694, 46.0415977],
              [-62.8813977, 46.0416957],
              [-62.8814177, 46.041744],
              [-62.8815591, 46.041768],
              [-62.8818849, 46.0421472],
              [-62.8820556, 46.0424465],
              [-62.8820371, 46.0428556],
              [-62.8816047, 46.0433084],
              [-62.8816637, 46.0435647],
              [-62.8815614, 46.0437487],
              [-62.8814527, 46.0438489],
              [-62.8814694, 46.0440483],
              [-62.8815526, 46.0440904],
              [-62.8818973, 46.0441521],
              [-62.8825873, 46.0444583],
              [-62.8828647, 46.0447884],
              [-62.882975, 46.0447598],
              [-62.8836915, 46.0444264],
              [-62.8841388, 46.0443955],
              [-62.8849985, 46.044635],
              [-62.8859131, 46.0446215],
              [-62.8862913, 46.044696],
              [-62.8869238, 46.0452034],
              [-62.8874587, 46.0454295],
              [-62.8879827, 46.0457428],
              [-62.8890626, 46.0462193],
              [-62.8897062, 46.0463451],
              [-62.8900501, 46.0465181],
              [-62.8902397, 46.0468854],
              [-62.8902906, 46.0472806],
              [-62.8900043, 46.0476781],
              [-62.8899905, 46.0480078],
              [-62.8898953, 46.0480727],
              [-62.88905, 46.0480723],
              [-62.8887664, 46.0481357],
              [-62.8883569, 46.0483944],
              [-62.8888675, 46.0483574],
              [-62.8892405, 46.0483283],
              [-62.8896796, 46.0484364],
              [-62.8911704, 46.0483399],
              [-62.8916468, 46.0484928],
              [-62.8918197, 46.0486608],
              [-62.8927927, 46.0494006],
              [-62.8929899, 46.049446],
              [-62.8935727, 46.0491525],
              [-62.8938005, 46.0490676],
              [-62.8939927, 46.0491009],
              [-62.894461, 46.0493927],
              [-62.8947191, 46.0494717],
              [-62.8948796, 46.0496553],
              [-62.8953067, 46.0498703],
              [-62.8967223, 46.049887],
              [-62.8968634, 46.0498194],
              [-62.8969454, 46.0498814],
              [-62.8973663, 46.0497185],
              [-62.8974639, 46.0496139],
              [-62.8975246, 46.0492617],
              [-62.8975752, 46.0491796],
              [-62.8976642, 46.0491225],
              [-62.8981448, 46.0490129],
              [-62.8989019, 46.048959],
              [-62.8993501, 46.0488167],
              [-62.8997678, 46.0488048],
              [-62.900025, 46.0489951],
              [-62.8999787, 46.0492006],
              [-62.8995632, 46.0498529],
              [-62.898448, 46.051038],
              [-62.8981527, 46.0513],
              [-62.8969797, 46.0519148],
              [-62.896928, 46.0520167],
              [-62.8957432, 46.0528299],
              [-62.8952735, 46.0532381],
              [-62.8949805, 46.0538462],
              [-62.8947495, 46.0540822],
              [-62.8942274, 46.0540235],
              [-62.8940135, 46.0541645],
              [-62.8938997, 46.0542527],
              [-62.8937436, 46.054284],
              [-62.8937913, 46.0544445],
              [-62.8940082, 46.0544467],
              [-62.8942563, 46.0545016],
              [-62.8945506, 46.0546452],
              [-62.8946909, 46.0550748],
              [-62.8946663, 46.0554917],
              [-62.8945713, 46.0556481],
              [-62.8945082, 46.05604],
              [-62.8942481, 46.056478],
              [-62.8931396, 46.057544],
              [-62.8927378, 46.0582524],
              [-62.8922234, 46.0586434],
              [-62.8916254, 46.0589007],
              [-62.890451, 46.0595352],
              [-62.8886194, 46.0601707],
              [-62.8880737, 46.060509],
              [-62.8880731, 46.0607119],
              [-62.8885106, 46.0606568],
              [-62.8887787, 46.06076],
              [-62.8890824, 46.0607448],
              [-62.8896155, 46.0607164],
              [-62.8897172, 46.0607352],
              [-62.8906909, 46.0605006],
              [-62.8915073, 46.0599314],
              [-62.8920844, 46.0597371],
              [-62.8929508, 46.0592886],
              [-62.8935332, 46.0591979],
              [-62.8940159, 46.0589572],
              [-62.895035, 46.0578568],
              [-62.8955347, 46.0575211],
              [-62.8963869, 46.0569251],
              [-62.8965355, 46.0565356],
              [-62.8967095, 46.0562979],
              [-62.8972983, 46.0559051],
              [-62.8979109, 46.0555925],
              [-62.8982906, 46.0553528],
              [-62.8990411, 46.0551237],
              [-62.9012021, 46.0545133],
              [-62.9018823, 46.0544094],
              [-62.9020191, 46.0545128],
              [-62.9020074, 46.0547113],
              [-62.9018717, 46.0552682],
              [-62.9015365, 46.0558194],
              [-62.9013911, 46.0560579],
              [-62.90143, 46.0565601],
              [-62.9014981, 46.0572462],
              [-62.9016966, 46.0572717],
              [-62.901794, 46.0567813],
              [-62.9019271, 46.0565584],
              [-62.9021014, 46.0564123],
              [-62.9039705, 46.0550265],
              [-62.9038346, 46.0546299],
              [-62.9038572, 46.0545037],
              [-62.9039524, 46.0544799],
              [-62.9041253, 46.0545411],
              [-62.9042289, 46.0547768],
              [-62.9047305, 46.0550233],
              [-62.9056643, 46.0554471],
              [-62.906329, 46.0554998],
              [-62.9069994, 46.0553602],
              [-62.9078393, 46.055056],
              [-62.9082148, 46.0550177],
              [-62.9090783, 46.0551018],
              [-62.9096313, 46.0549686],
              [-62.9098334, 46.0547157],
              [-62.9101798, 46.0543648],
              [-62.9102554, 46.0543367],
              [-62.9104466, 46.0543607],
              [-62.9106081, 46.0547939],
              [-62.9106903, 46.0548947],
              [-62.9110777, 46.0550258],
              [-62.911648, 46.0551433],
              [-62.9122172, 46.0553681],
              [-62.9122977, 46.0556192],
              [-62.9121776, 46.0558498],
              [-62.9120324, 46.0560367],
              [-62.9117408, 46.0562203],
              [-62.9105004, 46.0566165],
              [-62.9096545, 46.0566285],
              [-62.909259, 46.0566854],
              [-62.9090371, 46.0567996],
              [-62.9083453, 46.0573869],
              [-62.9074567, 46.0582544],
              [-62.9074102, 46.0585554],
              [-62.9075272, 46.058671],
              [-62.9080777, 46.0594733],
              [-62.90823, 46.0595563],
              [-62.9081927, 46.0596048],
              [-62.9074182, 46.0592846],
              [-62.9072764, 46.0591938],
              [-62.9070182, 46.0591656],
              [-62.9067751, 46.059244],
              [-62.9066943, 46.0594779],
              [-62.9066497, 46.0600258],
              [-62.9067085, 46.0602545],
              [-62.9071372, 46.0615254],
              [-62.9068064, 46.0616931],
              [-62.9068287, 46.0618344],
              [-62.9069473, 46.0619301],
              [-62.9074534, 46.0615919],
              [-62.9075009, 46.0616453],
              [-62.9077159, 46.0617787],
              [-62.9078473, 46.0617785],
              [-62.9082552, 46.061448],
              [-62.9084896, 46.0614467],
              [-62.9091863, 46.0608133],
              [-62.9094763, 46.0605427],
              [-62.9094613, 46.0603687],
              [-62.9093369, 46.0602498],
              [-62.9092068, 46.0599359],
              [-62.9093441, 46.0598364],
              [-62.9106286, 46.0598531],
              [-62.9116676, 46.0598667],
              [-62.9121739, 46.0600004],
              [-62.9124066, 46.0602218],
              [-62.9125202, 46.0604279],
              [-62.9126712, 46.0606788],
              [-62.913271, 46.0612646],
              [-62.9134135, 46.061563],
              [-62.9128343, 46.0621829],
              [-62.9126291, 46.0625],
              [-62.9129015, 46.0625],
              [-62.9133396, 46.0620421],
              [-62.9140124, 46.06158],
              [-62.9144271, 46.0614247],
              [-62.9147613, 46.0612791],
              [-62.9156147, 46.0610488],
              [-62.9162117, 46.0609027],
              [-62.9168823, 46.060866],
              [-62.918639, 46.0608204],
              [-62.9191208, 46.0606909],
              [-62.9196357, 46.0604828],
              [-62.9203572, 46.0604555],
              [-62.9206439, 46.0605352],
              [-62.9214763, 46.0609566],
              [-62.9223068, 46.0612148],
              [-62.9232818, 46.0613458],
              [-62.9237897, 46.0615511],
              [-62.9243046, 46.0619316],
              [-62.9245304, 46.062272],
              [-62.9245339, 46.0625],
              [-62.9245421, 46.063048],
              [-62.9245093, 46.0632181],
              [-62.9243127, 46.0633557],
              [-62.9237473, 46.0633516],
              [-62.923553, 46.0634495],
              [-62.9235353, 46.0636558],
              [-62.923413, 46.0637916],
              [-62.9224764, 46.0642742],
              [-62.9223869, 46.0645341],
              [-62.9224682, 46.0647075],
              [-62.9226493, 46.0647365],
              [-62.9228667, 46.0645358],
              [-62.9229516, 46.0643552],
              [-62.9234118, 46.0641058],
              [-62.9239368, 46.0639218],
              [-62.9244082, 46.0639709],
              [-62.9245959, 46.0637893],
              [-62.9248445, 46.0636412],
              [-62.9255155, 46.0639903],
              [-62.9256755, 46.0639908],
              [-62.9258004, 46.0639069],
              [-62.9258011, 46.0637955],
              [-62.9256008, 46.0633126],
              [-62.9256521, 46.0631191],
              [-62.9257605, 46.0625],
              [-62.9255097, 46.0619378],
              [-62.9255518, 46.0616089],
              [-62.9258262, 46.0614098],
              [-62.925856, 46.0605078],
              [-62.9261937, 46.0600083],
              [-62.9265009, 46.0596391],
              [-62.9270536, 46.059273],
              [-62.9277007, 46.0589532],
              [-62.9279628, 46.0587697],
              [-62.9277774, 46.058323],
              [-62.9271967, 46.0584855],
              [-62.9270202, 46.0580828],
              [-62.927263, 46.058034],
              [-62.9273717, 46.058228],
              [-62.9277073, 46.0581539],
              [-62.9273798, 46.0575004],
              [-62.9276808, 46.0574333],
              [-62.9279258, 46.0579334],
              [-62.9285925, 46.0581591],
              [-62.9291471, 46.0582503],
              [-62.9304365, 46.0582788],
              [-62.9313803, 46.0583571],
              [-62.9318532, 46.0584779],
              [-62.9320509, 46.0586147],
              [-62.9323318, 46.0587936],
              [-62.9323681, 46.0588583],
              [-62.9327249, 46.059107],
              [-62.9331639, 46.0591234],
              [-62.9333928, 46.0593128],
              [-62.9336375, 46.0594271],
              [-62.9344804, 46.0593753],
              [-62.9346831, 46.0595242],
              [-62.9347856, 46.0597259],
              [-62.9354057, 46.0600656],
              [-62.9356027, 46.0603137],
              [-62.9358447, 46.0603762],
              [-62.9363561, 46.0602276],
              [-62.9366679, 46.0600733],
              [-62.937261, 46.0598951],
              [-62.9376753, 46.0596483],
              [-62.9382791, 46.0590886],
              [-62.9383461, 46.05882],
              [-62.9383333, 46.0584497],
              [-62.9382281, 46.0581962],
              [-62.9378785, 46.0576256],
              [-62.9378769, 46.0572596],
              [-62.9381821, 46.0570216],
              [-62.9387797, 46.056764],
              [-62.9392048, 46.0567243],
              [-62.9395998, 46.0569064],
              [-62.9403914, 46.057048],
              [-62.9407282, 46.0572484],
              [-62.9408604, 46.0574311],
              [-62.9409072, 46.0579057],
              [-62.9412148, 46.0582166],
              [-62.9414125, 46.0583534],
              [-62.942374, 46.0585197],
              [-62.9428361, 46.0584332],
              [-62.9429208, 46.0582527],
              [-62.9433196, 46.0578781],
              [-62.9439511, 46.0574305],
              [-62.9442077, 46.0571434],
              [-62.9443163, 46.057043],
              [-62.9447129, 46.0559167],
              [-62.9449788, 46.0557651],
              [-62.9454166, 46.0558012],
              [-62.945696, 46.0559085],
              [-62.9461952, 46.056364],
              [-62.9467913, 46.0574148],
              [-62.9469772, 46.0579529],
              [-62.9470586, 46.0581262],
              [-62.9473651, 46.058457],
              [-62.947534, 46.0587958],
              [-62.9474397, 46.0590437],
              [-62.9471479, 46.058952],
              [-62.9469552, 46.0588273],
              [-62.9465524, 46.0588757],
              [-62.945931, 46.0593474],
              [-62.9455877, 46.059652],
              [-62.9451338, 46.0598938],
              [-62.9443611, 46.0601147],
              [-62.9440262, 46.0600774],
              [-62.9432967, 46.0602439],
              [-62.9429395, 46.0604924],
              [-62.9429577, 46.0606718],
              [-62.9426614, 46.0609539],
              [-62.9427165, 46.0610867],
              [-62.942905, 46.061088],
              [-62.9429655, 46.06103],
              [-62.9431389, 46.0609951],
              [-62.943737, 46.060829],
              [-62.9440245, 46.0607973],
              [-62.9442604, 46.0608675],
              [-62.9449338, 46.0614711],
              [-62.9450704, 46.0617772],
              [-62.944877, 46.0620581],
              [-62.9443579, 46.0624373],
              [-62.9443109, 46.0624999],
              [-62.9438562, 46.0631073],
              [-62.9431491, 46.063871],
              [-62.9429711, 46.0639853],
              [-62.9424794, 46.0643851],
              [-62.9416331, 46.0647909],
              [-62.9411082, 46.0652693],
              [-62.9411006, 46.0654998],
              [-62.9412952, 46.0654933],
              [-62.9417824, 46.0654672],
              [-62.9421574, 46.0653068],
              [-62.9424703, 46.0651326],
              [-62.9430049, 46.0651755],
              [-62.9433446, 46.0654276],
              [-62.9434129, 46.0657278],
              [-62.9433903, 46.0662164],
              [-62.9436794, 46.0662564],
              [-62.9439611, 46.0660296],
              [-62.9450684, 46.0657544],
              [-62.9453975, 46.0655966],
              [-62.9457497, 46.0653361],
              [-62.9460694, 46.0647484],
              [-62.9461367, 46.064277],
              [-62.9460881, 46.0642279],
              [-62.9455994, 46.0641824],
              [-62.9454872, 46.064048],
              [-62.945592, 46.0639157],
              [-62.9465146, 46.0636712],
              [-62.9470078, 46.063343],
              [-62.9476958, 46.0625],
              [-62.9480042, 46.062122],
              [-62.9484868, 46.0613839],
              [-62.9488139, 46.0610629],
              [-62.9496088, 46.0605562],
              [-62.949651, 46.0600245],
              [-62.9499996, 46.0595291],
              [-62.950391, 46.0591822],
              [-62.9506051, 46.0588382],
              [-62.9504058, 46.0578383],
              [-62.9502701, 46.0577152],
              [-62.9500039, 46.0577753],
              [-62.9493177, 46.0581816],
              [-62.9485008, 46.0584769],
              [-62.9481301, 46.0584665],
              [-62.9478926, 46.0583246],
              [-62.9479288, 46.058095],
              [-62.9481969, 46.0579036],
              [-62.9487552, 46.0577324],
              [-62.9490003, 46.0576438],
              [-62.9493698, 46.0573797],
              [-62.950405, 46.0568638],
              [-62.9510266, 46.0566864],
              [-62.9511406, 46.0566896],
              [-62.9516396, 46.0565564],
              [-62.9525357, 46.055977],
              [-62.953033, 46.0554778],
              [-62.9538104, 46.0549746],
              [-62.9546668, 46.0546844],
              [-62.9556348, 46.0546399],
              [-62.9560329, 46.0546709],
              [-62.9561608, 46.0547302],
              [-62.9565609, 46.0549243],
              [-62.9573839, 46.0554126],
              [-62.9581139, 46.0556319],
              [-62.9590992, 46.056081],
              [-62.9609069, 46.0566325],
              [-62.9614019, 46.0569645],
              [-62.9627673, 46.0575594],
              [-62.9627588, 46.0576069],
              [-62.9624378, 46.0576258],
              [-62.9619375, 46.0574846],
              [-62.9616053, 46.0574992],
              [-62.9613445, 46.0576629],
              [-62.9612934, 46.0578564],
              [-62.9604255, 46.0583452],
              [-62.9600453, 46.0586965],
              [-62.959886, 46.0590818],
              [-62.9597133, 46.0595026],
              [-62.9588495, 46.0606119],
              [-62.9583418, 46.0609955],
              [-62.9575142, 46.061378],
              [-62.9566599, 46.0616286],
              [-62.9559224, 46.0619341],
              [-62.955615, 46.0622119],
              [-62.9559499, 46.0622491],
              [-62.9566671, 46.0620981],
              [-62.9570532, 46.0619418],
              [-62.9575168, 46.061927],
              [-62.9577865, 46.0618072],
              [-62.9583421, 46.0615841],
              [-62.9589309, 46.0612824],
              [-62.9595608, 46.0610574],
              [-62.9599022, 46.0608839],
              [-62.9602241, 46.061048],
              [-62.9603875, 46.060989],
              [-62.9605233, 46.0606149],
              [-62.9608423, 46.0604329],
              [-62.9611012, 46.0604004],
              [-62.9612557, 46.0602973],
              [-62.9612548, 46.0601143],
              [-62.9609067, 46.0599097],
              [-62.9609574, 46.0596248],
              [-62.9612517, 46.0594739],
              [-62.961713, 46.0594987],
              [-62.9625262, 46.0589686],
              [-62.9633562, 46.0588406],
              [-62.9638931, 46.0588436],
              [-62.9644231, 46.0589658],
              [-62.9653708, 46.0595728],
              [-62.965679, 46.0599752],
              [-62.9657692, 46.0603953],
              [-62.965711, 46.0609107],
              [-62.9653906, 46.0613154],
              [-62.965316, 46.0620173],
              [-62.9652872, 46.0622193],
              [-62.9651251, 46.0625],
              [-62.9658789, 46.0625],
              [-62.9663788, 46.0618162],
              [-62.9668438, 46.0615786],
              [-62.9677067, 46.0615748],
              [-62.9677549, 46.0615323],
              [-62.9673635, 46.0608851],
              [-62.9672319, 46.060094],
              [-62.9672122, 46.0586457],
              [-62.9670361, 46.0581317],
              [-62.9666993, 46.0577286],
              [-62.9658148, 46.0571154],
              [-62.9657268, 46.0568584],
              [-62.9656718, 46.0565228],
              [-62.9655322, 46.0563678],
              [-62.9652878, 46.0563451],
              [-62.9644697, 46.0568631],
              [-62.9639203, 46.0570785],
              [-62.9629596, 46.0570955],
              [-62.9626302, 46.0571619],
              [-62.9626818, 46.0570599],
              [-62.9629599, 46.0568926],
              [-62.9638186, 46.0565626],
              [-62.9650106, 46.0561981],
              [-62.9655383, 46.0558628],
              [-62.965696, 46.0554059],
              [-62.9660615, 46.0551098],
              [-62.9666975, 46.054877],
              [-62.9673417, 46.0547995],
              [-62.9676447, 46.0548954],
              [-62.9680154, 46.0549057],
              [-62.9683583, 46.0548039],
              [-62.9691528, 46.0547942],
              [-62.9697387, 46.0549378],
              [-62.9698097, 46.0549954],
              [-62.9704144, 46.0552071],
              [-62.9719064, 46.0550895],
              [-62.972304, 46.055029],
              [-62.9731349, 46.0550838],
              [-62.9734463, 46.0551323],
              [-62.9743485, 46.0548392],
              [-62.9748171, 46.0548362],
              [-62.9753035, 46.0549213],
              [-62.9764579, 46.0552119],
              [-62.9767278, 46.0553864],
              [-62.9774754, 46.0553992],
              [-62.978495, 46.0557496],
              [-62.9792292, 46.0557978],
              [-62.9805799, 46.0561533],
              [-62.9816579, 46.0562826],
              [-62.9822093, 46.0562303],
              [-62.9826237, 46.0560747],
              [-62.9837655, 46.0553864],
              [-62.984086, 46.055276],
              [-62.9850948, 46.0552164],
              [-62.9880315, 46.0543033],
              [-62.9881456, 46.0543064],
              [-62.9884179, 46.0542384],
              [-62.9899601, 46.0542411],
              [-62.9907121, 46.0543772],
              [-62.9913093, 46.0543221],
              [-62.9918763, 46.0541946],
              [-62.9924407, 46.0538124],
              [-62.9926007, 46.05361],
              [-62.9930678, 46.0533326],
              [-62.993599, 46.0532319],
              [-62.9947749, 46.053145],
              [-62.9950651, 46.0531649],
              [-62.9956181, 46.0531841],
              [-62.9977908, 46.0535501],
              [-62.9981307, 46.0535992],
              [-62.9988447, 46.053599],
              [-62.9992233, 46.0534702],
              [-62.999223, 46.0527702],
              [-62.9993455, 46.0527259],
              [-63.0000001, 46.052245],
              [-63.0008654, 46.0521114],
              [-63.0016118, 46.0521439],
              [-63.0033098, 46.0525206],
              [-63.0037029, 46.0525393],
              [-63.0043174, 46.0524806],
              [-63.0046551, 46.0523666],
              [-63.0056376, 46.0518606],
              [-63.0065658, 46.0516077],
              [-63.0085541, 46.0513957],
              [-63.0093497, 46.0513658],
              [-63.0103276, 46.0513448],
              [-63.0116611, 46.0512978],
              [-63.0124003, 46.0513578],
              [-63.012965, 46.0512698],
              [-63.0136046, 46.0512714],
              [-63.0162503, 46.0519601],
              [-63.0171606, 46.0520247],
              [-63.018337, 46.0518262],
              [-63.0195905, 46.0518802],
              [-63.0214855, 46.0522103],
              [-63.0220278, 46.0523165],
              [-63.0223242, 46.0523285],
              [-63.0230857, 46.052397],
              [-63.0233977, 46.0523339],
              [-63.0242475, 46.0520508],
              [-63.0252058, 46.0518701],
              [-63.0266623, 46.0518699],
              [-63.0272729, 46.0517792],
              [-63.0276659, 46.051595],
              [-63.0286956, 46.0509548],
              [-63.0290383, 46.0508528],
              [-63.0292362, 46.0505838],
              [-63.0296414, 46.050384],
              [-63.0303799, 46.0501495],
              [-63.0309748, 46.0499311],
              [-63.0315539, 46.0495849],
              [-63.0318863, 46.0490531],
              [-63.0333946, 46.0486366],
              [-63.0336859, 46.0486366],
              [-63.0340063, 46.0487288],
              [-63.0370089, 46.0495737],
              [-63.037842, 46.0495883],
              [-63.0390362, 46.0494774],
              [-63.0397563, 46.0494691],
              [-63.040042, 46.0495683],
              [-63.0400858, 46.049808],
              [-63.0399528, 46.0499397],
              [-63.0399546, 46.0502141],
              [-63.0400336, 46.0505384],
              [-63.0399034, 46.0507218],
              [-63.039759, 46.0513661],
              [-63.0399928, 46.0516786],
              [-63.0400451, 46.0520737],
              [-63.0400466, 46.0528652],
              [-63.0397522, 46.0533305],
              [-63.0392843, 46.053628],
              [-63.0383468, 46.0539486],
              [-63.0374748, 46.0544261],
              [-63.0358756, 46.0544226],
              [-63.0345426, 46.0545615],
              [-63.0341194, 46.0546733],
              [-63.033912, 46.0548068],
              [-63.0333502, 46.0551495],
              [-63.0314497, 46.0555275],
              [-63.0309856, 46.0556541],
              [-63.0305613, 46.0557858],
              [-63.0301347, 46.0559571],
              [-63.0299692, 46.056056],
              [-63.0296382, 46.0562538],
              [-63.0291115, 46.0564782],
              [-63.0285737, 46.0566982],
              [-63.0266798, 46.0571597],
              [-63.0259613, 46.0574425],
              [-63.0249582, 46.0576061],
              [-63.0247183, 46.0577069],
              [-63.0243711, 46.0580912],
              [-63.0231672, 46.0586749],
              [-63.0219801, 46.0595692],
              [-63.0212264, 46.0601731],
              [-63.0200577, 46.061044],
              [-63.0194098, 46.0621568],
              [-63.018311, 46.0625211],
              [-63.0173091, 46.0634905],
              [-63.0173175, 46.0636459],
              [-63.0170311, 46.0644693],
              [-63.016978, 46.0651081],
              [-63.0169227, 46.0651782],
              [-63.0161441, 46.0652047],
              [-63.0162588, 46.0652993],
              [-63.0163142, 46.065432],
              [-63.0158908, 46.0657466],
              [-63.0148052, 46.0662539],
              [-63.0150457, 46.0662446],
              [-63.015525, 46.0661542],
              [-63.0157515, 46.0660888],
              [-63.0163259, 46.0657307],
              [-63.0167851, 46.0655921],
              [-63.0171682, 46.0653838],
              [-63.0173852, 46.0651829],
              [-63.0177918, 46.0649633],
              [-63.0182594, 46.06498],
              [-63.0194451, 46.0654339],
              [-63.0206386, 46.065546],
              [-63.0208243, 46.0656982],
              [-63.0208434, 46.0657663],
              [-63.0208059, 46.0659244],
              [-63.0200336, 46.0665516],
              [-63.02008, 46.0672489],
              [-63.0191678, 46.0677212],
              [-63.018585, 46.0677212],
              [-63.0180514, 46.0676589],
              [-63.0175844, 46.0677337],
              [-63.0174781, 46.0677944],
              [-63.0169373, 46.0681655],
              [-63.0163377, 46.0682605],
              [-63.0159277, 46.0685396],
              [-63.0155423, 46.0685848],
              [-63.0153096, 46.0684551],
              [-63.0145014, 46.0682978],
              [-63.0142581, 46.0682554],
              [-63.01356, 46.0678664],
              [-63.0134857, 46.0678684],
              [-63.0129946, 46.0678629],
              [-63.0118899, 46.0680992],
              [-63.0109418, 46.0683954],
              [-63.0103807, 46.068921],
              [-63.0101251, 46.0690969],
              [-63.0094063, 46.0693795],
              [-63.0088693, 46.0695796],
              [-63.0080565, 46.0695017],
              [-63.0070061, 46.0696877],
              [-63.0059316, 46.0695906],
              [-63.0055731, 46.069362],
              [-63.0050898, 46.0692175],
              [-63.0046492, 46.068927],
              [-63.0045804, 46.0688297],
              [-63.0044166, 46.0685945],
              [-63.0043294, 46.0681149],
              [-63.0044251, 46.0679385],
              [-63.0046679, 46.0676867],
              [-63.0047698, 46.0672997],
              [-63.0046681, 46.0670782],
              [-63.004582, 46.0669843],
              [-63.0035295, 46.0666015],
              [-63.0024568, 46.0663732],
              [-63.0019904, 46.0661337],
              [-63.0015542, 46.0659666],
              [-62.9998634, 46.0658631],
              [-62.999226, 46.0662671],
              [-62.9971967, 46.0665852],
              [-62.99578, 46.0666814],
              [-62.9941758, 46.0669594],
              [-62.9920042, 46.0675678],
              [-62.9900322, 46.0681816],
              [-62.9889805, 46.0683872],
              [-62.9878885, 46.0686991],
              [-62.9868478, 46.0691119],
              [-62.986558, 46.0691834],
              [-62.9861537, 46.0691603],
              [-62.9858305, 46.0690162],
              [-62.9858249, 46.0689126],
              [-62.9855923, 46.0687829],
              [-62.9850839, 46.0687808],
              [-62.9834491, 46.0693918],
              [-62.9826868, 46.0699316],
              [-62.9816377, 46.0703917],
              [-62.9791249, 46.0712648],
              [-62.9771978, 46.071987],
              [-62.9753715, 46.0725449],
              [-62.9748628, 46.0727456],
              [-62.9739094, 46.0735265],
              [-62.9731344, 46.0737874],
              [-62.972557, 46.0738907],
              [-62.9720875, 46.0740049],
              [-62.9713077, 46.0745481],
              [-62.9708406, 46.0746226],
              [-62.9707002, 46.0745789],
              [-62.9704596, 46.0745881],
              [-62.9698422, 46.07464],
              [-62.968596, 46.0749434],
              [-62.9675085, 46.0749728],
              [-62.9667179, 46.0751059],
              [-62.9652627, 46.0752683],
              [-62.964299, 46.0755278],
              [-62.962957, 46.0760075],
              [-62.9617938, 46.0765557],
              [-62.9610002, 46.0768399],
              [-62.9597277, 46.0771027],
              [-62.9586409, 46.0778122],
              [-62.9577365, 46.0780335],
              [-62.9566022, 46.078674],
              [-62.955774, 46.0792594],
              [-62.9555867, 46.0795326],
              [-62.955379, 46.079666],
              [-62.9549136, 46.0796093],
              [-62.9543442, 46.0798678],
              [-62.9539175, 46.0801303],
              [-62.9533176, 46.0802249],
              [-62.9525725, 46.0805581],
              [-62.9519565, 46.0809306],
              [-62.951318, 46.0810003],
              [-62.9502393, 46.0813678],
              [-62.9489768, 46.081949],
              [-62.9478476, 46.0823986],
              [-62.9474894, 46.0824641],
              [-62.9469296, 46.0826553],
              [-62.9452259, 46.0833513],
              [-62.9439841, 46.0841637],
              [-62.9428765, 46.0850275],
              [-62.9425014, 46.0854824],
              [-62.9423468, 46.0855854],
              [-62.9423163, 46.0857158],
              [-62.9420415, 46.0861178],
              [-62.9405796, 46.0879622],
              [-62.9392826, 46.0898191],
              [-62.9385574, 46.0910835],
              [-62.9382138, 46.0913881],
              [-62.9374024, 46.0916755],
              [-62.9371544, 46.0917123],
              [-62.9357475, 46.0917204],
              [-62.9351489, 46.0915922],
              [-62.934985, 46.0915597],
              [-62.9346522, 46.0914827],
              [-62.9344393, 46.0913097],
              [-62.9342607, 46.0910381],
              [-62.9339884, 46.0909032],
              [-62.9326841, 46.0908187],
              [-62.9308939, 46.0899487],
              [-62.9303926, 46.0899187],
              [-62.9294647, 46.0902425],
              [-62.927873, 46.0905755],
              [-62.9266004, 46.090635],
              [-62.9251472, 46.0908485],
              [-62.9232928, 46.0912733],
              [-62.9223575, 46.0912061],
              [-62.9216121, 46.091369],
              [-62.9206402, 46.0912698],
              [-62.9200686, 46.0913875],
              [-62.9191328, 46.0914124],
              [-62.9186206, 46.0913356],
              [-62.9182241, 46.0911337],
              [-62.9172284, 46.09066],
              [-62.9167441, 46.0905348],
              [-62.9165196, 46.0905603],
              [-62.9163167, 46.0907057],
              [-62.9162669, 46.0909708],
              [-62.9162782, 46.0916553],
              [-62.915979, 46.0922644],
              [-62.9156212, 46.0927292],
              [-62.9153544, 46.0930744],
              [-62.9153347, 46.0930999],
              [-62.9152387, 46.0934892],
              [-62.9150372, 46.0939101],
              [-62.9147963, 46.0945005],
              [-62.9145137, 46.0948353],
              [-62.914395, 46.0951351],
              [-62.9145461, 46.0954748],
              [-62.9146517, 46.095642],
              [-62.9148063, 46.0957106],
              [-62.9150774, 46.0957045],
              [-62.9149114, 46.0959464],
              [-62.9146296, 46.0962706],
              [-62.914366, 46.096532],
              [-62.914188, 46.0965595],
              [-62.9139901, 46.0965895],
              [-62.9135303, 46.0970834],
              [-62.9117449, 46.0985541],
              [-62.9114385, 46.0987363],
              [-62.9111953, 46.0989606],
              [-62.9108915, 46.0991424],
              [-62.9097274, 46.1001305],
              [-62.9088612, 46.1010195],
              [-62.907778, 46.1017405],
              [-62.9067929, 46.1023562],
              [-62.9057606, 46.1028844],
              [-62.9038536, 46.1037052],
              [-62.902899, 46.1039883],
              [-62.9022025, 46.1042588],
              [-62.9006382, 46.104795],
              [-62.8999896, 46.1049316],
              [-62.8994822, 46.1050007],
              [-62.8986094, 46.1052542],
              [-62.8975962, 46.1058499],
              [-62.8967271, 46.1068156],
              [-62.8963315, 46.1069276],
              [-62.8958874, 46.1069905],
              [-62.8946501, 46.1070227],
              [-62.8940227, 46.1071878],
              [-62.893633, 46.1072005],
              [-62.8925567, 46.1075078],
              [-62.8914601, 46.1076753],
              [-62.8902387, 46.1076324],
              [-62.889673, 46.1077196],
              [-62.8892145, 46.1079292],
              [-62.8890134, 46.1079434],
              [-62.8886561, 46.1079888],
              [-62.8873108, 46.107624],
              [-62.8862898, 46.1073839],
              [-62.8856717, 46.10739],
              [-62.8850863, 46.1075204],
              [-62.8848061, 46.1076158],
              [-62.88438, 46.1078582],
              [-62.8842457, 46.1081925],
              [-62.8838928, 46.1084528],
              [-62.8835096, 46.1085492],
              [-62.8829089, 46.1085519],
              [-62.8821168, 46.1085013],
              [-62.8817663, 46.1087219],
              [-62.8813813, 46.1086552],
              [-62.8810482, 46.1084865],
              [-62.8806892, 46.1079828],
              [-62.8800734, 46.1079492],
              [-62.8797232, 46.1078755],
              [-62.8793774, 46.1080167],
              [-62.8769161, 46.1084312],
              [-62.8762257, 46.1083079],
              [-62.8755797, 46.1081102],
              [-62.875, 46.1080751],
              [-62.875, 46.1087067],
              [-62.8753614, 46.1089911],
              [-62.8759715, 46.1092155],
              [-62.8769792, 46.1091968],
              [-62.8775623, 46.1091062],
              [-62.8787948, 46.1086762],
              [-62.8792772, 46.1085469],
              [-62.8798034, 46.1085461],
              [-62.8800719, 46.1086493],
              [-62.8801563, 46.1086716],
              [-62.8803282, 46.1088596],
              [-62.8803331, 46.1092576],
              [-62.8802448, 46.1095892],
              [-62.8804381, 46.1098971],
              [-62.880617, 46.1099659],
              [-62.8808137, 46.1097368],
              [-62.8809385, 46.1095614],
              [-62.8810377, 46.1095285],
              [-62.8838739, 46.1095423],
              [-62.8841193, 46.1094539],
              [-62.8849097, 46.1089554],
              [-62.8852473, 46.1089532],
              [-62.8855869, 46.1088197],
              [-62.8858898, 46.1088245],
              [-62.8862194, 46.1090527],
              [-62.8862079, 46.1093428],
              [-62.8856427, 46.1107707],
              [-62.8855676, 46.1121331],
              [-62.8856529, 46.1139735],
              [-62.8855177, 46.1148051],
              [-62.884924, 46.1154604],
              [-62.8849101, 46.1157902],
              [-62.8847084, 46.1163931],
              [-62.8848294, 46.1169575],
              [-62.8845331, 46.1182857],
              [-62.8845322, 46.118783],
              [-62.8844238, 46.1194522],
              [-62.8844712, 46.1199071],
              [-62.8847597, 46.1204445],
              [-62.8849522, 46.1212497],
              [-62.8853142, 46.1218967],
              [-62.8851979, 46.1224105],
              [-62.8855706, 46.1228788],
              [-62.8855638, 46.1230894],
              [-62.8859598, 46.1234549],
              [-62.8863508, 46.1245801],
              [-62.886542, 46.1249277],
              [-62.8868864, 46.1254866],
              [-62.8870351, 46.1261633],
              [-62.8873596, 46.1274457],
              [-62.8873028, 46.1279215],
              [-62.8871697, 46.128236],
              [-62.8868722, 46.1288123],
              [-62.8865922, 46.1293851],
              [-62.8864688, 46.1296322],
              [-62.886268, 46.1297379],
              [-62.8853083, 46.1298058],
              [-62.8844677, 46.1290258],
              [-62.8844892, 46.1287598],
              [-62.8846176, 46.1285248],
              [-62.8838921, 46.1280225],
              [-62.8831666, 46.1279062],
              [-62.8823486, 46.1276122],
              [-62.8816643, 46.1275726],
              [-62.8810816, 46.1278463],
              [-62.8803516, 46.1279009],
              [-62.8798186, 46.127818],
              [-62.8792592, 46.1276029],
              [-62.8790593, 46.1275972],
              [-62.8786493, 46.1283334],
              [-62.878494, 46.1287307],
              [-62.8784339, 46.1289717],
              [-62.8785378, 46.1292452],
              [-62.8789137, 46.1295624],
              [-62.8797538, 46.1297736],
              [-62.8811478, 46.1300046],
              [-62.8817505, 46.1301652],
              [-62.882186, 46.1304442],
              [-62.882312, 46.1306349],
              [-62.8823061, 46.1307342],
              [-62.8820837, 46.1311057],
              [-62.8821321, 46.1326986],
              [-62.8814485, 46.1337054],
              [-62.8811177, 46.1339744],
              [-62.88104, 46.1341274],
              [-62.880797, 46.1342676],
              [-62.8803676, 46.1343667],
              [-62.8799762, 46.1343077],
              [-62.8791723, 46.1337753],
              [-62.8786738, 46.1335938],
              [-62.8782233, 46.1332785],
              [-62.8780093, 46.1327392],
              [-62.877795, 46.1324943],
              [-62.8775971, 46.1323573],
              [-62.8772284, 46.1323984],
              [-62.8759943, 46.1333257],
              [-62.875905, 46.1337688],
              [-62.8757155, 46.1339702],
              [-62.8755665, 46.1339739],
              [-62.8755035, 46.1340715],
              [-62.8755005, 46.1343141],
              [-62.8758512, 46.1344794],
              [-62.8758317, 46.1346141],
              [-62.8754438, 46.1348814],
              [-62.8753895, 46.1350231],
              [-62.8753352, 46.1355507],
              [-62.8753339, 46.1359565],
              [-62.8750926, 46.1362599],
              [-62.8750001, 46.1363353],
              [-62.8746353, 46.1366327],
              [-62.8742595, 46.1367929],
              [-62.8728564, 46.1369037],
              [-62.8720525, 46.1372346],
              [-62.8713796, 46.1372908],
              [-62.8710508, 46.1374285],
              [-62.8709509, 46.1376644],
              [-62.8702654, 46.138122],
              [-62.8701787, 46.1382309],
              [-62.8703362, 46.1385657],
              [-62.8703369, 46.1388403],
              [-62.8702936, 46.1388947],
              [-62.870387, 46.1389611],
              [-62.8708662, 46.1388914],
              [-62.8710379, 46.1389878],
              [-62.8711881, 46.1389643],
              [-62.8718309, 46.1392216],
              [-62.8729125, 46.1391294],
              [-62.8734158, 46.1392315],
              [-62.8734991, 46.1392737],
              [-62.8735875, 46.1393279],
              [-62.8739011, 46.1395399],
              [-62.8741086, 46.1399955],
              [-62.8741137, 46.140485],
              [-62.8740079, 46.1408202],
              [-62.8738163, 46.1411528],
              [-62.8730457, 46.1422686],
              [-62.8730251, 46.1424232],
              [-62.873213, 46.142536],
              [-62.8739915, 46.1424391],
              [-62.8741432, 46.1424872],
              [-62.874144, 46.1427618],
              [-62.8742709, 46.142841],
              [-62.8746288, 46.1428871],
              [-62.8750001, 46.1426653],
              [-62.875353, 46.1424544],
              [-62.8756137, 46.1424022],
              [-62.8761288, 46.1426916],
              [-62.8768124, 46.1428426],
              [-62.8773824, 46.1427874],
              [-62.8777945, 46.1426918],
              [-62.8782271, 46.1428276],
              [-62.8786928, 46.1427932],
              [-62.8790054, 46.1426391],
              [-62.880126, 46.1418915],
              [-62.8811483, 46.1415429],
              [-62.8818595, 46.1413286],
              [-62.8821234, 46.1411253],
              [-62.8823741, 46.140663],
              [-62.8827753, 46.1402687],
              [-62.8828175, 46.1394622],
              [-62.882971, 46.1392875],
              [-62.8837886, 46.1390126],
              [-62.884181, 46.1389602],
              [-62.8845874, 46.1390554],
              [-62.8849343, 46.1391887],
              [-62.8855644, 46.1388925],
              [-62.8860288, 46.138878],
              [-62.8862167, 46.1389908],
              [-62.8863068, 46.1392082],
              [-62.8872104, 46.140571],
              [-62.8872196, 46.1407065],
              [-62.886935, 46.1410644],
              [-62.8873512, 46.1410923],
              [-62.8876623, 46.1412524],
              [-62.8881938, 46.1416496],
              [-62.8885829, 46.1417483],
              [-62.8887432, 46.1417489],
              [-62.8890242, 46.1418365],
              [-62.889598, 46.1421991],
              [-62.8895995, 46.1422708],
              [-62.8895215, 46.1423322],
              [-62.888973, 46.1425075],
              [-62.8879459, 46.1429356],
              [-62.8874104, 46.1432783],
              [-62.8868329, 46.1439816],
              [-62.8865568, 46.1445036],
              [-62.8865169, 46.1448397],
              [-62.8863262, 46.1454219],
              [-62.8861498, 46.1458402],
              [-62.8858394, 46.1462585],
              [-62.8858041, 46.1466417],
              [-62.8860004, 46.1469478],
              [-62.8862557, 46.1470272],
              [-62.8865046, 46.1470905],
              [-62.8864442, 46.147205],
              [-62.8860889, 46.1472193],
              [-62.8857083, 46.1472109],
              [-62.8853872, 46.1472223],
              [-62.885068, 46.1472699],
              [-62.884704, 46.1476942],
              [-62.8844544, 46.1480552],
              [-62.8841995, 46.148368],
              [-62.8841339, 46.1487813],
              [-62.8842112, 46.1491347],
              [-62.8843337, 46.1494501],
              [-62.884141, 46.1496285],
              [-62.8835709, 46.1501349],
              [-62.8829607, 46.1504531],
              [-62.8821883, 46.1507209],
              [-62.8804749, 46.1513973],
              [-62.8792182, 46.1519721],
              [-62.8786853, 46.1523016],
              [-62.8783497, 46.152436],
              [-62.8774968, 46.1522957],
              [-62.8765366, 46.1524676],
              [-62.8760505, 46.1523621],
              [-62.8757445, 46.152214],
              [-62.8757489, 46.1520431],
              [-62.8755958, 46.1519233],
              [-62.8754815, 46.15192],
              [-62.8753624, 46.1519961],
              [-62.8753953, 46.1522119],
              [-62.8752265, 46.1522588],
              [-62.8750186, 46.1520976],
              [-62.8750001, 46.1520915],
              [-62.8750001, 46.1527988],
              [-62.8752593, 46.1528606],
              [-62.8754287, 46.1529968],
              [-62.8754428, 46.1531444],
              [-62.8752457, 46.153282],
              [-62.8750001, 46.1533514],
              [-62.8748496, 46.1533939],
              [-62.8742609, 46.1534725],
              [-62.8730455, 46.1537876],
              [-62.8717239, 46.1543464],
              [-62.8707465, 46.1545092],
              [-62.8698715, 46.1543964],
              [-62.8692399, 46.1542349],
              [-62.8676694, 46.1537837],
              [-62.8671557, 46.1535659],
              [-62.8666275, 46.153495],
              [-62.8662926, 46.1532545],
              [-62.8662859, 46.1530792],
              [-62.8667114, 46.1528568],
              [-62.8667174, 46.1527575],
              [-62.8665631, 46.1526575],
              [-62.8656914, 46.1523936],
              [-62.8652882, 46.1521472],
              [-62.8648123, 46.1516798],
              [-62.8636097, 46.1512073],
              [-62.8630294, 46.1511468],
              [-62.861807, 46.1516724],
              [-62.861246, 46.1518631],
              [-62.85999, 46.1518984],
              [-62.8589636, 46.1518289],
              [-62.8578968, 46.1514796],
              [-62.8576155, 46.1513004],
              [-62.857536, 46.1512901],
              [-62.8573131, 46.1510926],
              [-62.8570724, 46.1507156],
              [-62.8566516, 46.1503811],
              [-62.8560765, 46.1504241],
              [-62.8556881, 46.1505999],
              [-62.8554373, 46.1506761],
              [-62.8542907, 46.1507026],
              [-62.8537847, 46.1505486],
              [-62.8528055, 46.1504565],
              [-62.8521373, 46.1505246],
              [-62.8507741, 46.1509148],
              [-62.849863, 46.1512146],
              [-62.8496198, 46.1513547],
              [-62.8488583, 46.1519254],
              [-62.848512, 46.1520666],
              [-62.8471661, 46.1524532],
              [-62.8459306, 46.1527196],
              [-62.8451534, 46.1529795],
              [-62.844414, 46.1534673],
              [-62.8436452, 46.1541572],
              [-62.843172, 46.154219],
              [-62.8422299, 46.1540801],
              [-62.8415437, 46.153877],
              [-62.8412517, 46.1538765],
              [-62.8411208, 46.1542427],
              [-62.8415988, 46.1546705],
              [-62.8422969, 46.1545834],
              [-62.8431277, 46.1549538],
              [-62.8434906, 46.1550121],
              [-62.8448532, 46.154825],
              [-62.8460576, 46.154506],
              [-62.8463362, 46.1546334],
              [-62.8468097, 46.1546631],
              [-62.8472255, 46.1545081],
              [-62.8478367, 46.1544384],
              [-62.8483338, 46.1550258],
              [-62.8485774, 46.1550687],
              [-62.8492629, 46.1549056],
              [-62.8506457, 46.1543808],
              [-62.852063, 46.1542349],
              [-62.8530085, 46.1542226],
              [-62.8531142, 46.1542734],
              [-62.8536089, 46.1543316],
              [-62.8537096, 46.1543703],
              [-62.8545617, 46.1542916],
              [-62.8550621, 46.1541589],
              [-62.8558635, 46.1541622],
              [-62.8563074, 46.1543024],
              [-62.8568635, 46.1545772],
              [-62.8574995, 46.1550453],
              [-62.8575434, 46.1551739],
              [-62.8576839, 46.1552177],
              [-62.8581116, 46.155819],
              [-62.8582871, 46.156425],
              [-62.8582911, 46.1565485],
              [-62.8581858, 46.1566807],
              [-62.8577907, 46.1566812],
              [-62.8575513, 46.1568533],
              [-62.8571285, 46.1576966],
              [-62.8571083, 46.1580342],
              [-62.85793, 46.1576999],
              [-62.8586155, 46.1575367],
              [-62.8596482, 46.157121],
              [-62.8603336, 46.1569578],
              [-62.8606318, 46.1569505],
              [-62.8620761, 46.1575971],
              [-62.8624708, 46.1578911],
              [-62.862462, 46.1583245],
              [-62.8620847, 46.1589821],
              [-62.8614033, 46.1597463],
              [-62.8610522, 46.1604444],
              [-62.8610123, 46.1608253],
              [-62.8614192, 46.1619671],
              [-62.8614223, 46.162202],
              [-62.8613839, 46.1622685],
              [-62.8612931, 46.1627315],
              [-62.8614753, 46.1630352],
              [-62.8615446, 46.1634073],
              [-62.8616454, 46.163446],
              [-62.8620294, 46.1634412],
              [-62.8624627, 46.1632826],
              [-62.8627111, 46.163246],
              [-62.8627678, 46.1630646],
              [-62.8627287, 46.1628566],
              [-62.862783, 46.1627149],
              [-62.8637778, 46.1619797],
              [-62.8646002, 46.1614424],
              [-62.8652988, 46.1605832],
              [-62.8657475, 46.1601664],
              [-62.8661309, 46.1599785],
              [-62.8668962, 46.159917],
              [-62.8673426, 46.1600175],
              [-62.8676286, 46.1601173],
              [-62.8682463, 46.1601311],
              [-62.8691338, 46.1598424],
              [-62.8700184, 46.1597963],
              [-62.8703338, 46.159694],
              [-62.8706786, 46.1594811],
              [-62.8719469, 46.1593386],
              [-62.8728481, 46.1590144],
              [-62.8750001, 46.1579189],
              [-62.8756001, 46.1576134],
              [-62.8761073, 46.1572699],
              [-62.8765219, 46.1571346],
              [-62.8769595, 46.156805],
              [-62.8776435, 46.15657],
              [-62.8779113, 46.1563987],
              [-62.8781076, 46.1559865],
              [-62.8783496, 46.1558662],
              [-62.8789383, 46.1557876],
              [-62.8792931, 46.1555988],
              [-62.8792816, 46.155503],
              [-62.8796537, 46.1553108],
              [-62.8805123, 46.1553156],
              [-62.8810336, 46.1551196],
              [-62.8836162, 46.1537653],
              [-62.8838896, 46.1534031],
              [-62.8839391, 46.1529549],
              [-62.8839041, 46.1528704],
              [-62.8838864, 46.1523964],
              [-62.8844135, 46.1521011],
              [-62.8847288, 46.1519988],
              [-62.885511, 46.1514562],
              [-62.8860649, 46.1506126],
              [-62.8865943, 46.1502777],
              [-62.8868291, 46.1498905],
              [-62.8874121, 46.1496167],
              [-62.8878075, 46.1493217],
              [-62.8878965, 46.149173],
              [-62.8878991, 46.1488389],
              [-62.8879409, 46.1487127],
              [-62.8882111, 46.1485016],
              [-62.8895821, 46.1479719],
              [-62.8900254, 46.1479289],
              [-62.8901995, 46.1479856],
              [-62.8902037, 46.1481091],
              [-62.8906471, 46.148838],
              [-62.8906035, 46.1491869],
              [-62.890549, 46.1492371],
              [-62.8904978, 46.1495221],
              [-62.8908454, 46.1498384],
              [-62.890866, 46.1500698],
              [-62.89036, 46.1510739],
              [-62.8903226, 46.151415],
              [-62.8904024, 46.1515168],
              [-62.8904113, 46.1519468],
              [-62.8902932, 46.1522975],
              [-62.8898713, 46.1528465],
              [-62.8894527, 46.1532443],
              [-62.8893513, 46.1537945],
              [-62.8892485, 46.154273],
              [-62.8891003, 46.1545513],
              [-62.8886154, 46.1555839],
              [-62.8881711, 46.1561242],
              [-62.8878438, 46.1563337],
              [-62.8867863, 46.1570753],
              [-62.8846671, 46.1584351],
              [-62.8842024, 46.1588356],
              [-62.8833304, 46.1593437],
              [-62.8826178, 46.1599639],
              [-62.8823641, 46.1602829],
              [-62.8818993, 46.1610694],
              [-62.8818518, 46.1613863],
              [-62.8811695, 46.1621705],
              [-62.8807344, 46.162938],
              [-62.879565, 46.163728],
              [-62.8794141, 46.1639545],
              [-62.878993, 46.1652555],
              [-62.8787609, 46.1657861],
              [-62.8785257, 46.1660817],
              [-62.878238, 46.1662963],
              [-62.8774929, 46.1664977],
              [-62.8771083, 46.1667055],
              [-62.8767056, 46.1671197],
              [-62.8765355, 46.1675724],
              [-62.8764761, 46.168088],
              [-62.8763295, 46.1685295],
              [-62.8760291, 46.168668],
              [-62.8761364, 46.1687905],
              [-62.8769138, 46.1690079],
              [-62.8777538, 46.1689445],
              [-62.8783444, 46.1690291],
              [-62.8789129, 46.1691967],
              [-62.8792082, 46.169432],
              [-62.8793472, 46.1697901],
              [-62.8792965, 46.1702582],
              [-62.8786812, 46.1710682],
              [-62.8784482, 46.1717102],
              [-62.8784454, 46.1724303],
              [-62.8784303, 46.1729319],
              [-62.8785297, 46.1734019],
              [-62.8788545, 46.1738275],
              [-62.8792353, 46.1741023],
              [-62.8792503, 46.174247],
              [-62.8793705, 46.1743634],
              [-62.8794933, 46.1744007],
              [-62.8802003, 46.1744708],
              [-62.8803346, 46.1744119],
              [-62.8804451, 46.1742317],
              [-62.8806612, 46.1742717],
              [-62.8806393, 46.1746746],
              [-62.880912, 46.1746969],
              [-62.8811855, 46.1744785],
              [-62.8811918, 46.174245],
              [-62.8809922, 46.1740089],
              [-62.881014, 46.1739662],
              [-62.8811256, 46.1739592],
              [-62.8814151, 46.1741375],
              [-62.8816242, 46.1739451],
              [-62.8818457, 46.173621],
              [-62.8817424, 46.1730464],
              [-62.8815726, 46.1728187],
              [-62.8813746, 46.1726816],
              [-62.8814274, 46.1724683],
              [-62.8818801, 46.1721749],
              [-62.882523, 46.171863],
              [-62.8829083, 46.1714523],
              [-62.8829475, 46.1712744],
              [-62.8830722, 46.1710073],
              [-62.8829756, 46.1707061],
              [-62.8832173, 46.1704942],
              [-62.8832565, 46.1703162],
              [-62.8829562, 46.1700689],
              [-62.8829206, 46.1698012],
              [-62.88414, 46.1686544],
              [-62.8845801, 46.167899],
              [-62.884634, 46.1676658],
              [-62.8843995, 46.1658286],
              [-62.8844299, 46.1652207],
              [-62.8849508, 46.1640697],
              [-62.8852814, 46.1637091],
              [-62.886576, 46.162835],
              [-62.8870623, 46.1625545],
              [-62.8874371, 46.162414],
              [-62.8880238, 46.1620807],
              [-62.8886482, 46.1618837],
              [-62.8895305, 46.1618771],
              [-62.8900097, 46.1617158],
              [-62.8906297, 46.1616897],
              [-62.8911669, 46.1618046],
              [-62.892328, 46.1623113],
              [-62.892935, 46.1625038],
              [-62.8936361, 46.1629456],
              [-62.8941121, 46.1633213],
              [-62.894945, 46.1641489],
              [-62.8952794, 46.1648866],
              [-62.8953222, 46.1658071],
              [-62.8951364, 46.166335],
              [-62.8950757, 46.1667789],
              [-62.8952788, 46.166928],
              [-62.8957113, 46.1676526],
              [-62.8958252, 46.1682448],
              [-62.895746, 46.1687121],
              [-62.8959841, 46.1689457],
              [-62.8972247, 46.1689851],
              [-62.897879, 46.169155],
              [-62.8983907, 46.1694123],
              [-62.898828, 46.1700575],
              [-62.8988033, 46.1704746],
              [-62.8984986, 46.1707842],
              [-62.89812, 46.1712788],
              [-62.8979049, 46.1716228],
              [-62.8980397, 46.172152],
              [-62.8984596, 46.1728006],
              [-62.8988717, 46.1729994],
              [-62.8991043, 46.1730379],
              [-62.8994495, 46.1733024],
              [-62.8994281, 46.1735684],
              [-62.8995028, 46.1739525],
              [-62.9001278, 46.174619],
              [-62.9001134, 46.1747658],
              [-62.8999351, 46.1752579],
              [-62.8999631, 46.1760456],
              [-62.8998537, 46.176752],
              [-62.8996385, 46.1773201],
              [-62.899627, 46.1777832],
              [-62.8997322, 46.1782023],
              [-62.8997197, 46.178277],
              [-62.8998016, 46.1784793],
              [-62.8999722, 46.178596],
              [-62.9002372, 46.1787353],
              [-62.9007435, 46.1793242],
              [-62.9007925, 46.1794649],
              [-62.900795, 46.1798112],
              [-62.9005621, 46.1800672],
              [-62.9005613, 46.1801786],
              [-62.9009124, 46.1803438],
              [-62.9009982, 46.1803462],
              [-62.9012725, 46.180553],
              [-62.901442, 46.1806892],
              [-62.9015708, 46.1816121],
              [-62.9009549, 46.1832142],
              [-62.9007601, 46.1836981],
              [-62.9002885, 46.18499],
              [-62.9003485, 46.1854294],
              [-62.9010017, 46.1859136],
              [-62.9010925, 46.1859282],
              [-62.9012691, 46.1859451],
              [-62.9012201, 46.1858044],
              [-62.9009868, 46.1855829],
              [-62.9008691, 46.1853448],
              [-62.9009192, 46.1850796],
              [-62.9015896, 46.1841078],
              [-62.9017675, 46.1834244],
              [-62.902566, 46.1821259],
              [-62.9025373, 46.1810586],
              [-62.9022642, 46.1799373],
              [-62.9021117, 46.1796772],
              [-62.9018849, 46.1792903],
              [-62.9015338, 46.1783389],
              [-62.9013194, 46.17815],
              [-62.9011649, 46.1781202],
              [-62.9010019, 46.1781856],
              [-62.900802, 46.1782316],
              [-62.900444, 46.1781797],
              [-62.9002551, 46.1782272],
              [-62.9001521, 46.1783342],
              [-62.8999633, 46.1782272],
              [-62.8997994, 46.1781936],
              [-62.8996895, 46.1777795],
              [-62.8997425, 46.1777205],
              [-62.8999345, 46.1776939],
              [-62.9000173, 46.1775473],
              [-62.9000609, 46.1774687],
              [-62.9008455, 46.1774585],
              [-62.9009629, 46.1775138],
              [-62.901037, 46.1775085],
              [-62.9012647, 46.1772897],
              [-62.9012849, 46.1770436],
              [-62.9013199, 46.1767421],
              [-62.9015093, 46.1761546],
              [-62.9017464, 46.1757276],
              [-62.9018348, 46.1753959],
              [-62.9018355, 46.1748985],
              [-62.9017371, 46.1741396],
              [-62.9019928, 46.1736893],
              [-62.9021091, 46.1731753],
              [-62.9017222, 46.1727424],
              [-62.9017513, 46.1725403],
              [-62.9019025, 46.1724054],
              [-62.9019283, 46.1719684],
              [-62.9021579, 46.1718635],
              [-62.9033239, 46.1719047],
              [-62.9040988, 46.17207],
              [-62.9043033, 46.1725851],
              [-62.9045203, 46.1727903],
              [-62.9048383, 46.1727396],
              [-62.9053597, 46.1725436],
              [-62.9066448, 46.1725085],
              [-62.9072228, 46.172517],
              [-62.9074662, 46.1724682],
              [-62.908134, 46.1722166],
              [-62.9085124, 46.1720165],
              [-62.9074457, 46.1719424],
              [-62.9068842, 46.1720418],
              [-62.9064913, 46.1720028],
              [-62.9058722, 46.171623],
              [-62.9048392, 46.1712673],
              [-62.9046017, 46.1712168],
              [-62.9042243, 46.1710111],
              [-62.9040144, 46.1700063],
              [-62.9036951, 46.1696908],
              [-62.9039183, 46.1692077],
              [-62.9038874, 46.1685662],
              [-62.903593, 46.1679251],
              [-62.9033471, 46.1676276],
              [-62.9026113, 46.1668984],
              [-62.9012043, 46.1657998],
              [-62.9008838, 46.1655042],
              [-62.9006989, 46.1648543],
              [-62.9001577, 46.1643216],
              [-62.900047, 46.1639643],
              [-62.8998684, 46.1636926],
              [-62.8995418, 46.1634047],
              [-62.8994454, 46.1631951],
              [-62.8994472, 46.1622918],
              [-62.899367, 46.1620986],
              [-62.8989304, 46.1616365],
              [-62.8989278, 46.1615847],
              [-62.898392, 46.1611555],
              [-62.8983079, 46.1609303],
              [-62.8985483, 46.1607382],
              [-62.8987832, 46.160737],
              [-62.8989741, 46.1606071],
              [-62.8992182, 46.160061],
              [-62.8996077, 46.1598652],
              [-62.9004124, 46.1597171],
              [-62.9010872, 46.1597323],
              [-62.9015136, 46.1597843],
              [-62.9021909, 46.1594653],
              [-62.9037278, 46.1590396],
              [-62.9039619, 46.1588553],
              [-62.9039837, 46.1586808],
              [-62.9039061, 46.1585393],
              [-62.9027346, 46.1585975],
              [-62.9022186, 46.1585112],
              [-62.9015489, 46.158508],
              [-62.9010638, 46.1583827],
              [-62.9005333, 46.1580573],
              [-62.8999348, 46.1578174],
              [-62.8992898, 46.157783],
              [-62.8989012, 46.1578675],
              [-62.8982689, 46.1579091],
              [-62.8969517, 46.1575253],
              [-62.8949789, 46.156514],
              [-62.8935989, 46.1555474],
              [-62.8932184, 46.1547209],
              [-62.8931444, 46.1541338],
              [-62.8932775, 46.1538193],
              [-62.8940335, 46.1532361],
              [-62.8946205, 46.1523139],
              [-62.8947218, 46.1517637],
              [-62.8945293, 46.1510499],
              [-62.894708, 46.1506412],
              [-62.8953054, 46.1502206],
              [-62.897021, 46.1494778],
              [-62.8980151, 46.1488338],
              [-62.8986045, 46.1482578],
              [-62.8986374, 46.1480876],
              [-62.8983958, 46.1479136],
              [-62.8981699, 46.1479589],
              [-62.897878, 46.1479585],
              [-62.8974899, 46.147454],
              [-62.8974851, 46.1471475],
              [-62.8978243, 46.146445],
              [-62.8979022, 46.1459976],
              [-62.8975988, 46.1444491],
              [-62.8976439, 46.1434915],
              [-62.8975955, 46.143148],
              [-62.8972417, 46.1428395],
              [-62.8972101, 46.1423094],
              [-62.8967753, 46.141919],
              [-62.8963857, 46.1417288],
              [-62.8958673, 46.1415907],
              [-62.895678, 46.1414062],
              [-62.8954247, 46.1414308],
              [-62.8951207, 46.1415375],
              [-62.8951092, 46.1414417],
              [-62.8956449, 46.1411904],
              [-62.8960334, 46.1407201],
              [-62.8961372, 46.1402217],
              [-62.8964703, 46.1399129],
              [-62.8968522, 46.1393589],
              [-62.8971546, 46.1391805],
              [-62.8974006, 46.1391836],
              [-62.8977599, 46.1393012],
              [-62.8981357, 46.1391409],
              [-62.8984334, 46.138656],
              [-62.8989243, 46.1383875],
              [-62.8990957, 46.1383924],
              [-62.8991511, 46.1386168],
              [-62.8992731, 46.138684],
              [-62.899706, 46.1385252],
              [-62.8998959, 46.1385068],
              [-62.9000311, 46.138447],
              [-62.9007435, 46.1383042],
              [-62.9010066, 46.1385982],
              [-62.9011247, 46.1386334],
              [-62.901876, 46.138607],
              [-62.9022881, 46.1385114],
              [-62.9029129, 46.1382029],
              [-62.9033137, 46.1381029],
              [-62.9040006, 46.1381026],
              [-62.9046124, 46.138307],
              [-62.9059994, 46.1392457],
              [-62.9060478, 46.1395893],
              [-62.9061191, 46.1397385],
              [-62.9062237, 46.1398091],
              [-62.9069788, 46.1401091],
              [-62.9070787, 46.1402592],
              [-62.9073701, 46.1404624],
              [-62.9075017, 46.1404622],
              [-62.907608, 46.14031],
              [-62.9075151, 46.1400408],
              [-62.9072673, 46.1398746],
              [-62.9067176, 46.1396839],
              [-62.906618, 46.1396254],
              [-62.9066139, 46.1395019],
              [-62.9069448, 46.1393243],
              [-62.9072228, 46.1392686],
              [-62.907504, 46.1390617],
              [-62.9075095, 46.1388709],
              [-62.9067582, 46.1375366],
              [-62.9066023, 46.137365],
              [-62.906108, 46.1370128],
              [-62.9050747, 46.136677],
              [-62.9031769, 46.1366389],
              [-62.9020377, 46.1365467],
              [-62.90162, 46.1364473],
              [-62.9011944, 46.1361924],
              [-62.9007696, 46.1358262],
              [-62.9003697, 46.1351345],
              [-62.9002626, 46.1343317],
              [-62.900294, 46.1340899],
              [-62.9009523, 46.1334084],
              [-62.9010954, 46.1331181],
              [-62.9014104, 46.1330157],
              [-62.9019562, 46.1324941],
              [-62.902144, 46.132221],
              [-62.9027638, 46.1319005],
              [-62.903626, 46.1316426],
              [-62.9040244, 46.1315823],
              [-62.9046886, 46.1311875],
              [-62.9050819, 46.1311151],
              [-62.9060358, 46.1311463],
              [-62.9067297, 46.1307323],
              [-62.9076763, 46.1304967],
              [-62.9088738, 46.1295958],
              [-62.9092073, 46.12947],
              [-62.9103592, 46.1293436],
              [-62.9112149, 46.1293878],
              [-62.9115569, 46.1295089],
              [-62.9115761, 46.1296686],
              [-62.9114706, 46.1300038],
              [-62.9114345, 46.130325],
              [-62.9115264, 46.1303197],
              [-62.9117702, 46.1307484],
              [-62.9117488, 46.1310143],
              [-62.9115277, 46.1316605],
              [-62.9117575, 46.1323275],
              [-62.9122732, 46.1330941],
              [-62.9121689, 46.1334094],
              [-62.9119684, 46.1336066],
              [-62.9116668, 46.1339681],
              [-62.9116237, 46.1344085],
              [-62.91167, 46.1344973],
              [-62.9115259, 46.1354879],
              [-62.911661, 46.1357225],
              [-62.9118995, 46.1357531],
              [-62.9122473, 46.1357749],
              [-62.9126281, 46.1356266],
              [-62.9129832, 46.1356208],
              [-62.9133008, 46.1358645],
              [-62.9137097, 46.1359199],
              [-62.9138853, 46.1360482],
              [-62.913829, 46.1366156],
              [-62.9148755, 46.1371187],
              [-62.9152818, 46.1375082],
              [-62.9153263, 46.1384086],
              [-62.9154197, 46.1384749],
              [-62.9157491, 46.1382257],
              [-62.9158897, 46.137975],
              [-62.9159544, 46.1373602],
              [-62.9158195, 46.1368312],
              [-62.9150573, 46.1359701],
              [-62.9149725, 46.1358562],
              [-62.9148801, 46.1350897],
              [-62.914771, 46.1348042],
              [-62.9147371, 46.1344053],
              [-62.9150578, 46.1338176],
              [-62.916559, 46.1328099],
              [-62.917024, 46.1322979],
              [-62.9173132, 46.131952],
              [-62.9178858, 46.1316539],
              [-62.9182047, 46.1315834],
              [-62.9186451, 46.1314884],
              [-62.9188285, 46.1313862],
              [-62.919056, 46.1311181],
              [-62.919605, 46.1308313],
              [-62.9197266, 46.1308069],
              [-62.9198999, 46.1306805],
              [-62.9199331, 46.1306019],
              [-62.9208574, 46.1300631],
              [-62.9212323, 46.1297197],
              [-62.9216393, 46.129023],
              [-62.92171, 46.1286948],
              [-62.9213102, 46.1283891],
              [-62.9214048, 46.1281411],
              [-62.9215719, 46.1280225],
              [-62.9218838, 46.1280711],
              [-62.921956, 46.128109],
              [-62.9224898, 46.1280803],
              [-62.9226716, 46.1279064],
              [-62.9228373, 46.1274218],
              [-62.9228888, 46.1269339],
              [-62.923125, 46.1267098],
              [-62.92322, 46.1265533],
              [-62.9236091, 46.1263575],
              [-62.9246246, 46.1260162],
              [-62.9255854, 46.1256336],
              [-62.9265777, 46.125],
              [-62.9270949, 46.1246697],
              [-62.9271459, 46.1243847],
              [-62.9273215, 46.1242186],
              [-62.9276256, 46.1242033],
              [-62.928357, 46.1245143],
              [-62.9299467, 46.1249212],
              [-62.930078, 46.125],
              [-62.932053, 46.1261861],
              [-62.9324818, 46.1262897],
              [-62.9332896, 46.1261732],
              [-62.9342877, 46.1261297],
              [-62.9348767, 46.1259394],
              [-62.9351198, 46.1255962],
              [-62.9356115, 46.1253076],
              [-62.9358871, 46.1252915],
              [-62.9359288, 46.1254598],
              [-62.936133, 46.1255889],
              [-62.9368621, 46.1256452],
              [-62.9373986, 46.1256682],
              [-62.9381632, 46.1256062],
              [-62.938663, 46.1254731],
              [-62.9388424, 46.1253389],
              [-62.9389952, 46.125],
              [-62.9396967, 46.1245195],
              [-62.9404952, 46.1242674],
              [-62.9412257, 46.1238065],
              [-62.941475, 46.1234555],
              [-62.9416347, 46.1233645],
              [-62.942749, 46.1237817],
              [-62.9443207, 46.1240088],
              [-62.944455, 46.1240603],
              [-62.9449737, 46.1239953],
              [-62.9452774, 46.1238884],
              [-62.9459222, 46.1234251],
              [-62.9468456, 46.123089],
              [-62.9471805, 46.1227403],
              [-62.9474393, 46.1223219],
              [-62.9476592, 46.1220814],
              [-62.9477575, 46.1215709],
              [-62.9478759, 46.1214032],
              [-62.9486153, 46.1212807],
              [-62.9492067, 46.1208477],
              [-62.9494573, 46.1210656],
              [-62.9496534, 46.1213336],
              [-62.950007, 46.1215504],
              [-62.9501078, 46.121589],
              [-62.9505288, 46.1216287],
              [-62.9506609, 46.1217199],
              [-62.9505742, 46.1220317],
              [-62.950575, 46.1230064],
              [-62.9500614, 46.123378],
              [-62.9499368, 46.1235535],
              [-62.9497953, 46.123824],
              [-62.9497643, 46.1243602],
              [-62.9500867, 46.125],
              [-62.9504251, 46.1252023],
              [-62.9506999, 46.1252975],
              [-62.9507702, 46.1254665],
              [-62.9508225, 46.1257505],
              [-62.9512711, 46.1261051],
              [-62.9512916, 46.126245],
              [-62.9511335, 46.1264076],
              [-62.9510911, 46.1266451],
              [-62.951314, 46.1270452],
              [-62.9513399, 46.1272886],
              [-62.9509593, 46.1279344],
              [-62.9511055, 46.1278788],
              [-62.9517051, 46.1273068],
              [-62.9518887, 46.1270016],
              [-62.9520309, 46.1266197],
              [-62.9521745, 46.1265123],
              [-62.9525504, 46.1264433],
              [-62.9531423, 46.1261018],
              [-62.9535915, 46.1260507],
              [-62.9540355, 46.1261904],
              [-62.9541844, 46.1261866],
              [-62.9547095, 46.1264082],
              [-62.9554066, 46.1265231],
              [-62.9556984, 46.1265233],
              [-62.9561673, 46.126429],
              [-62.9572946, 46.125633],
              [-62.9579895, 46.125],
              [-62.9585023, 46.1245328],
              [-62.9594508, 46.1242569],
              [-62.9599798, 46.1240131],
              [-62.9605342, 46.1236267],
              [-62.9609247, 46.1235024],
              [-62.9611326, 46.1233689],
              [-62.9617656, 46.1228097],
              [-62.9619095, 46.1227938],
              [-62.9617342, 46.1232544],
              [-62.9616398, 46.1235024],
              [-62.9615767, 46.1243003],
              [-62.9616459, 46.1244892],
              [-62.9614836, 46.1248228],
              [-62.9615101, 46.125],
              [-62.9615415, 46.1252103],
              [-62.9616633, 46.1254803],
              [-62.9617333, 46.1263495],
              [-62.9619216, 46.1267566],
              [-62.9624352, 46.127674],
              [-62.9627333, 46.128458],
              [-62.9629092, 46.1288807],
              [-62.9631782, 46.129278],
              [-62.9633493, 46.1294857],
              [-62.9635115, 46.1296494],
              [-62.9638054, 46.1298127],
              [-62.964357, 46.1298718],
              [-62.9645698, 46.1299533],
              [-62.9647652, 46.1300383],
              [-62.9649697, 46.1309591],
              [-62.9646879, 46.1314803],
              [-62.9644844, 46.132529],
              [-62.9645715, 46.1328059],
              [-62.9648389, 46.1331316],
              [-62.9643368, 46.1338019],
              [-62.9644599, 46.1343464],
              [-62.9646308, 46.134757],
              [-62.9644642, 46.1349672],
              [-62.9643136, 46.1353967],
              [-62.9644068, 46.1361631],
              [-62.964578, 46.1368681],
              [-62.964724, 46.1375127],
              [-62.964479, 46.1376929],
              [-62.9643475, 46.1379876],
              [-62.9643802, 46.1383148],
              [-62.9646451, 46.1388831],
              [-62.9647264, 46.1392594],
              [-62.9645699, 46.1394937],
              [-62.9646665, 46.1397033],
              [-62.9646249, 46.1398294],
              [-62.9644595, 46.1400198],
              [-62.9642912, 46.1406557],
              [-62.9643146, 46.141639],
              [-62.9644214, 46.1428674],
              [-62.9643626, 46.1432915],
              [-62.9641459, 46.1441727],
              [-62.9638324, 46.1453416],
              [-62.9635057, 46.1463431],
              [-62.9634844, 46.146812],
              [-62.9634869, 46.1470667],
              [-62.9635847, 46.1472564],
              [-62.9636851, 46.1477009],
              [-62.963418, 46.147964],
              [-62.963266, 46.1486162],
              [-62.9629854, 46.1489148],
              [-62.9627422, 46.1489637],
              [-62.9626032, 46.1489917],
              [-62.962341, 46.1489725],
              [-62.9617642, 46.14865],
              [-62.9613154, 46.1485898],
              [-62.9607656, 46.1486938],
              [-62.9599263, 46.1490524],
              [-62.9596302, 46.1492232],
              [-62.9587711, 46.1501223],
              [-62.9584371, 46.1514459],
              [-62.9579054, 46.1522268],
              [-62.9577748, 46.1527046],
              [-62.9578067, 46.1531432],
              [-62.9580361, 46.1538299],
              [-62.9578451, 46.1541628],
              [-62.9572746, 46.1546244],
              [-62.9566901, 46.1550298],
              [-62.9566002, 46.1551984],
              [-62.9562679, 46.1555989],
              [-62.956188, 46.1557917],
              [-62.9560761, 46.1560432],
              [-62.955804, 46.1562942],
              [-62.9551452, 46.1564987],
              [-62.9550498, 46.1565636],
              [-62.9546497, 46.1573443],
              [-62.954595, 46.1583892],
              [-62.9546778, 46.1593345],
              [-62.9545304, 46.1604962],
              [-62.9545113, 46.1609254],
              [-62.9542025, 46.1613147],
              [-62.9541712, 46.1620539],
              [-62.9540785, 46.1621707],
              [-62.9540813, 46.1622225],
              [-62.9539859, 46.1622875],
              [-62.9532786, 46.1622398],
              [-62.9516195, 46.1622134],
              [-62.9512023, 46.1622972],
              [-62.9506845, 46.1626369],
              [-62.950493, 46.1628782],
              [-62.9502618, 46.1634089],
              [-62.9501737, 46.1634462],
              [-62.9501545, 46.163581],
              [-62.9498935, 46.1638363],
              [-62.9490399, 46.1641387],
              [-62.9484192, 46.1645709],
              [-62.9482799, 46.1648018],
              [-62.9480765, 46.1654447],
              [-62.9480825, 46.1659343],
              [-62.9482022, 46.1668329],
              [-62.9484659, 46.1674213],
              [-62.9486453, 46.1677844],
              [-62.9487499, 46.1686468],
              [-62.9490097, 46.1692032],
              [-62.9492718, 46.1694254],
              [-62.9493797, 46.1696393],
              [-62.9493793, 46.1698422],
              [-62.9496105, 46.1701034],
              [-62.9495543, 46.1705793],
              [-62.9493481, 46.1708759],
              [-62.9484775, 46.1715678],
              [-62.9483227, 46.1719654],
              [-62.9488029, 46.1739565],
              [-62.9485473, 46.1743154],
              [-62.9482123, 46.1744612],
              [-62.9473291, 46.1745797],
              [-62.9470118, 46.1745191],
              [-62.9468696, 46.1744037],
              [-62.9468726, 46.1742526],
              [-62.9471168, 46.1740923],
              [-62.9469684, 46.1739847],
              [-62.9467307, 46.1739343],
              [-62.9465448, 46.1737818],
              [-62.9465405, 46.1736584],
              [-62.9466819, 46.1735907],
              [-62.9469594, 46.1736462],
              [-62.9474892, 46.1739913],
              [-62.9479243, 46.1739955],
              [-62.9480417, 46.1738476],
              [-62.9479652, 46.1736863],
              [-62.9476996, 46.1735237],
              [-62.9479457, 46.1732321],
              [-62.9478802, 46.1727807],
              [-62.9478025, 46.1726392],
              [-62.9470508, 46.1721685],
              [-62.9449898, 46.1718561],
              [-62.9446139, 46.1717222],
              [-62.9442154, 46.1712853],
              [-62.9440077, 46.170424],
              [-62.943792, 46.1699961],
              [-62.9433043, 46.1696164],
              [-62.9426119, 46.1693105],
              [-62.9421232, 46.169245],
              [-62.9416576, 46.1690768],
              [-62.941255, 46.1691053],
              [-62.9402269, 46.1690565],
              [-62.9401103, 46.169093],
              [-62.9390787, 46.1691038],
              [-62.9386074, 46.1693294],
              [-62.9382794, 46.169356],
              [-62.9380801, 46.1692389],
              [-62.9379495, 46.1692193],
              [-62.9373765, 46.1692231],
              [-62.9372216, 46.1693262],
              [-62.9372263, 46.1695412],
              [-62.9371614, 46.1697702],
              [-62.9368099, 46.1701025],
              [-62.9367504, 46.1704351],
              [-62.9368466, 46.1705532],
              [-62.9368158, 46.171181],
              [-62.9369193, 46.1712715],
              [-62.9371195, 46.1712771],
              [-62.9375854, 46.1715369],
              [-62.9381554, 46.1716843],
              [-62.9387311, 46.1720267],
              [-62.9388312, 46.1721768],
              [-62.939177, 46.1725327],
              [-62.9392265, 46.1727648],
              [-62.9391226, 46.1728773],
              [-62.9387355, 46.1730336],
              [-62.9384844, 46.1730186],
              [-62.9361316, 46.1716194],
              [-62.9354071, 46.1713722],
              [-62.9349481, 46.1712877],
              [-62.9341634, 46.17119],
              [-62.93376, 46.1710354],
              [-62.9335162, 46.1709927],
              [-62.9334679, 46.1710351],
              [-62.9334618, 46.1713374],
              [-62.9335197, 46.1715221],
              [-62.934138, 46.1725104],
              [-62.9350556, 46.1731768],
              [-62.9357264, 46.1733628],
              [-62.9364038, 46.1736326],
              [-62.9368157, 46.1740341],
              [-62.9368165, 46.1742172],
              [-62.9369823, 46.1743213],
              [-62.9369181, 46.1744389],
              [-62.9365665, 46.1744768],
              [-62.9361156, 46.1742532],
              [-62.9355959, 46.1739322],
              [-62.9354065, 46.1738393],
              [-62.9352983, 46.1738283],
              [-62.9347199, 46.173434],
              [-62.9341905, 46.1731804],
              [-62.9332841, 46.1728126],
              [-62.932752, 46.1725072],
              [-62.9323239, 46.1723837],
              [-62.9313271, 46.1723875],
              [-62.9306115, 46.1724787],
              [-62.9301113, 46.172476],
              [-62.9300312, 46.1725101],
              [-62.9295637, 46.1726785],
              [-62.9291127, 46.1728508],
              [-62.9290416, 46.1729019],
              [-62.9289994, 46.1729737],
              [-62.9290161, 46.1730345],
              [-62.9291328, 46.1730586],
              [-62.9292576, 46.173027],
              [-62.9294769, 46.1730541],
              [-62.9297178, 46.1731556],
              [-62.9301163, 46.1734013],
              [-62.9305227, 46.1735195],
              [-62.9309492, 46.1736544],
              [-62.9312444, 46.1737803],
              [-62.9314639, 46.1738716],
              [-62.9317239, 46.1745196],
              [-62.9319507, 46.1746573],
              [-62.9322243, 46.1746809],
              [-62.9325917, 46.1748623],
              [-62.932657, 46.1750194],
              [-62.9327305, 46.1756263],
              [-62.9329882, 46.1760195],
              [-62.9330991, 46.1763768],
              [-62.9329159, 46.1765706],
              [-62.9328391, 46.1769067],
              [-62.9329809, 46.1772251],
              [-62.932892, 46.1773738],
              [-62.9325999, 46.1773735],
              [-62.9324623, 46.1774731],
              [-62.9324615, 46.177879],
              [-62.9325415, 46.1779807],
              [-62.9326876, 46.178717],
              [-62.9326617, 46.1790625],
              [-62.9324805, 46.1794195],
              [-62.9318653, 46.1797523],
              [-62.9319163, 46.1800562],
              [-62.932353, 46.1801322],
              [-62.9325675, 46.1802854],
              [-62.9326652, 46.1804752],
              [-62.9327278, 46.1805804],
              [-62.9325698, 46.1817181],
              [-62.9327225, 46.1819492],
              [-62.9328299, 46.1817771],
              [-62.9329211, 46.1812942],
              [-62.9331185, 46.1806591],
              [-62.9331858, 46.1800959],
              [-62.933049, 46.1797896],
              [-62.9330899, 46.1794804],
              [-62.9333507, 46.1791336],
              [-62.933804, 46.1787285],
              [-62.9341487, 46.1785154],
              [-62.9345104, 46.1785017],
              [-62.9351268, 46.1787379],
              [-62.9364368, 46.1795547],
              [-62.9371676, 46.1797941],
              [-62.9377991, 46.1800665],
              [-62.938179, 46.1802324],
              [-62.9385921, 46.180614],
              [-62.9389617, 46.1812531],
              [-62.9396184, 46.1818804],
              [-62.9396501, 46.1820246],
              [-62.9405395, 46.1829847],
              [-62.9409494, 46.1840149],
              [-62.9415592, 46.1855482],
              [-62.9418913, 46.1861425],
              [-62.9421851, 46.1865089],
              [-62.9425666, 46.1867464],
              [-62.9429798, 46.1868336],
              [-62.9432906, 46.1868104],
              [-62.9436852, 46.186921],
              [-62.9441216, 46.1871999],
              [-62.9450076, 46.1873361],
              [-62.9452461, 46.1875],
              [-62.9455751, 46.1877261],
              [-62.9460985, 46.1879874],
              [-62.9465775, 46.1883232],
              [-62.9470912, 46.1883575],
              [-62.9473339, 46.18842],
              [-62.9486869, 46.188999],
              [-62.9513878, 46.1895561],
              [-62.9517817, 46.189778],
              [-62.9532379, 46.1908533],
              [-62.9538051, 46.1911517],
              [-62.9543428, 46.1912662],
              [-62.9546408, 46.1911671],
              [-62.955158, 46.1911417],
              [-62.9554506, 46.1912334],
              [-62.956366, 46.191259],
              [-62.9565987, 46.1912973],
              [-62.9571945, 46.1915964],
              [-62.9575208, 46.191701],
              [-62.9580167, 46.1916472],
              [-62.9581284, 46.1915986],
              [-62.9586714, 46.1915222],
              [-62.9606669, 46.1916972],
              [-62.9616751, 46.1919003],
              [-62.9620715, 46.191585],
              [-62.9623374, 46.1915447],
              [-62.963034, 46.1915879],
              [-62.9632365, 46.1915538],
              [-62.963483, 46.1913537],
              [-62.963396, 46.1908737],
              [-62.9637356, 46.1906484],
              [-62.9640205, 46.1906762],
              [-62.9642663, 46.191085],
              [-62.9648642, 46.1915473],
              [-62.9651162, 46.1914508],
              [-62.9652734, 46.191308],
              [-62.9648228, 46.1909731],
              [-62.9645377, 46.1906508],
              [-62.9645073, 46.1902838],
              [-62.9652146, 46.1897424],
              [-62.9654485, 46.1892634],
              [-62.9656988, 46.1890953],
              [-62.9660448, 46.1891566],
              [-62.9665223, 46.1894206],
              [-62.9668132, 46.1896436],
              [-62.9670669, 46.1894158],
              [-62.9670015, 46.1892588],
              [-62.9667072, 46.1890954],
              [-62.9665718, 46.1888609],
              [-62.966091, 46.188951],
              [-62.9659974, 46.1888847],
              [-62.9660395, 46.1883526],
              [-62.9661922, 46.1882892],
              [-62.9666754, 46.1884539],
              [-62.9669065, 46.1884205],
              [-62.9672388, 46.1882228],
              [-62.9673248, 46.1880222],
              [-62.9675616, 46.1878895],
              [-62.9679156, 46.1878118],
              [-62.9683993, 46.1875706],
              [-62.9694038, 46.1875388],
              [-62.9696658, 46.1875],
              [-62.9705133, 46.1873745],
              [-62.9709833, 46.1873717],
              [-62.9713273, 46.1875],
              [-62.9720323, 46.1877629],
              [-62.9721256, 46.1880321],
              [-62.9722355, 46.1881147],
              [-62.9726375, 46.1881975],
              [-62.972924, 46.1882969],
              [-62.9729268, 46.1883487],
              [-62.9724907, 46.188659],
              [-62.9724619, 46.1888611],
              [-62.9726109, 46.1890603],
              [-62.972629, 46.1894428],
              [-62.9724851, 46.1897532],
              [-62.9725215, 46.1898179],
              [-62.9725303, 46.1900648],
              [-62.9723559, 46.1905057],
              [-62.9723173, 46.1909782],
              [-62.9724675, 46.1911574],
              [-62.9724598, 46.191591],
              [-62.9724163, 46.1918484],
              [-62.9720273, 46.1921361],
              [-62.971842, 46.1923697],
              [-62.9714523, 46.1927688],
              [-62.9711601, 46.1927687],
              [-62.9707873, 46.1925753],
              [-62.9700408, 46.1925028],
              [-62.9697024, 46.1927083],
              [-62.9692646, 46.1934443],
              [-62.9690816, 46.1936382],
              [-62.9688671, 46.1937795],
              [-62.9685754, 46.1938709],
              [-62.968625, 46.1941031],
              [-62.9690741, 46.1943663],
              [-62.9693489, 46.1948674],
              [-62.9697513, 46.1952446],
              [-62.9700127, 46.1952837],
              [-62.970631, 46.1950939],
              [-62.9708958, 46.1950734],
              [-62.9709681, 46.1951112],
              [-62.9710599, 46.1953088],
              [-62.9710064, 46.1955421],
              [-62.9702784, 46.1959437],
              [-62.9700997, 46.1962611],
              [-62.9701876, 46.1964267],
              [-62.9703496, 46.1964989],
              [-62.9705279, 46.1965874],
              [-62.9710459, 46.196948],
              [-62.9711377, 46.1971455],
              [-62.9710718, 46.1973944],
              [-62.9706484, 46.1977806],
              [-62.9704423, 46.1980773],
              [-62.9701003, 46.1983424],
              [-62.9696346, 46.1984688],
              [-62.9691382, 46.1984311],
              [-62.9690057, 46.1985429],
              [-62.9689466, 46.1986726],
              [-62.9685614, 46.1989922],
              [-62.9684076, 46.1990755],
              [-62.9670916, 46.2000338],
              [-62.9668854, 46.2003306],
              [-62.9667875, 46.2011357],
              [-62.966381, 46.2014269],
              [-62.9658387, 46.2016864],
              [-62.9654713, 46.202094],
              [-62.9648183, 46.2025853],
              [-62.9637505, 46.2032123],
              [-62.9636903, 46.2033618],
              [-62.9637054, 46.203398],
              [-62.9637901, 46.2034203],
              [-62.9652479, 46.2026888],
              [-62.9672356, 46.2012158],
              [-62.9674464, 46.2008396],
              [-62.9674183, 46.2004329],
              [-62.9674734, 46.2002713],
              [-62.9679309, 46.1999895],
              [-62.9689188, 46.1995553],
              [-62.969303, 46.19955],
              [-62.9697068, 46.1997045],
              [-62.9700535, 46.1996544],
              [-62.9709393, 46.1992014],
              [-62.9714224, 46.1990715],
              [-62.9714158, 46.1989878],
              [-62.9716785, 46.198804],
              [-62.9727459, 46.1983799],
              [-62.9732528, 46.1980358],
              [-62.9737431, 46.1973808],
              [-62.9740379, 46.1971383],
              [-62.9743219, 46.196983],
              [-62.9740758, 46.1967772],
              [-62.9741309, 46.1966156],
              [-62.9744678, 46.1963384],
              [-62.9748859, 46.196143],
              [-62.9753134, 46.1960832],
              [-62.9755429, 46.1959782],
              [-62.9755634, 46.1956206],
              [-62.9757604, 46.1954828],
              [-62.9760497, 46.195634],
              [-62.9760422, 46.1958646],
              [-62.9759455, 46.1961525],
              [-62.9762728, 46.1964401],
              [-62.9766805, 46.1966265],
              [-62.9780958, 46.1966298],
              [-62.9785447, 46.1963955],
              [-62.9791395, 46.1962169],
              [-62.9794184, 46.1960495],
              [-62.9794843, 46.1958006],
              [-62.9795893, 46.1956682],
              [-62.9797746, 46.1954346],
              [-62.9797882, 46.1951962],
              [-62.9797705, 46.1949052],
              [-62.9798721, 46.1948324],
              [-62.9801817, 46.194829],
              [-62.9804546, 46.1951668],
              [-62.9805455, 46.1951813],
              [-62.9809741, 46.1951016],
              [-62.9810685, 46.1941531],
              [-62.9812072, 46.1940336],
              [-62.9816347, 46.1939738],
              [-62.9824379, 46.1939561],
              [-62.982997, 46.1940989],
              [-62.9839367, 46.1940015],
              [-62.9840415, 46.194072],
              [-62.9839942, 46.1942975],
              [-62.9842728, 46.194536],
              [-62.9844708, 46.1945813],
              [-62.9847692, 46.1945736],
              [-62.9858252, 46.1941451],
              [-62.9860649, 46.1938612],
              [-62.9861385, 46.1936762],
              [-62.9863938, 46.1935201],
              [-62.9871321, 46.1934369],
              [-62.9872348, 46.1933442],
              [-62.9871811, 46.1930801],
              [-62.986703, 46.1925218],
              [-62.986618, 46.1922051],
              [-62.9867588, 46.1920458],
              [-62.9868596, 46.1915869],
              [-62.9870038, 46.1913681],
              [-62.987487, 46.1910352],
              [-62.9879055, 46.1909313],
              [-62.9882505, 46.1908095],
              [-62.9884357, 46.1905758],
              [-62.9884162, 46.1904161],
              [-62.9881404, 46.1902294],
              [-62.9879065, 46.1902111],
              [-62.9870898, 46.1902642],
              [-62.9867573, 46.1901675],
              [-62.985696, 46.1895891],
              [-62.9855267, 46.1893417],
              [-62.9856491, 46.1892058],
              [-62.9862134, 46.1891576],
              [-62.9865247, 46.189023],
              [-62.9866797, 46.1887168],
              [-62.9866151, 46.1880426],
              [-62.9866954, 46.1879413],
              [-62.9872604, 46.1875788],
              [-62.9875897, 46.1875],
              [-62.9876031, 46.1874968],
              [-62.987606, 46.1875],
              [-62.9876894, 46.1875907],
              [-62.9876578, 46.1879439],
              [-62.9874474, 46.1881172],
              [-62.9874501, 46.188169],
              [-62.9877378, 46.1882486],
              [-62.9888556, 46.1882396],
              [-62.9900209, 46.1885024],
              [-62.9913837, 46.1885161],
              [-62.9924049, 46.1887949],
              [-62.9929484, 46.1888098],
              [-62.9934403, 46.1887238],
              [-62.993539, 46.1888021],
              [-62.9938217, 46.1886667],
              [-62.993874, 46.1884532],
              [-62.9935394, 46.1879904],
              [-62.9933869, 46.1878509],
              [-62.9933023, 46.1876257],
              [-62.993446, 46.1875182],
              [-62.9935097, 46.1875],
              [-62.9925847, 46.1871183],
              [-62.9920268, 46.1866133],
              [-62.9912544, 46.1863162],
              [-62.990911, 46.1860785],
              [-62.9912544, 46.1854248],
              [-62.9910398, 46.1849494],
              [-62.9906965, 46.1843849],
              [-62.9899669, 46.1839689],
              [-62.9893232, 46.1836421],
              [-62.9885078, 46.1832856],
              [-62.9879499, 46.1827804],
              [-62.9870057, 46.1825724],
              [-62.9867483, 46.182305],
              [-62.9865766, 46.181889],
              [-62.9864478, 46.1811165],
              [-62.9870487, 46.1799279],
              [-62.987392, 46.1798091],
              [-62.9879928, 46.1797794],
              [-62.9879499, 46.1793931],
              [-62.9877782, 46.1787096],
              [-62.9874778, 46.178353],
              [-62.9882074, 46.1778479],
              [-62.9891944, 46.1777587],
              [-62.9893661, 46.177521],
              [-62.9885507, 46.1772833],
              [-62.9876924, 46.1769861],
              [-62.986877, 46.1768078],
              [-62.985332, 46.1763621],
              [-62.9848171, 46.1769267],
              [-62.9840446, 46.1776399],
              [-62.9833579, 46.178353],
              [-62.9826713, 46.1788582],
              [-62.9830146, 46.1790365],
              [-62.9826713, 46.1791553],
              [-62.9821134, 46.1790068],
              [-62.9820276, 46.1785313],
              [-62.9822421, 46.1782639],
              [-62.9824567, 46.178145],
              [-62.9827571, 46.1777587],
              [-62.9836584, 46.1770158],
              [-62.9843021, 46.1760055],
              [-62.9846883, 46.1756192],
              [-62.9849029, 46.1750843],
              [-62.9852033, 46.1744008],
              [-62.9861045, 46.1741333],
              [-62.9867912, 46.1739847],
              [-62.9877782, 46.1738659],
              [-62.989924, 46.1738361],
              [-62.9910827, 46.1736875],
              [-62.9920268, 46.1734795],
              [-62.9927478, 46.1730108],
              [-62.9945941, 46.1730109],
              [-62.9949631, 46.1732674],
              [-62.9979168, 46.174037],
              [-63.0, 46.174034],
              [-63.0007633, 46.1741898],
              [-63.0020595, 46.1743607],
              [-63.0031847, 46.1744154],
              [-63.0047356, 46.1743384],
              [-63.0061216, 46.1743405],
              [-63.0081966, 46.1746161],
              [-63.0098085, 46.1747756],
              [-63.0124773, 46.1747808],
              [-63.0128446, 46.1748704],
              [-63.0136222, 46.174995],
              [-63.0149589, 46.1748564],
              [-63.0162614, 46.1748163],
              [-63.0186521, 46.1750803],
              [-63.0190339, 46.1749117],
              [-63.0193793, 46.1748813],
              [-63.0198065, 46.1750242],
              [-63.020051, 46.1749552],
              [-63.0208247, 46.1750479],
              [-63.0220431, 46.1748741],
              [-63.0225146, 46.1749426],
              [-63.0226806, 46.1750466],
              [-63.0227132, 46.1752823],
              [-63.0228427, 46.1755245],
              [-63.0230379, 46.1757208],
              [-63.02321, 46.17602],
              [-63.0247797, 46.1771369],
              [-63.0247903, 46.1772526],
              [-63.0247124, 46.1773142],
              [-63.0245672, 46.1775529],
              [-63.0247641, 46.1778209],
              [-63.024712, 46.1780344],
              [-63.0244659, 46.1782346],
              [-63.0239355, 46.1787017],
              [-63.0238391, 46.1789895],
              [-63.0238363, 46.1791406],
              [-63.0236216, 46.1793935],
              [-63.0231893, 46.1796443],
              [-63.0229337, 46.1799119],
              [-63.0228793, 46.1799622],
              [-63.0228996, 46.1806193],
              [-63.0226193, 46.181324],
              [-63.0226419, 46.1823472],
              [-63.02243, 46.1828548],
              [-63.022158, 46.183106],
              [-63.0220313, 46.1831185],
              [-63.0218255, 46.1832124],
              [-63.0215462, 46.1832884],
              [-63.0210747, 46.1836257],
              [-63.0208049, 46.1836343],
              [-63.0203569, 46.1839603],
              [-63.0196878, 46.1839382],
              [-63.0195936, 46.1839833],
              [-63.0192465, 46.1839421],
              [-63.0189734, 46.1838073],
              [-63.0189027, 46.1836383],
              [-63.0193928, 46.1834805],
              [-63.0193889, 46.1832456],
              [-63.0189599, 46.1828281],
              [-63.0185668, 46.1827895],
              [-63.0184103, 46.1828211],
              [-63.0178097, 46.1828047],
              [-63.0175938, 46.1826715],
              [-63.0171053, 46.1814804],
              [-63.0171048, 46.1811859],
              [-63.0173038, 46.1810083],
              [-63.017841, 46.1808279],
              [-63.0183563, 46.1805276],
              [-63.0184012, 46.1803418],
              [-63.0183462, 46.1803005],
              [-63.0180631, 46.1803445],
              [-63.0179694, 46.1802783],
              [-63.0180614, 46.1800699],
              [-63.0181477, 46.1799609],
              [-63.0180081, 46.1798974],
              [-63.0174373, 46.1800649],
              [-63.0167531, 46.1800065],
              [-63.0165289, 46.1801237],
              [-63.0164829, 46.1809382],
              [-63.0163427, 46.181189],
              [-63.0163937, 46.1816042],
              [-63.0163539, 46.1818021],
              [-63.0165412, 46.1819346],
              [-63.0164969, 46.1822119],
              [-63.0164997, 46.1826696],
              [-63.0163696, 46.1829446],
              [-63.0160842, 46.1832313],
              [-63.0155021, 46.1835974],
              [-63.0150209, 46.1835962],
              [-63.0135455, 46.1830427],
              [-63.0129735, 46.1830271],
              [-63.0125507, 46.1832105],
              [-63.0124851, 46.1832565],
              [-63.0124946, 46.1833921],
              [-63.0127811, 46.1834914],
              [-63.0131703, 46.183498],
              [-63.0132516, 46.1835798],
              [-63.0132415, 46.1837586],
              [-63.013006, 46.1838716],
              [-63.0120184, 46.1840118],
              [-63.0117806, 46.1841644],
              [-63.0117778, 46.1845185],
              [-63.011909, 46.1848325],
              [-63.0116093, 46.1864836],
              [-63.0117041, 46.18653],
              [-63.0118011, 46.1865366],
              [-63.0120019, 46.1864307],
              [-63.0122302, 46.1865483],
              [-63.0122425, 46.1867357],
              [-63.0120327, 46.1870006],
              [-63.0117517, 46.1870048],
              [-63.0116194, 46.1869137],
              [-63.0114433, 46.1865826],
              [-63.0110659, 46.1862659],
              [-63.011062, 46.186031],
              [-63.0113004, 46.1857669],
              [-63.0113352, 46.185557],
              [-63.0111401, 46.1853606],
              [-63.0108647, 46.1854685],
              [-63.0105018, 46.1861112],
              [-63.0104227, 46.1861926],
              [-63.0099213, 46.1861431],
              [-63.0097654, 46.1862662],
              [-63.0097783, 46.1863421],
              [-63.0101046, 46.1864465],
              [-63.0111034, 46.1871224],
              [-63.0110596, 46.1872884],
              [-63.0109216, 46.1874995],
              [-63.0110501, 46.1875587],
              [-63.0113372, 46.1875466],
              [-63.0115263, 46.1875],
              [-63.0116277, 46.187475],
              [-63.0117384, 46.1875],
              [-63.011888, 46.1875338],
              [-63.0122615, 46.1878186],
              [-63.0124387, 46.1881298],
              [-63.0126417, 46.1881871],
              [-63.0130865, 46.1879207],
              [-63.0135306, 46.1879686],
              [-63.0138817, 46.1880418],
              [-63.0144112, 46.1880005],
              [-63.015382, 46.1877524],
              [-63.0166332, 46.1876114],
              [-63.0168286, 46.1874999],
              [-63.0168996, 46.1874595],
              [-63.016815, 46.1872344],
              [-63.0166517, 46.1871822],
              [-63.0163287, 46.1872211],
              [-63.0162782, 46.1871004],
              [-63.0165508, 46.1869407],
              [-63.0169552, 46.1869835],
              [-63.0172861, 46.1872114],
              [-63.0174431, 46.1874743],
              [-63.0174838, 46.1874999],
              [-63.0176865, 46.1876282],
              [-63.0181178, 46.1876001],
              [-63.018263, 46.1875643],
              [-63.0183379, 46.1874999],
              [-63.0185822, 46.1872904],
              [-63.0185816, 46.1869959],
              [-63.0184812, 46.186643],
              [-63.0185658, 46.1864623],
              [-63.0188485, 46.1863267],
              [-63.0191154, 46.1862663],
              [-63.0199892, 46.1862145],
              [-63.0205046, 46.1861171],
              [-63.0209257, 46.1858619],
              [-63.0214389, 46.1858043],
              [-63.0223266, 46.1856056],
              [-63.0236893, 46.1850099],
              [-63.0243219, 46.1847644],
              [-63.0249819, 46.1847425],
              [-63.0250633, 46.1848243],
              [-63.0247145, 46.1851173],
              [-63.0238436, 46.1856269],
              [-63.0233541, 46.1856733],
              [-63.0231466, 46.1858984],
              [-63.0231702, 46.186293],
              [-63.0233553, 46.1866681],
              [-63.023366, 46.1869867],
              [-63.0229118, 46.1873206],
              [-63.0222366, 46.1875092],
              [-63.0221015, 46.1877721],
              [-63.0219097, 46.1879221],
              [-63.0211066, 46.188143],
              [-63.0209624, 46.188159],
              [-63.0204128, 46.188152],
              [-63.0199944, 46.1884589],
              [-63.0198419, 46.1885224],
              [-63.0191745, 46.1885719],
              [-63.0185155, 46.1887768],
              [-63.017506, 46.189406],
              [-63.0171728, 46.1894208],
              [-63.0167477, 46.189644],
              [-63.0158974, 46.1896845],
              [-63.0155227, 46.1898255],
              [-63.0155306, 46.1898894],
              [-63.0157191, 46.1900019],
              [-63.0157561, 46.1901581],
              [-63.0155805, 46.1903245],
              [-63.0158082, 46.1905535],
              [-63.0159513, 46.1905574],
              [-63.0164336, 46.1901328],
              [-63.0166798, 46.1901355],
              [-63.0169558, 46.1903221],
              [-63.0175094, 46.1903611],
              [-63.0177388, 46.1904588],
              [-63.0178173, 46.1906918],
              [-63.0176373, 46.1909376],
              [-63.0175481, 46.1909948],
              [-63.0175262, 46.1914837],
              [-63.017727, 46.1915807],
              [-63.0179161, 46.1915819],
              [-63.0180647, 46.1914864],
              [-63.018109, 46.1912091],
              [-63.0181909, 46.1911795],
              [-63.0189582, 46.1911884],
              [-63.0191977, 46.1913103],
              [-63.0190552, 46.1916009],
              [-63.0192987, 46.1917548],
              [-63.0197087, 46.1916983],
              [-63.0201989, 46.1917434],
              [-63.0206286, 46.1918466],
              [-63.0214571, 46.1922949],
              [-63.0220836, 46.1922602],
              [-63.0224745, 46.1921356],
              [-63.0229933, 46.1921815],
              [-63.0236284, 46.1927081],
              [-63.023938, 46.1927046],
              [-63.0241298, 46.1925546],
              [-63.024187, 46.1923532],
              [-63.0242935, 46.1922924],
              [-63.024846, 46.1923512],
              [-63.0250749, 46.1923574],
              [-63.0254108, 46.1921914],
              [-63.025408, 46.1921396],
              [-63.025229, 46.1919597],
              [-63.0252346, 46.1918603],
              [-63.025473, 46.1915962],
              [-63.0266149, 46.1912611],
              [-63.0264387, 46.1911329],
              [-63.025783, 46.1910754],
              [-63.0255783, 46.1909464],
              [-63.0256276, 46.1906812],
              [-63.0258093, 46.190507],
              [-63.0264609, 46.1901268],
              [-63.0265096, 46.18977],
              [-63.0263586, 46.1892963],
              [-63.0265352, 46.1889072],
              [-63.0269905, 46.1885534],
              [-63.027161, 46.1885779],
              [-63.0275498, 46.1888989],
              [-63.0281393, 46.1891138],
              [-63.0286363, 46.1892427],
              [-63.0287064, 46.1893202],
              [-63.0287995, 46.1892948],
              [-63.0293263, 46.1896076],
              [-63.0294838, 46.1895561],
              [-63.0295903, 46.1892924],
              [-63.0296773, 46.1892749],
              [-63.0300329, 46.1894715],
              [-63.0302057, 46.1896592],
              [-63.0303511, 46.1900293],
              [-63.030276, 46.1903456],
              [-63.0300288, 46.1905657],
              [-63.0296743, 46.1907551],
              [-63.0292835, 46.1910827],
              [-63.0294288, 46.1912498],
              [-63.0295943, 46.1912622],
              [-63.0304833, 46.1912465],
              [-63.0309882, 46.1914393],
              [-63.0313416, 46.1916756],
              [-63.0313815, 46.1918837],
              [-63.0313373, 46.192161],
              [-63.0311668, 46.1923394],
              [-63.0305353, 46.1925651],
              [-63.0304445, 46.1927536],
              [-63.0308434, 46.1928957],
              [-63.0308211, 46.193496],
              [-63.0309794, 46.1937391],
              [-63.0310355, 46.1937605],
              [-63.0315823, 46.1935126],
              [-63.0316046, 46.1931153],
              [-63.0319798, 46.1928628],
              [-63.031978, 46.1925882],
              [-63.031942, 46.1924122],
              [-63.0324921, 46.1919018],
              [-63.0325626, 46.191462],
              [-63.032848, 46.1911752],
              [-63.0333852, 46.1909948],
              [-63.0337532, 46.1909729],
              [-63.0338732, 46.1908767],
              [-63.0338209, 46.1906842],
              [-63.0334158, 46.190347],
              [-63.0334192, 46.1902874],
              [-63.0331504, 46.1900732],
              [-63.0330084, 46.1898465],
              [-63.0331809, 46.1892225],
              [-63.0334988, 46.1887655],
              [-63.0339619, 46.1884756],
              [-63.0343814, 46.1883516],
              [-63.0348298, 46.1877112],
              [-63.0350378, 46.1875775],
              [-63.035708, 46.1875797],
              [-63.0364384, 46.1878382],
              [-63.0373924, 46.1878878],
              [-63.0377312, 46.1879766],
              [-63.0383139, 46.1879048],
              [-63.0388432, 46.1876604],
              [-63.0390036, 46.1876607],
              [-63.0390479, 46.1877893],
              [-63.0388232, 46.188018],
              [-63.0379305, 46.1884077],
              [-63.0375201, 46.1887787],
              [-63.0375286, 46.1889341],
              [-63.037937, 46.1892117],
              [-63.0390348, 46.1895597],
              [-63.0395819, 46.1899206],
              [-63.0400544, 46.1904865],
              [-63.0402799, 46.1905523],
              [-63.0406029, 46.1903103],
              [-63.0408082, 46.1903278],
              [-63.0408906, 46.1903897],
              [-63.0408694, 46.1905642],
              [-63.0405617, 46.1908424],
              [-63.0404205, 46.1911132],
              [-63.0401973, 46.1912106],
              [-63.0397671, 46.1912189],
              [-63.0365302, 46.1912111],
              [-63.0364276, 46.1913038],
              [-63.0364271, 46.1914152],
              [-63.0365096, 46.1914771],
              [-63.0368383, 46.1915417],
              [-63.0374263, 46.1918879],
              [-63.0376204, 46.1919011],
              [-63.038023, 46.1916692],
              [-63.0384717, 46.1916376],
              [-63.03876, 46.1918085],
              [-63.038754, 46.1920192],
              [-63.0388096, 46.1921521],
              [-63.038997, 46.1922844],
              [-63.0391737, 46.1923012],
              [-63.0395362, 46.1925815],
              [-63.0399883, 46.1926932],
              [-63.0400995, 46.1929588],
              [-63.0404278, 46.1931348],
              [-63.0406667, 46.1931651],
              [-63.0415354, 46.1928981],
              [-63.042066, 46.1928368],
              [-63.0427764, 46.1932499],
              [-63.043384, 46.1935527],
              [-63.0433253, 46.1938854],
              [-63.0433221, 46.194148],
              [-63.0434176, 46.1942858],
              [-63.043752, 46.194454],
              [-63.0437462, 46.1941474],
              [-63.0438757, 46.1939838],
              [-63.0440647, 46.1939849],
              [-63.0442022, 46.1940881],
              [-63.0445483, 46.1941491],
              [-63.0447675, 46.1940198],
              [-63.0447865, 46.193885],
              [-63.0446459, 46.1933241],
              [-63.0447042, 46.1923826],
              [-63.044468, 46.1922011],
              [-63.0440983, 46.1921514],
              [-63.0439333, 46.1920275],
              [-63.0439062, 46.1916926],
              [-63.0439846, 46.1915196],
              [-63.044442, 46.191126],
              [-63.0455358, 46.1903158],
              [-63.0456417, 46.1901635],
              [-63.0459111, 46.189546],
              [-63.046692, 46.189002],
              [-63.0470797, 46.1884195],
              [-63.047351, 46.1882796],
              [-63.0476404, 46.1882277],
              [-63.048362, 46.1879247],
              [-63.0489732, 46.1878536],
              [-63.0496149, 46.187855],
              [-63.0500006, 46.1877181],
              [-63.0500345, 46.1874999],
              [-63.0500363, 46.1874883],
              [-63.0503533, 46.1869397],
              [-63.0505012, 46.1867527],
              [-63.0508091, 46.1866774],
              [-63.0512996, 46.186408],
              [-63.051622, 46.1862774],
              [-63.0520583, 46.1862613],
              [-63.0522569, 46.1863979],
              [-63.0523871, 46.1865287],
              [-63.0527069, 46.1872695],
              [-63.0527299, 46.1874999],
              [-63.052751, 46.1877124],
              [-63.0525832, 46.188257],
              [-63.0525857, 46.1886231],
              [-63.0526534, 46.1890547],
              [-63.0527808, 46.1891337],
              [-63.0528606, 46.1893468],
              [-63.0537725, 46.1899482],
              [-63.0537738, 46.1901313],
              [-63.0535457, 46.1904196],
              [-63.0534389, 46.1907948],
              [-63.0537046, 46.1912715],
              [-63.0537186, 46.1918449],
              [-63.0538253, 46.191987],
              [-63.0542117, 46.1919417],
              [-63.0548395, 46.1920898],
              [-63.0553342, 46.1920553],
              [-63.0559072, 46.1918478],
              [-63.0568416, 46.1912203],
              [-63.057039, 46.1911738],
              [-63.0576144, 46.1916469],
              [-63.0579651, 46.1918313],
              [-63.0580657, 46.1919813],
              [-63.0582036, 46.191973],
              [-63.0592631, 46.1924988],
              [-63.0595908, 46.1925832],
              [-63.0601358, 46.1929837],
              [-63.0609, 46.1932549],
              [-63.061321, 46.1935168],
              [-63.0616634, 46.1937488],
              [-63.0621554, 46.1938654],
              [-63.0626482, 46.1940736],
              [-63.0630225, 46.1942467],
              [-63.0637837, 46.1942631],
              [-63.0642625, 46.1941008],
              [-63.0647246, 46.1940335],
              [-63.0649867, 46.1941639],
              [-63.0653228, 46.1942007],
              [-63.0656953, 46.1943022],
              [-63.0660151, 46.1943227],
              [-63.0662484, 46.1942493],
              [-63.0666735, 46.1942288],
              [-63.0674079, 46.194316],
              [-63.0679211, 46.1942581],
              [-63.0684164, 46.1942828],
              [-63.0685646, 46.1943464],
              [-63.0687934, 46.1943185],
              [-63.0691948, 46.1944112],
              [-63.069737, 46.1944258],
              [-63.0701385, 46.1945184],
              [-63.0702846, 46.1945201],
              [-63.0711692, 46.1945834],
              [-63.0717875, 46.1942815],
              [-63.0721695, 46.1937983],
              [-63.0724553, 46.193603],
              [-63.073097, 46.1936041],
              [-63.0736991, 46.1938032],
              [-63.0743913, 46.1942394],
              [-63.0749196, 46.194532],
              [-63.0758792, 46.1947963],
              [-63.0766392, 46.1948324],
              [-63.0769655, 46.1947336],
              [-63.0775607, 46.1943316],
              [-63.0778343, 46.1941518],
              [-63.07856, 46.1940836],
              [-63.0790366, 46.1939609],
              [-63.0795733, 46.1938916],
              [-63.0799618, 46.1941208],
              [-63.0802011, 46.1943539],
              [-63.0801999, 46.1946882],
              [-63.0799814, 46.1949092],
              [-63.0795383, 46.195359],
              [-63.0794982, 46.1955157],
              [-63.0795331, 46.1956613],
              [-63.0799269, 46.1964199],
              [-63.0798583, 46.1967284],
              [-63.0799641, 46.1968904],
              [-63.0803425, 46.1970679],
              [-63.0805394, 46.1971603],
              [-63.0805483, 46.1975187],
              [-63.0806551, 46.1976608],
              [-63.0805602, 46.1979289],
              [-63.080425, 46.1981378],
              [-63.0802634, 46.1983229],
              [-63.0804988, 46.1985241],
              [-63.0811789, 46.1986616],
              [-63.0813816, 46.1988301],
              [-63.0816133, 46.1992023],
              [-63.0818042, 46.199275],
              [-63.0822913, 46.1990651],
              [-63.0825742, 46.1991323],
              [-63.082966, 46.1993019],
              [-63.0833974, 46.1992735],
              [-63.0838364, 46.1991061],
              [-63.0840352, 46.1989283],
              [-63.0843953, 46.1985279],
              [-63.0849318, 46.1982556],
              [-63.0851952, 46.1981759],
              [-63.0868897, 46.1976628],
              [-63.0872306, 46.1975087],
              [-63.0875207, 46.1972338],
              [-63.0876529, 46.1971219],
              [-63.0877826, 46.1968468],
              [-63.087803, 46.1962664],
              [-63.0880428, 46.1960738],
              [-63.0885447, 46.1960114],
              [-63.089026, 46.1960122],
              [-63.0895283, 46.1958385],
              [-63.0898948, 46.1956333],
              [-63.0907738, 46.1949641],
              [-63.0911841, 46.1947959],
              [-63.0919504, 46.1948241],
              [-63.0920895, 46.1947959],
              [-63.0922398, 46.194772],
              [-63.0926012, 46.1948691],
              [-63.0930274, 46.1948287],
              [-63.0931995, 46.1947218],
              [-63.0934185, 46.1945923],
              [-63.0939541, 46.1945427],
              [-63.0953299, 46.1944278],
              [-63.0960386, 46.1942515],
              [-63.0965249, 46.19395],
              [-63.0966499, 46.1938657],
              [-63.0976431, 46.1932634],
              [-63.0984988, 46.1927446],
              [-63.0990032, 46.192531],
              [-63.0992846, 46.1924151],
              [-63.0996014, 46.1923836],
              [-63.1002018, 46.1925108],
              [-63.1014443, 46.1925276],
              [-63.1021233, 46.1926847],
              [-63.1023913, 46.1929186],
              [-63.1024598, 46.1932387],
              [-63.1025029, 46.1934985],
              [-63.1027637, 46.19376],
              [-63.1035854, 46.1940323],
              [-63.1037846, 46.1940574],
              [-63.1059072, 46.1946424],
              [-63.1068477, 46.1948382],
              [-63.107421, 46.1948333],
              [-63.1076705, 46.1947762],
              [-63.1079569, 46.1949797],
              [-63.1081008, 46.195082],
              [-63.1090189, 46.1952692],
              [-63.110054, 46.1953695],
              [-63.1106247, 46.1954545],
              [-63.1114099, 46.195531],
              [-63.1119835, 46.1955903],
              [-63.1123533, 46.1956144],
              [-63.1125168, 46.1956367],
              [-63.1131842, 46.1954345],
              [-63.1136238, 46.1951154],
              [-63.1147924, 46.1946868],
              [-63.1160146, 46.1944196],
              [-63.1161674, 46.1942805],
              [-63.1170304, 46.1942527],
              [-63.1178559, 46.1941172],
              [-63.1183891, 46.1941814],
              [-63.1189149, 46.1943105],
              [-63.1191037, 46.1944228],
              [-63.119055, 46.1946882],
              [-63.1187814, 46.1949795],
              [-63.1187765, 46.1952818],
              [-63.1187353, 46.195408],
              [-63.1189266, 46.1956836],
              [-63.1188902, 46.1958848],
              [-63.1188613, 46.196044],
              [-63.1191411, 46.196485],
              [-63.1192502, 46.1965873],
              [-63.1192549, 46.1967108],
              [-63.1188342, 46.1970448],
              [-63.1186707, 46.1972656],
              [-63.1184093, 46.1980417],
              [-63.1180877, 46.1986898],
              [-63.1181458, 46.1990972],
              [-63.1184578, 46.199368],
              [-63.1186575, 46.1995961],
              [-63.1189997, 46.1998338],
              [-63.1190987, 46.200029],
              [-63.1190195, 46.2002619],
              [-63.1195142, 46.2006145],
              [-63.1195984, 46.2006967],
              [-63.1195535, 46.2008326],
              [-63.1194945, 46.2011282],
              [-63.1197535, 46.2016859],
              [-63.1204137, 46.2020891],
              [-63.1210774, 46.2023213],
              [-63.1211832, 46.2024833],
              [-63.1207408, 46.2028219],
              [-63.1206203, 46.2030914],
              [-63.120493, 46.2033765],
              [-63.1198866, 46.2038859],
              [-63.1197846, 46.2041817],
              [-63.1198859, 46.2052747],
              [-63.1200723, 46.2055382],
              [-63.1205603, 46.2058401],
              [-63.1208376, 46.2060118],
              [-63.1219284, 46.206295],
              [-63.1232322, 46.2064563],
              [-63.1237574, 46.2064577],
              [-63.1242523, 46.206459],
              [-63.1248949, 46.2065513],
              [-63.1250001, 46.2065952],
              [-63.1254443, 46.2067805],
              [-63.1256374, 46.2068134],
              [-63.125715, 46.2068631],
              [-63.1261017, 46.2071319],
              [-63.1260643, 46.2074659],
              [-63.1260243, 46.2078223],
              [-63.1259605, 46.2083658],
              [-63.1258064, 46.2086721],
              [-63.125, 46.209099],
              [-63.1249036, 46.20915],
              [-63.1244274, 46.2094759],
              [-63.1235814, 46.2097523],
              [-63.1227678, 46.2104872],
              [-63.1219363, 46.2114484],
              [-63.1214654, 46.2121007],
              [-63.1212684, 46.2123503],
              [-63.1209478, 46.2128919],
              [-63.1204798, 46.2136827],
              [-63.1195752, 46.2144033],
              [-63.1184603, 46.214983],
              [-63.1177545, 46.2155257],
              [-63.117349, 46.216132],
              [-63.1169554, 46.218],
              [-63.11691, 46.2185201],
              [-63.1167356, 46.2190926],
              [-63.1162222, 46.2198911],
              [-63.1156814, 46.220243],
              [-63.1153387, 46.2203256],
              [-63.1148614, 46.2202455],
              [-63.1143185, 46.219897],
              [-63.1136872, 46.2196974],
              [-63.1132022, 46.219442],
              [-63.1126958, 46.2188438],
              [-63.1122997, 46.2186424],
              [-63.1120127, 46.2185433],
              [-63.1108626, 46.2185013],
              [-63.1100053, 46.2183475],
              [-63.1093682, 46.2180443],
              [-63.1089692, 46.2177911],
              [-63.1086727, 46.217445],
              [-63.1086382, 46.2170263],
              [-63.1085425, 46.2168885],
              [-63.1075988, 46.2162151],
              [-63.1068273, 46.2157491],
              [-63.1061407, 46.2156197],
              [-63.1048759, 46.215686],
              [-63.104165, 46.2154763],
              [-63.1033545, 46.2154112],
              [-63.1025723, 46.2154583],
              [-63.1020567, 46.2155562],
              [-63.1020839, 46.2156883],
              [-63.1024281, 46.2157888],
              [-63.1031477, 46.2158396],
              [-63.1036031, 46.2156884],
              [-63.1040621, 46.2156805],
              [-63.1046263, 46.2160575],
              [-63.1051546, 46.216147],
              [-63.1058999, 46.2161466],
              [-63.106414, 46.21618],
              [-63.1067264, 46.2163394],
              [-63.1068797, 46.2166817],
              [-63.107223, 46.2171165],
              [-63.107231, 46.2171804],
              [-63.1072318, 46.2175863],
              [-63.1069026, 46.2182622],
              [-63.105617, 46.2194462],
              [-63.1057573, 46.2195851],
              [-63.1059345, 46.2196177],
              [-63.1059885, 46.2196788],
              [-63.1059345, 46.2199321],
              [-63.105931, 46.2201031],
              [-63.1063725, 46.220839],
              [-63.1063796, 46.2215515],
              [-63.1065046, 46.2217817],
              [-63.1067322, 46.221919],
              [-63.1072914, 46.2219694],
              [-63.1075331, 46.2221628],
              [-63.1076891, 46.2226683],
              [-63.1076269, 46.2232836],
              [-63.1075824, 46.2234694],
              [-63.1078414, 46.2243995],
              [-63.1078766, 46.2245954],
              [-63.1081892, 46.2253836],
              [-63.1083317, 46.2256102],
              [-63.1085029, 46.226152],
              [-63.108262, 46.2263646],
              [-63.1077554, 46.2265065],
              [-63.1074097, 46.2265372],
              [-63.1068538, 46.2267415],
              [-63.1065296, 46.2271151],
              [-63.1060473, 46.2273372],
              [-63.1053635, 46.227574],
              [-63.1051153, 46.2278142],
              [-63.1050639, 46.2282307],
              [-63.104814, 46.2287137],
              [-63.1043411, 46.2291828],
              [-63.1035326, 46.2297068],
              [-63.1026919, 46.2300867],
              [-63.1021747, 46.2303159],
              [-63.101559, 46.2306698],
              [-63.1012702, 46.2311279],
              [-63.1012442, 46.2316048],
              [-63.1014466, 46.2323105],
              [-63.1018732, 46.2328988],
              [-63.1029238, 46.2338259],
              [-63.1031558, 46.2341981],
              [-63.1038986, 46.2347748],
              [-63.1040422, 46.2349815],
              [-63.1040169, 46.2352356],
              [-63.1037136, 46.2354346],
              [-63.1034173, 46.2354531],
              [-63.1032491, 46.2353762],
              [-63.1030117, 46.2351465],
              [-63.1027848, 46.2348872],
              [-63.1026489, 46.2347659],
              [-63.1023494, 46.234368],
              [-63.1015638, 46.2337344],
              [-63.1013797, 46.2334311],
              [-63.1010969, 46.2332525],
              [-63.0995608, 46.233236],
              [-63.0990831, 46.2332672],
              [-63.0981868, 46.2334028],
              [-63.0978008, 46.2335399],
              [-63.0974637, 46.2337261],
              [-63.0966391, 46.2344367],
              [-63.0964724, 46.2347586],
              [-63.0964677, 46.2352639],
              [-63.0969151, 46.2361035],
              [-63.0973182, 46.2363888],
              [-63.0973747, 46.2366131],
              [-63.0973744, 46.2367246],
              [-63.0972337, 46.236884],
              [-63.0966451, 46.2370556],
              [-63.0956439, 46.2376381],
              [-63.0951393, 46.2380547],
              [-63.0946829, 46.2384287],
              [-63.093557, 46.2396129],
              [-63.0930846, 46.240488],
              [-63.092825, 46.2413528],
              [-63.0926107, 46.2418088],
              [-63.0922558, 46.2421099],
              [-63.0918932, 46.2422356],
              [-63.0893065, 46.2427524],
              [-63.0877157, 46.242786],
              [-63.0868786, 46.2428833],
              [-63.0860205, 46.2431551],
              [-63.084349, 46.2440303],
              [-63.0835644, 46.24432],
              [-63.082497, 46.244431],
              [-63.0820655, 46.2446624],
              [-63.0814388, 46.2454179],
              [-63.0807244, 46.2457851],
              [-63.0802135, 46.2458949],
              [-63.0792329, 46.2457853],
              [-63.0785165, 46.2455633],
              [-63.0758747, 46.2445778],
              [-63.0752692, 46.2445299],
              [-63.0742312, 46.2447331],
              [-63.0732787, 46.2447357],
              [-63.0728981, 46.2447733],
              [-63.0696572, 46.2447667],
              [-63.06929, 46.2447689],
              [-63.0677438, 46.2446162],
              [-63.0666969, 46.244461],
              [-63.0656244, 46.2440423],
              [-63.064295, 46.2433939],
              [-63.0636277, 46.2427035],
              [-63.0635286, 46.2424222],
              [-63.0637187, 46.2422005],
              [-63.0639595, 46.2420994],
              [-63.0647955, 46.2415048],
              [-63.0654018, 46.2413299],
              [-63.0657862, 46.2411213],
              [-63.0658353, 46.2408559],
              [-63.065752, 46.2407025],
              [-63.0657493, 46.2403362],
              [-63.0662172, 46.2394492],
              [-63.066172, 46.2387117],
              [-63.0653737, 46.2393511],
              [-63.0650814, 46.2395543],
              [-63.0644664, 46.2398881],
              [-63.0640657, 46.2400804],
              [-63.0635795, 46.2406764],
              [-63.0630902, 46.2410175],
              [-63.0624844, 46.2410809],
              [-63.0616021, 46.2409579],
              [-63.0612651, 46.2408295],
              [-63.0604423, 46.2406682],
              [-63.0593684, 46.2403808],
              [-63.0590987, 46.240278],
              [-63.0578502, 46.2394168],
              [-63.0570355, 46.238802],
              [-63.0568496, 46.2387413],
              [-63.0565681, 46.2385427],
              [-63.0565091, 46.2384695],
              [-63.0561058, 46.2382955],
              [-63.0553175, 46.2377212],
              [-63.0550001, 46.2375495],
              [-63.0545122, 46.2375563],
              [-63.0543214, 46.2376865],
              [-63.0540782, 46.2376243],
              [-63.054004, 46.2375148],
              [-63.054062, 46.2370905],
              [-63.0543469, 46.2367121],
              [-63.0544695, 46.2362617],
              [-63.0543295, 46.2359952],
              [-63.0540373, 46.2356809],
              [-63.0535655, 46.2355011],
              [-63.0518789, 46.2355077],
              [-63.051734, 46.2354321],
              [-63.0517371, 46.2351695],
              [-63.0514225, 46.2348467],
              [-63.0514149, 46.2344684],
              [-63.0514675, 46.2343464],
              [-63.0517694, 46.2340759],
              [-63.0524665, 46.2339155],
              [-63.0528049, 46.2337097],
              [-63.0529378, 46.2334864],
              [-63.0529697, 46.2327071],
              [-63.0523057, 46.2322714],
              [-63.0519846, 46.2322708],
              [-63.0519914, 46.2323546],
              [-63.0522257, 46.2325758],
              [-63.0526256, 46.2328094],
              [-63.052632, 46.2330045],
              [-63.0524599, 46.2333143],
              [-63.0520435, 46.2335818],
              [-63.0513144, 46.233801],
              [-63.050353, 46.2342767],
              [-63.0498974, 46.2346306],
              [-63.0497758, 46.2348582],
              [-63.0497832, 46.2350335],
              [-63.0498946, 46.2352992],
              [-63.0502992, 46.2358593],
              [-63.050856, 46.2362643],
              [-63.051531, 46.2365013],
              [-63.0519443, 46.2364964],
              [-63.0525779, 46.2361393],
              [-63.052853, 46.2361427],
              [-63.053062, 46.2363035],
              [-63.0531246, 46.2367231],
              [-63.0530217, 46.2371303],
              [-63.0530432, 46.2373617],
              [-63.0534677, 46.2373612],
              [-63.053698, 46.2375504],
              [-63.0537191, 46.2378933],
              [-63.0535419, 46.238191],
              [-63.0537758, 46.2385236],
              [-63.0538284, 46.2389191],
              [-63.0539301, 46.2390491],
              [-63.0545557, 46.2391455],
              [-63.0548039, 46.2392198],
              [-63.0551153, 46.2396023],
              [-63.0551571, 46.239882],
              [-63.054963, 46.2400718],
              [-63.0545218, 46.2401675],
              [-63.0542957, 46.2404161],
              [-63.0542963, 46.2405077],
              [-63.0538547, 46.2414352],
              [-63.0533593, 46.2419871],
              [-63.053172, 46.2422607],
              [-63.0531862, 46.2425197],
              [-63.0536236, 46.2423922],
              [-63.0543134, 46.2420564],
              [-63.0546491, 46.2420017],
              [-63.0550481, 46.2422552],
              [-63.0554289, 46.2424206],
              [-63.0560006, 46.2424558],
              [-63.0565558, 46.2422717],
              [-63.0568596, 46.2422758],
              [-63.0570085, 46.2423833],
              [-63.057101, 46.2427838],
              [-63.0569496, 46.2430305],
              [-63.0568579, 46.243442],
              [-63.056966, 46.2437672],
              [-63.0568782, 46.2442107],
              [-63.0565549, 46.2449702],
              [-63.0561938, 46.2462023],
              [-63.0561154, 46.2470958],
              [-63.0563916, 46.2475967],
              [-63.0563469, 46.2477826],
              [-63.0559754, 46.2480672],
              [-63.0559143, 46.2482367],
              [-63.05612, 46.2484571],
              [-63.056185, 46.248837],
              [-63.0561171, 46.2489227],
              [-63.056119, 46.2491974],
              [-63.0562854, 46.2493014],
              [-63.056724, 46.2493569],
              [-63.0570138, 46.2493049],
              [-63.0575635, 46.2492202],
              [-63.0582387, 46.2492541],
              [-63.0586881, 46.2494254],
              [-63.0589746, 46.249433],
              [-63.0589823, 46.2492939],
              [-63.0588294, 46.2491545],
              [-63.0587422, 46.2489691],
              [-63.0588398, 46.2488642],
              [-63.0591969, 46.248838],
              [-63.0600963, 46.249069],
              [-63.0606663, 46.2490325],
              [-63.0611773, 46.2489227],
              [-63.0623988, 46.2489315],
              [-63.0624775, 46.2489614],
              [-63.0640165, 46.2486244],
              [-63.0645597, 46.2486588],
              [-63.0650838, 46.248828],
              [-63.0657065, 46.2491869],
              [-63.0666667, 46.25],
              [-63.0664847, 46.2503659],
              [-63.0668373, 46.2512509],
              [-63.0672624, 46.2516563],
              [-63.0675552, 46.2518591],
              [-63.0677507, 46.2518524],
              [-63.0689558, 46.2521591],
              [-63.0695415, 46.2525648],
              [-63.069624, 46.2529411],
              [-63.0695234, 46.2533086],
              [-63.0687949, 46.2538226],
              [-63.0685565, 46.2540869],
              [-63.0678858, 46.2544909],
              [-63.0673201, 46.2549654],
              [-63.0668065, 46.2552264],
              [-63.0662053, 46.2556163],
              [-63.0659167, 46.2561659],
              [-63.0658786, 46.25675],
              [-63.0656657, 46.2572777],
              [-63.0656007, 46.2574153],
              [-63.0654994, 46.2582086],
              [-63.0655554, 46.2585445],
              [-63.0657456, 46.2588401],
              [-63.0657442, 46.2591744],
              [-63.0654703, 46.2593542],
              [-63.0649374, 46.2600644],
              [-63.0646432, 46.2607133],
              [-63.0642157, 46.2611796],
              [-63.0641114, 46.2619211],
              [-63.0641277, 46.2624549],
              [-63.0637874, 46.2625892],
              [-63.0631835, 46.2626129],
              [-63.0626045, 46.2628084],
              [-63.0621174, 46.2627954],
              [-63.0617198, 46.262928],
              [-63.0613332, 46.262862],
              [-63.0603771, 46.262924],
              [-63.0593264, 46.2628283],
              [-63.0590657, 46.2627696],
              [-63.0579741, 46.2626886],
              [-63.0566671, 46.2624547],
              [-63.0547504, 46.2619376],
              [-63.0542334, 46.2617407],
              [-63.0537567, 46.2614374],
              [-63.0535332, 46.2608144],
              [-63.0531526, 46.2605375],
              [-63.0519857, 46.2603669],
              [-63.0516469, 46.2603698],
              [-63.0509362, 46.2602512],
              [-63.0500654, 46.2602239],
              [-63.0495912, 46.2600838],
              [-63.0492613, 46.2599276],
              [-63.0487771, 46.2599664],
              [-63.048225, 46.2600909],
              [-63.0480643, 46.2600906],
              [-63.0471538, 46.2602611],
              [-63.046722, 46.2604923],
              [-63.0462474, 46.2606667],
              [-63.0457662, 46.2609602],
              [-63.0456669, 46.2611964],
              [-63.0455588, 46.2615915],
              [-63.0454364, 46.2617276],
              [-63.0454989, 46.2619442],
              [-63.0454884, 46.2622345],
              [-63.045666, 46.2623427],
              [-63.0463014, 46.2623718],
              [-63.0462414, 46.2625214],
              [-63.0452771, 46.2630368],
              [-63.0450544, 46.2634289],
              [-63.0448039, 46.2635973],
              [-63.0445533, 46.2635627],
              [-63.0440891, 46.2632437],
              [-63.0440293, 46.2628759],
              [-63.0438381, 46.2626001],
              [-63.0432415, 46.262596],
              [-63.0430902, 46.2623252],
              [-63.042835, 46.2621671],
              [-63.0422666, 46.2622752],
              [-63.0417225, 46.2619461],
              [-63.0414859, 46.2617646],
              [-63.0413112, 46.2617081],
              [-63.0412455, 46.2617541],
              [-63.0412518, 46.2619493],
              [-63.0405829, 46.2623135],
              [-63.0404511, 46.2627199],
              [-63.0407233, 46.263189],
              [-63.0417462, 46.2630613],
              [-63.0423109, 46.2631242],
              [-63.0428364, 46.2634767],
              [-63.0431051, 46.2638024],
              [-63.04358, 46.2640341],
              [-63.043852, 46.2640971],
              [-63.0445183, 46.2640872],
              [-63.0448567, 46.2643988],
              [-63.0449575, 46.2647518],
              [-63.045256, 46.2650584],
              [-63.0453167, 46.2659238],
              [-63.0449732, 46.2665236],
              [-63.0446715, 46.2690471],
              [-63.0445267, 46.2693776],
              [-63.0442812, 46.2702785],
              [-63.0432543, 46.2724244],
              [-63.0425299, 46.2732647],
              [-63.0422493, 46.2739697],
              [-63.0422144, 46.2746972],
              [-63.0423157, 46.2749388],
              [-63.0429343, 46.2757834],
              [-63.0430254, 46.2757978],
              [-63.0436674, 46.2762251],
              [-63.0441519, 46.2763894],
              [-63.0445696, 46.2766195],
              [-63.0461482, 46.2766222],
              [-63.0465341, 46.2763937],
              [-63.0465872, 46.2759572],
              [-63.0468338, 46.2757568],
              [-63.0472125, 46.2757591],
              [-63.0473166, 46.2758494],
              [-63.047453, 46.27649],
              [-63.0472183, 46.2767862],
              [-63.0469144, 46.2769851],
              [-63.0464435, 46.2769883],
              [-63.0460148, 46.2771599],
              [-63.0457855, 46.2771538],
              [-63.0453551, 46.2772537],
              [-63.043718, 46.2779899],
              [-63.0431595, 46.2783252],
              [-63.0431562, 46.2783848],
              [-63.0428366, 46.2786588],
              [-63.0422463, 46.279256],
              [-63.0414739, 46.2798244],
              [-63.040908, 46.2799843],
              [-63.0398404, 46.2803895],
              [-63.0396611, 46.2804125],
              [-63.0395982, 46.2805103],
              [-63.0387814, 46.2811531],
              [-63.0385847, 46.2811797],
              [-63.037229, 46.2809879],
              [-63.0368703, 46.2808309],
              [-63.0368613, 46.2807869],
              [-63.0369505, 46.2805265],
              [-63.0369915, 46.2805117],
              [-63.0370999, 46.2803196],
              [-63.0370801, 46.2799568],
              [-63.0367468, 46.2798602],
              [-63.035784, 46.2797268],
              [-63.0331509, 46.2796876],
              [-63.0328126, 46.2795789],
              [-63.031799, 46.2789186],
              [-63.031169, 46.2787901],
              [-63.0306294, 46.2787875],
              [-63.0301175, 46.2790085],
              [-63.0293409, 46.2791388],
              [-63.029002, 46.2793446],
              [-63.0284783, 46.2794697],
              [-63.0274792, 46.2800836],
              [-63.0271431, 46.2801382],
              [-63.0270155, 46.2800591],
              [-63.0270891, 46.279874],
              [-63.0270295, 46.2797092],
              [-63.0268069, 46.2795837],
              [-63.0262219, 46.2796753],
              [-63.0254344, 46.2792838],
              [-63.0253293, 46.2792133],
              [-63.0248066, 46.2791155],
              [-63.0244317, 46.2789422],
              [-63.0242862, 46.278978],
              [-63.024465, 46.2794725],
              [-63.0244054, 46.2797137],
              [-63.0241987, 46.2799191],
              [-63.0244635, 46.2804159],
              [-63.0243855, 46.2810866],
              [-63.0241394, 46.2813785],
              [-63.0244322, 46.2815815],
              [-63.0250505, 46.2818172],
              [-63.0256795, 46.2823716],
              [-63.0263013, 46.2827507],
              [-63.0265885, 46.28285],
              [-63.0272146, 46.2829466],
              [-63.0274502, 46.2831481],
              [-63.0276544, 46.2837029],
              [-63.0279332, 46.2840528],
              [-63.0286073, 46.2844214],
              [-63.029047, 46.2851777],
              [-63.0290354, 46.2856909],
              [-63.0288062, 46.2860908],
              [-63.0283864, 46.2865094],
              [-63.0275023, 46.2870149],
              [-63.0271697, 46.287416],
              [-63.0255503, 46.2875194],
              [-63.0247707, 46.2878008],
              [-63.024407, 46.2880378],
              [-63.0234487, 46.2884338],
              [-63.0230586, 46.2888333],
              [-63.0218597, 46.2898279],
              [-63.0211105, 46.2903848],
              [-63.0205883, 46.2909877],
              [-63.0200307, 46.291709],
              [-63.0188406, 46.2927476],
              [-63.0182611, 46.293552],
              [-63.0182195, 46.2936783],
              [-63.0177883, 46.2940925],
              [-63.0177377, 46.2943778],
              [-63.0173037, 46.2949433],
              [-63.0170586, 46.2954183],
              [-63.0170147, 46.2955843],
              [-63.0167663, 46.295916],
              [-63.0158183, 46.2962246],
              [-63.0156677, 46.2962484],
              [-63.0141896, 46.2962838],
              [-63.013606, 46.2962441],
              [-63.0132844, 46.2960402],
              [-63.0128566, 46.2953797],
              [-63.0128184, 46.2950402],
              [-63.013004, 46.2946034],
              [-63.0129933, 46.2940816],
              [-63.0128196, 46.2938022],
              [-63.0127246, 46.2935527],
              [-63.0128686, 46.2930391],
              [-63.0126814, 46.2929982],
              [-63.0123823, 46.293006],
              [-63.0111664, 46.2922682],
              [-63.0106571, 46.2921349],
              [-63.0098667, 46.2918944],
              [-63.00958, 46.2916836],
              [-63.0095159, 46.2915982],
              [-63.009344, 46.2911875],
              [-63.0093423, 46.2909127],
              [-63.0097764, 46.2903473],
              [-63.0103116, 46.2898205],
              [-63.0107367, 46.289211],
              [-63.0108008, 46.2886873],
              [-63.0106941, 46.288139],
              [-63.0105558, 46.2879441],
              [-63.0105592, 46.2878845],
              [-63.009597, 46.2868272],
              [-63.0094127, 46.2866351],
              [-63.009451, 46.2863654],
              [-63.00965, 46.2862992],
              [-63.0100761, 46.2860759],
              [-63.0102694, 46.285906],
              [-63.0103369, 46.2857287],
              [-63.010378, 46.2849018],
              [-63.0103241, 46.2848406],
              [-63.0100891, 46.2849337],
              [-63.0096085, 46.2850042],
              [-63.0087908, 46.2847431],
              [-63.0083057, 46.28469],
              [-63.0076178, 46.2844682],
              [-63.0068786, 46.2844401],
              [-63.0061682, 46.2842097],
              [-63.0060626, 46.2842506],
              [-63.0059788, 46.2842085],
              [-63.0048148, 46.2841807],
              [-63.0044883, 46.2839648],
              [-63.0045474, 46.283632],
              [-63.0049252, 46.2832482],
              [-63.0050068, 46.282924],
              [-63.0049721, 46.282322],
              [-63.0048457, 46.2820199],
              [-63.0042253, 46.2816208],
              [-63.0036882, 46.2807662],
              [-63.0033511, 46.2804345],
              [-63.0026161, 46.2800323],
              [-63.0020919, 46.2796596],
              [-63.0010838, 46.2792141],
              [-63.0000001, 46.279055],
              [-62.9995934, 46.2789703],
              [-62.9992872, 46.2788027],
              [-62.9992873, 46.2785996],
              [-62.9995957, 46.2785245],
              [-62.9998285, 46.2782681],
              [-62.9998397, 46.2767597],
              [-62.9998976, 46.2766498],
              [-62.9998091, 46.2759866],
              [-62.9997248, 46.2758529],
              [-62.9994642, 46.275591],
              [-62.9991181, 46.2754183],
              [-62.9990676, 46.2752975],
              [-62.9988737, 46.2751728],
              [-62.9985697, 46.2751684],
              [-62.9984843, 46.2750546],
              [-62.9986654, 46.2749003],
              [-62.9985957, 46.2747113],
              [-62.9983963, 46.274483],
              [-62.9982716, 46.2744557],
              [-62.9979705, 46.2744672],
              [-62.9979091, 46.2744696],
              [-62.9977478, 46.2745806],
              [-62.9976241, 46.2747365],
              [-62.9977544, 46.2750705],
              [-62.9978295, 46.2753631],
              [-62.998649, 46.2763967],
              [-62.9987309, 46.2767731],
              [-62.9986219, 46.2768736],
              [-62.9986662, 46.2770022],
              [-62.9984835, 46.2772878],
              [-62.9977195, 46.2778999],
              [-62.9977115, 46.278039],
              [-62.9978216, 46.2783246],
              [-62.9977075, 46.2784131],
              [-62.9973702, 46.2784874],
              [-62.9969942, 46.2787399],
              [-62.9971409, 46.2788872],
              [-62.9976685, 46.2789972],
              [-62.9978375, 46.2795591],
              [-62.9992725, 46.2803707],
              [-62.9998389, 46.2807087],
              [-62.9999349, 46.2809382],
              [-62.9999331, 46.2814756],
              [-62.9998369, 46.2816521],
              [-62.9994406, 46.2818562],
              [-62.9990763, 46.2820015],
              [-62.9986081, 46.2820564],
              [-62.9979055, 46.2822959],
              [-62.997412, 46.2822903],
              [-62.997268, 46.2826009],
              [-62.996353, 46.2838457],
              [-62.9964113, 46.2840305],
              [-62.9967968, 46.2843197],
              [-62.9964718, 46.2845815],
              [-62.9964683, 46.2848441],
              [-62.9967054, 46.2851174],
              [-62.9967485, 46.2854689],
              [-62.9966, 46.2856559],
              [-62.9961722, 46.2858074],
              [-62.995777, 46.2859916],
              [-62.9959362, 46.286824],
              [-62.9958384, 46.2869288],
              [-62.9957782, 46.2870785],
              [-62.9958826, 46.2872605],
              [-62.9965969, 46.2877259],
              [-62.9968852, 46.2878055],
              [-62.9973012, 46.2877611],
              [-62.9975671, 46.2876291],
              [-62.9982096, 46.2875392],
              [-62.99833, 46.287443],
              [-62.9983851, 46.2872813],
              [-62.9983516, 46.2868623],
              [-62.9981655, 46.2868015],
              [-62.9978181, 46.2870547],
              [-62.9977242, 46.2869885],
              [-62.9976984, 46.2868365],
              [-62.9975096, 46.2867238],
              [-62.9972763, 46.2866856],
              [-62.9966141, 46.2868187],
              [-62.9964073, 46.286821],
              [-62.9962269, 46.2866608],
              [-62.9960798, 46.2862189],
              [-62.9961676, 46.2860899],
              [-62.9965477, 46.2858694],
              [-62.9970744, 46.2857963],
              [-62.9978719, 46.2860092],
              [-62.998031, 46.2859379],
              [-62.9979682, 46.2856297],
              [-62.998174, 46.2854442],
              [-62.9985529, 46.2852436],
              [-62.9984889, 46.2849552],
              [-62.998747, 46.2847593],
              [-62.9988464, 46.2849292],
              [-62.9990499, 46.2849865],
              [-62.9996547, 46.2848518],
              [-63.0000001, 46.285012],
              [-63.000287, 46.2851438],
              [-63.0004336, 46.2852911],
              [-63.0007656, 46.2858137],
              [-63.0009145, 46.2859213],
              [-63.0017407, 46.286338],
              [-63.0018621, 46.2864249],
              [-63.0025853, 46.2871374],
              [-63.002797, 46.2877563],
              [-63.0034013, 46.287733],
              [-63.0042344, 46.2874213],
              [-63.0048848, 46.2873953],
              [-63.0055047, 46.2877028],
              [-63.0058262, 46.2879067],
              [-63.0058953, 46.2880041],
              [-63.005965, 46.2879901],
              [-63.0072128, 46.288466],
              [-63.0076872, 46.2888094],
              [-63.0076832, 46.2889805],
              [-63.0073166, 46.2893686],
              [-63.0071216, 46.2894668],
              [-63.007076, 46.2895611],
              [-63.0062197, 46.2901786],
              [-63.0058453, 46.2905028],
              [-63.0053741, 46.290912],
              [-63.0051835, 46.2911337],
              [-63.0049219, 46.2915923],
              [-63.0048533, 46.2917895],
              [-63.0046133, 46.2937894],
              [-63.0043623, 46.2947699],
              [-63.0031829, 46.2962186],
              [-63.0030851, 46.2963235],
              [-63.0026362, 46.2971472],
              [-63.002452, 46.2980619],
              [-63.0023348, 46.2989106],
              [-63.0022217, 46.2993853],
              [-63.0017166, 46.2999846],
              [-63.001226, 46.3007316],
              [-63.0006222, 46.3019532],
              [-63.0000001, 46.302736],
              [-62.9996001, 46.303259],
              [-62.9987992, 46.3036034],
              [-62.9983942, 46.3038551],
              [-62.9978864, 46.3039964],
              [-62.9973499, 46.303934],
              [-62.9968939, 46.3037702],
              [-62.9965714, 46.3033831],
              [-62.9963437, 46.3032455],
              [-62.9960744, 46.3032342],
              [-62.9956323, 46.3035326],
              [-62.9951066, 46.3033828],
              [-62.99483, 46.3031961],
              [-62.9945742, 46.3031492],
              [-62.9943785, 46.3031558],
              [-62.9937575, 46.3035688],
              [-62.9936079, 46.3035726],
              [-62.992872, 46.3032817],
              [-62.9923383, 46.3032711],
              [-62.9920053, 46.303469],
              [-62.9917984, 46.3034712],
              [-62.9913672, 46.3032763],
              [-62.9914134, 46.3030705],
              [-62.991237, 46.3029422],
              [-62.9909715, 46.3029628],
              [-62.9902917, 46.3028964],
              [-62.9895432, 46.3030271],
              [-62.9881959, 46.3039854],
              [-62.9881642, 46.3041358],
              [-62.9883614, 46.304404],
              [-62.9883432, 46.304722],
              [-62.9881576, 46.3047528],
              [-62.987795, 46.3045636],
              [-62.9875369, 46.3045566],
              [-62.9872906, 46.3046453],
              [-62.9866624, 46.3046798],
              [-62.9865624, 46.3046213],
              [-62.986383, 46.3037405],
              [-62.986385, 46.3034061],
              [-62.9867317, 46.3029697],
              [-62.9865762, 46.3025753],
              [-62.9864048, 46.3024591],
              [-62.9862102, 46.3024458],
              [-62.9857375, 46.302174],
              [-62.9854903, 46.3018766],
              [-62.9851255, 46.3015241],
              [-62.9850131, 46.3014812],
              [-62.9845827, 46.3011748],
              [-62.9845511, 46.3006245],
              [-62.9842636, 46.3000273],
              [-62.984171, 46.2997381],
              [-62.9841047, 46.2996925],
              [-62.9838141, 46.2996527],
              [-62.9837672, 46.2999699],
              [-62.983644, 46.3000143],
              [-62.9832264, 46.2997838],
              [-62.9822518, 46.2993509],
              [-62.981909, 46.2991185],
              [-62.9820359, 46.2986084],
              [-62.9818304, 46.2981847],
              [-62.9816911, 46.2980097],
              [-62.9817408, 46.2977443],
              [-62.9817903, 46.297682],
              [-62.981796, 46.2975826],
              [-62.9816078, 46.2973584],
              [-62.9812004, 46.2971521],
              [-62.9807796, 46.2967782],
              [-62.980174, 46.2968212],
              [-62.9800476, 46.2967222],
              [-62.9802361, 46.2965403],
              [-62.9807629, 46.2964672],
              [-62.9810852, 46.2963567],
              [-62.9814806, 46.2961725],
              [-62.9823023, 46.2957652],
              [-62.9826493, 46.2956234],
              [-62.9837816, 46.2948066],
              [-62.9841115, 46.2942623],
              [-62.9842679, 46.2941392],
              [-62.9844596, 46.2941007],
              [-62.9858283, 46.2920642],
              [-62.9858727, 46.2919898],
              [-62.9860862, 46.2913706],
              [-62.986005, 46.2908827],
              [-62.9860631, 46.2905698],
              [-62.9863416, 46.2902192],
              [-62.9858447, 46.2893695],
              [-62.9849947, 46.2886693],
              [-62.9845249, 46.2884493],
              [-62.9840736, 46.288409],
              [-62.9834634, 46.2887346],
              [-62.9831745, 46.2887665],
              [-62.9824327, 46.2884833],
              [-62.9812682, 46.2881606],
              [-62.9809946, 46.2880256],
              [-62.9790856, 46.2873679],
              [-62.97846, 46.2873625],
              [-62.9783031, 46.287394],
              [-62.9783204, 46.2875936],
              [-62.9789658, 46.2880533],
              [-62.9799291, 46.2882789],
              [-62.9815072, 46.2888001],
              [-62.9821541, 46.2888339],
              [-62.9824835, 46.2888987],
              [-62.9830482, 46.2891651],
              [-62.9841221, 46.2888642],
              [-62.9845196, 46.2888433],
              [-62.9849394, 46.289034],
              [-62.9853248, 46.2893233],
              [-62.9855955, 46.2896094],
              [-62.9856746, 46.2899341],
              [-62.9852645, 46.2910773],
              [-62.9850608, 46.291426],
              [-62.9849113, 46.2921306],
              [-62.9843723, 46.2927169],
              [-62.9840855, 46.292912],
              [-62.9835808, 46.2933997],
              [-62.9827826, 46.2939988],
              [-62.9824521, 46.2939539],
              [-62.9823003, 46.2939975],
              [-62.9822938, 46.2944113],
              [-62.9821486, 46.2945387],
              [-62.9819, 46.2946672],
              [-62.9809918, 46.2949806],
              [-62.9798313, 46.2951875],
              [-62.9793705, 46.2955092],
              [-62.9787696, 46.2954727],
              [-62.9783167, 46.2958583],
              [-62.9775875, 46.2958541],
              [-62.97739, 46.295992],
              [-62.9773763, 46.2962305],
              [-62.9775364, 46.2963424],
              [-62.9777186, 46.2963713],
              [-62.9781912, 46.2966431],
              [-62.978695, 46.2971706],
              [-62.978601, 46.2973074],
              [-62.9786031, 46.2974707],
              [-62.9787133, 46.2975533],
              [-62.9792002, 46.2974752],
              [-62.9793368, 46.2975984],
              [-62.9792923, 46.2976728],
              [-62.9788271, 46.297871],
              [-62.9786302, 46.2981004],
              [-62.9786717, 46.2981772],
              [-62.97868, 46.2983327],
              [-62.9781637, 46.2985215],
              [-62.9781585, 46.2987125],
              [-62.9783574, 46.2988493],
              [-62.9783877, 46.2989218],
              [-62.9778712, 46.2993137],
              [-62.977444, 46.2991506],
              [-62.9772107, 46.2991123],
              [-62.9771162, 46.2991575],
              [-62.9771206, 46.299281],
              [-62.9773532, 46.2994308],
              [-62.9773588, 46.2995344],
              [-62.9774476, 46.2995886],
              [-62.9777474, 46.2994695],
              [-62.9780672, 46.2996018],
              [-62.9782276, 46.3000083],
              [-62.9784192, 46.3001728],
              [-62.9787099, 46.3002127],
              [-62.979045, 46.3001782],
              [-62.9792119, 46.3003739],
              [-62.979356, 46.300764],
              [-62.9799188, 46.3015678],
              [-62.9799647, 46.3017682],
              [-62.9803775, 46.3015805],
              [-62.9805772, 46.3016059],
              [-62.9808317, 46.3018757],
              [-62.9809389, 46.3021096],
              [-62.9813896, 46.3024644],
              [-62.9814883, 46.3027458],
              [-62.9818579, 46.3033134],
              [-62.9819369, 46.303638],
              [-62.9818491, 46.303767],
              [-62.9815695, 46.3039345],
              [-62.9811348, 46.3040021],
              [-62.9805619, 46.3038748],
              [-62.9799326, 46.3039291],
              [-62.9789837, 46.3041458],
              [-62.9784166, 46.3044169],
              [-62.9782496, 46.3044242],
              [-62.977732, 46.3041352],
              [-62.9772069, 46.3040769],
              [-62.9767404, 46.304295],
              [-62.9763544, 46.3041171],
              [-62.9762397, 46.3041139],
              [-62.9760893, 46.3042292],
              [-62.9760639, 46.3043718],
              [-62.9762004, 46.304495],
              [-62.9762452, 46.3047152],
              [-62.9761051, 46.3048547],
              [-62.9758621, 46.3048838],
              [-62.9755175, 46.3047827],
              [-62.9751673, 46.304578],
              [-62.9750389, 46.3048133],
              [-62.9750403, 46.3050881],
              [-62.9747302, 46.3053861],
              [-62.9747819, 46.305487],
              [-62.9749987, 46.305712],
              [-62.9753529, 46.3064464],
              [-62.9753877, 46.3066424],
              [-62.9753066, 46.3066521],
              [-62.9749642, 46.3065113],
              [-62.9745678, 46.3065123],
              [-62.9740468, 46.3067806],
              [-62.9740579, 46.3069879],
              [-62.9733895, 46.3072203],
              [-62.9731624, 46.3071742],
              [-62.9729442, 46.3071721],
              [-62.9721926, 46.3074539],
              [-62.9718135, 46.3074514],
              [-62.9708712, 46.3072541],
              [-62.9695689, 46.3071225],
              [-62.9687535, 46.3071159],
              [-62.9683033, 46.3073503],
              [-62.967663, 46.3071972],
              [-62.9669336, 46.3071929],
              [-62.9665463, 46.3075325],
              [-62.9658734, 46.3078443],
              [-62.9659282, 46.3080887],
              [-62.9663722, 46.3083598],
              [-62.9674595, 46.3086328],
              [-62.9677259, 46.3087955],
              [-62.9677656, 46.3090036],
              [-62.9675763, 46.309297],
              [-62.9673235, 46.3095965],
              [-62.9669864, 46.3097624],
              [-62.9662896, 46.3097908],
              [-62.9660388, 46.309756],
              [-62.9646784, 46.3097342],
              [-62.9642518, 46.3094596],
              [-62.9639471, 46.3093636],
              [-62.9636596, 46.3094671],
              [-62.9636383, 46.3099363],
              [-62.9636974, 46.3103042],
              [-62.9635026, 46.3104939],
              [-62.9630779, 46.3105857],
              [-62.9625554, 46.3105791],
              [-62.9622558, 46.3104952],
              [-62.9622076, 46.3103346],
              [-62.9620009, 46.3101337],
              [-62.9612366, 46.3101364],
              [-62.9608192, 46.3102004],
              [-62.9602703, 46.3101533],
              [-62.9599686, 46.3099061],
              [-62.9600174, 46.3091629],
              [-62.9599128, 46.3091839],
              [-62.9595582, 46.3093532],
              [-62.9592477, 46.3098542],
              [-62.9591531, 46.310397],
              [-62.9592334, 46.3104988],
              [-62.9598295, 46.3105233],
              [-62.960002, 46.3106197],
              [-62.96003, 46.3107319],
              [-62.9606449, 46.3110277],
              [-62.9611795, 46.3112218],
              [-62.9613622, 46.3113423],
              [-62.9613739, 46.3114382],
              [-62.9615604, 46.3115907],
              [-62.9618045, 46.3115417],
              [-62.9621611, 46.3115357],
              [-62.962388, 46.3117849],
              [-62.9629803, 46.3117774],
              [-62.9631854, 46.3119065],
              [-62.9631406, 46.312184],
              [-62.962835, 46.3124025],
              [-62.9625842, 46.3123676],
              [-62.9624036, 46.3124104],
              [-62.9623224, 46.3125],
              [-62.9621642, 46.3126745],
              [-62.9618019, 46.3127799],
              [-62.961485, 46.312994],
              [-62.9611896, 46.3130335],
              [-62.9606527, 46.3128793],
              [-62.9605132, 46.3129072],
              [-62.9601196, 46.3132546],
              [-62.9596995, 46.3132669],
              [-62.9595235, 46.3132301],
              [-62.9591882, 46.3132646],
              [-62.9588368, 46.3135773],
              [-62.958694, 46.3139597],
              [-62.9586977, 46.3141947],
              [-62.9584555, 46.3144069],
              [-62.9580978, 46.3144328],
              [-62.9580495, 46.3147699],
              [-62.9581535, 46.3148604],
              [-62.9587189, 46.3147209],
              [-62.9587744, 46.3148538],
              [-62.9582646, 46.3154209],
              [-62.9580913, 46.3159337],
              [-62.9581592, 46.3160511],
              [-62.958152, 46.3163734],
              [-62.9580602, 46.3164704],
              [-62.9576288, 46.3164783],
              [-62.9574871, 46.3168407],
              [-62.9572585, 46.3170174],
              [-62.9567802, 46.3171395],
              [-62.9565151, 46.3172516],
              [-62.9556065, 46.3178594],
              [-62.9545701, 46.3188857],
              [-62.954091, 46.3196169],
              [-62.9540049, 46.3196145],
              [-62.953809, 46.3193264],
              [-62.9536722, 46.3194062],
              [-62.9536732, 46.319884],
              [-62.9531572, 46.3204079],
              [-62.9526773, 46.3207477],
              [-62.9524802, 46.321328],
              [-62.9523427, 46.3215192],
              [-62.9519539, 46.3223764],
              [-62.9517679, 46.3226101],
              [-62.9517695, 46.3226819],
              [-62.9512405, 46.3234754],
              [-62.9504583, 46.3239792],
              [-62.9497921, 46.3244663],
              [-62.9492766, 46.3249297],
              [-62.9488583, 46.3251052],
              [-62.9483746, 46.3254182],
              [-62.948047, 46.3255166],
              [-62.9476217, 46.3255167],
              [-62.9473778, 46.3253625],
              [-62.9470905, 46.3244705],
              [-62.9463483, 46.323672],
              [-62.9462559, 46.3234195],
              [-62.9461822, 46.3234387],
              [-62.9460586, 46.3235379],
              [-62.9462549, 46.3242123],
              [-62.9469324, 46.3256089],
              [-62.9469255, 46.3260228],
              [-62.9464142, 46.3263151],
              [-62.9463157, 46.3263283],
              [-62.9444919, 46.3276431],
              [-62.9443093, 46.3278172],
              [-62.9442035, 46.3281527],
              [-62.9441517, 46.3285494],
              [-62.9430306, 46.3294419],
              [-62.9421477, 46.3295008],
              [-62.9419961, 46.3296359],
              [-62.9418942, 46.3300034],
              [-62.9419001, 46.3301987],
              [-62.9420932, 46.330435],
              [-62.942089, 46.3306061],
              [-62.9418793, 46.3308511],
              [-62.9418064, 46.3310163],
              [-62.9414206, 46.3312245],
              [-62.9414017, 46.331451],
              [-62.9415122, 46.3316253],
              [-62.9411478, 46.331862],
              [-62.941026, 46.331978],
              [-62.9411362, 46.3323554],
              [-62.9406148, 46.3329182],
              [-62.9406122, 46.3334557],
              [-62.9401698, 46.3341402],
              [-62.9401718, 46.3343035],
              [-62.9403805, 46.3346677],
              [-62.9405008, 46.3350693],
              [-62.9399949, 46.3357599],
              [-62.9394194, 46.3362615],
              [-62.9392159, 46.3364987],
              [-62.93868, 46.3369137],
              [-62.9378594, 46.337281],
              [-62.9376977, 46.3373919],
              [-62.9353453, 46.3383932],
              [-62.9345374, 46.3385417],
              [-62.9329497, 46.3392458],
              [-62.9315562, 46.339577],
              [-62.9313145, 46.3396777],
              [-62.9297891, 46.3401008],
              [-62.9284014, 46.3403326],
              [-62.927488, 46.3403188],
              [-62.9266697, 46.3400569],
              [-62.9264518, 46.3398517],
              [-62.9263568, 46.3398052],
              [-62.9261384, 46.339803],
              [-62.9257723, 46.3399679],
              [-62.9254752, 46.3399357],
              [-62.9252588, 46.3398021],
              [-62.925094, 46.3397696],
              [-62.9242764, 46.3399855],
              [-62.9240367, 46.3399549],
              [-62.9236865, 46.3397499],
              [-62.9234794, 46.339752],
              [-62.9230958, 46.3399204],
              [-62.9227436, 46.3398468],
              [-62.9223197, 46.3399185],
              [-62.9217574, 46.3397035],
              [-62.9210508, 46.339596],
              [-62.9204256, 46.3395704],
              [-62.9199242, 46.3392974],
              [-62.9194735, 46.3392369],
              [-62.9187809, 46.3388908],
              [-62.9177365, 46.3388574],
              [-62.9166358, 46.3390971],
              [-62.9161494, 46.339155],
              [-62.9140936, 46.3398933],
              [-62.91372, 46.3400859],
              [-62.9132847, 46.3404479],
              [-62.9126218, 46.341353],
              [-62.9122826, 46.3420363],
              [-62.9109989, 46.3441065],
              [-62.9109481, 46.3441887],
              [-62.9103613, 46.3447774],
              [-62.909601, 46.3456758],
              [-62.9086936, 46.3466297],
              [-62.9081475, 46.347112],
              [-62.907509, 46.3478944],
              [-62.9063882, 46.3488582],
              [-62.9055389, 46.3497022],
              [-62.9046226, 46.3503174],
              [-62.9036979, 46.3506854],
              [-62.902693, 46.3509516],
              [-62.9000594, 46.3512352],
              [-62.8987176, 46.3511691],
              [-62.8973713, 46.3508879],
              [-62.895808, 46.3502899],
              [-62.8962299, 46.3499634],
              [-62.8962284, 46.3498917],
              [-62.8957345, 46.3495909],
              [-62.8956321, 46.349572],
              [-62.8954027, 46.3496571],
              [-62.8954862, 46.349994],
              [-62.8952356, 46.3500506],
              [-62.8950855, 46.3499627],
              [-62.8950112, 46.3497614],
              [-62.8946599, 46.3495762],
              [-62.8937677, 46.3492043],
              [-62.8925483, 46.3484329],
              [-62.8913758, 46.3478419],
              [-62.8894774, 46.3469833],
              [-62.8874873, 46.3459269],
              [-62.8869239, 46.3457316],
              [-62.886399, 46.3454697],
              [-62.885677, 46.3452341],
              [-62.8852908, 46.3450558],
              [-62.884349, 46.3447461],
              [-62.8840939, 46.3445875],
              [-62.8839987, 46.3444494],
              [-62.8839463, 46.3440735],
              [-62.8839218, 46.34381],
              [-62.8840481, 46.3435229],
              [-62.8843964, 46.3431785],
              [-62.8847367, 46.3430647],
              [-62.8852131, 46.3425965],
              [-62.8852809, 46.3423276],
              [-62.8851416, 46.3420608],
              [-62.8845285, 46.3423141],
              [-62.8836656, 46.3428987],
              [-62.8833786, 46.3432768],
              [-62.8830439, 46.3435858],
              [-62.882387, 46.3438019],
              [-62.8819014, 46.3437482],
              [-62.8816706, 46.3437615],
              [-62.8811642, 46.3442488],
              [-62.8805842, 46.344718],
              [-62.8788097, 46.3459296],
              [-62.8782275, 46.3465302],
              [-62.8770194, 46.3473079],
              [-62.8766959, 46.3476212],
              [-62.8766748, 46.3480706],
              [-62.8765439, 46.3481425],
              [-62.8760631, 46.3480093],
              [-62.8754718, 46.3480879],
              [-62.875, 46.3483014],
              [-62.8742306, 46.3486496],
              [-62.8729999, 46.3494187],
              [-62.8728569, 46.3495978],
              [-62.8721169, 46.3500465],
              [-62.8718402, 46.350154],
              [-62.8709221, 46.3503108],
              [-62.8704775, 46.3506286],
              [-62.8701658, 46.3507431],
              [-62.8695869, 46.3508061],
              [-62.869241, 46.3507245],
              [-62.8685288, 46.3504212],
              [-62.868201, 46.350133],
              [-62.8675248, 46.3493251],
              [-62.867527, 46.3491937],
              [-62.8676691, 46.3491261],
              [-62.867809, 46.3491899],
              [-62.8681496, 46.3491678],
              [-62.8681494, 46.3490762],
              [-62.8675174, 46.3488748],
              [-62.8671334, 46.3485651],
              [-62.8669814, 46.3482222],
              [-62.8669673, 46.3480745],
              [-62.867078, 46.3479542],
              [-62.8669292, 46.3478464],
              [-62.8669261, 46.3476113],
              [-62.8667847, 46.3474758],
              [-62.8664027, 46.347421],
              [-62.8655573, 46.3467516],
              [-62.8655082, 46.3466108],
              [-62.8656116, 46.3465182],
              [-62.8659622, 46.3465203],
              [-62.8660729, 46.3464],
              [-62.8658656, 46.3463104],
              [-62.865269, 46.3462853],
              [-62.8650241, 46.3461508],
              [-62.8647973, 46.3458097],
              [-62.86485, 46.3445489],
              [-62.8651551, 46.3433948],
              [-62.8655549, 46.3426736],
              [-62.8655493, 46.3415225],
              [-62.865369, 46.3404063],
              [-62.8654013, 46.3403475],
              [-62.8651152, 46.3397499],
              [-62.8644814, 46.3392936],
              [-62.8642016, 46.339166],
              [-62.8635984, 46.3389655],
              [-62.862308, 46.3388168],
              [-62.861474, 46.3386294],
              [-62.8607209, 46.3383409],
              [-62.8600779, 46.3377458],
              [-62.8594043, 46.3378953],
              [-62.859303, 46.3377112],
              [-62.8600869, 46.3374723],
              [-62.8597876, 46.3358888],
              [-62.8596192, 46.3354379],
              [-62.8591211, 46.3348302],
              [-62.8581548, 46.3340735],
              [-62.8572455, 46.3336132],
              [-62.8570874, 46.3332781],
              [-62.8568751, 46.3331764],
              [-62.8565506, 46.3331232],
              [-62.8552975, 46.3318804],
              [-62.8550905, 46.3314045],
              [-62.8551083, 46.3305369],
              [-62.8549616, 46.3302062],
              [-62.8546192, 46.3299733],
              [-62.8543086, 46.3299762],
              [-62.8541529, 46.3300792],
              [-62.8544931, 46.3304435],
              [-62.8544213, 46.3306803],
              [-62.8540316, 46.3309478],
              [-62.8537523, 46.3310034],
              [-62.8535488, 46.3309458],
              [-62.8531574, 46.3305721],
              [-62.8529071, 46.3302423],
              [-62.8527554, 46.3298994],
              [-62.8523941, 46.3295983],
              [-62.8523435, 46.3292942],
              [-62.8520244, 46.3288668],
              [-62.8510401, 46.3283166],
              [-62.8509999, 46.3276505],
              [-62.8507374, 46.3274278],
              [-62.8504964, 46.3273253],
              [-62.8500809, 46.3272575],
              [-62.8495257, 46.3272175],
              [-62.8487543, 46.3269522],
              [-62.8464582, 46.3265193],
              [-62.8443158, 46.3256368],
              [-62.8440588, 46.3256094],
              [-62.8438558, 46.3257349],
              [-62.8436775, 46.3257377],
              [-62.8431653, 46.3253684],
              [-62.8422466, 46.325067],
              [-62.8415647, 46.3246529],
              [-62.8407008, 46.3243013],
              [-62.8403389, 46.3242031],
              [-62.8398699, 46.3241656],
              [-62.8396311, 46.3239317],
              [-62.8395449, 46.3238376],
              [-62.8390742, 46.3236367],
              [-62.8389745, 46.3236697],
              [-62.8395571, 46.3242999],
              [-62.8405618, 46.3246039],
              [-62.8418674, 46.3256333],
              [-62.8424143, 46.3259041],
              [-62.843399, 46.3261597],
              [-62.8436012, 46.3262372],
              [-62.8440416, 46.3262739],
              [-62.8443408, 46.3261751],
              [-62.8449095, 46.3261797],
              [-62.8450943, 46.3262607],
              [-62.8456395, 46.326846],
              [-62.8458441, 46.3268837],
              [-62.8471051, 46.3268487],
              [-62.8474845, 46.3269433],
              [-62.8484514, 46.3275886],
              [-62.8487022, 46.3276237],
              [-62.8490262, 46.3274937],
              [-62.8494578, 46.3274863],
              [-62.8497879, 46.3278264],
              [-62.8498708, 46.3280717],
              [-62.8497027, 46.3281903],
              [-62.8491128, 46.3282488],
              [-62.8490645, 46.3283828],
              [-62.8491632, 46.3284614],
              [-62.8496251, 46.3286181],
              [-62.8500499, 46.3289131],
              [-62.8506389, 46.3295355],
              [-62.8510489, 46.3298858],
              [-62.851144, 46.330024],
              [-62.851274, 46.330633],
              [-62.8515353, 46.3308756],
              [-62.8526747, 46.3316174],
              [-62.8530403, 46.3316558],
              [-62.8533062, 46.3317272],
              [-62.8536701, 46.3320802],
              [-62.8538272, 46.3320489],
              [-62.8541466, 46.33209],
              [-62.8543805, 46.3323118],
              [-62.8543668, 46.3328251],
              [-62.8544155, 46.3328743],
              [-62.8546611, 46.3328058],
              [-62.854933, 46.3327778],
              [-62.8550693, 46.3329012],
              [-62.8551376, 46.3332934],
              [-62.8552768, 46.3335603],
              [-62.8557968, 46.3339934],
              [-62.8561041, 46.3341417],
              [-62.856546, 46.3342501],
              [-62.8572028, 46.3345119],
              [-62.8582249, 46.3351069],
              [-62.8585151, 46.3354419],
              [-62.8586579, 46.3356491],
              [-62.8587012, 46.3359809],
              [-62.8586055, 46.3361374],
              [-62.8586619, 46.3362505],
              [-62.8583232, 46.3368985],
              [-62.8583134, 46.3370999],
              [-62.8583553, 46.3373685],
              [-62.858414, 46.3375296],
              [-62.8584799, 46.337701],
              [-62.858706, 46.3378369],
              [-62.8590717, 46.3377656],
              [-62.8591334, 46.3379434],
              [-62.858715, 46.3380342],
              [-62.8586908, 46.3387323],
              [-62.8586924, 46.3388956],
              [-62.858879, 46.3391399],
              [-62.8595912, 46.3395348],
              [-62.8599808, 46.3396536],
              [-62.8611325, 46.3397187],
              [-62.8617768, 46.3399045],
              [-62.8621069, 46.3396751],
              [-62.8624362, 46.3396488],
              [-62.863118, 46.3398795],
              [-62.8636676, 46.3401104],
              [-62.864284, 46.3405702],
              [-62.8645285, 46.3409993],
              [-62.8646792, 46.3413621],
              [-62.8646749, 46.3416248],
              [-62.8644484, 46.3418532],
              [-62.8642892, 46.3424937],
              [-62.8639218, 46.3427699],
              [-62.8636707, 46.3431211],
              [-62.8635923, 46.3441383],
              [-62.8634716, 46.3447122],
              [-62.8632509, 46.3452276],
              [-62.8632368, 46.3460356],
              [-62.863504, 46.346565],
              [-62.8635064, 46.3470031],
              [-62.8637871, 46.3474054],
              [-62.8639246, 46.347509],
              [-62.8647326, 46.3477473],
              [-62.8651077, 46.348013],
              [-62.8652506, 46.3482202],
              [-62.8655961, 46.3495523],
              [-62.8660794, 46.3501237],
              [-62.8664529, 46.350704],
              [-62.8671584, 46.3513097],
              [-62.8674951, 46.351642],
              [-62.8682667, 46.3522018],
              [-62.8685478, 46.3523095],
              [-62.8689896, 46.3523262],
              [-62.8694544, 46.3520568],
              [-62.8696565, 46.3520427],
              [-62.8697391, 46.3521048],
              [-62.8698994, 46.3523085],
              [-62.8698466, 46.3526136],
              [-62.8695055, 46.3529304],
              [-62.8689671, 46.3531817],
              [-62.8683171, 46.3532785],
              [-62.8679772, 46.3535754],
              [-62.8678347, 46.3539377],
              [-62.8675104, 46.354454],
              [-62.8668846, 46.3552007],
              [-62.8668378, 46.3554064],
              [-62.8669804, 46.355522],
              [-62.8670374, 46.3558184],
              [-62.8669919, 46.3560959],
              [-62.8668436, 46.3561712],
              [-62.8666101, 46.3561327],
              [-62.8662314, 46.3559266],
              [-62.8659531, 46.3559624],
              [-62.8652799, 46.3562537],
              [-62.8647966, 46.3561601],
              [-62.8647368, 46.3561982],
              [-62.8647785, 46.3563667],
              [-62.8649299, 46.3565263],
              [-62.8651696, 46.3565571],
              [-62.8657531, 46.356323],
              [-62.8661175, 46.3562897],
              [-62.8663735, 46.3563369],
              [-62.8667413, 46.3566303],
              [-62.8667944, 46.3568946],
              [-62.8665964, 46.3570323],
              [-62.8663492, 46.3570292],
              [-62.8659506, 46.3568664],
              [-62.8655561, 46.3568271],
              [-62.8653293, 46.356964],
              [-62.865904, 46.3571637],
              [-62.8663981, 46.3575563],
              [-62.8668293, 46.3577519],
              [-62.8672671, 46.3581229],
              [-62.8673165, 46.3583553],
              [-62.867222, 46.358492],
              [-62.86589, 46.3584497],
              [-62.8655611, 46.3586593],
              [-62.865468, 46.3588677],
              [-62.8655506, 46.3589298],
              [-62.8657003, 46.3589261],
              [-62.8663013, 46.3586885],
              [-62.866687, 46.3586837],
              [-62.8670859, 46.3589381],
              [-62.8674063, 46.359254],
              [-62.8673576, 46.3596827],
              [-62.8676917, 46.3604411],
              [-62.8674397, 46.3613817],
              [-62.867446, 46.3618518],
              [-62.8671063, 46.3622403],
              [-62.8667136, 46.362456],
              [-62.8663255, 46.3625006],
              [-62.8660381, 46.3624007],
              [-62.8658884, 46.3624044],
              [-62.8657568, 46.3626794],
              [-62.8657546, 46.3628107],
              [-62.8654412, 46.3632398],
              [-62.8653858, 46.3634931],
              [-62.8653975, 46.3636806],
              [-62.865421, 46.3640557],
              [-62.8648728, 46.364466],
              [-62.8650639, 46.3647889],
              [-62.8653631, 46.3648292],
              [-62.8658477, 46.3649003],
              [-62.8661691, 46.3646268],
              [-62.8669905, 46.3641685],
              [-62.8676081, 46.3640389],
              [-62.8685369, 46.3636116],
              [-62.8691975, 46.3632443],
              [-62.8699772, 46.3626176],
              [-62.8702741, 46.3620805],
              [-62.8705355, 46.361845],
              [-62.8714541, 46.3617798],
              [-62.8714675, 46.3616528],
              [-62.8712147, 46.3614544],
              [-62.8706795, 46.3615545],
              [-62.8705979, 46.3613809],
              [-62.870541, 46.3606982],
              [-62.871038, 46.3598921],
              [-62.8711693, 46.3595255],
              [-62.8712682, 46.3587398],
              [-62.8712303, 46.3577391],
              [-62.8713627, 46.3568747],
              [-62.8716169, 46.3562806],
              [-62.8718013, 46.3561784],
              [-62.8724305, 46.3562363],
              [-62.8727942, 46.356406],
              [-62.8731144, 46.3566303],
              [-62.8734781, 46.3568],
              [-62.8736139, 46.3571265],
              [-62.8737026, 46.3571808],
              [-62.8739549, 46.3568097],
              [-62.873952, 46.3566663],
              [-62.8737182, 46.3565361],
              [-62.8732426, 46.3565065],
              [-62.8731876, 46.3564651],
              [-62.8732796, 46.3563682],
              [-62.873238, 46.3561997],
              [-62.8728281, 46.3560326],
              [-62.8724613, 46.3561057],
              [-62.8720613, 46.3558712],
              [-62.8717567, 46.3558665],
              [-62.8715823, 46.3559929],
              [-62.8714787, 46.3559939],
              [-62.87139, 46.3559396],
              [-62.8713871, 46.3557961],
              [-62.8714815, 46.3556594],
              [-62.8715122, 46.355051],
              [-62.8720965, 46.3533633],
              [-62.8723242, 46.3531149],
              [-62.8729621, 46.352739],
              [-62.8733149, 46.3526097],
              [-62.8734565, 46.3523589],
              [-62.875, 46.3516196],
              [-62.8752797, 46.3514913],
              [-62.875811, 46.3513592],
              [-62.8761587, 46.3512178],
              [-62.8762046, 46.3511236],
              [-62.8762053, 46.3509205],
              [-62.876382, 46.3507543],
              [-62.8772714, 46.3502103],
              [-62.8782262, 46.3498235],
              [-62.8789863, 46.3494232],
              [-62.8803974, 46.3489061],
              [-62.8815563, 46.3483738],
              [-62.8824841, 46.3481494],
              [-62.8831067, 46.3481234],
              [-62.8840691, 46.3481868],
              [-62.8852391, 46.3484313],
              [-62.8867454, 46.3488248],
              [-62.8880562, 46.3493162],
              [-62.8899555, 46.3504496],
              [-62.8905551, 46.3510044],
              [-62.8916049, 46.3518228],
              [-62.8937596, 46.3535011],
              [-62.8945464, 46.3540054],
              [-62.8951227, 46.3542767],
              [-62.8952588, 46.3543084],
              [-62.8961761, 46.3546491],
              [-62.8967544, 46.354789],
              [-62.8980082, 46.354984],
              [-62.8995783, 46.3549848],
              [-62.9026137, 46.3546211],
              [-62.9035652, 46.3543852],
              [-62.9040331, 46.3543507],
              [-62.9044703, 46.3544467],
              [-62.9046153, 46.3545225],
              [-62.9048462, 46.3545092],
              [-62.9057694, 46.3540694],
              [-62.9063297, 46.3540296],
              [-62.9067193, 46.3537618],
              [-62.9068573, 46.3532759],
              [-62.9073414, 46.3528715],
              [-62.9089512, 46.35181],
              [-62.909295, 46.3516366],
              [-62.9097749, 46.3508138],
              [-62.910544, 46.3502542],
              [-62.9108111, 46.3499193],
              [-62.9108675, 46.3496461],
              [-62.9113539, 46.3492019],
              [-62.9121306, 46.3487062],
              [-62.9126656, 46.3486058],
              [-62.9132196, 46.3485737],
              [-62.9136518, 46.3483629],
              [-62.9135153, 46.3482396],
              [-62.9131708, 46.3482299],
              [-62.9129179, 46.3483262],
              [-62.9126583, 46.3483388],
              [-62.912466, 46.3482856],
              [-62.9122794, 46.348133],
              [-62.9123041, 46.3477155],
              [-62.9124714, 46.3474136],
              [-62.9124519, 46.3468675],
              [-62.9119791, 46.3465953],
              [-62.9119229, 46.3465738],
              [-62.9116002, 46.3463894],
              [-62.911783, 46.3462154],
              [-62.9121169, 46.346404],
              [-62.9126071, 46.3466728],
              [-62.9126692, 46.3465949],
              [-62.9129819, 46.3464604],
              [-62.9137621, 46.3452241],
              [-62.9150379, 46.3440655],
              [-62.9157149, 46.3436027],
              [-62.916872, 46.3433845],
              [-62.9179621, 46.3429375],
              [-62.9183547, 46.3428131],
              [-62.9190524, 46.3428766],
              [-62.9198708, 46.342844],
              [-62.9218326, 46.3432179],
              [-62.9226517, 46.3436631],
              [-62.9251407, 46.3435779],
              [-62.9256302, 46.3436633],
              [-62.9270381, 46.3437746],
              [-62.927504, 46.3438713],
              [-62.9280788, 46.3440707],
              [-62.9289395, 46.3440033],
              [-62.9291346, 46.3441083],
              [-62.929493, 46.3441742],
              [-62.9298782, 46.3440775],
              [-62.9305469, 46.3440485],
              [-62.9315557, 46.3442002],
              [-62.931835, 46.3441444],
              [-62.9320328, 46.3440066],
              [-62.9320883, 46.3438449],
              [-62.9328016, 46.3434468],
              [-62.933153, 46.3433372],
              [-62.9335773, 46.343357],
              [-62.9341111, 46.3435711],
              [-62.934568, 46.3439184],
              [-62.9346142, 46.3442104],
              [-62.9348151, 46.344216],
              [-62.934992, 46.3441414],
              [-62.9357255, 46.344381],
              [-62.9357503, 46.3446445],
              [-62.9358279, 46.3446944],
              [-62.9367759, 46.3446095],
              [-62.9374558, 46.3445847],
              [-62.9380849, 46.3443475],
              [-62.9380023, 46.3442855],
              [-62.9377234, 46.3438436],
              [-62.9375895, 46.3437722],
              [-62.937164, 46.3437722],
              [-62.9366691, 46.3438778],
              [-62.9360574, 46.3438168],
              [-62.9356823, 46.343643],
              [-62.9351382, 46.3436079],
              [-62.9340013, 46.3429906],
              [-62.9334458, 46.3429511],
              [-62.9328574, 46.343082],
              [-62.932679, 46.3430849],
              [-62.93248, 46.3429479],
              [-62.9324273, 46.3428668],
              [-62.932802, 46.3426543],
              [-62.934372, 46.3421568],
              [-62.9362895, 46.341223],
              [-62.9367285, 46.3408929],
              [-62.9371656, 46.3406941],
              [-62.9378614, 46.3405941],
              [-62.9380561, 46.3406075],
              [-62.9385622, 46.3405062],
              [-62.9407561, 46.3397593],
              [-62.9412231, 46.3395414],
              [-62.9415449, 46.3392477],
              [-62.9418485, 46.3387744],
              [-62.9420327, 46.338672],
              [-62.9426225, 46.3386129],
              [-62.9430123, 46.3384366],
              [-62.9437265, 46.3377239],
              [-62.9444606, 46.3364702],
              [-62.9448645, 46.3355576],
              [-62.9449559, 46.335369],
              [-62.9460566, 46.3332495],
              [-62.9471572, 46.33113],
              [-62.948165, 46.3300114],
              [-62.9487305, 46.3295772],
              [-62.9490224, 46.3293026],
              [-62.9504525, 46.3286298],
              [-62.9510267, 46.3284427],
              [-62.9511464, 46.3281633],
              [-62.951408, 46.3278162],
              [-62.9516552, 46.3276161],
              [-62.9519022, 46.327619],
              [-62.9520983, 46.3274094],
              [-62.9521712, 46.3272442],
              [-62.9523328, 46.3271333],
              [-62.9526457, 46.3270902],
              [-62.9530512, 46.3271334],
              [-62.9532315, 46.326999],
              [-62.9532863, 46.3264511],
              [-62.9534757, 46.3261577],
              [-62.9539044, 46.3258033],
              [-62.9546862, 46.3255026],
              [-62.95479, 46.3252984],
              [-62.954771, 46.3252302],
              [-62.9548822, 46.3249983],
              [-62.9553631, 46.3246335],
              [-62.9559829, 46.3235597],
              [-62.9565173, 46.3231645],
              [-62.9567177, 46.3230784],
              [-62.9570548, 46.322618],
              [-62.9572199, 46.3224474],
              [-62.9574566, 46.3221314],
              [-62.9581606, 46.3214861],
              [-62.958638, 46.3211808],
              [-62.9592082, 46.3209617],
              [-62.9602126, 46.3202889],
              [-62.9613193, 46.3196348],
              [-62.9618504, 46.3195938],
              [-62.9623312, 46.3197266],
              [-62.9628313, 46.3197245],
              [-62.9633029, 46.3195186],
              [-62.9640034, 46.3189328],
              [-62.9643752, 46.318963],
              [-62.9651569, 46.3191599],
              [-62.9653779, 46.3192138],
              [-62.9657627, 46.319117],
              [-62.9661192, 46.3188163],
              [-62.9665221, 46.3186045],
              [-62.9669648, 46.3186008],
              [-62.9670208, 46.3183277],
              [-62.967304, 46.3181006],
              [-62.9675132, 46.3180586],
              [-62.9677528, 46.3180891],
              [-62.9679201, 46.3178788],
              [-62.9682944, 46.3176662],
              [-62.9684856, 46.3177391],
              [-62.9685395, 46.3178004],
              [-62.9686256, 46.3178027],
              [-62.9688704, 46.3176423],
              [-62.9693418, 46.3176394],
              [-62.969443, 46.317678],
              [-62.9697836, 46.3174526],
              [-62.9701622, 46.3173635],
              [-62.9705372, 46.3170394],
              [-62.9706719, 46.3167963],
              [-62.9707257, 46.3163598],
              [-62.9708657, 46.3159257],
              [-62.9710403, 46.3156877],
              [-62.971155, 46.3156908],
              [-62.9713265, 46.3158071],
              [-62.9714125, 46.3158094],
              [-62.9716523, 46.3156369],
              [-62.9718767, 46.3156312],
              [-62.9720773, 46.3158398],
              [-62.9719631, 46.3164259],
              [-62.9727698, 46.3168862],
              [-62.9732442, 46.3172297],
              [-62.9738827, 46.3172195],
              [-62.9743424, 46.3176184],
              [-62.9743489, 46.3179053],
              [-62.9742143, 46.3181484],
              [-62.9748173, 46.3187544],
              [-62.974896, 46.3187844],
              [-62.9748584, 46.3180388],
              [-62.9750025, 46.3179313],
              [-62.9757336, 46.3182103],
              [-62.9763411, 46.318239],
              [-62.9761286, 46.3179345],
              [-62.9760139, 46.3179314],
              [-62.9757337, 46.3180072],
              [-62.9755363, 46.3179421],
              [-62.975441, 46.317601],
              [-62.9752583, 46.3174805],
              [-62.9749747, 46.317616],
              [-62.9748138, 46.3176155],
              [-62.9744727, 46.3172517],
              [-62.9738673, 46.3168886],
              [-62.9731087, 46.3165889],
              [-62.9729233, 46.3164166],
              [-62.9730135, 46.3162479],
              [-62.9751742, 46.3147468],
              [-62.97576, 46.3144524],
              [-62.9762146, 46.3143416],
              [-62.9766595, 46.3142981],
              [-62.9775791, 46.3136944],
              [-62.9781371, 46.3135823],
              [-62.9782209, 46.3136244],
              [-62.9791619, 46.3135468],
              [-62.9795426, 46.313621],
              [-62.9799217, 46.3136235],
              [-62.9801851, 46.3134397],
              [-62.9803153, 46.313073],
              [-62.9806035, 46.312858],
              [-62.9807492, 46.3128222],
              [-62.9815574, 46.3128564],
              [-62.9816563, 46.3129348],
              [-62.9820202, 46.312901],
              [-62.9823708, 46.3126997],
              [-62.9827577, 46.3127661],
              [-62.9829136, 46.3125514],
              [-62.983078, 46.3125],
              [-62.9836157, 46.3123319],
              [-62.9838082, 46.3121819],
              [-62.9838522, 46.3120159],
              [-62.9841447, 46.3119244],
              [-62.9844343, 46.3119841],
              [-62.9851797, 46.31171],
              [-62.9856156, 46.3116225],
              [-62.9860157, 46.3113587],
              [-62.9860906, 46.3111538],
              [-62.9861576, 46.3110879],
              [-62.9867262, 46.3110916],
              [-62.9878314, 46.3109548],
              [-62.9888032, 46.3110412],
              [-62.9891945, 46.3112311],
              [-62.9896752, 46.3115668],
              [-62.9897807, 46.311932],
              [-62.9894701, 46.3121385],
              [-62.9884037, 46.3123003],
              [-62.988002, 46.3124923],
              [-62.9879964, 46.3125],
              [-62.9898662, 46.3125],
              [-62.9903774, 46.3120479],
              [-62.9907213, 46.3115597],
              [-62.9911197, 46.3112243],
              [-62.991912, 46.3104298],
              [-62.9920999, 46.3103593],
              [-62.9921988, 46.3104377],
              [-62.9921976, 46.3106606],
              [-62.9924152, 46.3107741],
              [-62.9926728, 46.3106896],
              [-62.9928848, 46.3106994],
              [-62.9933526, 46.310959],
              [-62.9936895, 46.3109961],
              [-62.9945828, 46.3106464],
              [-62.9947509, 46.3106191],
              [-62.9951401, 46.3106457],
              [-62.9958683, 46.3108727],
              [-62.9964521, 46.3109126],
              [-62.9968311, 46.3111181],
              [-62.9971769, 46.3111992],
              [-62.9973423, 46.3111201],
              [-62.997874, 46.3105614],
              [-62.9982566, 46.3103011],
              [-62.9986069, 46.3103027],
              [-62.9995561, 46.3107865],
              [-63.0000233, 46.310629],
              [-63.0001981, 46.3105833],
              [-63.0018612, 46.3105372],
              [-63.0024151, 46.3105962],
              [-63.0032102, 46.3109603],
              [-63.0034808, 46.3109518],
              [-63.0034815, 46.3102311],
              [-63.0036239, 46.3100519],
              [-63.0036302, 46.309638],
              [-63.003966, 46.3094919],
              [-63.0048754, 46.3093615],
              [-63.0063636, 46.3088526],
              [-63.0078899, 46.3086833],
              [-63.0082099, 46.3086124],
              [-63.0089163, 46.3085161],
              [-63.0090985, 46.308545],
              [-63.0090023, 46.3087215],
              [-63.0086772, 46.3089834],
              [-63.0087627, 46.3090972],
              [-63.0092424, 46.3090466],
              [-63.0094465, 46.3091955],
              [-63.0095787, 46.3091951],
              [-63.0097408, 46.3085665],
              [-63.0098853, 46.3083475],
              [-63.0102999, 46.3078253],
              [-63.0106733, 46.307724],
              [-63.0112171, 46.3077587],
              [-63.0122513, 46.3080615],
              [-63.0125977, 46.3080312],
              [-63.0132158, 46.3077693],
              [-63.0135476, 46.3077942],
              [-63.0140616, 46.3080511],
              [-63.0143084, 46.3080538],
              [-63.0138097, 46.3072241],
              [-63.0138125, 46.3070729],
              [-63.0138693, 46.3069828],
              [-63.0141943, 46.306924],
              [-63.0152055, 46.3069236],
              [-63.015774, 46.3067241],
              [-63.0160794, 46.3065054],
              [-63.0167396, 46.3060058],
              [-63.0173638, 46.3057361],
              [-63.017691, 46.3054345],
              [-63.0184148, 46.3051316],
              [-63.01894, 46.3049866],
              [-63.0193713, 46.3047754],
              [-63.0199988, 46.3046491],
              [-63.0205122, 46.3044082],
              [-63.0207686, 46.3043435],
              [-63.0222464, 46.3044194],
              [-63.0232361, 46.3045936],
              [-63.0237125, 46.3048055],
              [-63.0239622, 46.3050631],
              [-63.0243313, 46.3060565],
              [-63.0244388, 46.3064934],
              [-63.0245216, 46.3073675],
              [-63.0247786, 46.3071913],
              [-63.0248089, 46.3070608],
              [-63.024788, 46.3063117],
              [-63.0247418, 46.3059084],
              [-63.0247192, 46.3056968],
              [-63.024564, 46.305394],
              [-63.0246128, 46.305037],
              [-63.0245425, 46.3047564],
              [-63.0239502, 46.303749],
              [-63.0236038, 46.3037794],
              [-63.0229239, 46.3037132],
              [-63.0217571, 46.3033193],
              [-63.0210254, 46.3029491],
              [-63.0209698, 46.3028162],
              [-63.0210265, 46.3027262],
              [-63.0214938, 46.302488],
              [-63.022205, 46.3019977],
              [-63.0226986, 46.301394],
              [-63.0233154, 46.3009489],
              [-63.0236398, 46.3007985],
              [-63.0249352, 46.3004394],
              [-63.0264898, 46.2996614],
              [-63.0268249, 46.2996267],
              [-63.0271656, 46.2994926],
              [-63.027238, 46.2991243],
              [-63.0273903, 46.2989692],
              [-63.0282038, 46.2987005],
              [-63.0285389, 46.2986658],
              [-63.0299852, 46.2990951],
              [-63.030322, 46.299132],
              [-63.0305733, 46.2990552],
              [-63.0310405, 46.2990201],
              [-63.0312778, 46.2990902],
              [-63.0321607, 46.2995281],
              [-63.0329042, 46.299994],
              [-63.033286, 46.3000481],
              [-63.0346928, 46.2999547],
              [-63.0353112, 46.2997843],
              [-63.0359809, 46.2998262],
              [-63.0363677, 46.2996894],
              [-63.036389, 46.2995148],
              [-63.036178, 46.2992822],
              [-63.0361695, 46.2991267],
              [-63.0362015, 46.2990678],
              [-63.0365601, 46.2988187],
              [-63.0371426, 46.2986752],
              [-63.0381782, 46.2986434],
              [-63.0387639, 46.2982371],
              [-63.0392097, 46.2981735],
              [-63.0399334, 46.2982766],
              [-63.0403916, 46.2981974],
              [-63.0407807, 46.2982237],
              [-63.040976, 46.2985316],
              [-63.0410722, 46.2987611],
              [-63.0413563, 46.29892],
              [-63.0415952, 46.2988588],
              [-63.0419373, 46.2983902],
              [-63.0422745, 46.297189],
              [-63.0425031, 46.2966976],
              [-63.0427615, 46.29639],
              [-63.0429885, 46.2962329],
              [-63.0445172, 46.2961147],
              [-63.0451962, 46.2958862],
              [-63.0457744, 46.2955076],
              [-63.0461645, 46.295311],
              [-63.0470599, 46.2950126],
              [-63.0477979, 46.2948572],
              [-63.049167, 46.2949218],
              [-63.0496077, 46.2948461],
              [-63.0499866, 46.2948483],
              [-63.0506103, 46.2950959],
              [-63.0508633, 46.2950907],
              [-63.0511026, 46.294918],
              [-63.0512856, 46.2945208],
              [-63.0512355, 46.2937711],
              [-63.0512118, 46.2935793],
              [-63.0511571, 46.2934266],
              [-63.0511817, 46.2931924],
              [-63.0512254, 46.2930263],
              [-63.0515704, 46.2928127],
              [-63.0521274, 46.2926086],
              [-63.0530307, 46.2925771],
              [-63.0536929, 46.2924435],
              [-63.0537032, 46.2926707],
              [-63.0536167, 46.2927799],
              [-63.0535638, 46.2934194],
              [-63.0536017, 46.2936672],
              [-63.0540734, 46.294273],
              [-63.0542318, 46.2948306],
              [-63.0539387, 46.2958658],
              [-63.053974, 46.2964679],
              [-63.0536097, 46.2971309],
              [-63.0535836, 46.2974965],
              [-63.0536337, 46.2975257],
              [-63.0537309, 46.2975323],
              [-63.0541495, 46.2971335],
              [-63.0544988, 46.2966372],
              [-63.0547288, 46.2954968],
              [-63.0547472, 46.2952704],
              [-63.0548582, 46.294927],
              [-63.0555099, 46.2943632],
              [-63.0554764, 46.2940359],
              [-63.0550584, 46.2930852],
              [-63.0548597, 46.292837],
              [-63.0549033, 46.292468],
              [-63.0551991, 46.2920022],
              [-63.0550638, 46.2915447],
              [-63.0553586, 46.2905813],
              [-63.0559009, 46.289712],
              [-63.0561846, 46.2889473],
              [-63.0563419, 46.2888042],
              [-63.0565274, 46.2887733],
              [-63.0567166, 46.2885714],
              [-63.0568097, 46.288343],
              [-63.0571518, 46.2880775],
              [-63.0572972, 46.2878385],
              [-63.057353, 46.2874539],
              [-63.0574148, 46.2873759],
              [-63.0575103, 46.2873108],
              [-63.0579681, 46.2875459],
              [-63.0581811, 46.2875357],
              [-63.0582629, 46.287303],
              [-63.0581132, 46.287104],
              [-63.0582801, 46.2863759],
              [-63.0586237, 46.2859791],
              [-63.059155, 46.2855116],
              [-63.0593323, 46.2852138],
              [-63.0596115, 46.2850461],
              [-63.060047, 46.2849582],
              [-63.0606563, 46.2849466],
              [-63.0614012, 46.2845604],
              [-63.0619441, 46.2845033],
              [-63.0632857, 46.2839181],
              [-63.0639146, 46.2838632],
              [-63.0643872, 46.2837285],
              [-63.0650615, 46.2830618],
              [-63.0652414, 46.2826128],
              [-63.0656676, 46.2822778],
              [-63.0659547, 46.282174],
              [-63.0662116, 46.2822007],
              [-63.0664727, 46.2824625],
              [-63.0664698, 46.2829281],
              [-63.0668202, 46.2831325],
              [-63.0671159, 46.2831842],
              [-63.0671975, 46.283266],
              [-63.0673768, 46.2832429],
              [-63.0680538, 46.2834599],
              [-63.0690382, 46.284139],
              [-63.0700319, 46.2845476],
              [-63.0711985, 46.2852554],
              [-63.0716806, 46.2848675],
              [-63.072166, 46.2844769],
              [-63.0717952, 46.2842243],
              [-63.0710245, 46.2836266],
              [-63.0693291, 46.2825146],
              [-63.0684088, 46.2814034],
              [-63.0671082, 46.2804213],
              [-63.0666463, 46.2799513],
              [-63.0662774, 46.2792528],
              [-63.0662762, 46.2787551],
              [-63.066453, 46.2785688],
              [-63.0668023, 46.2782755],
              [-63.0670856, 46.2778253],
              [-63.0677904, 46.2774341],
              [-63.0691835, 46.2769497],
              [-63.0698971, 46.2763994],
              [-63.0704796, 46.2761443],
              [-63.0710821, 46.2757344],
              [-63.0718905, 46.2755449],
              [-63.072417, 46.2754713],
              [-63.0727701, 46.27521],
              [-63.0730478, 46.2748591],
              [-63.0738564, 46.2743552],
              [-63.0742004, 46.2740498],
              [-63.0746121, 46.2734557],
              [-63.0749966, 46.2727295],
              [-63.0762733, 46.2718558],
              [-63.0766468, 46.2713284],
              [-63.0773195, 46.2709959],
              [-63.0775604, 46.2708948],
              [-63.0785305, 46.2706857],
              [-63.079037, 46.2704523],
              [-63.0794197, 46.2704864],
              [-63.0803915, 46.2706634],
              [-63.080431, 46.2707799],
              [-63.0803805, 46.2708621],
              [-63.0791994, 46.2708388],
              [-63.0787589, 46.2709147],
              [-63.0785278, 46.2711514],
              [-63.0785662, 46.2712877],
              [-63.0788951, 46.2714636],
              [-63.0792593, 46.271521],
              [-63.0798768, 46.2714618],
              [-63.0804609, 46.2712782],
              [-63.0811173, 46.2709294],
              [-63.0817332, 46.2710014],
              [-63.0819774, 46.2708407],
              [-63.0820351, 46.2707308],
              [-63.0824654, 46.2706307],
              [-63.0836262, 46.2706057],
              [-63.0841065, 46.2705348],
              [-63.0849213, 46.2705405],
              [-63.0856864, 46.2704055],
              [-63.0862686, 46.2704646],
              [-63.0870014, 46.2704999],
              [-63.0873776, 46.2703387],
              [-63.0880005, 46.27018],
              [-63.0886727, 46.2702734],
              [-63.0892828, 46.2702417],
              [-63.0899746, 46.2699774],
              [-63.090924, 46.2695168],
              [-63.0911105, 46.269466],
              [-63.091705, 46.2695095],
              [-63.0917811, 46.2695527],
              [-63.0921392, 46.2697558],
              [-63.0922778, 46.2699506],
              [-63.0922973, 46.2702217],
              [-63.0921041, 46.2704794],
              [-63.0921657, 46.2707843],
              [-63.0923802, 46.2710082],
              [-63.0933513, 46.2715666],
              [-63.0936259, 46.2717436],
              [-63.0938593, 46.2719173],
              [-63.0938916, 46.2721],
              [-63.0941073, 46.2720836],
              [-63.0942167, 46.2720938],
              [-63.0944594, 46.2721722],
              [-63.0947598, 46.27226],
              [-63.0957645, 46.2722979],
              [-63.0963712, 46.2724507],
              [-63.096882, 46.2726476],
              [-63.0969534, 46.2725656],
              [-63.0969368, 46.2724355],
              [-63.0966609, 46.272283],
              [-63.0963683, 46.2721318],
              [-63.0949801, 46.271639],
              [-63.0943288, 46.271013],
              [-63.0940351, 46.2705912],
              [-63.0938177, 46.2703063],
              [-63.0937295, 46.2699404],
              [-63.0935109, 46.2696654],
              [-63.093265, 46.2694574],
              [-63.0930427, 46.2691939],
              [-63.0929684, 46.2691359],
              [-63.0925101, 46.2687776],
              [-63.0924617, 46.2688759],
              [-63.0923312, 46.2690471],
              [-63.0922373, 46.2691954],
              [-63.0921274, 46.2691972],
              [-63.0919396, 46.2691249],
              [-63.0918136, 46.2690192],
              [-63.0919074, 46.2688097],
              [-63.0920845, 46.2686799],
              [-63.0919524, 46.268611],
              [-63.0915166, 46.2685566],
              [-63.0904262, 46.2688006],
              [-63.0900704, 46.2688524],
              [-63.0897127, 46.2688131],
              [-63.0886668, 46.2684048],
              [-63.0879485, 46.2678326],
              [-63.0872667, 46.2674459],
              [-63.086822, 46.2671614],
              [-63.0866485, 46.2671249],
              [-63.0862636, 46.2665875],
              [-63.0859035, 46.2658429],
              [-63.0859249, 46.2656548],
              [-63.0860573, 46.2655911],
              [-63.0864572, 46.2655519],
              [-63.086761, 46.2653923],
              [-63.0870767, 46.2649492],
              [-63.0871221, 46.2648548],
              [-63.0873684, 46.2646544],
              [-63.0875958, 46.2645887],
              [-63.0884486, 46.2645277],
              [-63.0885113, 46.2644298],
              [-63.0882183, 46.264227],
              [-63.0878433, 46.2640539],
              [-63.0878873, 46.263462],
              [-63.0876167, 46.2632678],
              [-63.0869907, 46.2631716],
              [-63.0867597, 46.2630939],
              [-63.0865588, 46.2628856],
              [-63.0866038, 46.2625882],
              [-63.0867889, 46.2623543],
              [-63.0872259, 46.262338],
              [-63.0877648, 46.2624517],
              [-63.0888736, 46.2623258],
              [-63.0895693, 46.2624078],
              [-63.0897024, 46.2623875],
              [-63.0897865, 46.262318],
              [-63.0898486, 46.2621286],
              [-63.0895751, 46.2618826],
              [-63.0883726, 46.260999],
              [-63.0881394, 46.260961],
              [-63.087266, 46.2610852],
              [-63.0868443, 46.2608233],
              [-63.0866006, 46.2605582],
              [-63.0861484, 46.2602238],
              [-63.0855852, 46.2600298],
              [-63.0854402, 46.2599543],
              [-63.0854293, 46.2598386],
              [-63.0857394, 46.2595204],
              [-63.085803, 46.2591997],
              [-63.0859303, 46.2590757],
              [-63.0865686, 46.2591562],
              [-63.0866615, 46.2589278],
              [-63.0865168, 46.2587409],
              [-63.0865266, 46.2585621],
              [-63.0866057, 46.2584806],
              [-63.0879531, 46.2576644],
              [-63.0892083, 46.2571682],
              [-63.090003, 46.2566996],
              [-63.0912084, 46.2560627],
              [-63.0912586, 46.2558889],
              [-63.0914562, 46.2557309],
              [-63.091702, 46.2556419],
              [-63.0923745, 46.255622],
              [-63.0928895, 46.2555237],
              [-63.0934518, 46.2553338],
              [-63.0939999, 46.2551268],
              [-63.0945068, 46.254932],
              [-63.0947965, 46.2548726],
              [-63.0950486, 46.2547632],
              [-63.0955287, 46.2544201],
              [-63.0961377, 46.2542329],
              [-63.096505, 46.2541604],
              [-63.0968458, 46.2541494],
              [-63.0972909, 46.2540269],
              [-63.0977791, 46.2538545],
              [-63.0984256, 46.2536134],
              [-63.0993429, 46.2533352],
              [-63.099975, 46.2530377],
              [-63.1015266, 46.2528875],
              [-63.1026536, 46.2530524],
              [-63.1047616, 46.2533266],
              [-63.1050715, 46.2531972],
              [-63.1052215, 46.2531347],
              [-63.106619, 46.2521972],
              [-63.1069663, 46.2520352],
              [-63.1070138, 46.2519011],
              [-63.1068237, 46.2518085],
              [-63.1064908, 46.2516008],
              [-63.1065857, 46.2513327],
              [-63.10754, 46.2507726],
              [-63.1087535, 46.2504024],
              [-63.1091715, 46.2502064],
              [-63.1096263, 46.249967],
              [-63.1101769, 46.2496882],
              [-63.1105289, 46.2493261],
              [-63.110812, 46.2490055],
              [-63.1114729, 46.2479312],
              [-63.1115245, 46.247308],
              [-63.1114299, 46.2467562],
              [-63.1110867, 46.2466135],
              [-63.110406, 46.2462757],
              [-63.1104972, 46.2458343],
              [-63.1105938, 46.2454707],
              [-63.1107064, 46.2451071],
              [-63.1108084, 46.2447324],
              [-63.1108781, 46.244521],
              [-63.1109288, 46.2444847],
              [-63.1110122, 46.2444245],
              [-63.1111302, 46.2443355],
              [-63.1110045, 46.2442761],
              [-63.1109187, 46.2441871],
              [-63.1109531, 46.2440446],
              [-63.1110474, 46.24392],
              [-63.1112707, 46.2437834],
              [-63.1114852, 46.2435935],
              [-63.1115968, 46.2433263],
              [-63.1117342, 46.2426141],
              [-63.1118715, 46.2423173],
              [-63.1119573, 46.2417355],
              [-63.1120689, 46.2415336],
              [-63.1126782, 46.2411359],
              [-63.1119573, 46.240661],
              [-63.1118457, 46.2406432],
              [-63.1109788, 46.2400674],
              [-63.1103694, 46.2396815],
              [-63.1098974, 46.239462],
              [-63.109082, 46.2391888],
              [-63.1079747, 46.2388385],
              [-63.1073482, 46.238607],
              [-63.1067903, 46.2383577],
              [-63.106198, 46.2379955],
              [-63.1057603, 46.2376393],
              [-63.1053998, 46.2373484],
              [-63.1051767, 46.237105],
              [-63.105168, 46.2370398],
              [-63.1052024, 46.2369626],
              [-63.1052625, 46.2368854],
              [-63.1055371, 46.2367549],
              [-63.1056573, 46.2367488],
              [-63.1058204, 46.2367607],
              [-63.1059405, 46.2368082],
              [-63.1063954, 46.2372475],
              [-63.1069878, 46.2376928],
              [-63.1074597, 46.2380015],
              [-63.1083266, 46.2383814],
              [-63.1094167, 46.2386604],
              [-63.1096399, 46.2386961],
              [-63.1098029, 46.2386426],
              [-63.1108672, 46.239355],
              [-63.1110389, 46.2393788],
              [-63.1117255, 46.2397587],
              [-63.1117685, 46.2398122],
              [-63.1123522, 46.2401445],
              [-63.1124722, 46.2402633],
              [-63.1130674, 46.2407069],
              [-63.1135567, 46.2408197],
              [-63.1138656, 46.2405703],
              [-63.1142434, 46.2398521],
              [-63.1149644, 46.2391873],
              [-63.1152991, 46.239027],
              [-63.1154862, 46.2386366],
              [-63.1159015, 46.2382445],
              [-63.1162838, 46.2377252],
              [-63.1165215, 46.237245],
              [-63.1168573, 46.2366021],
              [-63.1171607, 46.2362856],
              [-63.1175064, 46.2359249],
              [-63.1169913, 46.2356057],
              [-63.1174715, 46.2352439],
              [-63.1170996, 46.2348506],
              [-63.1178437, 46.2343839],
              [-63.118399, 46.2340133],
              [-63.118348, 46.2339737],
              [-63.1177367, 46.2334998],
              [-63.1167714, 46.2327792],
              [-63.1165738, 46.2326316],
              [-63.1163405, 46.232446],
              [-63.116731, 46.2322041],
              [-63.1170299, 46.2320189],
              [-63.1181161, 46.2313458],
              [-63.1182314, 46.2314497],
              [-63.117188, 46.232138],
              [-63.117228, 46.2321689],
              [-63.1174833, 46.2323656],
              [-63.1178018, 46.2326111],
              [-63.1189529, 46.2334981],
              [-63.1192508, 46.2333126],
              [-63.1196938, 46.2330255],
              [-63.1198771, 46.2328764],
              [-63.1199154, 46.2327538],
              [-63.1198339, 46.2326125],
              [-63.1199186, 46.232516],
              [-63.1199186, 46.2324369],
              [-63.1200448, 46.2323813],
              [-63.1200634, 46.2322309],
              [-63.1201814, 46.2321344],
              [-63.1203639, 46.2320342],
              [-63.1205891, 46.2319081],
              [-63.1200216, 46.2313867],
              [-63.1205098, 46.2310787],
              [-63.1220965, 46.2322441],
              [-63.1221496, 46.2322121],
              [-63.1222414, 46.2321569],
              [-63.1222641, 46.2321432],
              [-63.1224866, 46.2320046],
              [-63.1224702, 46.2319921],
              [-63.1224482, 46.2319746],
              [-63.1224075, 46.2319444],
              [-63.1224006, 46.2319387],
              [-63.1223523, 46.2319024],
              [-63.1222628, 46.2318322],
              [-63.1222903, 46.2318166],
              [-63.1225841, 46.2316368],
              [-63.1226517, 46.2315943],
              [-63.1228062, 46.2317155],
              [-63.122985, 46.2317288],
              [-63.1230892, 46.2316608],
              [-63.1225093, 46.2311843],
              [-63.1219974, 46.2307983],
              [-63.1219722, 46.2307596],
              [-63.1219738, 46.2307338],
              [-63.1219788, 46.2307042],
              [-63.1224761, 46.2303828],
              [-63.1224606, 46.2303704],
              [-63.1224209, 46.2303411],
              [-63.122124, 46.2301185],
              [-63.1229711, 46.2295735],
              [-63.1237371, 46.2301354],
              [-63.1242044, 46.2305085],
              [-63.1246508, 46.2308648],
              [-63.1250473, 46.2306268],
              [-63.1246751, 46.230293],
              [-63.1244875, 46.230125],
              [-63.1243723, 46.2300186],
              [-63.1243823, 46.2300122],
              [-63.1245159, 46.2299391],
              [-63.1247921, 46.2301673],
              [-63.1248281, 46.2301431],
              [-63.1250496, 46.2299948],
              [-63.1250338, 46.2299827],
              [-63.124709, 46.2297294],
              [-63.1248193, 46.2296604],
              [-63.1251225, 46.2294695],
              [-63.1251586, 46.2294509],
              [-63.1253927, 46.2296443],
              [-63.1254041, 46.2296531],
              [-63.1258048, 46.2299612],
              [-63.1260203, 46.2301547],
              [-63.1261403, 46.2301945],
              [-63.1262752, 46.2302065],
              [-63.126465, 46.2301641],
              [-63.1265728, 46.2301026],
              [-63.1266931, 46.2299857],
              [-63.1267831, 46.229873],
              [-63.1267982, 46.229854],
              [-63.1269364, 46.2296118],
              [-63.1266574, 46.229237],
              [-63.1264883, 46.2288695],
              [-63.1268751, 46.228857],
              [-63.1275893, 46.2288726],
              [-63.1285443, 46.2288564],
              [-63.1285819, 46.2288559],
              [-63.1288023, 46.228872],
              [-63.1288953, 46.2290011],
              [-63.1290109, 46.2288763],
              [-63.1291944, 46.2288916],
              [-63.1293257, 46.2290364],
              [-63.1293593, 46.2292655],
              [-63.1293927, 46.2294511],
              [-63.1295416, 46.2296403],
              [-63.1297133, 46.2297822],
              [-63.1305099, 46.2300772],
              [-63.1311617, 46.2302795],
              [-63.1318902, 46.2304065],
              [-63.132219, 46.2305604],
              [-63.1326554, 46.2308025],
              [-63.1327572, 46.2308875],
              [-63.1330317, 46.2310561],
              [-63.133418, 46.2312008],
              [-63.1336701, 46.2312694],
              [-63.1338793, 46.231288],
              [-63.1343808, 46.2313009],
              [-63.13444, 46.2312302],
              [-63.1347912, 46.2308102],
              [-63.1352033, 46.2304658],
              [-63.1355723, 46.2300918],
              [-63.1360873, 46.2293556],
              [-63.1363498, 46.228799],
              [-63.1364393, 46.2284115],
              [-63.1365508, 46.2280612],
              [-63.136774, 46.2277881],
              [-63.1371087, 46.2275268],
              [-63.1374949, 46.2273961],
              [-63.1389455, 46.2272894],
              [-63.1403875, 46.2271468],
              [-63.1420612, 46.2271823],
              [-63.1427049, 46.2272477],
              [-63.1433828, 46.2274495],
              [-63.1437434, 46.2278177],
              [-63.144121, 46.228459],
              [-63.1444644, 46.2291062],
              [-63.1446406, 46.229424],
              [-63.1448312, 46.229718],
              [-63.1449655, 46.2298878],
              [-63.1451119, 46.2300261],
              [-63.1453881, 46.2300673],
              [-63.1456445, 46.2301254],
              [-63.1460023, 46.2301916],
              [-63.146237, 46.2302569],
              [-63.1467212, 46.2303825],
              [-63.1473177, 46.2305872],
              [-63.1474083, 46.2306667],
              [-63.147394, 46.2307924],
              [-63.1479268, 46.2312195],
              [-63.1482159, 46.2314512],
              [-63.1483497, 46.2315585],
              [-63.1484677, 46.2316533],
              [-63.1485754, 46.2317399],
              [-63.1488929, 46.2318348],
              [-63.1491247, 46.2320427],
              [-63.1491545, 46.2323772],
              [-63.1491928, 46.2326665],
              [-63.149468, 46.2328799],
              [-63.1500087, 46.2331945],
              [-63.1503597, 46.2334321],
              [-63.1507364, 46.2336223],
              [-63.1509707, 46.2338591],
              [-63.1511159, 46.2340672],
              [-63.1511675, 46.2345184],
              [-63.1511588, 46.2347737],
              [-63.1513048, 46.2353793],
              [-63.1513868, 46.2358285],
              [-63.1515463, 46.2360078],
              [-63.1515064, 46.2362371],
              [-63.151417, 46.2364104],
              [-63.1513888, 46.2368327],
              [-63.1512014, 46.2372193],
              [-63.1510237, 46.2373656],
              [-63.1508324, 46.2376827],
              [-63.1507456, 46.2377557],
              [-63.1505895, 46.2387686],
              [-63.1498149, 46.2413663],
              [-63.1496657, 46.2418824],
              [-63.1509704, 46.2431051],
              [-63.1512347, 46.2433762],
              [-63.1512547, 46.2433966],
              [-63.1514509, 46.2435978],
              [-63.1520947, 46.2441736],
              [-63.1530045, 46.2447376],
              [-63.1536054, 46.2458654],
              [-63.1536771, 46.2461098],
              [-63.1538993, 46.2468668],
              [-63.1538703, 46.2472775],
              [-63.1539383, 46.2479958],
              [-63.1537357, 46.2481994],
              [-63.1536635, 46.2484373],
              [-63.1534324, 46.2487798],
              [-63.1531714, 46.2491532],
              [-63.152677, 46.2495466],
              [-63.1525529, 46.2501382],
              [-63.1522853, 46.2505305],
              [-63.1525488, 46.2507027],
              [-63.1524805, 46.2508414],
              [-63.1525118, 46.2512728],
              [-63.1527127, 46.2515627],
              [-63.1526996, 46.2517593],
              [-63.1528279, 46.2521828],
              [-63.152772, 46.2524652],
              [-63.1530332, 46.2526846],
              [-63.1532755, 46.2528026],
              [-63.1534317, 46.2528285],
              [-63.1535043, 46.2529101],
              [-63.1535989, 46.252982],
              [-63.1537324, 46.253024],
              [-63.1539118, 46.2531209],
              [-63.1539844, 46.2531585],
              [-63.1540526, 46.2531841],
              [-63.1541803, 46.2532107],
              [-63.1543161, 46.2532446],
              [-63.1544083, 46.2532825],
              [-63.1544665, 46.253334],
              [-63.1545529, 46.2533708],
              [-63.1546284, 46.2534547],
              [-63.1547229, 46.2535714],
              [-63.1547614, 46.2536504],
              [-63.1547668, 46.2538305],
              [-63.1547738, 46.2539365],
              [-63.1547522, 46.2540332],
              [-63.1547014, 46.2540995],
              [-63.1544697, 46.2543562],
              [-63.1539926, 46.2548936],
              [-63.1535187, 46.2554098],
              [-63.1533183, 46.2555447],
              [-63.1531532, 46.2557239],
              [-63.1530953, 46.2559095],
              [-63.1531678, 46.2560442],
              [-63.1533797, 46.2563274],
              [-63.153935, 46.2567029],
              [-63.1546915, 46.2569635],
              [-63.1552703, 46.2571159],
              [-63.155802, 46.2573141],
              [-63.156151, 46.257174],
              [-63.1561774, 46.257137],
              [-63.1562726, 46.2567467],
              [-63.1563512, 46.2565659],
              [-63.1564192, 46.2564242],
              [-63.1562927, 46.256214],
              [-63.156202, 46.2559768],
              [-63.1562502, 46.255397],
              [-63.1563869, 46.255094],
              [-63.156727, 46.254848],
              [-63.1568563, 46.2546842],
              [-63.1572989, 46.2543453],
              [-63.1576106, 46.2539871],
              [-63.1578865, 46.2536559],
              [-63.1580751, 46.2534538],
              [-63.1582442, 46.2527576],
              [-63.1581947, 46.2523942],
              [-63.1579859, 46.2518993],
              [-63.1577611, 46.2517024],
              [-63.1576082, 46.2516066],
              [-63.157617, 46.25149],
              [-63.1575125, 46.2509793],
              [-63.1576458, 46.2507642],
              [-63.1579606, 46.250619],
              [-63.1580952, 46.2504179],
              [-63.1582187, 46.2502378],
              [-63.1581993, 46.2499737],
              [-63.15871, 46.2498787],
              [-63.1587635, 46.2497157],
              [-63.1588738, 46.2495568],
              [-63.1593892, 46.2492059],
              [-63.1596054, 46.2488995],
              [-63.1598309, 46.2486292],
              [-63.1598266, 46.2485332],
              [-63.159782, 46.2483681],
              [-63.1600132, 46.2482241],
              [-63.1601652, 46.2478251],
              [-63.1603041, 46.2474048],
              [-63.1604733, 46.2468677],
              [-63.1606381, 46.2465375],
              [-63.1608335, 46.2464056],
              [-63.1612111, 46.2463335],
              [-63.1616146, 46.2463935],
              [-63.1617882, 46.2465256],
              [-63.162053, 46.2468077],
              [-63.1623394, 46.2471648],
              [-63.1623741, 46.2472789],
              [-63.1625564, 46.247468],
              [-63.1628508, 46.2476087],
              [-63.1630673, 46.2477965],
              [-63.1640897, 46.2485031],
              [-63.1648964, 46.2497043],
              [-63.1649871, 46.2499999],
              [-63.1654227, 46.2503624],
              [-63.1675268, 46.251252],
              [-63.1682597, 46.2517125],
              [-63.168731, 46.2519116],
              [-63.1688128, 46.2519631],
              [-63.1694639, 46.2523721],
              [-63.1700984, 46.2527001],
              [-63.170793, 46.2530587],
              [-63.1711035, 46.2531811],
              [-63.1715067, 46.2533396],
              [-63.1721859, 46.253445],
              [-63.1727928, 46.2532482],
              [-63.173133, 46.2531461],
              [-63.1736906, 46.2529173],
              [-63.1741558, 46.2526112],
              [-63.1750142, 46.2526529],
              [-63.1755603, 46.2528499],
              [-63.1759761, 46.2531512],
              [-63.1762952, 46.253382],
              [-63.1766812, 46.2538933],
              [-63.1768516, 46.2542519],
              [-63.1769531, 46.2548276],
              [-63.1774883, 46.2555576],
              [-63.1774541, 46.2566393],
              [-63.1773966, 46.2567493],
              [-63.1763384, 46.2575542],
              [-63.1759085, 46.2584149],
              [-63.176046, 46.2588523],
              [-63.1764745, 46.258908],
              [-63.176596, 46.258908],
              [-63.1768823, 46.2590165],
              [-63.1775922, 46.2588266],
              [-63.1774882, 46.2585984],
              [-63.1776347, 46.258431],
              [-63.1780441, 46.2583936],
              [-63.1785141, 46.2581867],
              [-63.1787946, 46.2580903],
              [-63.1793246, 46.2581595],
              [-63.179692, 46.2581569],
              [-63.1802776, 46.257933],
              [-63.1806876, 46.2579471],
              [-63.1810502, 46.2578751],
              [-63.1813403, 46.2578263],
              [-63.1815246, 46.2577938],
              [-63.1817285, 46.2577694],
              [-63.1821128, 46.2576691],
              [-63.1824774, 46.2575715],
              [-63.1826342, 46.2575525],
              [-63.1829322, 46.2574658],
              [-63.183144, 46.2574143],
              [-63.1834089, 46.2573949],
              [-63.1835125, 46.2574305],
              [-63.1836262, 46.257501],
              [-63.1842374, 46.2573768],
              [-63.1846205, 46.2570761],
              [-63.1852367, 46.2569246],
              [-63.1854487, 46.256711],
              [-63.1855055, 46.2562866],
              [-63.1854509, 46.2557997],
              [-63.1847149, 46.2543044],
              [-63.1845106, 46.2540445],
              [-63.1829965, 46.2530549],
              [-63.1827533, 46.2527701],
              [-63.182627, 46.2523371],
              [-63.1826992, 46.2510772],
              [-63.1828122, 46.2506742],
              [-63.182821, 46.25],
              [-63.1826735, 46.2494967],
              [-63.181711, 46.2484932],
              [-63.1811198, 46.2476304],
              [-63.1812114, 46.2471989],
              [-63.1810603, 46.2469085],
              [-63.1808345, 46.2467317],
              [-63.1808265, 46.2465564],
              [-63.1813242, 46.2461471],
              [-63.1813969, 46.2453132],
              [-63.1815566, 46.2452217],
              [-63.1818431, 46.245229],
              [-63.1819362, 46.2452035],
              [-63.1819342, 46.2451318],
              [-63.1813483, 46.2449299],
              [-63.1797199, 46.2440488],
              [-63.1791353, 46.2436041],
              [-63.1790713, 46.243296],
              [-63.1787883, 46.2430063],
              [-63.1786855, 46.2426733],
              [-63.1787853, 46.2421943],
              [-63.1788786, 46.2420574],
              [-63.1793649, 46.2417553],
              [-63.1799912, 46.2415165],
              [-63.1808436, 46.2413432],
              [-63.181672, 46.241404],
              [-63.1827254, 46.2415502],
              [-63.183508, 46.2415024],
              [-63.1841363, 46.2413353],
              [-63.1842838, 46.241148],
              [-63.1840016, 46.2410612],
              [-63.1833531, 46.2410687],
              [-63.1823474, 46.2412142],
              [-63.1819055, 46.2412189],
              [-63.1815752, 46.2411747],
              [-63.1807579, 46.2411182],
              [-63.1801901, 46.2412271],
              [-63.1796622, 46.2412296],
              [-63.179327, 46.2410619],
              [-63.1793847, 46.240729],
              [-63.1793706, 46.2405615],
              [-63.1790515, 46.2405216],
              [-63.1787597, 46.2407251],
              [-63.1784923, 46.2410088],
              [-63.1782458, 46.2410065],
              [-63.1781086, 46.2411065],
              [-63.1780162, 46.241335],
              [-63.1777297, 46.2414391],
              [-63.1775602, 46.2419323],
              [-63.1777082, 46.2421709],
              [-63.1776013, 46.2425661],
              [-63.1776263, 46.2429608],
              [-63.1779379, 46.2432513],
              [-63.1779931, 46.2432925],
              [-63.1780316, 46.2435402],
              [-63.1779598, 46.2437055],
              [-63.1779532, 46.2440476],
              [-63.17823, 46.2443452],
              [-63.1781684, 46.2445347],
              [-63.177672, 46.2447011],
              [-63.1766744, 46.2447991],
              [-63.1747359, 46.2447935],
              [-63.172546, 46.2445507],
              [-63.1722362, 46.2444433],
              [-63.1722315, 46.2442083],
              [-63.1719882, 46.244035],
              [-63.1719334, 46.2437709],
              [-63.1715133, 46.2435811],
              [-63.170732, 46.243386],
              [-63.1706666, 46.2433207],
              [-63.170492, 46.2431531],
              [-63.1703328, 46.2429102],
              [-63.1695115, 46.2421729],
              [-63.1691528, 46.241905],
              [-63.168415, 46.2414325],
              [-63.167458, 46.2412011],
              [-63.1670605, 46.2409084],
              [-63.1670811, 46.2395478],
              [-63.1667804, 46.2389471],
              [-63.1665711, 46.2386751],
              [-63.166371, 46.2384471],
              [-63.1659441, 46.2380621],
              [-63.1637505, 46.2366011],
              [-63.1631705, 46.2360769],
              [-63.1628664, 46.2356472],
              [-63.1624498, 46.2352864],
              [-63.1619226, 46.2349545],
              [-63.1611048, 46.2346947],
              [-63.1604871, 46.234663],
              [-63.1604638, 46.2345629],
              [-63.1607955, 46.2343644],
              [-63.1611838, 46.2339645],
              [-63.1619098, 46.2334697],
              [-63.1625766, 46.2329018],
              [-63.1628909, 46.2327069],
              [-63.1633545, 46.2325078],
              [-63.1639968, 46.2325083],
              [-63.1648576, 46.2327134],
              [-63.1652029, 46.2329053],
              [-63.165656, 46.232905],
              [-63.1661336, 46.2328734],
              [-63.1672704, 46.2331731],
              [-63.1676121, 46.2331102],
              [-63.1678681, 46.2329337],
              [-63.1680392, 46.2327351],
              [-63.1684415, 46.2325026],
              [-63.1692057, 46.2323669],
              [-63.1704078, 46.2320832],
              [-63.1708387, 46.2318515],
              [-63.1710245, 46.2317056],
              [-63.1705877, 46.2317039],
              [-63.1692058, 46.2316068],
              [-63.1687445, 46.2316547],
              [-63.1679612, 46.2316108],
              [-63.1658024, 46.2315517],
              [-63.1640602, 46.2313161],
              [-63.1639403, 46.2314125],
              [-63.1642456, 46.2317108],
              [-63.1643036, 46.2319153],
              [-63.1642217, 46.231945],
              [-63.1640253, 46.2319718],
              [-63.1636308, 46.2318423],
              [-63.1627657, 46.2311794],
              [-63.1624866, 46.2311444],
              [-63.1624331, 46.2312863],
              [-63.1625403, 46.2314284],
              [-63.1624298, 46.2314574],
              [-63.1621733, 46.2313195],
              [-63.1619959, 46.2309886],
              [-63.1611355, 46.2300234],
              [-63.1606523, 46.2291912],
              [-63.1604933, 46.2288369],
              [-63.1604373, 46.2281669],
              [-63.1606198, 46.2273239],
              [-63.1605998, 46.2270528],
              [-63.1605162, 46.2268994],
              [-63.1592519, 46.2259835],
              [-63.158759, 46.2254416],
              [-63.1583613, 46.2248345],
              [-63.1580361, 46.2237278],
              [-63.1579188, 46.2235616],
              [-63.1579294, 46.2233629],
              [-63.1583688, 46.2229722],
              [-63.1591891, 46.2216721],
              [-63.1603062, 46.2201806],
              [-63.1609453, 46.2189434],
              [-63.1611088, 46.2184581],
              [-63.1611989, 46.2183809],
              [-63.1612316, 46.2177649],
              [-63.1610486, 46.2172189],
              [-63.1610577, 46.216077],
              [-63.1611461, 46.2158167],
              [-63.1613095, 46.2153314],
              [-63.161641, 46.2145957],
              [-63.1617174, 46.2141281],
              [-63.1616688, 46.2138564],
              [-63.1611635, 46.2134415],
              [-63.1610229, 46.2131753],
              [-63.1611794, 46.2131435],
              [-63.1615267, 46.2132956],
              [-63.1622868, 46.2135538],
              [-63.1627827, 46.2134988],
              [-63.1632886, 46.2135794],
              [-63.1647801, 46.2135579],
              [-63.1666236, 46.2133863],
              [-63.1671602, 46.2134279],
              [-63.1677653, 46.2134752],
              [-63.1691172, 46.2138162],
              [-63.1693543, 46.2138859],
              [-63.1723154, 46.2147971],
              [-63.172578, 46.2149271],
              [-63.1733268, 46.2152925],
              [-63.1739103, 46.2154227],
              [-63.1745836, 46.2156986],
              [-63.175203, 46.2158019],
              [-63.1766653, 46.2155765],
              [-63.1780097, 46.2155192],
              [-63.1784482, 46.2155741],
              [-63.178861, 46.2156802],
              [-63.1792351, 46.2157613],
              [-63.1800137, 46.2161074],
              [-63.1804763, 46.2164654],
              [-63.1805814, 46.2166471],
              [-63.1808654, 46.2168056],
              [-63.1815359, 46.2169181],
              [-63.1818362, 46.2170929],
              [-63.1820739, 46.2174769],
              [-63.1823762, 46.2177235],
              [-63.1826184, 46.2178053],
              [-63.1830826, 46.2178091],
              [-63.1837709, 46.2175838],
              [-63.1845868, 46.2175487],
              [-63.1850736, 46.2174496],
              [-63.1854368, 46.2171922],
              [-63.185484, 46.2170581],
              [-63.1854848, 46.2163896],
              [-63.185295, 46.2160744],
              [-63.1849037, 46.2158854],
              [-63.184673, 46.2155851],
              [-63.1845886, 46.2152288],
              [-63.184631, 46.2146577],
              [-63.1846676, 46.2141643],
              [-63.1845979, 46.2140843],
              [-63.1842491, 46.2138261],
              [-63.1841414, 46.2137551],
              [-63.1840896, 46.2136632],
              [-63.1841503, 46.2133672],
              [-63.1841537, 46.2131239],
              [-63.1841711, 46.2130088],
              [-63.1842106, 46.2129283],
              [-63.1844539, 46.212744],
              [-63.1848546, 46.2123234],
              [-63.1850132, 46.2120566],
              [-63.185177, 46.2116934],
              [-63.1855323, 46.2113568],
              [-63.1858917, 46.2112174],
              [-63.1861328, 46.2110745],
              [-63.1867085, 46.2109217],
              [-63.187804, 46.2108236],
              [-63.188186, 46.2108712],
              [-63.1889952, 46.211226],
              [-63.1916303, 46.2123473],
              [-63.1925316, 46.2126486],
              [-63.1930098, 46.2128199],
              [-63.1953288, 46.2133043],
              [-63.1956843, 46.2135201],
              [-63.1960508, 46.2140745],
              [-63.1961008, 46.2142151],
              [-63.196229, 46.2148311],
              [-63.1964178, 46.2151662],
              [-63.1969203, 46.2156404],
              [-63.1973829, 46.2162212],
              [-63.1975229, 46.2162844],
              [-63.199303, 46.216007],
              [-63.2009325, 46.2160879],
              [-63.2021411, 46.2158875],
              [-63.2036666, 46.2155439],
              [-63.2052118, 46.2149342],
              [-63.2060198, 46.2143894],
              [-63.2067028, 46.2142633],
              [-63.2067979, 46.2141981],
              [-63.2070823, 46.2139109],
              [-63.2072715, 46.2139116],
              [-63.2082219, 46.2142617],
              [-63.2086522, 46.2143641],
              [-63.2093891, 46.2147447],
              [-63.2104814, 46.215564],
              [-63.2110912, 46.2162916],
              [-63.2113866, 46.2164542],
              [-63.21191, 46.216531],
              [-63.2118518, 46.216438],
              [-63.2116198, 46.216269],
              [-63.2114206, 46.2160213],
              [-63.211384, 46.2157339],
              [-63.211518, 46.215582],
              [-63.2120016, 46.2155424],
              [-63.2126241, 46.2156973],
              [-63.2132773, 46.2158132],
              [-63.2133744, 46.2158196],
              [-63.2139387, 46.2157701],
              [-63.2145797, 46.2157901],
              [-63.2148179, 46.2158398],
              [-63.2150161, 46.2161074],
              [-63.2148206, 46.2165602],
              [-63.2149666, 46.2169498],
              [-63.2152537, 46.2172714],
              [-63.215318, 46.2173565],
              [-63.2153476, 46.2175603],
              [-63.2151081, 46.2179761],
              [-63.2150692, 46.2200484],
              [-63.2153074, 46.2200981],
              [-63.2155388, 46.2198431],
              [-63.2156358, 46.2197363],
              [-63.2157708, 46.219676],
              [-63.2160718, 46.2187364],
              [-63.216836, 46.217709],
              [-63.2171664, 46.2175302],
              [-63.2176409, 46.2172237],
              [-63.217873, 46.2171698],
              [-63.2184997, 46.2170223],
              [-63.2189845, 46.21674],
              [-63.2194476, 46.2166521],
              [-63.2196992, 46.2165549],
              [-63.219736, 46.2165081],
              [-63.2196798, 46.2163754],
              [-63.2193947, 46.2161255],
              [-63.2190574, 46.2158863],
              [-63.2190207, 46.2157103],
              [-63.2192561, 46.2151511],
              [-63.2192132, 46.2150943],
              [-63.2189607, 46.2150999],
              [-63.2179073, 46.215957],
              [-63.2175494, 46.2161152],
              [-63.2171691, 46.2161534],
              [-63.2169197, 46.215988],
              [-63.2165243, 46.2155443],
              [-63.2156117, 46.2149047],
              [-63.2145742, 46.2146837],
              [-63.2138596, 46.2146459],
              [-63.2133709, 46.2147849],
              [-63.2132093, 46.2148047],
              [-63.2127873, 46.2145434],
              [-63.2114091, 46.21316],
              [-63.2104813, 46.2124841],
              [-63.2099672, 46.2123399],
              [-63.208992, 46.2122438],
              [-63.2076358, 46.2118714],
              [-63.2070746, 46.2118614],
              [-63.2065501, 46.2119157],
              [-63.2064153, 46.2118646],
              [-63.2055116, 46.211826],
              [-63.204972, 46.2117329],
              [-63.2039418, 46.2113727],
              [-63.2032345, 46.2113071],
              [-63.2027788, 46.211033],
              [-63.2017973, 46.2100732],
              [-63.2010846, 46.2091241],
              [-63.2008337, 46.2085527],
              [-63.2007992, 46.208337],
              [-63.200735, 46.208029],
              [-63.2004788, 46.2076684],
              [-63.1993155, 46.2066801],
              [-63.1988817, 46.2059887],
              [-63.1984946, 46.2057203],
              [-63.1983772, 46.2055542],
              [-63.1983697, 46.2048218],
              [-63.1984599, 46.2045217],
              [-63.2002185, 46.2037702],
              [-63.200742, 46.2035128],
              [-63.2012019, 46.2034846],
              [-63.2027522, 46.2036471],
              [-63.2034961, 46.2037772],
              [-63.2037547, 46.2037638],
              [-63.204278, 46.2037292],
              [-63.2045499, 46.2037918],
              [-63.204891, 46.204063],
              [-63.204951, 46.2044505],
              [-63.205234, 46.2046287],
              [-63.2056029, 46.2046977],
              [-63.2056683, 46.204763],
              [-63.2058134, 46.2047268],
              [-63.2060761, 46.2047454],
              [-63.206436, 46.2045475],
              [-63.2069583, 46.2045328],
              [-63.2071924, 46.2044392],
              [-63.2073275, 46.2041561],
              [-63.208034, 46.2037959],
              [-63.2082334, 46.2035979],
              [-63.2086621, 46.2029601],
              [-63.2090547, 46.2026835],
              [-63.2096058, 46.2024466],
              [-63.2098921, 46.2021196],
              [-63.210252, 46.2019217],
              [-63.2106939, 46.2012484],
              [-63.2111897, 46.2010818],
              [-63.2115342, 46.2008477],
              [-63.21208, 46.2008216],
              [-63.2123692, 46.2008806],
              [-63.2129025, 46.2010929],
              [-63.2132856, 46.2013294],
              [-63.2135207, 46.2013273],
              [-63.2135842, 46.2010981],
              [-63.2132808, 46.2007602],
              [-63.2129897, 46.2005182],
              [-63.212893, 46.1999547],
              [-63.2126581, 46.1996225],
              [-63.2119808, 46.1992037],
              [-63.2117929, 46.1989602],
              [-63.2113036, 46.1986734],
              [-63.2110892, 46.1983895],
              [-63.2102944, 46.1979159],
              [-63.2095633, 46.1971018],
              [-63.2092775, 46.1965375],
              [-63.2092164, 46.1961699],
              [-63.2104265, 46.1958183],
              [-63.2111041, 46.1956802],
              [-63.2132847, 46.1958383],
              [-63.2139376, 46.1958428],
              [-63.2140929, 46.1959422],
              [-63.2142257, 46.196033],
              [-63.2144045, 46.1960097],
              [-63.2146989, 46.1959693],
              [-63.2152395, 46.1958197],
              [-63.2156095, 46.1956459],
              [-63.2158109, 46.1955197],
              [-63.2161626, 46.1951465],
              [-63.2162873, 46.1949507],
              [-63.2162884, 46.1948194],
              [-63.2158911, 46.194527],
              [-63.215886, 46.1944035],
              [-63.2158881, 46.1943637],
              [-63.216163, 46.1942552],
              [-63.2167618, 46.19431],
              [-63.2175352, 46.1945323],
              [-63.2190995, 46.1947505],
              [-63.2208997, 46.1953924],
              [-63.2216886, 46.195428],
              [-63.2223426, 46.1953011],
              [-63.2228362, 46.1952856],
              [-63.2231417, 46.1953608],
              [-63.223349, 46.195561],
              [-63.2234827, 46.196189],
              [-63.2239751, 46.1965276],
              [-63.2242888, 46.1965553],
              [-63.2246322, 46.1964524],
              [-63.2249307, 46.1962212],
              [-63.2251638, 46.1958132],
              [-63.2255828, 46.1955769],
              [-63.2274866, 46.1951032],
              [-63.2278279, 46.1951514],
              [-63.2288099, 46.1949969],
              [-63.2295334, 46.1950785],
              [-63.2302905, 46.1955072],
              [-63.2306726, 46.195652],
              [-63.2309536, 46.1956471],
              [-63.2312306, 46.1954988],
              [-63.2313594, 46.1953349],
              [-63.2314248, 46.1948431],
              [-63.2317682, 46.1945174],
              [-63.2317652, 46.1942428],
              [-63.2316702, 46.1940853],
              [-63.2318153, 46.1939377],
              [-63.2322312, 46.193761],
              [-63.2323344, 46.1936482],
              [-63.2327846, 46.1933137],
              [-63.2329683, 46.1932057],
              [-63.2332123, 46.1932265],
              [-63.233267, 46.1930164],
              [-63.2330432, 46.1929767],
              [-63.2329473, 46.1929086],
              [-63.2328837, 46.1928034],
              [-63.2328682, 46.1926671],
              [-63.2330463, 46.1918858],
              [-63.2331021, 46.1918086],
              [-63.2332139, 46.1917582],
              [-63.2335697, 46.1917988],
              [-63.2337056, 46.1918504],
              [-63.2338032, 46.1915759],
              [-63.2338845, 46.1910843],
              [-63.2337035, 46.1911095],
              [-63.2333479, 46.1910697],
              [-63.2332699, 46.1910448],
              [-63.233231, 46.1909952],
              [-63.2333638, 46.1903515],
              [-63.2335242, 46.1901287],
              [-63.2336949, 46.1895958],
              [-63.2336592, 46.1890657],
              [-63.233126, 46.1881851],
              [-63.2324219, 46.1875],
              [-63.2323077, 46.1873889],
              [-63.2322516, 46.1870334],
              [-63.23255, 46.1862451],
              [-63.2323755, 46.1849635],
              [-63.2323449, 46.1847798],
              [-63.2324777, 46.184425],
              [-63.2327199, 46.184061],
              [-63.2326055, 46.1837239],
              [-63.2326056, 46.1833897],
              [-63.2328947, 46.1831144],
              [-63.2328927, 46.1827086],
              [-63.2326598, 46.1825595],
              [-63.2323574, 46.1825361],
              [-63.2316434, 46.182387],
              [-63.2306086, 46.1823493],
              [-63.2299803, 46.1824252],
              [-63.2295666, 46.1825621],
              [-63.2294102, 46.1828168],
              [-63.2294734, 46.1837017],
              [-63.229272, 46.1841622],
              [-63.2287581, 46.1844637],
              [-63.2281441, 46.184707],
              [-63.2262887, 46.1852496],
              [-63.2259035, 46.1852758],
              [-63.2255174, 46.1852105],
              [-63.2250485, 46.184972],
              [-63.2248943, 46.1847414],
              [-63.2251377, 46.1839119],
              [-63.225474, 46.1835025],
              [-63.2253689, 46.1830981],
              [-63.2253597, 46.1828313],
              [-63.225559, 46.1825219],
              [-63.2240763, 46.1807343],
              [-63.2232717, 46.1799026],
              [-63.2229164, 46.1793526],
              [-63.22279, 46.1785855],
              [-63.2219977, 46.1777382],
              [-63.2217526, 46.1773819],
              [-63.2212502, 46.1770193],
              [-63.220753, 46.1765573],
              [-63.2203802, 46.1764564],
              [-63.2199391, 46.1761271],
              [-63.2195962, 46.1755615],
              [-63.2194565, 46.17483],
              [-63.2192166, 46.1745972],
              [-63.2190001, 46.1745758],
              [-63.218755, 46.1744424],
              [-63.218748, 46.1742473],
              [-63.218841, 46.1741104],
              [-63.2188912, 46.1736939],
              [-63.2187464, 46.1732844],
              [-63.2187894, 46.1728956],
              [-63.2191594, 46.1722763],
              [-63.2191564, 46.1722245],
              [-63.2186018, 46.172318],
              [-63.2179747, 46.1722625],
              [-63.2175337, 46.1719332],
              [-63.2173927, 46.1720013],
              [-63.216787, 46.1720856],
              [-63.2163007, 46.1724076],
              [-63.2158666, 46.1724962],
              [-63.2155805, 46.1727118],
              [-63.2162553, 46.1732817],
              [-63.2164064, 46.1734606],
              [-63.2164073, 46.1737749],
              [-63.2156818, 46.1744012],
              [-63.2156336, 46.1746666],
              [-63.2157336, 46.1748362],
              [-63.2163198, 46.1751294],
              [-63.2167333, 46.1754381],
              [-63.2169455, 46.1758731],
              [-63.2173203, 46.1760457],
              [-63.2179882, 46.1760863],
              [-63.2182404, 46.1763034],
              [-63.2182934, 46.1767185],
              [-63.2180951, 46.177008],
              [-63.2177639, 46.1774294],
              [-63.2177128, 46.1776429],
              [-63.2177573, 46.1784398],
              [-63.217844, 46.1788677],
              [-63.2178938, 46.1793424],
              [-63.2178916, 46.1797163],
              [-63.2176084, 46.1802066],
              [-63.2172466, 46.1805556],
              [-63.216892, 46.1808769],
              [-63.2164864, 46.1809662],
              [-63.2155087, 46.181264],
              [-63.2133182, 46.1818616],
              [-63.2124578, 46.182214],
              [-63.2118508, 46.1825409],
              [-63.2106449, 46.1833701],
              [-63.2078419, 46.1845052],
              [-63.2078184, 46.1845166],
              [-63.2074556, 46.184774],
              [-63.2074758, 46.1851565],
              [-63.2073797, 46.1852416],
              [-63.2070453, 46.1856112],
              [-63.2069748, 46.1856452],
              [-63.2066403, 46.1862376],
              [-63.206309, 46.186659],
              [-63.2059226, 46.1869278],
              [-63.2055385, 46.186934],
              [-63.2051574, 46.1868806],
              [-63.2044272, 46.1866036],
              [-63.2037171, 46.1865977],
              [-63.2032829, 46.1866862],
              [-63.2031531, 46.1867586],
              [-63.2028884, 46.1868911],
              [-63.2029291, 46.1869877],
              [-63.2031466, 46.187212],
              [-63.2032856, 46.1875],
              [-63.203374, 46.1876832],
              [-63.2033605, 46.1880529],
              [-63.2032755, 46.1882537],
              [-63.2030625, 46.1888213],
              [-63.2022789, 46.1900032],
              [-63.2019259, 46.1906189],
              [-63.2012192, 46.1914248],
              [-63.2006568, 46.1919915],
              [-63.2004247, 46.1921567],
              [-63.1999188, 46.1922991],
              [-63.1993434, 46.1924557],
              [-63.198861, 46.1925868],
              [-63.1971093, 46.1929962],
              [-63.1955078, 46.1933815],
              [-63.1943549, 46.1936229],
              [-63.1941536, 46.1936377],
              [-63.1926207, 46.1938058],
              [-63.1920915, 46.1937367],
              [-63.1918566, 46.1936273],
              [-63.1917454, 46.1934534],
              [-63.1917615, 46.1927098],
              [-63.1916546, 46.192345],
              [-63.1912953, 46.1919857],
              [-63.1907641, 46.1919564],
              [-63.1891281, 46.1920144],
              [-63.188744, 46.1920206],
              [-63.1880675, 46.1920273],
              [-63.1869458, 46.1918954],
              [-63.186427, 46.1916276],
              [-63.1863159, 46.1914537],
              [-63.1862879, 46.1908959],
              [-63.186034, 46.1904956],
              [-63.1859658, 46.190267],
              [-63.185859, 46.1899022],
              [-63.1855866, 46.1895253],
              [-63.1847026, 46.188906],
              [-63.1843931, 46.1887987],
              [-63.1843882, 46.1886752],
              [-63.1845313, 46.1885674],
              [-63.1845345, 46.1885078],
              [-63.1844293, 46.1884375],
              [-63.1835915, 46.188464],
              [-63.183088, 46.1883438],
              [-63.1827541, 46.1881562],
              [-63.1825652, 46.188044],
              [-63.1817873, 46.1875],
              [-63.1807468, 46.1875],
              [-63.1808484, 46.1875746],
              [-63.1811862, 46.1879055],
              [-63.1819889, 46.1881089],
              [-63.1823891, 46.1883419],
              [-63.1825717, 46.1885734],
              [-63.1830146, 46.1889745],
              [-63.1841424, 46.1890987],
              [-63.1843629, 46.1892634],
              [-63.1843656, 46.1895381],
              [-63.1839615, 46.1900331],
              [-63.1839913, 46.1906625],
              [-63.1838941, 46.1907675],
              [-63.1840365, 46.1913282],
              [-63.1841719, 46.1916937],
              [-63.1841642, 46.192167],
              [-63.1839972, 46.1925089],
              [-63.183735, 46.1930275],
              [-63.1832008, 46.1937062],
              [-63.18267, 46.1941026],
              [-63.1818267, 46.1943398],
              [-63.1810133, 46.194335],
              [-63.1804973, 46.1943418],
              [-63.1798097, 46.1942328],
              [-63.1789549, 46.1939285],
              [-63.1782398, 46.1937989],
              [-63.1778875, 46.1936348],
              [-63.1773208, 46.193298],
              [-63.177272, 46.1931376],
              [-63.17736, 46.1931001],
              [-63.1777909, 46.1932941],
              [-63.1781668, 46.1933355],
              [-63.1783745, 46.1932015],
              [-63.1784185, 46.193127],
              [-63.1783996, 46.1919647],
              [-63.178197, 46.1914622],
              [-63.1777632, 46.191105],
              [-63.1761172, 46.1903786],
              [-63.1754492, 46.1902263],
              [-63.1744899, 46.1901661],
              [-63.1740559, 46.1900316],
              [-63.1732617, 46.1895578],
              [-63.1720431, 46.1893078],
              [-63.1715328, 46.1889924],
              [-63.1713379, 46.1887766],
              [-63.1711668, 46.188438],
              [-63.1709478, 46.1880305],
              [-63.1708472, 46.1876579],
              [-63.1707796, 46.1875],
              [-63.1700988, 46.1875],
              [-63.1703655, 46.1879917],
              [-63.1704861, 46.1886354],
              [-63.1704875, 46.1890413],
              [-63.1706402, 46.1894033],
              [-63.1706041, 46.1903016],
              [-63.170845, 46.1904033],
              [-63.1714214, 46.1903384],
              [-63.1714981, 46.1902966],
              [-63.1720406, 46.1903304],
              [-63.1738206, 46.190905],
              [-63.1746409, 46.1909935],
              [-63.1757891, 46.1911661],
              [-63.1761821, 46.1914268],
              [-63.176479, 46.1917725],
              [-63.1768163, 46.1924377],
              [-63.1767719, 46.1927349],
              [-63.1760382, 46.1935001],
              [-63.1761343, 46.194175],
              [-63.1763145, 46.194669],
              [-63.1766535, 46.1948686],
              [-63.1769476, 46.1949398],
              [-63.1774866, 46.1953673],
              [-63.1782171, 46.1961817],
              [-63.1783139, 46.196411],
              [-63.1785251, 46.1966432],
              [-63.1790467, 46.1970743],
              [-63.1791659, 46.1973121],
              [-63.1792332, 46.1974491],
              [-63.1791893, 46.1981721],
              [-63.1781639, 46.2000122],
              [-63.1770852, 46.2011228],
              [-63.1766913, 46.2014191],
              [-63.1761208, 46.2015876],
              [-63.1753512, 46.2016198],
              [-63.1749671, 46.2015145],
              [-63.1739481, 46.2008439],
              [-63.1732482, 46.2007504],
              [-63.1719432, 46.2007211],
              [-63.1708228, 46.2008835],
              [-63.1695835, 46.2006966],
              [-63.1688897, 46.2005953],
              [-63.1678473, 46.2005846],
              [-63.1659992, 46.2008557],
              [-63.1649134, 46.2011223],
              [-63.1644592, 46.2013654],
              [-63.1621508, 46.2024244],
              [-63.161031, 46.2027896],
              [-63.1607527, 46.2029575],
              [-63.160334, 46.2033964],
              [-63.15875, 46.2044063],
              [-63.1584692, 46.2048368],
              [-63.1570658, 46.2060065],
              [-63.1568704, 46.2061248],
              [-63.1564784, 46.2063814],
              [-63.1561562, 46.2065124],
              [-63.1554925, 46.2067062],
              [-63.15524, 46.2067116],
              [-63.1539756, 46.2067786],
              [-63.1534176, 46.2067086],
              [-63.1527168, 46.2065234],
              [-63.1516283, 46.2059781],
              [-63.1512762, 46.2057024],
              [-63.1512765, 46.205591],
              [-63.1513028, 46.2052057],
              [-63.1511355, 46.2050104],
              [-63.1510643, 46.2048414],
              [-63.1511496, 46.2046407],
              [-63.1511072, 46.204361],
              [-63.151093, 46.2043049],
              [-63.1507695, 46.20403],
              [-63.1504938, 46.2039354],
              [-63.1502627, 46.2039692],
              [-63.1500702, 46.2041393],
              [-63.1499719, 46.2042641],
              [-63.1497334, 46.204437],
              [-63.149387, 46.2043764],
              [-63.1490688, 46.2045393],
              [-63.1486461, 46.2048348],
              [-63.1485734, 46.2049085],
              [-63.148662, 46.205074],
              [-63.1485595, 46.205704],
              [-63.1482365, 46.2067064],
              [-63.1482458, 46.2071762],
              [-63.1476185, 46.207546],
              [-63.1474794, 46.2075743],
              [-63.1469493, 46.2074134],
              [-63.1463012, 46.2074205],
              [-63.1456436, 46.2076065],
              [-63.1451339, 46.2079197],
              [-63.1447109, 46.2083266],
              [-63.1443266, 46.2083326],
              [-63.1439904, 46.208296],
              [-63.1437288, 46.2082574],
              [-63.1434683, 46.208199],
              [-63.1431406, 46.2080035],
              [-63.1428591, 46.2076938],
              [-63.1421332, 46.2073368],
              [-63.1419788, 46.206903],
              [-63.1417482, 46.206714],
              [-63.141635, 46.2065798],
              [-63.1415157, 46.2064533],
              [-63.1410634, 46.2062307],
              [-63.1404696, 46.2057617],
              [-63.1404618, 46.2055864],
              [-63.1407864, 46.2053043],
              [-63.1411742, 46.2051273],
              [-63.1413124, 46.2050076],
              [-63.141418, 46.2048551],
              [-63.14139, 46.2047738],
              [-63.1417509, 46.2045255],
              [-63.1421344, 46.204428],
              [-63.1425213, 46.2041594],
              [-63.1430367, 46.2040613],
              [-63.1430543, 46.2039464],
              [-63.142851, 46.2038894],
              [-63.1418901, 46.20396],
              [-63.1417575, 46.2038691],
              [-63.1413628, 46.2029994],
              [-63.1412817, 46.2026948],
              [-63.1413164, 46.202262],
              [-63.1415223, 46.2019215],
              [-63.1415769, 46.2017564],
              [-63.1414165, 46.2013573],
              [-63.1410102, 46.2007062],
              [-63.1406555, 46.2002672],
              [-63.1405308, 46.1998144],
              [-63.1401829, 46.1995706],
              [-63.1400481, 46.1995194],
              [-63.1374336, 46.1992249],
              [-63.1356128, 46.1990901],
              [-63.1348119, 46.1989579],
              [-63.1344413, 46.198817],
              [-63.1339517, 46.1984382],
              [-63.1331882, 46.1976107],
              [-63.1330987, 46.1974651],
              [-63.1322409, 46.1963685],
              [-63.1318553, 46.1960799],
              [-63.1315435, 46.1959538],
              [-63.1311309, 46.1960173],
              [-63.1310553, 46.1960729],
              [-63.1309595, 46.1961015],
              [-63.13077, 46.1960189],
              [-63.1306591, 46.1958671],
              [-63.1306935, 46.1956146],
              [-63.1307165, 46.195468],
              [-63.1306362, 46.1952806],
              [-63.1304088, 46.1951394],
              [-63.1303786, 46.1949225],
              [-63.1303826, 46.1944858],
              [-63.130573, 46.1939297],
              [-63.1306425, 46.1934898],
              [-63.1306543, 46.1932713],
              [-63.1304648, 46.1930674],
              [-63.1304594, 46.192741],
              [-63.1308375, 46.1923171],
              [-63.1308463, 46.1920467],
              [-63.1304969, 46.1916199],
              [-63.1299307, 46.1912829],
              [-63.1296967, 46.1912648],
              [-63.129216, 46.1914672],
              [-63.1289107, 46.1913916],
              [-63.1287781, 46.1913006],
              [-63.128673, 46.1912303],
              [-63.1286674, 46.1910152],
              [-63.1290139, 46.1905388],
              [-63.1290958, 46.1900834],
              [-63.1295263, 46.1897282],
              [-63.1305978, 46.1887894],
              [-63.1308296, 46.1884214],
              [-63.1309312, 46.188237],
              [-63.1311266, 46.1881188],
              [-63.1315471, 46.1875],
              [-63.1315809, 46.1874502],
              [-63.132022, 46.1871314],
              [-63.1331277, 46.186599],
              [-63.1335225, 46.1863944],
              [-63.1351967, 46.1851964],
              [-63.1369179, 46.1839758],
              [-63.1372028, 46.1835773],
              [-63.1371966, 46.1831593],
              [-63.1370979, 46.1829698],
              [-63.1367552, 46.1823123],
              [-63.1365552, 46.1821957],
              [-63.1361313, 46.1821967],
              [-63.1360211, 46.1821143],
              [-63.1361363, 46.181783],
              [-63.1363451, 46.1816293],
              [-63.1368884, 46.1812176],
              [-63.1368913, 46.1808437],
              [-63.1370864, 46.1804111],
              [-63.1373625, 46.1802829],
              [-63.137475, 46.1802142],
              [-63.1382597, 46.1796815],
              [-63.1389416, 46.1794644],
              [-63.1392874, 46.1792108],
              [-63.1395319, 46.1790301],
              [-63.1395525, 46.178967],
              [-63.1401121, 46.1785716],
              [-63.1415901, 46.1781404],
              [-63.1424512, 46.1776773],
              [-63.1430971, 46.1774871],
              [-63.1436613, 46.1773266],
              [-63.1439948, 46.1770885],
              [-63.1439332, 46.1767408],
              [-63.1442555, 46.1764984],
              [-63.1443776, 46.1762509],
              [-63.1446039, 46.1759822],
              [-63.1451352, 46.1759005],
              [-63.1456466, 46.1755476],
              [-63.1459093, 46.175455],
              [-63.1487938, 46.1744233],
              [-63.1490198, 46.174266],
              [-63.1490486, 46.1741553],
              [-63.1499665, 46.173805],
              [-63.1508725, 46.1732475],
              [-63.1511701, 46.1730363],
              [-63.151677, 46.1728743],
              [-63.1521742, 46.1724654],
              [-63.1528518, 46.1716791],
              [-63.1532293, 46.1713666],
              [-63.1544415, 46.1703275],
              [-63.1548844, 46.1699689],
              [-63.1549899, 46.1698165],
              [-63.1553345, 46.1695826],
              [-63.1559856, 46.169293],
              [-63.1576134, 46.1687261],
              [-63.158613, 46.1682346],
              [-63.1589234, 46.1683222],
              [-63.1594859, 46.1684042],
              [-63.159918, 46.1683557],
              [-63.1605586, 46.1681533],
              [-63.161418, 46.1678212],
              [-63.1622215, 46.1673565],
              [-63.1622973, 46.1672231],
              [-63.1623361, 46.1665995],
              [-63.1624028, 46.1664222],
              [-63.1628024, 46.1662295],
              [-63.1631262, 46.1661702],
              [-63.1632523, 46.1664917],
              [-63.1636361, 46.166597],
              [-63.1641274, 46.1665102],
              [-63.1648161, 46.1662652],
              [-63.1656081, 46.1659075],
              [-63.1662296, 46.1655256],
              [-63.1663954, 46.165315],
              [-63.1663804, 46.1651674],
              [-63.1662601, 46.1649495],
              [-63.1662584, 46.1647664],
              [-63.1663668, 46.1646658],
              [-63.1671366, 46.1647253],
              [-63.1674839, 46.1646546],
              [-63.1684697, 46.1644212],
              [-63.1689784, 46.1643308],
              [-63.1697099, 46.1641426],
              [-63.1706867, 46.1637538],
              [-63.1713906, 46.163545],
              [-63.1717857, 46.1635432],
              [-63.1718682, 46.1637164],
              [-63.1722446, 46.1639607],
              [-63.1728675, 46.1639846],
              [-63.1737255, 46.1637837],
              [-63.1750733, 46.16309],
              [-63.1755476, 46.1627838],
              [-63.1764438, 46.1622935],
              [-63.1782979, 46.1616604],
              [-63.1797549, 46.1609575],
              [-63.1800572, 46.1608697],
              [-63.1804553, 46.1609197],
              [-63.1809216, 46.1611981],
              [-63.1816598, 46.1612049],
              [-63.1817935, 46.161276],
              [-63.1822782, 46.161531],
              [-63.1839709, 46.1616774],
              [-63.1843089, 46.1616741],
              [-63.1846174, 46.1615785],
              [-63.1856186, 46.161047],
              [-63.1862817, 46.1607415],
              [-63.1865493, 46.1605494],
              [-63.1874845, 46.1597496],
              [-63.1880014, 46.1596116],
              [-63.1882474, 46.1596138],
              [-63.1885447, 46.1595139],
              [-63.1890368, 46.1595185],
              [-63.1893202, 46.1597882],
              [-63.1893436, 46.1598883],
              [-63.1897324, 46.160117],
              [-63.1907279, 46.1601302],
              [-63.1917501, 46.1599612],
              [-63.1925796, 46.159648],
              [-63.1948627, 46.1584685],
              [-63.1957363, 46.1578581],
              [-63.1961857, 46.1576944],
              [-63.1964044, 46.1574533],
              [-63.1967404, 46.1573782],
              [-63.197008, 46.1571861],
              [-63.1974951, 46.1570671],
              [-63.1993635, 46.1559327],
              [-63.199526, 46.1556703],
              [-63.1995917, 46.15529],
              [-63.1998441, 46.1550617],
              [-63.2010371, 46.1543598],
              [-63.2018081, 46.154065],
              [-63.2033662, 46.1538258],
              [-63.2039645, 46.1537692],
              [-63.2045157, 46.1538468],
              [-63.2051344, 46.1538385],
              [-63.205846, 46.1536933],
              [-63.2059421, 46.1536082],
              [-63.206762, 46.1533623],
              [-63.2068509, 46.1533049],
              [-63.2078484, 46.1531668],
              [-63.2082475, 46.1531968],
              [-63.2086149, 46.153397],
              [-63.2086737, 46.154027],
              [-63.2088665, 46.1541711],
              [-63.2095475, 46.1540649],
              [-63.2103183, 46.1539928],
              [-63.2106002, 46.1538566],
              [-63.2106911, 46.1537595],
              [-63.2107751, 46.1533558],
              [-63.2109876, 46.1531224],
              [-63.2112163, 46.1530168],
              [-63.2117369, 46.1530219],
              [-63.2121595, 46.1531519],
              [-63.212966, 46.1531642],
              [-63.2132733, 46.1530883],
              [-63.2140483, 46.1528253],
              [-63.2143198, 46.1528878],
              [-63.2145209, 46.1529844],
              [-63.2147361, 46.1532484],
              [-63.2147483, 46.1534555],
              [-63.2144511, 46.1535555],
              [-63.2142204, 46.1535895],
              [-63.2140631, 46.1537526],
              [-63.2140026, 46.1542563],
              [-63.2141965, 46.1543805],
              [-63.2148968, 46.1543424],
              [-63.215643, 46.1545242],
              [-63.2162667, 46.1547507],
              [-63.2176519, 46.1550838],
              [-63.2185625, 46.1551862],
              [-63.2194119, 46.1551438],
              [-63.2199602, 46.1549466],
              [-63.2204872, 46.1546097],
              [-63.2209927, 46.1541331],
              [-63.2214635, 46.1537749],
              [-63.2218484, 46.1537487],
              [-63.2221372, 46.1538076],
              [-63.2229059, 46.1541093],
              [-63.2232948, 46.1542264],
              [-63.2241095, 46.1539683],
              [-63.224375, 46.1540386],
              [-63.224287, 46.1544103],
              [-63.2251393, 46.1551994],
              [-63.2253956, 46.1550029],
              [-63.225465, 46.1551001],
              [-63.225426, 46.1557436],
              [-63.2257495, 46.1561296],
              [-63.2260507, 46.156173],
              [-63.2262314, 46.1559984],
              [-63.2261019, 46.1554025],
              [-63.2261204, 46.1549335],
              [-63.2258826, 46.154661],
              [-63.2257429, 46.1542637],
              [-63.2258787, 46.1540721],
              [-63.2261666, 46.1540396],
              [-63.2262545, 46.1538906],
              [-63.2258647, 46.1532364],
              [-63.2258209, 46.1528653],
              [-63.2258913, 46.1528313],
              [-63.2262088, 46.1530023],
              [-63.2263976, 46.153003],
              [-63.2264446, 46.1528689],
              [-63.2263579, 46.1526639],
              [-63.2263722, 46.1524972],
              [-63.2266387, 46.1523248],
              [-63.2270879, 46.1522724],
              [-63.2280536, 46.1525272],
              [-63.228613, 46.152557],
              [-63.2288029, 46.1524265],
              [-63.2287652, 46.1522704],
              [-63.2284763, 46.1521001],
              [-63.2275035, 46.1519844],
              [-63.226791, 46.151704],
              [-63.2262816, 46.1517033],
              [-63.2259375, 46.151826],
              [-63.2257885, 46.1518302],
              [-63.2256078, 46.1520047],
              [-63.2250593, 46.1528702],
              [-63.225045, 46.1530369],
              [-63.2249602, 46.1531263],
              [-63.2247805, 46.1531695],
              [-63.2244417, 46.1529701],
              [-63.2240231, 46.1528721],
              [-63.2233412, 46.1527755],
              [-63.2220539, 46.1527633],
              [-63.2219192, 46.1527122],
              [-63.2218651, 46.1527625],
              [-63.221765, 46.1527043],
              [-63.2206656, 46.1527126],
              [-63.2193924, 46.1529791],
              [-63.2190667, 46.1530784],
              [-63.2187871, 46.152952],
              [-63.2187963, 46.1528846],
              [-63.2188423, 46.1527704],
              [-63.2193957, 46.152474],
              [-63.219842, 46.152147],
              [-63.2199729, 46.1516093],
              [-63.220224, 46.1515121],
              [-63.2206017, 46.1515136],
              [-63.2209489, 46.1513313],
              [-63.2219485, 46.1507079],
              [-63.2219118, 46.1505319],
              [-63.2214802, 46.1501353],
              [-63.2213332, 46.1500998],
              [-63.2206176, 46.1501018],
              [-63.2205135, 46.1500116],
              [-63.2204779, 46.1497044],
              [-63.2203402, 46.1496015],
              [-63.2201227, 46.1496001],
              [-63.219942, 46.1497746],
              [-63.2194111, 46.1500795],
              [-63.2189363, 46.150183],
              [-63.2187832, 46.1501553],
              [-63.2186495, 46.1500843],
              [-63.2187017, 46.1498509],
              [-63.2189233, 46.1495502],
              [-63.218847, 46.1491465],
              [-63.2187358, 46.1489726],
              [-63.2186838, 46.1488719],
              [-63.2187809, 46.1485441],
              [-63.2188779, 46.1484391],
              [-63.2189178, 46.1483327],
              [-63.2193966, 46.1480384],
              [-63.2195467, 46.1479029],
              [-63.2196009, 46.1476298],
              [-63.2195306, 46.147441],
              [-63.2194521, 46.1472999],
              [-63.2194542, 46.1470374],
              [-63.2195982, 46.1469097],
              [-63.2203249, 46.1468006],
              [-63.2206628, 46.1465743],
              [-63.2209202, 46.1461352],
              [-63.2211837, 46.1459111],
              [-63.2213786, 46.145904],
              [-63.2217256, 46.1461673],
              [-63.2220676, 46.1463071],
              [-63.2230126, 46.1464023],
              [-63.2236343, 46.1463343],
              [-63.2239293, 46.1461627],
              [-63.2241662, 46.1457867],
              [-63.2246063, 46.1452448],
              [-63.2245287, 46.1451951],
              [-63.2246064, 46.1449106],
              [-63.2241155, 46.1446637],
              [-63.2239768, 46.1443579],
              [-63.2237024, 46.1440208],
              [-63.2237106, 46.1438619],
              [-63.2241404, 46.14363],
              [-63.2242016, 46.143552],
              [-63.2240538, 46.1430908],
              [-63.2241436, 46.1430135],
              [-63.2244643, 46.1424566],
              [-63.2245216, 46.1420125],
              [-63.2244186, 46.1416798],
              [-63.2242411, 46.1413491],
              [-63.2242902, 46.1411753],
              [-63.2247566, 46.1411194],
              [-63.2250894, 46.140881],
              [-63.2252539, 46.1402447],
              [-63.2257317, 46.1397474],
              [-63.2268014, 46.1388672],
              [-63.2267475, 46.138472],
              [-63.2267843, 46.1382024],
              [-63.2270884, 46.1379634],
              [-63.2272946, 46.1377378],
              [-63.2275253, 46.137481],
              [-63.2279561, 46.1372292],
              [-63.2280918, 46.1371491],
              [-63.2279439, 46.1367993],
              [-63.2279195, 46.1364964],
              [-63.2278543, 46.136097],
              [-63.2271564, 46.1355386],
              [-63.2257556, 46.1346325],
              [-63.2248434, 46.1342358],
              [-63.2244048, 46.1338668],
              [-63.2243761, 46.1338325],
              [-63.2241569, 46.1335702],
              [-63.2240241, 46.1334341],
              [-63.2236493, 46.1330502],
              [-63.2236235, 46.133032],
              [-63.223088, 46.1326528],
              [-63.2226153, 46.1325729],
              [-63.2225672, 46.1323951],
              [-63.2227143, 46.1322066],
              [-63.2230893, 46.1320045],
              [-63.2234159, 46.1318303],
              [-63.2238369, 46.1316632],
              [-63.2243545, 46.13163],
              [-63.2246413, 46.1313945],
              [-63.2254188, 46.1315325],
              [-63.2257175, 46.1316104],
              [-63.2264284, 46.1316578],
              [-63.2267607, 46.1316462],
              [-63.2270823, 46.1316791],
              [-63.2275498, 46.1318082],
              [-63.2282742, 46.1318604],
              [-63.2285387, 46.1318148],
              [-63.2287155, 46.131715],
              [-63.2288941, 46.1315802],
              [-63.2290125, 46.1315036],
              [-63.2294237, 46.1314065],
              [-63.2295727, 46.1312909],
              [-63.2298646, 46.1310674],
              [-63.230387, 46.1310327],
              [-63.2305125, 46.1309284],
              [-63.2308136, 46.1306376],
              [-63.2315237, 46.1307348],
              [-63.2317156, 46.130676],
              [-63.2320178, 46.1307622],
              [-63.2331581, 46.1312011],
              [-63.2335018, 46.1312882],
              [-63.2353459, 46.1313948],
              [-63.2366275, 46.1315062],
              [-63.237401, 46.1314857],
              [-63.2377407, 46.131331],
              [-63.2382938, 46.1307004],
              [-63.2384366, 46.1304811],
              [-63.2387499, 46.1302861],
              [-63.2398906, 46.13002],
              [-63.2402457, 46.1300129],
              [-63.2411375, 46.1301385],
              [-63.2416936, 46.1303392],
              [-63.2423282, 46.1304584],
              [-63.242919, 46.1304293],
              [-63.2432373, 46.1302462],
              [-63.2436322, 46.1299099],
              [-63.2439495, 46.1298581],
              [-63.2445495, 46.1299844],
              [-63.2456402, 46.1301347],
              [-63.2459963, 46.1301077],
              [-63.2462718, 46.1300906],
              [-63.2467044, 46.129799],
              [-63.2469156, 46.1295855],
              [-63.2472594, 46.1294627],
              [-63.2477174, 46.1287859],
              [-63.2478959, 46.1287625],
              [-63.2483724, 46.1288419],
              [-63.2496306, 46.1292986],
              [-63.2500001, 46.1293005],
              [-63.2504427, 46.1293027],
              [-63.2506683, 46.129368],
              [-63.2511805, 46.1296432],
              [-63.2516039, 46.1295303],
              [-63.2519559, 46.12936],
              [-63.2526129, 46.1291535],
              [-63.2532242, 46.1293953],
              [-63.2541619, 46.1296292],
              [-63.2545875, 46.1299221],
              [-63.2551294, 46.1300668],
              [-63.2561303, 46.1300715],
              [-63.2563875, 46.1301892],
              [-63.2566038, 46.1302105],
              [-63.2572365, 46.1304806],
              [-63.2593534, 46.1319409],
              [-63.2601516, 46.1324458],
              [-63.2607037, 46.1326145],
              [-63.2616823, 46.1326107],
              [-63.2624171, 46.1327879],
              [-63.263398, 46.1333012],
              [-63.2640501, 46.133528],
              [-63.2649279, 46.1338201],
              [-63.2656332, 46.1342391],
              [-63.2667742, 46.1344183],
              [-63.2674654, 46.135004],
              [-63.2680299, 46.1352685],
              [-63.268583, 46.1353059],
              [-63.2695932, 46.1351318],
              [-63.27004, 46.1350074],
              [-63.2708145, 46.1349667],
              [-63.271333, 46.1351226],
              [-63.2718618, 46.135414],
              [-63.2720712, 46.1356856],
              [-63.2721581, 46.1358906],
              [-63.2726747, 46.1361976],
              [-63.2729483, 46.1363316],
              [-63.2732606, 46.136379],
              [-63.2739374, 46.1353295],
              [-63.2747426, 46.1354725],
              [-63.2742975, 46.136468],
              [-63.2746303, 46.1365636],
              [-63.2755438, 46.1368286],
              [-63.275891, 46.1370917],
              [-63.2761535, 46.1373328],
              [-63.2773082, 46.1379218],
              [-63.2775767, 46.1380437],
              [-63.2802965, 46.1392557],
              [-63.2820984, 46.1398365],
              [-63.2821811, 46.1398982],
              [-63.2825935, 46.1400037],
              [-63.283343, 46.1403482],
              [-63.2840181, 46.1409175],
              [-63.2844777, 46.141223],
              [-63.2846635, 46.1412833],
              [-63.2853631, 46.14171],
              [-63.2859358, 46.1418154],
              [-63.2863024, 46.1420351],
              [-63.2866906, 46.1423946],
              [-63.2875824, 46.1430965],
              [-63.2892029, 46.1437604],
              [-63.2907565, 46.1447329],
              [-63.2912253, 46.144971],
              [-63.2915632, 46.1450786],
              [-63.2923983, 46.1452023],
              [-63.2931572, 46.1455906],
              [-63.2936615, 46.1456903],
              [-63.2943351, 46.1456112],
              [-63.2947668, 46.145562],
              [-63.2953374, 46.1455957],
              [-63.2956253, 46.1456742],
              [-63.2960766, 46.1458045],
              [-63.2968463, 46.1458629],
              [-63.2971926, 46.1460344],
              [-63.2976224, 46.1461362],
              [-63.2983016, 46.1464033],
              [-63.2988324, 46.146432],
              [-63.2995442, 46.1467316],
              [-63.3001087, 46.1467731],
              [-63.3013576, 46.1464055],
              [-63.3016667, 46.1462896],
              [-63.3025088, 46.1462741],
              [-63.3030627, 46.1460687],
              [-63.3051838, 46.1461119],
              [-63.3054199, 46.1463125],
              [-63.3055788, 46.1467976],
              [-63.3057609, 46.1470487],
              [-63.305749, 46.147287],
              [-63.3055308, 46.147417],
              [-63.3048777, 46.1475445],
              [-63.304483, 46.1477697],
              [-63.3042476, 46.1480146],
              [-63.3037283, 46.1482129],
              [-63.3034592, 46.1484451],
              [-63.3034473, 46.1486835],
              [-63.303608, 46.1490176],
              [-63.3035381, 46.1493858],
              [-63.3036619, 46.1495439],
              [-63.3038382, 46.1500255],
              [-63.3048878, 46.15021],
              [-63.3052644, 46.1501196],
              [-63.3058412, 46.1501454],
              [-63.3062151, 46.1503374],
              [-63.3067139, 46.150779],
              [-63.307099, 46.1509753],
              [-63.3075891, 46.1510189],
              [-63.3077778, 46.1509081],
              [-63.308013, 46.1505517],
              [-63.3081516, 46.1504119],
              [-63.3085852, 46.1502115],
              [-63.3096075, 46.1499099],
              [-63.3101526, 46.1498832],
              [-63.3106175, 46.1495125],
              [-63.3111959, 46.1492758],
              [-63.3127394, 46.148736],
              [-63.3155185, 46.1478639],
              [-63.3163006, 46.1474411],
              [-63.3166221, 46.147421],
              [-63.3169277, 46.1476073],
              [-63.3181482, 46.1480383],
              [-63.319028, 46.1485328],
              [-63.3194161, 46.1486694],
              [-63.3197591, 46.1486776],
              [-63.3202275, 46.1485814],
              [-63.3208878, 46.1485375],
              [-63.3211881, 46.1486004],
              [-63.3225319, 46.1494321],
              [-63.3233972, 46.149759],
              [-63.3242284, 46.1504273],
              [-63.3256268, 46.1514313],
              [-63.3265319, 46.1517632],
              [-63.3273845, 46.1523484],
              [-63.3279127, 46.1525479],
              [-63.328884, 46.1532792],
              [-63.3295114, 46.1535567],
              [-63.3306032, 46.1542828],
              [-63.3316245, 46.1544662],
              [-63.3324623, 46.1547726],
              [-63.3328659, 46.1549453],
              [-63.3334599, 46.1553214],
              [-63.3338327, 46.1554217],
              [-63.3347578, 46.155933],
              [-63.3351939, 46.1560269],
              [-63.336279, 46.1559612],
              [-63.3366756, 46.1561616],
              [-63.3372405, 46.1563142],
              [-63.3376376, 46.1562719],
              [-63.337868, 46.1561262],
              [-63.3381164, 46.1558457],
              [-63.3383837, 46.1557645],
              [-63.3387747, 46.1557301],
              [-63.3393027, 46.1558182],
              [-63.3401313, 46.1560805],
              [-63.3403304, 46.1561051],
              [-63.3412834, 46.1562828],
              [-63.3413335, 46.1563118],
              [-63.342141, 46.1563031],
              [-63.3423277, 46.156232],
              [-63.342777, 46.1562903],
              [-63.3442551, 46.1566157],
              [-63.3446813, 46.1567968],
              [-63.3452447, 46.157566],
              [-63.3457264, 46.1578996],
              [-63.3461002, 46.15798],
              [-63.3462801, 46.1580479],
              [-63.3472997, 46.1587364],
              [-63.3488198, 46.1594923],
              [-63.3500019, 46.1597867],
              [-63.3509569, 46.1599246],
              [-63.3512614, 46.1600193],
              [-63.351467, 46.1601475],
              [-63.3520196, 46.1610237],
              [-63.3543836, 46.1623206],
              [-63.3570785, 46.1635297],
              [-63.3593539, 46.1644186],
              [-63.360574, 46.1651116],
              [-63.3613396, 46.1653802],
              [-63.3631828, 46.1657416],
              [-63.3640547, 46.1660604],
              [-63.3641967, 46.1660837],
              [-63.3646189, 46.1662328],
              [-63.3647632, 46.1663277],
              [-63.3662967, 46.1669364],
              [-63.3673216, 46.1671713],
              [-63.3678007, 46.1672103],
              [-63.3688944, 46.1672081],
              [-63.3708218, 46.1670144],
              [-63.3712521, 46.167116],
              [-63.3718015, 46.1671209],
              [-63.372809, 46.1670052],
              [-63.3732666, 46.1670158],
              [-63.3742958, 46.1676366],
              [-63.375, 46.1680853],
              [-63.3759427, 46.1691246],
              [-63.3771374, 46.1695324],
              [-63.3844689, 46.170569],
              [-63.3855767, 46.1705652],
              [-63.3859441, 46.1703075],
              [-63.3870497, 46.1700472],
              [-63.3896357, 46.1702948],
              [-63.3922201, 46.1702861],
              [-63.3925876, 46.1700284],
              [-63.3955391, 46.1697617],
              [-63.3960908, 46.1693754],
              [-63.3966374, 46.1684753],
              [-63.3970068, 46.1684741],
              [-63.3977487, 46.1689846],
              [-63.3984879, 46.1691106],
              [-63.3984917, 46.1696235],
              [-63.3996002, 46.1697475],
              [-63.3997864, 46.1698757],
              [-63.399974, 46.170388],
              [-63.4007134, 46.1705131],
              [-63.4012707, 46.1708965],
              [-63.4021995, 46.1717904],
              [-63.4031261, 46.1721724],
              [-63.4033137, 46.1726847],
              [-63.404053, 46.1728099],
              [-63.4051682, 46.1738321],
              [-63.4059076, 46.1739581],
              [-63.4060966, 46.1744705],
              [-63.4066525, 46.1748528],
              [-63.4077641, 46.1753618],
              [-63.4085026, 46.1753592],
              [-63.4099755, 46.1748409],
              [-63.4114515, 46.1747079],
              [-63.4114553, 46.1752208],
              [-63.4129339, 46.175472],
              [-63.4131231, 46.1759843],
              [-63.4144176, 46.1763638],
              [-63.4147888, 46.176619],
              [-63.4170052, 46.1767397],
              [-63.4170091, 46.1772528],
              [-63.4162705, 46.1772553],
              [-63.4172059, 46.178791],
              [-63.4183196, 46.1795565],
              [-63.4185075, 46.1800687],
              [-63.4192461, 46.180066],
              [-63.4192499, 46.180579],
              [-63.4199893, 46.180704],
              [-63.4211011, 46.1812131],
              [-63.4222087, 46.1812088],
              [-63.4225771, 46.1810797],
              [-63.4227664, 46.181592],
              [-63.4229511, 46.1817192],
              [-63.4244293, 46.1818424],
              [-63.4246185, 46.1823546],
              [-63.4257323, 46.18312],
              [-63.4257403, 46.184146],
              [-63.4261134, 46.1846576],
              [-63.4261155, 46.1849142],
              [-63.4257481, 46.185172],
              [-63.4255727, 46.1864551],
              [-63.4248342, 46.186458],
              [-63.4244726, 46.1874851],
              [-63.4222638, 46.1883907],
              [-63.4211569, 46.1885236],
              [-63.4206113, 46.1895515],
              [-63.4202596, 46.1918613],
              [-63.4195247, 46.192377],
              [-63.4191632, 46.1934044],
              [-63.4193482, 46.1935315],
              [-63.4204555, 46.1933996],
              [-63.4206446, 46.1939119],
              [-63.421389, 46.1946787],
              [-63.4217802, 46.1974988],
              [-63.4226132, 46.1983202],
              [-63.4230372, 46.1985376],
              [-63.4238131, 46.1990837],
              [-63.424519, 46.199133],
              [-63.4250503, 46.199411],
              [-63.425312, 46.199626],
              [-63.4255089, 46.1998045],
              [-63.4271552, 46.2000439],
              [-63.4265898, 46.198507],
              [-63.426772, 46.1982496],
              [-63.4256618, 46.1979973],
              [-63.4254759, 46.1977416],
              [-63.4252767, 46.195947],
              [-63.4260152, 46.195944],
              [-63.4258254, 46.1951752],
              [-63.4260015, 46.1941486],
              [-63.4226852, 46.1951869],
              [-63.4226813, 46.194674],
              [-63.4234189, 46.1945424],
              [-63.4237863, 46.1942846],
              [-63.424525, 46.1942819],
              [-63.4265521, 46.1936335],
              [-63.4278372, 46.1927305],
              [-63.428574, 46.1924714],
              [-63.4296759, 46.1916977],
              [-63.4305969, 46.1913097],
              [-63.4313296, 46.1905376],
              [-63.4316929, 46.1897669],
              [-63.4322432, 46.1893796],
              [-63.433349, 46.1891187],
              [-63.4340837, 46.1886029],
              [-63.4348222, 46.1886001],
              [-63.4351936, 46.1888552],
              [-63.4385195, 46.1890991],
              [-63.439256, 46.1888397],
              [-63.4451649, 46.1888168],
              [-63.4459017, 46.1885576],
              [-63.4468203, 46.1879131],
              [-63.4473663, 46.1870126],
              [-63.4481049, 46.1870099],
              [-63.4514212, 46.1860994],
              [-63.4514254, 46.1866126],
              [-63.4529025, 46.1866067],
              [-63.452909, 46.1873761],
              [-63.4521704, 46.1873792],
              [-63.4527313, 46.1881463],
              [-63.4527355, 46.1886593],
              [-63.4538496, 46.1894245],
              [-63.4540399, 46.1901931],
              [-63.4547798, 46.1903182],
              [-63.4551512, 46.1905731],
              [-63.4558909, 46.1906987],
              [-63.4558972, 46.1914683],
              [-63.4566371, 46.1915932],
              [-63.4571947, 46.1919761],
              [-63.457383, 46.1924884],
              [-63.4588626, 46.192739],
              [-63.4588669, 46.193252],
              [-63.4621931, 46.1934951],
              [-63.4621994, 46.1942644],
              [-63.4629392, 46.1943893],
              [-63.4631255, 46.1945173],
              [-63.4631278, 46.1947738],
              [-63.4627607, 46.1950318],
              [-63.4623977, 46.1958027],
              [-63.4624041, 46.1965721],
              [-63.4631472, 46.1970823],
              [-63.4635231, 46.1978502],
              [-63.4650071, 46.1986137],
              [-63.4651975, 46.1993823],
              [-63.4659373, 46.1995072],
              [-63.4664953, 46.1998901],
              [-63.4666837, 46.2004024],
              [-63.4702949, 46.2022669],
              [-63.4703777, 46.2024092],
              [-63.4714215, 46.2030727],
              [-63.4716141, 46.2032465],
              [-63.472741, 46.2040635],
              [-63.4728344, 46.204072],
              [-63.4734425, 46.2045364],
              [-63.4741151, 46.2048559],
              [-63.4744237, 46.2051085],
              [-63.474895, 46.2053377],
              [-63.4748208, 46.2055662],
              [-63.4749913, 46.2058207],
              [-63.4754795, 46.2062811],
              [-63.4762251, 46.2067526],
              [-63.4769694, 46.2070896],
              [-63.4774465, 46.2073131],
              [-63.4783526, 46.2077859],
              [-63.4799963, 46.2086013],
              [-63.4817764, 46.2092071],
              [-63.4831894, 46.2099269],
              [-63.4850414, 46.2108743],
              [-63.4856347, 46.211651],
              [-63.4856328, 46.211904],
              [-63.4857221, 46.2121162],
              [-63.4854464, 46.2123121],
              [-63.4846241, 46.2118671],
              [-63.4839237, 46.2117953],
              [-63.4836551, 46.2116088],
              [-63.4827265, 46.2115336],
              [-63.4812769, 46.2114502],
              [-63.4797723, 46.211582],
              [-63.4794564, 46.2118375],
              [-63.4791255, 46.2118373],
              [-63.4787912, 46.211568],
              [-63.4787526, 46.211496],
              [-63.4785372, 46.2114839],
              [-63.4785092, 46.2114303],
              [-63.4785433, 46.2114066],
              [-63.4785421, 46.2112656],
              [-63.4777824, 46.2110498],
              [-63.4773589, 46.2110726],
              [-63.4769806, 46.2112062],
              [-63.4768859, 46.2111944],
              [-63.4766952, 46.2110262],
              [-63.476833, 46.2107275],
              [-63.4768641, 46.2105314],
              [-63.4763569, 46.2101385],
              [-63.4759048, 46.21033],
              [-63.4753265, 46.2101938],
              [-63.4748355, 46.2099147],
              [-63.4746875, 46.2097702],
              [-63.4744906, 46.2098475],
              [-63.4741548, 46.2099033],
              [-63.4737915, 46.2096783],
              [-63.472466, 46.2097165],
              [-63.4714572, 46.2094566],
              [-63.4709925, 46.2094478],
              [-63.4706995, 46.2095577],
              [-63.4705139, 46.2097919],
              [-63.4704141, 46.2099804],
              [-63.4709094, 46.2102507],
              [-63.4710627, 46.2103166],
              [-63.4713129, 46.2104128],
              [-63.4715844, 46.2106276],
              [-63.4717545, 46.2109111],
              [-63.4717634, 46.2111572],
              [-63.472054, 46.2113096],
              [-63.4723294, 46.2112471],
              [-63.4726118, 46.2113696],
              [-63.4729888, 46.2117873],
              [-63.473222, 46.2119198],
              [-63.473466, 46.2122928],
              [-63.4734093, 46.2126737],
              [-63.4735418, 46.212827],
              [-63.4738244, 46.212906],
              [-63.4739615, 46.2130158],
              [-63.474051, 46.2130933],
              [-63.4740932, 46.2132032],
              [-63.4740323, 46.2133682],
              [-63.4740339, 46.2135628],
              [-63.4743542, 46.213762],
              [-63.4746603, 46.2139605],
              [-63.4751808, 46.2140325],
              [-63.4754168, 46.2142469],
              [-63.4755832, 46.2143734],
              [-63.4757995, 46.2146193],
              [-63.4760972, 46.2148321],
              [-63.4765497, 46.2150694],
              [-63.4774827, 46.2152692],
              [-63.4778256, 46.2154837],
              [-63.4783609, 46.2151406],
              [-63.4780368, 46.2160772],
              [-63.4778412, 46.2166796],
              [-63.4779651, 46.2171122],
              [-63.4780118, 46.2175837],
              [-63.4782882, 46.2183641],
              [-63.4788252, 46.2189716],
              [-63.4790234, 46.219368],
              [-63.4790457, 46.2197397],
              [-63.4790831, 46.220014],
              [-63.4792606, 46.2201416],
              [-63.4795774, 46.2202706],
              [-63.4796893, 46.2203312],
              [-63.4800271, 46.2204904],
              [-63.4810317, 46.220761],
              [-63.481843, 46.2213581],
              [-63.4818559, 46.2216724],
              [-63.4816183, 46.2219008],
              [-63.4815888, 46.2221441],
              [-63.4813123, 46.2226247],
              [-63.4812756, 46.222914],
              [-63.4816332, 46.2234162],
              [-63.4816181, 46.2236541],
              [-63.4817857, 46.223886],
              [-63.4824426, 46.2242919],
              [-63.4825748, 46.2244981],
              [-63.4828938, 46.2247786],
              [-63.4835187, 46.2251769],
              [-63.4842858, 46.2251175],
              [-63.4853353, 46.2250726],
              [-63.4856904, 46.2251456],
              [-63.4858629, 46.22535],
              [-63.4862644, 46.226038],
              [-63.4864655, 46.2266227],
              [-63.4866503, 46.2269696],
              [-63.487419, 46.2267743],
              [-63.4875234, 46.22701],
              [-63.4872502, 46.2271631],
              [-63.4868722, 46.2272525],
              [-63.4870796, 46.2275475],
              [-63.4872425, 46.2277512],
              [-63.4874588, 46.2277691],
              [-63.4876524, 46.2276896],
              [-63.4877385, 46.2277138],
              [-63.4874548, 46.2279743],
              [-63.4875248, 46.2280673],
              [-63.4876116, 46.2282263],
              [-63.4876101, 46.228797],
              [-63.4875687, 46.2289641],
              [-63.4873672, 46.2293105],
              [-63.4869833, 46.2297385],
              [-63.4869588, 46.2298436],
              [-63.487087, 46.2300507],
              [-63.4873155, 46.2302146],
              [-63.4875171, 46.2303502],
              [-63.4873601, 46.2304582],
              [-63.4869833, 46.2302117],
              [-63.4868686, 46.2301497],
              [-63.486754, 46.2301693],
              [-63.4862799, 46.2303962],
              [-63.4857092, 46.2307528],
              [-63.4846724, 46.2315998],
              [-63.4843071, 46.2321093],
              [-63.4839703, 46.2326487],
              [-63.4835539, 46.2333158],
              [-63.483019, 46.2336921],
              [-63.4825874, 46.2340298],
              [-63.4822591, 46.2343128],
              [-63.4818918, 46.2344823],
              [-63.4815727, 46.2346228],
              [-63.4810012, 46.2348046],
              [-63.4804735, 46.2349723],
              [-63.479908, 46.2351521],
              [-63.4795541, 46.2352645],
              [-63.4794546, 46.2353306],
              [-63.4791643, 46.235523],
              [-63.478989, 46.2357962],
              [-63.4789388, 46.235911],
              [-63.478791, 46.2362481],
              [-63.4787047, 46.2364453],
              [-63.4786197, 46.236716],
              [-63.4785784, 46.2368477],
              [-63.4786281, 46.2370378],
              [-63.4788111, 46.2370626],
              [-63.4790711, 46.2368354],
              [-63.4790966, 46.2366916],
              [-63.4792456, 46.2364508],
              [-63.479456, 46.2360108],
              [-63.4798, 46.2357652],
              [-63.4799992, 46.2355369],
              [-63.4806318, 46.235345],
              [-63.4812781, 46.235216],
              [-63.4814372, 46.2352123],
              [-63.4818637, 46.2351196],
              [-63.4821833, 46.2349947],
              [-63.4822199, 46.2349709],
              [-63.4828478, 46.2345603],
              [-63.4829167, 46.2343748],
              [-63.4829611, 46.234255],
              [-63.4835601, 46.2339539],
              [-63.4842118, 46.2334084],
              [-63.4847722, 46.2329554],
              [-63.4848951, 46.2328857],
              [-63.4852901, 46.2325271],
              [-63.4854344, 46.2323521],
              [-63.4864347, 46.2317511],
              [-63.4875141, 46.2309604],
              [-63.4875995, 46.2308405],
              [-63.487696, 46.2307174],
              [-63.4874802, 46.2305567],
              [-63.4876343, 46.2304556],
              [-63.4877374, 46.2305048],
              [-63.4880913, 46.2304155],
              [-63.4885043, 46.2302039],
              [-63.4891047, 46.2298787],
              [-63.4894212, 46.2296402],
              [-63.4898173, 46.2294319],
              [-63.4900924, 46.2291537],
              [-63.4903311, 46.2289403],
              [-63.4907143, 46.228838],
              [-63.4909774, 46.2289084],
              [-63.4911758, 46.2290305],
              [-63.491228, 46.229197],
              [-63.4914457, 46.2297924],
              [-63.4915117, 46.2301032],
              [-63.4916026, 46.2307895],
              [-63.491852, 46.2310881],
              [-63.4923149, 46.2313434],
              [-63.4925488, 46.2315344],
              [-63.4925409, 46.2318923],
              [-63.4927653, 46.2320576],
              [-63.4930524, 46.2325457],
              [-63.493434, 46.2327658],
              [-63.4941775, 46.2329024],
              [-63.4947598, 46.2331058],
              [-63.4948657, 46.2331467],
              [-63.4949179, 46.2332504],
              [-63.4949445, 46.2333587],
              [-63.4949971, 46.2334885],
              [-63.495056, 46.2337254],
              [-63.495152, 46.233956],
              [-63.4952629, 46.2341121],
              [-63.4953899, 46.2341865],
              [-63.4954077, 46.2340357],
              [-63.4951849, 46.2336318],
              [-63.4951463, 46.2334371],
              [-63.4951256, 46.2332293],
              [-63.4950902, 46.2331155],
              [-63.4950801, 46.2329778],
              [-63.4949733, 46.2328511],
              [-63.4945915, 46.2326784],
              [-63.4943031, 46.2326212],
              [-63.4940823, 46.2325947],
              [-63.4938272, 46.2322352],
              [-63.4937423, 46.2320673],
              [-63.4935705, 46.2316062],
              [-63.4931979, 46.2312214],
              [-63.492958, 46.2311041],
              [-63.4927996, 46.2308876],
              [-63.4925216, 46.2303074],
              [-63.4924712, 46.2295716],
              [-63.4918711, 46.2285386],
              [-63.4915046, 46.2282853],
              [-63.4910941, 46.228158],
              [-63.4905256, 46.2279335],
              [-63.490418, 46.227938],
              [-63.490177, 46.2278687],
              [-63.4891526, 46.2275228],
              [-63.4886988, 46.2274106],
              [-63.4885614, 46.2273254],
              [-63.4883594, 46.2274109],
              [-63.4884453, 46.227245],
              [-63.4884613, 46.2270977],
              [-63.4884034, 46.2270208],
              [-63.4881841, 46.2269236],
              [-63.4881842, 46.2268705],
              [-63.4883958, 46.2266984],
              [-63.4884581, 46.2265404],
              [-63.4882683, 46.2253138],
              [-63.4881869, 46.224245],
              [-63.4880272, 46.2238637],
              [-63.4879422, 46.2234392],
              [-63.4875744, 46.2233189],
              [-63.4868875, 46.2229741],
              [-63.4864412, 46.2227835],
              [-63.4862142, 46.22258],
              [-63.4860236, 46.222075],
              [-63.4858435, 46.2214081],
              [-63.4857513, 46.2206357],
              [-63.485825, 46.2203049],
              [-63.4857974, 46.2201216],
              [-63.4857168, 46.2199011],
              [-63.4856362, 46.2191801],
              [-63.4860609, 46.2189229],
              [-63.4865677, 46.2185347],
              [-63.4874641, 46.2176126],
              [-63.487469, 46.2174193],
              [-63.487528, 46.2172727],
              [-63.4874609, 46.2170148],
              [-63.4875467, 46.2168477],
              [-63.4875804, 46.2165902],
              [-63.4875949, 46.2163915],
              [-63.4875593, 46.216224],
              [-63.4874652, 46.2161309],
              [-63.4873456, 46.2160868],
              [-63.4872544, 46.2160423],
              [-63.4872544, 46.2159439],
              [-63.4872988, 46.2158505],
              [-63.4873221, 46.2157436],
              [-63.4871417, 46.2155282],
              [-63.4870854, 46.2152795],
              [-63.4872038, 46.21505],
              [-63.4872927, 46.2148665],
              [-63.4877695, 46.2145674],
              [-63.4880723, 46.214406],
              [-63.4883462, 46.2143074],
              [-63.4884367, 46.2141202],
              [-63.4883465, 46.2139101],
              [-63.4880605, 46.2136855],
              [-63.487769, 46.2135],
              [-63.4869605, 46.2130778],
              [-63.4860871, 46.2126395],
              [-63.4859787, 46.2126191],
              [-63.4857938, 46.2125319],
              [-63.4858019, 46.2124264],
              [-63.48603, 46.212278],
              [-63.4881954, 46.2133922],
              [-63.4883767, 46.2133734],
              [-63.4888094, 46.2133041],
              [-63.4889391, 46.2132561],
              [-63.4890641, 46.2131996],
              [-63.4889603, 46.2130831],
              [-63.4890316, 46.2130563],
              [-63.4895045, 46.2135879],
              [-63.4898192, 46.2134728],
              [-63.4889977, 46.2124868],
              [-63.4892354, 46.2123905],
              [-63.4896019, 46.2128223],
              [-63.4899946, 46.2133241],
              [-63.4900868, 46.2132917],
              [-63.4902585, 46.2132342],
              [-63.4907144, 46.21321],
              [-63.490878, 46.2132082],
              [-63.4910551, 46.2131822],
              [-63.4911811, 46.2132156],
              [-63.4912616, 46.2132509],
              [-63.4914413, 46.21321],
              [-63.4917817, 46.2131376],
              [-63.4919375, 46.2130616],
              [-63.4922621, 46.2129576],
              [-63.4925491, 46.212863],
              [-63.4928066, 46.2127535],
              [-63.4929675, 46.2126607],
              [-63.4930319, 46.2125456],
              [-63.4931177, 46.2123711],
              [-63.4931727, 46.2122319],
              [-63.4932156, 46.212141],
              [-63.493221, 46.2120528],
              [-63.493233, 46.2119869],
              [-63.4932813, 46.2119117],
              [-63.4933336, 46.2118561],
              [-63.4933296, 46.2118115],
              [-63.4933873, 46.2117688],
              [-63.4934436, 46.2117484],
              [-63.4934999, 46.2117373],
              [-63.4935388, 46.2117568],
              [-63.4935911, 46.2117633],
              [-63.4936367, 46.2117428],
              [-63.4937038, 46.2116927],
              [-63.4937561, 46.2116797],
              [-63.4938218, 46.2116936],
              [-63.4938969, 46.2117206],
              [-63.4940216, 46.211702],
              [-63.4940954, 46.2116621],
              [-63.4941919, 46.2116602],
              [-63.4942737, 46.211676],
              [-63.4943341, 46.2117159],
              [-63.4943917, 46.2117317],
              [-63.4944668, 46.2117401],
              [-63.4945652, 46.2118036],
              [-63.4947085, 46.2118963],
              [-63.4949045, 46.2119919],
              [-63.4952179, 46.2120908],
              [-63.4954727, 46.212076],
              [-63.4956202, 46.2120686],
              [-63.4957248, 46.2121187],
              [-63.4958723, 46.212141],
              [-63.4960896, 46.2120927],
              [-63.4961782, 46.2120377],
              [-63.4975036, 46.2157901],
              [-63.4994546, 46.2209495],
              [-63.4995594, 46.221174],
              [-63.4997716, 46.2218422],
              [-63.5019681, 46.2275364],
              [-63.5028458, 46.2301199],
              [-63.503902, 46.2330377],
              [-63.5043441, 46.2331797],
              [-63.5047733, 46.2333352],
              [-63.5051963, 46.2335088],
              [-63.5056064, 46.2336914],
              [-63.5060036, 46.233892],
              [-63.5063945, 46.2340972],
              [-63.5065477, 46.2341864],
              [-63.5066552, 46.234249],
              [-63.5069159, 46.2344052],
              [-63.5071637, 46.2345661],
              [-63.5074051, 46.2347314],
              [-63.50764, 46.2349013],
              [-63.5078621, 46.2350758],
              [-63.50813, 46.2353085],
              [-63.5083785, 46.2355458],
              [-63.5086142, 46.2357966],
              [-63.5088305, 46.236052],
              [-63.5090339, 46.236312],
              [-63.5092309, 46.2365765],
              [-63.5095138, 46.2370251],
              [-63.5097709, 46.2374828],
              [-63.5098832, 46.2377117],
              [-63.5099891, 46.2379451],
              [-63.5100821, 46.2381786],
              [-63.510242, 46.2386457],
              [-63.5103025, 46.2388793],
              [-63.5103566, 46.2391175],
              [-63.5103913, 46.2393558],
              [-63.5104208, 46.2397425],
              [-63.5104115, 46.2401339],
              [-63.5103763, 46.2405254],
              [-63.5103021, 46.2409126],
              [-63.5101955, 46.2412954],
              [-63.5100694, 46.2416783],
              [-63.5099617, 46.2419487],
              [-63.5098345, 46.2422147],
              [-63.5097008, 46.2424762],
              [-63.5093886, 46.2429904],
              [-63.5092087, 46.2432386],
              [-63.5090421, 46.2434508],
              [-63.508869, 46.243654],
              [-63.5086829, 46.2438572],
              [-63.5084902, 46.244056],
              [-63.5082845, 46.2442459],
              [-63.5080723, 46.2444357],
              [-63.509913, 46.2493693],
              [-63.5101685, 46.250064],
              [-63.5136187, 46.2593352],
              [-63.514544, 46.2617515],
              [-63.5145839, 46.2617922],
              [-63.5146037, 46.2618236],
              [-63.5146434, 46.2619089],
              [-63.514657, 46.2619718],
              [-63.5154089, 46.2639881],
              [-63.5159016, 46.2653444],
              [-63.5159943, 46.2655419],
              [-63.5165806, 46.2671811],
              [-63.5177516, 46.2702841],
              [-63.5195361, 46.275125],
              [-63.519995, 46.2763109],
              [-63.5203744, 46.2773213],
              [-63.5207208, 46.2782644],
              [-63.5212199, 46.2795801],
              [-63.5217201, 46.2809992],
              [-63.5220532, 46.2819108],
              [-63.522486, 46.2830739],
              [-63.5227992, 46.2839316],
              [-63.5231586, 46.2848701],
              [-63.5239248, 46.2869583],
              [-63.5244686, 46.288294],
              [-63.5247171, 46.2889647],
              [-63.524897, 46.2895403],
              [-63.5252636, 46.2905552],
              [-63.5254172, 46.2910043],
              [-63.5255905, 46.2914803],
              [-63.5260521, 46.2924456],
              [-63.5262142, 46.2928806],
              [-63.5265233, 46.294004],
              [-63.5275159, 46.2966579],
              [-63.5279893, 46.2979602],
              [-63.5288623, 46.3003132],
              [-63.5294684, 46.3019028],
              [-63.5297284, 46.3026168],
              [-63.5302676, 46.3039998],
              [-63.5315873, 46.3075472],
              [-63.5337209, 46.313313],
              [-63.5347543, 46.3160791],
              [-63.5358742, 46.3190562],
              [-63.5368082, 46.3215978],
              [-63.5378828, 46.3245665],
              [-63.5388037, 46.3270767],
              [-63.5402783, 46.3310686],
              [-63.5408992, 46.332784],
              [-63.5422725, 46.3366886],
              [-63.5425673, 46.3372608],
              [-63.5432284, 46.3390795],
              [-63.5433559, 46.3392904],
              [-63.5438867, 46.3407653],
              [-63.5440432, 46.3413157],
              [-63.5448046, 46.3434127],
              [-63.5448219, 46.3434594],
              [-63.5448453, 46.3434634],
              [-63.5487666, 46.3542318],
              [-63.5507109, 46.3594237],
              [-63.5565091, 46.3750647],
              [-63.5573665, 46.377361],
              [-63.5533416, 46.3780734],
              [-63.5533839, 46.3781393],
              [-63.5534601, 46.3782851],
              [-63.5535865, 46.3785769],
              [-63.5538604, 46.3793065],
              [-63.553991, 46.379677],
              [-63.5541345, 46.3800505],
              [-63.554248, 46.380346],
              [-63.5542884, 46.3804807],
              [-63.5549039, 46.3822028],
              [-63.5550179, 46.3825396],
              [-63.5550378, 46.3825845],
              [-63.5550915, 46.3827529],
              [-63.5554458, 46.3837138],
              [-63.5555058, 46.3838575],
              [-63.5555193, 46.3839091],
              [-63.5560047, 46.38529],
              [-63.5562617, 46.38595],
              [-63.5563668, 46.386249],
              [-63.5568742, 46.3876743],
              [-63.5573856, 46.3890528],
              [-63.5575672, 46.3896502],
              [-63.5583916, 46.3920027],
              [-63.5597521, 46.3958974],
              [-63.5619696, 46.4020495],
              [-63.5625684, 46.4036704],
              [-63.5630013, 46.404787],
              [-63.5633856, 46.405795],
              [-63.5634609, 46.4063938],
              [-63.5639258, 46.4072758],
              [-63.5641799, 46.4079515],
              [-63.564522, 46.4088306],
              [-63.5645596, 46.4089092],
              [-63.5654741, 46.4113975],
              [-63.5656681, 46.4119139],
              [-63.5661011, 46.4130304],
              [-63.5664956, 46.4140657],
              [-63.5670357, 46.4155259],
              [-63.5672531, 46.4160983],
              [-63.5672633, 46.4161455],
              [-63.5676554, 46.4172623],
              [-63.5687916, 46.4204818],
              [-63.5688854, 46.4207445],
              [-63.568912, 46.4208006],
              [-63.5689189, 46.4208366],
              [-63.5689458, 46.4209174],
              [-63.5695327, 46.4225568],
              [-63.5695456, 46.4226035],
              [-63.5699341, 46.4236744],
              [-63.5700979, 46.4240987],
              [-63.5701249, 46.4241907],
              [-63.570343, 46.4247618],
              [-63.5704268, 46.4251353],
              [-63.5705539, 46.4254009],
              [-63.57117, 46.4270792],
              [-63.5714282, 46.4278098],
              [-63.5717677, 46.4287325],
              [-63.5723891, 46.4304094],
              [-63.5725161, 46.4307327],
              [-63.5725892, 46.4308808],
              [-63.5726094, 46.4309436],
              [-63.5726233, 46.431029],
              [-63.5726537, 46.4311323],
              [-63.5729181, 46.4318372],
              [-63.5730484, 46.4321649],
              [-63.5732584, 46.4326407],
              [-63.5737859, 46.4339889],
              [-63.5744913, 46.4360307],
              [-63.574612, 46.4363629],
              [-63.5747849, 46.4368168],
              [-63.5749571, 46.4372771],
              [-63.5753025, 46.4382914],
              [-63.5823905, 46.4583981],
              [-63.5840991, 46.4629942],
              [-63.5841266, 46.4630669],
              [-63.5873201, 46.4718144],
              [-63.5878237, 46.4732215],
              [-63.5883331, 46.4746295],
              [-63.5888913, 46.4762179],
              [-63.590019, 46.4792307],
              [-63.5931046, 46.4877257],
              [-63.5949509, 46.4927699],
              [-63.6018268, 46.5115436],
              [-63.6052554, 46.5208984],
              [-63.6128445, 46.5405344],
              [-63.6137452, 46.5428745],
              [-63.6142434, 46.544152],
              [-63.620472542971683, 46.558661303919884],
              [-63.619733, 46.5585573],
              [-63.6193551, 46.5583464],
              [-63.618903, 46.5582494],
              [-63.6177005, 46.5578742],
              [-63.6162741, 46.5574664],
              [-63.6158324, 46.5572621],
              [-63.6147209, 46.5569205],
              [-63.6132728, 46.5566158],
              [-63.6128224, 46.556479],
              [-63.6091516, 46.555754],
              [-63.6083875, 46.5559255],
              [-63.6081217, 46.5557965],
              [-63.6076695, 46.5554366],
              [-63.6071772, 46.5553348],
              [-63.6071088, 46.5550465],
              [-63.6066051, 46.554809],
              [-63.6053179, 46.554392],
              [-63.6047969, 46.5544211],
              [-63.6044506, 46.5544139],
              [-63.6041446, 46.5544116],
              [-63.6026144, 46.5538739],
              [-63.6016185, 46.5536661],
              [-63.5997772, 46.5533769],
              [-63.5976744, 46.5527278],
              [-63.5965203, 46.5524408],
              [-63.5951257, 46.5515792],
              [-63.5938055, 46.5511294],
              [-63.5924626, 46.5508027],
              [-63.5909632, 46.5503572],
              [-63.5885722, 46.5495703],
              [-63.5871717, 46.5491108],
              [-63.5862914, 46.5489052],
              [-63.5852433, 46.5485765],
              [-63.5842589, 46.5483727],
              [-63.5835098, 46.5483371],
              [-63.5833725, 46.5483064],
              [-63.5828166, 46.5483426],
              [-63.5824057, 46.5480989],
              [-63.5817833, 46.54807],
              [-63.5806767, 46.5478914],
              [-63.5803742, 46.5479409],
              [-63.5801662, 46.548076],
              [-63.5800691, 46.54805],
              [-63.5799861, 46.5478371],
              [-63.5799197, 46.547772],
              [-63.5793637, 46.5478082],
              [-63.578202, 46.5473057],
              [-63.577216, 46.5470102],
              [-63.5763699, 46.5469486],
              [-63.5759617, 46.5466452],
              [-63.57521, 46.5465378],
              [-63.5732451, 46.5459188],
              [-63.5725651, 46.5457571],
              [-63.5715983, 46.5458125],
              [-63.5703685, 46.5454161],
              [-63.569872, 46.5454136],
              [-63.5692681, 46.5450981],
              [-63.5688119, 46.544969],
              [-63.568423, 46.5447536],
              [-63.5679484, 46.5447795],
              [-63.5677587, 46.5446281],
              [-63.5677011, 46.5444954],
              [-63.5671208, 46.5441684],
              [-63.5647969, 46.5434461],
              [-63.5642315, 46.5433067],
              [-63.5619899, 46.5424227],
              [-63.5612838, 46.5423321],
              [-63.5602185, 46.5418753],
              [-63.5592275, 46.5416989],
              [-63.558614, 46.5414708],
              [-63.5583326, 46.541437],
              [-63.5576763, 46.5412637],
              [-63.5575261, 46.541137],
              [-63.557208, 46.5410187],
              [-63.5567186, 46.5409884],
              [-63.5554332, 46.5405508],
              [-63.5546668, 46.5403872],
              [-63.554097, 46.5403472],
              [-63.5536783, 46.5402825],
              [-63.5535673, 46.540312],
              [-63.5531801, 46.5403198],
              [-63.5528612, 46.5402213],
              [-63.5526322, 46.5401766],
              [-63.5521648, 46.5399117],
              [-63.5510813, 46.5396098],
              [-63.5465469, 46.5387524],
              [-63.5462061, 46.5387571],
              [-63.5460296, 46.5387015],
              [-63.543706, 46.5383731],
              [-63.5431562, 46.5384012],
              [-63.5425491, 46.5381652],
              [-63.542279, 46.5381355],
              [-63.5419259, 46.5381559],
              [-63.5416551, 46.5380146],
              [-63.5414489, 46.5379783],
              [-63.54094, 46.5382543],
              [-63.5403885, 46.5383222],
              [-63.5402024, 46.5382226],
              [-63.5402874, 46.5380013],
              [-63.5401477, 46.5378987],
              [-63.5397876, 46.5379468],
              [-63.5396583, 46.5378684],
              [-63.5396558, 46.5377966],
              [-63.5393885, 46.5375757],
              [-63.5385052, 46.5373178],
              [-63.5368914, 46.5370004],
              [-63.5360971, 46.5369476],
              [-63.5359563, 46.5369963],
              [-63.5356992, 46.537194],
              [-63.5354876, 46.5372771],
              [-63.5353041, 46.5372493],
              [-63.5352011, 46.5370997],
              [-63.5349478, 46.5369548],
              [-63.5349507, 46.5367636],
              [-63.5348014, 46.536617],
              [-63.5343506, 46.5364998],
              [-63.5316512, 46.5363144],
              [-63.5305696, 46.5361039],
              [-63.5304061, 46.5361442],
              [-63.5300963, 46.5364842],
              [-63.5298595, 46.5364871],
              [-63.5293478, 46.5361852],
              [-63.5283025, 46.5361907],
              [-63.5280088, 46.5363039],
              [-63.5280102, 46.536527],
              [-63.5279621, 46.5365698],
              [-63.5277768, 46.5365818],
              [-63.5276188, 46.5365027],
              [-63.5273488, 46.536473],
              [-63.5269064, 46.5365511],
              [-63.5265811, 46.5367234],
              [-63.5264935, 46.5368729],
              [-63.5261929, 46.5368824],
              [-63.5260078, 46.5367628],
              [-63.52557, 46.5367415],
              [-63.5254278, 46.5365671],
              [-63.5250278, 46.5363473],
              [-63.5249276, 46.536138],
              [-63.5246255, 46.5359243],
              [-63.5239106, 46.5359009],
              [-63.5237291, 46.5357018],
              [-63.523342, 46.5357094],
              [-63.5228784, 46.5353647],
              [-63.5222005, 46.5351629],
              [-63.5217945, 46.5349509],
              [-63.5212458, 46.5348275],
              [-63.5211559, 46.5347738],
              [-63.5211554, 46.5345308],
              [-63.5206112, 46.5343079],
              [-63.5199237, 46.5341934],
              [-63.5190097, 46.534106],
              [-63.5184393, 46.5339542],
              [-63.517939, 46.5336565],
              [-63.5175459, 46.5335405],
              [-63.5169665, 46.5335877],
              [-63.5167587, 46.5334597],
              [-63.5164422, 46.5335644],
              [-63.5162562, 46.5334648],
              [-63.5160919, 46.533525],
              [-63.5160226, 46.5336509],
              [-63.5158837, 46.5336599],
              [-63.5154951, 46.5334443],
              [-63.515108, 46.5334519],
              [-63.5147452, 46.5335596],
              [-63.5139769, 46.5342043],
              [-63.5138248, 46.5342488],
              [-63.5136659, 46.5341896],
              [-63.5137546, 46.5338887],
              [-63.5136045, 46.5337619],
              [-63.5131308, 46.5337676],
              [-63.5125123, 46.5336586],
              [-63.5123332, 46.5336627],
              [-63.5119844, 46.5337149],
              [-63.5112828, 46.5341618],
              [-63.5106832, 46.5342723],
              [-63.5103324, 46.5344958],
              [-63.5102214, 46.5345253],
              [-63.5095913, 46.5345435],
              [-63.5092482, 46.5348508],
              [-63.5089056, 46.5348951],
              [-63.50873, 46.5348196],
              [-63.5086103, 46.5342791],
              [-63.5084228, 46.5340878],
              [-63.5080313, 46.5340634],
              [-63.5067591, 46.5344899],
              [-63.5064212, 46.5348093],
              [-63.5057712, 46.5347593],
              [-63.5051433, 46.5349807],
              [-63.5049791, 46.5349094],
              [-63.5049619, 46.5347815],
              [-63.5053706, 46.5344279],
              [-63.5054076, 46.5342494],
              [-63.5056224, 46.5338437],
              [-63.5058493, 46.5335538],
              [-63.505855, 46.5333029],
              [-63.5056527, 46.5330555],
              [-63.5056617, 46.5328565],
              [-63.5058727, 46.5326619],
              [-63.5058023, 46.5324333],
              [-63.5055614, 46.5322726],
              [-63.5052049, 46.532241],
              [-63.5047658, 46.5319964],
              [-63.5044722, 46.531978],
              [-63.5042513, 46.5318856],
              [-63.504037, 46.5315224],
              [-63.5035014, 46.5309888],
              [-63.5032787, 46.5309361],
              [-63.5025236, 46.5310392],
              [-63.5024005, 46.5309529],
              [-63.5023154, 46.5306681],
              [-63.5020273, 46.5305304],
              [-63.5017467, 46.5306079],
              [-63.5017357, 46.5308467],
              [-63.5014324, 46.5309158],
              [-63.5011, 46.5311158],
              [-63.500892, 46.5311192],
              [-63.5006809, 46.5309393],
              [-63.5004747, 46.5309029],
              [-63.5002368, 46.5310571],
              [-63.5, 46.530956],
              [-63.5, 46.530591],
              [-63.5001415, 46.5306168],
              [-63.500134, 46.5304015],
              [-63.5005243, 46.5300713],
              [-63.5006131, 46.5297705],
              [-63.500691, 46.5297084],
              [-63.5010824, 46.5297329],
              [-63.5013625, 46.5295438],
              [-63.5014878, 46.5293274],
              [-63.5014009, 46.5288939],
              [-63.5011721, 46.5285317],
              [-63.500818, 46.5281973],
              [-63.5000001, 46.527706],
              [-63.499255, 46.5277792],
              [-63.4993088, 46.527447],
              [-63.4989654, 46.5271222],
              [-63.4984934, 46.5270263],
              [-63.4980428, 46.527078],
              [-63.4980213, 46.5268934],
              [-63.4977746, 46.5265465],
              [-63.4971951, 46.5265466],
              [-63.4964442, 46.526827],
              [-63.4958219, 46.5269599],
              [-63.4954785, 46.5267089],
              [-63.495264, 46.5263989],
              [-63.4948777, 46.5262069],
              [-63.4940946, 46.5262586],
              [-63.4937083, 46.526421],
              [-63.4933865, 46.5265613],
              [-63.4933865, 46.5259486],
              [-63.4932148, 46.5254688],
              [-63.492807, 46.5252621],
              [-63.492056, 46.5251513],
              [-63.4921956, 46.5247453],
              [-63.4918952, 46.5245165],
              [-63.4920776, 46.524236],
              [-63.4918308, 46.5239187],
              [-63.4914231, 46.5237562],
              [-63.4911334, 46.5237857],
              [-63.4908008, 46.5237193],
              [-63.4909403, 46.5232394],
              [-63.4914016, 46.5227891],
              [-63.4913695, 46.5221985],
              [-63.4904252, 46.5213348],
              [-63.4901678, 46.5210839],
              [-63.4898513, 46.5206557],
              [-63.4893684, 46.5197697],
              [-63.4884672, 46.519312],
              [-63.4878449, 46.5190979],
              [-63.4872656, 46.518943],
              [-63.486864, 46.5186272],
              [-63.486225, 46.5181825],
              [-63.48571, 46.5178946],
              [-63.4852378, 46.5177026],
              [-63.4850448, 46.5174147],
              [-63.4849374, 46.5168758],
              [-63.4849911, 46.5163737],
              [-63.4851949, 46.5158348],
              [-63.4856241, 46.5153031],
              [-63.4859137, 46.5148822],
              [-63.4858279, 46.5145058],
              [-63.4855812, 46.5141809],
              [-63.4849589, 46.5135829],
              [-63.4846048, 46.5132359],
              [-63.48468, 46.5129479],
              [-63.4848301, 46.5123054],
              [-63.48527, 46.5117444],
              [-63.4856992, 46.5110946],
              [-63.4857421, 46.5102971],
              [-63.485667, 46.5099575],
              [-63.4858494, 46.5097065],
              [-63.4863322, 46.5094849],
              [-63.4868151, 46.5093373],
              [-63.4879737, 46.5090567],
              [-63.4887354, 46.508872],
              [-63.4892075, 46.5087318],
              [-63.4895079, 46.5084733],
              [-63.4896903, 46.5082443],
              [-63.4899908, 46.5080155],
              [-63.4903019, 46.5077866],
              [-63.4916412, 46.5074692],
              [-63.4917012, 46.5076083],
              [-63.4915064, 46.5078752],
              [-63.4917793, 46.5081659],
              [-63.4922094, 46.5083417],
              [-63.4928886, 46.5084837],
              [-63.4934767, 46.5085193],
              [-63.4939635, 46.508456],
              [-63.4940616, 46.5084891],
              [-63.4943145, 46.5089681],
              [-63.4946148, 46.509197],
              [-63.4954938, 46.5096242],
              [-63.4959675, 46.5098528],
              [-63.4964307, 46.5099366],
              [-63.4970294, 46.5098107],
              [-63.4975438, 46.5101331],
              [-63.4983916, 46.5104143],
              [-63.499448, 46.5105987],
              [-63.5000001, 46.510675],
              [-63.5004454, 46.5106428],
              [-63.5012229, 46.5105368],
              [-63.5018372, 46.5102251],
              [-63.5022709, 46.5102219],
              [-63.5025015, 46.5103855],
              [-63.5025938, 46.5105422],
              [-63.5024415, 46.5107226],
              [-63.5024345, 46.5108988],
              [-63.5024201, 46.5110392],
              [-63.5025347, 46.5111672],
              [-63.5025674, 46.5112722],
              [-63.502885, 46.511448],
              [-63.5028797, 46.5116423],
              [-63.5029663, 46.5117079],
              [-63.5031052, 46.5118152],
              [-63.503105, 46.5120089],
              [-63.5035674, 46.5124849],
              [-63.5036353, 46.5127264],
              [-63.504028, 46.5130246],
              [-63.5044644, 46.5134572],
              [-63.5047502, 46.5135885],
              [-63.5051257, 46.5138659],
              [-63.5053053, 46.5139663],
              [-63.5056134, 46.5140673],
              [-63.5062588, 46.51431],
              [-63.5065105, 46.5143354],
              [-63.5070828, 46.5145498],
              [-63.5072273, 46.5146392],
              [-63.5077169, 46.5149062],
              [-63.5080427, 46.5150811],
              [-63.5081533, 46.5151904],
              [-63.5083222, 46.5153334],
              [-63.508465, 46.515539],
              [-63.5086741, 46.5159935],
              [-63.5089711, 46.5167263],
              [-63.5093267, 46.5171203],
              [-63.5099798, 46.5174084],
              [-63.5105041, 46.517583],
              [-63.5109123, 46.5176492],
              [-63.5109307, 46.51753],
              [-63.5109928, 46.517452],
              [-63.5111417, 46.5174255],
              [-63.5110789, 46.5171952],
              [-63.5112598, 46.5171766],
              [-63.511307, 46.5173865],
              [-63.5113423, 46.5175439],
              [-63.5116059, 46.5175044],
              [-63.5118903, 46.5175568],
              [-63.5118772, 46.5176207],
              [-63.5119638, 46.5176329],
              [-63.5119702, 46.5175691],
              [-63.5122146, 46.5176132],
              [-63.5122624, 46.5178205],
              [-63.5126812, 46.5177741],
              [-63.513137, 46.5177502],
              [-63.5140164, 46.5176553],
              [-63.5139567, 46.5175094],
              [-63.5141005, 46.5173893],
              [-63.5142182, 46.5170907],
              [-63.5142487, 46.5168415],
              [-63.5140387, 46.5168181],
              [-63.5128106, 46.5168607],
              [-63.5122544, 46.5167313],
              [-63.5121247, 46.5169381],
              [-63.5121425, 46.5166841],
              [-63.5122145, 46.5165585],
              [-63.5122639, 46.5159502],
              [-63.5126112, 46.5154739],
              [-63.5127478, 46.5150982],
              [-63.5128558, 46.5147903],
              [-63.5127147, 46.5146199],
              [-63.5125969, 46.5145456],
              [-63.5116324, 46.5143127],
              [-63.5112472, 46.5141205],
              [-63.510436, 46.5138408],
              [-63.5101278, 46.5137718],
              [-63.5094396, 46.5134515],
              [-63.5090574, 46.5132196],
              [-63.5086703, 46.5132087],
              [-63.5083498, 46.5131065],
              [-63.5082772, 46.512901],
              [-63.5083524, 46.5126646],
              [-63.5085707, 46.5126598],
              [-63.5085909, 46.5125602],
              [-63.5084214, 46.5125461],
              [-63.508499, 46.5122641],
              [-63.5085209, 46.5120538],
              [-63.5078739, 46.5119726],
              [-63.5070985, 46.5119069],
              [-63.5065744, 46.5118632],
              [-63.5059481, 46.5115724],
              [-63.5055778, 46.5116115],
              [-63.5053609, 46.5115283],
              [-63.5051794, 46.5112178],
              [-63.5049077, 46.5108075],
              [-63.504508, 46.5103367],
              [-63.5041599, 46.5101461],
              [-63.503703, 46.5099253],
              [-63.5034384, 46.5096963],
              [-63.5033717, 46.5095683],
              [-63.5030264, 46.5093711],
              [-63.5029057, 46.5093764],
              [-63.5027118, 46.5093811],
              [-63.5026463, 46.5093328],
              [-63.5023742, 46.5094805],
              [-63.5017812, 46.5090773],
              [-63.5015702, 46.5087479],
              [-63.5017103, 46.5087006],
              [-63.5016673, 46.5086387],
              [-63.5015217, 46.5086812],
              [-63.5014345, 46.5085636],
              [-63.5006711, 46.5087853],
              [-63.5004971, 46.5085035],
              [-63.4998024, 46.5085676],
              [-63.499508, 46.5086626],
              [-63.4983517, 46.5078067],
              [-63.497869, 46.507663],
              [-63.4966611, 46.5077771],
              [-63.4964718, 46.5077032],
              [-63.4954387, 46.5074269],
              [-63.4950133, 46.507373],
              [-63.4947251, 46.507258],
              [-63.4942618, 46.5071926],
              [-63.4941259, 46.5069162],
              [-63.4941957, 46.5064022],
              [-63.4942576, 46.5050955],
              [-63.4940555, 46.5043853],
              [-63.4941922, 46.5037509],
              [-63.4948147, 46.5033278],
              [-63.4956583, 46.5024531],
              [-63.4969549, 46.5016373],
              [-63.4972471, 46.5012914],
              [-63.4983131, 46.500877],
              [-63.4991809, 46.5004379],
              [-63.5, 46.500176],
              [-63.5000159, 46.5002804],
              [-63.4992032, 46.5005591],
              [-63.4991692, 46.5006657],
              [-63.4996344, 46.500651],
              [-63.5003799, 46.5004987],
              [-63.5007014, 46.5008017],
              [-63.5009843, 46.5010457],
              [-63.5013447, 46.5012944],
              [-63.5018551, 46.5015155],
              [-63.5027461, 46.5015621],
              [-63.5036867, 46.5013193],
              [-63.5043799, 46.501179],
              [-63.5046995, 46.501222],
              [-63.5048598, 46.5012938],
              [-63.5050877, 46.5012617],
              [-63.50508, 46.5010875],
              [-63.5050031, 46.500791],
              [-63.5043977, 46.5006854],
              [-63.503443, 46.5007595],
              [-63.5031181, 46.5007699],
              [-63.5026125, 46.5006745],
              [-63.5021394, 46.5006448],
              [-63.5015857, 46.5003117],
              [-63.5011961, 46.5],
              [-63.5009915, 46.4998891],
              [-63.5007112, 46.4998442],
              [-63.5, 46.4996658],
              [-63.49886, 46.499566],
              [-63.498649, 46.4993834],
              [-63.498439, 46.4989839],
              [-63.4981636, 46.4987526],
              [-63.49804, 46.498569],
              [-63.4974687, 46.4981526],
              [-63.4968564, 46.4977873],
              [-63.4962561, 46.4974416],
              [-63.4962498, 46.4968975],
              [-63.4963753, 46.4967053],
              [-63.4967898, 46.4964666],
              [-63.4972889, 46.4960822],
              [-63.4975177, 46.4956523],
              [-63.4974921, 46.4951811],
              [-63.497248, 46.494771],
              [-63.4970564, 46.4946372],
              [-63.4970763, 46.4942126],
              [-63.4968665, 46.4939489],
              [-63.4969477, 46.4935335],
              [-63.4973686, 46.4928757],
              [-63.4973161, 46.4922713],
              [-63.4973568, 46.4920388],
              [-63.4973593, 46.4916397],
              [-63.4972381, 46.4907682],
              [-63.4970587, 46.490264],
              [-63.4974598, 46.4890436],
              [-63.4974253, 46.4887979],
              [-63.4969906, 46.4886239],
              [-63.4981265, 46.4885733],
              [-63.4983865, 46.4886366],
              [-63.4990468, 46.4884994],
              [-63.5002683, 46.4877244],
              [-63.5005304, 46.4874528],
              [-63.5012641, 46.4867989],
              [-63.5014456, 46.486633],
              [-63.5014482, 46.486427],
              [-63.5017391, 46.4859564],
              [-63.502292, 46.4855857],
              [-63.5025986, 46.4853265],
              [-63.5027136, 46.485109],
              [-63.5023977, 46.4847236],
              [-63.5023885, 46.4840004],
              [-63.5025989, 46.4835718],
              [-63.5028491, 46.4833786],
              [-63.5033171, 46.4828394],
              [-63.5033617, 46.4825631],
              [-63.5035086, 46.4818786],
              [-63.5040011, 46.4810973],
              [-63.5039369, 46.480787],
              [-63.5041562, 46.4804262],
              [-63.5054859, 46.4799275],
              [-63.5061253, 46.479583],
              [-63.5065288, 46.4795817],
              [-63.507464, 46.4793884],
              [-63.5082437, 46.4791498],
              [-63.5089778, 46.4788443],
              [-63.5091214, 46.4789833],
              [-63.5084739, 46.4792862],
              [-63.5084109, 46.4794403],
              [-63.508539, 46.479659],
              [-63.5089721, 46.4799598],
              [-63.5094028, 46.4801059],
              [-63.5099108, 46.480133],
              [-63.5116517, 46.4797801],
              [-63.512163, 46.4798242],
              [-63.5124657, 46.4800846],
              [-63.5125666, 46.4802828],
              [-63.5128386, 46.4805784],
              [-63.5130485, 46.4805154],
              [-63.5131677, 46.480491],
              [-63.5135342, 46.4804755],
              [-63.5124096, 46.4795908],
              [-63.5117964, 46.4793823],
              [-63.5112893, 46.4793753],
              [-63.510865, 46.4794497],
              [-63.5101413, 46.4794223],
              [-63.5098039, 46.4793271],
              [-63.5093322, 46.47905],
              [-63.5091953, 46.4789491],
              [-63.5091705, 46.4789628],
              [-63.50902, 46.4788269],
              [-63.5094894, 46.4785697],
              [-63.5095004, 46.4784832],
              [-63.5091442, 46.4779493],
              [-63.5086062, 46.4775858],
              [-63.5083203, 46.4774781],
              [-63.5079688, 46.4774306],
              [-63.5070917, 46.4771957],
              [-63.5068515, 46.4770668],
              [-63.5067019, 46.4766939],
              [-63.5068716, 46.4763791],
              [-63.507425, 46.4756595],
              [-63.5078969, 46.4751465],
              [-63.5081607, 46.474995],
              [-63.5085426, 46.474739],
              [-63.5093651, 46.4741364],
              [-63.5094411, 46.4739299],
              [-63.5096734, 46.473747],
              [-63.5099116, 46.4735262],
              [-63.5099212, 46.4731929],
              [-63.5094218, 46.472902],
              [-63.5099148, 46.4729724],
              [-63.5105967, 46.4731976],
              [-63.5111334, 46.4732136],
              [-63.5119738, 46.4729466],
              [-63.5124853, 46.4727677],
              [-63.5128694, 46.4727715],
              [-63.5131243, 46.4727078],
              [-63.5135847, 46.4726936],
              [-63.5141493, 46.472586],
              [-63.5151993, 46.4719918],
              [-63.5157376, 46.4717158],
              [-63.5159823, 46.4715357],
              [-63.5159918, 46.4712379],
              [-63.5158037, 46.4707109],
              [-63.5156077, 46.4705185],
              [-63.5155709, 46.4704106],
              [-63.5139314, 46.4686863],
              [-63.5133233, 46.4691272],
              [-63.5128771, 46.4693047],
              [-63.5120918, 46.4693354],
              [-63.5114898, 46.4692626],
              [-63.5104643, 46.46887],
              [-63.5099662, 46.4687954],
              [-63.5091921, 46.4688304],
              [-63.508677, 46.4690024],
              [-63.5081415, 46.4693691],
              [-63.5080016, 46.4695294],
              [-63.5074092, 46.4700064],
              [-63.5073131, 46.4700919],
              [-63.5070603, 46.4704529],
              [-63.5063082, 46.4708906],
              [-63.5059785, 46.4712937],
              [-63.505635, 46.4716208],
              [-63.5046446, 46.4721011],
              [-63.5039727, 46.4725484],
              [-63.5031895, 46.4727823],
              [-63.5025759, 46.4733425],
              [-63.5009811, 46.4740525],
              [-63.5004298, 46.4743831],
              [-63.5, 46.474725],
              [-63.4983557, 46.4751025],
              [-63.4976145, 46.4752345],
              [-63.4976191, 46.4757475],
              [-63.4953893, 46.4755007],
              [-63.4952021, 46.475245],
              [-63.4951903, 46.4739627],
              [-63.4950017, 46.473707],
              [-63.4957443, 46.4737037],
              [-63.4959258, 46.4731899],
              [-63.4966637, 46.4726738],
              [-63.4966544, 46.4716478],
              [-63.4964657, 46.4713921],
              [-63.4972081, 46.4713888],
              [-63.4970116, 46.4701073],
              [-63.4966379, 46.4698525],
              [-63.496447, 46.4693403],
              [-63.4957047, 46.4693436],
              [-63.4959002, 46.4703687],
              [-63.4962737, 46.4706236],
              [-63.4962784, 46.4711365],
              [-63.4959095, 46.4713945],
              [-63.4955453, 46.4721657],
              [-63.4944385, 46.4729398],
              [-63.4937031, 46.4737125],
              [-63.4937102, 46.4744821],
              [-63.4938975, 46.4747376],
              [-63.4931548, 46.4747408],
              [-63.4933481, 46.4755094],
              [-63.4948425, 46.476529],
              [-63.4955942, 46.4775516],
              [-63.4968987, 46.4781868],
              [-63.4974599, 46.4785696],
              [-63.497098, 46.4795972],
              [-63.4963601, 46.4801133],
              [-63.4956245, 46.4808859],
              [-63.4958306, 46.4831935],
              [-63.4936107, 46.4841003],
              [-63.4913852, 46.4843663],
              [-63.4910163, 46.4846243],
              [-63.4902726, 46.4844998],
              [-63.4900922, 46.4850136],
              [-63.4897232, 46.4852717],
              [-63.4886413, 46.488867],
              [-63.4873616, 46.4911809],
              [-63.4847669, 46.4917049],
              [-63.4843841, 46.4904242],
              [-63.4836379, 46.490042],
              [-63.4825229, 46.4899191],
              [-63.4823491, 46.4912022],
              [-63.4825387, 46.4917143],
              [-63.4836538, 46.4918375],
              [-63.4838414, 46.4919654],
              [-63.4840376, 46.493247],
              [-63.4847804, 46.4932439],
              [-63.4847849, 46.4937568],
              [-63.4855278, 46.4937537],
              [-63.4857187, 46.4942658],
              [-63.4860923, 46.4945207],
              [-63.4862886, 46.4958024],
              [-63.4825807, 46.4964589],
              [-63.4799798, 46.4963421],
              [-63.4799753, 46.495829],
              [-63.4792326, 46.4958321],
              [-63.4794144, 46.4953184],
              [-63.478667, 46.4948084],
              [-63.4788273, 46.4919864],
              [-63.4791999, 46.4921128],
              [-63.480684, 46.4919788],
              [-63.4806729, 46.4906963],
              [-63.4814154, 46.4906931],
              [-63.4819461, 46.4876131],
              [-63.4815724, 46.4873582],
              [-63.4811875, 46.4858209],
              [-63.4804404, 46.485311],
              [-63.4800599, 46.4842867],
              [-63.4796865, 46.4840316],
              [-63.4796774, 46.4830057],
              [-63.4789304, 46.4824961],
              [-63.4787329, 46.4812145],
              [-63.4783627, 46.4813437],
              [-63.4776201, 46.4813467],
              [-63.4772465, 46.4810919],
              [-63.4735292, 46.4805944],
              [-63.4724198, 46.481112],
              [-63.4709349, 46.4811181],
              [-63.4701878, 46.4806082],
              [-63.4687016, 46.4804865],
              [-63.4692459, 46.4789453],
              [-63.4688725, 46.4786903],
              [-63.4686819, 46.4781782],
              [-63.4679392, 46.4781812],
              [-63.468306, 46.4776667],
              [-63.4671924, 46.4776713],
              [-63.467188, 46.4771584],
              [-63.465703, 46.4771643],
              [-63.465694, 46.4761384],
              [-63.4649517, 46.4761414],
              [-63.4645565, 46.4733217],
              [-63.4630692, 46.4730711],
              [-63.4617577, 46.4715377],
              [-63.4617533, 46.4710247],
              [-63.4613799, 46.4707697],
              [-63.4613733, 46.4700001],
              [-63.4606245, 46.4692337],
              [-63.4607966, 46.4676942],
              [-63.4615389, 46.4676912],
              [-63.4614899, 46.4649511],
              [-63.4615508, 46.4644514],
              [-63.4616127, 46.4636395],
              [-63.4616899, 46.4630704],
              [-63.4616909, 46.4629017],
              [-63.461565, 46.4627634],
              [-63.4613518, 46.4627302],
              [-63.4608468, 46.4627362],
              [-63.4607268, 46.4627223],
              [-63.4606809, 46.4626791],
              [-63.4606603, 46.4625478],
              [-63.4606838, 46.462516],
              [-63.4607468, 46.4624891],
              [-63.4621509, 46.4623932],
              [-63.4625465, 46.4623367],
              [-63.4629682, 46.4622181],
              [-63.4633532, 46.4620878],
              [-63.4637578, 46.4618959],
              [-63.4643623, 46.4616273],
              [-63.4647265, 46.4614803],
              [-63.4652186, 46.4613551],
              [-63.4658129, 46.4612103],
              [-63.4661882, 46.4611311],
              [-63.4662683, 46.4610876],
              [-63.4663231, 46.4610103],
              [-63.4663571, 46.4609614],
              [-63.4664268, 46.4609472],
              [-63.4665163, 46.4609767],
              [-63.4671737, 46.4608075],
              [-63.4677328, 46.4605706],
              [-63.468402, 46.4602499],
              [-63.4689038, 46.4599099],
              [-63.4694562, 46.4592805],
              [-63.4696006, 46.4587783],
              [-63.4694162, 46.458267],
              [-63.4689608, 46.4576969],
              [-63.4684271, 46.4572575],
              [-63.4683775, 46.4570331],
              [-63.4682637, 46.4567312],
              [-63.4678868, 46.4565869],
              [-63.4673398, 46.4565246],
              [-63.4670103, 46.4563835],
              [-63.4665063, 46.4561125],
              [-63.4658311, 46.4556381],
              [-63.4648209, 46.4544832],
              [-63.4643854, 46.454061],
              [-63.4643684, 46.4539111],
              [-63.4646506, 46.4538111],
              [-63.464883, 46.4536852],
              [-63.4651434, 46.4534474],
              [-63.4653632, 46.453208],
              [-63.4653508, 46.4531609],
              [-63.4653167, 46.4531051],
              [-63.4651747, 46.4529902],
              [-63.4651606, 46.4529601],
              [-63.4651768, 46.4529311],
              [-63.4652571, 46.4529198],
              [-63.4654431, 46.4529116],
              [-63.4656555, 46.4528756],
              [-63.4658381, 46.4527981],
              [-63.4661742, 46.4525631],
              [-63.4668788, 46.4520907],
              [-63.4670345, 46.4519495],
              [-63.4673185, 46.4518085],
              [-63.4675974, 46.4516172],
              [-63.4682649, 46.4510281],
              [-63.4685089, 46.4507346],
              [-63.4689235, 46.4503097],
              [-63.4692959, 46.4499749],
              [-63.4697604, 46.4496194],
              [-63.4701036, 46.4491889],
              [-63.4715613, 46.446105],
              [-63.4721128, 46.4455899],
              [-63.4728549, 46.4455868],
              [-63.4728503, 46.4450738],
              [-63.4743357, 46.4451956],
              [-63.4784127, 46.4446655],
              [-63.4787814, 46.4444074],
              [-63.4791524, 46.4444059],
              [-63.4802699, 46.4449142],
              [-63.4847235, 46.4450242],
              [-63.4850877, 46.444253],
              [-63.4865708, 46.444118],
              [-63.4873084, 46.443602],
              [-63.4884191, 46.4433408],
              [-63.4898951, 46.4424371],
              [-63.4902638, 46.4421792],
              [-63.4921131, 46.4415296],
              [-63.4932204, 46.4408839],
              [-63.4928401, 46.4398597],
              [-63.4922865, 46.4401185],
              [-63.4921074, 46.4408888],
              [-63.4909943, 46.4408935],
              [-63.4895171, 46.4416694],
              [-63.4876679, 46.4423181],
              [-63.4869304, 46.4428343],
              [-63.4843355, 46.4431016],
              [-63.4821138, 46.443624],
              [-63.4813718, 46.4436271],
              [-63.480252, 46.4428623],
              [-63.4795078, 46.4426089],
              [-63.4780246, 46.4427438],
              [-63.4776447, 46.4417194],
              [-63.4769017, 46.441594],
              [-63.4767157, 46.4414669],
              [-63.4767068, 46.4404411],
              [-63.477258, 46.4399256],
              [-63.4783699, 46.4397924],
              [-63.4794784, 46.4392747],
              [-63.4805904, 46.4391423],
              [-63.480772, 46.4386286],
              [-63.4811406, 46.4383705],
              [-63.481321, 46.4378567],
              [-63.4814689, 46.4375164],
              [-63.4813085, 46.4364456],
              [-63.4808043, 46.4356379],
              [-63.4816716, 46.435547],
              [-63.4816671, 46.4350339],
              [-63.4812963, 46.4350356],
              [-63.4812985, 46.4352919],
              [-63.4798168, 46.4355547],
              [-63.4796365, 46.4360684],
              [-63.4790827, 46.4364551],
              [-63.47723, 46.4367194],
              [-63.4770441, 46.4365922],
              [-63.4770285, 46.4347967],
              [-63.4766531, 46.4342854],
              [-63.4764794, 46.4333792],
              [-63.4760858, 46.4331332],
              [-63.4758999, 46.4330062],
              [-63.4753396, 46.4326233],
              [-63.4720008, 46.432637],
              [-63.471815, 46.43251],
              [-63.4714309, 46.4309728],
              [-63.4710555, 46.4304614],
              [-63.4710532, 46.4302048],
              [-63.4716045, 46.4296896],
              [-63.4708626, 46.4296926],
              [-63.4697021, 46.4275413],
              [-63.4697277, 46.4271322],
              [-63.4691826, 46.4267583],
              [-63.4690062, 46.4270746],
              [-63.4693612, 46.427647],
              [-63.469741, 46.4286714],
              [-63.4689991, 46.4286744],
              [-63.469577, 46.4306467],
              [-63.4695415, 46.4311125],
              [-63.4705182, 46.4327719],
              [-63.4720054, 46.4331501],
              [-63.4753451, 46.4332649],
              [-63.4755602, 46.4365983],
              [-63.4759333, 46.4368533],
              [-63.4768605, 46.4376272],
              [-63.4783508, 46.4376128],
              [-63.4783554, 46.4381257],
              [-63.4776145, 46.4382566],
              [-63.4768771, 46.4387727],
              [-63.4761351, 46.4387758],
              [-63.4750266, 46.4392933],
              [-63.4741051, 46.439939],
              [-63.4741185, 46.4414777],
              [-63.4746922, 46.4435272],
              [-63.4741341, 46.4432731],
              [-63.4739436, 46.4427609],
              [-63.4732004, 46.4426351],
              [-63.4730145, 46.4425081],
              [-63.4728127, 46.4407136],
              [-63.4716986, 46.4405897],
              [-63.470581, 46.4400812],
              [-63.47021, 46.4400827],
              [-63.4700264, 46.4402123],
              [-63.4698446, 46.440726],
              [-63.4694736, 46.4407275],
              [-63.4694691, 46.4402144],
              [-63.4679819, 46.4398354],
              [-63.4668623, 46.4390704],
              [-63.4661192, 46.4389458],
              [-63.46593, 46.4384336],
              [-63.4655568, 46.4381785],
              [-63.4659146, 46.436638],
              [-63.4647952, 46.4358731],
              [-63.4642327, 46.4352337],
              [-63.4627487, 46.4352399],
              [-63.4594175, 46.4361513],
              [-63.4594132, 46.4356384],
              [-63.4586715, 46.4356415],
              [-63.4584823, 46.4351293],
              [-63.4573608, 46.4341077],
              [-63.4575412, 46.433594],
              [-63.4567982, 46.4334683],
              [-63.4566123, 46.4333414],
              [-63.4560468, 46.4323175],
              [-63.4553048, 46.4323206],
              [-63.4554845, 46.4315503],
              [-63.4576699, 46.4296402],
              [-63.4578616, 46.4290876],
              [-63.4565392, 46.4280991],
              [-63.4563801, 46.4278272],
              [-63.4536614, 46.4278937],
              [-63.4532227, 46.427199],
              [-63.4524765, 46.4266888],
              [-63.4517832, 46.4261209],
              [-63.4513914, 46.4258942],
              [-63.4504244, 46.4258399],
              [-63.4474551, 46.425041],
              [-63.4470851, 46.4251713],
              [-63.4465176, 46.424595],
              [-63.4463359, 46.4242759],
              [-63.44559, 46.4237659],
              [-63.4452192, 46.4237674],
              [-63.4448505, 46.4240253],
              [-63.4444794, 46.4240266],
              [-63.4435476, 46.4233895],
              [-63.4424266, 46.4223679],
              [-63.4423916, 46.4197522],
              [-63.4415349, 46.4199106],
              [-63.4400888, 46.4202671],
              [-63.4397417, 46.4201905],
              [-63.4396056, 46.4200251],
              [-63.4396062, 46.4198494],
              [-63.4397149, 46.4197357],
              [-63.4394521, 46.4193252],
              [-63.4390119, 46.4189773],
              [-63.4386581, 46.4185745],
              [-63.4384582, 46.4183775],
              [-63.437004, 46.4168738],
              [-63.4362615, 46.4167488],
              [-63.4362573, 46.4162358],
              [-63.4347739, 46.4162416],
              [-63.43477, 46.4157286],
              [-63.4332856, 46.4156053],
              [-63.4325397, 46.4150952],
              [-63.4299379, 46.4143356],
              [-63.4288244, 46.4142121],
              [-63.4295721, 46.4149787],
              [-63.4310565, 46.415101],
              [-63.4318011, 46.4154832],
              [-63.4329177, 46.415992],
              [-63.434619, 46.4167553],
              [-63.4356387, 46.4174423],
              [-63.4368519, 46.4184063],
              [-63.4373143, 46.4187279],
              [-63.4378195, 46.4190089],
              [-63.43795, 46.4191107],
              [-63.438068, 46.4192679],
              [-63.4382875, 46.4196286],
              [-63.4385111, 46.4198181],
              [-63.4385884, 46.4197819],
              [-63.4386313, 46.4198817],
              [-63.4385696, 46.4199298],
              [-63.438516, 46.420063],
              [-63.4384349, 46.4202279],
              [-63.438406, 46.4203107],
              [-63.4384516, 46.4204309],
              [-63.438575, 46.4206251],
              [-63.43887, 46.4208599],
              [-63.4393043, 46.4211386],
              [-63.4397337, 46.4213147],
              [-63.4400679, 46.4215728],
              [-63.4401628, 46.421825],
              [-63.4401172, 46.4220377],
              [-63.4402567, 46.4219822],
              [-63.4404157, 46.4219889],
              [-63.4403801, 46.4220747],
              [-63.4402567, 46.4222078],
              [-63.4403801, 46.4223002],
              [-63.4405625, 46.4223039],
              [-63.4408629, 46.4222669],
              [-63.4411443, 46.422303],
              [-63.4419203, 46.4227582],
              [-63.4424155, 46.423138],
              [-63.4428841, 46.4235056],
              [-63.4438341, 46.4239973],
              [-63.4441877, 46.4243255],
              [-63.4448372, 46.4246673],
              [-63.4457221, 46.4251378],
              [-63.4470913, 46.4259406],
              [-63.4485815, 46.4267042],
              [-63.4485772, 46.4261913],
              [-63.4504348, 46.4265683],
              [-63.4512151, 46.4267801],
              [-63.4521529, 46.4281288],
              [-63.4527872, 46.4282593],
              [-63.4535738, 46.4287688],
              [-63.4551688, 46.4292834],
              [-63.4554273, 46.4296442],
              [-63.454965, 46.4303057],
              [-63.4541481, 46.4312287],
              [-63.4536662, 46.4320025],
              [-63.4535994, 46.4328525],
              [-63.4541363, 46.4339398],
              [-63.4554165, 46.4345234],
              [-63.4577954, 46.4357114],
              [-63.4579423, 46.4371834],
              [-63.4594262, 46.4371774],
              [-63.4594304, 46.4376903],
              [-63.4627672, 46.4374204],
              [-63.4625846, 46.4376775],
              [-63.4629641, 46.4387021],
              [-63.4633482, 46.4402394],
              [-63.4639085, 46.4407502],
              [-63.4631665, 46.4407532],
              [-63.4635417, 46.4412645],
              [-63.4642829, 46.4411328],
              [-63.4657713, 46.4416398],
              [-63.4698534, 46.4417518],
              [-63.4696708, 46.442009],
              [-63.4696884, 46.4440608],
              [-63.4698756, 46.4443166],
              [-63.4691335, 46.4443197],
              [-63.46878, 46.446373],
              [-63.468039, 46.4465039],
              [-63.4669346, 46.4475342],
              [-63.4661936, 46.447666],
              [-63.4661979, 46.4481789],
              [-63.465457, 46.44831],
              [-63.4649043, 46.4486974],
              [-63.4647224, 46.449211],
              [-63.4639814, 46.4493418],
              [-63.4628726, 46.4498594],
              [-63.4621293, 46.4497344],
              [-63.4619488, 46.4502481],
              [-63.4615799, 46.4505061],
              [-63.4613982, 46.4510199],
              [-63.4602858, 46.4511521],
              [-63.4601021, 46.4512815],
              [-63.459375, 46.45308],
              [-63.4595706, 46.4543617],
              [-63.4588284, 46.4543645],
              [-63.4594009, 46.4561577],
              [-63.4597743, 46.4564128],
              [-63.4599656, 46.4571814],
              [-63.4614521, 46.457432],
              [-63.4612738, 46.4582021],
              [-63.4609937, 46.4590772],
              [-63.4601698, 46.4596494],
              [-63.4597356, 46.4600435],
              [-63.4593689, 46.4603586],
              [-63.4590187, 46.4608665],
              [-63.4590495, 46.4611746],
              [-63.4591231, 46.4615226],
              [-63.4591934, 46.4616892],
              [-63.4593636, 46.4619195],
              [-63.459671, 46.4622573],
              [-63.4598364, 46.4624149],
              [-63.4599916, 46.4624927],
              [-63.460216, 46.4625107],
              [-63.4602259, 46.4627227],
              [-63.460109, 46.4627288],
              [-63.460072, 46.4631907],
              [-63.4600528, 46.4632072],
              [-63.4600342, 46.4632194],
              [-63.460008, 46.4632243],
              [-63.4588725, 46.4631464],
              [-63.4585284, 46.4630961],
              [-63.4584671, 46.4634466],
              [-63.4584431, 46.4635534],
              [-63.4583621, 46.4638192],
              [-63.4594807, 46.4639152],
              [-63.4594969, 46.46398],
              [-63.4581993, 46.4638932],
              [-63.4577533, 46.4638272],
              [-63.4575881, 46.4638261],
              [-63.4574706, 46.4638003],
              [-63.4574063, 46.4638205],
              [-63.4565049, 46.46489],
              [-63.4563205, 46.4652891],
              [-63.4555816, 46.4655344],
              [-63.4529888, 46.4661862],
              [-63.4526754, 46.4669845],
              [-63.4521283, 46.4672715],
              [-63.4516854, 46.4675766],
              [-63.4516203, 46.4678459],
              [-63.4517504, 46.4681777],
              [-63.4509691, 46.4687593],
              [-63.4504218, 46.4692455],
              [-63.4501481, 46.4693621],
              [-63.4498485, 46.4694338],
              [-63.449536, 46.4693353],
              [-63.4492494, 46.4692814],
              [-63.4488715, 46.4691916],
              [-63.4482141, 46.4693249],
              [-63.4479781, 46.4693989],
              [-63.4473473, 46.4696671],
              [-63.4471519, 46.4698377],
              [-63.4469566, 46.4702683],
              [-63.4469957, 46.470654],
              [-63.447204, 46.4710219],
              [-63.4477512, 46.4714795],
              [-63.4482107, 46.4719757],
              [-63.4489542, 46.4721017],
              [-63.4489586, 46.4726146],
              [-63.4511879, 46.4728621],
              [-63.4518449, 46.4730286],
              [-63.452965, 46.4741395],
              [-63.453824, 46.4751938],
              [-63.454469, 46.4769609],
              [-63.4538291, 46.4779816],
              [-63.4523515, 46.4788847],
              [-63.4504973, 46.4791484],
              [-63.4480129, 46.4785994],
              [-63.44766, 46.4785435],
              [-63.4474692, 46.478495],
              [-63.4464017, 46.4777542],
              [-63.4461552, 46.4775463],
              [-63.44585, 46.4772606],
              [-63.4455181, 46.4768384],
              [-63.4453194, 46.4766066],
              [-63.4450117, 46.4755034],
              [-63.4447233, 46.4750807],
              [-63.444647, 46.4747275],
              [-63.4442861, 46.4744461],
              [-63.4438982, 46.4740526],
              [-63.4436811, 46.4738472],
              [-63.4431063, 46.4736205],
              [-63.4429879, 46.4735121],
              [-63.4416397, 46.4724599],
              [-63.4410304, 46.4722783],
              [-63.4404687, 46.4722635],
              [-63.4395173, 46.4725164],
              [-63.4390837, 46.4728771],
              [-63.4390042, 46.4728992],
              [-63.4388985, 46.4728735],
              [-63.4385241, 46.4732011],
              [-63.4385183, 46.4732887],
              [-63.4382032, 46.4736927],
              [-63.4381316, 46.4738615],
              [-63.4382806, 46.4741201],
              [-63.43857, 46.4744497],
              [-63.4385929, 46.4748139],
              [-63.4388565, 46.475791],
              [-63.4384244, 46.4782981],
              [-63.4391711, 46.4788083],
              [-63.4395404, 46.4789826],
              [-63.439614, 46.4790907],
              [-63.4397375, 46.4795925],
              [-63.4400943, 46.479822],
              [-63.4402265, 46.4803128],
              [-63.4405593, 46.4805784],
              [-63.4407171, 46.480909],
              [-63.4409874, 46.4810394],
              [-63.4413901, 46.4811478],
              [-63.4414879, 46.4812544],
              [-63.4414943, 46.4813569],
              [-63.4409796, 46.4820674],
              [-63.440793, 46.4826284],
              [-63.4407856, 46.4827325],
              [-63.4410754, 46.4829688],
              [-63.4410997, 46.4830725],
              [-63.4408477, 46.483408],
              [-63.4409656, 46.4835204],
              [-63.4410599, 46.4836607],
              [-63.4409797, 46.4840043],
              [-63.44106, 46.4843845],
              [-63.4413762, 46.4850057],
              [-63.4417137, 46.4854258],
              [-63.4423858, 46.4858775],
              [-63.4425218, 46.4861305],
              [-63.4426071, 46.4863673],
              [-63.442589, 46.4866706],
              [-63.4418386, 46.4872621],
              [-63.4414742, 46.4875717],
              [-63.44043, 46.4880527],
              [-63.4396189, 46.4882765],
              [-63.4389511, 46.4883839],
              [-63.438137, 46.4884336],
              [-63.4374555, 46.4884092],
              [-63.4365926, 46.4885613],
              [-63.4349837, 46.4892116],
              [-63.4344275, 46.4892977],
              [-63.4335709, 46.4896672],
              [-63.4330386, 46.489727],
              [-63.4320009, 46.4899213],
              [-63.4303533, 46.4903496],
              [-63.4296783, 46.4903418],
              [-63.4290795, 46.490446],
              [-63.428651, 46.4904622],
              [-63.4280529, 46.4903626],
              [-63.4275588, 46.4904657],
              [-63.4272705, 46.4904427],
              [-63.4273099, 46.4905398],
              [-63.4276012, 46.4906797],
              [-63.4277488, 46.4908204],
              [-63.4276697, 46.4909911],
              [-63.4274774, 46.4912109],
              [-63.4271684, 46.4913552],
              [-63.4269762, 46.4914669],
              [-63.4263914, 46.4914289],
              [-63.4260505, 46.4915986],
              [-63.4255068, 46.4916576],
              [-63.425206, 46.4918263],
              [-63.4253008, 46.4919109],
              [-63.4255947, 46.4918158],
              [-63.4260012, 46.49181],
              [-63.4262991, 46.4918679],
              [-63.4265094, 46.4919957],
              [-63.426597, 46.4922394],
              [-63.4265799, 46.4924112],
              [-63.4266385, 46.492664],
              [-63.4264969, 46.492795],
              [-63.426401, 46.4930836],
              [-63.4264253, 46.4932671],
              [-63.4263062, 46.4933467],
              [-63.4261757, 46.4935406],
              [-63.4260329, 46.4937714],
              [-63.4258929, 46.4940114],
              [-63.4255732, 46.4943702],
              [-63.4253037, 46.4946307],
              [-63.4248015, 46.4950145],
              [-63.4242657, 46.4957928],
              [-63.4235259, 46.4961796],
              [-63.4224127, 46.4963125],
              [-63.4218603, 46.4968276],
              [-63.4210625, 46.4976276],
              [-63.4208165, 46.4982713],
              [-63.4208469, 46.4988433],
              [-63.4203924, 46.4991412],
              [-63.4199246, 46.499513],
              [-63.4194261, 46.4997404],
              [-63.4195193, 46.5004409],
              [-63.4193572, 46.500699],
              [-63.4195076, 46.50071],
              [-63.4196409, 46.5006501],
              [-63.4200058, 46.5006222],
              [-63.420553, 46.500385],
              [-63.4209939, 46.5004042],
              [-63.4214651, 46.5005664],
              [-63.4216171, 46.5007495],
              [-63.4214918, 46.5009401],
              [-63.4209282, 46.5015332],
              [-63.4210698, 46.5016773],
              [-63.4212927, 46.5016808],
              [-63.4215562, 46.5016058],
              [-63.421891, 46.5014105],
              [-63.4224076, 46.5012344],
              [-63.4227319, 46.5010861],
              [-63.4232437, 46.5010948],
              [-63.4237048, 46.5011315],
              [-63.4238467, 46.5010268],
              [-63.424575, 46.5010213],
              [-63.4249665, 46.5011297],
              [-63.42533, 46.5013201],
              [-63.4262959, 46.5017325],
              [-63.4267485, 46.5014458],
              [-63.427282, 46.501552],
              [-63.4279349, 46.5016997],
              [-63.4295362, 46.502116],
              [-63.4301613, 46.5020523],
              [-63.4312442, 46.5016142],
              [-63.4321459, 46.501686],
              [-63.4328847, 46.5022076],
              [-63.4343069, 46.5027667],
              [-63.4362165, 46.5028421],
              [-63.4370849, 46.5024727],
              [-63.4380098, 46.5023451],
              [-63.4387049, 46.502556],
              [-63.439774, 46.5028497],
              [-63.4408978, 46.5028591],
              [-63.4427491, 46.5032007],
              [-63.4435582, 46.5034501],
              [-63.4447891, 46.5034954],
              [-63.4457545, 46.5038351],
              [-63.4468431, 46.50399],
              [-63.4477664, 46.5043635],
              [-63.4489135, 46.5043349],
              [-63.4492837, 46.5044879],
              [-63.4502289, 46.504868],
              [-63.4518903, 46.504825],
              [-63.4527463, 46.5052392],
              [-63.4533907, 46.5054675],
              [-63.4541403, 46.5054744],
              [-63.4555297, 46.5054051],
              [-63.4562414, 46.5056882],
              [-63.4576946, 46.5058689],
              [-63.4584631, 46.5056213],
              [-63.4594292, 46.5056184],
              [-63.459913, 46.505775],
              [-63.4602265, 46.5061011],
              [-63.4606768, 46.5063843],
              [-63.4611527, 46.5061259],
              [-63.4617751, 46.5061557],
              [-63.4623434, 46.5062544],
              [-63.4629015, 46.5064586],
              [-63.4628827, 46.5071114],
              [-63.4645645, 46.507721],
              [-63.4661143, 46.5080006],
              [-63.4672798, 46.5075792],
              [-63.4690466, 46.5078738],
              [-63.4696376, 46.5076971],
              [-63.4692321, 46.5074988],
              [-63.4697713, 46.5072362],
              [-63.4719058, 46.5079592],
              [-63.4727657, 46.5079753],
              [-63.4728975, 46.5082639],
              [-63.4722132, 46.5090913],
              [-63.4716772, 46.5094554],
              [-63.4708674, 46.5097394],
              [-63.467489, 46.5095922],
              [-63.463365, 46.5090836],
              [-63.4619574, 46.5087022],
              [-63.4607135, 46.5085293],
              [-63.4594461, 46.5082152],
              [-63.4563064, 46.5078522],
              [-63.4532046, 46.5071106],
              [-63.4499259, 46.5065757],
              [-63.4444467, 46.5057072],
              [-63.4431787, 46.5054196],
              [-63.4409221, 46.5048166],
              [-63.4397164, 46.5047252],
              [-63.4354758, 46.5043426],
              [-63.4322862, 46.5038219],
              [-63.4281482, 46.5034486],
              [-63.4268008, 46.5031353],
              [-63.4260559, 46.5030446],
              [-63.4253515, 46.5029539],
              [-63.4247232, 46.50279],
              [-63.4243584, 46.5026749],
              [-63.423921, 46.502624],
              [-63.4235172, 46.5026365],
              [-63.4231929, 46.5026975],
              [-63.4229294, 46.5027272],
              [-63.4226203, 46.5027254],
              [-63.4217133, 46.5025528],
              [-63.4208805, 46.5023861],
              [-63.4205529, 46.5023435],
              [-63.4202235, 46.5023331],
              [-63.4196611, 46.5024429],
              [-63.4193216, 46.5024237],
              [-63.4190176, 46.5023261],
              [-63.4184348, 46.5022267],
              [-63.4177219, 46.5021627],
              [-63.4168184, 46.5021168],
              [-63.4161951, 46.5021377],
              [-63.4154411, 46.5020386],
              [-63.4148878, 46.502068],
              [-63.4142544, 46.5020017],
              [-63.413474, 46.5020565],
              [-63.4126481, 46.5020122],
              [-63.411514, 46.5020778],
              [-63.4111175, 46.5020367],
              [-63.4107305, 46.502086],
              [-63.4098563, 46.5020959],
              [-63.4090823, 46.5020416],
              [-63.4086309, 46.5020071],
              [-63.4080904, 46.5019917],
              [-63.4072837, 46.5019874],
              [-63.4063848, 46.5018662],
              [-63.405423, 46.5015776],
              [-63.404477, 46.5014755],
              [-63.4027113, 46.5010935],
              [-63.4017086, 46.5009416],
              [-63.4009644, 46.5008656],
              [-63.4001545, 46.50079],
              [-63.3970985, 46.5004532],
              [-63.3943265, 46.5000504],
              [-63.3931694, 46.4997977],
              [-63.3924599, 46.4996805],
              [-63.3917005, 46.499636],
              [-63.3898434, 46.4993675],
              [-63.3886624, 46.4990837],
              [-63.3880675, 46.4989499],
              [-63.3874007, 46.498917],
              [-63.3855166, 46.4990096],
              [-63.3847619, 46.4991411],
              [-63.3843483, 46.4992616],
              [-63.3840984, 46.4993887],
              [-63.3839345, 46.4995541],
              [-63.3836587, 46.4996413],
              [-63.38318, 46.4999023],
              [-63.3830402, 46.4998594],
              [-63.3830922, 46.4996259],
              [-63.382932, 46.4994951],
              [-63.3827612, 46.4996078],
              [-63.382686, 46.4997798],
              [-63.3825668, 46.4997978],
              [-63.3824849, 46.499844],
              [-63.3823767, 46.4997619],
              [-63.3821121, 46.4997234],
              [-63.3820087, 46.4998012],
              [-63.38187, 46.4998671],
              [-63.3817544, 46.5000287],
              [-63.3814185, 46.5000757],
              [-63.381043, 46.5003973],
              [-63.3808898, 46.5004186],
              [-63.3802637, 46.5002442],
              [-63.3800737, 46.5002955],
              [-63.3797532, 46.5002801],
              [-63.3796888, 46.5002429],
              [-63.3794439, 46.5002262],
              [-63.3793154, 46.5002389],
              [-63.3791271, 46.5002082],
              [-63.3789264, 46.5001369],
              [-63.3784824, 46.5002236],
              [-63.3783109, 46.500357],
              [-63.3781432, 46.5004502],
              [-63.3777969, 46.5008203],
              [-63.3773364, 46.5010774],
              [-63.3770163, 46.5011494],
              [-63.3767634, 46.5011494],
              [-63.376405, 46.5012329],
              [-63.3762047, 46.5012837],
              [-63.3759992, 46.5013925],
              [-63.3758463, 46.5013417],
              [-63.3756513, 46.5013635],
              [-63.3754958, 46.501488],
              [-63.3754404, 46.5015666],
              [-63.3753782, 46.5015946],
              [-63.3752085, 46.5015231],
              [-63.3751693, 46.5014382],
              [-63.3747343, 46.5014396],
              [-63.374555, 46.5015557],
              [-63.3743633, 46.5016583],
              [-63.3740926, 46.5016248],
              [-63.3734192, 46.5008433],
              [-63.3731689, 46.500413],
              [-63.3730403, 46.5003353],
              [-63.3729054, 46.5002352],
              [-63.3725418, 46.5000683],
              [-63.3722308, 46.499974],
              [-63.3719684, 46.499849],
              [-63.3715923, 46.4996745],
              [-63.3709066, 46.4995198],
              [-63.3706865, 46.4994951],
              [-63.3705073, 46.4995096],
              [-63.3704001, 46.4995645],
              [-63.3703059, 46.499541],
              [-63.3701753, 46.4996039],
              [-63.3699954, 46.4995889],
              [-63.3697364, 46.4994172],
              [-63.3695113, 46.4993899],
              [-63.3693259, 46.4993852],
              [-63.3692687, 46.4995785],
              [-63.3691739, 46.4996765],
              [-63.3690282, 46.4998123],
              [-63.3687599, 46.4996789],
              [-63.3685933, 46.4996178],
              [-63.3684008, 46.499589],
              [-63.3682088, 46.4993212],
              [-63.3673804, 46.4990644],
              [-63.3663643, 46.4987977],
              [-63.3660709, 46.498877],
              [-63.365788, 46.4987905],
              [-63.3653481, 46.4986823],
              [-63.3647195, 46.4986246],
              [-63.3644052, 46.4986463],
              [-63.3641119, 46.4986246],
              [-63.3638036, 46.4987195],
              [-63.3637073, 46.4986686],
              [-63.3634629, 46.4988011],
              [-63.3631814, 46.4987348],
              [-63.3628108, 46.4986782],
              [-63.3625073, 46.4987399],
              [-63.3621665, 46.498796],
              [-63.3620184, 46.4989592],
              [-63.3619073, 46.4989541],
              [-63.3618924, 46.4988062],
              [-63.361848, 46.4986635],
              [-63.3615185, 46.4985732],
              [-63.3605961, 46.4984595],
              [-63.359685, 46.4984187],
              [-63.359072, 46.4983236],
              [-63.3581723, 46.4980706],
              [-63.3572404, 46.4978527],
              [-63.3556506, 46.4976801],
              [-63.3550646, 46.4975828],
              [-63.3546485, 46.4975562],
              [-63.3542249, 46.4974236],
              [-63.3536623, 46.4972963],
              [-63.3531769, 46.4972115],
              [-63.352584, 46.4971208],
              [-63.3517929, 46.4970405],
              [-63.3504004, 46.4970633],
              [-63.3495505, 46.4970821],
              [-63.3494756, 46.4971065],
              [-63.3493544, 46.4971459],
              [-63.348925, 46.4970778],
              [-63.3484827, 46.4970821],
              [-63.3479619, 46.4970195],
              [-63.3474312, 46.4970371],
              [-63.3466848, 46.4969433],
              [-63.3463726, 46.4968538],
              [-63.3459935, 46.4966559],
              [-63.3458023, 46.496602],
              [-63.345601, 46.4966084],
              [-63.3451079, 46.4965705],
              [-63.3448652, 46.4964933],
              [-63.3442441, 46.4964145],
              [-63.343841, 46.4962945],
              [-63.3432255, 46.4962009],
              [-63.3422177, 46.4961872],
              [-63.3412913, 46.4962645],
              [-63.3409644, 46.4963358],
              [-63.3409208, 46.4963958],
              [-63.3403923, 46.496437],
              [-63.340002, 46.4964124],
              [-63.3396254, 46.4964307],
              [-63.3392373, 46.4965233],
              [-63.3391665, 46.496587],
              [-63.3390521, 46.496587],
              [-63.3389922, 46.4965345],
              [-63.3387906, 46.496587],
              [-63.3386054, 46.4965833],
              [-63.338273, 46.4967221],
              [-63.3383438, 46.4968121],
              [-63.3382567, 46.4968421],
              [-63.3379813, 46.4965927],
              [-63.33775, 46.4963883],
              [-63.3374613, 46.4962007],
              [-63.3373301, 46.4962486],
              [-63.3370418, 46.496062],
              [-63.3368293, 46.4959832],
              [-63.3363379, 46.4958903],
              [-63.3361374, 46.4959007],
              [-63.3359358, 46.4958032],
              [-63.3347699, 46.4956307],
              [-63.3346337, 46.4956644],
              [-63.3340344, 46.4956944],
              [-63.3335332, 46.4956194],
              [-63.3331355, 46.4956457],
              [-63.3327977, 46.4956644],
              [-63.3325361, 46.495582],
              [-63.3319718, 46.4952791],
              [-63.3313087, 46.4950508],
              [-63.3306901, 46.4948793],
              [-63.3301609, 46.494753],
              [-63.3293928, 46.4946068],
              [-63.3289951, 46.4945655],
              [-63.328581, 46.4945505],
              [-63.3281452, 46.4945955],
              [-63.3275568, 46.4946593],
              [-63.3271155, 46.4949256],
              [-63.3269319, 46.4952187],
              [-63.3268569, 46.4951766],
              [-63.3270174, 46.4947718],
              [-63.3271155, 46.4945093],
              [-63.3268703, 46.4941605],
              [-63.3263332, 46.4939502],
              [-63.3255955, 46.4937629],
              [-63.3247821, 46.4934734],
              [-63.3241541, 46.4930373],
              [-63.3240646, 46.4926752],
              [-63.3236229, 46.4923655],
              [-63.3224813, 46.492021],
              [-63.3216075, 46.4918425],
              [-63.3211934, 46.4917938],
              [-63.3204743, 46.4919475],
              [-63.3201855, 46.4920113],
              [-63.3200077, 46.4921412],
              [-63.3200221, 46.4922739],
              [-63.3198913, 46.4923751],
              [-63.3197516, 46.4922411],
              [-63.3191359, 46.4915175],
              [-63.3189814, 46.4913137],
              [-63.3186273, 46.4911336],
              [-63.3183167, 46.4910661],
              [-63.3180131, 46.4908911],
              [-63.3175743, 46.4907982],
              [-63.3173851, 46.490676],
              [-63.3170473, 46.490571],
              [-63.3168241, 46.4906685],
              [-63.3166665, 46.4904701],
              [-63.3162684, 46.4903647],
              [-63.315749, 46.490211],
              [-63.3151624, 46.4901884],
              [-63.3148737, 46.4902709],
              [-63.3146728, 46.4903752],
              [-63.3142919, 46.4903275],
              [-63.3139295, 46.4902495],
              [-63.3137729, 46.4898664],
              [-63.3133946, 46.4895681],
              [-63.3129614, 46.4894832],
              [-63.3124289, 46.4895185],
              [-63.3123076, 46.4892507],
              [-63.3124384, 46.4886505],
              [-63.3124857, 46.487987],
              [-63.3120405, 46.4873438],
              [-63.3119901, 46.4871735],
              [-63.3122401, 46.4869198],
              [-63.3125855, 46.4864524],
              [-63.3127797, 46.4860279],
              [-63.3125759, 46.4854911],
              [-63.3113821, 46.4841385],
              [-63.3107835, 46.4837773],
              [-63.3098054, 46.4833356],
              [-63.3094075, 46.4831533],
              [-63.3090564, 46.4830948],
              [-63.3089914, 46.482957],
              [-63.3088682, 46.4829623],
              [-63.3088219, 46.4830313],
              [-63.3086833, 46.4829517],
              [-63.3087064, 46.4828456],
              [-63.30856, 46.4827077],
              [-63.3082925, 46.4827081],
              [-63.3081131, 46.4825963],
              [-63.3080823, 46.4824477],
              [-63.3076005, 46.4820709],
              [-63.3073849, 46.482055],
              [-63.3072137, 46.4818908],
              [-63.3070829, 46.4816357],
              [-63.3066812, 46.4813832],
              [-63.3063576, 46.481139],
              [-63.3060765, 46.4805242],
              [-63.3058242, 46.4802449],
              [-63.3049474, 46.4795928],
              [-63.3047551, 46.4795309],
              [-63.30436, 46.4792393],
              [-63.3039115, 46.4789262],
              [-63.3036779, 46.4783006],
              [-63.3035033, 46.4781607],
              [-63.3034571, 46.4777681],
              [-63.3035654, 46.4772933],
              [-63.3042008, 46.4770662],
              [-63.3051275, 46.4766796],
              [-63.305391, 46.4764629],
              [-63.305337, 46.4758291],
              [-63.3049879, 46.4752866],
              [-63.3046151, 46.474941],
              [-63.3041871, 46.47463],
              [-63.3037604, 46.4743084],
              [-63.3033955, 46.4739121],
              [-63.3027455, 46.4735161],
              [-63.3024709, 46.4730351],
              [-63.3020332, 46.4724944],
              [-63.3013754, 46.4721329],
              [-63.3010727, 46.4710751],
              [-63.3007308, 46.4706094],
              [-63.3001265, 46.4702227],
              [-63.2993349, 46.4698973],
              [-63.2987536, 46.469554],
              [-63.2984181, 46.4692573],
              [-63.2982145, 46.4685065],
              [-63.2983114, 46.4683542],
              [-63.2982388, 46.4675459],
              [-63.2981599, 46.4672778],
              [-63.2983493, 46.4671761],
              [-63.2991324, 46.4666923],
              [-63.2994066, 46.4665505],
              [-63.2995785, 46.4663534],
              [-63.2995844, 46.466192],
              [-63.2995637, 46.4659434],
              [-63.299369, 46.4649316],
              [-63.2986572, 46.4636438],
              [-63.2982774, 46.4631526],
              [-63.2979529, 46.4627328],
              [-63.2974725, 46.4623152],
              [-63.2963193, 46.4614411],
              [-63.2959209, 46.4610917],
              [-63.2954523, 46.4606909],
              [-63.2946798, 46.4601359],
              [-63.2935927, 46.4593788],
              [-63.2911201, 46.4577357],
              [-63.2906414, 46.4574012],
              [-63.2904347, 46.4572599],
              [-63.2881256, 46.4571717],
              [-63.2881095, 46.4572826],
              [-63.2878865, 46.4572775],
              [-63.2879181, 46.4570411],
              [-63.2907703, 46.45712],
              [-63.2908829, 46.4570424],
              [-63.291119, 46.4567985],
              [-63.2914177, 46.456347],
              [-63.2914516, 46.4562959],
              [-63.2916185, 46.4559632],
              [-63.2916529, 46.4557323],
              [-63.2915455, 46.4550054],
              [-63.2916251, 46.4541493],
              [-63.2915657, 46.4539428],
              [-63.2915448, 46.4539277],
              [-63.2915127, 46.4538819],
              [-63.2916186, 46.4538588],
              [-63.2919231, 46.4539188],
              [-63.2925387, 46.4541376],
              [-63.2925065, 46.4541738],
              [-63.2922414, 46.4540885],
              [-63.2922994, 46.4543843],
              [-63.2924716, 46.4546733],
              [-63.2930858, 46.4549516],
              [-63.2928808, 46.4550701],
              [-63.2929782, 46.4551468],
              [-63.2932844, 46.454985],
              [-63.2944791, 46.4557993],
              [-63.2941065, 46.4560058],
              [-63.2941251, 46.4560213],
              [-63.2941458, 46.4560385],
              [-63.2945686, 46.4563901],
              [-63.2945696, 46.4564098],
              [-63.2945712, 46.4564403],
              [-63.294572, 46.4564563],
              [-63.2942485, 46.4565551],
              [-63.2941165, 46.4565736],
              [-63.2940277, 46.4566328],
              [-63.2941518, 46.4568516],
              [-63.2949551, 46.4570214],
              [-63.295121, 46.4571253],
              [-63.2957366, 46.4571963],
              [-63.2962969, 46.4573373],
              [-63.301864, 46.4573228],
              [-63.3029746, 46.4568068],
              [-63.3052014, 46.4568009],
              [-63.3076484, 46.4568978],
              [-63.3074902, 46.456225],
              [-63.3075802, 46.456225],
              [-63.3077242, 46.4568511],
              [-63.3093674, 46.4568813],
              [-63.3103421, 46.4568877],
              [-63.3113526, 46.4563477],
              [-63.3114801, 46.4564407],
              [-63.3115469, 46.4563903],
              [-63.3114162, 46.4562886],
              [-63.3118914, 46.4557956],
              [-63.3133491, 46.4538296],
              [-63.3126075, 46.4539596],
              [-63.3122357, 46.4538327],
              [-63.3113943, 46.4544469],
              [-63.3115001, 46.4549886],
              [-63.311125, 46.4550071],
              [-63.3106763, 46.4548541],
              [-63.3103859, 46.4548636],
              [-63.3081662, 46.4545248],
              [-63.3074099, 46.4535894],
              [-63.3067722, 46.4531977],
              [-63.3062922, 46.4528228],
              [-63.3056044, 46.4525188],
              [-63.3043216, 46.4503803],
              [-63.3049519, 46.4496904],
              [-63.3056128, 46.4492741],
              [-63.3068178, 46.4471787],
              [-63.3071875, 46.4469212],
              [-63.3075542, 46.4461507],
              [-63.3079238, 46.4458933],
              [-63.3086574, 46.4443525],
              [-63.3082775, 46.4428143],
              [-63.3084566, 46.441788],
              [-63.3095704, 46.4419128],
              [-63.3101256, 46.4415271],
              [-63.3104921, 46.4407566],
              [-63.3112312, 46.4402415],
              [-63.3112268, 46.439472],
              [-63.3115964, 46.4392147],
              [-63.3112035, 46.4353684],
              [-63.311412, 46.434869],
              [-63.3142318, 46.4344193],
              [-63.3150455, 46.4344132],
              [-63.3162237, 46.4341804],
              [-63.3169425, 46.4335571],
              [-63.3176839, 46.4334264],
              [-63.3178691, 46.4332981],
              [-63.317677, 46.4322727],
              [-63.3187574, 46.4318202],
              [-63.3198977, 46.4313684],
              [-63.3206386, 46.4312385],
              [-63.3206356, 46.4307253],
              [-63.322862, 46.4308468],
              [-63.3232346, 46.4311025],
              [-63.3250893, 46.4310972],
              [-63.3261991, 46.430581],
              [-63.3280546, 46.4307043],
              [-63.3280576, 46.4312173],
              [-63.3295438, 46.4315975],
              [-63.3299163, 46.4318527],
              [-63.3302874, 46.4318519],
              [-63.3313955, 46.431079],
              [-63.3336196, 46.430816],
              [-63.3373226, 46.4297792],
              [-63.3380647, 46.4297772],
              [-63.3399225, 46.4302847],
              [-63.340295, 46.4305399],
              [-63.3417788, 46.4305355],
              [-63.3419659, 46.4306636],
              [-63.3421537, 46.4311762],
              [-63.3436376, 46.4311715],
              [-63.3436327, 46.4304021],
              [-63.343261, 46.4302745],
              [-63.3425191, 46.4302769],
              [-63.3414014, 46.4295106],
              [-63.3406579, 46.4292564],
              [-63.3395452, 46.4292597],
              [-63.3388017, 46.4290054],
              [-63.3354632, 46.4290152],
              [-63.3339771, 46.4286353],
              [-63.3330442, 46.4276122],
              [-63.3330412, 46.427099],
              [-63.3326687, 46.4268436],
              [-63.3324779, 46.4260749],
              [-63.3332197, 46.4260726],
              [-63.3334028, 46.425559],
              [-63.3341414, 46.425044],
              [-63.3345074, 46.4242734],
              [-63.334877, 46.4240159],
              [-63.3346783, 46.4219644],
              [-63.3331932, 46.4217124],
              [-63.3333747, 46.4209424],
              [-63.3342988, 46.4205545],
              [-63.3368949, 46.4205467],
              [-63.3381913, 46.4201587],
              [-63.3383729, 46.4196453],
              [-63.3402279, 46.4197675],
              [-63.3404133, 46.4196391],
              [-63.3404053, 46.4183569],
              [-63.3405886, 46.4180998],
              [-63.3398468, 46.4181018],
              [-63.3396556, 46.4170765],
              [-63.3402088, 46.4166896],
              [-63.3416914, 46.4165576],
              [-63.3418743, 46.4160438],
              [-63.3426113, 46.4152724],
              [-63.3432002, 46.4141578],
              [-63.3434255, 46.4137694],
              [-63.3437742, 46.4137472],
              [-63.3435005, 46.4134845],
              [-63.3437634, 46.4131036],
              [-63.3442087, 46.4126561],
              [-63.3446217, 46.4122752],
              [-63.3448041, 46.4121013],
              [-63.3460165, 46.4119128],
              [-63.346805, 46.4117055],
              [-63.3474328, 46.4113802],
              [-63.3476151, 46.4109881],
              [-63.3474971, 46.4106663],
              [-63.3476312, 46.4104629],
              [-63.3479209, 46.4102558],
              [-63.3480282, 46.4100264],
              [-63.3483983, 46.4098193],
              [-63.3487041, 46.409797],
              [-63.3492245, 46.409516],
              [-63.3495945, 46.4091312],
              [-63.3500345, 46.4086135],
              [-63.350292, 46.4078109],
              [-63.3505065, 46.4071709],
              [-63.3506729, 46.4069231],
              [-63.3510322, 46.4067233],
              [-63.3513917, 46.4065199],
              [-63.3520621, 46.4064607],
              [-63.3531223, 46.4063909],
              [-63.3534891, 46.4063571],
              [-63.3542322, 46.4060032],
              [-63.3548513, 46.4056407],
              [-63.3553574, 46.4049983],
              [-63.35385, 46.4042088],
              [-63.3536881, 46.4048489],
              [-63.3531264, 46.4051122],
              [-63.3528124, 46.4052842],
              [-63.3523742, 46.4053672],
              [-63.3517242, 46.405473],
              [-63.3505012, 46.4055249],
              [-63.3499379, 46.4056026],
              [-63.349088, 46.4060552],
              [-63.3489861, 46.406169],
              [-63.3488681, 46.4062725],
              [-63.3487085, 46.4063937],
              [-63.3486892, 46.4064458],
              [-63.3486508, 46.406549],
              [-63.3485636, 46.4067783],
              [-63.348561, 46.4068681],
              [-63.3485878, 46.4069411],
              [-63.3486112, 46.4069945],
              [-63.3486441, 46.4070697],
              [-63.3486455, 46.4071686],
              [-63.3486253, 46.4073628],
              [-63.3486334, 46.4075589],
              [-63.3486119, 46.4079519],
              [-63.3486199, 46.4083364],
              [-63.3485999, 46.4084547],
              [-63.3485341, 46.4088414],
              [-63.348455, 46.4090976],
              [-63.3482538, 46.4093971],
              [-63.3481546, 46.4095812],
              [-63.3479922, 46.4097041],
              [-63.347649, 46.4097781],
              [-63.3466494, 46.4104444],
              [-63.3465852, 46.4107514],
              [-63.34629, 46.411025],
              [-63.3454104, 46.4110399],
              [-63.344595, 46.4112026],
              [-63.3435151, 46.4116787],
              [-63.3431573, 46.4122308],
              [-63.3429212, 46.4125673],
              [-63.3422307, 46.4137344],
              [-63.3418615, 46.413992],
              [-63.3416802, 46.4147621],
              [-63.3402024, 46.4156637],
              [-63.3394624, 46.4159226],
              [-63.3383492, 46.415798],
              [-63.3379846, 46.416825],
              [-63.3372429, 46.4168272],
              [-63.3368845, 46.4188801],
              [-63.3350302, 46.4188856],
              [-63.3350273, 46.4183727],
              [-63.3346554, 46.4182449],
              [-63.333172, 46.4182492],
              [-63.3324335, 46.4187645],
              [-63.3313217, 46.4188964],
              [-63.3307721, 46.419924],
              [-63.3307768, 46.4206935],
              [-63.3308211, 46.4210565],
              [-63.3319019, 46.422742],
              [-63.3322745, 46.4229976],
              [-63.332287, 46.4250494],
              [-63.3311804, 46.4260787],
              [-63.3310082, 46.4283875],
              [-63.330638, 46.4285163],
              [-63.3284124, 46.4285227],
              [-63.3269273, 46.4282705],
              [-63.3250733, 46.4284044],
              [-63.3250703, 46.4278914],
              [-63.3239561, 46.4276383],
              [-63.3237652, 46.4266127],
              [-63.3230187, 46.4258453],
              [-63.322828, 46.4250766],
              [-63.3213466, 46.425465],
              [-63.3206078, 46.4259799],
              [-63.3202347, 46.4265367],
              [-63.3194906, 46.4268585],
              [-63.3189476, 46.4273957],
              [-63.3185765, 46.4280527],
              [-63.3180827, 46.4315701],
              [-63.3174818, 46.431745],
              [-63.3161882, 46.4313786],
              [-63.3158155, 46.4311232],
              [-63.3154445, 46.4311241],
              [-63.3150753, 46.4313816],
              [-63.3147043, 46.4313828],
              [-63.3143318, 46.4311271],
              [-63.3113599, 46.4303658],
              [-63.3109873, 46.4301104],
              [-63.3091298, 46.4296025],
              [-63.3061622, 46.4296104],
              [-63.3057922, 46.4297402],
              [-63.3057891, 46.4292271],
              [-63.3054183, 46.4292281],
              [-63.3048668, 46.429999],
              [-63.3043241, 46.4325655],
              [-63.3035821, 46.4325674],
              [-63.3037881, 46.4361577],
              [-63.3034187, 46.4364152],
              [-63.3036093, 46.4374408],
              [-63.3028672, 46.4374427],
              [-63.3028643, 46.4369296],
              [-63.3013839, 46.4375743],
              [-63.3006426, 46.437705],
              [-63.2993504, 46.4387342],
              [-63.2991671, 46.4392477],
              [-63.2984252, 46.4392497],
              [-63.2985494, 46.4408884],
              [-63.2992112, 46.4430422],
              [-63.2996076, 46.4436085],
              [-63.2992766, 46.4447114],
              [-63.2987242, 46.4450474],
              [-63.2963476, 46.4451131],
              [-63.2954882, 46.4450279],
              [-63.2939962, 46.4451409],
              [-63.2934138, 46.4453828],
              [-63.2920464, 46.4459572],
              [-63.2899273, 46.4459402],
              [-63.2899412, 46.4468776],
              [-63.2877071, 46.4470997],
              [-63.2858517, 46.4471042],
              [-63.2854802, 46.4469775],
              [-63.2858234, 46.447667],
              [-63.2863945, 46.4480851],
              [-63.2870307, 46.4482991],
              [-63.2877302, 46.4484104],
              [-63.2884102, 46.4487158],
              [-63.2906807, 46.4507795],
              [-63.2911205, 46.45092],
              [-63.2916784, 46.4509052],
              [-63.2924509, 46.4510161],
              [-63.2928908, 46.4510235],
              [-63.293277, 46.4512156],
              [-63.2935589, 46.4517119],
              [-63.2934961, 46.4523602],
              [-63.2932263, 46.4529003],
              [-63.2928212, 46.453101],
              [-63.2915052, 46.45338],
              [-63.290982, 46.4531016],
              [-63.2894503, 46.4528605],
              [-63.2887067, 46.4524581],
              [-63.2874476, 46.4518805],
              [-63.2867368, 46.4516855],
              [-63.2861384, 46.4514515],
              [-63.2852806, 46.4509565],
              [-63.2845169, 46.4505075],
              [-63.2837438, 46.4500846],
              [-63.2828442, 46.449584],
              [-63.2824807, 46.449175],
              [-63.2811476, 46.4485289],
              [-63.2803314, 46.4480675],
              [-63.2795672, 46.4474978],
              [-63.2789348, 46.4473355],
              [-63.278286, 46.446994],
              [-63.2777041, 46.4465549],
              [-63.2773128, 46.446228],
              [-63.2762802, 46.4458631],
              [-63.2754384, 46.4454623],
              [-63.2747847, 46.4450632],
              [-63.2741713, 46.4446511],
              [-63.2738273, 46.4440213],
              [-63.2735156, 46.4432032],
              [-63.273025, 46.4410029],
              [-63.2733582, 46.44009],
              [-63.2731674, 46.4392353],
              [-63.2733828, 46.4382517],
              [-63.2739712, 46.4388014],
              [-63.274817, 46.4392208],
              [-63.2757566, 46.4404694],
              [-63.2758282, 46.4406877],
              [-63.2758098, 46.4407585],
              [-63.2758378, 46.441205],
              [-63.2757322, 46.4416814],
              [-63.2757824, 46.4419538],
              [-63.2760482, 46.4422787],
              [-63.2766055, 46.4425221],
              [-63.2773182, 46.4430984],
              [-63.2798917, 46.4445902],
              [-63.2803367, 46.4449398],
              [-63.2808914, 46.4449725],
              [-63.2811482, 46.4451977],
              [-63.2813356, 46.4452544],
              [-63.2815554, 46.4452916],
              [-63.2819975, 46.4453442],
              [-63.2817754, 46.4452495],
              [-63.2816053, 46.445234],
              [-63.2810999, 46.4450055],
              [-63.2808426, 46.4445842],
              [-63.2808822, 46.4443233],
              [-63.2806573, 46.4435165],
              [-63.2803308, 46.4427137],
              [-63.2804058, 46.4425424],
              [-63.2803766, 46.442371],
              [-63.2804127, 46.4422341],
              [-63.2803219, 46.4422269],
              [-63.2799079, 46.4415475],
              [-63.2788573, 46.4409173],
              [-63.277634, 46.4404239],
              [-63.2760046, 46.4396816],
              [-63.2753229, 46.4390964],
              [-63.2750438, 46.4386279],
              [-63.2750402, 46.4384495],
              [-63.2749614, 46.4381681],
              [-63.2750496, 46.4375438],
              [-63.2750539, 46.4372979],
              [-63.2746846, 46.4366515],
              [-63.2746597, 46.4364261],
              [-63.274829, 46.4363569],
              [-63.2752709, 46.4365538],
              [-63.2754777, 46.4364894],
              [-63.2755464, 46.4362684],
              [-63.2754434, 46.4361785],
              [-63.2755921, 46.4359726],
              [-63.2761466, 46.4357148],
              [-63.2763664, 46.435754],
              [-63.2761951, 46.4360827],
              [-63.2760682, 46.4363652],
              [-63.2764585, 46.4364993],
              [-63.2766705, 46.4366994],
              [-63.2770121, 46.4368144],
              [-63.2776804, 46.4368627],
              [-63.2779616, 46.437319],
              [-63.2779482, 46.4374895],
              [-63.2779941, 46.4376274],
              [-63.2783865, 46.4376869],
              [-63.2786745, 46.4377678],
              [-63.2789399, 46.4377599],
              [-63.279436, 46.4378051],
              [-63.2795253, 46.4377409],
              [-63.2792868, 46.4374521],
              [-63.279317, 46.4372938],
              [-63.2794476, 46.4372632],
              [-63.2795781, 46.437302],
              [-63.2798663, 46.437629],
              [-63.2800631, 46.4377172],
              [-63.280278, 46.4376804],
              [-63.2808368, 46.4377086],
              [-63.2817933, 46.4379585],
              [-63.2829585, 46.438084],
              [-63.2838248, 46.4383129],
              [-63.2842086, 46.4383981],
              [-63.2840575, 46.4382518],
              [-63.2841454, 46.4382025],
              [-63.2839311, 46.4380917],
              [-63.2835276, 46.4379965],
              [-63.283456, 46.4379155],
              [-63.2825243, 46.4376597],
              [-63.2816169, 46.4373916],
              [-63.2806449, 46.4369657],
              [-63.2801533, 46.4365926],
              [-63.2796593, 46.4360518],
              [-63.2794282, 46.4359312],
              [-63.2792381, 46.435772],
              [-63.2790777, 46.4357929],
              [-63.2786961, 46.4356771],
              [-63.2785485, 46.4355552],
              [-63.2782919, 46.4355258],
              [-63.2781087, 46.4354418],
              [-63.2778503, 46.4352851],
              [-63.2777095, 46.4352642],
              [-63.2776423, 46.4352846],
              [-63.2773869, 46.4351622],
              [-63.2773492, 46.4350012],
              [-63.2772557, 46.4349428],
              [-63.2771938, 46.4348797],
              [-63.277208, 46.434773],
              [-63.2771169, 46.434674],
              [-63.2762869, 46.4344571],
              [-63.2762453, 46.4343074],
              [-63.2762649, 46.4341225],
              [-63.276153, 46.4338558],
              [-63.2756653, 46.4334509],
              [-63.274942, 46.432934],
              [-63.2748851, 46.4327361],
              [-63.2749168, 46.432656],
              [-63.274317, 46.4322373],
              [-63.2740621, 46.4322095],
              [-63.2739629, 46.432109],
              [-63.2739942, 46.4319919],
              [-63.2733494, 46.4315076],
              [-63.2729148, 46.4312874],
              [-63.2726868, 46.4312621],
              [-63.2725092, 46.4309891],
              [-63.271486, 46.4304524],
              [-63.2705578, 46.4300136],
              [-63.2698957, 46.4294221],
              [-63.2696759, 46.4290572],
              [-63.2694254, 46.4283548],
              [-63.2693352, 46.4282852],
              [-63.269371, 46.4282334],
              [-63.2692917, 46.4281397],
              [-63.2693437, 46.4280423],
              [-63.2693088, 46.4279553],
              [-63.2693542, 46.4278785],
              [-63.269343, 46.4278173],
              [-63.269439, 46.4277386],
              [-63.2694282, 46.4276212],
              [-63.2693757, 46.4275854],
              [-63.2695135, 46.427441],
              [-63.2694993, 46.427235],
              [-63.2694222, 46.426916],
              [-63.2691917, 46.4266133],
              [-63.2691609, 46.4264784],
              [-63.2692696, 46.4263251],
              [-63.269357, 46.4262919],
              [-63.269279, 46.4262053],
              [-63.2690603, 46.4259358],
              [-63.2690011, 46.4257937],
              [-63.2691291, 46.4256294],
              [-63.2688309, 46.4254489],
              [-63.2686354, 46.425267],
              [-63.2685969, 46.4250488],
              [-63.2686937, 46.4250372],
              [-63.2688601, 46.4249596],
              [-63.2690704, 46.4249075],
              [-63.2692523, 46.42483],
              [-63.269392, 46.4246845],
              [-63.2694547, 46.4246285],
              [-63.2695701, 46.4246658],
              [-63.2697861, 46.4247472],
              [-63.2707906, 46.4253779],
              [-63.2713216, 46.4255363],
              [-63.2716985, 46.4255071],
              [-63.2724521, 46.4253535],
              [-63.272876, 46.4252049],
              [-63.2735585, 46.4252484],
              [-63.2749347, 46.4252082],
              [-63.275295, 46.4251111],
              [-63.2756951, 46.425102],
              [-63.2760372, 46.4251689],
              [-63.2767991, 46.42515],
              [-63.2772056, 46.4250675],
              [-63.2775069, 46.4248983],
              [-63.277832, 46.4246806],
              [-63.2779176, 46.4246093],
              [-63.2780055, 46.4245542],
              [-63.2780896, 46.4244352],
              [-63.278451, 46.4241456],
              [-63.2788163, 46.4238163],
              [-63.2790528, 46.4236178],
              [-63.2795624, 46.423095],
              [-63.2796691, 46.4226921],
              [-63.2796266, 46.4223661],
              [-63.2796874, 46.4219308],
              [-63.2796235, 46.4216882],
              [-63.2796935, 46.4213389],
              [-63.2797883, 46.4210826],
              [-63.2799702, 46.4209251],
              [-63.2801614, 46.4207459],
              [-63.2807233, 46.4204429],
              [-63.2813555, 46.4202047],
              [-63.2818757, 46.4200262],
              [-63.2822562, 46.4196699],
              [-63.2830069, 46.4195242],
              [-63.2831532, 46.4196127],
              [-63.2832919, 46.4196209],
              [-63.2834994, 46.4195696],
              [-63.284254, 46.4194295],
              [-63.2847805, 46.4192203],
              [-63.2850111, 46.4190355],
              [-63.2851944, 46.4188619],
              [-63.2855325, 46.4188122],
              [-63.2860718, 46.4185415],
              [-63.2864536, 46.418404],
              [-63.2866541, 46.4181711],
              [-63.2870227, 46.4181851],
              [-63.2870788, 46.4180441],
              [-63.2869447, 46.4180066],
              [-63.2869716, 46.4178788],
              [-63.2871253, 46.4178081],
              [-63.2872247, 46.4177859],
              [-63.2875286, 46.4177181],
              [-63.2869482, 46.4177559],
              [-63.2872347, 46.4176092],
              [-63.2875206, 46.4175593],
              [-63.2876665, 46.4173573],
              [-63.2871482, 46.4174504],
              [-63.2865871, 46.4175855],
              [-63.2861883, 46.4178491],
              [-63.284685, 46.4184516],
              [-63.2842408, 46.4184236],
              [-63.284027, 46.4184867],
              [-63.2832486, 46.4183239],
              [-63.2828515, 46.418342],
              [-63.2825569, 46.4182888],
              [-63.2820955, 46.4179775],
              [-63.2816568, 46.4179673],
              [-63.2808792, 46.4181333],
              [-63.2802757, 46.4185627],
              [-63.2798215, 46.4187351],
              [-63.2792048, 46.4191991],
              [-63.2789762, 46.4192996],
              [-63.2787267, 46.4196081],
              [-63.2782275, 46.4197862],
              [-63.2778204, 46.4201462],
              [-63.2776012, 46.4202424],
              [-63.2771987, 46.4207489],
              [-63.2768158, 46.4213496],
              [-63.2751651, 46.4209433],
              [-63.2750663, 46.4203765],
              [-63.2753308, 46.4201908],
              [-63.2750997, 46.4199952],
              [-63.2747936, 46.4199873],
              [-63.2745164, 46.4201504],
              [-63.2742074, 46.4202684],
              [-63.2745077, 46.4211219],
              [-63.2742057, 46.4213643],
              [-63.2739601, 46.4217869],
              [-63.2734325, 46.4216775],
              [-63.2727392, 46.4220004],
              [-63.2720434, 46.422269],
              [-63.2708886, 46.4227742],
              [-63.2698843, 46.4231401],
              [-63.2695854, 46.4232015],
              [-63.2683615, 46.4230901],
              [-63.2671537, 46.422988],
              [-63.2668093, 46.4229124],
              [-63.2660689, 46.4228277],
              [-63.2653227, 46.4226961],
              [-63.2647692, 46.4226098],
              [-63.2639323, 46.4223046],
              [-63.2636404, 46.4222958],
              [-63.2634997, 46.4221987],
              [-63.2628113, 46.422039],
              [-63.2612594, 46.4219655],
              [-63.25995, 46.421992],
              [-63.2593279, 46.4220246],
              [-63.2586831, 46.4219974],
              [-63.2574695, 46.4221139],
              [-63.2568729, 46.4222386],
              [-63.2565208, 46.4225154],
              [-63.2562357, 46.4226659],
              [-63.2561195, 46.4227036],
              [-63.2559456, 46.4226383],
              [-63.2554949, 46.4227342],
              [-63.2554332, 46.4228159],
              [-63.254522, 46.4231927],
              [-63.2541262, 46.4232861],
              [-63.2538473, 46.4233081],
              [-63.2535601, 46.423311],
              [-63.2534392, 46.4232595],
              [-63.2517972, 46.4230669],
              [-63.2515419, 46.423052],
              [-63.2512318, 46.4229479],
              [-63.2509071, 46.4228842],
              [-63.2505083, 46.4227288],
              [-63.2501114, 46.4226083],
              [-63.2498027, 46.4225768],
              [-63.2492681, 46.4227049],
              [-63.2487692, 46.4225418],
              [-63.2484689, 46.4224626],
              [-63.248061, 46.4225001],
              [-63.2478437, 46.4224792],
              [-63.2475547, 46.4224971],
              [-63.2473225, 46.422398],
              [-63.2471166, 46.4223702],
              [-63.2468409, 46.4223776],
              [-63.2463251, 46.4224474],
              [-63.2460135, 46.4224245],
              [-63.2439188, 46.4224378],
              [-63.2435939, 46.4224765],
              [-63.2424607, 46.4223403],
              [-63.2413373, 46.4221004],
              [-63.2408424, 46.421944],
              [-63.2401395, 46.4216648],
              [-63.2394424, 46.4211601],
              [-63.2388532, 46.4206543],
              [-63.2386634, 46.4202646],
              [-63.2386817, 46.419907],
              [-63.2388506, 46.4195201],
              [-63.2389765, 46.4193271],
              [-63.239358, 46.4186977],
              [-63.2397776, 46.4181225],
              [-63.2398678, 46.4177715],
              [-63.2401938, 46.4173514],
              [-63.2401593, 46.4171841],
              [-63.2403502, 46.4171202],
              [-63.2405686, 46.4167533],
              [-63.2405576, 46.4166314],
              [-63.2408974, 46.4164915],
              [-63.2412331, 46.416212],
              [-63.2422957, 46.4151436],
              [-63.242884, 46.4148531],
              [-63.2433615, 46.4142143],
              [-63.2441436, 46.4137294],
              [-63.2445788, 46.4133152],
              [-63.2448626, 46.4128872],
              [-63.2451255, 46.4129143],
              [-63.2450939, 46.4127899],
              [-63.245205, 46.412641],
              [-63.2454128, 46.4125917],
              [-63.2455682, 46.4123389],
              [-63.2456573, 46.4121945],
              [-63.2455369, 46.4120152],
              [-63.2455693, 46.4118578],
              [-63.2455064, 46.4117005],
              [-63.2457217, 46.4111025],
              [-63.2462938, 46.410178],
              [-63.2467209, 46.4096201],
              [-63.2473354, 46.4090264],
              [-63.2477389, 46.4084197],
              [-63.2477123, 46.4083489],
              [-63.248421, 46.4076127],
              [-63.2488099, 46.4073057],
              [-63.2493048, 46.4070496],
              [-63.2496081, 46.4068373],
              [-63.2496782, 46.4063983],
              [-63.2500416, 46.406022],
              [-63.2506271, 46.4055002],
              [-63.2511511, 46.4053778],
              [-63.251522, 46.405377],
              [-63.2517073, 46.4054387],
              [-63.2519236, 46.4053978],
              [-63.2519275, 46.4052904],
              [-63.2520851, 46.4052397],
              [-63.2521432, 46.4053461],
              [-63.2523456, 46.4052595],
              [-63.2523401, 46.4052004],
              [-63.2528029, 46.4050055],
              [-63.2531831, 46.404676],
              [-63.2535162, 46.4041186],
              [-63.2538971, 46.4033707],
              [-63.2540392, 46.4028435],
              [-63.2538913, 46.4025487],
              [-63.253659, 46.402368],
              [-63.252779, 46.4021013],
              [-63.2524184, 46.4020297],
              [-63.2503683, 46.4015543],
              [-63.2488277, 46.4014025],
              [-63.2474048, 46.4016854],
              [-63.2465941, 46.4015812],
              [-63.2452032, 46.4019285],
              [-63.2439272, 46.4027265],
              [-63.2439108, 46.4029572],
              [-63.2436762, 46.4032714],
              [-63.2429856, 46.4034722],
              [-63.242431, 46.4037259],
              [-63.2400222, 46.4041192],
              [-63.2394816, 46.4044462],
              [-63.2385437, 46.4051483],
              [-63.2378375, 46.4056707],
              [-63.2375773, 46.406035],
              [-63.2372549, 46.4064855],
              [-63.2366981, 46.4070763],
              [-63.2313111, 46.4074354],
              [-63.2296557, 46.4077314],
              [-63.2287184, 46.4079122],
              [-63.2284211, 46.407801],
              [-63.2282182, 46.4074562],
              [-63.2282477, 46.4072013],
              [-63.2283562, 46.4067014],
              [-63.2281358, 46.4064015],
              [-63.2273176, 46.4058955],
              [-63.2271913, 46.4056898],
              [-63.2270233, 46.4050259],
              [-63.2268934, 46.4044272],
              [-63.2270404, 46.4038862],
              [-63.2274134, 46.4035837],
              [-63.2277455, 46.4033197],
              [-63.2278586, 46.4033347],
              [-63.2278854, 46.4034418],
              [-63.2278395, 46.4038034],
              [-63.2279853, 46.4038384],
              [-63.2283108, 46.4036828],
              [-63.2298197, 46.4024735],
              [-63.2303736, 46.4020872],
              [-63.2311145, 46.4019579],
              [-63.231566, 46.4015255],
              [-63.2314705, 46.400644],
              [-63.2301753, 46.3988819],
              [-63.2301709, 46.3978559],
              [-63.2303552, 46.3977269],
              [-63.2318365, 46.3973396],
              [-63.232706, 46.3964876],
              [-63.2321136, 46.3962657],
              [-63.2313638, 46.3969029],
              [-63.2299818, 46.3970867],
              [-63.229061, 46.3983713],
              [-63.2290621, 46.3986276],
              [-63.2294338, 46.3988833],
              [-63.2294372, 46.3996529],
              [-63.2301809, 46.4001643],
              [-63.230367, 46.4011198],
              [-63.2301494, 46.4014555],
              [-63.2285176, 46.4015779],
              [-63.2276869, 46.4011596],
              [-63.2262139, 46.4007967],
              [-63.226644, 46.401339],
              [-63.2267805, 46.401773],
              [-63.2264872, 46.4035062],
              [-63.2261184, 46.4040198],
              [-63.225988, 46.4049454],
              [-63.2256504, 46.4052762],
              [-63.2247653, 46.4058363],
              [-63.2240292, 46.4059824],
              [-63.2230303, 46.4061337],
              [-63.2223048, 46.4067959],
              [-63.2214968, 46.4073633],
              [-63.2206812, 46.4076928],
              [-63.2203183, 46.4080154],
              [-63.2196489, 46.4089056],
              [-63.2189089, 46.4092914],
              [-63.2152049, 46.4103246],
              [-63.2148352, 46.4105816],
              [-63.2133528, 46.4108408],
              [-63.2129829, 46.4110981],
              [-63.212241, 46.4109191],
              [-63.2118662, 46.4105577],
              [-63.2111619, 46.4095215],
              [-63.2110746, 46.4092757],
              [-63.2103552, 46.4085411],
              [-63.2097621, 46.4084817],
              [-63.2095636, 46.4081792],
              [-63.2093833, 46.4084853],
              [-63.2091418, 46.4085334],
              [-63.2095849, 46.4087158],
              [-63.2097453, 46.4089719],
              [-63.20997, 46.4091421],
              [-63.2101952, 46.4096158],
              [-63.2100354, 46.4099621],
              [-63.2099257, 46.410038],
              [-63.209698, 46.4106407],
              [-63.2095759, 46.4112158],
              [-63.209696, 46.4112703],
              [-63.2096882, 46.4120608],
              [-63.2098652, 46.4123264],
              [-63.2101837, 46.4124209],
              [-63.2104762, 46.4125946],
              [-63.2108905, 46.4127938],
              [-63.2113132, 46.4129353],
              [-63.2115696, 46.412956],
              [-63.2116288, 46.4129838],
              [-63.2116681, 46.4129301],
              [-63.2119922, 46.4128447],
              [-63.212091, 46.4129616],
              [-63.2121211, 46.4128191],
              [-63.2119884, 46.4127401],
              [-63.2118648, 46.4125596],
              [-63.211637, 46.4124328],
              [-63.2111499, 46.4124149],
              [-63.2111397, 46.4123652],
              [-63.211674, 46.4122579],
              [-63.2116688, 46.4122031],
              [-63.211236, 46.4120171],
              [-63.2112817, 46.4119938],
              [-63.211591, 46.4119838],
              [-63.2116921, 46.4120732],
              [-63.2118622, 46.4121124],
              [-63.2121172, 46.4123972],
              [-63.2122912, 46.4123786],
              [-63.2124642, 46.4124349],
              [-63.2126985, 46.4125564],
              [-63.2127932, 46.4125382],
              [-63.2129669, 46.4125983],
              [-63.2132797, 46.4128477],
              [-63.213354, 46.4128457],
              [-63.2135224, 46.4129534],
              [-63.2136164, 46.4132083],
              [-63.2136931, 46.4132485],
              [-63.2137046, 46.4133298],
              [-63.2140608, 46.4134953],
              [-63.2140977, 46.413684],
              [-63.2140205, 46.4137105],
              [-63.2140244, 46.413786],
              [-63.2139637, 46.4138321],
              [-63.2140268, 46.4138734],
              [-63.2141064, 46.4139021],
              [-63.214118, 46.4139855],
              [-63.2140375, 46.4140796],
              [-63.2142015, 46.4142966],
              [-63.2142626, 46.414269],
              [-63.2143358, 46.4143033],
              [-63.2144129, 46.4144305],
              [-63.214528, 46.4144136],
              [-63.2146134, 46.4144664],
              [-63.2145695, 46.4145765],
              [-63.2146627, 46.4147084],
              [-63.2146695, 46.4148161],
              [-63.214739, 46.4149417],
              [-63.2147304, 46.4150005],
              [-63.214735, 46.4150548],
              [-63.2146974, 46.4150775],
              [-63.2146991, 46.4151092],
              [-63.2146306, 46.4151022],
              [-63.2146212, 46.4149675],
              [-63.2145126, 46.4148706],
              [-63.2143135, 46.4147706],
              [-63.2143518, 46.41485],
              [-63.2143422, 46.4149525],
              [-63.2145222, 46.4150981],
              [-63.2145054, 46.4151309],
              [-63.2145521, 46.4152693],
              [-63.2145099, 46.4153732],
              [-63.2143936, 46.4154596],
              [-63.2143676, 46.415497],
              [-63.2143962, 46.4155483],
              [-63.2143725, 46.4155728],
              [-63.2143912, 46.4156147],
              [-63.2144848, 46.415673],
              [-63.2144836, 46.4157152],
              [-63.2145601, 46.4158071],
              [-63.2145818, 46.4158459],
              [-63.2145087, 46.4159103],
              [-63.2145089, 46.4160304],
              [-63.2143929, 46.416197],
              [-63.214426, 46.4163889],
              [-63.214557, 46.4165004],
              [-63.2145839, 46.4167561],
              [-63.2145542, 46.4168611],
              [-63.2145612, 46.4170434],
              [-63.2145673, 46.4174484],
              [-63.214227, 46.4180701],
              [-63.2140577, 46.4185244],
              [-63.2139107, 46.4187202],
              [-63.2140987, 46.4200081],
              [-63.2137252, 46.4213958],
              [-63.2134105, 46.4220796],
              [-63.2133808, 46.4224755],
              [-63.2131098, 46.4227449],
              [-63.2126502, 46.4230705],
              [-63.2123262, 46.423684],
              [-63.2119156, 46.4241241],
              [-63.2114256, 46.4248201],
              [-63.2111786, 46.4254014],
              [-63.2111281, 46.4257498],
              [-63.2112873, 46.4262609],
              [-63.2116069, 46.4267175],
              [-63.2123371, 46.4272159],
              [-63.2125008, 46.42726],
              [-63.2125717, 46.4273467],
              [-63.2126314, 46.4274336],
              [-63.2127684, 46.4274564],
              [-63.213199, 46.4275616],
              [-63.2133267, 46.4278166],
              [-63.2137171, 46.4278461],
              [-63.2141079, 46.42822],
              [-63.2147157, 46.4286273],
              [-63.2162483, 46.4291926],
              [-63.2168291, 46.4292204],
              [-63.2177524, 46.4290117],
              [-63.2179684, 46.4289517],
              [-63.2181858, 46.4290429],
              [-63.2205715, 46.4297314],
              [-63.2214032, 46.4298101],
              [-63.2249943, 46.4298306],
              [-63.2269396, 46.4298417],
              [-63.227438, 46.4298646],
              [-63.2280958, 46.4299505],
              [-63.2287535, 46.4300875],
              [-63.2292269, 46.4302633],
              [-63.2296844, 46.4304537],
              [-63.2303553, 46.4307967],
              [-63.2308699, 46.4311065],
              [-63.2320419, 46.4318755],
              [-63.2331907, 46.4328048],
              [-63.235276, 46.4341206],
              [-63.2357011, 46.4338812],
              [-63.2365559, 46.4335868],
              [-63.2368024, 46.4334877],
              [-63.237012, 46.4335146],
              [-63.2371844, 46.4337503],
              [-63.2372542, 46.4340953],
              [-63.2370217, 46.4344025],
              [-63.2371889, 46.4347761],
              [-63.2374434, 46.4350322],
              [-63.2379327, 46.4351592],
              [-63.2388766, 46.4350238],
              [-63.2405301, 46.4352823],
              [-63.240875, 46.4354477],
              [-63.2416446, 46.4356644],
              [-63.2432061, 46.4349787],
              [-63.2438647, 46.434377],
              [-63.2447345, 46.4340904],
              [-63.2453464, 46.4338609],
              [-63.2481271, 46.4331538],
              [-63.2490513, 46.432827],
              [-63.2501708, 46.4326865],
              [-63.2530028, 46.4329795],
              [-63.2541589, 46.4333136],
              [-63.2557838, 46.4345481],
              [-63.2562025, 46.4350294],
              [-63.2564918, 46.435638],
              [-63.2567215, 46.4358045],
              [-63.2566719, 46.4360166],
              [-63.2568939, 46.4370293],
              [-63.2569169, 46.4373876],
              [-63.2571589, 46.4381857],
              [-63.2574515, 46.4391906],
              [-63.2587991, 46.439154],
              [-63.2591653, 46.4388338],
              [-63.2583491, 46.43785],
              [-63.2583554, 46.4372846],
              [-63.2597173, 46.4380852],
              [-63.2609509, 46.4388283],
              [-63.2611395, 46.4393409],
              [-63.2622563, 46.4401078],
              [-63.2626252, 46.4403147],
              [-63.2636427, 46.440383],
              [-63.2657388, 46.4401625],
              [-63.2681294, 46.4401529],
              [-63.2692185, 46.4396318],
              [-63.2696586, 46.4397325],
              [-63.2702474, 46.4404439],
              [-63.2690509, 46.441077],
              [-63.267872, 46.4413257],
              [-63.2666652, 46.4412075],
              [-63.2647109, 46.440865],
              [-63.2622497, 46.4405394],
              [-63.2602845, 46.440474],
              [-63.259349, 46.4406142],
              [-63.2584785, 46.4407736],
              [-63.2576808, 46.4409595],
              [-63.2561378, 46.4415824],
              [-63.2549202, 46.4412394],
              [-63.2541324, 46.4408122],
              [-63.253171, 46.4404925],
              [-63.2520975, 46.4402776],
              [-63.2511587, 46.4399319],
              [-63.2502123, 46.4397938],
              [-63.2487693, 46.4395256],
              [-63.2476173, 46.4394899],
              [-63.2464923, 46.439426],
              [-63.2453832, 46.4389519],
              [-63.2442, 46.4384705],
              [-63.24314, 46.4381272],
              [-63.2419828, 46.4377938],
              [-63.2403623, 46.4373305],
              [-63.2388461, 46.4370574],
              [-63.2379091, 46.4368274],
              [-63.2369762, 46.4369002],
              [-63.235843, 46.4370944],
              [-63.235556, 46.4372328],
              [-63.2348817, 46.4372031],
              [-63.2344639, 46.4370713],
              [-63.2339734, 46.436738],
              [-63.2334936, 46.4360875],
              [-63.2322098, 46.4352164],
              [-63.2305372, 46.434482],
              [-63.2284387, 46.4338557],
              [-63.2271909, 46.4335536],
              [-63.2234417, 46.4329681],
              [-63.2185266, 46.432255],
              [-63.2144933, 46.4318475],
              [-63.2075821, 46.4311733],
              [-63.2029432, 46.430665],
              [-63.2007277, 46.4305559],
              [-63.1993546, 46.4302497],
              [-63.1907985, 46.429723],
              [-63.187065, 46.4295851],
              [-63.1837251, 46.4296524],
              [-63.1803554, 46.4297048],
              [-63.1750875, 46.4294942],
              [-63.1691688, 46.4297167],
              [-63.1662274, 46.4297662],
              [-63.1633095, 46.4299281],
              [-63.1602246, 46.4299604],
              [-63.1574176, 46.4302606],
              [-63.156574, 46.4304739],
              [-63.1550413, 46.4306226],
              [-63.1543704, 46.4306804],
              [-63.1515431, 46.4308762],
              [-63.1502813, 46.4306924],
              [-63.1495822, 46.4306094],
              [-63.1488665, 46.4304309],
              [-63.1484559, 46.4302617],
              [-63.1480536, 46.4301013],
              [-63.1473658, 46.4297503],
              [-63.1454812, 46.4297186],
              [-63.1449395, 46.4299117],
              [-63.1444494, 46.4301469],
              [-63.1438617, 46.4304561],
              [-63.1433134, 46.4307004],
              [-63.1427807, 46.4308529],
              [-63.1422227, 46.4309133],
              [-63.1417078, 46.4309171],
              [-63.1410292, 46.4309046],
              [-63.1398507, 46.4308795],
              [-63.1386994, 46.4308256],
              [-63.1370143, 46.4307076],
              [-63.1356771, 46.4304692],
              [-63.1351221, 46.4303395],
              [-63.1325609, 46.429602],
              [-63.1293478, 46.4284117],
              [-63.1285314, 46.4280261],
              [-63.1274583, 46.4277127],
              [-63.1269362, 46.4275147],
              [-63.1257255, 46.4271988],
              [-63.1251771, 46.426953],
              [-63.1200843, 46.425416],
              [-63.1151805, 46.4245113],
              [-63.106962, 46.4226007],
              [-63.1042552, 46.4220256],
              [-63.1021447, 46.4215005],
              [-63.0997859, 46.4209742],
              [-63.0952074, 46.4200266],
              [-63.0902579, 46.4191062],
              [-63.0891983, 46.4187337],
              [-63.0867288, 46.4188335],
              [-63.0858213, 46.4188198],
              [-63.0841815, 46.4185585],
              [-63.0822703, 46.418215],
              [-63.0812673, 46.4180398],
              [-63.0809525, 46.4179573],
              [-63.0801385, 46.4178157],
              [-63.0792779, 46.4176908],
              [-63.0783121, 46.4175631],
              [-63.0777834, 46.4175749],
              [-63.0773868, 46.4176362],
              [-63.0763451, 46.4175659],
              [-63.0728461, 46.4170663],
              [-63.0706607, 46.4166292],
              [-63.0688606, 46.4163966],
              [-63.0665729, 46.4161842],
              [-63.0647045, 46.41595],
              [-63.0626889, 46.4156377],
              [-63.0617264, 46.4155284],
              [-63.0607125, 46.4153465],
              [-63.0604129, 46.4150444],
              [-63.0603822, 46.4148477],
              [-63.0608731, 46.4146434],
              [-63.0617717, 46.4144198],
              [-63.0608545, 46.4143651],
              [-63.0604695, 46.4143885],
              [-63.059858, 46.414209],
              [-63.0593371, 46.4143807],
              [-63.0589974, 46.4146072],
              [-63.0585831, 46.4149785],
              [-63.0577451, 46.4150957],
              [-63.0551408, 46.4154317],
              [-63.0538997, 46.4153323],
              [-63.0498722, 46.415106],
              [-63.0486974, 46.4151013],
              [-63.0477435, 46.4150638],
              [-63.0467816, 46.4149183],
              [-63.0457594, 46.4146043],
              [-63.0439471, 46.4142503],
              [-63.0425379, 46.4135161],
              [-63.0417141, 46.4121766],
              [-63.0420196, 46.4112874],
              [-63.0413929, 46.4104557],
              [-63.0406252, 46.409888],
              [-63.0400893, 46.4095273],
              [-63.0394168, 46.409066],
              [-63.0386794, 46.4087455],
              [-63.0378593, 46.4084139],
              [-63.037113, 46.4079874],
              [-63.0368338, 46.4075258],
              [-63.0360938, 46.4068219],
              [-63.0357501, 46.406538],
              [-63.035467, 46.4063078],
              [-63.0350054, 46.4060057],
              [-63.0337255, 46.4051559],
              [-63.0332604, 46.4049283],
              [-63.0328173, 46.4048208],
              [-63.0324594, 46.4044563],
              [-63.0317799, 46.4041474],
              [-63.030635, 46.4038766],
              [-63.0304642, 46.4038948],
              [-63.03027, 46.4039482],
              [-63.0298222, 46.4041408],
              [-63.0292415, 46.4040726],
              [-63.0289318, 46.4039849],
              [-63.0288122, 46.4037735],
              [-63.0290078, 46.4034583],
              [-63.0295259, 46.403579],
              [-63.0296875, 46.403522],
              [-63.030083, 46.4029743],
              [-63.0304391, 46.4030994],
              [-63.030834, 46.4032339],
              [-63.031046, 46.4033551],
              [-63.0311824, 46.4035898],
              [-63.0313414, 46.40363],
              [-63.031489, 46.4034627],
              [-63.0328264, 46.4033314],
              [-63.0334997, 46.4029274],
              [-63.0336518, 46.4028837],
              [-63.0341405, 46.4028968],
              [-63.0348011, 46.4030262],
              [-63.0352271, 46.4030257],
              [-63.0352265, 46.4029341],
              [-63.0348195, 46.4025965],
              [-63.0347133, 46.4020281],
              [-63.0345351, 46.4019197],
              [-63.0338842, 46.4019261],
              [-63.0334947, 46.4017881],
              [-63.0326345, 46.4013189],
              [-63.0314841, 46.40079],
              [-63.0305553, 46.400518],
              [-63.030079, 46.4002861],
              [-63.0297543, 46.3999189],
              [-63.0291901, 46.3996129],
              [-63.0285256, 46.3994516],
              [-63.0276177, 46.3993196],
              [-63.0271319, 46.3991551],
              [-63.0260971, 46.3987209],
              [-63.0253025, 46.3985202],
              [-63.0241664, 46.3984536],
              [-63.0225339, 46.398513],
              [-63.0214536, 46.3983762],
              [-63.0159564, 46.3981635],
              [-63.0144382, 46.3979311],
              [-63.0135789, 46.3977564],
              [-63.0130007, 46.3975974],
              [-63.0103838, 46.3970483],
              [-63.0063003, 46.3956666],
              [-63.0051272, 46.3950372],
              [-63.0037885, 46.3944869],
              [-63.0034719, 46.3941836],
              [-63.0034252, 46.3938916],
              [-63.0035284, 46.3935957],
              [-63.003609, 46.3934943],
              [-63.0036334, 46.3929653],
              [-63.0035226, 46.3923847],
              [-63.0035374, 46.3919231],
              [-63.0034598, 46.3910607],
              [-63.0035405, 46.3907563],
              [-63.0041019, 46.3899949],
              [-63.0041538, 46.3896897],
              [-63.0044335, 46.3888211],
              [-63.0044021, 46.3885653],
              [-63.0040828, 46.388007],
              [-63.0040863, 46.3875411],
              [-63.0044178, 46.3867736],
              [-63.0048877, 46.386404],
              [-63.0049007, 46.3862769],
              [-63.0050568, 46.3862653],
              [-63.0058404, 46.3865535],
              [-63.0065142, 46.3866475],
              [-63.0071778, 46.3865142],
              [-63.0080917, 46.3864355],
              [-63.0090398, 46.3864614],
              [-63.0097824, 46.3863581],
              [-63.0107953, 46.3863578],
              [-63.0113626, 46.386198],
              [-63.0115187, 46.3861863],
              [-63.0124218, 46.3857887],
              [-63.0133576, 46.3850176],
              [-63.0136872, 46.3845844],
              [-63.0138528, 46.3845053],
              [-63.0146635, 46.3843162],
              [-63.0153716, 46.3843116],
              [-63.0156382, 46.3845764],
              [-63.0158807, 46.3847593],
              [-63.0162588, 46.3850047],
              [-63.0185743, 46.3857844],
              [-63.0191682, 46.3858682],
              [-63.0200796, 46.3862354],
              [-63.0207202, 46.3864081],
              [-63.0221619, 46.3868653],
              [-63.0221614, 46.3871799],
              [-63.022544, 46.3874372],
              [-63.0226511, 46.3875795],
              [-63.0225807, 46.3875884],
              [-63.021984, 46.3875694],
              [-63.0212493, 46.3875336],
              [-63.0211085, 46.3875816],
              [-63.0207565, 46.387803],
              [-63.0202207, 46.3880155],
              [-63.0195379, 46.3880806],
              [-63.0193254, 46.388274],
              [-63.0192128, 46.3886374],
              [-63.0192703, 46.3890452],
              [-63.0201436, 46.3896822],
              [-63.0202338, 46.3899196],
              [-63.0204834, 46.3900857],
              [-63.0205758, 46.3900802],
              [-63.0207673, 46.38995],
              [-63.0217623, 46.3894552],
              [-63.0222654, 46.3894131],
              [-63.022493, 46.3892559],
              [-63.0232547, 46.3892208],
              [-63.0233454, 46.3891436],
              [-63.0233662, 46.3888773],
              [-63.0234614, 46.3887206],
              [-63.0234574, 46.3882824],
              [-63.0231408, 46.3879791],
              [-63.023281, 46.3876364],
              [-63.0233683, 46.3870095],
              [-63.0233603, 46.3867424],
              [-63.0236155, 46.386606],
              [-63.0242234, 46.3865427],
              [-63.0251817, 46.3865926],
              [-63.0260836, 46.3864178],
              [-63.0271061, 46.3863499],
              [-63.0283241, 46.3861836],
              [-63.0290812, 46.3858217],
              [-63.0295876, 46.3857199],
              [-63.029649, 46.3857534],
              [-63.0292182, 46.386148],
              [-63.0290014, 46.386421],
              [-63.0289119, 46.3866814],
              [-63.0287141, 46.3868194],
              [-63.0282369, 46.3868105],
              [-63.0278399, 46.387218],
              [-63.0277537, 46.3872157],
              [-63.0277577, 46.3874507],
              [-63.0279465, 46.3876749],
              [-63.0284643, 46.3879836],
              [-63.0286908, 46.3880494],
              [-63.0288137, 46.3881165],
              [-63.0302718, 46.3881837],
              [-63.0313328, 46.3884553],
              [-63.0318663, 46.3886887],
              [-63.032077, 46.3888298],
              [-63.0326072, 46.3889198],
              [-63.0341643, 46.3882528],
              [-63.0349885, 46.3880281],
              [-63.0363119, 46.3879323],
              [-63.0366515, 46.3877264],
              [-63.0367703, 46.3875583],
              [-63.0371042, 46.3872487],
              [-63.0376451, 46.3872513],
              [-63.0394217, 46.3876933],
              [-63.0399058, 46.3879891],
              [-63.0400945, 46.3880101],
              [-63.0403254, 46.3877933],
              [-63.040212, 46.3874557],
              [-63.0403803, 46.3872253],
              [-63.04038, 46.386819],
              [-63.04011, 46.3864016],
              [-63.0398158, 46.3861069],
              [-63.0395988, 46.3859736],
              [-63.0391429, 46.3857901],
              [-63.0385897, 46.3858031],
              [-63.0377273, 46.3860946],
              [-63.036955, 46.3862172],
              [-63.0362899, 46.3866851],
              [-63.0357772, 46.3867948],
              [-63.0354319, 46.3866939],
              [-63.0351478, 46.3864234],
              [-63.0350908, 46.3863103],
              [-63.0344387, 46.3855241],
              [-63.0339258, 46.3852276],
              [-63.0334509, 46.3851789],
              [-63.0330525, 46.3849969],
              [-63.0325985, 46.3848851],
              [-63.0323799, 46.3848832],
              [-63.0319748, 46.3850236],
              [-63.0311113, 46.3849287],
              [-63.0305153, 46.3852909],
              [-63.0301909, 46.38533],
              [-63.0301469, 46.3852929],
              [-63.0304228, 46.3850933],
              [-63.0306255, 46.3845611],
              [-63.0309893, 46.3842324],
              [-63.0313493, 46.3840748],
              [-63.0315678, 46.3840767],
              [-63.0316709, 46.3839839],
              [-63.0334571, 46.3836378],
              [-63.0345872, 46.3836006],
              [-63.0349291, 46.383558],
              [-63.0371438, 46.3832956],
              [-63.0378805, 46.383076],
              [-63.0382818, 46.3827368],
              [-63.0385039, 46.382105],
              [-63.0384564, 46.3817214],
              [-63.0382215, 46.3812259],
              [-63.037896, 46.3809521],
              [-63.0376949, 46.3808522],
              [-63.0375152, 46.3808558],
              [-63.037037, 46.3808668],
              [-63.0367909, 46.3808873],
              [-63.0364262, 46.3803988],
              [-63.0358651, 46.3796344],
              [-63.0350904, 46.3789074],
              [-63.0343352, 46.3781358],
              [-63.0341341, 46.377742],
              [-63.0337931, 46.3771554],
              [-63.0334798, 46.3767925],
              [-63.0331057, 46.3764877],
              [-63.0330458, 46.3761197],
              [-63.0331465, 46.3758636],
              [-63.0332202, 46.3754753],
              [-63.0331328, 46.3752897],
              [-63.0331234, 46.3749999],
              [-63.0331139, 46.3747038],
              [-63.0332659, 46.374457],
              [-63.0332235, 46.3740855],
              [-63.0328533, 46.3736096],
              [-63.0327833, 46.3734205],
              [-63.0326848, 46.3734338],
              [-63.0321166, 46.3738167],
              [-63.0304541, 46.3739152],
              [-63.0286328, 46.3743798],
              [-63.0280116, 46.3746816],
              [-63.0275252, 46.375],
              [-63.0272171, 46.3752017],
              [-63.0271524, 46.3752279],
              [-63.0268567, 46.3752677],
              [-63.0265249, 46.3751313],
              [-63.0259746, 46.375196],
              [-63.025486, 46.375],
              [-63.0248047, 46.375],
              [-63.0236311, 46.3754313],
              [-63.0232664, 46.3756242],
              [-63.0232468, 46.375629],
              [-63.023234, 46.3756356],
              [-63.0232109, 46.3757386],
              [-63.0236892, 46.3763369],
              [-63.0241084, 46.3766589],
              [-63.0242756, 46.3766515],
              [-63.0246209, 46.3767524],
              [-63.0250755, 46.3767528],
              [-63.0263422, 46.3764326],
              [-63.0271222, 46.376374],
              [-63.0274123, 46.3764336],
              [-63.0278427, 46.3767598],
              [-63.0279853, 46.3769867],
              [-63.028298, 46.377258],
              [-63.0283251, 46.3773902],
              [-63.0282964, 46.3775925],
              [-63.0280419, 46.3778206],
              [-63.0277333, 46.3781906],
              [-63.0275335, 46.3787746],
              [-63.0272513, 46.378982],
              [-63.0271302, 46.3789867],
              [-63.0268153, 46.3787552],
              [-63.0266389, 46.3785155],
              [-63.0259043, 46.3780735],
              [-63.0256041, 46.3779897],
              [-63.0252633, 46.3780123],
              [-63.0248471, 46.3781484],
              [-63.0242641, 46.3781804],
              [-63.0235499, 46.3785992],
              [-63.0232565, 46.3785992],
              [-63.0229365, 46.3783556],
              [-63.0226278, 46.3779131],
              [-63.0220436, 46.3775588],
              [-63.0217079, 46.3773904],
              [-63.0209385, 46.3773616],
              [-63.0205031, 46.3774295],
              [-63.0196379, 46.3774658],
              [-63.0186651, 46.3772681],
              [-63.0186054, 46.3773063],
              [-63.0186353, 46.3774904],
              [-63.0185756, 46.3775286],
              [-63.017373, 46.3775278],
              [-63.0168267, 46.3776245],
              [-63.0162741, 46.3779321],
              [-63.0160212, 46.3782319],
              [-63.0160206, 46.3787496],
              [-63.0159114, 46.3788502],
              [-63.0155892, 46.3788494],
              [-63.0149476, 46.3784934],
              [-63.0133704, 46.3785024],
              [-63.0110097, 46.3784263],
              [-63.0106718, 46.3785007],
              [-63.0102718, 46.3788562],
              [-63.0099485, 46.3788753],
              [-63.0096477, 46.3786998],
              [-63.0094078, 46.3786694],
              [-63.0078123, 46.3794026],
              [-63.0068169, 46.3800087],
              [-63.0064181, 46.3801412],
              [-63.0055939, 46.3805688],
              [-63.0047979, 46.3809055],
              [-63.004465, 46.380992],
              [-63.0038409, 46.3808356],
              [-63.0034511, 46.3806059],
              [-63.0032118, 46.3802609],
              [-63.0032097, 46.3798944],
              [-63.0033927, 46.3796087],
              [-63.003313, 46.3791923],
              [-63.0032071, 46.3790301],
              [-63.0028884, 46.3787666],
              [-63.0028919, 46.3783007],
              [-63.002549, 46.3779568],
              [-63.0025028, 46.3777564],
              [-63.0013895, 46.3769972],
              [-63.0011958, 46.376761],
              [-63.0004103, 46.375],
              [-63.000257, 46.3746564],
              [-63.0, 46.374346],
              [-62.999457, 46.3738539],
              [-62.999336, 46.3736555],
              [-62.9988028, 46.3730157],
              [-62.9987043, 46.3728259],
              [-62.9981446, 46.3725517],
              [-62.9972742, 46.3718748],
              [-62.9966949, 46.3714408],
              [-62.9962647, 46.3711144],
              [-62.9958853, 46.3707058],
              [-62.9954902, 46.3701693],
              [-62.9947723, 46.3688394],
              [-62.9941735, 46.3680425],
              [-62.9931225, 46.3663012],
              [-62.9925954, 46.3651558],
              [-62.9922178, 46.3648189],
              [-62.9917753, 46.3645081],
              [-62.9916224, 46.3641654],
              [-62.9916812, 46.3637409],
              [-62.9919156, 46.3634646],
              [-62.9920761, 46.3633734],
              [-62.9922647, 46.3633945],
              [-62.9926474, 46.3637435],
              [-62.9928369, 46.364354],
              [-62.9936844, 46.3641184],
              [-62.9938162, 46.3640264],
              [-62.9935415, 46.3640029],
              [-62.9928794, 46.36371],
              [-62.9928361, 46.3635615],
              [-62.9929888, 46.3634064],
              [-62.9933171, 46.3633995],
              [-62.9935621, 46.3632389],
              [-62.9938526, 46.3622831],
              [-62.9941044, 46.3620033],
              [-62.9948849, 46.3618335],
              [-62.9955573, 46.3615413],
              [-62.9961098, 46.361437],
              [-62.996361, 46.3612686],
              [-62.9965098, 46.3608785],
              [-62.9968066, 46.3606158],
              [-62.9969998, 46.3603543],
              [-62.9969443, 46.3600182],
              [-62.9969456, 46.3595922],
              [-62.9970876, 46.3593213],
              [-62.9970916, 46.3591501],
              [-62.9965954, 46.359479],
              [-62.9963971, 46.3597285],
              [-62.9964576, 46.3604828],
              [-62.9959174, 46.3609777],
              [-62.9956055, 46.3610011],
              [-62.9953638, 46.3613051],
              [-62.9948407, 46.3612987],
              [-62.9946469, 46.3614686],
              [-62.9944487, 46.361515],
              [-62.9943552, 46.3615403],
              [-62.9941582, 46.3613636],
              [-62.99392, 46.3614049],
              [-62.9936975, 46.361574],
              [-62.9932816, 46.3624109],
              [-62.9926017, 46.3629338],
              [-62.992155, 46.3632003],
              [-62.9917946, 46.3632661],
              [-62.9916269, 46.3631819],
              [-62.9916325, 46.3630825],
              [-62.9922997, 46.3622804],
              [-62.9923206, 46.3620142],
              [-62.9919621, 46.3617455],
              [-62.9913463, 46.3614499],
              [-62.9902046, 46.361096],
              [-62.9896834, 46.3607551],
              [-62.9891543, 46.3605534],
              [-62.9884463, 46.3600601],
              [-62.9881524, 46.3599684],
              [-62.9880973, 46.3599271],
              [-62.9863347, 46.3594685],
              [-62.9859474, 46.3594021],
              [-62.9852661, 46.3594471],
              [-62.9850499, 46.3594053],
              [-62.9849447, 46.3593347],
              [-62.9844205, 46.3593482],
              [-62.9838923, 46.3595328],
              [-62.9836249, 46.3594816],
              [-62.9832827, 46.3592293],
              [-62.9830445, 46.3592705],
              [-62.9827584, 46.3594458],
              [-62.9825061, 46.3594309],
              [-62.9822242, 46.359232],
              [-62.982042, 46.359],
              [-62.9818906, 46.3589321],
              [-62.9816186, 46.3589605],
              [-62.9813294, 46.3587892],
              [-62.9812331, 46.3587627],
              [-62.9809394, 46.3584679],
              [-62.9807975, 46.3585357],
              [-62.9804293, 46.3585375],
              [-62.98018, 46.3583713],
              [-62.9802952, 46.3579683],
              [-62.980175, 46.3576584],
              [-62.980313, 46.3575586],
              [-62.980513, 46.3573809],
              [-62.9804952, 46.3570897],
              [-62.9802918, 46.3566262],
              [-62.9799841, 46.3562752],
              [-62.9800908, 46.3559198],
              [-62.979949, 46.3557845],
              [-62.9795753, 46.3556826],
              [-62.9794825, 46.3555964],
              [-62.9794794, 46.3552498],
              [-62.9791453, 46.3548583],
              [-62.9786204, 46.3544855],
              [-62.9785122, 46.3545661],
              [-62.9785174, 46.355076],
              [-62.9788835, 46.3556117],
              [-62.9793327, 46.3563011],
              [-62.9793399, 46.3564765],
              [-62.9783731, 46.3565853],
              [-62.9782278, 46.3567127],
              [-62.9782088, 46.3571422],
              [-62.9786044, 46.3580651],
              [-62.978565, 46.3586494],
              [-62.9782133, 46.3591654],
              [-62.9783116, 46.3593552],
              [-62.9786363, 46.3596111],
              [-62.9789414, 46.3597071],
              [-62.9795096, 46.3595276],
              [-62.9796723, 46.3595998],
              [-62.9797077, 46.3596844],
              [-62.9796522, 46.3600493],
              [-62.9797132, 46.360489],
              [-62.9800067, 46.3609869],
              [-62.9801415, 46.3614446],
              [-62.9801082, 46.3615233],
              [-62.9798275, 46.3618023],
              [-62.9797699, 46.3620038],
              [-62.9799977, 46.3623446],
              [-62.9800835, 46.3627532],
              [-62.9799122, 46.3629317],
              [-62.9797993, 46.3632949],
              [-62.9798236, 46.363873],
              [-62.9796973, 46.3640687],
              [-62.9797727, 46.3648593],
              [-62.9799623, 46.3650636],
              [-62.9802161, 46.3651502],
              [-62.9807334, 46.3654592],
              [-62.9810291, 46.3654195],
              [-62.9812841, 46.3654863],
              [-62.9816364, 46.3657628],
              [-62.9818945, 46.3661761],
              [-62.9820992, 46.3666198],
              [-62.9824755, 46.3685816],
              [-62.9824018, 46.3692645],
              [-62.9825092, 46.3694985],
              [-62.9826355, 46.3700037],
              [-62.9825262, 46.3708052],
              [-62.9821284, 46.3718217],
              [-62.9819028, 46.3721421],
              [-62.9815342, 46.3725501],
              [-62.9813096, 46.3730537],
              [-62.9805643, 46.373511],
              [-62.9802406, 46.3739361],
              [-62.9802368, 46.374402],
              [-62.9802727, 46.3745782],
              [-62.9801834, 46.3749302],
              [-62.9801862, 46.3749821],
              [-62.9797646, 46.3753091],
              [-62.9795327, 46.3758403],
              [-62.979129, 46.3762553],
              [-62.9790479, 46.3762651],
              [-62.9792377, 46.3769672],
              [-62.9789846, 46.3772669],
              [-62.9785664, 46.3775342],
              [-62.9782352, 46.3774892],
              [-62.9780797, 46.3775925],
              [-62.9777102, 46.3776141],
              [-62.9772772, 46.3774389],
              [-62.9770722, 46.3774014],
              [-62.976592, 46.3777466],
              [-62.976325, 46.377787],
              [-62.976062, 46.3776563],
              [-62.9758221, 46.3776258],
              [-62.9758378, 46.3777537],
              [-62.9764627, 46.3780935],
              [-62.9765606, 46.3781918],
              [-62.9767352, 46.3788577],
              [-62.9769462, 46.3790905],
              [-62.9775973, 46.3791762],
              [-62.9782167, 46.3794123],
              [-62.9786639, 46.3794406],
              [-62.9787579, 46.3795069],
              [-62.9788477, 46.3799474],
              [-62.9793313, 46.3802435],
              [-62.979402, 46.3806159],
              [-62.9793329, 46.3810162],
              [-62.9794798, 46.3811636],
              [-62.9799, 46.3812628],
              [-62.9804257, 46.3817273],
              [-62.9808183, 46.3818058],
              [-62.9810429, 46.3820032],
              [-62.9809621, 46.3823076],
              [-62.9809454, 46.3833984],
              [-62.980747, 46.3836479],
              [-62.9799739, 46.3838815],
              [-62.97983, 46.3840806],
              [-62.9796361, 46.3842505],
              [-62.97927, 46.3842126],
              [-62.9788455, 46.3844876],
              [-62.9783044, 46.3848909],
              [-62.9783115, 46.3850664],
              [-62.9782185, 46.3851833],
              [-62.977965, 46.3851882],
              [-62.9777722, 46.3853383],
              [-62.9778317, 46.3855032],
              [-62.977892, 46.3855566],
              [-62.9778902, 46.385688],
              [-62.9773617, 46.3856695],
              [-62.9770184, 46.3859348],
              [-62.9769304, 46.3860638],
              [-62.9772429, 46.3863353],
              [-62.9777517, 46.386895],
              [-62.9778071, 46.387231],
              [-62.9778752, 46.3873484],
              [-62.9782541, 46.3876655],
              [-62.9788181, 46.3882665],
              [-62.9789529, 46.3887243],
              [-62.9789085, 46.3892966],
              [-62.9785327, 46.390027],
              [-62.9784769, 46.390595],
              [-62.9787093, 46.3910595],
              [-62.9794059, 46.392158],
              [-62.9794579, 46.3925537],
              [-62.9792114, 46.3929373],
              [-62.9785805, 46.3933978],
              [-62.9770613, 46.3947898],
              [-62.9754306, 46.3959159],
              [-62.9748532, 46.396346],
              [-62.9740013, 46.3968443],
              [-62.9734712, 46.3972518],
              [-62.9733443, 46.3973559],
              [-62.9732016, 46.3975352],
              [-62.9722895, 46.3989757],
              [-62.9721925, 46.3995585],
              [-62.9726913, 46.4000941],
              [-62.9726817, 46.4006594],
              [-62.9726027, 46.4008325],
              [-62.9718246, 46.4011456],
              [-62.9712381, 46.4015317],
              [-62.9704284, 46.4024931],
              [-62.9700399, 46.4032392],
              [-62.9700657, 46.4038891],
              [-62.9699526, 46.4042524],
              [-62.9701418, 46.4043652],
              [-62.9705431, 46.4043962],
              [-62.9710286, 46.4047641],
              [-62.9710058, 46.4049587],
              [-62.9712376, 46.4053315],
              [-62.9714541, 46.4058712],
              [-62.9714336, 46.406026],
              [-62.9708662, 46.4064803],
              [-62.9703734, 46.4071359],
              [-62.9699182, 46.4080394],
              [-62.9698125, 46.408873],
              [-62.9697061, 46.409818],
              [-62.9701299, 46.4115545],
              [-62.9700814, 46.4115969],
              [-62.9701805, 46.4116753],
              [-62.9701745, 46.4119779],
              [-62.9702667, 46.4121756],
              [-62.9702634, 46.4127332],
              [-62.9703699, 46.4127839],
              [-62.970765, 46.4128227],
              [-62.9717681, 46.4132049],
              [-62.9723497, 46.4133046],
              [-62.9737803, 46.4134636],
              [-62.9740018, 46.4135175],
              [-62.9770875, 46.4142359],
              [-62.9780993, 46.4143673],
              [-62.9807629, 46.4144167],
              [-62.9813262, 46.4143366],
              [-62.9819041, 46.4142012],
              [-62.9821646, 46.4140769],
              [-62.9828427, 46.4142031],
              [-62.9838885, 46.4141442],
              [-62.9851745, 46.4139126],
              [-62.9862693, 46.4139029],
              [-62.987638, 46.4139364],
              [-62.9892096, 46.4140472],
              [-62.990655, 46.4139474],
              [-62.9927133, 46.4137051],
              [-62.9947106, 46.4137239],
              [-62.9967581, 46.4135688],
              [-62.9990153, 46.4135747],
              [-62.9996935, 46.4132945],
              [-63.0000001, 46.413338],
              [-63.0010007, 46.4134974],
              [-63.0014714, 46.4136258],
              [-63.0019116, 46.4138848],
              [-63.0024695, 46.4144019],
              [-63.003076, 46.4148765],
              [-63.0035162, 46.4149323],
              [-63.0040382, 46.4152731],
              [-63.0042851, 46.4151843],
              [-63.0044419, 46.4148579],
              [-63.00469, 46.414546],
              [-63.0050514, 46.4143687],
              [-63.0059835, 46.4136771],
              [-63.0059835, 46.4134739],
              [-63.0058319, 46.4132029],
              [-63.0058427, 46.4129125],
              [-63.0059397, 46.4128275],
              [-63.0065163, 46.4129149],
              [-63.0080417, 46.4134344],
              [-63.0081572, 46.4135291],
              [-63.0089819, 46.4137109],
              [-63.0103393, 46.413943],
              [-63.0107334, 46.4140015],
              [-63.011562, 46.4140121],
              [-63.0123045, 46.4140203],
              [-63.0124618, 46.4139887],
              [-63.0141045, 46.4140652],
              [-63.015016, 46.4139346],
              [-63.0160871, 46.4139358],
              [-63.0166142, 46.4138823],
              [-63.0169287, 46.4138192],
              [-63.020762, 46.4130428],
              [-63.0225641, 46.4126414],
              [-63.0235703, 46.4124655],
              [-63.0248816, 46.4127001],
              [-63.0258049, 46.4126653],
              [-63.0271138, 46.4125334],
              [-63.0283776, 46.4121812],
              [-63.0297179, 46.4116955],
              [-63.0306232, 46.4115726],
              [-63.0322855, 46.4116054],
              [-63.0334789, 46.4115818],
              [-63.0342565, 46.4112818],
              [-63.0349822, 46.4110848],
              [-63.0354774, 46.4110453],
              [-63.0358774, 46.4111764],
              [-63.0364715, 46.4116284],
              [-63.0367642, 46.4122373],
              [-63.0370086, 46.4129653],
              [-63.0371435, 46.4138744],
              [-63.0374797, 46.4142315],
              [-63.0376718, 46.414555],
              [-63.0374701, 46.4148574],
              [-63.0373595, 46.4149369],
              [-63.0372821, 46.415018],
              [-63.0367303, 46.4153059],
              [-63.0360037, 46.4154258],
              [-63.0341339, 46.4155985],
              [-63.032344, 46.4157813],
              [-63.0310925, 46.4157117],
              [-63.029935, 46.4155053],
              [-63.0287252, 46.4155124],
              [-63.0270498, 46.4156066],
              [-63.0207318, 46.4156111],
              [-63.0194775, 46.4154895],
              [-63.0180884, 46.4156112],
              [-63.0165358, 46.4155691],
              [-63.0158751, 46.4154396],
              [-63.0153012, 46.4156073],
              [-63.0140784, 46.4157414],
              [-63.0132655, 46.4158587],
              [-63.0118618, 46.4158326],
              [-63.0102353, 46.4159756],
              [-63.0089393, 46.4159801],
              [-63.0078856, 46.4159754],
              [-63.0068725, 46.4160673],
              [-63.0044535, 46.4159696],
              [-63.0032571, 46.4161441],
              [-63.0019424, 46.416172],
              [-63.0014316, 46.4162417],
              [-63.0004451, 46.4161709],
              [-63.0000001, 46.416293],
              [-62.9996671, 46.4162811],
              [-62.9989527, 46.4165883],
              [-62.9983636, 46.4165164],
              [-62.997006, 46.4164873],
              [-62.9957087, 46.4167147],
              [-62.9926524, 46.4170852],
              [-62.9903199, 46.4172842],
              [-62.9876592, 46.4177848],
              [-62.9866837, 46.4179214],
              [-62.9853332, 46.4182707],
              [-62.984346, 46.4183113],
              [-62.9830056, 46.4186847],
              [-62.9822782, 46.4189157],
              [-62.9819382, 46.4189183],
              [-62.9816541, 46.4188507],
              [-62.9805631, 46.4188924],
              [-62.9797912, 46.4189947],
              [-62.9792024, 46.4192175],
              [-62.9774913, 46.4192261],
              [-62.9769094, 46.4193296],
              [-62.976399, 46.4194908],
              [-62.9749443, 46.4195463],
              [-62.9717618, 46.4200002],
              [-62.9713258, 46.4200679],
              [-62.9701883, 46.4203153],
              [-62.9693513, 46.4206467],
              [-62.9687858, 46.4207665],
              [-62.968028, 46.4207217],
              [-62.9657217, 46.4209606],
              [-62.9639082, 46.421245],
              [-62.9626552, 46.4213975],
              [-62.9608816, 46.421687],
              [-62.9598119, 46.4217569],
              [-62.9594816, 46.421692],
              [-62.9593526, 46.4216326],
              [-62.9567662, 46.4221305],
              [-62.9559332, 46.4222906],
              [-62.9552905, 46.4222488],
              [-62.954232, 46.422323],
              [-62.953434, 46.4224761],
              [-62.9528655, 46.4225439],
              [-62.9517484, 46.4226363],
              [-62.9508202, 46.4227499],
              [-62.9502366, 46.4227815],
              [-62.9486831, 46.4228498],
              [-62.9472516, 46.4228019],
              [-62.9456647, 46.4229489],
              [-62.9436298, 46.4232706],
              [-62.9427066, 46.4233962],
              [-62.9415641, 46.423428],
              [-62.9384506, 46.42407],
              [-62.9373839, 46.4242831],
              [-62.9355102, 46.4246051],
              [-62.934548, 46.4247057],
              [-62.9339387, 46.4248798],
              [-62.9311836, 46.4254957],
              [-62.9289872, 46.4261113],
              [-62.9274922, 46.4264557],
              [-62.9262572, 46.4267875],
              [-62.925621, 46.4268294],
              [-62.9250389, 46.4269326],
              [-62.9247902, 46.4269495],
              [-62.9230464, 46.4273108],
              [-62.9221215, 46.4273644],
              [-62.9219742, 46.42742],
              [-62.9214783, 46.4275256],
              [-62.9208021, 46.4275623],
              [-62.9203342, 46.4277802],
              [-62.919841, 46.4279376],
              [-62.9184843, 46.4278874],
              [-62.9179654, 46.4281875],
              [-62.9171212, 46.4284346],
              [-62.9158974, 46.4284758],
              [-62.9137742, 46.4290174],
              [-62.912277, 46.429493],
              [-62.9118575, 46.4296684],
              [-62.9108769, 46.4298836],
              [-62.9094308, 46.4299822],
              [-62.9084513, 46.4301776],
              [-62.9072377, 46.4306293],
              [-62.906148, 46.4311282],
              [-62.9050002, 46.4314423],
              [-62.9044066, 46.431541],
              [-62.903264, 46.4315724],
              [-62.9020946, 46.4314715],
              [-62.9005561, 46.4312805],
              [-62.8995907, 46.4312371],
              [-62.8978767, 46.4312841],
              [-62.8966407, 46.4314322],
              [-62.8958358, 46.431501],
              [-62.8926566, 46.4319643],
              [-62.8914944, 46.4321304],
              [-62.8904593, 46.4321926],
              [-62.8894388, 46.4321077],
              [-62.8875507, 46.4320778],
              [-62.8857845, 46.4322266],
              [-62.8851986, 46.4323892],
              [-62.8841203, 46.4325974],
              [-62.8837416, 46.4326663],
              [-62.8831478, 46.4326732],
              [-62.8817255, 46.4324653],
              [-62.8802751, 46.4324397],
              [-62.8781062, 46.4325769],
              [-62.8765978, 46.4328444],
              [-62.8743156, 46.4331416],
              [-62.8714234, 46.4335208],
              [-62.8697537, 46.4337876],
              [-62.867594774210325, 46.434017376836692],
              [-62.8674951, 46.433655],
              [-62.8608907, 46.4167248],
              [-62.857707632343768, 46.408643692763988],
              [-62.8582579, 46.4084794],
              [-62.8592822, 46.4083018],
              [-62.8603855, 46.4082459],
              [-62.8608018, 46.4083137],
              [-62.8619553, 46.4083788],
              [-62.8624459, 46.4086479],
              [-62.8632246, 46.4087221],
              [-62.8634397, 46.408784],
              [-62.8643658, 46.4088027],
              [-62.865516, 46.4090191],
              [-62.8663659, 46.4090594],
              [-62.8671574, 46.4092097],
              [-62.8677154, 46.4090265],
              [-62.868378, 46.4091173],
              [-62.8684678, 46.4090601],
              [-62.8676919, 46.4086514],
              [-62.8671908, 46.4086529],
              [-62.8652721, 46.4084783],
              [-62.8648879, 46.4082601],
              [-62.8639789, 46.4080547],
              [-62.8631251, 46.4076356],
              [-62.8627719, 46.4074719],
              [-62.8622942, 46.4071159],
              [-62.8621698, 46.4071774],
              [-62.8623151, 46.4073634],
              [-62.8621935, 46.407442],
              [-62.8620302, 46.4072044],
              [-62.861753, 46.4068989],
              [-62.8614967, 46.4068845],
              [-62.8612631, 46.4067929],
              [-62.8611302, 46.406654],
              [-62.860778, 46.4060917],
              [-62.860074, 46.4053796],
              [-62.8595612, 46.4051116],
              [-62.8585289, 46.405089],
              [-62.8584601, 46.4050657],
              [-62.8584496, 46.4049609],
              [-62.8590049, 46.4048922],
              [-62.8592023, 46.4044674],
              [-62.8592423, 46.4041899],
              [-62.8590914, 46.4038877],
              [-62.8593478, 46.4035888],
              [-62.8595376, 46.4032024],
              [-62.8597357, 46.4029731],
              [-62.8600301, 46.4027705],
              [-62.8601247, 46.4026338],
              [-62.8601041, 46.4024022],
              [-62.8599273, 46.402182],
              [-62.8598895, 46.4020454],
              [-62.8599383, 46.4016166],
              [-62.8599891, 46.4014428],
              [-62.8598361, 46.4012114],
              [-62.8598287, 46.4007611],
              [-62.8599927, 46.4004272],
              [-62.860737, 46.3998273],
              [-62.861226, 46.3990686],
              [-62.8616162, 46.3987094],
              [-62.8618513, 46.3983418],
              [-62.8629502, 46.3972063],
              [-62.8640629, 46.395649],
              [-62.8644508, 46.3954212],
              [-62.8651316, 46.395397],
              [-62.8653811, 46.3952687],
              [-62.8654608, 46.3951874],
              [-62.8652079, 46.3949889],
              [-62.864644, 46.3947935],
              [-62.8647385, 46.3946568],
              [-62.8650939, 46.3943961],
              [-62.8650199, 46.3942865],
              [-62.8648839, 46.3943463],
              [-62.8642706, 46.3943963],
              [-62.8638336, 46.3940054],
              [-62.8636724, 46.3940047],
              [-62.8631052, 46.3944385],
              [-62.8625006, 46.394441],
              [-62.8621598, 46.3945546],
              [-62.8620214, 46.3946542],
              [-62.8619173, 46.3954279],
              [-62.861527, 46.3961735],
              [-62.8615189, 46.3968823],
              [-62.8611252, 46.3973011],
              [-62.8607198, 46.3980104],
              [-62.8602411, 46.3984069],
              [-62.8599338, 46.3984418],
              [-62.8597037, 46.3983436],
              [-62.8596034, 46.3981933],
              [-62.8595033, 46.3971787],
              [-62.8596, 46.3969106],
              [-62.8596601, 46.3964861],
              [-62.8592147, 46.3967123],
              [-62.859025, 46.3972804],
              [-62.8588866, 46.3983359],
              [-62.8587288, 46.398662],
              [-62.8583844, 46.3988353],
              [-62.8581696, 46.398865],
              [-62.8580261, 46.3989524],
              [-62.8583626, 46.3991016],
              [-62.8588068, 46.3993733],
              [-62.8588111, 46.3995885],
              [-62.8584466, 46.4001914],
              [-62.8583196, 46.4008649],
              [-62.8581215, 46.4020502],
              [-62.8581097, 46.4025837],
              [-62.8580588, 46.4026279],
              [-62.8579754, 46.4031986],
              [-62.8572705, 46.4035458],
              [-62.8573388, 46.4044434],
              [-62.8574579, 46.4046823],
              [-62.8577372, 46.4049214],
              [-62.8581572, 46.4049239],
              [-62.8582231, 46.4049452],
              [-62.8582175, 46.4050979],
              [-62.8572819, 46.4051025],
              [-62.856473245114472, 46.405375244617325],
              [-62.8564653, 46.4053542],
            ],
          ],
          [
            [
              [-62.7595002, 45.9567417],
              [-62.7597457, 45.9563862],
              [-62.7598084, 45.9561477],
              [-62.7595576, 45.9559887],
              [-62.7592366, 45.9556656],
              [-62.7589157, 45.9553682],
              [-62.7586059, 45.9549451],
              [-62.7579458, 45.9542553],
              [-62.7575252, 45.9535579],
              [-62.7572782, 45.9530657],
              [-62.7570016, 45.9529631],
              [-62.7567766, 45.9527683],
              [-62.7567582, 45.9525939],
              [-62.7567873, 45.9521915],
              [-62.7563357, 45.9512952],
              [-62.7562577, 45.9509907],
              [-62.7559843, 45.9504042],
              [-62.7556, 45.9497593],
              [-62.7556912, 45.9494314],
              [-62.7555505, 45.949381],
              [-62.7552189, 45.9491614],
              [-62.7549302, 45.9490818],
              [-62.7546262, 45.9491091],
              [-62.7540984, 45.9492288],
              [-62.7537109, 45.9493783],
              [-62.7528579, 45.9497674],
              [-62.7529029, 45.9499389],
              [-62.7528924, 45.9499908],
              [-62.7528388, 45.9500001],
              [-62.7527923, 45.9499664],
              [-62.7523915, 45.9488013],
              [-62.752259, 45.9483341],
              [-62.7522816, 45.9482975],
              [-62.7523357, 45.948291],
              [-62.7523895, 45.94832],
              [-62.7525407, 45.9488716],
              [-62.7535683, 45.9487796],
              [-62.7540609, 45.9487181],
              [-62.7544145, 45.9485571],
              [-62.7549366, 45.9483872],
              [-62.7555512, 45.9483609],
              [-62.7557912, 45.9484809],
              [-62.7560564, 45.9485922],
              [-62.7564732, 45.9488877],
              [-62.7560606, 45.9494672],
              [-62.756153, 45.9499466],
              [-62.756595, 45.951094],
              [-62.7572476, 45.9522004],
              [-62.7578654, 45.9532423],
              [-62.7581093, 45.9534645],
              [-62.7584354, 45.9538872],
              [-62.7587389, 45.9542935],
              [-62.7597742, 45.9555141],
              [-62.7600494, 45.9560702],
              [-62.7601232, 45.9564647],
              [-62.7599915, 45.9565795],
              [-62.7596604, 45.9567693],
              [-62.7595002, 45.9567417],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        id: "relation/2256615",
        "@id": "relation/2256615",
        "@relations": null,
        admin_level: "5",
        boundary: "administrative",
        land_area: null,
        name: "Prince County",
        source: "StatisticsCanadaCensusBoundaries",
        type: "boundary",
        wikidata: "Q2110336",
        wikipedia: "en:Prince County, Prince Edward Island",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-63.6142434, 46.544152],
            [-63.6137452, 46.5428745],
            [-63.6128445, 46.5405344],
            [-63.6052554, 46.5208984],
            [-63.6018268, 46.5115436],
            [-63.5949509, 46.4927699],
            [-63.5931046, 46.4877257],
            [-63.590019, 46.4792307],
            [-63.5888913, 46.4762179],
            [-63.5883331, 46.4746295],
            [-63.5878237, 46.4732215],
            [-63.5873201, 46.4718144],
            [-63.5841266, 46.4630669],
            [-63.5840991, 46.4629942],
            [-63.5823905, 46.4583981],
            [-63.5753025, 46.4382914],
            [-63.5749571, 46.4372771],
            [-63.5747849, 46.4368168],
            [-63.574612, 46.4363629],
            [-63.5744913, 46.4360307],
            [-63.5737859, 46.4339889],
            [-63.5732584, 46.4326407],
            [-63.5730484, 46.4321649],
            [-63.5729181, 46.4318372],
            [-63.5726537, 46.4311323],
            [-63.5726233, 46.431029],
            [-63.5726094, 46.4309436],
            [-63.5725892, 46.4308808],
            [-63.5725161, 46.4307327],
            [-63.5723891, 46.4304094],
            [-63.5717677, 46.4287325],
            [-63.5714282, 46.4278098],
            [-63.57117, 46.4270792],
            [-63.5705539, 46.4254009],
            [-63.5704268, 46.4251353],
            [-63.570343, 46.4247618],
            [-63.5701249, 46.4241907],
            [-63.5700979, 46.4240987],
            [-63.5699341, 46.4236744],
            [-63.5695456, 46.4226035],
            [-63.5695327, 46.4225568],
            [-63.5689458, 46.4209174],
            [-63.5689189, 46.4208366],
            [-63.568912, 46.4208006],
            [-63.5688854, 46.4207445],
            [-63.5687916, 46.4204818],
            [-63.5676554, 46.4172623],
            [-63.5672633, 46.4161455],
            [-63.5672531, 46.4160983],
            [-63.5670357, 46.4155259],
            [-63.5664956, 46.4140657],
            [-63.5661011, 46.4130304],
            [-63.5656681, 46.4119139],
            [-63.5654741, 46.4113975],
            [-63.5645596, 46.4089092],
            [-63.564522, 46.4088306],
            [-63.5641799, 46.4079515],
            [-63.5639258, 46.4072758],
            [-63.5634609, 46.4063938],
            [-63.5633856, 46.405795],
            [-63.5630013, 46.404787],
            [-63.5625684, 46.4036704],
            [-63.5619696, 46.4020495],
            [-63.5597521, 46.3958974],
            [-63.5583916, 46.3920027],
            [-63.5575672, 46.3896502],
            [-63.5573856, 46.3890528],
            [-63.5568742, 46.3876743],
            [-63.5563668, 46.386249],
            [-63.5562617, 46.38595],
            [-63.5560047, 46.38529],
            [-63.5555193, 46.3839091],
            [-63.5555058, 46.3838575],
            [-63.5554458, 46.3837138],
            [-63.5550915, 46.3827529],
            [-63.5550378, 46.3825845],
            [-63.5550179, 46.3825396],
            [-63.5549039, 46.3822028],
            [-63.5542884, 46.3804807],
            [-63.554248, 46.380346],
            [-63.5541345, 46.3800505],
            [-63.553991, 46.379677],
            [-63.5538604, 46.3793065],
            [-63.5535865, 46.3785769],
            [-63.5534601, 46.3782851],
            [-63.5533839, 46.3781393],
            [-63.5533416, 46.3780734],
            [-63.5573665, 46.377361],
            [-63.5565091, 46.3750647],
            [-63.5507109, 46.3594237],
            [-63.5487666, 46.3542318],
            [-63.5448453, 46.3434634],
            [-63.5448219, 46.3434594],
            [-63.5448046, 46.3434127],
            [-63.5440432, 46.3413157],
            [-63.5438867, 46.3407653],
            [-63.5433559, 46.3392904],
            [-63.5432284, 46.3390795],
            [-63.5425673, 46.3372608],
            [-63.5422725, 46.3366886],
            [-63.5408992, 46.332784],
            [-63.5402783, 46.3310686],
            [-63.5388037, 46.3270767],
            [-63.5378828, 46.3245665],
            [-63.5368082, 46.3215978],
            [-63.5358742, 46.3190562],
            [-63.5347543, 46.3160791],
            [-63.5337209, 46.313313],
            [-63.5315873, 46.3075472],
            [-63.5302676, 46.3039998],
            [-63.5297284, 46.3026168],
            [-63.5294684, 46.3019028],
            [-63.5288623, 46.3003132],
            [-63.5279893, 46.2979602],
            [-63.5275159, 46.2966579],
            [-63.5265233, 46.294004],
            [-63.5262142, 46.2928806],
            [-63.5260521, 46.2924456],
            [-63.5255905, 46.2914803],
            [-63.5254172, 46.2910043],
            [-63.5252636, 46.2905552],
            [-63.524897, 46.2895403],
            [-63.5247171, 46.2889647],
            [-63.5244686, 46.288294],
            [-63.5239248, 46.2869583],
            [-63.5231586, 46.2848701],
            [-63.5227992, 46.2839316],
            [-63.522486, 46.2830739],
            [-63.5220532, 46.2819108],
            [-63.5217201, 46.2809992],
            [-63.5212199, 46.2795801],
            [-63.5207208, 46.2782644],
            [-63.5203744, 46.2773213],
            [-63.519995, 46.2763109],
            [-63.5195361, 46.275125],
            [-63.5177516, 46.2702841],
            [-63.5165806, 46.2671811],
            [-63.5159943, 46.2655419],
            [-63.5159016, 46.2653444],
            [-63.5154089, 46.2639881],
            [-63.514657, 46.2619718],
            [-63.5146434, 46.2619089],
            [-63.5146037, 46.2618236],
            [-63.5145839, 46.2617922],
            [-63.514544, 46.2617515],
            [-63.5136187, 46.2593352],
            [-63.5101685, 46.250064],
            [-63.509913, 46.2493693],
            [-63.5080723, 46.2444357],
            [-63.5082845, 46.2442459],
            [-63.5084902, 46.244056],
            [-63.5086829, 46.2438572],
            [-63.508869, 46.243654],
            [-63.5090421, 46.2434508],
            [-63.5092087, 46.2432386],
            [-63.5093886, 46.2429904],
            [-63.5097008, 46.2424762],
            [-63.5098345, 46.2422147],
            [-63.5099617, 46.2419487],
            [-63.5100694, 46.2416783],
            [-63.5101955, 46.2412954],
            [-63.5103021, 46.2409126],
            [-63.5103763, 46.2405254],
            [-63.5104115, 46.2401339],
            [-63.5104208, 46.2397425],
            [-63.5103913, 46.2393558],
            [-63.5103566, 46.2391175],
            [-63.5103025, 46.2388793],
            [-63.510242, 46.2386457],
            [-63.5100821, 46.2381786],
            [-63.5099891, 46.2379451],
            [-63.5098832, 46.2377117],
            [-63.5097709, 46.2374828],
            [-63.5095138, 46.2370251],
            [-63.5092309, 46.2365765],
            [-63.5090339, 46.236312],
            [-63.5088305, 46.236052],
            [-63.5086142, 46.2357966],
            [-63.5083785, 46.2355458],
            [-63.50813, 46.2353085],
            [-63.5078621, 46.2350758],
            [-63.50764, 46.2349013],
            [-63.5074051, 46.2347314],
            [-63.5071637, 46.2345661],
            [-63.5069159, 46.2344052],
            [-63.5066552, 46.234249],
            [-63.5065477, 46.2341864],
            [-63.5063945, 46.2340972],
            [-63.5060036, 46.233892],
            [-63.5056064, 46.2336914],
            [-63.5051963, 46.2335088],
            [-63.5047733, 46.2333352],
            [-63.5043441, 46.2331797],
            [-63.503902, 46.2330377],
            [-63.5028458, 46.2301199],
            [-63.5019681, 46.2275364],
            [-63.4997716, 46.2218422],
            [-63.4995594, 46.221174],
            [-63.4994546, 46.2209495],
            [-63.4975036, 46.2157901],
            [-63.4961782, 46.2120377],
            [-63.4963873, 46.2120092],
            [-63.4968674, 46.2118162],
            [-63.497075, 46.211685],
            [-63.4971759, 46.2116213],
            [-63.4974468, 46.211276],
            [-63.4977338, 46.2109661],
            [-63.4978008, 46.2106988],
            [-63.4977525, 46.2104287],
            [-63.4977606, 46.2102348],
            [-63.497715, 46.2101048],
            [-63.497605, 46.209935],
            [-63.4974212, 46.2097336],
            [-63.4973002, 46.2095673],
            [-63.4968469, 46.2092061],
            [-63.4965847, 46.2090849],
            [-63.4962898, 46.208888],
            [-63.495962, 46.2087366],
            [-63.4957908, 46.208538],
            [-63.4955424, 46.2083582],
            [-63.4954432, 46.2082394],
            [-63.495411, 46.2081466],
            [-63.4954995, 46.2079424],
            [-63.4955236, 46.2077865],
            [-63.4954941, 46.2075674],
            [-63.4952608, 46.207367],
            [-63.4949738, 46.2072129],
            [-63.4947351, 46.2071554],
            [-63.4945393, 46.2070366],
            [-63.4944508, 46.2069437],
            [-63.4943354, 46.2067693],
            [-63.4942496, 46.2065409],
            [-63.4942925, 46.20632],
            [-63.4945312, 46.2061363],
            [-63.4947136, 46.20601],
            [-63.4949247, 46.205951],
            [-63.4951873, 46.2057726],
            [-63.4954255, 46.2055964],
            [-63.4956116, 46.2054971],
            [-63.4957284, 46.2054313],
            [-63.4957424, 46.2053498],
            [-63.4958025, 46.2051347],
            [-63.4961257, 46.2047537],
            [-63.4963819, 46.2045148],
            [-63.4965134, 46.2043719],
            [-63.4967038, 46.2043895],
            [-63.4968432, 46.2044343],
            [-63.4969654, 46.2045047],
            [-63.4971946, 46.2046115],
            [-63.4976716, 46.2049626],
            [-63.4976479, 46.2050095],
            [-63.4976989, 46.2050875],
            [-63.4977311, 46.2051766],
            [-63.4976855, 46.2053566],
            [-63.4976479, 46.205468],
            [-63.4977016, 46.2055553],
            [-63.4977311, 46.2056109],
            [-63.4977177, 46.2056666],
            [-63.4976882, 46.2057168],
            [-63.4977338, 46.2058226],
            [-63.4976962, 46.2058615],
            [-63.4976426, 46.2059525],
            [-63.4975889, 46.206062],
            [-63.4975353, 46.2061493],
            [-63.4975407, 46.2062458],
            [-63.4975809, 46.2063609],
            [-63.4976694, 46.2064555],
            [-63.4978089, 46.2065706],
            [-63.498053, 46.206682],
            [-63.4983319, 46.2067785],
            [-63.4986779, 46.2068509],
            [-63.4991929, 46.2069233],
            [-63.4995148, 46.2069604],
            [-63.5000914, 46.2069809],
            [-63.5004401, 46.2069753],
            [-63.501057, 46.206927],
            [-63.5016713, 46.2068454],
            [-63.5024062, 46.2067117],
            [-63.5033195, 46.2064685],
            [-63.5038774, 46.2063145],
            [-63.5045922, 46.206088],
            [-63.5050401, 46.2058374],
            [-63.5053137, 46.2056184],
            [-63.5055524, 46.2054383],
            [-63.5057831, 46.2053344],
            [-63.5060272, 46.2052991],
            [-63.5064563, 46.2053047],
            [-63.506864, 46.2053195],
            [-63.5071886, 46.2052712],
            [-63.5075963, 46.2051673],
            [-63.5084304, 46.2049835],
            [-63.5089079, 46.2048963],
            [-63.5096991, 46.2047849],
            [-63.5104743, 46.2047515],
            [-63.51181, 46.2047719],
            [-63.5128748, 46.2046902],
            [-63.5136125, 46.2046958],
            [-63.5147792, 46.2046884],
            [-63.5154283, 46.2046791],
            [-63.516013, 46.2045584],
            [-63.5164793, 46.2043549],
            [-63.5169025, 46.204372],
            [-63.5173163, 46.2043452],
            [-63.5175574, 46.2043346],
            [-63.5194889, 46.2038156],
            [-63.5209012, 46.2036274],
            [-63.521889, 46.2037046],
            [-63.5222297, 46.2037716],
            [-63.523046, 46.2037138],
            [-63.5235951, 46.2036063],
            [-63.5240057, 46.2033964],
            [-63.5241219, 46.2033591],
            [-63.5248879, 46.2032723],
            [-63.5258136, 46.2030735],
            [-63.5264631, 46.2031552],
            [-63.5273985, 46.2035057],
            [-63.5282426, 46.2041048],
            [-63.5286886, 46.2046356],
            [-63.5291563, 46.2059626],
            [-63.5293596, 46.2064126],
            [-63.5302165, 46.2072387],
            [-63.5309696, 46.2078239],
            [-63.5320685, 46.2086195],
            [-63.532388, 46.2091635],
            [-63.5322874, 46.2098734],
            [-63.5323466, 46.2104715],
            [-63.5327462, 46.2115305],
            [-63.5333073, 46.2124377],
            [-63.5340287, 46.2128392],
            [-63.5341318, 46.2129688],
            [-63.5341201, 46.2133584],
            [-63.5342783, 46.2137915],
            [-63.5342294, 46.2141167],
            [-63.5335661, 46.2145957],
            [-63.5333465, 46.2146348],
            [-63.5331359, 46.214475],
            [-63.5327618, 46.2146381],
            [-63.5326236, 46.2149096],
            [-63.5320688, 46.2152676],
            [-63.5312311, 46.2156713],
            [-63.5306692, 46.2161883],
            [-63.5298186, 46.2164962],
            [-63.529189, 46.2169878],
            [-63.5289528, 46.2171419],
            [-63.5288496, 46.2171436],
            [-63.5288079, 46.2171785],
            [-63.5283774, 46.2173204],
            [-63.5281811, 46.2174793],
            [-63.5281931, 46.2175949],
            [-63.528611, 46.2178626],
            [-63.5288105, 46.2178868],
            [-63.5292478, 46.2178485],
            [-63.5296463, 46.2176542],
            [-63.5299718, 46.2175538],
            [-63.5302624, 46.2175919],
            [-63.530806, 46.2178663],
            [-63.5312303, 46.2178635],
            [-63.5318224, 46.2175699],
            [-63.5321002, 46.217381],
            [-63.532522, 46.2173065],
            [-63.5328491, 46.2172976],
            [-63.5337729, 46.2166333],
            [-63.5349731, 46.2163249],
            [-63.535554, 46.2158958],
            [-63.5356182, 46.2158693],
            [-63.5356644, 46.2157351],
            [-63.5363998, 46.215182],
            [-63.5366819, 46.2150249],
            [-63.5373481, 46.2151228],
            [-63.5381104, 46.2153779],
            [-63.5391722, 46.2154724],
            [-63.5399546, 46.2156643],
            [-63.5402764, 46.2157746],
            [-63.5407552, 46.2158327],
            [-63.5412903, 46.2160431],
            [-63.5416683, 46.2163059],
            [-63.5418319, 46.216751],
            [-63.5419991, 46.2169853],
            [-63.5422592, 46.2171938],
            [-63.5424499, 46.2174168],
            [-63.5424731, 46.2176679],
            [-63.5422862, 46.2180021],
            [-63.5418426, 46.2183108],
            [-63.5409407, 46.2187411],
            [-63.5404771, 46.219113],
            [-63.5398396, 46.2197837],
            [-63.5395192, 46.2200274],
            [-63.5392293, 46.2201008],
            [-63.5387985, 46.2205053],
            [-63.5386525, 46.2208244],
            [-63.5386613, 46.2215249],
            [-63.538359, 46.2218764],
            [-63.5383501, 46.2220752],
            [-63.5385208, 46.2223613],
            [-63.5389153, 46.2226404],
            [-63.5389413, 46.2227006],
            [-63.5389788, 46.2234017],
            [-63.5392518, 46.223706],
            [-63.539349, 46.223712],
            [-63.5398633, 46.2240056],
            [-63.5399187, 46.2241779],
            [-63.5401892, 46.2244105],
            [-63.5404399, 46.2244437],
            [-63.5406411, 46.2245594],
            [-63.5406895, 46.2247594],
            [-63.5405295, 46.2251339],
            [-63.5401995, 46.2253337],
            [-63.5399665, 46.2258022],
            [-63.5399542, 46.2259492],
            [-63.5395486, 46.2263025],
            [-63.5395155, 46.2264013],
            [-63.5395979, 46.2264826],
            [-63.5398495, 46.226496],
            [-63.5407194, 46.2261446],
            [-63.541855, 46.2254887],
            [-63.5424028, 46.2252896],
            [-63.5428505, 46.2252753],
            [-63.5433277, 46.2253731],
            [-63.5438247, 46.2256701],
            [-63.5440752, 46.225966],
            [-63.5441304, 46.2264009],
            [-63.5440737, 46.2267737],
            [-63.5439685, 46.2270778],
            [-63.5423955, 46.2286714],
            [-63.5421931, 46.2288381],
            [-63.5417028, 46.2296751],
            [-63.5411825, 46.2301573],
            [-63.5408343, 46.2303806],
            [-63.5405476, 46.2307683],
            [-63.5405471, 46.2312935],
            [-63.540785, 46.2319989],
            [-63.540749, 46.2324199],
            [-63.540471, 46.2327402],
            [-63.5400557, 46.2333122],
            [-63.5401984, 46.2338405],
            [-63.5402016, 46.2341549],
            [-63.5404711, 46.2345387],
            [-63.5407461, 46.2346719],
            [-63.5412095, 46.2348251],
            [-63.5418448, 46.2348507],
            [-63.5422998, 46.2346774],
            [-63.5429095, 46.2342488],
            [-63.5430215, 46.2341796],
            [-63.5433512, 46.2342424],
            [-63.5434918, 46.2343051],
            [-63.5435522, 46.234752],
            [-63.5433815, 46.2353651],
            [-63.5436338, 46.2356212],
            [-63.5438586, 46.2357255],
            [-63.5443166, 46.2359979],
            [-63.5443407, 46.2362292],
            [-63.5440601, 46.2364778],
            [-63.5432597, 46.2366835],
            [-63.5428323, 46.2370085],
            [-63.5432139, 46.2374544],
            [-63.5434331, 46.2374253],
            [-63.5436523, 46.2373962],
            [-63.543644, 46.2369384],
            [-63.543921, 46.2367693],
            [-63.5448871, 46.2367064],
            [-63.5451512, 46.2364415],
            [-63.5450569, 46.2361132],
            [-63.5445547, 46.2356728],
            [-63.5445574, 46.2356131],
            [-63.5438791, 46.2350057],
            [-63.5438492, 46.2345197],
            [-63.5438953, 46.2343854],
            [-63.5438418, 46.234042],
            [-63.5435261, 46.2337925],
            [-63.5432849, 46.233672],
            [-63.5428405, 46.233738],
            [-63.5426538, 46.2338096],
            [-63.5417386, 46.2342754],
            [-63.5413523, 46.2343228],
            [-63.5409002, 46.2341739],
            [-63.5406565, 46.2339816],
            [-63.5406749, 46.2338268],
            [-63.5408114, 46.233595],
            [-63.5417162, 46.2329738],
            [-63.5417659, 46.23276],
            [-63.541584, 46.2323384],
            [-63.5416251, 46.2320607],
            [-63.5416737, 46.2319981],
            [-63.5417391, 46.2316892],
            [-63.5415425, 46.2313427],
            [-63.5413908, 46.2311445],
            [-63.5413806, 46.2309891],
            [-63.54292, 46.2301508],
            [-63.5436109, 46.2299548],
            [-63.5445136, 46.2298985],
            [-63.5449564, 46.2297409],
            [-63.5454007, 46.2296748],
            [-63.5454973, 46.2294382],
            [-63.5457786, 46.229301],
            [-63.5462604, 46.229168],
            [-63.5469019, 46.2289232],
            [-63.547447, 46.2287836],
            [-63.5483574, 46.2288111],
            [-63.5489398, 46.2286047],
            [-63.5490345, 46.2285391],
            [-63.5489356, 46.2284415],
            [-63.5484428, 46.2283076],
            [-63.5482025, 46.2281672],
            [-63.5477504, 46.2281496],
            [-63.5474147, 46.2279633],
            [-63.5470537, 46.2279596],
            [-63.5467969, 46.2278029],
            [-63.5467556, 46.2274439],
            [-63.5469556, 46.2268116],
            [-63.5468899, 46.2264839],
            [-63.5452904, 46.2246831],
            [-63.5452923, 46.224512],
            [-63.5451954, 46.2241121],
            [-63.5446597, 46.2235277],
            [-63.5446026, 46.2232638],
            [-63.5441916, 46.2229686],
            [-63.5435757, 46.2227683],
            [-63.5430959, 46.2227302],
            [-63.5429727, 46.2226639],
            [-63.5429798, 46.2225049],
            [-63.5435591, 46.2218529],
            [-63.5442536, 46.221446],
            [-63.544909, 46.2211458],
            [-63.5452007, 46.2209014],
            [-63.5452781, 46.2207081],
            [-63.545563, 46.2203601],
            [-63.5462184, 46.2200598],
            [-63.5469207, 46.2196054],
            [-63.5471414, 46.2191526],
            [-63.547048, 46.2188044],
            [-63.5473198, 46.218492],
            [-63.5472065, 46.2180758],
            [-63.5471223, 46.2180342],
            [-63.5470907, 46.2174566],
            [-63.5469469, 46.2172108],
            [-63.5469681, 46.2167338],
            [-63.5468722, 46.2161827],
            [-63.5465101, 46.2155623],
            [-63.5460133, 46.2151339],
            [-63.5459902, 46.2147514],
            [-63.5457128, 46.2144153],
            [-63.5456557, 46.2142828],
            [-63.5454406, 46.2142225],
            [-63.545295, 46.2140164],
            [-63.5450496, 46.213864],
            [-63.5443437, 46.21363],
            [-63.5438164, 46.213372],
            [-63.543619, 46.2129142],
            [-63.5434794, 46.2128317],
            [-63.5432486, 46.2128665],
            [-63.5429977, 46.2130958],
            [-63.54291, 46.213265],
            [-63.5427754, 46.2133258],
            [-63.5426376, 46.2132035],
            [-63.5426259, 46.212694],
            [-63.5429447, 46.2122274],
            [-63.5430127, 46.2118588],
            [-63.5428518, 46.2113541],
            [-63.5429332, 46.2105562],
            [-63.5429225, 46.2098955],
            [-63.542517, 46.2093497],
            [-63.5426449, 46.2089228],
            [-63.5426897, 46.2083031],
            [-63.5429964, 46.2077209],
            [-63.543147, 46.2071711],
            [-63.5433285, 46.2069562],
            [-63.5436538, 46.2068557],
            [-63.5441041, 46.2067819],
            [-63.5443393, 46.2066477],
            [-63.5445131, 46.2062177],
            [-63.5447761, 46.2059727],
            [-63.5458332, 46.2052674],
            [-63.5462843, 46.2051736],
            [-63.546719, 46.2049323],
            [-63.5470122, 46.2049107],
            [-63.5479842, 46.2053453],
            [-63.5488278, 46.2058328],
            [-63.5491513, 46.2059034],
            [-63.5497946, 46.2062553],
            [-63.5502741, 46.2064247],
            [-63.5509793, 46.2065472],
            [-63.5516157, 46.2067955],
            [-63.5527805, 46.2070193],
            [-63.5536844, 46.2069233],
            [-63.5550361, 46.206419],
            [-63.5554716, 46.2064203],
            [-63.5559487, 46.2062555],
            [-63.5563858, 46.2063483],
            [-63.5572522, 46.2067129],
            [-63.5575973, 46.2068118],
            [-63.5578219, 46.2070473],
            [-63.5579301, 46.2074514],
            [-63.5578275, 46.2078272],
            [-63.5582931, 46.2081832],
            [-63.5584413, 46.2084609],
            [-63.5584411, 46.2088548],
            [-63.5586595, 46.2090981],
            [-63.558708, 46.2092981],
            [-63.5585716, 46.2096612],
            [-63.558535, 46.2099707],
            [-63.5588367, 46.2104068],
            [-63.5595521, 46.2109472],
            [-63.5599674, 46.2112743],
            [-63.5602987, 46.2115599],
            [-63.5610202, 46.2120924],
            [-63.5648345, 46.214489],
            [-63.5657062, 46.2148655],
            [-63.5661989, 46.2152619],
            [-63.5663567, 46.215321],
            [-63.5670897, 46.2158577],
            [-63.5675286, 46.2160421],
            [-63.568803, 46.2163873],
            [-63.5700679, 46.2165574],
            [-63.5724615, 46.216604],
            [-63.5730854, 46.2164939],
            [-63.5736823, 46.2162121],
            [-63.5738723, 46.2161922],
            [-63.5740111, 46.2161633],
            [-63.5748198, 46.2157587],
            [-63.5753707, 46.2157425],
            [-63.5760015, 46.2154733],
            [-63.5764084, 46.2154739],
            [-63.5777114, 46.2158197],
            [-63.5778277, 46.2157824],
            [-63.5794605, 46.2157969],
            [-63.5800105, 46.2161944],
            [-63.58004, 46.2164377],
            [-63.58021, 46.2166123],
            [-63.5803766, 46.2167352],
            [-63.5803722, 46.2169659],
            [-63.5803193, 46.2171279],
            [-63.5802811, 46.2173459],
            [-63.5806195, 46.217735],
            [-63.5810854, 46.2179596],
            [-63.581389, 46.217962],
            [-63.5816172, 46.2179867],
            [-63.5817187, 46.218156],
            [-63.5821387, 46.2179897],
            [-63.582477, 46.2179849],
            [-63.5831893, 46.2178169],
            [-63.5837255, 46.2178759],
            [-63.5836127, 46.2177024],
            [-63.583349, 46.2175736],
            [-63.5826618, 46.2176904],
            [-63.5820415, 46.2177211],
            [-63.5817977, 46.2176603],
            [-63.5814706, 46.2176693],
            [-63.5810706, 46.217641],
            [-63.5807869, 46.2174441],
            [-63.5806863, 46.2171237],
            [-63.5807739, 46.2166919],
            [-63.5806291, 46.216466],
            [-63.5802022, 46.2161348],
            [-63.5797659, 46.2156283],
            [-63.579791, 46.2153145],
            [-63.5801841, 46.2147141],
            [-63.5809432, 46.213998],
            [-63.5811696, 46.2139311],
            [-63.5816876, 46.2134885],
            [-63.5817734, 46.213359],
            [-63.5818689, 46.2131422],
            [-63.5819253, 46.2122443],
            [-63.5822454, 46.2118691],
            [-63.5819591, 46.2108127],
            [-63.5820979, 46.2106525],
            [-63.5823209, 46.2102713],
            [-63.582765, 46.2100738],
            [-63.5828595, 46.2097456],
            [-63.5832169, 46.2093035],
            [-63.5832837, 46.2088235],
            [-63.5835014, 46.2085616],
            [-63.5839629, 46.2083604],
            [-63.5844504, 46.2082195],
            [-63.5848407, 46.2079412],
            [-63.5851148, 46.2079629],
            [-63.5857906, 46.2082357],
            [-63.5862859, 46.208441],
            [-63.5866268, 46.2085079],
            [-63.5869486, 46.2084868],
            [-63.5871949, 46.2083567],
            [-63.5875758, 46.2084283],
            [-63.5876998, 46.2084747],
            [-63.588242, 46.2085259],
            [-63.5884198, 46.2086529],
            [-63.5885786, 46.2089546],
            [-63.5889169, 46.2090811],
            [-63.5893038, 46.2091449],
            [-63.5901704, 46.2095092],
            [-63.5907473, 46.2096844],
            [-63.5912114, 46.2098174],
            [-63.5919064, 46.2101781],
            [-63.5923228, 46.2102226],
            [-63.5928987, 46.2100238],
            [-63.59327, 46.2100515],
            [-63.5936986, 46.2102116],
            [-63.5945748, 46.2107511],
            [-63.5963438, 46.2113212],
            [-63.5972522, 46.2116504],
            [-63.5979775, 46.2118406],
            [-63.5985466, 46.2120634],
            [-63.5990299, 46.212153],
            [-63.6001055, 46.2120601],
            [-63.6003537, 46.2121528],
            [-63.6004839, 46.2124538],
            [-63.6003287, 46.2125978],
            [-63.6003367, 46.2129441],
            [-63.6005857, 46.2131482],
            [-63.6014639, 46.2136479],
            [-63.6035941, 46.2144839],
            [-63.6037824, 46.2146351],
            [-63.6037956, 46.2149934],
            [-63.6037514, 46.215088],
            [-63.6036928, 46.2159143],
            [-63.604277, 46.2165869],
            [-63.6043917, 46.2168518],
            [-63.6043892, 46.2170427],
            [-63.6042721, 46.2172313],
            [-63.6042306, 46.2175288],
            [-63.604334, 46.2176582],
            [-63.6055021, 46.2183469],
            [-63.6062259, 46.2187081],
            [-63.6066164, 46.2188236],
            [-63.607112, 46.2190289],
            [-63.6073272, 46.219089],
            [-63.6083912, 46.2195368],
            [-63.6086507, 46.219765],
            [-63.6088807, 46.2198812],
            [-63.6088262, 46.220083],
            [-63.6087126, 46.220192],
            [-63.608717, 46.2203552],
            [-63.6086754, 46.2203902],
            [-63.6080967, 46.2203861],
            [-63.6078242, 46.220456],
            [-63.6077453, 46.2205579],
            [-63.6086972, 46.2205498],
            [-63.6087727, 46.2205274],
            [-63.6090953, 46.2203551],
            [-63.6093953, 46.2199118],
            [-63.6099581, 46.2194858],
            [-63.6103623, 46.2192833],
            [-63.6105627, 46.2192875],
            [-63.6106963, 46.2192465],
            [-63.6111206, 46.2192433],
            [-63.6117956, 46.2191419],
            [-63.6121773, 46.2190623],
            [-63.61244, 46.2188171],
            [-63.612562, 46.218378],
            [-63.6129315, 46.2181828],
            [-63.6135952, 46.2180771],
            [-63.6138804, 46.2178403],
            [-63.6140294, 46.2174416],
            [-63.6145191, 46.216847],
            [-63.6146379, 46.21675],
            [-63.6150247, 46.2165511],
            [-63.6162686, 46.2161472],
            [-63.6164957, 46.2159291],
            [-63.616472, 46.2155466],
            [-63.6166054, 46.2153743],
            [-63.6178588, 46.2150143],
            [-63.6182551, 46.2148594],
            [-63.6184312, 46.2147636],
            [-63.6196526, 46.2146137],
            [-63.6200983, 46.2143762],
            [-63.620905, 46.2142735],
            [-63.6212878, 46.2144366],
            [-63.6215441, 46.2148755],
            [-63.6215843, 46.2152742],
            [-63.6214743, 46.215435],
            [-63.6211615, 46.2159139],
            [-63.6210334, 46.2162296],
            [-63.6209746, 46.2163994],
            [-63.6210408, 46.216727],
            [-63.6211172, 46.2168161],
            [-63.6215576, 46.2173742],
            [-63.6216505, 46.2174796],
            [-63.6224266, 46.2179611],
            [-63.6228285, 46.218212],
            [-63.6233217, 46.2186081],
            [-63.6248357, 46.2195265],
            [-63.6252899, 46.2198978],
            [-63.6262683, 46.220475],
            [-63.6271635, 46.221122],
            [-63.6278011, 46.2217637],
            [-63.6286867, 46.2223667],
            [-63.6291243, 46.2225905],
            [-63.6294984, 46.2226897],
            [-63.6301722, 46.2231531],
            [-63.6307605, 46.2230697],
            [-63.6310512, 46.2231075],
            [-63.633298, 46.2240566],
            [-63.6339037, 46.2241008],
            [-63.6348271, 46.2242232],
            [-63.6355467, 46.2244209],
            [-63.6365173, 46.2247829],
            [-63.6371266, 46.2248789],
            [-63.6374763, 46.2248781],
            [-63.6377488, 46.2249394],
            [-63.6384727, 46.2250377],
            [-63.6392614, 46.2249583],
            [-63.6395452, 46.2250238],
            [-63.6404382, 46.2250539],
            [-63.6407348, 46.2249526],
            [-63.6416069, 46.2249345],
            [-63.6418331, 46.2247363],
            [-63.6418371, 46.2245056],
            [-63.642116, 46.2241452],
            [-63.6421061, 46.2238387],
            [-63.642643, 46.2230698],
            [-63.6426954, 46.2226452],
            [-63.6429805, 46.2224083],
            [-63.6435123, 46.2223038],
            [-63.6438305, 46.2220994],
            [-63.6440984, 46.2219974],
            [-63.6444828, 46.2219893],
            [-63.6451826, 46.2222502],
            [-63.6454664, 46.2223157],
            [-63.6460303, 46.2222635],
            [-63.646249, 46.2222441],
            [-63.6473168, 46.2220669],
            [-63.6473608, 46.221841],
            [-63.6475853, 46.2216824],
            [-63.6476971, 46.2216131],
            [-63.6493778, 46.2215836],
            [-63.6499122, 46.2215507],
            [-63.6504924, 46.2213834],
            [-63.650997, 46.2211072],
            [-63.6510879, 46.2209897],
            [-63.6511316, 46.2206325],
            [-63.6512459, 46.2205035],
            [-63.6512936, 46.2204607],
            [-63.6517576, 46.2203309],
            [-63.6518452, 46.2202929],
            [-63.6519996, 46.2197549],
            [-63.652213, 46.2197235],
            [-63.6525527, 46.2199611],
            [-63.6527236, 46.2199845],
            [-63.6528935, 46.2198964],
            [-63.653253, 46.2200708],
            [-63.6534595, 46.220067],
            [-63.6537363, 46.2200288],
            [-63.6538629, 46.2200155],
            [-63.6540592, 46.2201189],
            [-63.6541367, 46.2203194],
            [-63.6541344, 46.2205103],
            [-63.6543403, 46.2206577],
            [-63.6543142, 46.2211426],
            [-63.6542172, 46.2212679],
            [-63.6539994, 46.2212675],
            [-63.6533122, 46.2212536],
            [-63.6530815, 46.2212887],
            [-63.6526549, 46.2214829],
            [-63.6513889, 46.2225553],
            [-63.650901, 46.2235242],
            [-63.6506953, 46.2240531],
            [-63.6506907, 46.224435],
            [-63.6504494, 46.224991],
            [-63.6503719, 46.2254669],
            [-63.6502103, 46.2259013],
            [-63.6501077, 46.2273872],
            [-63.6500517, 46.2281738],
            [-63.6501491, 46.228311],
            [-63.6502442, 46.2286392],
            [-63.6504766, 46.2291094],
            [-63.6505015, 46.229472],
            [-63.6504512, 46.2295744],
            [-63.6505329, 46.2296755],
            [-63.6505522, 46.2308536],
            [-63.6502761, 46.2314169],
            [-63.6498626, 46.2317069],
            [-63.6496035, 46.2320041],
            [-63.6493541, 46.2334353],
            [-63.6493195, 46.234664],
            [-63.6492097, 46.2349562],
            [-63.6484104, 46.2350116],
            [-63.6482541, 46.2349129],
            [-63.6482484, 46.2346383],
            [-63.6484969, 46.2341858],
            [-63.6483781, 46.2337378],
            [-63.6482069, 46.2335831],
            [-63.647904, 46.233561],
            [-63.6475992, 46.2334474],
            [-63.6475538, 46.2332993],
            [-63.6476033, 46.232803],
            [-63.6474564, 46.232617],
            [-63.6471635, 46.2323564],
            [-63.6463239, 46.2321443],
            [-63.6461814, 46.2319903],
            [-63.6461609, 46.2316596],
            [-63.6463186, 46.2314559],
            [-63.6464155, 46.2311993],
            [-63.6462591, 46.2311006],
            [-63.6460699, 46.2311007],
            [-63.645885, 46.2310015],
            [-63.6457513, 46.2310425],
            [-63.6454392, 46.2312391],
            [-63.6449685, 46.2316592],
            [-63.6449228, 46.2319249],
            [-63.6446726, 46.2322858],
            [-63.6446748, 46.23264],
            [-63.6445518, 46.2328364],
            [-63.6441999, 46.2331595],
            [-63.6441126, 46.2333288],
            [-63.6440772, 46.2334872],
            [-63.6435789, 46.2340182],
            [-63.6433452, 46.2345267],
            [-63.6429013, 46.2348558],
            [-63.6427898, 46.2351878],
            [-63.6425575, 46.2353939],
            [-63.6423727, 46.2361023],
            [-63.6420703, 46.2364741],
            [-63.6420273, 46.2368115],
            [-63.6418341, 46.2370423],
            [-63.6416696, 46.237405],
            [-63.6417519, 46.2379],
            [-63.6418739, 46.2382685],
            [-63.6418487, 46.2388649],
            [-63.6418992, 46.239025],
            [-63.641854, 46.2395533],
            [-63.6416474, 46.2402334],
            [-63.6412713, 46.2407192],
            [-63.6398567, 46.2423017],
            [-63.6397859, 46.2426186],
            [-63.639506, 46.2429988],
            [-63.6395021, 46.2433608],
            [-63.6394027, 46.2436771],
            [-63.6394758, 46.2437455],
            [-63.6395461, 46.2438114],
            [-63.6396702, 46.2437264],
            [-63.6401383, 46.2425582],
            [-63.640601, 46.241923],
            [-63.6408288, 46.2415536],
            [-63.6418524, 46.2409459],
            [-63.6421841, 46.2404234],
            [-63.6426637, 46.2400671],
            [-63.6428185, 46.2396605],
            [-63.6430008, 46.2390117],
            [-63.6431108, 46.2388508],
            [-63.6431945, 46.2384984],
            [-63.6431909, 46.2384466],
            [-63.6429098, 46.2379077],
            [-63.642816, 46.2378222],
            [-63.6427929, 46.2374199],
            [-63.6429815, 46.2370259],
            [-63.6433791, 46.2364371],
            [-63.6436042, 46.2359961],
            [-63.6440912, 46.2354609],
            [-63.6442696, 46.2351741],
            [-63.6446598, 46.2348956],
            [-63.6449772, 46.2347111],
            [-63.6453336, 46.2344199],
            [-63.6455366, 46.2343644],
            [-63.6460296, 46.2343664],
            [-63.6464395, 46.2345697],
            [-63.6469082, 46.2351482],
            [-63.6471455, 46.2353679],
            [-63.6478247, 46.2357119],
            [-63.6485931, 46.2358469],
            [-63.6489646, 46.2358743],
            [-63.6496303, 46.23586],
            [-63.6501474, 46.2356994],
            [-63.6507795, 46.2352586],
            [-63.6512679, 46.2350974],
            [-63.651971, 46.2347211],
            [-63.6521272, 46.2345698],
            [-63.6522476, 46.2345015],
            [-63.6524036, 46.2344149],
            [-63.652577, 46.2344458],
            [-63.65305, 46.2348579],
            [-63.6535991, 46.2350174],
            [-63.6537971, 46.2350811],
            [-63.6541182, 46.2350796],
            [-63.6544113, 46.2349264],
            [-63.6547151, 46.2349285],
            [-63.6549662, 46.2350928],
            [-63.655959, 46.236028],
            [-63.6563971, 46.236383],
            [-63.6568979, 46.2368825],
            [-63.6575436, 46.2373451],
            [-63.6581017, 46.2378458],
            [-63.6585508, 46.2380737],
            [-63.6594546, 46.2386728],
            [-63.6636736, 46.2407592],
            [-63.6641316, 46.2410509],
            [-63.6641985, 46.2410961],
            [-63.6650907, 46.2414283],
            [-63.6658363, 46.2418372],
            [-63.6666305, 46.2427285],
            [-63.6671786, 46.2433364],
            [-63.6683368, 46.2443026],
            [-63.669388, 46.2450877],
            [-63.6702144, 46.2454862],
            [-63.6703213, 46.2455361],
            [-63.6716998, 46.245898],
            [-63.6718058, 46.2459678],
            [-63.6721805, 46.2461981],
            [-63.672383, 46.2462937],
            [-63.6737433, 46.246679],
            [-63.6744979, 46.2471517],
            [-63.6748803, 46.2473344],
            [-63.6751489, 46.247495],
            [-63.6759556, 46.2479567],
            [-63.6764628, 46.2480345],
            [-63.6769286, 46.2478648],
            [-63.6771926, 46.2478621],
            [-63.6774681, 46.2479949],
            [-63.6775066, 46.2481708],
            [-63.6774064, 46.2483757],
            [-63.6770805, 46.2486278],
            [-63.6770794, 46.2489302],
            [-63.6769201, 46.2491737],
            [-63.6769414, 46.2493532],
            [-63.6771218, 46.2492891],
            [-63.6774139, 46.2490244],
            [-63.6774709, 46.2488942],
            [-63.6774279, 46.2486865],
            [-63.677524, 46.248581],
            [-63.677761, 46.2485379],
            [-63.6779403, 46.2483625],
            [-63.6779425, 46.2481715],
            [-63.6777124, 46.247773],
            [-63.6773482, 46.2475668],
            [-63.6769011, 46.2475619],
            [-63.6765826, 46.2476351],
            [-63.6761423, 46.2476024],
            [-63.6758139, 46.2475003],
            [-63.6751348, 46.2470053],
            [-63.6750808, 46.2469246],
            [-63.6751211, 46.2466469],
            [-63.6754894, 46.24634],
            [-63.6758227, 46.2463228],
            [-63.676452, 46.2466377],
            [-63.6767385, 46.2466435],
            [-63.6768443, 46.2465819],
            [-63.6768211, 46.2458971],
            [-63.6771046, 46.2456998],
            [-63.6783167, 46.2453739],
            [-63.6784599, 46.2453768],
            [-63.6787742, 46.2449891],
            [-63.6785541, 46.2443521],
            [-63.6784854, 46.243933],
            [-63.6787347, 46.2435918],
            [-63.6792248, 46.2433907],
            [-63.6793528, 46.2432063],
            [-63.6787903, 46.2428051],
            [-63.6787897, 46.2425425],
            [-63.6788217, 46.2424635],
            [-63.6787335, 46.242239],
            [-63.6786825, 46.2419475],
            [-63.6790681, 46.2416369],
            [-63.679059, 46.2414418],
            [-63.6791303, 46.241105],
            [-63.6790331, 46.2406853],
            [-63.6790724, 46.2404274],
            [-63.6793575, 46.2401904],
            [-63.6798376, 46.2402279],
            [-63.6804274, 46.2403869],
            [-63.6809488, 46.2406718],
            [-63.6815455, 46.2408031],
            [-63.6824502, 46.2412508],
            [-63.6828686, 46.2412552],
            [-63.6831515, 46.2412091],
            [-63.6834953, 46.2412159],
            [-63.6837909, 46.2414168],
            [-63.6839904, 46.2417231],
            [-63.6841373, 46.2421916],
            [-63.6844707, 46.2425881],
            [-63.6848534, 46.2429021],
            [-63.6865875, 46.24493],
            [-63.6872162, 46.2453961],
            [-63.6882247, 46.2459732],
            [-63.6885788, 46.2462866],
            [-63.6888348, 46.2466139],
            [-63.6894455, 46.2471035],
            [-63.6900809, 46.2474106],
            [-63.6909069, 46.2475463],
            [-63.6915186, 46.2477335],
            [-63.692048, 46.2483846],
            [-63.693518, 46.2494562],
            [-63.694539, 46.25],
            [-63.6950266, 46.2502898],
            [-63.6955657, 46.2502886],
            [-63.6958611, 46.2503581],
            [-63.6967226, 46.2507491],
            [-63.6982588, 46.2511893],
            [-63.6995462, 46.2514057],
            [-63.7012457, 46.2515029],
            [-63.7019798, 46.2511827],
            [-63.7018312, 46.2508987],
            [-63.7018477, 46.2508944],
            [-63.7019349, 46.250873],
            [-63.7019643, 46.2508659],
            [-63.7022402, 46.2513077],
            [-63.7033663, 46.2509993],
            [-63.703642, 46.2507511],
            [-63.7035509, 46.2504805],
            [-63.7033179, 46.2499728],
            [-63.7030246, 46.2493935],
            [-63.7024566, 46.2487407],
            [-63.7023391, 46.2483466],
            [-63.7020572, 46.248072],
            [-63.7011017, 46.2474994],
            [-63.7009462, 46.2472784],
            [-63.7007221, 46.2471143],
            [-63.7007242, 46.2470528],
            [-63.7007858, 46.247017],
            [-63.7008934, 46.2470231],
            [-63.7011142, 46.2471065],
            [-63.7013117, 46.2472443],
            [-63.701377, 46.247201],
            [-63.701158, 46.2470469],
            [-63.7009958, 46.2468615],
            [-63.7002093, 46.246307],
            [-63.7004473, 46.24616],
            [-63.7003391, 46.2460946],
            [-63.7004253, 46.2460374],
            [-63.700463, 46.2460384],
            [-63.7007167, 46.2461349],
            [-63.7010175, 46.2463415],
            [-63.7010972, 46.2462849],
            [-63.7007927, 46.246068],
            [-63.7007406, 46.2459782],
            [-63.7004669, 46.2457668],
            [-63.699982, 46.2454345],
            [-63.7000809, 46.2453428],
            [-63.701068, 46.2459895],
            [-63.7016152, 46.2464201],
            [-63.7033064, 46.2478193],
            [-63.7036522, 46.2482001],
            [-63.7046462, 46.2494174],
            [-63.705061, 46.2496523],
            [-63.7054476, 46.2497355],
            [-63.7059565, 46.2497734],
            [-63.7062231, 46.2498423],
            [-63.706411, 46.25],
            [-63.7055853, 46.2508563],
            [-63.7052917, 46.2514434],
            [-63.7046102, 46.2521183],
            [-63.7044114, 46.2527709],
            [-63.7042167, 46.2530416],
            [-63.7037909, 46.2532163],
            [-63.7030016, 46.2538612],
            [-63.702255, 46.2548651],
            [-63.7017835, 46.256153],
            [-63.7014098, 46.257013],
            [-63.701318, 46.257433],
            [-63.7008068, 46.2578686],
            [-63.7001254, 46.2579785],
            [-63.6995054, 46.2579901],
            [-63.6989943, 46.2581432],
            [-63.6985854, 46.2581828],
            [-63.6981733, 46.2580195],
            [-63.6965085, 46.2580117],
            [-63.6961884, 46.2581031],
            [-63.6951075, 46.258477],
            [-63.6942696, 46.2586822],
            [-63.693648, 46.258698],
            [-63.693566, 46.2588794],
            [-63.693331, 46.259014],
            [-63.6925657, 46.2589312],
            [-63.6924462, 46.2590482],
            [-63.6922213, 46.2590756],
            [-63.691693, 46.2589497],
            [-63.6909313, 46.2589187],
            [-63.6907331, 46.2588551],
            [-63.6889443, 46.258553],
            [-63.6884676, 46.2585674],
            [-63.6881764, 46.2588123],
            [-63.6880847, 46.2589497],
            [-63.6882764, 46.2588899],
            [-63.6885288, 46.2587516],
            [-63.6888978, 46.2587072],
            [-63.6896681, 46.2590846],
            [-63.6907405, 46.2593725],
            [-63.6911748, 46.2594129],
            [-63.692123, 46.259372],
            [-63.6926501, 46.259669],
            [-63.6929512, 46.2595993],
            [-63.6931794, 46.2594924],
            [-63.6943159, 46.2590574],
            [-63.6957613, 46.2587248],
            [-63.6959201, 46.2583308],
            [-63.696972, 46.2582186],
            [-63.6988174, 46.2585415],
            [-63.6992207, 46.2583585],
            [-63.6994274, 46.2583546],
            [-63.6999451, 46.2587389],
            [-63.7002299, 46.2590668],
            [-63.7009874, 46.2594797],
            [-63.7012392, 46.2597751],
            [-63.7012473, 46.2598588],
            [-63.7013885, 46.2600526],
            [-63.7013948, 46.2604586],
            [-63.701255, 46.2607901],
            [-63.7011627, 46.2613612],
            [-63.7012283, 46.2618599],
            [-63.7017523, 46.2632152],
            [-63.7019166, 46.264245],
            [-63.702341, 46.265089],
            [-63.7024314, 46.2654051],
            [-63.7023745, 46.2662317],
            [-63.7021264, 46.2669669],
            [-63.7020794, 46.2675549],
            [-63.7021617, 46.2677873],
            [-63.7021663, 46.2685156],
            [-63.7020418, 46.2687519],
            [-63.7020499, 46.2688356],
            [-63.7018285, 46.2691974],
            [-63.7014888, 46.269505],
            [-63.7006731, 46.2698073],
            [-63.700289, 46.2700782],
            [-63.6996481, 46.2704556],
            [-63.6994291, 46.2707576],
            [-63.6992611, 46.2709374],
            [-63.6988936, 46.2709421],
            [-63.6986089, 46.271028],
            [-63.6983586, 46.2709753],
            [-63.6979008, 46.2709463],
            [-63.6977376, 46.2710068],
            [-63.6971671, 46.2710671],
            [-63.6968815, 46.2711729],
            [-63.696462, 46.2711885],
            [-63.6963559, 46.2711187],
            [-63.6963495, 46.2709952],
            [-63.6966815, 46.2707352],
            [-63.6967837, 46.2706218],
            [-63.6966828, 46.2705641],
            [-63.6965394, 46.2705613],
            [-63.696004, 46.2707457],
            [-63.6956252, 46.2707462],
            [-63.6954408, 46.2706271],
            [-63.6953374, 46.270629],
            [-63.6952178, 46.2707461],
            [-63.6951644, 46.270928],
            [-63.6952757, 46.2712924],
            [-63.6950215, 46.2717529],
            [-63.6943517, 46.2724121],
            [-63.6942234, 46.2728792],
            [-63.6937334, 46.2732117],
            [-63.693775, 46.2738731],
            [-63.6936583, 46.2740618],
            [-63.6932613, 46.2740857],
            [-63.6932104, 46.2742081],
            [-63.6934395, 46.2744952],
            [-63.6935408, 46.2746842],
            [-63.6935438, 46.2748872],
            [-63.693604, 46.27496],
            [-63.6938782, 46.27485],
            [-63.6942611, 46.2747502],
            [-63.6949213, 46.2747433],
            [-63.6949646, 46.2746686],
            [-63.6948346, 46.274479],
            [-63.6947691, 46.2739803],
            [-63.694928, 46.2736054],
            [-63.6952419, 46.2733689],
            [-63.6954947, 46.2733619],
            [-63.6957809, 46.2735188],
            [-63.6961362, 46.2735298],
            [-63.6962837, 46.2734332],
            [-63.6964745, 46.2729794],
            [-63.6970993, 46.2727172],
            [-63.6971381, 46.2726105],
            [-63.6970472, 46.2724456],
            [-63.6966566, 46.2721792],
            [-63.6967422, 46.2720496],
            [-63.697035, 46.2720474],
            [-63.6971827, 46.2720821],
            [-63.697902, 46.2724505],
            [-63.6996761, 46.2731302],
            [-63.6999316, 46.2731949],
            [-63.7003756, 46.2735618],
            [-63.700529, 46.2737399],
            [-63.7010574, 46.2741483],
            [-63.7011945, 46.2744415],
            [-63.7012501, 46.2747649],
            [-63.7014491, 46.2750911],
            [-63.7019287, 46.2754309],
            [-63.7022637, 46.2756563],
            [-63.7029379, 46.2762904],
            [-63.7035588, 46.2768239],
            [-63.7062342, 46.2784842],
            [-63.7073927, 46.2789248],
            [-63.7078461, 46.2789218],
            [-63.7084173, 46.2787101],
            [-63.7087917, 46.2786777],
            [-63.7089918, 46.2788328],
            [-63.7089316, 46.2790426],
            [-63.7090067, 46.2791714],
            [-63.7093455, 46.2791661],
            [-63.7099586, 46.2790508],
            [-63.7101953, 46.2788763],
            [-63.7112195, 46.2788129],
            [-63.7120901, 46.2788538],
            [-63.7123344, 46.2789143],
            [-63.713166, 46.2789306],
            [-63.7134159, 46.2788519],
            [-63.713748, 46.2785918],
            [-63.713895, 46.2783639],
            [-63.7143877, 46.2781029],
            [-63.7155116, 46.2779856],
            [-63.7159045, 46.2777784],
            [-63.71624, 46.2772876],
            [-63.7165272, 46.277142],
            [-63.7172565, 46.2772716],
            [-63.7179523, 46.2772375],
            [-63.7190886, 46.2769533],
            [-63.7192324, 46.2770874],
            [-63.7193176, 46.2773915],
            [-63.7196586, 46.2777602],
            [-63.720478, 46.2783572],
            [-63.7209137, 46.2787915],
            [-63.7222946, 46.2799804],
            [-63.7227296, 46.2804345],
            [-63.7232995, 46.280959],
            [-63.7235285, 46.2813972],
            [-63.7241631, 46.2823089],
            [-63.724587, 46.2833239],
            [-63.7246811, 46.2836919],
            [-63.7251599, 46.2847677],
            [-63.7254011, 46.2851902],
            [-63.7260384, 46.2868899],
            [-63.7263684, 46.2873858],
            [-63.726511, 46.287691],
            [-63.7266051, 46.2877764],
            [-63.7265967, 46.2878439],
            [-63.7273495, 46.2892434],
            [-63.7274978, 46.2894094],
            [-63.7275644, 46.2896057],
            [-63.727785, 46.2898289],
            [-63.7285015, 46.2909928],
            [-63.7300286, 46.2928411],
            [-63.7312315, 46.2939031],
            [-63.7329573, 46.2950986],
            [-63.7343958, 46.2958746],
            [-63.7355571, 46.2964064],
            [-63.7362594, 46.2966469],
            [-63.7377103, 46.2969734],
            [-63.7388092, 46.2970582],
            [-63.7391627, 46.2969775],
            [-63.7402915, 46.2961755],
            [-63.740396, 46.2960024],
            [-63.7408662, 46.2957329],
            [-63.7415791, 46.2955636],
            [-63.743232, 46.2957228],
            [-63.7438461, 46.2958699],
            [-63.7443902, 46.296334],
            [-63.7445851, 46.2966283],
            [-63.7453778, 46.2984861],
            [-63.7457882, 46.2989914],
            [-63.7461431, 46.2993047],
            [-63.7460807, 46.2995741],
            [-63.7459422, 46.2997346],
            [-63.7460249, 46.3002496],
            [-63.7458425, 46.3005048],
            [-63.7459079, 46.3010234],
            [-63.7461301, 46.3013528],
            [-63.7466071, 46.3020596],
            [-63.7470413, 46.3028058],
            [-63.7470638, 46.3028444],
            [-63.7472846, 46.3030675],
            [-63.7474699, 46.3032355],
            [-63.7480381, 46.3037506],
            [-63.7484263, 46.3040963],
            [-63.7485832, 46.3041948],
            [-63.748985, 46.3044851],
            [-63.7494428, 46.3050988],
            [-63.7498434, 46.3052776],
            [-63.7499324, 46.305351],
            [-63.75, 46.3053974],
            [-63.7507316, 46.3058996],
            [-63.7523696, 46.3065876],
            [-63.7537672, 46.3069646],
            [-63.7548317, 46.3070566],
            [-63.7551194, 46.3070422],
            [-63.7554442, 46.3069608],
            [-63.7559023, 46.3067069],
            [-63.7559262, 46.306393],
            [-63.7556223, 46.3058061],
            [-63.7559671, 46.3055102],
            [-63.7569634, 46.3052945],
            [-63.7574617, 46.3053279],
            [-63.7585948, 46.3058589],
            [-63.7593252, 46.3061196],
            [-63.7599067, 46.3060829],
            [-63.7602086, 46.3061444],
            [-63.7607503, 46.3063855],
            [-63.7612757, 46.3064592],
            [-63.7619288, 46.3064995],
            [-63.7622128, 46.3064333],
            [-63.7627179, 46.3064389],
            [-63.7632941, 46.306824],
            [-63.7640209, 46.3070328],
            [-63.7645082, 46.3073445],
            [-63.7648331, 46.3076969],
            [-63.7654888, 46.3079601],
            [-63.7661456, 46.3080522],
            [-63.7677609, 46.308449],
            [-63.7681989, 46.3084096],
            [-63.7687882, 46.308174],
            [-63.7694444, 46.3081347],
            [-63.7700665, 46.3082341],
            [-63.7709434, 46.3085691],
            [-63.7717755, 46.3085848],
            [-63.7723866, 46.3083775],
            [-63.7729666, 46.3083805],
            [-63.7735402, 46.3085426],
            [-63.7739411, 46.3085701],
            [-63.7742618, 46.3087393],
            [-63.774981, 46.3089957],
            [-63.7755598, 46.309321],
            [-63.7756909, 46.3094906],
            [-63.775858, 46.3099156],
            [-63.7755749, 46.3103958],
            [-63.775521, 46.3105978],
            [-63.7759611, 46.3112349],
            [-63.7764462, 46.3116062],
            [-63.7764569, 46.3120641],
            [-63.7768116, 46.312831],
            [-63.7773905, 46.3135901],
            [-63.7792978, 46.3150429],
            [-63.7798622, 46.3154435],
            [-63.7806733, 46.3159961],
            [-63.7843572, 46.3179957],
            [-63.7864205, 46.3186991],
            [-63.7865723, 46.3187855],
            [-63.7871016, 46.3189108],
            [-63.7884168, 46.3193574],
            [-63.7899535, 46.3195732],
            [-63.7914238, 46.3198754],
            [-63.7936962, 46.3200811],
            [-63.7950149, 46.3201455],
            [-63.7961178, 46.3199989],
            [-63.7978008, 46.319704],
            [-63.7990834, 46.319513],
            [-63.7995428, 46.3193703],
            [-63.799826, 46.3193238],
            [-63.800537, 46.3190624],
            [-63.8011551, 46.318676],
            [-63.8013902, 46.3183898],
            [-63.8014833, 46.3180612],
            [-63.8014278, 46.3175667],
            [-63.8008165, 46.3170379],
            [-63.8003924, 46.3165684],
            [-63.8007287, 46.3163398],
            [-63.8009441, 46.316117],
            [-63.8016089, 46.3158587],
            [-63.8022395, 46.3158903],
            [-63.8030861, 46.3158305],
            [-63.8041993, 46.3158591],
            [-63.8048134, 46.3157233],
            [-63.8065024, 46.3151178],
            [-63.8075136, 46.31526],
            [-63.8079516, 46.3152203],
            [-63.8084956, 46.3148165],
            [-63.8086951, 46.3147088],
            [-63.8089768, 46.3147021],
            [-63.8104822, 46.3154304],
            [-63.8110129, 46.3158183],
            [-63.8113027, 46.3161977],
            [-63.8114973, 46.316663],
            [-63.811747, 46.317189],
            [-63.8123795, 46.3179171],
            [-63.812377, 46.3182792],
            [-63.8125137, 46.3183096],
            [-63.8127282, 46.318409],
            [-63.8127644, 46.3185132],
            [-63.8127332, 46.3188748],
            [-63.8135282, 46.3194108],
            [-63.8137252, 46.3196652],
            [-63.8137847, 46.3200603],
            [-63.8142776, 46.3206863],
            [-63.8144253, 46.3213258],
            [-63.8147205, 46.3217173],
            [-63.8148411, 46.3221653],
            [-63.8150704, 46.3227745],
            [-63.8151799, 46.3229158],
            [-63.8152181, 46.3237165],
            [-63.8156045, 46.3244241],
            [-63.815675, 46.3254283],
            [-63.8158419, 46.3255707],
            [-63.8158803, 46.3257664],
            [-63.8160752, 46.3259292],
            [-63.815989, 46.3260789],
            [-63.8159738, 46.3263253],
            [-63.816312, 46.326642],
            [-63.8163709, 46.3267546],
            [-63.8162477, 46.3269712],
            [-63.816181, 46.32736],
            [-63.8160027, 46.3275159],
            [-63.8155426, 46.3276786],
            [-63.8154708, 46.3277529],
            [-63.8154191, 46.3283489],
            [-63.8156969, 46.3287441],
            [-63.8157783, 46.3290858],
            [-63.8166663, 46.3302823],
            [-63.8169274, 46.3309637],
            [-63.8168825, 46.3316833],
            [-63.816788, 46.3317492],
            [-63.8164563, 46.3318585],
            [-63.815839, 46.3319228],
            [-63.8151817, 46.3321335],
            [-63.8147184, 46.3323757],
            [-63.8144796, 46.3326101],
            [-63.8143845, 46.333886],
            [-63.8142786, 46.334099],
            [-63.8146191, 46.3346585],
            [-63.8147242, 46.3361092],
            [-63.8153505, 46.3379039],
            [-63.8154144, 46.3384822],
            [-63.815273, 46.3387224],
            [-63.8151295, 46.3387197],
            [-63.8147842, 46.338582],
            [-63.8141609, 46.3385028],
            [-63.8131869, 46.3385764],
            [-63.8126162, 46.3389201],
            [-63.8118167, 46.3390884],
            [-63.8112582, 46.3392651],
            [-63.810853, 46.3394885],
            [-63.8100549, 46.3399195],
            [-63.8099679, 46.3400891],
            [-63.8097191, 46.3402795],
            [-63.8086075, 46.3404937],
            [-63.8076483, 46.3409257],
            [-63.8072855, 46.3410941],
            [-63.8065728, 46.3412441],
            [-63.8058436, 46.3412266],
            [-63.8056411, 46.3412627],
            [-63.8051255, 46.3415158],
            [-63.8043592, 46.3415653],
            [-63.8030892, 46.341267],
            [-63.8017253, 46.3411661],
            [-63.8005722, 46.3411128],
            [-63.8002306, 46.341178],
            [-63.7998346, 46.341314],
            [-63.7988305, 46.3420117],
            [-63.7980295, 46.3428048],
            [-63.7973074, 46.3433446],
            [-63.7966678, 46.3436829],
            [-63.7963488, 46.3437566],
            [-63.7961011, 46.3436246],
            [-63.7959871, 46.3434513],
            [-63.7959874, 46.3431488],
            [-63.7961946, 46.3429935],
            [-63.7969601, 46.342964],
            [-63.797196, 46.3428092],
            [-63.7974494, 46.3424994],
            [-63.7978433, 46.3422719],
            [-63.7981555, 46.342226],
            [-63.7986356, 46.3418489],
            [-63.7988459, 46.341614],
            [-63.7998145, 46.3409434],
            [-63.8000283, 46.3409116],
            [-63.8002279, 46.3408039],
            [-63.8000097, 46.3406525],
            [-63.799616, 46.3405775],
            [-63.7988371, 46.34036],
            [-63.7978591, 46.3397965],
            [-63.797436, 46.3397409],
            [-63.7969896, 46.3395455],
            [-63.7966522, 46.3390576],
            [-63.7963502, 46.338845],
            [-63.7958282, 46.338672],
            [-63.7957279, 46.3384433],
            [-63.7953827, 46.3383055],
            [-63.7948157, 46.3378133],
            [-63.7942551, 46.3377471],
            [-63.7938352, 46.3376119],
            [-63.7933927, 46.337317],
            [-63.7933082, 46.3371244],
            [-63.7933056, 46.3367502],
            [-63.7930876, 46.3362964],
            [-63.7931121, 46.3359625],
            [-63.7930746, 46.3357469],
            [-63.7927375, 46.3358441],
            [-63.7925643, 46.336012],
            [-63.7924084, 46.3363275],
            [-63.7920362, 46.3367345],
            [-63.7920524, 46.3372044],
            [-63.7916164, 46.3371843],
            [-63.791478, 46.3373449],
            [-63.7908961, 46.3373818],
            [-63.7908046, 46.3375194],
            [-63.7906368, 46.3375481],
            [-63.7901701, 46.3372846],
            [-63.7899774, 46.3373646],
            [-63.7892656, 46.3373433],
            [-63.7891197, 46.3374003],
            [-63.7886641, 46.3374435],
            [-63.788327, 46.3375407],
            [-63.7880079, 46.3377655],
            [-63.787904, 46.3380701],
            [-63.787724, 46.3382657],
            [-63.7874859, 46.3383289],
            [-63.7872736, 46.3383209],
            [-63.7870554, 46.3381696],
            [-63.7867085, 46.3382228],
            [-63.7860802, 46.3385652],
            [-63.7852921, 46.3385863],
            [-63.7852149, 46.3386485],
            [-63.7852253, 46.3388238],
            [-63.7849962, 46.3389509],
            [-63.78479, 46.338935],
            [-63.7846722, 46.3388612],
            [-63.7841935, 46.3391985],
            [-63.7840884, 46.3392403],
            [-63.7838429, 46.3391998],
            [-63.7837696, 46.3391627],
            [-63.783484, 46.3391175],
            [-63.782942, 46.3393103],
            [-63.7827426, 46.3392667],
            [-63.7823301, 46.3392351],
            [-63.781739, 46.3393593],
            [-63.7811429, 46.3393202],
            [-63.780386, 46.3391308],
            [-63.779307, 46.3390946],
            [-63.778529, 46.338857],
            [-63.7783019, 46.3386418],
            [-63.7781239, 46.3383439],
            [-63.7778657, 46.3381878],
            [-63.7777274, 46.3381971],
            [-63.7776774, 46.3382997],
            [-63.7779001, 46.338483],
            [-63.7780222, 46.33874],
            [-63.7777362, 46.3389973],
            [-63.7778591, 46.3392345],
            [-63.7778581, 46.3394056],
            [-63.7774084, 46.3394409],
            [-63.7770129, 46.3397081],
            [-63.7770141, 46.3399708],
            [-63.7773969, 46.3401731],
            [-63.7773553, 46.3402081],
            [-63.776864, 46.3402784],
            [-63.7764308, 46.3404812],
            [-63.7756637, 46.3405503],
            [-63.7752242, 46.3403271],
            [-63.7747361, 46.3403179],
            [-63.774305, 46.3400271],
            [-63.7740224, 46.3400536],
            [-63.7738818, 46.3401226],
            [-63.7741716, 46.340351],
            [-63.7741735, 46.3405938],
            [-63.774314, 46.3406761],
            [-63.7744991, 46.3406438],
            [-63.7745128, 46.3405883],
            [-63.7747425, 46.3405926],
            [-63.775066, 46.340551],
            [-63.7755484, 46.3408507],
            [-63.7758491, 46.3409519],
            [-63.7768292, 46.3408709],
            [-63.7773224, 46.3410434],
            [-63.7779292, 46.3411065],
            [-63.7782079, 46.3411795],
            [-63.7784954, 46.3414675],
            [-63.7785429, 46.3415758],
            [-63.7786878, 46.34169],
            [-63.7793211, 46.3416622],
            [-63.7797182, 46.3419403],
            [-63.7795637, 46.342216],
            [-63.7796649, 46.3422736],
            [-63.779672, 46.3426798],
            [-63.7798567, 46.3429499],
            [-63.780007, 46.3430762],
            [-63.7800589, 46.3432164],
            [-63.7799243, 46.3432776],
            [-63.7796712, 46.3432848],
            [-63.7794424, 46.3431093],
            [-63.7792369, 46.3430736],
            [-63.7791842, 46.3429532],
            [-63.7792313, 46.3427789],
            [-63.7790961, 46.3427087],
            [-63.7788013, 46.3427509],
            [-63.7786841, 46.3428084],
            [-63.7786855, 46.3429199],
            [-63.7793807, 46.3435102],
            [-63.7801713, 46.3440146],
            [-63.7804034, 46.3439593],
            [-63.7804926, 46.3438814],
            [-63.7807677, 46.3439025],
            [-63.7809136, 46.3438455],
            [-63.7811795, 46.343954],
            [-63.7812943, 46.3439562],
            [-63.7813828, 46.3438981],
            [-63.7814019, 46.3437034],
            [-63.7820192, 46.3432056],
            [-63.7825656, 46.3431959],
            [-63.7829401, 46.3434657],
            [-63.7831482, 46.3438756],
            [-63.7834812, 46.3440291],
            [-63.7838567, 46.3441277],
            [-63.7840191, 46.3442383],
            [-63.784024, 46.3445528],
            [-63.7842121, 46.3445921],
            [-63.7844592, 46.3445928],
            [-63.784957, 46.3447972],
            [-63.785359, 46.3448047],
            [-63.7857038, 46.34466],
            [-63.7860295, 46.3447099],
            [-63.7861979, 46.3448125],
            [-63.7866081, 46.3449038],
            [-63.7878685, 46.3450071],
            [-63.7879545, 46.3451599],
            [-63.7878614, 46.3453373],
            [-63.7878665, 46.3455006],
            [-63.7881996, 46.3456541],
            [-63.7881314, 46.3457802],
            [-63.7882137, 46.3458812],
            [-63.7882118, 46.3462235],
            [-63.7882677, 46.3462644],
            [-63.7883557, 46.3466601],
            [-63.7882779, 46.3473273],
            [-63.787883, 46.3477259],
            [-63.7877906, 46.3478834],
            [-63.7879005, 46.3483074],
            [-63.7877475, 46.3485434],
            [-63.7876008, 46.3486202],
            [-63.7870839, 46.3486105],
            [-63.786854, 46.3487575],
            [-63.7868485, 46.3488967],
            [-63.7873478, 46.3492125],
            [-63.7874791, 46.3493822],
            [-63.7875337, 46.3498967],
            [-63.7876371, 46.3500459],
            [-63.7877776, 46.3501281],
            [-63.7880224, 46.3501884],
            [-63.788267, 46.3504],
            [-63.788509, 46.3509738],
            [-63.7888912, 46.3511959],
            [-63.7887528, 46.3513565],
            [-63.7890215, 46.3516879],
            [-63.788994, 46.3519501],
            [-63.7890831, 46.3520234],
            [-63.7890912, 46.3522584],
            [-63.7891363, 46.3524264],
            [-63.7894347, 46.3525873],
            [-63.7896834, 46.3525481],
            [-63.790079, 46.3522809],
            [-63.7903368, 46.3523056],
            [-63.7906162, 46.3525099],
            [-63.790692, 46.3523361],
            [-63.7906036, 46.3522429],
            [-63.7901897, 46.3519486],
            [-63.7901137, 46.3516884],
            [-63.7902596, 46.3516315],
            [-63.7905013, 46.3517713],
            [-63.7915266, 46.3528971],
            [-63.7926482, 46.3537659],
            [-63.7930592, 46.3539885],
            [-63.7935323, 46.3540929],
            [-63.7942182, 46.3544878],
            [-63.7954037, 46.3550473],
            [-63.7966108, 46.3559376],
            [-63.7988728, 46.3573491],
            [-63.7999017, 46.3582439],
            [-63.801389, 46.359621],
            [-63.8019956, 46.3602891],
            [-63.8027731, 46.3608489],
            [-63.8030164, 46.3609489],
            [-63.8035831, 46.3613098],
            [-63.80438, 46.36226],
            [-63.8052414, 46.3630323],
            [-63.8069123, 46.365173],
            [-63.8072817, 46.3657332],
            [-63.8078869, 46.3662897],
            [-63.808093, 46.3667593],
            [-63.808412, 46.3669881],
            [-63.8087649, 46.3672295],
            [-63.8089273, 46.3674913],
            [-63.8092682, 46.3677484],
            [-63.8098194, 46.3677705],
            [-63.8103794, 46.368159],
            [-63.81114, 46.3682686],
            [-63.8114386, 46.3684294],
            [-63.8115413, 46.3685985],
            [-63.8115828, 46.368866],
            [-63.8114851, 46.3691627],
            [-63.8116202, 46.3695354],
            [-63.8115256, 46.3696013],
            [-63.8114847, 46.3697678],
            [-63.8114664, 46.3700939],
            [-63.8114451, 46.3703482],
            [-63.8108898, 46.3707281],
            [-63.8108367, 46.3710615],
            [-63.8109485, 46.3712944],
            [-63.8110208, 46.3718053],
            [-63.8108763, 46.3719738],
            [-63.810778, 46.3719879],
            [-63.8103507, 46.3720318],
            [-63.8101034, 46.3721825],
            [-63.8097449, 46.3722316],
            [-63.8069372, 46.3722632],
            [-63.8056932, 46.3723078],
            [-63.8052925, 46.3722605],
            [-63.8046082, 46.3721085],
            [-63.8031142, 46.3717783],
            [-63.8014743, 46.371505],
            [-63.800927, 46.3712321],
            [-63.800938, 46.3708023],
            [-63.8008875, 46.3706223],
            [-63.8011962, 46.3703732],
            [-63.8009899, 46.3702062],
            [-63.8009688, 46.3700067],
            [-63.8011188, 46.369699],
            [-63.8009678, 46.3694415],
            [-63.8009348, 46.3691065],
            [-63.8007859, 46.3689405],
            [-63.8002963, 46.3685174],
            [-63.7988851, 46.3670979],
            [-63.7979074, 46.3662119],
            [-63.7970173, 46.3655902],
            [-63.7960982, 46.3652705],
            [-63.7952798, 46.3648771],
            [-63.7934581, 46.3636688],
            [-63.7930009, 46.3634493],
            [-63.7920413, 46.3628422],
            [-63.7914734, 46.3622186],
            [-63.791152, 46.3616155],
            [-63.7906068, 46.3610002],
            [-63.7888383, 46.359761],
            [-63.7878236, 46.3592444],
            [-63.7867634, 46.3588623],
            [-63.7852592, 46.3580738],
            [-63.7836322, 46.3576332],
            [-63.7830399, 46.3573434],
            [-63.7813253, 46.3562046],
            [-63.781098, 46.3559894],
            [-63.7808848, 46.3554161],
            [-63.7805835, 46.3551836],
            [-63.7803055, 46.3550908],
            [-63.7793744, 46.3549379],
            [-63.7782226, 46.3548445],
            [-63.7765984, 46.3546268],
            [-63.7749112, 46.3546985],
            [-63.773011, 46.3546307],
            [-63.7713306, 46.3546746],
            [-63.769345, 46.3545852],
            [-63.7685281, 46.3544543],
            [-63.7676098, 46.354417],
            [-63.7665423, 46.3545162],
            [-63.7642098, 46.3549137],
            [-63.7634478, 46.3558626],
            [-63.7631593, 46.3557456],
            [-63.7640029, 46.3547665],
            [-63.7640243, 46.3546634],
            [-63.7640182, 46.3542374],
            [-63.7637506, 46.3537347],
            [-63.7632684, 46.3532837],
            [-63.7629998, 46.3529522],
            [-63.7628443, 46.35238],
            [-63.7626738, 46.351318],
            [-63.7622792, 46.350395],
            [-63.7622209, 46.349546],
            [-63.7620768, 46.3494119],
            [-63.7620252, 46.3491203],
            [-63.7618349, 46.3489893],
            [-63.7615282, 46.3488959],
            [-63.7610392, 46.3484727],
            [-63.7607143, 46.3484028],
            [-63.7603958, 46.3481738],
            [-63.75999, 46.3481143],
            [-63.7595392, 46.3478868],
            [-63.7593155, 46.3478746],
            [-63.7590173, 46.3477137],
            [-63.7586795, 46.3476794],
            [-63.7583397, 46.3475535],
            [-63.7578167, 46.3475515],
            [-63.7573643, 46.3473637],
            [-63.7566904, 46.3472553],
            [-63.7563842, 46.3472933],
            [-63.7561123, 46.3471926],
            [-63.7555756, 46.347246],
            [-63.7545431, 46.3469039],
            [-63.7543869, 46.3467854],
            [-63.7541959, 46.3466743],
            [-63.7535172, 46.3466852],
            [-63.7534137, 46.3466872],
            [-63.7526499, 46.3465254],
            [-63.7518625, 46.3465262],
            [-63.7515474, 46.3465003],
            [-63.7512623, 46.3465864],
            [-63.751081, 46.3465192],
            [-63.7509208, 46.3465002],
            [-63.7504477, 46.3465469],
            [-63.75, 46.3464101],
            [-63.7495164, 46.3462623],
            [-63.7493217, 46.3460993],
            [-63.7491958, 46.3459417],
            [-63.7480286, 46.3465283],
            [-63.7474268, 46.3466282],
            [-63.7470566, 46.3465415],
            [-63.7468925, 46.3466219],
            [-63.7467757, 46.3468107],
            [-63.7465146, 46.3470167],
            [-63.745834, 46.3472186],
            [-63.7460829, 46.3474622],
            [-63.7461308, 46.3477019],
            [-63.7460269, 46.3478552],
            [-63.7453926, 46.3483366],
            [-63.7449173, 46.3482916],
            [-63.7448166, 46.3483653],
            [-63.7446183, 46.348867],
            [-63.7436043, 46.3495003],
            [-63.7434216, 46.3497556],
            [-63.7431968, 46.3499144],
            [-63.7427831, 46.3500538],
            [-63.7426884, 46.3501196],
            [-63.7422508, 46.350139],
            [-63.7412673, 46.3505819],
            [-63.7405284, 46.3508025],
            [-63.7396727, 46.3512119],
            [-63.7388278, 46.3514942],
            [-63.7382259, 46.351594],
            [-63.737654, 46.351806],
            [-63.7374045, 46.3518649],
            [-63.7369223, 46.3518476],
            [-63.736476, 46.3516518],
            [-63.7350793, 46.3506496],
            [-63.7343684, 46.3501741],
            [-63.7342191, 46.3500279],
            [-63.7335786, 46.349518],
            [-63.7329633, 46.3492394],
            [-63.7323677, 46.3488975],
            [-63.7323317, 46.3487933],
            [-63.7324174, 46.3486636],
            [-63.7323329, 46.3486222],
            [-63.7316942, 46.3486377],
            [-63.7298121, 46.3484141],
            [-63.7287459, 46.3483417],
            [-63.7282641, 46.3481732],
            [-63.7272492, 46.3481097],
            [-63.7262279, 46.3482053],
            [-63.7252629, 46.3484732],
            [-63.7244071, 46.3488824],
            [-63.7241713, 46.3488858],
            [-63.7241094, 46.3488528],
            [-63.7236952, 46.3488606],
            [-63.7223653, 46.3493363],
            [-63.7218625, 46.3494022],
            [-63.7215915, 46.3492815],
            [-63.7215433, 46.3489103],
            [-63.7212601, 46.3476748],
            [-63.720964, 46.3470401],
            [-63.7203378, 46.3460408],
            [-63.7202436, 46.3456727],
            [-63.7203382, 46.3456069],
            [-63.7207317, 46.3456822],
            [-63.7197299, 46.3447353],
            [-63.719302, 46.344516],
            [-63.7184741, 46.3443804],
            [-63.7178815, 46.3443927],
            [-63.7172539, 46.3445636],
            [-63.716938, 46.3445574],
            [-63.7166852, 46.3444132],
            [-63.7164931, 46.3441905],
            [-63.716483, 46.3437326],
            [-63.7162347, 46.3433376],
            [-63.7162723, 46.3431194],
            [-63.7164669, 46.3429998],
            [-63.7170351, 46.3430189],
            [-63.7179653, 46.3433236],
            [-63.7183944, 46.3433718],
            [-63.718563, 46.3433233],
            [-63.718967, 46.3431402],
            [-63.7191866, 46.3429693],
            [-63.7191676, 46.3427301],
            [-63.7185193, 46.3421364],
            [-63.7185274, 46.3419376],
            [-63.7185958, 46.3418115],
            [-63.7191619, 46.3414564],
            [-63.7193597, 46.3412572],
            [-63.7193075, 46.3409855],
            [-63.7186143, 46.3402237],
            [-63.7180074, 46.3397462],
            [-63.717618, 46.3395714],
            [-63.7171869, 46.3397142],
            [-63.7169159, 46.3398761],
            [-63.7168172, 46.3400414],
            [-63.7167201, 46.3404494],
            [-63.7165972, 46.3406461],
            [-63.7163023, 46.3406881],
            [-63.7146598, 46.3395335],
            [-63.7147216, 46.3390013],
            [-63.7158552, 46.3369895],
            [-63.7162512, 46.3367226],
            [-63.7171404, 46.3364773],
            [-63.717389, 46.3364384],
            [-63.718167, 46.33596],
            [-63.7182592, 46.3356712],
            [-63.7181351, 46.3354737],
            [-63.717797, 46.3353079],
            [-63.717516, 46.3352945],
            [-63.7171177, 46.3350559],
            [-63.7165023, 46.3346458],
            [-63.7159492, 46.3344002],
            [-63.7157199, 46.3342444],
            [-63.7156775, 46.3340167],
            [-63.7157067, 46.3335834],
            [-63.7155644, 46.3334094],
            [-63.7154277, 46.3333789],
            [-63.7153331, 46.3334447],
            [-63.7151802, 46.3338119],
            [-63.7152347, 46.334024],
            [-63.7153835, 46.3343215],
            [-63.7159552, 46.3346749],
            [-63.7161153, 46.334694],
            [-63.7165795, 46.3348662],
            [-63.7172815, 46.3354093],
            [-63.7177958, 46.3354791],
            [-63.7180372, 46.3356191],
            [-63.7178945, 46.335879],
            [-63.7177513, 46.3360076],
            [-63.7169964, 46.3363431],
            [-63.7163154, 46.3364134],
            [-63.7159411, 46.3365773],
            [-63.715482, 46.3369822],
            [-63.7147649, 46.3383614],
            [-63.7142874, 46.3392237],
            [-63.7142999, 46.339622],
            [-63.7143533, 46.3397226],
            [-63.7148449, 46.3400665],
            [-63.7151466, 46.3402794],
            [-63.7152319, 46.340301],
            [-63.7162623, 46.3409659],
            [-63.7167395, 46.34082],
            [-63.717393, 46.3400128],
            [-63.7175581, 46.3399125],
            [-63.7178226, 46.3399097],
            [-63.7183527, 46.3402982],
            [-63.7188695, 46.3408735],
            [-63.7189342, 46.3412609],
            [-63.7186791, 46.3414589],
            [-63.7180712, 46.3418491],
            [-63.7179293, 46.3420891],
            [-63.7179867, 46.3423727],
            [-63.7185509, 46.3427738],
            [-63.7186819, 46.3429435],
            [-63.7185691, 46.3430329],
            [-63.718174, 46.3429973],
            [-63.7173315, 46.3426545],
            [-63.7168486, 46.342657],
            [-63.7163419, 46.3428223],
            [-63.7156143, 46.3433295],
            [-63.7156305, 46.343497],
            [-63.716005, 46.3438984],
            [-63.7160599, 46.3445244],
            [-63.7161772, 46.3447496],
            [-63.7167018, 46.3449947],
            [-63.7172769, 46.344986],
            [-63.7180555, 46.3449216],
            [-63.7185934, 46.3449798],
            [-63.7188263, 46.3451874],
            [-63.7191495, 46.346145],
            [-63.719302, 46.3470595],
            [-63.7192859, 46.3483051],
            [-63.7189239, 46.3491499],
            [-63.7185032, 46.3495994],
            [-63.717873, 46.3501126],
            [-63.717401, 46.3502706],
            [-63.7169338, 46.3503092],
            [-63.7165819, 46.3504815],
            [-63.7148348, 46.3508932],
            [-63.713561, 46.3509757],
            [-63.7110822, 46.3514288],
            [-63.7099807, 46.3515147],
            [-63.7087287, 46.3519082],
            [-63.707578, 46.3527733],
            [-63.7073442, 46.3528683],
            [-63.7069351, 46.3531707],
            [-63.7064621, 46.3539138],
            [-63.7061285, 46.3547591],
            [-63.7063438, 46.3561008],
            [-63.7062892, 46.356593],
            [-63.7057965, 46.3572397],
            [-63.7049376, 46.3576708],
            [-63.7045112, 46.3576942],
            [-63.7047209, 46.3579243],
            [-63.7051698, 46.3579148],
            [-63.7056997, 46.3578558],
            [-63.7063236, 46.3575773],
            [-63.706799, 46.3573398],
            [-63.7073687, 46.3569051],
            [-63.7076629, 46.3566004],
            [-63.7076601, 46.3565287],
            [-63.7079283, 46.3562951],
            [-63.7081765, 46.3554282],
            [-63.7082777, 46.3552033],
            [-63.7082531, 46.3551033],
            [-63.7083477, 46.3550375],
            [-63.7082899, 46.354905],
            [-63.7083753, 46.354644],
            [-63.7086465, 46.3541995],
            [-63.7090713, 46.3539331],
            [-63.7095874, 46.3539631],
            [-63.7099802, 46.3540584],
            [-63.7107043, 46.3540646],
            [-63.7118147, 46.3543252],
            [-63.7128443, 46.3543135],
            [-63.7146942, 46.3542024],
            [-63.7152399, 46.3542131],
            [-63.7154875, 46.3543453],
            [-63.7157375, 46.3544178],
            [-63.716551, 46.3543461],
            [-63.7169839, 46.3544461],
            [-63.7175017, 46.3544363],
            [-63.7182691, 46.3543677],
            [-63.718924, 46.3545198],
            [-63.7199046, 46.3544394],
            [-63.7204584, 46.3545338],
            [-63.7213512, 46.3544915],
            [-63.7220765, 46.3540438],
            [-63.7226497, 46.3539435],
            [-63.7247375, 46.3539204],
            [-63.7250154, 46.3537308],
            [-63.7251108, 46.3533624],
            [-63.7255893, 46.3530453],
            [-63.7258655, 46.3526128],
            [-63.7260487, 46.3526967],
            [-63.7261612, 46.3529597],
            [-63.7265755, 46.3532344],
            [-63.7264558, 46.3533515],
            [-63.7260088, 46.3534583],
            [-63.7258989, 46.3537758],
            [-63.7254483, 46.3538307],
            [-63.7252076, 46.3539534],
            [-63.7252036, 46.3540529],
            [-63.7258238, 46.3544948],
            [-63.7259104, 46.3546279],
            [-63.7262596, 46.3549491],
            [-63.7262531, 46.3551082],
            [-63.7258693, 46.3556421],
            [-63.7258038, 46.3558399],
            [-63.7261634, 46.35562],
            [-63.726235, 46.3554144],
            [-63.7264583, 46.3552953],
            [-63.7269389, 46.3553524],
            [-63.727428, 46.3556246],
            [-63.7279848, 46.3563559],
            [-63.7284141, 46.3566867],
            [-63.7284457, 46.3567589],
            [-63.7286468, 46.3567628],
            [-63.7291837, 46.3569922],
            [-63.729706, 46.3570142],
            [-63.7302502, 46.3573472],
            [-63.7306852, 46.3575387],
            [-63.7311371, 46.3575953],
            [-63.7320556, 46.3581982],
            [-63.7335394, 46.3588996],
            [-63.7349366, 46.3591854],
            [-63.7358236, 46.359716],
            [-63.7366729, 46.3598996],
            [-63.7380504, 46.3605312],
            [-63.7390883, 46.3608856],
            [-63.7395634, 46.3610819],
            [-63.7400601, 46.3614577],
            [-63.7400473, 46.3617759],
            [-63.7401335, 46.3617775],
            [-63.7404785, 46.3616329],
            [-63.7408762, 46.3616087],
            [-63.7415353, 46.3616612],
            [-63.7419236, 46.3614418],
            [-63.7421627, 46.3613588],
            [-63.7426488, 46.360994],
            [-63.7430315, 46.3609138],
            [-63.7440931, 46.3608227],
            [-63.7447257, 46.3605323],
            [-63.7449062, 46.3606194],
            [-63.7451898, 46.3605731],
            [-63.7456743, 46.360248],
            [-63.7468139, 46.3600748],
            [-63.7485541, 46.3601241],
            [-63.7492909, 46.3602457],
            [-63.7493918, 46.3604547],
            [-63.7497507, 46.3609711],
            [-63.75, 46.3615409],
            [-63.7500352, 46.3616214],
            [-63.7501037, 46.361778],
            [-63.7501234, 46.363299],
            [-63.7504233, 46.3645706],
            [-63.7508283, 46.3655178],
            [-63.7517656, 46.3668136],
            [-63.7523928, 46.3673789],
            [-63.7526783, 46.3675754],
            [-63.7537608, 46.3681176],
            [-63.7559114, 46.3688434],
            [-63.757513, 46.3692043],
            [-63.7590982, 46.3693978],
            [-63.7606543, 46.3694593],
            [-63.762302, 46.3693831],
            [-63.7644013, 46.3692319],
            [-63.7653734, 46.3689359],
            [-63.7657393, 46.3689906],
            [-63.7660891, 46.3691605],
            [-63.7667158, 46.3697456],
            [-63.7676726, 46.3709978],
            [-63.7681264, 46.3714483],
            [-63.7682, 46.3717681],
            [-63.7681136, 46.3719178],
            [-63.7667154, 46.3733881],
            [-63.7661092, 46.3741727],
            [-63.7658625, 46.3747373],
            [-63.7658245, 46.375],
            [-63.765728, 46.3756663],
            [-63.7653628, 46.3770447],
            [-63.7653432, 46.3779759],
            [-63.7652083, 46.3790562],
            [-63.7652545, 46.379487],
            [-63.7651182, 46.381041],
            [-63.7648488, 46.3815972],
            [-63.7642208, 46.3822023],
            [-63.7636038, 46.382529],
            [-63.7617555, 46.383298],
            [-63.7601626, 46.3837176],
            [-63.7587076, 46.3839964],
            [-63.757694, 46.3840249],
            [-63.7570295, 46.3838092],
            [-63.7549852, 46.3828506],
            [-63.7539543, 46.3824487],
            [-63.7525637, 46.3822669],
            [-63.7520308, 46.3820696],
            [-63.751885, 46.3819752],
            [-63.7519723, 46.3818057],
            [-63.7517517, 46.3817139],
            [-63.7511278, 46.3816343],
            [-63.7504345, 46.3811353],
            [-63.7500001, 46.3811491],
            [-63.7500001, 46.3817498],
            [-63.75008, 46.3818013],
            [-63.750893, 46.3826171],
            [-63.7512049, 46.3828739],
            [-63.7514696, 46.382871],
            [-63.7519923, 46.382459],
            [-63.7521647, 46.3824623],
            [-63.7522259, 46.3825152],
            [-63.7522227, 46.3825947],
            [-63.751772, 46.3829325],
            [-63.751762, 46.3830398],
            [-63.7519152, 46.3832378],
            [-63.7516153, 46.3833992],
            [-63.7518117, 46.3835224],
            [-63.7518159, 46.3837056],
            [-63.7509498, 46.3849391],
            [-63.7500001, 46.3856879],
            [-63.7497913, 46.3858525],
            [-63.7495202, 46.3860145],
            [-63.7479892, 46.3871835],
            [-63.7472358, 46.3878937],
            [-63.7467843, 46.3882513],
            [-63.7465389, 46.3887761],
            [-63.7457858, 46.3893349],
            [-63.7456415, 46.3896347],
            [-63.7451081, 46.3900226],
            [-63.743498, 46.3908596],
            [-63.7430196, 46.3910256],
            [-63.7425151, 46.39098],
            [-63.7429279, 46.3907292],
            [-63.742919, 46.3906653],
            [-63.7425532, 46.3904592],
            [-63.7414608, 46.3901555],
            [-63.7409566, 46.3902753],
            [-63.7408583, 46.3910042],
            [-63.7408717, 46.3914092],
            [-63.7408495, 46.3917457],
            [-63.740883, 46.3925053],
            [-63.7418031, 46.3935223],
            [-63.74252, 46.3940098],
            [-63.7435433, 46.3941768],
            [-63.7455878, 46.3944191],
            [-63.7460751, 46.394751],
            [-63.7464597, 46.3953476],
            [-63.7467171, 46.395675],
            [-63.74724, 46.3959796],
            [-63.7476588, 46.3961549],
            [-63.7478039, 46.3962691],
            [-63.7477583, 46.3959697],
            [-63.7474615, 46.3953349],
            [-63.7469377, 46.3944191],
            [-63.7466124, 46.3938113],
            [-63.7463757, 46.3936511],
            [-63.7462886, 46.393512],
            [-63.7462625, 46.393377],
            [-63.7463312, 46.3932769],
            [-63.7465669, 46.393086],
            [-63.7469573, 46.392837],
            [-63.7474166, 46.3924317],
            [-63.7478178, 46.3923279],
            [-63.7481536, 46.391988],
            [-63.7483717, 46.3918568],
            [-63.7496742, 46.3912288],
            [-63.75, 46.3911766],
            [-63.751672, 46.3909088],
            [-63.7521662, 46.3907789],
            [-63.7527781, 46.3908742],
            [-63.7530688, 46.3908001],
            [-63.7536829, 46.3908357],
            [-63.7539169, 46.3907407],
            [-63.7544994, 46.390704],
            [-63.754876, 46.3907829],
            [-63.7560005, 46.3910074],
            [-63.7574938, 46.3910757],
            [-63.7577818, 46.3912125],
            [-63.7578907, 46.3912226],
            [-63.7584757, 46.3915602],
            [-63.7589633, 46.3917406],
            [-63.7593694, 46.3918001],
            [-63.7596709, 46.3920328],
            [-63.7598782, 46.3920288],
            [-63.7599892, 46.3921304],
            [-63.7596218, 46.3923981],
            [-63.7597442, 46.392504],
            [-63.7597503, 46.3929301],
            [-63.7599246, 46.3931763],
            [-63.7599168, 46.3933752],
            [-63.7601655, 46.3934874],
            [-63.760144, 46.3927226],
            [-63.7602495, 46.3925295],
            [-63.7601419, 46.392197],
            [-63.7602927, 46.3920207],
            [-63.7601792, 46.3916961],
            [-63.7602913, 46.3916266],
            [-63.7620062, 46.3911815],
            [-63.7625916, 46.3907825],
            [-63.7632116, 46.3905276],
            [-63.764128, 46.3903419],
            [-63.7646866, 46.3901853],
            [-63.7660678, 46.3898891],
            [-63.7680395, 46.3892058],
            [-63.7686834, 46.3890707],
            [-63.7697183, 46.3890903],
            [-63.7702048, 46.389008],
            [-63.7706826, 46.3887105],
            [-63.7709141, 46.388675],
            [-63.7712485, 46.3886575],
            [-63.7721902, 46.3887031],
            [-63.7739266, 46.3890226],
            [-63.7752045, 46.3894448],
            [-63.7755658, 46.3895988],
            [-63.7758028, 46.3896389],
            [-63.7760077, 46.3896493],
            [-63.7761992, 46.3896228],
            [-63.7763853, 46.3895518],
            [-63.7765368, 46.389505],
            [-63.7769988, 46.3894627],
            [-63.779178, 46.3896431],
            [-63.7792485, 46.3896527],
            [-63.7804672, 46.389818],
            [-63.780748, 46.3898561],
            [-63.7810603, 46.3898805],
            [-63.7814015, 46.3896738],
            [-63.7813494, 46.3895496],
            [-63.7812768, 46.389246],
            [-63.7813383, 46.3889386],
            [-63.7814119, 46.3887775],
            [-63.7815124, 46.3886128],
            [-63.7818079, 46.3883406],
            [-63.7818261, 46.3883239],
            [-63.7820446, 46.3882139],
            [-63.7820711, 46.3882006],
            [-63.7823922, 46.3880535],
            [-63.7825499, 46.3880231],
            [-63.7827652, 46.3879816],
            [-63.7828956, 46.3879517],
            [-63.7832798, 46.3878636],
            [-63.7833165, 46.3878574],
            [-63.7836616, 46.3877988],
            [-63.7839566, 46.3877981],
            [-63.7843348, 46.3878371],
            [-63.7844079, 46.3878406],
            [-63.7845939, 46.3878495],
            [-63.7848477, 46.3877695],
            [-63.7850613, 46.3876774],
            [-63.7852856, 46.3876796],
            [-63.7857028, 46.3877649],
            [-63.7860065, 46.3878145],
            [-63.7863129, 46.3878623],
            [-63.7867925, 46.3879412],
            [-63.7870234, 46.3879816],
            [-63.7871079, 46.3880143],
            [-63.7872277, 46.3880608],
            [-63.7873596, 46.3882008],
            [-63.787434, 46.3882769],
            [-63.7874521, 46.3883281],
            [-63.7874592, 46.3884044],
            [-63.7873992, 46.3884604],
            [-63.7873339, 46.3884646],
            [-63.7872847, 46.388454],
            [-63.787206, 46.3883671],
            [-63.7870829, 46.388205],
            [-63.7866549, 46.3880943],
            [-63.7858221, 46.3879417],
            [-63.7852113, 46.3878795],
            [-63.7847495, 46.3880107],
            [-63.7847126, 46.3881229],
            [-63.7845951, 46.3884812],
            [-63.7846637, 46.3884891],
            [-63.7848416, 46.3885096],
            [-63.7848259, 46.3885637],
            [-63.784769, 46.3887601],
            [-63.7847323, 46.388864],
            [-63.7848194, 46.3889739],
            [-63.7859735, 46.3891597],
            [-63.7861355, 46.3890866],
            [-63.7862306, 46.388773],
            [-63.7870239, 46.3888916],
            [-63.7868865, 46.389173],
            [-63.786785, 46.3893909],
            [-63.7867932, 46.3894793],
            [-63.7870703, 46.3894065],
            [-63.7874048, 46.3893183],
            [-63.7876689, 46.3892825],
            [-63.7878588, 46.3892996],
            [-63.7881378, 46.3884704],
            [-63.7888524, 46.3863463],
            [-63.7896736, 46.3865005],
            [-63.7890078, 46.3882547],
            [-63.7888767, 46.3886486],
            [-63.7887588, 46.3889682],
            [-63.7903732, 46.3892548],
            [-63.7908323, 46.38827],
            [-63.791125, 46.3883345],
            [-63.7906371, 46.3895599],
            [-63.7917917, 46.3899104],
            [-63.7924747, 46.3898235],
            [-63.7921901, 46.3886738],
            [-63.7917064, 46.3887047],
            [-63.791686, 46.3886345],
            [-63.7922918, 46.3885785],
            [-63.7925888, 46.3896684],
            [-63.7931185, 46.3897127],
            [-63.7934216, 46.390696],
            [-63.7931659, 46.3910716],
            [-63.7934489, 46.3912707],
            [-63.7936157, 46.3913643],
            [-63.7938164, 46.3913994],
            [-63.7940441, 46.3912595],
            [-63.7944959, 46.391338],
            [-63.7947609, 46.3915335],
            [-63.7948529, 46.3917009],
            [-63.7949192, 46.3920583],
            [-63.7951655, 46.3922063],
            [-63.7953885, 46.3922646],
            [-63.7955842, 46.3923281],
            [-63.7957459, 46.3924131],
            [-63.7959869, 46.3924484],
            [-63.7961618, 46.3925872],
            [-63.796219, 46.392721],
            [-63.7962195, 46.3928548],
            [-63.7962764, 46.3930841],
            [-63.7965074, 46.3933087],
            [-63.7966014, 46.3934712],
            [-63.7967634, 46.3936117],
            [-63.797303, 46.393981],
            [-63.797673, 46.3941181],
            [-63.7981868, 46.3942694],
            [-63.7991343, 46.394495],
            [-63.8000803, 46.3948058],
            [-63.8020358, 46.3953267],
            [-63.8026061, 46.3953379],
            [-63.8029057, 46.395306],
            [-63.8033707, 46.3951749],
            [-63.8039249, 46.395083],
            [-63.8048676, 46.3950588],
            [-63.8054285, 46.395122],
            [-63.8059145, 46.3952298],
            [-63.8064253, 46.3953073],
            [-63.8084967, 46.3951628],
            [-63.8091722, 46.3952265],
            [-63.8097598, 46.3951373],
            [-63.8103315, 46.3950335],
            [-63.8110238, 46.3949299],
            [-63.8113485, 46.3948375],
            [-63.8116519, 46.3946638],
            [-63.8122573, 46.3947463],
            [-63.8127849, 46.3945944],
            [-63.8133187, 46.3945632],
            [-63.8147336, 46.3942787],
            [-63.815006, 46.394228],
            [-63.8157929, 46.3939638],
            [-63.816563, 46.393986],
            [-63.8194712, 46.3943739],
            [-63.8205132, 46.3945143],
            [-63.8219187, 46.3946051],
            [-63.8231255, 46.3946083],
            [-63.8240765, 46.3945123],
            [-63.8242885, 46.3942574],
            [-63.8248155, 46.3942198],
            [-63.824973, 46.3940903],
            [-63.8252759, 46.3938898],
            [-63.8256635, 46.3937212],
            [-63.8263117, 46.3934636],
            [-63.8269079, 46.3931575],
            [-63.8274081, 46.3930208],
            [-63.828129, 46.3928244],
            [-63.8283046, 46.3926907],
            [-63.8288317, 46.39244],
            [-63.829505, 46.3919063],
            [-63.8301198, 46.3913095],
            [-63.8307897, 46.3911699],
            [-63.8310423, 46.3910928],
            [-63.8311708, 46.3910146],
            [-63.8313348, 46.3908868],
            [-63.8317628, 46.3908237],
            [-63.8323496, 46.3906154],
            [-63.8329252, 46.3903706],
            [-63.8332768, 46.3901221],
            [-63.8340233, 46.3900903],
            [-63.8344644, 46.3902456],
            [-63.8351166, 46.3902993],
            [-63.836059, 46.3904478],
            [-63.8373453, 46.3905849],
            [-63.8381492, 46.3907779],
            [-63.8388957, 46.3910967],
            [-63.8396334, 46.3913215],
            [-63.8404556, 46.3916708],
            [-63.8408691, 46.391778],
            [-63.8412846, 46.391778],
            [-63.8414859, 46.39184],
            [-63.8416799, 46.39196],
            [-63.8419831, 46.3922554],
            [-63.8428836, 46.3926757],
            [-63.8434644, 46.3928394],
            [-63.844698, 46.3932844],
            [-63.8461542, 46.3939517],
            [-63.8471657, 46.3946288],
            [-63.847865, 46.395031],
            [-63.8486184, 46.3953734],
            [-63.8495015, 46.3957043],
            [-63.8509289, 46.3961916],
            [-63.8520311, 46.3965739],
            [-63.853001, 46.3969155],
            [-63.8535473, 46.3970885],
            [-63.8540273, 46.3972218],
            [-63.8557884, 46.3976959],
            [-63.8566762, 46.3978786],
            [-63.8574985, 46.3980934],
            [-63.8581847, 46.3982883],
            [-63.8595796, 46.3988137],
            [-63.8598964, 46.3990314],
            [-63.8606882, 46.3995713],
            [-63.8618411, 46.400186],
            [-63.8620272, 46.4005129],
            [-63.8623624, 46.4006264],
            [-63.8626531, 46.4008547],
            [-63.8626807, 46.4013487],
            [-63.8631834, 46.4017558],
            [-63.8633598, 46.401962],
            [-63.863711, 46.4021117],
            [-63.8641167, 46.4021906],
            [-63.8647101, 46.4024799],
            [-63.8658529, 46.4026994],
            [-63.8663251, 46.4026919],
            [-63.8665187, 46.4025918],
            [-63.8665126, 46.4024484],
            [-63.8666011, 46.4023903],
            [-63.8669968, 46.4024252],
            [-63.8671769, 46.4023806],
            [-63.867631, 46.402548],
            [-63.8681032, 46.4025405],
            [-63.8686011, 46.4024618],
            [-63.8690612, 46.40247],
            [-63.8695525, 46.4027216],
            [-63.869784, 46.402686],
            [-63.8703496, 46.4021785],
            [-63.8712415, 46.4018719],
            [-63.8717635, 46.4017618],
            [-63.8744119, 46.4017293],
            [-63.8752146, 46.4016521],
            [-63.8768654, 46.4013629],
            [-63.8775455, 46.4011799],
            [-63.877823, 46.4009898],
            [-63.8786815, 46.4006507],
            [-63.8792574, 46.400641],
            [-63.8794398, 46.400688],
            [-63.8798033, 46.4009731],
            [-63.8801513, 46.401676],
            [-63.8806512, 46.4021626],
            [-63.8808068, 46.4026232],
            [-63.8810332, 46.4027267],
            [-63.8814433, 46.4026862],
            [-63.8820439, 46.4024739],
            [-63.8823889, 46.40248],
            [-63.8827061, 46.402617],
            [-63.8828259, 46.4028023],
            [-63.8827921, 46.4030923],
            [-63.8825428, 46.4034542],
            [-63.8825475, 46.4036374],
            [-63.8826144, 46.4038535],
            [-63.8831341, 46.4044281],
            [-63.8831927, 46.4047118],
            [-63.8830529, 46.4049123],
            [-63.8830221, 46.4051228],
            [-63.8831069, 46.4051641],
            [-63.8834943, 46.4051152],
            [-63.8838478, 46.4052051],
            [-63.8842174, 46.4054823],
            [-63.8843143, 46.4055079],
            [-63.8845438, 46.4056832],
            [-63.8845174, 46.4057743],
            [-63.8837075, 46.4063532],
            [-63.8835656, 46.4066134],
            [-63.8835166, 46.4068475],
            [-63.8834662, 46.4071213],
            [-63.8831033, 46.4074413],
            [-63.8826722, 46.4077363],
            [-63.8825817, 46.4080054],
            [-63.8834888, 46.4077547],
            [-63.8836008, 46.407685],
            [-63.883733, 46.4075321],
            [-63.8836794, 46.4069578],
            [-63.8838941, 46.4067546],
            [-63.8841559, 46.4066796],
            [-63.8843017, 46.4064712],
            [-63.8846231, 46.4061862],
            [-63.885161, 46.4061121],
            [-63.8854537, 46.405978],
            [-63.8855027, 46.4057439],
            [-63.8853215, 46.4055058],
            [-63.8843742, 46.4049755],
            [-63.8836878, 46.4042388],
            [-63.8835913, 46.4038907],
            [-63.8836183, 46.4036285],
            [-63.8838017, 46.4031818],
            [-63.8838254, 46.4026965],
            [-63.8841132, 46.4022279],
            [-63.8841115, 46.4021164],
            [-63.8838147, 46.4018961],
            [-63.8831993, 46.40173],
            [-63.8823443, 46.401042],
            [-63.8821082, 46.4010458],
            [-63.8814327, 46.4012608],
            [-63.8808552, 46.401159],
            [-63.8807393, 46.4010256],
            [-63.8807239, 46.4000419],
            [-63.8806542, 46.3999053],
            [-63.8806556, 46.3997143],
            [-63.8807727, 46.3995054],
            [-63.8807087, 46.3992096],
            [-63.8804595, 46.3989464],
            [-63.8805195, 46.398414],
            [-63.8803897, 46.3980335],
            [-63.880748, 46.3969052],
            [-63.8812224, 46.396213],
            [-63.8812628, 46.3957439],
            [-63.8811196, 46.3955702],
            [-63.8810241, 46.3953535],
            [-63.8807187, 46.3948983],
            [-63.8807122, 46.3944523],
            [-63.8809631, 46.3942019],
            [-63.8826276, 46.3935347],
            [-63.8832678, 46.3930245],
            [-63.883555, 46.3927271],
            [-63.8838394, 46.3926604],
            [-63.8852572, 46.3927492],
            [-63.8860155, 46.3929378],
            [-63.886151, 46.3930078],
            [-63.8863447, 46.393059],
            [-63.8864416, 46.3930846],
            [-63.8875265, 46.3937766],
            [-63.8883601, 46.394265],
            [-63.8890512, 46.3945598],
            [-63.8894964, 46.3948145],
            [-63.89008, 46.3956847],
            [-63.8907146, 46.3961099],
            [-63.8914372, 46.3967994],
            [-63.8922634, 46.3979605],
            [-63.894728, 46.4004204],
            [-63.8959026, 46.4016592],
            [-63.8971957, 46.4032744],
            [-63.8972846, 46.4035188],
            [-63.8975006, 46.4037495],
            [-63.8977965, 46.404312],
            [-63.8983247, 46.4048189],
            [-63.8990278, 46.4054205],
            [-63.8991711, 46.4055942],
            [-63.899248, 46.4064793],
            [-63.8994377, 46.4068012],
            [-63.900211, 46.4072168],
            [-63.900368, 46.4074863],
            [-63.900374, 46.4079522],
            [-63.9006227, 46.4082353],
            [-63.9016287, 46.4088978],
            [-63.9022164, 46.4091947],
            [-63.9033796, 46.409653],
            [-63.9059402, 46.4103028],
            [-63.9071825, 46.4104877],
            [-63.9106505, 46.410564],
            [-63.9108537, 46.4103565],
            [-63.9110695, 46.4094884],
            [-63.9113126, 46.4091343],
            [-63.9114757, 46.4089222],
            [-63.9123768, 46.4083566],
            [-63.9127257, 46.4079406],
            [-63.9132421, 46.4076669],
            [-63.9139732, 46.4076637],
            [-63.914713, 46.4078955],
            [-63.9149076, 46.4079267],
            [-63.9151478, 46.408126],
            [-63.9152982, 46.4084232],
            [-63.9159786, 46.4088649],
            [-63.9159864, 46.4089686],
            [-63.9158436, 46.4090975],
            [-63.9153687, 46.4093361],
            [-63.9150792, 46.4095421],
            [-63.9150261, 46.4098955],
            [-63.9152838, 46.4100911],
            [-63.9153264, 46.4101874],
            [-63.915135, 46.4102278],
            [-63.9149989, 46.410329],
            [-63.9147191, 46.4104276],
            [-63.9147169, 46.4104873],
            [-63.9149572, 46.4106866],
            [-63.9149603, 46.4107583],
            [-63.9147653, 46.4108982],
            [-63.9146215, 46.4108957],
            [-63.9137016, 46.4107086],
            [-63.9131363, 46.4107426],
            [-63.9128226, 46.41098],
            [-63.9125919, 46.4114697],
            [-63.9125256, 46.4125036],
            [-63.9128273, 46.4132295],
            [-63.9135499, 46.4139388],
            [-63.9145065, 46.4147039],
            [-63.9154742, 46.4153218],
            [-63.9157121, 46.4154295],
            [-63.9163877, 46.415688],
            [-63.9167268, 46.4157018],
            [-63.9170512, 46.4154885],
            [-63.9171549, 46.4154863],
            [-63.9184387, 46.4159584],
            [-63.9206236, 46.4165098],
            [-63.9207315, 46.4167107],
            [-63.9207397, 46.4174394],
            [-63.9210187, 46.4176832],
            [-63.9209668, 46.4178455],
            [-63.9201054, 46.4182646],
            [-63.9200723, 46.4183834],
            [-63.9201266, 46.4186352],
            [-63.9204578, 46.4188679],
            [-63.9207685, 46.4190325],
            [-63.9204197, 46.419126],
            [-63.9207016, 46.4192901],
            [-63.9206042, 46.4194358],
            [-63.9201348, 46.419364],
            [-63.9200814, 46.4195661],
            [-63.9202227, 46.4197994],
            [-63.9200798, 46.4199284],
            [-63.9200862, 46.4200718],
            [-63.9205286, 46.4202546],
            [-63.9205318, 46.4203264],
            [-63.9203783, 46.4204312],
            [-63.9200409, 46.4205289],
            [-63.9197133, 46.4206705],
            [-63.9195814, 46.4209748],
            [-63.9192799, 46.4211966],
            [-63.9188553, 46.4216351],
            [-63.9187109, 46.4221263],
            [-63.9188202, 46.4222875],
            [-63.918873, 46.422579],
            [-63.9187456, 46.4229153],
            [-63.9186899, 46.4230258],
            [-63.9182183, 46.4234874],
            [-63.9175647, 46.423731],
            [-63.9175671, 46.4238226],
            [-63.9178065, 46.4238904],
            [-63.9184139, 46.4236501],
            [-63.9187842, 46.4232822],
            [-63.9189768, 46.4229678],
            [-63.9190362, 46.4228435],
            [-63.9193119, 46.4228352],
            [-63.9193674, 46.4222837],
            [-63.9194996, 46.4216503],
            [-63.9200068, 46.4213126],
            [-63.9203868, 46.4213112],
            [-63.9210354, 46.4215295],
            [-63.9212669, 46.4214937],
            [-63.9214951, 46.4212348],
            [-63.9218806, 46.4206005],
            [-63.9224205, 46.4203152],
            [-63.9227171, 46.4202327],
            [-63.9232013, 46.4197354],
            [-63.9235814, 46.419734],
            [-63.9238693, 46.4198903],
            [-63.9242694, 46.4204506],
            [-63.9244134, 46.4209268],
            [-63.9245196, 46.4210163],
            [-63.9246486, 46.4207915],
            [-63.9247042, 46.4202072],
            [-63.9245914, 46.4198231],
            [-63.9245732, 46.4190504],
            [-63.9247653, 46.41899],
            [-63.9249769, 46.4191887],
            [-63.925098, 46.4191828],
            [-63.9254905, 46.4189945],
            [-63.9265164, 46.4183234],
            [-63.9270502, 46.4180459],
            [-63.9273947, 46.4180718],
            [-63.9275797, 46.4182103],
            [-63.9276866, 46.4186023],
            [-63.9279251, 46.41869],
            [-63.9280562, 46.4184056],
            [-63.9280598, 46.4183061],
            [-63.9282133, 46.4182013],
            [-63.9282882, 46.4181986],
            [-63.9285368, 46.4183303],
            [-63.9285541, 46.4186491],
            [-63.9288464, 46.418686],
            [-63.9289859, 46.4188078],
            [-63.9289408, 46.4189424],
            [-63.9288012, 46.419143],
            [-63.9289482, 46.4192172],
            [-63.9296212, 46.4192328],
            [-63.9299199, 46.4190906],
            [-63.930122, 46.4187517],
            [-63.9303678, 46.4186404],
            [-63.9307017, 46.4183197],
            [-63.9320935, 46.4181963],
            [-63.9344279, 46.4181168],
            [-63.9346527, 46.4181087],
            [-63.9357088, 46.4181945],
            [-63.9360086, 46.4181837],
            [-63.9366823, 46.4181793],
            [-63.9375953, 46.4182427],
            [-63.9380137, 46.4184569],
            [-63.9383848, 46.4188653],
            [-63.9383638, 46.4189685],
            [-63.9379615, 46.4187904],
            [-63.9377594, 46.4188069],
            [-63.9374362, 46.4191517],
            [-63.9373918, 46.4192664],
            [-63.9371034, 46.4199264],
            [-63.9370977, 46.4200855],
            [-63.9372458, 46.4202911],
            [-63.9371985, 46.4204854],
            [-63.9367393, 46.4209314],
            [-63.9361793, 46.4219451],
            [-63.9359761, 46.4224751],
            [-63.9358972, 46.423071],
            [-63.9353697, 46.4238144],
            [-63.9351423, 46.4240534],
            [-63.9347583, 46.424648],
            [-63.9346578, 46.4250445],
            [-63.9347209, 46.4255313],
            [-63.9346115, 46.4256926],
            [-63.9341309, 46.4260905],
            [-63.9336317, 46.4266832],
            [-63.9331638, 46.4270455],
            [-63.9327042, 46.4273402],
            [-63.932507, 46.4275399],
            [-63.9321958, 46.4281915],
            [-63.9316146, 46.4286633],
            [-63.9316042, 46.4287905],
            [-63.9319062, 46.42872],
            [-63.9323453, 46.4283573],
            [-63.9327535, 46.4277312],
            [-63.9330786, 46.4274979],
            [-63.9333983, 46.4272525],
            [-63.9334473, 46.4271698],
            [-63.9335085, 46.4270713],
            [-63.9338931, 46.4267792],
            [-63.9339905, 46.4266336],
            [-63.9345105, 46.4262603],
            [-63.9347827, 46.4260579],
            [-63.9352104, 46.425691],
            [-63.9357159, 46.4242742],
            [-63.9360435, 46.42381],
            [-63.9367102, 46.4230371],
            [-63.9372792, 46.421936],
            [-63.9376536, 46.4214487],
            [-63.9381997, 46.4209842],
            [-63.9385144, 46.4208781],
            [-63.9383382, 46.4206522],
            [-63.9382527, 46.4203083],
            [-63.938378, 46.420183],
            [-63.9387275, 46.4200696],
            [-63.9390699, 46.4198325],
            [-63.9390571, 46.4195457],
            [-63.9388855, 46.4193516],
            [-63.9388451, 46.4191957],
            [-63.9390997, 46.418997],
            [-63.939077, 46.4186661],
            [-63.9393021, 46.4184868],
            [-63.939604, 46.4184163],
            [-63.9400213, 46.4181766],
            [-63.9400341, 46.418141],
            [-63.9400571, 46.4179782],
            [-63.9399662, 46.4176223],
            [-63.9401172, 46.4174258],
            [-63.9410135, 46.4171504],
            [-63.941114, 46.4170765],
            [-63.9409993, 46.4169033],
            [-63.9404651, 46.4163846],
            [-63.9398398, 46.4163262],
            [-63.9396185, 46.4162348],
            [-63.9395326, 46.4160621],
            [-63.9396913, 46.4159693],
            [-63.9403621, 46.4160444],
            [-63.9404065, 46.4159297],
            [-63.9405479, 46.4158406],
            [-63.9408302, 46.4158335],
            [-63.9409662, 46.4157323],
            [-63.9409744, 46.4156647],
            [-63.9407922, 46.4154466],
            [-63.9402517, 46.415107],
            [-63.9388099, 46.4145369],
            [-63.937795, 46.4139423],
            [-63.9377396, 46.4138816],
            [-63.9377851, 46.4135758],
            [-63.9380479, 46.4133096],
            [-63.9387709, 46.4127287],
            [-63.9391118, 46.412209],
            [-63.9393081, 46.4117068],
            [-63.9395155, 46.4113799],
            [-63.9398752, 46.4111392],
            [-63.9401688, 46.4111362],
            [-63.9406603, 46.4115586],
            [-63.9408882, 46.4116223],
            [-63.9413357, 46.4116657],
            [-63.9417124, 46.411915],
            [-63.941781, 46.4124138],
            [-63.9418676, 46.4125666],
            [-63.9419813, 46.4129308],
            [-63.941906, 46.4134272],
            [-63.9413351, 46.4142655],
            [-63.9412484, 46.4144352],
            [-63.9412087, 46.4149043],
            [-63.9415056, 46.4152956],
            [-63.9418004, 46.415424],
            [-63.9420983, 46.4156242],
            [-63.9421995, 46.4158528],
            [-63.9427347, 46.4161804],
            [-63.9432752, 46.4161976],
            [-63.9433875, 46.4162792],
            [-63.9435878, 46.4164737],
            [-63.9437724, 46.4164609],
            [-63.9439233, 46.4162644],
            [-63.9441531, 46.416117],
            [-63.9441492, 46.4160652],
            [-63.943615, 46.415869],
            [-63.9432457, 46.415572],
            [-63.9430446, 46.4150749],
            [-63.9427016, 46.4146869],
            [-63.942692, 46.4144717],
            [-63.9427516, 46.414413],
            [-63.9430282, 46.4142426],
            [-63.9438251, 46.4141725],
            [-63.9440754, 46.4140932],
            [-63.9441319, 46.4139628],
            [-63.9440626, 46.4138063],
            [-63.9443889, 46.4135332],
            [-63.9443907, 46.4133222],
            [-63.9445327, 46.4132131],
            [-63.9446591, 46.4132193],
            [-63.9450778, 46.4135847],
            [-63.9454666, 46.4134958],
            [-63.9457038, 46.4133008],
            [-63.9456675, 46.4127029],
            [-63.9459299, 46.4126079],
            [-63.9462559, 46.4128284],
            [-63.9466948, 46.4127881],
            [-63.9470784, 46.4130096],
            [-63.9473159, 46.4129659],
            [-63.9476887, 46.4128408],
            [-63.9477693, 46.4126789],
            [-63.947591, 46.4125127],
            [-63.9479734, 46.4122803],
            [-63.9479624, 46.4121049],
            [-63.9475689, 46.4118395],
            [-63.9475653, 46.4116165],
            [-63.9479317, 46.411348],
            [-63.9489979, 46.4109839],
            [-63.9496796, 46.4105894],
            [-63.9499529, 46.4103472],
            [-63.9499969, 46.4100812],
            [-63.9497887, 46.409783],
            [-63.9491193, 46.4093457],
            [-63.9491101, 46.4092818],
            [-63.9492105, 46.4092079],
            [-63.9493082, 46.4092135],
            [-63.9497733, 46.4090821],
            [-63.9501197, 46.4087257],
            [-63.9503096, 46.4084024],
            [-63.9505091, 46.4082943],
            [-63.9507232, 46.4082621],
            [-63.950818, 46.4080249],
            [-63.9508701, 46.4076913],
            [-63.9512151, 46.4073747],
            [-63.9514927, 46.4073356],
            [-63.9518464, 46.4074252],
            [-63.9521347, 46.4074102],
            [-63.9528081, 46.4070832],
            [-63.9531855, 46.4069901],
            [-63.9535754, 46.4070325],
            [-63.9538261, 46.4071044],
            [-63.9543707, 46.4070022],
            [-63.9547978, 46.4071288],
            [-63.9548855, 46.4070905],
            [-63.9549212, 46.4065696],
            [-63.9540095, 46.4066377],
            [-63.9535624, 46.4064232],
            [-63.9532332, 46.4061309],
            [-63.953052, 46.4057218],
            [-63.9529971, 46.4049963],
            [-63.9528515, 46.4048824],
            [-63.9522891, 46.404837],
            [-63.9519737, 46.4046406],
            [-63.9517354, 46.4043818],
            [-63.9513392, 46.4041959],
            [-63.9495533, 46.4036003],
            [-63.9470758, 46.4030327],
            [-63.9450518, 46.4025007],
            [-63.9443254, 46.4022136],
            [-63.9431325, 46.4014528],
            [-63.9426774, 46.4013057],
            [-63.9424566, 46.4013657],
            [-63.9421335, 46.401388],
            [-63.9417238, 46.4015801],
            [-63.9414076, 46.4022196],
            [-63.9414122, 46.402574],
            [-63.9413604, 46.4027364],
            [-63.9411335, 46.4028042],
            [-63.9409873, 46.4027101],
            [-63.9410653, 46.4021342],
            [-63.9408793, 46.4018643],
            [-63.9406783, 46.4013672],
            [-63.9407657, 46.4008551],
            [-63.9410148, 46.4003219],
            [-63.9411402, 46.4001967],
            [-63.9413304, 46.4000247],
            [-63.9418118, 46.3997583],
            [-63.9427791, 46.3995797],
            [-63.9436832, 46.3992368],
            [-63.9448155, 46.3984638],
            [-63.9456078, 46.3981907],
            [-63.9465555, 46.397753],
            [-63.9469917, 46.3976211],
            [-63.9476413, 46.3974768],
            [-63.9488535, 46.3972068],
            [-63.9491492, 46.3971441],
            [-63.9499783, 46.3968039],
            [-63.9505139, 46.3967891],
            [-63.9514773, 46.3968811],
            [-63.9533657, 46.3968494],
            [-63.9555012, 46.3966665],
            [-63.9569518, 46.396484],
            [-63.9573523, 46.3965505],
            [-63.9577779, 46.3967169],
            [-63.9586955, 46.3966409],
            [-63.958996, 46.3964389],
            [-63.9594248, 46.3963546],
            [-63.9597471, 46.3963521],
            [-63.9599416, 46.3963832],
            [-63.9603657, 46.3967606],
            [-63.9604975, 46.3971012],
            [-63.9604411, 46.3972316],
            [-63.9602274, 46.3970926],
            [-63.9601776, 46.3968729],
            [-63.9601215, 46.3968321],
            [-63.95989, 46.396868],
            [-63.9598244, 46.3969346],
            [-63.9601719, 46.3975256],
            [-63.9605681, 46.3977115],
            [-63.9609618, 46.3978057],
            [-63.9616254, 46.3980796],
            [-63.9619467, 46.3982681],
            [-63.9618493, 46.398585],
            [-63.961663, 46.3988087],
            [-63.9616047, 46.3993213],
            [-63.9616691, 46.3992945],
            [-63.9619035, 46.3990079],
            [-63.9619555, 46.3986743],
            [-63.9624267, 46.3983638],
            [-63.9628875, 46.3983516],
            [-63.9633032, 46.3984741],
            [-63.9635847, 46.3984868],
            [-63.9629499, 46.3982133],
            [-63.9625306, 46.3981904],
            [-63.9615627, 46.3975729],
            [-63.9609067, 46.3975738],
            [-63.9607764, 46.3975159],
            [-63.9606989, 46.397427],
            [-63.9606536, 46.3969167],
            [-63.9603094, 46.3963974],
            [-63.9599714, 46.3961926],
            [-63.9597428, 46.3961489],
            [-63.959397, 46.396163],
            [-63.9581267, 46.3964521],
            [-63.9575283, 46.396454],
            [-63.9574697, 46.3963216],
            [-63.9575399, 46.396287],
            [-63.9597542, 46.3956595],
            [-63.9609034, 46.3953763],
            [-63.9616569, 46.3950586],
            [-63.9625398, 46.3948187],
            [-63.966241, 46.3932965],
            [-63.9675817, 46.3928014],
            [-63.9691806, 46.3921595],
            [-63.9758308, 46.3903598],
            [-63.9766642, 46.3900513],
            [-63.9769204, 46.389964],
            [-63.9776047, 46.389641],
            [-63.9779365, 46.3895311],
            [-63.9782262, 46.389305],
            [-63.9802336, 46.3889842],
            [-63.9813438, 46.3886563],
            [-63.9817974, 46.3883494],
            [-63.9819047, 46.3880765],
            [-63.9820579, 46.3879715],
            [-63.9830394, 46.3878684],
            [-63.98514, 46.3878436],
            [-63.9859997, 46.3879374],
            [-63.9881092, 46.3876539],
            [-63.9899003, 46.387596],
            [-63.990579, 46.387432],
            [-63.9914929, 46.3874551],
            [-63.9923119, 46.387393],
            [-63.9926536, 46.387327],
            [-63.9930386, 46.3873373],
            [-63.9942414, 46.3873253],
            [-63.9950652, 46.3871239],
            [-63.9952176, 46.3870388],
            [-63.995596, 46.3870769],
            [-63.9957771, 46.3871635],
            [-63.9965754, 46.3870333],
            [-63.9974471, 46.3871114],
            [-63.9986627, 46.3868925],
            [-63.9991388, 46.386765],
            [-64.0, 46.386296],
            [-64.0008037, 46.3863584],
            [-64.001287, 46.3863544],
            [-64.001745, 46.3862505],
            [-64.0021824, 46.3860785],
            [-64.0034596, 46.3853988],
            [-64.0036402, 46.3853341],
            [-64.0039137, 46.3852431],
            [-64.0041904, 46.3852237],
            [-64.0044779, 46.3852284],
            [-64.00606, 46.385728],
            [-64.0064938, 46.3858267],
            [-64.007324, 46.3859398],
            [-64.0080426, 46.3861227],
            [-64.0085773, 46.3862986],
            [-64.0092999, 46.3865333],
            [-64.0099168, 46.3866588],
            [-64.0107872, 46.3867765],
            [-64.0114758, 46.3868275],
            [-64.0128756, 46.3869379],
            [-64.0136297, 46.3869223],
            [-64.0140636, 46.3868498],
            [-64.0146346, 46.3866361],
            [-64.0151902, 46.3865377],
            [-64.0158634, 46.3865327],
            [-64.0166636, 46.386685],
            [-64.0173127, 46.3868827],
            [-64.0182085, 46.3871002],
            [-64.01996, 46.3873595],
            [-64.0211846, 46.3877177],
            [-64.0213905, 46.3877529],
            [-64.02239, 46.3877969],
            [-64.023088, 46.3879117],
            [-64.0244591, 46.3881926],
            [-64.0270497, 46.3884773],
            [-64.0275645, 46.3885652],
            [-64.0281869, 46.3887026],
            [-64.0296056, 46.3889404],
            [-64.0302594, 46.3889987],
            [-64.0309574, 46.3889422],
            [-64.0314615, 46.3888349],
            [-64.0325593, 46.3885222],
            [-64.0329958, 46.3885411],
            [-64.0333555, 46.3886225],
            [-64.033629, 46.3887025],
            [-64.0345001, 46.3891424],
            [-64.0360072, 46.3896641],
            [-64.0371873, 46.3901567],
            [-64.0378639, 46.3903945],
            [-64.0383259, 46.3905133],
            [-64.038818, 46.3905929],
            [-64.0392914, 46.3907159],
            [-64.0397936, 46.3910106],
            [-64.0402978, 46.3914167],
            [-64.0407197, 46.3915309],
            [-64.0410808, 46.3915725],
            [-64.0412339, 46.3916386],
            [-64.04172, 46.3918973],
            [-64.0421747, 46.3920637],
            [-64.0429163, 46.3922547],
            [-64.0433917, 46.3924892],
            [-64.0441842, 46.3930512],
            [-64.04476, 46.3933828],
            [-64.0455244, 46.3937532],
            [-64.0464446, 46.3942814],
            [-64.0484677, 46.3950421],
            [-64.0496988, 46.395392],
            [-64.0503609, 46.3955538],
            [-64.0509012, 46.3957415],
            [-64.0539387, 46.3964505],
            [-64.0544028, 46.3965096],
            [-64.0550862, 46.3965483],
            [-64.0556365, 46.3966086],
            [-64.0563072, 46.3965118],
            [-64.0567646, 46.3965986],
            [-64.0569787, 46.3967373],
            [-64.056997, 46.3969165],
            [-64.0570118, 46.3969245],
            [-64.0570281, 46.3969335],
            [-64.0572148, 46.3969043],
            [-64.0575197, 46.3969051],
            [-64.0579578, 46.3970553],
            [-64.0584801, 46.3970955],
            [-64.0592176, 46.3970633],
            [-64.0596081, 46.3970854],
            [-64.0599913, 46.3971551],
            [-64.0603157, 46.3972638],
            [-64.0606655, 46.3973012],
            [-64.0620383, 46.3973705],
            [-64.0632667, 46.3972864],
            [-64.0642069, 46.3973887],
            [-64.0661321, 46.3974588],
            [-64.0673338, 46.3974856],
            [-64.0699914, 46.3976825],
            [-64.0714104, 46.3977485],
            [-64.0747746, 46.3978011],
            [-64.0755731, 46.3978414],
            [-64.0767194, 46.3979787],
            [-64.0776917, 46.3979819],
            [-64.0784661, 46.3980537],
            [-64.0798217, 46.3981265],
            [-64.0810594, 46.3981059],
            [-64.0836383, 46.3984246],
            [-64.0857996, 46.39849],
            [-64.0864825, 46.3985483],
            [-64.0866443, 46.3985269],
            [-64.0876842, 46.3985748],
            [-64.0885315, 46.3987034],
            [-64.0888418, 46.3987161],
            [-64.0906219, 46.3986521],
            [-64.0909262, 46.3986727],
            [-64.0914245, 46.3987441],
            [-64.0919107, 46.3988312],
            [-64.0921977, 46.3988555],
            [-64.0933017, 46.3988765],
            [-64.0957032, 46.3989891],
            [-64.0960242, 46.3990259],
            [-64.0969873, 46.3991362],
            [-64.0974396, 46.3992108],
            [-64.0977499, 46.3992236],
            [-64.0983148, 46.3991885],
            [-64.0991696, 46.3992692],
            [-64.0999995, 46.3992302],
            [-64.1006446, 46.3992043],
            [-64.1030115, 46.3993241],
            [-64.1039901, 46.3994903],
            [-64.1049419, 46.3995965],
            [-64.1052276, 46.3996605],
            [-64.1056772, 46.3998147],
            [-64.1063168, 46.3999678],
            [-64.1076424, 46.4000795],
            [-64.1084573, 46.3999646],
            [-64.1086065, 46.3999788],
            [-64.1089544, 46.4000756],
            [-64.1091399, 46.4002138],
            [-64.1092764, 46.4002637],
            [-64.1094937, 46.4003028],
            [-64.1097166, 46.400354],
            [-64.1102526, 46.4005094],
            [-64.1113289, 46.400681],
            [-64.1120079, 46.4008586],
            [-64.1135544, 46.4010811],
            [-64.1145097, 46.4012588],
            [-64.1150805, 46.4014068],
            [-64.1157748, 46.4014691],
            [-64.1159641, 46.4014879],
            [-64.1167816, 46.4016555],
            [-64.1176124, 46.4017676],
            [-64.1191843, 46.4019188],
            [-64.1207173, 46.4020255],
            [-64.121404, 46.4019642],
            [-64.1223173, 46.4018347],
            [-64.1230497, 46.4015989],
            [-64.1235455, 46.4013875],
            [-64.1238033, 46.4012401],
            [-64.1241754, 46.4007521],
            [-64.1243318, 46.4007186],
            [-64.1245145, 46.4007652],
            [-64.1249785, 46.401015],
            [-64.1264575, 46.4011925],
            [-64.126739, 46.4012047],
            [-64.1275174, 46.4011567],
            [-64.1283201, 46.4012483],
            [-64.1301605, 46.401276],
            [-64.1305325, 46.4013413],
            [-64.131244, 46.4015908],
            [-64.1314961, 46.4016224],
            [-64.1317963, 46.4015911],
            [-64.1322656, 46.4014906],
            [-64.1326802, 46.4014809],
            [-64.1330408, 46.4015421],
            [-64.1332876, 46.4015617],
            [-64.1340725, 46.4014858],
            [-64.1344625, 46.4015275],
            [-64.1349489, 46.4016144],
            [-64.1350508, 46.4016511],
            [-64.1350064, 46.4017088],
            [-64.1348806, 46.4018084],
            [-64.1342701, 46.4022234],
            [-64.1340464, 46.4025655],
            [-64.1337984, 46.40316],
            [-64.1333504, 46.4041887],
            [-64.1329699, 46.4051251],
            [-64.1328954, 46.4060712],
            [-64.1329794, 46.406797],
            [-64.1331369, 46.4071962],
            [-64.1332903, 46.4073852],
            [-64.1334726, 46.407354],
            [-64.133565, 46.4073382],
            [-64.1336902, 46.4073042],
            [-64.1338645, 46.407334],
            [-64.1345634, 46.407376],
            [-64.1346351, 46.4072497],
            [-64.134793, 46.4072852],
            [-64.1347898, 46.407507],
            [-64.1337303, 46.4074472],
            [-64.1337027, 46.4077748],
            [-64.1336676, 46.4077742],
            [-64.1336187, 46.4077733],
            [-64.1336192, 46.4078168],
            [-64.1336197, 46.4078533],
            [-64.1336427, 46.4078539],
            [-64.1336931, 46.4078553],
            [-64.1336605, 46.4081534],
            [-64.1336583, 46.4081715],
            [-64.1344858, 46.4082143],
            [-64.1344822, 46.4082782],
            [-64.1347642, 46.4084476],
            [-64.1350378, 46.4084615],
            [-64.1350831, 46.4084327],
            [-64.1350984, 46.4076088],
            [-64.1351303, 46.4076091],
            [-64.1351211, 46.4072761],
            [-64.1351696, 46.407263],
            [-64.1352233, 46.4072726],
            [-64.1352861, 46.4084927],
            [-64.1352234, 46.4085429],
            [-64.1351325, 46.408564],
            [-64.1347166, 46.4085228],
            [-64.1343834, 46.4083674],
            [-64.1339529, 46.4083151],
            [-64.1334322, 46.4087975],
            [-64.1331587, 46.4097916],
            [-64.1329863, 46.4107974],
            [-64.133008, 46.4111409],
            [-64.1330235, 46.4113859],
            [-64.1329933, 46.4116302],
            [-64.132994, 46.4121108],
            [-64.1330235, 46.4130504],
            [-64.1330574, 46.4134259],
            [-64.1333331, 46.4141256],
            [-64.1336447, 46.4147356],
            [-64.1339826, 46.4159823],
            [-64.134271, 46.416348],
            [-64.1346082, 46.4169976],
            [-64.1350129, 46.4174852],
            [-64.135513, 46.4177554],
            [-64.1359572, 46.417923],
            [-64.1366758, 46.4181427],
            [-64.1370969, 46.4183223],
            [-64.1372674, 46.418423],
            [-64.1374106, 46.4185904],
            [-64.1375308, 46.4187953],
            [-64.137669, 46.4189767],
            [-64.137897, 46.4191923],
            [-64.1380667, 46.4193389],
            [-64.1382295, 46.4194223],
            [-64.1384237, 46.4194769],
            [-64.1390226, 46.4194063],
            [-64.1391505, 46.4194159],
            [-64.1394114, 46.4196311],
            [-64.1395462, 46.4197406],
            [-64.1398361, 46.4198563],
            [-64.140418, 46.4200282],
            [-64.1405707, 46.4201141],
            [-64.1406365, 46.4202186],
            [-64.1406831, 46.4203865],
            [-64.1407898, 46.4215824],
            [-64.1409803, 46.4223058],
            [-64.141177, 46.4228303],
            [-64.1413894, 46.4232196],
            [-64.1415109, 46.4233847],
            [-64.1422566, 46.4240288],
            [-64.1423218, 46.4241531],
            [-64.142296, 46.4242443],
            [-64.1418543, 46.4247473],
            [-64.1412342, 46.425618],
            [-64.1407178, 46.4264862],
            [-64.140567, 46.426894],
            [-64.1405558, 46.4272521],
            [-64.140618, 46.4280294],
            [-64.140615, 46.4292357],
            [-64.1405758, 46.4301269],
            [-64.1404783, 46.4304837],
            [-64.1402599, 46.4306557],
            [-64.1401201, 46.4307053],
            [-64.1388952, 46.4308424],
            [-64.1385581, 46.4309409],
            [-64.1381845, 46.4311065],
            [-64.1378377, 46.4313322],
            [-64.1374009, 46.4316761],
            [-64.1372605, 46.4317456],
            [-64.1366846, 46.4321193],
            [-64.1362237, 46.4324946],
            [-64.1357898, 46.43293],
            [-64.1353763, 46.4332623],
            [-64.1342887, 46.4343449],
            [-64.1340636, 46.4345446],
            [-64.1332749, 46.4352733],
            [-64.1316391, 46.4363875],
            [-64.1302778, 46.4375615],
            [-64.1298841, 46.4378104],
            [-64.1293747, 46.4380775],
            [-64.1290345, 46.4382755],
            [-64.1286383, 46.438604],
            [-64.1283706, 46.4388786],
            [-64.1282733, 46.4390444],
            [-64.1275351, 46.4399928],
            [-64.1272879, 46.4401643],
            [-64.1266686, 46.4404417],
            [-64.126527, 46.440551],
            [-64.1260477, 46.441141],
            [-64.1257618, 46.4414393],
            [-64.1254491, 46.4416775],
            [-64.1250659, 46.4421416],
            [-64.1248474, 46.4423135],
            [-64.1245141, 46.4426548],
            [-64.1235855, 46.4441578],
            [-64.1228234, 46.4449466],
            [-64.1217692, 46.4456793],
            [-64.1213995, 46.4458966],
            [-64.1207856, 46.446186],
            [-64.1198888, 46.4466741],
            [-64.1192553, 46.4468557],
            [-64.1186378, 46.4470733],
            [-64.1180355, 46.4471757],
            [-64.117232, 46.4470839],
            [-64.1163285, 46.4468751],
            [-64.1160545, 46.4467953],
            [-64.1159868, 46.4467505],
            [-64.1159973, 46.4466034],
            [-64.1160687, 46.4465288],
            [-64.1163069, 46.4464647],
            [-64.116584, 46.446445],
            [-64.1172911, 46.4464915],
            [-64.1184789, 46.4466329],
            [-64.1188436, 46.4465747],
            [-64.1190243, 46.4465098],
            [-64.1192956, 46.4463068],
            [-64.1196101, 46.4462001],
            [-64.1199289, 46.4459541],
            [-64.1200414, 46.4456731],
            [-64.1199208, 46.445297],
            [-64.1198275, 46.4451523],
            [-64.1194555, 46.4448958],
            [-64.1191426, 46.4447716],
            [-64.1187784, 46.4444476],
            [-64.1183944, 46.4442069],
            [-64.1183017, 46.4440422],
            [-64.1180309, 46.443863],
            [-64.1179129, 46.4437696],
            [-64.1177364, 46.4435241],
            [-64.1175728, 46.4434141],
            [-64.1174194, 46.4433481],
            [-64.1172397, 46.443202],
            [-64.1170907, 46.4429967],
            [-64.1170903, 46.4428255],
            [-64.1171827, 46.4428189],
            [-64.117395, 46.442846],
            [-64.1175718, 46.4429004],
            [-64.117896, 46.4430288],
            [-64.1180596, 46.4431388],
            [-64.1182434, 46.4433366],
            [-64.1185879, 46.4435529],
            [-64.1188327, 46.4438233],
            [-64.1191035, 46.4440026],
            [-64.1193743, 46.4441819],
            [-64.1196189, 46.4442811],
            [-64.1201993, 46.4443217],
            [-64.1206317, 46.4443084],
            [-64.1208285, 46.4442795],
            [-64.1212071, 46.4441458],
            [-64.1216929, 46.4440815],
            [-64.122304, 46.4437005],
            [-64.12252, 46.4436082],
            [-64.1228208, 46.4433858],
            [-64.1228599, 46.4432391],
            [-64.1227143, 46.4427432],
            [-64.1227765, 46.4424137],
            [-64.1227252, 46.4422138],
            [-64.1224173, 46.4415681],
            [-64.1224799, 46.4414098],
            [-64.1228708, 46.4410693],
            [-64.1229603, 46.4409711],
            [-64.1230228, 46.4408128],
            [-64.1229858, 46.4407087],
            [-64.122833, 46.4406228],
            [-64.1223127, 46.4405035],
            [-64.122028, 46.4403997],
            [-64.1218315, 46.4402375],
            [-64.1216885, 46.4400243],
            [-64.1214161, 46.4397136],
            [-64.1208309, 46.4392788],
            [-64.1201849, 46.4389425],
            [-64.1197901, 46.4386778],
            [-64.1194593, 46.4385772],
            [-64.1188352, 46.4384603],
            [-64.1183661, 46.4381785],
            [-64.1176906, 46.4380528],
            [-64.1173256, 46.4379398],
            [-64.1166557, 46.4379461],
            [-64.1143259, 46.4384317],
            [-64.1142439, 46.4382201],
            [-64.1165474, 46.4377271],
            [-64.1165317, 46.4375495],
            [-64.1165, 46.4374575],
            [-64.1165361, 46.4374102],
            [-64.116592, 46.4372797],
            [-64.1167144, 46.4372338],
            [-64.1170819, 46.4369049],
            [-64.1170964, 46.4368096],
            [-64.1170224, 46.4366014],
            [-64.1167773, 46.4365221],
            [-64.1164215, 46.4363017],
            [-64.116307, 46.43628],
            [-64.1161384, 46.4363292],
            [-64.1160138, 46.436626],
            [-64.1159382, 46.4366487],
            [-64.1158124, 46.4366229],
            [-64.1156441, 46.436481],
            [-64.1154164, 46.4360356],
            [-64.1151463, 46.4358364],
            [-64.1147183, 46.4357105],
            [-64.1145776, 46.4356088],
            [-64.1145185, 46.4354766],
            [-64.1144397, 46.4352365],
            [-64.1143378, 46.4351792],
            [-64.1140851, 46.4349763],
            [-64.1139435, 46.4347233],
            [-64.113976, 46.4346044],
            [-64.1141266, 46.4345788],
            [-64.1147344, 46.4346597],
            [-64.115006, 46.434628],
            [-64.1154997, 46.4344961],
            [-64.1158681, 46.4343185],
            [-64.1159826, 46.4343402],
            [-64.1161256, 46.4345533],
            [-64.1163862, 46.4346887],
            [-64.1165756, 46.4347075],
            [-64.117031, 46.4348736],
            [-64.117211, 46.4348286],
            [-64.1172992, 46.4347702],
            [-64.1173419, 46.4346952],
            [-64.117327, 46.4346193],
            [-64.1171414, 46.4344811],
            [-64.1169016, 46.4344138],
            [-64.1167843, 46.4343005],
            [-64.1167485, 46.4341567],
            [-64.1167703, 46.4340137],
            [-64.1169904, 46.4336109],
            [-64.1170543, 46.4334128],
            [-64.1171832, 46.4326145],
            [-64.1173735, 46.4324223],
            [-64.1176189, 46.4323106],
            [-64.1178864, 46.432227],
            [-64.1181337, 46.4320556],
            [-64.118391, 46.4315658],
            [-64.1185668, 46.4314689],
            [-64.1188679, 46.4314177],
            [-64.1188985, 46.4313585],
            [-64.1188723, 46.4312784],
            [-64.118692, 46.4311523],
            [-64.1180582, 46.4308003],
            [-64.1176819, 46.4306831],
            [-64.117082, 46.4305347],
            [-64.1168347, 46.4303439],
            [-64.1167743, 46.4302514],
            [-64.1167553, 46.4301237],
            [-64.1167705, 46.4300085],
            [-64.1168366, 46.4299219],
            [-64.1170424, 46.4297856],
            [-64.1173687, 46.4296632],
            [-64.1177617, 46.4294342],
            [-64.1180239, 46.4293387],
            [-64.1183303, 46.4292995],
            [-64.1190553, 46.4291313],
            [-64.1193702, 46.4290047],
            [-64.1194417, 46.4289301],
            [-64.1192643, 46.4288956],
            [-64.1190401, 46.4288842],
            [-64.1185263, 46.4289282],
            [-64.1182338, 46.4288919],
            [-64.1180912, 46.42885],
            [-64.1178606, 46.4286753],
            [-64.11778, 46.4284949],
            [-64.1177363, 46.4280563],
            [-64.1176717, 46.427912],
            [-64.1174304, 46.4277133],
            [-64.1173533, 46.4276046],
            [-64.1172954, 46.4274325],
            [-64.1171976, 46.4267025],
            [-64.1170926, 46.4263824],
            [-64.1168778, 46.4260726],
            [-64.116911, 46.4259338],
            [-64.1169831, 46.4258393],
            [-64.1174118, 46.4253919],
            [-64.1174905, 46.4252697],
            [-64.1174813, 46.4250148],
            [-64.1174437, 46.4249306],
            [-64.117227, 46.4246805],
            [-64.1170348, 46.4245701],
            [-64.116558, 46.4243558],
            [-64.1160669, 46.4240458],
            [-64.1159173, 46.4238604],
            [-64.115787, 46.4232493],
            [-64.1157266, 46.4231568],
            [-64.115444, 46.4229933],
            [-64.1143494, 46.4226542],
            [-64.1141135, 46.4224675],
            [-64.1138492, 46.4220893],
            [-64.1137246, 46.4220237],
            [-64.1135365, 46.4219651],
            [-64.1129329, 46.421936],
            [-64.1128245, 46.4219065],
            [-64.1127144, 46.4217456],
            [-64.1126114, 46.4213658],
            [-64.112415, 46.4212036],
            [-64.1122791, 46.4211339],
            [-64.1120312, 46.4209629],
            [-64.1118529, 46.420777],
            [-64.1116844, 46.420464],
            [-64.1115336, 46.4203183],
            [-64.1114625, 46.4202018],
            [-64.1114514, 46.4200066],
            [-64.1116088, 46.4197621],
            [-64.1115372, 46.4196655],
            [-64.1114275, 46.4196758],
            [-64.1111934, 46.4197916],
            [-64.1111074, 46.4199615],
            [-64.1111166, 46.4202165],
            [-64.1112266, 46.4203774],
            [-64.1114482, 46.4208306],
            [-64.1116066, 46.4210998],
            [-64.1117675, 46.4212893],
            [-64.1121716, 46.4216179],
            [-64.1122601, 46.4217307],
            [-64.1123825, 46.4220471],
            [-64.1125621, 46.4221932],
            [-64.1134084, 46.4225524],
            [-64.1137354, 46.4227723],
            [-64.1138742, 46.4229337],
            [-64.1143428, 46.4237689],
            [-64.1146337, 46.424036],
            [-64.1151756, 46.4243747],
            [-64.1159459, 46.4251388],
            [-64.116044, 46.4253155],
            [-64.1160737, 46.4254672],
            [-64.1160513, 46.4256301],
            [-64.1158335, 46.4261444],
            [-64.1157978, 46.4263628],
            [-64.1161166, 46.427566],
            [-64.116209, 46.428284],
            [-64.1161129, 46.4287723],
            [-64.1159517, 46.4291361],
            [-64.115278, 46.4302287],
            [-64.1152255, 46.430431],
            [-64.1151993, 46.4307132],
            [-64.1152724, 46.4314947],
            [-64.1152509, 46.4318089],
            [-64.1151652, 46.43215],
            [-64.1150878, 46.4322324],
            [-64.1148825, 46.4323487],
            [-64.1144831, 46.4324144],
            [-64.1141511, 46.4323536],
            [-64.1137988, 46.4322049],
            [-64.1127938, 46.4315765],
            [-64.1124984, 46.4314486],
            [-64.1122641, 46.4313933],
            [-64.1119542, 46.4313607],
            [-64.1110112, 46.4313185],
            [-64.1106156, 46.4312647],
            [-64.1102045, 46.431155],
            [-64.1100793, 46.4311093],
            [-64.1096612, 46.4308561],
            [-64.1092963, 46.430743],
            [-64.1085715, 46.43072],
            [-64.1083293, 46.4307323],
            [-64.1082939, 46.4307596],
            [-64.1083369, 46.4308558],
            [-64.1085064, 46.4309579],
            [-64.108649, 46.4309999],
            [-64.1090392, 46.4310417],
            [-64.1091531, 46.4310832],
            [-64.1093728, 46.4312339],
            [-64.1094105, 46.4313181],
            [-64.1093731, 46.4314051],
            [-64.1091385, 46.4315409],
            [-64.1090003, 46.4317219],
            [-64.1084143, 46.4318603],
            [-64.1079747, 46.4319213],
            [-64.1079099, 46.4319681],
            [-64.1078963, 46.4322147],
            [-64.1083352, 46.4321736],
            [-64.108777, 46.4322241],
            [-64.1089329, 46.4322106],
            [-64.1094895, 46.4320917],
            [-64.1100483, 46.4320842],
            [-64.1101909, 46.4321262],
            [-64.1105773, 46.4322874],
            [-64.1107709, 46.432358],
            [-64.1109596, 46.4323967],
            [-64.1121658, 46.4324946],
            [-64.1122976, 46.4325126],
            [-64.1123922, 46.4326175],
            [-64.1124118, 46.4327253],
            [-64.1123773, 46.432904],
            [-64.1124203, 46.4330002],
            [-64.1126838, 46.4332271],
            [-64.1127062, 46.4334265],
            [-64.1124884, 46.4335785],
            [-64.1121727, 46.433725],
            [-64.1115118, 46.4338662],
            [-64.1113119, 46.4339946],
            [-64.1112547, 46.4341649],
            [-64.1111544, 46.434239],
            [-64.111026, 46.4342928],
            [-64.1108904, 46.4343943],
            [-64.1108651, 46.4344656],
            [-64.1109552, 46.4347098],
            [-64.1109299, 46.4347811],
            [-64.1106579, 46.4350039],
            [-64.1106266, 46.435083],
            [-64.1106898, 46.4352671],
            [-64.1106759, 46.4353426],
            [-64.1104077, 46.435446],
            [-64.1102319, 46.4355429],
            [-64.110218, 46.4356183],
            [-64.1102616, 46.4356946],
            [-64.1104191, 46.4358125],
            [-64.110446, 46.4358726],
            [-64.1104008, 46.4360272],
            [-64.1104659, 46.4361516],
            [-64.1106576, 46.4362819],
            [-64.1109087, 46.4363534],
            [-64.1109764, 46.4363982],
            [-64.1110421, 46.4365028],
            [-64.1112268, 46.4364896],
            [-64.1114242, 46.4364409],
            [-64.1115273, 46.4364584],
            [-64.1116272, 46.4365753],
            [-64.1117177, 46.4366285],
            [-64.1118322, 46.4366501],
            [-64.1121674, 46.4366114],
            [-64.1123167, 46.4366256],
            [-64.11241, 46.4367704],
            [-64.1124015, 46.4368578],
            [-64.1122718, 46.4369514],
            [-64.1120102, 46.4370271],
            [-64.1119388, 46.4371016],
            [-64.1118474, 46.4372595],
            [-64.1118736, 46.4373395],
            [-64.1118357, 46.4374465],
            [-64.1117107, 46.437572],
            [-64.111743, 46.4376441],
            [-64.1119761, 46.4377392],
            [-64.1121222, 46.4378529],
            [-64.1121573, 46.4380167],
            [-64.1120229, 46.4384407],
            [-64.1120647, 46.4385767],
            [-64.1121765, 46.438678],
            [-64.1129355, 46.4384859],
            [-64.1130244, 46.4386843],
            [-64.1123866, 46.4388538],
            [-64.1124437, 46.4390026],
            [-64.1123452, 46.4391247],
            [-64.1123925, 46.4391424],
            [-64.1121116, 46.4394632],
            [-64.1121733, 46.4394898],
            [-64.1121466, 46.4395245],
            [-64.1121007, 46.439584],
            [-64.1126543, 46.4398517],
            [-64.1125321, 46.439977],
            [-64.1129756, 46.4401966],
            [-64.1130616, 46.4401127],
            [-64.1132231, 46.4401924],
            [-64.1130299, 46.4403712],
            [-64.1118409, 46.439754],
            [-64.1117216, 46.4398993],
            [-64.1116096, 46.4400357],
            [-64.1116209, 46.4400807],
            [-64.1130886, 46.4407953],
            [-64.1134158, 46.4404781],
            [-64.1134823, 46.4405085],
            [-64.1131154, 46.4408737],
            [-64.112883, 46.440761],
            [-64.1115002, 46.4400906],
            [-64.1112336, 46.4402196],
            [-64.1108859, 46.4402549],
            [-64.1107852, 46.4401787],
            [-64.1104555, 46.4404819],
            [-64.1105644, 46.4406122],
            [-64.1104443, 46.4408433],
            [-64.1100237, 46.4410022],
            [-64.1098709, 46.4412786],
            [-64.1105834, 46.4414776],
            [-64.1104064, 46.4416574],
            [-64.1102028, 46.4416792],
            [-64.1095843, 46.4419591],
            [-64.1091886, 46.4422676],
            [-64.1089242, 46.442614],
            [-64.1086986, 46.4430047],
            [-64.1084649, 46.4432918],
            [-64.1076793, 46.4446256],
            [-64.1072019, 46.4456894],
            [-64.1070124, 46.4460329],
            [-64.1066204, 46.4465843],
            [-64.1063882, 46.4470028],
            [-64.1061198, 46.4478309],
            [-64.1057831, 46.448634],
            [-64.105785, 46.4489367],
            [-64.1058254, 46.4491125],
            [-64.105933, 46.449353],
            [-64.1059076, 46.4494243],
            [-64.1057425, 46.4495452],
            [-64.1054473, 46.4495885],
            [-64.1049418, 46.4495449],
            [-64.1045334, 46.4495268],
            [-64.1039459, 46.4493426],
            [-64.1032617, 46.4493043],
            [-64.1027969, 46.4492454],
            [-64.1026242, 46.4492428],
            [-64.1024556, 46.4492919],
            [-64.1024195, 46.4493392],
            [-64.1024799, 46.4494317],
            [-64.102857, 46.449529],
            [-64.1035647, 46.4495558],
            [-64.1037308, 46.4495862],
            [-64.1039471, 46.4496651],
            [-64.1041844, 46.4498121],
            [-64.1043331, 46.4498462],
            [-64.1044723, 46.4498165],
            [-64.1046308, 46.4497233],
            [-64.10477, 46.4496936],
            [-64.1049307, 46.449712],
            [-64.1051993, 46.4497798],
            [-64.1054631, 46.4498157],
            [-64.1057924, 46.4497849],
            [-64.1060335, 46.4498124],
            [-64.1061132, 46.4498415],
            [-64.1062594, 46.4499552],
            [-64.1064093, 46.4503119],
            [-64.106767, 46.4515596],
            [-64.1070883, 46.452321],
            [-64.1073835, 46.4526401],
            [-64.1076805, 46.4528994],
            [-64.1086929, 46.4536714],
            [-64.1089444, 46.4539141],
            [-64.1098859, 46.4554654],
            [-64.109997, 46.4557777],
            [-64.1101887, 46.456995],
            [-64.1104089, 46.4576792],
            [-64.1105883, 46.4580164],
            [-64.1106449, 46.4582283],
            [-64.1106668, 46.4584477],
            [-64.1105361, 46.4591146],
            [-64.1102022, 46.4600094],
            [-64.1098971, 46.4609047],
            [-64.1093803, 46.4621153],
            [-64.108903, 46.4629881],
            [-64.1087651, 46.4633403],
            [-64.1082767, 46.4643801],
            [-64.1072639, 46.4657822],
            [-64.1068635, 46.4662301],
            [-64.1062449, 46.4668299],
            [-64.1051495, 46.4677489],
            [-64.1041666, 46.4683869],
            [-64.1036593, 46.4685743],
            [-64.1033329, 46.4688679],
            [-64.1029208, 46.4691404],
            [-64.1027166, 46.4692169],
            [-64.1024735, 46.469249],
            [-64.1022558, 46.4692098],
            [-64.1021216, 46.4690804],
            [-64.1020625, 46.4689481],
            [-64.1019511, 46.4688269],
            [-64.1017653, 46.4686887],
            [-64.1010153, 46.4683547],
            [-64.1006451, 46.4680385],
            [-64.1004625, 46.4678008],
            [-64.1003411, 46.4676356],
            [-64.1000079, 46.4674235],
            [-64.0996841, 46.4672751],
            [-64.0993073, 46.4669867],
            [-64.0989513, 46.4667662],
            [-64.0985465, 46.4666285],
            [-64.0980997, 46.466546],
            [-64.0979891, 46.466405],
            [-64.097986, 46.4661422],
            [-64.0980347, 46.4660593],
            [-64.0980244, 46.4658441],
            [-64.0978358, 46.4656143],
            [-64.0975093, 46.4653743],
            [-64.0973227, 46.4650847],
            [-64.0972405, 46.4647729],
            [-64.0971654, 46.4642421],
            [-64.0970834, 46.4641015],
            [-64.0970217, 46.4640488],
            [-64.0969192, 46.4640114],
            [-64.0968135, 46.4640735],
            [-64.0966922, 46.4644419],
            [-64.0966791, 46.4646687],
            [-64.0967145, 46.4650037],
            [-64.0966571, 46.4651741],
            [-64.0963896, 46.4654288],
            [-64.0964095, 46.4657078],
            [-64.0965559, 46.4659928],
            [-64.0969637, 46.4663933],
            [-64.0970309, 46.4666291],
            [-64.0970217, 46.4667366],
            [-64.0969242, 46.4669023],
            [-64.0967931, 46.4670357],
            [-64.0965991, 46.4671562],
            [-64.0964706, 46.4672099],
            [-64.0960534, 46.4672791],
            [-64.0956087, 46.4673082],
            [-64.0953153, 46.4672917],
            [-64.0945818, 46.4671649],
            [-64.0938672, 46.4671659],
            [-64.0934614, 46.4672393],
            [-64.0925673, 46.4672654],
            [-64.0922232, 46.4673916],
            [-64.0922669, 46.4674679],
            [-64.0925384, 46.4676274],
            [-64.0931943, 46.4678366],
            [-64.0934443, 46.4679479],
            [-64.094497, 46.4687246],
            [-64.0945944, 46.4689212],
            [-64.0944956, 46.4691267],
            [-64.0939631, 46.4695566],
            [-64.0932362, 46.4699357],
            [-64.0928059, 46.4702317],
            [-64.0925298, 46.4705739],
            [-64.0924436, 46.4707438],
            [-64.0923264, 46.4711641],
            [-64.0923275, 46.4714867],
            [-64.0924068, 46.4717069],
            [-64.092596, 46.4719169],
            [-64.0928884, 46.4721444],
            [-64.0936485, 46.4725224],
            [-64.0937919, 46.4725445],
            [-64.0938347, 46.4724695],
            [-64.0938217, 46.472334],
            [-64.0935895, 46.4720277],
            [-64.0931858, 46.4716791],
            [-64.0931466, 46.4714634],
            [-64.0933342, 46.4710084],
            [-64.0937303, 46.4706999],
            [-64.0940804, 46.4705659],
            [-64.0946246, 46.4703115],
            [-64.0950013, 46.4700663],
            [-64.0951264, 46.4699409],
            [-64.0954523, 46.4693047],
            [-64.0956429, 46.4691125],
            [-64.0960858, 46.4687808],
            [-64.0964378, 46.4685871],
            [-64.0969158, 46.4684193],
            [-64.0972063, 46.4683441],
            [-64.0976918, 46.4682998],
            [-64.0981112, 46.468342],
            [-64.098396, 46.4684459],
            [-64.0987479, 46.4686146],
            [-64.0992277, 46.4687494],
            [-64.0995159, 46.468925],
            [-64.0997957, 46.4691881],
            [-64.1001374, 46.4693128],
            [-64.1005048, 46.4695375],
            [-64.1006263, 46.4697027],
            [-64.1008785, 46.4702879],
            [-64.1010161, 46.4704891],
            [-64.1011087, 46.4710162],
            [-64.1012029, 46.4713123],
            [-64.1017525, 46.4723281],
            [-64.1018911, 46.4726807],
            [-64.1023692, 46.4734087],
            [-64.1024828, 46.4736414],
            [-64.1026128, 46.4740814],
            [-64.1028245, 46.4744908],
            [-64.1029716, 46.4747559],
            [-64.1030558, 46.4750081],
            [-64.103106, 46.475419],
            [-64.1030653, 46.4763303],
            [-64.1032188, 46.4780171],
            [-64.1032884, 46.4783646],
            [-64.1035599, 46.4792488],
            [-64.1037152, 46.4796175],
            [-64.1040593, 46.4802161],
            [-64.1042232, 46.4804973],
            [-64.1042684, 46.4807051],
            [-64.1040952, 46.481256],
            [-64.1038632, 46.4827418],
            [-64.103832, 46.483717],
            [-64.1038689, 46.4841834],
            [-64.1039661, 46.4845712],
            [-64.1041514, 46.4859877],
            [-64.1043494, 46.4870062],
            [-64.1046915, 46.4883893],
            [-64.1049649, 46.4892139],
            [-64.1052465, 46.4908668],
            [-64.1058408, 46.4939224],
            [-64.1058645, 46.4944445],
            [-64.1057409, 46.4954263],
            [-64.105762, 46.4956655],
            [-64.1063068, 46.4979279],
            [-64.1061389, 46.4984908],
            [-64.1061097, 46.4986816],
            [-64.1062025, 46.4992087],
            [-64.1061172, 46.4998924],
            [-64.1060561, 46.5],
            [-64.1056696, 46.5010883],
            [-64.1056169, 46.501653],
            [-64.1055716, 46.5018076],
            [-64.1055367, 46.5025399],
            [-64.1056718, 46.5039079],
            [-64.106109, 46.505205],
            [-64.1064301, 46.5063489],
            [-64.1064531, 46.5068909],
            [-64.1066824, 46.507659],
            [-64.10678, 46.5078556],
            [-64.1069649, 46.5085674],
            [-64.1074093, 46.509283],
            [-64.1077748, 46.510121],
            [-64.1078596, 46.5103533],
            [-64.108375, 46.5110143],
            [-64.1087295, 46.5112945],
            [-64.1089972, 46.5114021],
            [-64.1093305, 46.511443],
            [-64.1099546, 46.5114087],
            [-64.1101497, 46.5114395],
            [-64.1103984, 46.5114194],
            [-64.1111002, 46.5113026],
            [-64.1111746, 46.5113197],
            [-64.1112351, 46.5114122],
            [-64.111193, 46.5114673],
            [-64.1108385, 46.5115495],
            [-64.1103807, 46.5116143],
            [-64.1104196, 46.5116587],
            [-64.1104364, 46.5120373],
            [-64.1105308, 46.5123334],
            [-64.1106027, 46.5127925],
            [-64.1111461, 46.5147523],
            [-64.1113263, 46.5157946],
            [-64.111807, 46.5175503],
            [-64.1118764, 46.518089],
            [-64.112153, 46.5190053],
            [-64.1122094, 46.5194084],
            [-64.1122839, 46.5208752],
            [-64.1119493, 46.5226863],
            [-64.1118172, 46.5230309],
            [-64.1117009, 46.5232402],
            [-64.111499, 46.5235996],
            [-64.1110414, 46.524198],
            [-64.1103601, 46.5252989],
            [-64.1099104, 46.5258298],
            [-64.1095367, 46.5261467],
            [-64.1093092, 46.5264061],
            [-64.1089802, 46.5269508],
            [-64.1085704, 46.527315],
            [-64.1082899, 46.5276055],
            [-64.1077032, 46.5280864],
            [-64.1073974, 46.5284482],
            [-64.1072243, 46.528808],
            [-64.106914, 46.5293092],
            [-64.1064778, 46.5295933],
            [-64.1060318, 46.5300047],
            [-64.1057079, 46.5303901],
            [-64.1054644, 46.5307847],
            [-64.1052809, 46.5309293],
            [-64.1051893, 46.5310872],
            [-64.1048041, 46.5314],
            [-64.1046897, 46.5315496],
            [-64.1045185, 46.5318497],
            [-64.1043384, 46.532066],
            [-64.1033858, 46.5327963],
            [-64.1032365, 46.5329534],
            [-64.1029683, 46.5333994],
            [-64.1028665, 46.5335133],
            [-64.1024544, 46.5337659],
            [-64.10225, 46.5338425],
            [-64.1020816, 46.5338718],
            [-64.1010889, 46.5338725],
            [-64.1006892, 46.5339182],
            [-64.1005431, 46.5339757],
            [-64.1003716, 46.5341045],
            [-64.1000926, 46.534164],
            [-64.0998229, 46.5341161],
            [-64.0990625, 46.5338893],
            [-64.0988499, 46.5338622],
            [-64.0986701, 46.5338873],
            [-64.0991214, 46.5342129],
            [-64.0993679, 46.5344437],
            [-64.0996069, 46.5345509],
            [-64.1002478, 46.534724],
            [-64.1003156, 46.5347689],
            [-64.1003466, 46.5348809],
            [-64.1002147, 46.5350342],
            [-64.1001095, 46.5350764],
            [-64.0998011, 46.5351553],
            [-64.0994666, 46.5353255],
            [-64.0993046, 46.5355182],
            [-64.0984203, 46.5371696],
            [-64.0982659, 46.5374859],
            [-64.0978899, 46.5385874],
            [-64.0973922, 46.5395238],
            [-64.096443, 46.5408596],
            [-64.0950313, 46.5422082],
            [-64.0941493, 46.5432462],
            [-64.0933768, 46.5439314],
            [-64.0931553, 46.5443542],
            [-64.0926688, 46.544761],
            [-64.092105, 46.545059],
            [-64.0916597, 46.5452593],
            [-64.0914305, 46.5453872],
            [-64.0911697, 46.5455944],
            [-64.0910466, 46.5458315],
            [-64.0908001, 46.5461344],
            [-64.0900033, 46.5468511],
            [-64.0886896, 46.5478426],
            [-64.0880583, 46.5484383],
            [-64.0887689, 46.5489591],
            [-64.0889058, 46.5490091],
            [-64.0886705, 46.5491449],
            [-64.088203, 46.5487831],
            [-64.0878578, 46.5485667],
            [-64.0865284, 46.5489684],
            [-64.0857425, 46.5493467],
            [-64.0851826, 46.5496965],
            [-64.0845308, 46.550033],
            [-64.0839634, 46.5502592],
            [-64.0826716, 46.5507451],
            [-64.0823129, 46.5509467],
            [-64.0808313, 46.551585],
            [-64.0797337, 46.5517791],
            [-64.0790945, 46.5520799],
            [-64.0788202, 46.5521713],
            [-64.078582, 46.5522154],
            [-64.0780398, 46.552199],
            [-64.0775699, 46.5520881],
            [-64.0772183, 46.5520707],
            [-64.0755689, 46.5521845],
            [-64.0744697, 46.5520758],
            [-64.0735637, 46.5520577],
            [-64.0731328, 46.5519912],
            [-64.0725999, 46.5518674],
            [-64.0722569, 46.5517625],
            [-64.0721783, 46.5516935],
            [-64.0721359, 46.5515774],
            [-64.0721775, 46.5515422],
            [-64.0727981, 46.5514562],
            [-64.0732773, 46.5514597],
            [-64.0735908, 46.5515841],
            [-64.074223, 46.5516736],
            [-64.0745813, 46.5516633],
            [-64.0749041, 46.5516802],
            [-64.0750987, 46.5517311],
            [-64.0753767, 46.5518828],
            [-64.0755257, 46.551917],
            [-64.0758076, 46.5519492],
            [-64.0759525, 46.5519316],
            [-64.0759887, 46.5518843],
            [-64.0757711, 46.5516539],
            [-64.0752735, 46.5513314],
            [-64.0747371, 46.5511359],
            [-64.0745873, 46.5509503],
            [-64.0742603, 46.5507102],
            [-64.0740549, 46.5506353],
            [-64.0739226, 46.5506173],
            [-64.0735811, 46.5506439],
            [-64.0732689, 46.5504797],
            [-64.0727833, 46.5499702],
            [-64.0725912, 46.5498397],
            [-64.0722033, 46.5496982],
            [-64.0710866, 46.5495932],
            [-64.0709383, 46.5495391],
            [-64.0708898, 46.5494308],
            [-64.0708923, 46.5491799],
            [-64.0707907, 46.5489313],
            [-64.0703094, 46.548645],
            [-64.0700114, 46.5485766],
            [-64.0697798, 46.5484216],
            [-64.0695988, 46.5479527],
            [-64.0694691, 46.5476838],
            [-64.0694354, 46.5474801],
            [-64.0694336, 46.5470061],
            [-64.0693138, 46.5466099],
            [-64.069197, 46.5464766],
            [-64.0689653, 46.5463216],
            [-64.0687813, 46.5461235],
            [-64.0687786, 46.5460319],
            [-64.068924, 46.545823],
            [-64.0694234, 46.5453807],
            [-64.0696039, 46.5453357],
            [-64.0697688, 46.5452347],
            [-64.0698068, 46.5449565],
            [-64.0697456, 46.5447126],
            [-64.0695153, 46.5445178],
            [-64.0693125, 46.5436582],
            [-64.0690492, 46.5434111],
            [-64.0680361, 46.5426185],
            [-64.0672362, 46.5421957],
            [-64.0669966, 46.5421083],
            [-64.0664846, 46.5420525],
            [-64.0656655, 46.5420357],
            [-64.0654099, 46.5420835],
            [-64.0651556, 46.5420915],
            [-64.0652576, 46.5421488],
            [-64.0655851, 46.5421978],
            [-64.0659138, 46.5422069],
            [-64.0660325, 46.5422805],
            [-64.065938, 46.5423467],
            [-64.0657166, 46.542407],
            [-64.0645312, 46.5424681],
            [-64.064495, 46.5425153],
            [-64.0645387, 46.5425917],
            [-64.0646179, 46.5426407],
            [-64.0653378, 46.5426918],
            [-64.0656913, 46.5426495],
            [-64.0661635, 46.5425096],
            [-64.0663574, 46.542409],
            [-64.0666779, 46.5421432],
            [-64.0667947, 46.5422765],
            [-64.0668245, 46.5425996],
            [-64.0670461, 46.5428819],
            [-64.0675375, 46.5432122],
            [-64.067863, 46.5433209],
            [-64.0680677, 46.5434157],
            [-64.0680825, 46.5434916],
            [-64.0679861, 46.5436175],
            [-64.0675743, 46.5438501],
            [-64.0674845, 46.5439483],
            [-64.0674645, 46.5440316],
            [-64.0674558, 46.5441191],
            [-64.0675924, 46.5445315],
            [-64.0676225, 46.5451972],
            [-64.0675677, 46.5454593],
            [-64.0672775, 46.545857],
            [-64.0670468, 46.5460247],
            [-64.0670026, 46.5461395],
            [-64.0671225, 46.546707],
            [-64.0672321, 46.546888],
            [-64.0679747, 46.5474812],
            [-64.0682993, 46.5481435],
            [-64.0686062, 46.548467],
            [-64.0691388, 46.5489533],
            [-64.0703444, 46.550219],
            [-64.0708352, 46.5505692],
            [-64.0708668, 46.5506613],
            [-64.0708455, 46.5507845],
            [-64.0707423, 46.5509382],
            [-64.0700745, 46.5515811],
            [-64.0699699, 46.5516033],
            [-64.069745, 46.5515918],
            [-64.06862, 46.5512118],
            [-64.0683563, 46.5511559],
            [-64.0678785, 46.5511126],
            [-64.0677295, 46.5510784],
            [-64.0676502, 46.5510293],
            [-64.0673225, 46.5506378],
            [-64.0670264, 46.5505097],
            [-64.0666653, 46.5504283],
            [-64.0662834, 46.550279],
            [-64.0656405, 46.5501653],
            [-64.0650235, 46.5497892],
            [-64.0646403, 46.5496796],
            [-64.0643464, 46.5496631],
            [-64.0639124, 46.5498674],
            [-64.0637608, 46.5499128],
            [-64.0634771, 46.5501115],
            [-64.0633047, 46.5502602],
            [-64.0628794, 46.550377],
            [-64.0625145, 46.5505864],
            [-64.0620805, 46.5507907],
            [-64.0612272, 46.5511039],
            [-64.0605744, 46.5512888],
            [-64.0593626, 46.5514411],
            [-64.0590915, 46.5514328],
            [-64.0589828, 46.5514032],
            [-64.0581706, 46.5509961],
            [-64.0577128, 46.5508694],
            [-64.0566808, 46.5508253],
            [-64.0561486, 46.5509063],
            [-64.0563359, 46.5510049],
            [-64.0572204, 46.5510927],
            [-64.0579594, 46.5512716],
            [-64.0583822, 46.5514057],
            [-64.0592239, 46.5517934],
            [-64.059585, 46.5518748],
            [-64.0599138, 46.5518839],
            [-64.0603775, 46.5518315],
            [-64.0613676, 46.5513969],
            [-64.0619105, 46.5513935],
            [-64.0621661, 46.5513457],
            [-64.0627268, 46.5509761],
            [-64.0631569, 46.5508912],
            [-64.0634313, 46.5507999],
            [-64.0637686, 46.5505503],
            [-64.0639418, 46.550553],
            [-64.0640942, 46.5506589],
            [-64.0641882, 46.5507839],
            [-64.0643225, 46.5509135],
            [-64.0647683, 46.5512272],
            [-64.0650792, 46.5514312],
            [-64.0655477, 46.551582],
            [-64.0658858, 46.5514837],
            [-64.0660133, 46.5514698],
            [-64.0662489, 46.5515053],
            [-64.0666476, 46.5516709],
            [-64.0669302, 46.5518546],
            [-64.067017, 46.5520272],
            [-64.0669547, 46.5521657],
            [-64.0667267, 46.552425],
            [-64.0667586, 46.5528597],
            [-64.066697, 46.5529782],
            [-64.0667099, 46.5531139],
            [-64.0668288, 46.5533587],
            [-64.0671298, 46.5536901],
            [-64.0675521, 46.5540154],
            [-64.0677859, 46.5542819],
            [-64.0678869, 46.5547217],
            [-64.0680004, 46.5549545],
            [-64.0681092, 46.5549841],
            [-64.0683004, 46.5549632],
            [-64.0691345, 46.5547133],
            [-64.0695854, 46.5546964],
            [-64.0700639, 46.5547199],
            [-64.0704942, 46.5548062],
            [-64.0709624, 46.5546144],
            [-64.0712468, 46.5543957],
            [-64.0717776, 46.554408],
            [-64.0720414, 46.5544639],
            [-64.0723986, 46.5546646],
            [-64.0727604, 46.5547261],
            [-64.0732557, 46.5547656],
            [-64.0735356, 46.5548576],
            [-64.0740392, 46.5551722],
            [-64.0743367, 46.5554318],
            [-64.0744061, 46.5556081],
            [-64.0744153, 46.5560345],
            [-64.0743654, 46.5564998],
            [-64.0744499, 46.5570947],
            [-64.0746679, 46.5576677],
            [-64.0748057, 46.557869],
            [-64.0759993, 46.5588078],
            [-64.0760283, 46.5589795],
            [-64.0758542, 46.5593592],
            [-64.0755392, 46.5598085],
            [-64.0751999, 46.5601179],
            [-64.074118, 46.5614156],
            [-64.0726581, 46.5627872],
            [-64.0723256, 46.5632401],
            [-64.0714317, 46.5640826],
            [-64.0709442, 46.5646806],
            [-64.0705555, 46.5652641],
            [-64.0698185, 46.5660732],
            [-64.0691213, 46.5670782],
            [-64.0687081, 46.5675219],
            [-64.0682224, 46.5678887],
            [-64.0680815, 46.5679583],
            [-64.0673361, 46.5683211],
            [-64.0666993, 46.5687135],
            [-64.0665061, 46.5687941],
            [-64.0661859, 46.5688688],
            [-64.0660067, 46.5688739],
            [-64.0657092, 46.5687856],
            [-64.0655575, 46.568831],
            [-64.0653743, 46.5689557],
            [-64.0646125, 46.569111],
            [-64.0635726, 46.5691147],
            [-64.0628778, 46.5691635],
            [-64.0624052, 46.5691322],
            [-64.0622918, 46.5690707],
            [-64.0621306, 46.5688809],
            [-64.0619437, 46.5685911],
            [-64.0619121, 46.568499],
            [-64.0619483, 46.5684518],
            [-64.0621396, 46.5684309],
            [-64.0624276, 46.5684553],
            [-64.0629433, 46.5685829],
            [-64.0641175, 46.5687089],
            [-64.0642853, 46.5686996],
            [-64.0645316, 46.5685879],
            [-64.0646013, 46.5684018],
            [-64.064569, 46.5683295],
            [-64.0643277, 46.5681106],
            [-64.0640056, 46.5680737],
            [-64.0637564, 46.5680937],
            [-64.0635953, 46.5680753],
            [-64.0634476, 46.5680012],
            [-64.0632723, 46.567887],
            [-64.0626257, 46.5677015],
            [-64.0625424, 46.5676006],
            [-64.0625189, 46.5674409],
            [-64.0625651, 46.5672664],
            [-64.0626675, 46.5669612],
            [-64.0629042, 46.5666144],
            [-64.0634476, 46.5662484],
            [-64.0634624, 46.566153],
            [-64.0631824, 46.566061],
            [-64.0626762, 46.5661686],
            [-64.0622709, 46.5662021],
            [-64.0619614, 46.5663008],
            [-64.0616375, 46.5664949],
            [-64.0614455, 46.5665357],
            [-64.0612502, 46.5665047],
            [-64.0612071, 46.5664085],
            [-64.0615345, 46.5652384],
            [-64.0614565, 46.5649782],
            [-64.0613423, 46.5649366],
            [-64.0612605, 46.5649672],
            [-64.0611424, 46.565045],
            [-64.0610029, 46.565246],
            [-64.0608969, 46.5653081],
            [-64.0607399, 46.5653415],
            [-64.060666, 46.5653044],
            [-64.0606189, 46.5651563],
            [-64.0605074, 46.565035],
            [-64.0601643, 46.5649301],
            [-64.0596969, 46.5645682],
            [-64.0594631, 46.5644729],
            [-64.0590106, 46.5643582],
            [-64.0586817, 46.5641777],
            [-64.0584233, 46.5641338],
            [-64.0576105, 46.5642605],
            [-64.0575923, 46.5642841],
            [-64.0576912, 46.5646123],
            [-64.0574728, 46.5652781],
            [-64.057526, 46.5654184],
            [-64.0576556, 46.565516],
            [-64.0577697, 46.5655577],
            [-64.0581503, 46.5655756],
            [-64.0583833, 46.5656908],
            [-64.0584049, 46.5659103],
            [-64.0584379, 46.5659626],
            [-64.0589186, 46.5662689],
            [-64.0589591, 46.5664448],
            [-64.058876, 46.5666865],
            [-64.058774, 46.5668005],
            [-64.0584064, 46.5670895],
            [-64.0577829, 46.5672549],
            [-64.0571646, 46.5672611],
            [-64.0562758, 46.5672392],
            [-64.0560953, 46.5672841],
            [-64.055808, 46.5674111],
            [-64.0555758, 46.5674472],
            [-64.0553126, 46.5673714],
            [-64.0550817, 46.5673677],
            [-64.0549125, 46.5674169],
            [-64.0548809, 46.567496],
            [-64.0548442, 46.5677345],
            [-64.0547495, 46.5678007],
            [-64.054379, 46.5678267],
            [-64.0537784, 46.5680005],
            [-64.053151, 46.5684567],
            [-64.0531067, 46.5685715],
            [-64.0531672, 46.5686641],
            [-64.053235, 46.568709],
            [-64.0536748, 46.5688594],
            [-64.053975, 46.5690394],
            [-64.0540161, 46.5693667],
            [-64.053959, 46.5695172],
            [-64.0536881, 46.5698515],
            [-64.0531827, 46.5702818],
            [-64.0526525, 46.5705921],
            [-64.0522654, 46.5707732],
            [-64.0518217, 46.5709136],
            [-64.0515545, 46.5709572],
            [-64.0515962, 46.5710933],
            [-64.0516358, 46.5711178],
            [-64.051848, 46.571165],
            [-64.0520581, 46.5711006],
            [-64.0524923, 46.5708963],
            [-64.052591, 46.5708819],
            [-64.0527461, 46.5709083],
            [-64.0527898, 46.5709847],
            [-64.05261, 46.571181],
            [-64.052116, 46.5714441],
            [-64.0520912, 46.5714955],
            [-64.0521288, 46.5715798],
            [-64.0529337, 46.5715208],
            [-64.0534539, 46.5713378],
            [-64.0539358, 46.5710904],
            [-64.0549645, 46.5703776],
            [-64.0550597, 46.5702915],
            [-64.0552671, 46.5701354],
            [-64.0558148, 46.5699926],
            [-64.0559672, 46.5699273],
            [-64.0568203, 46.5698053],
            [-64.0573701, 46.5697741],
            [-64.0576581, 46.5697986],
            [-64.0580595, 46.5697133],
            [-64.0582998, 46.5696095],
            [-64.0584743, 46.5695724],
            [-64.0593651, 46.5695346],
            [-64.059621, 46.5696582],
            [-64.0595476, 46.5703064],
            [-64.0594255, 46.5705036],
            [-64.059078, 46.5707093],
            [-64.0589995, 46.5708116],
            [-64.058905, 46.5710492],
            [-64.058679, 46.5714201],
            [-64.0585891, 46.5715183],
            [-64.0581675, 46.5716869],
            [-64.0578407, 46.5717894],
            [-64.0576876, 46.5718746],
            [-64.0575039, 46.5721904],
            [-64.0561737, 46.5752011],
            [-64.0559491, 46.5757036],
            [-64.0556164, 46.5763278],
            [-64.0555079, 46.5768121],
            [-64.0553883, 46.5778062],
            [-64.0554618, 46.578377],
            [-64.0555465, 46.5796572],
            [-64.0556421, 46.580085],
            [-64.0561829, 46.5817229],
            [-64.0563466, 46.5825383],
            [-64.0564926, 46.5830146],
            [-64.0565823, 46.5834503],
            [-64.0564899, 46.5839708],
            [-64.0563551, 46.5842037],
            [-64.0561886, 46.5843445],
            [-64.0560073, 46.5844094],
            [-64.0558965, 46.5844395],
            [-64.0557877, 46.5844099],
            [-64.0554747, 46.5842655],
            [-64.0553813, 46.5841206],
            [-64.0553731, 46.5838456],
            [-64.0552857, 46.5836928],
            [-64.0551842, 46.5836155],
            [-64.0547691, 46.5834138],
            [-64.0543667, 46.583005],
            [-64.0542644, 46.5829477],
            [-64.0541489, 46.5829458],
            [-64.053804, 46.5830719],
            [-64.0527276, 46.5832939],
            [-64.0521414, 46.5833723],
            [-64.0515364, 46.5834942],
            [-64.0513753, 46.5834757],
            [-64.0509407, 46.5833374],
            [-64.0505244, 46.5833467],
            [-64.050245, 46.5834061],
            [-64.050139, 46.5834681],
            [-64.0501605, 46.5835163],
            [-64.0506844, 46.5837477],
            [-64.0507422, 46.5839199],
            [-64.0506671, 46.584094],
            [-64.0505073, 46.584207],
            [-64.0495123, 46.5845897],
            [-64.0488126, 46.5847778],
            [-64.0484566, 46.5847084],
            [-64.0479947, 46.5848724],
            [-64.0476287, 46.5849304],
            [-64.0474615, 46.5849197],
            [-64.0469564, 46.5848161],
            [-64.0463231, 46.584575],
            [-64.0459657, 46.5843741],
            [-64.0458449, 46.5843603],
            [-64.0457362, 46.5848446],
            [-64.0455919, 46.5850136],
            [-64.0454744, 46.5850715],
            [-64.0444792, 46.5852828],
            [-64.0443154, 46.5853439],
            [-64.0441845, 46.5854574],
            [-64.0440711, 46.5857385],
            [-64.0438589, 46.5858626],
            [-64.0434083, 46.5860307],
            [-64.0431552, 46.5861701],
            [-64.0431008, 46.586241],
            [-64.0432701, 46.5863632],
            [-64.0434023, 46.5863812],
            [-64.0435616, 46.5864595],
            [-64.043939, 46.5864057],
            [-64.0447998, 46.5860648],
            [-64.0452847, 46.5859091],
            [-64.0464686, 46.5857566],
            [-64.0472986, 46.5856462],
            [-64.0478909, 46.5857313],
            [-64.0484302, 46.5856761],
            [-64.0485041, 46.5857132],
            [-64.0485477, 46.5857895],
            [-64.0485035, 46.5859044],
            [-64.0480725, 46.5863517],
            [-64.0473795, 46.5868547],
            [-64.0470063, 46.587303],
            [-64.0462222, 46.5877726],
            [-64.0457495, 46.5880837],
            [-64.0456783, 46.5883097],
            [-64.0461571, 46.5881619],
            [-64.0467569, 46.5880281],
            [-64.0473758, 46.5874881],
            [-64.0477598, 46.5872352],
            [-64.0489758, 46.5866409],
            [-64.0497077, 46.5861824],
            [-64.050036, 46.5860402],
            [-64.0511438, 46.5853964],
            [-64.0514292, 46.5853292],
            [-64.0516609, 46.5853129],
            [-64.0518859, 46.5853244],
            [-64.0522116, 46.5854331],
            [-64.0525622, 46.5854905],
            [-64.0529, 46.5854121],
            [-64.0530906, 46.5852399],
            [-64.0531536, 46.5850815],
            [-64.0532058, 46.5847277],
            [-64.0532649, 46.5846888],
            [-64.0535389, 46.5846175],
            [-64.054019, 46.5846011],
            [-64.0541909, 46.5846437],
            [-64.0542695, 46.5847126],
            [-64.0544288, 46.5849622],
            [-64.0546276, 46.5850649],
            [-64.0549769, 46.585162],
            [-64.0551745, 46.5853046],
            [-64.0552061, 46.5853967],
            [-64.0551551, 46.5855394],
            [-64.0549605, 46.5858311],
            [-64.0548606, 46.5860566],
            [-64.0549282, 46.586978],
            [-64.0550069, 46.5872183],
            [-64.0549211, 46.5875396],
            [-64.0548063, 46.5876892],
            [-64.054564, 46.5878527],
            [-64.0544492, 46.5880023],
            [-64.0543627, 46.5881722],
            [-64.0542193, 46.5888352],
            [-64.0542694, 46.5895891],
            [-64.0541696, 46.589986],
            [-64.0540012, 46.5903578],
            [-64.0532179, 46.5913216],
            [-64.0531803, 46.5914086],
            [-64.0532268, 46.5915767],
            [-64.0534237, 46.5919105],
            [-64.0533641, 46.5921406],
            [-64.0528017, 46.593064],
            [-64.0527105, 46.5933734],
            [-64.0528162, 46.5938452],
            [-64.0527948, 46.5939683],
            [-64.0526464, 46.5940855],
            [-64.0521279, 46.5942088],
            [-64.0517243, 46.5943538],
            [-64.0511187, 46.5948383],
            [-64.051016, 46.5949721],
            [-64.0507897, 46.5951718],
            [-64.0504136, 46.595357],
            [-64.0501209, 46.5956433],
            [-64.0498699, 46.5960656],
            [-64.0494542, 46.5976169],
            [-64.0494152, 46.5979151],
            [-64.0494322, 46.5981026],
            [-64.0495271, 46.598379],
            [-64.0494559, 46.5987763],
            [-64.0495837, 46.599105],
            [-64.0497101, 46.5993023],
            [-64.0497189, 46.5993861],
            [-64.049686, 46.5995051],
            [-64.0495315, 46.5996301],
            [-64.049185, 46.599796],
            [-64.0488652, 46.5998507],
            [-64.0484541, 46.5998721],
            [-64.0476546, 46.5997518],
            [-64.0474403, 46.599593],
            [-64.0473778, 46.5995602],
            [-64.0470956, 46.5995278],
            [-64.0469277, 46.5995371],
            [-64.0466234, 46.5996478],
            [-64.0463326, 46.599703],
            [-64.045912, 46.5996605],
            [-64.045646, 46.5996642],
            [-64.0458099, 46.5997744],
            [-64.0463266, 46.5998822],
            [-64.0465866, 46.6000576],
            [-64.0467539, 46.6000682],
            [-64.0472718, 46.5999649],
            [-64.0475936, 46.6000218],
            [-64.0479458, 46.600382],
            [-64.0482105, 46.6005894],
            [-64.0482798, 46.6007658],
            [-64.0483094, 46.6009176],
            [-64.0482638, 46.6010723],
            [-64.0481389, 46.6011779],
            [-64.0478763, 46.6012534],
            [-64.0477466, 46.6013271],
            [-64.0477218, 46.6013785],
            [-64.0478838, 46.6015484],
            [-64.0478925, 46.6016322],
            [-64.0478617, 46.6018628],
            [-64.0479054, 46.6019392],
            [-64.0478639, 46.6021457],
            [-64.047911, 46.6022939],
            [-64.0480306, 46.6023476],
            [-64.0482195, 46.6024063],
            [-64.0482625, 46.6025026],
            [-64.0481853, 46.6027365],
            [-64.0481496, 46.6027638],
            [-64.0479985, 46.6027893],
            [-64.0477756, 46.6028894],
            [-64.0477588, 46.6030445],
            [-64.0477817, 46.6032242],
            [-64.0478791, 46.6034209],
            [-64.047812, 46.6035275],
            [-64.0473874, 46.6036044],
            [-64.0473955, 46.6037081],
            [-64.0474386, 46.6038044],
            [-64.0476476, 46.6039512],
            [-64.0480903, 46.6040219],
            [-64.0483235, 46.6041372],
            [-64.0488088, 46.6046668],
            [-64.0491287, 46.6049548],
            [-64.049194, 46.6050793],
            [-64.0492324, 46.605315],
            [-64.0491902, 46.6057128],
            [-64.0492602, 46.6060406],
            [-64.0492267, 46.6061795],
            [-64.0491313, 46.6062657],
            [-64.0488956, 46.6064014],
            [-64.0488593, 46.6064486],
            [-64.0488922, 46.6065009],
            [-64.0491052, 46.6065281],
            [-64.0491449, 46.6065527],
            [-64.0495175, 46.6073435],
            [-64.0496543, 46.6084414],
            [-64.0497297, 46.6086099],
            [-64.0497908, 46.6086826],
            [-64.0500409, 46.608814],
            [-64.0500779, 46.6089182],
            [-64.0500349, 46.6089933],
            [-64.0499697, 46.60904],
            [-64.0497655, 46.6090966],
            [-64.0496654, 46.6091508],
            [-64.0503455, 46.6093886],
            [-64.0513261, 46.6099779],
            [-64.0515949, 46.6100658],
            [-64.0520156, 46.6101083],
            [-64.0521587, 46.6101504],
            [-64.0526017, 46.6103925],
            [-64.0528968, 46.6107318],
            [-64.0529924, 46.6109884],
            [-64.0530128, 46.6115903],
            [-64.0529659, 46.6117848],
            [-64.0527122, 46.6122869],
            [-64.0527217, 46.6125221],
            [-64.0528764, 46.6127397],
            [-64.0529, 46.6128995],
            [-64.0528335, 46.6129861],
            [-64.0528277, 46.6135079],
            [-64.052768, 46.6137381],
            [-64.05267, 46.6139039],
            [-64.0524974, 46.6140526],
            [-64.05214, 46.6143657],
            [-64.0518565, 46.6145445],
            [-64.0506461, 46.6156451],
            [-64.0505138, 46.6159697],
            [-64.050809, 46.6163091],
            [-64.0509966, 46.6164077],
            [-64.0510289, 46.6164799],
            [-64.0509483, 46.616642],
            [-64.0506036, 46.6167481],
            [-64.0504618, 46.6168375],
            [-64.0503893, 46.616932],
            [-64.050466, 46.6170607],
            [-64.0506071, 46.6171626],
            [-64.0506267, 46.6172704],
            [-64.0505602, 46.6173571],
            [-64.0503882, 46.6174858],
            [-64.0492687, 46.617763],
            [-64.048234, 46.6184439],
            [-64.0478256, 46.6188997],
            [-64.0477067, 46.6191687],
            [-64.0477048, 46.6193998],
            [-64.0476558, 46.6194827],
            [-64.0472271, 46.6198505],
            [-64.0467762, 46.6200187],
            [-64.0466042, 46.6201474],
            [-64.0464295, 46.6203558],
            [-64.0461756, 46.6205152],
            [-64.0456031, 46.6210321],
            [-64.0456791, 46.6211807],
            [-64.045464, 46.6212132],
            [-64.0450273, 46.6216485],
            [-64.0444294, 46.6220654],
            [-64.044176, 46.6222048],
            [-64.0438078, 46.6224939],
            [-64.0434476, 46.6227153],
            [-64.0431378, 46.6228139],
            [-64.0428092, 46.6229562],
            [-64.0426264, 46.6230608],
            [-64.0422857, 46.6233901],
            [-64.0426668, 46.6234081],
            [-64.0427501, 46.6233377],
            [-64.0427729, 46.6231747],
            [-64.0429147, 46.6230853],
            [-64.0433455, 46.6230005],
            [-64.0435801, 46.6229046],
            [-64.0439577, 46.6226795],
            [-64.0440625, 46.6224859],
            [-64.0446505, 46.6221964],
            [-64.0451135, 46.6218412],
            [-64.0453654, 46.6215702],
            [-64.0456073, 46.6214266],
            [-64.0458822, 46.6215067],
            [-64.045981, 46.6214923],
            [-64.0460367, 46.6213816],
            [-64.0459217, 46.6210172],
            [-64.0459552, 46.6208783],
            [-64.0465727, 46.620398],
            [-64.0470034, 46.6201418],
            [-64.0473536, 46.6200477],
            [-64.0479119, 46.6196063],
            [-64.0480568, 46.6190747],
            [-64.0481683, 46.6188533],
            [-64.0490242, 46.6181576],
            [-64.0497008, 46.6178097],
            [-64.0502028, 46.6176702],
            [-64.0505697, 46.6175923],
            [-64.0508579, 46.6174454],
            [-64.0509359, 46.617363],
            [-64.0509741, 46.617256],
            [-64.0509774, 46.6171564],
            [-64.0507818, 46.6169541],
            [-64.0507622, 46.6168462],
            [-64.0508281, 46.6167795],
            [-64.0511378, 46.6166808],
            [-64.0512695, 46.6165474],
            [-64.0513024, 46.6164284],
            [-64.0512761, 46.6163483],
            [-64.0509951, 46.6161048],
            [-64.0508975, 46.615908],
            [-64.0509673, 46.6157219],
            [-64.0516499, 46.6153661],
            [-64.0522304, 46.6149529],
            [-64.0524521, 46.6148926],
            [-64.0525791, 46.6148986],
            [-64.0531397, 46.6152541],
            [-64.0532432, 46.6152717],
            [-64.0533595, 46.6152536],
            [-64.0534247, 46.6152068],
            [-64.0534495, 46.6151554],
            [-64.0534004, 46.615067],
            [-64.0532989, 46.6149896],
            [-64.052944, 46.6148804],
            [-64.0527517, 46.6147499],
            [-64.0527335, 46.6146022],
            [-64.0528531, 46.6144845],
            [-64.0528738, 46.6143813],
            [-64.0531573, 46.6140311],
            [-64.0532559, 46.6138454],
            [-64.0532538, 46.6135625],
            [-64.0532119, 46.613255],
            [-64.053594, 46.6113286],
            [-64.053554, 46.61079],
            [-64.0533662, 46.6101774],
            [-64.0532835, 46.6100566],
            [-64.0531477, 46.6099668],
            [-64.0529475, 46.6099039],
            [-64.0522573, 46.6097934],
            [-64.0520181, 46.609686],
            [-64.0514749, 46.6091555],
            [-64.0510534, 46.6087902],
            [-64.0508066, 46.6083879],
            [-64.0507514, 46.6079646],
            [-64.0507291, 46.6077651],
            [-64.0505119, 46.6075146],
            [-64.0504653, 46.6070039],
            [-64.0503349, 46.6067548],
            [-64.050442, 46.6059677],
            [-64.0505131, 46.6057417],
            [-64.0504949, 46.605594],
            [-64.0502575, 46.6049129],
            [-64.0502015, 46.6045096],
            [-64.0496219, 46.6035123],
            [-64.0495325, 46.6034192],
            [-64.0489756, 46.6031355],
            [-64.0489211, 46.603035],
            [-64.048962, 46.6028484],
            [-64.04889, 46.6027516],
            [-64.0488161, 46.6027146],
            [-64.0487651, 46.6026859],
            [-64.0487348, 46.602554],
            [-64.0487623, 46.6024229],
            [-64.0488872, 46.6023173],
            [-64.0491169, 46.6021895],
            [-64.0492209, 46.6020158],
            [-64.0492396, 46.6018009],
            [-64.04921, 46.6016491],
            [-64.0489996, 46.6015421],
            [-64.0489136, 46.6015209],
            [-64.0488988, 46.6014449],
            [-64.0489492, 46.6013222],
            [-64.0492937, 46.6012161],
            [-64.0497969, 46.6012081],
            [-64.0500132, 46.6011358],
            [-64.0503114, 46.6008617],
            [-64.0511392, 46.5999544],
            [-64.0512923, 46.5996978],
            [-64.0513332, 46.5995112],
            [-64.0512819, 46.5991399],
            [-64.0513114, 46.5989491],
            [-64.0514161, 46.5987555],
            [-64.0514986, 46.5985337],
            [-64.0514856, 46.5977127],
            [-64.0515361, 46.5968848],
            [-64.0515125, 46.5967251],
            [-64.0515735, 46.5964551],
            [-64.0517098, 46.5963537],
            [-64.0519033, 46.5962731],
            [-64.0520658, 46.5962517],
            [-64.0526019, 46.596296],
            [-64.0527778, 46.5962191],
            [-64.0535191, 46.5956531],
            [-64.0539071, 46.5952807],
            [-64.0543153, 46.594825],
            [-64.0551599, 46.5941052],
            [-64.0553149, 46.593789],
            [-64.0555166, 46.5931069],
            [-64.0554883, 46.5929152],
            [-64.0553022, 46.5927768],
            [-64.0551777, 46.5923485],
            [-64.0552816, 46.5920035],
            [-64.0554957, 46.5916483],
            [-64.055795, 46.5913343],
            [-64.0561502, 46.5910809],
            [-64.0569952, 46.5901699],
            [-64.0571415, 46.5899412],
            [-64.0572092, 46.5896434],
            [-64.0571921, 46.5892846],
            [-64.0572162, 46.5890818],
            [-64.0572954, 46.5889594],
            [-64.057574, 46.5887487],
            [-64.0577536, 46.5882096],
            [-64.057926, 46.5878898],
            [-64.0580555, 46.5876448],
            [-64.0585138, 46.5870663],
            [-64.0587593, 46.5866319],
            [-64.0589091, 46.5864749],
            [-64.0594414, 46.5861048],
            [-64.0596562, 46.585901],
            [-64.0600094, 46.5858787],
            [-64.0604195, 46.5855345],
            [-64.0605242, 46.5855123],
            [-64.060813, 46.5855168],
            [-64.0608698, 46.585195],
            [-64.0610397, 46.585126],
            [-64.0612143, 46.5850889],
            [-64.0617971, 46.5849386],
            [-64.0618984, 46.5848446],
            [-64.0619929, 46.5846071],
            [-64.0622821, 46.5842491],
            [-64.0625244, 46.5840855],
            [-64.0626861, 46.5839128],
            [-64.0628068, 46.583584],
            [-64.0628975, 46.582932],
            [-64.0628438, 46.5828117],
            [-64.0626199, 46.582589],
            [-64.0625452, 46.5824006],
            [-64.062708, 46.5818454],
            [-64.0626643, 46.581769],
            [-64.0625132, 46.5817946],
            [-64.0621105, 46.582091],
            [-64.0618932, 46.5823745],
            [-64.061528, 46.5825839],
            [-64.0612313, 46.5826469],
            [-64.0609774, 46.582635],
            [-64.0607801, 46.5826638],
            [-64.0605237, 46.5829028],
            [-64.0604129, 46.5829329],
            [-64.0603269, 46.5829116],
            [-64.0603652, 46.5828046],
            [-64.0605214, 46.5826199],
            [-64.0608416, 46.5823739],
            [-64.0612591, 46.5821534],
            [-64.0617975, 46.5819467],
            [-64.0622459, 46.5816669],
            [-64.06303, 46.5813684],
            [-64.06342, 46.5812789],
            [-64.0642494, 46.5813596],
            [-64.0644663, 46.5814387],
            [-64.0644999, 46.5814711],
            [-64.0646202, 46.5815049],
            [-64.0654365, 46.5812786],
            [-64.0657636, 46.5813475],
            [-64.0663213, 46.5817825],
            [-64.0678772, 46.5826116],
            [-64.0689131, 46.5830899],
            [-64.0697488, 46.583334],
            [-64.0709396, 46.583496],
            [-64.0721775, 46.5838061],
            [-64.0728968, 46.583897],
            [-64.0731399, 46.5838849],
            [-64.073728, 46.5837466],
            [-64.0740602, 46.5834849],
            [-64.0747101, 46.5833994],
            [-64.0753291, 46.5832018],
            [-64.0757816, 46.5831451],
            [-64.0759615, 46.58312],
            [-64.0762259, 46.5829847],
            [-64.0776497, 46.5825566],
            [-64.0782712, 46.5822794],
            [-64.0791821, 46.5819868],
            [-64.079456, 46.5819153],
            [-64.0802094, 46.5818473],
            [-64.080705, 46.5818869],
            [-64.080922, 46.5819659],
            [-64.0812553, 46.5821982],
            [-64.0815014, 46.582449],
            [-64.0815553, 46.5825693],
            [-64.081505, 46.5826921],
            [-64.0813246, 46.5827371],
            [-64.0808987, 46.5826827],
            [-64.080765, 46.5827045],
            [-64.0807061, 46.5827434],
            [-64.0806599, 46.582918],
            [-64.0806836, 46.5830777],
            [-64.0808661, 46.5835069],
            [-64.080973, 46.5835962],
            [-64.0810758, 46.5836336],
            [-64.0812141, 46.5836437],
            [-64.081713, 46.5835837],
            [-64.0819722, 46.5836076],
            [-64.0820865, 46.5836492],
            [-64.0823056, 46.5838399],
            [-64.0825109, 46.5842773],
            [-64.0826609, 46.5844629],
            [-64.0830654, 46.5846405],
            [-64.0838587, 46.5847683],
            [-64.0841729, 46.5847014],
            [-64.0847995, 46.5842649],
            [-64.0855924, 46.5835162],
            [-64.0857109, 46.5832471],
            [-64.0857929, 46.582854],
            [-64.0858527, 46.5827951],
            [-64.0860145, 46.5827936],
            [-64.0861966, 46.5834139],
            [-64.0863541, 46.5837231],
            [-64.0865839, 46.5843004],
            [-64.0866166, 46.5847152],
            [-64.0867386, 46.5850517],
            [-64.0869524, 46.5852303],
            [-64.0870293, 46.5855303],
            [-64.0869839, 46.585685],
            [-64.0867794, 46.5859328],
            [-64.0863197, 46.5862086],
            [-64.0859021, 46.5862579],
            [-64.0856536, 46.5862581],
            [-64.0853842, 46.5861902],
            [-64.0851275, 46.5860866],
            [-64.0847538, 46.5858498],
            [-64.0845368, 46.5857707],
            [-64.0838864, 46.5860475],
            [-64.0837118, 46.5860847],
            [-64.0834836, 46.5861728],
            [-64.0833239, 46.5862859],
            [-64.0832511, 46.5865716],
            [-64.0832639, 46.5867072],
            [-64.0832029, 46.5868059],
            [-64.0830572, 46.5868435],
            [-64.0827066, 46.5867863],
            [-64.0826193, 46.5868048],
            [-64.0824482, 46.5869137],
            [-64.0823146, 46.5869356],
            [-64.0821137, 46.5868926],
            [-64.0819665, 46.5867987],
            [-64.0818516, 46.586777],
            [-64.0817644, 46.5867956],
            [-64.0816094, 46.5869406],
            [-64.0815178, 46.5872699],
            [-64.081346, 46.5873987],
            [-64.0807122, 46.5875203],
            [-64.0802725, 46.5877127],
            [-64.0798439, 46.5881004],
            [-64.0796056, 46.5883158],
            [-64.0792006, 46.5888633],
            [-64.0787642, 46.5896614],
            [-64.0786321, 46.5898148],
            [-64.0786235, 46.5899023],
            [-64.0788272, 46.5907421],
            [-64.0787683, 46.5909524],
            [-64.0784021, 46.591373],
            [-64.0780174, 46.5921798],
            [-64.0777458, 46.5925341],
            [-64.0772048, 46.5928205],
            [-64.076892, 46.5930189],
            [-64.0766356, 46.5932579],
            [-64.0765356, 46.5933122],
            [-64.0761353, 46.5933577],
            [-64.076036, 46.593392],
            [-64.0757091, 46.5936659],
            [-64.0753425, 46.5937438],
            [-64.0752418, 46.593818],
            [-64.0751566, 46.5939481],
            [-64.0748505, 46.5941187],
            [-64.0744098, 46.5946935],
            [-64.074377, 46.5948125],
            [-64.0744167, 46.594837],
            [-64.0751219, 46.5948321],
            [-64.0753511, 46.5948954],
            [-64.0753672, 46.5949315],
            [-64.075229, 46.5950927],
            [-64.0751613, 46.5952191],
            [-64.0751822, 46.5952872],
            [-64.0753309, 46.5955126],
            [-64.0754601, 46.5958015],
            [-64.0754845, 46.5959413],
            [-64.0752453, 46.5965392],
            [-64.0756702, 46.59751],
            [-64.0757808, 46.5978424],
            [-64.0761874, 46.5986655],
            [-64.076375, 46.5989353],
            [-64.076736, 46.5992079],
            [-64.0770293, 46.5996069],
            [-64.0773313, 46.6006235],
            [-64.0775102, 46.6013435],
            [-64.0775815, 46.6014601],
            [-64.078135, 46.6020345],
            [-64.0782629, 46.6021919],
            [-64.0784494, 46.6025015],
            [-64.0784866, 46.602777],
            [-64.0785654, 46.6030173],
            [-64.0788594, 46.6033964],
            [-64.0790053, 46.6035301],
            [-64.0795486, 46.6038892],
            [-64.0798646, 46.6041252],
            [-64.0801772, 46.6042894],
            [-64.0806075, 46.604567],
            [-64.0809673, 46.6048793],
            [-64.0816115, 46.6053355],
            [-64.0816895, 46.6054244],
            [-64.0817486, 46.6059194],
            [-64.081841, 46.6062754],
            [-64.0820059, 46.6065368],
            [-64.0821909, 46.606715],
            [-64.0825783, 46.6070676],
            [-64.0827359, 46.6073769],
            [-64.0827913, 46.6076287],
            [-64.08277, 46.6077519],
            [-64.082605, 46.6080243],
            [-64.0822673, 46.6082741],
            [-64.0819133, 46.6083164],
            [-64.0816942, 46.6082971],
            [-64.0814586, 46.6084329],
            [-64.0813827, 46.6084556],
            [-64.0813141, 46.6084307],
            [-64.0811729, 46.6083289],
            [-64.0807522, 46.6082865],
            [-64.0805748, 46.608232],
            [-64.0805015, 46.608175],
            [-64.0804577, 46.6080987],
            [-64.0804562, 46.6079671],
            [-64.0811496, 46.6079779],
            [-64.0817838, 46.6078562],
            [-64.0819067, 46.6078103],
            [-64.0819315, 46.6077589],
            [-64.0819039, 46.6077186],
            [-64.0812532, 46.6074615],
            [-64.0807422, 46.6071747],
            [-64.0805057, 46.6071591],
            [-64.0799548, 46.6072103],
            [-64.0796322, 46.6071735],
            [-64.0794548, 46.6071189],
            [-64.0786278, 46.6065961],
            [-64.0784732, 46.6065499],
            [-64.078277, 46.6065389],
            [-64.0780398, 46.6065431],
            [-64.0772688, 46.6067862],
            [-64.0771359, 46.6069594],
            [-64.0768632, 46.6069911],
            [-64.076301, 46.6072094],
            [-64.076246, 46.6073002],
            [-64.0765088, 46.6073959],
            [-64.076986, 46.6074791],
            [-64.0773803, 46.6074414],
            [-64.0776283, 46.6074611],
            [-64.078287, 46.6076506],
            [-64.0791552, 46.6077956],
            [-64.0793098, 46.6078418],
            [-64.0794161, 46.607951],
            [-64.079343, 46.6080655],
            [-64.0792893, 46.6081164],
            [-64.0788943, 46.608174],
            [-64.0782139, 46.6084703],
            [-64.0780192, 46.6085908],
            [-64.0779582, 46.6086895],
            [-64.0778617, 46.6089868],
            [-64.0774898, 46.6092241],
            [-64.0773233, 46.6093649],
            [-64.0771083, 46.6093974],
            [-64.0763584, 46.609346],
            [-64.075876, 46.6094221],
            [-64.0755536, 46.6095566],
            [-64.0750984, 46.6098643],
            [-64.0750259, 46.6099588],
            [-64.0749629, 46.6101172],
            [-64.0755453, 46.6099868],
            [-64.0760963, 46.6099356],
            [-64.0762864, 46.6099545],
            [-64.0771213, 46.6102384],
            [-64.0773283, 46.6102734],
            [-64.0774734, 46.6102558],
            [-64.0779181, 46.6100953],
            [-64.0781841, 46.6100915],
            [-64.0782527, 46.6101165],
            [-64.0783012, 46.6102248],
            [-64.0783691, 46.6102697],
            [-64.0784551, 46.6102909],
            [-64.0786473, 46.6102501],
            [-64.0795916, 46.6098384],
            [-64.0802055, 46.6096287],
            [-64.0806346, 46.6094123],
            [-64.0808953, 46.6093964],
            [-64.0809746, 46.6094454],
            [-64.0810016, 46.6095056],
            [-64.0809459, 46.6096163],
            [-64.0806485, 46.6098707],
            [-64.080316, 46.6099612],
            [-64.0800448, 46.6101243],
            [-64.0799729, 46.6101989],
            [-64.0799509, 46.610342],
            [-64.0799951, 46.610761],
            [-64.0803186, 46.6116546],
            [-64.0803873, 46.6123848],
            [-64.080532, 46.6130923],
            [-64.0806492, 46.6133969],
            [-64.0807946, 46.6135506],
            [-64.0808883, 46.6138668],
            [-64.0808245, 46.6144077],
            [-64.0808257, 46.6149018],
            [-64.0810077, 46.6153509],
            [-64.0810947, 46.6156949],
            [-64.0811078, 46.6170697],
            [-64.0812735, 46.6180166],
            [-64.0814266, 46.6184653],
            [-64.0815905, 46.618938],
            [-64.0817735, 46.6191759],
            [-64.0817699, 46.6194667],
            [-64.0818911, 46.6196519],
            [-64.0822133, 46.61988],
            [-64.0824037, 46.6200702],
            [-64.0824414, 46.6201545],
            [-64.0823014, 46.6205468],
            [-64.0821209, 46.6207631],
            [-64.0819552, 46.6210554],
            [-64.0818896, 46.6212935],
            [-64.0818972, 46.6214171],
            [-64.0820492, 46.621543],
            [-64.0821327, 46.6216439],
            [-64.0821793, 46.621812],
            [-64.0821478, 46.6224251],
            [-64.0820768, 46.6226512],
            [-64.0818911, 46.6230268],
            [-64.0816808, 46.6236253],
            [-64.0815835, 46.6237712],
            [-64.0811442, 46.624115],
            [-64.0810826, 46.6242336],
            [-64.0809792, 46.6243874],
            [-64.0809331, 46.624562],
            [-64.0808666, 46.6246487],
            [-64.080253, 46.625],
            [-64.0801889, 46.6250367],
            [-64.0798167, 46.6252741],
            [-64.0797745, 46.6253292],
            [-64.0795313, 46.6255127],
            [-64.0792619, 46.6256161],
            [-64.0790201, 46.6257598],
            [-64.0787917, 46.6258479],
            [-64.0785255, 46.6258517],
            [-64.0778915, 46.6257822],
            [-64.0775615, 46.625793],
            [-64.0773383, 46.6257218],
            [-64.0772964, 46.6255856],
            [-64.0773297, 46.6252754],
            [-64.0772676, 46.6250512],
            [-64.0772178, 46.625],
            [-64.0767094, 46.625],
            [-64.0762453, 46.6257168],
            [-64.076171, 46.6260424],
            [-64.0760985, 46.6261369],
            [-64.0760032, 46.626223],
            [-64.0759098, 46.6262495],
            [-64.0754973, 46.6264822],
            [-64.0752172, 46.6267328],
            [-64.0751238, 46.6267593],
            [-64.0745619, 46.6267864],
            [-64.0742985, 46.6268819],
            [-64.0739316, 46.6269599],
            [-64.0735506, 46.6271134],
            [-64.0731059, 46.6274452],
            [-64.072745, 46.6275152],
            [-64.0725776, 46.6275047],
            [-64.0723839, 46.627414],
            [-64.0721292, 46.627422],
            [-64.0712597, 46.6278308],
            [-64.071044, 46.6278832],
            [-64.070599, 46.6278723],
            [-64.0704607, 46.6280335],
            [-64.0702924, 46.6285768],
            [-64.0701958, 46.6287028],
            [-64.0701306, 46.6287496],
            [-64.0698081, 46.6288841],
            [-64.069526, 46.6291945],
            [-64.0689822, 46.6291979],
            [-64.0688747, 46.6292999],
            [-64.0687996, 46.629474],
            [-64.068395, 46.6296391],
            [-64.0682817, 46.6299202],
            [-64.0682038, 46.6300027],
            [-64.067931, 46.6302056],
            [-64.0677019, 46.6303136],
            [-64.0677087, 46.6304572],
            [-64.0679811, 46.6306168],
            [-64.0680302, 46.6307053],
            [-64.0680088, 46.6308285],
            [-64.0678417, 46.6311606],
            [-64.0677507, 46.6316413],
            [-64.0673593, 46.6322847],
            [-64.0671672, 46.6324969],
            [-64.0670027, 46.6327494],
            [-64.066872, 46.6332056],
            [-64.0665419, 46.6339217],
            [-64.0665139, 46.634244],
            [-64.0665574, 46.6348544],
            [-64.0665046, 46.6352281],
            [-64.0665209, 46.6354356],
            [-64.0668391, 46.6364886],
            [-64.066789, 46.6367827],
            [-64.0665877, 46.6372736],
            [-64.0664212, 46.6375858],
            [-64.066373, 46.6378202],
            [-64.066402, 46.637992],
            [-64.0666032, 46.6382063],
            [-64.0669275, 46.6383748],
            [-64.0671507, 46.638446],
            [-64.0675024, 46.6384834],
            [-64.0678057, 46.6385838],
            [-64.068021, 46.6387226],
            [-64.0683353, 46.6390184],
            [-64.0686852, 46.6392869],
            [-64.0688802, 46.6393377],
            [-64.069088, 46.6393529],
            [-64.0693199, 46.6393366],
            [-64.0694833, 46.6392953],
            [-64.0700135, 46.6390047],
            [-64.0701823, 46.6389755],
            [-64.0703033, 46.6389893],
            [-64.070308, 46.6390213],
            [-64.0700334, 46.6394553],
            [-64.0696899, 46.6395217],
            [-64.0695078, 46.6396065],
            [-64.0694792, 46.6399488],
            [-64.0693542, 46.6400544],
            [-64.0690853, 46.6401379],
            [-64.0690262, 46.6401769],
            [-64.0688692, 46.640553],
            [-64.0686239, 46.6407962],
            [-64.0684548, 46.6411881],
            [-64.0683171, 46.6413294],
            [-64.0677305, 46.6417506],
            [-64.0674155, 46.6421801],
            [-64.0672495, 46.642301],
            [-64.0671367, 46.6425622],
            [-64.0672576, 46.6431101],
            [-64.0673866, 46.6430563],
            [-64.0674995, 46.6429664],
            [-64.0680986, 46.6421668],
            [-64.0686369, 46.6418086],
            [-64.0694825, 46.6414313],
            [-64.0698427, 46.6412097],
            [-64.07007, 46.6411615],
            [-64.0702206, 46.6411558],
            [-64.070339, 46.6412493],
            [-64.070469, 46.6415184],
            [-64.0706373, 46.6416804],
            [-64.0707134, 46.641829],
            [-64.0706825, 46.6418883],
            [-64.0705306, 46.6419338],
            [-64.0704216, 46.6419042],
            [-64.0703073, 46.6418625],
            [-64.0700886, 46.6416519],
            [-64.0698935, 46.641601],
            [-64.0697994, 46.6416474],
            [-64.0697316, 46.6417739],
            [-64.0697693, 46.6418581],
            [-64.0702633, 46.6423201],
            [-64.0705214, 46.6429179],
            [-64.0705786, 46.6429387],
            [-64.0707305, 46.6428933],
            [-64.0712298, 46.6424906],
            [-64.0713757, 46.642453],
            [-64.0715189, 46.6424951],
            [-64.0715405, 46.6425432],
            [-64.0715578, 46.6430735],
            [-64.0714541, 46.6435899],
            [-64.0714879, 46.6437937],
            [-64.0715679, 46.6438228],
            [-64.0716668, 46.6438084],
            [-64.071826, 46.6437153],
            [-64.0721902, 46.6433743],
            [-64.072398, 46.6433894],
            [-64.0728837, 46.6435763],
            [-64.0730593, 46.6436906],
            [-64.0731529, 46.6438356],
            [-64.0737169, 46.6442827],
            [-64.074246, 46.6449086],
            [-64.0746377, 46.6451418],
            [-64.0747291, 46.6451751],
            [-64.0747963, 46.6450685],
            [-64.0748363, 46.6447304],
            [-64.0749008, 46.6447036],
            [-64.0750098, 46.6447331],
            [-64.0755027, 46.6454063],
            [-64.0757877, 46.6458929],
            [-64.0758634, 46.6462328],
            [-64.0758327, 46.6464635],
            [-64.0756979, 46.6468678],
            [-64.0756484, 46.647142],
            [-64.0756728, 46.6472819],
            [-64.0758614, 46.6475319],
            [-64.0761145, 46.647755],
            [-64.0762176, 46.6479638],
            [-64.07617, 46.6481783],
            [-64.0759085, 46.6489194],
            [-64.0757353, 46.6497934],
            [-64.0755547, 46.6501812],
            [-64.0756943, 46.6505141],
            [-64.0756435, 46.6508281],
            [-64.0757347, 46.6512241],
            [-64.0759076, 46.6512467],
            [-64.0760328, 46.6513124],
            [-64.0762574, 46.6513438],
            [-64.0762811, 46.6507982],
            [-64.0765755, 46.649404],
            [-64.0766634, 46.6491941],
            [-64.0768186, 46.6490491],
            [-64.0770462, 46.6486382],
            [-64.0775118, 46.6482031],
            [-64.0775634, 46.6480405],
            [-64.0772721, 46.6473904],
            [-64.0772059, 46.6471144],
            [-64.0770383, 46.6469325],
            [-64.0770819, 46.6468375],
            [-64.0773118, 46.6467096],
            [-64.0773325, 46.6466063],
            [-64.077296, 46.6464822],
            [-64.0773509, 46.64622],
            [-64.0775713, 46.6460282],
            [-64.077849, 46.6460285],
            [-64.0779567, 46.6460979],
            [-64.0781683, 46.6465276],
            [-64.0781814, 46.6468346],
            [-64.0780908, 46.6469528],
            [-64.0779181, 46.6471015],
            [-64.0778872, 46.6471608],
            [-64.0779351, 46.6472891],
            [-64.078092, 46.6474469],
            [-64.0782877, 46.6474779],
            [-64.078439, 46.6474523],
            [-64.07857, 46.6473388],
            [-64.078915, 46.6466985],
            [-64.0791878, 46.6464955],
            [-64.0792314, 46.6464006],
            [-64.0791943, 46.6462964],
            [-64.0789386, 46.646153],
            [-64.0783816, 46.6460208],
            [-64.0781561, 46.645838],
            [-64.0780746, 46.6456773],
            [-64.0780131, 46.6454333],
            [-64.0780235, 46.6451146],
            [-64.077784, 46.6450073],
            [-64.0776716, 46.6449059],
            [-64.0774339, 46.6445675],
            [-64.0774181, 46.6443401],
            [-64.0775733, 46.6441951],
            [-64.0775887, 46.6440798],
            [-64.0773542, 46.6436418],
            [-64.0773586, 46.643331],
            [-64.0773208, 46.6432467],
            [-64.0769803, 46.6430422],
            [-64.0769484, 46.6427787],
            [-64.0772474, 46.6424845],
            [-64.077403, 46.6421482],
            [-64.0774894, 46.6418068],
            [-64.0776023, 46.6417169],
            [-64.0779579, 46.6416347],
            [-64.0783167, 46.641453],
            [-64.0785971, 46.6413737],
            [-64.0787859, 46.641261],
            [-64.0788254, 46.6411142],
            [-64.0783904, 46.6411473],
            [-64.0772079, 46.641392],
            [-64.0767443, 46.6417673],
            [-64.0763668, 46.642164],
            [-64.0758627, 46.6423634],
            [-64.0752227, 46.642473],
            [-64.0750673, 46.6424467],
            [-64.0750363, 46.6423346],
            [-64.0750866, 46.6422118],
            [-64.075221, 46.6421701],
            [-64.0756688, 46.6421013],
            [-64.0757614, 46.6419234],
            [-64.0759992, 46.6417279],
            [-64.0760206, 46.6416047],
            [-64.0759755, 46.6415681],
            [-64.0758948, 46.6415589],
            [-64.0754699, 46.641636],
            [-64.0752345, 46.6415805],
            [-64.0749748, 46.6413852],
            [-64.0745332, 46.6409121],
            [-64.0745082, 46.6407922],
            [-64.0745451, 46.640725],
            [-64.0746754, 46.6406314],
            [-64.0749605, 46.640584],
            [-64.075107, 46.6405265],
            [-64.0750954, 46.640351],
            [-64.0745441, 46.6396969],
            [-64.0744725, 46.6394089],
            [-64.0743689, 46.638686],
            [-64.074164, 46.6382285],
            [-64.0738808, 46.6380448],
            [-64.0735934, 46.6378092],
            [-64.073334, 46.6372513],
            [-64.0731842, 46.636703],
            [-64.0728404, 46.6364267],
            [-64.0727468, 46.6362818],
            [-64.0727391, 46.6359868],
            [-64.072923, 46.6356709],
            [-64.0734282, 46.6338497],
            [-64.0734791, 46.633707],
            [-64.0736787, 46.6336185],
            [-64.0738239, 46.6336008],
            [-64.0740949, 46.6336289],
            [-64.0749711, 46.6338976],
            [-64.0753295, 46.6339071],
            [-64.0762048, 46.633853],
            [-64.0764501, 46.6337811],
            [-64.0765032, 46.63375],
            [-64.0765071, 46.6336305],
            [-64.0761708, 46.6334779],
            [-64.0761686, 46.6333663],
            [-64.0762627, 46.6333199],
            [-64.0767542, 46.6333275],
            [-64.0770789, 46.6333047],
            [-64.0786866, 46.632963],
            [-64.0791746, 46.6328988],
            [-64.0796378, 46.6328861],
            [-64.0801851, 46.6329543],
            [-64.0816023, 46.6329563],
            [-64.0836818, 46.6330482],
            [-64.0841417, 46.633135],
            [-64.0847419, 46.6330008],
            [-64.0849253, 46.6328762],
            [-64.085105, 46.6325084],
            [-64.0854058, 46.6321544],
            [-64.0858329, 46.6318262],
            [-64.086098, 46.6314996],
            [-64.0865938, 46.6311964],
            [-64.0868874, 46.6310615],
            [-64.087256, 46.6307524],
            [-64.0874968, 46.6302859],
            [-64.0877923, 46.6300912],
            [-64.0880859, 46.6299562],
            [-64.0883083, 46.629876],
            [-64.0886632, 46.6298137],
            [-64.088829, 46.6296927],
            [-64.0892391, 46.629177],
            [-64.0895057, 46.6289819],
            [-64.0898651, 46.6287802],
            [-64.0901323, 46.6285652],
            [-64.0903892, 46.6281348],
            [-64.0902006, 46.6278848],
            [-64.0902036, 46.6276139],
            [-64.0903101, 46.6273605],
            [-64.0906706, 46.6269477],
            [-64.0912975, 46.6263397],
            [-64.0913176, 46.6262563],
            [-64.0912764, 46.6261003],
            [-64.0913676, 46.6259622],
            [-64.0916223, 46.6259542],
            [-64.0918396, 46.6260332],
            [-64.0920123, 46.6260558],
            [-64.092384, 46.6260097],
            [-64.0924701, 46.626031],
            [-64.0926793, 46.6261776],
            [-64.092996, 46.6262223],
            [-64.0931023, 46.6263315],
            [-64.0932954, 46.6264421],
            [-64.0935374, 46.6264697],
            [-64.0936714, 46.6266192],
            [-64.0937851, 46.6266807],
            [-64.09413, 46.6267458],
            [-64.0942767, 46.6268596],
            [-64.0944146, 46.6268896],
            [-64.0947701, 46.6268074],
            [-64.0952513, 46.6267709],
            [-64.0955867, 46.6267721],
            [-64.0962671, 46.6268383],
            [-64.096633, 46.6269714],
            [-64.0967829, 46.6269856],
            [-64.0975551, 46.6268938],
            [-64.0978416, 46.6269779],
            [-64.0983259, 46.6272045],
            [-64.0989538, 46.6276444],
            [-64.0990843, 46.6277221],
            [-64.0993546, 46.6277701],
            [-64.0992851, 46.6275937],
            [-64.098528, 46.6270362],
            [-64.0980212, 46.6266101],
            [-64.0975733, 46.6265076],
            [-64.0965347, 46.6264319],
            [-64.0960062, 46.6263202],
            [-64.0954751, 46.6262882],
            [-64.0948472, 46.6262108],
            [-64.094439, 46.6261329],
            [-64.0940725, 46.6260197],
            [-64.0936965, 46.6258426],
            [-64.093017, 46.6253938],
            [-64.09277, 46.6251629],
            [-64.0925547, 46.6250241],
            [-64.0923234, 46.6250206],
            [-64.0921589, 46.6251017],
            [-64.0920305, 46.6251356],
            [-64.091435, 46.6251305],
            [-64.0911278, 46.6251497],
            [-64.0906837, 46.6252903],
            [-64.0906233, 46.625369],
            [-64.0906382, 46.625445],
            [-64.0907493, 46.6255861],
            [-64.0907166, 46.6257052],
            [-64.0907267, 46.6257492],
            [-64.0909252, 46.6258717],
            [-64.0909467, 46.6259199],
            [-64.0909099, 46.6259871],
            [-64.0905029, 46.6262318],
            [-64.0900419, 46.6263563],
            [-64.0899513, 46.6264744],
            [-64.0898819, 46.626832],
            [-64.0898101, 46.6269066],
            [-64.0896878, 46.6269326],
            [-64.0895795, 46.6268831],
            [-64.0894811, 46.6267063],
            [-64.0894574, 46.6265465],
            [-64.0895169, 46.6263163],
            [-64.0898807, 46.6259752],
            [-64.090013, 46.6258219],
            [-64.090097, 46.6253689],
            [-64.0901714, 46.6252147],
            [-64.0904701, 46.625],
            [-64.0908879, 46.6246996],
            [-64.0910249, 46.6245782],
            [-64.0915416, 46.6239804],
            [-64.0921358, 46.6234915],
            [-64.0922826, 46.6232427],
            [-64.0924617, 46.6225322],
            [-64.0926784, 46.6222686],
            [-64.0932217, 46.6219223],
            [-64.0937067, 46.6217664],
            [-64.0939377, 46.6215986],
            [-64.0942684, 46.6212052],
            [-64.0946255, 46.6208919],
            [-64.0950674, 46.6206397],
            [-64.0955, 46.6204949],
            [-64.0957391, 46.6204308],
            [-64.0960555, 46.6203041],
            [-64.0963938, 46.6200344],
            [-64.0967173, 46.6196887],
            [-64.0969485, 46.6196922],
            [-64.0970729, 46.6197778],
            [-64.0971793, 46.619887],
            [-64.0974556, 46.6199271],
            [-64.0980778, 46.6198211],
            [-64.0982436, 46.6197001],
            [-64.0984523, 46.6195041],
            [-64.0987356, 46.6193251],
            [-64.0988573, 46.619319],
            [-64.0991732, 46.6193836],
            [-64.0997445, 46.6197828],
            [-64.0999515, 46.6198178],
            [-64.1001786, 46.6197695],
            [-64.1004089, 46.6196216],
            [-64.1006728, 46.6195061],
            [-64.1009066, 46.61943],
            [-64.1009778, 46.6193753],
            [-64.1010079, 46.6193359],
            [-64.1009768, 46.6192238],
            [-64.1006774, 46.6190041],
            [-64.0996019, 46.6184617],
            [-64.098715, 46.6181693],
            [-64.0985394, 46.618055],
            [-64.098509, 46.6179231],
            [-64.0985639, 46.6178323],
            [-64.0987278, 46.617771],
            [-64.0988326, 46.6177487],
            [-64.0992702, 46.6178072],
            [-64.0996694, 46.6178013],
            [-64.1001124, 46.6178719],
            [-64.1004881, 46.6178776],
            [-64.1007622, 46.6178061],
            [-64.1013024, 46.6177306],
            [-64.1015085, 46.6176142],
            [-64.1017226, 46.6174302],
            [-64.1021056, 46.6173883],
            [-64.1023219, 46.6173159],
            [-64.1025595, 46.6171203],
            [-64.1027611, 46.6171432],
            [-64.1031844, 46.6174685],
            [-64.1033571, 46.617491],
            [-64.1036306, 46.6174394],
            [-64.1038891, 46.6173118],
            [-64.1042905, 46.617055],
            [-64.1045021, 46.6169506],
            [-64.105328, 46.6169791],
            [-64.1055694, 46.6170266],
            [-64.1059809, 46.6171763],
            [-64.1061632, 46.6172628],
            [-64.1068174, 46.6177827],
            [-64.1069612, 46.6178048],
            [-64.1071997, 46.6177606],
            [-64.1073776, 46.6176239],
            [-64.1075967, 46.6172806],
            [-64.1079512, 46.6166843],
            [-64.108117, 46.6162007],
            [-64.1081761, 46.6161617],
            [-64.1082929, 46.6161237],
            [-64.1085927, 46.6161521],
            [-64.1090284, 46.6162703],
            [-64.1091783, 46.6162845],
            [-64.1098245, 46.6161469],
            [-64.109928, 46.6161644],
            [-64.1101319, 46.616299],
            [-64.1103894, 46.6163826],
            [-64.1105695, 46.6163574],
            [-64.1107537, 46.6163841],
            [-64.1109998, 46.6164636],
            [-64.1111345, 46.6165931],
            [-64.1112567, 46.6165671],
            [-64.1114104, 46.6164618],
            [-64.1114342, 46.616259],
            [-64.1113171, 46.6157631],
            [-64.1113269, 46.6156358],
            [-64.1117413, 46.6151519],
            [-64.1118753, 46.6149388],
            [-64.1121147, 46.6146835],
            [-64.1122265, 46.6144421],
            [-64.1123103, 46.6143517],
            [-64.1127949, 46.6143869],
            [-64.1132938, 46.614518],
            [-64.1136507, 46.6145672],
            [-64.1157475, 46.6146348],
            [-64.1162753, 46.6147663],
            [-64.1170686, 46.6152763],
            [-64.1176393, 46.6156953],
            [-64.1179884, 46.6161747],
            [-64.1182793, 46.6164819],
            [-64.1184534, 46.6168272],
            [-64.1197061, 46.6181689],
            [-64.1200864, 46.6187604],
            [-64.1203017, 46.6188991],
            [-64.1205352, 46.6190141],
            [-64.1207709, 46.6192408],
            [-64.1209643, 46.6195226],
            [-64.1211998, 46.6199406],
            [-64.1216205, 46.6208992],
            [-64.1218346, 46.6210777],
            [-64.1222602, 46.6211519],
            [-64.1224402, 46.6211267],
            [-64.1222792, 46.6209171],
            [-64.1220661, 46.62089],
            [-64.1219752, 46.6208368],
            [-64.1218516, 46.6205401],
            [-64.1218141, 46.6202646],
            [-64.1215834, 46.6198786],
            [-64.121571, 46.619723],
            [-64.1216268, 46.619421],
            [-64.121487, 46.6190882],
            [-64.1211191, 46.6186524],
            [-64.1198365, 46.6175214],
            [-64.1197209, 46.6171571],
            [-64.1191939, 46.6164519],
            [-64.1189423, 46.6159978],
            [-64.1188765, 46.615702],
            [-64.1185033, 46.6152541],
            [-64.118408, 46.6149777],
            [-64.1178843, 46.6145355],
            [-64.1171698, 46.6140944],
            [-64.1164489, 46.6138525],
            [-64.1157658, 46.6136947],
            [-64.1153499, 46.6136845],
            [-64.1149434, 46.6137381],
            [-64.1140765, 46.613725],
            [-64.1132868, 46.6136493],
            [-64.1120966, 46.6136193],
            [-64.1113926, 46.6137561],
            [-64.1110583, 46.6139064],
            [-64.1107864, 46.6140896],
            [-64.1104162, 46.6144585],
            [-64.109588, 46.6150436],
            [-64.10893, 46.6157309],
            [-64.1088125, 46.6157889],
            [-64.1084698, 46.6158355],
            [-64.1081605, 46.6157431],
            [-64.1078655, 46.6157466],
            [-64.1077715, 46.615793],
            [-64.1073034, 46.6163278],
            [-64.1071928, 46.6165293],
            [-64.1071036, 46.6166077],
            [-64.1069013, 46.6166046],
            [-64.1065352, 46.6163002],
            [-64.1062735, 46.6161647],
            [-64.1057934, 46.6159901],
            [-64.1053543, 46.6154375],
            [-64.1052251, 46.61532],
            [-64.1045735, 46.615083],
            [-64.1041184, 46.6150282],
            [-64.1033404, 46.615128],
            [-64.1022763, 46.6149524],
            [-64.1020277, 46.6149526],
            [-64.101036, 46.615045],
            [-64.1005495, 46.6150695],
            [-64.1002516, 46.6149812],
            [-64.0995899, 46.6147002],
            [-64.0985916, 46.6144578],
            [-64.0985129, 46.6143889],
            [-64.0984602, 46.6142287],
            [-64.0985492, 46.613979],
            [-64.0987423, 46.613727],
            [-64.1000591, 46.6126952],
            [-64.1010212, 46.6117258],
            [-64.1012404, 46.6113825],
            [-64.1012417, 46.6109801],
            [-64.1012792, 46.610893],
            [-64.1015616, 46.6105626],
            [-64.1029392, 46.6094321],
            [-64.1031853, 46.609149],
            [-64.1032777, 46.6089711],
            [-64.10332, 46.6085534],
            [-64.1036621, 46.6078016],
            [-64.1037575, 46.6073528],
            [-64.1037819, 46.60713],
            [-64.1038985, 46.6069206],
            [-64.1039979, 46.6068863],
            [-64.1042999, 46.6066638],
            [-64.1043856, 46.6065138],
            [-64.1045159, 46.6060576],
            [-64.1050201, 46.6054756],
            [-64.1051379, 46.6052264],
            [-64.1050919, 46.6050384],
            [-64.1051313, 46.6048916],
            [-64.1061909, 46.6037562],
            [-64.107007, 46.6024618],
            [-64.1071613, 46.6023367],
            [-64.1076028, 46.6020844],
            [-64.107813, 46.6020199],
            [-64.1084699, 46.6019063],
            [-64.1091939, 46.6018575],
            [-64.1102777, 46.6015871],
            [-64.1114425, 46.6011346],
            [-64.1119996, 46.6008841],
            [-64.1125352, 46.6005854],
            [-64.1129272, 46.600436],
            [-64.1130783, 46.6004104],
            [-64.1131992, 46.6004242],
            [-64.1133411, 46.600506],
            [-64.1140199, 46.6009744],
            [-64.1142967, 46.6009946],
            [-64.114837, 46.6007278],
            [-64.1149395, 46.6005939],
            [-64.1154188, 46.6004258],
            [-64.1156217, 46.600409],
            [-64.1162312, 46.6005098],
            [-64.1166382, 46.6004363],
            [-64.1169838, 46.6002901],
            [-64.1171175, 46.6002682],
            [-64.1172787, 46.6002866],
            [-64.1189549, 46.6006545],
            [-64.1196908, 46.6007811],
            [-64.1205314, 46.6008854],
            [-64.1209485, 46.6008558],
            [-64.1212453, 46.6007926],
            [-64.1215453, 46.6006297],
            [-64.1221977, 46.6006555],
            [-64.1227573, 46.6006878],
            [-64.1235713, 46.6009032],
            [-64.1239288, 46.6009325],
            [-64.1242881, 46.600902],
            [-64.1248462, 46.6008028],
            [-64.125123, 46.6008229],
            [-64.1256412, 46.601253],
            [-64.1260038, 46.6014856],
            [-64.1267613, 46.6016603],
            [-64.1274345, 46.6019452],
            [-64.1279453, 46.6020605],
            [-64.1275293, 46.6016877],
            [-64.1264608, 46.6009266],
            [-64.1261807, 46.6006436],
            [-64.1254523, 46.600278],
            [-64.1244019, 46.6000471],
            [-64.124032, 46.5998623],
            [-64.1236993, 46.5997816],
            [-64.1234164, 46.5997694],
            [-64.1229073, 46.5997856],
            [-64.1222619, 46.5999034],
            [-64.120782, 46.5999091],
            [-64.1204131, 46.5998756],
            [-64.1197928, 46.5997507],
            [-64.1195649, 46.5996477],
            [-64.1191007, 46.5993379],
            [-64.1189553, 46.5991843],
            [-64.1186127, 46.5988684],
            [-64.1182169, 46.5987748],
            [-64.1179978, 46.5987555],
            [-64.1177433, 46.5987637],
            [-64.1174868, 46.5988315],
            [-64.1169535, 46.5988792],
            [-64.1151613, 46.598892],
            [-64.1145781, 46.5988713],
            [-64.1140258, 46.5987912],
            [-64.1137757, 46.5986599],
            [-64.1136928, 46.5985391],
            [-64.113708, 46.5980613],
            [-64.1137884, 46.5978991],
            [-64.1140544, 46.5975326],
            [-64.1150319, 46.5965992],
            [-64.1156441, 46.595704],
            [-64.1160861, 46.5947067],
            [-64.1161766, 46.5945886],
            [-64.116552, 46.5942317],
            [-64.1166926, 46.5939908],
            [-64.1167399, 46.5937763],
            [-64.1167148, 46.5932939],
            [-64.1167836, 46.5931276],
            [-64.1172593, 46.5925252],
            [-64.1182237, 46.5918186],
            [-64.1192361, 46.5912403],
            [-64.119601, 46.5910306],
            [-64.1202266, 46.590805],
            [-64.1204884, 46.5907492],
            [-64.1211847, 46.5906601],
            [-64.121325, 46.5906104],
            [-64.1222335, 46.5902057],
            [-64.123335, 46.5899115],
            [-64.1238754, 46.5894535],
            [-64.1246493, 46.5889192],
            [-64.1252862, 46.5886978],
            [-64.1261617, 46.5886034],
            [-64.126308, 46.5885458],
            [-64.1263448, 46.5884786],
            [-64.1262017, 46.5884365],
            [-64.125757, 46.5884259],
            [-64.1253311, 46.5883717],
            [-64.1242968, 46.5883681],
            [-64.1240563, 46.5883008],
            [-64.1238154, 46.5880621],
            [-64.1236373, 46.5880276],
            [-64.1233184, 46.5880626],
            [-64.1227567, 46.5882813],
            [-64.1225942, 46.5883027],
            [-64.1224565, 46.5882728],
            [-64.1222641, 46.5881424],
            [-64.1220854, 46.5881278],
            [-64.1219566, 46.5881816],
            [-64.1217941, 46.588203],
            [-64.1213756, 46.5881011],
            [-64.1212701, 46.5881434],
            [-64.121108, 46.5883361],
            [-64.1209004, 46.5883211],
            [-64.1208554, 46.5882845],
            [-64.120829, 46.5882045],
            [-64.1209854, 46.5878284],
            [-64.1209092, 46.5876799],
            [-64.1207841, 46.5876142],
            [-64.1205357, 46.5876145],
            [-64.1202605, 46.5877259],
            [-64.1201034, 46.5877594],
            [-64.1198172, 46.5876754],
            [-64.1197277, 46.5875824],
            [-64.1198796, 46.5873457],
            [-64.119825, 46.5872452],
            [-64.1196429, 46.5871588],
            [-64.119309, 46.5871179],
            [-64.1187892, 46.5871101],
            [-64.1183779, 46.5869605],
            [-64.1180551, 46.5867524],
            [-64.1172484, 46.586318],
            [-64.1159933, 46.5859923],
            [-64.1153976, 46.5860072],
            [-64.114809, 46.5861656],
            [-64.114537, 46.5861774],
            [-64.1136942, 46.5861527],
            [-64.1133584, 46.5861715],
            [-64.1131334, 46.5861602],
            [-64.1129681, 46.5860899],
            [-64.1129097, 46.586109],
            [-64.1128059, 46.5862827],
            [-64.1125435, 46.5863584],
            [-64.1120909, 46.5864153],
            [-64.1115069, 46.5866056],
            [-64.1110947, 46.5866671],
            [-64.1108583, 46.5866516],
            [-64.1100931, 46.5865444],
            [-64.1097009, 46.5865225],
            [-64.1092148, 46.586547],
            [-64.1088878, 46.5866496],
            [-64.1086508, 46.586654],
            [-64.1085788, 46.5865572],
            [-64.1086049, 46.586466],
            [-64.109152, 46.5859803],
            [-64.1095893, 46.5856762],
            [-64.1096563, 46.5855696],
            [-64.1095062, 46.5853841],
            [-64.1095183, 46.5850058],
            [-64.1095859, 46.5848793],
            [-64.1098575, 46.5846962],
            [-64.1100676, 46.5846317],
            [-64.110463, 46.584554],
            [-64.1108793, 46.5845444],
            [-64.11197, 46.5845888],
            [-64.1141841, 46.5849211],
            [-64.1155095, 46.5852121],
            [-64.116004, 46.5852912],
            [-64.1180594, 46.5855254],
            [-64.1193076, 46.5857076],
            [-64.1201333, 46.5859073],
            [-64.1204701, 46.5860398],
            [-64.1215177, 46.5863504],
            [-64.1231879, 46.5867062],
            [-64.124185, 46.5869682],
            [-64.12619, 46.5877074],
            [-64.1264318, 46.5877349],
            [-64.1269535, 46.587683],
            [-64.1273553, 46.5877687],
            [-64.1275953, 46.587856],
            [-64.1285568, 46.5883364],
            [-64.129116, 46.5885599],
            [-64.1304019, 46.5890173],
            [-64.1324004, 46.5897843],
            [-64.1337806, 46.5903667],
            [-64.1347152, 46.590787],
            [-64.1351403, 46.5910522],
            [-64.1365135, 46.591491],
            [-64.1378409, 46.5921044],
            [-64.1393494, 46.5926527],
            [-64.1400942, 46.5928629],
            [-64.140682, 46.5929155],
            [-64.1415913, 46.5928533],
            [-64.1418681, 46.5928733],
            [-64.1426604, 46.5930404],
            [-64.1433804, 46.5933021],
            [-64.1441479, 46.5937118],
            [-64.1443025, 46.593758],
            [-64.1446653, 46.5937992],
            [-64.1452668, 46.5941587],
            [-64.145573, 46.5941592],
            [-64.1457416, 46.5941298],
            [-64.1458565, 46.5941514],
            [-64.1460315, 46.5942855],
            [-64.1466861, 46.5944227],
            [-64.1469658, 46.5945344],
            [-64.1472364, 46.5947535],
            [-64.1476154, 46.5948308],
            [-64.1481074, 46.5951807],
            [-64.1483294, 46.5952915],
            [-64.1494676, 46.5956749],
            [-64.1500879, 46.5957995],
            [-64.1510393, 46.5958733],
            [-64.1511825, 46.5959153],
            [-64.1515452, 46.5961477],
            [-64.1520843, 46.5962831],
            [-64.1526445, 46.5962953],
            [-64.1528894, 46.5964145],
            [-64.153746, 46.596937],
            [-64.1540252, 46.5970686],
            [-64.1547596, 46.5976172],
            [-64.1554622, 46.5980738],
            [-64.1574361, 46.5991027],
            [-64.1580202, 46.5994659],
            [-64.1582597, 46.599573],
            [-64.1586045, 46.5996378],
            [-64.1589156, 46.5996702],
            [-64.1592862, 46.599835],
            [-64.1599739, 46.6002156],
            [-64.1604637, 46.6004538],
            [-64.1612411, 46.6007361],
            [-64.1616594, 46.6008578],
            [-64.1622413, 46.600918],
            [-64.1624363, 46.6009687],
            [-64.1631943, 46.6016969],
            [-64.164625, 46.6027297],
            [-64.1664435, 46.603561],
            [-64.1669213, 46.603815],
            [-64.1675826, 46.6043067],
            [-64.167931, 46.6046345],
            [-64.1683167, 46.6048752],
            [-64.1687777, 46.6051129],
            [-64.1693752, 46.6056117],
            [-64.1727117, 46.607668],
            [-64.1737987, 46.6082336],
            [-64.1745426, 46.6086746],
            [-64.1760666, 46.6095094],
            [-64.1774576, 46.6103463],
            [-64.1782841, 46.6107367],
            [-64.1796198, 46.6116843],
            [-64.1805655, 46.6121482],
            [-64.1815798, 46.6128281],
            [-64.1817116, 46.6128659],
            [-64.1818868, 46.6128087],
            [-64.1821993, 46.6128012],
            [-64.1842925, 46.6135604],
            [-64.1848054, 46.6138068],
            [-64.1855995, 46.6143163],
            [-64.1862455, 46.6145606],
            [-64.1869962, 46.6145913],
            [-64.1870432, 46.614568],
            [-64.1870787, 46.6143494],
            [-64.187172, 46.6143229],
            [-64.1876446, 46.6143735],
            [-64.1881058, 46.6144199],
            [-64.1882551, 46.6144539],
            [-64.188287, 46.6147373],
            [-64.1889354, 46.6150932],
            [-64.1889902, 46.6151936],
            [-64.1893406, 46.6154615],
            [-64.1894406, 46.6155984],
            [-64.190018, 46.6160091],
            [-64.1901204, 46.6160664],
            [-64.1907195, 46.6165251],
            [-64.1908297, 46.616706],
            [-64.1907815, 46.6167691],
            [-64.1905853, 46.6167583],
            [-64.1895743, 46.6161502],
            [-64.1892251, 46.6160336],
            [-64.1890806, 46.6160316],
            [-64.1889584, 46.6160577],
            [-64.1889385, 46.6161411],
            [-64.1890427, 46.6163299],
            [-64.1893787, 46.6166933],
            [-64.1894919, 46.6167746],
            [-64.1897388, 46.6168339],
            [-64.18987, 46.6168915],
            [-64.1903005, 46.6171885],
            [-64.1903951, 46.6173134],
            [-64.1904927, 46.61753],
            [-64.1905963, 46.6179299],
            [-64.1905771, 46.6185671],
            [-64.1904429, 46.619374],
            [-64.1904857, 46.6194902],
            [-64.1907044, 46.6201029],
            [-64.1910103, 46.620697],
            [-64.1911609, 46.6210737],
            [-64.1912989, 46.6216773],
            [-64.1914712, 46.6219109],
            [-64.1915569, 46.6227169],
            [-64.191578, 46.6229762],
            [-64.1913662, 46.625],
            [-64.1913507, 46.6251486],
            [-64.1911418, 46.625556],
            [-64.1908576, 46.6259664],
            [-64.1906782, 46.6263543],
            [-64.1905144, 46.626607],
            [-64.1902754, 46.6268625],
            [-64.1895101, 46.6275011],
            [-64.1889796, 46.6278322],
            [-64.1888814, 46.6280181],
            [-64.1888465, 46.6282168],
            [-64.1887742, 46.6283114],
            [-64.1882883, 46.6286989],
            [-64.1881829, 46.6289325],
            [-64.1880565, 46.6294805],
            [-64.1879391, 46.6297299],
            [-64.1876524, 46.6300286],
            [-64.1874164, 46.6301846],
            [-64.1872881, 46.6302187],
            [-64.1869876, 46.6302104],
            [-64.1867816, 46.6301357],
            [-64.1865654, 46.630017],
            [-64.1864648, 46.6299],
            [-64.1859336, 46.6291035],
            [-64.1848032, 46.6280393],
            [-64.1846327, 46.6279373],
            [-64.1844202, 46.6278904],
            [-64.1842058, 46.6279033],
            [-64.1843304, 46.6279887],
            [-64.1845659, 46.6280439],
            [-64.1847363, 46.628146],
            [-64.185129, 46.6287413],
            [-64.1852964, 46.6291342],
            [-64.1859944, 46.629961],
            [-64.1862486, 46.6303552],
            [-64.1864233, 46.6305091],
            [-64.1866859, 46.6306244],
            [-64.1869153, 46.6306875],
            [-64.187688, 46.6307663],
            [-64.1881813, 46.6309048],
            [-64.1883517, 46.6310069],
            [-64.1883674, 46.6312542],
            [-64.188312, 46.6313649],
            [-64.1876008, 46.6319325],
            [-64.1874508, 46.6321097],
            [-64.187387, 46.6324993],
            [-64.1874274, 46.6326951],
            [-64.1874828, 46.6327755],
            [-64.1877141, 46.6329702],
            [-64.1878171, 46.6331988],
            [-64.1877737, 46.6332938],
            [-64.1876846, 46.6333722],
            [-64.1873847, 46.6335352],
            [-64.1873793, 46.6337144],
            [-64.1874227, 46.6338106],
            [-64.1872673, 46.634167],
            [-64.1869825, 46.6353624],
            [-64.187012, 46.6357254],
            [-64.1869741, 46.6358324],
            [-64.1865693, 46.6362091],
            [-64.1866, 46.6363411],
            [-64.1866778, 46.6364498],
            [-64.1869042, 46.6366124],
            [-64.1869579, 46.6367526],
            [-64.1868555, 46.6368867],
            [-64.1865091, 46.637053],
            [-64.1864158, 46.6372709],
            [-64.1865049, 46.637575],
            [-64.1863562, 46.6380948],
            [-64.1863201, 46.6383334],
            [-64.1863592, 46.6385691],
            [-64.1864562, 46.6386143],
            [-64.1865345, 46.6387031],
            [-64.1865646, 46.6388549],
            [-64.1865351, 46.639257],
            [-64.1865677, 46.6395204],
            [-64.1866496, 46.639681],
            [-64.1866454, 46.6398204],
            [-64.1863918, 46.6403627],
            [-64.1863563, 46.6405813],
            [-64.186425, 46.6407975],
            [-64.1866684, 46.6411676],
            [-64.1866913, 46.6413671],
            [-64.1866202, 46.6414219],
            [-64.1867238, 46.6414393],
            [-64.1868702, 46.6413817],
            [-64.1869792, 46.6412199],
            [-64.1870491, 46.6410137],
            [-64.1870129, 46.6406784],
            [-64.1870394, 46.6401847],
            [-64.1872044, 46.6398922],
            [-64.1876062, 46.6394237],
            [-64.1873893, 46.6389425],
            [-64.1875585, 46.6385105],
            [-64.1874272, 46.6382616],
            [-64.1872073, 46.6380712],
            [-64.1871471, 46.6379587],
            [-64.1871693, 46.6377957],
            [-64.1873639, 46.6374837],
            [-64.1874645, 46.6374094],
            [-64.1880144, 46.6372061],
            [-64.1883384, 46.6370115],
            [-64.1887938, 46.6366833],
            [-64.1891714, 46.6362464],
            [-64.1893003, 46.6360012],
            [-64.1893574, 46.6354482],
            [-64.1892466, 46.635096],
            [-64.1892526, 46.6348967],
            [-64.1893537, 46.6348025],
            [-64.1901566, 46.6342681],
            [-64.1902246, 46.6341217],
            [-64.1901312, 46.633957],
            [-64.1895313, 46.6335181],
            [-64.1895686, 46.6332397],
            [-64.1897872, 46.6328962],
            [-64.1899757, 46.6327832],
            [-64.1900208, 46.6326286],
            [-64.1898835, 46.6323875],
            [-64.189869, 46.6322917],
            [-64.1900213, 46.6320349],
            [-64.1908343, 46.6313532],
            [-64.1910914, 46.6310739],
            [-64.1912227, 46.6307491],
            [-64.1912274, 46.6303985],
            [-64.1908503, 46.6294767],
            [-64.1908334, 46.6292692],
            [-64.1909026, 46.6288917],
            [-64.1911741, 46.6283258],
            [-64.1914661, 46.6278478],
            [-64.1923151, 46.6271188],
            [-64.193382, 46.6260661],
            [-64.1936246, 46.6258823],
            [-64.1941323, 46.6257342],
            [-64.1955576, 46.6256469],
            [-64.1958979, 46.6256796],
            [-64.196208, 46.6257518],
            [-64.1968428, 46.6259919],
            [-64.1978516, 46.6264883],
            [-64.1983798, 46.6266194],
            [-64.1990386, 46.6266367],
            [-64.19996, 46.626578],
            [-64.2003875, 46.6265921],
            [-64.2020129, 46.6265872],
            [-64.2036435, 46.6264031],
            [-64.2042867, 46.6263644],
            [-64.2045812, 46.6263805],
            [-64.2050918, 46.6263239],
            [-64.2055091, 46.626294],
            [-64.2077728, 46.6264096],
            [-64.2096078, 46.626551],
            [-64.2112196, 46.627004],
            [-64.2121388, 46.6274074],
            [-64.2123623, 46.6274783],
            [-64.2132382, 46.6279767],
            [-64.2144293, 46.6287704],
            [-64.2149529, 46.6290606],
            [-64.2161156, 46.6296427],
            [-64.2168482, 46.629908],
            [-64.2172843, 46.6300257],
            [-64.2179872, 46.6301192],
            [-64.2188779, 46.6301197],
            [-64.2204081, 46.6300096],
            [-64.2219364, 46.6297679],
            [-64.2238638, 46.6293286],
            [-64.2247646, 46.6291817],
            [-64.2251265, 46.6290712],
            [-64.22572, 46.6287527],
            [-64.2260397, 46.6286974],
            [-64.2265961, 46.6286573],
            [-64.227482, 46.6286258],
            [-64.2298235, 46.6286583],
            [-64.2307444, 46.6288105],
            [-64.2317066, 46.6291386],
            [-64.2318711, 46.6292484],
            [-64.2318727, 46.6295911],
            [-64.2320029, 46.6296886],
            [-64.232433, 46.6298141],
            [-64.2327293, 46.630364],
            [-64.2330054, 46.6306149],
            [-64.2332336, 46.6311201],
            [-64.233313, 46.6315715],
            [-64.2336054, 46.6312647],
            [-64.2337272, 46.630856],
            [-64.233636, 46.6306117],
            [-64.2332613, 46.6299729],
            [-64.2332539, 46.6298294],
            [-64.2334386, 46.6296447],
            [-64.2333656, 46.629568],
            [-64.2332271, 46.6295581],
            [-64.2329818, 46.6294391],
            [-64.2325232, 46.6291021],
            [-64.2321792, 46.6289977],
            [-64.2320773, 46.6289206],
            [-64.232029, 46.6287924],
            [-64.2320474, 46.6285576],
            [-64.232229, 46.6282812],
            [-64.2326624, 46.6280959],
            [-64.2331627, 46.6279953],
            [-64.2342646, 46.6278909],
            [-64.237011, 46.6275854],
            [-64.237602, 46.6271262],
            [-64.2374864, 46.6270156],
            [-64.2373728, 46.6268881],
            [-64.2376039, 46.6266657],
            [-64.2383368, 46.6261881],
            [-64.2388563, 46.6258908],
            [-64.2397284, 46.6257807],
            [-64.2408089, 46.6259504],
            [-64.241479, 46.6259526],
            [-64.2427936, 46.6260319],
            [-64.2436016, 46.6261156],
            [-64.2438711, 46.6260385],
            [-64.2463955, 46.6255993],
            [-64.2483167, 46.6253586],
            [-64.2491272, 46.6253298],
            [-64.2496871, 46.6252069],
            [-64.2504465, 46.6252396],
            [-64.2511766, 46.6253736],
            [-64.2519968, 46.6253645],
            [-64.2541557, 46.6253062],
            [-64.2548972, 46.6253015],
            [-64.2548883, 46.6252586],
            [-64.2543451, 46.6252473],
            [-64.2541081, 46.625239],
            [-64.2535207, 46.6251863],
            [-64.2529308, 46.6250864],
            [-64.2525339, 46.6250737],
            [-64.2519148, 46.6250811],
            [-64.2512032, 46.6249595],
            [-64.2507428, 46.6249652],
            [-64.250375, 46.6249289],
            [-64.249957, 46.6249343],
            [-64.2495892, 46.6248927],
            [-64.2489542, 46.6249036],
            [-64.2485837, 46.6248891],
            [-64.2483932, 46.6248109],
            [-64.2479699, 46.6247509],
            [-64.2475492, 46.6247255],
            [-64.2469619, 46.6246929],
            [-64.2463586, 46.624702],
            [-64.2459246, 46.6246802],
            [-64.244996, 46.6246638],
            [-64.2446231, 46.6246641],
            [-64.2439907, 46.6246731],
            [-64.2434007, 46.6246077],
            [-64.2430329, 46.6245477],
            [-64.2427261, 46.6245351],
            [-64.2422154, 46.6244969],
            [-64.2415936, 46.6244352],
            [-64.2409666, 46.6244317],
            [-64.2406253, 46.6243627],
            [-64.2402945, 46.6243409],
            [-64.2398077, 46.6242809],
            [-64.2390365, 46.6242777],
            [-64.238645, 46.6242337],
            [-64.2384584, 46.6242869],
            [-64.2381922, 46.6242912],
            [-64.2377986, 46.624501],
            [-64.2376289, 46.6245704],
            [-64.2363699, 46.6247084],
            [-64.2361379, 46.625],
            [-64.2359033, 46.6250933],
            [-64.2358405, 46.6252112],
            [-64.2354758, 46.625381],
            [-64.2349526, 46.6255137],
            [-64.2343386, 46.6258566],
            [-64.2342517, 46.6257235],
            [-64.2341597, 46.6256442],
            [-64.2339973, 46.6255798],
            [-64.2338205, 46.6255339],
            [-64.2335793, 46.6255268],
            [-64.2332329, 46.6255407],
            [-64.2329379, 46.6255574],
            [-64.2327105, 46.6255881],
            [-64.2324426, 46.6256559],
            [-64.2322126, 46.625694],
            [-64.2319449, 46.6257096],
            [-64.2316131, 46.6257004],
            [-64.2314223, 46.6256675],
            [-64.2313464, 46.6256903],
            [-64.2309108, 46.6256718],
            [-64.2306028, 46.6256923],
            [-64.2303427, 46.6256917],
            [-64.2301519, 46.6256792],
            [-64.2298545, 46.6256463],
            [-64.2297202, 46.6255291],
            [-64.229475, 46.6253086],
            [-64.2292139, 46.6251608],
            [-64.2289314, 46.6251015],
            [-64.2279684, 46.6252234],
            [-64.2270402, 46.6254509],
            [-64.226086, 46.6256089],
            [-64.2258268, 46.6246437],
            [-64.225356, 46.6245687],
            [-64.224817, 46.6246061],
            [-64.2242508, 46.6249716],
            [-64.223725, 46.6248203],
            [-64.2233259, 46.6245827],
            [-64.2227428, 46.6245586],
            [-64.2221057, 46.6246429],
            [-64.2202586, 46.6251045],
            [-64.2201972, 46.6249438],
            [-64.2200974, 46.6247804],
            [-64.2195973, 46.6247625],
            [-64.2194168, 46.6246085],
            [-64.2192172, 46.6245295],
            [-64.2192188, 46.6244177],
            [-64.2194583, 46.6243195],
            [-64.2204043, 46.6241382],
            [-64.2217743, 46.6240276],
            [-64.222854, 46.6239184],
            [-64.224667, 46.6238612],
            [-64.2257517, 46.6238378],
            [-64.2273687, 46.6237909],
            [-64.2287673, 46.6237956],
            [-64.2302238, 46.6238624],
            [-64.2304865, 46.6237815],
            [-64.2309368, 46.623819],
            [-64.232008, 46.623758],
            [-64.234123, 46.6237955],
            [-64.2352828, 46.6238845],
            [-64.2366184, 46.6239154],
            [-64.2375455, 46.6239806],
            [-64.2383639, 46.6239935],
            [-64.2390976, 46.6240548],
            [-64.2413211, 46.6241826],
            [-64.2422604, 46.6242407],
            [-64.2444454, 46.6243814],
            [-64.246138, 46.624474],
            [-64.2494947, 46.6246941],
            [-64.2519075, 46.6248513],
            [-64.2534794, 46.6249917],
            [-64.2545153, 46.6251219],
            [-64.2552693, 46.6251668],
            [-64.2562334, 46.6251835],
            [-64.2580719, 46.6252044],
            [-64.2584402, 46.6252272],
            [-64.2590413, 46.6253233],
            [-64.260064, 46.6253663],
            [-64.2616309, 46.6254676],
            [-64.2621642, 46.6254512],
            [-64.2626604, 46.6255187],
            [-64.2631884, 46.6255752],
            [-64.264117, 46.6256226],
            [-64.2650139, 46.6257721],
            [-64.2661522, 46.6258795],
            [-64.2676514, 46.6259415],
            [-64.2687553, 46.6260544],
            [-64.269485, 46.6261314],
            [-64.2730814, 46.6265462],
            [-64.2737471, 46.6268757],
            [-64.274827, 46.6272269],
            [-64.2754945, 46.6273474],
            [-64.2770638, 46.6275743],
            [-64.2777786, 46.6276576],
            [-64.2788704, 46.6277409],
            [-64.2798279, 46.6276843],
            [-64.2802005, 46.6276903],
            [-64.2806338, 46.627723],
            [-64.2813826, 46.6278134],
            [-64.2836874, 46.6280542],
            [-64.2845099, 46.6280094],
            [-64.2857862, 46.6280781],
            [-64.2870711, 46.6282505],
            [-64.2884924, 46.6285123],
            [-64.2889914, 46.6286624],
            [-64.2893797, 46.6288428],
            [-64.2898006, 46.6290954],
            [-64.2899216, 46.6293201],
            [-64.2901319, 46.6298688],
            [-64.2902671, 46.6299981],
            [-64.2905065, 46.6301248],
            [-64.290723, 46.6302432],
            [-64.2907955, 46.6303398],
            [-64.2908386, 46.6304559],
            [-64.2907239, 46.6308369],
            [-64.2905034, 46.631069],
            [-64.2899906, 46.6312057],
            [-64.2891674, 46.6314817],
            [-64.287535, 46.6321495],
            [-64.2873713, 46.632211],
            [-64.2869004, 46.6325036],
            [-64.2865407, 46.6329571],
            [-64.2863207, 46.6333805],
            [-64.286026, 46.6335759],
            [-64.2860591, 46.6338393],
            [-64.2862596, 46.6343241],
            [-64.2862448, 46.6344394],
            [-64.2860062, 46.6349064],
            [-64.2858134, 46.63539],
            [-64.2852941, 46.6361681],
            [-64.285307, 46.6365348],
            [-64.2853768, 46.636731],
            [-64.2855485, 46.6370042],
            [-64.2858849, 46.6373872],
            [-64.2860047, 46.6376518],
            [-64.2854928, 46.6389958],
            [-64.285072, 46.6393568],
            [-64.2849081, 46.6398407],
            [-64.2846029, 46.6402033],
            [-64.2839415, 46.6404774],
            [-64.2837614, 46.6405029],
            [-64.2829027, 46.6403839],
            [-64.2826992, 46.6404211],
            [-64.2825988, 46.6404955],
            [-64.2828497, 46.6406263],
            [-64.2839239, 46.6406924],
            [-64.2850348, 46.64048],
            [-64.2851816, 46.6404022],
            [-64.2853172, 46.639898],
            [-64.2856415, 46.6394719],
            [-64.2857059, 46.6394449],
            [-64.2860913, 46.6391113],
            [-64.2862944, 46.6386717],
            [-64.2863491, 46.6383696],
            [-64.2864064, 46.6373543],
            [-64.2863586, 46.636995],
            [-64.2863859, 46.636844],
            [-64.2863222, 46.6366399],
            [-64.2861298, 46.6362788],
            [-64.2861168, 46.6361232],
            [-64.2868782, 46.6353722],
            [-64.2870698, 46.6351397],
            [-64.2873606, 46.6340478],
            [-64.2877374, 46.6331881],
            [-64.2880883, 46.6328421],
            [-64.2883699, 46.6327024],
            [-64.2895045, 46.6326696],
            [-64.2896796, 46.6326121],
            [-64.2910955, 46.6318259],
            [-64.2918887, 46.6315894],
            [-64.2919929, 46.6315868],
            [-64.292547, 46.6318371],
            [-64.2926452, 46.6318424],
            [-64.2927559, 46.631812],
            [-64.2928208, 46.631765],
            [-64.2927691, 46.6315452],
            [-64.2923306, 46.6312964],
            [-64.2922364, 46.6309405],
            [-64.2925117, 46.6299839],
            [-64.2925576, 46.6297892],
            [-64.2925102, 46.6296213],
            [-64.2921144, 46.6290861],
            [-64.2920834, 46.6289542],
            [-64.2921041, 46.628831],
            [-64.2921762, 46.6287363],
            [-64.2922984, 46.62871],
            [-64.2924484, 46.6287239],
            [-64.2928896, 46.6288732],
            [-64.293195, 46.6289131],
            [-64.2935664, 46.6288662],
            [-64.2940039, 46.6289437],
            [-64.2944903, 46.6291294],
            [-64.2950947, 46.6292369],
            [-64.2958921, 46.6292633],
            [-64.2967636, 46.6295378],
            [-64.2970783, 46.6296614],
            [-64.2974176, 46.6297336],
            [-64.2984539, 46.6299066],
            [-64.2985684, 46.6299479],
            [-64.299434, 46.630019],
            [-64.300286, 46.6301657],
            [-64.3007305, 46.6301954],
            [-64.3010027, 46.630183],
            [-64.3013938, 46.6300527],
            [-64.3018242, 46.6299667],
            [-64.3026702, 46.62991],
            [-64.3032173, 46.6297856],
            [-64.3036226, 46.6295598],
            [-64.3038714, 46.6293479],
            [-64.3040994, 46.6292593],
            [-64.3045232, 46.629201],
            [-64.3047288, 46.6290842],
            [-64.3050468, 46.6290883],
            [-64.3053207, 46.6290162],
            [-64.3057407, 46.628675],
            [-64.3060396, 46.6285315],
            [-64.3061405, 46.6284372],
            [-64.306182, 46.6279795],
            [-64.3062834, 46.6278653],
            [-64.3068638, 46.627582],
            [-64.3075036, 46.6274509],
            [-64.3079612, 46.6272138],
            [-64.3081892, 46.6269139],
            [-64.3082486, 46.6266438],
            [-64.308205, 46.6263364],
            [-64.3086833, 46.6259761],
            [-64.3091818, 46.6257236],
            [-64.3092587, 46.6254496],
            [-64.3095227, 46.6253136],
            [-64.3093547, 46.6251122],
            [-64.309289, 46.625],
            [-64.3093911, 46.6248642],
            [-64.309521, 46.6247751],
            [-64.3097844, 46.6246999],
            [-64.3105655, 46.6246716],
            [-64.3135329, 46.6246392],
            [-64.3157457, 46.6245823],
            [-64.3172228, 46.6246658],
            [-64.3178903, 46.6247536],
            [-64.3183148, 46.6248536],
            [-64.318377, 46.6249062],
            [-64.3183836, 46.6249893],
            [-64.3182085, 46.625155],
            [-64.3181576, 46.6251165],
            [-64.3181381, 46.6250434],
            [-64.3177738, 46.6248719],
            [-64.3174469, 46.624798],
            [-64.3161128, 46.6247286],
            [-64.3151243, 46.6247742],
            [-64.3143057, 46.6247184],
            [-64.3135626, 46.6248616],
            [-64.313122, 46.625],
            [-64.3132276, 46.6251824],
            [-64.3134081, 46.6253481],
            [-64.313719, 46.6253999],
            [-64.3139453, 46.625371],
            [-64.314086, 46.6253011],
            [-64.3143573, 46.625],
            [-64.3143663, 46.62499],
            [-64.3144541, 46.6249513],
            [-64.3145174, 46.625],
            [-64.3145278, 46.625008],
            [-64.3146489, 46.6252327],
            [-64.3147176, 46.6252575],
            [-64.314885, 46.6252676],
            [-64.3149647, 46.6253165],
            [-64.3149903, 46.6254363],
            [-64.3147673, 46.6257681],
            [-64.3147684, 46.6259395],
            [-64.3149506, 46.6260454],
            [-64.3149768, 46.6261454],
            [-64.3148192, 46.6266214],
            [-64.3146747, 46.6268307],
            [-64.3142171, 46.6268567],
            [-64.3141485, 46.6276766],
            [-64.3140912, 46.6278671],
            [-64.3138856, 46.6281951],
            [-64.3134717, 46.6285285],
            [-64.3131401, 46.6294446],
            [-64.3129559, 46.6308965],
            [-64.312879, 46.6311704],
            [-64.312644, 46.631518],
            [-64.3124651, 46.6317149],
            [-64.3119741, 46.632111],
            [-64.3117298, 46.6323748],
            [-64.3108385, 46.6330286],
            [-64.3105461, 46.6333555],
            [-64.3104005, 46.6336047],
            [-64.3103279, 46.6341416],
            [-64.3103574, 46.6343333],
            [-64.3104398, 46.6344937],
            [-64.3105963, 46.634691],
            [-64.3108506, 46.6349135],
            [-64.3120824, 46.6357702],
            [-64.3123754, 46.6360569],
            [-64.3124474, 46.6363845],
            [-64.312382, 46.6368738],
            [-64.3124071, 46.6370136],
            [-64.3125604, 46.6373303],
            [-64.3126674, 46.6378617],
            [-64.3127612, 46.6380263],
            [-64.3130787, 46.6382615],
            [-64.3132823, 46.6386466],
            [-64.3134732, 46.6388563],
            [-64.3135006, 46.6393388],
            [-64.31347, 46.6396094],
            [-64.3136567, 46.6401895],
            [-64.3135657, 46.6414037],
            [-64.3136269, 46.6419185],
            [-64.3137251, 46.6421349],
            [-64.3138124, 46.6425385],
            [-64.3140372, 46.6427805],
            [-64.3145322, 46.6430897],
            [-64.3156231, 46.6435938],
            [-64.3159002, 46.6439785],
            [-64.316112, 46.644042],
            [-64.3162978, 46.6439827],
            [-64.3164221, 46.6439067],
            [-64.3165918, 46.6438772],
            [-64.3168153, 46.6439399],
            [-64.3169469, 46.6440071],
            [-64.3170542, 46.6440232],
            [-64.3168962, 46.6438906],
            [-64.3167696, 46.6438468],
            [-64.3165587, 46.6438022],
            [-64.316384, 46.6438396],
            [-64.3160218, 46.643627],
            [-64.3155399, 46.6432194],
            [-64.3150881, 46.643069],
            [-64.3149337, 46.6430033],
            [-64.3146101, 46.642776],
            [-64.3143231, 46.6424814],
            [-64.3142511, 46.6423649],
            [-64.3142112, 46.6421293],
            [-64.3143191, 46.6411425],
            [-64.3141788, 46.6403477],
            [-64.3142628, 46.6400261],
            [-64.3142552, 46.6398825],
            [-64.3140914, 46.6393106],
            [-64.3135157, 46.6379364],
            [-64.3132379, 46.6366697],
            [-64.3133851, 46.6359385],
            [-64.313312, 46.6356506],
            [-64.312959, 46.6350205],
            [-64.3127364, 46.6346988],
            [-64.3125089, 46.6345564],
            [-64.3117779, 46.6342123],
            [-64.311528, 46.6340417],
            [-64.3114978, 46.6339438],
            [-64.3115341, 46.6338805],
            [-64.3116594, 46.63379],
            [-64.3118078, 46.63367],
            [-64.3119892, 46.6334888],
            [-64.312398, 46.6333145],
            [-64.3126024, 46.6333349],
            [-64.3132618, 46.6330156],
            [-64.313641, 46.6327168],
            [-64.3137069, 46.6324722],
            [-64.3140334, 46.6320217],
            [-64.3141462, 46.6317885],
            [-64.3142875, 46.6314875],
            [-64.3144019, 46.6313376],
            [-64.3143092, 46.6311133],
            [-64.3142231, 46.6311113],
            [-64.3142154, 46.631072],
            [-64.3145577, 46.6310502],
            [-64.3149028, 46.6308868],
            [-64.31533, 46.6305954],
            [-64.3154608, 46.6305286],
            [-64.3156542, 46.629939],
            [-64.315901, 46.6294045],
            [-64.3159626, 46.6292659],
            [-64.3158629, 46.6289329],
            [-64.3157641, 46.6287256],
            [-64.3157087, 46.628637],
            [-64.3156494, 46.6285487],
            [-64.315872, 46.6281054],
            [-64.3159584, 46.6274532],
            [-64.3160623, 46.6270032],
            [-64.3163863, 46.6268673],
            [-64.3165518, 46.6267739],
            [-64.3167373, 46.6265463],
            [-64.3172863, 46.6265209],
            [-64.3174842, 46.6263069],
            [-64.3175089, 46.6260691],
            [-64.3177018, 46.6259944],
            [-64.3181989, 46.6259808],
            [-64.318048, 46.6258194],
            [-64.3182434, 46.6257669],
            [-64.3184561, 46.6254832],
            [-64.3183868, 46.6252421],
            [-64.3185674, 46.6251283],
            [-64.3187425, 46.6249077],
            [-64.3188357, 46.624881],
            [-64.3189852, 46.6249148],
            [-64.3190583, 46.6249914],
            [-64.3193048, 46.625002],
            [-64.3194767, 46.6250639],
            [-64.319817, 46.6250962],
            [-64.3200253, 46.6250909],
            [-64.3203174, 46.625],
            [-64.3203694, 46.6249838],
            [-64.3205052, 46.6248819],
            [-64.3206115, 46.6245885],
            [-64.3206813, 46.6245734],
            [-64.3211924, 46.6247075],
            [-64.3212166, 46.6249403],
            [-64.321295, 46.6250512],
            [-64.3213425, 46.625172],
            [-64.3214186, 46.6253434],
            [-64.3215302, 46.6256518],
            [-64.3218225, 46.6258379],
            [-64.321978, 46.6258053],
            [-64.3223806, 46.6258902],
            [-64.3224261, 46.6260793],
            [-64.3224641, 46.626342],
            [-64.3225002, 46.626597],
            [-64.32258, 46.6270682],
            [-64.3229061, 46.6269847],
            [-64.3230567, 46.6269787],
            [-64.3235067, 46.6270203],
            [-64.3235437, 46.6269331],
            [-64.3232595, 46.6267502],
            [-64.3232758, 46.6265751],
            [-64.3233718, 46.6264488],
            [-64.3238254, 46.6261399],
            [-64.3232997, 46.6261212],
            [-64.3232026, 46.6260761],
            [-64.3231758, 46.6259961],
            [-64.3232515, 46.625762],
            [-64.3232379, 46.6256263],
            [-64.3230818, 46.6254091],
            [-64.3230671, 46.6253133],
            [-64.3231057, 46.6251664],
            [-64.3232611, 46.625],
            [-64.3234399, 46.6247842],
            [-64.3238712, 46.6244471],
            [-64.3239088, 46.62434],
            [-64.3241327, 46.6239229],
            [-64.3238251, 46.6233445],
            [-64.3238759, 46.6233087],
            [-64.324579, 46.6233426],
            [-64.3252181, 46.6233582],
            [-64.3260478, 46.6233191],
            [-64.3269961, 46.623283],
            [-64.3277055, 46.6231704],
            [-64.3283615, 46.6230578],
            [-64.3291113, 46.6228425],
            [-64.3296151, 46.6226661],
            [-64.3308543, 46.6223959],
            [-64.3314402, 46.6221173],
            [-64.3319358, 46.6219729],
            [-64.3321536, 46.6218078],
            [-64.3328521, 46.6216634],
            [-64.3337914, 46.6214169],
            [-64.3339467, 46.6214387],
            [-64.3349922, 46.6211427],
            [-64.3355543, 46.6211464],
            [-64.3379478, 46.620862],
            [-64.338966, 46.6207311],
            [-64.3394241, 46.6206851],
            [-64.3400912, 46.6206745],
            [-64.3405715, 46.6206824],
            [-64.3408965, 46.6207081],
            [-64.3413105, 46.6206883],
            [-64.3420382, 46.6205383],
            [-64.3430131, 46.6205444],
            [-64.3438334, 46.6206659],
            [-64.3440655, 46.6207557],
            [-64.3442783, 46.6209216],
            [-64.3471052, 46.6213855],
            [-64.3477292, 46.6213065],
            [-64.3489632, 46.6211444],
            [-64.3502477, 46.6213192],
            [-64.3513388, 46.6213686],
            [-64.3523639, 46.6216008],
            [-64.3542506, 46.621838],
            [-64.3544967, 46.6221047],
            [-64.3548039, 46.6221164],
            [-64.3549916, 46.6222023],
            [-64.3555774, 46.6221516],
            [-64.3559624, 46.6221171],
            [-64.3560893, 46.6219992],
            [-64.3562428, 46.6218742],
            [-64.3564869, 46.6218921],
            [-64.3567718, 46.6220969],
            [-64.3570852, 46.6222164],
            [-64.3582878, 46.6222808],
            [-64.3617834, 46.6223779],
            [-64.3633267, 46.6224048],
            [-64.3646102, 46.6220413],
            [-64.3651327, 46.6217783],
            [-64.3658356, 46.6214919],
            [-64.366585, 46.6213441],
            [-64.3670823, 46.6213422],
            [-64.3688072, 46.621258],
            [-64.3698602, 46.6209684],
            [-64.3706704, 46.6207645],
            [-64.3712009, 46.6205452],
            [-64.3712344, 46.6202495],
            [-64.3713015, 46.6197525],
            [-64.3715084, 46.6195183],
            [-64.3716258, 46.618591],
            [-64.3716944, 46.6185029],
            [-64.3717666, 46.6184849],
            [-64.3718284, 46.6185162],
            [-64.371815, 46.6185956],
            [-64.3716836, 46.6195485],
            [-64.3716648, 46.619931],
            [-64.3717083, 46.6199379],
            [-64.371774, 46.6199483],
            [-64.3717837, 46.6200265],
            [-64.3718431, 46.6200265],
            [-64.371904, 46.6200265],
            [-64.3719186, 46.6199362],
            [-64.372688, 46.6199585],
            [-64.372834, 46.618146],
            [-64.3722292, 46.6179941],
            [-64.3715881, 46.6183127],
            [-64.371487, 46.6182837],
            [-64.3714457, 46.6182432],
            [-64.3720761, 46.6179265],
            [-64.3722221, 46.6177993],
            [-64.37259, 46.6178688],
            [-64.3731052, 46.6179783],
            [-64.3730928, 46.618021],
            [-64.3739766, 46.6181594],
            [-64.3756331, 46.6181659],
            [-64.3773853, 46.6181119],
            [-64.3777844, 46.6180936],
            [-64.3782214, 46.6180634],
            [-64.3787654, 46.6180414],
            [-64.3800123, 46.618015],
            [-64.3805989, 46.6180126],
            [-64.3810588, 46.6179669],
            [-64.3819735, 46.6179082],
            [-64.3828414, 46.6177322],
            [-64.3833612, 46.6176478],
            [-64.3835994, 46.6176543],
            [-64.384018, 46.6178624],
            [-64.38433, 46.6180804],
            [-64.3846276, 46.6183183],
            [-64.3849487, 46.6185302],
            [-64.3866782, 46.6198172],
            [-64.3869686, 46.620097],
            [-64.3873763, 46.6206186],
            [-64.3876323, 46.6209354],
            [-64.3889474, 46.6227233],
            [-64.3894793, 46.6235833],
            [-64.3900385, 46.6243288],
            [-64.3906547, 46.6250521],
            [-64.3915538, 46.6263742],
            [-64.3922062, 46.6272402],
            [-64.3923995, 46.6276066],
            [-64.3925601, 46.6278713],
            [-64.3927771, 46.6283117],
            [-64.393241, 46.6293598],
            [-64.3938894, 46.6310371],
            [-64.3943524, 46.6320507],
            [-64.3945713, 46.6328397],
            [-64.3947968, 46.6336014],
            [-64.3953181, 46.6343375],
            [-64.3955676, 46.6347588],
            [-64.395868, 46.6354597],
            [-64.3966122, 46.636884],
            [-64.397053, 46.6378284],
            [-64.397783, 46.6392755],
            [-64.3980364, 46.6397358],
            [-64.3982203, 46.640111],
            [-64.398762, 46.6410928],
            [-64.399082, 46.6414792],
            [-64.400658, 46.6434557],
            [-64.4013544, 46.6442772],
            [-64.4017297, 46.6449935],
            [-64.4018666, 46.6451877],
            [-64.4022694, 46.6456729],
            [-64.4025408, 46.6460178],
            [-64.402787, 46.6463447],
            [-64.4030417, 46.6466508],
            [-64.4032276, 46.6468483],
            [-64.403438, 46.6470289],
            [-64.4036419, 46.6471022],
            [-64.4039864, 46.6471598],
            [-64.4040277, 46.647349],
            [-64.404083, 46.6474715],
            [-64.4041588, 46.6476298],
            [-64.4043009, 46.6477342],
            [-64.4044202, 46.6477922],
            [-64.4046699, 46.6478618],
            [-64.4047857, 46.6481633],
            [-64.4051345, 46.6484957],
            [-64.4054703, 46.6486667],
            [-64.4055967, 46.648803],
            [-64.4057808, 46.6488848],
            [-64.4059685, 46.649093],
            [-64.4061996, 46.6490657],
            [-64.4061491, 46.6491748],
            [-64.4062266, 46.6492448],
            [-64.4062853, 46.6494184],
            [-64.4063647, 46.6498541],
            [-64.4071247, 46.6512877],
            [-64.4075165, 46.6517174],
            [-64.407781, 46.6523124],
            [-64.4079444, 46.6529596],
            [-64.4082023, 46.6533525],
            [-64.4086011, 46.6536766],
            [-64.4089362, 46.6538172],
            [-64.4091621, 46.6537676],
            [-64.4090751, 46.6539458],
            [-64.4091187, 46.6540278],
            [-64.4090686, 46.6541024],
            [-64.409172, 46.6543706],
            [-64.4093489, 46.6544841],
            [-64.4094547, 46.65464],
            [-64.4095254, 46.6548382],
            [-64.4095513, 46.6550332],
            [-64.4096094, 46.6551864],
            [-64.4096794, 46.655651],
            [-64.409819, 46.6559942],
            [-64.4098961, 46.656315],
            [-64.4100963, 46.6568239],
            [-64.4102413, 46.6569809],
            [-64.410576, 46.6571981],
            [-64.4107996, 46.657213],
            [-64.4109322, 46.6573194],
            [-64.4110673, 46.6574865],
            [-64.4111736, 46.6576083],
            [-64.411435, 46.6577202],
            [-64.4116405, 46.6577841],
            [-64.4117632, 46.657913],
            [-64.4116632, 46.6582378],
            [-64.4116757, 46.6585296],
            [-64.4116485, 46.6590601],
            [-64.4116395, 46.6591737],
            [-64.4115206, 46.6597063],
            [-64.4116643, 46.6604412],
            [-64.4116448, 46.6607558],
            [-64.4115076, 46.6616109],
            [-64.4117171, 46.6629523],
            [-64.4117605, 46.6630684],
            [-64.4118333, 46.6631649],
            [-64.4121027, 46.6632717],
            [-64.4121705, 46.6633363],
            [-64.412192, 46.6634043],
            [-64.4120204, 46.6637848],
            [-64.4120702, 46.6638731],
            [-64.4121945, 46.6639981],
            [-64.4124354, 46.6640846],
            [-64.4124618, 46.6641845],
            [-64.4122763, 46.6651228],
            [-64.4122179, 46.6658354],
            [-64.4124648, 46.6668385],
            [-64.4123715, 46.6670964],
            [-64.4123456, 46.66767],
            [-64.4123875, 46.6678458],
            [-64.4122478, 46.6685694],
            [-64.4123326, 46.6695746],
            [-64.4123994, 46.669679],
            [-64.4126838, 46.6698817],
            [-64.4127407, 46.6699222],
            [-64.4128011, 46.6700544],
            [-64.4129073, 46.6706654],
            [-64.413061, 46.6710019],
            [-64.4134237, 46.6713131],
            [-64.4134886, 46.6714972],
            [-64.4134357, 46.6717596],
            [-64.413529, 46.672195],
            [-64.4134916, 46.6723022],
            [-64.4132277, 46.6726697],
            [-64.4132137, 46.6727651],
            [-64.4132556, 46.672941],
            [-64.4132057, 46.6730839],
            [-64.4129697, 46.6734915],
            [-64.4129243, 46.6736862],
            [-64.4130321, 46.6756243],
            [-64.4132167, 46.6758815],
            [-64.4135251, 46.6760525],
            [-64.413569, 46.6761487],
            [-64.4135505, 46.6761923],
            [-64.4133445, 46.6763294],
            [-64.4136898, 46.6765927],
            [-64.4137078, 46.6768532],
            [-64.4134953, 46.6774777],
            [-64.4135509, 46.6776543],
            [-64.4135274, 46.6777727],
            [-64.413461, 46.6779187],
            [-64.413462, 46.6780038],
            [-64.4135049, 46.6781014],
            [-64.4135344, 46.6781731],
            [-64.4135156, 46.6783075],
            [-64.4134378, 46.6784878],
            [-64.4134325, 46.6786866],
            [-64.4135183, 46.6789589],
            [-64.4135183, 46.6791153],
            [-64.4135371, 46.6792828],
            [-64.4136015, 46.6794318],
            [-64.4136444, 46.6797336],
            [-64.4136819, 46.6798459],
            [-64.4137034, 46.6799655],
            [-64.4136685, 46.6801274],
            [-64.4136631, 46.6802618],
            [-64.4136926, 46.6806059],
            [-64.4137061, 46.6807292],
            [-64.413757, 46.6809463],
            [-64.4137999, 46.6811101],
            [-64.4137999, 46.6812996],
            [-64.4137919, 46.6814119],
            [-64.4137677, 46.6815664],
            [-64.4137007, 46.681721],
            [-64.4135666, 46.6818903],
            [-64.4133976, 46.6821038],
            [-64.4131616, 46.6823448],
            [-64.4129953, 46.6825067],
            [-64.4127861, 46.6828398],
            [-64.4125447, 46.6831839],
            [-64.4123542, 46.6833753],
            [-64.4122201, 46.6835372],
            [-64.4121238, 46.6836716],
            [-64.4117701, 46.6841654],
            [-64.4115964, 46.6843945],
            [-64.4110668, 46.6854204],
            [-64.410977, 46.6857701],
            [-64.4106955, 46.6863725],
            [-64.4106201, 46.6866067],
            [-64.4105597, 46.6873991],
            [-64.4106136, 46.6875591],
            [-64.4107099, 46.6876439],
            [-64.4107808, 46.6878201],
            [-64.410638, 46.688201],
            [-64.4104488, 46.6883542],
            [-64.4101613, 46.6894268],
            [-64.4101418, 46.6899726],
            [-64.4102141, 46.6905513],
            [-64.4101612, 46.6908137],
            [-64.4102096, 46.6909618],
            [-64.4101881, 46.6913561],
            [-64.4103099, 46.6918118],
            [-64.4104207, 46.6920124],
            [-64.4106403, 46.6927244],
            [-64.4107281, 46.6929168],
            [-64.4109872, 46.6932108],
            [-64.4112892, 46.6936408],
            [-64.4114274, 46.6939015],
            [-64.4114634, 46.6940853],
            [-64.4113311, 46.6945101],
            [-64.4112981, 46.6951315],
            [-64.4112187, 46.6952939],
            [-64.4111174, 46.6958706],
            [-64.4109022, 46.6963662],
            [-64.4106136, 46.6967853],
            [-64.4105497, 46.6970236],
            [-64.4106161, 46.6973791],
            [-64.4103485, 46.6983484],
            [-64.4101068, 46.6987441],
            [-64.4100119, 46.6995241],
            [-64.4096919, 46.7002736],
            [-64.4096255, 46.7006115],
            [-64.4093518, 46.7011264],
            [-64.409244, 46.7014997],
            [-64.4093328, 46.7018834],
            [-64.4095505, 46.7022128],
            [-64.4097916, 46.7027617],
            [-64.4098155, 46.7029613],
            [-64.4098, 46.7035789],
            [-64.4099008, 46.7041779],
            [-64.4100431, 46.7045104],
            [-64.4102243, 46.7046759],
            [-64.4103117, 46.7048882],
            [-64.4102977, 46.7049837],
            [-64.4102433, 46.7050747],
            [-64.4100421, 46.7052437],
            [-64.4100111, 46.7055542],
            [-64.410077, 46.7056985],
            [-64.4099796, 46.7058847],
            [-64.4099262, 46.7059358],
            [-64.4099536, 46.7062271],
            [-64.4098463, 46.7063494],
            [-64.4097614, 46.7064998],
            [-64.4098228, 46.7065922],
            [-64.4099551, 46.7066297],
            [-64.409988, 46.7067018],
            [-64.4099391, 46.7068049],
            [-64.4097973, 46.7069148],
            [-64.4096455, 46.7069608],
            [-64.4096155, 46.7070003],
            [-64.4096944, 46.7070889],
            [-64.4096849, 46.7072363],
            [-64.409614, 46.7072912],
            [-64.4094098, 46.7073486],
            [-64.4093703, 46.7075354],
            [-64.4092934, 46.7075983],
            [-64.4089444, 46.7076539],
            [-64.408879, 46.7077209],
            [-64.408846, 46.7078799],
            [-64.4087566, 46.7079785],
            [-64.4086807, 46.7080015],
            [-64.4086183, 46.7081801],
            [-64.4085129, 46.7082227],
            [-64.4084944, 46.7082663],
            [-64.4086013, 46.7083951],
            [-64.4085708, 46.7084546],
            [-64.4082872, 46.7086744],
            [-64.4079726, 46.7087424],
            [-64.4078902, 46.7087932],
            [-64.4078038, 46.7090034],
            [-64.4062501, 46.7106469],
            [-64.405334, 46.7118158],
            [-64.4050443, 46.7122747],
            [-64.404387, 46.7130639],
            [-64.4040967, 46.7135428],
            [-64.4038285, 46.7138385],
            [-64.4034017, 46.7151327],
            [-64.402935, 46.7161714],
            [-64.4028401, 46.7164892],
            [-64.4029284, 46.7166616],
            [-64.4038518, 46.7172745],
            [-64.4039367, 46.7173552],
            [-64.404014, 46.7175036],
            [-64.4040025, 46.7177307],
            [-64.4038751, 46.7179563],
            [-64.4035554, 46.7182236],
            [-64.4025794, 46.7187779],
            [-64.4021662, 46.7190719],
            [-64.4019169, 46.719304],
            [-64.4012315, 46.719822],
            [-64.4010387, 46.7201146],
            [-64.4008577, 46.7206226],
            [-64.4008127, 46.7210287],
            [-64.4007382, 46.7212231],
            [-64.3999558, 46.7219072],
            [-64.3997349, 46.7223909],
            [-64.3997053, 46.7226416],
            [-64.3996563, 46.7227447],
            [-64.3996024, 46.7228158],
            [-64.399414, 46.7229291],
            [-64.3994335, 46.7230768],
            [-64.3995858, 46.7232421],
            [-64.3995178, 46.7234086],
            [-64.3991806, 46.7234484],
            [-64.3991791, 46.7235082],
            [-64.3993244, 46.7237212],
            [-64.3992409, 46.7238119],
            [-64.3989152, 46.7238558],
            [-64.3988563, 46.723895],
            [-64.3988113, 46.7240698],
            [-64.3988926, 46.72429],
            [-64.3988946, 46.7244415],
            [-64.3988356, 46.7244806],
            [-64.3986723, 46.7245225],
            [-64.398412, 46.7244995],
            [-64.3983016, 46.7245101],
            [-64.3980428, 46.7246585],
            [-64.3973514, 46.7249531],
            [-64.397157, 46.7250743],
            [-64.3969886, 46.7253154],
            [-64.3967587, 46.7254641],
            [-64.3963691, 46.7255152],
            [-64.395796, 46.7257116],
            [-64.3951161, 46.7260104],
            [-64.3940209, 46.7264516],
            [-64.3935747, 46.7266734],
            [-64.3928777, 46.7269559],
            [-64.3927478, 46.72705],
            [-64.391634, 46.7275348],
            [-64.3906896, 46.7279699],
            [-64.390138, 46.7282342],
            [-64.3898896, 46.7284265],
            [-64.3896916, 46.7286872],
            [-64.3893359, 46.7287706],
            [-64.3890755, 46.7289787],
            [-64.3887352, 46.729138],
            [-64.3886877, 46.7291813],
            [-64.3887771, 46.7293139],
            [-64.3886646, 46.7294042],
            [-64.3885067, 46.7294581],
            [-64.3883807, 46.729624],
            [-64.3880435, 46.7296638],
            [-64.3878356, 46.7298605],
            [-64.3875907, 46.7299134],
            [-64.3873485, 46.7298666],
            [-64.3868656, 46.7303869],
            [-64.3864697, 46.7306771],
            [-64.3854882, 46.7311993],
            [-64.3847995, 46.7316055],
            [-64.3842076, 46.7320766],
            [-64.3832643, 46.7329141],
            [-64.3830013, 46.7332219],
            [-64.3818499, 46.7342562],
            [-64.3810929, 46.7350601],
            [-64.3799243, 46.735858],
            [-64.3792155, 46.7364445],
            [-64.3788274, 46.7368476],
            [-64.3780043, 46.7375433],
            [-64.377416, 46.7384055],
            [-64.3771912, 46.7391671],
            [-64.3773219, 46.7392133],
            [-64.3778122, 46.7393127],
            [-64.3781288, 46.7393603],
            [-64.3787844, 46.7396483],
            [-64.3790366, 46.7398071],
            [-64.3790144, 46.739859],
            [-64.3789404, 46.7398475],
            [-64.3788208, 46.7398721],
            [-64.3784085, 46.7396863],
            [-64.3779994, 46.7395589],
            [-64.3777937, 46.7398272],
            [-64.3779297, 46.7398817],
            [-64.3774957, 46.7405119],
            [-64.3785164, 46.7408278],
            [-64.3785555, 46.7407669],
            [-64.3785751, 46.7407732],
            [-64.3785131, 46.7408741],
            [-64.3785576, 46.7408863],
            [-64.378615, 46.7409034],
            [-64.3792439, 46.74004],
            [-64.3793222, 46.7400707],
            [-64.3794436, 46.7399686],
            [-64.3795304, 46.7398636],
            [-64.3795453, 46.7397948],
            [-64.3795131, 46.7397441],
            [-64.3795373, 46.7397057],
            [-64.3796008, 46.7397135],
            [-64.3796677, 46.7398277],
            [-64.3796526, 46.739945],
            [-64.3795723, 46.7400503],
            [-64.3795345, 46.7401497],
            [-64.3792836, 46.7405965],
            [-64.378706, 46.7411778],
            [-64.3784081, 46.7413322],
            [-64.3783557, 46.741588],
            [-64.3783401, 46.7417432],
            [-64.3780244, 46.7422935],
            [-64.3780177, 46.7425525],
            [-64.378119, 46.7426693],
            [-64.3783434, 46.7427398],
            [-64.3784857, 46.7428412],
            [-64.3785066, 46.7429292],
            [-64.3784054, 46.7432548],
            [-64.3784418, 46.7434187],
            [-64.3784112, 46.7434781],
            [-64.3780337, 46.7437246],
            [-64.3778641, 46.7440056],
            [-64.3774796, 46.7442999],
            [-64.3773344, 46.7445293],
            [-64.3770504, 46.7447491],
            [-64.3768739, 46.7448465],
            [-64.3767852, 46.7451365],
            [-64.3767247, 46.7452354],
            [-64.3764531, 46.7454194],
            [-64.3762921, 46.7455928],
            [-64.3757, 46.7460639],
            [-64.375506, 46.7461652],
            [-64.3751713, 46.7465478],
            [-64.3750588, 46.7466381],
            [-64.3747781, 46.7469496],
            [-64.3746502, 46.7469639],
            [-64.3743903, 46.7469209],
            [-64.3742323, 46.7469748],
            [-64.3740517, 46.7472317],
            [-64.3739337, 46.7473099],
            [-64.3737877, 46.747348],
            [-64.3730362, 46.7477015],
            [-64.3725717, 46.747947],
            [-64.3723852, 46.7479806],
            [-64.3720489, 46.7479804],
            [-64.3719013, 46.7480783],
            [-64.371476, 46.7485992],
            [-64.3713115, 46.7486809],
            [-64.3707876, 46.7487542],
            [-64.3703902, 46.7488729],
            [-64.3698961, 46.7491379],
            [-64.3696791, 46.7492309],
            [-64.3693568, 46.7491352],
            [-64.3692863, 46.7491702],
            [-64.3691435, 46.7495312],
            [-64.3690485, 46.7496178],
            [-64.368779, 46.7497221],
            [-64.3686933, 46.7498924],
            [-64.3687591, 46.7499999],
            [-64.3687354, 46.7502795],
            [-64.3682249, 46.7505084],
            [-64.367016, 46.7508284],
            [-64.3665584, 46.751026],
            [-64.3660698, 46.751303],
            [-64.3654851, 46.7517064],
            [-64.3650865, 46.7518649],
            [-64.3646721, 46.7519674],
            [-64.3644655, 46.7521044],
            [-64.3641978, 46.7523602],
            [-64.3641367, 46.7524791],
            [-64.3642458, 46.7527395],
            [-64.3642388, 46.7527873],
            [-64.3640082, 46.7529558],
            [-64.3639481, 46.7530348],
            [-64.3637005, 46.7531872],
            [-64.3635749, 46.7533332],
            [-64.3634464, 46.7533675],
            [-64.362865, 46.7534201],
            [-64.3625665, 46.753524],
            [-64.3623604, 46.753641],
            [-64.3621674, 46.7537024],
            [-64.3616205, 46.7537674],
            [-64.3611284, 46.7539527],
            [-64.3610223, 46.7540151],
            [-64.3609317, 46.7541535],
            [-64.3608499, 46.7543957],
            [-64.3606679, 46.7544811],
            [-64.3604122, 46.7547211],
            [-64.3602432, 46.7547509],
            [-64.3596568, 46.7547715],
            [-64.3595163, 46.7548216],
            [-64.3592545, 46.7550695],
            [-64.3586945, 46.7551901],
            [-64.3581789, 46.755387],
            [-64.3578433, 46.7555782],
            [-64.3577952, 46.7556414],
            [-64.357732, 46.7558399],
            [-64.3576418, 46.7559584],
            [-64.3574997, 46.7560682],
            [-64.3574752, 46.7561198],
            [-64.3575159, 46.7563355],
            [-64.3574733, 46.7564108],
            [-64.357165, 46.7566621],
            [-64.3566609, 46.7568631],
            [-64.3565548, 46.7569255],
            [-64.3559932, 46.7571059],
            [-64.3556056, 46.7572884],
            [-64.355505, 46.7573629],
            [-64.3554336, 46.7576491],
            [-64.3550089, 46.7579188],
            [-64.3549267, 46.7583922],
            [-64.3547023, 46.7587642],
            [-64.3546187, 46.7588548],
            [-64.35401, 46.7590585],
            [-64.3539269, 46.7591292],
            [-64.3539091, 46.7593642],
            [-64.353773, 46.7594662],
            [-64.3532944, 46.7595758],
            [-64.3532239, 46.7596108],
            [-64.3531267, 46.759777],
            [-64.3530078, 46.7601064],
            [-64.3526041, 46.7602329],
            [-64.3523975, 46.76037],
            [-64.3520434, 46.760816],
            [-64.351704, 46.7611467],
            [-64.3511179, 46.7615898],
            [-64.3508068, 46.7617294],
            [-64.349388, 46.7627283],
            [-64.3490398, 46.7631664],
            [-64.3486814, 46.7637719],
            [-64.3485308, 46.7642005],
            [-64.3483695, 46.7643739],
            [-64.3481924, 46.7644913],
            [-64.347506, 46.7647777],
            [-64.3464625, 46.7653985],
            [-64.3455593, 46.7659891],
            [-64.3443572, 46.7666836],
            [-64.3434838, 46.7672348],
            [-64.3427187, 46.767855],
            [-64.3427171, 46.7679148],
            [-64.3428716, 46.7682118],
            [-64.3428345, 46.768299],
            [-64.3427459, 46.7683577],
            [-64.3423126, 46.7685037],
            [-64.3417667, 46.76874],
            [-64.3406685, 46.7694518],
            [-64.3401937, 46.7696332],
            [-64.339952, 46.7697776],
            [-64.3396037, 46.7702157],
            [-64.3393069, 46.7706824],
            [-64.3387797, 46.7710864],
            [-64.3383803, 46.7712647],
            [-64.3382151, 46.7713663],
            [-64.3378258, 46.7718199],
            [-64.3372405, 46.7722231],
            [-64.3369167, 46.7723984],
            [-64.3368095, 46.7725007],
            [-64.3361941, 46.7729434],
            [-64.3358648, 46.7731067],
            [-64.3346568, 46.7735778],
            [-64.3340144, 46.7737291],
            [-64.3335674, 46.7739507],
            [-64.3334717, 46.7740571],
            [-64.3330405, 46.7741234],
            [-64.3329634, 46.7741862],
            [-64.3318647, 46.7746865],
            [-64.3310791, 46.7751988],
            [-64.3305205, 46.7754708],
            [-64.3299767, 46.7758386],
            [-64.3296206, 46.7761331],
            [-64.3291834, 46.7764186],
            [-64.3287917, 46.7767405],
            [-64.3280503, 46.7771178],
            [-64.3277253, 46.7773329],
            [-64.3273374, 46.7777266],
            [-64.3270825, 46.7779267],
            [-64.326758, 46.7781219],
            [-64.326299, 46.7785706],
            [-64.3254558, 46.7792734],
            [-64.3253815, 46.7794479],
            [-64.3253433, 46.7797863],
            [-64.3252601, 46.779857],
            [-64.325097, 46.7798788],
            [-64.324715, 46.7798421],
            [-64.3246389, 46.779865],
            [-64.3242778, 46.7803388],
            [-64.3241766, 46.7804332],
            [-64.3240945, 46.7804641],
            [-64.3231199, 46.7810895],
            [-64.3225738, 46.781537],
            [-64.3219877, 46.7819601],
            [-64.3216753, 46.7823508],
            [-64.3214757, 46.7828626],
            [-64.3215359, 46.7829949],
            [-64.3218945, 46.7832547],
            [-64.3219274, 46.7833269],
            [-64.3219022, 46.7833983],
            [-64.3218136, 46.783457],
            [-64.3207676, 46.7837147],
            [-64.3202931, 46.783876],
            [-64.3200397, 46.7840163],
            [-64.319424, 46.7844589],
            [-64.3191125, 46.7845985],
            [-64.3189971, 46.7847884],
            [-64.3186474, 46.785055],
            [-64.3183819, 46.785211],
            [-64.3182139, 46.7854122],
            [-64.3178351, 46.7854672],
            [-64.3175374, 46.7855311],
            [-64.3171827, 46.7857658],
            [-64.316544, 46.7864114],
            [-64.3164471, 46.7865577],
            [-64.3163163, 46.7866716],
            [-64.3161789, 46.7868134],
            [-64.3159715, 46.7869702],
            [-64.3148811, 46.7873628],
            [-64.3143808, 46.7876155],
            [-64.3139971, 46.7878498],
            [-64.3134239, 46.7882172],
            [-64.3126302, 46.788797],
            [-64.3117697, 46.7892723],
            [-64.3114675, 46.7894957],
            [-64.3105571, 46.7898826],
            [-64.3103918, 46.7899841],
            [-64.310077, 46.7902432],
            [-64.3092815, 46.7906715],
            [-64.3089175, 46.7908223],
            [-64.3083454, 46.7911498],
            [-64.3080689, 46.7912818],
            [-64.3071409, 46.7918837],
            [-64.3064582, 46.7924331],
            [-64.3050894, 46.7932287],
            [-64.3043951, 46.7937739],
            [-64.3039625, 46.7940912],
            [-64.303386, 46.7945781],
            [-64.3028411, 46.7949658],
            [-64.3023954, 46.7953387],
            [-64.3016551, 46.7958674],
            [-64.3009755, 46.7965084],
            [-64.3004706, 46.7969205],
            [-64.2993151, 46.7977627],
            [-64.2981897, 46.798354],
            [-64.2978337, 46.7986285],
            [-64.2949414, 46.8005364],
            [-64.2940021, 46.8011142],
            [-64.2932588, 46.8017424],
            [-64.2925834, 46.802224],
            [-64.2912222, 46.8033545],
            [-64.2911444, 46.8034372],
            [-64.2904793, 46.8039627],
            [-64.2899299, 46.8042984],
            [-64.2893937, 46.8045785],
            [-64.2879634, 46.8054727],
            [-64.287152, 46.8060362],
            [-64.2860464, 46.8069467],
            [-64.2854792, 46.8074975],
            [-64.2842654, 46.8085301],
            [-64.2835782, 46.8092188],
            [-64.2824992, 46.8102094],
            [-64.2820197, 46.8107414],
            [-64.2803268, 46.812286],
            [-64.2795292, 46.8131766],
            [-64.2788496, 46.8137976],
            [-64.2782909, 46.8144521],
            [-64.2780836, 46.8148003],
            [-64.2777519, 46.8152345],
            [-64.2765991, 46.816571],
            [-64.2756211, 46.8174671],
            [-64.2748745, 46.8181949],
            [-64.2742571, 46.8188686],
            [-64.2735952, 46.8196772],
            [-64.2731294, 46.8205363],
            [-64.2729007, 46.8208164],
            [-64.2725039, 46.8210863],
            [-64.2718197, 46.8216553],
            [-64.2713898, 46.8220643],
            [-64.2703958, 46.8229044],
            [-64.2697555, 46.8233584],
            [-64.2691419, 46.8238926],
            [-64.2673334, 46.8255871],
            [-64.2660717, 46.8266428],
            [-64.264916, 46.8278476],
            [-64.264595, 46.8283059],
            [-64.264459, 46.8283878],
            [-64.264231, 46.8284366],
            [-64.2641244, 46.8287103],
            [-64.2639971, 46.828896],
            [-64.2628413, 46.8301008],
            [-64.2623955, 46.8304537],
            [-64.2618568, 46.8310047],
            [-64.2613673, 46.8316641],
            [-64.2608074, 46.8325499],
            [-64.2604633, 46.8329998],
            [-64.2598093, 46.8341236],
            [-64.2597415, 46.8344617],
            [-64.2596162, 46.834779],
            [-64.2592658, 46.8354482],
            [-64.2588405, 46.8365033],
            [-64.2581495, 46.8383085],
            [-64.2576067, 46.8394019],
            [-64.2570498, 46.8407822],
            [-64.2565404, 46.8419278],
            [-64.2563006, 46.8425866],
            [-64.2561462, 46.8429036],
            [-64.2559058, 46.8431795],
            [-64.2557393, 46.8435122],
            [-64.2556139, 46.8438296],
            [-64.2555173, 46.8439559],
            [-64.2549448, 46.8454716],
            [-64.2540203, 46.847505],
            [-64.253867, 46.8477821],
            [-64.2535414, 46.8481885],
            [-64.2529328, 46.8487346],
            [-64.2514842, 46.8501866],
            [-64.2507564, 46.8508308],
            [-64.2501218, 46.8514683],
            [-64.24995, 46.8516175],
            [-64.2493763, 46.8521162],
            [-64.2484433, 46.8528174],
            [-64.2474431, 46.8538368],
            [-64.2466375, 46.8547551],
            [-64.2460277, 46.8555325],
            [-64.2452957, 46.8563162],
            [-64.2447689, 46.8570428],
            [-64.2441257, 46.8581707],
            [-64.2436938, 46.8588309],
            [-64.243157, 46.8598964],
            [-64.2426706, 46.8606276],
            [-64.2420426, 46.8614287],
            [-64.2403112, 46.8633833],
            [-64.239236, 46.8649599],
            [-64.2389333, 46.8659689],
            [-64.2384227, 46.8673259],
            [-64.2383158, 46.8680025],
            [-64.2378297, 46.8691166],
            [-64.2376989, 46.8696134],
            [-64.2376456, 46.8700474],
            [-64.2372915, 46.8712192],
            [-64.2371479, 46.8721546],
            [-64.2370329, 46.8727074],
            [-64.2369936, 46.8732573],
            [-64.2368372, 46.8736341],
            [-64.2366244, 46.8739502],
            [-64.2361998, 46.8749455],
            [-64.2360894, 46.8753389],
            [-64.2358025, 46.8760011],
            [-64.2352319, 46.8770143],
            [-64.2349339, 46.877461],
            [-64.234753, 46.8776778],
            [-64.2343974, 46.8781037],
            [-64.2342183, 46.8782608],
            [-64.2340774, 46.8785022],
            [-64.2339628, 46.8788437],
            [-64.2338655, 46.8792133],
            [-64.2338597, 46.8792351],
            [-64.2338813, 46.8794805],
            [-64.2339567, 46.8795787],
            [-64.2340612, 46.8796613],
            [-64.2340715, 46.8796695],
            [-64.2347558, 46.8798489],
            [-64.2347115, 46.8799327],
            [-64.2346423, 46.8800547],
            [-64.2353994, 46.8802473],
            [-64.2353258, 46.8803617],
            [-64.2350701, 46.880296],
            [-64.2346616, 46.8801911],
            [-64.2336256, 46.8803528],
            [-64.2330056, 46.8803422],
            [-64.2318115, 46.8811913],
            [-64.2310815, 46.8816839],
            [-64.2305908, 46.8821518],
            [-64.229071, 46.8831958],
            [-64.2288627, 46.8833525],
            [-64.228041, 46.8841947],
            [-64.2274735, 46.8848969],
            [-64.2273421, 46.8850108],
            [-64.2271007, 46.8853066],
            [-64.2268759, 46.88583],
            [-64.2265246, 46.8864993],
            [-64.2262991, 46.8870426],
            [-64.2260561, 46.8875897],
            [-64.2259086, 46.8878589],
            [-64.2257926, 46.8882402],
            [-64.2257202, 46.8891208],
            [-64.2257733, 46.8902783],
            [-64.2256916, 46.8910749],
            [-64.2257029, 46.891482],
            [-64.225614, 46.8923264],
            [-64.2256388, 46.8928692],
            [-64.2257644, 46.8937406],
            [-64.2259219, 46.8943211],
            [-64.2262377, 46.8950595],
            [-64.2262404, 46.8953627],
            [-64.225891, 46.8961636],
            [-64.2256934, 46.8967473],
            [-64.2252032, 46.8973867],
            [-64.2249876, 46.8975912],
            [-64.2245265, 46.898231],
            [-64.2240779, 46.8986436],
            [-64.2236286, 46.8990762],
            [-64.2232472, 46.8993821],
            [-64.2225162, 46.8998945],
            [-64.2218106, 46.9003355],
            [-64.2206689, 46.9009619],
            [-64.2189903, 46.9018281],
            [-64.2185457, 46.9021012],
            [-64.2172325, 46.9028169],
            [-64.2162159, 46.9033373],
            [-64.2149105, 46.9041768],
            [-64.2143303, 46.9047032],
            [-64.2122875, 46.9068488],
            [-64.2113911, 46.9076341],
            [-64.2110216, 46.9079241],
            [-64.210268, 46.9085997],
            [-64.2095975, 46.9094161],
            [-64.2090832, 46.9098756],
            [-64.2082029, 46.9105056],
            [-64.2061519, 46.9121244],
            [-64.2048958, 46.9130522],
            [-64.2043953, 46.9134361],
            [-64.2039372, 46.9139563],
            [-64.2038839, 46.9139874],
            [-64.2034435, 46.9145038],
            [-64.2032054, 46.9148715],
            [-64.2029528, 46.9151432],
            [-64.2019391, 46.9165212],
            [-64.2014095, 46.9170962],
            [-64.201275, 46.9173097],
            [-64.2008544, 46.9177427],
            [-64.2001599, 46.9183791],
            [-64.1992986, 46.9189455],
            [-64.1979325, 46.9200352],
            [-64.1978041, 46.9202409],
            [-64.1974677, 46.9205832],
            [-64.1962567, 46.9215475],
            [-64.1960998, 46.9217328],
            [-64.1956227, 46.922105],
            [-64.1953457, 46.9222168],
            [-64.194871, 46.9223178],
            [-64.1949491, 46.9226181],
            [-64.1949232, 46.9227095],
            [-64.1946419, 46.9229608],
            [-64.1914616, 46.92511],
            [-64.1910809, 46.9255674],
            [-64.1894166, 46.9268722],
            [-64.1888776, 46.9273633],
            [-64.188483, 46.9278962],
            [-64.1880398, 46.9286839],
            [-64.1879635, 46.9290897],
            [-64.1879132, 46.9301702],
            [-64.1877817, 46.9304755],
            [-64.1873668, 46.9309005],
            [-64.1872765, 46.9311904],
            [-64.1873553, 46.9314708],
            [-64.1875264, 46.931593],
            [-64.1876852, 46.9317309],
            [-64.1878587, 46.9319647],
            [-64.1877587, 46.9323822],
            [-64.1877914, 46.932646],
            [-64.1881517, 46.9334411],
            [-64.1885406, 46.934065],
            [-64.1885546, 46.9341809],
            [-64.1884291, 46.9346699],
            [-64.1883685, 46.9347488],
            [-64.1883333, 46.9347563],
            [-64.1880391, 46.9346603],
            [-64.1876274, 46.9346026],
            [-64.1873005, 46.9346259],
            [-64.1870336, 46.9347817],
            [-64.1866473, 46.934844],
            [-64.1856958, 46.9351057],
            [-64.1850754, 46.9353961],
            [-64.1847115, 46.9354867],
            [-64.1841329, 46.9357417],
            [-64.1832559, 46.9362319],
            [-64.1830722, 46.9365285],
            [-64.1828399, 46.9366968],
            [-64.1823371, 46.9369489],
            [-64.1820811, 46.9371288],
            [-64.1819665, 46.9372588],
            [-64.181799, 46.9375005],
            [-64.1815643, 46.9378395],
            [-64.1815273, 46.9379068],
            [-64.1809183, 46.9382014],
            [-64.1805205, 46.938451],
            [-64.1797446, 46.9388668],
            [-64.178497, 46.9394753],
            [-64.1780166, 46.9397557],
            [-64.1763333, 46.9408926],
            [-64.1761398, 46.9411252],
            [-64.1759074, 46.941485],
            [-64.175561, 46.9417633],
            [-64.175413, 46.9418409],
            [-64.1750817, 46.9421953],
            [-64.1744926, 46.9429808],
            [-64.1741861, 46.9432836],
            [-64.1735563, 46.9436815],
            [-64.1729418, 46.9439639],
            [-64.1724267, 46.9442318],
            [-64.171555, 46.9445424],
            [-64.1710993, 46.9447713],
            [-64.1706365, 46.9450479],
            [-64.1693665, 46.9459911],
            [-64.1689362, 46.94634],
            [-64.1682942, 46.9469452],
            [-64.1677686, 46.9475521],
            [-64.167064, 46.9484875],
            [-64.1661761, 46.9493165],
            [-64.1651499, 46.9500957],
            [-64.1643932, 46.9504478],
            [-64.1638683, 46.9506517],
            [-64.1633386, 46.9508236],
            [-64.1622166, 46.9513061],
            [-64.1621862, 46.9513455],
            [-64.1618779, 46.9515166],
            [-64.1605483, 46.9521158],
            [-64.1600568, 46.952372],
            [-64.159178, 46.9527103],
            [-64.1586495, 46.9528423],
            [-64.1578496, 46.9532696],
            [-64.1574916, 46.9533521],
            [-64.1570194, 46.9535448],
            [-64.1563324, 46.9539018],
            [-64.1550439, 46.9544856],
            [-64.1542901, 46.9549294],
            [-64.1535447, 46.9552856],
            [-64.153132, 46.9554392],
            [-64.1521098, 46.9560787],
            [-64.1500661, 46.9569545],
            [-64.1491485, 46.9572284],
            [-64.1488675, 46.9572682],
            [-64.1485677, 46.9573516],
            [-64.1478897, 46.957601],
            [-64.1467147, 46.957903],
            [-64.1440717, 46.9583709],
            [-64.143163, 46.9587286],
            [-64.1417657, 46.9592428],
            [-64.1414797, 46.959442],
            [-64.14124, 46.9594664],
            [-64.1408261, 46.9594683],
            [-64.1387751, 46.9600087],
            [-64.1377323, 46.9603684],
            [-64.136993, 46.960525],
            [-64.1360697, 46.9607867],
            [-64.1354863, 46.9609896],
            [-64.1339476, 46.9613618],
            [-64.1329888, 46.9618225],
            [-64.1323805, 46.9620768],
            [-64.1314752, 46.9625063],
            [-64.1302475, 46.9629909],
            [-64.1291101, 46.9635685],
            [-64.1281855, 46.96387],
            [-64.1276068, 46.9641048],
            [-64.1274647, 46.9641745],
            [-64.1270766, 46.964468],
            [-64.1268884, 46.9645211],
            [-64.1265926, 46.9646563],
            [-64.1258182, 46.9651835],
            [-64.125388, 46.9653793],
            [-64.1246238, 46.9645811],
            [-64.1250952, 46.9644323],
            [-64.1242351, 46.963392],
            [-64.1232016, 46.9640735],
            [-64.1231165, 46.9642538],
            [-64.1237461, 46.9649446],
            [-64.1244005, 46.9646726],
            [-64.1253903, 46.9657319],
            [-64.1253439, 46.9657683],
            [-64.1251733, 46.9656418],
            [-64.1249307, 46.9657808],
            [-64.1249048, 46.9658121],
            [-64.1249049, 46.9661138],
            [-64.1247186, 46.966037],
            [-64.1245109, 46.9661536],
            [-64.1234812, 46.9664575],
            [-64.1227591, 46.9668018],
            [-64.1219121, 46.9670406],
            [-64.1211439, 46.9673683],
            [-64.1205572, 46.9676708],
            [-64.1200251, 46.9679022],
            [-64.119352, 46.968355],
            [-64.1187602, 46.9688171],
            [-64.1181295, 46.9692146],
            [-64.1175863, 46.9696135],
            [-64.1170761, 46.9698932],
            [-64.1163026, 46.9703804],
            [-64.1156566, 46.9708934],
            [-64.1148518, 46.9716316],
            [-64.1144421, 46.9720484],
            [-64.1135578, 46.9727175],
            [-64.1130882, 46.9730017],
            [-64.1129137, 46.9731707],
            [-64.1114598, 46.97433],
            [-64.110571, 46.9747755],
            [-64.1101839, 46.9750291],
            [-64.109108, 46.9758509],
            [-64.1087955, 46.9761414],
            [-64.1084196, 46.9765907],
            [-64.1080177, 46.9769398],
            [-64.1072662, 46.9772836],
            [-64.1070657, 46.9773524],
            [-64.1063961, 46.9776855],
            [-64.1062533, 46.9777751],
            [-64.1060529, 46.9778439],
            [-64.1052507, 46.9783107],
            [-64.1048424, 46.978496],
            [-64.1031554, 46.979097],
            [-64.1026469, 46.9793168],
            [-64.1023302, 46.9795554],
            [-64.1018189, 46.9802181],
            [-64.1012104, 46.9806439],
            [-64.1004094, 46.9810707],
            [-64.0985382, 46.9817766],
            [-64.0981176, 46.9819777],
            [-64.0978555, 46.9821453],
            [-64.0972935, 46.9823962],
            [-64.0966157, 46.9827969],
            [-64.0958803, 46.9831768],
            [-64.0952256, 46.9835859],
            [-64.0944978, 46.9840896],
            [-64.0942142, 46.9843806],
            [-64.0930018, 46.985264],
            [-64.0926213, 46.9854896],
            [-64.0921911, 46.9856267],
            [-64.0910461, 46.9858687],
            [-64.0907283, 46.9859556],
            [-64.0899304, 46.9862826],
            [-64.0898227, 46.9863648],
            [-64.0893249, 46.9866086],
            [-64.0884804, 46.9871105],
            [-64.0880257, 46.9876423],
            [-64.0879065, 46.9877203],
            [-64.0872521, 46.9879377],
            [-64.0867229, 46.9880693],
            [-64.0863449, 46.9882152],
            [-64.0851686, 46.9885164],
            [-64.0839987, 46.9889814],
            [-64.0814958, 46.9897211],
            [-64.0802844, 46.9902014],
            [-64.0791705, 46.9905554],
            [-64.0782436, 46.9908963],
            [-64.0773331, 46.9912734],
            [-64.0769691, 46.9913436],
            [-64.0760569, 46.9914173],
            [-64.0757973, 46.9915051],
            [-64.0752154, 46.9918194],
            [-64.0748665, 46.9919656],
            [-64.0735517, 46.9922167],
            [-64.0727624, 46.9926276],
            [-64.0722962, 46.992792],
            [-64.0716795, 46.9929221],
            [-64.0711367, 46.9931092],
            [-64.0700004, 46.9936065],
            [-64.0696935, 46.9937175],
            [-64.0682133, 46.9940298],
            [-64.0679774, 46.9941059],
            [-64.0675486, 46.9943746],
            [-64.0670588, 46.9947222],
            [-64.0668216, 46.9948383],
            [-64.0655232, 46.995297],
            [-64.0644633, 46.9957714],
            [-64.0636245, 46.9962652],
            [-64.0615394, 46.9973981],
            [-64.0608103, 46.9979215],
            [-64.0603706, 46.9983377],
            [-64.0594286, 46.998774],
            [-64.0582187, 46.9991941],
            [-64.057341, 46.9996234],
            [-64.0571046, 46.9998911],
            [-64.0565765, 46.9999826],
            [-64.056508, 46.9999999],
            [-64.0558019, 47.0006409],
            [-64.0548348, 47.0013003],
            [-64.0524498, 47.0028394],
            [-64.051448, 47.0034862],
            [-64.0505703, 47.0042586],
            [-64.0499949, 47.0047166],
            [-64.0499, 47.004763],
            [-64.0495672, 47.0049453],
            [-64.049029, 47.005336],
            [-64.048991, 47.0054232],
            [-64.0488284, 47.0055763],
            [-64.0483437, 47.0059358],
            [-64.0479798, 47.0065208],
            [-64.0477643, 47.0068566],
            [-64.0474411, 47.0072746],
            [-64.0475111, 47.0076229],
            [-64.0473505, 47.0080594],
            [-64.0471655, 47.0083559],
            [-64.0470503, 47.0084858],
            [-64.0463154, 47.008997],
            [-64.045965, 47.0095263],
            [-64.0456668, 47.0098928],
            [-64.045378, 47.0101517],
            [-64.0447048, 47.0105641],
            [-64.0443265, 47.0108815],
            [-64.0434704, 47.0120454],
            [-64.0425775, 47.0129133],
            [-64.0415829, 47.0138555],
            [-64.0402647, 47.0148724],
            [-64.0396734, 47.0152742],
            [-64.037401, 47.0168946],
            [-64.0364923, 47.0175348],
            [-64.0341227, 47.0190898],
            [-64.0323444, 47.020243],
            [-64.0312964, 47.0210246],
            [-64.0312456, 47.0211475],
            [-64.0312395, 47.0214986],
            [-64.0312897, 47.0215673],
            [-64.0312883, 47.0217788],
            [-64.0310794, 47.0219152],
            [-64.030589, 47.0219194],
            [-64.0301027, 47.0221471],
            [-64.0297567, 47.0223651],
            [-64.0285832, 47.0230609],
            [-64.0281877, 47.023362],
            [-64.0281084, 47.0234645],
            [-64.0278262, 47.0236955],
            [-64.0276857, 47.0240485],
            [-64.027065, 47.0242781],
            [-64.0262863, 47.0248644],
            [-64.0261153, 47.0250851],
            [-64.0260278, 47.0252554],
            [-64.0258127, 47.0253996],
            [-64.0253629, 47.0255801],
            [-64.0245502, 47.0261339],
            [-64.0244443, 47.0263278],
            [-64.0241832, 47.0264553],
            [-64.0239816, 47.0267155],
            [-64.0237035, 47.0268268],
            [-64.0232306, 47.0271705],
            [-64.0223899, 47.0280272],
            [-64.0214115, 47.0289855],
            [-64.0212921, 47.0290634],
            [-64.0212663, 47.0291349],
            [-64.0205049, 47.0300607],
            [-64.0202884, 47.0304164],
            [-64.0202524, 47.0306154],
            [-64.0203473, 47.0307407],
            [-64.0204762, 47.0310701],
            [-64.0207014, 47.0311415],
            [-64.020698, 47.0312413],
            [-64.0205039, 47.0314538],
            [-64.0204197, 47.0315243],
            [-64.0202636, 47.0318212],
            [-64.0202392, 47.0320243],
            [-64.0202948, 47.0321051],
            [-64.0203124, 47.032273],
            [-64.0201095, 47.0324015],
            [-64.0200844, 47.0324529],
            [-64.0200233, 47.0327033],
            [-64.020146, 47.0330406],
            [-64.020709, 47.0334767],
            [-64.020806, 47.0337138],
            [-64.0206011, 47.0337304],
            [-64.0204063, 47.0337912],
            [-64.0202733, 47.0339248],
            [-64.0202312, 47.0341317],
            [-64.0201213, 47.0342736],
            [-64.0197786, 47.0343918],
            [-64.0195153, 47.0345792],
            [-64.0190959, 47.0347202],
            [-64.0186861, 47.0349252],
            [-64.0181825, 47.0353083],
            [-64.0178791, 47.0356427],
            [-64.0177874, 47.0359326],
            [-64.0175784, 47.036069],
            [-64.0173715, 47.0361455],
            [-64.0172499, 47.0364549],
            [-64.0169988, 47.0366265],
            [-64.0165834, 47.036991],
            [-64.016204, 47.0374999],
            [-64.0151662, 47.0383055],
            [-64.0149408, 47.0385773],
            [-64.0147995, 47.0389502],
            [-64.0148062, 47.0392657],
            [-64.0150328, 47.0394689],
            [-64.0150144, 47.0396642],
            [-64.0146851, 47.0400701],
            [-64.0146484, 47.040289],
            [-64.014702, 47.0404296],
            [-64.0147243, 47.0406295],
            [-64.0145566, 47.0407506],
            [-64.0146244, 47.0409872],
            [-64.0144174, 47.0410637],
            [-64.0144126, 47.0412033],
            [-64.014532, 47.041297],
            [-64.0145401, 47.041401],
            [-64.0143643, 47.0414181],
            [-64.0142456, 47.0414761],
            [-64.0142469, 47.0416078],
            [-64.0143628, 47.0418013],
            [-64.0143017, 47.0418801],
            [-64.01405, 47.0419],
            [-64.0138789, 47.0419492],
            [-64.0139447, 47.042074],
            [-64.0139487, 47.0422976],
            [-64.0138652, 47.0423481],
            [-64.0135762, 47.0422636],
            [-64.0131215, 47.0422404],
            [-64.0130082, 47.0423105],
            [-64.0120423, 47.0428896],
            [-64.0110145, 47.0437393],
            [-64.0097713, 47.0447332],
            [-64.0088466, 47.0456404],
            [-64.0082307, 47.0463968],
            [-64.0080935, 47.0466501],
            [-64.0079922, 47.0470476],
            [-64.0080007, 47.0474749],
            [-64.0076762, 47.0477411],
            [-64.0076015, 47.0478756],
            [-64.0074338, 47.0479967],
            [-64.0071304, 47.0479878],
            [-64.0068906, 47.0481635],
            [-64.0065681, 47.0485415],
            [-64.005758, 47.0493388],
            [-64.005671, 47.0494891],
            [-64.0050562, 47.0502057],
            [-64.0044946, 47.0507395],
            [-64.0042984, 47.0510117],
            [-64.003846, 47.0526052],
            [-64.0036931, 47.052974],
            [-64.0035971, 47.0532119],
            [-64.0034443, 47.053409],
            [-64.0021744, 47.0541509],
            [-64.001782, 47.0543522],
            [-64.0014995, 47.0544115],
            [-64.0012632, 47.0546591],
            [-64.0008348, 47.054716],
            [-64.0006087, 47.0548361],
            [-64.000559, 47.0549192],
            [-64.0, 47.0550479],
            [-63.9996914, 47.0551805],
            [-63.9995515, 47.0553419],
            [-63.9989027, 47.056026],
            [-63.9986235, 47.0563289],
            [-63.998425, 47.056661],
            [-63.9984886, 47.0568456],
            [-63.9987417, 47.0571292],
            [-63.9987505, 47.0572131],
            [-63.9985867, 47.0573862],
            [-63.9985152, 47.0575926],
            [-63.9982239, 47.0577395],
            [-63.9980942, 47.057945],
            [-63.9980573, 47.058164],
            [-63.9978609, 47.0584362],
            [-63.9977997, 47.0585151],
            [-63.9978171, 47.0588547],
            [-63.9972815, 47.0589737],
            [-63.9971708, 47.0589639],
            [-63.9967255, 47.0586046],
            [-63.9955761, 47.0584852],
            [-63.9952013, 47.0585344],
            [-63.9950604, 47.0585025],
            [-63.9950394, 47.0584343],
            [-63.9949982, 47.0582779],
            [-63.9950615, 47.0581392],
            [-63.9952388, 47.0577121],
            [-63.9949903, 47.0572671],
            [-63.994696, 47.0570264],
            [-63.994689, 47.0566888],
            [-63.9944288, 47.0561498],
            [-63.9941284, 47.0558738],
            [-63.9939537, 47.0558455],
            [-63.9938219, 47.0552577],
            [-63.9933979, 47.0545271],
            [-63.9929668, 47.0540253],
            [-63.9923376, 47.0532941],
            [-63.9919033, 47.0527712],
            [-63.9913535, 47.0521821],
            [-63.9908052, 47.0517261],
            [-63.9902456, 47.05135],
            [-63.9899733, 47.051208],
            [-63.9896412, 47.0510762],
            [-63.9896232, 47.0509617],
            [-63.9896121, 47.0508389],
            [-63.9897079, 47.0507244],
            [-63.9897722, 47.0505688],
            [-63.9898547, 47.0503835],
            [-63.9898942, 47.0501408],
            [-63.9897702, 47.0499012],
            [-63.9896128, 47.0497183],
            [-63.9895113, 47.0495528],
            [-63.9895162, 47.0493654],
            [-63.9890142, 47.0485209],
            [-63.9889108, 47.0481835],
            [-63.9888015, 47.0479301],
            [-63.9885827, 47.0470305],
            [-63.9883256, 47.0463051],
            [-63.9882566, 47.0461084],
            [-63.9882, 47.0458709],
            [-63.9879014, 47.0457757],
            [-63.9882568, 47.0456279],
            [-63.9882764, 47.0451589],
            [-63.9880275, 47.044968],
            [-63.9883146, 47.0448877],
            [-63.9882456, 47.0446335],
            [-63.988456, 47.0444831],
            [-63.9882856, 47.0443435],
            [-63.988119, 47.0437677],
            [-63.9879362, 47.0432916],
            [-63.9878057, 47.0430891],
            [-63.9874209, 47.0429572],
            [-63.9874203, 47.0428055],
            [-63.987723, 47.0426627],
            [-63.9878339, 47.0425009],
            [-63.9878566, 47.0421859],
            [-63.9879354, 47.0421034],
            [-63.9880707, 47.0417383],
            [-63.9879174, 47.0411171],
            [-63.9879507, 47.0409979],
            [-63.9882483, 47.0406715],
            [-63.9883985, 47.0403825],
            [-63.9884286, 47.0401914],
            [-63.9886262, 47.0400509],
            [-63.9891337, 47.0400632],
            [-63.989241, 47.0400011],
            [-63.9892148, 47.0397492],
            [-63.9892389, 47.0393943],
            [-63.9893789, 47.0390613],
            [-63.9895352, 47.0389361],
            [-63.9895162, 47.0388081],
            [-63.9894762, 47.0381169],
            [-63.9898307, 47.0378313],
            [-63.9897997, 47.0375475],
            [-63.9897903, 47.0374833],
            [-63.9899502, 47.0369151],
            [-63.9899006, 47.0363315],
            [-63.9895562, 47.0359946],
            [-63.9896018, 47.0358597],
            [-63.9898076, 47.0356514],
            [-63.9898255, 47.0353045],
            [-63.9900815, 47.035165],
            [-63.9900731, 47.0347377],
            [-63.9899972, 47.0345688],
            [-63.9899083, 47.033769],
            [-63.9898125, 47.0333404],
            [-63.9895754, 47.0329413],
            [-63.9895818, 47.0325902],
            [-63.9898383, 47.0317641],
            [-63.9899135, 47.0311147],
            [-63.9900065, 47.0296234],
            [-63.9908953, 47.029582],
            [-63.9911341, 47.0295979],
            [-63.9918845, 47.0295064],
            [-63.9920595, 47.0295092],
            [-63.9930018, 47.0296084],
            [-63.9932291, 47.0296201],
            [-63.9934693, 47.0295961],
            [-63.9934455, 47.029436],
            [-63.9932862, 47.0293177],
            [-63.9930313, 47.0292656],
            [-63.9925673, 47.0291782],
            [-63.9915626, 47.0291978],
            [-63.9912259, 47.029308],
            [-63.9910041, 47.0293084],
            [-63.9907788, 47.0294085],
            [-63.9900181, 47.0294559],
            [-63.9899328, 47.0293947],
            [-63.9898553, 47.0287707],
            [-63.9896753, 47.0284125],
            [-63.9895905, 47.0283313],
            [-63.9894465, 47.0271076],
            [-63.9893923, 47.0269869],
            [-63.9893919, 47.0266636],
            [-63.9895463, 47.026255],
            [-63.9896071, 47.0258529],
            [-63.9895492, 47.025337],
            [-63.9893756, 47.0244561],
            [-63.9893638, 47.0241286],
            [-63.9895549, 47.0236727],
            [-63.989639, 47.0236023],
            [-63.9897857, 47.023413],
            [-63.989849, 47.0231027],
            [-63.9897322, 47.0226059],
            [-63.9895935, 47.0222324],
            [-63.9896258, 47.0218099],
            [-63.9897982, 47.0215492],
            [-63.9899038, 47.0212037],
            [-63.9894609, 47.0190132],
            [-63.9893466, 47.0186083],
            [-63.9893733, 47.0183452],
            [-63.9895443, 47.0181245],
            [-63.9895488, 47.0178332],
            [-63.9893271, 47.0169954],
            [-63.9892785, 47.0167152],
            [-63.9891173, 47.016485],
            [-63.989089, 47.016293],
            [-63.9891044, 47.0158542],
            [-63.9891838, 47.0157517],
            [-63.9893094, 47.015666],
            [-63.9893916, 47.0154837],
            [-63.989437, 47.0146822],
            [-63.9895248, 47.014512],
            [-63.9900596, 47.0140697],
            [-63.9904357, 47.0136608],
            [-63.9905253, 47.0134308],
            [-63.990526, 47.0127443],
            [-63.9901268, 47.0118118],
            [-63.9901582, 47.0115809],
            [-63.9902749, 47.0114111],
            [-63.9899662, 47.010057],
            [-63.9898041, 47.0095235],
            [-63.9897178, 47.0089873],
            [-63.9897968, 47.0085615],
            [-63.9897629, 47.0078625],
            [-63.9895977, 47.0067423],
            [-63.989514, 47.0064695],
            [-63.9895494, 47.0062905],
            [-63.9898005, 47.006119],
            [-63.9900975, 47.0059642],
            [-63.9903079, 47.005788],
            [-63.9904827, 47.005296],
            [-63.9905137, 47.0047417],
            [-63.9904134, 47.0037662],
            [-63.9906171, 47.0027798],
            [-63.9906668, 47.0018586],
            [-63.990731, 47.0013568],
            [-63.9909109, 47.0010485],
            [-63.9913486, 47.0005408],
            [-63.9913253, 47.0002091],
            [-63.991106, 46.9999999],
            [-63.9898624, 46.9929426],
            [-63.9896918, 46.9837111],
            [-63.9900522, 46.9829385],
            [-63.9900287, 46.9816563],
            [-63.9903988, 46.9813969],
            [-63.9907592, 46.9806243],
            [-63.9907118, 46.9780601],
            [-63.9921439, 46.974457],
            [-63.9927911, 46.9723624],
            [-63.9941276, 46.9687591],
            [-63.9944656, 46.9668848],
            [-63.9945889, 46.9653912],
            [-63.9947928, 46.9631213],
            [-63.9946802, 46.9623745],
            [-63.9945407, 46.9616386],
            [-63.9942884, 46.9610637],
            [-63.9943744, 46.960306],
            [-63.9945621, 46.9594566],
            [-63.9945353, 46.9580762],
            [-63.994562, 46.9544952],
            [-63.9943529, 46.9534334],
            [-63.9938003, 46.9522505],
            [-63.9936502, 46.9518954],
            [-63.9938264, 46.9515705],
            [-63.9937085, 46.9511657],
            [-63.9935644, 46.9510349],
            [-63.9931819, 46.9508892],
            [-63.9932008, 46.9508365],
            [-63.9917065, 46.9506257],
            [-63.9916846, 46.9507084],
            [-63.9913783, 46.9506671],
            [-63.9914259, 46.9505063],
            [-63.9926124, 46.9506704],
            [-63.993269, 46.9507619],
            [-63.9933155, 46.9506716],
            [-63.9929583, 46.9505705],
            [-63.9929682, 46.9505535],
            [-63.9927542, 46.9504854],
            [-63.9927491, 46.9504216],
            [-63.9928211, 46.9503665],
            [-63.9930134, 46.9504107],
            [-63.9935719, 46.9506366],
            [-63.9936857, 46.9506195],
            [-63.9938258, 46.9505306],
            [-63.9937907, 46.9503143],
            [-63.9940041, 46.9494242],
            [-63.9939567, 46.9486094],
            [-63.9937897, 46.9476255],
            [-63.9937628, 46.9470103],
            [-63.9937589, 46.946256],
            [-63.9934194, 46.9435677],
            [-63.9931297, 46.942621],
            [-63.9929732, 46.9418819],
            [-63.992867, 46.9400297],
            [-63.9923305, 46.9383852],
            [-63.9917592, 46.9369384],
            [-63.99144, 46.9364146],
            [-63.9910376, 46.9355135],
            [-63.9909178, 46.9354405],
            [-63.9907855, 46.9353597],
            [-63.9898039, 46.9334513],
            [-63.9879555, 46.9292474],
            [-63.9875678, 46.9283393],
            [-63.9870314, 46.927357],
            [-63.9860039, 46.9260953],
            [-63.9847403, 46.9240916],
            [-63.9841808, 46.9232725],
            [-63.9832962, 46.9220929],
            [-63.9826902, 46.9211055],
            [-63.9823366, 46.9202299],
            [-63.9822212, 46.9200365],
            [-63.9814759, 46.9190388],
            [-63.9812285, 46.9187873],
            [-63.9811298, 46.9186102],
            [-63.9794817, 46.9171168],
            [-63.9791027, 46.9168636],
            [-63.9783311, 46.9155878],
            [-63.9779522, 46.9153346],
            [-63.9756324, 46.9112509],
            [-63.9756229, 46.9107381],
            [-63.9748466, 46.9092058],
            [-63.9751976, 46.9079205],
            [-63.9757512, 46.9075305],
            [-63.9768714, 46.9073931],
            [-63.9768527, 46.9063674],
            [-63.9776011, 46.9063612],
            [-63.9774101, 46.9061063],
            [-63.9773729, 46.9040548],
            [-63.9769893, 46.9035451],
            [-63.9761619, 46.8991923],
            [-63.9764427, 46.8940603],
            [-63.9736851, 46.8863898],
            [-63.9736526, 46.8845947],
            [-63.9740219, 46.884335],
            [-63.9739615, 46.8810014],
            [-63.9745102, 46.8803552],
            [-63.9752561, 46.8802211],
            [-63.9757943, 46.8789341],
            [-63.9759784, 46.8788037],
            [-63.977098, 46.8786665],
            [-63.9774534, 46.8776376],
            [-63.978199, 46.8775025],
            [-63.9785682, 46.8772427],
            [-63.9793138, 46.8771087],
            [-63.9793044, 46.8765959],
            [-63.980798, 46.8764545],
            [-63.9819105, 46.8759318],
            [-63.9845188, 46.8753967],
            [-63.9848881, 46.875137],
            [-63.9867483, 46.8746079],
            [-63.9871176, 46.8743482],
            [-63.9878632, 46.8742141],
            [-63.9880413, 46.8736995],
            [-63.9884106, 46.8734399],
            [-63.9900524, 46.8712452],
            [-63.9907981, 46.8711111],
            [-63.9913408, 46.8700805],
            [-63.9918939, 46.8696905],
            [-63.9930061, 46.8691679],
            [-63.9937515, 46.8690336],
            [-63.9937422, 46.8685209],
            [-63.9944875, 46.8683856],
            [-63.995411, 46.8677368],
            [-63.9959524, 46.8667063],
            [-63.9966999, 46.8666997],
            [-63.9970549, 46.8656706],
            [-63.9978027, 46.8656643],
            [-63.9981575, 46.8646351],
            [-63.9989052, 46.8646287],
            [-63.9992555, 46.8633433],
            [-64.0000001, 46.863412],
            [-64.0007286, 46.8622959],
            [-64.0011316, 46.8619076],
            [-64.0017019, 46.8610553],
            [-64.0021449, 46.86052],
            [-64.002458, 46.86021],
            [-64.0025195, 46.8601113],
            [-64.0033853, 46.8589527],
            [-64.0037105, 46.8586269],
            [-64.0047915, 46.8577988],
            [-64.0054399, 46.8572071],
            [-64.0067819, 46.8569746],
            [-64.0076869, 46.856362],
            [-64.0081518, 46.8555528],
            [-64.0084345, 46.8552513],
            [-64.0090844, 46.8547298],
            [-64.0096293, 46.8544222],
            [-64.00977, 46.8543299],
            [-64.0119548, 46.8511129],
            [-64.0109892, 46.8501505],
            [-64.0111084, 46.8499439],
            [-64.0115431, 46.8494763],
            [-64.0126007, 46.8486398],
            [-64.013839, 46.8472838],
            [-64.01401, 46.8470432],
            [-64.0145493, 46.8464018],
            [-64.0149035, 46.8460765],
            [-64.0163179, 46.8444919],
            [-64.0166194, 46.8440062],
            [-64.0167634, 46.8437054],
            [-64.0168911, 46.8435399],
            [-64.0170317, 46.8431673],
            [-64.0172189, 46.8427914],
            [-64.0173892, 46.8425708],
            [-64.017734, 46.8418385],
            [-64.0184166, 46.8409162],
            [-64.0187072, 46.8404064],
            [-64.0191167, 46.8398187],
            [-64.02017, 46.8387588],
            [-64.0204483, 46.8384362],
            [-64.020876, 46.8379964],
            [-64.0215665, 46.8370064],
            [-64.0222264, 46.8362273],
            [-64.0227899, 46.8353829],
            [-64.0230162, 46.83488],
            [-64.0232297, 46.8345843],
            [-64.0237877, 46.8338993],
            [-64.0245557, 46.8328467],
            [-64.0250589, 46.8320611],
            [-64.0261208, 46.8302276],
            [-64.0263545, 46.829334],
            [-64.0268759, 46.8281818],
            [-64.0276209, 46.8259205],
            [-64.0276797, 46.8252155],
            [-64.0278418, 46.8247196],
            [-64.0279971, 46.824423],
            [-64.0281153, 46.8240221],
            [-64.0280411, 46.823642],
            [-64.0280385, 46.8230358],
            [-64.0287395, 46.8200121],
            [-64.0289954, 46.8172605],
            [-64.029038, 46.8158335],
            [-64.0291724, 46.8144399],
            [-64.0291629, 46.814033],
            [-64.0289746, 46.8127339],
            [-64.0289563, 46.8124215],
            [-64.028934, 46.8120434],
            [-64.0289914, 46.8110353],
            [-64.0289699, 46.8103012],
            [-64.0289868, 46.8099745],
            [-64.0289341, 46.8094712],
            [-64.0291279, 46.8076957],
            [-64.0293203, 46.8073319],
            [-64.0295012, 46.8071354],
            [-64.0298542, 46.80683],
            [-64.0302079, 46.8063332],
            [-64.0307795, 46.8057442],
            [-64.0315591, 46.8046759],
            [-64.0319856, 46.804256],
            [-64.0322812, 46.8041012],
            [-64.0327597, 46.8040331],
            [-64.0343181, 46.8043171],
            [-64.0348615, 46.8043936],
            [-64.0355951, 46.8043375],
            [-64.0369146, 46.8043026],
            [-64.0373472, 46.8043893],
            [-64.0378109, 46.804588],
            [-64.0380499, 46.8047354],
            [-64.0381181, 46.8049518],
            [-64.038052, 46.8053615],
            [-64.0378462, 46.8057809],
            [-64.0373604, 46.8064113],
            [-64.0361099, 46.8078429],
            [-64.035242, 46.8087782],
            [-64.0349868, 46.8091091],
            [-64.0344598, 46.8100977],
            [-64.0341689, 46.8107989],
            [-64.0338854, 46.8112809],
            [-64.0335679, 46.812425],
            [-64.0333895, 46.8130675],
            [-64.0332134, 46.8141534],
            [-64.0333768, 46.8149895],
            [-64.0337145, 46.8166858],
            [-64.0337132, 46.8172401],
            [-64.0338977, 46.8195162],
            [-64.0337884, 46.8205154],
            [-64.0331262, 46.8224032],
            [-64.0330734, 46.8227572],
            [-64.0330425, 46.823331],
            [-64.0329634, 46.8236049],
            [-64.0322464, 46.8250492],
            [-64.0321296, 46.8252387],
            [-64.0320216, 46.8253407],
            [-64.0316765, 46.8255785],
            [-64.0314112, 46.8258654],
            [-64.031087, 46.8265143],
            [-64.0310378, 46.8271117],
            [-64.0309662, 46.8271664],
            [-64.0307305, 46.8272623],
            [-64.0303807, 46.8274681],
            [-64.0301525, 46.8276878],
            [-64.0295339, 46.8291376],
            [-64.0292556, 46.8299746],
            [-64.029182, 46.8300891],
            [-64.0287059, 46.8304205],
            [-64.0286208, 46.8305308],
            [-64.0285513, 46.8306972],
            [-64.0285404, 46.8310161],
            [-64.0286444, 46.8312052],
            [-64.0287545, 46.8312149],
            [-64.0290476, 46.8311399],
            [-64.029417, 46.8308706],
            [-64.0295865, 46.8306699],
            [-64.0299519, 46.8303487],
            [-64.0300275, 46.8303459],
            [-64.0301545, 46.8303719],
            [-64.0305428, 46.8305735],
            [-64.0305928, 46.8306421],
            [-64.0303591, 46.8308497],
            [-64.0303686, 46.8309137],
            [-64.030794, 46.8312196],
            [-64.0310007, 46.8312947],
            [-64.0316423, 46.8317396],
            [-64.031934, 46.8318759],
            [-64.0319901, 46.8321081],
            [-64.0321259, 46.8322179],
            [-64.0323724, 46.8323176],
            [-64.0323872, 46.8323936],
            [-64.0323494, 46.8324807],
            [-64.0320016, 46.8327982],
            [-64.0318733, 46.8328121],
            [-64.0315896, 46.8327797],
            [-64.0314863, 46.8327421],
            [-64.0313411, 46.8325683],
            [-64.0311797, 46.8321869],
            [-64.0312121, 46.8320877],
            [-64.0313776, 46.8320066],
            [-64.031385, 46.8319588],
            [-64.031281, 46.8317697],
            [-64.0309777, 46.8316293],
            [-64.0307353, 46.8315816],
            [-64.0306178, 46.8316196],
            [-64.0304692, 46.8317169],
            [-64.0303814, 46.8317354],
            [-64.030324, 46.8317146],
            [-64.0301592, 46.8314328],
            [-64.0301194, 46.8314082],
            [-64.0299863, 46.8313901],
            [-64.0299046, 46.8314008],
            [-64.0298486, 46.8315116],
            [-64.0298202, 46.8316627],
            [-64.0298992, 46.8319032],
            [-64.0298499, 46.8319862],
            [-64.0297182, 46.8320998],
            [-64.029571, 46.8321572],
            [-64.0295156, 46.8322481],
            [-64.0295534, 46.8323324],
            [-64.0300431, 46.8324599],
            [-64.0301633, 46.8325137],
            [-64.0302362, 46.8325906],
            [-64.0301099, 46.8327162],
            [-64.0298803, 46.8328043],
            [-64.0296594, 46.8329762],
            [-64.0293474, 46.8330949],
            [-64.0288909, 46.8331913],
            [-64.0295818, 46.8333818],
            [-64.0313688, 46.8336376],
            [-64.0320246, 46.8338355],
            [-64.0322624, 46.8338513],
            [-64.0332295, 46.8337789],
            [-64.0338211, 46.8338123],
            [-64.0345127, 46.8339828],
            [-64.0347647, 46.833923],
            [-64.0352239, 46.8337469],
            [-64.0357703, 46.8337436],
            [-64.0361825, 46.8339379],
            [-64.0363309, 46.8340078],
            [-64.0366821, 46.8341051],
            [-64.0368442, 46.8341236],
            [-64.0369901, 46.834106],
            [-64.0370785, 46.8340675],
            [-64.0371136, 46.8337171],
            [-64.0371703, 46.8335864],
            [-64.0369953, 46.8330891],
            [-64.0368035, 46.8329185],
            [-64.0366137, 46.8326882],
            [-64.0365785, 46.8325241],
            [-64.0365738, 46.8323206],
            [-64.0366649, 46.8322024],
            [-64.0367818, 46.8321843],
            [-64.0371627, 46.8322622],
            [-64.0380124, 46.8327423],
            [-64.038388, 46.8333226],
            [-64.0390453, 46.834338],
            [-64.0395614, 46.8348886],
            [-64.0399904, 46.8352663],
            [-64.040468, 46.835581],
            [-64.0407422, 46.8357209],
            [-64.041288, 46.835909],
            [-64.0428486, 46.8362822],
            [-64.0434292, 46.8362968],
            [-64.0436179, 46.8361929],
            [-64.0438061, 46.8360917],
            [-64.0453673, 46.8354411],
            [-64.0455364, 46.8356033],
            [-64.0442545, 46.8361333],
            [-64.0440939, 46.8362099],
            [-64.0440035, 46.8362822],
            [-64.0439695, 46.8363674],
            [-64.0440105, 46.8364727],
            [-64.0440088, 46.8365881],
            [-64.0439665, 46.8369017],
            [-64.0441752, 46.8372134],
            [-64.0444048, 46.8374004],
            [-64.044491, 46.8376621],
            [-64.0447897, 46.8379636],
            [-64.0450125, 46.8382354],
            [-64.0459826, 46.8388625],
            [-64.0462871, 46.8389995],
            [-64.0464761, 46.8390535],
            [-64.0468965, 46.8390747],
            [-64.0473096, 46.8390417],
            [-64.0474731, 46.8389804],
            [-64.047628, 46.8388768],
            [-64.0480744, 46.8385648],
            [-64.0482736, 46.8385161],
            [-64.0483777, 46.8385337],
            [-64.0484689, 46.838587],
            [-64.0485297, 46.8386796],
            [-64.0484595, 46.838866],
            [-64.0482482, 46.839102],
            [-64.0481861, 46.8392206],
            [-64.04812, 46.8394589],
            [-64.0475719, 46.8403795],
            [-64.0470769, 46.8407546],
            [-64.046798, 46.8409257],
            [-64.046538, 46.8410532],
            [-64.0460611, 46.8414046],
            [-64.0458086, 46.8416559],
            [-64.0458155, 46.8421426],
            [-64.0458581, 46.8422589],
            [-64.0462602, 46.8427478],
            [-64.0468408, 46.8436344],
            [-64.0471288, 46.8442332],
            [-64.0474472, 46.8446212],
            [-64.0476101, 46.8449627],
            [-64.047844, 46.8452695],
            [-64.04803, 46.845791],
            [-64.0483606, 46.8465061],
            [-64.0485792, 46.8472713],
            [-64.0486686, 46.8478989],
            [-64.0489954, 46.8490767],
            [-64.0491032, 46.8498522],
            [-64.0492412, 46.8502452],
            [-64.0493824, 46.8503671],
            [-64.0501495, 46.8508977],
            [-64.0505286, 46.8510352],
            [-64.0510478, 46.8513346],
            [-64.0512377, 46.8515649],
            [-64.0513744, 46.8519978],
            [-64.0514346, 46.8521104],
            [-64.0515583, 46.852236],
            [-64.0519875, 46.8524422],
            [-64.0527694, 46.8527058],
            [-64.0532655, 46.8529967],
            [-64.0532879, 46.8531965],
            [-64.0532388, 46.8536225],
            [-64.0531966, 46.8543637],
            [-64.053317, 46.854589],
            [-64.0536799, 46.8546904],
            [-64.0538596, 46.8547052],
            [-64.0541623, 46.854694],
            [-64.0551077, 46.8547646],
            [-64.0568944, 46.8550599],
            [-64.0573756, 46.8551033],
            [-64.0587952, 46.8550737],
            [-64.0593614, 46.8550068],
            [-64.059454, 46.8550202],
            [-64.0598068, 46.8550775],
            [-64.0599446, 46.8551276],
            [-64.0606294, 46.8555172],
            [-64.0609161, 46.8558128],
            [-64.0615111, 46.8562808],
            [-64.0618477, 46.8564735],
            [-64.06181, 46.8565607],
            [-64.0616735, 46.8566423],
            [-64.0613797, 46.8567374],
            [-64.0611669, 46.8568418],
            [-64.0611122, 46.8569127],
            [-64.0610502, 46.8572029],
            [-64.060974, 46.8573972],
            [-64.0601301, 46.8584729],
            [-64.0599924, 46.8587659],
            [-64.0599811, 46.8591047],
            [-64.0600637, 46.8592456],
            [-64.060443, 46.8595547],
            [-64.0609638, 46.8601571],
            [-64.0611785, 46.8608705],
            [-64.0612604, 46.8610313],
            [-64.0615092, 46.8612426],
            [-64.0617844, 46.8613626],
            [-64.0622414, 46.861609],
            [-64.0624646, 46.8618917],
            [-64.0625217, 46.8622755],
            [-64.0624273, 46.8626649],
            [-64.0622362, 46.862989],
            [-64.0619783, 46.8632283],
            [-64.0619095, 46.8633748],
            [-64.0618416, 46.8638444],
            [-64.0618891, 46.8641642],
            [-64.0619994, 46.8643454],
            [-64.0626269, 46.8647142],
            [-64.0627231, 46.864971],
            [-64.0627116, 46.8658443],
            [-64.0627711, 46.8659769],
            [-64.0630098, 46.8661442],
            [-64.0635062, 46.8664351],
            [-64.0637301, 46.8666979],
            [-64.0638915, 46.8674423],
            [-64.0640168, 46.8676995],
            [-64.0640236, 46.8678432],
            [-64.0641331, 46.8678728],
            [-64.0642238, 46.8679461],
            [-64.0645597, 46.8679872],
            [-64.064822, 46.8679713],
            [-64.0652484, 46.8679142],
            [-64.0654052, 46.8679206],
            [-64.0658312, 46.8680549],
            [-64.0659502, 46.8681484],
            [-64.0659584, 46.8682523],
            [-64.0659282, 46.8684632],
            [-64.065777, 46.8688119],
            [-64.0657624, 46.8690789],
            [-64.0658311, 46.8696384],
            [-64.0659551, 46.8699355],
            [-64.0662128, 46.8702307],
            [-64.0673748, 46.8711462],
            [-64.0677219, 46.871383],
            [-64.0680431, 46.8715196],
            [-64.0684723, 46.8715542],
            [-64.0688522, 46.8715003],
            [-64.0696602, 46.8711578],
            [-64.070065, 46.8708809],
            [-64.0703163, 46.8708409],
            [-64.0705652, 46.8708807],
            [-64.0708444, 46.8710525],
            [-64.0717856, 46.8719686],
            [-64.072053, 46.8723277],
            [-64.0724332, 46.8726168],
            [-64.0732433, 46.8729206],
            [-64.0736098, 46.8730938],
            [-64.0745102, 46.8736622],
            [-64.0751537, 46.8742585],
            [-64.0755699, 46.8746917],
            [-64.0757854, 46.8749609],
            [-64.0762067, 46.8754874],
            [-64.0763828, 46.8757932],
            [-64.0766669, 46.8765316],
            [-64.0771548, 46.8776161],
            [-64.0772929, 46.8778376],
            [-64.0774912, 46.8780002],
            [-64.0778261, 46.8782527],
            [-64.0781252, 46.8785326],
            [-64.0783534, 46.8788472],
            [-64.0785449, 46.8795722],
            [-64.0785263, 46.8797873],
            [-64.0785469, 46.8800469],
            [-64.0785822, 46.880211],
            [-64.078704, 46.8803964],
            [-64.0788921, 46.8805149],
            [-64.0790501, 46.8803099],
            [-64.0790941, 46.8796804],
            [-64.0790618, 46.8792451],
            [-64.0791987, 46.878972],
            [-64.0800837, 46.8784152],
            [-64.0802119, 46.8782297],
            [-64.080251, 46.8781026],
            [-64.0802766, 46.8780312],
            [-64.0801734, 46.8776308],
            [-64.0796214, 46.8772593],
            [-64.0792597, 46.8767551],
            [-64.0789309, 46.8764947],
            [-64.0785008, 46.8757741],
            [-64.0783625, 46.875381],
            [-64.0782026, 46.8751113],
            [-64.0780783, 46.874961],
            [-64.0779286, 46.87478],
            [-64.0779021, 46.8746998],
            [-64.0779115, 46.8745923],
            [-64.0779929, 46.8742385],
            [-64.077963, 46.8740865],
            [-64.077694, 46.8735957],
            [-64.0772644, 46.8732181],
            [-64.0768037, 46.8728998],
            [-64.0756782, 46.8722801],
            [-64.0744616, 46.8717787],
            [-64.074097, 46.8715457],
            [-64.0735327, 46.8710184],
            [-64.0731056, 46.8703895],
            [-64.0727084, 46.8699127],
            [-64.0711901, 46.8690396],
            [-64.0694742, 46.8686899],
            [-64.0687455, 46.8685669],
            [-64.067935, 46.8684545],
            [-64.0673806, 46.8683342],
            [-64.0673305, 46.8682657],
            [-64.0673798, 46.8681827],
            [-64.0675473, 46.8680417],
            [-64.0675437, 46.8677983],
            [-64.0673319, 46.8675198],
            [-64.0671723, 46.8674216],
            [-64.0667952, 46.8673958],
            [-64.0666876, 46.8673063],
            [-64.0666861, 46.8671747],
            [-64.0669655, 46.8666405],
            [-64.0669404, 46.8665205],
            [-64.0668281, 46.8663991],
            [-64.066607, 46.8662281],
            [-64.066172, 46.8658384],
            [-64.0660834, 46.8657054],
            [-64.0658466, 46.8654784],
            [-64.0651404, 46.8646776],
            [-64.064851, 46.8644617],
            [-64.0646717, 46.8642555],
            [-64.0644455, 46.8637095],
            [-64.0644217, 46.8635496],
            [-64.0644804, 46.863359],
            [-64.0646323, 46.863162],
            [-64.0648281, 46.8630414],
            [-64.0650809, 46.8629616],
            [-64.0654838, 46.8630875],
            [-64.0660286, 46.8631438],
            [-64.0662617, 46.8631275],
            [-64.0662873, 46.8630561],
            [-64.0662506, 46.8629319],
            [-64.0661782, 46.8628351],
            [-64.0660133, 46.8627248],
            [-64.0652685, 46.8625656],
            [-64.0646298, 46.8625357],
            [-64.0638159, 46.8623515],
            [-64.0637056, 46.8621703],
            [-64.0637658, 46.8621114],
            [-64.0641015, 46.861981],
            [-64.0642326, 46.8618874],
            [-64.0642575, 46.8618359],
            [-64.0640809, 46.8615499],
            [-64.0639352, 46.8610331],
            [-64.0630086, 46.859164],
            [-64.0627617, 46.8587214],
            [-64.0627568, 46.8585179],
            [-64.0628972, 46.8583167],
            [-64.0629309, 46.8581776],
            [-64.0628936, 46.8580733],
            [-64.0626906, 46.8577072],
            [-64.0625259, 46.8570624],
            [-64.0625299, 46.8569428],
            [-64.0627291, 46.8567226],
            [-64.0629864, 46.8565033],
            [-64.0636954, 46.8558123],
            [-64.0638479, 46.8555953],
            [-64.0639011, 46.8553927],
            [-64.0638083, 46.8550363],
            [-64.0634579, 46.8545562],
            [-64.0632528, 46.8540784],
            [-64.0632256, 46.8538466],
            [-64.0632862, 46.8535963],
            [-64.063411, 46.853339],
            [-64.0636325, 46.853147],
            [-64.0640303, 46.8528979],
            [-64.0644085, 46.8527124],
            [-64.0647415, 46.8524902],
            [-64.0657174, 46.8519869],
            [-64.0663463, 46.8517813],
            [-64.0664449, 46.8517869],
            [-64.0664835, 46.8518513],
            [-64.0664518, 46.8519306],
            [-64.0662134, 46.8521063],
            [-64.0661629, 46.8522292],
            [-64.0661825, 46.8523372],
            [-64.0663664, 46.8524039],
            [-64.0665691, 46.852427],
            [-64.0670589, 46.8523827],
            [-64.067194, 46.852341],
            [-64.0673148, 46.8522032],
            [-64.0671128, 46.8521602],
            [-64.0669992, 46.8520787],
            [-64.0669841, 46.8518312],
            [-64.0670534, 46.8514932],
            [-64.0670867, 46.8510111],
            [-64.0672087, 46.850662],
            [-64.0673781, 46.8504613],
            [-64.0679528, 46.8501352],
            [-64.0680697, 46.850117],
            [-64.0683474, 46.8501573],
            [-64.0686515, 46.8502777],
            [-64.0688341, 46.8503842],
            [-64.0690591, 46.8504355],
            [-64.0694036, 46.8503891],
            [-64.0697495, 46.8503027],
            [-64.0698677, 46.8502447],
            [-64.0702012, 46.8500026],
            [-64.0702731, 46.8495849],
            [-64.0702642, 46.849501],
            [-64.0701228, 46.8490244],
            [-64.0697642, 46.8486238],
            [-64.0696502, 46.8483012],
            [-64.0697115, 46.848031],
            [-64.0698075, 46.8479741],
            [-64.0699417, 46.8480212],
            [-64.0702992, 46.8481578],
            [-64.0704807, 46.8482435],
            [-64.0705733, 46.8483955],
            [-64.0706558, 46.8487096],
            [-64.070929, 46.8488331],
            [-64.0712629, 46.8488542],
            [-64.0715932, 46.8488306],
            [-64.0718246, 46.848845],
            [-64.0720794, 46.8487948],
            [-64.0724147, 46.8490722],
            [-64.0728973, 46.8491489],
            [-64.0739735, 46.8488349],
            [-64.07407, 46.8487287],
            [-64.0740429, 46.8486685],
            [-64.0739692, 46.8486115],
            [-64.0738307, 46.8485814],
            [-64.0736861, 46.8485592],
            [-64.0728162, 46.8486589],
            [-64.0728203, 46.8485595],
            [-64.073251, 46.8483016],
            [-64.0737622, 46.8480318],
            [-64.0741683, 46.8480549],
            [-64.0745538, 46.847956],
            [-64.0748595, 46.8476741],
            [-64.0749559, 46.8473547],
            [-64.0749058, 46.8472477],
            [-64.0748627, 46.8471295],
            [-64.0751851, 46.8466475],
            [-64.0755058, 46.8465088],
            [-64.0758943, 46.8462578],
            [-64.0762537, 46.8460769],
            [-64.0763827, 46.846043],
            [-64.0765212, 46.8460731],
            [-64.0766361, 46.8461148],
            [-64.076784, 46.846169],
            [-64.0769392, 46.8463742],
            [-64.0774217, 46.8462422],
            [-64.078252, 46.8457416],
            [-64.0787527, 46.8454704],
            [-64.0793635, 46.8452451],
            [-64.0794787, 46.845103],
            [-64.0792104, 46.8450572],
            [-64.0787532, 46.8452071],
            [-64.0779793, 46.845545],
            [-64.0775618, 46.845632],
            [-64.0770723, 46.8455919],
            [-64.0766112, 46.8453054],
            [-64.0763664, 46.845076],
            [-64.0764339, 46.8448501],
            [-64.0765256, 46.8448215],
            [-64.0767305, 46.8444798],
            [-64.0768378, 46.8443151],
            [-64.0767006, 46.844245],
            [-64.0765413, 46.8442339],
            [-64.0764312, 46.8442242],
            [-64.0763609, 46.8442391],
            [-64.0762712, 46.8443174],
            [-64.0758988, 46.8448582],
            [-64.0755745, 46.8449698],
            [-64.0748012, 46.8450046],
            [-64.0745035, 46.8452194],
            [-64.0737482, 46.8459136],
            [-64.0733531, 46.8466174],
            [-64.0731846, 46.8468724],
            [-64.0729848, 46.8470469],
            [-64.0725635, 46.8472213],
            [-64.0723771, 46.8471855],
            [-64.0716598, 46.8470219],
            [-64.0709424, 46.847085],
            [-64.0693727, 46.8467471],
            [-64.0686984, 46.8467446],
            [-64.068381, 46.8468514],
            [-64.06825, 46.8469451],
            [-64.0678823, 46.8475177],
            [-64.06779, 46.8478473],
            [-64.0677296, 46.8482691],
            [-64.0673764, 46.8484032],
            [-64.0667536, 46.8486009],
            [-64.0650027, 46.8489845],
            [-64.0648494, 46.8490499],
            [-64.0644612, 46.8493629],
            [-64.0642121, 46.8496861],
            [-64.0640859, 46.8498117],
            [-64.0636602, 46.8505549],
            [-64.0635521, 46.8506569],
            [-64.0633569, 46.8507576],
            [-64.0629935, 46.8508477],
            [-64.0623949, 46.8508423],
            [-64.0619509, 46.8507317],
            [-64.0606189, 46.8502283],
            [-64.0602351, 46.8502302],
            [-64.05878, 46.8506303],
            [-64.0584882, 46.8506656],
            [-64.0582166, 46.8506175],
            [-64.0581713, 46.8505808],
            [-64.0581068, 46.8500733],
            [-64.0579734, 46.8497123],
            [-64.0575988, 46.8492637],
            [-64.0574899, 46.8490426],
            [-64.0573787, 46.8483668],
            [-64.0573435, 46.8482027],
            [-64.057217, 46.8479854],
            [-64.0565837, 46.8476245],
            [-64.0552357, 46.8472563],
            [-64.0547985, 46.8471179],
            [-64.0539917, 46.8467343],
            [-64.053622, 46.8464892],
            [-64.0533747, 46.8464095],
            [-64.0532956, 46.8463405],
            [-64.0532895, 46.8461768],
            [-64.0536236, 46.8457434],
            [-64.0536324, 46.8456558],
            [-64.0535911, 46.8454996],
            [-64.0533848, 46.8450616],
            [-64.0533388, 46.8448734],
            [-64.053321, 46.8445341],
            [-64.0533357, 46.8442671],
            [-64.0534417, 46.8440534],
            [-64.053732, 46.843715],
            [-64.0539824, 46.843352],
            [-64.0540235, 46.8431652],
            [-64.0539653, 46.8429928],
            [-64.0537423, 46.8428816],
            [-64.0530971, 46.8427079],
            [-64.0527931, 46.8425875],
            [-64.0524134, 46.8424698],
            [-64.0520295, 46.8421288],
            [-64.0517673, 46.8418016],
            [-64.0516799, 46.8414572],
            [-64.051532, 46.8411917],
            [-64.0513786, 46.8410856],
            [-64.0510104, 46.8409721],
            [-64.0509725, 46.8408877],
            [-64.0509853, 46.8408521],
            [-64.0511677, 46.8407871],
            [-64.0516189, 46.8406786],
            [-64.0519309, 46.8405598],
            [-64.0526805, 46.8402167],
            [-64.0534983, 46.8397469],
            [-64.0538561, 46.8394734],
            [-64.0541207, 46.8392063],
            [-64.0544623, 46.8387251],
            [-64.054556, 46.8385272],
            [-64.0545918, 46.8384998],
            [-64.0551186, 46.8383884],
            [-64.0553732, 46.8382488],
            [-64.0559013, 46.8380976],
            [-64.0559843, 46.838047],
            [-64.0560842, 46.8378412],
            [-64.0560457, 46.8377768],
            [-64.0559828, 46.8377439],
            [-64.0558146, 46.8377333],
            [-64.0556107, 46.83775],
            [-64.054393, 46.8380141],
            [-64.054316, 46.8380568],
            [-64.054279, 46.8382955],
            [-64.0541561, 46.8383215],
            [-64.0531685, 46.8381345],
            [-64.0530029, 46.8380441],
            [-64.0528218, 46.8378977],
            [-64.0526778, 46.8376841],
            [-64.052527, 46.8371552],
            [-64.051865, 46.8359164],
            [-64.0517804, 46.8356639],
            [-64.0517397, 46.8353162],
            [-64.0517052, 46.8337404],
            [-64.0517653, 46.8336815],
            [-64.0518308, 46.8336346],
            [-64.0522502, 46.8336053],
            [-64.0522927, 46.8335502],
            [-64.0522481, 46.8334936],
            [-64.0520306, 46.8333945],
            [-64.051753, 46.8333542],
            [-64.0512822, 46.8333548],
            [-64.05049, 46.8336052],
            [-64.0488365, 46.8342714],
            [-64.0486705, 46.834096],
            [-64.049726, 46.8336955],
            [-64.0499233, 46.8335389],
            [-64.0499673, 46.8334019],
            [-64.0500145, 46.8332072],
            [-64.0499967, 46.8326965],
            [-64.0499548, 46.8325602],
            [-64.049533, 46.8317918],
            [-64.0492978, 46.8315249],
            [-64.0487803, 46.8310142],
            [-64.0483304, 46.8307399],
            [-64.0481298, 46.830657],
            [-64.0477043, 46.8305227],
            [-64.0473275, 46.8304968],
            [-64.0468203, 46.8305446],
            [-64.0466116, 46.8305294],
            [-64.0463469, 46.8304534],
            [-64.0456018, 46.829971],
            [-64.045337, 46.8297236],
            [-64.0451943, 46.8291271],
            [-64.0452172, 46.828964],
            [-64.0453623, 46.8286233],
            [-64.045531, 46.8284425],
            [-64.0461366, 46.8280572],
            [-64.0466383, 46.8278259],
            [-64.0476963, 46.8274637],
            [-64.0478442, 46.8273862],
            [-64.0482128, 46.8271368],
            [-64.0483586, 46.8269477],
            [-64.0483072, 46.826919],
            [-64.0481506, 46.8269125],
            [-64.0479757, 46.8269297],
            [-64.0474997, 46.8270897],
            [-64.0472458, 46.8272093],
            [-64.046917, 46.8273118],
            [-64.046707, 46.8273364],
            [-64.0465794, 46.8273304],
            [-64.0461296, 46.8272276],
            [-64.0458142, 46.827103],
            [-64.0454285, 46.8268217],
            [-64.0453177, 46.8266604],
            [-64.0450894, 46.8265372],
            [-64.0448645, 46.8264858],
            [-64.0443014, 46.8264729],
            [-64.0441467, 46.8264066],
            [-64.0439988, 46.8263125],
            [-64.0438779, 46.8261072],
            [-64.0437353, 46.8260252],
            [-64.0429938, 46.8257862],
            [-64.0427676, 46.8256031],
            [-64.0425109, 46.8254595],
            [-64.0420268, 46.8253441],
            [-64.0418032, 46.8252529],
            [-64.0415696, 46.8251176],
            [-64.0413197, 46.8251176],
            [-64.0409929, 46.8251603],
            [-64.040951, 46.8251955],
            [-64.0409497, 46.8254068],
            [-64.0410308, 46.8255876],
            [-64.0414691, 46.8258577],
            [-64.0415543, 46.8260904],
            [-64.0417501, 46.8261414],
            [-64.0419189, 46.8261321],
            [-64.0422552, 46.8261533],
            [-64.0423984, 46.8262154],
            [-64.0424457, 46.8263637],
            [-64.0424248, 46.8264671],
            [-64.0423478, 46.8265098],
            [-64.0417374, 46.82652],
            [-64.0411722, 46.8265669],
            [-64.040946, 46.8265553],
            [-64.0407332, 46.8264882],
            [-64.0405401, 46.8263575],
            [-64.040353, 46.8260474],
            [-64.0402793, 46.8258189],
            [-64.0402198, 46.8253434],
            [-64.0397866, 46.8238849],
            [-64.0396921, 46.8237598],
            [-64.0395286, 46.8236096],
            [-64.0388243, 46.8231318],
            [-64.0377249, 46.8223088],
            [-64.0376533, 46.822192],
            [-64.0376276, 46.8220919],
            [-64.0376763, 46.8218574],
            [-64.0381299, 46.8211547],
            [-64.0382926, 46.8209818],
            [-64.0386301, 46.8207918],
            [-64.0388064, 46.8207348],
            [-64.0389738, 46.8205938],
            [-64.0393106, 46.8200808],
            [-64.039991, 46.8193697],
            [-64.0403879, 46.8191407],
            [-64.0410509, 46.8191193],
            [-64.0413048, 46.8189997],
            [-64.0418287, 46.8189681],
            [-64.0424032, 46.8189852],
            [-64.0426632, 46.8190292],
            [-64.04273, 46.819114],
            [-64.0426072, 46.8193115],
            [-64.0426457, 46.8193759],
            [-64.0428287, 46.8194625],
            [-64.0431163, 46.8195468],
            [-64.0438624, 46.8196464],
            [-64.0451385, 46.8197064],
            [-64.0453519, 46.8197537],
            [-64.0456146, 46.8198894],
            [-64.0460116, 46.8200034],
            [-64.0462317, 46.8200228],
            [-64.0474551, 46.8199225],
            [-64.0476596, 46.8198858],
            [-64.0478628, 46.8197175],
            [-64.0480032, 46.8196879],
            [-64.0482227, 46.8197272],
            [-64.0484921, 46.8198351],
            [-64.048667, 46.8199894],
            [-64.0487116, 46.820046],
            [-64.0488534, 46.8201479],
            [-64.0490547, 46.8202109],
            [-64.0497042, 46.8202451],
            [-64.0501228, 46.8202357],
            [-64.0502632, 46.820206],
            [-64.0505751, 46.8200873],
            [-64.0509247, 46.8198814],
            [-64.0511765, 46.8198216],
            [-64.0514621, 46.8197942],
            [-64.0530954, 46.8199435],
            [-64.052963, 46.819734],
            [-64.0527982, 46.8196238],
            [-64.0527003, 46.8195983],
            [-64.0515282, 46.819556],
            [-64.0503433, 46.8197208],
            [-64.0499416, 46.8197463],
            [-64.0496581, 46.8197139],
            [-64.0494622, 46.819663],
            [-64.049315, 46.819549],
            [-64.0488603, 46.8188998],
            [-64.0485578, 46.8187395],
            [-64.0479285, 46.8186219],
            [-64.0475598, 46.8185283],
            [-64.0466894, 46.8181517],
            [-64.0465091, 46.8179854],
            [-64.0464254, 46.8178843],
            [-64.0461098, 46.8172453],
            [-64.045808, 46.817065],
            [-64.0452712, 46.8169608],
            [-64.0450167, 46.8167574],
            [-64.0449882, 46.8163941],
            [-64.0450151, 46.8161114],
            [-64.0452053, 46.8156358],
            [-64.0455455, 46.815366],
            [-64.0455954, 46.8152631],
            [-64.0455353, 46.8151505],
            [-64.0453144, 46.8149795],
            [-64.0451969, 46.8146746],
            [-64.045159, 46.8144188],
            [-64.0451758, 46.8142635],
            [-64.0452756, 46.8140577],
            [-64.0459141, 46.8137248],
            [-64.0461227, 46.8135686],
            [-64.0463312, 46.8132409],
            [-64.0464593, 46.812884],
            [-64.0466415, 46.8126476],
            [-64.0469865, 46.812425],
            [-64.0481315, 46.8116862],
            [-64.0486802, 46.8114316],
            [-64.04916, 46.8111521],
            [-64.0493477, 46.8110992],
            [-64.0501651, 46.8109725],
            [-64.050659, 46.8106174],
            [-64.0513184, 46.8103526],
            [-64.0514203, 46.8102585],
            [-64.0524539, 46.8080017],
            [-64.0527628, 46.8074483],
            [-64.0533387, 46.8065481],
            [-64.0535262, 46.8061523],
            [-64.0540016, 46.8054779],
            [-64.0541101, 46.8051845],
            [-64.0538999, 46.8045232],
            [-64.0538477, 46.8041715],
            [-64.0539237, 46.8036343],
            [-64.0540349, 46.8034327],
            [-64.0540807, 46.8032779],
            [-64.0540328, 46.8031496],
            [-64.0535465, 46.8025796],
            [-64.0534985, 46.8024513],
            [-64.0534701, 46.8022594],
            [-64.0535576, 46.8018979],
            [-64.0537708, 46.8017737],
            [-64.0540475, 46.8016624],
            [-64.0556279, 46.8002397],
            [-64.0558306, 46.799557],
            [-64.0566074, 46.7985484],
            [-64.0567039, 46.7984422],
            [-64.056971, 46.7982669],
            [-64.0572416, 46.7981635],
            [-64.0575679, 46.7977779],
            [-64.0581271, 46.7973759],
            [-64.0583746, 46.7969218],
            [-64.0583839, 46.7968417],
            [-64.0583676, 46.7967724],
            [-64.057646, 46.7963324],
            [-64.0575441, 46.7963167],
            [-64.0574453, 46.7963332],
            [-64.0573213, 46.7963206],
            [-64.0572557, 46.796243],
            [-64.0572513, 46.7961617],
            [-64.0572533, 46.7961588],
            [-64.0572968, 46.7960963],
            [-64.0573739, 46.7960421],
            [-64.0574579, 46.7960297],
            [-64.0580742, 46.7963932],
            [-64.0585504, 46.7961135],
            [-64.0580801, 46.7957556],
            [-64.0581824, 46.7956925],
            [-64.0580782, 46.7956222],
            [-64.0579397, 46.7957118],
            [-64.0577803, 46.7955976],
            [-64.0581739, 46.7953455],
            [-64.0583372, 46.7954603],
            [-64.0581768, 46.795558],
            [-64.0582739, 46.7956252],
            [-64.0583121, 46.7956068],
            [-64.0590822, 46.7961856],
            [-64.0597224, 46.7957716],
            [-64.0586157, 46.7949776],
            [-64.0583623, 46.7951629],
            [-64.0582313, 46.7950749],
            [-64.0591346, 46.7944314],
            [-64.0592536, 46.7945075],
            [-64.0589665, 46.7947035],
            [-64.0598861, 46.7953575],
            [-64.0600423, 46.7952607],
            [-64.0602007, 46.7953835],
            [-64.0602792, 46.7953319],
            [-64.0601212, 46.7952144],
            [-64.0604032, 46.7950395],
            [-64.060677, 46.7948697],
            [-64.0606287, 46.7948312],
            [-64.0615253, 46.7942599],
            [-64.0615274, 46.793926],
            [-64.0615276, 46.7938926],
            [-64.060559, 46.793552],
            [-64.0604633, 46.7935729],
            [-64.0598326, 46.7940372],
            [-64.0598161, 46.7941491],
            [-64.0597414, 46.7941654],
            [-64.0596756, 46.794119],
            [-64.05968, 46.7940048],
            [-64.0597087, 46.7939453],
            [-64.0605222, 46.7933734],
            [-64.0621395, 46.7938813],
            [-64.0621425, 46.7938771],
            [-64.0623338, 46.7936097],
            [-64.0624913, 46.7925299],
            [-64.0624916, 46.7925276],
            [-64.0624658, 46.792256],
            [-64.0624663, 46.7922488],
            [-64.0625109, 46.7915916],
            [-64.0625112, 46.7915868],
            [-64.0624773, 46.7913829],
            [-64.0623109, 46.7907982],
            [-64.062013, 46.7899876],
            [-64.0620073, 46.789972],
            [-64.061756, 46.7894975],
            [-64.0614622, 46.7890783],
            [-64.0610199, 46.7885849],
            [-64.0598573, 46.7874063],
            [-64.0589954, 46.7867907],
            [-64.0586586, 46.786618],
            [-64.0581902, 46.7863874],
            [-64.0575519, 46.7862059],
            [-64.0565977, 46.7857483],
            [-64.0562698, 46.7856595],
            [-64.055307, 46.7856324],
            [-64.0550047, 46.7854721],
            [-64.0549723, 46.7853998],
            [-64.0550343, 46.7852812],
            [-64.0555562, 46.784775],
            [-64.0557018, 46.7845859],
            [-64.0562695, 46.7840964],
            [-64.0567751, 46.7835541],
            [-64.0569753, 46.7832941],
            [-64.0570946, 46.7830248],
            [-64.0571848, 46.7827551],
            [-64.0572325, 46.782369],
            [-64.0571999, 46.7821253],
            [-64.0572639, 46.7819469],
            [-64.0573886, 46.7818611],
            [-64.0584845, 46.7813719],
            [-64.0587246, 46.7813079],
            [-64.0592419, 46.7811126],
            [-64.0601073, 46.7810943],
            [-64.0605802, 46.781014],
            [-64.0608358, 46.781006],
            [-64.0610382, 46.7810291],
            [-64.0620907, 46.7813207],
            [-64.0624254, 46.7813817],
            [-64.0634832, 46.7815139],
            [-64.0639772, 46.7818446],
            [-64.06436, 46.7822054],
            [-64.0645437, 46.7824435],
            [-64.0652139, 46.7837658],
            [-64.0657548, 46.7844362],
            [-64.0663939, 46.7854749],
            [-64.0666264, 46.7859929],
            [-64.0669243, 46.786464],
            [-64.0673421, 46.7870833],
            [-64.0676991, 46.7876125],
            [-64.0677606, 46.7876852],
            [-64.0678445, 46.7879577],
            [-64.06782, 46.7885235],
            [-64.0679659, 46.7886773],
            [-64.0683515, 46.7891299],
            [-64.0687076, 46.7897734],
            [-64.0690213, 46.7904721],
            [-64.0689836, 46.7905593],
            [-64.0686884, 46.7908856],
            [-64.0684721, 46.791281],
            [-64.0684049, 46.7915591],
            [-64.068395, 46.791858],
            [-64.0684203, 46.7921495],
            [-64.0685135, 46.7923144],
            [-64.0684396, 46.7926203],
            [-64.0684945, 46.7928923],
            [-64.0688255, 46.7934158],
            [-64.0689398, 46.7936489],
            [-64.0690257, 46.7938615],
            [-64.0693622, 46.7943971],
            [-64.0693764, 46.794493],
            [-64.0693509, 46.7945644],
            [-64.0692612, 46.7946428],
            [-64.0691795, 46.7946535],
            [-64.0690587, 46.7946197],
            [-64.0688048, 46.7943964],
            [-64.0683696, 46.7943897],
            [-64.0682219, 46.7944671],
            [-64.0681289, 46.7946451],
            [-64.0677829, 46.7956286],
            [-64.0677582, 46.7967288],
            [-64.0678064, 46.7970286],
            [-64.0679491, 46.7974535],
            [-64.067977, 46.7976653],
            [-64.0679274, 46.7981111],
            [-64.0679405, 46.7984184],
            [-64.0678183, 46.7991303],
            [-64.067716, 46.7994158],
            [-64.0674909, 46.7997273],
            [-64.0672716, 46.7998595],
            [-64.0671731, 46.7998539],
            [-64.0672607, 46.7996639],
            [-64.0673976, 46.7995623],
            [-64.0674312, 46.7994233],
            [-64.0673778, 46.7992829],
            [-64.0672583, 46.7992093],
            [-64.0668323, 46.799095],
            [-64.06637, 46.799028],
            [-64.0664282, 46.7992003],
            [-64.066662, 46.799507],
            [-64.0665798, 46.7997091],
            [-64.0662907, 46.8001991],
            [-64.0662807, 46.8003265],
            [-64.0663051, 46.8004665],
            [-64.0666024, 46.8009576],
            [-64.0666377, 46.8011216],
            [-64.066604, 46.8012607],
            [-64.0657905, 46.8025081],
            [-64.06567, 46.8029887],
            [-64.0656398, 46.8031996],
            [-64.065705, 46.8036871],
            [-64.0656809, 46.8038901],
            [-64.0654886, 46.8044838],
            [-64.0652398, 46.8053121],
            [-64.0655847, 46.8053915],
            [-64.0657848, 46.805335],
            [-64.0666946, 46.805446],
            [-64.0668642, 46.8047444],
            [-64.0674732, 46.8027257],
            [-64.0678542, 46.8015633],
            [-64.0678816, 46.8012606],
            [-64.0678686, 46.8011248],
            [-64.0677909, 46.801016],
            [-64.0676545, 46.8009261],
            [-64.0673912, 46.8008104],
            [-64.067383, 46.8007066],
            [-64.0674998, 46.8006884],
            [-64.0681646, 46.8007785],
            [-64.0685999, 46.8007853],
            [-64.0688873, 46.8006981],
            [-64.069201, 46.8005195],
            [-64.0693992, 46.8003192],
            [-64.0695476, 46.8002218],
            [-64.0698634, 46.800155],
            [-64.0701759, 46.8001877],
            [-64.0709576, 46.8004312],
            [-64.0712142, 46.8005747],
            [-64.0722021, 46.801256],
            [-64.0723392, 46.8013259],
            [-64.0743159, 46.8026685],
            [-64.0747332, 46.8028703],
            [-64.0750955, 46.8033345],
            [-64.0751788, 46.8036269],
            [-64.0751103, 46.8039449],
            [-64.0749594, 46.8042935],
            [-64.0749524, 46.8046841],
            [-64.074995, 46.8048004],
            [-64.0752837, 46.8052077],
            [-64.0756648, 46.8056283],
            [-64.0759599, 46.8058362],
            [-64.0761098, 46.8058704],
            [-64.0767241, 46.8059119],
            [-64.0773798, 46.8062809],
            [-64.0778546, 46.8065036],
            [-64.0782301, 46.8067406],
            [-64.0783815, 46.8069065],
            [-64.0786034, 46.8074004],
            [-64.0787565, 46.8078693],
            [-64.0789087, 46.8081867],
            [-64.0791047, 46.8084091],
            [-64.0791292, 46.808549],
            [-64.0790912, 46.808999],
            [-64.0791022, 46.8091946],
            [-64.0791455, 46.809291],
            [-64.0798098, 46.8099353],
            [-64.0799718, 46.8099537],
            [-64.0804908, 46.80989],
            [-64.080746, 46.8099019],
            [-64.0813468, 46.8099989],
            [-64.0818243, 46.8099099],
            [-64.0817272, 46.8097336],
            [-64.0813612, 46.8095604],
            [-64.0804348, 46.8092949],
            [-64.0803159, 46.8092014],
            [-64.0801946, 46.8088247],
            [-64.0802007, 46.8082824],
            [-64.08016, 46.8081063],
            [-64.0798337, 46.8072519],
            [-64.0793831, 46.8062919],
            [-64.0791633, 46.8059097],
            [-64.0786051, 46.8052231],
            [-64.0782105, 46.8048582],
            [-64.0780617, 46.8046126],
            [-64.0780433, 46.8044648],
            [-64.0780964, 46.8042622],
            [-64.0781746, 46.8041797],
            [-64.0785193, 46.8039418],
            [-64.0785563, 46.8038746],
            [-64.0785063, 46.803806],
            [-64.0781424, 46.8037445],
            [-64.0777279, 46.8036345],
            [-64.0773918, 46.8036133],
            [-64.0773242, 46.8035485],
            [-64.0772957, 46.8033566],
            [-64.0773245, 46.8031856],
            [-64.0775863, 46.8024639],
            [-64.0776868, 46.8024096],
            [-64.0782111, 46.8023579],
            [-64.0784687, 46.8021186],
            [-64.0785118, 46.8020435],
            [-64.0784381, 46.8019866],
            [-64.0778241, 46.8021166],
            [-64.0775387, 46.8021441],
            [-64.0772147, 46.8021072],
            [-64.0768246, 46.8021371],
            [-64.0766694, 46.8020908],
            [-64.076154, 46.8016921],
            [-64.0760925, 46.8016193],
            [-64.0760179, 46.8012394],
            [-64.0758455, 46.8010054],
            [-64.0754058, 46.8006038],
            [-64.0749331, 46.8003214],
            [-64.0748263, 46.800212],
            [-64.0744678, 46.7999912],
            [-64.0739812, 46.7999557],
            [-64.0737402, 46.7998682],
            [-64.0735591, 46.7997219],
            [-64.0735091, 46.7996533],
            [-64.073695, 46.7992973],
            [-64.0738227, 46.7987689],
            [-64.0739616, 46.7979018],
            [-64.0740229, 46.7978031],
            [-64.0742252, 46.7976547],
            [-64.0742561, 46.7975953],
            [-64.0741468, 46.7975657],
            [-64.073972, 46.7975829],
            [-64.0736191, 46.7975456],
            [-64.0732341, 46.7972445],
            [-64.073018, 46.7971056],
            [-64.0713167, 46.7962259],
            [-64.0711554, 46.7961875],
            [-64.0709267, 46.7962557],
            [-64.0708827, 46.7961792],
            [-64.0710111, 46.795631],
            [-64.0710792, 46.7955044],
            [-64.0715619, 46.7951251],
            [-64.0718869, 46.7947793],
            [-64.0719549, 46.7946527],
            [-64.0718797, 46.7942927],
            [-64.0720251, 46.7939321],
            [-64.0722178, 46.7937197],
            [-64.072648, 46.7933516],
            [-64.0727093, 46.7932529],
            [-64.072814, 46.7927162],
            [-64.0727753, 46.7924803],
            [-64.0727455, 46.7916225],
            [-64.0726961, 46.791534],
            [-64.0726273, 46.7915091],
            [-64.0722804, 46.7914638],
            [-64.0722291, 46.7914351],
            [-64.0722088, 46.791347],
            [-64.072235, 46.7912557],
            [-64.0723078, 46.7911612],
            [-64.0729179, 46.7906164],
            [-64.0731813, 46.7901978],
            [-64.0732587, 46.7899637],
            [-64.0733234, 46.7892311],
            [-64.0734547, 46.788946],
            [-64.0742065, 46.7885031],
            [-64.0747487, 46.7882563],
            [-64.0750339, 46.7880574],
            [-64.0752794, 46.7880053],
            [-64.0755797, 46.7880538],
            [-64.0757377, 46.7881919],
            [-64.0758641, 46.7884091],
            [-64.0759926, 46.7887381],
            [-64.0765518, 46.7892133],
            [-64.0767985, 46.7896557],
            [-64.0771691, 46.7898608],
            [-64.0777647, 46.7902887],
            [-64.0779043, 46.7902789],
            [-64.0779583, 46.7902279],
            [-64.0779488, 46.7896296],
            [-64.0778826, 46.7895249],
            [-64.0776185, 46.7892577],
            [-64.0774652, 46.7891516],
            [-64.0772189, 46.7890521],
            [-64.0771169, 46.7889748],
            [-64.0768472, 46.788524],
            [-64.0768516, 46.7882131],
            [-64.0769027, 46.7880703],
            [-64.0770082, 46.7869794],
            [-64.0770006, 46.7868557],
            [-64.0768816, 46.7865907],
            [-64.0766872, 46.7865],
            [-64.0765603, 46.7864741],
            [-64.0754694, 46.7864612],
            [-64.0750726, 46.7863474],
            [-64.0748748, 46.7861848],
            [-64.0746222, 46.7859217],
            [-64.0744999, 46.7857563],
            [-64.0741867, 46.7855721],
            [-64.0739916, 46.7855012],
            [-64.0733318, 46.7854432],
            [-64.0730673, 46.7853673],
            [-64.0729829, 46.7852862],
            [-64.0729578, 46.7851662],
            [-64.0729806, 46.7850031],
            [-64.0732446, 46.7845646],
            [-64.0735471, 46.7841905],
            [-64.073831, 46.7840314],
            [-64.0741122, 46.783952],
            [-64.0743625, 46.783932],
            [-64.0750067, 46.783934],
            [-64.0752696, 46.7837068],
            [-64.0755231, 46.7835871],
            [-64.07665, 46.7832098],
            [-64.077593, 46.7831287],
            [-64.0778796, 46.7830614],
            [-64.0793043, 46.7829837],
            [-64.0795147, 46.7829391],
            [-64.0799347, 46.7826984],
            [-64.0801585, 46.7825982],
            [-64.0806928, 46.7825905],
            [-64.0819209, 46.7828446],
            [-64.0821192, 46.7828158],
            [-64.0825439, 46.782607],
            [-64.0826781, 46.7825852],
            [-64.0827996, 46.782599],
            [-64.0829279, 46.7827565],
            [-64.0831307, 46.7831225],
            [-64.083384, 46.7833656],
            [-64.0837594, 46.7836027],
            [-64.0847935, 46.784109],
            [-64.0850264, 46.7842641],
            [-64.0855313, 46.7844473],
            [-64.0859705, 46.7845059],
            [-64.086384, 46.7844644],
            [-64.086546, 46.7844828],
            [-64.0867802, 46.7845981],
            [-64.0869711, 46.784617],
            [-64.0872045, 46.7845807],
            [-64.0874331, 46.7845124],
            [-64.0877453, 46.7843737],
            [-64.0881423, 46.7841246],
            [-64.0883865, 46.7841124],
            [-64.0884541, 46.7841772],
            [-64.0885342, 46.7845692],
            [-64.088689, 46.7848068],
            [-64.0889503, 46.7849823],
            [-64.0890538, 46.7851912],
            [-64.0891902, 46.785281],
            [-64.0894952, 46.7853615],
            [-64.0901099, 46.7853829],
            [-64.0902727, 46.7855528],
            [-64.0902541, 46.7857679],
            [-64.0900775, 46.7863792],
            [-64.0901635, 46.7865918],
            [-64.0902897, 46.7866376],
            [-64.0904806, 46.7866565],
            [-64.0906844, 46.7866397],
            [-64.0910061, 46.7865649],
            [-64.0913763, 46.786427],
            [-64.0916438, 46.7862317],
            [-64.0917726, 46.7861978],
            [-64.0921355, 46.7861077],
            [-64.0922413, 46.7860654],
            [-64.0923431, 46.7859713],
            [-64.0924366, 46.7857734],
            [-64.0925721, 46.7857116],
            [-64.0932304, 46.785638],
            [-64.093832, 46.7855236],
            [-64.0942139, 46.7855613],
            [-64.0943746, 46.7856196],
            [-64.0946948, 46.7859474],
            [-64.0949163, 46.7860984],
            [-64.095054, 46.7861483],
            [-64.0951181, 46.7861413],
            [-64.0951902, 46.7860667],
            [-64.095404, 46.7855595],
            [-64.0956743, 46.785456],
            [-64.0960926, 46.7854465],
            [-64.0965379, 46.7854971],
            [-64.0967337, 46.7855479],
            [-64.0970314, 46.7856761],
            [-64.0972787, 46.7859271],
            [-64.097554, 46.7863898],
            [-64.0976856, 46.7864476],
            [-64.097751, 46.7864008],
            [-64.0977013, 46.7861409],
            [-64.097718, 46.7859856],
            [-64.097609, 46.7857646],
            [-64.0973429, 46.7855572],
            [-64.0961479, 46.7849927],
            [-64.0956774, 46.7848221],
            [-64.0953326, 46.7847171],
            [-64.0944107, 46.7845037],
            [-64.0936348, 46.784444],
            [-64.0931053, 46.7844837],
            [-64.0923519, 46.7846237],
            [-64.0920997, 46.7847036],
            [-64.0918105, 46.7848507],
            [-64.0916298, 46.7848758],
            [-64.0915174, 46.784583],
            [-64.091505, 46.7844273],
            [-64.0916002, 46.7839982],
            [-64.0917281, 46.7838127],
            [-64.0919123, 46.7833251],
            [-64.0920664, 46.7830483],
            [-64.0920419, 46.7829084],
            [-64.0918717, 46.7827862],
            [-64.0912562, 46.7826133],
            [-64.0910908, 46.782523],
            [-64.0905948, 46.7818894],
            [-64.0903491, 46.78177],
            [-64.0890193, 46.7816101],
            [-64.0887777, 46.7815426],
            [-64.0886467, 46.7814648],
            [-64.0886532, 46.7812656],
            [-64.0893309, 46.7805941],
            [-64.0897231, 46.7803131],
            [-64.0899272, 46.7801049],
            [-64.0903896, 46.7798089],
            [-64.0913725, 46.7793894],
            [-64.0921937, 46.7791228],
            [-64.0925909, 46.7790452],
            [-64.0929983, 46.7790116],
            [-64.0935595, 46.779064],
            [-64.0937511, 46.7790629],
            [-64.0942264, 46.7789027],
            [-64.0941969, 46.7785594],
            [-64.0940562, 46.7782462],
            [-64.0939825, 46.7781893],
            [-64.093799, 46.7781227],
            [-64.0931823, 46.7779897],
            [-64.0928158, 46.7778365],
            [-64.0926485, 46.777806],
            [-64.0924333, 46.7778187],
            [-64.09201, 46.7779877],
            [-64.0916609, 46.7781936],
            [-64.0908334, 46.7782966],
            [-64.0906714, 46.7782782],
            [-64.090552, 46.7782046],
            [-64.0905253, 46.777953],
            [-64.0904807, 46.7778965],
            [-64.0903491, 46.7778386],
            [-64.0900506, 46.7775589],
            [-64.0899021, 46.7774849],
            [-64.0895661, 46.7774638],
            [-64.0889603, 46.7773548],
            [-64.0883711, 46.7770906],
            [-64.0879743, 46.7769768],
            [-64.0868529, 46.7766606],
            [-64.0862518, 46.7765836],
            [-64.0859955, 46.7766115],
            [-64.0857144, 46.7766909],
            [-64.0854494, 46.7768065],
            [-64.0848934, 46.7771288],
            [-64.0846358, 46.7771967],
            [-64.0843187, 46.777132],
            [-64.0830722, 46.7765585],
            [-64.0823753, 46.7763963],
            [-64.0799764, 46.7761001],
            [-64.0795818, 46.776098],
            [-64.0790585, 46.7761298],
            [-64.0786559, 46.7761954],
            [-64.0781264, 46.776235],
            [-64.0777669, 46.7762255],
            [-64.0768752, 46.7761638],
            [-64.0760248, 46.7762583],
            [-64.0757328, 46.7763136],
            [-64.0748771, 46.7765675],
            [-64.074339, 46.7766947],
            [-64.073353, 46.7768509],
            [-64.0729019, 46.7769795],
            [-64.0727326, 46.7770087],
            [-64.0724789, 46.7769569],
            [-64.0722907, 46.7768583],
            [-64.070985, 46.775618],
            [-64.0705184, 46.7749847],
            [-64.0703313, 46.7746748],
            [-64.0699572, 46.7742264],
            [-64.0693955, 46.7736595],
            [-64.0692219, 46.7732939],
            [-64.0690466, 46.7726254],
            [-64.0690997, 46.7724228],
            [-64.0692156, 46.7722532],
            [-64.0696578, 46.7720408],
            [-64.0699376, 46.7720013],
            [-64.0702748, 46.7719826],
            [-64.0707551, 46.7720259],
            [-64.072579, 46.7723254],
            [-64.0733971, 46.7723301],
            [-64.0748448, 46.7720695],
            [-64.0751942, 46.772035],
            [-64.075408, 46.7720622],
            [-64.0758053, 46.7721561],
            [-64.0760091, 46.7723108],
            [-64.0764696, 46.7727804],
            [-64.0774882, 46.7735737],
            [-64.0777656, 46.7737853],
            [-64.0787947, 46.7742597],
            [-64.0799132, 46.7744843],
            [-64.0801911, 46.7745046],
            [-64.0819607, 46.7743405],
            [-64.0831403, 46.7742949],
            [-64.0837149, 46.7742918],
            [-64.0848843, 46.7742021],
            [-64.0852256, 46.7742353],
            [-64.0854079, 46.7743417],
            [-64.0858538, 46.7749068],
            [-64.0862583, 46.7753157],
            [-64.0865998, 46.7755203],
            [-64.0882598, 46.7760522],
            [-64.0885714, 46.7761048],
            [-64.0892481, 46.776179],
            [-64.089845, 46.7762041],
            [-64.0906065, 46.7761679],
            [-64.0910859, 46.7760597],
            [-64.0918503, 46.7757524],
            [-64.092486, 46.775479],
            [-64.0928851, 46.7753416],
            [-64.0946656, 46.7748385],
            [-64.094971, 46.7747276],
            [-64.0952445, 46.7745244],
            [-64.0955672, 46.7742383],
            [-64.0959138, 46.7737492],
            [-64.0960888, 46.7735405],
            [-64.096231, 46.773451],
            [-64.0964002, 46.7734217],
            [-64.0967071, 46.7734423],
            [-64.0970383, 46.7734314],
            [-64.0972662, 46.7733831],
            [-64.0975891, 46.7732684],
            [-64.0977555, 46.7731473],
            [-64.0979359, 46.7729507],
            [-64.0979876, 46.772788],
            [-64.0978717, 46.7724234],
            [-64.0979039, 46.7723242],
            [-64.0981168, 46.7721999],
            [-64.0986972, 46.7720174],
            [-64.0989366, 46.7719732],
            [-64.0991444, 46.7720082],
            [-64.099361, 46.7721272],
            [-64.099874, 46.7727769],
            [-64.1000291, 46.7731859],
            [-64.1002063, 46.7743489],
            [-64.1003248, 46.7746338],
            [-64.1005149, 46.7753982],
            [-64.1006189, 46.77595],
            [-64.1006329, 46.7762373],
            [-64.1006015, 46.7768509],
            [-64.1006832, 46.7777373],
            [-64.1006656, 46.7781038],
            [-64.1004343, 46.7789775],
            [-64.100359, 46.7791518],
            [-64.1002701, 46.7792102],
            [-64.100148, 46.7792163],
            [-64.1000804, 46.7791515],
            [-64.0999874, 46.7787952],
            [-64.0998147, 46.7787527],
            [-64.0991842, 46.7788667],
            [-64.0985154, 46.7790878],
            [-64.0981587, 46.7791701],
            [-64.097941, 46.7792625],
            [-64.0977328, 46.7794188],
            [-64.0973846, 46.7797763],
            [-64.0971075, 46.7799077],
            [-64.0969673, 46.7799374],
            [-64.0967467, 46.779938],
            [-64.0966387, 46.7798686],
            [-64.096581, 46.7796763],
            [-64.096108, 46.7795854],
            [-64.0956924, 46.7795152],
            [-64.0957379, 46.7797233],
            [-64.0958096, 46.77984],
            [-64.0959568, 46.7799539],
            [-64.0972027, 46.7803756],
            [-64.0976221, 46.780689],
            [-64.0977314, 46.7807186],
            [-64.097958, 46.7807101],
            [-64.0982468, 46.7807544],
            [-64.0985642, 46.7809905],
            [-64.098708, 46.7810325],
            [-64.0989474, 46.7809883],
            [-64.099066, 46.7809104],
            [-64.0994135, 46.7805728],
            [-64.0994983, 46.7804624],
            [-64.0996594, 46.7803293],
            [-64.0998933, 46.7802731],
            [-64.1003938, 46.7802328],
            [-64.1005191, 46.7801271],
            [-64.1004914, 46.7800868],
            [-64.1000181, 46.7798244],
            [-64.0999843, 46.779792],
            [-64.1000152, 46.7797327],
            [-64.100144, 46.7796987],
            [-64.1002999, 46.779725],
            [-64.1010619, 46.7800317],
            [-64.1011773, 46.7800534],
            [-64.1016784, 46.7799932],
            [-64.1018403, 46.7800116],
            [-64.1023404, 46.7801628],
            [-64.1026359, 46.7801792],
            [-64.1035316, 46.780121],
            [-64.1042552, 46.7800005],
            [-64.1044621, 46.779884],
            [-64.1046021, 46.7796828],
            [-64.1046735, 46.779628],
            [-64.1050659, 46.7795183],
            [-64.1054321, 46.7795],
            [-64.1058031, 46.7795136],
            [-64.1059211, 46.7794555],
            [-64.1061041, 46.7791792],
            [-64.1064193, 46.7789408],
            [-64.1073278, 46.778484],
            [-64.1077193, 46.7782228],
            [-64.1081142, 46.7776706],
            [-64.1082762, 46.7773262],
            [-64.1084075, 46.7768497],
            [-64.1085255, 46.776066],
            [-64.1088015, 46.7754203],
            [-64.1089912, 46.7751161],
            [-64.1091273, 46.7743088],
            [-64.1092842, 46.773761],
            [-64.1094225, 46.7734281],
            [-64.1095079, 46.7732979],
            [-64.1096561, 46.7732004],
            [-64.1102473, 46.7730419],
            [-64.1111677, 46.7729322],
            [-64.111858, 46.7727592],
            [-64.1124189, 46.7726401],
            [-64.1130694, 46.7724426],
            [-64.1137364, 46.7720898],
            [-64.1139413, 46.7720331],
            [-64.1141976, 46.7720051],
            [-64.1148506, 46.7720907],
            [-64.1155638, 46.7722888],
            [-64.1160884, 46.7725798],
            [-64.1163798, 46.7729071],
            [-64.1165943, 46.7732771],
            [-64.1167856, 46.7741931],
            [-64.116808, 46.7745841],
            [-64.1167844, 46.7749586],
            [-64.1166723, 46.7755629],
            [-64.1163821, 46.7764756],
            [-64.1160309, 46.7769328],
            [-64.115454, 46.7775501],
            [-64.1153055, 46.777839],
            [-64.115208, 46.7783479],
            [-64.1150538, 46.7784532],
            [-64.1148307, 46.7785336],
            [-64.1147593, 46.7785883],
            [-64.1145843, 46.778797],
            [-64.1140119, 46.7792748],
            [-64.1133942, 46.7797161],
            [-64.1129358, 46.7798926],
            [-64.1122546, 46.7803209],
            [-64.1115084, 46.7809675],
            [-64.111094, 46.7812204],
            [-64.1107992, 46.7815469],
            [-64.1107281, 46.7817731],
            [-64.1107647, 46.7818973],
            [-64.1106739, 46.7820155],
            [-64.1104581, 46.7820481],
            [-64.1103306, 46.7820422],
            [-64.1093185, 46.7815644],
            [-64.1091566, 46.781546],
            [-64.1087811, 46.7816719],
            [-64.1080304, 46.7820951],
            [-64.1076564, 46.7823526],
            [-64.1073022, 46.7827181],
            [-64.1072809, 46.7828414],
            [-64.1073115, 46.7829734],
            [-64.1076245, 46.783349],
            [-64.1077596, 46.7838415],
            [-64.1078597, 46.7839785],
            [-64.1081245, 46.7842258],
            [-64.1092144, 46.7855381],
            [-64.1097085, 46.78606],
            [-64.1100021, 46.786499],
            [-64.1104656, 46.7868888],
            [-64.1107124, 46.7871597],
            [-64.1108957, 46.7874177],
            [-64.1116066, 46.7880584],
            [-64.1119203, 46.788414],
            [-64.1121104, 46.7886442],
            [-64.1124588, 46.7891837],
            [-64.1125825, 46.7893092],
            [-64.1127305, 46.7894031],
            [-64.1128682, 46.7894531],
            [-64.1142985, 46.7897497],
            [-64.1144767, 46.7898043],
            [-64.1146536, 46.7898986],
            [-64.1147031, 46.7899871],
            [-64.1146782, 46.7900386],
            [-64.1146135, 46.7900655],
            [-64.1141378, 46.7900544],
            [-64.1136012, 46.7899506],
            [-64.1133981, 46.7899475],
            [-64.1132349, 46.789969],
            [-64.1129566, 46.7901402],
            [-64.1128982, 46.7903307],
            [-64.112911, 46.7910207],
            [-64.1131033, 46.7913626],
            [-64.1132276, 46.7914681],
            [-64.1139214, 46.7919212],
            [-64.11399, 46.7921375],
            [-64.1139619, 46.7922886],
            [-64.1136894, 46.7930062],
            [-64.1137009, 46.7933733],
            [-64.1138016, 46.7938533],
            [-64.1138004, 46.7946189],
            [-64.1133101, 46.7968803],
            [-64.1132699, 46.7974101],
            [-64.1132384, 46.7974893],
            [-64.1131912, 46.7975126],
            [-64.1130818, 46.797483],
            [-64.1129775, 46.797294],
            [-64.1127977, 46.7971079],
            [-64.1126937, 46.7970904],
            [-64.1125171, 46.7971674],
            [-64.112415, 46.7978159],
            [-64.1122636, 46.7983758],
            [-64.1121711, 46.7987253],
            [-64.1119581, 46.7992126],
            [-64.1117046, 46.7996952],
            [-64.1113956, 46.8000973],
            [-64.1114469, 46.800126],
            [-64.1118108, 46.8001873],
            [-64.1120098, 46.8001385],
            [-64.1122234, 46.7999942],
            [-64.1129272, 46.7999569],
            [-64.1129974, 46.799942],
            [-64.1133029, 46.799831],
            [-64.1137002, 46.7997532],
            [-64.1139394, 46.7995375],
            [-64.1140232, 46.7992756],
            [-64.1139566, 46.7989995],
            [-64.1139907, 46.7988405],
            [-64.1140688, 46.7987579],
            [-64.1145312, 46.7984618],
            [-64.1148067, 46.7981988],
            [-64.1148449, 46.7980917],
            [-64.1148185, 46.7980116],
            [-64.1147448, 46.7979547],
            [-64.1143682, 46.797929],
            [-64.1142741, 46.7977841],
            [-64.1143475, 46.7976695],
            [-64.1146191, 46.7975261],
            [-64.1152294, 46.7973239],
            [-64.1154377, 46.7971676],
            [-64.1162386, 46.7964499],
            [-64.1163961, 46.7962449],
            [-64.1167665, 46.7955527],
            [-64.117194, 46.7943469],
            [-64.1173176, 46.7937467],
            [-64.1175336, 46.7920712],
            [-64.1177474, 46.7913727],
            [-64.1177349, 46.7908541],
            [-64.1176842, 46.7904427],
            [-64.1177587, 46.7899254],
            [-64.1176627, 46.7898402],
            [-64.1168777, 46.7896969],
            [-64.1168095, 46.789652],
            [-64.1166587, 46.7894663],
            [-64.116534, 46.7891893],
            [-64.1164938, 46.7889933],
            [-64.1164548, 46.7883946],
            [-64.1165323, 46.7879691],
            [-64.1165233, 46.7878853],
            [-64.1164503, 46.7878084],
            [-64.1162335, 46.7876895],
            [-64.1154916, 46.787471],
            [-64.1153443, 46.7873571],
            [-64.1152665, 46.7872483],
            [-64.1149413, 46.7865257],
            [-64.1149052, 46.7863816],
            [-64.1149492, 46.7860952],
            [-64.1150056, 46.7859644],
            [-64.1152622, 46.785745],
            [-64.1157018, 46.7856121],
            [-64.1158332, 46.7854984],
            [-64.1158647, 46.7854192],
            [-64.1157394, 46.7851621],
            [-64.115719, 46.7847112],
            [-64.1157869, 46.7845846],
            [-64.1158589, 46.78451],
            [-64.1164687, 46.7841364],
            [-64.1165981, 46.7840825],
            [-64.1177498, 46.7838247],
            [-64.1179471, 46.7836442],
            [-64.1181071, 46.7833595],
            [-64.1181973, 46.7828984],
            [-64.1183518, 46.7826017],
            [-64.1186055, 46.7822905],
            [-64.1191422, 46.78184],
            [-64.1192914, 46.7815312],
            [-64.1194118, 46.7810307],
            [-64.1198209, 46.7802114],
            [-64.1198805, 46.7799811],
            [-64.1201743, 46.779503],
            [-64.1202125, 46.779396],
            [-64.1200789, 46.7790351],
            [-64.1200836, 46.7787042],
            [-64.1201575, 46.7785698],
            [-64.1205007, 46.7781802],
            [-64.1210192, 46.7777534],
            [-64.121299, 46.7773509],
            [-64.121883, 46.7766858],
            [-64.1225255, 46.7761931],
            [-64.1227317, 46.7760965],
            [-64.1231749, 46.7758439],
            [-64.1235787, 46.7757383],
            [-64.1242936, 46.7757052],
            [-64.1245352, 46.7757726],
            [-64.124925, 46.7760629],
            [-64.1249832, 46.7761062],
            [-64.1255173, 46.7766525],
            [-64.1259435, 46.776938],
            [-64.1260321, 46.7770709],
            [-64.1259837, 46.7771339],
            [-64.125884, 46.7771683],
            [-64.1257848, 46.7771828],
            [-64.1255601, 46.7771316],
            [-64.1255034, 46.7770909],
            [-64.1253988, 46.7770933],
            [-64.1253051, 46.7771198],
            [-64.1252959, 46.7772273],
            [-64.1253157, 46.7773353],
            [-64.1254315, 46.7775284],
            [-64.1256234, 46.7776987],
            [-64.1258982, 46.7778184],
            [-64.1261532, 46.7778302],
            [-64.1262415, 46.7777917],
            [-64.1263485, 46.7777095],
            [-64.126416, 46.7774115],
            [-64.1264747, 46.7773924],
            [-64.126917, 46.7773512],
            [-64.1273275, 46.7772178],
            [-64.1277355, 46.7771641],
            [-64.1279729, 46.7771796],
            [-64.128452, 46.7772625],
            [-64.128963, 46.7774376],
            [-64.1307103, 46.7781613],
            [-64.1318131, 46.778712],
            [-64.13224, 46.7789776],
            [-64.1323777, 46.7790274],
            [-64.1330661, 46.7794683],
            [-64.1334463, 46.7799285],
            [-64.1335653, 46.780022],
            [-64.133819, 46.7800736],
            [-64.1339006, 46.7800628],
            [-64.1339484, 46.7800197],
            [-64.1339432, 46.7798163],
            [-64.1336329, 46.7795325],
            [-64.1335697, 46.7793283],
            [-64.1336081, 46.7790298],
            [-64.1335762, 46.7789376],
            [-64.1332803, 46.7787498],
            [-64.1331904, 46.7786568],
            [-64.1331332, 46.7784446],
            [-64.1331493, 46.7781179],
            [-64.1332689, 46.7776372],
            [-64.133243, 46.7775372],
            [-64.133141, 46.7774599],
            [-64.1329561, 46.7774332],
            [-64.1324321, 46.7774852],
            [-64.1319005, 46.7774135],
            [-64.131013, 46.7772129],
            [-64.1302308, 46.77699],
            [-64.1295702, 46.7765894],
            [-64.1293473, 46.7764784],
            [-64.1284727, 46.7762421],
            [-64.1282352, 46.7762266],
            [-64.1280255, 46.7762514],
            [-64.1279656, 46.7763103],
            [-64.1278965, 46.7764767],
            [-64.1277949, 46.7765709],
            [-64.1274067, 46.7767325],
            [-64.1271571, 46.7767328],
            [-64.1269222, 46.7766376],
            [-64.1267032, 46.776407],
            [-64.1265995, 46.7761982],
            [-64.126499, 46.7758897],
            [-64.1264651, 46.7754944],
            [-64.1266105, 46.774751],
            [-64.1266457, 46.7741894],
            [-64.1265859, 46.7736941],
            [-64.1263821, 46.7733481],
            [-64.1257569, 46.7727487],
            [-64.1256919, 46.7726042],
            [-64.1256673, 46.7724643],
            [-64.1257367, 46.7721065],
            [-64.1258946, 46.7718816],
            [-64.1261975, 46.771296],
            [-64.1263879, 46.7707805],
            [-64.1264493, 46.7704904],
            [-64.1264164, 46.7702467],
            [-64.1263249, 46.7700221],
            [-64.1262477, 46.7698933],
            [-64.1257801, 46.7694518],
            [-64.1256945, 46.7692192],
            [-64.125534, 46.769161],
            [-64.1253545, 46.7691464],
            [-64.1251738, 46.7691716],
            [-64.1251454, 46.7691512],
            [-64.1251709, 46.7690798],
            [-64.1253952, 46.7689596],
            [-64.1257409, 46.7688531],
            [-64.1259742, 46.7688167],
            [-64.1263791, 46.7686713],
            [-64.1274255, 46.7682444],
            [-64.1279433, 46.7682002],
            [-64.1282333, 46.7682046],
            [-64.1296747, 46.7683138],
            [-64.1298589, 46.7683604],
            [-64.1306074, 46.7683596],
            [-64.1313719, 46.7682234],
            [-64.1315133, 46.7681538],
            [-64.1318876, 46.7678763],
            [-64.1324297, 46.7672464],
            [-64.1326204, 46.7669024],
            [-64.1326715, 46.7665682],
            [-64.1325675, 46.7661879],
            [-64.1325523, 46.765195],
            [-64.1326733, 46.7650373],
            [-64.1329629, 46.7648702],
            [-64.1333847, 46.7647409],
            [-64.1335539, 46.7647115],
            [-64.1338735, 46.7646963],
            [-64.1341749, 46.7647048],
            [-64.1345723, 46.7647984],
            [-64.1375371, 46.7655401],
            [-64.1379418, 46.765586],
            [-64.1380547, 46.7656873],
            [-64.1382326, 46.7659331],
            [-64.1384325, 46.7660358],
            [-64.1387658, 46.7661364],
            [-64.1390552, 46.7661606],
            [-64.1391259, 46.7661258],
            [-64.138498, 46.7657975],
            [-64.1383183, 46.7654201],
            [-64.1382975, 46.7651606],
            [-64.1385065, 46.7646016],
            [-64.1385233, 46.7640636],
            [-64.1384939, 46.7638917],
            [-64.1385456, 46.7635376],
            [-64.1386612, 46.7633679],
            [-64.138909, 46.763236],
            [-64.1402657, 46.7627099],
            [-64.1419048, 46.7624272],
            [-64.1425194, 46.7622568],
            [-64.1429259, 46.7622429],
            [-64.1431856, 46.7622866],
            [-64.1439959, 46.7627212],
            [-64.1442398, 46.7629002],
            [-64.1444891, 46.7630912],
            [-64.1449806, 46.7633297],
            [-64.145446, 46.7636595],
            [-64.1454792, 46.7637118],
            [-64.1453958, 46.7637824],
            [-64.1452145, 46.7638275],
            [-64.1451184, 46.7639338],
            [-64.1450984, 46.7640172],
            [-64.1452346, 46.7642983],
            [-64.1454055, 46.7644005],
            [-64.1461484, 46.7645789],
            [-64.1466691, 46.7646264],
            [-64.1470648, 46.7645884],
            [-64.1475277, 46.7644437],
            [-64.1478529, 46.7642491],
            [-64.1480915, 46.7642247],
            [-64.1486593, 46.764249],
            [-64.1511358, 46.7646522],
            [-64.1513255, 46.7647108],
            [-64.1515084, 46.7647972],
            [-64.1522507, 46.7649955],
            [-64.1525866, 46.7650163],
            [-64.1531859, 46.7649613],
            [-64.1538946, 46.7649358],
            [-64.154448, 46.7648642],
            [-64.1549295, 46.7648673],
            [-64.1550267, 46.7649125],
            [-64.1552767, 46.7650836],
            [-64.1554215, 46.7652771],
            [-64.1554637, 46.7654133],
            [-64.1554943, 46.7657367],
            [-64.1553886, 46.7667159],
            [-64.1553973, 46.7671825],
            [-64.1553154, 46.7677674],
            [-64.155389, 46.7678243],
            [-64.1555413, 46.7677787],
            [-64.1558381, 46.7675638],
            [-64.1558992, 46.767465],
            [-64.1559624, 46.7669237],
            [-64.156064, 46.7666381],
            [-64.1565558, 46.7657482],
            [-64.1566182, 46.7654182],
            [-64.156605, 46.7652825],
            [-64.156433, 46.7650288],
            [-64.1561915, 46.7647701],
            [-64.1558564, 46.7645379],
            [-64.1556794, 46.7644437],
            [-64.1552941, 46.7643344],
            [-64.1549588, 46.7642936],
            [-64.1542231, 46.7642589],
            [-64.1534825, 46.7641922],
            [-64.1519205, 46.7638584],
            [-64.15122, 46.7636248],
            [-64.1508194, 46.7636309],
            [-64.148033, 46.7633069],
            [-64.1476386, 46.7633051],
            [-64.1473069, 46.7633361],
            [-64.1470991, 46.7633011],
            [-64.1468582, 46.7632139],
            [-64.1463529, 46.762114],
            [-64.1463038, 46.7616428],
            [-64.1463333, 46.7610692],
            [-64.1462685, 46.7605419],
            [-64.1463025, 46.760383],
            [-64.1463817, 46.7602605],
            [-64.1466778, 46.7600656],
            [-64.1467324, 46.7598032],
            [-64.1464288, 46.7589376],
            [-64.1465002, 46.7586915],
            [-64.1466618, 46.758347],
            [-64.1467573, 46.7582607],
            [-64.1474493, 46.7578363],
            [-64.1477243, 46.7575733],
            [-64.1480346, 46.7571114],
            [-64.1489187, 46.7564745],
            [-64.1495807, 46.7557068],
            [-64.150422, 46.7549537],
            [-64.1515386, 46.7543004],
            [-64.1521736, 46.7540266],
            [-64.1529023, 46.753535],
            [-64.1536558, 46.7529919],
            [-64.153735, 46.7528694],
            [-64.1538416, 46.7524245],
            [-64.1539734, 46.7522909],
            [-64.1542459, 46.7521075],
            [-64.1547642, 46.7518519],
            [-64.1553963, 46.7512951],
            [-64.1556338, 46.7511192],
            [-64.1567568, 46.7506293],
            [-64.1570993, 46.750431],
            [-64.1577692, 46.7501497],
            [-64.1578912, 46.7501435],
            [-64.1580935, 46.7501664],
            [-64.1582716, 46.7502208],
            [-64.1583417, 46.7502059],
            [-64.158524, 46.75],
            [-64.1586698, 46.7499117],
            [-64.1589217, 46.7498317],
            [-64.159546, 46.7498966],
            [-64.1597206, 46.7498792],
            [-64.160135, 46.7497975],
            [-64.1615834, 46.7492844],
            [-64.1617489, 46.7491832],
            [-64.1618535, 46.7489894],
            [-64.1620565, 46.748801],
            [-64.1623143, 46.748713],
            [-64.1638568, 46.7483448],
            [-64.1639691, 46.7482747],
            [-64.1640314, 46.748136],
            [-64.164075, 46.7478496],
            [-64.1642743, 46.7477808],
            [-64.1654952, 46.7480616],
            [-64.1660894, 46.7481659],
            [-64.1666854, 46.7482105],
            [-64.1672591, 46.7482268],
            [-64.1689028, 46.7483383],
            [-64.1691498, 46.7484176],
            [-64.1693255, 46.7485517],
            [-64.1697124, 46.7491752],
            [-64.1698199, 46.7492645],
            [-64.1703223, 46.7493356],
            [-64.1710722, 46.7498487],
            [-64.171199, 46.7498745],
            [-64.1713041, 46.7498521],
            [-64.1713633, 46.7498131],
            [-64.1714479, 46.7497027],
            [-64.1715712, 46.7492739],
            [-64.171675, 46.7492913],
            [-64.171803, 46.74966],
            [-64.1719405, 46.7499011],
            [-64.172182, 46.75],
            [-64.172908, 46.75],
            [-64.1729713, 46.7499519],
            [-64.1731676, 46.7499827],
            [-64.173284, 46.75],
            [-64.17335, 46.7498976],
            [-64.1733121, 46.7498133],
            [-64.1732263, 46.7497722],
            [-64.1730742, 46.7496265],
            [-64.1730169, 46.7494144],
            [-64.1728001, 46.7491043],
            [-64.1726376, 46.7489146],
            [-64.1721196, 46.7485961],
            [-64.171485, 46.7482959],
            [-64.171086, 46.7476882],
            [-64.1705125, 46.7470978],
            [-64.1704745, 46.7470136],
            [-64.1705041, 46.7469941],
            [-64.1713622, 46.7470025],
            [-64.1715204, 46.746949],
            [-64.1717982, 46.7467776],
            [-64.1719039, 46.7467353],
            [-64.1723357, 46.7468412],
            [-64.1730229, 46.7467474],
            [-64.1741963, 46.7463059],
            [-64.1742446, 46.7462428],
            [-64.1748141, 46.7458245],
            [-64.1752308, 46.7456631],
            [-64.17595, 46.7454701],
            [-64.1764066, 46.7451418],
            [-64.1765473, 46.745092],
            [-64.1775068, 46.7450062],
            [-64.1775636, 46.7448555],
            [-64.177531, 46.7445919],
            [-64.1752424, 46.7449018],
            [-64.1749206, 46.7449968],
            [-64.1743837, 46.7451047],
            [-64.1731682, 46.7452187],
            [-64.1723168, 46.7451825],
            [-64.1713102, 46.7452915],
            [-64.170338, 46.7452216],
            [-64.1700609, 46.7451817],
            [-64.1695421, 46.7452659],
            [-64.169085, 46.7452314],
            [-64.1682137, 46.7452786],
            [-64.167753, 46.7451722],
            [-64.1671559, 46.7449762],
            [-64.1669337, 46.7448454],
            [-64.1657541, 46.7441665],
            [-64.1647247, 46.7438845],
            [-64.1642387, 46.7438495],
            [-64.1640292, 46.7438744],
            [-64.1638474, 46.7439395],
            [-64.1637749, 46.7440341],
            [-64.1636154, 46.7441275],
            [-64.1631576, 46.7443042],
            [-64.1624987, 46.7446095],
            [-64.1615299, 46.7451854],
            [-64.1613246, 46.745132],
            [-64.1610579, 46.7450435],
            [-64.1608998, 46.744997],
            [-64.1606988, 46.7448718],
            [-64.1603604, 46.7441308],
            [-64.1603301, 46.7440913],
            [-64.1606308, 46.7439792],
            [-64.1606659, 46.7438717],
            [-64.1602244, 46.7433905],
            [-64.15982, 46.7432091],
            [-64.1594078, 46.7430126],
            [-64.1592989, 46.7431816],
            [-64.1594637, 46.7432938],
            [-64.1595536, 46.7433847],
            [-64.1596944, 46.7435505],
            [-64.1599177, 46.7438401],
            [-64.1602238, 46.7440996],
            [-64.1602901, 46.7441028],
            [-64.1603122, 46.744154],
            [-64.1599975, 46.7448521],
            [-64.1598863, 46.7450738],
            [-64.1596632, 46.7455369],
            [-64.1593278, 46.7460702],
            [-64.159051, 46.7463931],
            [-64.1589151, 46.7464748],
            [-64.1579487, 46.746971],
            [-64.1573781, 46.7471884],
            [-64.1569472, 46.7473624],
            [-64.1564165, 46.7475357],
            [-64.1562325, 46.7475613],
            [-64.1555288, 46.74741],
            [-64.15502, 46.7472293],
            [-64.1544962, 46.7470803],
            [-64.1539485, 46.7467807],
            [-64.1539498, 46.7467101],
            [-64.1542929, 46.7466901],
            [-64.1543446, 46.7466453],
            [-64.1543336, 46.746524],
            [-64.1540607, 46.7462839],
            [-64.1537703, 46.7460053],
            [-64.1534104, 46.7457851],
            [-64.1533337, 46.7458059],
            [-64.1530531, 46.7453894],
            [-64.152831, 46.7454474],
            [-64.1527827, 46.7454698],
            [-64.1530322, 46.7459154],
            [-64.153092, 46.7459603],
            [-64.1532658, 46.7460748],
            [-64.1533592, 46.7462661],
            [-64.1534986, 46.7465515],
            [-64.1536683, 46.7466833],
            [-64.1539193, 46.7467085],
            [-64.1539174, 46.7467809],
            [-64.1540799, 46.7470336],
            [-64.1538801, 46.7472512],
            [-64.153796, 46.7473543],
            [-64.153752, 46.7476471],
            [-64.1534002, 46.7481443],
            [-64.1532739, 46.74829],
            [-64.1531124, 46.7486344],
            [-64.1530525, 46.7488847],
            [-64.1529787, 46.7490192],
            [-64.1525727, 46.7493959],
            [-64.1522099, 46.749869],
            [-64.151976, 46.75],
            [-64.1513394, 46.7502589],
            [-64.1503408, 46.7506628],
            [-64.1497149, 46.7510204],
            [-64.1492574, 46.7513685],
            [-64.148928, 46.7517025],
            [-64.1486389, 46.7522325],
            [-64.1482907, 46.7526101],
            [-64.148136, 46.7527354],
            [-64.1472325, 46.7534357],
            [-64.1465031, 46.7539473],
            [-64.1463176, 46.7541319],
            [-64.1459514, 46.7543418],
            [-64.1457103, 46.7544459],
            [-64.1454832, 46.7544744],
            [-64.1451697, 46.7544818],
            [-64.1450803, 46.7545602],
            [-64.1450263, 46.7548026],
            [-64.1448182, 46.7551504],
            [-64.1443257, 46.75606],
            [-64.1441347, 46.7562326],
            [-64.1433466, 46.7567632],
            [-64.1432015, 46.7569524],
            [-64.1428464, 46.7575492],
            [-64.1424679, 46.7579662],
            [-64.1419884, 46.7584575],
            [-64.1415079, 46.7587972],
            [-64.1409562, 46.7590004],
            [-64.1405653, 46.7590704],
            [-64.1396267, 46.7590325],
            [-64.1390838, 46.7589567],
            [-64.1383777, 46.7589024],
            [-64.1382136, 46.7587724],
            [-64.1379339, 46.758258],
            [-64.1379226, 46.7580624],
            [-64.1379897, 46.7579558],
            [-64.1380546, 46.7577375],
            [-64.1380083, 46.7575494],
            [-64.137743, 46.7571308],
            [-64.1375742, 46.7567775],
            [-64.1374366, 46.7567276],
            [-64.1373713, 46.7567745],
            [-64.137305, 46.757224],
            [-64.1373361, 46.7580816],
            [-64.1374162, 46.7588563],
            [-64.1374099, 46.7590555],
            [-64.1373517, 46.759246],
            [-64.1368749, 46.7600204],
            [-64.1367781, 46.7601465],
            [-64.1366119, 46.7602677],
            [-64.1358964, 46.7605122],
            [-64.1355871, 46.7605714],
            [-64.1351455, 46.7605928],
            [-64.1348562, 46.7605685],
            [-64.133791, 46.7603135],
            [-64.1336237, 46.7602831],
            [-64.1331604, 46.7602563],
            [-64.1328577, 46.7602876],
            [-64.1323605, 46.7604198],
            [-64.1318565, 46.7605797],
            [-64.1306675, 46.7607534],
            [-64.1302378, 46.7609503],
            [-64.1293395, 46.7614712],
            [-64.1291812, 46.7615246],
            [-64.1285335, 46.7616426],
            [-64.1276624, 46.7616694],
            [-64.1268515, 46.7618088],
            [-64.1266129, 46.7618332],
            [-64.125318, 46.7616862],
            [-64.124925, 46.7616735],
            [-64.1248601, 46.7616714],
            [-64.1236749, 46.7617254],
            [-64.1233269, 46.7617202],
            [-64.1218657, 46.7613315],
            [-64.1213893, 46.7611689],
            [-64.1212752, 46.7611074],
            [-64.1210735, 46.7610645],
            [-64.1209158, 46.761098],
            [-64.1208129, 46.761232],
            [-64.1207697, 46.7614985],
            [-64.1206945, 46.7616728],
            [-64.1202205, 46.7619846],
            [-64.1201695, 46.7621274],
            [-64.1201953, 46.7622275],
            [-64.1204879, 46.7625149],
            [-64.1208168, 46.7627551],
            [-64.120936, 46.76302],
            [-64.120996, 46.7633239],
            [-64.12096, 46.7635427],
            [-64.1208157, 46.7638834],
            [-64.1205373, 46.764246],
            [-64.1204491, 46.7646473],
            [-64.1204932, 46.7650866],
            [-64.1205978, 46.765447],
            [-64.1207969, 46.7664866],
            [-64.1208613, 46.766651],
            [-64.1212001, 46.7671265],
            [-64.1214722, 46.768243],
            [-64.1214308, 46.7684497],
            [-64.1208915, 46.7695341],
            [-64.1206797, 46.76981],
            [-64.1204426, 46.7699659],
            [-64.1202782, 46.7700272],
            [-64.1199397, 46.7700859],
            [-64.1195114, 46.7700516],
            [-64.1177048, 46.7697493],
            [-64.1168708, 46.7695175],
            [-64.1163332, 46.7694536],
            [-64.1159654, 46.7693404],
            [-64.1154214, 46.769113],
            [-64.1149574, 46.769106],
            [-64.114706, 46.769166],
            [-64.1136878, 46.7696131],
            [-64.1132803, 46.7698382],
            [-64.1129141, 46.7702194],
            [-64.1124125, 46.7702996],
            [-64.1122197, 46.7703405],
            [-64.1119023, 46.7704673],
            [-64.1116773, 46.7706075],
            [-64.1110693, 46.7707498],
            [-64.1108771, 46.7707708],
            [-64.1106747, 46.7707478],
            [-64.1104682, 46.7706729],
            [-64.109394, 46.7699709],
            [-64.1091053, 46.7699267],
            [-64.1089771, 46.7699407],
            [-64.1087123, 46.7700563],
            [-64.1079774, 46.7705356],
            [-64.107624, 46.7708811],
            [-64.1071363, 46.7712485],
            [-64.1061665, 46.7719953],
            [-64.1059768, 46.7722994],
            [-64.1053415, 46.7731072],
            [-64.1051583, 46.773212],
            [-64.1050295, 46.773246],
            [-64.1049371, 46.7732326],
            [-64.1045907, 46.7729961],
            [-64.1044556, 46.7728665],
            [-64.1042893, 46.7726247],
            [-64.104231, 46.7724524],
            [-64.1041632, 46.7718533],
            [-64.1041964, 46.7715428],
            [-64.1040694, 46.7706199],
            [-64.1039502, 46.7703549],
            [-64.103811, 46.7701734],
            [-64.1032961, 46.7695834],
            [-64.1027943, 46.7691293],
            [-64.1023986, 46.7688043],
            [-64.1013752, 46.7681508],
            [-64.100823, 46.7678195],
            [-64.1003546, 46.7675891],
            [-64.0995854, 46.7673301],
            [-64.0990033, 46.7672096],
            [-64.0985919, 46.7671914],
            [-64.0983876, 46.7672282],
            [-64.0981384, 46.7673998],
            [-64.0979149, 46.7676715],
            [-64.0971994, 46.7680872],
            [-64.0964096, 46.7684659],
            [-64.0953422, 46.7689958],
            [-64.0944911, 46.7694732],
            [-64.0941319, 46.7696352],
            [-64.0936557, 46.7700067],
            [-64.0932839, 46.7703758],
            [-64.0929979, 46.770786],
            [-64.0925076, 46.7712331],
            [-64.0923252, 46.7714894],
            [-64.0922224, 46.7717949],
            [-64.0921047, 46.7720243],
            [-64.0919801, 46.7721101],
            [-64.0918277, 46.7721556],
            [-64.0916119, 46.7721882],
            [-64.091444, 46.7721777],
            [-64.091002, 46.7720274],
            [-64.0894216, 46.7713732],
            [-64.0886687, 46.7711503],
            [-64.0882363, 46.7710639],
            [-64.0879852, 46.7709325],
            [-64.0876305, 46.7704207],
            [-64.0871864, 46.7701587],
            [-64.0866291, 46.7699867],
            [-64.0862432, 46.769897],
            [-64.0857381, 46.7699052],
            [-64.0852151, 46.7701084],
            [-64.0847513, 46.7702728],
            [-64.0843907, 46.7704745],
            [-64.0840436, 46.7706207],
            [-64.0836289, 46.770702],
            [-64.083525, 46.7706845],
            [-64.0835519, 46.7705733],
            [-64.0837964, 46.7703697],
            [-64.0838462, 46.7702668],
            [-64.0837798, 46.7699907],
            [-64.0837043, 46.7694592],
            [-64.083648, 46.7692271],
            [-64.0830214, 46.7679815],
            [-64.0826938, 46.7675299],
            [-64.0822859, 46.7670491],
            [-64.0820974, 46.7667791],
            [-64.0819131, 46.7665609],
            [-64.0816863, 46.766398],
            [-64.0813418, 46.7659301],
            [-64.081196, 46.7657764],
            [-64.0808173, 46.7654675],
            [-64.0806263, 46.7652772],
            [-64.0801087, 46.7649582],
            [-64.0798456, 46.7648425],
            [-64.0785019, 46.7644151],
            [-64.0777816, 46.7642644],
            [-64.0770496, 46.7639381],
            [-64.0767709, 46.7637663],
            [-64.0766507, 46.7635412],
            [-64.0766202, 46.7634092],
            [-64.076647, 46.7632979],
            [-64.0774096, 46.7619821],
            [-64.0775244, 46.7609552],
            [-64.0778824, 46.7602989],
            [-64.0779775, 46.7596984],
            [-64.0778878, 46.759071],
            [-64.0773595, 46.7580223],
            [-64.0772913, 46.7579774],
            [-64.0767795, 46.7573076],
            [-64.0763314, 46.7568223],
            [-64.0758516, 46.7564161],
            [-64.0756639, 46.7561262],
            [-64.0756322, 46.756034],
            [-64.0757001, 46.7559075],
            [-64.0759312, 46.7557595],
            [-64.0762467, 46.7556927],
            [-64.0763564, 46.7555309],
            [-64.0763089, 46.7552112],
            [-64.0763505, 46.7550045],
            [-64.07692, 46.7542638],
            [-64.0771689, 46.7537494],
            [-64.0772771, 46.7534561],
            [-64.0775402, 46.7530375],
            [-64.0775608, 46.7527627],
            [-64.0775423, 46.7524435],
            [-64.0775904, 46.7520376],
            [-64.0777168, 46.7517206],
            [-64.0777086, 46.7516168],
            [-64.0775769, 46.7513876],
            [-64.0773505, 46.7508618],
            [-64.0776811, 46.7501286],
            [-64.0778078, 46.7498207],
            [-64.0779122, 46.7495679],
            [-64.0778311, 46.7493509],
            [-64.0778416, 46.7491023],
            [-64.0779093, 46.7490175],
            [-64.0780828, 46.7490608],
            [-64.0781014, 46.7494321],
            [-64.0780613, 46.749704],
            [-64.0780431, 46.75],
            [-64.0781274, 46.7502277],
            [-64.0785094, 46.7502562],
            [-64.078837, 46.7501636],
            [-64.0794051, 46.75],
            [-64.0797471, 46.749938],
            [-64.0798838, 46.7498365],
            [-64.0800413, 46.7496316],
            [-64.0801727, 46.749518],
            [-64.0808229, 46.7493208],
            [-64.0811651, 46.7491426],
            [-64.0815108, 46.7490363],
            [-64.0818723, 46.7491575],
            [-64.0820182, 46.7494827],
            [-64.0823373, 46.7496591],
            [-64.0825376, 46.7497419],
            [-64.0827029, 46.7498322],
            [-64.0828021, 46.75],
            [-64.0829871, 46.7504631],
            [-64.0833197, 46.750555],
            [-64.0835639, 46.7504674],
            [-64.0841878, 46.7502268],
            [-64.0843371, 46.75],
            [-64.0843499, 46.7497809],
            [-64.0851168, 46.7498132],
            [-64.0853196, 46.7496541],
            [-64.0856398, 46.7495702],
            [-64.0855126, 46.7495253],
            [-64.0853972, 46.7494364],
            [-64.0848206, 46.7496335],
            [-64.0837542, 46.7496091],
            [-64.083506, 46.7495694],
            [-64.0832026, 46.7494492],
            [-64.0830265, 46.7493348],
            [-64.0829508, 46.7491662],
            [-64.0829662, 46.7490508],
            [-64.0831518, 46.7486949],
            [-64.0831611, 46.7485874],
            [-64.0830901, 46.7484507],
            [-64.083022, 46.7484058],
            [-64.0827153, 46.7483852],
            [-64.0819651, 46.7484453],
            [-64.0808427, 46.7483603],
            [-64.0806742, 46.7483696],
            [-64.080094, 46.748552],
            [-64.0792586, 46.7489139],
            [-64.0789008, 46.7490359],
            [-64.0785153, 46.7491177],
            [-64.0783226, 46.7490996],
            [-64.078084, 46.748912],
            [-64.0776603, 46.7488373],
            [-64.076979, 46.7483882],
            [-64.0769304, 46.7482798],
            [-64.0767964, 46.7477675],
            [-64.0763332, 46.7477404],
            [-64.0762072, 46.7476946],
            [-64.0761579, 46.7476061],
            [-64.076403, 46.7473826],
            [-64.0764285, 46.7473113],
            [-64.0764143, 46.7472154],
            [-64.0758933, 46.7466531],
            [-64.0752289, 46.7462203],
            [-64.0740407, 46.7456676],
            [-64.0734404, 46.7452277],
            [-64.0731779, 46.7449207],
            [-64.0731853, 46.744873],
            [-64.073279, 46.7448465],
            [-64.0733538, 46.7448636],
            [-64.0735865, 46.7450187],
            [-64.0739081, 46.7451154],
            [-64.0741562, 46.7451551],
            [-64.0742944, 46.7451852],
            [-64.0749104, 46.7451469],
            [-64.0752722, 46.7450767],
            [-64.0754717, 46.7450081],
            [-64.0756616, 46.7448755],
            [-64.0762918, 46.7442274],
            [-64.0770706, 46.7432907],
            [-64.0774851, 46.7426752],
            [-64.0779942, 46.7416505],
            [-64.07819, 46.7413386],
            [-64.0790969, 46.7405593],
            [-64.0792618, 46.7403067],
            [-64.079321, 46.7402677],
            [-64.0796693, 46.7395476],
            [-64.0797236, 46.7393052],
            [-64.0791953, 46.7375509],
            [-64.0790563, 46.7373694],
            [-64.0788316, 46.7371467],
            [-64.0787958, 46.7370026],
            [-64.0788946, 46.7368168],
            [-64.0792649, 46.7364876],
            [-64.0794767, 46.7360404],
            [-64.0797022, 46.7358804],
            [-64.079853, 46.7357034],
            [-64.079938, 46.7352303],
            [-64.0800712, 46.735057],
            [-64.0802308, 46.7349637],
            [-64.0806304, 46.7349779],
            [-64.0813382, 46.7351443],
            [-64.0814508, 46.7352457],
            [-64.0815486, 46.7352711],
            [-64.0822602, 46.735318],
            [-64.0829734, 46.7354964],
            [-64.0835233, 46.7355248],
            [-64.0841162, 46.7354781],
            [-64.0848599, 46.7352544],
            [-64.08553, 46.7349737],
            [-64.0860276, 46.7346505],
            [-64.0863042, 46.7343478],
            [-64.0863606, 46.7342171],
            [-64.0865001, 46.7336731],
            [-64.0865567, 46.7331796],
            [-64.0866534, 46.7328821],
            [-64.0867939, 46.7326611],
            [-64.0869917, 46.7324608],
            [-64.0876339, 46.7319684],
            [-64.0878996, 46.7316416],
            [-64.0879831, 46.7315711],
            [-64.0884642, 46.7315745],
            [-64.0886966, 46.7315581],
            [-64.088768, 46.7315034],
            [-64.0888142, 46.7313287],
            [-64.088856, 46.7312935],
            [-64.0889611, 46.7312712],
            [-64.0892199, 46.731335],
            [-64.0892705, 46.7313836],
            [-64.0893267, 46.7316157],
            [-64.0894206, 46.7317606],
            [-64.089449, 46.7323152],
            [-64.0895606, 46.7326278],
            [-64.0898307, 46.7330585],
            [-64.0905739, 46.7335602],
            [-64.0910081, 46.7337583],
            [-64.0918614, 46.7339069],
            [-64.0922648, 46.7341642],
            [-64.0924488, 46.7342109],
            [-64.0926529, 46.7341741],
            [-64.0927915, 46.7340128],
            [-64.0929167, 46.7339071],
            [-64.0929982, 46.7338964],
            [-64.0931364, 46.7339264],
            [-64.0932328, 46.7339917],
            [-64.0933661, 46.7343525],
            [-64.09358, 46.7345511],
            [-64.0935465, 46.7346901],
            [-64.0934031, 46.7348195],
            [-64.0933413, 46.7349381],
            [-64.0933677, 46.7350183],
            [-64.0936262, 46.7352734],
            [-64.0936866, 46.7355573],
            [-64.0938027, 46.7357305],
            [-64.0939229, 46.7354214],
            [-64.0942634, 46.7351117],
            [-64.0943492, 46.7347901],
            [-64.0946298, 46.7345393],
            [-64.0949085, 46.7343482],
            [-64.0950028, 46.7343018],
            [-64.0951247, 46.7342957],
            [-64.0955055, 46.7343533],
            [-64.0958991, 46.7343753],
            [-64.0962825, 46.7343532],
            [-64.0968315, 46.7342301],
            [-64.0970153, 46.7341053],
            [-64.0973293, 46.7337154],
            [-64.0974424, 46.7336255],
            [-64.0976647, 46.7335651],
            [-64.0987811, 46.7334665],
            [-64.0992858, 46.7334583],
            [-64.1000738, 46.7336536],
            [-64.1003766, 46.7337938],
            [-64.1010754, 46.734239],
            [-64.1015329, 46.7346167],
            [-64.1018821, 46.7351163],
            [-64.1020769, 46.735187],
            [-64.1024536, 46.7351928],
            [-64.1020576, 46.7348877],
            [-64.1019528, 46.7347187],
            [-64.1018586, 46.7344024],
            [-64.1018861, 46.7342712],
            [-64.1018189, 46.7340151],
            [-64.1012787, 46.7336879],
            [-64.1010424, 46.7334611],
            [-64.1009511, 46.7332365],
            [-64.1010056, 46.7331655],
            [-64.1013549, 46.7329396],
            [-64.1013534, 46.7328081],
            [-64.1009417, 46.7328098],
            [-64.1004344, 46.7328977],
            [-64.0986086, 46.7330612],
            [-64.0976298, 46.7332098],
            [-64.0968942, 46.733366],
            [-64.095858, 46.7334936],
            [-64.0952031, 46.7334876],
            [-64.0948047, 46.7334337],
            [-64.0937073, 46.7331259],
            [-64.0930839, 46.7330406],
            [-64.0923956, 46.7328108],
            [-64.0921979, 46.7326483],
            [-64.0919574, 46.7321982],
            [-64.0915636, 46.7320048],
            [-64.0913653, 46.7318622],
            [-64.0913428, 46.7316625],
            [-64.0914833, 46.7314414],
            [-64.0917477, 46.7311545],
            [-64.0918653, 46.7309251],
            [-64.0919864, 46.7307675],
            [-64.0921338, 46.73069],
            [-64.0923676, 46.7306338],
            [-64.0932097, 46.7305869],
            [-64.0935243, 46.7305399],
            [-64.0939695, 46.7303992],
            [-64.0943134, 46.7301613],
            [-64.0946208, 46.7297992],
            [-64.0949381, 46.7293098],
            [-64.095619, 46.7286903],
            [-64.0959496, 46.728508],
            [-64.0963584, 46.7284146],
            [-64.0978325, 46.7283654],
            [-64.0982299, 46.7282678],
            [-64.098499, 46.7280128],
            [-64.0985984, 46.727807],
            [-64.0987315, 46.7276336],
            [-64.0992604, 46.7272311],
            [-64.0998145, 46.7269485],
            [-64.1003001, 46.7264497],
            [-64.1005393, 46.7260427],
            [-64.10077, 46.7249978],
            [-64.1007822, 46.7246193],
            [-64.1005749, 46.7247557],
            [-64.1003667, 46.7251033],
            [-64.1001206, 46.7262636],
            [-64.10004, 46.7264258],
            [-64.0999069, 46.7265992],
            [-64.0993866, 46.7269141],
            [-64.0991806, 46.7270106],
            [-64.0988064, 46.727288],
            [-64.0982336, 46.7276141],
            [-64.0974828, 46.7278857],
            [-64.0970227, 46.7279504],
            [-64.0958221, 46.7279679],
            [-64.0955945, 46.7280163],
            [-64.0951897, 46.7281616],
            [-64.0947853, 46.7284783],
            [-64.0946031, 46.7287346],
            [-64.0942533, 46.7291518],
            [-64.093616, 46.7296762],
            [-64.0933103, 46.7298071],
            [-64.0930531, 46.7298749],
            [-64.092287, 46.7298991],
            [-64.0918189, 46.7300314],
            [-64.0912546, 46.7302699],
            [-64.0906983, 46.7304408],
            [-64.0904248, 46.7304725],
            [-64.0899975, 46.7304181],
            [-64.0895865, 46.7303998],
            [-64.0893015, 46.7304273],
            [-64.0890974, 46.730464],
            [-64.0887863, 46.7305828],
            [-64.0884125, 46.7306688],
            [-64.0882507, 46.7306504],
            [-64.0881435, 46.730561],
            [-64.0880888, 46.7304605],
            [-64.0881512, 46.7303219],
            [-64.0883579, 46.7302055],
            [-64.0883424, 46.7301495],
            [-64.0882392, 46.730112],
            [-64.0881409, 46.7301065],
            [-64.0878144, 46.7303407],
            [-64.0877329, 46.7303514],
            [-64.0873143, 46.7302094],
            [-64.087135, 46.7301947],
            [-64.0868959, 46.7302389],
            [-64.0865471, 46.7304448],
            [-64.0864624, 46.7305551],
            [-64.0859348, 46.7316233],
            [-64.0848829, 46.7325918],
            [-64.0844032, 46.7332541],
            [-64.0840612, 46.7334322],
            [-64.0832083, 46.7336264],
            [-64.082953, 46.7336344],
            [-64.0821153, 46.7335418],
            [-64.0815916, 46.733422],
            [-64.0801368, 46.7328734],
            [-64.079663, 46.7328222],
            [-64.0793039, 46.7328127],
            [-64.0786154, 46.7329455],
            [-64.0781278, 46.7331413],
            [-64.0776853, 46.7333736],
            [-64.0771747, 46.7335611],
            [-64.0768089, 46.7335793],
            [-64.0765224, 46.7334752],
            [-64.0763073, 46.7333164],
            [-64.0761224, 46.7331182],
            [-64.0761383, 46.7328115],
            [-64.0761018, 46.7326874],
            [-64.0759729, 46.7325498],
            [-64.0759492, 46.73239],
            [-64.0760537, 46.7318534],
            [-64.0761405, 46.7316834],
            [-64.076267, 46.7315378],
            [-64.0765747, 46.7313473],
            [-64.0768273, 46.7312475],
            [-64.0771013, 46.7310245],
            [-64.0773455, 46.7306496],
            [-64.0774569, 46.7302567],
            [-64.0775036, 46.7298907],
            [-64.0774912, 46.7295636],
            [-64.0773572, 46.7290512],
            [-64.0772701, 46.7288785],
            [-64.0770213, 46.7286873],
            [-64.0766564, 46.7283228],
            [-64.0762656, 46.7276869],
            [-64.0761361, 46.7275693],
            [-64.0754897, 46.7273042],
            [-64.0751102, 46.7270352],
            [-64.0750157, 46.7269101],
            [-64.0748251, 46.7263571],
            [-64.0746619, 46.7255015],
            [-64.0747173, 46.7243423],
            [-64.0747844, 46.7240643],
            [-64.0749665, 46.7236366],
            [-64.0748187, 46.7233712],
            [-64.0748261, 46.7233235],
            [-64.0750402, 46.7231594],
            [-64.0751252, 46.7226863],
            [-64.0753458, 46.722323],
            [-64.0752851, 46.7222304],
            [-64.0749622, 46.7220021],
            [-64.0749203, 46.7218659],
            [-64.0749458, 46.7217946],
            [-64.0750461, 46.7217403],
            [-64.0751801, 46.7217185],
            [-64.0753418, 46.7217369],
            [-64.0754389, 46.7217823],
            [-64.0759294, 46.7218497],
            [-64.0761284, 46.7221438],
            [-64.0763279, 46.7222465],
            [-64.0767045, 46.7222524],
            [-64.0769335, 46.7221642],
            [-64.0770695, 46.7220826],
            [-64.0769508, 46.7219891],
            [-64.076692, 46.7219253],
            [-64.0766023, 46.7218322],
            [-64.0765261, 46.7216836],
            [-64.0762827, 46.7215044],
            [-64.0762684, 46.7214085],
            [-64.0763093, 46.7212218],
            [-64.0766006, 46.7208237],
            [-64.076701, 46.7202353],
            [-64.0765982, 46.719835],
            [-64.0763902, 46.7199913],
            [-64.0762274, 46.7201841],
            [-64.0761253, 46.7204695],
            [-64.0757126, 46.7206823],
            [-64.0756899, 46.7208454],
            [-64.0752117, 46.7209337],
            [-64.0749948, 46.7210061],
            [-64.0747834, 46.7210905],
            [-64.0746232, 46.7212036],
            [-64.0745795, 46.7212986],
            [-64.0745541, 46.7215414],
            [-64.0744659, 46.7215799],
            [-64.074218, 46.7215401],
            [-64.0732153, 46.7211857],
            [-64.0728069, 46.7210877],
            [-64.07188, 46.7210733],
            [-64.0712501, 46.7210157],
            [-64.0687256, 46.7205817],
            [-64.068384, 46.7205685],
            [-64.0679482, 46.7206015],
            [-64.0674875, 46.720686],
            [-64.0669717, 46.7208614],
            [-64.0666403, 46.7208921],
            [-64.0665304, 46.7208824],
            [-64.0663869, 46.7208403],
            [-64.0663087, 46.7207514],
            [-64.066223, 46.7205387],
            [-64.066327, 46.7200221],
            [-64.0659898, 46.7195266],
            [-64.0659269, 46.7193223],
            [-64.0659955, 46.7191759],
            [-64.0662317, 46.7188686],
            [-64.0661285, 46.7186597],
            [-64.0661067, 46.7184402],
            [-64.0662323, 46.7179717],
            [-64.0663754, 46.7166226],
            [-64.0663008, 46.7160714],
            [-64.0664282, 46.7153718],
            [-64.0662595, 46.7150383],
            [-64.0658377, 46.7148244],
            [-64.0657723, 46.7146998],
            [-64.0659299, 46.7146664],
            [-64.0660747, 46.7146687],
            [-64.0663502, 46.7147487],
            [-64.0666042, 46.7147806],
            [-64.0675935, 46.7146565],
            [-64.0682523, 46.7147146],
            [-64.0687926, 46.7148506],
            [-64.0697022, 46.7152116],
            [-64.0701016, 46.7152257],
            [-64.0705375, 46.7153641],
            [-64.0710718, 46.7155079],
            [-64.0713608, 46.7155323],
            [-64.0717447, 46.7154905],
            [-64.0735561, 46.7150403],
            [-64.0741311, 46.714826],
            [-64.0747699, 46.7144333],
            [-64.0748533, 46.7143628],
            [-64.0753602, 46.7141036],
            [-64.0760133, 46.7138068],
            [-64.0765113, 46.7134637],
            [-64.0768747, 46.7131624],
            [-64.07736, 46.712855],
            [-64.078056, 46.7124832],
            [-64.0781522, 46.712377],
            [-64.078257, 46.7121833],
            [-64.0786952, 46.7118991],
            [-64.0788296, 46.7116859],
            [-64.0791655, 46.7115157],
            [-64.0794569, 46.7112891],
            [-64.0795423, 46.7111588],
            [-64.0795635, 46.7108642],
            [-64.0795456, 46.7105251],
            [-64.0794503, 46.7102486],
            [-64.0795229, 46.710154],
            [-64.0801151, 46.7102987],
            [-64.0809396, 46.7104271],
            [-64.0817844, 46.7106434],
            [-64.0825754, 46.7109107],
            [-64.0828232, 46.7109504],
            [-64.0836039, 46.7110023],
            [-64.0845712, 46.7111926],
            [-64.0854993, 46.7111671],
            [-64.0863391, 46.71118],
            [-64.0868293, 46.7110759],
            [-64.0869103, 46.7107224],
            [-64.0869527, 46.7106672],
            [-64.0872064, 46.7105276],
            [-64.0874871, 46.7104483],
            [-64.0876129, 46.7103226],
            [-64.0878018, 46.7100385],
            [-64.0879444, 46.7099291],
            [-64.0883207, 46.7097635],
            [-64.0894345, 46.7091907],
            [-64.0895987, 46.7091294],
            [-64.0903225, 46.7089691],
            [-64.0905049, 46.7088842],
            [-64.0907248, 46.7087122],
            [-64.0908631, 46.7083795],
            [-64.0909405, 46.7083169],
            [-64.0910805, 46.7082872],
            [-64.09142, 46.7083602],
            [-64.091519, 46.7083457],
            [-64.0916018, 46.7082952],
            [-64.0916478, 46.7079491],
            [-64.0917955, 46.7076803],
            [-64.0926097, 46.7066963],
            [-64.0931443, 46.7057518],
            [-64.0936484, 46.7050381],
            [-64.0936988, 46.7045526],
            [-64.0937794, 46.7043904],
            [-64.0940465, 46.7041952],
            [-64.0948023, 46.7039357],
            [-64.0952218, 46.7035036],
            [-64.0953413, 46.7032145],
            [-64.0957105, 46.7029053],
            [-64.0957299, 46.7028418],
            [-64.0957096, 46.7027538],
            [-64.0953733, 46.7025812],
            [-64.0953024, 46.7024446],
            [-64.0953231, 46.7023413],
            [-64.0955972, 46.701927],
            [-64.0955712, 46.7016555],
            [-64.0956639, 46.7014776],
            [-64.0958112, 46.7014001],
            [-64.0959011, 46.7011304],
            [-64.0957275, 46.7007651],
            [-64.0959516, 46.7002822],
            [-64.0958522, 46.6999539],
            [-64.0958554, 46.6998543],
            [-64.0956748, 46.6993453],
            [-64.0957089, 46.6991864],
            [-64.0957856, 46.6991437],
            [-64.0960294, 46.6991315],
            [-64.0962886, 46.6991753],
            [-64.0964745, 46.6991622],
            [-64.0965827, 46.6990403],
            [-64.0966174, 46.6988615],
            [-64.096586, 46.698578],
            [-64.0964954, 46.6983335],
            [-64.0962592, 46.6979352],
            [-64.0961502, 46.697543],
            [-64.0960779, 46.6969121],
            [-64.0958788, 46.6966181],
            [-64.0954918, 46.6962255],
            [-64.0954276, 46.6960611],
            [-64.0954696, 46.6958346],
            [-64.0955766, 46.6957525],
            [-64.0960077, 46.6954961],
            [-64.0963059, 46.6954129],
            [-64.0966249, 46.6953979],
            [-64.0969084, 46.6954102],
            [-64.0980674, 46.6952166],
            [-64.0990094, 46.6952868],
            [-64.0992296, 46.6952862],
            [-64.0997902, 46.6951473],
            [-64.0999683, 46.6950105],
            [-64.1000549, 46.6948404],
            [-64.0999993, 46.6945884],
            [-64.0997875, 46.6943301],
            [-64.0993979, 46.6940173],
            [-64.0994529, 46.6939264],
            [-64.0996333, 46.6939013],
            [-64.1000265, 46.6939232],
            [-64.1005145, 46.6938789],
            [-64.1011604, 46.6936097],
            [-64.1013764, 46.6935572],
            [-64.1015441, 46.6935677],
            [-64.1017556, 46.6936546],
            [-64.1022929, 46.6936987],
            [-64.1027179, 46.6938128],
            [-64.1036223, 46.69397],
            [-64.1037597, 46.6940199],
            [-64.1038885, 46.6941574],
            [-64.1039741, 46.6941986],
            [-64.1045127, 46.6942028],
            [-64.1050818, 46.6947017],
            [-64.1052509, 46.6952064],
            [-64.105444, 46.6955083],
            [-64.1054013, 46.6957548],
            [-64.1049361, 46.6961702],
            [-64.1049202, 46.6963055],
            [-64.1049568, 46.6964296],
            [-64.1050134, 46.6964703],
            [-64.1053516, 46.6965831],
            [-64.1056681, 46.6966477],
            [-64.1063492, 46.6969051],
            [-64.1064402, 46.6969583],
            [-64.106501, 46.6970509],
            [-64.1063702, 46.6973359],
            [-64.1064135, 46.6974322],
            [-64.1068035, 46.6975538],
            [-64.1073294, 46.6975936],
            [-64.1076081, 46.6975739],
            [-64.1077312, 46.697528],
            [-64.10782, 46.6974695],
            [-64.1078541, 46.6973106],
            [-64.1075948, 46.6965414],
            [-64.107612, 46.6963663],
            [-64.1076944, 46.6961443],
            [-64.1080186, 46.6959699],
            [-64.1083546, 46.695971],
            [-64.1090844, 46.6963368],
            [-64.1095317, 46.6964791],
            [-64.1110527, 46.6965579],
            [-64.1113572, 46.6966382],
            [-64.112298, 46.6971108],
            [-64.1131506, 46.6976219],
            [-64.1135757, 46.697736],
            [-64.1147688, 46.697746],
            [-64.1155387, 46.6979449],
            [-64.1158642, 46.6980933],
            [-64.1161397, 46.6981732],
            [-64.1165222, 46.698171],
            [-64.1170846, 46.6979722],
            [-64.1172217, 46.6978507],
            [-64.1172589, 46.6975922],
            [-64.1172513, 46.6974685],
            [-64.1169203, 46.6969454],
            [-64.1167555, 46.6966639],
            [-64.1166072, 46.6962272],
            [-64.116611, 46.6961077],
            [-64.1167156, 46.695914],
            [-64.1169234, 46.6957577],
            [-64.1169934, 46.6957428],
            [-64.1179389, 46.6958846],
            [-64.1180588, 46.6959382],
            [-64.1183003, 46.696177],
            [-64.1184311, 46.6962547],
            [-64.1190725, 46.6964875],
            [-64.1191912, 46.696581],
            [-64.1192711, 46.6968014],
            [-64.1204156, 46.6974284],
            [-64.1207637, 46.6977765],
            [-64.1212223, 46.6981142],
            [-64.1212195, 46.6983852],
            [-64.121285, 46.6985097],
            [-64.1215212, 46.6987365],
            [-64.1215746, 46.6988768],
            [-64.1215425, 46.6989759],
            [-64.1214188, 46.6990418],
            [-64.1211515, 46.6990657],
            [-64.1210055, 46.6991034],
            [-64.1207978, 46.6992597],
            [-64.1204527, 46.7000915],
            [-64.1198871, 46.7020321],
            [-64.119737, 46.7023806],
            [-64.1194704, 46.7027473],
            [-64.118709, 46.7031863],
            [-64.1186661, 46.7032614],
            [-64.1186804, 46.7033572],
            [-64.1191657, 46.7032211],
            [-64.1195516, 46.7029279],
            [-64.1199775, 46.702468],
            [-64.1201964, 46.7021444],
            [-64.1203496, 46.7013336],
            [-64.1210917, 46.7004041],
            [-64.121746, 46.6996845],
            [-64.1224777, 46.699265],
            [-64.1226208, 46.6991356],
            [-64.1226647, 46.6988493],
            [-64.1229204, 46.6984585],
            [-64.1238199, 46.6978582],
            [-64.1240843, 46.6977426],
            [-64.1242182, 46.6977207],
            [-64.1248501, 46.697811],
            [-64.1257754, 46.6979433],
            [-64.1260729, 46.69788],
            [-64.1262281, 46.6977348],
            [-64.12644, 46.6976304],
            [-64.1277955, 46.6974474],
            [-64.1284258, 46.6974847],
            [-64.1294631, 46.6976596],
            [-64.1299829, 46.6977072],
            [-64.1305818, 46.6978238],
            [-64.1309861, 46.6976783],
            [-64.1312415, 46.6974789],
            [-64.13176, 46.6966496],
            [-64.1318531, 46.6964517],
            [-64.1319602, 46.6961782],
            [-64.1322022, 46.6960344],
            [-64.1323984, 46.6958739],
            [-64.1324324, 46.695715],
            [-64.1324614, 46.6951614],
            [-64.1325122, 46.6950186],
            [-64.1328078, 46.694461],
            [-64.132938, 46.6940046],
            [-64.1330395, 46.6939105],
            [-64.1332411, 46.693762],
            [-64.1339675, 46.6935018],
            [-64.1343154, 46.6931243],
            [-64.1344331, 46.6930663],
            [-64.1351672, 46.6929298],
            [-64.1354103, 46.6929374],
            [-64.1359457, 46.693041],
            [-64.1370541, 46.6931611],
            [-64.1373996, 46.693226],
            [-64.1375539, 46.693292],
            [-64.137951, 46.693557],
            [-64.1381934, 46.6935845],
            [-64.138606, 46.6935428],
            [-64.1389299, 46.6935596],
            [-64.1391301, 46.6936422],
            [-64.1392543, 46.6937477],
            [-64.1393734, 46.6940125],
            [-64.1392568, 46.6942221],
            [-64.139256, 46.6944333],
            [-64.139362, 46.6945624],
            [-64.1395947, 46.6947173],
            [-64.1396838, 46.6948302],
            [-64.139703, 46.6949581],
            [-64.1396345, 46.6952958],
            [-64.1396926, 46.6956594],
            [-64.1396412, 46.695822],
            [-64.1395096, 46.6959556],
            [-64.1395154, 46.696139],
            [-64.139725, 46.696477],
            [-64.139811, 46.6966895],
            [-64.1398899, 46.6967584],
            [-64.1403471, 46.6967731],
            [-64.1406248, 46.6971559],
            [-64.1407158, 46.6972091],
            [-64.1409589, 46.6972167],
            [-64.1410712, 46.6971466],
            [-64.1411707, 46.6971122],
            [-64.1411328, 46.6970279],
            [-64.1409243, 46.6968415],
            [-64.1406452, 46.6966899],
            [-64.140556, 46.696577],
            [-64.1405099, 46.6961976],
            [-64.1406286, 46.695737],
            [-64.1405686, 46.6954332],
            [-64.1404978, 46.6951053],
            [-64.1405221, 46.6948825],
            [-64.1409236, 46.694454],
            [-64.1409846, 46.6943553],
            [-64.1410107, 46.694264],
            [-64.1409794, 46.6941519],
            [-64.1407299, 46.6939808],
            [-64.1406516, 46.693892],
            [-64.1406439, 46.6937683],
            [-64.1406699, 46.693677],
            [-64.1407054, 46.6936496],
            [-64.1410661, 46.6935992],
            [-64.1412744, 46.6934229],
            [-64.1419211, 46.6933049],
            [-64.1424995, 46.6933334],
            [-64.142752, 46.6934049],
            [-64.1428997, 46.6934988],
            [-64.1432015, 46.6940413],
            [-64.1433714, 46.6941634],
            [-64.1438857, 46.6943902],
            [-64.1441817, 46.694558],
            [-64.1445549, 46.6946631],
            [-64.1447165, 46.6946815],
            [-64.1452118, 46.6945891],
            [-64.1455931, 46.6944353],
            [-64.1463037, 46.6943103],
            [-64.1465915, 46.694183],
            [-64.1478325, 46.6937668],
            [-64.1497435, 46.692667],
            [-64.1505296, 46.6923478],
            [-64.1506641, 46.6923059],
            [-64.1509421, 46.692306],
            [-64.1513045, 46.692387],
            [-64.1517043, 46.6925723],
            [-64.1515925, 46.6920684],
            [-64.1512355, 46.6919994],
            [-64.1510443, 46.6920006],
            [-64.1507469, 46.692064],
            [-64.1503059, 46.6922767],
            [-64.1498107, 46.6923691],
            [-64.1491089, 46.6927693],
            [-64.1486214, 46.6929853],
            [-64.1478734, 46.6931975],
            [-64.1473906, 46.6934455],
            [-64.1470334, 46.6935677],
            [-64.1467892, 46.6936],
            [-64.1455612, 46.6935978],
            [-64.1452965, 46.6935421],
            [-64.1444178, 46.6934853],
            [-64.144217, 46.6934226],
            [-64.1436645, 46.6929401],
            [-64.1435116, 46.6926429],
            [-64.1433753, 46.6925532],
            [-64.1432662, 46.6925237],
            [-64.1431962, 46.6925386],
            [-64.1430936, 46.6926726],
            [-64.1429813, 46.6927427],
            [-64.1427835, 46.6927716],
            [-64.142298, 46.6927366],
            [-64.1421008, 46.6927456],
            [-64.1418371, 46.6928413],
            [-64.1413581, 46.6929698],
            [-64.1408117, 46.6930334],
            [-64.1405807, 46.6930101],
            [-64.1404203, 46.6929519],
            [-64.140136, 46.6925969],
            [-64.1396857, 46.6923631],
            [-64.1394206, 46.692136],
            [-64.1392002, 46.691774],
            [-64.1389917, 46.6915875],
            [-64.1388918, 46.6914506],
            [-64.1386929, 46.691328],
            [-64.1383492, 46.6912034],
            [-64.1378725, 46.6908894],
            [-64.1373927, 46.690675],
            [-64.1370241, 46.6902391],
            [-64.1363377, 46.6897785],
            [-64.1358958, 46.6896484],
            [-64.1356975, 46.689506],
            [-64.1356192, 46.6894171],
            [-64.1355717, 46.6892689],
            [-64.1356263, 46.6890066],
            [-64.1356107, 46.6889506],
            [-64.1353046, 46.6887388],
            [-64.1348175, 46.6885721],
            [-64.1345661, 46.6884607],
            [-64.1344191, 46.6883469],
            [-64.1343329, 46.6883257],
            [-64.1343069, 46.688417],
            [-64.1343381, 46.6885291],
            [-64.1344429, 46.688698],
            [-64.134847, 46.6891067],
            [-64.1349216, 46.6893151],
            [-64.1347183, 46.689886],
            [-64.1346115, 46.6899681],
            [-64.1342531, 46.6901302],
            [-64.1334105, 46.6902173],
            [-64.1329365, 46.6901863],
            [-64.1322588, 46.6900008],
            [-64.1323219, 46.690205],
            [-64.1325587, 46.6904118],
            [-64.1326155, 46.6908152],
            [-64.1328283, 46.6908622],
            [-64.1329797, 46.6908366],
            [-64.1331916, 46.6907321],
            [-64.1333019, 46.6907218],
            [-64.1334683, 46.6907721],
            [-64.1336147, 46.6909058],
            [-64.1336532, 46.6909702],
            [-64.1336957, 46.6912777],
            [-64.1338342, 46.6914791],
            [-64.1338196, 46.6915745],
            [-64.1337259, 46.6917923],
            [-64.1332893, 46.6922282],
            [-64.1332579, 46.6923075],
            [-64.1332316, 46.6925901],
            [-64.1331308, 46.6926643],
            [-64.1326839, 46.6926935],
            [-64.1325347, 46.6928308],
            [-64.1324289, 46.6930644],
            [-64.1320088, 46.6933451],
            [-64.1316153, 46.6935146],
            [-64.1314475, 46.6936954],
            [-64.1311986, 46.6940584],
            [-64.1308857, 46.6944284],
            [-64.1307871, 46.6946143],
            [-64.1307598, 46.6947454],
            [-64.1308058, 46.6951247],
            [-64.1307912, 46.6952202],
            [-64.1307381, 46.6952513],
            [-64.1304878, 46.6952914],
            [-64.1298419, 46.695198],
            [-64.1296501, 46.6952191],
            [-64.1292995, 46.6953135],
            [-64.1288532, 46.6953228],
            [-64.1284552, 46.695269],
            [-64.1280582, 46.6953667],
            [-64.127449, 46.6953975],
            [-64.1270386, 46.6955508],
            [-64.1267714, 46.6955747],
            [-64.1258679, 46.6955691],
            [-64.1254963, 46.6955954],
            [-64.1249008, 46.695742],
            [-64.1248501, 46.6957754],
            [-64.1246049, 46.6959368],
            [-64.1239318, 46.6963373],
            [-64.1236814, 46.6963774],
            [-64.12307, 46.6962965],
            [-64.1227388, 46.6963274],
            [-64.1225018, 46.6963119],
            [-64.1223987, 46.6962744],
            [-64.1222685, 46.6961768],
            [-64.1221126, 46.6959792],
            [-64.1219306, 46.6958728],
            [-64.1216894, 46.6958054],
            [-64.1215255, 46.6956754],
            [-64.1215302, 46.6953447],
            [-64.1210017, 46.6946591],
            [-64.1206139, 46.6946493],
            [-64.120547, 46.6945646],
            [-64.1205896, 46.6943182],
            [-64.1205645, 46.6941982],
            [-64.1201212, 46.6937451],
            [-64.1200665, 46.6936447],
            [-64.1201138, 46.6934302],
            [-64.1205232, 46.6931254],
            [-64.1206968, 46.6927653],
            [-64.120706, 46.6926578],
            [-64.1206513, 46.6925574],
            [-64.1206121, 46.6925129],
            [-64.120497, 46.6924913],
            [-64.1202597, 46.6926671],
            [-64.1201372, 46.6926931],
            [-64.120045, 46.6926798],
            [-64.1199257, 46.6926063],
            [-64.1196779, 46.6925667],
            [-64.1194348, 46.692559],
            [-64.1191886, 46.692651],
            [-64.1188705, 46.6928176],
            [-64.1184252, 46.6929783],
            [-64.1180715, 46.6931722],
            [-64.1173631, 46.6937714],
            [-64.1166782, 46.6939962],
            [-64.1160522, 46.6943734],
            [-64.1153806, 46.6945427],
            [-64.1151328, 46.6945031],
            [-64.1148598, 46.6943435],
            [-64.1142274, 46.6941945],
            [-64.1137979, 46.6938571],
            [-64.1134145, 46.6937079],
            [-64.1130293, 46.6936184],
            [-64.1126412, 46.6934371],
            [-64.112275, 46.6931127],
            [-64.112345, 46.6927351],
            [-64.1123144, 46.6926031],
            [-64.1121611, 46.6923258],
            [-64.1122126, 46.6921632],
            [-64.1123993, 46.6919388],
            [-64.1129096, 46.6915599],
            [-64.1131017, 46.6913476],
            [-64.1131399, 46.6912404],
            [-64.1130457, 46.6909241],
            [-64.1131154, 46.6907379],
            [-64.1134131, 46.6904833],
            [-64.1135216, 46.6901702],
            [-64.113471, 46.6901216],
            [-64.113157, 46.6899772],
            [-64.1129655, 46.6898069],
            [-64.1129681, 46.6897273],
            [-64.1130997, 46.6888684],
            [-64.1132387, 46.6883244],
            [-64.1135859, 46.6875499],
            [-64.1135958, 46.6873702],
            [-64.1141462, 46.6869794],
            [-64.1156365, 46.6869649],
            [-64.1163765, 46.6867013],
            [-64.1174944, 46.6866903],
            [-64.1184027, 46.6855277],
            [-64.1183868, 46.6847585],
            [-64.1172321, 46.6829744],
            [-64.1172004, 46.681436],
            [-64.1175677, 46.6811758],
            [-64.1178715, 46.6778385],
            [-64.1186062, 46.6773184],
            [-64.1187811, 46.6768037],
            [-64.121381, 46.676393],
            [-64.1215652, 46.6762635],
            [-64.1217404, 46.6757488],
            [-64.1206307, 46.676144],
            [-64.1187652, 46.6760345],
            [-64.1191219, 46.6752614],
            [-64.1187493, 46.6752651],
            [-64.1180412, 46.6770673],
            [-64.1173042, 46.6774591],
            [-64.1161891, 46.6775985],
            [-64.1164076, 46.6791352],
            [-64.1168173, 46.6809265],
            [-64.1164501, 46.6811867],
            [-64.1162787, 46.6819577],
            [-64.1155335, 46.6819651],
            [-64.1161459, 46.684524],
            [-64.11672, 46.6852877],
            [-64.1159829, 46.6856792],
            [-64.1137444, 46.6855733],
            [-64.1137338, 46.6850603],
            [-64.1126159, 46.6850714],
            [-64.1122222, 46.6840492],
            [-64.111477, 46.6840564],
            [-64.1120578, 46.6850766],
            [-64.1124357, 46.6853296],
            [-64.1124462, 46.6858422],
            [-64.1120842, 46.6863588],
            [-64.1119223, 46.6875499],
            [-64.1118124, 46.6876452],
            [-64.1112984, 46.6877809],
            [-64.111048, 46.687821],
            [-64.110805, 46.6878133],
            [-64.1106056, 46.6877106],
            [-64.1103072, 46.6876224],
            [-64.1100413, 46.6876064],
            [-64.1100334, 46.6876741],
            [-64.110082, 46.6877824],
            [-64.1102567, 46.6879365],
            [-64.1104621, 46.6880313],
            [-64.1109367, 46.6884052],
            [-64.1110811, 46.6885988],
            [-64.1109987, 46.6888207],
            [-64.1108422, 46.6890057],
            [-64.1106332, 46.6892018],
            [-64.1106498, 46.6894093],
            [-64.110931, 46.6896725],
            [-64.1110973, 46.6900857],
            [-64.1112402, 46.6901476],
            [-64.1114994, 46.6901914],
            [-64.1115506, 46.6902201],
            [-64.1115462, 46.6903594],
            [-64.1110617, 46.6908383],
            [-64.1109539, 46.6911317],
            [-64.1108362, 46.6911897],
            [-64.1106612, 46.6912269],
            [-64.1105575, 46.6912094],
            [-64.1100018, 46.6910176],
            [-64.1098052, 46.6910067],
            [-64.1087061, 46.6911416],
            [-64.1079427, 46.691106],
            [-64.1071197, 46.6913009],
            [-64.1059536, 46.6911794],
            [-64.1058142, 46.6911893],
            [-64.1057315, 46.6912398],
            [-64.1056326, 46.6912543],
            [-64.1049538, 46.6911084],
            [-64.1045081, 46.6910977],
            [-64.1031908, 46.6911733],
            [-64.1026732, 46.6912372],
            [-64.1012848, 46.6910048],
            [-64.0999087, 46.690928],
            [-64.0994595, 46.6908454],
            [-64.0993247, 46.6907158],
            [-64.0992354, 46.6904315],
            [-64.0989832, 46.6901687],
            [-64.0987142, 46.6898895],
            [-64.098351, 46.6896567],
            [-64.0982075, 46.6896148],
            [-64.0976359, 46.6895582],
            [-64.0976538, 46.6893632],
            [-64.0977712, 46.6891338],
            [-64.0977287, 46.6890176],
            [-64.0973372, 46.6887645],
            [-64.0970347, 46.6886244],
            [-64.0967752, 46.688409],
            [-64.0964178, 46.687997],
            [-64.0962128, 46.6877109],
            [-64.096218, 46.6875499],
            [-64.0947973, 46.6875499],
            [-64.0947327, 46.6878079],
            [-64.0948406, 46.6884113],
            [-64.0950816, 46.6888416],
            [-64.0952291, 46.6889355],
            [-64.0954931, 46.6890113],
            [-64.0957603, 46.6889875],
            [-64.0958755, 46.6890092],
            [-64.0959934, 46.6891226],
            [-64.0960583, 46.6892671],
            [-64.0961708, 46.6893684],
            [-64.0967211, 46.6895482],
            [-64.0970912, 46.6899245],
            [-64.0973978, 46.6901166],
            [-64.0976102, 46.6903549],
            [-64.0978466, 46.6903904],
            [-64.0981535, 46.6903911],
            [-64.0982553, 46.6904684],
            [-64.0982072, 46.6907028],
            [-64.0978129, 46.6912548],
            [-64.0968798, 46.692528],
            [-64.09686, 46.6927828],
            [-64.0968284, 46.692862],
            [-64.0965265, 46.6930647],
            [-64.0956856, 46.6934544],
            [-64.0952458, 46.6937984],
            [-64.0947246, 46.6941531],
            [-64.0945027, 46.6943849],
            [-64.0942243, 46.6945759],
            [-64.0936484, 46.6948302],
            [-64.0934823, 46.6949512],
            [-64.0927212, 46.6951986],
            [-64.0924233, 46.6954531],
            [-64.0923417, 46.6958266],
            [-64.0924094, 46.6960628],
            [-64.0923517, 46.6962333],
            [-64.0919892, 46.6965147],
            [-64.0917607, 46.6967743],
            [-64.0913759, 46.6970274],
            [-64.0911592, 46.6970998],
            [-64.0904644, 46.6970892],
            [-64.0895835, 46.6969202],
            [-64.0893863, 46.6969291],
            [-64.0888189, 46.6970958],
            [-64.0885752, 46.697108],
            [-64.0883617, 46.6970808],
            [-64.0882956, 46.6969761],
            [-64.0880854, 46.6968494],
            [-64.0877237, 46.6967481],
            [-64.0873372, 46.6966983],
            [-64.0867977, 46.6965426],
            [-64.0862833, 46.6965067],
            [-64.0848584, 46.6968555],
            [-64.0847292, 46.6969093],
            [-64.084551, 46.6970461],
            [-64.0843373, 46.6973816],
            [-64.0841536, 46.6975063],
            [-64.0839901, 46.6975476],
            [-64.0833892, 46.6973191],
            [-64.0824472, 46.6972488],
            [-64.0818991, 46.6971805],
            [-64.0811233, 46.6969892],
            [-64.0806828, 46.6968189],
            [-64.080435, 46.6967792],
            [-64.0796364, 46.696751],
            [-64.0791691, 46.6966919],
            [-64.0784391, 46.6965172],
            [-64.0780384, 46.6963715],
            [-64.0775395, 46.6963917],
            [-64.076548, 46.6965955],
            [-64.0763978, 46.6965812],
            [-64.0761857, 46.6965142],
            [-64.0754912, 46.6961407],
            [-64.0747867, 46.6958946],
            [-64.0740386, 46.6957435],
            [-64.0734851, 46.6956631],
            [-64.07325, 46.6955877],
            [-64.0725837, 46.6950632],
            [-64.0721357, 46.6947693],
            [-64.0718393, 46.6946212],
            [-64.0717188, 46.6945874],
            [-64.0712427, 46.6944445],
            [-64.0707617, 46.6940982],
            [-64.0700602, 46.6934097],
            [-64.0697812, 46.6930865],
            [-64.0696589, 46.6925784],
            [-64.0688836, 46.6920242],
            [-64.0688181, 46.6918996],
            [-64.0691461, 46.6912629],
            [-64.0691983, 46.690909],
            [-64.0690165, 46.6900972],
            [-64.0689514, 46.6896099],
            [-64.0690944, 46.6891378],
            [-64.0691858, 46.6889997],
            [-64.0691951, 46.6888923],
            [-64.0690866, 46.6886713],
            [-64.0689795, 46.688582],
            [-64.0690365, 46.6884313],
            [-64.0690836, 46.6884082],
            [-64.0693385, 46.6880574],
            [-64.0694356, 46.6875687],
            [-64.0694366, 46.6875499],
            [-64.0661315, 46.6875499],
            [-64.0660487, 46.6878785],
            [-64.0655473, 46.6888553],
            [-64.0655543, 46.6891703],
            [-64.0658427, 46.6895574],
            [-64.0658995, 46.6897694],
            [-64.0658888, 46.6899168],
            [-64.0657442, 46.6900859],
            [-64.0656878, 46.6902166],
            [-64.0656957, 46.6908544],
            [-64.0659674, 46.6913968],
            [-64.0660752, 46.6914662],
            [-64.0661514, 46.6916149],
            [-64.066273, 46.6921429],
            [-64.0661825, 46.6926039],
            [-64.0663169, 46.6930962],
            [-64.0662599, 46.6934182],
            [-64.0663227, 46.6936225],
            [-64.0665013, 46.6938285],
            [-64.0664055, 46.6944488],
            [-64.0664299, 46.6945887],
            [-64.0667468, 46.6951676],
            [-64.0670169, 46.695407],
            [-64.0671206, 46.6954246],
            [-64.0674795, 46.6954341],
            [-64.0675159, 46.6955583],
            [-64.0674891, 46.6956695],
            [-64.0673304, 46.6959141],
            [-64.0674086, 46.696003],
            [-64.0676579, 46.6961743],
            [-64.0676997, 46.6963105],
            [-64.0676729, 46.6964217],
            [-64.0676985, 46.6965217],
            [-64.068226, 46.6968647],
            [-64.0683062, 46.6968939],
            [-64.0685721, 46.69691],
            [-64.0686294, 46.6969308],
            [-64.0686557, 46.6970109],
            [-64.068652, 46.6974733],
            [-64.0687243, 46.6977414],
            [-64.0689387, 46.6980916],
            [-64.0691692, 46.6983064],
            [-64.069628, 46.6986244],
            [-64.0699708, 46.6987693],
            [-64.0701897, 46.6988086],
            [-64.0707458, 46.6988093],
            [-64.0709148, 46.69878],
            [-64.0715892, 46.6985314],
            [-64.0719777, 46.6985215],
            [-64.072571, 46.6986264],
            [-64.0733053, 46.6990244],
            [-64.0736656, 46.6991655],
            [-64.0738179, 46.6992915],
            [-64.0739583, 46.6996046],
            [-64.0743342, 46.6998017],
            [-64.0744212, 46.6999745],
            [-64.0743285, 46.7001524],
            [-64.0737622, 46.7008132],
            [-64.0733806, 46.7016723],
            [-64.0730981, 46.7019828],
            [-64.0721405, 46.7025618],
            [-64.0711648, 46.7033358],
            [-64.0705425, 46.703936],
            [-64.0696467, 46.7043964],
            [-64.0693438, 46.7046189],
            [-64.068881, 46.7051259],
            [-64.0685909, 46.7053127],
            [-64.0682153, 46.7054583],
            [-64.0680067, 46.7056344],
            [-64.0675929, 46.7060585],
            [-64.067463, 46.7061322],
            [-64.0672704, 46.706173],
            [-64.0664973, 46.7060733],
            [-64.0653387, 46.7057124],
            [-64.0647037, 46.7056427],
            [-64.0638922, 46.7056499],
            [-64.0623035, 46.7055055],
            [-64.0615917, 46.7054784],
            [-64.0608315, 46.7055143],
            [-64.0593185, 46.7057098],
            [-64.0585536, 46.7058852],
            [-64.0582223, 46.7059158],
            [-64.0580317, 46.7058969],
            [-64.0579058, 46.7058511],
            [-64.0571155, 46.7052208],
            [-64.0566877, 46.7050148],
            [-64.0558888, 46.7044721],
            [-64.0555062, 46.7043027],
            [-64.055263, 46.7041235],
            [-64.0549038, 46.7035996],
            [-64.0547905, 46.7033467],
            [-64.0547184, 46.7032499],
            [-64.0544402, 46.7030781],
            [-64.0543365, 46.7030606],
            [-64.0543009, 46.7030879],
            [-64.054221, 46.7035729],
            [-64.0543167, 46.7038296],
            [-64.0543087, 46.7038972],
            [-64.0540664, 46.7040409],
            [-64.0538038, 46.7040965],
            [-64.0513542, 46.7044047],
            [-64.0508801, 46.7043733],
            [-64.0505252, 46.7042441],
            [-64.0502301, 46.7040561],
            [-64.0501122, 46.7039427],
            [-64.0499242, 46.7036726],
            [-64.0495551, 46.7034476],
            [-64.0490009, 46.7032156],
            [-64.0488991, 46.7031383],
            [-64.0488621, 46.703034],
            [-64.0489058, 46.7029391],
            [-64.0490761, 46.7026986],
            [-64.0491283, 46.7021733],
            [-64.0490252, 46.701793],
            [-64.0488419, 46.7015549],
            [-64.0481292, 46.7008621],
            [-64.0478988, 46.7004758],
            [-64.0479068, 46.7004081],
            [-64.0480287, 46.7004021],
            [-64.0489048, 46.7005395],
            [-64.0494293, 46.7004481],
            [-64.0495942, 46.700367],
            [-64.0498917, 46.7001326],
            [-64.0502652, 46.699704],
            [-64.0505579, 46.6992662],
            [-64.0505672, 46.6991587],
            [-64.0505194, 46.6990304],
            [-64.0504298, 46.6989373],
            [-64.0503799, 46.6988687],
            [-64.0497939, 46.6983732],
            [-64.0496996, 46.6982482],
            [-64.0496719, 46.6980365],
            [-64.0497748, 46.6977312],
            [-64.0497444, 46.6975992],
            [-64.0495532, 46.6974287],
            [-64.0494608, 46.6972439],
            [-64.0493967, 46.6969081],
            [-64.0494088, 46.6967209],
            [-64.0494774, 46.6965745],
            [-64.0495777, 46.6965203],
            [-64.0496524, 46.6965375],
            [-64.0500114, 46.6968899],
            [-64.0500862, 46.696907],
            [-64.0503494, 46.6968315],
            [-64.0507996, 46.6965316],
            [-64.0509942, 46.6964311],
            [-64.0512021, 46.6962749],
            [-64.0512775, 46.6961007],
            [-64.0512504, 46.6958691],
            [-64.0511918, 46.6957167],
            [-64.0508845, 46.6952017],
            [-64.0507053, 46.6950155],
            [-64.0506008, 46.6946751],
            [-64.0507117, 46.6943022],
            [-64.051098, 46.6938379],
            [-64.0511484, 46.6937151],
            [-64.0512357, 46.6933538],
            [-64.0510961, 46.692678],
            [-64.0510314, 46.6925335],
            [-64.0508805, 46.6923677],
            [-64.0506307, 46.6922163],
            [-64.0503115, 46.6920598],
            [-64.0499836, 46.6919908],
            [-64.0487833, 46.6918563],
            [-64.0485187, 46.6918003],
            [-64.0483295, 46.6917415],
            [-64.0481382, 46.6915711],
            [-64.0481065, 46.6914789],
            [-64.048128, 46.6913557],
            [-64.0483003, 46.6912268],
            [-64.0485399, 46.6911629],
            [-64.0489472, 46.6911095],
            [-64.0490434, 46.6910033],
            [-64.0490111, 46.6909311],
            [-64.048807, 46.6907963],
            [-64.0487834, 46.6906365],
            [-64.048811, 46.6905054],
            [-64.04929, 46.6900347],
            [-64.0497483, 46.6896673],
            [-64.0498121, 46.6893176],
            [-64.0499137, 46.6892236],
            [-64.050123, 46.689199],
            [-64.0516773, 46.6891398],
            [-64.0519372, 46.6891638],
            [-64.0522388, 46.689324],
            [-64.0523822, 46.6893661],
            [-64.0527363, 46.6893438],
            [-64.0528318, 46.6892576],
            [-64.0528634, 46.6891784],
            [-64.0528317, 46.6890863],
            [-64.0525954, 46.6888793],
            [-64.0524249, 46.6886054],
            [-64.052375, 46.6883655],
            [-64.0523714, 46.6879509],
            [-64.0521478, 46.6877083],
            [-64.0520715, 46.6875499],
            [-64.050445, 46.6875499],
            [-64.0503848, 46.6876007],
            [-64.0502644, 46.6877383],
            [-64.0500909, 46.6880784],
            [-64.0500157, 46.6884239],
            [-64.0499545, 46.6885227],
            [-64.0495903, 46.6886723],
            [-64.0494537, 46.6887739],
            [-64.049196, 46.6890329],
            [-64.0490562, 46.6894053],
            [-64.048958, 46.6895712],
            [-64.0482002, 46.6900614],
            [-64.0479552, 46.6901133],
            [-64.04763, 46.6899646],
            [-64.0474294, 46.6899017],
            [-64.047146, 46.6898892],
            [-64.0470867, 46.6899281],
            [-64.0471985, 46.6900495],
            [-64.0480597, 46.6904537],
            [-64.0481492, 46.6905468],
            [-64.0481439, 46.6907061],
            [-64.0477625, 46.6913738],
            [-64.047704, 46.6915642],
            [-64.0477465, 46.6916805],
            [-64.0480576, 46.692076],
            [-64.048214, 46.6924252],
            [-64.0481838, 46.692636],
            [-64.0481111, 46.6927305],
            [-64.047708, 46.6930071],
            [-64.047638, 46.6931934],
            [-64.0477128, 46.6933819],
            [-64.0478576, 46.6935556],
            [-64.0478988, 46.6937117],
            [-64.0478894, 46.6938191],
            [-64.0477204, 46.6938483],
            [-64.0473851, 46.6938271],
            [-64.0472626, 46.6938531],
            [-64.0471052, 46.6940578],
            [-64.0467915, 46.6942562],
            [-64.046696, 46.6943423],
            [-64.0466974, 46.6946453],
            [-64.0469611, 46.6957695],
            [-64.0471457, 46.6961391],
            [-64.0473296, 46.6963573],
            [-64.0474697, 46.696499],
            [-64.0475229, 46.6966393],
            [-64.0474462, 46.6968534],
            [-64.0472901, 46.6970183],
            [-64.0469771, 46.6971967],
            [-64.04611, 46.6973145],
            [-64.0446712, 46.6973754],
            [-64.0432965, 46.6977722],
            [-64.0428569, 46.698096],
            [-64.0423204, 46.6983745],
            [-64.0420922, 46.698614],
            [-64.0420545, 46.6987011],
            [-64.042097, 46.6989888],
            [-64.0422371, 46.6991305],
            [-64.0422789, 46.6992667],
            [-64.0425369, 46.6996933],
            [-64.042772, 46.6999402],
            [-64.0430151, 46.7001194],
            [-64.0434488, 46.7003176],
            [-64.0435956, 46.7004315],
            [-64.0436481, 46.7005918],
            [-64.0435963, 46.7007544],
            [-64.0435392, 46.700905],
            [-64.0436247, 46.7011176],
            [-64.0435487, 46.7013117],
            [-64.0433831, 46.7014127],
            [-64.0429986, 46.7014744],
            [-64.0425994, 46.7016315],
            [-64.042227, 46.7016774],
            [-64.0417941, 46.7016306],
            [-64.0411752, 46.7014255],
            [-64.040916, 46.7013815],
            [-64.0402554, 46.7012116],
            [-64.0400938, 46.7011931],
            [-64.039879, 46.7012056],
            [-64.0394346, 46.7013261],
            [-64.038999, 46.701359],
            [-64.038529, 46.7015509],
            [-64.0382725, 46.7015986],
            [-64.0379823, 46.7016139],
            [-64.0377163, 46.7015977],
            [-64.0373359, 46.7015398],
            [-64.0369972, 46.7014468],
            [-64.0367615, 46.7013912],
            [-64.0365359, 46.7013796],
            [-64.0358969, 46.7014292],
            [-64.035163, 46.7013737],
            [-64.0344277, 46.701358],
            [-64.0340055, 46.7015067],
            [-64.0337901, 46.7015391],
            [-64.0333685, 46.7014965],
            [-64.0331827, 46.7015095],
            [-64.0322609, 46.7018695],
            [-64.0321033, 46.7019028],
            [-64.0320576, 46.7018861],
            [-64.032102, 46.7017713],
            [-64.0328252, 46.7014599],
            [-64.0330036, 46.7013233],
            [-64.0331806, 46.7008837],
            [-64.0332237, 46.7006372],
            [-64.0330809, 46.7002323],
            [-64.0330587, 46.7000327],
            [-64.0330823, 46.6998497],
            [-64.0332135, 46.6995648],
            [-64.0334438, 46.6992656],
            [-64.0337797, 46.6989241],
            [-64.0338841, 46.6987504],
            [-64.0339312, 46.6985559],
            [-64.0338894, 46.6982483],
            [-64.033814, 46.6980797],
            [-64.0335178, 46.6979314],
            [-64.0333878, 46.6978337],
            [-64.0332323, 46.6976359],
            [-64.0331245, 46.6975664],
            [-64.0326909, 46.6973682],
            [-64.0323637, 46.6971079],
            [-64.032157, 46.6968813],
            [-64.0318755, 46.6962949],
            [-64.0315355, 46.6960702],
            [-64.0314514, 46.6956464],
            [-64.031349, 46.6955889],
            [-64.0311767, 46.6955463],
            [-64.0303573, 46.6954495],
            [-64.0301426, 46.695462],
            [-64.0289287, 46.6957256],
            [-64.028178, 46.6958252],
            [-64.0281356, 46.6958803],
            [-64.0280346, 46.6959544],
            [-64.0277256, 46.6960132],
            [-64.0274496, 46.695953],
            [-64.0272624, 46.6958344],
            [-64.0269749, 46.6955986],
            [-64.0268134, 46.6954087],
            [-64.0266828, 46.6953309],
            [-64.026534, 46.6952767],
            [-64.0263206, 46.6952493],
            [-64.0258607, 46.6953137],
            [-64.0248609, 46.6955846],
            [-64.0236861, 46.6957212],
            [-64.0229819, 46.6958175],
            [-64.0223167, 46.6959583],
            [-64.0216582, 46.6960712],
            [-64.0214387, 46.6960518],
            [-64.0215364, 46.6959059],
            [-64.0219316, 46.6955256],
            [-64.0221236, 46.695162],
            [-64.0222307, 46.6947372],
            [-64.0221783, 46.6942341],
            [-64.0220565, 46.6940687],
            [-64.0217805, 46.6938371],
            [-64.0217328, 46.6935374],
            [-64.0216197, 46.6934559],
            [-64.0214749, 46.6934535],
            [-64.0212575, 46.6935457],
            [-64.0209444, 46.6935526],
            [-64.0203203, 46.6935067],
            [-64.0198269, 46.6935386],
            [-64.019499, 46.6936409],
            [-64.0192371, 46.6936766],
            [-64.0191563, 46.6936673],
            [-64.0190675, 46.6935543],
            [-64.0191052, 46.6934672],
            [-64.0195354, 46.6932509],
            [-64.0198559, 46.6930249],
            [-64.0199886, 46.6928716],
            [-64.020353, 46.6922078],
            [-64.0204648, 46.691815],
            [-64.0204473, 46.6916473],
            [-64.0203915, 46.6915866],
            [-64.0200373, 46.6917802],
            [-64.0196743, 46.6925755],
            [-64.0192224, 46.6930864],
            [-64.0188265, 46.6933152],
            [-64.0187215, 46.6933374],
            [-64.0182812, 46.6933383],
            [-64.0180585, 46.6930756],
            [-64.0178424, 46.6929565],
            [-64.0175846, 46.6928727],
            [-64.017044, 46.6927563],
            [-64.016626, 46.692614],
            [-64.0165432, 46.6924931],
            [-64.0164585, 46.6922606],
            [-64.016258, 46.6920262],
            [-64.0159867, 46.6918265],
            [-64.0155943, 46.6917843],
            [-64.014637, 46.6918286],
            [-64.0142385, 46.6917942],
            [-64.0136872, 46.6916538],
            [-64.0134454, 46.6917774],
            [-64.0126422, 46.6920594],
            [-64.0125412, 46.6921335],
            [-64.0125539, 46.6922692],
            [-64.0126206, 46.692354],
            [-64.0130191, 46.6923883],
            [-64.0131901, 46.6922994],
            [-64.0132891, 46.6922851],
            [-64.0137892, 46.6923968],
            [-64.0142052, 46.6924274],
            [-64.0145647, 46.6924173],
            [-64.0149754, 46.6924359],
            [-64.0154284, 46.6925708],
            [-64.0158275, 46.6927566],
            [-64.0162751, 46.6930508],
            [-64.016582, 46.6933946],
            [-64.016969, 46.6935961],
            [-64.0171017, 46.6936142],
            [-64.0173184, 46.6937134],
            [-64.0174705, 46.6938394],
            [-64.0181955, 46.6943254],
            [-64.0184519, 46.6944491],
            [-64.0187198, 46.694577],
            [-64.0188814, 46.6945956],
            [-64.0191419, 46.6945998],
            [-64.0193668, 46.6946313],
            [-64.0195196, 46.6947374],
            [-64.0195532, 46.6949412],
            [-64.01951, 46.695359],
            [-64.0195854, 46.6955277],
            [-64.0200067, 46.6960845],
            [-64.0202873, 46.6966909],
            [-64.0204145, 46.6968683],
            [-64.020706, 46.697156],
            [-64.0208668, 46.6973659],
            [-64.0209577, 46.6974192],
            [-64.0210729, 46.697441],
            [-64.0212419, 46.6974118],
            [-64.021555, 46.6972335],
            [-64.0218196, 46.6971182],
            [-64.0225043, 46.6970853],
            [-64.0229837, 46.6969575],
            [-64.023117, 46.6969557],
            [-64.0236469, 46.6970479],
            [-64.0245012, 46.6973087],
            [-64.0251192, 46.6975339],
            [-64.0257333, 46.6977072],
            [-64.0259675, 46.6978026],
            [-64.0264092, 46.6981047],
            [-64.0268354, 46.6983507],
            [-64.0273733, 46.698718],
            [-64.0277651, 46.6989515],
            [-64.0283119, 46.6992313],
            [-64.0285603, 46.6994226],
            [-64.0287044, 46.6996163],
            [-64.0287286, 46.6997562],
            [-64.0286882, 46.6999229],
            [-64.0285199, 46.7001036],
            [-64.0280108, 46.7004223],
            [-64.0279092, 46.7005163],
            [-64.027724, 46.7008522],
            [-64.0276156, 46.700974],
            [-64.0273247, 46.7011806],
            [-64.0271866, 46.7013219],
            [-64.0269752, 46.7015776],
            [-64.0268634, 46.701799],
            [-64.0263731, 46.7022456],
            [-64.026062, 46.7027069],
            [-64.0258849, 46.7028037],
            [-64.025629, 46.7028315],
            [-64.0251967, 46.7027648],
            [-64.0248001, 46.7028421],
            [-64.0244183, 46.7029954],
            [-64.0236782, 46.7034619],
            [-64.0233004, 46.7036671],
            [-64.0228607, 46.7039908],
            [-64.022571, 46.7045004],
            [-64.0224242, 46.7045578],
            [-64.0221104, 46.7045847],
            [-64.0218404, 46.7046879],
            [-64.0202772, 46.7058427],
            [-64.0195208, 46.7062729],
            [-64.0192117, 46.7063317],
            [-64.0189181, 46.7062752],
            [-64.0187491, 46.7063043],
            [-64.018516, 46.7065118],
            [-64.0181678, 46.7066976],
            [-64.0173011, 46.7067952],
            [-64.0167428, 46.7070253],
            [-64.0163986, 46.7070915],
            [-64.0160464, 46.707054],
            [-64.0156633, 46.7069043],
            [-64.0152472, 46.7065308],
            [-64.0147666, 46.7060128],
            [-64.0146966, 46.7058562],
            [-64.0146489, 46.7055565],
            [-64.0145836, 46.7054319],
            [-64.0142356, 46.7051034],
            [-64.014138, 46.7050779],
            [-64.0140619, 46.7051006],
            [-64.0140369, 46.705152],
            [-64.0140733, 46.7052762],
            [-64.0143452, 46.7056273],
            [-64.0144865, 46.7059007],
            [-64.0145161, 46.7060526],
            [-64.0144628, 46.7062551],
            [-64.0142163, 46.7063467],
            [-64.0139712, 46.7063986],
            [-64.0135294, 46.7064393],
            [-64.0133947, 46.7064809],
            [-64.0132701, 46.7065666],
            [-64.0132027, 46.7066732],
            [-64.0134572, 46.7066852],
            [-64.0142061, 46.7066455],
            [-64.0146242, 46.7067878],
            [-64.0148235, 46.7068907],
            [-64.0152153, 46.7072956],
            [-64.0155102, 46.7074838],
            [-64.016049, 46.7074885],
            [-64.0165332, 46.7073927],
            [-64.0169218, 46.707383],
            [-64.0174894, 46.7075596],
            [-64.0176874, 46.7075309],
            [-64.0181858, 46.7073595],
            [-64.0186646, 46.7072517],
            [-64.0189125, 46.7072915],
            [-64.0193165, 46.7075093],
            [-64.0194181, 46.7077581],
            [-64.0193971, 46.7083756],
            [-64.019605, 46.7089051],
            [-64.0197498, 46.7090789],
            [-64.0199591, 46.7093971],
            [-64.0200157, 46.7096093],
            [-64.0200816, 46.709714],
            [-64.0203073, 46.7095542],
            [-64.0206098, 46.7091804],
            [-64.0207297, 46.7088914],
            [-64.0206947, 46.708556],
            [-64.0206503, 46.7084994],
            [-64.0200834, 46.7081316],
            [-64.020012, 46.7080148],
            [-64.019905, 46.707754],
            [-64.0198115, 46.7072662],
            [-64.0198782, 46.7071795],
            [-64.0201153, 46.7070239],
            [-64.0211356, 46.7064982],
            [-64.0214737, 46.7062685],
            [-64.0216684, 46.7059966],
            [-64.0225991, 46.7057206],
            [-64.023686, 46.7051082],
            [-64.0240389, 46.7049545],
            [-64.0244807, 46.7045709],
            [-64.025907, 46.7042032],
            [-64.0260976, 46.7040508],
            [-64.0262363, 46.7037182],
            [-64.026402, 46.7036172],
            [-64.0270329, 46.7034639],
            [-64.0277716, 46.7033801],
            [-64.0281952, 46.7033629],
            [-64.028583, 46.7033731],
            [-64.0289514, 46.7034468],
            [-64.0292658, 46.7035714],
            [-64.0296779, 46.7038929],
            [-64.029948, 46.7043038],
            [-64.0301163, 46.704466],
            [-64.0307533, 46.7048189],
            [-64.0310335, 46.7049311],
            [-64.0318314, 46.7051511],
            [-64.0322934, 46.7051983],
            [-64.0329789, 46.7051455],
            [-64.0342476, 46.7051538],
            [-64.0351311, 46.7052436],
            [-64.0353203, 46.7053024],
            [-64.0358099, 46.7055614],
            [-64.0359426, 46.7055794],
            [-64.0360187, 46.7055567],
            [-64.0359607, 46.7053844],
            [-64.0359688, 46.7053168],
            [-64.0361392, 46.7052477],
            [-64.0363075, 46.7052385],
            [-64.0370994, 46.7052949],
            [-64.0378193, 46.705426],
            [-64.0389905, 46.7059229],
            [-64.0394349, 46.7059738],
            [-64.0398807, 46.7059849],
            [-64.04007, 46.7060437],
            [-64.0406317, 46.7065708],
            [-64.0409833, 46.7067996],
            [-64.0412183, 46.7068751],
            [-64.0419281, 46.7069621],
            [-64.042512, 46.7070033],
            [-64.0426319, 46.707057],
            [-64.0428757, 46.7072163],
            [-64.0431606, 46.7073604],
            [-64.0433021, 46.7074622],
            [-64.0437023, 46.7079708],
            [-64.0441698, 46.7083729],
            [-64.0442802, 46.7085341],
            [-64.0443423, 46.7089297],
            [-64.044389, 46.7094406],
            [-64.0443688, 46.7096954],
            [-64.0442747, 46.7100846],
            [-64.0440506, 46.7105474],
            [-64.0440149, 46.7107462],
            [-64.0440271, 46.7109018],
            [-64.0441821, 46.7112909],
            [-64.0442698, 46.7116152],
            [-64.0444821, 46.7121965],
            [-64.0447126, 46.7125829],
            [-64.0450488, 46.712927],
            [-64.0451135, 46.7130715],
            [-64.0450812, 46.7131707],
            [-64.0449163, 46.7134232],
            [-64.0447514, 46.7138471],
            [-64.0446491, 46.7139611],
            [-64.0444417, 46.7140973],
            [-64.043851, 46.7142554],
            [-64.0434349, 46.7145677],
            [-64.0430417, 46.7150597],
            [-64.0427386, 46.7152821],
            [-64.042144, 46.7155597],
            [-64.0419527, 46.7157321],
            [-64.0416685, 46.7159109],
            [-64.041546, 46.7161083],
            [-64.0412268, 46.7162946],
            [-64.0411601, 46.7163812],
            [-64.0412147, 46.7164817],
            [-64.04147, 46.7166452],
            [-64.0418001, 46.7166545],
            [-64.0421625, 46.7167359],
            [-64.0423787, 46.7166836],
            [-64.0425012, 46.7164862],
            [-64.0426615, 46.7163731],
            [-64.0428258, 46.716312],
            [-64.0430636, 46.7163078],
            [-64.0431465, 46.7164287],
            [-64.0430132, 46.7166019],
            [-64.0430408, 46.7166422],
            [-64.0431614, 46.7166761],
            [-64.0432604, 46.7166617],
            [-64.0436806, 46.7164013],
            [-64.0438975, 46.716329],
            [-64.0443811, 46.7162529],
            [-64.0444706, 46.7161746],
            [-64.0445352, 46.7158049],
            [-64.044585, 46.7157021],
            [-64.0448395, 46.7155426],
            [-64.0452982, 46.7153466],
            [-64.0457709, 46.7150751],
            [-64.045822, 46.7149324],
            [-64.0458193, 46.7148406],
            [-64.0458913, 46.714766],
            [-64.0460328, 46.7146965],
            [-64.0462725, 46.7146326],
            [-64.047284, 46.7141941],
            [-64.0481863, 46.7135546],
            [-64.0483472, 46.7134217],
            [-64.0485249, 46.7131335],
            [-64.0488642, 46.7126924],
            [-64.0491759, 46.7123824],
            [-64.0493476, 46.7122735],
            [-64.0496587, 46.7121548],
            [-64.0498856, 46.7121265],
            [-64.0501732, 46.7121909],
            [-64.0503673, 46.7122816],
            [-64.0506556, 46.7124974],
            [-64.050835, 46.7130264],
            [-64.0510803, 46.7133173],
            [-64.0512293, 46.7135429],
            [-64.0515889, 46.7147524],
            [-64.0516375, 46.7150322],
            [-64.051368, 46.7163354],
            [-64.051638, 46.7174518],
            [-64.0516719, 46.7178271],
            [-64.0516922, 46.7180865],
            [-64.051788, 46.7183431],
            [-64.0524001, 46.7192816],
            [-64.0527014, 46.7198046],
            [-64.0528188, 46.7201094],
            [-64.0528506, 46.720373],
            [-64.0528339, 46.7206996],
            [-64.0527875, 46.7208742],
            [-64.0529312, 46.7212592],
            [-64.0533472, 46.7218238],
            [-64.0536196, 46.7223463],
            [-64.0537634, 46.7229027],
            [-64.0541455, 46.7230921],
            [-64.0543551, 46.7232389],
            [-64.0544636, 46.7234598],
            [-64.0546786, 46.7236187],
            [-64.0548182, 46.7237803],
            [-64.0549484, 46.7240495],
            [-64.0551438, 46.7241004],
            [-64.0553243, 46.7240753],
            [-64.0556415, 46.7239488],
            [-64.0557695, 46.7239348],
            [-64.0567963, 46.7240865],
            [-64.0581, 46.7242784],
            [-64.0586754, 46.724407],
            [-64.0595251, 46.7246555],
            [-64.0603418, 46.7248517],
            [-64.0610648, 46.7250743],
            [-64.0620548, 46.7254645],
            [-64.0623876, 46.7255654],
            [-64.0628081, 46.7256477],
            [-64.0632016, 46.7256698],
            [-64.0636078, 46.7256562],
            [-64.0643468, 46.7255721],
            [-64.0651909, 46.7254657],
            [-64.0665139, 46.7252511],
            [-64.0677164, 46.7251742],
            [-64.0680404, 46.7251912],
            [-64.0683167, 46.7252513],
            [-64.0690028, 46.7255411],
            [-64.0693514, 46.7258694],
            [-64.069626, 46.7263321],
            [-64.0698016, 46.7268092],
            [-64.0698936, 46.7271854],
            [-64.0699732, 46.7281114],
            [-64.0700421, 46.7283078],
            [-64.0702256, 46.7285459],
            [-64.070929, 46.7291947],
            [-64.0711449, 46.729505],
            [-64.0713475, 46.7300423],
            [-64.0713814, 46.7302461],
            [-64.0711476, 46.7310079],
            [-64.0710638, 46.7314411],
            [-64.0707104, 46.7317864],
            [-64.0707099, 46.7319778],
            [-64.0708186, 46.7321987],
            [-64.0708308, 46.7323544],
            [-64.0707355, 46.7327835],
            [-64.0699859, 46.733892],
            [-64.0697741, 46.7343392],
            [-64.069765, 46.7347895],
            [-64.0696321, 46.7353057],
            [-64.0696034, 46.7356481],
            [-64.0696461, 46.7359358],
            [-64.0699917, 46.7365352],
            [-64.0703278, 46.7370706],
            [-64.0704501, 46.7374074],
            [-64.0704384, 46.7379374],
            [-64.0704083, 46.7381482],
            [-64.0702899, 46.7383975],
            [-64.0703151, 46.7386889],
            [-64.0707697, 46.7391465],
            [-64.0716093, 46.7397137],
            [-64.0716519, 46.73983],
            [-64.0715833, 46.7399764],
            [-64.0714412, 46.7400659],
            [-64.0708753, 46.7403441],
            [-64.0705485, 46.7404068],
            [-64.0703396, 46.7404115],
            [-64.0694951, 46.7403466],
            [-64.0691871, 46.7403657],
            [-64.068423, 46.7405013],
            [-64.0676364, 46.7404372],
            [-64.0669841, 46.7405227],
            [-64.0666762, 46.7405418],
            [-64.0660042, 46.7405194],
            [-64.0654779, 46.7404793],
            [-64.0650941, 46.7401584],
            [-64.0648448, 46.7401585],
            [-64.0644539, 46.7402281],
            [-64.0640132, 46.7402292],
            [-64.063857, 46.7403942],
            [-64.0637027, 46.7404994],
            [-64.0625654, 46.7408723],
            [-64.0619807, 46.7413655],
            [-64.06157, 46.7418614],
            [-64.0614994, 46.7420676],
            [-64.0616616, 46.7427718],
            [-64.0617716, 46.7429529],
            [-64.0617819, 46.7431683],
            [-64.0613229, 46.7440701],
            [-64.0611393, 46.7447091],
            [-64.0611106, 46.7450515],
            [-64.0611432, 46.7454666],
            [-64.0612182, 46.7456552],
            [-64.061374, 46.745853],
            [-64.0617167, 46.7461892],
            [-64.0622784, 46.7465648],
            [-64.0629332, 46.7469338],
            [-64.063731, 46.7475364],
            [-64.0647467, 46.748218],
            [-64.0651714, 46.7483522],
            [-64.065882, 46.748439],
            [-64.0659501, 46.748484],
            [-64.0659576, 46.7486077],
            [-64.0656739, 46.7487667],
            [-64.0636354, 46.7493767],
            [-64.063326, 46.7494357],
            [-64.0621579, 46.7495051],
            [-64.0617116, 46.7494942],
            [-64.0608737, 46.7494013],
            [-64.0601199, 46.7492181],
            [-64.0592252, 46.748913],
            [-64.0588362, 46.7487515],
            [-64.057789, 46.748149],
            [-64.056623, 46.7472815],
            [-64.056265, 46.7470606],
            [-64.0558354, 46.746723],
            [-64.0557572, 46.746634],
            [-64.0556728, 46.7463815],
            [-64.0555904, 46.7462407],
            [-64.0552917, 46.7459809],
            [-64.0552323, 46.7458484],
            [-64.0552565, 46.7456454],
            [-64.0551727, 46.745373],
            [-64.05495, 46.744919],
            [-64.0542435, 46.7445411],
            [-64.0535902, 46.7441322],
            [-64.0531655, 46.7439979],
            [-64.053007, 46.7438798],
            [-64.0527272, 46.7435764],
            [-64.0526583, 46.74338],
            [-64.0526913, 46.7432609],
            [-64.0528118, 46.7431232],
            [-64.0529817, 46.7430741],
            [-64.0530652, 46.7430037],
            [-64.0530941, 46.7428327],
            [-64.0531493, 46.7427419],
            [-64.0533871, 46.7425662],
            [-64.0534242, 46.742499],
            [-64.0534086, 46.7424429],
            [-64.0533081, 46.7423258],
            [-64.0531133, 46.7422549],
            [-64.0529623, 46.7420891],
            [-64.0527816, 46.7419427],
            [-64.0524661, 46.7418381],
            [-64.0524499, 46.7418019],
            [-64.0524873, 46.7412006],
            [-64.0524455, 46.7410643],
            [-64.0523322, 46.7409828],
            [-64.0521415, 46.7409639],
            [-64.0518861, 46.7409718],
            [-64.0517998, 46.7409505],
            [-64.0514897, 46.7406865],
            [-64.0514741, 46.7406304],
            [-64.0515441, 46.7404442],
            [-64.0515023, 46.740308],
            [-64.0513404, 46.7401181],
            [-64.0512453, 46.7398415],
            [-64.0509385, 46.7396493],
            [-64.0507888, 46.7394436],
            [-64.0504134, 46.7392264],
            [-64.0492553, 46.738654],
            [-64.0474941, 46.7379126],
            [-64.0473681, 46.7378667],
            [-64.0472892, 46.7377977],
            [-64.0471624, 46.7376003],
            [-64.0469851, 46.7375258],
            [-64.0468894, 46.7374406],
            [-64.0468179, 46.7371524],
            [-64.0466716, 46.7370185],
            [-64.0465233, 46.7369444],
            [-64.0449692, 46.7364295],
            [-64.043897, 46.7362411],
            [-64.0433835, 46.7363366],
            [-64.0432049, 46.7363019],
            [-64.0430809, 46.7361963],
            [-64.0429306, 46.7360105],
            [-64.0425767, 46.7358414],
            [-64.0420187, 46.735709],
            [-64.0418509, 46.7355269],
            [-64.0414243, 46.735281],
            [-64.0412693, 46.7352347],
            [-64.0407093, 46.735162],
            [-64.0402719, 46.7348919],
            [-64.039819, 46.7347372],
            [-64.0396195, 46.7346344],
            [-64.0394308, 46.7343842],
            [-64.039391, 46.7341883],
            [-64.0393027, 46.7340553],
            [-64.0388882, 46.7337936],
            [-64.0385122, 46.7335962],
            [-64.0377251, 46.7330376],
            [-64.0374676, 46.7329338],
            [-64.037122, 46.7328685],
            [-64.036562, 46.7329672],
            [-64.036343, 46.7329279],
            [-64.035971, 46.732611],
            [-64.0357581, 46.7323923],
            [-64.0356159, 46.7323103],
            [-64.0350566, 46.7322177],
            [-64.034905, 46.7320718],
            [-64.0346368, 46.7316011],
            [-64.0344151, 46.7312986],
            [-64.0343625, 46.7311383],
            [-64.0342304, 46.7309288],
            [-64.0339312, 46.7305175],
            [-64.0339063, 46.7303975],
            [-64.0339218, 46.7302821],
            [-64.0341481, 46.7299309],
            [-64.0341805, 46.7298318],
            [-64.0341333, 46.7296835],
            [-64.0338718, 46.7293564],
            [-64.0337377, 46.7290354],
            [-64.0334756, 46.7287282],
            [-64.0330767, 46.7285225],
            [-64.0321739, 46.7283048],
            [-64.0317824, 46.7282228],
            [-64.0315015, 46.7281306],
            [-64.0311922, 46.7280181],
            [-64.0307644, 46.7278119],
            [-64.0297686, 46.7276006],
            [-64.0294324, 46.7275992],
            [-64.0292592, 46.727748],
            [-64.0288146, 46.7283827],
            [-64.0288402, 46.7284827],
            [-64.0292842, 46.728725],
            [-64.0294458, 46.728915],
            [-64.0294715, 46.7290151],
            [-64.0294283, 46.7292615],
            [-64.0293657, 46.7294001],
            [-64.0291231, 46.7297151],
            [-64.0282539, 46.7305583],
            [-64.0278752, 46.7307834],
            [-64.0272998, 46.7311689],
            [-64.0266455, 46.7316567],
            [-64.0262041, 46.731849],
            [-64.0255875, 46.7322497],
            [-64.0250222, 46.7325077],
            [-64.0248315, 46.7326601],
            [-64.0246495, 46.7328964],
            [-64.0244426, 46.7330127],
            [-64.024349, 46.7330391],
            [-64.0240787, 46.7331424],
            [-64.0235659, 46.7335607],
            [-64.0227288, 46.7344761],
            [-64.0225084, 46.7349908],
            [-64.0222098, 46.7354166],
            [-64.0220723, 46.7357094],
            [-64.0218182, 46.7360202],
            [-64.0208996, 46.7366035],
            [-64.0206643, 46.7368708],
            [-64.020241, 46.7372108],
            [-64.0200604, 46.7374072],
            [-64.0200348, 46.7374786],
            [-64.0201796, 46.7378237],
            [-64.0202684, 46.7382796],
            [-64.0202218, 46.7387972],
            [-64.020244, 46.7389969],
            [-64.0204124, 46.7395019],
            [-64.0206448, 46.7396571],
            [-64.0206347, 46.7397845],
            [-64.0201137, 46.7399276],
            [-64.0199257, 46.7400004],
            [-64.0196304, 46.7403265],
            [-64.0195003, 46.7404001],
            [-64.0193662, 46.7404219],
            [-64.0189336, 46.7403551],
            [-64.0187644, 46.7403843],
            [-64.0181402, 46.7406613],
            [-64.0179293, 46.7407257],
            [-64.0177143, 46.7407381],
            [-64.017535, 46.7407233],
            [-64.0172722, 46.7406074],
            [-64.01706, 46.7405402],
            [-64.0169367, 46.7404146],
            [-64.0167723, 46.7403043],
            [-64.0164441, 46.7402353],
            [-64.016066, 46.7400976],
            [-64.0159677, 46.7399206],
            [-64.0158478, 46.7398668],
            [-64.0154785, 46.739813],
            [-64.0152696, 46.7396462],
            [-64.0151801, 46.7393816],
            [-64.0151134, 46.7392968],
            [-64.0148924, 46.7391458],
            [-64.0143331, 46.7388816],
            [-64.0138015, 46.7384863],
            [-64.0134869, 46.7381902],
            [-64.0128565, 46.7374664],
            [-64.0125527, 46.7370229],
            [-64.0121554, 46.7364344],
            [-64.0116125, 46.7358635],
            [-64.0111126, 46.7355604],
            [-64.0104166, 46.7352263],
            [-64.0098198, 46.7348777],
            [-64.0092431, 46.7346172],
            [-64.008859, 46.7344874],
            [-64.0084965, 46.7344058],
            [-64.0077499, 46.7343657],
            [-64.0071233, 46.7342081],
            [-64.0062191, 46.7340299],
            [-64.0059106, 46.7338973],
            [-64.0057603, 46.7338829],
            [-64.0055218, 46.733907],
            [-64.0053452, 46.7339838],
            [-64.005176, 46.734013],
            [-64.0041209, 46.7338403],
            [-64.0031897, 46.7337734],
            [-64.0024849, 46.7336981],
            [-64.0018348, 46.733552],
            [-64.001134, 46.7335286],
            [-64.0006468, 46.7335326],
            [-64.0004777, 46.7335617],
            [-64.0, 46.733513],
            [-63.9989367, 46.7335246],
            [-63.9985238, 46.7333943],
            [-63.9974723, 46.7329505],
            [-63.9965413, 46.7325406],
            [-63.9954311, 46.7322873],
            [-63.9947097, 46.7320243],
            [-63.9944334, 46.731964],
            [-63.9933414, 46.731687],
            [-63.9927203, 46.7315412],
            [-63.9920906, 46.7311402],
            [-63.9918474, 46.7311322],
            [-63.9914984, 46.7311664],
            [-63.9909905, 46.7309308],
            [-63.9891213, 46.73033],
            [-63.9886113, 46.7303256],
            [-63.9882738, 46.7301925],
            [-63.9875657, 46.7302167],
            [-63.9868873, 46.7300501],
            [-63.9857817, 46.73],
            [-63.9853567, 46.7298854],
            [-63.9852215, 46.7296041],
            [-63.9849313, 46.7294478],
            [-63.984329, 46.7294299],
            [-63.9840105, 46.7292532],
            [-63.9839798, 46.7289697],
            [-63.983782, 46.7286555],
            [-63.9838221, 46.7283372],
            [-63.98404, 46.7280737],
            [-63.9839763, 46.7277378],
            [-63.9838215, 46.72752],
            [-63.9839203, 46.7271827],
            [-63.9839367, 46.7270475],
            [-63.9842972, 46.7268461],
            [-63.9847581, 46.726762],
            [-63.9848067, 46.726699],
            [-63.9845765, 46.726484],
            [-63.9847195, 46.7263747],
            [-63.9852631, 46.7264116],
            [-63.9854458, 46.7263269],
            [-63.9855752, 46.7262732],
            [-63.9858632, 46.7259949],
            [-63.9859221, 46.7256331],
            [-63.9856541, 46.7248394],
            [-63.9855707, 46.7247383],
            [-63.9852912, 46.7246061],
            [-63.9851506, 46.7244842],
            [-63.9853171, 46.7243634],
            [-63.9857121, 46.7241746],
            [-63.9862349, 46.7241433],
            [-63.9865119, 46.7240123],
            [-63.9865232, 46.7235222],
            [-63.9866089, 46.7233921],
            [-63.9871516, 46.7229545],
            [-63.9871193, 46.7228822],
            [-63.9870049, 46.7228405],
            [-63.9863129, 46.7229008],
            [-63.9858236, 46.7231359],
            [-63.9855535, 46.7230677],
            [-63.9853258, 46.7231158],
            [-63.9851121, 46.7232598],
            [-63.984511, 46.7233734],
            [-63.9843804, 46.7232955],
            [-63.9844642, 46.7230537],
            [-63.9841539, 46.7228094],
            [-63.9841992, 46.7226746],
            [-63.983998, 46.72246],
            [-63.98399, 46.7223563],
            [-63.9840925, 46.7222424],
            [-63.983546, 46.7217908],
            [-63.9834132, 46.7212784],
            [-63.983233, 46.7209605],
            [-63.982936, 46.720832],
            [-63.9822435, 46.7209123],
            [-63.9820105, 46.7207769],
            [-63.9820093, 46.7206453],
            [-63.9824549, 46.7203337],
            [-63.9839036, 46.7193491],
            [-63.9839295, 46.7191064],
            [-63.983632, 46.7188264],
            [-63.9834267, 46.7187313],
            [-63.9831889, 46.7187354],
            [-63.9828869, 46.7189177],
            [-63.9821805, 46.7187307],
            [-63.9813674, 46.718777],
            [-63.981323, 46.7187205],
            [-63.9809439, 46.7186225],
            [-63.980384, 46.7187209],
            [-63.9802595, 46.7186351],
            [-63.9803809, 46.7184777],
            [-63.980674, 46.7183829],
            [-63.9807422, 46.7182564],
            [-63.9804704, 46.7180766],
            [-63.9804746, 46.717957],
            [-63.9807093, 46.7177098],
            [-63.9806867, 46.7173586],
            [-63.9804091, 46.7168438],
            [-63.9791339, 46.715854],
            [-63.9777996, 46.715226],
            [-63.977634, 46.7151555],
            [-63.9766906, 46.7149525],
            [-63.976134, 46.7146283],
            [-63.9754564, 46.7146131],
            [-63.9750012, 46.7145378],
            [-63.9746163, 46.7142763],
            [-63.9746263, 46.713826],
            [-63.9749152, 46.7133564],
            [-63.9754397, 46.7129426],
            [-63.9757571, 46.7128163],
            [-63.9760308, 46.7126135],
            [-63.9762304, 46.7118794],
            [-63.9761598, 46.7117427],
            [-63.9759155, 46.7116031],
            [-63.9752541, 46.711624],
            [-63.9750472, 46.7117402],
            [-63.9742681, 46.7116475],
            [-63.9739738, 46.7116107],
            [-63.9736669, 46.7114382],
            [-63.9734511, 46.7111476],
            [-63.973019, 46.7109092],
            [-63.9722749, 46.7103149],
            [-63.9720629, 46.7102476],
            [-63.9713312, 46.7099604],
            [-63.9706119, 46.709486],
            [-63.9699769, 46.7089213],
            [-63.9692383, 46.7088333],
            [-63.9688212, 46.7084995],
            [-63.9685223, 46.7084307],
            [-63.9682755, 46.7081994],
            [-63.968162, 46.7079663],
            [-63.967588, 46.7076458],
            [-63.9672053, 46.7073245],
            [-63.9672065, 46.7064675],
            [-63.9669144, 46.7058766],
            [-63.9661586, 46.7051266],
            [-63.9661105, 46.7048468],
            [-63.965645, 46.7045759],
            [-63.9655906, 46.7044753],
            [-63.9658474, 46.7042564],
            [-63.9661559, 46.7042177],
            [-63.9666028, 46.7043607],
            [-63.9667766, 46.7043636],
            [-63.9671576, 46.7044018],
            [-63.9679658, 46.7048179],
            [-63.9690621, 46.7047844],
            [-63.9698311, 46.704833],
            [-63.9705099, 46.7048085],
            [-63.971113, 46.704958],
            [-63.9714504, 46.7049198],
            [-63.9715039, 46.7047174],
            [-63.9713089, 46.7044949],
            [-63.9713003, 46.704411],
            [-63.9719378, 46.7040788],
            [-63.9723018, 46.703778],
            [-63.972586, 46.7037707],
            [-63.972932, 46.7038163],
            [-63.9731025, 46.7037474],
            [-63.9736579, 46.7032743],
            [-63.973713, 46.7028607],
            [-63.9736352, 46.7026003],
            [-63.9737562, 46.7022914],
            [-63.9742504, 46.7017455],
            [-63.9743896, 46.701413],
            [-63.9743347, 46.701161],
            [-63.9744164, 46.700979],
            [-63.9766808, 46.6998486],
            [-63.9771893, 46.6997215],
            [-63.9782827, 46.6997675],
            [-63.9783199, 46.6997004],
            [-63.9781403, 46.699534],
            [-63.9775722, 46.6993771],
            [-63.9774748, 46.6991802],
            [-63.9776272, 46.6989635],
            [-63.9775975, 46.6984886],
            [-63.9773689, 46.6982337],
            [-63.9772592, 46.6982239],
            [-63.9772113, 46.698267],
            [-63.9772284, 46.6986061],
            [-63.9770118, 46.6990011],
            [-63.9762005, 46.6995018],
            [-63.9754137, 46.6997997],
            [-63.9751118, 46.699982],
            [-63.9745109, 46.700267],
            [-63.9737878, 46.7007294],
            [-63.9733325, 46.7013197],
            [-63.9732403, 46.7018005],
            [-63.972839, 46.7023399],
            [-63.9727827, 46.702622],
            [-63.9725686, 46.7031087],
            [-63.9721292, 46.7034124],
            [-63.9718439, 46.7037823],
            [-63.9712664, 46.7040557],
            [-63.9702939, 46.704195],
            [-63.9699871, 46.7040225],
            [-63.9693662, 46.704048],
            [-63.968703, 46.7039572],
            [-63.9684716, 46.703782],
            [-63.9683717, 46.7034933],
            [-63.9680997, 46.7034848],
            [-63.9678216, 46.7034841],
            [-63.9674589, 46.7032509],
            [-63.9670994, 46.7032608],
            [-63.966772, 46.7033431],
            [-63.9665843, 46.7032443],
            [-63.9665777, 46.7031006],
            [-63.9663286, 46.7031005],
            [-63.9659337, 46.7032892],
            [-63.9657149, 46.7032497],
            [-63.9654039, 46.7031966],
            [-63.9650021, 46.7030903],
            [-63.9646939, 46.7029576],
            [-63.9641209, 46.70294],
            [-63.963886, 46.7028644],
            [-63.9632294, 46.7024229],
            [-63.9631786, 46.7022227],
            [-63.9629587, 46.7020516],
            [-63.9630729, 46.7017705],
            [-63.9629917, 46.7016097],
            [-63.9626445, 46.7014325],
            [-63.9624486, 46.7014013],
            [-63.9620756, 46.7009725],
            [-63.9603036, 46.6999623],
            [-63.957568, 46.6986807],
            [-63.9558365, 46.6981693],
            [-63.9553113, 46.6977858],
            [-63.954971, 46.6975808],
            [-63.9533543, 46.6972626],
            [-63.9525148, 46.6972485],
            [-63.9522295, 46.6971241],
            [-63.9520919, 46.6969225],
            [-63.9517147, 46.6966132],
            [-63.9515939, 46.6964278],
            [-63.9512529, 46.6962427],
            [-63.9511775, 46.6962454],
            [-63.9508736, 46.6959931],
            [-63.949803, 46.6953094],
            [-63.949511, 46.6952128],
            [-63.9486189, 46.6948868],
            [-63.9480774, 46.6946385],
            [-63.9478544, 46.6943956],
            [-63.9465613, 46.6939432],
            [-63.9464512, 46.6937819],
            [-63.9465759, 46.6936963],
            [-63.9474096, 46.693547],
            [-63.9473128, 46.6935015],
            [-63.9462714, 46.693464],
            [-63.9457721, 46.693332],
            [-63.9454026, 46.6932978],
            [-63.9451849, 46.6933898],
            [-63.9449069, 46.6933891],
            [-63.9445431, 46.6931956],
            [-63.9445198, 46.6930358],
            [-63.9445738, 46.6929849],
            [-63.9449469, 46.6929194],
            [-63.9451913, 46.6928877],
            [-63.9452413, 46.6927849],
            [-63.9449307, 46.6922375],
            [-63.9444911, 46.6918953],
            [-63.9444707, 46.6916558],
            [-63.9442638, 46.691449],
            [-63.9437749, 46.6911896],
            [-63.9431602, 46.6912071],
            [-63.9429369, 46.6911355],
            [-63.9425102, 46.6910805],
            [-63.9421982, 46.6908958],
            [-63.9421857, 46.6907601],
            [-63.9424419, 46.6905611],
            [-63.9425433, 46.6903157],
            [-63.9423818, 46.6899742],
            [-63.9420652, 46.6897576],
            [-63.9417696, 46.6897605],
            [-63.9416385, 46.6897025],
            [-63.9416106, 46.6895107],
            [-63.9418043, 46.6892788],
            [-63.9418032, 46.6888244],
            [-63.9415308, 46.6880186],
            [-63.9416033, 46.6874498],
            [-63.9419253, 46.6870328],
            [-63.9423526, 46.6867451],
            [-63.942632, 46.686706],
            [-63.9430287, 46.6869519],
            [-63.9438573, 46.686942],
            [-63.9447691, 46.6867103],
            [-63.9456263, 46.6862266],
            [-63.9466218, 46.6862474],
            [-63.947246, 46.6861224],
            [-63.9479641, 46.6857997],
            [-63.9481052, 46.6855789],
            [-63.9483035, 46.685379],
            [-63.9485061, 46.6853824],
            [-63.9496125, 46.6861903],
            [-63.9502246, 46.6864039],
            [-63.9508074, 46.6864655],
            [-63.9512965, 46.6862307],
            [-63.9518075, 46.6861954],
            [-63.9523327, 46.6860847],
            [-63.9526503, 46.68611],
            [-63.9530444, 46.6859412],
            [-63.9536468, 46.6859394],
            [-63.9538929, 46.6856964],
            [-63.9541739, 46.6851232],
            [-63.9541846, 46.6848245],
            [-63.9542324, 46.6847814],
            [-63.9547819, 46.6846392],
            [-63.9551495, 46.6845617],
            [-63.9554206, 46.6842673],
            [-63.9555302, 46.6839543],
            [-63.956175, 46.6835745],
            [-63.9563039, 46.6833694],
            [-63.9563884, 46.6831078],
            [-63.9567671, 46.6828829],
            [-63.957106, 46.6826335],
            [-63.9571481, 46.682427],
            [-63.9573496, 46.6822988],
            [-63.9577594, 46.6823376],
            [-63.9582154, 46.6825445],
            [-63.9583937, 46.6825794],
            [-63.9598757, 46.6825963],
            [-63.9604689, 46.6828534],
            [-63.9609307, 46.6832238],
            [-63.9614084, 46.6838018],
            [-63.9616284, 46.6838015],
            [-63.9618563, 46.6835821],
            [-63.9616501, 46.6828612],
            [-63.9616693, 46.6826463],
            [-63.9620671, 46.6822066],
            [-63.9622126, 46.6816948],
            [-63.9623197, 46.6816129],
            [-63.9628332, 46.6816693],
            [-63.9631433, 46.6817423],
            [-63.9647995, 46.6814192],
            [-63.9661504, 46.6803896],
            [-63.9665757, 46.6799901],
            [-63.9670821, 46.6797514],
            [-63.968133, 46.6795099],
            [-63.9686824, 46.6793676],
            [-63.9687613, 46.6792653],
            [-63.9686408, 46.6792314],
            [-63.9674408, 46.6792672],
            [-63.9670625, 46.6793207],
            [-63.9668371, 46.6793089],
            [-63.9663846, 46.6794967],
            [-63.9654108, 46.6803413],
            [-63.9651511, 46.6803171],
            [-63.9647765, 46.6800996],
            [-63.964581, 46.679897],
            [-63.9645341, 46.6797487],
            [-63.9644319, 46.6796912],
            [-63.9643248, 46.6797731],
            [-63.9644022, 46.6802049],
            [-63.9641545, 46.6806591],
            [-63.9638459, 46.6808692],
            [-63.9632337, 46.6811499],
            [-63.9620687, 46.6811782],
            [-63.961062, 46.6814763],
            [-63.9602811, 46.6816146],
            [-63.9588443, 46.6819572],
            [-63.9585563, 46.6819125],
            [-63.957675, 46.6812879],
            [-63.9575588, 46.6811345],
            [-63.9575584, 46.680983],
            [-63.957688, 46.680758],
            [-63.9576547, 46.6805542],
            [-63.9575585, 46.6804888],
            [-63.9573478, 46.680553],
            [-63.9571572, 46.6810281],
            [-63.9564153, 46.6815338],
            [-63.9562386, 46.6817819],
            [-63.9562315, 46.6819811],
            [-63.9560029, 46.6822204],
            [-63.9555628, 46.682221],
            [-63.9553202, 46.6823644],
            [-63.9550136, 46.6830089],
            [-63.9547999, 46.6831528],
            [-63.9542722, 46.6831718],
            [-63.953524, 46.6830396],
            [-63.95328, 46.6829],
            [-63.9530311, 46.6824056],
            [-63.9527981, 46.6821187],
            [-63.952703, 46.681862],
            [-63.9520884, 46.6815567],
            [-63.9519175, 46.6811512],
            [-63.9515497, 46.6809059],
            [-63.950937, 46.6807122],
            [-63.9508345, 46.680826],
            [-63.9509028, 46.6810225],
            [-63.9513627, 46.6816042],
            [-63.9514772, 46.6819689],
            [-63.9523996, 46.6829011],
            [-63.9524964, 46.6829466],
            [-63.9526555, 46.6831964],
            [-63.9525936, 46.6839606],
            [-63.9524114, 46.6841967],
            [-63.9517851, 46.6843815],
            [-63.9509566, 46.6847143],
            [-63.9505904, 46.684752],
            [-63.950039, 46.6844597],
            [-63.9492926, 46.6844391],
            [-63.9488181, 46.6841042],
            [-63.9483232, 46.6841756],
            [-63.9472421, 46.6846077],
            [-63.9462342, 46.684774],
            [-63.945634, 46.6850389],
            [-63.9454131, 46.6850591],
            [-63.9450487, 46.6852084],
            [-63.9448751, 46.6852054],
            [-63.9446571, 46.6851459],
            [-63.9436509, 46.6844553],
            [-63.9434668, 46.6842569],
            [-63.9431252, 46.6840916],
            [-63.9427411, 46.6836586],
            [-63.9422344, 46.6835743],
            [-63.9402715, 46.6834015],
            [-63.9388234, 46.6832454],
            [-63.9386115, 46.683178],
            [-63.9375303, 46.6829643],
            [-63.936381, 46.682877],
            [-63.9360565, 46.6828794],
            [-63.9349729, 46.6828969],
            [-63.9334091, 46.6827387],
            [-63.9320078, 46.6824079],
            [-63.9314151, 46.6823021],
            [-63.9300353, 46.6823423],
            [-63.9294586, 46.6822726],
            [-63.9287513, 46.6819736],
            [-63.9276432, 46.6812252],
            [-63.9267258, 46.6809703],
            [-63.9257674, 46.6804079],
            [-63.9253719, 46.6802935],
            [-63.9246878, 46.6801542],
            [-63.9233102, 46.6801345],
            [-63.9230427, 46.6800064],
            [-63.9229066, 46.6797649],
            [-63.922959, 46.6792795],
            [-63.9231023, 46.678999],
            [-63.9235054, 46.6787429],
            [-63.9247423, 46.6784891],
            [-63.9249771, 46.678242],
            [-63.9250247, 46.6780475],
            [-63.9253427, 46.6779015],
            [-63.9256524, 46.6778231],
            [-63.9258811, 46.6775839],
            [-63.9257915, 46.6773392],
            [-63.9253394, 46.6770325],
            [-63.9246518, 46.67667],
            [-63.9241942, 46.6766741],
            [-63.9241059, 46.6767124],
            [-63.9235501, 46.6767109],
            [-63.9229832, 46.6765337],
            [-63.9215614, 46.6756604],
            [-63.9206663, 46.6754337],
            [-63.9203499, 46.675217],
            [-63.9202374, 46.674964],
            [-63.9194704, 46.6744008],
            [-63.9191259, 46.6738408],
            [-63.9180836, 46.6730457],
            [-63.9175813, 46.6723674],
            [-63.9166883, 46.6716067],
            [-63.9164206, 46.6713271],
            [-63.9164862, 46.6709576],
            [-63.9162345, 46.6707141],
            [-63.9151672, 46.6699703],
            [-63.9149559, 46.6697315],
            [-63.9141469, 46.6690519],
            [-63.9137102, 46.668327],
            [-63.9138212, 46.6678227],
            [-63.9137348, 46.6676498],
            [-63.9132571, 46.6674144],
            [-63.9132476, 46.667199],
            [-63.9133667, 46.66695],
            [-63.9132688, 46.6662986],
            [-63.9130736, 46.666096],
            [-63.9127704, 46.6659951],
            [-63.9127505, 46.665907],
            [-63.9125034, 46.6656955],
            [-63.9125093, 46.6655362],
            [-63.9126172, 46.6654344],
            [-63.9125637, 46.6653139],
            [-63.9123344, 46.6652502],
            [-63.9122478, 46.6649259],
            [-63.9123779, 46.6648524],
            [-63.9124583, 46.6647103],
            [-63.9121817, 46.6645182],
            [-63.9123221, 46.6643174],
            [-63.912264, 46.6641649],
            [-63.9118267, 46.6639342],
            [-63.911498, 46.6635817],
            [-63.9112838, 46.6634226],
            [-63.9111369, 46.6630055],
            [-63.9108442, 46.6627773],
            [-63.9108471, 46.6626976],
            [-63.9110812, 46.6624705],
            [-63.9110668, 46.6620717],
            [-63.9107406, 46.661811],
            [-63.9107313, 46.6617471],
            [-63.9109632, 46.6615797],
            [-63.9108859, 46.6613193],
            [-63.9105878, 46.661079],
            [-63.9098717, 46.6607159],
            [-63.9095866, 46.6604399],
            [-63.9095968, 46.6601611],
            [-63.9092805, 46.6599444],
            [-63.9085856, 46.6599523],
            [-63.9080127, 46.6596315],
            [-63.9079675, 46.6594434],
            [-63.9068992, 46.6587394],
            [-63.90631, 46.658231],
            [-63.9062891, 46.6580114],
            [-63.9064556, 46.6578907],
            [-63.9072958, 46.6578655],
            [-63.9079394, 46.6576773],
            [-63.9090088, 46.6572415],
            [-63.9094028, 46.657073],
            [-63.9100841, 46.6571207],
            [-63.9106105, 46.6569704],
            [-63.9109476, 46.6569324],
            [-63.9111967, 46.6566099],
            [-63.9115889, 46.6563297],
            [-63.9115911, 46.65627],
            [-63.9117413, 46.6559617],
            [-63.9122618, 46.6554964],
            [-63.9131204, 46.6551247],
            [-63.913448, 46.6548713],
            [-63.9136084, 46.6547585],
            [-63.9143668, 46.6545922],
            [-63.914778, 46.6542685],
            [-63.9147741, 46.6542167],
            [-63.9148988, 46.6541311],
            [-63.9151559, 46.6540638],
            [-63.9156001, 46.653944],
            [-63.9158834, 46.653634],
            [-63.9162941, 46.6534817],
            [-63.9166458, 46.6535196],
            [-63.9171397, 46.6539426],
            [-63.917638, 46.6542461],
            [-63.9180347, 46.6543207],
            [-63.9182471, 46.6543682],
            [-63.918862, 46.654331],
            [-63.9193373, 46.6541519],
            [-63.9194038, 46.6539139],
            [-63.9194988, 46.6538478],
            [-63.9206464, 46.6538037],
            [-63.921029, 46.6541052],
            [-63.9212075, 46.6542916],
            [-63.9210535, 46.6553451],
            [-63.9214396, 46.6558699],
            [-63.9214638, 46.6561612],
            [-63.921259, 46.6565403],
            [-63.9215571, 46.6567806],
            [-63.9224193, 46.6571062],
            [-63.9229262, 46.6574936],
            [-63.9235439, 46.6576995],
            [-63.9241269, 46.6580642],
            [-63.9245885, 46.6582634],
            [-63.9249531, 46.6582657],
            [-63.9253724, 46.6581971],
            [-63.9256345, 46.6579904],
            [-63.9257268, 46.6576811],
            [-63.9261232, 46.6572814],
            [-63.9262662, 46.6568494],
            [-63.9264851, 46.6567177],
            [-63.9273393, 46.6564653],
            [-63.9276067, 46.6565934],
            [-63.9277055, 46.656902],
            [-63.9282239, 46.6572935],
            [-63.9284145, 46.6577869],
            [-63.9282255, 46.6580507],
            [-63.9282619, 46.6583263],
            [-63.9284071, 46.6586317],
            [-63.9281025, 46.6588935],
            [-63.9281374, 46.659209],
            [-63.9283444, 46.6595672],
            [-63.9283815, 46.6598229],
            [-63.928, 46.6604501],
            [-63.9281105, 46.6609143],
            [-63.9286557, 46.661366],
            [-63.9286746, 46.6616453],
            [-63.9288446, 46.6617479],
            [-63.9304206, 46.6615477],
            [-63.9308795, 46.6615037],
            [-63.9316262, 46.6615045],
            [-63.9317794, 46.6615908],
            [-63.9320039, 46.6619454],
            [-63.9329331, 46.6625072],
            [-63.9330424, 46.6623656],
            [-63.933181, 46.6617303],
            [-63.9337146, 46.6613807],
            [-63.9345095, 46.6611671],
            [-63.9352773, 46.6610646],
            [-63.937099, 46.6604699],
            [-63.9378028, 46.6600515],
            [-63.938321, 46.6596458],
            [-63.9386563, 46.659496],
            [-63.9389927, 46.659155],
            [-63.9394044, 46.6584885],
            [-63.9400168, 46.6580366],
            [-63.9404646, 46.657817],
            [-63.9404782, 46.6577615],
            [-63.9403107, 46.6577506],
            [-63.9393619, 46.6582207],
            [-63.9383046, 46.6591354],
            [-63.9373911, 46.6597495],
            [-63.9367805, 46.6599902],
            [-63.9361313, 46.660015],
            [-63.9358445, 46.6601018],
            [-63.935517, 46.6603553],
            [-63.9351185, 46.660492],
            [-63.9344221, 46.6605399],
            [-63.933449, 46.6607186],
            [-63.9325919, 46.6607279],
            [-63.9322991, 46.6608225],
            [-63.931797, 46.6609415],
            [-63.9308717, 46.6610771],
            [-63.930296, 46.6609876],
            [-63.9298311, 46.6607166],
            [-63.9296533, 46.6605103],
            [-63.9295152, 46.6600057],
            [-63.9294611, 46.6595824],
            [-63.9295165, 46.6591688],
            [-63.9293616, 46.6586481],
            [-63.9291092, 46.6584245],
            [-63.9291025, 46.6581295],
            [-63.9291747, 46.658055],
            [-63.9291341, 46.6575761],
            [-63.9290423, 46.6573912],
            [-63.9290363, 46.6570762],
            [-63.9293317, 46.6567505],
            [-63.9291957, 46.656509],
            [-63.9289683, 46.6562341],
            [-63.9288878, 46.6555791],
            [-63.9293078, 46.6551679],
            [-63.9298105, 46.655029],
            [-63.9304293, 46.6550436],
            [-63.9305707, 46.6549742],
            [-63.9304554, 46.6548009],
            [-63.929441, 46.6548433],
            [-63.9292096, 46.6548394],
            [-63.9288901, 46.6548738],
            [-63.9281858, 46.6554635],
            [-63.9280923, 46.6554898],
            [-63.9271857, 46.6554304],
            [-63.9267562, 46.6553035],
            [-63.9264459, 46.655079],
            [-63.9264664, 46.6546729],
            [-63.9269126, 46.6540189],
            [-63.9268748, 46.6537831],
            [-63.9267309, 46.6537607],
            [-63.926468, 46.6539874],
            [-63.9259933, 46.6547923],
            [-63.9256197, 46.6552003],
            [-63.9252447, 46.6553254],
            [-63.9248466, 46.6552907],
            [-63.9241455, 46.6548323],
            [-63.9236078, 46.6546557],
            [-63.9233876, 46.6545045],
            [-63.9228124, 46.6542435],
            [-63.9226165, 46.6540608],
            [-63.9218911, 46.6536339],
            [-63.9215189, 46.653205],
            [-63.9212187, 46.6530245],
            [-63.9206524, 46.6529988],
            [-63.9204811, 46.6529361],
            [-63.9203102, 46.652702],
            [-63.9203096, 46.6523991],
            [-63.9204443, 46.6520348],
            [-63.9203059, 46.6517016],
            [-63.9203781, 46.6516271],
            [-63.9202896, 46.651514],
            [-63.9203186, 46.6511917],
            [-63.9201002, 46.6511521],
            [-63.9192384, 46.6517749],
            [-63.9188814, 46.6517249],
            [-63.9173491, 46.6507301],
            [-63.9172703, 46.6505095],
            [-63.9173744, 46.6500331],
            [-63.9177682, 46.6493902],
            [-63.917833, 46.6490407],
            [-63.9179378, 46.6485443],
            [-63.9182047, 46.6480468],
            [-63.9189592, 46.6470315],
            [-63.9190464, 46.6468617],
            [-63.9190815, 46.6465315],
            [-63.9192041, 46.6461829],
            [-63.9196434, 46.6458796],
            [-63.920175, 46.6457413],
            [-63.9206188, 46.64547],
            [-63.9218927, 46.6451292],
            [-63.9226848, 46.6451468],
            [-63.9228808, 46.6450067],
            [-63.9229837, 46.6447216],
            [-63.9231205, 46.6446203],
            [-63.923474, 46.644447],
            [-63.9237222, 46.6441444],
            [-63.9245473, 46.6435688],
            [-63.9248779, 46.6433872],
            [-63.9249865, 46.6432655],
            [-63.9245424, 46.6433854],
            [-63.9241765, 46.6434229],
            [-63.9232685, 46.644049],
            [-63.9226684, 46.6441622],
            [-63.9223056, 46.6442715],
            [-63.9219512, 46.6443133],
            [-63.9216199, 46.644192],
            [-63.9213711, 46.6441917],
            [-63.9211326, 46.6443869],
            [-63.9207567, 46.6443804],
            [-63.9202159, 46.6444548],
            [-63.9198624, 46.644628],
            [-63.9197456, 46.6448173],
            [-63.9193421, 46.6450933],
            [-63.9190674, 46.6454871],
            [-63.9186354, 46.6455913],
            [-63.9178822, 46.6460924],
            [-63.9175082, 46.6466718],
            [-63.9170556, 46.6471821],
            [-63.9165478, 46.6474602],
            [-63.9159012, 46.6475766],
            [-63.9146267, 46.6476144],
            [-63.9143694, 46.6475303],
            [-63.9140005, 46.6471732],
            [-63.9135704, 46.6462691],
            [-63.9134693, 46.6458688],
            [-63.9133679, 46.6457913],
            [-63.913219, 46.6459083],
            [-63.9131799, 46.6461866],
            [-63.9132082, 46.6466813],
            [-63.9132958, 46.6471371],
            [-63.9138256, 46.6476843],
            [-63.9138996, 46.6481957],
            [-63.9142906, 46.648581],
            [-63.9147089, 46.6488552],
            [-63.9151507, 46.6491179],
            [-63.9158181, 46.6495439],
            [-63.9161446, 46.6499561],
            [-63.9162224, 46.6505193],
            [-63.9163537, 46.6507288],
            [-63.916259, 46.6509464],
            [-63.9156212, 46.6512981],
            [-63.9151281, 46.6513294],
            [-63.9147656, 46.6515902],
            [-63.9141872, 46.6515802],
            [-63.914055, 46.6517134],
            [-63.9137925, 46.6517686],
            [-63.9136139, 46.651905],
            [-63.9134344, 46.6519099],
            [-63.9132805, 46.6518435],
            [-63.911948, 46.6518802],
            [-63.9118073, 46.6519296],
            [-63.9114827, 46.6521033],
            [-63.9112737, 46.6522791],
            [-63.9109498, 46.6524329],
            [-63.9104254, 46.6528462],
            [-63.9097658, 46.6529982],
            [-63.9092047, 46.6533073],
            [-63.9089711, 46.6533631],
            [-63.9086943, 46.6534938],
            [-63.9085575, 46.6540693],
            [-63.9081539, 46.6543453],
            [-63.9071937, 46.6549623],
            [-63.9069669, 46.6549902],
            [-63.9068084, 46.6548918],
            [-63.9067732, 46.6547477],
            [-63.9065666, 46.6545409],
            [-63.9060511, 46.6543925],
            [-63.9055556, 46.6543321],
            [-63.9046739, 46.6545439],
            [-63.9030543, 46.6545158],
            [-63.9027588, 46.6543671],
            [-63.9015796, 46.653693],
            [-63.9003458, 46.6530897],
            [-63.8995126, 46.6526128],
            [-63.8987383, 46.65242],
            [-63.8984445, 46.652383],
            [-63.8983714, 46.6523259],
            [-63.8983048, 46.6520896],
            [-63.8980412, 46.6518618],
            [-63.897892, 46.6518274],
            [-63.8975926, 46.6516268],
            [-63.8975368, 46.6514146],
            [-63.8973577, 46.6512481],
            [-63.8969611, 46.6511734],
            [-63.8961721, 46.6507532],
            [-63.8953148, 46.6504592],
            [-63.8951222, 46.6503482],
            [-63.8951251, 46.6502686],
            [-63.895477, 46.6499838],
            [-63.895445, 46.6499115],
            [-63.8953125, 46.6498932],
            [-63.8950085, 46.650135],
            [-63.8946828, 46.6501772],
            [-63.8926942, 46.6494888],
            [-63.8918836, 46.6493431],
            [-63.8898968, 46.6493919],
            [-63.8887452, 46.6492322],
            [-63.8873526, 46.6491759],
            [-63.8867717, 46.6489226],
            [-63.8866337, 46.6487408],
            [-63.8865573, 46.6484605],
            [-63.8867264, 46.6479574],
            [-63.88665, 46.6470513],
            [-63.8874102, 46.6463713],
            [-63.8875947, 46.6460756],
            [-63.887731, 46.6455201],
            [-63.8875726, 46.6454216],
            [-63.887282, 46.6454564],
            [-63.8867194, 46.6451795],
            [-63.8864884, 46.6448526],
            [-63.8852496, 46.6440856],
            [-63.8844746, 46.642984],
            [-63.8827804, 46.6410811],
            [-63.8819488, 46.6404129],
            [-63.8812325, 46.6400695],
            [-63.8805431, 46.6399377],
            [-63.8803269, 46.6398383],
            [-63.8797819, 46.6395577],
            [-63.8792406, 46.6391775],
            [-63.8789269, 46.638901],
            [-63.8786584, 46.6381869],
            [-63.8783866, 46.6372495],
            [-63.8781334, 46.6365915],
            [-63.8782305, 46.6363142],
            [-63.8782378, 46.6359637],
            [-63.8783457, 46.635862],
            [-63.8783342, 46.6357063],
            [-63.8781202, 46.6355471],
            [-63.8781056, 46.6353197],
            [-63.8782185, 46.6349272],
            [-63.8785721, 46.6347541],
            [-63.8793556, 46.6346882],
            [-63.8798924, 46.6344108],
            [-63.8801482, 46.6339092],
            [-63.8803491, 46.6331755],
            [-63.8802054, 46.6325274],
            [-63.8801777, 46.6321842],
            [-63.8803733, 46.6315899],
            [-63.8804538, 46.6314478],
            [-63.8806635, 46.6312523],
            [-63.8810148, 46.6311389],
            [-63.8820136, 46.6311924],
            [-63.8827278, 46.6311213],
            [-63.8833759, 46.6311168],
            [-63.8835624, 46.6312356],
            [-63.8836539, 46.6314205],
            [-63.8837352, 46.6320357],
            [-63.8839027, 46.632198],
            [-63.8841483, 46.6321266],
            [-63.8843511, 46.6318074],
            [-63.8844809, 46.6315825],
            [-63.8850169, 46.631325],
            [-63.8848694, 46.6307764],
            [-63.8850129, 46.630496],
            [-63.8848461, 46.6303138],
            [-63.8844831, 46.6301201],
            [-63.8844083, 46.6299514],
            [-63.8841184, 46.6298148],
            [-63.8837769, 46.6298207],
            [-63.8835508, 46.6296773],
            [-63.8833901, 46.6294871],
            [-63.8833314, 46.6293546],
            [-63.8833715, 46.6292079],
            [-63.8833714, 46.6290564],
            [-63.883477, 46.6290144],
            [-63.883422, 46.6287823],
            [-63.8837056, 46.628624],
            [-63.8835767, 46.6283547],
            [-63.8835422, 46.6280393],
            [-63.8831547, 46.6277256],
            [-63.8830739, 46.6275648],
            [-63.8827184, 46.6273234],
            [-63.8823274, 46.6272607],
            [-63.8819766, 46.6270513],
            [-63.881812, 46.6268093],
            [-63.8814763, 46.6265045],
            [-63.8815091, 46.6259312],
            [-63.8814032, 46.6256704],
            [-63.8813408, 46.6256374],
            [-63.8813588, 46.6254624],
            [-63.8814135, 46.6253916],
            [-63.8814498, 46.625193],
            [-63.8811822, 46.62495],
            [-63.8811141, 46.6248882],
            [-63.8807238, 46.6248056],
            [-63.8801028, 46.6243961],
            [-63.879705, 46.6243612],
            [-63.8794251, 46.6242686],
            [-63.8794606, 46.6240899],
            [-63.8801126, 46.6235116],
            [-63.8801013, 46.6228818],
            [-63.8800069, 46.6227766],
            [-63.8799992, 46.6226728],
            [-63.8809105, 46.6221191],
            [-63.8814031, 46.6219365],
            [-63.8817399, 46.6218986],
            [-63.8824538, 46.621676],
            [-63.8828453, 46.6215674],
            [-63.8826285, 46.6214879],
            [-63.8822261, 46.621421],
            [-63.8818399, 46.6215417],
            [-63.8813807, 46.6216053],
            [-63.8806813, 46.6219038],
            [-63.8801395, 46.6223206],
            [-63.8793494, 46.6224143],
            [-63.8787119, 46.6229171],
            [-63.8783599, 46.6230503],
            [-63.8781911, 46.6230792],
            [-63.8778921, 46.6230301],
            [-63.8778889, 46.6228069],
            [-63.8781877, 46.6219275],
            [-63.8781813, 46.6216325],
            [-63.8777213, 46.620939],
            [-63.8778116, 46.6206895],
            [-63.8781625, 46.6202733],
            [-63.878356, 46.6198902],
            [-63.878755, 46.6188054],
            [-63.8788501, 46.6181137],
            [-63.8791798, 46.6170237],
            [-63.8791314, 46.616461],
            [-63.8785022, 46.6153421],
            [-63.8782983, 46.6144499],
            [-63.8783441, 46.6138411],
            [-63.8785088, 46.6136089],
            [-63.8789799, 46.6132267],
            [-63.8791241, 46.6129264],
            [-63.8790469, 46.6125146],
            [-63.8789249, 46.6122176],
            [-63.8788098, 46.6118928],
            [-63.8787695, 46.6111111],
            [-63.878209, 46.6101687],
            [-63.8775461, 46.6094916],
            [-63.8764631, 46.6090739],
            [-63.875835, 46.609015],
            [-63.8753855, 46.6088198],
            [-63.8753337, 46.6086595],
            [-63.8755152, 46.6084435],
            [-63.8762206, 46.6082886],
            [-63.8765002, 46.6080784],
            [-63.8765601, 46.6070913],
            [-63.8764457, 46.6067466],
            [-63.8765025, 46.6064647],
            [-63.8766445, 46.6063755],
            [-63.8780791, 46.6063571],
            [-63.8792299, 46.6061941],
            [-63.880592, 46.6059472],
            [-63.8818422, 46.6055987],
            [-63.8826165, 46.6052976],
            [-63.882894, 46.6052985],
            [-63.8830599, 46.6055006],
            [-63.8830994, 46.6061508],
            [-63.8833599, 46.6066097],
            [-63.8842171, 46.6076608],
            [-63.8847657, 46.6081446],
            [-63.8853737, 46.6085896],
            [-63.8864257, 46.6090664],
            [-63.8875292, 46.6094006],
            [-63.8885474, 46.609542],
            [-63.8892607, 46.6096422],
            [-63.8901092, 46.609681],
            [-63.8904953, 46.6095602],
            [-63.893274, 46.6094695],
            [-63.8938147, 46.6095467],
            [-63.8941674, 46.6095449],
            [-63.8957298, 46.6084127],
            [-63.8961727, 46.6081614],
            [-63.8967413, 46.6081076],
            [-63.8975672, 46.6082894],
            [-63.8978121, 46.6083893],
            [-63.8991276, 46.6090936],
            [-63.8997787, 46.6093122],
            [-63.900419, 46.6093552],
            [-63.9010334, 46.6094695],
            [-63.9011492, 46.609623],
            [-63.9008015, 46.6099596],
            [-63.900656, 46.6102997],
            [-63.9005858, 46.6106372],
            [-63.9006501, 46.6109332],
            [-63.9010089, 46.6113977],
            [-63.901156, 46.6116433],
            [-63.9013267, 46.6123516],
            [-63.9011512, 46.6135081],
            [-63.9010993, 46.6149218],
            [-63.9008438, 46.6154234],
            [-63.9008547, 46.6160731],
            [-63.9009489, 46.6165011],
            [-63.9011596, 46.6165885],
            [-63.9013447, 46.6162729],
            [-63.9014552, 46.6159401],
            [-63.9017476, 46.6156942],
            [-63.9020174, 46.6155913],
            [-63.9025907, 46.6150952],
            [-63.9030346, 46.6149754],
            [-63.903509, 46.6149677],
            [-63.9039109, 46.6152058],
            [-63.9042416, 46.6156499],
            [-63.9042498, 46.6160565],
            [-63.9041724, 46.6165931],
            [-63.90465, 46.6171314],
            [-63.9050909, 46.6175654],
            [-63.9052704, 46.6180347],
            [-63.9055756, 46.6182273],
            [-63.906563, 46.6184278],
            [-63.9075508, 46.6184569],
            [-63.9081021, 46.6184067],
            [-63.9089449, 46.6181304],
            [-63.9092992, 46.6180887],
            [-63.9093952, 46.6181541],
            [-63.9094401, 46.6181908],
            [-63.9097683, 46.6198143],
            [-63.9098043, 46.6204127],
            [-63.910274, 46.6211699],
            [-63.9107233, 46.6215364],
            [-63.9109334, 46.6216436],
            [-63.9114492, 46.6217721],
            [-63.91208, 46.6219225],
            [-63.9130115, 46.6219107],
            [-63.9140531, 46.6217374],
            [-63.9149429, 46.6217608],
            [-63.915958, 46.6216787],
            [-63.9164047, 46.6218019],
            [-63.9167663, 46.6220353],
            [-63.9169986, 46.6221708],
            [-63.9177342, 46.623096],
            [-63.9180687, 46.6234405],
            [-63.9190036, 46.6239747],
            [-63.9190423, 46.6243419],
            [-63.9189877, 46.6244127],
            [-63.9190454, 46.62495],
            [-63.9200721, 46.62495],
            [-63.9201834, 46.6244572],
            [-63.9206667, 46.6238877],
            [-63.9210368, 46.6237307],
            [-63.9214978, 46.6237785],
            [-63.9221809, 46.6242406],
            [-63.9226679, 46.6243685],
            [-63.9238089, 46.6244837],
            [-63.9244336, 46.6246419],
            [-63.9252358, 46.6245321],
            [-63.9248812, 46.6244224],
            [-63.9244484, 46.6243951],
            [-63.9238458, 46.6242652],
            [-63.9230937, 46.6239494],
            [-63.922722, 46.6236721],
            [-63.9226539, 46.6234756],
            [-63.9229695, 46.6230666],
            [-63.9237336, 46.6225697],
            [-63.9242139, 46.6224026],
            [-63.9257478, 46.6223692],
            [-63.9261973, 46.6224127],
            [-63.9273319, 46.6223844],
            [-63.9281294, 46.6222426],
            [-63.9283178, 46.6221502],
            [-63.9264272, 46.6221337],
            [-63.9258278, 46.6220757],
            [-63.925206, 46.6218378],
            [-63.9248028, 46.6217911],
            [-63.9238555, 46.6219183],
            [-63.9231115, 46.6221804],
            [-63.9224616, 46.6227192],
            [-63.9217736, 46.6228707],
            [-63.9201465, 46.6230818],
            [-63.9197873, 46.6229401],
            [-63.9195841, 46.6224824],
            [-63.9191181, 46.621777],
            [-63.9185921, 46.6211304],
            [-63.9177964, 46.6205867],
            [-63.9172606, 46.6203702],
            [-63.9150798, 46.6200656],
            [-63.9142743, 46.6199521],
            [-63.9141851, 46.6198589],
            [-63.9142669, 46.619677],
            [-63.9149084, 46.6190545],
            [-63.9155176, 46.6186825],
            [-63.9170527, 46.6181351],
            [-63.9172457, 46.6180747],
            [-63.9181928, 46.6174734],
            [-63.9185889, 46.6173966],
            [-63.9186607, 46.6171707],
            [-63.9193983, 46.616765],
            [-63.919665, 46.6162675],
            [-63.9195245, 46.6159941],
            [-63.9195378, 46.6157871],
            [-63.9196296, 46.6156492],
            [-63.9203261, 46.6152588],
            [-63.9204656, 46.6150779],
            [-63.9208963, 46.6148422],
            [-63.9214936, 46.6146373],
            [-63.921738, 46.6144343],
            [-63.921854, 46.614265],
            [-63.9220727, 46.6141333],
            [-63.9227323, 46.6142841],
            [-63.922799, 46.6141975],
            [-63.922722, 46.6140886],
            [-63.9225701, 46.6138111],
            [-63.9226038, 46.6135208],
            [-63.922992, 46.6133402],
            [-63.9233765, 46.6132591],
            [-63.9243293, 46.6132954],
            [-63.9252697, 46.613196],
            [-63.9261027, 46.613027],
            [-63.9265108, 46.6129344],
            [-63.9268586, 46.6127491],
            [-63.9270895, 46.6122789],
            [-63.9275091, 46.6118677],
            [-63.9282505, 46.6115138],
            [-63.9271675, 46.6117223],
            [-63.9269453, 46.6116309],
            [-63.9268893, 46.6114187],
            [-63.9271594, 46.6111444],
            [-63.9277345, 46.6109112],
            [-63.9299017, 46.6096573],
            [-63.9303006, 46.609178],
            [-63.9308434, 46.6077528],
            [-63.9308927, 46.6073473],
            [-63.9307304, 46.6071971],
            [-63.9305974, 46.6071988],
            [-63.9302712, 46.6082292],
            [-63.9300963, 46.6085888],
            [-63.9298954, 46.6088683],
            [-63.9286989, 46.6096606],
            [-63.9269192, 46.610431],
            [-63.9260879, 46.6110344],
            [-63.925852, 46.6113212],
            [-63.9258187, 46.611763],
            [-63.9256532, 46.6118637],
            [-63.9248231, 46.6119531],
            [-63.9243222, 46.6120521],
            [-63.9232895, 46.6123093],
            [-63.9229613, 46.6124311],
            [-63.921785, 46.6128173],
            [-63.9206632, 46.6134555],
            [-63.9201593, 46.6139569],
            [-63.9198978, 46.6139922],
            [-63.9193503, 46.6139429],
            [-63.9189118, 46.6140749],
            [-63.9185578, 46.614268],
            [-63.9182994, 46.6146979],
            [-63.9179585, 46.6150068],
            [-63.9174725, 46.6153331],
            [-63.9170983, 46.6160838],
            [-63.9168155, 46.6165451],
            [-63.9164972, 46.616711],
            [-63.9161672, 46.6167212],
            [-63.9154441, 46.6167286],
            [-63.9131054, 46.616477],
            [-63.9125191, 46.6160605],
            [-63.9121694, 46.6156599],
            [-63.9115179, 46.61529],
            [-63.9102164, 46.6149845],
            [-63.9097069, 46.6149996],
            [-63.9090989, 46.6151804],
            [-63.9088617, 46.6151842],
            [-63.9088022, 46.6150716],
            [-63.9092933, 46.6141318],
            [-63.9088172, 46.6135537],
            [-63.9086732, 46.6133798],
            [-63.9087433, 46.6130424],
            [-63.9096149, 46.6118182],
            [-63.9097069, 46.6113576],
            [-63.9100502, 46.6111404],
            [-63.9119089, 46.6105908],
            [-63.9124741, 46.6103137],
            [-63.9145162, 46.6090142],
            [-63.9145812, 46.6088161],
            [-63.9144853, 46.6087507],
            [-63.9143166, 46.6087796],
            [-63.9128702, 46.60944],
            [-63.9126772, 46.6095004],
            [-63.9120389, 46.6097204],
            [-63.9110819, 46.6098035],
            [-63.9107694, 46.60981],
            [-63.9104077, 46.6098994],
            [-63.9098602, 46.6103242],
            [-63.9096216, 46.6103679],
            [-63.909043, 46.6103778],
            [-63.9074937, 46.6100521],
            [-63.9071414, 46.6098826],
            [-63.9070268, 46.6095379],
            [-63.9071171, 46.6089657],
            [-63.9067539, 46.6086207],
            [-63.9064019, 46.6086026],
            [-63.9060625, 46.6083975],
            [-63.9054092, 46.6082387],
            [-63.9048908, 46.6078671],
            [-63.904542, 46.6074466],
            [-63.9040849, 46.6072992],
            [-63.9037513, 46.6069348],
            [-63.9031861, 46.6067377],
            [-63.902897, 46.6065813],
            [-63.9027295, 46.606419],
            [-63.902909, 46.6059399],
            [-63.9031918, 46.6054787],
            [-63.903397, 46.6044542],
            [-63.9033543, 46.6043578],
            [-63.9035275, 46.6023127],
            [-63.9037324, 46.6020852],
            [-63.9041045, 46.6018685],
            [-63.9043252, 46.6015257],
            [-63.9046806, 46.6012928],
            [-63.9050073, 46.6012108],
            [-63.9056039, 46.6011774],
            [-63.9054806, 46.6010716],
            [-63.904665, 46.6010853],
            [-63.9040883, 46.6012068],
            [-63.9034649, 46.6016542],
            [-63.9024876, 46.6018204],
            [-63.9019776, 46.6018554],
            [-63.9014152, 46.6019014],
            [-63.9009748, 46.6017702],
            [-63.9001797, 46.6012264],
            [-63.8998736, 46.6009023],
            [-63.8996501, 46.6003764],
            [-63.8993791, 46.6000449],
            [-63.899372, 46.5999213],
            [-63.9002093, 46.5991589],
            [-63.9010181, 46.5986989],
            [-63.9016799, 46.5979932],
            [-63.9021642, 46.5975554],
            [-63.9021882, 46.5973725],
            [-63.9019974, 46.5973732],
            [-63.9014612, 46.5976507],
            [-63.9010136, 46.5977186],
            [-63.9005574, 46.5975513],
            [-63.8998203, 46.5971599],
            [-63.8994902, 46.5970187],
            [-63.8991883, 46.5968979],
            [-63.8981527, 46.5966088],
            [-63.89804, 46.5965272],
            [-63.8981505, 46.5961944],
            [-63.8980637, 46.5960415],
            [-63.8984586, 46.5958332],
            [-63.8984157, 46.5955854],
            [-63.8986155, 46.5954973],
            [-63.8990373, 46.5955007],
            [-63.9003067, 46.5950885],
            [-63.9008603, 46.5948073],
            [-63.9010523, 46.5946154],
            [-63.9012415, 46.594029],
            [-63.9016271, 46.5937568],
            [-63.9016646, 46.593367],
            [-63.9014479, 46.5928134],
            [-63.9015906, 46.5927043],
            [-63.9025162, 46.5926806],
            [-63.9035215, 46.5930089],
            [-63.9038848, 46.5930311],
            [-63.9041877, 46.5928093],
            [-63.9048874, 46.5916939],
            [-63.9050645, 46.5912746],
            [-63.9052686, 46.591067],
            [-63.9057593, 46.5909241],
            [-63.9058375, 46.5908418],
            [-63.9056994, 46.5905087],
            [-63.9059105, 46.5904247],
            [-63.9078285, 46.5907649],
            [-63.9089031, 46.5912497],
            [-63.9096399, 46.5918587],
            [-63.9097005, 46.5918953],
            [-63.9097445, 46.5919281],
            [-63.9098006, 46.59197],
            [-63.9098346, 46.5920035],
            [-63.9098858, 46.5920654],
            [-63.9099033, 46.5920899],
            [-63.9099151, 46.5921207],
            [-63.9099371, 46.5921577],
            [-63.9099517, 46.592213],
            [-63.9099809, 46.5922731],
            [-63.9100371, 46.5923152],
            [-63.9100736, 46.5923385],
            [-63.9101297, 46.5923569],
            [-63.9101687, 46.5923569],
            [-63.9102179, 46.592333],
            [-63.9102335, 46.5923152],
            [-63.9102993, 46.5923251],
            [-63.9103627, 46.5923353],
            [-63.9104018, 46.5923419],
            [-63.9104627, 46.5923569],
            [-63.9104958, 46.5923898],
            [-63.9105226, 46.592425],
            [-63.9105481, 46.5924686],
            [-63.9105797, 46.5925122],
            [-63.9106626, 46.5925676],
            [-63.9107066, 46.5926011],
            [-63.9107651, 46.5926312],
            [-63.9108176, 46.5926562],
            [-63.9108504, 46.5927049],
            [-63.9108883, 46.59274],
            [-63.9109225, 46.5927703],
            [-63.910942, 46.5928104],
            [-63.9109762, 46.5928499],
            [-63.9110592, 46.5929371],
            [-63.9110785, 46.5929796],
            [-63.9110884, 46.5930193],
            [-63.9110957, 46.5930544],
            [-63.9111128, 46.5930796],
            [-63.9111541, 46.5931138],
            [-63.9111712, 46.5931489],
            [-63.9111811, 46.5931701],
            [-63.9111981, 46.5932019],
            [-63.9112272, 46.5932193],
            [-63.9112346, 46.5932494],
            [-63.911215, 46.5932712],
            [-63.9111835, 46.5933108],
            [-63.9111835, 46.5933671],
            [-63.9112396, 46.5934073],
            [-63.9113102, 46.5934526],
            [-63.911423, 46.59348],
            [-63.9114835, 46.593408],
            [-63.9114996, 46.5933686],
            [-63.9114996, 46.5933453],
            [-63.9114647, 46.5932384],
            [-63.9113829, 46.5930995],
            [-63.9113869, 46.5930504],
            [-63.9113869, 46.5929896],
            [-63.9113681, 46.5929343],
            [-63.9113842, 46.5928772],
            [-63.9113601, 46.59282],
            [-63.9113279, 46.5927832],
            [-63.9112957, 46.5927389],
            [-63.9112716, 46.5927002],
            [-63.9112608, 46.5926468],
            [-63.9112743, 46.5926007],
            [-63.911293, 46.5925675],
            [-63.9112957, 46.5925288],
            [-63.9112555, 46.5924993],
            [-63.9112152, 46.5924735],
            [-63.9111696, 46.5924477],
            [-63.9110972, 46.5924164],
            [-63.9110463, 46.5923629],
            [-63.9110033, 46.5922966],
            [-63.9109604, 46.5922431],
            [-63.9108934, 46.5921952],
            [-63.9108451, 46.5921565],
            [-63.9108075, 46.592127],
            [-63.9107834, 46.5920477],
            [-63.9107834, 46.5919869],
            [-63.9107754, 46.5919095],
            [-63.9107968, 46.5918432],
            [-63.91088, 46.591751],
            [-63.9109873, 46.5916625],
            [-63.911108, 46.5915869],
            [-63.9112796, 46.591504],
            [-63.9115291, 46.5913971],
            [-63.9116793, 46.5913252],
            [-63.9117356, 46.5912644],
            [-63.9117758, 46.5912072],
            [-63.9118295, 46.5911298],
            [-63.9119019, 46.5910524],
            [-63.9120199, 46.5909842],
            [-63.9120923, 46.5908792],
            [-63.9121701, 46.5907575],
            [-63.9122801, 46.5906856],
            [-63.9124061, 46.5905842],
            [-63.9125483, 46.59054],
            [-63.9127924, 46.5904534],
            [-63.9129453, 46.5903907],
            [-63.9130713, 46.5902949],
            [-63.9131518, 46.5902137],
            [-63.9132685, 46.5900895],
            [-63.913365, 46.5899955],
            [-63.9134294, 46.5899402],
            [-63.9134737, 46.5898783],
            [-63.9133422, 46.5898967],
            [-63.9132671, 46.589928],
            [-63.9131142, 46.5899926],
            [-63.9129721, 46.5900792],
            [-63.9127817, 46.5901787],
            [-63.9124866, 46.5903354],
            [-63.9122801, 46.5904257],
            [-63.9120253, 46.590516],
            [-63.9115854, 46.5906985],
            [-63.9113789, 46.5907796],
            [-63.9110731, 46.5908607],
            [-63.9108397, 46.5909105],
            [-63.9107512, 46.5909252],
            [-63.9106734, 46.5909252],
            [-63.9105259, 46.5909234],
            [-63.9104052, 46.5909179],
            [-63.9103167, 46.5909142],
            [-63.9101826, 46.5909105],
            [-63.9101397, 46.5908957],
            [-63.910031, 46.590836],
            [-63.909905, 46.5907236],
            [-63.9098634, 46.5906801],
            [-63.9098513, 46.5906222],
            [-63.9098057, 46.5905227],
            [-63.9097333, 46.5903918],
            [-63.9096488, 46.5902617],
            [-63.9094302, 46.5900619],
            [-63.9093283, 46.5899844],
            [-63.9092022, 46.5899162],
            [-63.9091178, 46.5898838],
            [-63.9090239, 46.589823],
            [-63.9089059, 46.5897511],
            [-63.9088562, 46.5897116],
            [-63.9087865, 46.5896453],
            [-63.9087422, 46.5895741],
            [-63.9087288, 46.5895078],
            [-63.9087127, 46.5894119],
            [-63.9087047, 46.5893235],
            [-63.9087114, 46.5891531],
            [-63.908871, 46.5887207],
            [-63.9089354, 46.588588],
            [-63.9089702, 46.5885345],
            [-63.9090145, 46.5884859],
            [-63.9090346, 46.5884424],
            [-63.909052, 46.5883863],
            [-63.9090775, 46.5883262],
            [-63.9090923, 46.5882554],
            [-63.9091312, 46.5882248],
            [-63.909158, 46.5881879],
            [-63.9092063, 46.588116],
            [-63.9092438, 46.5880662],
            [-63.909276, 46.588022],
            [-63.9093377, 46.5879722],
            [-63.9094101, 46.5879077],
            [-63.9094959, 46.5878432],
            [-63.9095831, 46.5876931],
            [-63.9096676, 46.5876054],
            [-63.9097937, 46.587434],
            [-63.909846, 46.5873558],
            [-63.9098688, 46.5873031],
            [-63.9098902, 46.5872478],
            [-63.9098755, 46.5871899],
            [-63.909858, 46.5871464],
            [-63.9098433, 46.5870738],
            [-63.9098902, 46.5870966],
            [-63.9099948, 46.5871335],
            [-63.9100646, 46.5871777],
            [-63.9101209, 46.5872404],
            [-63.9102416, 46.5873197],
            [-63.9103382, 46.5873658],
            [-63.9105259, 46.5874524],
            [-63.9105822, 46.5874764],
            [-63.9107003, 46.5875243],
            [-63.9107646, 46.5875538],
            [-63.9108236, 46.5875575],
            [-63.9108612, 46.5875556],
            [-63.9109269, 46.5875862],
            [-63.9109899, 46.587598],
            [-63.9110329, 46.5876091],
            [-63.9111106, 46.5876275],
            [-63.9111965, 46.5876478],
            [-63.9112796, 46.5876681],
            [-63.9114057, 46.5876847],
            [-63.9114674, 46.5877012],
            [-63.9115559, 46.5877178],
            [-63.9116283, 46.5877234],
            [-63.9117168, 46.5877307],
            [-63.9119287, 46.5877381],
            [-63.9120937, 46.5877503],
            [-63.9122278, 46.5877484],
            [-63.9123351, 46.5877355],
            [-63.9124209, 46.5877171],
            [-63.9125067, 46.587695],
            [-63.9125859, 46.5876644],
            [-63.9126811, 46.5876139],
            [-63.9127977, 46.5875446],
            [-63.9129898, 46.5874389],
            [-63.9130702, 46.5873635],
            [-63.9131629, 46.5872865],
            [-63.913236, 46.5872077],
            [-63.9132825, 46.5871301],
            [-63.9133287, 46.587076],
            [-63.9133394, 46.5870064],
            [-63.9133206, 46.5869787],
            [-63.9133206, 46.58694],
            [-63.913326, 46.5868571],
            [-63.9133662, 46.5867852],
            [-63.9134038, 46.5867391],
            [-63.9134333, 46.5866727],
            [-63.9134387, 46.5866469],
            [-63.9134333, 46.5865935],
            [-63.9134011, 46.5865658],
            [-63.9134252, 46.5865363],
            [-63.913436, 46.5864773],
            [-63.9134333, 46.5864294],
            [-63.9134467, 46.5863944],
            [-63.9134601, 46.5863594],
            [-63.9134452, 46.5863435],
            [-63.9134279, 46.5863243],
            [-63.9134279, 46.5862838],
            [-63.9134467, 46.5862746],
            [-63.9134494, 46.5862377],
            [-63.9134172, 46.5862119],
            [-63.9134172, 46.5861548],
            [-63.9134306, 46.5861068],
            [-63.9134494, 46.5860958],
            [-63.9134789, 46.5860921],
            [-63.9134735, 46.5859409],
            [-63.913444, 46.5859077],
            [-63.9134252, 46.5858506],
            [-63.9134279, 46.5857805],
            [-63.9134252, 46.58574],
            [-63.9134226, 46.5857031],
            [-63.9134065, 46.585657],
            [-63.9134011, 46.5856368],
            [-63.9133823, 46.5855925],
            [-63.9134038, 46.5855796],
            [-63.9134226, 46.585598],
            [-63.913436, 46.5855354],
            [-63.9134145, 46.5854911],
            [-63.9134252, 46.5854561],
            [-63.9134279, 46.585434],
            [-63.9133957, 46.5853363],
            [-63.9133877, 46.5852884],
            [-63.913385, 46.5852423],
            [-63.9133743, 46.5852054],
            [-63.9133662, 46.5851833],
            [-63.9133448, 46.5851778],
            [-63.9133299, 46.5851619],
            [-63.9133206, 46.5851354],
            [-63.9132992, 46.5851003],
            [-63.9132831, 46.5850671],
            [-63.913267, 46.5850341],
            [-63.913267, 46.5850101],
            [-63.9132911, 46.5850101],
            [-63.9133153, 46.5850212],
            [-63.9133019, 46.5849714],
            [-63.9132804, 46.5849548],
            [-63.9132563, 46.5849235],
            [-63.9132697, 46.5849069],
            [-63.9132402, 46.5848313],
            [-63.9132268, 46.5847907],
            [-63.9131919, 46.5847686],
            [-63.9131919, 46.5847483],
            [-63.9131517, 46.5847446],
            [-63.9131329, 46.5847281],
            [-63.9131195, 46.5846986],
            [-63.9130965, 46.5846716],
            [-63.9130846, 46.5846285],
            [-63.9130766, 46.5845861],
            [-63.9130766, 46.5845529],
            [-63.9130766, 46.5845271],
            [-63.913047, 46.5844755],
            [-63.9130363, 46.5844497],
            [-63.913039, 46.5844202],
            [-63.9129988, 46.5843686],
            [-63.9129773, 46.5843151],
            [-63.9129478, 46.5842635],
            [-63.9128781, 46.5841584],
            [-63.9128352, 46.5840994],
            [-63.9128271, 46.5840626],
            [-63.9128095, 46.584032],
            [-63.912811, 46.5839944],
            [-63.912772, 46.583938],
            [-63.9127559, 46.5838992],
            [-63.9127264, 46.5838734],
            [-63.9127145, 46.5838377],
            [-63.9126796, 46.5837658],
            [-63.9126447, 46.5837142],
            [-63.9126098, 46.5836589],
            [-63.9125655, 46.5835969],
            [-63.9125964, 46.5835925],
            [-63.9126233, 46.5835833],
            [-63.9126286, 46.5835704],
            [-63.912575, 46.5835114],
            [-63.9125643, 46.5834745],
            [-63.9124865, 46.5833068],
            [-63.9124355, 46.5832035],
            [-63.9124126, 46.5831748],
            [-63.9124087, 46.5831501],
            [-63.912406, 46.5831187],
            [-63.9124033, 46.5830763],
            [-63.9123738, 46.5830284],
            [-63.9123711, 46.582997],
            [-63.9123121, 46.5829436],
            [-63.9123121, 46.5829233],
            [-63.9123416, 46.5829288],
            [-63.9123443, 46.5828993],
            [-63.9123416, 46.582868],
            [-63.9123524, 46.5828422],
            [-63.912355, 46.5828219],
            [-63.9123577, 46.5827906],
            [-63.9123497, 46.5827611],
            [-63.912355, 46.5827371],
            [-63.9123819, 46.5827076],
            [-63.9124033, 46.5826929],
            [-63.9124582, 46.5826254],
            [-63.9124677, 46.5826025],
            [-63.9125133, 46.5824919],
            [-63.9125401, 46.5824587],
            [-63.9125643, 46.5824274],
            [-63.9125938, 46.582409],
            [-63.9126447, 46.5823813],
            [-63.9126876, 46.5823684],
            [-63.9127627, 46.5823426],
            [-63.9128244, 46.5823223],
            [-63.912862, 46.5823076],
            [-63.912921, 46.5823002],
            [-63.9129961, 46.5822873],
            [-63.9130605, 46.5822799],
            [-63.9131248, 46.5822725],
            [-63.9131973, 46.5822725],
            [-63.9132777, 46.5822781],
            [-63.9134521, 46.5822689],
            [-63.9134896, 46.5822652],
            [-63.913554, 46.5822725],
            [-63.9135835, 46.5822855],
            [-63.9136291, 46.5822855],
            [-63.9136854, 46.5822928],
            [-63.9138276, 46.582326],
            [-63.9139027, 46.5823426],
            [-63.913908, 46.5823629],
            [-63.9139483, 46.5823813],
            [-63.9139965, 46.5824163],
            [-63.9140556, 46.582444],
            [-63.9141816, 46.5825048],
            [-63.914254, 46.5825214],
            [-63.9142889, 46.5825196],
            [-63.9142943, 46.5825362],
            [-63.9143399, 46.5825675],
            [-63.914415, 46.5826007],
            [-63.9145249, 46.5826431],
            [-63.9145705, 46.5826726],
            [-63.9146296, 46.5827353],
            [-63.9147154, 46.5827943],
            [-63.914769, 46.5828164],
            [-63.9148066, 46.5828348],
            [-63.9148334, 46.5828569],
            [-63.9148909, 46.5828761],
            [-63.9149473, 46.582889],
            [-63.9149916, 46.582903],
            [-63.9150411, 46.5829185],
            [-63.9150775, 46.5829233],
            [-63.9151431, 46.5829462],
            [-63.9152116, 46.5829694],
            [-63.9152706, 46.5830007],
            [-63.9153778, 46.5830254],
            [-63.9154664, 46.5830449],
            [-63.9155751, 46.5830625],
            [-63.9156515, 46.5830744],
            [-63.9157132, 46.5830799],
            [-63.9157615, 46.5831002],
            [-63.9157964, 46.5831076],
            [-63.915858, 46.5831094],
            [-63.9159197, 46.5831131],
            [-63.9160083, 46.5831002],
            [-63.9160458, 46.5831002],
            [-63.9160941, 46.5830799],
            [-63.9161745, 46.5830596],
            [-63.9162362, 46.5830412],
            [-63.9162979, 46.5830412],
            [-63.9163757, 46.5830301],
            [-63.9165071, 46.5830135],
            [-63.9165903, 46.5830154],
            [-63.9166708, 46.5829988],
            [-63.916711, 46.5829859],
            [-63.9167673, 46.5829768],
            [-63.9168183, 46.5829822],
            [-63.91688, 46.5829785],
            [-63.916939, 46.5829877],
            [-63.9169926, 46.5829988],
            [-63.9170516, 46.5829951],
            [-63.917089, 46.5829822],
            [-63.9171078, 46.5829767],
            [-63.91714, 46.5829951],
            [-63.9171722, 46.5830154],
            [-63.9172097, 46.5830246],
            [-63.917258, 46.5830265],
            [-63.917309, 46.5830283],
            [-63.9173948, 46.5830486],
            [-63.917478, 46.5830818],
            [-63.9175718, 46.5830983],
            [-63.9176255, 46.5831149],
            [-63.9177194, 46.5831389],
            [-63.917773, 46.5831592],
            [-63.9178535, 46.5831795],
            [-63.9179098, 46.5832053],
            [-63.9179795, 46.5832182],
            [-63.9180668, 46.5832339],
            [-63.9180976, 46.5832348],
            [-63.9181324, 46.5832532],
            [-63.9181914, 46.5832716],
            [-63.918229, 46.5832901],
            [-63.918296, 46.5832974],
            [-63.9183282, 46.5832974],
            [-63.9183524, 46.5833031],
            [-63.9183846, 46.5833214],
            [-63.9185268, 46.583327],
            [-63.9185805, 46.583327],
            [-63.9186475, 46.583327],
            [-63.9186877, 46.583327],
            [-63.9187494, 46.583327],
            [-63.9188111, 46.5833252],
            [-63.9188594, 46.5833197],
            [-63.918905, 46.5833141],
            [-63.9189801, 46.5833123],
            [-63.9190633, 46.5833215],
            [-63.919251, 46.5833307],
            [-63.9193422, 46.5833344],
            [-63.9194146, 46.5833197],
            [-63.919538, 46.5833012],
            [-63.9196721, 46.583292],
            [-63.9197338, 46.5832828],
            [-63.9198465, 46.5832422],
            [-63.9199081, 46.5832256],
            [-63.9199698, 46.5832164],
            [-63.9200584, 46.5832183],
            [-63.9201549, 46.5832183],
            [-63.9202139, 46.5832091],
            [-63.9202845, 46.583221],
            [-63.9203076, 46.5832353],
            [-63.9210134, 46.5832116],
            [-63.92139, 46.5830269],
            [-63.9216228, 46.5828197],
            [-63.9217018, 46.5823948],
            [-63.9213662, 46.5817675],
            [-63.9204285, 46.5805362],
            [-63.9201931, 46.5801775],
            [-63.9198746, 46.5797178],
            [-63.9199068, 46.5794674],
            [-63.9200387, 46.5793342],
            [-63.9204067, 46.5790656],
            [-63.9209407, 46.5789991],
            [-63.9220231, 46.5792648],
            [-63.9225246, 46.5792973],
            [-63.9228632, 46.5791996],
            [-63.9233835, 46.5787145],
            [-63.9239052, 46.5778669],
            [-63.9241933, 46.577569],
            [-63.9243969, 46.5775327],
            [-63.9253513, 46.5776606],
            [-63.9257591, 46.577568],
            [-63.9267886, 46.5772192],
            [-63.927649, 46.5770706],
            [-63.9280572, 46.5768067],
            [-63.9280783, 46.5767034],
            [-63.9251586, 46.5773984],
            [-63.9242262, 46.5772987],
            [-63.9237076, 46.5774212],
            [-63.923524, 46.5775456],
            [-63.9230835, 46.5783826],
            [-63.9226571, 46.5786701],
            [-63.92224, 46.5786988],
            [-63.9216979, 46.5785102],
            [-63.9211904, 46.5784855],
            [-63.9209398, 46.5785449],
            [-63.9201171, 46.5786065],
            [-63.9193949, 46.5787653],
            [-63.9188964, 46.5791273],
            [-63.9187238, 46.5794271],
            [-63.9187132, 46.5798771],
            [-63.9189579, 46.5802997],
            [-63.9190194, 46.5806752],
            [-63.9196924, 46.581396],
            [-63.9196991, 46.581691],
            [-63.9193485, 46.5819558],
            [-63.9187461, 46.5819972],
            [-63.9179898, 46.5818208],
            [-63.917834, 46.5818142],
            [-63.9173499, 46.5817779],
            [-63.9171842, 46.5817273],
            [-63.9156374, 46.581836],
            [-63.9153516, 46.5817514],
            [-63.9152822, 46.5815948],
            [-63.9156232, 46.5811146],
            [-63.9155896, 46.5806081],
            [-63.915281, 46.5792441],
            [-63.9153248, 46.5786752],
            [-63.9155, 46.5781443],
            [-63.9157297, 46.5778654],
            [-63.9165208, 46.5775604],
            [-63.9169112, 46.5774715],
            [-63.9171928, 46.5773528],
            [-63.9176507, 46.5768348],
            [-63.9179346, 46.5763338],
            [-63.9179319, 46.5760907],
            [-63.9179295, 46.575999],
            [-63.9178076, 46.5758535],
            [-63.9176422, 46.5759542],
            [-63.9170544, 46.5768684],
            [-63.9165959, 46.5770836],
            [-63.9157058, 46.5772515],
            [-63.9153257, 46.5775357],
            [-63.9150454, 46.5784114],
            [-63.9148175, 46.5788019],
            [-63.9146246, 46.5788623],
            [-63.9143522, 46.5790448],
            [-63.9142838, 46.5794938],
            [-63.9143249, 46.5799527],
            [-63.9144844, 46.580334],
            [-63.9144995, 46.5807127],
            [-63.9142994, 46.5809722],
            [-63.9140498, 46.5811631],
            [-63.9130519, 46.5814327],
            [-63.9129015, 46.5814381],
            [-63.912167, 46.5816126],
            [-63.910765, 46.5823493],
            [-63.910154, 46.582781],
            [-63.9100327, 46.5829382],
            [-63.9099112, 46.5834182],
            [-63.9099763, 46.5836943],
            [-63.9103609, 46.5842388],
            [-63.9112913, 46.5848725],
            [-63.9117822, 46.5853551],
            [-63.9118759, 46.5854802],
            [-63.9119388, 46.585816],
            [-63.9118314, 46.5860691],
            [-63.9115632, 46.5861322],
            [-63.9107937, 46.58584],
            [-63.9101949, 46.5857818],
            [-63.9094284, 46.5858323],
            [-63.9093613, 46.5858307],
            [-63.9092931, 46.5858458],
            [-63.9092564, 46.5858559],
            [-63.9092186, 46.5858624],
            [-63.9091858, 46.585886],
            [-63.9091284, 46.5859261],
            [-63.9090943, 46.5859395],
            [-63.9090345, 46.5859599],
            [-63.9089748, 46.5859831],
            [-63.9088895, 46.5860484],
            [-63.9088761, 46.5860621],
            [-63.9088297, 46.586092],
            [-63.9088053, 46.5861105],
            [-63.9087663, 46.5861439],
            [-63.9087321, 46.5861875],
            [-63.9087077, 46.5862227],
            [-63.9086492, 46.5863024],
            [-63.908637, 46.5863192],
            [-63.9085931, 46.5863577],
            [-63.9085687, 46.5863829],
            [-63.9085345, 46.5864114],
            [-63.9084785, 46.5864448],
            [-63.9084298, 46.586465],
            [-63.908403, 46.5864767],
            [-63.9083761, 46.5865069],
            [-63.9083517, 46.5865404],
            [-63.9083419, 46.5865806],
            [-63.9083127, 46.5866191],
            [-63.9082395, 46.5867156],
            [-63.908159, 46.5867944],
            [-63.9081429, 46.5868179],
            [-63.9081349, 46.586863],
            [-63.9081137, 46.5869218],
            [-63.9080869, 46.5869704],
            [-63.9080519, 46.5870105],
            [-63.908026, 46.5870458],
            [-63.908021, 46.5871162],
            [-63.9079796, 46.5871664],
            [-63.9078909, 46.5872266],
            [-63.9077918, 46.5872596],
            [-63.9077064, 46.5872898],
            [-63.9076423, 46.5873239],
            [-63.9075382, 46.5873651],
            [-63.9075082, 46.5873825],
            [-63.9074838, 46.5873992],
            [-63.9074301, 46.5874294],
            [-63.9073784, 46.587454],
            [-63.9073149, 46.5874792],
            [-63.9072687, 46.5874942],
            [-63.9072131, 46.5875132],
            [-63.9071619, 46.5875383],
            [-63.9071205, 46.5875651],
            [-63.9070741, 46.587597],
            [-63.9070302, 46.5876204],
            [-63.9069717, 46.5876405],
            [-63.9069418, 46.5876611],
            [-63.90692, 46.5876913],
            [-63.9068815, 46.5877277],
            [-63.9068736, 46.5877516],
            [-63.9068302, 46.5877863],
            [-63.9067955, 46.5878069],
            [-63.9067673, 46.5878304],
            [-63.9067429, 46.5878874],
            [-63.9067405, 46.5879175],
            [-63.9067137, 46.5879527],
            [-63.9066575, 46.5879845],
            [-63.9066259, 46.5879879],
            [-63.9065576, 46.5879929],
            [-63.9064966, 46.5880147],
            [-63.9064259, 46.5880147],
            [-63.9063965, 46.5880181],
            [-63.906321, 46.5880097],
            [-63.9062868, 46.5880097],
            [-63.9062381, 46.5880197],
            [-63.9061893, 46.5880365],
            [-63.9061577, 46.5880516],
            [-63.9061065, 46.5880482],
            [-63.906061, 46.5880533],
            [-63.9059353, 46.5880612],
            [-63.9058612, 46.5880432],
            [-63.9058086, 46.5880193],
            [-63.9057524, 46.5880294],
            [-63.9056807, 46.5880415],
            [-63.9056294, 46.5880431],
            [-63.9055782, 46.5880465],
            [-63.9055232, 46.5880595],
            [-63.9054891, 46.5880796],
            [-63.905449, 46.5880818],
            [-63.9053865, 46.5880734],
            [-63.9053305, 46.5880651],
            [-63.9052793, 46.5880534],
            [-63.9052452, 46.5880466],
            [-63.9052232, 46.588045],
            [-63.9052086, 46.5880566],
            [-63.9051599, 46.5880599],
            [-63.9051257, 46.5880616],
            [-63.9050794, 46.5880532],
            [-63.9050501, 46.5880649],
            [-63.9050306, 46.5880515],
            [-63.9050014, 46.5880515],
            [-63.9049526, 46.5880683],
            [-63.9048917, 46.5880666],
            [-63.9048623, 46.5880666],
            [-63.9048112, 46.5880716],
            [-63.9047219, 46.5880532],
            [-63.9046244, 46.5880482],
            [-63.9045683, 46.5880331],
            [-63.9044879, 46.5880347],
            [-63.9044269, 46.5880364],
            [-63.904378, 46.5880498],
            [-63.904344, 46.5880482],
            [-63.9042927, 46.5880347],
            [-63.9042293, 46.5880498],
            [-63.9041944, 46.5880478],
            [-63.9041708, 46.5880415],
            [-63.9041342, 46.5880331],
            [-63.9040806, 46.588028],
            [-63.9040611, 46.5879913],
            [-63.9040441, 46.5879628],
            [-63.9039943, 46.5879657],
            [-63.9039432, 46.5879573],
            [-63.9039139, 46.587907],
            [-63.9039085, 46.5878321],
            [-63.9039334, 46.5877545],
            [-63.9039481, 46.5876958],
            [-63.9039695, 46.5876082],
            [-63.9039938, 46.587558],
            [-63.9040256, 46.5874826],
            [-63.9040879, 46.5873568],
            [-63.9041195, 46.5872999],
            [-63.904161, 46.5872178],
            [-63.9041806, 46.5871547],
            [-63.9042237, 46.5870876],
            [-63.9042635, 46.5870257],
            [-63.9042903, 46.5869654],
            [-63.9043049, 46.5869017],
            [-63.9043195, 46.5868163],
            [-63.9043676, 46.5867676],
            [-63.904448, 46.586724],
            [-63.9045001, 46.5866461],
            [-63.9045731, 46.5865741],
            [-63.9046365, 46.5864684],
            [-63.9047065, 46.5864206],
            [-63.904738, 46.5863864],
            [-63.9047722, 46.5863395],
            [-63.9048063, 46.5862976],
            [-63.9048257, 46.5862742],
            [-63.9048843, 46.5862312],
            [-63.9049204, 46.5861659],
            [-63.9048183, 46.5861675],
            [-63.9046751, 46.5861918],
            [-63.9044658, 46.5862345],
            [-63.9042549, 46.5862364],
            [-63.9035817, 46.5867865],
            [-63.9032369, 46.5873662],
            [-63.9030643, 46.5878174],
            [-63.9030781, 46.5883874],
            [-63.9031322, 46.5884879],
            [-63.9033676, 46.5888466],
            [-63.9035219, 46.5893673],
            [-63.9035124, 46.589626],
            [-63.9033158, 46.5897859],
            [-63.9021571, 46.5898654],
            [-63.9007163, 46.589912],
            [-63.9004988, 46.5898524],
            [-63.8992768, 46.5892933],
            [-63.8983698, 46.5889707],
            [-63.8982526, 46.5888571],
            [-63.8990059, 46.5884877],
            [-63.8991954, 46.5882042],
            [-63.8992604, 46.5873805],
            [-63.8995131, 46.5866359],
            [-63.8994676, 46.5856709],
            [-63.8991056, 46.5854574],
            [-63.8988605, 46.5856802],
            [-63.8986837, 46.5864023],
            [-63.8985722, 46.5870777],
            [-63.8982747, 46.5873115],
            [-63.8973828, 46.5879932],
            [-63.8970371, 46.5885928],
            [-63.8968046, 46.5886286],
            [-63.8966209, 46.5887529],
            [-63.8973469, 46.5892402],
            [-63.897422, 46.5892948],
            [-63.8974777, 46.5893568],
            [-63.8975742, 46.5893994],
            [-63.8977137, 46.589426],
            [-63.8978103, 46.5894865],
            [-63.8979005, 46.5895542],
            [-63.8979155, 46.5896457],
            [-63.8979263, 46.5898241],
            [-63.8979241, 46.5899184],
            [-63.8978941, 46.5900629],
            [-63.8978683, 46.5901855],
            [-63.8977975, 46.5903963],
            [-63.8977718, 46.5905378],
            [-63.8977569, 46.5907236],
            [-63.8977761, 46.591073],
            [-63.8977718, 46.5912192],
            [-63.897703, 46.5913916],
            [-63.897598, 46.5915655],
            [-63.8975164, 46.5917321],
            [-63.8974584, 46.5917928],
            [-63.8973662, 46.5918354],
            [-63.8971665, 46.5918472],
            [-63.8968683, 46.5918767],
            [-63.896656, 46.5919328],
            [-63.8964328, 46.5919918],
            [-63.896231, 46.5919947],
            [-63.8958642, 46.5920566],
            [-63.895716, 46.5921098],
            [-63.8954071, 46.5922425],
            [-63.8949565, 46.5924502],
            [-63.8947313, 46.5925593],
            [-63.894388, 46.59276],
            [-63.8940338, 46.592965],
            [-63.8937658, 46.5931374],
            [-63.893534, 46.5932789],
            [-63.8932142, 46.5935178],
            [-63.8930874, 46.5936152],
            [-63.8929545, 46.5936711],
            [-63.8928193, 46.5937405],
            [-63.8927141, 46.593826],
            [-63.892639, 46.5939101],
            [-63.8926025, 46.5939824],
            [-63.892594, 46.5940856],
            [-63.8925082, 46.5941474],
            [-63.8923215, 46.5942817],
            [-63.8922634, 46.5943598],
            [-63.8922357, 46.594432],
            [-63.8922036, 46.5945145],
            [-63.8921756, 46.5945795],
            [-63.8921239, 46.5946976],
            [-63.892004, 46.5948743],
            [-63.8919074, 46.5949702],
            [-63.8916564, 46.5951633],
            [-63.8914675, 46.5952886],
            [-63.8913173, 46.5954154],
            [-63.8910876, 46.5955704],
            [-63.8909933, 46.5956618],
            [-63.8908623, 46.5957488],
            [-63.8907936, 46.5958226],
            [-63.8907293, 46.5958874],
            [-63.8906713, 46.5959345],
            [-63.8906005, 46.5959935],
            [-63.8904997, 46.5960585],
            [-63.8904353, 46.5961277],
            [-63.8904138, 46.5961897],
            [-63.8903108, 46.5962796],
            [-63.8902015, 46.596343],
            [-63.8900727, 46.5964566],
            [-63.8898752, 46.5966556],
            [-63.8897767, 46.5967573],
            [-63.8897358, 46.5968296],
            [-63.8896908, 46.5969461],
            [-63.8896372, 46.5970139],
            [-63.8895621, 46.5970831],
            [-63.889517, 46.5971539],
            [-63.8894182, 46.5972895],
            [-63.8893817, 46.5973633],
            [-63.8893475, 46.5974811],
            [-63.8893261, 46.5975681],
            [-63.8893153, 46.5976522],
            [-63.8893066, 46.5977319],
            [-63.8890167, 46.5977876],
            [-63.8887992, 46.5983536],
            [-63.8889236, 46.5987423],
            [-63.8893833, 46.5992843],
            [-63.8904753, 46.6002398],
            [-63.8909905, 46.6006911],
            [-63.8927347, 46.601674],
            [-63.8929699, 46.6018813],
            [-63.8928065, 46.6019223],
            [-63.8921931, 46.6019394],
            [-63.8911308, 46.6028412],
            [-63.8909909, 46.6028707],
            [-63.8905468, 46.6026876],
            [-63.8891687, 46.602747],
            [-63.8889504, 46.6027074],
            [-63.8887602, 46.6025367],
            [-63.8887538, 46.6022417],
            [-63.8888752, 46.6020844],
            [-63.8889481, 46.6019901],
            [-63.888845, 46.6016496],
            [-63.8852449, 46.5981795],
            [-63.8848205, 46.597933],
            [-63.8843405, 46.5976257],
            [-63.884326, 46.5975497],
            [-63.884186, 46.5974277],
            [-63.8835274, 46.5971053],
            [-63.8834452, 46.5969844],
            [-63.883569, 46.5969188],
            [-63.8842629, 46.5969111],
            [-63.8844035, 46.5968618],
            [-63.8845495, 46.5961989],
            [-63.8848996, 46.5959541],
            [-63.8850738, 46.5954631],
            [-63.8854164, 46.5952659],
            [-63.8854538, 46.5947247],
            [-63.8856026, 46.5946078],
            [-63.8855848, 46.5943086],
            [-63.8855132, 46.5942117],
            [-63.8855578, 46.5939456],
            [-63.8858212, 46.5936992],
            [-63.8857677, 46.5934273],
            [-63.8856908, 46.5933184],
            [-63.8857962, 46.593125],
            [-63.8860216, 46.5928142],
            [-63.8867488, 46.592062],
            [-63.8868505, 46.5919681],
            [-63.8867786, 46.5915684],
            [-63.8866052, 46.591414],
            [-63.8864809, 46.5913299],
            [-63.8864343, 46.5912827],
            [-63.8864235, 46.5912513],
            [-63.8864208, 46.5912071],
            [-63.8864343, 46.5911702],
            [-63.8864557, 46.5911278],
            [-63.8864772, 46.5910928],
            [-63.8865067, 46.5910467],
            [-63.8865212, 46.5910074],
            [-63.8865426, 46.5909724],
            [-63.8865775, 46.59093],
            [-63.8866016, 46.5908949],
            [-63.8866354, 46.5908643],
            [-63.8866794, 46.5908452],
            [-63.8867035, 46.5908378],
            [-63.8867518, 46.5908138],
            [-63.8867947, 46.5907733],
            [-63.8868403, 46.5907106],
            [-63.8868779, 46.5906553],
            [-63.886902, 46.5905982],
            [-63.8869315, 46.5905226],
            [-63.8869557, 46.5904802],
            [-63.8869959, 46.5904341],
            [-63.8870281, 46.590401],
            [-63.8870817, 46.5903586],
            [-63.8871112, 46.5903309],
            [-63.887122, 46.590283],
            [-63.8871407, 46.5902277],
            [-63.8871515, 46.590165],
            [-63.8871729, 46.5901226],
            [-63.8872078, 46.5900821],
            [-63.8872266, 46.590036],
            [-63.887248, 46.5899899],
            [-63.8872454, 46.5899328],
            [-63.887248, 46.5898812],
            [-63.8872588, 46.5898517],
            [-63.8872963, 46.5898203],
            [-63.8873419, 46.5897964],
            [-63.8873419, 46.5897669],
            [-63.8873097, 46.589754],
            [-63.8872856, 46.5897448],
            [-63.8872775, 46.5897171],
            [-63.8872936, 46.5896895],
            [-63.8873205, 46.5896821],
            [-63.887358, 46.5896858],
            [-63.8873714, 46.5896471],
            [-63.8873768, 46.5896047],
            [-63.8873848, 46.5895715],
            [-63.8873526, 46.5895475],
            [-63.8873419, 46.5895088],
            [-63.8873446, 46.5894738],
            [-63.8873238, 46.5894182],
            [-63.8871891, 46.589691],
            [-63.886903, 46.5900805],
            [-63.8868791, 46.5904147],
            [-63.8860666, 46.5903486],
            [-63.8857756, 46.5904033],
            [-63.8853553, 46.59036],
            [-63.8850846, 46.59018],
            [-63.8850303, 46.589928],
            [-63.8847673, 46.5898516],
            [-63.8845767, 46.5900037],
            [-63.8849764, 46.5906044],
            [-63.8849717, 46.591198],
            [-63.8853383, 46.5914435],
            [-63.8850269, 46.5915814],
            [-63.8846248, 46.5915146],
            [-63.8842896, 46.5915127],
            [-63.8839177, 46.5918807],
            [-63.883556, 46.5919699],
            [-63.8831231, 46.5922651],
            [-63.8829573, 46.5928399],
            [-63.8826983, 46.5929668],
            [-63.8823195, 46.5933626],
            [-63.8822675, 46.5938278],
            [-63.8821393, 46.5940128],
            [-63.8815863, 46.594274],
            [-63.8814265, 46.594841],
            [-63.8812709, 46.5949857],
            [-63.8810269, 46.5950172],
            [-63.8804314, 46.5953334],
            [-63.8799493, 46.5958628],
            [-63.8797536, 46.5963056],
            [-63.8798599, 46.5968693],
            [-63.8803866, 46.5976277],
            [-63.8809587, 46.5981],
            [-63.8809961, 46.5983357],
            [-63.8807888, 46.5984715],
            [-63.8803302, 46.5989894],
            [-63.8800012, 46.599131],
            [-63.8795672, 46.5991433],
            [-63.8786343, 46.5988917],
            [-63.8778548, 46.5984038],
            [-63.8774652, 46.5979984],
            [-63.8771332, 46.5972913],
            [-63.8769414, 46.596232],
            [-63.8768672, 46.5957406],
            [-63.8767194, 46.5953634],
            [-63.8761104, 46.5943326],
            [-63.8752941, 46.5934376],
            [-63.87515, 46.5932552],
            [-63.8749464, 46.5929973],
            [-63.8743819, 46.5923259],
            [-63.8741795, 46.5920194],
            [-63.8741722, 46.591593],
            [-63.8742063, 46.5914541],
            [-63.8743885, 46.5912183],
            [-63.874621, 46.5911826],
            [-63.8749541, 46.5913957],
            [-63.875179, 46.5914075],
            [-63.8752653, 46.5910753],
            [-63.8751501, 46.5907814],
            [-63.8739507, 46.5902503],
            [-63.8734879, 46.590262],
            [-63.8729903, 46.5904325],
            [-63.8723271, 46.5907036],
            [-63.8723302, 46.5907754],
            [-63.8727362, 46.5910456],
            [-63.873185, 46.5915635],
            [-63.8731822, 46.5917946],
            [-63.8730068, 46.5920026],
            [-63.872516, 46.5921453],
            [-63.8716063, 46.5922049],
            [-63.8712629, 46.5922705],
            [-63.8701192, 46.5924056],
            [-63.8684473, 46.5924476],
            [-63.8679015, 46.5923582],
            [-63.8668467, 46.5924351],
            [-63.8665701, 46.593494],
            [-63.8661711, 46.5934988],
            [-63.8663626, 46.5923985],
            [-63.8661414, 46.592287],
            [-63.8649352, 46.5922376],
            [-63.8634694, 46.5924864],
            [-63.862993, 46.592705],
            [-63.8622661, 46.5932857],
            [-63.8618877, 46.5935101],
            [-63.8615503, 46.5935678],
            [-63.8611991, 46.5933783],
            [-63.860642, 46.5920534],
            [-63.8601704, 46.5913756],
            [-63.8593346, 46.5902411],
            [-63.8585904, 46.5897456],
            [-63.8575946, 46.5893294],
            [-63.8569539, 46.5890031],
            [-63.8563951, 46.5884951],
            [-63.8548703, 46.5875513],
            [-63.8534535, 46.5868087],
            [-63.8507958, 46.5857211],
            [-63.8486004, 46.5850959],
            [-63.84782, 46.5849503],
            [-63.8460175, 46.584631],
            [-63.8439735, 46.5844347],
            [-63.8426521, 46.584383],
            [-63.8409979, 46.5839666],
            [-63.8405351, 46.5838267],
            [-63.8400609, 46.5835313],
            [-63.8397159, 46.5827282],
            [-63.8392524, 46.5823054],
            [-63.8377153, 46.5815484],
            [-63.8367252, 46.5812954],
            [-63.8362154, 46.5814814],
            [-63.8356327, 46.5814589],
            [-63.8343509, 46.5809774],
            [-63.8338867, 46.5807259],
            [-63.8334392, 46.5804907],
            [-63.8330456, 46.5802046],
            [-63.8327129, 46.57984],
            [-63.832735, 46.5791113],
            [-63.8325222, 46.5787807],
            [-63.832457, 46.5782217],
            [-63.8325443, 46.5779006],
            [-63.83283, 46.5776827],
            [-63.8328566, 46.5772887],
            [-63.8334743, 46.5768497],
            [-63.834073, 46.5766056],
            [-63.8352528, 46.5755154],
            [-63.8360482, 46.5745059],
            [-63.8364227, 46.5742299],
            [-63.8373905, 46.573849],
            [-63.8384168, 46.5736007],
            [-63.8398366, 46.5733355],
            [-63.8409609, 46.5732443],
            [-63.8413225, 46.5731552],
            [-63.8422606, 46.5732479],
            [-63.8428638, 46.5730357],
            [-63.8435285, 46.5730278],
            [-63.844598, 46.5727045],
            [-63.8452497, 46.5725808],
            [-63.8465054, 46.5726791],
            [-63.8467835, 46.5728116],
            [-63.8467843, 46.5729431],
            [-63.8464303, 46.5729845],
            [-63.8461812, 46.5731553],
            [-63.8456874, 46.5730747],
            [-63.8454694, 46.5731863],
            [-63.8454589, 46.5737678],
            [-63.8457431, 46.5740439],
            [-63.8460355, 46.5741009],
            [-63.8462278, 46.574212],
            [-63.846284, 46.5744042],
            [-63.8464527, 46.5746782],
            [-63.8468144, 46.5748919],
            [-63.8473105, 46.5749128],
            [-63.8475111, 46.5751076],
            [-63.8475021, 46.5756493],
            [-63.8472006, 46.576134],
            [-63.845737, 46.5774024],
            [-63.8457485, 46.5777094],
            [-63.8459932, 46.5779609],
            [-63.8464581, 46.5778896],
            [-63.8469077, 46.5774594],
            [-63.8474129, 46.5773928],
            [-63.8476217, 46.5769145],
            [-63.8482589, 46.5761092],
            [-63.8485178, 46.5755282],
            [-63.8486036, 46.5752468],
            [-63.8485358, 46.5745962],
            [-63.8478023, 46.5732164],
            [-63.8479861, 46.5730923],
            [-63.8487244, 46.5729701],
            [-63.8494355, 46.572959],
            [-63.849497, 46.5728605],
            [-63.84919, 46.5727275],
            [-63.8482192, 46.5727339],
            [-63.8477027, 46.5727963],
            [-63.8474877, 46.5729797],
            [-63.8473039, 46.5729525],
            [-63.847158, 46.5728383],
            [-63.8471572, 46.5727068],
            [-63.8477466, 46.5723987],
            [-63.8496533, 46.5723932],
            [-63.8499746, 46.5724508],
            [-63.8501897, 46.5727216],
            [-63.8503341, 46.5730269],
            [-63.8505028, 46.5731495],
            [-63.8511387, 46.5732924],
            [-63.8513431, 46.5733877],
            [-63.8515984, 46.5736632],
            [-63.8516753, 46.574075],
            [-63.851471, 46.5742825],
            [-63.8514354, 46.5746125],
            [-63.8517449, 46.5752914],
            [-63.8520291, 46.5755674],
            [-63.8524401, 46.5756983],
            [-63.8546138, 46.5759724],
            [-63.854787, 46.5761269],
            [-63.8549095, 46.576404],
            [-63.8550533, 46.5768807],
            [-63.8551582, 46.5770101],
            [-63.8556015, 46.576285],
            [-63.8558263, 46.5761456],
            [-63.856235, 46.5760334],
            [-63.8567866, 46.576115],
            [-63.8573481, 46.576392],
            [-63.8574347, 46.5762421],
            [-63.8575324, 46.5756423],
            [-63.8577982, 46.5754877],
            [-63.8588876, 46.575555],
            [-63.8592873, 46.5756816],
            [-63.8598542, 46.5759707],
            [-63.859981, 46.5759769],
            [-63.8599567, 46.575857],
            [-63.8595963, 46.5754521],
            [-63.8596197, 46.5751378],
            [-63.8605106, 46.5746477],
            [-63.8606516, 46.5742757],
            [-63.8611787, 46.5739346],
            [-63.8612341, 46.5738439],
            [-63.8603887, 46.5740479],
            [-63.859649, 46.5745128],
            [-63.8588303, 46.5749284],
            [-63.8580684, 46.5750622],
            [-63.8567634, 46.5753496],
            [-63.855715, 46.5752671],
            [-63.8545632, 46.5750153],
            [-63.8542197, 46.5747781],
            [-63.854133, 46.5744737],
            [-63.8542285, 46.5739336],
            [-63.8542087, 46.5738456],
            [-63.8539762, 46.5737299],
            [-63.8538007, 46.5736351],
            [-63.8532734, 46.573522],
            [-63.8527985, 46.5730952],
            [-63.8525917, 46.5727568],
            [-63.8526379, 46.5722994],
            [-63.8534477, 46.5704375],
            [-63.8532611, 46.5694102],
            [-63.8530932, 46.5691164],
            [-63.8531119, 46.5684673],
            [-63.8528491, 46.5665542],
            [-63.8527114, 46.5660697],
            [-63.8527007, 46.5657428],
            [-63.8526486, 46.5646742],
            [-63.8529052, 46.5644557],
            [-63.8542867, 46.5642773],
            [-63.8550378, 46.5642708],
            [-63.8567089, 46.5646833],
            [-63.8569703, 46.5649509],
            [-63.8575447, 46.5653437],
            [-63.8577438, 46.5655783],
            [-63.8579745, 46.5666621],
            [-63.8581776, 46.5672514],
            [-63.8586717, 46.5679375],
            [-63.8590394, 46.5681433],
            [-63.8594253, 46.5681741],
            [-63.8603078, 46.5680544],
            [-63.8608418, 46.5681397],
            [-63.8617416, 46.5689446],
            [-63.8626822, 46.5692801],
            [-63.8634327, 46.5692935],
            [-63.8636901, 46.5692065],
            [-63.8635282, 46.5690562],
            [-63.8630747, 46.5689803],
            [-63.8623066, 46.5686678],
            [-63.8623201, 46.5684609],
            [-63.8624665, 46.568101],
            [-63.8625298, 46.5671858],
            [-63.8626959, 46.5669138],
            [-63.863109, 46.5666822],
            [-63.8636102, 46.5665636],
            [-63.8644791, 46.5664995],
            [-63.8652468, 46.5662064],
            [-63.8656833, 46.5659632],
            [-63.8663114, 46.5658509],
            [-63.8663842, 46.5657565],
            [-63.8664494, 46.5655585],
            [-63.8669203, 46.5654793],
            [-63.867372, 46.5652921],
            [-63.8678824, 46.5652374],
            [-63.8685321, 46.5646992],
            [-63.8690242, 46.5645167],
            [-63.8692822, 46.5642584],
            [-63.8693571, 46.5639529],
            [-63.8695956, 46.5639094],
            [-63.8697673, 46.5639523],
            [-63.8699754, 46.563948],
            [-63.8702889, 46.5637504],
            [-63.8705023, 46.5637582],
            [-63.8705973, 46.5638435],
            [-63.8710036, 46.563791],
            [-63.871693, 46.5635801],
            [-63.8726911, 46.5637652],
            [-63.8747221, 46.5630482],
            [-63.874965, 46.5628852],
            [-63.8751021, 46.5624614],
            [-63.8747506, 46.5625946],
            [-63.8744, 46.5628593],
            [-63.8739254, 46.5630381],
            [-63.8728856, 46.5628882],
            [-63.8716909, 46.562863],
            [-63.8714311, 46.562707],
            [-63.8713383, 46.5625619],
            [-63.8713589, 46.562425],
            [-63.8713844, 46.562256],
            [-63.8714647, 46.562114],
            [-63.8714076, 46.5619417],
            [-63.8718949, 46.5615758],
            [-63.8719115, 46.5614407],
            [-63.8712615, 46.5615247],
            [-63.8709169, 46.5617815],
            [-63.8704704, 46.5618294],
            [-63.8698169, 46.562425],
            [-63.8693343, 46.5628649],
            [-63.8681615, 46.5636448],
            [-63.8671245, 46.5643435],
            [-63.8665777, 46.5644453],
            [-63.8663257, 46.5645444],
            [-63.8657936, 46.5650249],
            [-63.8652249, 46.5652498],
            [-63.8644747, 46.5656906],
            [-63.8641755, 46.5656614],
            [-63.8638632, 46.5655164],
            [-63.8631644, 46.5653605],
            [-63.8627541, 46.5652097],
            [-63.8618412, 46.5652173],
            [-63.8603231, 46.565497],
            [-63.8599205, 46.5654499],
            [-63.8595209, 46.5651719],
            [-63.8593566, 46.5647785],
            [-63.8593875, 46.5642651],
            [-63.8594116, 46.5639309],
            [-63.8594677, 46.5638203],
            [-63.8594334, 46.563505],
            [-63.8594311, 46.5634133],
            [-63.8592312, 46.5631986],
            [-63.8590367, 46.5631473],
            [-63.8586547, 46.563017],
            [-63.8579551, 46.5628809],
            [-63.8574864, 46.562749],
            [-63.8572236, 46.5625212],
            [-63.8572302, 46.562425],
            [-63.8572364, 46.5623342],
            [-63.8573685, 46.5622011],
            [-63.8571831, 46.5620624],
            [-63.8567797, 46.5618838],
            [-63.8565859, 46.5615098],
            [-63.8563648, 46.5613983],
            [-63.8556859, 46.5614818],
            [-63.8544291, 46.5617261],
            [-63.853937, 46.5617571],
            [-63.8530401, 46.5618008],
            [-63.8521227, 46.5617763],
            [-63.8514567, 46.5619755],
            [-63.8511971, 46.5621222],
            [-63.8508693, 46.562425],
            [-63.8500719, 46.5631617],
            [-63.8498434, 46.563552],
            [-63.849808, 46.564639],
            [-63.8498544, 46.5647873],
            [-63.8496017, 46.5655118],
            [-63.8497256, 46.5655977],
            [-63.8497719, 46.5657459],
            [-63.8493102, 46.565889],
            [-63.8486654, 46.5658336],
            [-63.8483136, 46.5656639],
            [-63.848312, 46.5654009],
            [-63.848473, 46.5652683],
            [-63.8488488, 46.5646496],
            [-63.8491137, 46.5640608],
            [-63.8492507, 46.5627286],
            [-63.8493569, 46.562425],
            [-63.8494321, 46.56221],
            [-63.8498602, 46.5615803],
            [-63.8502897, 46.5603052],
            [-63.8513455, 46.5588063],
            [-63.8517881, 46.5584039],
            [-63.8531525, 46.5579065],
            [-63.853463, 46.5577886],
            [-63.8545229, 46.5570985],
            [-63.8550953, 46.5569255],
            [-63.8564158, 46.5566743],
            [-63.8566914, 46.5565637],
            [-63.8571931, 46.5561226],
            [-63.8576022, 46.5558391],
            [-63.8578194, 46.5557474],
            [-63.8579757, 46.5555828],
            [-63.8583127, 46.5552224],
            [-63.8594931, 46.5545463],
            [-63.8615371, 46.5530173],
            [-63.8630025, 46.5518204],
            [-63.8642701, 46.5500464],
            [-63.8646723, 46.5496393],
            [-63.8652173, 46.5494259],
            [-63.8655754, 46.5491136],
            [-63.8658342, 46.5489867],
            [-63.8662927, 46.5489232],
            [-63.8665781, 46.5487052],
            [-63.8668804, 46.547878],
            [-63.8668769, 46.5472007],
            [-63.8667626, 46.5465533],
            [-63.86659, 46.5462276],
            [-63.8660541, 46.5452739],
            [-63.8645611, 46.543363],
            [-63.8643817, 46.543065],
            [-63.864137, 46.5426623],
            [-63.8637277, 46.5423403],
            [-63.8631909, 46.5421833],
            [-63.8626314, 46.5421693],
            [-63.8620884, 46.5418688],
            [-63.8616724, 46.5418773],
            [-63.8615958, 46.5419198],
            [-63.86128, 46.5418743],
            [-63.8611676, 46.5417926],
            [-63.8608137, 46.5415313],
            [-63.8607384, 46.5412312],
            [-63.8609066, 46.5407482],
            [-63.8608932, 46.5398756],
            [-63.8605988, 46.5387988],
            [-63.860481, 46.5384023],
            [-63.8604128, 46.5372976],
            [-63.8606553, 46.5366805],
            [-63.861643, 46.5357422],
            [-63.8622772, 46.535148],
            [-63.8626085, 46.5346241],
            [-63.8630926, 46.5331071],
            [-63.8635271, 46.5324496],
            [-63.8639559, 46.531326],
            [-63.8641333, 46.5310583],
            [-63.8644017, 46.5306727],
            [-63.864787, 46.5302494],
            [-63.8649324, 46.5299094],
            [-63.8649572, 46.529404],
            [-63.8649812, 46.5290698],
            [-63.8656555, 46.5284803],
            [-63.8659511, 46.5279837],
            [-63.8663744, 46.5266967],
            [-63.8665996, 46.526386],
            [-63.8665936, 46.5254658],
            [-63.8668003, 46.5248759],
            [-63.8674358, 46.524242],
            [-63.8676689, 46.5234096],
            [-63.8682161, 46.5225112],
            [-63.8685345, 46.5221743],
            [-63.8700308, 46.5212012],
            [-63.8703812, 46.5210879],
            [-63.8710443, 46.5209483],
            [-63.8715868, 46.520946],
            [-63.8718442, 46.5211617],
            [-63.8717851, 46.5213519],
            [-63.8715993, 46.5213844],
            [-63.8714019, 46.5212614],
            [-63.8711075, 46.5212641],
            [-63.870795, 46.5214418],
            [-63.8709378, 46.5216355],
            [-63.8708864, 46.521778],
            [-63.870391, 46.5219086],
            [-63.8699716, 46.522168],
            [-63.8695027, 46.5222075],
            [-63.8694079, 46.5222735],
            [-63.869383, 46.5224762],
            [-63.8690554, 46.5227492],
            [-63.8687784, 46.5236764],
            [-63.8684288, 46.5239211],
            [-63.8680422, 46.5243842],
            [-63.8680316, 46.5245115],
            [-63.8682237, 46.5246225],
            [-63.8684476, 46.5246543],
            [-63.8686435, 46.5248172],
            [-63.86875, 46.5252094],
            [-63.8689646, 46.525026],
            [-63.8690874, 46.5248291],
            [-63.8696866, 46.524545],
            [-63.8704346, 46.5242954],
            [-63.8716715, 46.5234689],
            [-63.8721404, 46.5234295],
            [-63.8725169, 46.5235477],
            [-63.8729095, 46.5238534],
            [-63.8730455, 46.5240749],
            [-63.8732915, 46.5242864],
            [-63.8745371, 46.5247745],
            [-63.8751501, 46.5251141],
            [-63.8751501, 46.5245972],
            [-63.8748381, 46.5244413],
            [-63.8746905, 46.5240643],
            [-63.8741544, 46.5235847],
            [-63.8739819, 46.523259],
            [-63.8740044, 46.5229647],
            [-63.8741157, 46.5227635],
            [-63.8738743, 46.5225839],
            [-63.873447, 46.5224369],
            [-63.8731463, 46.5221448],
            [-63.8729365, 46.5217348],
            [-63.8724255, 46.5213553],
            [-63.8721915, 46.5209767],
            [-63.8722096, 46.5208018],
            [-63.8723279, 46.5207242],
            [-63.8728818, 46.5207261],
            [-63.8732912, 46.5204226],
            [-63.8738314, 46.519552],
            [-63.8741745, 46.518861],
            [-63.8747264, 46.5182972],
            [-63.8750827, 46.5180247],
            [-63.8751501, 46.5179871],
            [-63.8758251, 46.5176116],
            [-63.8761692, 46.5172034],
            [-63.8768524, 46.5168292],
            [-63.8771526, 46.5165158],
            [-63.8776107, 46.5164523],
            [-63.8788788, 46.5152439],
            [-63.8793679, 46.5149698],
            [-63.8798803, 46.5145327],
            [-63.8801851, 46.5144027],
            [-63.8812754, 46.5137847],
            [-63.8815588, 46.5134551],
            [-63.8818999, 46.5132779],
            [-63.8822565, 46.5133081],
            [-63.8823369, 46.5133175],
            [-63.8832553, 46.5137559],
            [-63.8839147, 46.5138671],
            [-63.8847803, 46.5138625],
            [-63.885513, 46.5137081],
            [-63.8855728, 46.5136494],
            [-63.8856593, 46.513651],
            [-63.8869313, 46.5127971],
            [-63.8878624, 46.5124231],
            [-63.889084, 46.5119946],
            [-63.8898419, 46.5114662],
            [-63.8907669, 46.5104747],
            [-63.8914666, 46.5094913],
            [-63.8917298, 46.5089224],
            [-63.8920267, 46.5085372],
            [-63.8922558, 46.5079558],
            [-63.8924215, 46.5076839],
            [-63.8925426, 46.5069013],
            [-63.892695, 46.5068363],
            [-63.8934474, 46.5073952],
            [-63.8940212, 46.5076362],
            [-63.8946238, 46.5078778],
            [-63.8958143, 46.5081337],
            [-63.8962958, 46.5080585],
            [-63.8967452, 46.5077597],
            [-63.8973104, 46.5072916],
            [-63.8973001, 46.5069449],
            [-63.8977199, 46.5066655],
            [-63.8981748, 46.50653],
            [-63.898906, 46.5060928],
            [-63.8994118, 46.5059861],
            [-63.8997698, 46.5059764],
            [-63.9007979, 46.5062533],
            [-63.9014285, 46.5063639],
            [-63.9029445, 46.5067089],
            [-63.9039664, 46.5068423],
            [-63.9057535, 46.5068734],
            [-63.906515, 46.5068707],
            [-63.907114, 46.5067377],
            [-63.908034, 46.5066621],
            [-63.9092465, 46.5064721],
            [-63.9100142, 46.5061389],
            [-63.9109178, 46.5054019],
            [-63.9119585, 46.5050176],
            [-63.9137016, 46.5041955],
            [-63.9149221, 46.5034639],
            [-63.9154634, 46.5033299],
            [-63.9156901, 46.503282],
            [-63.9160018, 46.5032755],
            [-63.9162486, 46.5034669],
            [-63.9165791, 46.5037395],
            [-63.9168219, 46.5042017],
            [-63.9169602, 46.50516],
            [-63.9171261, 46.5057639],
            [-63.9179748, 46.50632],
            [-63.9178937, 46.5064573],
            [-63.9178048, 46.5066316],
            [-63.9174291, 46.5068648],
            [-63.9163494, 46.5070533],
            [-63.916151, 46.5072729],
            [-63.9160876, 46.5074311],
            [-63.9158339, 46.5088606],
            [-63.9152948, 46.5094088],
            [-63.9148538, 46.5101142],
            [-63.914818, 46.510464],
            [-63.9149147, 46.5106609],
            [-63.9149906, 46.5111123],
            [-63.9143566, 46.5120492],
            [-63.9139069, 46.5125194],
            [-63.9140028, 46.5127362],
            [-63.9142032, 46.5127795],
            [-63.9144783, 46.5126687],
            [-63.9151453, 46.512732],
            [-63.9154772, 46.5129648],
            [-63.9153457, 46.5132493],
            [-63.9153303, 46.5135159],
            [-63.9159714, 46.5139731],
            [-63.9160635, 46.514138],
            [-63.916252, 46.5143484],
            [-63.9164047, 46.5144347],
            [-63.9168684, 46.5145343],
            [-63.9170886, 46.5146656],
            [-63.9172699, 46.515075],
            [-63.9173217, 46.5155578],
            [-63.917345, 46.5158689],
            [-63.9173871, 46.5164591],
            [-63.9177546, 46.5166646],
            [-63.917917, 46.5166435],
            [-63.918219, 46.5162703],
            [-63.9181295, 46.5158745],
            [-63.9182895, 46.5154391],
            [-63.9182189, 46.5149997],
            [-63.9181588, 46.5149071],
            [-63.9181931, 46.514597],
            [-63.9185588, 46.514217],
            [-63.9185924, 46.5139268],
            [-63.9189363, 46.5136698],
            [-63.9193403, 46.5135056],
            [-63.9197309, 46.5130742],
            [-63.9199069, 46.5126749],
            [-63.9197719, 46.5122623],
            [-63.9187821, 46.5115562],
            [-63.9186188, 46.5114458],
            [-63.9185043, 46.5111013],
            [-63.9181542, 46.5108921],
            [-63.9179265, 46.5108086],
            [-63.9176028, 46.5105082],
            [-63.9173404, 46.5101094],
            [-63.9169413, 46.5096604],
            [-63.9169724, 46.5092785],
            [-63.9174034, 46.5085292],
            [-63.9183048, 46.5078518],
            [-63.918461, 46.5078385],
            [-63.9185678, 46.5077567],
            [-63.9184406, 46.5074479],
            [-63.9182718, 46.5068515],
            [-63.9180195, 46.5066913],
            [-63.9181208, 46.5065099],
            [-63.9191534, 46.5062374],
            [-63.9194852, 46.5059962],
            [-63.9199304, 46.5059681],
            [-63.9202166, 46.5057101],
            [-63.9203982, 46.5054743],
            [-63.9208285, 46.5052188],
            [-63.9216086, 46.5050212],
            [-63.9223361, 46.5046832],
            [-63.9228214, 46.5046597],
            [-63.9229922, 46.5047224],
            [-63.9234927, 46.5050775],
            [-63.9234295, 46.5057097],
            [-63.9235176, 46.5061454],
            [-63.9240343, 46.5065366],
            [-63.9243136, 46.5066291],
            [-63.9248719, 46.5066626],
            [-63.9252981, 46.5070005],
            [-63.9252378, 46.5075531],
            [-63.9249015, 46.5077624],
            [-63.9249616, 46.507855],
            [-63.9258399, 46.5078143],
            [-63.9262093, 46.5078087],
            [-63.9269246, 46.5074865],
            [-63.9268754, 46.5067727],
            [-63.9270434, 46.5065924],
            [-63.9273939, 46.5066302],
            [-63.9286702, 46.5072297],
            [-63.929325, 46.5073086],
            [-63.9295626, 46.5074362],
            [-63.9297022, 46.5074067],
            [-63.9297731, 46.5068782],
            [-63.9301404, 46.5066097],
            [-63.9308599, 46.5064906],
            [-63.9318392, 46.5065272],
            [-63.9322498, 46.5063351],
            [-63.933842, 46.5063345],
            [-63.9339896, 46.5062375],
            [-63.9329911, 46.5060929],
            [-63.9326427, 46.5059954],
            [-63.9310247, 46.5059159],
            [-63.9299412, 46.50573],
            [-63.9293309, 46.5055363],
            [-63.9289812, 46.5054786],
            [-63.9286118, 46.5054842],
            [-63.9280278, 46.5055219],
            [-63.9274952, 46.5054172],
            [-63.9261935, 46.505363],
            [-63.9254918, 46.5053071],
            [-63.9250936, 46.5053122],
            [-63.9248702, 46.5051092],
            [-63.9249082, 46.504377],
            [-63.924879, 46.5042252],
            [-63.9246497, 46.5037074],
            [-63.9244323, 46.5034966],
            [-63.9243039, 46.5033789],
            [-63.923375, 46.5030682],
            [-63.9229595, 46.5027543],
            [-63.9228934, 46.5023469],
            [-63.923169, 46.5009458],
            [-63.9234246, 46.5005758],
            [-63.9238018, 46.5003513],
            [-63.9243118, 46.5001251],
            [-63.92436, 46.4999999],
            [-63.9244606, 46.4998369],
            [-63.9259307, 46.4988944],
            [-63.9262335, 46.4986526],
            [-63.9271083, 46.4977437],
            [-63.9274994, 46.4972924],
            [-63.9286791, 46.4964046],
            [-63.9295802, 46.4954044],
            [-63.9300833, 46.4948834],
            [-63.9304826, 46.4943645],
            [-63.9307381, 46.4936719],
            [-63.9306802, 46.493197],
            [-63.9304747, 46.4928191],
            [-63.9305451, 46.4924619],
            [-63.9308746, 46.4922803],
            [-63.9315145, 46.4922833],
            [-63.9316159, 46.4921895],
            [-63.9318508, 46.4917515],
            [-63.9318616, 46.4911303],
            [-63.9317855, 46.4910016],
            [-63.9317994, 46.4907748],
            [-63.9318531, 46.490724],
            [-63.9318574, 46.4906046],
            [-63.9314185, 46.4903023],
            [-63.9314061, 46.4898441],
            [-63.9309691, 46.4893308],
            [-63.9302652, 46.4888608],
            [-63.9297242, 46.4886723],
            [-63.9295845, 46.4885504],
            [-63.9297062, 46.4883733],
            [-63.930206, 46.4881031],
            [-63.9308865, 46.4879395],
            [-63.9327226, 46.4878674],
            [-63.9338851, 46.4876085],
            [-63.9373692, 46.4864375],
            [-63.9379415, 46.4860729],
            [-63.9384719, 46.4855921],
            [-63.939228, 46.4847607],
            [-63.9399422, 46.4838131],
            [-63.9401201, 46.4836767],
            [-63.9404163, 46.4832915],
            [-63.9404024, 46.4828731],
            [-63.9402637, 46.4825601],
            [-63.9405329, 46.4822859],
            [-63.9420217, 46.4812798],
            [-63.9424758, 46.4806703],
            [-63.9430412, 46.4803334],
            [-63.9441031, 46.4799772],
            [-63.9443325, 46.4798496],
            [-63.9446923, 46.4794575],
            [-63.9447602, 46.4790087],
            [-63.9445399, 46.4785549],
            [-63.944594, 46.4783328],
            [-63.9449484, 46.4779287],
            [-63.9448815, 46.4775412],
            [-63.9448612, 46.4769794],
            [-63.9450514, 46.4763335],
            [-63.9449674, 46.4757785],
            [-63.9440416, 46.475066],
            [-63.9443675, 46.4750623],
            [-63.9445187, 46.4750215],
            [-63.9445442, 46.4749458],
            [-63.9444114, 46.4746964],
            [-63.9443993, 46.474617],
            [-63.9444258, 46.474575],
            [-63.9438527, 46.4735923],
            [-63.9437368, 46.4735678],
            [-63.9435453, 46.4733384],
            [-63.9426518, 46.473306],
            [-63.9420715, 46.4734156],
            [-63.941867, 46.4734918],
            [-63.9406556, 46.4743274],
            [-63.9401663, 46.4747928],
            [-63.9383001, 46.4760433],
            [-63.9376757, 46.4764189],
            [-63.9369986, 46.4766543],
            [-63.936436, 46.4769115],
            [-63.9350629, 46.4774256],
            [-63.9347346, 46.4777386],
            [-63.9336383, 46.4785761],
            [-63.9325559, 46.4796606],
            [-63.9322419, 46.4798982],
            [-63.9319534, 46.4800645],
            [-63.9314822, 46.4801839],
            [-63.9308203, 46.4801526],
            [-63.9301717, 46.4799145],
            [-63.9292262, 46.4794363],
            [-63.9287867, 46.479154],
            [-63.9283529, 46.4787124],
            [-63.9273917, 46.4780269],
            [-63.9269953, 46.4776696],
            [-63.926719, 46.4774976],
            [-63.9264367, 46.4773335],
            [-63.9259221, 46.4773764],
            [-63.9258659, 46.4773356],
            [-63.9258629, 46.4769413],
            [-63.9254786, 46.4765683],
            [-63.9254133, 46.4761411],
            [-63.9257881, 46.4748572],
            [-63.9261037, 46.4742574],
            [-63.9262465, 46.4736546],
            [-63.9261474, 46.4730436],
            [-63.9264779, 46.4726709],
            [-63.9266613, 46.472698],
            [-63.9269381, 46.4723762],
            [-63.9272742, 46.4718444],
            [-63.9275669, 46.4715587],
            [-63.9276015, 46.4714],
            [-63.9275468, 46.4709968],
            [-63.9271551, 46.4706715],
            [-63.9263924, 46.4705628],
            [-63.9262499, 46.4705206],
            [-63.9256565, 46.4701918],
            [-63.9250695, 46.4700065],
            [-63.9245225, 46.4696745],
            [-63.9244802, 46.4694069],
            [-63.9245912, 46.4692058],
            [-63.924763, 46.4690773],
            [-63.9250661, 46.4686644],
            [-63.9250052, 46.4681178],
            [-63.925114, 46.4679763],
            [-63.9254294, 46.467699],
            [-63.9255273, 46.4670595],
            [-63.9254856, 46.4662982],
            [-63.9257691, 46.4659487],
            [-63.9259807, 46.4656735],
            [-63.9263475, 46.4649312],
            [-63.9267304, 46.4646989],
            [-63.9284588, 46.4639122],
            [-63.9283041, 46.4638857],
            [-63.9277783, 46.4639244],
            [-63.9274437, 46.4640939],
            [-63.9270089, 46.4641661],
            [-63.9264852, 46.4644677],
            [-63.9259608, 46.4644666],
            [-63.9250426, 46.4641999],
            [-63.9245069, 46.4638721],
            [-63.9241537, 46.46344],
            [-63.9240052, 46.463083],
            [-63.923978, 46.4627201],
            [-63.9241167, 46.462388],
            [-63.9242867, 46.462148],
            [-63.9249198, 46.4612112],
            [-63.9255369, 46.4602382],
            [-63.92622, 46.45935],
            [-63.9262143, 46.4583903],
            [-63.9264834, 46.4581162],
            [-63.9265343, 46.4578224],
            [-63.9263276, 46.4574843],
            [-63.9263152, 46.4573487],
            [-63.9264394, 46.4566181],
            [-63.9263666, 46.4565611],
            [-63.9262622, 46.4562607],
            [-63.9261619, 46.4561634],
            [-63.9258701, 46.4561066],
            [-63.9256765, 46.4558843],
            [-63.9256631, 46.4556172],
            [-63.9255181, 46.4554833],
            [-63.9254051, 46.4554217],
            [-63.9253233, 46.4554521],
            [-63.9253265, 46.4555238],
            [-63.9254441, 46.4556175],
            [-63.9254312, 46.4559756],
            [-63.9259184, 46.4563663],
            [-63.9260204, 46.4565751],
            [-63.9261102, 46.4579186],
            [-63.9258602, 46.4583006],
            [-63.9257921, 46.4590719],
            [-63.9255545, 46.4595895],
            [-63.9251339, 46.4599088],
            [-63.9245839, 46.4599789],
            [-63.9245871, 46.4600507],
            [-63.9249134, 46.4602713],
            [-63.9249144, 46.4604027],
            [-63.9244304, 46.4608802],
            [-63.9242139, 46.4609721],
            [-63.9242529, 46.4611679],
            [-63.9243205, 46.4612128],
            [-63.9243232, 46.4614558],
            [-63.9241092, 46.4616393],
            [-63.9236069, 46.4616664],
            [-63.9236256, 46.4619455],
            [-63.9236757, 46.4619942],
            [-63.9236326, 46.4623916],
            [-63.9228723, 46.4630197],
            [-63.9228754, 46.4630914],
            [-63.9230779, 46.4635488],
            [-63.9232481, 46.4637827],
            [-63.9233746, 46.4641115],
            [-63.9242378, 46.4649427],
            [-63.9243843, 46.4650368],
            [-63.9247883, 46.4654977],
            [-63.9247996, 46.4658245],
            [-63.9242565, 46.4663408],
            [-63.9240953, 46.4668158],
            [-63.9235864, 46.4673447],
            [-63.9232119, 46.4679833],
            [-63.9224006, 46.4685826],
            [-63.9222657, 46.4689666],
            [-63.9222172, 46.469352],
            [-63.9218853, 46.4697644],
            [-63.9213464, 46.4701613],
            [-63.92093, 46.4703612],
            [-63.9206875, 46.470373],
            [-63.919551, 46.4705604],
            [-63.9192253, 46.4706424],
            [-63.9173311, 46.4709044],
            [-63.9163396, 46.4712257],
            [-63.9157777, 46.4714629],
            [-63.914696, 46.4722048],
            [-63.9141675, 46.4724744],
            [-63.9140678, 46.4726797],
            [-63.9143519, 46.4726329],
            [-63.9146919, 46.4724755],
            [-63.9150373, 46.4723301],
            [-63.915373, 46.4722922],
            [-63.9161708, 46.4717484],
            [-63.9163405, 46.4716797],
            [-63.9169641, 46.4716467],
            [-63.9175789, 46.4717011],
            [-63.9183398, 46.4716983],
            [-63.9190511, 46.4714757],
            [-63.9197826, 46.4711697],
            [-63.9201933, 46.471129],
            [-63.9205538, 46.4713622],
            [-63.9208978, 46.4717306],
            [-63.9213785, 46.4718265],
            [-63.9215235, 46.4719604],
            [-63.9218612, 46.4721852],
            [-63.9221144, 46.4721976],
            [-63.9223533, 46.4722853],
            [-63.9223035, 46.472388],
            [-63.9218183, 46.4727341],
            [-63.9218758, 46.4730576],
            [-63.922708, 46.4737968],
            [-63.9227605, 46.473937],
            [-63.922732, 46.4740879],
            [-63.9225755, 46.4742724],
            [-63.9224272, 46.4743893],
            [-63.9222898, 46.4746815],
            [-63.9224058, 46.47546],
            [-63.9222784, 46.4757964],
            [-63.9218394, 46.4761392],
            [-63.921801, 46.4762461],
            [-63.9219254, 46.476312],
            [-63.9228184, 46.4771238],
            [-63.9236038, 46.4775635],
            [-63.9241005, 46.4776955],
            [-63.9243242, 46.4777272],
            [-63.9246738, 46.477785],
            [-63.9248775, 46.4780513],
            [-63.924849, 46.4782022],
            [-63.9236557, 46.4788427],
            [-63.9235618, 46.4788889],
            [-63.922872, 46.4804302],
            [-63.9224083, 46.4812983],
            [-63.921897, 46.4818869],
            [-63.9198638, 46.4834288],
            [-63.9192321, 46.4841547],
            [-63.9190996, 46.4846303],
            [-63.9190002, 46.484987],
            [-63.9190014, 46.4855924],
            [-63.9192445, 46.4874764],
            [-63.91915, 46.4888129],
            [-63.9193167, 46.4891463],
            [-63.9193155, 46.4893375],
            [-63.9192015, 46.4896182],
            [-63.9188069, 46.4898464],
            [-63.9173562, 46.4901001],
            [-63.9157486, 46.4908609],
            [-63.9151278, 46.4909656],
            [-63.9147567, 46.4911822],
            [-63.9141323, 46.4918604],
            [-63.91275, 46.493087],
            [-63.9116602, 46.4938766],
            [-63.9104208, 46.4945003],
            [-63.9087475, 46.4951562],
            [-63.9073566, 46.4955024],
            [-63.906449, 46.4955623],
            [-63.9057928, 46.4953717],
            [-63.9046135, 46.4948175],
            [-63.9042709, 46.4948832],
            [-63.9040769, 46.4949833],
            [-63.9038456, 46.4949992],
            [-63.9036517, 46.4950994],
            [-63.9033519, 46.4954128],
            [-63.9030692, 46.496519],
            [-63.9027232, 46.496987],
            [-63.9019667, 46.4976468],
            [-63.9016756, 46.4977214],
            [-63.9011508, 46.4977202],
            [-63.900905, 46.4976602],
            [-63.9002076, 46.4973333],
            [-63.8988435, 46.4969431],
            [-63.8978295, 46.4967621],
            [-63.8957726, 46.4965508],
            [-63.8950849, 46.4965905],
            [-63.8943237, 46.4967444],
            [-63.8936317, 46.4970549],
            [-63.8933991, 46.4972619],
            [-63.8930526, 46.4979011],
            [-63.8928601, 46.4979614],
            [-63.8923722, 46.4977417],
            [-63.8919961, 46.497775],
            [-63.8909609, 46.4983223],
            [-63.8902794, 46.4985055],
            [-63.8891821, 46.498546],
            [-63.8886011, 46.4985039],
            [-63.8878986, 46.4983163],
            [-63.8872619, 46.4979108],
            [-63.8871008, 46.4975893],
            [-63.8870835, 46.497119],
            [-63.8870143, 46.4969625],
            [-63.8868242, 46.4971145],
            [-63.8866553, 46.4973146],
            [-63.8862219, 46.4976495],
            [-63.8856261, 46.4978541],
            [-63.8849163, 46.4978654],
            [-63.8848847, 46.4980959],
            [-63.8851799, 46.4990012],
            [-63.8853128, 46.4991509],
            [-63.8851917, 46.4993081],
            [-63.8847135, 46.4996063],
            [-63.8844537, 46.4999244],
            [-63.884452, 46.4999999],
            [-63.884303, 46.5001009],
            [-63.8841902, 46.5003419],
            [-63.8837638, 46.5008004],
            [-63.8835262, 46.501298],
            [-63.8833519, 46.5021114],
            [-63.8831984, 46.5032956],
            [-63.8831031, 46.5035329],
            [-63.8828084, 46.5038583],
            [-63.8827753, 46.5047538],
            [-63.8827147, 46.5048324],
            [-63.8816156, 46.5055379],
            [-63.8811704, 46.5055659],
            [-63.8803638, 46.505229],
            [-63.8796378, 46.5050529],
            [-63.8789293, 46.5050244],
            [-63.878425, 46.5050911],
            [-63.8776456, 46.5054199],
            [-63.8759287, 46.5064529],
            [-63.8754934, 46.5065248],
            [-63.8751856, 46.5067344],
            [-63.8751501, 46.5067767],
            [-63.8750045, 46.5069504],
            [-63.8746149, 46.5071904],
            [-63.8743964, 46.5071706],
            [-63.8740124, 46.5069487],
            [-63.8733389, 46.5061362],
            [-63.8726255, 46.5056217],
            [-63.8711261, 46.5048382],
            [-63.8708551, 46.5045267],
            [-63.8704358, 46.5037068],
            [-63.8704867, 46.5029589],
            [-63.8704012, 46.5021608],
            [-63.870241, 46.5019707],
            [-63.8702681, 46.5017083],
            [-63.8709952, 46.5013907],
            [-63.8715295, 46.500982],
            [-63.871825, 46.5006368],
            [-63.871704, 46.5001686],
            [-63.8717301, 46.5],
            [-63.871354, 46.4999336],
            [-63.8710081, 46.5],
            [-63.8709889, 46.5001678],
            [-63.871119, 46.5006999],
            [-63.8709166, 46.5008675],
            [-63.869924, 46.5010371],
            [-63.8695935, 46.5012383],
            [-63.8693859, 46.5015453],
            [-63.8694645, 46.5020684],
            [-63.8697491, 46.5023244],
            [-63.8697841, 46.5024684],
            [-63.8694892, 46.5026424],
            [-63.8690888, 46.502707],
            [-63.8685236, 46.5025495],
            [-63.8681671, 46.5025193],
            [-63.8677714, 46.5027671],
            [-63.8673732, 46.502772],
            [-63.8667926, 46.5031838],
            [-63.8663846, 46.503296],
            [-63.86596, 46.5035434],
            [-63.8648466, 46.5035474],
            [-63.8639541, 46.5038142],
            [-63.8638191, 46.5037242],
            [-63.8637734, 46.5035561],
            [-63.8635041, 46.5033561],
            [-63.86319, 46.5032709],
            [-63.8623944, 46.5032606],
            [-63.8621851, 46.5033047],
            [-63.8617272, 46.5036709],
            [-63.8612123, 46.5037134],
            [-63.8608064, 46.5036146],
            [-63.8603514, 46.5035985],
            [-63.8598554, 46.5037489],
            [-63.858444, 46.503855],
            [-63.8574302, 46.504279],
            [-63.8569508, 46.5042943],
            [-63.8553162, 46.5040459],
            [-63.8546859, 46.503935],
            [-63.8532107, 46.5038965],
            [-63.8520351, 46.5038674],
            [-63.8516975, 46.5037936],
            [-63.8505819, 46.5038572],
            [-63.8502466, 46.503875],
            [-63.8486394, 46.5036668],
            [-63.8479894, 46.5034679],
            [-63.8477944, 46.5032852],
            [-63.847849, 46.5030631],
            [-63.8479945, 46.5027232],
            [-63.8482508, 46.5025048],
            [-63.848181, 46.5023681],
            [-63.8479603, 46.5024079],
            [-63.8468448, 46.5030768],
            [-63.8465643, 46.503478],
            [-63.8455464, 46.5035473],
            [-63.844945, 46.5037395],
            [-63.844609, 46.5037773],
            [-63.8441737, 46.5040004],
            [-63.8441229, 46.504123],
            [-63.8437764, 46.5044393],
            [-63.8432796, 46.5046096],
            [-63.8429095, 46.5047861],
            [-63.8427685, 46.5050066],
            [-63.8427458, 46.5053009],
            [-63.8428611, 46.5054544],
            [-63.8415361, 46.5063186],
            [-63.8407307, 46.507021],
            [-63.8398047, 46.5081632],
            [-63.8395757, 46.508876],
            [-63.838918, 46.5091788],
            [-63.8379919, 46.509867],
            [-63.8376915, 46.5098775],
            [-63.8369906, 46.5102551],
            [-63.8359673, 46.5104635],
            [-63.8350737, 46.51075],
            [-63.8344289, 46.5111685],
            [-63.8337287, 46.5119802],
            [-63.8337059, 46.5121232],
            [-63.8336384, 46.5126836],
            [-63.8337544, 46.5131199],
            [-63.8342392, 46.5135708],
            [-63.834422, 46.5139207],
            [-63.8343347, 46.5142417],
            [-63.8340783, 46.5146115],
            [-63.8338196, 46.5147382],
            [-63.8339046, 46.5150823],
            [-63.8336982, 46.5151981],
            [-63.8337809, 46.5152992],
            [-63.8336898, 46.5155684],
            [-63.8332013, 46.515671],
            [-63.8328629, 46.5160711],
            [-63.8324699, 46.5162392],
            [-63.8323629, 46.5166237],
            [-63.8319653, 46.5167598],
            [-63.8319107, 46.5169819],
            [-63.8315306, 46.5171144],
            [-63.8312574, 46.5174679],
            [-63.8309645, 46.517582],
            [-63.8306694, 46.5176046],
            [-63.830356, 46.517802],
            [-63.8294372, 46.5179884],
            [-63.8290723, 46.5180255],
            [-63.8289509, 46.5180313],
            [-63.8288212, 46.5179532],
            [-63.8288197, 46.5178417],
            [-63.8306294, 46.5160862],
            [-63.8306977, 46.51596],
            [-63.8309147, 46.5155656],
            [-63.8309967, 46.5150812],
            [-63.8309034, 46.5148046],
            [-63.8307775, 46.5146271],
            [-63.830003, 46.513908],
            [-63.8296686, 46.513149],
            [-63.8291839, 46.5126981],
            [-63.8284178, 46.5122141],
            [-63.8284049, 46.511947],
            [-63.828191, 46.5118077],
            [-63.8278929, 46.5117584],
            [-63.8275159, 46.5113572],
            [-63.8269091, 46.5110832],
            [-63.8270996, 46.51078],
            [-63.8272446, 46.5103087],
            [-63.82708, 46.5099352],
            [-63.8267964, 46.5096592],
            [-63.825413, 46.5088771],
            [-63.8242192, 46.5081144],
            [-63.8239425, 46.5078106],
            [-63.8235368, 46.5072575],
            [-63.8232752, 46.5070098],
            [-63.8230356, 46.506639],
            [-63.8229098, 46.5061587],
            [-63.8219977, 46.5051263],
            [-63.8218249, 46.5048204],
            [-63.8218871, 46.5045507],
            [-63.8215968, 46.5039997],
            [-63.8215848, 46.5035614],
            [-63.820873, 46.5025725],
            [-63.8204874, 46.5013506],
            [-63.8203684, 46.5011453],
            [-63.820037, 46.5009122],
            [-63.8195403, 46.5007796],
            [-63.8189678, 46.5005182],
            [-63.8181336, 46.5004631],
            [-63.8174291, 46.5003346],
            [-63.8171921, 46.5],
            [-63.8165678, 46.4996337],
            [-63.8161174, 46.499498],
            [-63.8156822, 46.4992669],
            [-63.8152236, 46.4987447],
            [-63.8146877, 46.4982848],
            [-63.8142055, 46.4979254],
            [-63.8128893, 46.4973595],
            [-63.8124131, 46.497295],
            [-63.8121182, 46.497166],
            [-63.8116814, 46.4971261],
            [-63.8112704, 46.4971663],
            [-63.810951, 46.4973716],
            [-63.8107916, 46.4976156],
            [-63.8105049, 46.4977218],
            [-63.8103131, 46.4976107],
            [-63.8100373, 46.4971356],
            [-63.8091974, 46.4966342],
            [-63.8087108, 46.4962429],
            [-63.8084373, 46.4958595],
            [-63.8084186, 46.4954489],
            [-63.8087919, 46.4950415],
            [-63.8095687, 46.4944903],
            [-63.8096622, 46.4941615],
            [-63.8096095, 46.493587],
            [-63.8093731, 46.4932879],
            [-63.8093296, 46.4924746],
            [-63.8090447, 46.492087],
            [-63.8086946, 46.4917459],
            [-63.8083031, 46.4909859],
            [-63.8077781, 46.4902474],
            [-63.8071491, 46.4896622],
            [-63.8063314, 46.4890378],
            [-63.8040867, 46.4875943],
            [-63.8037745, 46.4873177],
            [-63.8035307, 46.4869149],
            [-63.803487, 46.4865556],
            [-63.8036033, 46.4860838],
            [-63.8042822, 46.4856782],
            [-63.8044165, 46.4854856],
            [-63.8045526, 46.4849504],
            [-63.8047377, 46.4847866],
            [-63.8048506, 46.4848484],
            [-63.8048929, 46.4850961],
            [-63.8042393, 46.4861872],
            [-63.8041504, 46.486548],
            [-63.8042548, 46.4868287],
            [-63.8046451, 46.4870231],
            [-63.8051516, 46.4870484],
            [-63.8065452, 46.4867954],
            [-63.8067749, 46.4868196],
            [-63.8073368, 46.4867543],
            [-63.8076516, 46.4865172],
            [-63.8081281, 46.485825],
            [-63.8086256, 46.4856351],
            [-63.8091019, 46.4852456],
            [-63.8094765, 46.4851012],
            [-63.8095669, 46.4848519],
            [-63.8095109, 46.4846597],
            [-63.8097551, 46.4844571],
            [-63.8099894, 46.4843619],
            [-63.8099873, 46.4841189],
            [-63.8099025, 46.4839262],
            [-63.8092471, 46.4834322],
            [-63.8091911, 46.48324],
            [-63.8092851, 46.4831939],
            [-63.8102653, 46.4831921],
            [-63.8105876, 46.4830587],
            [-63.8108296, 46.4827644],
            [-63.8110102, 46.4824173],
            [-63.812184, 46.4818814],
            [-63.812166, 46.4814509],
            [-63.8125111, 46.4811745],
            [-63.8125134, 46.4811148],
            [-63.81259, 46.4810724],
            [-63.8125454, 46.4808844],
            [-63.812253, 46.4803931],
            [-63.8124187, 46.4796873],
            [-63.812252, 46.4795249],
            [-63.8119708, 46.4794918],
            [-63.8113127, 46.4796629],
            [-63.8105719, 46.4800355],
            [-63.8101724, 46.4800799],
            [-63.8100655, 46.4800103],
            [-63.8101657, 46.479805],
            [-63.810156, 46.4796097],
            [-63.8096747, 46.4793818],
            [-63.8096225, 46.4792414],
            [-63.8097742, 46.4790451],
            [-63.810067, 46.4787797],
            [-63.8101544, 46.4784587],
            [-63.8106283, 46.4781289],
            [-63.810492, 46.4779273],
            [-63.8100061, 46.4779701],
            [-63.8093237, 46.4781725],
            [-63.8088265, 46.4782111],
            [-63.808496, 46.4781095],
            [-63.8081134, 46.4778674],
            [-63.8080937, 46.4777794],
            [-63.8080879, 46.4774846],
            [-63.8084778, 46.4769422],
            [-63.8088056, 46.4763668],
            [-63.8093319, 46.4760261],
            [-63.8100043, 46.4757796],
            [-63.8104623, 46.4754137],
            [-63.8108228, 46.4747394],
            [-63.8108125, 46.4741099],
            [-63.8107074, 46.4736978],
            [-63.8107652, 46.4733962],
            [-63.8110899, 46.4727491],
            [-63.8110826, 46.4723427],
            [-63.8110356, 46.4722144],
            [-63.8108951, 46.4715268],
            [-63.8108393, 46.4708806],
            [-63.8109886, 46.4697046],
            [-63.8110617, 46.469005],
            [-63.8115373, 46.4681815],
            [-63.8117292, 46.4679899],
            [-63.8116519, 46.4679008],
            [-63.8117717, 46.4677836],
            [-63.8119758, 46.4674249],
            [-63.812489, 46.4671198],
            [-63.8130574, 46.4670267],
            [-63.8143472, 46.4670584],
            [-63.81492, 46.4671486],
            [-63.8159363, 46.4670996],
            [-63.8163918, 46.4669447],
            [-63.8169057, 46.4666197],
            [-63.8171536, 46.4666202],
            [-63.8177831, 46.467034],
            [-63.8188962, 46.4674647],
            [-63.8190992, 46.4675799],
            [-63.8192319, 46.4675784],
            [-63.8195311, 46.4677392],
            [-63.8195811, 46.4679392],
            [-63.819105, 46.46848],
            [-63.8191139, 46.4688466],
            [-63.8196457, 46.4694059],
            [-63.8197017, 46.4695981],
            [-63.8196805, 46.4697013],
            [-63.8198434, 46.4698118],
            [-63.820248, 46.4699307],
            [-63.8208474, 46.4702324],
            [-63.8211331, 46.4702974],
            [-63.8220744, 46.4702509],
            [-63.8237204, 46.470767],
            [-63.8250004, 46.4710572],
            [-63.8260136, 46.4710877],
            [-63.8275036, 46.4714654],
            [-63.828911, 46.4715907],
            [-63.8307405, 46.4715285],
            [-63.8309785, 46.4716363],
            [-63.8311687, 46.4716358],
            [-63.8315674, 46.4714599],
            [-63.8320297, 46.4714285],
            [-63.8321646, 46.4713672],
            [-63.8327839, 46.4708489],
            [-63.8336009, 46.470585],
            [-63.8338608, 46.4704185],
            [-63.8343034, 46.4698451],
            [-63.8347817, 46.4693958],
            [-63.8347726, 46.4690293],
            [-63.8346718, 46.4688004],
            [-63.8342838, 46.4685465],
            [-63.8340845, 46.4683318],
            [-63.8340875, 46.4681008],
            [-63.8344225, 46.4676291],
            [-63.8346582, 46.4671913],
            [-63.834571, 46.4670583],
            [-63.834374, 46.4669352],
            [-63.8337359, 46.4668918],
            [-63.8335283, 46.4667446],
            [-63.8334586, 46.4664566],
            [-63.8334359, 46.4661456],
            [-63.8332017, 46.4659382],
            [-63.8326023, 46.4659393],
            [-63.8326023, 46.4657879],
            [-63.8330472, 46.4653062],
            [-63.8332132, 46.4648831],
            [-63.8331185, 46.4641924],
            [-63.8329336, 46.4639024],
            [-63.8329222, 46.4637468],
            [-63.8330321, 46.4632829],
            [-63.83267, 46.4629577],
            [-63.8327351, 46.4627598],
            [-63.8331807, 46.4625608],
            [-63.8337672, 46.4624441],
            [-63.8344128, 46.4624399],
            [-63.8348902, 46.4626159],
            [-63.8353145, 46.4628227],
            [-63.8356676, 46.4627813],
            [-63.8362238, 46.4625525],
            [-63.8366428, 46.462596],
            [-63.8372217, 46.4628295],
            [-63.8380886, 46.4627656],
            [-63.8384766, 46.4628682],
            [-63.8390062, 46.4627305],
            [-63.8393957, 46.4627933],
            [-63.8401133, 46.4630174],
            [-63.8409627, 46.4629571],
            [-63.8414621, 46.46301],
            [-63.8416084, 46.4631042],
            [-63.8416167, 46.4633393],
            [-63.8412872, 46.4638232],
            [-63.8405113, 46.4646772],
            [-63.8401779, 46.4652606],
            [-63.8402848, 46.4653302],
            [-63.8403341, 46.4657015],
            [-63.8406396, 46.4669136],
            [-63.8408337, 46.4675703],
            [-63.8411233, 46.468141],
            [-63.841218, 46.468529],
            [-63.8417449, 46.4693789],
            [-63.841807, 46.4694118],
            [-63.8421558, 46.4703978],
            [-63.8423491, 46.4706204],
            [-63.8433973, 46.4712486],
            [-63.8436929, 46.4715089],
            [-63.8438476, 46.4718382],
            [-63.844072, 46.4720016],
            [-63.8439741, 46.4712392],
            [-63.8438444, 46.4710098],
            [-63.8427226, 46.4703444],
            [-63.8426362, 46.4701915],
            [-63.8425717, 46.4696129],
            [-63.8418949, 46.4689196],
            [-63.8418773, 46.468318],
            [-63.8417363, 46.4680844],
            [-63.8417181, 46.4678053],
            [-63.8418295, 46.4674529],
            [-63.841687, 46.4672592],
            [-63.8417779, 46.4668387],
            [-63.8416225, 46.4666806],
            [-63.8416224, 46.4663779],
            [-63.8418641, 46.4660836],
            [-63.8418633, 46.4658009],
            [-63.8419891, 46.4656757],
            [-63.8423263, 46.4654469],
            [-63.8425225, 46.4651358],
            [-63.8435591, 46.4645493],
            [-63.8440099, 46.464211],
            [-63.844153, 46.4639308],
            [-63.8442818, 46.4634234],
            [-63.8443802, 46.4632579],
            [-63.8447848, 46.4630741],
            [-63.8453304, 46.4629725],
            [-63.8455569, 46.4627735],
            [-63.8452606, 46.4625331],
            [-63.8446597, 46.4625741],
            [-63.843949, 46.4629276],
            [-63.8436482, 46.462958],
            [-63.843508, 46.4628559],
            [-63.8436041, 46.4621448],
            [-63.8434571, 46.4619191],
            [-63.8434063, 46.4618903],
            [-63.8433972, 46.4618264],
            [-63.8434563, 46.4617877],
            [-63.8437753, 46.4617337],
            [-63.8445587, 46.461286],
            [-63.8450481, 46.4611435],
            [-63.8463855, 46.4611318],
            [-63.8469174, 46.4609344],
            [-63.847484, 46.4604269],
            [-63.8479052, 46.4602593],
            [-63.8484568, 46.4601498],
            [-63.8488023, 46.4600047],
            [-63.8493068, 46.4596156],
            [-63.8494491, 46.4593553],
            [-63.8494634, 46.4589773],
            [-63.8496868, 46.4587065],
            [-63.8499384, 46.4586075],
            [-63.8503588, 46.4583085],
            [-63.8509603, 46.4582476],
            [-63.8509777, 46.4580926],
            [-63.8510625, 46.4579827],
            [-63.8512989, 46.457979],
            [-63.8515081, 46.4580863],
            [-63.8522379, 46.4585972],
            [-63.8525849, 46.4587149],
            [-63.8527804, 46.4587264],
            [-63.853545, 46.4589273],
            [-63.8534503, 46.458842],
            [-63.8527811, 46.4585552],
            [-63.8522809, 46.4582196],
            [-63.8518238, 46.4575066],
            [-63.8510001, 46.4570417],
            [-63.8508977, 46.4568527],
            [-63.8508309, 46.4564852],
            [-63.8516524, 46.4550226],
            [-63.8517553, 46.4545864],
            [-63.8514816, 46.4539005],
            [-63.8515254, 46.4536544],
            [-63.852335, 46.4529642],
            [-63.8523759, 46.4527977],
            [-63.8521357, 46.4529009],
            [-63.8514685, 46.4533309],
            [-63.8510657, 46.4539289],
            [-63.8509696, 46.4543373],
            [-63.8509509, 46.4549861],
            [-63.8505412, 46.4554605],
            [-63.8499762, 46.4562308],
            [-63.849737, 46.4569194],
            [-63.8496378, 46.4569535],
            [-63.8495878, 46.4570561],
            [-63.8488333, 46.4575044],
            [-63.8487265, 46.4575861],
            [-63.8485924, 46.4576275],
            [-63.8484924, 46.4576815],
            [-63.847525, 46.4579706],
            [-63.8470386, 46.4580335],
            [-63.8468257, 46.4578744],
            [-63.8468308, 46.4572811],
            [-63.846724, 46.4572115],
            [-63.8465375, 46.4569612],
            [-63.8464458, 46.456645],
            [-63.8469592, 46.4557145],
            [-63.8468796, 46.4553825],
            [-63.8466871, 46.4551401],
            [-63.8466824, 46.4548055],
            [-63.8468036, 46.4543458],
            [-63.8467649, 46.4541499],
            [-63.846658, 46.4540803],
            [-63.8462997, 46.4541097],
            [-63.8457337, 46.4536893],
            [-63.8456011, 46.4536909],
            [-63.8452783, 46.4535417],
            [-63.8451805, 46.453082],
            [-63.8448857, 46.4528019],
            [-63.8446955, 46.4524998],
            [-63.8446325, 46.4518814],
            [-63.8443961, 46.4515825],
            [-63.844437, 46.4512646],
            [-63.8440589, 46.4510547],
            [-63.8439066, 46.4506657],
            [-63.8436203, 46.4509233],
            [-63.8432628, 46.4516894],
            [-63.8432652, 46.452235],
            [-63.8433584, 46.4525114],
            [-63.8433676, 46.4527266],
            [-63.8431403, 46.4529455],
            [-63.8432146, 46.4532654],
            [-63.8437192, 46.4536329],
            [-63.8438617, 46.4538267],
            [-63.8438785, 46.4545995],
            [-63.8446954, 46.4553948],
            [-63.8447136, 46.4558252],
            [-63.844513, 46.4562556],
            [-63.8444357, 46.4563179],
            [-63.8443873, 46.4566834],
            [-63.8437191, 46.4571332],
            [-63.8435434, 46.4575124],
            [-63.8427813, 46.4581596],
            [-63.8425958, 46.4586461],
            [-63.8423564, 46.4587293],
            [-63.8419291, 46.4590561],
            [-63.8415184, 46.4590964],
            [-63.8409441, 46.4590462],
            [-63.8400334, 46.4590536],
            [-63.8393227, 46.4591044],
            [-63.8389749, 46.4590065],
            [-63.8387711, 46.4590625],
            [-63.8385423, 46.4593212],
            [-63.8383104, 46.4593568],
            [-63.8378831, 46.4592296],
            [-63.8373845, 46.4593081],
            [-63.8372603, 46.4592422],
            [-63.8371345, 46.4589133],
            [-63.8366836, 46.4586463],
            [-63.8360957, 46.4581976],
            [-63.8355502, 46.4578452],
            [-63.8352797, 46.4578363],
            [-63.8349009, 46.4576462],
            [-63.8348781, 46.4574865],
            [-63.8350751, 46.4571556],
            [-63.8351683, 46.4566754],
            [-63.8354562, 46.456378],
            [-63.8360722, 46.4559392],
            [-63.8361737, 46.4556942],
            [-63.8359699, 46.4555989],
            [-63.835876, 46.455645],
            [-63.8352736, 46.4561796],
            [-63.8345887, 46.4564618],
            [-63.8343645, 46.4567524],
            [-63.8343289, 46.4570823],
            [-63.834666, 46.4576101],
            [-63.834613, 46.4577923],
            [-63.8343341, 46.4580023],
            [-63.8337219, 46.4580389],
            [-63.8330794, 46.4579635],
            [-63.8317649, 46.4579833],
            [-63.8312474, 46.4578027],
            [-63.8297852, 46.4576167],
            [-63.82952, 46.4574685],
            [-63.829489, 46.4573763],
            [-63.8292049, 46.4572716],
            [-63.8287753, 46.4570527],
            [-63.8284754, 46.4564579],
            [-63.8282899, 46.455885],
            [-63.8280649, 46.4555902],
            [-63.8282529, 46.4551954],
            [-63.8284787, 46.454865],
            [-63.828553, 46.4545797],
            [-63.8284205, 46.453976],
            [-63.8286842, 46.4534074],
            [-63.8286214, 46.4530916],
            [-63.8284259, 46.4530801],
            [-63.8282062, 46.4532513],
            [-63.8279842, 46.4537848],
            [-63.8279151, 46.4543849],
            [-63.8275908, 46.454578],
            [-63.8274302, 46.4547105],
            [-63.8274308, 46.4552959],
            [-63.8273376, 46.4556247],
            [-63.8271034, 46.4560226],
            [-63.8270003, 46.4564588],
            [-63.8270851, 46.4566515],
            [-63.8277873, 46.4571223],
            [-63.8278744, 46.4572553],
            [-63.8278335, 46.4575731],
            [-63.8276819, 46.4577694],
            [-63.8279266, 46.4578496],
            [-63.8282032, 46.4578506],
            [-63.82831, 46.4579203],
            [-63.8282289, 46.4580821],
            [-63.8280258, 46.4581182],
            [-63.8268219, 46.4581081],
            [-63.8264635, 46.4579861],
            [-63.8257894, 46.4573844],
            [-63.8253628, 46.4572372],
            [-63.8248204, 46.4569565],
            [-63.8236659, 46.456561],
            [-63.8229931, 46.4565248],
            [-63.8219856, 46.4562037],
            [-63.8209075, 46.4560684],
            [-63.8199999, 46.455996],
            [-63.8194189, 46.4556708],
            [-63.8193045, 46.4556488],
            [-63.8189401, 46.4556859],
            [-63.8188962, 46.4556293],
            [-63.8189402, 46.4555345],
            [-63.8188841, 46.4554937],
            [-63.8181689, 46.4555124],
            [-63.8165674, 46.4553555],
            [-63.8154272, 46.4553385],
            [-63.8148591, 46.455129],
            [-63.8145083, 46.4551106],
            [-63.8142234, 46.455177],
            [-63.8135408, 46.4550967],
            [-63.8127915, 46.4552541],
            [-63.8124362, 46.4552038],
            [-63.8097512, 46.45386],
            [-63.808094, 46.4532082],
            [-63.8069617, 46.4528408],
            [-63.8050817, 46.4524396],
            [-63.8032861, 46.4516458],
            [-63.8025938, 46.4515214],
            [-63.8022029, 46.4514982],
            [-63.8017091, 46.4513059],
            [-63.8010388, 46.4512098],
            [-63.8004466, 46.4508803],
            [-63.7992346, 46.4507861],
            [-63.7985576, 46.4505664],
            [-63.7981685, 46.4502008],
            [-63.7975764, 46.4498712],
            [-63.7975403, 46.4496157],
            [-63.7976624, 46.4494387],
            [-63.7974623, 46.4486585],
            [-63.7965569, 46.4480921],
            [-63.7961371, 46.4473317],
            [-63.7959034, 46.4469729],
            [-63.795236, 46.4462119],
            [-63.7947258, 46.4458519],
            [-63.7934961, 46.4454786],
            [-63.7928639, 46.4451443],
            [-63.7926573, 46.4449771],
            [-63.7923496, 46.4444498],
            [-63.7922087, 46.4436308],
            [-63.792041, 46.4432056],
            [-63.7920387, 46.4425286],
            [-63.7917955, 46.4425599],
            [-63.7917943, 46.4422971],
            [-63.7920405, 46.4421862],
            [-63.7922242, 46.4417595],
            [-63.7924796, 46.4408246],
            [-63.7930163, 46.4403567],
            [-63.7931939, 46.439938],
            [-63.7932887, 46.439872],
            [-63.7932986, 46.4397647],
            [-63.7939544, 46.4390482],
            [-63.7944689, 46.4387034],
            [-63.7946164, 46.4381726],
            [-63.7947543, 46.4380318],
            [-63.7947741, 46.4379685],
            [-63.7947014, 46.4379114],
            [-63.7942136, 46.4380138],
            [-63.7938751, 46.4379796],
            [-63.7936077, 46.4380423],
            [-63.7935486, 46.438081],
            [-63.7933262, 46.4386144],
            [-63.7927515, 46.4393205],
            [-63.7923282, 46.4399815],
            [-63.7924043, 46.4403931],
            [-63.7923306, 46.4406585],
            [-63.7920638, 46.4408526],
            [-63.7919078, 46.4408657],
            [-63.7917888, 46.440963],
            [-63.7912827, 46.4410889],
            [-63.7908299, 46.4410327],
            [-63.7904193, 46.4410728],
            [-63.7899968, 46.4409773],
            [-63.7895739, 46.4405991],
            [-63.7892368, 46.4399397],
            [-63.7891214, 46.4395035],
            [-63.7896775, 46.4385383],
            [-63.7898628, 46.4380719],
            [-63.7896629, 46.4380284],
            [-63.7895689, 46.4380744],
            [-63.7894765, 46.4380806],
            [-63.788712, 46.4384844],
            [-63.7885836, 46.4388205],
            [-63.7882929, 46.4387434],
            [-63.787826, 46.4390453],
            [-63.7874343, 46.4391932],
            [-63.7868754, 46.4391987],
            [-63.7846644, 46.4394718],
            [-63.7836965, 46.4394776],
            [-63.783405, 46.4394203],
            [-63.7826057, 46.4390948],
            [-63.7823743, 46.4388276],
            [-63.782183, 46.4385652],
            [-63.7821033, 46.4381018],
            [-63.782092, 46.43755],
            [-63.7806546, 46.43755],
            [-63.7807161, 46.4379324],
            [-63.7809281, 46.4385456],
            [-63.7809397, 46.4389838],
            [-63.7813677, 46.4395254],
            [-63.7819096, 46.4398064],
            [-63.7821874, 46.4399191],
            [-63.7822365, 46.4399877],
            [-63.7823691, 46.4399863],
            [-63.782908, 46.4401955],
            [-63.7843815, 46.4405178],
            [-63.7844684, 46.4406508],
            [-63.7843811, 46.4408204],
            [-63.7842515, 46.4408936],
            [-63.7843332, 46.4410146],
            [-63.7846672, 46.4410169],
            [-63.7851505, 46.4408826],
            [-63.7854951, 46.440909],
            [-63.7857305, 46.4410766],
            [-63.7858435, 46.4415725],
            [-63.7861008, 46.4417685],
            [-63.7862925, 46.4417283],
            [-63.7864745, 46.4414928],
            [-63.7865164, 46.4413064],
            [-63.7862863, 46.4411508],
            [-63.7862653, 46.4409513],
            [-63.78652, 46.4407729],
            [-63.7870519, 46.4405758],
            [-63.7875501, 46.4406488],
            [-63.7878074, 46.4408448],
            [-63.7879902, 46.441326],
            [-63.7879384, 46.4416197],
            [-63.7876663, 46.4418018],
            [-63.7873455, 46.442198],
            [-63.7869696, 46.4423821],
            [-63.7862947, 46.4424053],
            [-63.7858785, 46.4427359],
            [-63.785487, 46.4427326],
            [-63.7847032, 46.4425945],
            [-63.7834445, 46.4425231],
            [-63.7829695, 46.4425898],
            [-63.7823284, 46.4429282],
            [-63.7817056, 46.4430917],
            [-63.7814182, 46.443369],
            [-63.7814102, 46.4437193],
            [-63.7814797, 46.443856],
            [-63.7813967, 46.4442089],
            [-63.7810623, 46.4445092],
            [-63.780666, 46.4446252],
            [-63.7795518, 46.4446879],
            [-63.7790858, 46.4448185],
            [-63.7782184, 46.4449017],
            [-63.7771946, 46.4453006],
            [-63.7764135, 46.4453854],
            [-63.7760803, 46.4453632],
            [-63.7754616, 46.4452759],
            [-63.7744458, 46.4453244],
            [-63.7721121, 46.4453401],
            [-63.771584, 46.4454377],
            [-63.7704655, 46.445751],
            [-63.7697877, 46.4457024],
            [-63.7695057, 46.4458404],
            [-63.7675488, 46.4460861],
            [-63.766954, 46.44627],
            [-63.7660797, 46.4468149],
            [-63.7659715, 46.447219],
            [-63.7654915, 46.4475564],
            [-63.7652224, 46.4476588],
            [-63.7633162, 46.4479332],
            [-63.7625789, 46.4480745],
            [-63.7616842, 46.4481173],
            [-63.7607793, 46.4479846],
            [-63.7590446, 46.4479994],
            [-63.7588024, 46.4478594],
            [-63.7586627, 46.4476059],
            [-63.7579461, 46.4467958],
            [-63.7571114, 46.4463459],
            [-63.7564975, 46.446139],
            [-63.7552514, 46.4457489],
            [-63.7544976, 46.4454399],
            [-63.7534638, 46.4453604],
            [-63.7527263, 46.4452189],
            [-63.7520945, 46.4448843],
            [-63.7519005, 46.4445501],
            [-63.7519265, 46.4431768],
            [-63.7517619, 46.4425405],
            [-63.7511444, 46.4418477],
            [-63.7511872, 46.4416415],
            [-63.7515178, 46.4414407],
            [-63.7517671, 46.4414017],
            [-63.7520454, 46.4412119],
            [-63.7523212, 46.440365],
            [-63.7525942, 46.4401631],
            [-63.7536672, 46.4398332],
            [-63.7539236, 46.4396151],
            [-63.7539255, 46.439424],
            [-63.75344, 46.4390325],
            [-63.7533624, 46.438812],
            [-63.7537401, 46.4381542],
            [-63.7536622, 46.437651],
            [-63.7536287, 46.43755],
            [-63.753544, 46.4372943],
            [-63.7531164, 46.4367526],
            [-63.7530365, 46.4361577],
            [-63.7529159, 46.4358607],
            [-63.7523953, 46.4356278],
            [-63.7522797, 46.4354942],
            [-63.7523844, 46.4346042],
            [-63.7523021, 46.4336351],
            [-63.7522575, 46.4335984],
            [-63.7521158, 46.4336872],
            [-63.7521226, 46.4340935],
            [-63.7520156, 46.4346091],
            [-63.7517069, 46.4352722],
            [-63.751314, 46.4355912],
            [-63.7506909, 46.4359058],
            [-63.75, 46.4369452],
            [-63.7499364, 46.4372317],
            [-63.7500001, 46.43755],
            [-63.7491727, 46.43755],
            [-63.7492341, 46.4377694],
            [-63.7495875, 46.4382938],
            [-63.7495819, 46.4384331],
            [-63.7495098, 46.4385074],
            [-63.7492281, 46.438494],
            [-63.7488255, 46.438335],
            [-63.7487104, 46.4383328],
            [-63.7486513, 46.4383715],
            [-63.7486592, 46.4386066],
            [-63.7480972, 46.4389741],
            [-63.7480114, 46.4391039],
            [-63.7475464, 46.4394972],
            [-63.7473008, 46.439588],
            [-63.7463962, 46.4397379],
            [-63.745617, 46.4400655],
            [-63.7451201, 46.4401037],
            [-63.7446435, 46.4402101],
            [-63.7442168, 46.4403651],
            [-63.7437298, 46.4407301],
            [-63.7431746, 46.4415039],
            [-63.7430745, 46.4415577],
            [-63.7420424, 46.4414384],
            [-63.7415674, 46.4415049],
            [-63.741344, 46.4414727],
            [-63.7407518, 46.4414454],
            [-63.7403, 46.4416518],
            [-63.7397556, 46.4415816],
            [-63.7390772, 46.4411185],
            [-63.7387471, 46.4407339],
            [-63.7383116, 46.440391],
            [-63.7377007, 46.4402558],
            [-63.7374822, 46.4401043],
            [-63.7370726, 46.4396902],
            [-63.7364694, 46.4395074],
            [-63.734731, 46.4393384],
            [-63.7343556, 46.4392197],
            [-63.7341962, 46.4390295],
            [-63.734143, 46.4384948],
            [-63.7337124, 46.4380326],
            [-63.7332352, 46.4377247],
            [-63.7330641, 46.4376816],
            [-63.7328232, 46.43755],
            [-63.7323766, 46.437306],
            [-63.732345, 46.4372337],
            [-63.7320296, 46.4370563],
            [-63.7315772, 46.4365658],
            [-63.7311591, 46.4359365],
            [-63.7311351, 46.4355339],
            [-63.731468, 46.4347081],
            [-63.7317637, 46.4342319],
            [-63.7320412, 46.4340621],
            [-63.7321956, 46.434089],
            [-63.7321924, 46.4341686],
            [-63.7319128, 46.4345295],
            [-63.7319519, 46.4347055],
            [-63.7319547, 46.4350599],
            [-63.7313945, 46.4356703],
            [-63.7313983, 46.4360048],
            [-63.7316885, 46.4362334],
            [-63.7319399, 46.4365688],
            [-63.7322857, 46.4367069],
            [-63.7326569, 46.4366424],
            [-63.7327926, 46.4365614],
            [-63.7330507, 46.4363036],
            [-63.7334435, 46.4354192],
            [-63.7337618, 46.4349515],
            [-63.7338185, 46.4346897],
            [-63.7337616, 46.4341033],
            [-63.7335077, 46.4335449],
            [-63.7329819, 46.4330171],
            [-63.733026, 46.4329224],
            [-63.7329202, 46.4328327],
            [-63.7328999, 46.4327646],
            [-63.7328948, 46.4323186],
            [-63.733567, 46.4307905],
            [-63.7335027, 46.4301004],
            [-63.7332875, 46.4298693],
            [-63.733007, 46.4296847],
            [-63.7328579, 46.4296699],
            [-63.7326189, 46.4298843],
            [-63.7326684, 46.4300843],
            [-63.7321407, 46.4313125],
            [-63.7317895, 46.4315964],
            [-63.7309799, 46.431963],
            [-63.7302793, 46.4323397],
            [-63.7305245, 46.4324002],
            [-63.7308601, 46.4323629],
            [-63.7311004, 46.4325427],
            [-63.7313066, 46.433144],
            [-63.7313109, 46.4338927],
            [-63.7304097, 46.434811],
            [-63.7304749, 46.4354813],
            [-63.7303837, 46.435599],
            [-63.730032, 46.4357514],
            [-63.7294889, 46.4357927],
            [-63.7287694, 46.4354961],
            [-63.7284489, 46.4355894],
            [-63.7278514, 46.4352672],
            [-63.72731, 46.4352687],
            [-63.7267341, 46.4354089],
            [-63.7261509, 46.4354453],
            [-63.7256204, 46.4353196],
            [-63.7250116, 46.4352759],
            [-63.7231048, 46.4352867],
            [-63.7225289, 46.4354268],
            [-63.7211311, 46.4353837],
            [-63.7202723, 46.4356816],
            [-63.7196514, 46.4356536],
            [-63.7194354, 46.435725],
            [-63.7189584, 46.4356998],
            [-63.7185657, 46.4358674],
            [-63.7181884, 46.4359397],
            [-63.7179869, 46.4359358],
            [-63.7176514, 46.435973],
            [-63.7165595, 46.4364774],
            [-63.7161717, 46.4365256],
            [-63.7156217, 46.4363118],
            [-63.715505, 46.4363493],
            [-63.7153461, 46.4368559],
            [-63.714719, 46.4374012],
            [-63.7147028, 46.4375163],
            [-63.7145751, 46.4376811],
            [-63.7138245, 46.4380089],
            [-63.7128505, 46.4385872],
            [-63.7117552, 46.4394538],
            [-63.7110053, 46.4397617],
            [-63.7105691, 46.4400041],
            [-63.7099152, 46.4409231],
            [-63.7090541, 46.4416947],
            [-63.7088299, 46.4419651],
            [-63.7082604, 46.441946],
            [-63.708095, 46.4420463],
            [-63.7079049, 46.4420465],
            [-63.7076167, 46.4419095],
            [-63.7071502, 46.4419083],
            [-63.7067449, 46.4416774],
            [-63.7062078, 46.4417106],
            [-63.7057543, 46.4413911],
            [-63.7052652, 46.4410988],
            [-63.704224, 46.4407837],
            [-63.7031398, 46.4406748],
            [-63.7026611, 46.4406893],
            [-63.7024032, 46.4407957],
            [-63.7015746, 46.441337],
            [-63.7012677, 46.4413747],
            [-63.7008931, 46.4415187],
            [-63.7007282, 46.4414677],
            [-63.7006132, 46.4411827],
            [-63.7003668, 46.4410106],
            [-63.6996279, 46.4409084],
            [-63.696953, 46.4404018],
            [-63.6965802, 46.4402232],
            [-63.6964785, 46.4400341],
            [-63.6964561, 46.4394602],
            [-63.6963143, 46.4392663],
            [-63.6960772, 46.4392895],
            [-63.6957799, 46.4396539],
            [-63.6953586, 46.4399523],
            [-63.6945877, 46.4399291],
            [-63.6942648, 46.4397993],
            [-63.6942573, 46.4392815],
            [-63.6940835, 46.4391665],
            [-63.6940371, 46.4387554],
            [-63.694131, 46.4382954],
            [-63.694683, 46.4376213],
            [-63.6946823, 46.43755],
            [-63.6946795, 46.4372868],
            [-63.6943809, 46.4371256],
            [-63.6944434, 46.4370074],
            [-63.6949087, 46.4368971],
            [-63.6953928, 46.4366119],
            [-63.6961486, 46.4358822],
            [-63.6962619, 46.4355101],
            [-63.6960685, 46.4346103],
            [-63.695937, 46.4343091],
            [-63.6955774, 46.4338122],
            [-63.6956915, 46.4334202],
            [-63.6955525, 46.433298],
            [-63.6954553, 46.4331408],
            [-63.6955301, 46.4327242],
            [-63.6954074, 46.4324868],
            [-63.6954279, 46.4319895],
            [-63.6956074, 46.4312683],
            [-63.6956542, 46.4308312],
            [-63.6955047, 46.4306849],
            [-63.6952762, 46.4306406],
            [-63.6951692, 46.4307221],
            [-63.6951531, 46.4312514],
            [-63.694905, 46.4320986],
            [-63.6948781, 46.4324724],
            [-63.6945696, 46.4328327],
            [-63.6939749, 46.4331475],
            [-63.6935767, 46.4335856],
            [-63.6933003, 46.4335841],
            [-63.6931537, 46.4335095],
            [-63.692848, 46.432962],
            [-63.6924916, 46.4326682],
            [-63.6921163, 46.4325493],
            [-63.6919655, 46.4325742],
            [-63.6913874, 46.4322084],
            [-63.6906005, 46.4320176],
            [-63.6893798, 46.4315953],
            [-63.6885482, 46.4316505],
            [-63.6881313, 46.4315666],
            [-63.6870946, 46.4315659],
            [-63.6867413, 46.4314753],
            [-63.686333, 46.4313239],
            [-63.6856221, 46.430828],
            [-63.6855009, 46.4308335],
            [-63.6853383, 46.4310055],
            [-63.685394, 46.4313291],
            [-63.6855219, 46.4315786],
            [-63.6867562, 46.4322282],
            [-63.6870738, 46.432346],
            [-63.6881299, 46.4324346],
            [-63.6890132, 46.4328025],
            [-63.6902223, 46.4330892],
            [-63.6903916, 46.4331722],
            [-63.690512, 46.4331866],
            [-63.6908806, 46.4334646],
            [-63.6909712, 46.433781],
            [-63.6911624, 46.4338923],
            [-63.6911359, 46.4341148],
            [-63.6909526, 46.43437],
            [-63.6903894, 46.434757],
            [-63.6903845, 46.4348764],
            [-63.6906045, 46.4349883],
            [-63.6911762, 46.4349478],
            [-63.691316, 46.4350501],
            [-63.6916338, 46.4351679],
            [-63.6920192, 46.4351795],
            [-63.692069, 46.4352283],
            [-63.6920821, 46.4356068],
            [-63.6922989, 46.4357983],
            [-63.6922411, 46.4360799],
            [-63.692055, 46.4362633],
            [-63.692033, 46.4365178],
            [-63.6917528, 46.4367472],
            [-63.6917575, 46.4369105],
            [-63.6916193, 46.4370511],
            [-63.6915734, 46.4371856],
            [-63.6917192, 46.4372801],
            [-63.6917038, 46.4373754],
            [-63.6912141, 46.437517],
            [-63.6911852, 46.43755],
            [-63.6909602, 46.4378067],
            [-63.6907153, 46.4378775],
            [-63.690579, 46.4381097],
            [-63.6907359, 46.4387739],
            [-63.690653, 46.4391067],
            [-63.6903435, 46.4394869],
            [-63.690289, 46.4396889],
            [-63.6905839, 46.440081],
            [-63.690572, 46.4402281],
            [-63.6905373, 46.4406495],
            [-63.6906384, 46.44099],
            [-63.6908065, 46.4412442],
            [-63.6908085, 46.4413358],
            [-63.690189, 46.4415506],
            [-63.6885445, 46.4416176],
            [-63.6881253, 46.4417248],
            [-63.6876717, 46.4416879],
            [-63.6873978, 46.4416267],
            [-63.687402, 46.4419414],
            [-63.6888965, 46.4418794],
            [-63.6898056, 46.4420447],
            [-63.6903036, 46.442401],
            [-63.690329, 46.4424812],
            [-63.6919897, 46.4425817],
            [-63.692662, 46.4429016],
            [-63.6932481, 46.4429371],
            [-63.6940519, 46.4428613],
            [-63.6950189, 46.4424623],
            [-63.6955706, 46.4423537],
            [-63.6967754, 46.4421943],
            [-63.6974577, 46.4419927],
            [-63.6979385, 46.4417871],
            [-63.6985088, 46.4417864],
            [-63.6986912, 46.4418338],
            [-63.6993456, 46.4423086],
            [-63.6996334, 46.4423142],
            [-63.7000148, 46.4424253],
            [-63.7007447, 46.4427463],
            [-63.7011346, 46.4427898],
            [-63.7019952, 46.442735],
            [-63.7025534, 46.4424672],
            [-63.7027618, 46.4424434],
            [-63.7031342, 46.4427733],
            [-63.7032772, 46.4430788],
            [-63.703339, 46.4436773],
            [-63.7035164, 46.4441268],
            [-63.7040566, 46.4445794],
            [-63.7040225, 46.4448495],
            [-63.7042876, 46.4448468],
            [-63.7049188, 46.4444848],
            [-63.7050575, 46.4444756],
            [-63.705402, 46.4445023],
            [-63.7065859, 46.4452741],
            [-63.7068007, 46.4453739],
            [-63.7073755, 46.4454051],
            [-63.7079824, 46.4453572],
            [-63.7085446, 46.4455554],
            [-63.7090893, 46.4460399],
            [-63.7095161, 46.4467332],
            [-63.7098594, 46.4469311],
            [-63.7103697, 46.4469889],
            [-63.7110901, 46.446983],
            [-63.712001, 46.4471084],
            [-63.712374, 46.4470041],
            [-63.7127133, 46.446736],
            [-63.7129675, 46.4462949],
            [-63.7132838, 46.4461697],
            [-63.7136263, 46.4461047],
            [-63.7137159, 46.4460268],
            [-63.7137183, 46.4459671],
            [-63.7134415, 46.4458343],
            [-63.7127653, 46.4457454],
            [-63.7122797, 46.445505],
            [-63.711904, 46.444972],
            [-63.7115473, 46.4446783],
            [-63.711262, 46.444613],
            [-63.7105704, 46.4446194],
            [-63.7097074, 46.4447339],
            [-63.7095606, 46.4446594],
            [-63.7095639, 46.4445798],
            [-63.7098023, 46.4443853],
            [-63.710232, 46.4443022],
            [-63.7113159, 46.4442795],
            [-63.7118554, 46.4444693],
            [-63.7132247, 46.4453602],
            [-63.7144026, 46.445857],
            [-63.7145514, 46.4460232],
            [-63.7146916, 46.4462569],
            [-63.7156313, 46.4466655],
            [-63.7168855, 46.4475541],
            [-63.7173177, 46.4476939],
            [-63.7176586, 46.4479514],
            [-63.7179136, 46.4486214],
            [-63.7180182, 46.4491651],
            [-63.7179148, 46.4495812],
            [-63.7177689, 46.4500522],
            [-63.7169313, 46.4508125],
            [-63.7160004, 46.4515987],
            [-63.7156894, 46.4520188],
            [-63.715693, 46.4523534],
            [-63.7158037, 46.4526064],
            [-63.7157684, 46.4530478],
            [-63.7137536, 46.4548364],
            [-63.7129767, 46.4552354],
            [-63.7126324, 46.4553402],
            [-63.7118705, 46.4555124],
            [-63.7101808, 46.4563914],
            [-63.7098374, 46.4564763],
            [-63.7093638, 46.4565028],
            [-63.7092048, 46.456444],
            [-63.7089145, 46.4562153],
            [-63.7084763, 46.4558005],
            [-63.7083657, 46.4555475],
            [-63.7081306, 46.4553796],
            [-63.7077556, 46.4551094],
            [-63.7071515, 46.4549462],
            [-63.7060713, 46.4548693],
            [-63.7050464, 46.4545705],
            [-63.7042173, 46.4544148],
            [-63.7034818, 46.4543646],
            [-63.7020639, 46.4545159],
            [-63.7003827, 46.4550444],
            [-63.699903, 46.4550788],
            [-63.6991713, 46.4547976],
            [-63.6989353, 46.4546496],
            [-63.6990393, 46.4543649],
            [-63.6988366, 46.4542494],
            [-63.6983237, 46.4542512],
            [-63.6977373, 46.4544985],
            [-63.6974478, 46.4545327],
            [-63.6973224, 46.4546377],
            [-63.6973718, 46.4548378],
            [-63.6974585, 46.4549709],
            [-63.6971779, 46.455069],
            [-63.6963536, 46.4550766],
            [-63.6950935, 46.4548925],
            [-63.6949058, 46.454833],
            [-63.6945413, 46.4548696],
            [-63.6935494, 46.4551687],
            [-63.6935226, 46.4552597],
            [-63.6923582, 46.4556868],
            [-63.6917163, 46.4557418],
            [-63.6912401, 46.4554138],
            [-63.6908938, 46.4554269],
            [-63.6903707, 46.455536],
            [-63.6898869, 46.4556698],
            [-63.6874737, 46.4566137],
            [-63.6868354, 46.4567205],
            [-63.6864643, 46.4569162],
            [-63.6863203, 46.4569134],
            [-63.686067, 46.456769],
            [-63.6858367, 46.4567644],
            [-63.6855455, 46.4568383],
            [-63.6849908, 46.4568751],
            [-63.6835955, 46.4574488],
            [-63.683112, 46.457714],
            [-63.6816644, 46.4587127],
            [-63.6804903, 46.4588128],
            [-63.6797562, 46.4590053],
            [-63.6791898, 46.4593206],
            [-63.679055, 46.4595131],
            [-63.6791284, 46.4599646],
            [-63.6789917, 46.4600654],
            [-63.6779048, 46.4601473],
            [-63.6770024, 46.4599541],
            [-63.6767546, 46.4599532],
            [-63.6764706, 46.4601307],
            [-63.6763167, 46.4603666],
            [-63.6761467, 46.4604349],
            [-63.6758413, 46.4604328],
            [-63.6756052, 46.4605675],
            [-63.6753644, 46.4605388],
            [-63.6749826, 46.4602962],
            [-63.6743525, 46.460204],
            [-63.6735608, 46.4599811],
            [-63.673243, 46.4598633],
            [-63.6723887, 46.4593444],
            [-63.6720867, 46.4588486],
            [-63.672029, 46.4578876],
            [-63.6719291, 46.4576586],
            [-63.6718965, 46.4574748],
            [-63.6717874, 46.4574646],
            [-63.6712703, 46.4575658],
            [-63.6707723, 46.4580377],
            [-63.6704069, 46.4580942],
            [-63.670305, 46.4581877],
            [-63.669892, 46.4584184],
            [-63.6697355, 46.458714],
            [-63.6689655, 46.4593477],
            [-63.668566, 46.4598057],
            [-63.6680095, 46.4602963],
            [-63.6677271, 46.4604341],
            [-63.6670399, 46.4606035],
            [-63.666734, 46.4607527],
            [-63.6672152, 46.4609615],
            [-63.6673306, 46.4610952],
            [-63.6673431, 46.4612109],
            [-63.667157, 46.4615258],
            [-63.6671289, 46.4619195],
            [-63.6669409, 46.4621428],
            [-63.6658957, 46.4630179],
            [-63.6646882, 46.4634995],
            [-63.6637451, 46.4639983],
            [-63.6634068, 46.4640951],
            [-63.6631863, 46.4642659],
            [-63.6631605, 46.4644685],
            [-63.6632549, 46.464554],
            [-63.6635954, 46.4646804],
            [-63.664089, 46.4650049],
            [-63.6645035, 46.4650172],
            [-63.6647886, 46.4649512],
            [-63.6649381, 46.4650976],
            [-63.6650139, 46.4654894],
            [-63.6651968, 46.4657997],
            [-63.6651068, 46.4661603],
            [-63.6652003, 46.4662658],
            [-63.6655863, 46.4659947],
            [-63.6663399, 46.4649305],
            [-63.6670026, 46.4642468],
            [-63.6686553, 46.4630413],
            [-63.6695264, 46.4619197],
            [-63.6702069, 46.4614952],
            [-63.6706831, 46.4614092],
            [-63.6711817, 46.4612001],
            [-63.6715616, 46.4609369],
            [-63.6720781, 46.4605728],
            [-63.67248, 46.4604693],
            [-63.6730558, 46.4604808],
            [-63.6739969, 46.4611328],
            [-63.6742308, 46.4611892],
            [-63.6743706, 46.4612916],
            [-63.6744337, 46.4614362],
            [-63.6748288, 46.4613605],
            [-63.6751172, 46.4616291],
            [-63.6756748, 46.4616641],
            [-63.6758741, 46.4618592],
            [-63.6762296, 46.4624557],
            [-63.6769818, 46.4629366],
            [-63.6776034, 46.4630964],
            [-63.6780832, 46.4630621],
            [-63.6782296, 46.4630053],
            [-63.6787365, 46.4625972],
            [-63.6789095, 46.4623179],
            [-63.6794977, 46.4620309],
            [-63.6804526, 46.461799],
            [-63.6806992, 46.4616884],
            [-63.6810715, 46.4616042],
            [-63.6814372, 46.461265],
            [-63.6816603, 46.4611659],
            [-63.6818109, 46.4610095],
            [-63.6818062, 46.4608462],
            [-63.6819829, 46.4607501],
            [-63.6822858, 46.4608119],
            [-63.6823811, 46.4608775],
            [-63.6828505, 46.4605364],
            [-63.6835695, 46.4605705],
            [-63.6838053, 46.4603044],
            [-63.6839675, 46.4602837],
            [-63.6844412, 46.4606715],
            [-63.684725, 46.4607766],
            [-63.6852615, 46.4607634],
            [-63.6857337, 46.460494],
            [-63.6859701, 46.4604907],
            [-63.6860986, 46.4605888],
            [-63.6861929, 46.4609571],
            [-63.686381, 46.461148],
            [-63.6863196, 46.4613777],
            [-63.6861904, 46.4617137],
            [-63.686215, 46.4618137],
            [-63.6868425, 46.4622483],
            [-63.6869567, 46.4629674],
            [-63.6870451, 46.4630608],
            [-63.6872333, 46.4629689],
            [-63.6874813, 46.4626871],
            [-63.6875264, 46.4625725],
            [-63.6874623, 46.4620336],
            [-63.6871736, 46.4613508],
            [-63.6871592, 46.4611435],
            [-63.6872534, 46.4608148],
            [-63.6880595, 46.4597196],
            [-63.6884384, 46.4587793],
            [-63.6898817, 46.4581627],
            [-63.6901939, 46.458137],
            [-63.6903925, 46.458352],
            [-63.6905756, 46.4590764],
            [-63.690875, 46.4595005],
            [-63.6912629, 46.4597352],
            [-63.6921793, 46.4598728],
            [-63.6928194, 46.4601403],
            [-63.6931884, 46.4601356],
            [-63.6938671, 46.4597508],
            [-63.6944337, 46.4595668],
            [-63.6944662, 46.4593365],
            [-63.6943673, 46.459219],
            [-63.6940228, 46.4589095],
            [-63.693341, 46.4586771],
            [-63.6927073, 46.4585331],
            [-63.6922914, 46.458278],
            [-63.6922044, 46.4580134],
            [-63.692288, 46.4579434],
            [-63.6927904, 46.4579175],
            [-63.6939237, 46.4586607],
            [-63.6944807, 46.4588469],
            [-63.6950591, 46.4592127],
            [-63.6951458, 46.4593459],
            [-63.6951319, 46.4596841],
            [-63.6949846, 46.4597609],
            [-63.6946756, 46.4606867],
            [-63.6946714, 46.4610689],
            [-63.6945755, 46.4614374],
            [-63.6946497, 46.4638284],
            [-63.6948702, 46.4647686],
            [-63.6956297, 46.4666156],
            [-63.696116, 46.4674017],
            [-63.6972714, 46.468456],
            [-63.6979252, 46.4692335],
            [-63.6983722, 46.469995],
            [-63.6988789, 46.4715463],
            [-63.6990207, 46.4717403],
            [-63.6998265, 46.4721902],
            [-63.6999153, 46.472415],
            [-63.6999236, 46.472913],
            [-63.7000128, 46.4729864],
            [-63.7003484, 46.4735148],
            [-63.700861, 46.473947],
            [-63.702077, 46.4743771],
            [-63.7027097, 46.4746922],
            [-63.7032938, 46.4747873],
            [-63.7051148, 46.4746637],
            [-63.7063078, 46.4746712],
            [-63.7079883, 46.4748793],
            [-63.709197, 46.4752056],
            [-63.7097248, 46.4754111],
            [-63.7103327, 46.4759089],
            [-63.7107144, 46.4763027],
            [-63.7118388, 46.4770017],
            [-63.7127284, 46.4773815],
            [-63.7128123, 46.4774429],
            [-63.7138135, 46.4777731],
            [-63.7145749, 46.4779034],
            [-63.7159773, 46.477867],
            [-63.7168958, 46.4779646],
            [-63.718245, 46.477959],
            [-63.7190982, 46.478103],
            [-63.7223676, 46.4789392],
            [-63.7236256, 46.479334],
            [-63.7244796, 46.4794581],
            [-63.7246524, 46.4794614],
            [-63.7250257, 46.4793571],
            [-63.7254958, 46.4789959],
            [-63.7258726, 46.4786606],
            [-63.7267435, 46.4780841],
            [-63.7270619, 46.4780504],
            [-63.7273317, 46.4780795],
            [-63.7276669, 46.4783449],
            [-63.7277452, 46.4785456],
            [-63.7277847, 46.4794186],
            [-63.7277032, 46.4795803],
            [-63.7266587, 46.4801734],
            [-63.7266555, 46.480253],
            [-63.7262353, 46.4809458],
            [-63.7257299, 46.4814657],
            [-63.7255218, 46.481621],
            [-63.7238309, 46.4823689],
            [-63.7235526, 46.4824073],
            [-63.7233112, 46.4822473],
            [-63.7232933, 46.4824022],
            [-63.7231603, 46.482555],
            [-63.7226479, 46.4828199],
            [-63.7217951, 46.4833729],
            [-63.7214377, 46.4837961],
            [-63.7209173, 46.485391],
            [-63.7204803, 46.4860676],
            [-63.7199095, 46.486634],
            [-63.7191524, 46.4871012],
            [-63.7183729, 46.4874087],
            [-63.7177375, 46.4874362],
            [-63.717326, 46.4873445],
            [-63.7166014, 46.4872986],
            [-63.7156458, 46.4876822],
            [-63.71456, 46.4877248],
            [-63.7120101, 46.4880335],
            [-63.7115357, 46.4882114],
            [-63.7116826, 46.488286],
            [-63.7122904, 46.4883696],
            [-63.7126008, 46.4885349],
            [-63.7126081, 46.4886386],
            [-63.7129656, 46.488781],
            [-63.7139228, 46.4886404],
            [-63.7140397, 46.4886028],
            [-63.7141253, 46.4886244],
            [-63.7142961, 46.4885361],
            [-63.7157687, 46.4882024],
            [-63.7166022, 46.488127],
            [-63.7167596, 46.4882257],
            [-63.716816, 46.488398],
            [-63.7171722, 46.4887117],
            [-63.7171925, 46.4893453],
            [-63.7172964, 46.4893434],
            [-63.7178917, 46.4890284],
            [-63.7181591, 46.4888344],
            [-63.7182552, 46.4885973],
            [-63.7183981, 46.4884886],
            [-63.7186026, 46.4885642],
            [-63.7186371, 46.4887083],
            [-63.7188964, 46.4887133],
            [-63.7190721, 46.4887884],
            [-63.7190388, 46.4890387],
            [-63.7188039, 46.4892851],
            [-63.7188095, 46.4894286],
            [-63.7189982, 46.4894681],
            [-63.7198637, 46.4890309],
            [-63.7201279, 46.4883509],
            [-63.7203677, 46.488268],
            [-63.7210997, 46.4884176],
            [-63.7213829, 46.4882598],
            [-63.7216345, 46.4883125],
            [-63.7218638, 46.4884882],
            [-63.7219676, 46.4884862],
            [-63.7220626, 46.4884204],
            [-63.7219538, 46.4879762],
            [-63.721929, 46.4875933],
            [-63.7221603, 46.4872951],
            [-63.7219939, 46.4871326],
            [-63.7219144, 46.4871031],
            [-63.7216997, 46.4871348],
            [-63.7214924, 46.4872702],
            [-63.7212952, 46.4872982],
            [-63.7212964, 46.487127],
            [-63.7214311, 46.4869344],
            [-63.7218709, 46.4866124],
            [-63.7224024, 46.4865869],
            [-63.7229161, 46.4867163],
            [-63.7246358, 46.4875343],
            [-63.7256093, 46.4878439],
            [-63.7261981, 46.4879708],
            [-63.7267463, 46.4879615],
            [-63.7269633, 46.4878701],
            [-63.7273788, 46.4875795],
            [-63.7275577, 46.487575],
            [-63.7283098, 46.4882268],
            [-63.7289782, 46.4886659],
            [-63.7297857, 46.4889444],
            [-63.733631, 46.489397],
            [-63.7338591, 46.4894611],
            [-63.7342528, 46.4897077],
            [-63.7344029, 46.4897026],
            [-63.7346414, 46.4895081],
            [-63.7347874, 46.4893197],
            [-63.7351648, 46.4893987],
            [-63.7354306, 46.4892445],
            [-63.7355181, 46.4890749],
            [-63.7368869, 46.4873089],
            [-63.736976, 46.4870996],
            [-63.7374514, 46.4867503],
            [-63.7377898, 46.4866532],
            [-63.7383617, 46.4861982],
            [-63.7385686, 46.4862141],
            [-63.7388677, 46.4863752],
            [-63.7395329, 46.4866111],
            [-63.7403907, 46.4867869],
            [-63.7409293, 46.4870163],
            [-63.7417495, 46.4874104],
            [-63.7421658, 46.4873825],
            [-63.742566, 46.4874699],
            [-63.7430642, 46.4874117],
            [-63.7441734, 46.4870745],
            [-63.744739, 46.4870615],
            [-63.7458926, 46.4871951],
            [-63.7468269, 46.4874798],
            [-63.7479245, 46.4875725],
            [-63.7482205, 46.4878132],
            [-63.7482228, 46.4881876],
            [-63.7483158, 46.4883129],
            [-63.7483469, 46.4886878],
            [-63.7482541, 46.4888454],
            [-63.748263, 46.4889093],
            [-63.7482038, 46.488948],
            [-63.7480091, 46.4896333],
            [-63.7478097, 46.4900039],
            [-63.7473267, 46.4904009],
            [-63.7470538, 46.4907342],
            [-63.7469133, 46.4912174],
            [-63.747009, 46.4922827],
            [-63.7473872, 46.4934928],
            [-63.7473244, 46.4949135],
            [-63.7474071, 46.4952975],
            [-63.7478754, 46.4958441],
            [-63.7477402, 46.4963394],
            [-63.7475259, 46.4969368],
            [-63.7475297, 46.4972714],
            [-63.7476431, 46.4974648],
            [-63.7475127, 46.4978406],
            [-63.7473054, 46.4979761],
            [-63.7468655, 46.4980155],
            [-63.7459653, 46.4980261],
            [-63.7448952, 46.498388],
            [-63.7445251, 46.4984128],
            [-63.7443604, 46.4984932],
            [-63.7443106, 46.4987273],
            [-63.7439909, 46.4989323],
            [-63.7440942, 46.499233],
            [-63.743765, 46.4994043],
            [-63.7434374, 46.4994386],
            [-63.7431938, 46.4993949],
            [-63.7431572, 46.4993353],
            [-63.7430265, 46.4992601],
            [-63.7424769, 46.498716],
            [-63.7421249, 46.4984344],
            [-63.7417442, 46.4984351],
            [-63.7415628, 46.4984993],
            [-63.7416478, 46.4988236],
            [-63.7420085, 46.4993204],
            [-63.7419631, 46.4995864],
            [-63.7413231, 46.5],
            [-63.7409321, 46.5001243],
            [-63.7408308, 46.5003494],
            [-63.7410102, 46.5004763],
            [-63.7413507, 46.5004709],
            [-63.7414356, 46.5005123],
            [-63.7413306, 46.5006856],
            [-63.7410365, 46.5009707],
            [-63.7414277, 46.5009941],
            [-63.7421727, 46.5005424],
            [-63.7424458, 46.5004919],
            [-63.7427327, 46.5003859],
            [-63.7425741, 46.5],
            [-63.742482, 46.4998792],
            [-63.7425352, 46.4998483],
            [-63.742991, 46.4998451],
            [-63.7432861, 46.4998949],
            [-63.7433996, 46.4999899],
            [-63.7434774, 46.5000812],
            [-63.7437673, 46.500334],
            [-63.7437814, 46.5008441],
            [-63.7435148, 46.5010182],
            [-63.7428646, 46.5011213],
            [-63.7425936, 46.5012635],
            [-63.7423965, 46.5015743],
            [-63.7419541, 46.5016734],
            [-63.7414985, 46.5019594],
            [-63.7410086, 46.50195],
            [-63.7405436, 46.5023234],
            [-63.7398569, 46.5024736],
            [-63.7382486, 46.5030202],
            [-63.7375559, 46.503461],
            [-63.7369201, 46.5042055],
            [-63.7364457, 46.5043836],
            [-63.7359405, 46.5047523],
            [-63.7351608, 46.5050599],
            [-63.7346345, 46.5053804],
            [-63.7329717, 46.506129],
            [-63.7324323, 46.5064851],
            [-63.7315628, 46.5065878],
            [-63.7311427, 46.5068465],
            [-63.730652, 46.506857],
            [-63.7304802, 46.5066824],
            [-63.7300982, 46.5065714],
            [-63.7302337, 46.506359],
            [-63.7300961, 46.506197],
            [-63.7301609, 46.5057362],
            [-63.7301162, 46.5056995],
            [-63.7298666, 46.5057385],
            [-63.7297079, 46.5058111],
            [-63.7296795, 46.505942],
            [-63.7294729, 46.5060575],
            [-63.7292972, 46.5062652],
            [-63.729273, 46.5068622],
            [-63.7292999, 46.5073367],
            [-63.7291611, 46.5076288],
            [-63.7286924, 46.5079503],
            [-63.7280071, 46.5080606],
            [-63.727298, 46.5083337],
            [-63.7263083, 46.5085535],
            [-63.7249833, 46.5087907],
            [-63.7244739, 46.508976],
            [-63.7241257, 46.5095947],
            [-63.7238695, 46.5097928],
            [-63.7233475, 46.5098624],
            [-63.7229151, 46.5101368],
            [-63.7225965, 46.5101705],
            [-63.7222311, 46.5100758],
            [-63.7213116, 46.5101296],
            [-63.7206173, 46.5098931],
            [-63.7201765, 46.5099522],
            [-63.7200112, 46.5099012],
            [-63.7197364, 46.5099915],
            [-63.719658, 46.5103564],
            [-63.7201387, 46.5104534],
            [-63.7219754, 46.5112538],
            [-63.7223501, 46.5112611],
            [-63.7226014, 46.5111823],
            [-63.7231528, 46.5112448],
            [-63.7233009, 46.5111481],
            [-63.7233646, 46.5108585],
            [-63.7234884, 46.5107932],
            [-63.7237589, 46.5112366],
            [-63.7237013, 46.5115183],
            [-63.7228837, 46.5126098],
            [-63.7218619, 46.5131915],
            [-63.7213362, 46.513492],
            [-63.7208303, 46.514012],
            [-63.7199842, 46.5141031],
            [-63.7195997, 46.5143346],
            [-63.7193127, 46.5144406],
            [-63.7188133, 46.5145185],
            [-63.7183846, 46.5148448],
            [-63.7175941, 46.5151282],
            [-63.7165656, 46.5153033],
            [-63.7158766, 46.5156444],
            [-63.7154385, 46.5157753],
            [-63.7148786, 46.5157804],
            [-63.713909, 46.5155025],
            [-63.7133727, 46.5154961],
            [-63.7125529, 46.5156474],
            [-63.7114936, 46.5157303],
            [-63.710448, 46.5160405],
            [-63.7095567, 46.516529],
            [-63.7089492, 46.5169911],
            [-63.7086028, 46.5171357],
            [-63.7081931, 46.5171357],
            [-63.7078947, 46.5172374],
            [-63.7075471, 46.5172704],
            [-63.7071118, 46.5174731],
            [-63.706683, 46.5175165],
            [-63.7062992, 46.517728],
            [-63.7061177, 46.5177922],
            [-63.7056021, 46.5177024],
            [-63.7050993, 46.5178599],
            [-63.7045763, 46.5179492],
            [-63.7037976, 46.5178025],
            [-63.7032333, 46.5174927],
            [-63.7032731, 46.517366],
            [-63.7040353, 46.5172136],
            [-63.7042189, 46.5169583],
            [-63.7041347, 46.5161998],
            [-63.7039713, 46.5155433],
            [-63.7036206, 46.5150903],
            [-63.7030073, 46.5147118],
            [-63.7026379, 46.5144337],
            [-63.7022498, 46.5143305],
            [-63.7020229, 46.514095],
            [-63.7019105, 46.5138817],
            [-63.7014644, 46.5136459],
            [-63.7012005, 46.5136088],
            [-63.7008989, 46.5135073],
            [-63.7002568, 46.5131282],
            [-63.6997509, 46.5130824],
            [-63.6993345, 46.5128273],
            [-63.6992156, 46.5124904],
            [-63.6987318, 46.5121901],
            [-63.6987161, 46.5118711],
            [-63.698499, 46.5116796],
            [-63.698411, 46.5114349],
            [-63.6985654, 46.5113304],
            [-63.6987358, 46.5113935],
            [-63.6989205, 46.5115325],
            [-63.6991345, 46.5115208],
            [-63.6994366, 46.5111882],
            [-63.6995586, 46.5107484],
            [-63.6996192, 46.5105385],
            [-63.7001717, 46.5097328],
            [-63.700132, 46.5092938],
            [-63.7000213, 46.5090407],
            [-63.699919, 46.5090028],
            [-63.6996234, 46.5091763],
            [-63.6988214, 46.5097381],
            [-63.6983641, 46.5101951],
            [-63.698116, 46.5101943],
            [-63.6977204, 46.5098559],
            [-63.6973035, 46.5097521],
            [-63.6965976, 46.5096625],
            [-63.6962907, 46.5095489],
            [-63.6958435, 46.5092015],
            [-63.6957946, 46.50885],
            [-63.6958332, 46.5086118],
            [-63.6961229, 46.5081634],
            [-63.6961377, 46.5078052],
            [-63.6960832, 46.5073102],
            [-63.6967884, 46.506157],
            [-63.6980184, 46.5052889],
            [-63.6980803, 46.5049077],
            [-63.698503, 46.5045894],
            [-63.6984926, 46.5042825],
            [-63.6986251, 46.5038669],
            [-63.6984798, 46.5037525],
            [-63.698367, 46.5036905],
            [-63.6983342, 46.5035067],
            [-63.6981175, 46.5034466],
            [-63.6969772, 46.5035397],
            [-63.6965515, 46.503372],
            [-63.6963672, 46.5033644],
            [-63.6965607, 46.5035674],
            [-63.696514, 46.5037218],
            [-63.6962521, 46.5037764],
            [-63.6968836, 46.5041314],
            [-63.6972166, 46.504588],
            [-63.6972173, 46.5048509],
            [-63.6963361, 46.5055147],
            [-63.6959565, 46.5056267],
            [-63.6957831, 46.5057747],
            [-63.6955395, 46.5058057],
            [-63.6954376, 46.5058993],
            [-63.6954895, 46.5060398],
            [-63.695711, 46.5062632],
            [-63.6957097, 46.5064345],
            [-63.6951814, 46.5067945],
            [-63.6951865, 46.5070894],
            [-63.6948746, 46.507378],
            [-63.69479, 46.5077508],
            [-63.6954681, 46.5082341],
            [-63.6955605, 46.5085108],
            [-63.6952591, 46.5088235],
            [-63.6952735, 46.5093138],
            [-63.6951228, 46.5094701],
            [-63.695134, 46.5097572],
            [-63.6951839, 46.5098059],
            [-63.6951826, 46.5099772],
            [-63.694917, 46.5102627],
            [-63.6946472, 46.5106478],
            [-63.694647, 46.5109306],
            [-63.6952581, 46.5115003],
            [-63.6951923, 46.5116982],
            [-63.6949046, 46.5122382],
            [-63.6948698, 46.5126598],
            [-63.6950146, 46.5129255],
            [-63.6950659, 46.5135002],
            [-63.6951527, 46.5136333],
            [-63.6957091, 46.5139908],
            [-63.6957187, 46.5143177],
            [-63.6960301, 46.5144632],
            [-63.6966662, 46.5145674],
            [-63.6969382, 46.5146882],
            [-63.6968727, 46.5150176],
            [-63.697117, 46.5152494],
            [-63.6970287, 46.5155703],
            [-63.6968645, 46.5159137],
            [-63.6962757, 46.5162008],
            [-63.6956273, 46.5168095],
            [-63.695231, 46.5169053],
            [-63.6948688, 46.5170136],
            [-63.6944719, 46.5172608],
            [-63.694536, 46.5173855],
            [-63.6950777, 46.5174041],
            [-63.6952571, 46.5175312],
            [-63.695445, 46.5175906],
            [-63.6956781, 46.5175355],
            [-63.6959305, 46.5178512],
            [-63.6960852, 46.5178781],
            [-63.6965208, 46.5180898],
            [-63.6966007, 46.5182507],
            [-63.6962411, 46.5187137],
            [-63.69625, 46.5194747],
            [-63.6960915, 46.5196788],
            [-63.6960044, 46.5201112],
            [-63.6954965, 46.5206709],
            [-63.6953717, 46.5210389],
            [-63.6954073, 46.5212945],
            [-63.6956566, 46.521407],
            [-63.6957625, 46.5214967],
            [-63.6957713, 46.5218435],
            [-63.6962182, 46.5223422],
            [-63.6962099, 46.5228241],
            [-63.6965284, 46.5233562],
            [-63.6965641, 46.5240261],
            [-63.6965593, 46.5241455],
            [-63.6968928, 46.5244509],
            [-63.6968881, 46.5249846],
            [-63.6970374, 46.5252823],
            [-63.6969873, 46.5255163],
            [-63.6973316, 46.5258458],
            [-63.6974906, 46.5261875],
            [-63.6978418, 46.5264892],
            [-63.6978075, 46.5267594],
            [-63.6980115, 46.526855],
            [-63.6980524, 46.5271227],
            [-63.6982562, 46.5272184],
            [-63.6982931, 46.5273028],
            [-63.6981509, 46.5273916],
            [-63.6977704, 46.5272407],
            [-63.6975157, 46.527399],
            [-63.6978755, 46.5276332],
            [-63.6977209, 46.5280205],
            [-63.6976964, 46.5283347],
            [-63.6979738, 46.5284677],
            [-63.6983852, 46.5284279],
            [-63.6985473, 46.5282758],
            [-63.6983184, 46.5279486],
            [-63.6985418, 46.5278494],
            [-63.69924, 46.5278552],
            [-63.6994179, 46.5277392],
            [-63.6994115, 46.5276155],
            [-63.6992539, 46.5275168],
            [-63.6992564, 46.5274571],
            [-63.6995175, 46.5274224],
            [-63.6997019, 46.52743],
            [-63.7003977, 46.5272126],
            [-63.7008538, 46.5272096],
            [-63.7010979, 46.5270272],
            [-63.701146, 46.5267015],
            [-63.7012723, 46.5265765],
            [-63.7016354, 46.5264482],
            [-63.7021152, 46.5261509],
            [-63.7025931, 46.5261762],
            [-63.7027377, 46.5260276],
            [-63.7030342, 46.5258343],
            [-63.7031979, 46.5259251],
            [-63.7037817, 46.5266058],
            [-63.7039887, 46.5271875],
            [-63.7040041, 46.527375],
            [-63.7044432, 46.5282042],
            [-63.7049844, 46.5299198],
            [-63.7052881, 46.5303958],
            [-63.7058456, 46.5307334],
            [-63.7064379, 46.5306494],
            [-63.7069881, 46.5311661],
            [-63.7077858, 46.5311379],
            [-63.710072, 46.531549],
            [-63.7104315, 46.5313688],
            [-63.7108653, 46.531206],
            [-63.71141, 46.5312963],
            [-63.7118677, 46.5315363],
            [-63.7129342, 46.5319913],
            [-63.7131726, 46.532231],
            [-63.7132924, 46.5322652],
            [-63.7136775, 46.5321651],
            [-63.7137616, 46.5322265],
            [-63.7137725, 46.5323821],
            [-63.7138391, 46.5324471],
            [-63.713972, 46.5324457],
            [-63.7154748, 46.5318296],
            [-63.7162051, 46.5317562],
            [-63.7175, 46.5317097],
            [-63.7178493, 46.5316368],
            [-63.7188298, 46.5310902],
            [-63.7197392, 46.5310123],
            [-63.7202314, 46.5308306],
            [-63.7210019, 46.5307619],
            [-63.7212477, 46.5308224],
            [-63.7218268, 46.5307739],
            [-63.7224139, 46.5306105],
            [-63.7240706, 46.5318054],
            [-63.7244931, 46.5319211],
            [-63.725673, 46.5320037],
            [-63.7261401, 46.5318733],
            [-63.7264345, 46.5318711],
            [-63.7275719, 46.5322914],
            [-63.729005, 46.5325382],
            [-63.7296175, 46.5325222],
            [-63.7301115, 46.5328663],
            [-63.7305274, 46.5328584],
            [-63.7308312, 46.5327687],
            [-63.7316723, 46.5323826],
            [-63.7322121, 46.5323093],
            [-63.73252, 46.5324029],
            [-63.732823, 46.5326159],
            [-63.7328865, 46.5327605],
            [-63.7329329, 46.5330403],
            [-63.7331693, 46.5331882],
            [-63.7336267, 46.5332966],
            [-63.7339769, 46.5334866],
            [-63.7340355, 46.5337507],
            [-63.7338824, 46.5339668],
            [-63.7333496, 46.5342952],
            [-63.7318566, 46.5346727],
            [-63.7310406, 46.5350076],
            [-63.7293543, 46.5358793],
            [-63.7284076, 46.53659],
            [-63.7262298, 46.5379064],
            [-63.7243727, 46.5392807],
            [-63.7243386, 46.5395509],
            [-63.7244731, 46.5397925],
            [-63.7239386, 46.5404435],
            [-63.7234605, 46.5412668],
            [-63.7232104, 46.5420229],
            [-63.7224036, 46.5428359],
            [-63.7223618, 46.5431538],
            [-63.7221335, 46.5433725],
            [-63.7219755, 46.543708],
            [-63.7209086, 46.5446674],
            [-63.7207478, 46.545214],
            [-63.7201144, 46.5455961],
            [-63.7197614, 46.5461829],
            [-63.7194169, 46.5475506],
            [-63.7193888, 46.5480959],
            [-63.7197013, 46.5485043],
            [-63.7210959, 46.5494238],
            [-63.721202, 46.5497964],
            [-63.7211232, 46.5503128],
            [-63.7214345, 46.5508925],
            [-63.7214374, 46.55153],
            [-63.7211972, 46.5518958],
            [-63.7196731, 46.5531611],
            [-63.7184613, 46.5548347],
            [-63.7182922, 46.5552976],
            [-63.7183467, 46.5556612],
            [-63.7184133, 46.5557262],
            [-63.7184885, 46.5560066],
            [-63.7182065, 46.5561246],
            [-63.717677, 46.5565047],
            [-63.7171868, 46.5566267],
            [-63.7167365, 46.5569048],
            [-63.7161009, 46.5569123],
            [-63.7151849, 46.557281],
            [-63.7145732, 46.5576914],
            [-63.7140895, 46.5582198],
            [-63.713629, 46.5586053],
            [-63.7131425, 46.5587791],
            [-63.7119525, 46.5590666],
            [-63.7116306, 46.5593114],
            [-63.7112396, 46.5592679],
            [-63.7109136, 46.5593293],
            [-63.7104494, 46.5596628],
            [-63.7101689, 46.5600239],
            [-63.7102319, 46.56032],
            [-63.7098835, 46.5605044],
            [-63.7096144, 46.5608697],
            [-63.7091234, 46.5610115],
            [-63.7088339, 46.5613086],
            [-63.7080608, 46.5615684],
            [-63.7076795, 46.5618518],
            [-63.707449, 46.5622617],
            [-63.7073563, 46.5622678],
            [-63.7071747, 46.5620491],
            [-63.7069105, 46.5620121],
            [-63.7068483, 46.561979],
            [-63.7068162, 46.5617752],
            [-63.7066297, 46.5616759],
            [-63.7048468, 46.5610673],
            [-63.7039718, 46.560855],
            [-63.7037146, 46.5607902],
            [-63.702833, 46.560737],
            [-63.702175, 46.5605847],
            [-63.7017102, 46.5603724],
            [-63.7012473, 46.5602518],
            [-63.7009925, 46.5601272],
            [-63.7007142, 46.5597313],
            [-63.7001748, 46.5590873],
            [-63.7000083, 46.5586418],
            [-63.6999397, 46.558485],
            [-63.6999468, 46.5578915],
            [-63.6999843, 46.5578245],
            [-63.6999518, 46.556792],
            [-63.6999519, 46.5565092],
            [-63.7001978, 46.5562869],
            [-63.7005986, 46.5560916],
            [-63.7009648, 46.556035],
            [-63.7013167, 46.5561853],
            [-63.702012, 46.5562707],
            [-63.7021306, 46.5564762],
            [-63.7026242, 46.5569719],
            [-63.7038208, 46.5565252],
            [-63.7035567, 46.5562053],
            [-63.7035092, 46.5560968],
            [-63.7035202, 46.5559696],
            [-63.7036336, 46.5558801],
            [-63.7038694, 46.5557652],
            [-63.7038454, 46.5556452],
            [-63.7043397, 46.5554238],
            [-63.7046369, 46.5550791],
            [-63.7048543, 46.5549877],
            [-63.7052749, 46.5550119],
            [-63.7055176, 46.5548692],
            [-63.7056968, 46.5548647],
            [-63.7059364, 46.5552161],
            [-63.7060519, 46.5552183],
            [-63.7064011, 46.5547311],
            [-63.7064478, 46.5545767],
            [-63.7071356, 46.5541439],
            [-63.7072652, 46.5539392],
            [-63.7075314, 46.5537851],
            [-63.7079326, 46.5537212],
            [-63.708491, 46.5533417],
            [-63.7084557, 46.5532175],
            [-63.7079295, 46.5529522],
            [-63.7076711, 46.5527759],
            [-63.7069266, 46.5526219],
            [-63.7068084, 46.5525479],
            [-63.7065793, 46.5522207],
            [-63.7066506, 46.5514691],
            [-63.7066248, 46.5506917],
            [-63.7064936, 46.5506533],
            [-63.7063452, 46.55075],
            [-63.7062924, 46.5509123],
            [-63.7058749, 46.5513742],
            [-63.7058349, 46.5520667],
            [-63.7058364, 46.5525926],
            [-63.706076, 46.5532268],
            [-63.7060015, 46.5534922],
            [-63.7050634, 46.5543982],
            [-63.7050405, 46.5546726],
            [-63.7044443, 46.5549876],
            [-63.703755, 46.5551773],
            [-63.7034969, 46.5551324],
            [-63.7032946, 46.554997],
            [-63.7031767, 46.5550545],
            [-63.7030954, 46.5553477],
            [-63.7028962, 46.5554155],
            [-63.7028068, 46.555342],
            [-63.7027797, 46.5550188],
            [-63.7023323, 46.5548028],
            [-63.7017634, 46.5548754],
            [-63.7015025, 46.5547587],
            [-63.7009596, 46.55476],
            [-63.7008755, 46.5546986],
            [-63.700761, 46.5543935],
            [-63.7003064, 46.5540739],
            [-63.7000964, 46.5539861],
            [-63.700049, 46.5535947],
            [-63.6997029, 46.5533051],
            [-63.6988972, 46.5528151],
            [-63.6983869, 46.552586],
            [-63.6976813, 46.5521936],
            [-63.6971125, 46.5519833],
            [-63.6968391, 46.5517508],
            [-63.6964275, 46.5517905],
            [-63.6958877, 46.551995],
            [-63.6957171, 46.5519319],
            [-63.6956831, 46.5516364],
            [-63.6952318, 46.5512371],
            [-63.6951942, 46.5508898],
            [-63.6945878, 46.550332],
            [-63.6945623, 46.5502518],
            [-63.6946124, 46.5500178],
            [-63.6943118, 46.5497449],
            [-63.6939856, 46.5496747],
            [-63.6932569, 46.5498397],
            [-63.6926539, 46.5498995],
            [-63.6922192, 46.5497993],
            [-63.6916028, 46.5497632],
            [-63.6903743, 46.5500059],
            [-63.6898444, 46.5499716],
            [-63.6893044, 46.5500446],
            [-63.6860203, 46.550143],
            [-63.6855614, 46.5497913],
            [-63.6844266, 46.5490278],
            [-63.6827982, 46.5483342],
            [-63.6818816, 46.5477424],
            [-63.681392, 46.5472984],
            [-63.680896, 46.5471451],
            [-63.6803276, 46.5466518],
            [-63.6802025, 46.5466055],
            [-63.6795293, 46.5465483],
            [-63.6793429, 46.546449],
            [-63.679109, 46.5462412],
            [-63.6788563, 46.5462083],
            [-63.6782561, 46.5463398],
            [-63.67804, 46.5462598],
            [-63.6779437, 46.5457997],
            [-63.6774952, 46.5453406],
            [-63.677448, 46.5449492],
            [-63.677101, 46.5445479],
            [-63.6767615, 46.5435532],
            [-63.6768635, 46.5426309],
            [-63.6769836, 46.5423823],
            [-63.6768174, 46.5415225],
            [-63.6767517, 46.5414375],
            [-63.6763368, 46.5411424],
            [-63.6763209, 46.5406919],
            [-63.6760297, 46.5402001],
            [-63.6759354, 46.5398317],
            [-63.6760261, 46.5397339],
            [-63.6760885, 46.5394842],
            [-63.6760219, 46.5394191],
            [-63.6760119, 46.5388293],
            [-63.6761731, 46.5385656],
            [-63.6766695, 46.5381532],
            [-63.677368, 46.5378762],
            [-63.6780486, 46.5377543],
            [-63.6788464, 46.537312],
            [-63.6792807, 46.5372808],
            [-63.6800722, 46.5375435],
            [-63.680573, 46.5375774],
            [-63.6807546, 46.5375132],
            [-63.681266, 46.5375712],
            [-63.6813481, 46.537541],
            [-63.6815386, 46.5371264],
            [-63.6820158, 46.5367574],
            [-63.6820445, 46.5363437],
            [-63.6824217, 46.5361599],
            [-63.6826351, 46.5358853],
            [-63.6829509, 46.5356486],
            [-63.6834246, 46.535642],
            [-63.6835077, 46.535309],
            [-63.683724, 46.5351061],
            [-63.6843188, 46.5349626],
            [-63.6847008, 46.5346594],
            [-63.6849583, 46.5341586],
            [-63.6852724, 46.5339616],
            [-63.6859496, 46.5339192],
            [-63.68615, 46.5336802],
            [-63.686092, 46.5335476],
            [-63.6856919, 46.5335914],
            [-63.6853577, 46.5334374],
            [-63.6851519, 46.5332501],
            [-63.6846037, 46.5331078],
            [-63.6844908, 46.5330458],
            [-63.6840268, 46.5326821],
            [-63.684024, 46.532196],
            [-63.6839399, 46.5321346],
            [-63.6837957, 46.5321317],
            [-63.6836056, 46.5322634],
            [-63.6835956, 46.5325022],
            [-63.6834169, 46.5330524],
            [-63.6829426, 46.5339075],
            [-63.6825117, 46.5342734],
            [-63.6819544, 46.53435],
            [-63.6808691, 46.5347667],
            [-63.6802033, 46.5348132],
            [-63.6798045, 46.5346858],
            [-63.6795388, 46.5346885],
            [-63.6791507, 46.5349995],
            [-63.6787525, 46.535135],
            [-63.6783024, 46.53513],
            [-63.6781518, 46.5350036],
            [-63.6780495, 46.5349657],
            [-63.6775026, 46.5350663],
            [-63.6773609, 46.5350038],
            [-63.6772328, 46.5347542],
            [-63.676511, 46.5343455],
            [-63.6758077, 46.5340447],
            [-63.6752728, 46.5335839],
            [-63.6745152, 46.5336166],
            [-63.6738775, 46.5332692],
            [-63.6731809, 46.5332235],
            [-63.6726962, 46.5333572],
            [-63.6720523, 46.5338464],
            [-63.6717265, 46.5339076],
            [-63.6715052, 46.5338155],
            [-63.6714767, 46.5335321],
            [-63.6715707, 46.5333547],
            [-63.6715632, 46.5331195],
            [-63.6712962, 46.5328792],
            [-63.6708417, 46.5328422],
            [-63.6696438, 46.5329139],
            [-63.6692967, 46.5327954],
            [-63.6686761, 46.532313],
            [-63.6676705, 46.5319303],
            [-63.6670743, 46.5318308],
            [-63.6664627, 46.5318265],
            [-63.6655821, 46.5324503],
            [-63.665438, 46.5324474],
            [-63.665283, 46.5322889],
            [-63.6652721, 46.5315876],
            [-63.6653905, 46.5313788],
            [-63.6656218, 46.5312321],
            [-63.666017, 46.5308934],
            [-63.66625, 46.5302925],
            [-63.666635, 46.5296469],
            [-63.6670311, 46.5292883],
            [-63.6685595, 46.5284902],
            [-63.6687414, 46.5281433],
            [-63.6687604, 46.5263112],
            [-63.6685431, 46.5259882],
            [-63.6682598, 46.525863],
            [-63.6679584, 46.5258928],
            [-63.6676354, 46.5260258],
            [-63.6674283, 46.5260097],
            [-63.6673557, 46.5259525],
            [-63.6673685, 46.5257854],
            [-63.6676033, 46.5256905],
            [-63.6678994, 46.5256487],
            [-63.668101, 46.5255212],
            [-63.6681928, 46.5251207],
            [-63.6684333, 46.524755],
            [-63.6684253, 46.5242569],
            [-63.6687986, 46.5234756],
            [-63.6691405, 46.5231678],
            [-63.6689926, 46.5225673],
            [-63.6691783, 46.5219894],
            [-63.6692066, 46.521727],
            [-63.6693879, 46.5215315],
            [-63.669643, 46.5210904],
            [-63.6697323, 46.5207496],
            [-63.6701942, 46.5201931],
            [-63.6703398, 46.5200248],
            [-63.6703872, 46.519719],
            [-63.6703248, 46.5195544],
            [-63.6698218, 46.5191659],
            [-63.6685363, 46.5184471],
            [-63.6682317, 46.5181423],
            [-63.6681682, 46.5178661],
            [-63.6681128, 46.5176738],
            [-63.6681076, 46.5172475],
            [-63.6679264, 46.5170287],
            [-63.6677673, 46.5169698],
            [-63.6675489, 46.5169495],
            [-63.6668345, 46.5170587],
            [-63.6651896, 46.5174799],
            [-63.6643974, 46.5175198],
            [-63.6640844, 46.517414],
            [-63.6637222, 46.5171079],
            [-63.6634293, 46.5169387],
            [-63.6633923, 46.5172686],
            [-63.6635879, 46.5176948],
            [-63.6638398, 46.5178791],
            [-63.6644507, 46.5180348],
            [-63.6648893, 46.5180356],
            [-63.6655798, 46.5178064],
            [-63.6657753, 46.5176868],
            [-63.6662388, 46.5175049],
            [-63.6668414, 46.5174452],
            [-63.6670135, 46.5174686],
            [-63.6673336, 46.5176782],
            [-63.667473, 46.5179319],
            [-63.6673493, 46.5185429],
            [-63.6675544, 46.5188816],
            [-63.6681046, 46.5192471],
            [-63.6688545, 46.519525],
            [-63.6693011, 46.5196097],
            [-63.6694857, 46.5197488],
            [-63.6694233, 46.5199985],
            [-63.6687457, 46.5206064],
            [-63.668447, 46.5215366],
            [-63.6682374, 46.5219945],
            [-63.6677114, 46.5228404],
            [-63.6667612, 46.523893],
            [-63.6667294, 46.524235],
            [-63.6669512, 46.5250043],
            [-63.6669974, 46.5254155],
            [-63.6668195, 46.5260772],
            [-63.6669395, 46.5262429],
            [-63.6667751, 46.5265862],
            [-63.6661093, 46.527047],
            [-63.6650493, 46.5274122],
            [-63.6646617, 46.5275717],
            [-63.663204, 46.527921],
            [-63.6628602, 46.5281372],
            [-63.6629322, 46.5293059],
            [-63.6627948, 46.5296895],
            [-63.6623674, 46.5301072],
            [-63.6603503, 46.5314013],
            [-63.6598623, 46.5316146],
            [-63.6589588, 46.5316841],
            [-63.6576812, 46.5321405],
            [-63.6571081, 46.5321808],
            [-63.6564298, 46.5321113],
            [-63.6559182, 46.5323361],
            [-63.6554717, 46.5323829],
            [-63.6549288, 46.5322525],
            [-63.6545693, 46.5320181],
            [-63.654444, 46.5312946],
            [-63.6541165, 46.5308498],
            [-63.6539441, 46.5306949],
            [-63.6535901, 46.5306041],
            [-63.6521177, 46.5306143],
            [-63.6517532, 46.5304994],
            [-63.6514507, 46.5304176],
            [-63.6510424, 46.530246],
            [-63.6504665, 46.5302145],
            [-63.650015, 46.5298349],
            [-63.6496111, 46.5296953],
            [-63.6490668, 46.5297361],
            [-63.6488555, 46.5298194],
            [-63.6480603, 46.5297875],
            [-63.6477702, 46.5298214],
            [-63.647444, 46.5296196],
            [-63.6473575, 46.5296179],
            [-63.6467142, 46.5294097],
            [-63.6464071, 46.5291644],
            [-63.6459857, 46.5283512],
            [-63.6456298, 46.5281687],
            [-63.6454337, 46.5278939],
            [-63.6450439, 46.5278302],
            [-63.6446373, 46.5276188],
            [-63.6441932, 46.5274744],
            [-63.6432736, 46.5271132],
            [-63.6431687, 46.5271349],
            [-63.6435292, 46.5274809],
            [-63.6439734, 46.5277568],
            [-63.6447322, 46.5279673],
            [-63.6450925, 46.5281818],
            [-63.645692, 46.5288791],
            [-63.6457572, 46.5292469],
            [-63.6455733, 46.5296336],
            [-63.6456459, 46.5296908],
            [-63.646276, 46.5298032],
            [-63.6467816, 46.5301321],
            [-63.6475273, 46.5303782],
            [-63.6494601, 46.5303973],
            [-63.6498571, 46.5305647],
            [-63.6508375, 46.5311303],
            [-63.6512728, 46.5312107],
            [-63.6515744, 46.5313124],
            [-63.6516304, 46.5313534],
            [-63.6517794, 46.5316512],
            [-63.6520577, 46.5318958],
            [-63.6524309, 46.5319432],
            [-63.6530144, 46.5317956],
            [-63.6534242, 46.5317959],
            [-63.6536036, 46.531923],
            [-63.6536819, 46.5322552],
            [-63.6535625, 46.5324839],
            [-63.6534912, 46.5332155],
            [-63.653536, 46.5339295],
            [-63.6536325, 46.5341067],
            [-63.6540912, 46.53459],
            [-63.6539785, 46.5346595],
            [-63.6537478, 46.5346548],
            [-63.6535327, 46.5345549],
            [-63.6532479, 46.5346009],
            [-63.6525837, 46.5351532],
            [-63.652197, 46.5354243],
            [-63.6522391, 46.5355208],
            [-63.6533519, 46.5351448],
            [-63.6535466, 46.5350452],
            [-63.6543825, 46.5347991],
            [-63.6549243, 46.5342721],
            [-63.6550807, 46.5342594],
            [-63.6558391, 46.5342069],
            [-63.6565397, 46.5340218],
            [-63.6578269, 46.5340238],
            [-63.6579774, 46.5341503],
            [-63.6579442, 46.5346635],
            [-63.6580928, 46.5346984],
            [-63.6585347, 46.5344881],
            [-63.6586429, 46.5343867],
            [-63.6585849, 46.5342541],
            [-63.6584729, 46.5341722],
            [-63.6584658, 46.5340685],
            [-63.6586203, 46.533964],
            [-63.6591665, 46.5340148],
            [-63.6598223, 46.5342072],
            [-63.6605369, 46.5340981],
            [-63.6607762, 46.5340352],
            [-63.6616667, 46.5340012],
            [-63.6623782, 46.5341031],
            [-63.663487, 46.5345038],
            [-63.6642756, 46.5346949],
            [-63.6645473, 46.5346844],
            [-63.6659585, 46.534621],
            [-63.6671029, 46.534863],
            [-63.6677008, 46.5349228],
            [-63.6679894, 46.53506],
            [-63.6684708, 46.5354202],
            [-63.6689075, 46.5357436],
            [-63.6691243, 46.5358037],
            [-63.6695525, 46.5357804],
            [-63.67007, 46.5354163],
            [-63.6703279, 46.5350469],
            [-63.6706943, 46.5347077],
            [-63.6709563, 46.5346531],
            [-63.6710465, 46.5347067],
            [-63.6712145, 46.5348296],
            [-63.6712114, 46.5350407],
            [-63.6702995, 46.5361379],
            [-63.6699715, 46.5363903],
            [-63.6697987, 46.5365184],
            [-63.6678576, 46.537376],
            [-63.667509, 46.5374287],
            [-63.6668437, 46.5377381],
            [-63.6660311, 46.5378414],
            [-63.6629778, 46.5389236],
            [-63.6597123, 46.5402406],
            [-63.6589059, 46.5408817],
            [-63.6584335, 46.5411312],
            [-63.6567674, 46.54203],
            [-63.6563671, 46.5420737],
            [-63.6557347, 46.5422841],
            [-63.6555275, 46.542268],
            [-63.6549401, 46.5422323],
            [-63.6547279, 46.5423356],
            [-63.6543285, 46.5423594],
            [-63.6540653, 46.5423023],
            [-63.6537419, 46.5423038],
            [-63.6536249, 46.5423412],
            [-63.653487, 46.542462],
            [-63.6536042, 46.542556],
            [-63.6543858, 46.5426434],
            [-63.6548311, 46.5428994],
            [-63.655185, 46.5428587],
            [-63.6557631, 46.5425675],
            [-63.6560882, 46.5425263],
            [-63.6561783, 46.5425799],
            [-63.6560817, 46.542817],
            [-63.6560819, 46.5429484],
            [-63.6560026, 46.5430504],
            [-63.6557718, 46.5430458],
            [-63.655584, 46.5431177],
            [-63.6555806, 46.5431973],
            [-63.6558897, 46.5435342],
            [-63.6556768, 46.544749],
            [-63.6556732, 46.5452429],
            [-63.6556722, 46.5456772],
            [-63.6559885, 46.5466636],
            [-63.656304, 46.5471241],
            [-63.6569532, 46.5474758],
            [-63.656989, 46.5479945],
            [-63.6568432, 46.5485772],
            [-63.6568546, 46.5491272],
            [-63.6571446, 46.5500534],
            [-63.6573864, 46.5503451],
            [-63.6576, 46.5504848],
            [-63.6580443, 46.5506292],
            [-63.6584876, 46.550662],
            [-63.6587963, 46.550736],
            [-63.658964, 46.5511417],
            [-63.6596485, 46.5516176],
            [-63.6596393, 46.5523824],
            [-63.6599454, 46.5529303],
            [-63.6600529, 46.5533947],
            [-63.6604847, 46.5538376],
            [-63.6606536, 46.5539406],
            [-63.6609567, 46.5542853],
            [-63.6611435, 46.555062],
            [-63.6614778, 46.5557619],
            [-63.6618694, 46.5562001],
            [-63.6621049, 46.5563681],
            [-63.6633974, 46.5569478],
            [-63.6639125, 46.5570577],
            [-63.6648129, 46.5569363],
            [-63.6657038, 46.5569023],
            [-63.6658122, 46.5569324],
            [-63.6668619, 46.5576546],
            [-63.6672512, 46.5577381],
            [-63.6678607, 46.5578021],
            [-63.6685629, 46.5582743],
            [-63.6691893, 46.558486],
            [-63.6705738, 46.558665],
            [-63.6710746, 46.5585674],
            [-63.6716065, 46.5586936],
            [-63.6720504, 46.5589893],
            [-63.6723262, 46.5595765],
            [-63.6724163, 46.5596366],
            [-63.6729049, 46.5599625],
            [-63.6733343, 46.5601551],
            [-63.6738064, 46.5603669],
            [-63.6750752, 46.5608265],
            [-63.6765442, 46.5611983],
            [-63.6769728, 46.5613064],
            [-63.677911, 46.5612494],
            [-63.678437, 46.5611005],
            [-63.6786238, 46.5609169],
            [-63.6786215, 46.5606937],
            [-63.6792977, 46.5604084],
            [-63.6793257, 46.5600145],
            [-63.6795781, 46.5599159],
            [-63.6799233, 46.5598112],
            [-63.6804166, 46.5598927],
            [-63.6806333, 46.5598213],
            [-63.6808647, 46.5596745],
            [-63.6810417, 46.5598613],
            [-63.6808646, 46.5607861],
            [-63.6804715, 46.561348],
            [-63.679815, 46.5618529],
            [-63.6785706, 46.5626052],
            [-63.6772454, 46.5632163],
            [-63.6767074, 46.5635124],
            [-63.6762401, 46.5637741],
            [-63.6759127, 46.5640066],
            [-63.6756544, 46.5642445],
            [-63.6755528, 46.5646011],
            [-63.6754132, 46.5647617],
            [-63.6751657, 46.5647408],
            [-63.6738729, 46.56499],
            [-63.673554, 46.565155],
            [-63.6730567, 46.5657188],
            [-63.6727667, 46.5658843],
            [-63.6711572, 46.5661073],
            [-63.6708583, 46.5662089],
            [-63.6702758, 46.5665997],
            [-63.6696323, 46.5666546],
            [-63.669226, 46.5668377],
            [-63.6688813, 46.5672054],
            [-63.6687869, 46.5672513],
            [-63.6684628, 46.5674042],
            [-63.6683942, 46.5676618],
            [-63.6683217, 46.5677361],
            [-63.6679066, 46.5678553],
            [-63.6674773, 46.5678985],
            [-63.6671917, 46.5676816],
            [-63.6665241, 46.567485],
            [-63.6663596, 46.567667],
            [-63.6659686, 46.5676776],
            [-63.6653904, 46.5676522],
            [-63.6651061, 46.5675936],
            [-63.6648773, 46.5674919],
            [-63.6641231, 46.5672936],
            [-63.6637957, 46.5671627],
            [-63.6634313, 46.567017],
            [-63.6627168, 46.5668493],
            [-63.6625563, 46.5668801],
            [-63.6614057, 46.5666231],
            [-63.6607511, 46.5665958],
            [-63.6601078, 46.5665997],
            [-63.65956, 46.5663893],
            [-63.6590008, 46.5662101],
            [-63.6585736, 46.5661323],
            [-63.6570378, 46.5658526],
            [-63.6560045, 46.5655609],
            [-63.6554823, 46.5654787],
            [-63.6543984, 46.5652868],
            [-63.6525151, 46.5648851],
            [-63.6519457, 46.5648258],
            [-63.6509449, 46.5645865],
            [-63.6505318, 46.5643829],
            [-63.6496808, 46.5642901],
            [-63.6491736, 46.5642639],
            [-63.648667, 46.5640863],
            [-63.6475948, 46.5640328],
            [-63.6473056, 46.5641784],
            [-63.6471937, 46.5642279],
            [-63.6464459, 46.564153],
            [-63.6456577, 46.5639419],
            [-63.6451516, 46.5634814],
            [-63.6444386, 46.5632678],
            [-63.6430699, 46.5631245],
            [-63.6404939, 46.5625743],
            [-63.6398141, 46.5622617],
            [-63.6363372, 46.5614381],
            [-63.6355509, 46.5613186],
            [-63.6354897, 46.5612655],
            [-63.6360193, 46.5610372],
            [-63.6363128, 46.5607922],
            [-63.6362794, 46.5606281],
            [-63.6357277, 46.5606966],
            [-63.6349866, 46.5610083],
            [-63.6345817, 46.5610199],
            [-63.6319135, 46.5606071],
            [-63.6279585, 46.5597575],
            [-63.6259026, 46.5594804],
            [-63.6256804, 46.5594081],
            [-63.6248384, 46.559379],
            [-63.6242087, 46.5592466],
            [-63.6233167, 46.5591686],
            [-63.6226302, 46.5590151],
            [-63.6217339, 46.5589051],
            [-63.6213578, 46.5587858],
            [-63.620472542971683, 46.558661303919884],
            [-63.6142434, 46.544152],
          ],
        ],
      },
    },
  ],
};
