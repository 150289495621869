import "./PopupForm.css";
import React, { useState, useEffect } from "react";

export const IrrigationTypes = new Set(
  Object.keys({
    "Center Pivot": 0.9,
    "Trickle/Drip": 0.88,
    "Subsurface Drip": 0.9,
  }).sort()
);

export const CropTypes = {
  "Agronmic Crops": new Set([
    "Barley",
    "Beans (Dry)",
    "Castor Beans",
    "Cotton",
    "Flax",
    "Groundnut",
    "Maize",
    "Millets",
    "Oats",
    "Rice",
    "Safflower",
    "Sesame",
    "Sorghum",
    "Sugarcane",
    "Sunflower",
    "Wheat",
  ]),
  Vegetables: new Set([
    "Beans (green)",
    "Cabbage",
    "Carrot",
    "Cauliflower",
    "Cucumber",
    "Egg Plant",
    "Lettuce",
    "Onion (Green)",
    "Onion (dry)",
    "Peas (Green)",
    "Potato",
    "Pumpkin",
    "Spinach",
    "Sweet Melon",
    "Sweet Pepper",
    "Sweet Potato",
    "Tomato",
    "Water melon",
  ]),
};

{
  /* Forages: new Set([
    "Alfalfa (Lucerne)",
    "Bermuda Grass",
    "Maize",
    "Millet",
    "Oats",
    "Sorghum",
  ]),
  Pulses: new Set(["Beans dry", "Lentil"]),
  Orchards: new Set([
    "Banana 2nd year",
    "Banana Ist year",
    "Citrus with GC",
    "Citrus without GC",
    "Grapes",
    "Olives with 60% GC",
    "Pine apple with GC",
    "Pine apple without GC",
    "Pistachio",
    "Walnut",
  ]), */
}

{
  /*     "Graded Furrow": 0.65,
    "Graded Furrow with Tailwater reuse": 0.75,
    "Level Furrow": 0.8,
    "Graded Border": 0.65,
    "Level Basins": 0.85,
    "Flood": 0.5,
    "Side Roll (Wheelline)": 0.75,
    "Moving Big Gun": 0.65,
    "Solid-Set (Overtree)": 0.7,
    "Solid-Set (Undertree)": 0.75,
    "Pop-Up Impact": 0.75,
    "Impact heads with end gun": 0.8,
    "Spray heads without end gun": 0.9,
    "LEPA without end gun": 0.92,
    "Spray heads with hose feed": 0.9,
    "Spray heads with canal feed": 0.85,
    "Microspray": 0.85 */
}

export function PopupForm({ latitude, longitude, onClose }) {
  const [cropType, setCropType] = React.useState("");
  const [crop, setCrop] = React.useState("");
  const [irrigationType, setIrrigationType] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [waterRequirement, setWaterRequirement] = useState(null);
  const [etMm, setEtMm] = useState(null);
  const [area, setArea] = React.useState("1");
  const [errorMessage, setErrorMessage] = useState("");

  const formatDate = (date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  };

  const getMay30thOrCurrent = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const may30th = new Date(currentYear, 4, 30); // Months are 0-indexed in JS Date

    if (currentDate <= may30th) {
      return formatDate(currentDate);
    } else {
      return `${currentYear}-05-30`;
    }
  };

  const currentDate = new Date();
  const defaultSowingDate = getMay30thOrCurrent;
  const [sowingDate, setSowingDate] = React.useState(defaultSowingDate);

  const oneAndHalfYearsAgo = new Date(currentDate);
  oneAndHalfYearsAgo.setMonth(currentDate.getMonth() - 18);

  const minDate = formatDate(oneAndHalfYearsAgo);
  const maxDate = formatDate(currentDate);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!cropType) {
      setErrorMessage("Please select a Crop Type.");
      return;
    }
    if (!crop) {
      setErrorMessage("Please select a Crop.");
      return;
    }
    if (!irrigationType) {
      setErrorMessage("Please select an Irrigation Type.");
      return;
    }
    if (!area) {
      setErrorMessage("Please enter the Area in acres.");
      return;
    }
    if (!sowingDate) {
      setErrorMessage("Please select a Sowing Date.");
      return;
    }

    setLoading(true);

    const formData = {
      crop,
      cropType,
      irrigationType,
      latitude,
      longitude,
      area,
      sowingDate,
    };
    console.log(sowingDate);

    // Making a POST request to your server
    fetch(`${process.env.REACT_APP_BACKEND_URL}calcFieldEt0/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        setWaterRequirement(data.etLiters);
        setEtMm(data.etMm);
        setLoading(false);
      })
      .catch((error) => {
        alert("An error occurred. Please try again later.");
        console.error("Error sending POST request:", error);
        setLoading(false);
        console.log(JSON.stringify(formData));
      });

    setErrorMessage("");
  };

  // If water requirement is set, display it
  if (waterRequirement !== null) {
    return (
      <div className="popup-container">
        <div className="requirement-section">
          <h2>Potential Crop Water Requirement</h2>
          <p id="crop-water-req">{etMm} mm/day</p>
          <div>
            <span className="water-volume-label">Volume requirement:</span>
            <span className="water-volume-value">
              {waterRequirement} liters
            </span>
          </div>
        </div>
        <div className="details-section">
          <p>
            <strong>Crop Type:</strong> <span>{cropType}</span>
          </p>
          <p>
            <strong>Crop:</strong> <span>{crop}</span>
          </p>
          <p>
            <strong>Irrigation Type:</strong> <span>{irrigationType}</span>
          </p>
          <p>
            <strong>Area:</strong> <span>{area} acre(s)</span>
          </p>
          <p>
            <strong>Sowing Date:</strong> <span>{sowingDate} </span>
          </p>
        </div>
      </div>
    );
  }

  // If loading, display spinner
  if (loading) {
    return <div className="loading-spinner"></div>;
  }

  const handleCropTypeChange = (e) => {
    setCropType(e.target.value);
    setCrop("");
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const maxDigits = 8;

    const isNumber = /^[0-9]*$/.test(inputValue);

    // Allow input change only if the input value is a number and its length is less than or equal to the maximum digit limit
    if (isNumber && inputValue.length <= maxDigits) {
      setArea(inputValue);
    }
  };

  return (
    <div className="popup-container">
      <div className="popup-form">
        <label htmlFor="cropType">Crop Type:</label>
        <select
          id="cropType"
          value={cropType}
          onChange={(e) => setCropType(e.target.value)}
          required
        >
          <option value="">Select Crop Type</option>
          {Object.keys(CropTypes).map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>

        <label htmlFor="crop">Crop:</label>
        <select
          id="crop"
          value={crop}
          onChange={(e) => setCrop(e.target.value)}
          required
          disabled={!cropType}
        >
          <option value="">Select Crop</option>
          {cropType &&
            Array.from(CropTypes[cropType]).map((cropName) => (
              <option key={cropName} value={cropName}>
                {cropName}
              </option>
            ))}
        </select>

        <label htmlFor="irrigation-type">Irrigation Type:</label>
        <select
          id="irrigation-type"
          value={irrigationType}
          onChange={(e) => setIrrigationType(e.target.value)}
          required
        >
          <option value="">Select Irrigation Type</option>
          {Array.from(IrrigationTypes).map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>

        <label htmlFor="area">Area (in acres):</label>
        <input
          type="number"
          id="area"
          value={area}
          onChange={(e) => handleInputChange(e)}
          placeholder="Enter area in acres"
          required
        />

        <label htmlFor="sowing-date">Sowing Date:</label>
        <input
          type="date"
          id="sowing-date"
          value={sowingDate}
          onChange={(e) => setSowingDate(e.target.value)}
          min={minDate}
          max={maxDate}
          required
        />

        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
}
