import React from "react";
import "./Legend.css";

const heatmapColors = [
  { value: 0.0, color: "#2d0055" },
  { value: 2.6, color: "#7df0fa" },
  { value: 5.0, color: "#288228" },
  { value: 7.6, color: "#aa0000" },
  { value: 10.0, color: "#e63200" },
];

const Legend = ({ isVisible }) => {
  if (!isVisible) return null;

  return (
    <div className="legend-container">
      <span className="legend-title">mm / day</span>
      {heatmapColors.map((item, index) => (
        <div key={index} className="legend-item">
          <span
            className="legend-color"
            style={{ backgroundColor: item.color }}
          />
          <span className="legend-value">
            {parseFloat(item.value).toFixed(1)}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Legend;
